import { Button, Grid } from '@material-ui/core'
import { useState } from 'react'
import {
  BreadcumbsList,
  CustomButton,
  FlexBox,
  Topic,
  TypographyWithLabel,
  withLayout,
} from '../../../custom-components'
import { useContext } from 'react'
import { useRefundRequisitionProfile } from '../../../hooks/finance'
import { ConditionTooltip, CustomerProfileCard, Loading, RefundRequisitionStatusChip } from '../../../shared-components'
import { useCustomerProfile } from '../../../hooks'
import RefundRequisitionProfileMenu from './RefundRequisitionProfileMenu'
import RefundRequisitionSaleOrderDocumentTable from './RefundRequisitionSaleOrderDocumentTable'
import RefundRequisitionSaleOrderPaymentTable from './RefundRequisitionSaleOrderPaymentTable'
import RefundRequisitionSaleOrderActivityTable from './RefundRequisitionSaleOrderActivityTable'
import { RefundRequisitionApproveDialog, RefundRequisitionRejectDialog, RefundRequisitionUploadSlipDialog } from '.'
import { RefundStatus, PageName } from '../../../enums'
import { RootContext } from '../../..'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import RefundRequisitionProfileInfoBox from './RefundRequisitionProfileInfoBox'
import { IRefundRequisitionProfile } from '../../../api/smartbestbuys-api'
import RefundRequisitionProfilePayslipBox from './RefundRequisitionProfilePayslipBox'

interface Props {
  refundRequisitionId: number
}

const RefundRequisitionProfilePage = (props: Props) => {
  // props
  const { refundRequisitionId } = props

  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.FINANCE_REFUND_REQUISITION_LIST)

  // prepare data
  const [refundRequisitionProfile, setRefundRequisitionProfile] = useRefundRequisitionProfile(refundRequisitionId)

  // TODO: optimize
  const customerProfile = useCustomerProfile(refundRequisitionProfile?.customer.id)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_RefundRequisition_Write)
  const reviewPermission = getUserPermissionInfo(PermissionEnum.FinancePay_RefundRequisition_Review)

  //state
  const [approveOpen, setApproveOpen] = useState(false)
  const [rejectOpen, setRejectOpen] = useState(false)
  const [openUploadSlip, setOpenUploadSlip] = useState(false)

  // pending load data
  if (!refundRequisitionProfile) return <Loading />

  const url = '/finance/refund-requisitions-list'
  const listPageName = 'รายการคืนเงิน'
  const topic = `การคืนเงิน ${refundRequisitionProfile.code}`
  const breadcumbsItems = [{ url, name: listPageName }, { name: topic }]

  return (
    <>
      <RefundRequisitionUploadSlipDialog
        refundRequisitionProfile={refundRequisitionProfile}
        open={openUploadSlip}
        onClose={() => setOpenUploadSlip(false)}
        onComplete={(data) => setRefundRequisitionProfile(data)}
      />
      <RefundRequisitionApproveDialog
        open={approveOpen}
        onClose={() => setApproveOpen(false)}
        refundRequisitionId={refundRequisitionId}
        onComplete={(data: IRefundRequisitionProfile) => setRefundRequisitionProfile(data)}
      />
      <RefundRequisitionRejectDialog
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        refundRequisitionId={refundRequisitionId}
        onComplete={(data: IRefundRequisitionProfile) => setRefundRequisitionProfile(data)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BreadcumbsList items={breadcumbsItems} />
        </Grid>
        <Grid item xs={12} container justify="space-between">
          <Topic>{topic}</Topic>
          <RefundRequisitionProfileMenu
            refundRequisitionInfo={{
              id: refundRequisitionProfile.id,
              code: refundRequisitionProfile.code,
              status: refundRequisitionProfile.status,
            }}
            onCancelSuccess={setRefundRequisitionProfile}
          />
        </Grid>
        <Grid item xs={12} container justify="space-between" alignItems="center">
          <FlexBox alignItems="center" gridGap={16}>
            <RefundRequisitionStatusChip
              icon
              status={refundRequisitionProfile.status.id}
              label={refundRequisitionProfile.status.name}
            />
            {[RefundStatus.Approve, RefundStatus.Failed, RefundStatus.Done].includes(
              refundRequisitionProfile.status.id,
            ) && (
              <TypographyWithLabel variant="body2" label="ตรวจสอบโดย:">
                {refundRequisitionProfile.reviewedBy}
              </TypographyWithLabel>
            )}
            {[RefundStatus.Failed].includes(refundRequisitionProfile.status.id) && (
              <TypographyWithLabel variant="body2" label="สาเหตุที่ไม่อนุมัติ:" color="error">
                {refundRequisitionProfile.failureMessage}
              </TypographyWithLabel>
            )}
            {[RefundStatus.Canceled].includes(refundRequisitionProfile.status.id) && (
              <TypographyWithLabel variant="body2" label="สาเหตุที่ยกเลิก:" color="error">
                {refundRequisitionProfile.failureMessage}
              </TypographyWithLabel>
            )}
          </FlexBox>
          {reviewPermission.hasPermission && refundRequisitionProfile.status.id === RefundStatus.Pending && (
            <FlexBox gridGap={8}>
              <CustomButton
                variant="contained"
                color="error"
                onClick={() => setRejectOpen(true)}
                classNames={['pr-2']}
                startIcon={<Cancel fontSize="small" />}
              >
                ไม่อนุมัติ
              </CustomButton>
              <CustomButton
                variant="contained"
                color="success"
                onClick={() => setApproveOpen(true)}
                startIcon={<CheckCircle fontSize="small" />}
              >
                อนุมัติ
              </CustomButton>
            </FlexBox>
          )}
          {refundRequisitionProfile.status.id === RefundStatus.Approve && (
            <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
              <Button
                disabled={!writePermission.hasPermission}
                variant="contained"
                color="primary"
                onClick={() => setOpenUploadSlip(true)}
              >
                ยืนยันการโอนเงินคืน
              </Button>
            </ConditionTooltip>
          )}
        </Grid>
        <Grid item xs={12} container justify="space-between" alignItems="center">
          <CustomerProfileCard
            customerProfile={customerProfile}
            customerBankAccount={refundRequisitionProfile.customerBankAccount}
          />
        </Grid>
        <Grid item xs={12}>
          <RefundRequisitionProfilePayslipBox profile={refundRequisitionProfile} />
        </Grid>
        <Grid item xs={12}>
          <RefundRequisitionProfileInfoBox refundRequisitionProfile={refundRequisitionProfile} />
        </Grid>
        <Grid item xs={12}>
          <RefundRequisitionSaleOrderDocumentTable saleOrderDocuments={refundRequisitionProfile.saleOrderDocuments} />
        </Grid>
        <Grid item xs={12}>
          <RefundRequisitionSaleOrderPaymentTable saleOrderPayments={refundRequisitionProfile.saleOrderPayments} />
        </Grid>
        <Grid item xs={12}>
          <RefundRequisitionSaleOrderActivityTable saleOrderActivities={refundRequisitionProfile.activities} />
        </Grid>
      </Grid>
    </>
  )
}

export default withLayout(RefundRequisitionProfilePage)
