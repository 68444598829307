import React from 'react'

interface TabPanelProps {
  children: any
  value: string
  index: string
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index } = props;
    return (
      <div
        hidden={value !== index}
      >
        {value === index && children}
      </div>
    )
  }

export default TabPanel