import { SmartBankAccount } from '../types'

export const bankAccounts: SmartBankAccount[] = [
  // { id: 1, name: 'พงศ์เทพ วงศ์แหลมทอง', number: '079-292-1590', bankName: 'กสิกรไทย' },
  { id: 2, name: 'บริษัท สมาร์ท เบสท์บายส์ จำกัด', number: '604-100-6461', bankName: 'กสิกรไทย' },
  { id: 3, name: 'บริษัท สมาร์ท เบสท์บายส์ จำกัด', number: '425-084053-9', bankName: 'ไทยพาณิชย์' },
  { id: 4, name: 'บริษัท สมาร์ท เบสท์บายส์ จำกัด (ออมทรัพย์)', number: '604-216542-3', bankName: 'กสิกรไทย' },
  { id: 5, name: 'SMART BESTBUYS CO., LTD.', number: '6009126602164300', bankName: 'Batercard' },
  { id: 6, name: 'บริษัท สมาร์ท เบสท์บายส์ จํากัด', number: '661-9-94560-0', bankName: 'กรุงไทย' },
  { id: 7, name: 'บจ. สมาร์ท เบสท์บายส์', number: '865-7-51055-1', bankName: 'กรุงเทพ' },
  { id: 8, name: 'นาย พงศ์เทพ วงศ์แหลมทอง', number: '132-1-61393-0', bankName: 'กสิกรไทย' },
]
