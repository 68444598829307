import { Box, Paper } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import { IMessengerTaskMobileData } from '../../../api/smartbestbuys-api'
import { FilteredTable, FlexBox, Topic, withLayout } from '../../../custom-components'
import { PageName, TaskStatus, TaskType } from '../../../enums'
import { formatNumberInt } from '../../../functions/number-utils'
import { useMessengerTasks } from '../../../hooks'
import { Space, TypographyLink } from '../../../shared-components'

const PickupTaskListPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.MESSENGER_PICKUP_TASK)

  // state
  const [dueDate, setDueDate] = useState<Date>(DateTime.now().toJSDate())
  const [tabValue, setTabValue] = useState<TaskStatus>(TaskStatus.Pending)

  const [subTasks] = useMessengerTasks(dueDate)

  const pendingAmount = useMemo(
    () =>
      subTasks.filter((t) => t.status.id === TaskStatus.Pending && t.type.id === TaskType.MessengerPickupTask).length,
    // eslint-disable-next-line
    [subTasks.map((t) => t.status.id).join(',')],
  )

  return (
    <Box p={3}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Topic>รายการงานรับของ</Topic>
        <KeyboardDatePicker
          size="small"
          inputVariant="outlined"
          value={dueDate}
          defaultValue={null}
          onChange={(newDate) => {
            if (newDate) setDueDate(newDate)
          }}
        />
      </FlexBox>
      <Space />
      <Paper>
        <FilteredTable<IMessengerTaskMobileData>
          columns={[
            {
              name: 'เลขที่งานรับของ',
              dataIndex: 'code',
              sort: true,
              fixedWidth: 170,
              render: (task) => (
                <TypographyLink
                  variant="body2"
                  color="primary"
                  href={`/transport/pickup-tasks/${task.id}`}
                  target="_blank"
                >
                  {task.code}
                </TypographyLink>
              ),
            },
            {
              name: 'ใบสั่งขายอ้างอิง',
              fixedWidth: 170,
              render: (task) => {
                const { saleOrderInfo } = task
                if (!saleOrderInfo) return 'loading...'
                return (
                  <TypographyLink
                    variant="body2"
                    color="secondary.dark"
                    href={`/sale-orders/${saleOrderInfo.saleOrderId}/profile`}
                    target="_blank"
                  >
                    {saleOrderInfo.saleOrderCode}
                  </TypographyLink>
                )
              },
            },
            {
              name: 'คำร้องอ้างอิง',
              fixedWidth: 170,
              render: (task) => {
                const { customerRequisitionInfo } = task
                if (!customerRequisitionInfo) return 'loading...'
                return (
                  <TypographyLink
                    variant="body2"
                    color="secondary.dark"
                    href={`/customer-requisitions/${customerRequisitionInfo.id}/profile`}
                    target="_blank"
                  >
                    {customerRequisitionInfo.code}
                  </TypographyLink>
                )
              },
            },
            { name: 'ชื่อลูกค้า', render: (task) => task.saleOrderInfo?.customerName },
            { name: 'ชื่อผู้รับ', render: (task) => task.shippingInfo.consignee.name },
            { name: 'เบอร์โทรศัพท์', fixedWidth: 170, render: (task) => task.shippingInfo.consignee.phoneNumber },
          ]}
          data={subTasks.filter((t) => t.status.id === tabValue && t.type.id === TaskType.MessengerPickupTask)}
          sortable
          disableDateRangeFilter
          color="primary.dark"
          cleanSpace
          tabOption={{
            tabs: [
              {
                label: `งานส่งที่ต้องรับ (${formatNumberInt(pendingAmount)})`,
                value: TaskStatus.Pending,
              },
              { label: 'กำลังดำเนินการ', value: TaskStatus.Processing },
              { label: 'รับสำเร็จ', value: TaskStatus.Done },
              { label: 'รับไม่สำเร็จ', value: TaskStatus.Failed },
              { label: 'ถูกยกเลิก', value: TaskStatus.PreCancel },
              { label: 'งานที่ยกเลิก', value: TaskStatus.Canceled },
            ],
            value: tabValue,
            onChange: (_, newValue) => setTabValue(newValue),
          }}
        />
      </Paper>
    </Box>
  )
}

export default withLayout(PickupTaskListPage)
