import { useContext, useEffect, useState } from 'react'
import { Paper, Box, Switch, Collapse, Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { EmptyCard, FlexBox, GeneralTextFieldForm, Radio, Title } from '../../../custom-components'
import { CustomerAddressForm, Space } from '../../../shared-components'
import { useFormContext } from 'react-hook-form'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'
import { PurchaseOrderCreatePageContext } from './PurchaseOrderCreatePage'
import { ICustomerProfile_Address } from '../../../api/smartbestbuys-api/customer/types'
import { getAddressText } from '../../../functions/get-address-text'

export const VendorShippingInformationBox = () => {
  // context
  const { purchaseOrderCreate } = useContext(PurchaseOrderCreatePageContext)
  const { customerShippingAddresses } = purchaseOrderCreate

  // form hook
  const {
    control,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext<PurchaseOrderCreateFormValues>()
  const { customerShippingAddressId, sendCustomerShippingAddressById, shippingByVendor } = watch()

  // state
  const [openend, setOpened] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  // constant
  const shippingInputName = 'newShippingAddressInfo'

  // effect
  useEffect(() => {
    if (shippingByVendor) {
      register('newShippingAddressInfo.consigneeName', { required: 'กรุณากรอกชื่อ/ตำแหน่งผู้รับ' })
      register('newShippingAddressInfo.consigneePhoneNumber', { required: 'กรุณากรอกเบอร์โทรผู้รับ' })
      if (sendCustomerShippingAddressById) {
        register('customerShippingAddressId', { required: 'กรุณาเลือกที่อยู่ของลูกค้า' })
      } else {
        unregister('customerShippingAddressId')
      }
    } else {
      unregister('newShippingAddressInfo.consigneeName')
      unregister('newShippingAddressInfo.consigneePhoneNumber')
      unregister('customerShippingAddressId')
    }
  }, [shippingByVendor, sendCustomerShippingAddressById, clearErrors, register, unregister])

  return (
    <Box p={3} clone>
      <Paper>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Title>ผู้ขายเป็นผู้ส่งของให้ลูกค้า</Title>
          <Switch
            checked={openend}
            onChange={() => {
              setOpened((prev) => {
                const nextOpened = !prev
                setValue('shippingByVendor', nextOpened)
                return nextOpened
              })
            }}
          />
        </FlexBox>
        {shippingByVendor && (
          <Collapse in={openend}>
            <Space />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name={`${shippingInputName}.consigneeName`}
                  label="ชื่อ/ตำแหน่งผู้รับ *"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name={`${shippingInputName}.consigneePhoneNumber`}
                  label="เบอร์โทรผู้รับ *"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">เลือกที่อยู่ขนส่ง</Typography>
                <Space />
                <Tabs
                  style={{
                    marginBottom: 4,
                    boxShadow:
                      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
                  }}
                  variant="fullWidth"
                  textColor="primary"
                  value={tabValue}
                  onChange={(e, value) => {
                    setValue('sendCustomerShippingAddressById', value === 0)
                    setTabValue(value)
                  }}
                  indicatorColor="primary"
                  aria-label="tabs table"
                >
                  <Tab label="ที่อยู่ขนส่งที่มีในระบบ" value={0} />
                  <Tab label="ที่อยู่ขนส่งใหม่" value={1} />
                </Tabs>
                {
                  [
                    <ShippingAddressRadios
                      customerAddresses={customerShippingAddresses ?? []}
                      value={customerShippingAddressId}
                      onChange={(addressId) => setValue('customerShippingAddressId', addressId)}
                      errorMessage={errors.customerShippingAddressId?.message}
                    />,
                    <CustomerAddressForm name={shippingInputName} />,
                  ][tabValue]
                }
              </Grid>
            </Grid>
          </Collapse>
        )}
      </Paper>
    </Box>
  )
}

export default VendorShippingInformationBox

interface Props {
  customerAddresses: ICustomerProfile_Address[]
  value?: number
  onChange?: (value: number) => void
  errorMessage?: string
}

const ShippingAddressRadios = (props: Props) => {
  const { customerAddresses, value, onChange, errorMessage } = props
  return (
    <Box p={2} borderColor={errorMessage ? 'error.main' : undefined} clone>
      <Paper variant="outlined">
        <Grid container spacing={2}>
          {customerAddresses.length > 0 ? (
            customerAddresses.map((address) => (
              <Grid item xs={12} key={getAddressText(address)}>
                <Radio
                  label={getAddressText(address)}
                  value={address.id}
                  checked={value === address.id}
                  outlined
                  onChange={(e) => {
                    if (!!onChange) onChange(Number(e.target.value))
                  }}
                  color="primary"
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} key="empty card">
              <EmptyCard>ลูกค้ายังไม่มีที่อยู่จัดส่ง</EmptyCard>
            </Grid>
          )}
        </Grid>
        <Space />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Paper>
    </Box>
  )
}
