import { createContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PurchaseOrderCreateData } from '../../../api/smartbestbuys-api'
import { withLayout } from '../../../custom-components'
import { PurchaseOrderCreateState } from '../../../enums'
import { usePurchaseOrderCreateData } from '../../../hooks'
import { Loading } from '../../../shared-components'

import PurchaseOrderCreateForm from './PurchaseOrderCreateForm'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'
import PurchaseOrderVerification from './PurchaseOrderVerification'

interface IPurchaseOrderCreatePageContext {
  purchaseOrderCreate: PurchaseOrderCreateData
}

export const PurchaseOrderCreatePageContext = createContext<IPurchaseOrderCreatePageContext>({
  purchaseOrderCreate: {} as PurchaseOrderCreateData,
})

interface Props {
  id: number
}

const PurchaseOrderCreatePage: React.FC<Props> = (props: Props) => {
  // props
  const { id } = props
  const [state, setState] = useState(PurchaseOrderCreateState.Form)

  // from
  const formMethod = useForm<PurchaseOrderCreateFormValues>({
    defaultValues: {
      products: [],
      sendCustomerShippingAddressById: true,
      shippingByVendor: false,
      vatIncluded: true,
    },
  })

  const { setValue } = formMethod

  // data
  const createPurchaseOrderData = usePurchaseOrderCreateData(id)

  useEffect(() => {
    if (createPurchaseOrderData?.purchaseRequisitionInfo.dueDate) {
      setValue('vendorShippingDate', new Date(createPurchaseOrderData.purchaseRequisitionInfo.dueDate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPurchaseOrderData?.purchaseRequisitionInfo])

  if (!createPurchaseOrderData) return <Loading />

  return (
    <PurchaseOrderCreatePageContext.Provider
      value={{
        purchaseOrderCreate: createPurchaseOrderData,
      }}
    >
      <FormProvider {...formMethod}>
        {state === PurchaseOrderCreateState.Form ? (
          <PurchaseOrderCreateForm onNextPage={() => setState(PurchaseOrderCreateState.DataVerification)} />
        ) : (
          <PurchaseOrderVerification
            onPreviousPage={() => setState(PurchaseOrderCreateState.Form)}
            purchaseRequisitionId={id}
          />
        )}
      </FormProvider>
    </PurchaseOrderCreatePageContext.Provider>
  )
}

export default withLayout(PurchaseOrderCreatePage)
