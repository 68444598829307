import { Box } from '@material-ui/core'
import { Topic, withLayout } from '../../../custom-components'
import { PWTicketForm, PWTicketFormValues } from './forms'
import {
  CreateProductWithdrawalTicketRequestBody,
  createProductWithdrawalTicketAPI,
} from '../../../api/smartbestbuys-api/warehouse'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { PageName } from '../../../enums'
import { useHistory } from 'react-router-dom'

const PWTicketCreatePage: React.FC = () => {
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  const history = useHistory()
  setCurrentPage(PageName.PRODUCT_WITHDRAWAL_TABLE_DATA)

  const handleSubmit = async (values: PWTicketFormValues) => {
    const request: CreateProductWithdrawalTicketRequestBody = {
      actionMessageId: values.actionMessageId,
      requestedDate: values.requestedDate,
      estimatedReturnedDate: values.estimatedReturnedDate,
      remark: values.remark,
      products: values.products
        .filter((product) => !!product.requestToWithdrawnAmount && product.requestToWithdrawnAmount > 0)
        .map((product) => ({
          productId: product.id,
          amount: product.requestToWithdrawnAmount!, // risk to broke
        })),
    }

    const response = await createProductWithdrawalTicketAPI(request)

    if (response?.status === 201) {
      history.replace(`/warehouse/product-withdrawal-tickets/${response.data.id}/profile`)
    } else {
      triggerSnackbar('สร้างคำขอเบิกสินค้าไม่สำเร็จ')
    }
  }

  return (
    <Box>
      <Topic style={{ marginBottom: 8 }}>สร้างคำขอเบิกสินค้า</Topic>
      <PWTicketForm onSubmit={handleSubmit} />
    </Box>
  )
}

export default withLayout(PWTicketCreatePage)
