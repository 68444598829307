import { Box, Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import { Cancel, CheckCircle, Photo } from '@material-ui/icons'
import numeral from 'numeral'
import React, { useContext, useState } from 'react'
import { QuotationProfile_Product } from '../../../api/smartbestbuys-api/quotation/quotation-profile-types'
import { Avatar, CustomButton, EmptyCard, FlexBox, SubTopic, Table } from '../../../custom-components'
import { BasePriceBreakdownsDomain } from '../../../data'
import { calculatePriceBreakdowns } from '../../../sales-system/utils/sale-order-price-calculator'
import { Space } from '../../../shared-components'
import { QuotationsProfilePageContextImpl } from './QuotationsProfilePage'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { QuotationStatus } from '../../../enums'
import QuotationApproveDataDialog from './QuotationApproveDataDialog'
import QuotationRequestChangeDialog from './QuotationRequestChangeDialog'
import { calculatePercent } from '../../../functions/number-utils'

interface QuotationProfileSelectedProductProps {
  toggleReload: () => void
}

const QuotationProfileSelectedProduct: React.FC<QuotationProfileSelectedProductProps> = (
  props: QuotationProfileSelectedProductProps,
) => {
  const { profile } = useContext(QuotationsProfilePageContextImpl)
  const { toggleReload } = props
  const [approveReviewDialog, setApproveReviewDialog] = useState(false)
  const [requestChangedReviewDialog, setRequestChangedReviewDialog] = useState(false)

  const priceBreakdownsDomain: BasePriceBreakdownsDomain = {
    selectedProducts: profile.products,
    shippingFee: profile.estimatedShippingFee,
    shippingFeeEnabled: profile.estimatedShippingFee !== undefined,
    isShippingFeeIncludingVat: profile.isShippingFeeIncludingVat,
    vatIncluded: profile.vatIncluded,
    discount: profile.discountAmount,
    discountEnabled: profile.estimatedShippingFee !== undefined,
  }

  const priceBreakdowns = getPricebreakdown(priceBreakdownsDomain)

  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_Quotation_ReviewData)
  const saleProfitRead = getUserPermissionInfo(PermissionEnum.Sales_Profit_Read)
  const isPendingReviewStatus = profile.status.id === QuotationStatus.PendingReview
  const ableToPendingReview = isPendingReviewStatus && writePermission.hasPermission

  return (
    <>
      <QuotationApproveDataDialog
        quotationId={profile.id}
        quotationCode={profile.code}
        open={approveReviewDialog}
        onSuccess={() => {
          setApproveReviewDialog(false)
          toggleReload()
        }}
        onClose={() => {
          setApproveReviewDialog(false)
        }}
      />
      <QuotationRequestChangeDialog
        quotationId={profile.id}
        quotationCode={profile.code}
        open={requestChangedReviewDialog}
        onSuccess={() => {
          setRequestChangedReviewDialog(false)
          toggleReload()
        }}
        onClose={() => {
          setRequestChangedReviewDialog(false)
        }}
      />
      <Box p={3} clone>
        <Paper>
          <SubTopic gutterBottom>ข้อมูลสินค้า/บริการ</SubTopic>
          <Space />
          {profile.products.length === 0 ? (
            <EmptyCard emptyText="ไม่มีสินค้า/บริการ" />
          ) : (
            <>
              <Table<QuotationProfile_Product>
                color="primary.dark"
                size="small"
                columns={[
                  {
                    name: 'ลำดับ',
                    render: (_, index) => index + 1,
                  },
                  {
                    name: 'ชื่อสินค้า',
                    render: (product) => (
                      <div className="sale-order-products-table__product-cell" key={'product-' + product.id}>
                        <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                          <Photo />
                        </Avatar>
                        <div className="sale-order-products-table__product-cell__info">
                          <Typography color="textSecondary">{product.code}</Typography>
                          <Typography>{product.nameLocal}</Typography>
                        </div>
                      </div>
                    ),
                  },
                  {
                    name: 'ราคาต่อหน่วย',
                    numeric: true,
                    format: '0,0.00',
                    dataIndex: 'price',
                  },
                  {
                    name: 'จำนวนที่เสนอ',
                    numeric: true,
                    format: '0,0',
                    dataIndex: 'amount',
                  },
                  {
                    name: 'ราคารวม',
                    numeric: true,
                    format: '0,0.00',
                    render: (product) => product.amount * product.price,
                  },
                  {
                    name: 'กำไร/ขาดทุน',
                    numeric: true,
                    hide: !saleProfitRead.hasPermission,
                    render: (product) => {
                      const _cost = product.cost ? product.cost : product.lastBuyPrice
                      if (!_cost) return <span className="text-gray-500">คำนวณไม่ได้</span>

                      const _totalSell = product.amount * product.price
                      const _totalCost = product.amount * _cost
                      const _pnl = _totalSell - _totalCost
                      if (_pnl > 0) {
                        return (
                          <Box color="success.main" clone>
                            <Typography variant="body2">+{calculatePercent(_totalSell, _totalCost)}</Typography>
                          </Box>
                        )
                      } else {
                        return (
                          <Typography variant="body2" color="error">
                            {calculatePercent(_totalSell, _totalCost)}
                          </Typography>
                        )
                      }
                    },
                  },
                ]}
                data={profile.products}
                postCells={
                  <>
                    {priceBreakdowns
                      .filter((price) => {
                        return price.amount > 0 || !['ส่วนลด', 'ค่าขนส่ง'].includes(price.name)
                      })
                      .map((price) => (
                        <TableRow key={price.name}>
                          <TableCell colSpan={3} />
                          <TableCell align="right">{price.name}</TableCell>
                          <TableCell align="right">{numeral(price.amount).format('0,0.00')}</TableCell>
                        </TableRow>
                      ))}
                  </>
                }
                pagination={false}
                cleanSpace
              />
              {ableToPendingReview && (
                <FlexBox gridGap={8} alignItems="center" justifyContent="flex-end">
                  {!profile.requestChanged && (
                    <CustomButton
                      color="error"
                      variant="contained"
                      size="small"
                      startIcon={<Cancel />}
                      onClick={() => setRequestChangedReviewDialog(true)}
                    >
                      แจ้งให้กลับไปแก้ไข
                    </CustomButton>
                  )}
                  <CustomButton
                    variant="contained"
                    size="small"
                    color="success"
                    startIcon={<CheckCircle />}
                    onClick={() => setApproveReviewDialog(true)}
                  >
                    อนุมัติใบเสนอราคา
                  </CustomButton>
                </FlexBox>
              )}
            </>
          )}
        </Paper>
      </Box>
    </>
  )
}

interface PriceDetail {
  name: string
  amount: number
}

const getPricebreakdown = (values: BasePriceBreakdownsDomain): PriceDetail[] => {
  const priceBreakdowns = calculatePriceBreakdowns(values)

  const { productTotal, discount, shippingFee, vatAmount, total } = priceBreakdowns

  return [
    { name: 'รวมเงิน', amount: productTotal },
    { name: 'ส่วนลด', amount: discount },
    { name: 'ค่าขนส่ง', amount: shippingFee },
    { name: 'ยอดหลังหักส่วนลด', amount: productTotal - discount },
    { name: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
    { name: 'ยอดเงินสุทธิ', amount: total },
  ]
}

export default QuotationProfileSelectedProduct
