import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Form, Topic } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'

import PurchaseProductSelector from './PurchaseProductSelector/PurchaseProductSelector'
import { VendorBox } from './VendorBox'
import { PurchaseProductInfo } from './PurchaseProductInfo'
import SelectedPurchaseProductTable from './SelectedPurchaseProductTable'
import { useFormContext } from 'react-hook-form'
import PurchaseRequisitionCreateFormValues from './PurchaseRequisitionCreateFormValue'
import { AttachmentSelectionBox } from './AttachmentSelectionBox'
import { PurchaseRequisitionType } from '../../../enums'
import { SourceFile } from '../../../types'
import { PurchaseRequisitionCreateData } from '../../../api/smartbestbuys-api'
import PRSaleOrderProductsTable from './PRSaleOrderProductsTable'

interface Props {
  type: PurchaseRequisitionType
  onNextPage: () => void
  createPurchaseRequisitionData: PurchaseRequisitionCreateData
  rop?: boolean
}

const PurchaseRequisitionCreateForm: React.FC<Props> = (props: Props) => {
  const { onNextPage, createPurchaseRequisitionData, rop } = props

  const existCodeLists = createPurchaseRequisitionData?.products
    .filter((product) => !!product.code)
    .map((product) => product.code!)
  const { saleOrderInfo, soProducts } = createPurchaseRequisitionData
  const { watch, setValue, setError, clearErrors } = useFormContext<PurchaseRequisitionCreateFormValues>()

  const { topicId, currencyId, attachments, products } = watch()

  const handleAddAttachmentsToForm = (attachments: SourceFile[]) => {
    setValue('attachments', attachments)
  }

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  useEffect(() => {
    if (products.length > 0) {
      clearErrors('products')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.length])

  return (
    <Form
      onSubmit={() => {
        let error = false
        if (!topicId) {
          error = true
          scrollTo('#purchase-requisition-info-box')
          return
        }

        if (products.length === 0) {
          setError('products', {
            type: 'required',
            message: 'กรุณาเลือกสินค้า',
          })
          error = true
          if (!!topicId) scrollTo('#selected-purchase-product-table')
          return
        }
        if (!error) {
          onNextPage()
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>สร้างคำขอซื้อสำหรับซื้อสินค้า/บริการ</Topic>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8} id="purchase-requisition-info-box">
              <PurchaseProductInfo
                rop={rop}
                purchaseRequisitionTopics={createPurchaseRequisitionData.purchaseRequisitionTopics}
                currencies={createPurchaseRequisitionData.currencies}
                isPRRefFromSaleOrder={!!saleOrderInfo && !!soProducts}
              />
            </Grid>
            <Grid item xs={4}>
              <VendorBox vendors={createPurchaseRequisitionData.vendors} />
            </Grid>
            {!!saleOrderInfo && !!soProducts && (
              <Grid item xs={12}>
                <PRSaleOrderProductsTable saleOrderCode={saleOrderInfo.code} products={soProducts} />
              </Grid>
            )}
            <Grid item xs={12}>
              <PurchaseProductSelector
                productData={createPurchaseRequisitionData.products}
                saleOrderProductsData={createPurchaseRequisitionData.soProducts}
                rop={rop}
              />
            </Grid>
            <Grid item xs={12} id="selected-purchase-product-table">
              <SelectedPurchaseProductTable
                existingProductCodeList={existCodeLists}
                currency={createPurchaseRequisitionData.currencies.find((currency) => currency.id === currencyId)?.name}
                rop={rop}
              />
            </Grid>
            <Grid item xs={12}>
              <AttachmentSelectionBox onChange={handleAddAttachmentsToForm} defaultValue={attachments} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SubmitForm goBack submitText="ตรวจทานการสร้างคำขอซื้อ" cancelText="ยกเลิก" />
        </Grid>
      </Grid>
    </Form>
  )
}

export default PurchaseRequisitionCreateForm
