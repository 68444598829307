import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Paper, Typography, Box, Switch, BoxProps, InputAdornment, FormControlLabel, Collapse } from '@material-ui/core'
import numeral from 'numeral'
import { calculateExcludingVat } from '../../../../functions'
import { CheckboxForm, TextFieldForm, SubTopic } from '../../../../custom-components'
import { QuotationsCreateValue } from '../QuotationsCreateValue'

const QuotationsShippingFeeBox: React.FC = () => {
  const { control, getValues, setValue } = useFormContext<QuotationsCreateValue>()
  const { shippingFeeEnabled, shippingFee, isShippingFeeIncludingVat } = getValues()

  const renderLabel = () => {
    if (isShippingFeeIncludingVat) {
      const shippingFeeExcludingVat = calculateExcludingVat(shippingFee || 0)
      return (
        <Typography variant="body1">
          รวม VAT (ค่าจัดส่งก่อน VAT:&nbsp;
          <Box component="span" color="primary.main">
            {numeral(shippingFeeExcludingVat).format('0,0.00')}
          </Box>
          &nbsp; บาท)
        </Typography>
      )
    }

    return 'รวม VAT'
  }

  return (
    <Box display="flex" flexDirection="column" padding={2}>
      {(props: BoxProps) => (
        <Paper className={props.className}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <SubTopic>ค่าจัดส่ง</SubTopic>
            <Switch
              checked={!!shippingFeeEnabled}
              onClick={() => setValue('shippingFeeEnabled', !shippingFeeEnabled)}
            />
          </Box>
          <Collapse in={!!shippingFeeEnabled}>
            <Box mb={2}>
              <TextFieldForm
                control={control}
                name="shippingFee"
                label="ค่าจัดส่ง"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                }}
                type="number"
              />
            </Box>
            <Box height={30}>
              <FormControlLabel
                control={
                  <CheckboxForm
                    control={control}
                    checked={isShippingFeeIncludingVat}
                    name="isShippingFeeIncludingVat"
                    size="small"
                  />
                }
                label={renderLabel()}
              />
            </Box>
          </Collapse>
        </Paper>
      )}
    </Box>
  )
}

export default QuotationsShippingFeeBox
