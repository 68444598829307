import { useState, useEffect } from 'react'
import { getProductPrices, ProductPrice } from '../api/smartbestbuys-api'

export const useProductsPricing = (id: number) => {
  const [products, setProducts] = useState<ProductPrice[]>([])

  const _fetchProductsPricing = async (id: number) => {
    const response = await getProductPrices(id)
    setProducts(response?.data || [])
  }

  useEffect(() => {
    _fetchProductsPricing(id)
  }, [id])

  return products
}
