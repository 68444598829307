import React, { useState, createContext, useContext, useEffect } from 'react'
import { Form, withLayout } from '../../../custom-components'
import { Loading } from '../../../shared-components'
import { PageName, DocumentType, PaymentCondition } from '../../../enums'
import { RootContext } from '../../..'
import {
  TransportTaskUpdateData,
  TransportTaskUpdateRequestBody,
  updateTransportTaskAPI,
  TransportTaskStateV2_Item,
} from '../../../api/smartbestbuys-api'
import { FormProvider, useForm } from 'react-hook-form'
import TransportTaskUpdateFormValues from './TransportTaskUpdateFormValues'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'
import { useAddressData, useTransportTaskUpdateData } from '../../../hooks'
import TransportTaskUpdateForm from './TransportTaskUpdateForm'
import { ShippingMethod, ShippingPaymentCondition } from '../../../types'
import { dateSqlFormat } from '../../../functions'
import { useHistory } from 'react-router-dom'

interface ITransportTaskUpdatePageContext {
  transportTaskUpdateData: TransportTaskUpdateData
}

export const TransportTaskUpdatePageContext = createContext<ITransportTaskUpdatePageContext>({
  transportTaskUpdateData: {} as TransportTaskUpdateData,
})

interface Props {
  taskId: number
}

const TransportTaskUpdatePage: React.FC<Props> = (props) => {
  // set up props & state & context
  const { taskId } = props

  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const [loading, setLoading] = useState(true)

  setCurrentPage(PageName.TRANSPORT)

  const history = useHistory()

  const formMethod = useForm<TransportTaskUpdateFormValues>({
    defaultValues: {
      selectedProducts: [],
      selectedDocuments: [],
      selectedOtherDocuments: [],
      requestedDocuments: [],
      customerShippingAddress: {},
    },
  })

  const { reset, register, setError, watch } = formMethod

  const addressData = useAddressData()

  // call hooks
  const updateData = useTransportTaskUpdateData(taskId)

  useEffect(() => {
    if (!updateData) return
    register('selectedProducts')
    register('selectedDocuments')
    register('selectedOtherDocuments')
    register('requestedDocuments')
    const { documents, othersDocuments, requestedDocuments } = updateData.documentsWithShippingAmount
    const initValues = {
      customerId: updateData.customerId,
      saleOrderId: updateData.saleOrderInfo.id,
      selectedProducts: updateData.productsWithShippingAmount,
      selectedDocuments: documents,
      selectedOtherDocuments: othersDocuments,
      requestedDocuments: [
        {
          typeId: DocumentType.Invoice,
          name: 'ใบแจ้งหนี้',
          requestChange: requestedDocuments.some((d) => d.typeId === DocumentType.Invoice),
        },
        {
          typeId: DocumentType.TaxInvoice,
          name: 'ใบกำกับภาษี',
          requestChange: requestedDocuments.some((d) => d.typeId === DocumentType.TaxInvoice),
        },
        {
          typeId: DocumentType.BillingNote,
          name: 'ใบวางบิล',
          requestChange: requestedDocuments.some((d) => d.typeId === DocumentType.BillingNote),
        },
        {
          typeId: DocumentType.Receipts,
          name: 'ใบเสร็จรับเงิน',
          requestChange: requestedDocuments.some((d) => d.typeId === DocumentType.Receipts),
        },
      ],
      shippingMethod: updateData.shippingMethod,

      shippingProviderId: updateData.shippingProvider?.id,
      shippingProviderName: updateData.shippingProvider?.name,
      shippingProviderDetail: updateData.shippingProvider?.detail,

      dueDate: updateData.dueDate,
      messengerId: updateData.shippingInfo.messengerInfo?.userId,
      messenger: updateData.shippingInfo.messengerInfo
        ? {
            id: updateData.shippingInfo.messengerInfo.userId,
            name: updateData.shippingInfo.messengerInfo.name,
          }
        : undefined,

      car: updateData.shippingInfo.messengerInfo?.car,
      chargeAmount: updateData.shippingInfo.chargeAmount,
      remark: updateData.shippingInfo.remark,

      consignee: updateData.consignee.name,
      phoneNumber: updateData.consignee.phoneNumber,

      customerShippingAddress: {
        id: updateData.shippingInfo.shippingAddress?.id,
      },
      sendCustomerShippingAddressById: true,
      assigneeUser: updateData.assignee
        ? {
            id: updateData.assignee?.userId,
            name: updateData.assignee.firstName + ' ' + updateData.assignee.lastName,
          }
        : undefined,

      assigneeUserId: updateData.assignee?.userId,

      shippingPaymentCondition: updateData.shippingInfo.useShippingProvider
        ? updateData.shippingInfo.payShippingFeeBySmart
          ? ShippingPaymentCondition.Smart
          : ShippingPaymentCondition.Customer
        : undefined,
      skipChargeAmount:
        updateData.saleOrderInfo.paymentCondition.id === PaymentCondition.PayAtDestination &&
        !updateData.shippingInfo.chargeAmount,
    }
    reset(initValues)
    setLoading(false)
    // eslint-disable-next-line
  }, [!!updateData])

  if (!updateData || loading) return <Loading />

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const handleSubmit = async (values: TransportTaskUpdateFormValues) => {
    if (!validateValues(values)) return
    const productItems: TransportTaskStateV2_Item[] = values.selectedProducts
      .filter((p) => !!p.amount && p.amount > 0)
      .map((p) => ({
        type: TransportTaskItemType.Product,
        id: p.id,
        code: p.code,
        name: p.nameLocal,
        amount: p.amount!,
        imageUrl: p.imageUrl,
      }))

    const documentItems: TransportTaskStateV2_Item[] = values.selectedDocuments
      .filter((d) => !!d.amount && d.amount > 0)
      .map((d) => ({
        type: TransportTaskItemType.Document,
        id: d.id,
        code: d.code,
        name: d.type.name,
        amount: d.amount!,
      }))

    const otherDocumentItems: TransportTaskStateV2_Item[] = values.selectedOtherDocuments
      .filter((d) => d.selected && !!d.amount && d.amount > 0)
      .map((d) => ({
        type: TransportTaskItemType.Document,
        id: d.id,
        name: d.name,
        amount: d.amount!,
      }))

    const requestDocuments: TransportTaskStateV2_Item[] = values.requestedDocuments
      .filter((d) => d.requestChange)
      .map((d) => ({
        type: TransportTaskItemType.Document,
        id: -d.typeId,
        name: d.name,
        amount: 1,
        requestChange: true,
      }))

    const customerShippingAddress = updateData.customerShippingAddresses.find(
      (a) => a.id === values.customerShippingAddress.id,
    )

    const { total, paidAmount, paymentCondition } = { ...updateData.saleOrderInfo }

    const shippingBySmart = values.shippingMethod === ShippingMethod.Smart
    const payAtDestination = paymentCondition?.id === PaymentCondition.PayAtDestination

    const request: TransportTaskUpdateRequestBody = {
      customerId: updateData.saleOrderInfo.customerId,
      saleOrderId: updateData.saleOrderInfo.id,
      items: [...productItems, ...requestDocuments, ...documentItems, ...otherDocumentItems],
      useShippingProvider: values.shippingMethod !== ShippingMethod.Smart,
      shippingProvider:
        values.shippingMethod === ShippingMethod.Express && !!values.shippingProviderName
          ? {
              id: values.shippingProviderId,
              name: values.shippingProviderName,
              detail: values.shippingProviderDetail,
            }
          : undefined,
      dueDate: values.dueDate ? dateSqlFormat(values.dueDate, 'yyyy-MM-dd') : undefined,
      messenger: values.messenger,
      car: values.car,
      chargeAmount: values.skipChargeAmount
        ? 0
        : shippingBySmart
        ? payAtDestination
          ? total - paidAmount
          : values.chargeAmount
        : undefined,
      remark: values.remark,

      consignee: values.consignee,
      phoneNumber: values.phoneNumber,

      customerShippingAddress: {
        id: values.customerShippingAddress.id,
        name: customerShippingAddress?.name ?? values.customerShippingAddress.name,
        branchName: customerShippingAddress?.branchName ?? values.customerShippingAddress.branchName,
        addressLine1: customerShippingAddress?.addressLine1 ?? values.customerShippingAddress.addressLine1,
        phoneNumber: customerShippingAddress?.phoneNumber ?? values.customerShippingAddress.phoneNumber,
        faxNumber: customerShippingAddress?.faxNumber ?? values.customerShippingAddress.faxNumber,

        province:
          customerShippingAddress?.province ??
          addressData?.provinces.find((d) => d.id === values.customerShippingAddress.provinceId),
        district:
          customerShippingAddress?.district ??
          addressData?.districts.find((d) => d.id === values.customerShippingAddress.districtId),
        subDistrict:
          customerShippingAddress?.subDistrict ??
          addressData?.subDistricts.find((d) => d.id === values.customerShippingAddress.subDistrictId),

        postalCode: customerShippingAddress?.postalCode ?? values.customerShippingAddress.postalCode,
        googleMapLink: customerShippingAddress?.googleMapLink ?? values.customerShippingAddress.googleMapLink,
      },
      assigneeUserId: values.assigneeUserId,

      payShippingFeeBySmart: shippingBySmart
        ? undefined
        : values.shippingPaymentCondition === ShippingPaymentCondition.Smart,
    }

    const response = await updateTransportTaskAPI(updateData.profile.id, request)
    if (response?.status === 200) {
      triggerSnackbar('แก้ไขงานขนส่งสำเร็จ')
      history.push(`/transport-tasks/${response.data.id}/profile`)
    } else {
      triggerSnackbar('แก้ไขงานขนส่งไม่สำเร็จ')
    }
    return true
  }

  const handleInvalid = (errors: TransportTaskUpdateFormValues) => {
    validateValues(watch())
  }

  const validateValues = (values: TransportTaskUpdateFormValues): boolean => {
    let valid = true
    // validate products or documents
    const hasSelectSomeProduct = values.selectedProducts.some((p) => !!p.amount && p.amount > 0)
    if (!hasSelectSomeProduct) {
      const hasSelectSomeDocument = values.selectedDocuments.some((d) => !!d.amount && d.amount > 0)
      const hasSelectSomeOtherDocument = values.selectedOtherDocuments.some((d) => !!d.amount && d.amount > 0)
      const hasRequestedDocument = values.requestedDocuments.some((d) => d.requestChange)
      const hasDocument = !hasSelectSomeDocument && !hasSelectSomeOtherDocument && !hasRequestedDocument
      if (hasDocument) {
        setError('selectedProducts', {
          type: 'required',
          message: 'กรุณาเลือกสินค้า หรือ เอกสารที่จะส่ง',
        })
        scrollTo('#shipping-product-selector-box')
        valid = false
      }
    }

    if (!values.shippingMethod) {
      setError('shippingMethod', {
        type: 'required',
        message: 'กรุณาเลือกวิธีการส่งสินค้า / เอกสาร',
      })
      scrollTo('#shipping-method-selector-box')
      valid = false
    }

    return valid
  }

  // render
  return (
    <TransportTaskUpdatePageContext.Provider
      value={{
        transportTaskUpdateData: updateData,
      }}
    >
      <FormProvider {...formMethod}>
        <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
          <TransportTaskUpdateForm />
          <div className="mb-12" />
        </Form>
      </FormProvider>
    </TransportTaskUpdatePageContext.Provider>
  )
}

export default withLayout(TransportTaskUpdatePage)
