import { Box, Paper, Grid, Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useState } from 'react'
import { VendorBankAccount } from '../../../../api/smartbestbuys-api'
import { Title, FlexBox, TypographyWithLabel, EmptyCard } from '../../../../custom-components'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { ConditionTooltip, Space } from '../../../../shared-components'
import { getUserPermissionInfo } from '../../../../utils/permission'
import VendorBankAccountCreateFormDialog from './VendorBankAccountCreateFormDialog'

interface Props {
  vendorId: number
  bankAccounts?: VendorBankAccount[]
  onUpdate?: () => void
}

const VendorBankAccountBox = (props: Props) => {
  const { vendorId, bankAccounts, onUpdate } = props

  // state
  const [dialogOpen, setDialogOpen] = useState(false)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_Vendor_Write)

  return (
    <>
      <VendorBankAccountCreateFormDialog
        open={dialogOpen}
        vendorId={vendorId}
        onClose={() => setDialogOpen(false)}
        onComplete={() => {
          if (!!onUpdate) onUpdate()
          setDialogOpen(false)
        }}
      />
      <Box p={3}>
        <FlexBox justifyContent="space-between">
          <Title>ข้อมูลบัญชีธนาคาร</Title>
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<Add />}
              onClick={() => setDialogOpen(true)}
              disabled={!writePermission.hasPermission}
            >
              เพิ่มบัญชีธนาคาร
            </Button>
          </ConditionTooltip>
        </FlexBox>
        <Space />
        <Grid container spacing={2}>
          {!!bankAccounts && bankAccounts.length > 0 ? (
            bankAccounts.map((ba) => {
              return (
                <Grid item xs={12}>
                  <Box p={3} clone>
                    <Paper variant="outlined" key={ba.id}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="ชื่อบัญชี:">{ba.accountName}</TypographyWithLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="เลขบัญชี:">{ba.accountNumber}</TypographyWithLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="ธนาคาร:">{ba.bank.name}</TypographyWithLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="สาขา:">{ba.branchName}</TypographyWithLabel>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12}>
              <EmptyCard>ไม่มีข้อมูลบัญชีธนาคาร</EmptyCard>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default VendorBankAccountBox
