import React, { useState, createContext, useContext, useEffect } from 'react'
import { Form, withLayout } from '../../../custom-components'
import { Loading } from '../../../shared-components'
import { PageName, DocumentType, PaymentCondition } from '../../../enums'
import { RootContext } from '../../..'
import {
  createTransportTaskAPI,
  TransportTaskCreateDataV2,
  TransportTaskCreateRequestBody,
  TransportTaskStateV2_Item,
} from '../../../api/smartbestbuys-api'
import { FormProvider, useForm } from 'react-hook-form'
import TransportTaskCreateFormValues from './TransportTaskCreateFormValues'
import { TransportTaskCreateState, TransportTaskItemType } from '../../../enums/TransportTaskEnums'
import TransportTaskCreateForm from './TransportTaskCreateForm'
import TransportVerificationForm from './TransportVerificationForm'
import { ShippingMethod, ShippingPaymentCondition } from '../../../types'
import { dateSqlFormat } from '../../../functions'
import { useHistory } from 'react-router-dom'
import { useAddressData } from '../../../hooks'
import { useTransportTaskCreateDataV2 } from '../../../hooks/transport/useTransportTaskCreateDataV2'

interface ITransportTaskCreatePageContext {
  transportTaskCreateData: TransportTaskCreateDataV2
}

export const TransportTaskCreatePageContext = createContext<ITransportTaskCreatePageContext>({
  transportTaskCreateData: {} as TransportTaskCreateDataV2,
})

interface Props {
  saleOrderId: number
  query: QueryParams
}

interface QueryParams {
  customerRequisitionId?: string
}

const TransportTaskCreatePage: React.FC<Props> = (props) => {
  // set up props & state & context
  const { saleOrderId, query } = props
  const { customerRequisitionId } = query

  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  setCurrentPage(PageName.TRANSPORT)

  const [state, setState] = useState<TransportTaskCreateState>(TransportTaskCreateState.Form)
  const [loading, setLoading] = useState(true)

  const history = useHistory()

  const addressData = useAddressData()

  const formMethod = useForm<TransportTaskCreateFormValues>({
    defaultValues: {
      selectedProducts: [],
      selectedDocuments: [],
      selectedOtherDocuments: [],
      requestedDocuments: [],
      customerShippingAddress: {},
    },
  })

  const { reset, register, setError, watch } = formMethod

  // call hooks
  const createData = useTransportTaskCreateDataV2(customerRequisitionId, saleOrderId.toString())

  useEffect(() => {
    register('selectedProducts')
    register('selectedDocuments')
    register('selectedOtherDocuments')
    register('requestedDocuments')

    const customerRequisitionProducts = createData?.customerRequisitionInfo?.products.map((p) => ({
      id: p.id,
      imageUrl: p.imageUrl,
      code: p.code,
      nameLocal: p.nameLocal,
      nameEn: p.nameEn,
      salesAmount: p.receivedAmount,
      shippedAmount: p.shippedAmount,
      reservedAmount: p.shippingAmount,
    }))

    reset({
      selectedProducts:
        (!!customerRequisitionId ? customerRequisitionProducts : createData?.saleOrderInfo?.products) ?? [],
      selectedDocuments: createData?.documents ?? [],
      selectedOtherDocuments: [],
      requestedDocuments: [
        { typeId: DocumentType.Invoice, name: 'ใบแจ้งหนี้', requestChange: false },
        { typeId: DocumentType.TaxInvoice, name: 'ใบกำกับภาษี', requestChange: false },
        { typeId: DocumentType.BillingNote, name: 'ใบวางบิล', requestChange: false },
        { typeId: DocumentType.Receipts, name: 'ใบเสร็จรับเงิน', requestChange: false },
      ],
      sendCustomerShippingAddressById: true,
      dueDate: null,
    })
    setLoading(false)
    // eslint-disable-next-line
  }, [!!createData])

  if (!createData || !addressData || loading) return <Loading />

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const handleSubmit = (values: TransportTaskCreateFormValues) => {
    if (!validateValues(values)) return

    setState(TransportTaskCreateState.DataVerification)
  }

  const handleInvalid = (errors: TransportTaskCreateFormValues) => {
    validateValues(watch(), false)
  }

  const handleConfirmValues = async (values: TransportTaskCreateFormValues) => {
    const productItems: TransportTaskStateV2_Item[] = values.selectedProducts
      .filter((p) => !!p.amount && p.amount > 0)
      .map((p) => ({
        type: TransportTaskItemType.Product,
        id: p.id,
        code: p.code,
        name: p.nameLocal,
        amount: p.amount!,
        imageUrl: p.imageUrl,
        productTypeId: p.productTypeId,
      }))

    const documentItems: TransportTaskStateV2_Item[] = values.selectedDocuments
      .filter((d) => !!d.amount && d.amount > 0)
      .map((d) => ({
        type: TransportTaskItemType.Document,
        id: d.id,
        code: d.code,
        name: d.type.name,
        amount: d.amount!,
      }))

    const otherDocumentItems: TransportTaskStateV2_Item[] = values.selectedOtherDocuments
      .filter((d) => d.selected && !!d.amount && d.amount > 0)
      .map((d) => ({
        type: TransportTaskItemType.Document,
        id: d.id,
        name: d.name,
        amount: d.amount!,
      }))

    const requestDocuments: TransportTaskStateV2_Item[] = values.requestedDocuments
      .filter((d) => d.requestChange)
      .map((d) => ({
        type: TransportTaskItemType.Document,
        id: -d.typeId,
        name: d.name,
        amount: 1,
        requestChange: true,
      }))

    const customerShippingAddress = createData.customerShippingAddresses.find(
      (a) => a.id === values.customerShippingAddress.id,
    )

    if (!createData.saleOrderInfo) {
      console.log('something wrong sale order info is missing')
      return
    }

    const { total, paidAmount, paymentCondition } = { ...createData.saleOrderInfo }

    const shippingBySmart = values.shippingMethod === ShippingMethod.Smart
    const payAtDestination = paymentCondition?.id === PaymentCondition.PayAtDestination

    const request: TransportTaskCreateRequestBody = {
      customerId: createData.saleOrderInfo.customerId,
      saleOrderId: createData.saleOrderInfo.id,
      customerRequisitionId: !!customerRequisitionId ? Number(customerRequisitionId) : undefined,
      items: [...productItems, ...requestDocuments, ...documentItems, ...otherDocumentItems],
      useShippingProvider: values.shippingMethod !== ShippingMethod.Smart,
      shippingProvider:
        values.shippingMethod === ShippingMethod.Express && !!values.shippingProviderName
          ? {
              id: values.shippingProviderId,
              name: values.shippingProviderName,
              detail: values.shippingProviderDetail,
            }
          : undefined,
      dueDate: values.dueDate ? dateSqlFormat(values.dueDate, 'yyyy-MM-dd') : undefined,
      messenger: values.messenger,
      car: values.car,
      chargeAmount: values.skipChargeAmount
        ? 0
        : shippingBySmart
        ? payAtDestination
          ? total - paidAmount
          : values.chargeAmount
        : undefined,
      remark: values.remark,

      consignee: values.consignee,
      phoneNumber: values.phoneNumber,

      customerShippingAddress: {
        id: values.customerShippingAddress.id,
        name: customerShippingAddress?.name ?? values.customerShippingAddress.name,
        branchName: customerShippingAddress?.branchName ?? values.customerShippingAddress.branchName,
        addressLine1: customerShippingAddress?.addressLine1 ?? values.customerShippingAddress.addressLine1,
        phoneNumber: customerShippingAddress?.phoneNumber ?? values.customerShippingAddress.phoneNumber,
        faxNumber: customerShippingAddress?.faxNumber ?? values.customerShippingAddress.faxNumber,

        province:
          customerShippingAddress?.province ??
          addressData?.provinces.find((d) => d.id === values.customerShippingAddress.provinceId),
        district:
          customerShippingAddress?.district ??
          addressData?.districts.find((d) => d.id === values.customerShippingAddress.districtId),
        subDistrict:
          customerShippingAddress?.subDistrict ??
          addressData?.subDistricts.find((d) => d.id === values.customerShippingAddress.subDistrictId),

        postalCode: customerShippingAddress?.postalCode ?? values.customerShippingAddress.postalCode,
        googleMapLink: customerShippingAddress?.googleMapLink ?? values.customerShippingAddress.googleMapLink,
      },
      assigneeUserId: values.assigneeUserId,

      payShippingFeeBySmart: shippingBySmart
        ? undefined
        : values.shippingPaymentCondition === ShippingPaymentCondition.Smart,
    }

    const response = await createTransportTaskAPI(request)
    if (response?.status === 201) {
      triggerSnackbar('สร้างงานขนส่งสำเร็จ')
      history.push(`/transport-tasks/${response.data.id}/profile`)
    } else {
      triggerSnackbar('สร้างงานขนส่งไม่สำเร็จ')
    }

    return true
  }

  const validateValues = (values: TransportTaskCreateFormValues, triggerSubmit?: boolean): boolean => {
    let valid = true

    // validate products or documents
    const hasSelectSomeProduct = values.selectedProducts.some((p) => !!p.amount && p.amount > 0)
    if (!hasSelectSomeProduct) {
      const hasSelectSomeDocument = values.selectedDocuments.some((d) => !!d.amount && d.amount > 0)
      const hasSelectSomeOtherDocument = values.selectedOtherDocuments.some((d) => !!d.amount && d.amount > 0)
      const hasRequestedDocument = values.requestedDocuments.some((d) => d.requestChange)
      const hasDocument = !hasSelectSomeDocument && !hasSelectSomeOtherDocument && !hasRequestedDocument
      if (hasDocument) {
        setError('selectedProducts', {
          type: 'required',
          message: 'กรุณาเลือกสินค้า หรือ เอกสารที่จะส่ง',
        })
        scrollTo('#shipping-product-selector-box')
        valid = false
      }
    }

    if (!values.shippingMethod) {
      setError('shippingMethod', {
        type: 'required',
        message: 'กรุณาเลือกวิธีการส่งสินค้า / เอกสาร',
      })
      scrollTo('#shipping-method-selector-box')
      valid = false
    }

    // if (valid && triggerSubmit) handleSubmit(values)
    return valid
  }

  // render
  return (
    <TransportTaskCreatePageContext.Provider
      value={{
        transportTaskCreateData: createData,
      }}
    >
      <FormProvider {...formMethod}>
        {state === TransportTaskCreateState.Form ? (
          <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
            <TransportTaskCreateForm />
            <div className="mb-12" />
          </Form>
        ) : (
          <Form onSubmit={handleConfirmValues} onInvalid={handleInvalid}>
            <TransportVerificationForm
              onBack={() => {
                setState(TransportTaskCreateState.Form)
              }}
            />
            <div className="mb-12" />
          </Form>
        )}
      </FormProvider>
    </TransportTaskCreatePageContext.Provider>
  )
}

export default withLayout(TransportTaskCreatePage)
