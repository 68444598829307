import { InputAdornment, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'

interface SearchBarProps {
  onChange: (query: string) => void
}

const SearchBar = (props: SearchBarProps) => {
  const { onChange } = props

  return (
    <TextField
      fullWidth
      label="ค้นหา"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      size="small"
      onChange={({ target: { value } }) => {
        onChange(value)
      }}
    />
  )
}

export default SearchBar
