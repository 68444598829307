import { Avatar, Button, Card, Grid, Paper, Typography } from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { useContext, useRef, useState } from 'react'
import { EmptyCard, FlexBox, SubTopic, TypographyWithLabel } from '../../../custom-components'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import PurchaseOrderProfileUploadDocumentDialog from './PurchaseOrderProfileUploadDocumentDialog'
import { RootContext } from '../../..'
import PurchaseOrderProfileDeleteDocumentDialog from './PurchaseOrderProfileDeleteDocumentDialog'
import { BaseData, PurchaseOrderProfile_Document } from '../../../api/smartbestbuys-api'
import { dateFormat } from '../../../functions/date-formatter'
import { formatNumber } from '../../../functions/number-utils'
import './PurchaseOrderProfileDocumentBox.less'
import { PurchaseOrderStatus } from '../../../enums'
import { FileType } from '../../../types'
import { MultipleConditionTooltip, PDFAvatar } from '../../../shared-components'
import PreviewImageDialog from '../../purchase-requisitions/create/AttachmentSelectionBox/UploadCard/PreviewImageDialog'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const PurchaseOrderProfileDocumentBox: React.FC = () => {
  //set context & state
  const { purchaseOrderProfile, forceReload } = useContext(PurchaseOrderProfilePageContext)
  const { documents, total } = purchaseOrderProfile
  const [documentOpen, setDocumentOpen] = useState(false)
  const [documentToDelete, setDocumentToDelete] = useState<PurchaseOrderProfile_Document | undefined>()
  const { triggerSnackbar } = useContext(RootContext)

  // data
  const blackListStatusUploadDocument = [PurchaseOrderStatus.Rejected, PurchaseOrderStatus.Canceled]

  // calculation
  const totalDocument = !!documents
    ? documents.reduce<number>((sum, document) => {
        return sum + document.total
      }, 0)
    : 0
  const remainingTotal = total - totalDocument
  const showedUploadDocumentButton = !blackListStatusUploadDocument.includes(purchaseOrderProfile.status.id)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Write)

  // conditions
  const uploadDocumentConditions = [
    { showTooltip: !writePermission.hasPermission, message: writePermission.alertMessage },
    { showTooltip: total <= totalDocument, message: 'อัพโหลดเอกสารครบแล้ว' },
  ]

  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <PurchaseOrderProfileUploadDocumentDialog
          open={documentOpen}
          onClose={() => setDocumentOpen(false)}
          onComplete={(data) => {
            setDocumentOpen(false)
            triggerSnackbar(`เพิ่มเอกสารทางการเงินสำเร็จ`)
            forceReload()
          }}
          onFail={() => {
            triggerSnackbar(`เพิ่มเอกสารทางการเงินไม่สำเร็จ`)
          }}
        />
        {!!documentToDelete && (
          <PurchaseOrderProfileDeleteDocumentDialog
            value={documentToDelete}
            open={true}
            onClose={() => setDocumentToDelete(undefined)}
            onComplete={(data) => {
              setDocumentToDelete(undefined)
              triggerSnackbar(`ลบเอกสารทางการเงินสำเร็จ`)
              forceReload()
            }}
            onFail={() => {
              triggerSnackbar(`ลบเอกสารทางการเงินไม่สำเร็จ`)
            }}
          />
        )}
        <FlexBox justifyContent="space-between">
          <SubTopic>เอกสารทางการเงิน</SubTopic>
          {showedUploadDocumentButton && (
            <MultipleConditionTooltip conditionItems={uploadDocumentConditions}>
              <Button
                variant="contained"
                color="primary"
                disabled={uploadDocumentConditions.some((cond) => cond.showTooltip)}
                classes={{ label: 'px-2 leading-none' }}
                onClick={() => setDocumentOpen(true)}
              >
                <Add fontSize="small" classes={{ root: 'mr-2' }} /> เพิ่มเอกสารทางการเงิน
              </Button>
            </MultipleConditionTooltip>
          )}
        </FlexBox>
        <div className="mb-4" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TypographyWithLabel label="ยอดทั้งหมด">{formatNumber(total)}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={4}>
            <TypographyWithLabel label="ยอดที่เพิ่มเอกสารแล้ว">{formatNumber(totalDocument)}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={4}>
            <TypographyWithLabel label="ยอดคงค้าง">{formatNumber(remainingTotal)}</TypographyWithLabel>
          </Grid>
        </Grid>
        <div className="mb-4" />

        <Grid container spacing={1}>
          {!!documents && documents.length > 0 ? (
            documents.map((document) => (
              <Grid item xs={12} md={4} key={document.id}>
                <DocumentCard
                  value={document}
                  currency={purchaseOrderProfile.currency}
                  onRemove={(document) => setDocumentToDelete(document)}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <EmptyCard emptyText="ยังไม่มีข้อมูลการทำเบิก/ทำจ่าย" />
            </Grid>
          )}
        </Grid>
      </Paper>
    </FlexBox>
  )
}

export default PurchaseOrderProfileDocumentBox

interface DocumentCardProps {
  value: PurchaseOrderProfile_Document
  currency?: BaseData
  onRemove: (data: PurchaseOrderProfile_Document) => void
}

const DocumentCard: React.FC<DocumentCardProps> = (props: DocumentCardProps) => {
  // prop
  const { value: document, currency, onRemove } = props
  const [imageDocument, setOpenImageDocument] = useState('')
  const attachmentFileType = document.attachmentUrl.slice(-4) === '.pdf' ? FileType.PDF : FileType.Image
  const isImageFile = attachmentFileType === FileType.Image

  // ref
  const ref = useRef<HTMLInputElement | null>(null)

  return (
    <Card variant="outlined" className="document-card">
      {!!imageDocument && (
        <PreviewImageDialog
          refInput={ref}
          source={imageDocument}
          open={!!imageDocument}
          onClose={() => {
            setOpenImageDocument('')
          }}
          readOnly
        />
      )}
      <FlexBox>
        {isImageFile ? (
          <Avatar
            className="document-image"
            variant="square"
            sizes="large"
            src={document.attachmentUrl}
            onClick={() => setOpenImageDocument(document.attachmentUrl)}
          />
        ) : (
          <PDFAvatar src={document.attachmentUrl} />
        )}
        <div className="document-card__box">
          <div className="document-card__box__title">
            <Typography variant="subtitle2">{document.type.name}</Typography>
            <Delete
              style={{ cursor: 'pointer' }}
              color="action"
              onClick={() => {
                onRemove(document)
              }}
            />
          </div>
          <div className="document-card__box__content">
            <Typography variant="subtitle2" color="textSecondary">
              {document.referenceNumber}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {dateFormat(document.referenceDate)}
            </Typography>
          </div>
          <Typography variant="subtitle1" color="secondary">
            {formatNumber(document.total)} {currency?.name ?? 'บาท'}
          </Typography>
        </div>
      </FlexBox>
    </Card>
  )
}
