import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      width: 120,
      height: 100,
    },
    avatarSmall: {
      width: 30,
      height: 30,
    },
  }),
)
