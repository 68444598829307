import React from 'react'
import { Container, Typography } from '@material-ui/core'
import { withLayout } from '../custom-components'

const NotFoundPage: React.FC = (_) => (
  <Container>
    <Typography>NOT FOUND</Typography>
  </Container>
)

export default withLayout(NotFoundPage)
