import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { FlexBox, GeneralAutoComplete, SubTopic } from '../../../custom-components'
import { useFormContext } from 'react-hook-form'
import { TransportPickupTaskFormValues } from './TransportPickupTaskFormValues'
import { TransportPickupTaskFormData } from './TransportPickupTaskForm'

interface Props {
  formData: TransportPickupTaskFormData
}

const TransportPickupTaskAssigneeUserForm: React.FC<Props> = ({ formData }) => {
  // form data
  const { users } = formData

  const { control } = useFormContext<TransportPickupTaskFormValues>()

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ผู้รับผิดชอบงาน</SubTopic>
        <div className="mb-4" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <GeneralAutoComplete
              control={control}
              renderInput={(params) => params}
              name="assigneeUserId"
              label="ผู้รับผิดชอบงาน *"
              rules={{ required: 'เลือกผู้รับผิดชอบงาน' }}
              options={users.map((user) => {
                return {
                  label: user?.firstName + ' ' + user?.lastName,
                  value: user.id,
                }
              })}
            ></GeneralAutoComplete>
          </Grid>
        </Grid>
      </Paper>
    </FlexBox>
  )
}

export default TransportPickupTaskAssigneeUserForm
