import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from '../../../../../../custom-components'
import { updateProductPrice } from './service'
import { PriceRangeFormValue } from '../../../../create/ProductCreationFormValues'
import { ModifyPriceRange, SubmitForm } from '../../../../../../shared-components'
import { ProductPrice } from '../../../../../../api/smartbestbuys-api'
import { useContext } from 'react'
import ProductProfileContext from '../../ProductProfileContext'
import { Close } from '@material-ui/icons'
import { useStyles } from './style'

interface Props extends DialogProps {
  onSuccess: (data: ProductPrice) => void
  onFail: () => void
}

const ProductEditPriceRangeDialog: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { onClose, onSuccess, onFail, ...otherProps } = props
  const {
    productProfile: { id, prices },
  } = useContext(ProductProfileContext)

  const tempPriceRange = prices.map((price) => {
    if (price.toAmount !== price.fromAmount) return { ...price, type: 1 }
    return { ...price, type: 2 }
  })

  const formMethod = useForm<PriceRangeFormValue>({
    defaultValues: {
      priceRange: tempPriceRange,
    },
  })

  const { handleSubmit, setValue, getValues } = formMethod

  const handleSubmitPriceRangeForm = async () => {
    const priceRange = getValues('priceRange')
    const response = await updateProductPrice(id, priceRange)
    if (response?.status === 200) {
      onSuccess(response.data)
    } else {
      onFail()
    }
  }

  const handleOnCancel = () => {
    setValue('priceRange', tempPriceRange)
    onClose && onClose({}, 'backdropClick')
  }

  return (
    <Dialog onClose={onClose} {...otherProps} fullWidth maxWidth="md">
      <FormProvider {...formMethod}>
        <Form onSubmit={handleSubmit(handleSubmitPriceRangeForm)}>
          <DialogTitle>
            ช่วงราคาตามจำนวน
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => onClose && onClose({}, 'backdropClick')}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ModifyPriceRange />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box mb={2}>
              <SubmitForm submitText="บันทึก" cancelText="ยกเลิก" onCancel={handleOnCancel} />
            </Box>
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

export default ProductEditPriceRangeDialog
