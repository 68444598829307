import { Button, Chip, Paper, Typography, createStyles, makeStyles } from '@material-ui/core'
import { Delete, Photo } from '@material-ui/icons'
import { Avatar, FlexBox } from '../../../../custom-components'
import PWTicketProductModal from './PWTicketProductModal'
import { IProductTableData_Data } from '../../../../api/smartbestbuys-api'
import { useState } from 'react'
import numeral from 'numeral'
import { useFormContext } from 'react-hook-form'
import { PWTicketFormValues, PWTicketFormValuesProductInfo } from './PWTicketFormValues'

interface Props {
  product: IProductTableData_Data
}

const PWTicketProductCard: React.FC<Props> = (props) => {
  const { watch, setValue } = useFormContext<PWTicketFormValues>()

  const classes = useStyles()

  const { products } = watch()

  const { product } = props

  const { id, code, imageUrl, nameLocal, stockAmount, reservedAmount, unitLocal } = product

  // state
  const [selectProduct, setSelectProduct] = useState<PWTicketFormValuesProductInfo | undefined>(undefined)

  const selectedProduct = products?.find(
    (product: PWTicketFormValuesProductInfo) =>
      product.id === id && !!product.requestToWithdrawnAmount && product.requestToWithdrawnAmount !== 0,
  )

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="body2">{code}</Typography>
        <div className={classes.image}>
          <Avatar className={classes.avatar} variant="rounded" src={imageUrl} objectFit="contain">
            <Photo style={{ fontSize: 120 }} />
          </Avatar>
          {!!selectedProduct && (
            <Chip
              className={classes.chip}
              size="small"
              color="secondary"
              label={`เลือกแล้ว ${selectedProduct.requestToWithdrawnAmount} ${unitLocal || ''}`}
            />
          )}
        </div>
        <Typography variant="body2" className={classes.productName}>
          {nameLocal}
        </Typography>
        <Typography variant="caption" className={classes.stockAmount}>
          พร้อมเบิก {numeral(stockAmount - reservedAmount).format('0,0')} {unitLocal || 'ชิ้น'}
        </Typography>
        <FlexBox gridGap={8}>
          <Button
            variant={!!selectedProduct ? 'outlined' : 'contained'}
            size="small"
            color="primary"
            onClick={() =>
              setSelectProduct(
                !!selectedProduct
                  ? selectedProduct
                  : {
                      id: product.id,
                      code: product.code,
                      nameLocal: product.nameLocal,
                      returnedAmount: 0,
                      type: product.type,
                      imageUrl: product.imageUrl,
                      requestToWithdrawnAmount: 0,
                      stockAmount: product.stockAmount,
                      reservedAmount: product.reservedAmount,
                      unitLocal: product.unitLocal,
                    },
              )
            }
            style={{ width: selectedProduct ? '75%' : '100%' }}
          >
            {!!selectedProduct ? 'แก้ไข' : 'เลือก'}
          </Button>
          {!!selectedProduct && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                const index = products.findIndex((p) => p.id === product.id)
                if (index !== -1) {
                  setValue(`products.${index}.requestToWithdrawnAmount`, 0)
                }
              }}
              style={{ width: '25%' }}
            >
              <Delete />
            </Button>
          )}
        </FlexBox>
      </Paper>
      {selectProduct && (
        <PWTicketProductModal
          open={!!selectProduct}
          onClose={() => setSelectProduct(undefined)}
          selectedProduct={selectProduct}
        />
      )}
    </>
  )
}

export default PWTicketProductCard

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    image: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      width: '100%',
      height: 150,
    },
    chip: {
      position: 'absolute',
      bottom: theme.spacing(1),
    },
    productName: {
      height: 40,
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    stockAmount: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
)
