import React, { FC } from 'react'
import numeral from 'numeral'
import { Document, Page, StyleSheet, Image, Text, Font } from '@react-pdf/renderer'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
  PDFTableCellView,
} from '../../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../../assets/images/new-logo.png'
import { useSaleOrderProfile, useDocument } from '../../../../../hooks'
import { ThaiBaht } from 'thai-baht-text-ts'
import { dateFormat, getAddressText } from '../../../../../functions'
import { CreditNoteState } from '../../../../../api/smartbestbuys-api'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 52,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 272,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 12,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
})

const formatInteger = (number: number) => numeral(number).format('0,0')
const formatDecimal = (number: number) => numeral(number).format('0,0.00')

const CreditNotePDF: FC<RouteComponentProps<any>> = (props) => {
  const { id } = props.match.params
  const creditNote = useDocument<CreditNoteState>(id)
  const saleOrderProfile = useSaleOrderProfile(creditNote?.saleOrderInfo.id)

  if (!creditNote || !saleOrderProfile) return <Loading />
  document.title = `ใบลดหนี้ ${creditNote?.code}`
  const { billingAddress } = creditNote

  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>
                    0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>ที่อยู่: </Text>519/174-6 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ กรุงเทพฯ
                    10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>โทร: </Text>02-1147006
                    </Text>
                    <Text>
                      <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>อีเมล: </Text>
                      sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="เลขที่" direction="row" dataWidth={88} noMargin>
                {creditNote.code}
              </PDFInfo>
              <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                {dateFormat(creditNote.recCreatedAt)}
              </PDFInfo>
              <PDFInfo label="อ้างอิงใบสั่งซื้อ" direction="row" dataWidth={88} noMargin>
                {saleOrderProfile.code}
              </PDFInfo>
              {!!creditNote.referenceDocument && (
                <PDFInfo label="อ้างอิงใบกำกับภาษี" direction="row" dataWidth={88} noMargin>
                  {creditNote.referenceDocument.code}
                </PDFInfo>
              )}
              {!!saleOrderProfile.customerPurchaseReference && (
                <PDFInfo label="เลขที่ PO ของลูกค้า" direction="row" dataWidth={88} noMargin>
                  {saleOrderProfile.customerPurchaseReference}
                </PDFInfo>
              )}
              <PDFInfo label="พนักงานขาย" direction="row" dataWidth={88} noMargin>
                {saleOrderProfile.createdBy}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            ใบลดหนี้
          </Text>
          {/* SubHeader */}
          <PDFRow borderColor="light">
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ชื่อลูกค้า">
                {billingAddress?.overrideDocumentData?.customerName || creditNote.customerInfo.name}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เลขประจำตัวผู้เสียภาษี">
                {billingAddress?.overrideDocumentData?.taxNumber || creditNote.customerInfo.referenceNumber}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={12}>
              <PDFInfo label="ที่อยู่">
                {billingAddress?.overrideDocumentData?.address || getAddressText(billingAddress)}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรศัพท์">{creditNote.customerInfo.phoneNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={8}>
              <PDFInfo label="โทรสาร">{billingAddress?.faxNumber || '-'}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <PDFTable>
            {/* Head Table */}
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                ลำดับ
              </PDFTableCell>
              <PDFTableCell head width={271}>
                รายละเอียด
              </PDFTableCell>
              <PDFTableCell head width={80} textAlign="right">
                จำนวน
              </PDFTableCell>
              <PDFTableCell head width={80} textAlign="right">
                ราคาต่อหน่วย
              </PDFTableCell>
              <PDFTableCell head width={80} textAlign="right">
                จำนวนเงิน
              </PDFTableCell>
            </PDFTableRow>
            {/* Content Table */}

            {creditNote.state?.items.map((item, index) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCellView width={271}>
                  <Text>{item.name}</Text>
                </PDFTableCellView>
                <PDFTableCell width={80} textAlign="right">
                  {formatInteger(item.amount)}
                </PDFTableCell>
                <PDFTableCell width={80} textAlign="right">
                  {formatDecimal(item.discountPerEach || 0)}
                </PDFTableCell>
                <PDFTableCell width={80} textAlign="right">
                  {formatDecimal(item.amount * item.discountPerEach || 0)}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          {/* Footer Table */}
          <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(creditNote.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="หมายเหตุ">{creditNote.state?.remark || '-'}</PDFInfo>

                {creditNote.state?.taxInvoiceReferences && creditNote.state?.taxInvoiceReferences.length > 0 && (
                  <PDFInfo label="ใบกำกับภาษีที่เกี่ยวข้อง">
                    {creditNote.state?.taxInvoiceReferences.join('\n') || '-'}
                  </PDFInfo>
                )}
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {creditNote.state?.priceBreakdowns.map((item) => (
                <PDFInfo key={item.label} label={item.label} direction="row" dataWidth={160}>
                  {formatDecimal(item.amount || 0)}
                </PDFInfo>
              ))}
            </PDFView>
          </PDFView>

          <PDFRow style={{ marginTop: 8 }}>
            <PDFColumn columnSpan={3}>
              <PDFSignatureView title="ผู้รับของ" />
            </PDFColumn>
            <PDFColumn columnSpan={3}>
              <PDFSignatureView title="ผู้ส่งของ" />
            </PDFColumn>
            <PDFColumn columnSpan={3}>
              <PDFSignatureView title="ผู้จัดทำ" endorser={creditNote.recCreatedBy} date={creditNote.recCreatedAt} />
            </PDFColumn>
            <PDFColumn columnSpan={3}>
              <PDFSignatureView title="ผู้อนุมัติ" />
            </PDFColumn>
          </PDFRow>

          <PDFPagination />
        </Page>
      </Document>
    </SoraPDFViewer>
  )
}

export default CreditNotePDF
