export enum UserAction {
  Unknown = 0,
  CreateUser = 1001,
  UpdateUser = 1002,
  DeleteUser = 1003,

  CreateProduct = 2001,
  UpdateProduct = 2002,
  DeleteProduct = 2003,
  UpdateProductStock = 2004,
  UpdateProductPrice = 2005,
  RestoreProduct = 2006,
  RequestToUpdateProduct = 2007,
  ConfirmUpdateProduct = 2008,
  RejectUpdateProduct = 2009,
  VerifyDataProduct = 2010,

  CreateCustomer = 3001,
  UpdateCustomer = 3002,
  DeleteCustomer = 3003,
  BlacklistCustomer = 3004,
  WhitelistCustomer = 3005,
  RestoreCustomer = 3006,

  CreateQuotation = 4001,
  UpdateQuotation = 4002,
  CancelQuotation = 4003,
  ApproveQuotation = 4004,
  RequestChangeQuotation = 4005,
  CantContactCustomerQuotation = 4011,
  ContactCustomerQuotation = 4012,
  CustomerRequestQuotation = 4013,
  ConfirmQuotation = 4014,
  CustomerRejectQuotation = 4015,

  CreateSaleOrder = 5001,
  UpdateSaleOrder = 5002,
  CancelSaleOrder = 5003,
  UpdateSaleOrderStatus = 5004,
  SOCustomerUnavailable = 5011,
  SOContactCustomer = 5012,
  CancelSaleOrderByCustomer = 5013,
  CloseSaleOrder = 5021,
  CloseSaleOrderAtShop = 5022,
  SOCancelPaymentTransaction = 5101,

  SOCreatePaymentTransaction = 5801,
  SOApprovePaymentTransaction = 5802,
  SODeclinePaymentTransaction = 5803,

  SOVerifyPaymentStatement = 5804,

  CreateTask = 6001,
  UpdateTask = 6002,
  UpdateStatusTask = 6003,
  CancelTask = 6004,
  DoneTask = 6005,
  ProcessedFailureTask = 6006,

  CancelDocument = 7002,
  CreateInvoice = 7011,
  CreateTaxInvoice = 7012,
  CreateCreditNote = 7013,

  ApproveSOPaymentTransaction = 8002,
  CancelSOPaymentTransaction = 8003,

  UpdatePurchaseRequisition = 90002,
  CancelPurchaseRequisition = 90003,
  ApprovePurchaseRequisition = 90101,
  RejectPurchaseRequisition = 90102,

  //purchase order
  CreatePurchaseOrder = 91001,
  UpdatePurchaseOrder = 91002,
  UpdatePurchaseOrderShippingDate = 91003,
  ApprovePurchaseOrder = 91004,
  RejectPurchaseOrder = 91005,
  UploadPurchaseOrderDocument = 91006,
  DeletePurchaseOrderDocument = 91007,
  VendorRejectCancelPurchaseOrder = 91097,
  CallLeader = 91098,
  CancelPurchaseOrder = 91099,

  // payment requisition
  ApprovePaymentRequisition = 100002,
  RejectPaymentRequisition = 100098,
  CancelPaymentRequisition = 100099,

  // refund requisition
  ApproveRefundRequisition = 120003,
  RejectRefundRequisition = 120098,
  CancelRefundRequisition = 120099,

  // customer requisition
  RejectCustomerRequisition = 110098,
  CancelCustomerRequisition = 110099,

  // product withdrawal ticket
  CreateProductWithdrawalTicket = 130001,
  UpdateProductWithdrawalTicket = 130002,
  ApproveProductWithdrawalTicket = 130003,
  ConfirmReceivedProductWithdrawalTicket = 130004,
  ConfirmReturnedProductWithdrawalTicket = 130005,
  CloseProductWithdrawalTicket = 130006,
  FollowUpProductWithdrawalTicket = 130007,
  RejectProductWithdrawalTicket = 130098,
  CancelProductWithdrawalTicket = 130099,
}
