import { FormProvider, useForm } from 'react-hook-form'
import {
  PWTicketFormDefaultValues,
  PWTicketFormDetail,
  PWTicketFormProduct,
  PWTicketFormSelectedProduct,
  PWTicketFormValues,
} from '.'
import { Button } from '@material-ui/core'
import { FlexBox } from '../../../../custom-components'
import { useProductTableData } from '../../../../hooks/product/useProductTableData'
import { Loading } from '../../../../shared-components'
import { useMemo } from 'react'
import { useActionMessages } from '../../../../hooks'
import { UserAction } from '../../../../enums'

interface Props {
  defaultValues?: PWTicketFormDefaultValues
  onSubmit: (values: PWTicketFormValues) => void
}

const PWTicketForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
  const productTableData = useProductTableData(8)
  const actionMessages = useActionMessages(UserAction.CreateProductWithdrawalTicket)

  const ticketDefaultValues = useMemo<PWTicketFormValues | undefined>(() => {
    if (!defaultValues) return

    return {
      actionMessageId: defaultValues.actionMessageId,
      requestedDate: defaultValues.requestedDate,
      estimatedReturnedDate: defaultValues.estimatedReturnedDate,
      remark: defaultValues.remark,
      products: defaultValues.selectedProducts ?? [],
    }
  }, [defaultValues])

  const formMethods = useForm<PWTicketFormValues>({
    defaultValues: { ...ticketDefaultValues, products: ticketDefaultValues?.products ?? [] },
  })

  const { handleSubmit, setError } = formMethods

  const actionMessageOptions = useMemo(() => {
    return actionMessages.map((sp) => ({ label: sp.name, value: sp.id }))
  }, [actionMessages])

  if (!productTableData || actionMessageOptions.length === 0) return <Loading />

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'start' })
    }
  }

  const handleSubmitForm = (values: PWTicketFormValues) => {
    if (
      !values.products ||
      values.products.filter((p) => !!p.requestToWithdrawnAmount && p.requestToWithdrawnAmount !== 0).length === 0
    ) {
      setError('products', { message: 'กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น' })
      scrollTo('#products-selector-input-box')
      return
    }

    onSubmit(values)
  }

  if (!productTableData?.data) return <Loading />

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <PWTicketFormProduct productTableData={productTableData} />
        <div className="mb-4" />

        <PWTicketFormSelectedProduct />
        <div className="mb-4" />

        <PWTicketFormDetail actionMessageOptions={actionMessageOptions} />
        <div className="mb-4" />

        <FlexBox gridGap={8} justifyContent="flex-end">
          <Button variant="outlined" color="primary" size="large">
            ย้อนกลับ
          </Button>
          <Button type="submit" variant="contained" color="primary" size="large">
            ยืนยัน
          </Button>
        </FlexBox>
        <div className="mb-40" />
      </form>
    </FormProvider>
  )
}

export default PWTicketForm
