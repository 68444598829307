import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../..'
import {
  createCustomerRequisitionNoteAPI,
  CreateCustomerRequisitionNoteRequestBody,
} from '../../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm } from '../../../../custom-components'
import AttachmentInputBox from '../../../../shared-components/AttachmentInputBox'
import { SourceFile } from '../../../../types'

interface Props extends DialogProps {
  customerRequisitionId: number
}

interface CustomerRequisitionCreateNoteForm {
  content: string
}

const CustomerRequisitionNoteDialog: React.FC<Props> = (props) => {
  const { customerRequisitionId, onClose, ...otherProps } = props

  const { triggerSnackbar } = useContext(RootContext)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<CustomerRequisitionCreateNoteForm>()

  const [files, setFiles] = useState<SourceFile[]>([])

  const handleSubmitNote = async (values: CustomerRequisitionCreateNoteForm) => {
    const request: CreateCustomerRequisitionNoteRequestBody = {
      content: values.content,
      base64Images: files.map((f) => f.base64!), // risk to broke
    }

    const response = await createCustomerRequisitionNoteAPI(customerRequisitionId, request)
    if (response?.status === 200) {
      triggerSnackbar('บันทึกสำเร็จ')
      window.location.reload()
      reset()
      onClose && onClose({}, 'escapeKeyDown')
    } else {
      triggerSnackbar('บันทึกไม่สำเร็จ')
      reset()
      onClose && onClose({}, 'escapeKeyDown')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitNote)}>
        <DialogTitle>จดบันทึก</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GeneralTextFieldForm
                control={control}
                name="content"
                rules={{ required: 'กรุณากรอกข้อความ' }}
                label="ข้อความ *"
              />
            </Grid>
            <Grid item xs={12}>
              <AttachmentInputBox onChange={setFiles} limit={2} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              onClose && onClose({}, 'escapeKeyDown')
              reset()
            }}
          >
            ย้อนกลับ
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            บันทึก
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerRequisitionNoteDialog
