import { Grid, Paper, Typography } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { CustomButton, SubTopic } from '../../../custom-components'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import PurchaseOrderProfileApproveDialog from './PurchaseOrderProfileApproveDialog'
import PurchaseOrderProfileRejectDialog from './PurchaseOrderProfileRejectDialog'
import { RootContext } from '../../..'

const PurchaseOrderVerificationBox: React.FC = () => {
  // context
  const { triggerSnackbar } = useContext(RootContext)
  const { purchaseOrderProfile, forceReload } = useContext(PurchaseOrderProfilePageContext)
  const { verificationList, id, code } = purchaseOrderProfile

  const [approveOpen, setApproveOpen] = useState(false)
  const [rejectOpen, setRejectOpen] = useState(false)

  return (
    <Paper>
      <PurchaseOrderProfileApproveDialog
        purchaseOrderId={id}
        open={approveOpen}
        onClose={() => setApproveOpen(false)}
        onComplete={() => {
          triggerSnackbar(`อนุมัติใบสั่งซื้อเลขที่ ${code} สำเร็จ`)
          forceReload()
          setApproveOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`อนุมัติใบสั่งซื้อเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <PurchaseOrderProfileRejectDialog
        purchaseOrderId={id}
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ไม่อนุมัติใบสั่งซื้อเลขที่ ${code} สำเร็จ`)
          forceReload()
          setRejectOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ไม่อนุมัติใบสั่งซื้อเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} className="purchase-requisition-box__check-list__header">
          <SubTopic>ข้อมูลที่ต้องรีวิว</SubTopic>
          <div>
            <CustomButton variant="contained" color="error" onClick={() => setRejectOpen(true)}>
              <Cancel fontSize="small" className="mr-2" /> ไม่อนุมัติ
            </CustomButton>
            <span className="mr-2" />
            <CustomButton variant="contained" color="success" onClick={() => setApproveOpen(true)}>
              <CheckCircle fontSize="small" className="mr-2" /> อนุมัติ
            </CustomButton>
          </div>
        </Grid>
        <Grid item xs={12}>
          <ul className="purchase-requisition-box__check-list__list">
            {verificationList?.map((item, index) => (
              <li key={`list-${index}`} className="ml-8">
                <Typography color="textSecondary">{item}</Typography>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default PurchaseOrderVerificationBox
