import { useState } from 'react'
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@material-ui/core'
import { Edit, Delete, Photo } from '@material-ui/icons'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Avatar, FlexBox, TColumn } from '../../../../custom-components'
import { SelectedProduct } from '../../../../sales-system/types'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import QuotationsProductModal from './QuotationsProductModal'

export const QuotationSelectedProductColumn: (readOnly?: boolean, isDealer?: boolean) => TColumn<SelectedProduct>[] = (
  readOnly,
  isDealer,
) => [
  {
    name: 'ชื่อสินค้า',
    dataIndex: 'nameLocal',
    fixedWidth: 300,
    render: (product: SelectedProduct, index: number) => (
      <QuotationProductName product={product} index={index} readOnly={readOnly} />
    ),
  },
  {
    name: 'พร้อมขาย',
    dataIndex: 'stockAmount',
    numeric: true,
    format: '0,0',
    fixedWidth: 100,
  },
  {
    name: 'ราคาต่อหน่วย',
    dataIndex: 'price',
    numeric: true,
    format: '0,0.00',
    fixedWidth: 100,
  },
  {
    name: 'จำนวน',
    dataIndex: 'amount',
    numeric: true,
    format: '0,0',
    fixedWidth: 100,
  },
  {
    name: 'ราคารวม',
    numeric: true,
    format: '0,0.00',
    fixedWidth: 100,
    render: (data: SelectedProduct) => data.price * data.amount,
  },
  ...(readOnly
    ? []
    : [
        {
          name: '',
          fixedWidth: 100,
          render: (data: SelectedProduct, index: number) => <Action product={data} index={index} isDealer={isDealer} />,
        },
      ]),
]

//TODO: have to refactor this file
const QuotationProductName: React.FC<{ product: SelectedProduct; index: number; readOnly?: boolean }> = (props) => {
  const { getValues } = useFormContext<QuotationsCreateValue>()
  const { selectedProducts } = getValues()

  const { index } = props
  const product = selectedProducts[index]
  const { imageUrl, nameLocal, code } = product

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Box display="flex" alignItems="flex-start">
        <Avatar variant="rounded" src={imageUrl} objectFit="contain">
          <Photo />
        </Avatar>
        <FlexBox ml={1} flexDirection="column">
          <Typography variant="body2" color="textSecondary">
            {code}
          </Typography>
          <Typography variant="body2">{nameLocal}</Typography>
        </FlexBox>
      </Box>
    </Box>
  )
}

const Action: React.FC<{ product: SelectedProduct; index?: number; isDealer?: boolean }> = (props) => {
  const { control } = useFormContext<QuotationsCreateValue>()
  const { remove } = useFieldArray<QuotationsCreateValue>({
    control,
    name: 'selectedProducts',
  })
  const { product, index, isDealer } = props
  const [open, setOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  return (
    <Box display="flex" justifyContent="space-around">
      <IconButton color="secondary" size="small" onClick={() => setOpen(true)}>
        <Edit />
      </IconButton>
      <IconButton size="small" onClick={() => setConfirmOpen(true)}>
        <Delete />
      </IconButton>
      {open && (
        <QuotationsProductModal open={open} onClose={() => setOpen(false)} product={product} isDealer={isDealer} />
      )}
      {confirmOpen && (
        <Dialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">ยืนยันการนำสินค้าออกจากใบสั่งขาย</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              คุณแน่ใจที่จะทำการนำสินค้าออกจากใบสั่งขายนี้ใช่หรือไม่?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmOpen(false)}>ยกเลิก</Button>
            <Button
              onClick={() => {
                remove(index)
                setConfirmOpen(false)
              }}
              color="primary"
            >
              นำสินค้าออก
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}
