import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Paper, Grid, withWidth, isWidthUp, Box, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import ProductCard from './ProductCard/ProductCard'
import { SearchTextField, SubTopic } from '../../../custom-components'
import { TablePagination } from '@material-ui/core'
import { useProducts } from '../../../hooks'
import SOCreationFormValues from '../../pages/SOCreationPage/SOCreationFormValues'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { ProductDataRow } from '../../../api/smartbestbuys-api'

interface Props {
  width: Breakpoint
  isDealer?: boolean
}

const ProductSelector: React.FC<Props> = (props) => {
  const { width, isDealer } = props

  // FOR DREAM: see hooks example here
  const products = useProducts()

  const {
    watch,
    formState: { errors },
  } = useFormContext<SOCreationFormValues>()

  const { selectedProducts } = watch()

  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')

  const rowsPerPage = isWidthUp('xl', width) ? 12 : 8
  const styles = useStyles()

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const filtered = products.filter((p: ProductDataRow) => {
    const lowerCase = query.toLowerCase()
    return (
      p.code.toLowerCase().includes(lowerCase) ||
      p.nameLocal.toLowerCase().includes(lowerCase) ||
      p.id.toString() === lowerCase
    )
  })

  const paginate = (products: ProductDataRow[]) => products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <>
      <Box display={!!errors.selectedProducts ? 'block' : 'none'} color="error.main" pl={2} mb={0.5} clone>
        <Typography variant="body2">{errors.selectedProducts?.message}</Typography>
      </Box>
      <Box
        borderColor={!!errors.selectedProducts ? 'error.main' : undefined}
        border={!!errors.selectedProducts ? 2 : undefined}
        clone
      >
        <Paper className={styles.root}>
          <SubTopic>เลือกสินค้าลงในใบสั่งขาย</SubTopic>
          <SearchTextField
            onChange={(e) => {
              setQuery(e.target.value)
              setPage(0)
            }}
          />
          <Grid container spacing={2}>
            {paginate(filtered).map((product) => {
              const selectedProduct = selectedProducts.find((sp) => sp.id === product.id)
              return (
                <Grid key={product.nameLocal} item xs={12} sm={6} md={3} xl={2}>
                  <ProductCard isDealer={isDealer} product={product} selectedAmount={selectedProduct?.amount} />
                </Grid>
              )
            })}
          </Grid>
          <TablePagination
            component="div"
            count={filtered.length}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        </Paper>
      </Box>
    </>
  )
}

export default withWidth()(ProductSelector)
