import React, { useContext, useState } from 'react'
import { Button, Card, Grid, IconButton, Paper, Typography, FormControlLabel } from '@material-ui/core'
import { CheckboxForm, FlexBox, GeneralTextFieldForm, SubTopic, Table, Title } from '../../../custom-components'
import { TransportTaskCreatePageContext } from './TransportTaskCreatePage'
import { TransportTaskCreateData_RelatedDocument } from '../../../api/smartbestbuys-api'
import TransportTaskCreateFormValues, { TransportTaskCreateFormValues_Document } from './TransportTaskCreateFormValues'
import { dateFormat } from '../../../functions'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { AddCircle, Delete } from '@material-ui/icons'
import { DocumentStatusChip, Space, TaskStatusChip } from '../../../shared-components'
import { DocumentStatus } from '../../../enums'
import { DateTime } from 'luxon'

const whitelistedDocuments = ['ใบแจ้งหนี้', 'ใบกำกับภาษี', 'ใบวางบิล', 'ใบเสร็จรับเงิน']

const TransportTaskDocument: React.FC = () => {
  // state
  const [selectedIdList, setSelectedIdList] = useState<number[]>([])

  // context
  const { transportTaskCreateData } = useContext(TransportTaskCreatePageContext)
  const { relatedDocuments } = transportTaskCreateData

  const {
    control,
    setValue,
    watch,
    trigger,
    // formState: { errors },
  } = useFormContext<TransportTaskCreateFormValues>()
  // const { selectedOtherDocuments } = watch()
  const { append } = useFieldArray({
    control,
    name: 'selectedOtherDocuments',
  })

  const { selectedDocuments, selectedOtherDocuments, requestedDocuments } = watch()

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>เลือกเอกสารสำหรับขนส่ง</SubTopic>
        <div className="mb-4" />
        {relatedDocuments.length > 0 && (
          <>
            <Card style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>
              <Typography variant="h6">การส่งเอกสารที่เกี่ยวข้อง</Typography>
              <div className="mb-4" />
              <Table<TransportTaskCreateData_RelatedDocument>
                color="primary.dark"
                size="small"
                columns={[
                  { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
                  { name: 'ชื่อเอกสาร', render: ({ name }) => name },
                  {
                    name: 'เลขที่งาน',
                    render: ({ taskInfo }) => (
                      <Typography color="primary" variant="body2">
                        {taskInfo.code}
                      </Typography>
                    ),
                  },
                  {
                    name: 'สถานะงาน',
                    render: ({ taskInfo }) => (
                      <TaskStatusChip status={taskInfo.status.id} label={taskInfo.status.name} />
                    ),
                  },
                  {
                    name: 'วันที่ส่งเอกสาร',
                    render: ({ dueDate }) => (dueDate ? dateFormat(dueDate) : 'ยังไม่ระบุ'),
                    align: 'right',
                  },
                  {
                    name: 'จำนวน',
                    render: ({ amount }) => amount,
                    align: 'right',
                  },
                ]}
                data={relatedDocuments}
                pagination={true}
                paperClassName="table--white"
                cleanSpace
              />
            </Card>
            <div className="mb-4" />
          </>
        )}
        <Paper style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>
          <Title>เอกสารที่จะส่ง</Title>
          <Space />
          <Typography>เอกสารที่ต้องการเพิ่มเติม</Typography>
          <div className="mb-1" />
          <Paper>
            <FlexBox gridGap={16}>
              {whitelistedDocuments.map((d, index) => (
                <FormControlLabel
                  key={d}
                  control={
                    <CheckboxForm control={control} name={`requestedDocuments.${index}.requestChange`} size="small" />
                  }
                  label={d}
                  checked={requestedDocuments[index].requestChange}
                />
              ))}
            </FlexBox>
          </Paper>
          <Space />
          {selectedDocuments.length > 0 && (
            <>
              <Typography>เอกสารที่เปิดแล้ว</Typography>
              <div className="mb-2" />
              <Table<TransportTaskCreateFormValues_Document>
                color="primary.dark"
                size="small"
                columns={[
                  {
                    name: 'สถานะเอกสาร',
                    render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
                  },
                  { name: 'เลขที่เอกสาร', render: ({ code }) => code },
                  { name: 'ชื่อเอกสาร', render: ({ type }) => type.name },
                  {
                    name: 'จำนวนที่จะส่ง',
                    fixedWidth: 200,
                    render: (document, index) => {
                      const selected = selectedIdList.includes(document.id)
                      if (!selected)
                        return (
                          <Typography variant="body2" color="textSecondary">
                            ไม่ได้เลือก
                          </Typography>
                        )
                      return (
                        <GeneralTextFieldForm
                          margin="none"
                          control={control}
                          name={`selectedDocuments.${index}.amount`}
                          rules={{ required: 'กรุณากรอกจำนวน' }}
                          type="number"
                          inputProps={{
                            min: 1,
                          }}
                        />
                      )
                    },
                    align: 'right',
                  },
                ]}
                data={selectedDocuments}
                selectable
                hideSelectAll
                hideCheckbox={({ status: { id } }) => id !== DocumentStatus.Pending}
                onChange={setSelectedIdList}
                onDeSelect={(id) => {
                  const index = selectedDocuments.findIndex((d) => d.id === id)
                  setValue(`selectedDocuments.${index}.amount`, undefined)
                }}
                pagination={false}
                paperClassName="table--white"
                cleanSpace
              />
              <div className="mb-1" />
            </>
          )}
          <FlexBox display="flex" justifyContent="space-between" alignItems="center">
            <Typography>เอกสารอื่น ๆ</Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                append({
                  id: parseInt(DateTime.now().toFormat('X')),
                  name: undefined,
                  amount: undefined,
                  selected: true,
                })
              }}
            >
              <AddCircle className="mr-2" /> เพิ่มเอกสารอื่น ๆ
            </Button>
          </FlexBox>
          <div className="mb-1" />
          {selectedOtherDocuments.filter((d) => d.selected).length > 0 && (
            <Paper>
              <Grid container spacing={2} justify="center" alignItems="center">
                {selectedOtherDocuments.map((item, index) => {
                  if (!item.selected) return <></>
                  return (
                    <>
                      <Grid item xs={8}>
                        <GeneralTextFieldForm
                          key={`${item.id}-name`}
                          control={control}
                          name={`selectedOtherDocuments.${index}.name`}
                          type="text"
                          label="ชื่อเอกสาร *"
                          rules={{ required: 'กรุณากรอกชื่อเอกสาร' }}
                          value={item.name}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <GeneralTextFieldForm
                          key={`${item.id}-amount`}
                          control={control}
                          name={`selectedOtherDocuments.${index}.amount`}
                          type="number"
                          label="จำนวน *"
                          rules={{ required: 'กรุณากรอกจำนวนเอกสาร' }}
                          value={item.amount}
                          inputProps={{
                            onWheel: (event) => event.currentTarget.blur(),
                            min: 1,
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          type="button"
                          onClick={() => {
                            setValue(`selectedOtherDocuments.${index}`, {
                              id: item.id,
                              name: item.name ?? '-',
                              amount: item.amount ?? -1,
                              selected: false,
                            })
                            trigger()
                          }}
                        >
                          <Delete color="action" />
                        </IconButton>
                      </Grid>
                    </>
                  )
                })}
              </Grid>
            </Paper>
          )}
        </Paper>
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskDocument
