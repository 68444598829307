import { useEffect, useState } from 'react'
import { fetchCustomerCreditorAPI, ICreditor } from '../../api/smartbestbuys-api'

export const useCustomerCreditors = (): ICreditor[] => {
  const [data, setData] = useState<ICreditor[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await fetchCustomerCreditorAPI()
      setData(response?.data ?? [])
    })()
  }, [])

  return data
}
