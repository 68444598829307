import { Grid } from '@material-ui/core'
import { ProductProfileCard } from './ProductProfileCard'
import { ProductProfileStock } from './ProductProfileStock'
import PairedProductList from './PairedProductList'
import { ProductPriceRange } from './ProductPriceRangeDialog'
import ProductReservedListTable from './ProductReservedListTable'
import ProductPurchaseOrderTable from './ProductPurchaseOrderTable'
import { ProductActivityLogs } from './ProductActivityLogs'
import { useContext } from 'react'
import ProductProfileContext from '../ProductProfileContext'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

interface ProductProfileDataProps {
  width: Breakpoint
}

const ProductProfileData: React.FC<ProductProfileDataProps> = (props) => {
  const { width } = props
  const { productProfile } = useContext(ProductProfileContext)

  const isProductAvailable = !productProfile.recDeletedAt
  return (
    <Grid container spacing={2}>
      {/* profile */}
      <Grid item xs={12} md={6}>
        <ProductProfileCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProductProfileStock />
      </Grid>
      <Grid item xs={12}>
        <PairedProductList width={width} />
      </Grid>
      <Grid item xs={12}>
        <ProductPriceRange isProductAvailable={isProductAvailable} />
      </Grid>
      {
        //ToDo
        /*<Grid item xs={12}>
                <ProductProfileSaleOrder />
            </Grid>*/
      }
      <Grid item xs={12}>
        <ProductReservedListTable />
      </Grid>
      <Grid item xs={12}>
        <ProductPurchaseOrderTable />
      </Grid>
      <Grid item xs={12}>
        <ProductActivityLogs />
      </Grid>
      {/* end profile */}
    </Grid>
  )
}

export default ProductProfileData
