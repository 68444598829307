import { roundNumber } from './number-utils'

export const calculateVatAmount = (total: number, vatPercentage?: number) => {
  return roundNumber((total * (vatPercentage || 7)) / 100)
}

export const calculateVatInAmount = (total: number, vatPercentage?: number) => {
  const withVatPercent = 100 + (vatPercentage || 7)
  return roundNumber((total / withVatPercent) * 7)
}

export const calculatePercentage = (total: number, percent: number) => {
  return roundNumber((total * percent) / 100)
}

export const calculateAsPercentage = (targetNumber: number, mainNumber: number) => {
  return (targetNumber / mainNumber) * 100
}

export const calculateExcludingVat = (total: number, vatPercentage?: number) => {
  const withVatPercent = 100 + (vatPercentage || 7)
  return roundNumber((total / withVatPercent) * 100)
}

export const calculatePurchaseOrderTotal = (productTotal: number, discountAmount?: number, vatIncluded?: boolean) => {
  const totalBeforeVat = productTotal - (discountAmount ?? 0)
  const vatAmount = vatIncluded ? calculateVatAmount(totalBeforeVat) : 0
  return totalBeforeVat + vatAmount
}
