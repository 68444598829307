import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { FilteredTable, FlexBox, Topic, withLayout, Link } from '../../../custom-components'
import { Add } from '@material-ui/icons'
import { FetchVendor } from '../../../api/smartbestbuys-api'
import { useVendors } from '../../../hooks'
import { RootContext } from '../../..'
import { PageName } from '../../../enums'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../shared-components'

const VendorListPage: React.FC = () => {
  // context & state
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.VENDOR)

  // hooks
  const history = useHistory()
  const vendors = useVendors()

  const vendorCredity = [
    {
      name: 'ผู้ขายเครดิต',
      value: 1,
    },
    {
      name: 'ไม่ใช่ผู้ขายเครดิต',
      value: 0,
    },
  ]

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_Vendor_Write)

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการผู้ขาย</Topic>
            <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => history.push('/vendors/create')}
                disabled={!writePermission.hasPermission}
              >
                สร้างผู้ขาย
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<FetchVendor>
            color="secondary.dark"
            columns={[
              {
                name: 'รหัสผู้ขาย',
                render: ({ id, code }) => (
                  <Link to={`/vendors/${id}/profile`}>
                    <Box color="primary.dark">
                      <Typography variant="body2">{code}</Typography>
                    </Box>
                  </Link>
                ),
                dataIndex: 'code',
              },
              {
                name: 'ชื่อ',
                dataIndex: 'name',
              },
              {
                name: 'ประเทศ',
                render: ({ country }) => country,
              },
              {
                name: 'ที่อยู่',
                dataIndex: 'address',
              },
              {
                name: 'ขอบเขตงานที่รับ',
                dataIndex: 'scopeWork',
              },
              {
                name: 'คะแนน',
                dataIndex: 'averageRating',
              },
              {
                name: 'สกุลเงินที่ใช้',
                render: ({ currency }) => currency.name,
              },
              {
                name: 'อีเมล',
                dataIndex: 'email',
              },
              {
                name: 'เบอร์โทรศัพท์',
                dataIndex: 'phoneNumber',
              },
            ]}
            disableDateRangeFilter
            sortable={true}
            data={vendors}
            size="small"
            filters={[
              {
                name: 'สถานะผู้ขาย',
                values: vendorCredity,
                filter: (data: FetchVendor, values: (string | number)[]) =>
                  !data.creditPeriodDay ? values.includes(0) : values.includes(1),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withLayout(VendorListPage)
