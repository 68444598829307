import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import {
  PermissionGroup,
  PermissionTopic,
  updateUserPermissionByIdAPI,
  UserPermissionUpdateRequestBody,
  UserProfile,
} from '../../../api/smartbestbuys-api'
import UserPermissionUpdateForm from './UserPermissionUpdateForm'

interface Props extends DialogProps {
  userProfile: UserProfile
  permissionGroups: PermissionGroup[]
  permissionTopics: PermissionTopic[]
  onComplete: (user: UserProfile) => void
}

const UserPermissionUpdateDialog = (props: Props) => {
  const { userProfile, permissionGroups, permissionTopics, onComplete, onClose, ...dialogProps } = props
  const { triggerSnackbar } = useContext(RootContext)

  const initSelectedPermissionIds = userProfile.permissionTopics.reduce<number[]>((summary, topic) => {
    return [...summary, ...topic.permissions.map((permission) => permission.id)]
  }, [])

  const initSelectedPermissionGroupIds: (number | undefined)[] = userProfile.permissionGroups.map((group) => group.id)

  const [selectedPermissionGroupIds, setSelectedPermissionGroupIds] = useState<number[]>(
    initSelectedPermissionGroupIds.every((groupId) => groupId === undefined)
      ? []
      : (initSelectedPermissionGroupIds as number[]),
  )

  const allPermissionIdInSelectedGroup = useMemo(
    () => {
      return permissionGroups.reduce<number[]>((previousValue, currentValue) => {
        if (selectedPermissionGroupIds.includes(currentValue.id)) {
          return [...previousValue, ...(currentValue.permissions?.map((permission) => permission.id) ?? [])]
        } else {
          return previousValue
        }
      }, [])
    }, // eslint-disable-next-line
    [selectedPermissionGroupIds.length],
  )

  const [selectedPermissionIds, setSelectedPermissionIds] = useState<number[]>(initSelectedPermissionIds)

  const handleSubmit = async () => {
    const request: UserPermissionUpdateRequestBody = {
      permissionGroupIds: selectedPermissionGroupIds,
      permissionIds: selectedPermissionIds,
    }

    try {
      const response = await updateUserPermissionByIdAPI(userProfile.id, request)
      if (response && response.status === 200) {
        triggerSnackbar('แก้ไขสิทธิ์การใช้งานสำเร็จ')
        if (onComplete) onComplete(response.data)
      } else {
        triggerSnackbar('แก้ไขสิทธิ์การใช้งานไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('แก้ไขสิทธิ์การใช้งานไม่สำเร็จ')
    }
  }

  return (
    <Dialog maxWidth="lg" fullWidth {...dialogProps}>
      <DialogTitle>แก้ไขสิทธิ์การใช้งาน</DialogTitle>
      <DialogContent>
        <UserPermissionUpdateForm
          permissionGroups={permissionGroups}
          permissionTopics={permissionTopics}
          selectedPermissionGroupIds={selectedPermissionGroupIds}
          selectedPermissionIds={selectedPermissionIds}
          onPermissionGroupSelect={setSelectedPermissionGroupIds}
          onPermissionSelect={setSelectedPermissionIds}
          allPermissionIdInSelectedGroup={allPermissionIdInSelectedGroup}
          onSubmit={handleSubmit}
          onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
        />
      </DialogContent>
    </Dialog>
  )
}

export default UserPermissionUpdateDialog
