import React, { useContext } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { FilteredTable, Link, Title } from '../../../../../custom-components'
import { ICustomer, ICustomerContact, ISaleOrderProduct } from '../../../../../api/smartbestbuys-api'
import ProductProfileContext from '../ProductProfileContext'
import { ISaleOrder } from '../../../../../api/smartbestbuys-api/sale/sale-types'
import { dateFormat } from '../../../../../functions'

const ProductReservedListTable: React.FC = () => {
  const { productProfile } = useContext(ProductProfileContext)
  const { reservedSaleOrders } = productProfile

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>รายงาน SO ค้างเบิก</Title>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<
            Pick<ISaleOrder, 'id' | 'code' | 'status' | 'dueDate' | 'recCreatedAt'> &
              Pick<
                ISaleOrderProduct,
                'amount' | 'price' | 'productTotal' | 'shippedAmount' | 'reservedAmount' | 'pendingDeliverAmount'
              > & {
                customer: Pick<ICustomer, 'id' | 'nameLocal'>
                customerContact: Pick<ICustomerContact, 'id' | 'nameLocal' | 'nicknameLocal'>
              }
          >
            color="primary.dark"
            columns={[
              {
                name: 'วันที่บันทึก',
                render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
              },
              {
                name: 'เลขที่ SO',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/sale-orders/${id}`}>
                    <Typography variant="body2">{code}</Typography>
                  </Link>
                ),
              },
              {
                name: 'ชื่อผู้ติดต่อ',
                render: ({ customer, customerContact }) => (
                  <div>{customerContact?.nicknameLocal ?? customer.nameLocal}</div>
                ),
              },
              {
                name: 'จำนวน',
                dataIndex: 'amount',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'ราคา (บาท)',
                dataIndex: 'price',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนเงิน',
                dataIndex: 'productTotal',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนที่ส่งแล้ว',
                dataIndex: 'shippedAmount',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนที่กำลังส่ง',
                dataIndex: 'reservedAmount',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนที่ค้างส่ง',
                dataIndex: 'pendingDeliverAmount',
                numeric: true,
                format: '0,0',
              },
              {
                name: 'วันที่ส่งมอบ',
                render: ({ dueDate }) => (dueDate ? dateFormat(dueDate) : '-'),
              },
            ]}
            data={(reservedSaleOrders || []) as any}
            size="small"
            disableDateRangeFilter
            cleanSpace
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductReservedListTable
