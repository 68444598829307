import React, { useContext, useState } from 'react'
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions, Grid, Typography } from '@material-ui/core'
import { FlexBox, Form, GeneralTextFieldForm } from '../../../custom-components'
import { SubmitForm, UploadImageCard } from '../../../shared-components'
import { FormProvider, useForm } from 'react-hook-form'
import { finishPickupTaskAPI } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import { ImageSource } from '../../../shared-components/UploadImageCard/UploadImageCard'
import { uploadPublicFile } from '../../../api/aws-api'
import { DateTime } from 'luxon'

interface Values {
  remark?: string
}

interface Props {
  data: { id: number }
  onSuccess?: (data: any) => void
  onFail?: () => void
}

const ConfirmPickupResultDialog: React.FC<Props & DialogProps> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { data, onSuccess, onFail, onClose, ...dialogProps } = props

  // state
  const [attachedImage, setAttachedImage] = useState<Partial<ImageSource>>()

  const formMethod = useForm<Values>()

  const {
    control,
    formState: { isSubmitting },
  } = formMethod

  const handleSubmit = async (values: Values) => {
    const attachedUrl = await uploadPublicFile(
      attachedImage?.base64!,
      `pickup-tasks/${data.id}/attachment_${DateTime.now().toFormat('x')}`,
    )

    const response = await finishPickupTaskAPI(data.id, {
      action: 1,
      pickupResult: {
        attachmentUrl: attachedUrl,
        remark: values.remark,
      },
    })

    if (response?.status === 200) {
      if (onSuccess) onSuccess(response.data)
      triggerSnackbar('จบงานสำเร็จ')
    } else {
      if (onFail) onFail()
      triggerSnackbar('จบงานไม่สำเร็จ')
    }
    return true
  }

  const handleImageChange = (f: ImageSource) => {
    setAttachedImage(f)
  }

  return (
    <Dialog {...dialogProps} maxWidth="md">
      <FormProvider {...formMethod}>
        <Form onSubmit={handleSubmit}>
          <DialogTitle>จบงานรับของ</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
              </Grid>
              <Grid item xs={12}>
                <FlexBox justifyContent="center" gridGap={8}>
                  <UploadImageCard
                    defaultSrc={attachedImage?.base64}
                    title={`อัปโหลดรูปหลักฐาน *`}
                    onChange={(img) => handleImageChange(img)}
                  />
                </FlexBox>
                {!attachedImage?.base64 && <Typography color="error">** กรุณาเลือกรูปอย่างน้อย 1 รูป</Typography>}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitForm
              submitText="ยืนยันจบงาน"
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

export default ConfirmPickupResultDialog
