import { useState, useEffect } from 'react'
import { getVendorProfile, VendorProfile } from '../../api/smartbestbuys-api'

export const useVendorProfile = (
  vendorId: number,
  reload?: boolean,
): [VendorProfile | undefined, (data: VendorProfile | undefined) => void] => {
  const [data, setData] = useState<VendorProfile | undefined>(undefined)

  const _getVendorProfile = async (vendorId: number) => {
    const response = await getVendorProfile(vendorId)
    setData(response?.data)
  }

  useEffect(() => {
    _getVendorProfile(vendorId)
  }, [vendorId, reload])

  return [data, setData]
}
