import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import {
  LocalPhoneTwoTone as LocalPhoneIcon,
  EmailTwoTone as EmailIcon,
  ChatTwoTone as ChatIcon,
} from '@material-ui/icons'
import { useStyles } from './styles'
import { Title, TypographyWithLabel } from '../../custom-components'
import { ICustomerContact } from '../../api/smartbestbuys-api'

interface Props {
  customerContact: ICustomerContact
}

const CustomerContactInfo: React.FC<Props> = (props) => {
  const styles = useStyles()
  const { customerContact } = props

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>ผู้ติดต่อ</Title>
      </Grid>
      <Grid item xs={12}>
        <Paper className={styles.contactInfo} elevation={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>{customerContact.nicknameLocal}</Title>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel icon={<LocalPhoneIcon />} label="เบอร์โทร:">
                {customerContact.phoneNumber}
              </TypographyWithLabel>
            </Grid>
            {customerContact.email && (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel icon={<EmailIcon />} label="อีเมล:">
                  {customerContact.email}
                </TypographyWithLabel>
              </Grid>
            )}
            {customerContact.line && (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel icon={<ChatIcon />} label="ไลน์ไอดี:">
                  {customerContact.line}
                </TypographyWithLabel>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

export default CustomerContactInfo
