import { Card, Grid, Typography, Divider, IconButton, Avatar } from '@material-ui/core'
import { Edit, Photo } from '@material-ui/icons'
import { useMemo, useState } from 'react'
import { TransportTask, TransportTaskProfile } from '../../../api/smartbestbuys-api'
import { FlexBox, TypographyWithLabel } from '../../../custom-components'
import { ProductType, TaskStatus, TaskType } from '../../../enums'
import { formatNumber } from '../../../functions/number-utils'
import { TypographyLink, Space, TaskStatusChip } from '../../../shared-components'
import { UpdateMessengerInfoDialog } from './UpdateMessengerInfoDialog'
import { UpdateShippingAddressDialog } from './UpdateShippingAddressDialog'

interface Props {
  task: TransportTask
  onUpdate?: (task: TransportTaskProfile) => void
}

export const TransportPickupCard = (props: Props) => {
  const { task, onUpdate } = props

  const [openShippingForm, setOpenShippingForm] = useState(false)
  const [openMessengerForm, setOpenMessengerForm] = useState(false)

  const messengerInfo = task.state?.shippingInfo.messengerInfo
  const pickupTask = task.relatedTasks?.find((t) => t.type.id === TaskType.MessengerPickupTask)
  const ableToEdit = !pickupTask || pickupTask.status.id === TaskStatus.Pending

  const isCanceledTask = task.status.id === TaskStatus.Canceled

  const failureMessage = useMemo(
    () => {
      return pickupTask?.state.failureMessages?.failed ?? pickupTask?.state.failureMessages?.preCanceled
    }, // eslint-disable-next-line
    [pickupTask?.id],
  )

  return (
    <>
      {openShippingForm && task.saleOrderInfo && task.state && (
        <UpdateShippingAddressDialog
          taskId={task.id}
          taskCode={task.code}
          customerId={task.saleOrderInfo.customerId}
          consigneeName={task.state.shippingInfo.consignee}
          consigneePhoneNumber={task.state.shippingInfo.phoneNumber}
          shippingAddressId={task.state.shippingInfo.shippingAddress?.id}
          onComplete={(task) => {
            setOpenShippingForm(false)
            if (onUpdate) onUpdate(task)
          }}
          onClose={() => setOpenShippingForm(false)}
        />
      )}
      {openMessengerForm && messengerInfo && (
        <UpdateMessengerInfoDialog
          taskId={task.id}
          taskCode={task.code}
          messengerUserId={messengerInfo.userId}
          shippingCarId={messengerInfo.car?.id}
          onComplete={(task) => {
            setOpenMessengerForm(false)
            if (onUpdate) onUpdate(task)
          }}
          onClose={() => setOpenMessengerForm(false)}
        />
      )}
      <Card>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between" alignItems="top">
              <TypographyLink
                href={`/customer-requisitions/${task.customerRequisitionInfo?.id}/profile`}
                target="_blank"
                variant="h6"
                color="primary"
                textDecorationColor="primary"
              >
                {task.customerRequisitionInfo?.code}
              </TypographyLink>
              <FlexBox>
                <FlexBox flexDirection="column" justifyContent="right">
                  <TypographyLink
                    href={`/transport-pickup-tasks/${task.id}/profile`}
                    target="_blank"
                    variant="body2"
                    color="#828282"
                    textDecorationColor="#828282"
                  >
                    {task.code}
                  </TypographyLink>
                  {isCanceledTask && (
                    <TypographyWithLabel label="เหตุผล:" labelProps={{ color: 'primary' }} color="primary">
                      {task.canceledMessage}
                    </TypographyWithLabel>
                  )}
                </FlexBox>

                <Space size="small" direction="horizontal" />
                {/* {task.status.id === TaskStatus.Canceled && ( */}
                <TaskStatusChip status={task.status.id} label={task.status.name} />
                {/* )} */}
              </FlexBox>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <Typography>{task.saleOrderInfo?.customerName ?? 'ไม่เจอ!!'}</Typography>
          </Grid>

          <Grid item xs={12}>
            <TypographyWithLabel label="ฝ่ายบริการหลังการขายผู้รับผิดชอบ:">
              {task.customerRequisitionInfo?.recCreatedBy}
            </TypographyWithLabel>
          </Grid>

          <Grid item xs={12}>
            <TypographyWithLabel
              label="ยอดที่ต้องรับชำระ:"
              color={task.state?.shippingInfo.chargeAmount ? 'primary' : 'textSecondary'}
            >
              {task.state?.shippingInfo.chargeAmount
                ? `${formatNumber(task.state?.shippingInfo.chargeAmount)} บาท`
                : 'ไม่ต้องเก็บเงินเพิ่มเติม'}
            </TypographyWithLabel>
          </Grid>

          <Grid item xs={12}>
            <TypographyWithLabel
              label="หมายเหตุ:"
              color={task.state?.shippingInfo?.remark ? 'primary' : 'textSecondary'}
            >
              {task.state?.shippingInfo?.remark ?? '-'}
            </TypographyWithLabel>

            <Space size="small" />
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FlexBox justifyContent="space-between" alignItems="center">
              <span className="font-bold">ข้อมูลที่อยู่สำหรับรับของ</span>
              {ableToEdit && (
                <IconButton size="small" onClick={() => setOpenShippingForm(true)}>
                  <Edit color="secondary" />
                </IconButton>
              )}
            </FlexBox>
            <Space size="small" />
          </Grid>
          <Grid item xs={12}>
            <TypographyWithLabel label="ที่อยู่รับของ:">
              {task.state?.shippingInfo.shippingAddress?.text ?? '-'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12}>
            <TypographyWithLabel label="ข้อมูลผู้ติดต่อ:">
              {task.state?.shippingInfo.consignee} {task.state?.shippingInfo.phoneNumber}
            </TypographyWithLabel>

            <Space size="small" />
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FlexBox justifyContent="space-between" alignItems="center">
              <span className="font-bold">ข้อมูลผู้รับ</span>
              {ableToEdit && (
                <IconButton size="small" onClick={() => setOpenMessengerForm(true)}>
                  <Edit color="secondary" />
                </IconButton>
              )}
            </FlexBox>
          </Grid>
          <Grid item xs={6}>
            <TypographyWithLabel label="ทะเบียนรถ:">
              {task.state?.shippingInfo.messengerInfo?.car?.name ?? 'ไม่ได้ระบุ'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={6}>
            <TypographyWithLabel label="ชื่อผู้รับของ:">
              {task.state?.shippingInfo.messengerInfo?.name ?? 'ไม่ได้ระบุ'}
            </TypographyWithLabel>
          </Grid>
        </Grid>
        <Space size="small" />
        <Divider />

        {pickupTask && (
          <>
            <Space size="small" />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FlexBox justifyContent="space-between">
                  <span className="font-bold">รายละเอียดงานรับของ</span>
                </FlexBox>
                <Space size="small" />
              </Grid>

              <Grid item xs={12}>
                <TypographyWithLabel label="สถานะการรับของ:">
                  <TaskStatusChip size="small" status={pickupTask.status.id} label={pickupTask.status.name} />
                </TypographyWithLabel>
              </Grid>

              {failureMessage && (
                <Grid item xs={12}>
                  <TypographyWithLabel label="เหตุผล:" color="primary">
                    {failureMessage}
                  </TypographyWithLabel>
                  <Space size="small" />
                </Grid>
              )}

              {pickupTask?.state.failureMessages?.remark && (
                <Grid item xs={12}>
                  <TypographyWithLabel label="หมายเหตุ:" color="primary">
                    {pickupTask?.state.failureMessages?.remark}
                  </TypographyWithLabel>
                </Grid>
              )}

              {pickupTask?.state.shippingResult?.remark && (
                <Grid item xs={12}>
                  <TypographyWithLabel label="หมายเหตุ:" color="primary">
                    {pickupTask?.state.shippingResult?.remark}
                  </TypographyWithLabel>
                </Grid>
              )}
            </Grid>

            <Space size="small" />
            <Divider />
          </>
        )}

        <Space />

        {(task.state?.items.filter((item) => item.type === ProductType.Product) ?? []).length > 0 && (
          <>
            <FlexBox justifyContent="space-between">
              <span className="font-bold">รายการสินค้า</span>
            </FlexBox>
            <Space size="small" />
            {task.state?.items
              .filter((item) => item.type === ProductType.Product)
              .map((product) => (
                <>
                  <div
                    className="transport-shipping-daily-summary-products__product-item"
                    key={'product-' + product.id}
                  >
                    <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                      <Photo />
                    </Avatar>
                    <div className="transport-shipping-daily-summary-products__product-item__info">
                      <FlexBox justifyContent="space-between">
                        <Typography color="textSecondary">{product.code}</Typography>
                        <Typography>
                          {product.amount} {product.unit ?? 'อัน'}
                        </Typography>
                      </FlexBox>
                      <Typography color="textSecondary">{product.name}</Typography>
                    </div>
                  </div>
                  <Space size="small" />
                  <Divider />
                </>
              ))}
          </>
        )}
        {/* {(task.state?.items.filter((item) => item.type === ProductType.Service) ?? []).length > 0 && (
          <>
            <Space />
            <FlexBox justifyContent="space-between">
              <span className="font-bold">รายการเอกสาร</span>
            </FlexBox>
            <Space size="small" />
            {task.state?.items
              .filter((item) => item.type === ProductType.Service)
              .map((document) => (
                <>
                  <FlexBox justifyContent="space-between">
                    <Typography color="textSecondary">
                      {document.name} {document.code}
                    </Typography>
                    <Typography>{document.amount} ฉบับ</Typography>
                  </FlexBox>

                  <Space size="small" />
                  <Divider />
                </>
              ))}
          </>
        )} */}
      </Card>
    </>
  )
}
