import { Box, Typography } from '@material-ui/core'
import { differenceInDays } from 'date-fns'

import { dateFormat } from '../functions'
import { formatNumberInt } from '../functions/number-utils'

export const displayDateWithDiffDay = (base: Date, conditionDate: Date): JSX.Element => {
  const diffDays = differenceInDays(base, conditionDate)
  if (diffDays > 0) {
    return (
      <Typography variant="body2">
        {dateFormat(base)} <Box color="error.main">(ล่าช้า {formatNumberInt(diffDays)} วัน)</Box>
      </Typography>
    )
  } else if (diffDays < 0) {
    return (
      <Typography variant="body2">
        {dateFormat(base)} <Box color="success.main">(ล่วงหน้า {formatNumberInt(Math.abs(diffDays))} วัน)</Box>
      </Typography>
    )
  } else {
    return <Typography variant="body2">{dateFormat(base)}</Typography>
  }
}

export const isDateBetween = (target: Date, from: Date, to: Date) => {
  return target >= from && target <= to
}

export const getPreviousDate = (startDate: Date, previousDays: number): Date => {
  const current = startDate
  return new Date(new Date().setDate(current.getDate() - previousDays))
}
