import React, { useContext, useEffect, useState } from 'react'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Avatar,
  TableRow,
  TableCell,
  Divider,
  MenuItem,
} from '@material-ui/core'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  TypographyWithLabel,
  Table,
  Grid,
  KeyboardDatePickerForm,
} from '../../../custom-components'
import { Space, SubmitForm } from '../../../shared-components'
import { useForm } from 'react-hook-form'
import {
  BaseData,
  CreatedInfo,
  PurchaseOrderProfile_Product,
  uploadPurchaseOrderDocument,
  UploadPurchaseOrderDocumentRequestBody,
} from '../../../api/smartbestbuys-api'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import { Photo } from '@material-ui/icons'
import { UploadCard } from '../../purchase-requisitions/create/AttachmentSelectionBox/UploadCard'
import { FileType, SourceFile } from '../../../types/SourceFile'
import FileCard from '../../purchase-requisitions/create/AttachmentSelectionBox/FileCard'
import { Alert } from '@material-ui/lab'
import { dateFormat, dateSqlFormat } from '../../../functions'
import { add } from 'date-fns'
import { DocumentType, PaymentRequisitionStatus } from '../../../enums'
import { formatNumber, roundNumber } from '../../../functions/number-utils'
import './PurchaseOrderProfileUploadDocumentDialog.less'

export interface DocumentProps extends DialogProps {
  onComplete: (data: CreatedInfo) => void
  onFail: () => void
}

interface DocumentFormValues {
  documentTypeId: number
  base64: string
  referenceNumber: string
  total: number
  referenceDate: Date
}

// TODO: move to constants
const paidStatuses = [
  PaymentRequisitionStatus.PendingReceipt,
  PaymentRequisitionStatus.PendingStatement,
  PaymentRequisitionStatus.Success,
]

// document types which can upload as purchase order document
const documentTypes: BaseData[] = [
  { id: 101, name: 'ใบแจ้งหนี้' },
  { id: 102, name: 'ใบกำกับภาษี' },
]

const PurchaseOrderProfileUploadDocumentDialog: React.FC<DocumentProps> = (props) => {
  // set up props & context & state
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const [sourceFile, setSourceFile] = useState<SourceFile | undefined>(undefined)
  const { id, products, total, vatIncluded, discountAmount, paymentCondition, documents, vendorInfo, payments } =
    purchaseOrderProfile
  const { creditPeriodDay } = vendorInfo
  const { onClose, onComplete, onFail, ...otherProps } = props

  const {
    control,
    setValue,
    reset,
    register,
    unregister,
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
  } = useForm<DocumentFormValues>({})

  const { documentTypeId, referenceDate } = watch()

  //calculation
  const exactDiscountAmount = discountAmount ?? 0

  const receivedProductTotal = products.reduce<number>((sum, product) => {
    const productTotal = Math.min(product.receivedAmount, product.amount) * product.price
    return sum + productTotal
  }, 0)

  const receivedProductTotalWithDiscount = receivedProductTotal - exactDiscountAmount
  const receivedProductTotalWithDiscountWithVat = vatIncluded
    ? roundNumber(receivedProductTotalWithDiscount * 1.07)
    : receivedProductTotalWithDiscount
  const priceBreakdowns = [
    { label: 'รวมเงิน', value: vatIncluded ? receivedProductTotal : 0 },
    { label: 'ส่วนลด', value: exactDiscountAmount },
    { label: 'ภาษีมูลค่าเพิ่ม 7 %', value: receivedProductTotalWithDiscountWithVat - receivedProductTotalWithDiscount },
    { label: 'ยอดเงินสุทธิที่รับของแล้ว', value: receivedProductTotalWithDiscountWithVat },
  ]

  const totalDocument = !!documents
    ? documents.reduce<number>((sum, document) => {
        return sum + document.total
      }, 0)
    : 0

  const paidTotal =
    payments?.reduce<number>((sum, payment) => {
      if (paidStatuses.includes(payment.status.id)) return sum + payment.total
      return sum
    }, 0) ?? 0

  const remainingTotal = receivedProductTotalWithDiscountWithVat - totalDocument

  useEffect(() => {
    register('base64', {
      required: 'กรุณาอัพโหลดรูปภาพ',
    })

    return () => {
      unregister('base64')
    }
  }, [register, unregister])

  // handler
  const handleSubmitForm = async (values: DocumentFormValues) => {
    const { documentTypeId, base64, referenceNumber, total, referenceDate } = values
    const request: UploadPurchaseOrderDocumentRequestBody = {
      documentTypeId,
      base64,
      referenceNumber,
      total,
      referenceDate: dateSqlFormat(referenceDate),
    }

    const response = await uploadPurchaseOrderDocument(id, request)
    if (response?.status === 201) {
      onComplete(response.data)
      reset({})
      setSourceFile(undefined)
    } else {
      onFail()
    }
    return true
  }

  const handleInvalid = async () => {}

  return (
    <Dialog {...otherProps} maxWidth="md">
      <Form onSubmit={handleSubmit(handleSubmitForm, handleInvalid)}>
        <DialogTitle>เพิ่มเอกสารทางการเงิน</DialogTitle>
        <DialogContent>
          <FlexBox justifyContent="space-between">
            <Typography>ข้อมูลสินค้า/บริการ</Typography>
            <TypographyWithLabel label="เงื่อนไขการสั่งซื้อ">{paymentCondition.name}</TypographyWithLabel>
          </FlexBox>
          <div className="mb-4" />
          <Table<PurchaseOrderProfile_Product>
            color="primary.dark"
            columns={[
              { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
              {
                name: 'ชื่อสินค้า/บริการ',
                dataIndex: 'name',
                render: (product) => (
                  <div className="purchase-order-products-table__product-cell" key={'product-' + product.id}>
                    <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                      <Photo />
                    </Avatar>
                    <div className="purchase-order-products-table__product-cell__info">
                      <Typography color="textSecondary">{product.code}</Typography>
                      <Typography>{product.name}</Typography>
                    </div>
                  </div>
                ),
              },
              {
                name: 'จำนวนรับจริง/จำนวนทั้งหมด',
                render: (product) => {
                  return product.receivedAmount + '/' + product.amount
                },
              },
              {
                name: 'ราคารวมของจำนวนที่รับ',
                render: (product) => {
                  return formatNumber(Math.min(product.receivedAmount, product.amount) * product.price)
                },
                numeric: true,
                format: '0,0.00',
              },
            ]}
            data={products}
            postCells={
              <>
                {priceBreakdowns
                  .filter((item) => item.value > 0)
                  .map((item) => (
                    <TableRow>
                      <TableCell colSpan={2} />
                      <TableCell align="right">{item.label}</TableCell>
                      <TableCell align="right">{formatNumber(item.value)}</TableCell>
                    </TableRow>
                  ))}
              </>
            }
            pagination={false}
            cleanSpace
          />
          <div className="mb-12" />
          <Divider />
          <Grid container id="document-dialog-form">
            <Grid item xs={12} md={4}>
              <Typography>ข้อมูลจากใบสั่งซื้อ</Typography>
              <Space />
              <TypographyWithLabel label="เงื่อนไขการซื้อ:">{paymentCondition.name}</TypographyWithLabel>
              <Space />
              <TypographyWithLabel label="ยอดที่จ่ายแล้ว:">{formatNumber(paidTotal)}</TypographyWithLabel>
              <Space />
              <TypographyWithLabel label="ยอดทั้งหมด:">{formatNumber(total)}</TypographyWithLabel>
              <Space />
              <TypographyWithLabel label="ยอดเงินสุทธิที่รับของแล้ว:">
                {formatNumber(receivedProductTotal)}
              </TypographyWithLabel>
              <Space />
              <TypographyWithLabel label="ยอดที่เพิ่มเอกสารแล้ว:">{formatNumber(totalDocument)}</TypographyWithLabel>
              <Space />
              <TypographyWithLabel label="ยอดที่ขาด (จากที่รับของแล้ว):">
                {formatNumber(remainingTotal)}
              </TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>ข้อมูลที่ต้องกรอก</Typography>
              <Grid container id="document-dialog-form__layout">
                <Grid item xs={12} md={3}>
                  {!!sourceFile ? (
                    <FileCard
                      value={sourceFile}
                      onRemove={() => {
                        setSourceFile(undefined)
                        setValue('base64', '')
                      }}
                    />
                  ) : (
                    <UploadCard
                      onChange={(image) => {
                        const sourceFile: SourceFile = {
                          base64: image.base64!,
                          name: image.base64!,
                          type: image.name.slice(-4) === '.pdf' ? FileType.PDF : FileType.Image,
                        }
                        setSourceFile(sourceFile)
                        setValue('base64', image.base64!)
                      }}
                    />
                  )}
                  {errors?.base64 && (
                    <Grid item xs={12}>
                      <Alert severity="error">{errors?.base64?.message}</Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container id="document-dialog-form__layout__detail">
                    <Grid item xs={12} md={6} className="md:pr-3">
                      <GeneralTextFieldForm
                        control={control}
                        name="documentTypeId"
                        select
                        label="ชื่อเรียกเอกสาร"
                        rules={{ required: { value: true, message: 'กรุณาเลือกประเภทเอกสาร' } }}
                        variant="outlined"
                        defaultValue=""
                      >
                        {documentTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </GeneralTextFieldForm>
                    </Grid>
                    <Grid item xs={12} md={6} className="md:pr-3">
                      <GeneralTextFieldForm
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'กรุณาระบุเลขที่เอกสาร',
                          },
                        }}
                        label="เลขที่เอกสาร"
                        name="referenceNumber"
                        defaultValue=""
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="md:pr-3">
                      <GeneralTextFieldForm
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'กรุณาระบุจำนวนเงิน',
                          },
                          max:
                            documentTypeId !== DocumentType.Invoice
                              ? {
                                  value: remainingTotal,
                                  message: 'ต้องตรงกับยอดที่ขาด',
                                }
                              : undefined,
                          min:
                            documentTypeId !== DocumentType.Invoice
                              ? {
                                  value: remainingTotal,
                                  message: 'ต้องตรงกับยอดที่ขาด',
                                }
                              : undefined,
                        }}
                        type="number"
                        label="จำนวนเงิน"
                        name="total"
                        defaultValue=""
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="md:pr-3 pt-2">
                      <KeyboardDatePickerForm
                        control={control}
                        label="วันที่ออกเอกสาร"
                        name="referenceDate"
                        value={null}
                        size="small"
                        rules={{ required: { value: true, message: 'กรุณาเลือกวันที่ออกเอกสาร' } }}
                        defaultValue={null}
                        helperText={renderPaymentDueDataText(documentTypeId, referenceDate, creditPeriodDay)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText="ยืนยันเพิ่มเอกสาร"
            cancelText="ยกเลิก"
            onCancel={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default PurchaseOrderProfileUploadDocumentDialog

const renderPaymentDueDataText = (
  documentType?: DocumentType,
  documentDate?: Date,
  creditDay?: number,
): string | undefined => {
  if (!documentType || !documentDate) return undefined
  if (!!creditDay && creditDay > 0 && documentType !== DocumentType.Invoice)
    return `ต้องชำระภายใน ${dateFormat(add(documentDate, { days: creditDay }), 'dd MMMM yyyy')}`
  return `ต้องชำระภายใน ${dateFormat(documentDate, 'dd MMMM yyyy')}`
}
