import { useContext } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { Loading } from '../../../../../shared-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { SubTopic, Table } from '../../../../../custom-components'
import { SaleOrderActivity } from '../../../../../api/smartbestbuys-api'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'

const SOUserActivities: React.FC = (props) => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)

  if (!saleOrderProfile) {
    return <Loading />
  }

  const { activities } = saleOrderProfile

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>ประวัติการจัดการใบสั่งขาย</SubTopic>
        </Grid>
        <Grid item xs={12}>
          <Table<SaleOrderActivity>
            color="secondary.dark"
            size="small"
            columns={[
              { name: 'การกระทำ', dataIndex: 'actionName' },
              { name: 'รายละเอียดเพิ่มเติม', dataIndex: 'actionMessage' },
              { name: 'จัดการโดย', dataIndex: 'createdBy' },
              {
                name: 'วันที่',
                render: (ua) => format(new Date(ua.createdAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
              },
              { name: 'หมายเหตุ', dataIndex: 'remark' },
            ]}
            data={activities || []}
            rowsPerPage={5}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SOUserActivities
