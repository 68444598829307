import React, { useState, useContext, useEffect } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, Link, FlexBox } from '../../../custom-components'
import { DocumentCancellationDialog, DocumentStatusChip } from '../../../shared-components'
import { defaultStartDate, defaultEndDate } from '../../../data'
import { useDocumentBillingNotes } from '../../../hooks'
import { dateTimeFormat } from '../../../functions/date-formatter'
import { RootContext } from '../../..'
import { DocumentStatus, PageName } from '../../../enums'
import { BillingNote } from '../../../api/smartbestbuys-api'
import { Cancel } from '@material-ui/icons'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const BillingNoteListPage: React.FC = (_) => {
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.BILLING_NOTE)

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [idToCancelId, setIdToCancel] = useState<number | undefined>(undefined)
  const billingNotes = useDocumentBillingNotes(startDate, endDate)

  const [data, setData] = useState<BillingNote[]>([])

  useEffect(() => {
    if (billingNotes.length > 0) {
      setData(billingNotes)
    }
  }, [billingNotes])

  // permission
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>รายการใบวางบิล</Topic>
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<BillingNote>
          color="primary.dark"
          columns={[
            {
              name: 'สถานะใบวางบิล',
              render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
            },
            {
              name: 'เลขใบวางบิล',
              render: ({ id, code }) => (
                <Link color="primary" to={`/billing-notes/${id}/pdf`} target="_blank">
                  {code}
                </Link>
              ),
            },
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
            },
            {
              name: 'ชื่อลูกค้า',
              render: ({ customerInfo: { name } }) => name,
            },
            {
              name: 'ยอดเงินสุทธิ',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
            },
            {
              name: '',
              render: (taxInvoice) => {
                // TODO: support issued credit note and credit note dialog
                const isCancelled = taxInvoice.status.id === DocumentStatus.Cancelled
                return isCancelled ? (
                  <></>
                ) : (
                  <>
                    <DocumentCancellationDialog
                      documentInfo={taxInvoice}
                      open={idToCancelId === taxInvoice.id}
                      onClose={() => {
                        setIdToCancel(undefined)
                      }}
                      onSuccess={(updatedData) => {
                        triggerSnackbar('ยกเลิกใบวางบิลสำเร็จ')
                        let newData = data
                        const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                        if (findIndex !== -1) {
                          const found = newData[findIndex]
                          const updated = {
                            ...found,
                            ...updatedData,
                          }
                          newData[findIndex] = updated
                          setData([...newData])
                        }
                      }}
                      onFail={() => {
                        triggerSnackbar('ยกเลิกใบวางบิลสำเร็จ')
                      }}
                    />
                    {cancelPermission.hasPermission && (
                      <FlexBox gridGap={8}>
                        <IconButton size="small" onClick={() => setIdToCancel(taxInvoice.id)}>
                          <Cancel fontSize="small" />
                        </IconButton>
                      </FlexBox>
                    )}
                  </>
                )
              },
            },
          ]}
          data={data}
          defaultDateRage={{
            startDate: defaultStartDate,
            endDate: defaultEndDate,
          }}
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(BillingNoteListPage)
