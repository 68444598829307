import { Avatar, Chip, Grid, Paper, Typography, IconButton } from '@material-ui/core'
import {
  People,
  Business,
  Create,
  Description,
  DesktopWindows,
  Email,
  LocalPhone,
  PermIdentity,
} from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { CustomButton, FlexBox, SubTopic, Topic, TypographyWithLabel } from '../../../custom-components'
import CustomerProfileContext from '../../../pages/customers/id/profile/CustomerProfileContext'
import { CustomerUpdationDialog } from '../../../pages/customers/id/profile/CustomerProfilePage/CustomerUpdationDialog'
import { CustomerTierChip } from '../../StatusChip'
import { useStyles } from '../style'
import { BlackListSignal, ConditionTooltip, ImageIcon } from '../../../shared-components'
import { AddCustomerBlacklistDialog } from '../../../pages/customers/id/profile/CustomerProfilePage/AddCustomerBlacklist'
import { AddCustomerWhitelistDialog } from '../../../pages/customers/id/profile/CustomerProfilePage/AddCustomerWhitelist'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import dealerLogo from '../../../assets/images/dealer-logo.png'

const CustomerProfileContent: React.FC = () => {
  const [openCustomerUpdationDialog, setOpenCustomerUpdationDialog] = useState(false)
  const [openAddBlacklistDialog, setOpenAddBlacklistDialog] = useState(false)
  const [openAddWhitelistDialog, setOpenAddWhitelistDialog] = useState(false)
  const {
    customerProfile: {
      id,
      code,
      nameLocal,
      customerType,
      creditPeriodDay,
      referenceNumber,
      websiteUrl,
      phoneNumber,
      email,
      businessType,
      isBlacklist,
      blacklistRemark,
      tier,
      isDealer,
      additionalData,
      recDeletedAt,
      saleUser,
    },
    reload,
  } = useContext(CustomerProfileContext)

  const isActiveCustomer = !recDeletedAt
  const isCredit = (creditPeriodDay || 0) > 0
  const classes = useStyles(customerType.id, isCredit)()

  const blacklistManagePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_BlacklistManage)
  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Write)
  const updatePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Update)

  const hasUpdatePermission = updatePermission.hasPermission || writePermission.hasPermission

  const updatePermissionAlertMessage = updatePermission.hasPermission
    ? updatePermission.alertMessage
    : writePermission.alertMessage

  const disableUpdate = !hasUpdatePermission || !isActiveCustomer
  const disableBlackListManage = !blacklistManagePermission.hasPermission || !isActiveCustomer

  function triggerSnackbar(arg0: string) {
    throw new Error('Function not implemented.')
  }

  return (
    <Paper className={classes.root}>
      <CustomerUpdationDialog
        open={openCustomerUpdationDialog}
        onClose={() => {
          setOpenCustomerUpdationDialog(false)
        }}
      />
      <AddCustomerBlacklistDialog
        customerId={id}
        open={openAddBlacklistDialog}
        onClose={() => setOpenAddBlacklistDialog(false)}
        onComplete={() => {
          setOpenAddBlacklistDialog(false)
          reload()
        }}
        onFail={() => {
          triggerSnackbar(`เพิ่มเข้าบัญชีดำไม่สำเร็จ`)
        }}
      />
      <AddCustomerWhitelistDialog
        customerId={id}
        open={openAddWhitelistDialog}
        onClose={() => setOpenAddWhitelistDialog(false)}
        onComplete={() => {
          setOpenAddWhitelistDialog(false)
          reload()
        }}
        onFail={() => {
          triggerSnackbar(`ลบจากบัญชีดำไม่สำเร็จ`)
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <Avatar alt="Customer Avatar" className={classes.avatar}></Avatar>
              {isCredit && (
                <Typography className={classes.creditBadge} variant="body2" component="div">
                  ลูกค้าเครดิต <br /> {creditPeriodDay} วัน
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FlexBox gridGap={16} alignItems="center" height={32}>
                <Typography variant="h6" color="textSecondary" component="span">
                  {code}
                </Typography>
                {tier && <CustomerTierChip tier={tier} size="small" />}
                <Chip label={customerType?.name} size="small" className={classes.chip} />
                {isBlacklist && (
                  <>
                    <BlackListSignal />
                    <Typography noWrap={true} style={{ overflow: 'visible' }}>
                      {blacklistRemark}
                    </Typography>
                  </>
                )}
                {isDealer && <ImageIcon name="dealer" src={dealerLogo} />}
              </FlexBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <FlexBox gridGap={16} justifyContent="flex-end" height={32}>
                {isBlacklist ? (
                  <ConditionTooltip
                    title={
                      !blacklistManagePermission.hasPermission
                        ? blacklistManagePermission.alertMessage
                        : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                    }
                    showTooltip={disableBlackListManage}
                  >
                    <CustomButton
                      variant="contained"
                      classes={{ label: 'px-4 py-1 leading-none' }}
                      color="black"
                      onClick={() => setOpenAddWhitelistDialog(true)}
                      disabled={disableBlackListManage}
                    >
                      ลบจากบัญชีดำ
                    </CustomButton>
                  </ConditionTooltip>
                ) : (
                  <ConditionTooltip
                    title={
                      !blacklistManagePermission.hasPermission
                        ? blacklistManagePermission.alertMessage
                        : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                    }
                    showTooltip={disableBlackListManage}
                  >
                    <CustomButton
                      variant="contained"
                      classes={{ label: 'px-4 py-1 leading-none' }}
                      color="black"
                      onClick={() => setOpenAddBlacklistDialog(true)}
                      disabled={disableBlackListManage}
                    >
                      <FlexBox gridGap={5} alignItems="center">
                        <BlackListSignal />
                        เพิ่มเข้าบัญชีดำ
                      </FlexBox>
                    </CustomButton>
                  </ConditionTooltip>
                )}
                <ConditionTooltip
                  title={!hasUpdatePermission ? updatePermissionAlertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'}
                  showTooltip={disableUpdate}
                >
                  <IconButton
                    size="small"
                    color="primary"
                    disabled={disableUpdate}
                    onClick={() => setOpenCustomerUpdationDialog(true)}
                  >
                    <Create />
                  </IconButton>
                </ConditionTooltip>
              </FlexBox>
            </Grid>
            <Grid item xs={12}>
              <Topic>{nameLocal}</Topic>
            </Grid>
            {referenceNumber && (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel icon={<PermIdentity fontSize="small" />} label="เลขประจำตัวผู้เสียภาษี:">
                  {referenceNumber}
                </TypographyWithLabel>
              </Grid>
            )}
            {phoneNumber && (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel icon={<LocalPhone fontSize="small" />} label="เบอร์โทร:">
                  {phoneNumber}
                </TypographyWithLabel>
              </Grid>
            )}
            {email && (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel icon={<Email fontSize="small" />} label="อีเมล:">
                  {email}
                </TypographyWithLabel>
              </Grid>
            )}
            {websiteUrl && (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel icon={<DesktopWindows fontSize="small" />} label="เว็บไซต์:">
                  {websiteUrl}
                </TypographyWithLabel>
              </Grid>
            )}
            {businessType && (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel icon={<Business fontSize="small" />} label="ประเภทธุรกิจ:">
                  {businessType.name}
                </TypographyWithLabel>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TypographyWithLabel icon={<People fontSize="small" />} label="เซลล์ผู้ดูแล:">
                {saleUser
                  ? saleUser.fullName.concat(saleUser.nickname ? ` (${saleUser.nickname})` : '')
                  : 'ยังไม่มีใครดู'}
              </TypographyWithLabel>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SubTopic>รายละเอียดเพิ่มเติม</SubTopic>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel icon={<Description fontSize="small" />} label="เอกสารที่ใช้ตอนส่งสินค้า:">
                {additionalData?.shippingDocumentRequireList || '-'}
              </TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel icon={<Description fontSize="small" />} label="ระเบียบการวางบิล:">
                {additionalData?.billingTermAndCondition || '-'}
              </TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel icon={<Description fontSize="small" />} label="ระเบียบการรับเงิน:">
                {additionalData?.paymentTermAndCondition || '-'}
              </TypographyWithLabel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerProfileContent
