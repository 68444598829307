import React, { useState, useContext, ChangeEvent } from 'react'
import { RootContext } from '../../../../..'
import { BreadcumbsList, FlexBox, Grid, withLayout } from '../../../../../custom-components'
import { PageName, QuotationStatus, SaleOrderStatus, TabEnums } from '../../../../../enums'
import { useCustomerProfile } from '../../../../../hooks'
import { ConditionTooltip, CustomerProfileContent, Loading } from '../../../../../shared-components'
import CustomerProfileContext from '../CustomerProfileContext'

import { CustomerBillingAddressBox } from './CustomerBillingAddressBox'
import { CustomerShippingAddressBox } from './CustomerShippingAddressBox'
import { CustomerContactList } from './CustomerContactList'
import { CustomerDocumentBox } from './CustomerDocumentBox'
import { CustomerPurchaseLogBox } from './CustomerPurchaseLogBox'
import { Button, Chip, Paper, Tab, Tabs, createStyles, makeStyles } from '@material-ui/core'
import { CustomerTransportationBox } from './CustomerTransportationBox'
import { CustomerBankAccountBox } from './CustomerBankAccountBox'
import TabPanel from '../../../../../shared-components/TabPanel'
import { CustomerDebtSaleOrder } from './CustomerDebtSaleOrder'
import { CustomerRefundRequisition } from './CustomerRefundRequisition'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'
import { Delete, RestoreFromTrash } from '@material-ui/icons'
import CustomerDeleteDialog from './CustomerDeleteDialog'
import CustomerRestoreDialog from './CustomerRestoreDialog'

interface QueryParams {
  triggerCreateBillingAddress?: boolean
}

interface Props {
  query: QueryParams
  id: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      backgroundColor: theme.palette.primary.light,
    },
  }),
)

const CutomerProfilePage: React.FC<Props> = ({ id, query }) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const { triggerCreateBillingAddress } = query
  const [currentTab, setCurrentTab] = useState(TabEnums.Contract)
  const [deleteCustomerOpen, setDeleteCustomerOpen] = useState(false)
  const [restoreCustomerOpen, setRestoreCustomerOpen] = useState(false)

  const classes = useStyles()

  setCurrentPage(PageName.CUSTOMER)

  const [reload, setReload] = useState(false)

  const customerProfile = useCustomerProfile(id, reload)

  if (!customerProfile) return <Loading />

  const breadcumbsItems = [{ url: '/customers', name: 'รายการลูกค้า' }, { name: `${customerProfile.nameLocal}` }]

  const tabLabelStyle = { padding: '6px 0px', minWidth: '120px' }

  // find sale orders that is already refunded
  let refendedSaleOrders: any[] = []
  customerProfile.refundRequisitions?.forEach((refund) => {
    const saleOrders = refund.saleOrders

    refendedSaleOrders = [...refendedSaleOrders, ...saleOrders]
  })

  // filter dept sale orders
  const debtSaleOrders = customerProfile.debtSaleOrders?.filter((dept) => {
    const refundedTotal = refendedSaleOrders
      .filter((so) => so.id === dept.id)
      .reduce<number>((sum, each) => sum + each.refundAmount, 0)

    return refundedTotal ? dept.debtTotal > refundedTotal : true
  })

  const customerDebtCount = debtSaleOrders?.length ?? 0

  const customerRefundCount = customerProfile.refundRequisitions?.length ?? 0

  const handleChange = (event: ChangeEvent<{}>, newValue: TabEnums) => {
    setCurrentTab(newValue)
  }

  const quotationProcessing = (customerProfile.quotations ?? []).filter((qt) =>
    [QuotationStatus.Pending, QuotationStatus.PendingReview].includes(qt.status.id),
  )

  const saleOrderProcessing = (customerProfile.saleOrders ?? []).filter(
    (so) => ![SaleOrderStatus.Closed, SaleOrderStatus.ClosedAtShop, SaleOrderStatus.Cancelled].includes(so.status.id),
  )

  const deletePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Delete)

  const isShowDeleteButton = deletePermission.hasPermission && !customerProfile.recDeletedAt
  const isShowRestoreButton = deletePermission.hasPermission && customerProfile.recDeletedAt

  const ableToDelete = quotationProcessing.length > 0 && saleOrderProcessing.length > 0

  const DeleteButton = () => (
    <ConditionTooltip
      showTooltip={ableToDelete}
      title={`ลูกค้าคนนี้ มีใบเสนอราคา หรือ ใบสั่งขายที่ กำลังดำเนินการอยู่`}
    >
      <Button
        color="primary"
        variant="outlined"
        startIcon={<Delete />}
        onClick={() => setDeleteCustomerOpen(true)}
        disabled={ableToDelete}
      >
        ยกเลิกการขาย
      </Button>
    </ConditionTooltip>
  )

  const RestoreButton = () => (
    <Button
      color="primary"
      variant="outlined"
      startIcon={<RestoreFromTrash />}
      onClick={() => setRestoreCustomerOpen(true)}
    >
      นำกลับมาขาย
    </Button>
  )

  return (
    <CustomerProfileContext.Provider
      value={{
        customerProfile,
        reload: () => setReload((prev) => !prev),
      }}
    >
      {isShowDeleteButton && (
        <CustomerDeleteDialog
          customerId={id}
          open={deleteCustomerOpen}
          onClose={() => setDeleteCustomerOpen(false)}
          onComplete={() => {
            triggerSnackbar(`ยกเลิกลูกค้า สำเร็จ`)
            setReload((prev) => !prev)
            setDeleteCustomerOpen(false)
          }}
          onFail={() => {
            triggerSnackbar(`ยกเลิกลูกค้า ไม่สำเร็จ`)
          }}
        />
      )}
      {isShowRestoreButton && (
        <CustomerRestoreDialog
          customerId={id}
          open={restoreCustomerOpen}
          onClose={() => setRestoreCustomerOpen(false)}
          onComplete={() => {
            triggerSnackbar(`นำลูกค้ากลับมา สำเร็จ`)
            setReload((prev) => !prev)
            setRestoreCustomerOpen(false)
          }}
          onFail={() => {
            triggerSnackbar(`นำลูกค้ากลับมา ไม่สำเร็จ`)
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <BreadcumbsList items={breadcumbsItems} />
              {customerProfile.recDeletedAt && (
                <span className="ml-2">
                  <Chip size="small" label="ยกเลิกใช้งานลูกค้านี้" className={classes.chip} />
                </span>
              )}
            </FlexBox>
            {deletePermission.hasPermission && (
              <span className="ml-2">{!!customerProfile.recDeletedAt ? <RestoreButton /> : <DeleteButton />}</span>
            )}
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <CustomerProfileContent />
        </Grid>
        <Grid item xs={12}>
          <Paper square style={{ padding: 0 }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
            >
              <Tab label="ผู้ติดต่อ" value={TabEnums.Contract} style={tabLabelStyle} />
              <Tab label="ที่อยู่" value={TabEnums.Address} style={tabLabelStyle} />
              <Tab label="ประวัติการซื้อของลูกค้า" value={TabEnums.PurchaseLog} style={tabLabelStyle} />
              <Tab label="เอกสารของลูกค้า" value={TabEnums.CustomerDocument} style={tabLabelStyle} />
              <Tab label="ประวัติงานขนส่ง" value={TabEnums.Transportation} style={tabLabelStyle} />
              <Tab label="บัญชีธนาคาร" value={TabEnums.BackAccount} style={tabLabelStyle} />
              <Tab label={`รายการหนี้ (${customerDebtCount})`} value={TabEnums.Debt} style={tabLabelStyle} />
              <Tab label={`รายการคืนเงิน (${customerRefundCount})`} value={TabEnums.Refund} style={tabLabelStyle} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={currentTab} index={TabEnums.Contract}>
            <CustomerContactList />
          </TabPanel>
          <TabPanel value={currentTab} index={TabEnums.Address}>
            <Grid container spacing={2}>
              <Grid item>
                <CustomerBillingAddressBox triggerCreateBillingAddress={triggerCreateBillingAddress} />
              </Grid>
              <Grid item>
                <CustomerShippingAddressBox />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={currentTab} index={TabEnums.PurchaseLog}>
            <CustomerPurchaseLogBox />
          </TabPanel>
          <TabPanel value={currentTab} index={TabEnums.CustomerDocument}>
            <CustomerDocumentBox />
          </TabPanel>
          <TabPanel value={currentTab} index={TabEnums.Transportation}>
            <CustomerTransportationBox />
          </TabPanel>
          <TabPanel value={currentTab} index={TabEnums.BackAccount}>
            <CustomerBankAccountBox />
          </TabPanel>
          <TabPanel value={currentTab} index={TabEnums.Debt}>
            <CustomerDebtSaleOrder customerProfile={customerProfile} />
          </TabPanel>
          <TabPanel value={currentTab} index={TabEnums.Refund}>
            <CustomerRefundRequisition customerProfile={customerProfile} />
          </TabPanel>
        </Grid>
      </Grid>
    </CustomerProfileContext.Provider>
  )
}

export default withLayout(CutomerProfilePage)
