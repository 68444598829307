import { FormControlLabel, Paper, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { CheckboxForm, FlexBox } from '../../../custom-components'
import { calculateVatAmount } from '../../../functions'
import { formatNumber } from '../../../functions/number-utils'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'

const PurchaseOrderVatBox: React.FC = () => {
  // hook form
  const { control, watch } = useFormContext<PurchaseOrderCreateFormValues>()

  // data
  const { vatIncluded, products, discountAmount, vendor } = watch()
  const currency = vendor?.currency

  const total = products.reduce<number>((sum, product) => {
    return sum + product.price * product.amount
  }, 0)

  return (
    <Paper>
      <FlexBox justifyContent="space-between">
        <FormControlLabel
          control={<CheckboxForm size="small" control={control} name="vatIncluded" checked={!!vatIncluded} />}
          label={<Typography variant="h5">ภาษีมูลค่าเพิ่ม 7 %</Typography>}
        />

        {vatIncluded && (
          <Typography variant="h6">
            ภาษีมูลค่าเพิ่ม {formatNumber(calculateVatAmount(total - (discountAmount ?? 0)))} {currency?.name ?? 'บาท'}
          </Typography>
        )}
      </FlexBox>
    </Paper>
  )
}

export default PurchaseOrderVatBox
