import { createCustomerAPI } from '../../../api/smartbestbuys-api'
import CustomerCreationFormValue from './CustomerCreationFormValues'

export const createCustomerService = (values: CustomerCreationFormValue) => {
  const requestForm = {
    customerTypeId: values.customerTypeId,
    businessTypeId: values?.typeOfBusiness,
    nameLocal: values.thaiName,
    nameEn: values?.englishName,
    referenceNumber: values?.numberOfTaxpayer,
    phoneNumber: values.phoneNumber,
    websiteUrl: values?.websiteAddress,
    creditPeriodDay: values.hasCreditCustomer ? values?.creditPeriodDay : undefined,
    email: values?.emailAddress,
    isDealer: values.isDealer,
    shippingDocumentRequireList: values?.shippingDocumentRequireList,
    billingTermAndCondition: values?.billingTermAndCondition,
    paymentTermAndCondition: values?.paymentTermAndCondition,
  }

  return createCustomerAPI(requestForm)
}
