import { Button, Grid, Menu, MenuItem, Paper } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ICustomerProfile_Contact } from '../../../../../../../api/smartbestbuys-api'
import { FlexBox, Title, TypographyWithLabel } from '../../../../../../../custom-components'
import { getGenderByValue } from '../../../../../../../data'
import { PermissionEnum } from '../../../../../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../../../../../shared-components'
import { getUserPermissionInfo } from '../../../../../../../utils/permission'
import { CustomerContactUpdationDialog } from '../CustomerContactDialog'

interface Props {
  customerId: number
  contact: ICustomerProfile_Contact
  isActiveCustomer: boolean
}

const CustomerContactCard: React.FC<Props> = (props) => {
  const history = useHistory()

  const { customerId, contact, isActiveCustomer } = props

  const { id, nicknameLocal, gender, customerDepartment, phoneNumber, email, line, facebook } = contact

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [updateCustomerContact, setUpdateCustomerContact] = useState(false)

  const writeQTPermission = getUserPermissionInfo(PermissionEnum.Sales_Quotation_Write)

  const updateCustomerPermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Update)
  const deleteCustomerPermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Delete)

  const disableQuotationWrite = !writeQTPermission.hasPermission || !isActiveCustomer
  const disableCustomerUpdate = !updateCustomerPermission.hasPermission || !isActiveCustomer
  const disableCustomerDelete = !deleteCustomerPermission.hasPermission || !isActiveCustomer

  return (
    <>
      <CustomerContactUpdationDialog
        contact={contact}
        open={updateCustomerContact}
        onClose={() => {
          setUpdateCustomerContact(false)
        }}
      />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Title>{nicknameLocal}</Title>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexBox justifyContent="flex-end" gridGap={16}>
              <ConditionTooltip
                title={
                  !writeQTPermission.hasPermission
                    ? writeQTPermission.alertMessage
                    : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                }
                showTooltip={disableQuotationWrite}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push(`/quotations/create?customerId=${customerId}&contactId=${id}`)}
                  disabled={disableQuotationWrite}
                >
                  + ใบเสนอราคา
                </Button>
              </ConditionTooltip>

              <Button
                variant="outlined"
                aria-controls="contact-optional-menu"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MoreHoriz />
              </Button>
              <Menu
                id="contact-optional-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
              >
                <ConditionTooltip
                  title={
                    !updateCustomerPermission.hasPermission
                      ? updateCustomerPermission.alertMessage
                      : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                  }
                  showTooltip={disableCustomerUpdate}
                >
                  <MenuItem
                    disabled={disableCustomerUpdate}
                    onClick={() => {
                      setAnchorEl(null)
                      setUpdateCustomerContact(true)
                    }}
                  >
                    แก้ไขผู้ติดต่อ
                  </MenuItem>
                </ConditionTooltip>
                <ConditionTooltip
                  title={
                    !deleteCustomerPermission.hasPermission
                      ? deleteCustomerPermission.alertMessage
                      : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                  }
                  showTooltip={disableCustomerDelete}
                >
                  <MenuItem onClick={() => setAnchorEl(null)} disabled={disableCustomerDelete}>
                    ลบผู้ติดต่อ
                  </MenuItem>
                </ConditionTooltip>
              </Menu>
            </FlexBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="เพศ:">{getGenderByValue(gender) || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ตำแหน่ง:">{customerDepartment?.name || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="เบอร์โทรศัพท์:">{phoneNumber || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="อีเมล:">{email || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ไลน์:">{line || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="เฟชบุ๊ค:">{facebook || '-'}</TypographyWithLabel>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerContactCard
