import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { DocumentStatus } from '../../enums'

interface Props {
  status: DocumentStatus
  icon?: boolean
}

const useStyles = (status: DocumentStatus) =>
  makeStyles((theme) => {
    const chipColors = {
      [DocumentStatus.Pending]: theme.palette.warning.light,
      [DocumentStatus.Sent]: theme.palette.success.light,
      [DocumentStatus.Cancelled]: theme.palette.error.light,
    }

    const iconColors = {
      [DocumentStatus.Pending]: theme.palette.warning.darkText,
      [DocumentStatus.Sent]: theme.palette.success.darkText,
      [DocumentStatus.Cancelled]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: chipColors[status],
      },
      icon: {
        color: iconColors[status],
      },
    })
  })

const DocumentStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ children, status, icon, ...othersProps }) => {
  const classes = useStyles(status)()

  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      {...othersProps}
    />
  )
}

export default DocumentStatusChip
