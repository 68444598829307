/* eslint-disable react-hooks/exhaustive-deps */
import {
  ITransportTaskTableData_SearchParams,
  ITransportTaskTableData_Summary,
  TransportTask,
  TransportTaskTableDataSearchParams,
  getTransportTaskTableDataAPI,
} from '../../api/smartbestbuys-api'
import { useTableData } from '../useTablaData'
import { dateSqlFormat } from '../../functions'
import { TransportTaskStatus } from '../../enums'

const _buildQueryParams = (_s: ITransportTaskTableData_SearchParams): TransportTaskTableDataSearchParams => {
  return {
    code: _s.code,
    saleOrderCode: _s.saleOrderCode,
    customerName: _s.customerName,
    deliverDate: _s.deliverDate ? dateSqlFormat(_s.deliverDate, 'yyyy-MM-dd') : undefined,
    statusIds: _s?.statusIds?.join(','),
  }
}

export const useTransportTaskTableData = () =>
  useTableData<
    TransportTask,
    ITransportTaskTableData_Summary,
    ITransportTaskTableData_SearchParams,
    TransportTaskTableDataSearchParams
  >(
    0,
    10,
    {
      code: undefined,
      saleOrderCode: undefined,
      deliverDate: undefined,
      customerName: undefined,
      statusIds: [TransportTaskStatus.TransportPending],
    },
    getTransportTaskTableDataAPI,
    _buildQueryParams,
  )
