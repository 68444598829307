import { Box, Grid, IconButton, Paper } from '@material-ui/core'
import { Edit, LocalPhoneTwoTone } from '@material-ui/icons'
import { useState } from 'react'
import { VendorContactInfo } from '../../../../api/smartbestbuys-api'
import { EmptyCard, FlexBox, Title, TypographyWithLabel } from '../../../../custom-components'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { ConditionTooltip, Space } from '../../../../shared-components'
import { getUserPermissionInfo } from '../../../../utils/permission'
import VendorContactCreateFormDialog from './VendorContactCreateFormDialog'

interface Props {
  vendorId: number
  contactInfo?: VendorContactInfo
  onUpdate?: () => void
}

const VendorContactBox = (props: Props) => {
  const { vendorId, contactInfo, onUpdate } = props

  // state
  const [dialogOpen, setDialogOpen] = useState(false)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_Vendor_Write)

  return (
    <>
      <VendorContactCreateFormDialog
        vendorId={vendorId}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onComplete={() => {
          if (!!onUpdate) onUpdate()
          setDialogOpen(false)
        }}
      />
      <Box p={3}>
        <FlexBox justifyContent="space-between">
          <Title>ข้อมูลผู้ติดต่อ</Title>
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <IconButton onClick={() => setDialogOpen(true)} disabled={!writePermission.hasPermission}>
              <Edit color={writePermission.hasPermission ? 'secondary' : 'disabled'} />
            </IconButton>
          </ConditionTooltip>
        </FlexBox>
        <Space />
        <Box p={3} clone>
          {contactInfo ? (
            <Paper variant="outlined">
              <Grid container>
                <Grid item xs={6}>
                  <Title>{contactInfo.name}</Title>
                </Grid>
                <Grid item xs={6}>
                  <TypographyWithLabel icon={<LocalPhoneTwoTone />} label="เบอร์โทร">
                    {contactInfo.phoneNumber}
                  </TypographyWithLabel>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <EmptyCard>ไม่มีผู้ติดต่อ</EmptyCard>
          )}
        </Box>
      </Box>
    </>
  )
}

export default VendorContactBox
