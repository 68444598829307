import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import {
  IProductWithdrawalTicketProfile,
  approveProductWithdrawalTicketAPI,
} from '../../../../../api/smartbestbuys-api/warehouse'

interface Props extends DialogProps {
  profile: IProductWithdrawalTicketProfile
  onSuccess: (updatedProfile: IProductWithdrawalTicketProfile) => void
  onFail: () => void
}

const PWTicketApproveDialog: React.FC<Props> = (props) => {
  const { profile, onSuccess, onFail, onClose, ...dialogProps } = props

  const { id, code } = profile

  const handleClose = () => {
    onClose && onClose({}, 'escapeKeyDown')
  }

  const handleSubmit = async () => {
    const response = await approveProductWithdrawalTicketAPI(id)

    if (response?.status === 200) {
      onSuccess(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} fullWidth>
      <DialogTitle>อนุมัติคำขอเบิกสินค้าเลขที่ {code}</DialogTitle>
      <DialogContent>
        ต้องการ "อนุมัติ" ใช่หรือไม่ ? <br />
        <span style={{ color: 'red' }}>หลังจากอนุมัติคำขอเบิกจะไม่สามารถแก้ไขได้</span>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary" size="large">
          ย้อนกลับ
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary" size="large">
          อนุมัติ
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PWTicketApproveDialog
