import { useState, useEffect } from 'react'
import { PurchaseOrderProfile } from '../../api/smartbestbuys-api/purchase-order/types'
import { getPurchaseOrderProfileAPI } from '../../api/smartbestbuys-api/purchase-order/apis'

export const usePurchaseOrderProfile = (id: number, reload?: boolean) => {
  const [data, setData] = useState<PurchaseOrderProfile | undefined>(undefined)

  const _getPurchaseOrderProfieAPI = async (id: number) => {
    const response = await getPurchaseOrderProfileAPI(id)
    setData(response?.data)
  }

  useEffect(() => {
    _getPurchaseOrderProfieAPI(id)
  }, [id, reload])

  return data
}
