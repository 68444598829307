import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export interface CustomerContactCreationRequest {
  nameLocal?: string
  nameEn?: string
  nicknameLocal: string
  nicknameEn?: string
  customerDepartmentId?: number
  phoneNumber: string
  email?: string
  line?: string
  facebook?: string
  birthdate?: Date
  gender?: string
}

export interface CreatedCustomerContact {
  id: number
  customerId: number
  nameLocal?: string
  nameEn?: string
  nicknameLocal: string
  nicknameEn?: string
  positionName?: string
  phoneNumber: string
  email?: string
  line?: string
  facebook?: string
  birthdate?: Date
  gender?: string
  recCreatedAt: Date
  recCreatedBy: string
}

export const createCustomerContactAPI = errorSingleHandler<CreatedCustomerContact>(
  (id: number, request: CustomerContactCreationRequest) => {
    const path = `/customers/${id}/create-contact`
    return smartAuthAPI.post(path, request)
  },
)
