import { useEffect, useState } from 'react'
import { getUserDataAPI } from '../../api/smartbestbuys-api/data/data-apis'
import { UserData } from '../../api/smartbestbuys-api/data/data-types'

export const useUserData = (): UserData[] => {
  const [value, setValue] = useState<UserData[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await getUserDataAPI()
      if (response?.status === 200) {
        setValue(response?.data)
      }
    })()
  }, [])

  return value
}
