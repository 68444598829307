import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = (hasVendor: boolean) => {
  return makeStyles(() => {
    return createStyles({
      box: {
        height: 75,
        border: '1px solid #0000003B',
        borderRadius: '4px',
        padding: '8px',
        justifyContent: hasVendor ? 'center' : 'start',
      },
      avatar: {
        width: 59,
        height: 59,
      },
      vendorName: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width: '100%',
      },
      vendorNameBox: {
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      },
      vendorCountry: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      actions: {
        height: '100%',
      },
      closeIcon: {
        color: '#0000008A',
      },
      editIcon: {
        color: '#FFC400',
      },
      noVendor: {
        color: '#0000008A',
      },
    })
  })
}
