import { BaseData, UserLogin, UserPermission, UserProfile } from '../api/smartbestbuys-api'
import { INotification, Notification } from '../api/smartbestbuys-api/general/general-types'
import { version } from '../../package.json'
import { UserAction } from '../enums'

export const SESSION_KEY_PREFIX = 'smartbestbuys-auth-token-'
export const SESSION_KEY = `${SESSION_KEY_PREFIX}${version}`

export const getToken = () => window.localStorage.getItem(SESSION_KEY)

export const setToken = (token: string) => window.localStorage.setItem(SESSION_KEY, token)

export const setUser = (user: UserLogin) => window.localStorage.setItem('user', JSON.stringify(user))

export const getUser = (): UserLogin | undefined => {
  const userString = window.localStorage.getItem('user')
  return !!userString ? JSON.parse(userString) : undefined
}

export const setProfileToLocalStorage = (profile: UserProfile) =>
  window.localStorage.setItem('profile', JSON.stringify(profile))

export const getProfileFromLocalStorage = (): UserProfile | undefined => {
  const profileString = window.localStorage.getItem('profile')
  return !!profileString && profileString !== undefined ? JSON.parse(profileString) : undefined
}

export const checkSession = () => getToken()

export const clearSession = () => {
  window.localStorage.clear()
  window.location.href = '/login'
}

export const setPermission = (permission: UserPermission) => {
  window.localStorage.setItem('permission', JSON.stringify(permission))
}

export const getPermission = () => {
  const permission = window.localStorage.getItem('permission')
  return permission ? JSON.parse(permission) : undefined
}

export const saveLocalNotification = (notification: Notification) => {
  window.localStorage.setItem('notification', notification.toJsonString())
}

export const loadNotification = (): Notification => {
  const notificationData = window.localStorage.getItem('notification')
  if (!notificationData) return new Notification()

  return new Notification(JSON.parse(notificationData) as INotification)
}

export const getActionMessagesFromLocalStorage = (actionId: UserAction): BaseData[] => {
  const actionMessages = window.localStorage.getItem(`action-${actionId}`)
  return actionMessages ? JSON.parse(actionMessages) : undefined
}

export const setActionMessageToLocalStorage = (actionId: UserAction, actionMessages: BaseData[]) => {
  window.localStorage.setItem(`action-${actionId}`, JSON.stringify(actionMessages))
}
