import { useEffect, useState } from 'react'
import { getNotificationAPI } from '../api/smartbestbuys-api/general/general-apis'
import { Notification } from '../api/smartbestbuys-api/general/general-types'
import { saveLocalNotification } from '../functions'

export const useNotification = (): Notification => {
  const [notification, setNotification] = useState<Notification>(new Notification())

  useEffect(() => {
    ;(async () => {
      const response = await getNotificationAPI()
      if (response?.data) {
        const _notification = new Notification(response.data)
        setNotification(_notification)
        saveLocalNotification(_notification)
      }
    })()
  }, [])

  return notification
}
