import { ShippingMethod } from '../../../../types'
import { ICustomerProfile_Address } from '../../customer'
import { ShippingProviderData, ShippingCarData, UserData, AddressData, ShippingProvider } from '../../data/data-types'
import { TaskAssignee } from '../../tasks'
import {
  IShippingInfo,
  ITransportTaskUpdateDataV2,
  TransportTaskCreateDataV2_CustomerRequisitionProduct,
  TransportTaskProfile,
  TransportTaskStateV2,
  TransportTaskUpdateDataV2_CustomerRequisitionInfo,
  TransportTaskUpdateDataV2_SaleOrderInfo,
  TransportTaskUpdateData_Document,
  TransportTaskUpdateData_RelatedDocument,
  TransportTaskUpdateData_RelatedTask,
} from '../transport-types'

export class TransportTaskUpdateDataV2 {
  public profile: TransportTaskProfile
  public saleOrderInfo: TransportTaskUpdateDataV2_SaleOrderInfo
  public customerRequisitionInfo: TransportTaskUpdateDataV2_CustomerRequisitionInfo
  public documents: TransportTaskUpdateData_Document[]
  public relatedTasks: TransportTaskUpdateData_RelatedTask[]
  public relatedDocuments: TransportTaskUpdateData_RelatedDocument[]
  public customerShippingAddresses: ICustomerProfile_Address[]
  public customerBillingAddresses: ICustomerProfile_Address[]
  public shippingProviders: ShippingProviderData[]
  public shippingCars: ShippingCarData[]
  public users: UserData[]
  public address: AddressData

  constructor(_data: ITransportTaskUpdateDataV2) {
    this.profile = _data.profile
    this.saleOrderInfo = _data.saleOrderInfo ?? ({} as TransportTaskUpdateDataV2_SaleOrderInfo)
    this.customerRequisitionInfo =
      _data.customerRequisitionInfo ?? ({} as TransportTaskUpdateDataV2_CustomerRequisitionInfo)
    this.documents = _data.documents
    this.relatedTasks = _data.relatedTasks
    this.relatedDocuments = _data.relatedDocuments
    this.customerShippingAddresses = _data.customerShippingAddresses
    this.customerBillingAddresses = _data.customerBillingAddresses
    this.shippingProviders = _data.shippingProviders
    this.shippingCars = _data.shippingCars
    this.users = _data.users
    this.address = _data.address
  }

  get transportTaskState(): TransportTaskStateV2 {
    return this.profile.state!
  }

  get shippingInfo(): IShippingInfo {
    return this.transportTaskState.shippingInfo
  }

  get selectedProducts(): Array<
    TransportTaskCreateDataV2_CustomerRequisitionProduct & { selectedAmount: number | undefined }
  > {
    return this.customerRequisitionInfo.products.map((product) => ({
      id: product.id,
      imageUrl: product.imageUrl,
      code: product.code,
      nameLocal: product.nameLocal,
      nameEn: product.nameEn,
      amount: product.amount,
      shippedAmount: product.shippedAmount,
      shippingAmount: product.shippingAmount,
      selectedAmount: this.transportTaskState.items.map((item) => item.id).includes(product.id)
        ? product.amount
        : undefined,
      receivedAmount: product.receivedAmount,
    }))
  }

  get items(): TransportTaskCreateDataV2_CustomerRequisitionProduct[] {
    return this.customerRequisitionInfo.products
  }

  get shippingMethod(): ShippingMethod {
    if (!this.shippingInfo.useShippingProvider) return ShippingMethod.Smart
    if (!this.shippingInfo.shippingProvider) return ShippingMethod.None
    return ShippingMethod.Express
  }

  public setCustomerBillingAddresses = (_customerBillingAddresses: ICustomerProfile_Address[]) => {
    this.customerBillingAddresses = _customerBillingAddresses
  }

  get shippingProvider(): ShippingProvider | undefined {
    return this.shippingInfo.shippingProvider
  }

  get customerId(): number | undefined {
    return this.saleOrderInfo.customerId
  }

  get assignee(): TaskAssignee | undefined {
    return this.profile.assignee
  }

  get dueDate(): Date | undefined {
    return this.profile.dueDate
  }

  get consignee(): { name: string; phoneNumber: string } {
    return {
      name: this.shippingInfo.consignee,
      phoneNumber: this.shippingInfo.phoneNumber,
    }
  }
}
