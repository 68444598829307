import { useEffect, useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogProps,
  DialogActions,
  InputAdornment,
  MenuItem,
  Typography,
} from '@material-ui/core'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  KeyboardDateTimePickerForm,
  TypographyWithLabel,
} from '../../../custom-components'
import { PaymentMethod } from '../../../enums'
import { SubmitForm, UploadImageCard } from '../../../shared-components'
import { bankAccounts } from '../../../data'
import { VendorBankAccount } from '../../../api/smartbestbuys-api/vendor/vendor-types'
import { formatNumber } from '../../../functions/number-utils'
import {
  PaymentRequisitionProfile,
  PaymentRequisitionUploadPayslipRequestBody,
} from '../../../api/smartbestbuys-api/payment-requisitions/types'
import { uploadPaymentRequisitionPayslip } from '../../../api/smartbestbuys-api/payment-requisitions/apis'
import { RootContext } from '../../..'
import { BaseData } from '../../../api/smartbestbuys-api/types'

interface UploadPurchasePayslipFormValues {
  base64: string
  total: number
  bankAccountId: number
  payDate: string
  chequeNumber?: string
  branchName?: string
  remark?: string
}

// ref from PaymentRequisitionProfile
interface PaymentRequisitionInfo {
  id: number
  paymentMethod: BaseData
  vendorInfo: { bankAccount: VendorBankAccount }
  total: number
  withholdingTaxAmount?: number
}

interface UploadPurchasePayslipDialogProps extends DialogProps {
  paymentRequisitionInfo: PaymentRequisitionInfo
  onSuccess?: (paymentRequisitionProfile: PaymentRequisitionProfile) => void
  onFailed?: () => void
}

const UploadPurchasePayslipDialog = (props: UploadPurchasePayslipDialogProps) => {
  // root context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { paymentRequisitionInfo, onSuccess, onFailed, onClose, ...otherProps } = props

  // form hook
  const formMethod = useForm<UploadPurchasePayslipFormValues>()
  const {
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    register,
  } = formMethod

  // prepare data
  const {
    id,
    paymentMethod: { id: paymentMethodId },
    vendorInfo: { bankAccount: venderBankAccount },
    total,
    withholdingTaxAmount,
  } = paymentRequisitionInfo
  const { payDate } = watch()
  const isCheque = paymentMethodId === PaymentMethod.Cheque
  const title = isCheque ? 'ยืนยันการออกเช็ค' : 'ยืนยันการโอน'
  const payDateLabel = isCheque ? 'วันที่ออกเช็ค *' : 'วันที่ออกโอน *'
  const finalTotal = total - (withholdingTaxAmount ?? 0)

  // handle
  const handleSubmit = async (values: UploadPurchasePayslipFormValues) => {
    const requestBody: PaymentRequisitionUploadPayslipRequestBody = {
      base64: values.base64,
      total: values.total,
      payDate: new Date(values.payDate),
      chequeNumber: values.chequeNumber,
      branchName: values.branchName,
      remark: values.remark,
      bankAccount: bankAccounts.find((bankAccount) => bankAccount.id === values.bankAccountId)!,
    }

    const response = await uploadPaymentRequisitionPayslip(id, requestBody)
    if (response?.status === 200) {
      triggerSnackbar('ยินยันการจ่ายเงินสำเร็จ')
      if (!!onSuccess) onSuccess(response.data)
    } else {
      triggerSnackbar('ยินยันการจ่ายเงินไม่สำเร็จ')
      if (!!onFailed) onFailed()
    }
    return true
  }

  // effect
  useEffect(() => {
    register('base64', { required: { value: true, message: 'กรุณาแนบรูปหลักฐาน' } })
  }, [register])

  return (
    <FormProvider {...formMethod}>
      <Dialog {...otherProps} fullWidth maxWidth="sm">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="จำนวนเงินที่ต้องจ่าย:">{formatNumber(finalTotal)}</TypographyWithLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="บัญชีธนาคาร:">
                  {`${venderBankAccount.bank.name} ${venderBankAccount.accountNumber}`}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={12}>
                <FlexBox justifyContent="center">
                  <UploadImageCard
                    previewOnChange
                    title="อัปโหลดรูปหลักฐาน *"
                    onChange={(image) => setValue('base64', image.base64)}
                    error={!!errors.base64}
                    message={errors.base64?.message}
                  />
                </FlexBox>
              </Grid>
              {isCheque && (
                <Grid item xs={12} md={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="chequeNumber"
                    label="เลขที่เช็ค *"
                    rules={{
                      required: 'กรุณากรอกเลขที่เช็ค',
                    }}
                    error={!!errors.chequeNumber}
                    helperText={!!errors.chequeNumber && errors.chequeNumber.message}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="total"
                  label="จำนวนเงิน *"
                  type="number"
                  inputProps={{
                    step: '0.01',
                  }}
                  rules={{
                    required: 'กรุณากรอกจำนวนเงิน',
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                  }}
                  error={!!errors.total}
                  helperText={!!errors.total && errors.total.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="bankAccountId"
                  label="ธนาคารที่ใช้โอน *"
                  rules={{
                    required: 'กรุณาเลือกธนาคารที่ใช้โอน',
                  }}
                  select
                  error={!!errors.bankAccountId}
                  helperText={!!errors.bankAccountId && errors.bankAccountId.message}
                >
                  {bankAccounts.map((c, index) => (
                    <MenuItem key={index} value={c.id}>
                      <FlexBox flexDirection="column">
                        <div>{c.bankName}</div>
                        <div>{c.name}</div>
                        <Typography color="primary">{c.number}</Typography>
                      </FlexBox>
                    </MenuItem>
                  ))}
                </GeneralTextFieldForm>
              </Grid>
              {isCheque && (
                <Grid item xs={12} md={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="branchName"
                    label="สาขา *"
                    rules={{
                      required: 'กรุณากรอกสาขา',
                    }}
                    error={!!errors.branchName}
                    helperText={!!errors.branchName && errors.branchName.message}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <KeyboardDateTimePickerForm
                  control={control}
                  label={payDateLabel}
                  name="payDate"
                  value={payDate}
                  defaultValue={null}
                  size="small"
                  gutterTop
                  fullWidth
                  rules={{
                    required: 'กรุณาระบุวันเวลา',
                  }}
                  error={!!errors.payDate}
                  helperText={errors.payDate?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitForm
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  )
}
export default UploadPurchasePayslipDialog
