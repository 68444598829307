import { SubDistrict, District, Province } from '../../data/data-types'
import { BaseMultiLangData } from '../../types'
import { ICustomerShippingAddress } from '../customer-types'

export class CustomerShippingAddress {
  public id: number
  public customerId: number
  public name: string
  public branchName?: string
  public addressLine1: string
  public phoneNumber: string
  public googleMapLink?: string
  public subDistrict?: SubDistrict
  public district?: District
  public province?: Province
  public postalCode?: string

  constructor(result: ICustomerShippingAddress) {
    this.id = result.id
    this.customerId = result.customerId
    this.name = result.name
    this.branchName = result.branchName
    this.addressLine1 = result.addressLine1
    this.phoneNumber = result.phoneNumber
    this.googleMapLink = result.googleMapLink
    this.province = result.province
    this.district = result.district
    this.subDistrict = result.subDistrict
    this.postalCode = result.postalCode
  }

  toString = () => {
    const { branchName, addressLine1, subDistrict, district, province, postalCode } = this
    const addressInfo = [
      branchName,
      addressLine1,
      subDistrict
        ? province
          ? _mapBangkokPrefix(province, 'แขวง', 'ตำบล') + subDistrict.nameLocal
          : subDistrict.nameLocal
        : undefined,
      district
        ? province
          ? _mapBangkokPrefix(province, 'เขต', 'อำเภอ') + district.nameLocal
          : district.nameLocal
        : undefined,
      province ? _mapBangkokPrefix(province, '', 'จังหวัด') + province?.nameLocal : undefined,
      postalCode,
    ]
    return addressInfo.filter((a) => a).join(' ')
  }
}

const _mapBangkokPrefix = (province: BaseMultiLangData, str: string, str2: string) => (province.id === 1 ? str : str2)
