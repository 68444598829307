import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import { Close, Photo, Print } from '@material-ui/icons'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  TransportShipingTaskMobileData_Document,
  TransportShipingTaskMobileData_Product,
} from '../../../api/smartbestbuys-api'
import {
  Avatar,
  BreadcumbsList,
  CustomButton,
  FlexBox,
  Link,
  SubTopic,
  Table,
  TypographyWithLabel,
  withLayout,
} from '../../../custom-components'
import { TaskStatus, TaskType } from '../../../enums'
import { formatNumber, formatNumberInt } from '../../../functions/number-utils'
import { useTransportShippingTask } from '../../../hooks/transport'
import {
  ConditionTooltip,
  Loading,
  PrepareDocumentIcon,
  PrepareProductIcon,
  Space,
  TaskStatusChip,
  TypographyLink,
} from '../../../shared-components'
import ConfirmCancelDialog from './ConfirmCancelDialog'
import ConfirmShippingFailureDialog from './ConfirmShippingFailureDialog'
import ConfirmShippingResultDialog from './ConfirmShippingResultDialog'
import ConfirmTaskDialog from './ConfirmTaskDialog'

interface Props {
  id: number
}

const ShippingTaskPage = (props: Props) => {
  const { id } = props

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [finish1Open, setFinish1Open] = useState(false)
  const [finish2Open, setFinish2Open] = useState(false)
  const history = useHistory()

  const [shippingTask, setShippingTask] = useTransportShippingTask(id)

  if (!shippingTask) return <Loading />

  const breadcumbsItems = [
    { url: '/transport/messenger-tasks', name: 'รายการงานส่งของ' },
    { name: `งานส่งของ ${shippingTask.code}` },
  ]

  const ableToConfirmTask = shippingTask.status.id === TaskStatus.Pending
  const ableToFinishTask = shippingTask.status.id === TaskStatus.Processing
  const ableToConfirmCancel = shippingTask.status.id === TaskStatus.PreCancel

  const showFailureMessage = [TaskStatus.Failed, TaskStatus.PreCancel, TaskStatus.Canceled].includes(
    shippingTask.status.id,
  )

  return (
    <>
      {ableToConfirmTask && (
        <ConfirmTaskDialog
          open={confirmOpen}
          data={{ id: shippingTask.id }}
          onSuccess={(data) => {
            setShippingTask((prev) => ({
              ...prev,
              ...data,
            }))
            setConfirmOpen(false)
          }}
          onClose={() => setConfirmOpen(false)}
        />
      )}
      {ableToConfirmCancel && (
        <ConfirmCancelDialog
          open={confirmOpen}
          data={{ id: shippingTask.id }}
          onSuccess={(data) => {
            setShippingTask((prev) => ({
              ...prev,
              ...data,
            }))
            setConfirmOpen(false)
          }}
          onClose={() => setConfirmOpen(false)}
        />
      )}
      {ableToFinishTask && (
        <>
          <FinishTaskSelectionDialog
            onSuccess={() => {
              setFinish1Open(true)
            }}
            onFail={() => {
              setFinish2Open(true)
            }}
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
          />
          <ConfirmShippingResultDialog
            open={finish1Open}
            data={{
              id: shippingTask.id,
              shippingInfo: {
                useShippingProvider: !!shippingTask.shippingInfo.shippingProvider,
                chargeAmount: shippingTask.shippingInfo.chargeAmount,
                shippingProvider: shippingTask.shippingInfo.shippingProvider,
                payShippingFeeBySmart: shippingTask.shippingInfo.payShippingFeeBySmart,
              },
            }}
            onSuccess={(data) => {
              setShippingTask((prev) => ({
                ...prev,
                ...data,
              }))
              setFinish1Open(false)
              setConfirmOpen(false)
            }}
            onClose={() => setFinish1Open(false)}
          />
          <ConfirmShippingFailureDialog
            open={finish2Open}
            data={{
              id: shippingTask.id,
            }}
            onSuccess={(data) => {
              setShippingTask(data)
              setFinish2Open(false)
              setConfirmOpen(false)
            }}
            onClose={() => setFinish2Open(false)}
          />
        </>
      )}
      <Box p={3} clone>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <BreadcumbsList items={breadcumbsItems} />
          </Grid>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <FlexBox gridGap={8}>
                <TaskStatusChip label={shippingTask.status.name} status={shippingTask.status.id} />
                {showFailureMessage ? (
                  <FlexBox alignItems="center">
                    <Typography variant="body2" color="error">
                      เหตุผล: {shippingTask.result?.failureMessage}
                    </Typography>
                  </FlexBox>
                ) : (
                  shippingTask.preparationTaskStatuses?.map((pt) => {
                    const Icon =
                      pt.type.id === TaskType.WarehousePrepareProduct ? PrepareProductIcon : PrepareDocumentIcon
                    return (
                      <FlexBox alignItems="center" pl={1}>
                        {pt.success ? (
                          <Icon size="small" status={TaskStatus.Done} />
                        ) : (
                          <Icon size="small" status={undefined} />
                        )}
                        {pt.success ? (
                          <Box color="success.main" clone>
                            <Typography>งานเสร็จสิ้น</Typography>
                          </Box>
                        ) : (
                          <Typography color="textSecondary">ยังเตรียมไม่เสร็จ</Typography>
                        )}
                      </FlexBox>
                    )
                  })
                )}
              </FlexBox>

              <FlexBox>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push(`/transport-tasks/${shippingTask.transportTask.id}/shipping-cover-pdf`)}
                >
                  <Print />
                  <span className="ml-1">พิมพ์ใบปะหน้า</span>
                </Button>
                {ableToConfirmTask && (
                  <>
                    <Space direction="horizontal" />
                    <ConditionTooltip showTooltip={!shippingTask.readyToConfirm} title="เตรียมสินค้า / เอกสารยังไม่ครบ">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!shippingTask.readyToConfirm}
                        onClick={() => setConfirmOpen(true)}
                      >
                        ยืนยันรับงาน
                      </Button>
                    </ConditionTooltip>
                  </>
                )}
                {ableToFinishTask && (
                  <>
                    <Space direction="horizontal" />
                    <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>
                      จบงาน
                    </Button>
                  </>
                )}
                {ableToConfirmCancel && (
                  <>
                    <Space direction="horizontal" />
                    <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>
                      รับทราบการยกเลิกงาน
                    </Button>
                  </>
                )}
              </FlexBox>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <FlexBox p={3} clone justifyContent="space-between" alignItems="center">
              <Paper>
                <SubTopic>งานจัดส่งอ้างอิง {shippingTask.transportTask.code}</SubTopic>
                <Link color="primary" to={`/transport-tasks/${shippingTask.transportTask.id}/profile`} target="_blank">
                  ไปที่หน้ารายละเอียดงานขนส่ง
                </Link>
              </Paper>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <Box p={3} clone>
              <Paper>
                <SubTopic>รายละเอียดขนส่ง</SubTopic>
                <Space />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="ใบสั่งขายอ้างอิง:">
                      {shippingTask.saleOrderInfo?.saleOrderCode}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="ฝ่ายขายผู้รับผิดชอบ:">
                      {shippingTask.saleOrderInfo?.assignee}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="เงื่อนไขการชำระเงิน:">
                      {shippingTask.saleOrderInfo?.paymentCondition}
                    </TypographyWithLabel>
                  </Grid>
                  {!!shippingTask.shippingInfo.chargeAmount && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="ยอดเงินที่ต้องเก็บ:" color="primary">
                        {formatNumber(shippingTask.shippingInfo.chargeAmount)} บาท
                      </TypographyWithLabel>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <TypographyWithLabel label="ขนส่งที่เลือกใช้:">
                      {shippingTask.shippingInfo.shippingProvider ?? '-'}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="ผู้ส่งของ:">{shippingTask.assignee.name}</TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="หมายเหตุ:">{shippingTask.remark ?? '-'}</TypographyWithLabel>
                  </Grid>
                  {!!shippingTask.relatedPO && shippingTask.relatedPO.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body2">เลขที่ใบสั่งซื้อที่เกี่ยวข้อง</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {shippingTask.relatedPO.map((po, index, arr) => {
                          return (
                            <>
                              <TypographyLink
                                variant="body2"
                                href={`/purchase-orders/${po.id}/profile`}
                                target="_blank"
                              >
                                {po.code}
                              </TypographyLink>
                              {index !== arr.length - 1 && (
                                <Typography variant="body2" color="textSecondary">
                                  {', '}
                                </Typography>
                              )}
                            </>
                          )
                        })}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Box p={3} clone>
                      <Paper variant="outlined">
                        <SubTopic>{shippingTask.saleOrderInfo?.customerName}</SubTopic>
                        <Space />
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TypographyWithLabel label="รายละเอียดที่อยู่:">
                              {shippingTask.shippingInfo.address}
                            </TypographyWithLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <TypographyWithLabel label="เบอร์โทร:">
                              {shippingTask.shippingInfo.consignee.phoneNumber}
                            </TypographyWithLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="overflow-hidden hover:overflow-unset">
                              <TypographyWithLabel label="ลิงก์ googlemap:">
                                {shippingTask.shippingInfo.googleMapLink ? (
                                  <TypographyLink
                                    target="_blank"
                                    href={shippingTask.shippingInfo.googleMapLink}
                                    color="secondary.main"
                                  >
                                    {shippingTask.shippingInfo.googleMapLink}
                                  </TypographyLink>
                                ) : (
                                  '-'
                                )}
                              </TypographyWithLabel>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ชื่อผู้รับ/ตำแหน่งงาน:">
                              {shippingTask.shippingInfo.consignee.name}
                            </TypographyWithLabel>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          {shippingTask.shippingInfo.products && (
            <Grid item xs={12}>
              <Box p={3} clone>
                <Paper>
                  <SubTopic>ข้อมูลสินค้าสำหรับขนส่ง</SubTopic>
                  <Space />
                  <Table<TransportShipingTaskMobileData_Product & { id: number }>
                    color="primary.dark"
                    columns={[
                      { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70 },
                      {
                        name: 'ชื่อสินค้า/บริการ',
                        render: ({ imageUrl, code, name }) => {
                          return (
                            <Box display="flex" alignItems="flex-start">
                              <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                                <Photo />
                              </Avatar>
                              <FlexBox ml={1} flexDirection="column">
                                <Typography variant="body2" color="textSecondary">
                                  {code}
                                </Typography>
                                <Typography variant="body2">{name}</Typography>
                              </FlexBox>
                            </Box>
                          )
                        },
                      },
                      {
                        name: 'จำนวนที่จะส่ง',
                        render: ({ amount, unit }) => `${formatNumberInt(amount)} ${unit ?? ''}`,
                        align: 'right',
                        fixedWidth: 200,
                      },
                    ]}
                    data={shippingTask.shippingInfo.products as any}
                    cleanSpace
                    pagination={false}
                    size="small"
                  />
                </Paper>
              </Box>
            </Grid>
          )}
          {shippingTask.shippingInfo.documents && (
            <Grid item xs={12}>
              <Box p={3} clone>
                <Paper>
                  <SubTopic>รายการเอกสารสำหรับขนส่ง</SubTopic>
                  <Space />
                  <Table<TransportShipingTaskMobileData_Document & { id: number }>
                    color="secondary.dark"
                    columns={[
                      { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70 },
                      {
                        name: 'ชื่อเอกสาร',
                        dataIndex: 'name',
                      },
                      {
                        name: 'จำนวนที่จะส่ง',
                        render: ({ amount }) => `${formatNumberInt(amount)} ใบ`,
                        align: 'right',
                        fixedWidth: 200,
                      },
                    ]}
                    data={shippingTask.shippingInfo.documents as any}
                    cleanSpace
                    pagination={false}
                    size="small"
                  />
                </Paper>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default withLayout(ShippingTaskPage)

interface FinishTaskSelectionDialogProps extends DialogProps {
  onSuccess: () => void
  onFail: () => void
}

const FinishTaskSelectionDialog = (props: FinishTaskSelectionDialogProps) => {
  const { onClose, onSuccess, onFail, ...otherProps } = props
  return (
    <Dialog {...otherProps} fullWidth maxWidth="sm">
      <DialogTitle>
        เลือกผลการจบงาน
        <IconButton
          onClick={() => {
            if (!!onClose) onClose({}, 'escapeKeyDown')
          }}
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FlexBox flexDirection="column">
          <CustomButton fullWidth color="success" onClick={onSuccess}>
            ส่งสำเร็จ
          </CustomButton>
          <Space />
          <CustomButton fullWidth color="error" onClick={onFail}>
            ส่งไม่สำเร็จ
          </CustomButton>
        </FlexBox>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}
