import { IconButton, Paper, Typography } from '@material-ui/core'
import { Cake, Edit, Email, LocalPhone, Photo } from '@material-ui/icons'
import { useState } from 'react'
import { Avatar, FlexBox, Grid, TypographyWithLabel } from '../../../custom-components'
import { DateTime } from 'luxon'
import { UserProfile } from '../../../api/smartbestbuys-api'
import { UserInformationUpdateDialog } from '../update'
import { useStyles } from './style'
import { ConditionTooltip } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
interface Props {
  userProfile: UserProfile
  onUpdate?: (user: UserProfile) => void
}

const UserInformationCard: React.FC<Props> = (props) => {
  const { userProfile, onUpdate } = props
  const [openInformationForm, setOpenInformationForm] = useState(false)

  const classes = useStyles()

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.HR_User_Write)

  return (
    <>
      <UserInformationUpdateDialog
        open={openInformationForm}
        user={userProfile}
        onComplete={(task) => {
          setOpenInformationForm(false)
          if (onUpdate) onUpdate(task)
        }}
        onClose={() => setOpenInformationForm(false)}
      />
      <Paper>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <Grid item xs={1}>
              <Avatar variant="rounded" src={userProfile.imageUrl} className={classes.avatar} objectFit="contain">
                <Photo />
              </Avatar>
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4">{userProfile.nickName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant="body2">
                    {userProfile.firstName} {userProfile.lastName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TypographyWithLabel icon={<Cake fontSize="small" />} label="วันเกิด:" variant="body2">
                    {userProfile.birthDay
                      ? DateTime.fromJSDate(new Date(userProfile.birthDay)).toFormat('d LLL yyyy')
                      : '-'}
                  </TypographyWithLabel>
                </Grid>
                <Grid item xs={12}>
                  <TypographyWithLabel icon={<Email fontSize="small" />} label="อีเมล:" variant="body2">
                    {userProfile.email ? userProfile.email : '-'}
                  </TypographyWithLabel>
                </Grid>
                <Grid item xs={12}>
                  <TypographyWithLabel icon={<LocalPhone fontSize="small" />} label="เบอร์โทร:" variant="body2">
                    {userProfile.phoneNumber ? userProfile.phoneNumber : '-'}
                  </TypographyWithLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
                <IconButton
                  size="small"
                  disabled={!writePermission.hasPermission}
                  onClick={() => {
                    setOpenInformationForm(true)
                  }}
                >
                  <Edit color={!writePermission.hasPermission ? 'disabled' : 'secondary'} />
                </IconButton>
              </ConditionTooltip>
            </Grid>
          </FlexBox>
        </Grid>
      </Paper>
    </>
  )
}

export default UserInformationCard
