import { DialogProps } from '@material-ui/core'

import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import { PaymentRequisitionProfile, rejectPaymentRequisition, UserActivityNote } from '../../../api/smartbestbuys-api'
import { useContext } from 'react'
import { RootContext } from '../../..'

interface PaymentRequisitionInfo {
  id: number
  code: string
}

interface Props extends DialogProps {
  paymentRequisitionInfo: PaymentRequisitionInfo
  onComplete?: (data: PaymentRequisitionProfile) => void
  onFail?: () => void
}

const PaymentRequisitionRejectDialog: React.FC<Props> = (props: Props) => {
  // set up props & state
  const { paymentRequisitionInfo, onComplete, onFail, ...dialogProps } = props

  //context
  const { triggerSnackbar } = useContext(RootContext)

  const textOption: UserActivityDialog_TextOption = {
    title: 'ไม่อนุมัติการทำเบิก',
    description: `ยืนยันไม่อนุมัติการทำเบิกเลขที่ ${paymentRequisitionInfo.code} ใช่หรือไม่?`,
    submitText: 'ยืนยันไม่อนุมัติ',
    cancelText: 'ยกเลิก',
    actionMessageText: 'สาเหตุของการไม่อนุมัติ',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { actionMessageId, actionMessage, remark } = values
    const requestBody: UserActivityNote = {
      actionMessageId: actionMessageId !== -1 ? actionMessageId : undefined,
      actionMessage,
      remark,
    }
    const response = await rejectPaymentRequisition(paymentRequisitionInfo.id, requestBody)
    if (response?.status === 200) {
      triggerSnackbar('ไม่อนุมัติการทำเบิกสำเร็จ')
      if (!!onComplete) onComplete(response.data)
    } else {
      triggerSnackbar('ไม่อนุมัติการทำเบิกไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.RejectPaymentRequisition}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default PaymentRequisitionRejectDialog
