import { Paper, Typography } from '@material-ui/core'
import { IRefundRequisitionProfile_SaleOrderPayment } from '../../../api/smartbestbuys-api'
import { Link, SubTopic, Table } from '../../../custom-components'
import { dateTimeFormat } from '../../../functions'
import { DocumentStatusChip } from '../../../shared-components'

interface Props {
  saleOrderPayments: IRefundRequisitionProfile_SaleOrderPayment[]
}

const RefundRequisitionSaleOrderPaymentTable: React.FC<Props> = (props: Props) => {
  // props and state
  const { saleOrderPayments } = props

  return (
    <Paper>
      <SubTopic>รายการชำระเงินจากใบสั่งขาย</SubTopic>
      <div className="mb-4" />
      <Table<IRefundRequisitionProfile_SaleOrderPayment>
        size="small"
        color="primary.dark"
        columns={[
          {
            name: 'สถานะการชำระเงิน',
            dataIndex: 'status',
            render: ({ status }) => {
              return <DocumentStatusChip status={status.id} label={status.name} />
            },
          },
          {
            name: 'เลขที่การชำระ',
            render: (record) => (
              //TODO : change Link
              <Link color="primary" to={`/finance/${record.id}/profile`}>
                <Typography variant="body2">{record.code}</Typography>
              </Link>
            ),
          },
          {
            name: 'เลขที่ใบสั่งขายอ้างอิง',
            render: (record) => (
              //TODO : change Link
              <Link color="secondary" to={`/finance/${record.id}/profile`}>
                <Typography variant="body2">{record.saleOrderCode}</Typography>
              </Link>
            ),
          },
          { name: 'วันที่บันทึก', render: (record) => dateTimeFormat(record.recCreatedAt) },
          {
            name: 'วิธีชำระเงิน',
            render: ({ paymentMethod }) => paymentMethod.name,
          },
          {
            name: 'ยอดชำระ',
            dataIndex: 'amount',
            numeric: true,
            format: '0,0.00',
          },
        ]}
        data={saleOrderPayments}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default RefundRequisitionSaleOrderPaymentTable
