import { useContext, useState } from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { PermissionGroupCreateDialog, PermissionGroupDialog } from '..'
import { RootContext } from '../../../..'
import { PermissionGroup } from '../../../../api/smartbestbuys-api/data/data-types'
import { FilteredTable, FlexBox, Grid, Topic, withLayout } from '../../../../custom-components'
import { PageName } from '../../../../enums'
import usePermissionData from '../../../../hooks/data/usePermissionData'
import { ConditionTooltip, Loading } from '../../../../shared-components'
import { getUserPermissionInfo } from '../../../../utils/permission'
import { PermissionEnum } from '../../../../enums/PermissionEnum'

const PermissionListPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PERMISSION)

  const [openPermissionGroupCreate, setOpenPermissionGroupCreate] = useState(false)

  const permissions = usePermissionData()

  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState<PermissionGroup | undefined>(undefined)

  if (!permissions) return <Loading />

  const writePermission = getUserPermissionInfo(PermissionEnum.HR_User_Write)

  return (
    <>
      {selectedPermissionGroup && (
        <PermissionGroupDialog
          open={true}
          onUpdate={(data) => {
            if (data) window.location.reload()
          }}
          onClose={() => {
            setSelectedPermissionGroup(undefined)
          }}
          permissionGroup={selectedPermissionGroup}
          permissionTopics={permissions.permissionTopics}
        />
      )}
      <PermissionGroupCreateDialog
        open={openPermissionGroupCreate}
        permissionTopics={permissions.permissionTopics}
        onComplete={() => {
          setOpenPermissionGroupCreate(false)
          window.location.reload()
        }}
        onClose={() => setOpenPermissionGroupCreate(false)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <Topic>การตั้งค่าสิทธิ์การใช้งาน</Topic>
            <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
              <Button
                variant="contained"
                color="primary"
                disabled={!writePermission.hasPermission}
                onClick={() => {
                  setOpenPermissionGroupCreate(true)
                }}
              >
                + เพิ่มกลุ่มสิทธิ์การใช้งาน
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<PermissionGroup>
              disableDateRangeFilter
              color="primary"
              columns={[
                {
                  name: 'ชื่อกลุ่มสิทธิ์',
                  render: (permissionGroup) => {
                    return (
                      <div style={{ width: 200 }}>
                        <Typography
                          variant="body2"
                          color="primary"
                          onClick={() => {
                            setSelectedPermissionGroup(permissionGroup)
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {permissionGroup.name}
                        </Typography>
                      </div>
                    )
                  },
                },
                {
                  name: 'รายละเอียดสิทธิ์การใช้งาน',
                  render: (permissionGroup) => (
                    <div style={{ maxWidth: 900, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography variant="body2">
                        {permissionGroup.permissions?.map((permission) => permission.name).join(', ')}
                      </Typography>
                    </div>
                  ),
                },
              ]}
              data={permissions.permissionGroups}
              rowsPerPage={10}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default withLayout(PermissionListPage)
