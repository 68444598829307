import Compressor from 'compressorjs'

export const compressImage = (file: File | Blob, quality: number): Promise<File | Blob> => {
  return new Promise((success, error) => {
    new Compressor(file, {
      quality,
      success,
      error,
    })
  })
}
