import React, { useContext, useState } from 'react'
import { Paper, Accordion, Card, Typography, AccordionSummary, AccordionDetails, Grid, Button } from '@material-ui/core'
import { Photo, LocalShipping } from '@material-ui/icons'
import { ExpandMore } from '@material-ui/icons'
import { Loading, MultipleConditionTooltip, TaskStatusChip, TypographyLink } from '../../../../../shared-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { FlexBox, SubTopic, Table, Avatar, TypographyWithLabel } from '../../../../../custom-components'
import { useStyles } from './styles'
import { dateFormat } from '../../../../../functions'
import { TransportTaskStateV2_Item } from '../../../../../api/smartbestbuys-api/transport/transport-types'
import { TransportTaskItemType } from '../../../../../enums/TransportTaskEnums'
import { formatNumberInt } from '../../../../../functions/number-utils'
import { SaleOrderProduct } from '../../../../../api/smartbestbuys-api'
import { useHistory } from 'react-router-dom'
import { SaleOrderStatus } from '../../../../../enums'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'

const shippingWhitelist = [SaleOrderStatus.PendingDelivery, SaleOrderStatus.Delivering]

const SOTasks: React.FC = (props) => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  const classes = useStyles()
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined)
  const history = useHistory()

  if (!saleOrderProfile) return <Loading />

  const { transportTasks, status } = saleOrderProfile

  const isCancelledSO = status.id === SaleOrderStatus.Cancelled

  const tasks = transportTasks ?? []
  const hasTasks = tasks.length > 0

  const hasSomePendingToShip = saleOrderProfile.products.some(
    (product) => product.amount > product.reservedAmount + product.shippedAmount,
  )

  // permission
  const writeTransportTaskPermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)

  // condition items
  const createTransportTaskConditionItems = [
    { showTooltip: !writeTransportTaskPermission.hasPermission, message: writeTransportTaskPermission.alertMessage },
    { showTooltip: isCancelledSO, message: 'ใบสั่งขายถูกยกเลิกแล้ว' },
    { showTooltip: !hasSomePendingToShip, message: 'ไม่มีสินค้ารอส่ง' },
  ]

  const isShowDeliveryButton = shippingWhitelist.includes(saleOrderProfile.status.id)

  return (
    <Paper>
      <FlexBox justifyContent="space-between" paddingBottom={2}>
        <SubTopic gutterBottom>การขนส่งของใบสั่งขายนี้</SubTopic>
        {isShowDeliveryButton && (
          <MultipleConditionTooltip conditionItems={createTransportTaskConditionItems}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<LocalShipping />}
              disabled={createTransportTaskConditionItems.some((item) => item.showTooltip)}
              onClick={() => history.push(`/transport-tasks/${saleOrderProfile.id}/create`)}
            >
              สร้างงานขนส่ง
            </Button>
          </MultipleConditionTooltip>
        )}
      </FlexBox>
      <Table<SaleOrderProduct>
        color="grey.800"
        size="small"
        columns={[
          {
            name: 'ลำดับ',
            render: (_, index) => index + 1,
          },
          {
            name: 'ชื่อสินค้า',
            render: (product) => (
              <div className="sale-order-products-table__product-cell" key={'product-' + product.id}>
                <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                  <Photo />
                </Avatar>
                <div className="sale-order-products-table__product-cell__info">
                  <Typography color="textSecondary">{product.code}</Typography>
                  <Typography>{product.nameLocal}</Typography>
                </div>
              </div>
            ),
          },
          {
            name: 'จำนวนที่ขายทั้งหมด',
            dataIndex: 'amount',
            format: '0,0',
          },
          {
            name: 'จำนวนที่ค้างส่ง',
            render: ({ amount, shippedAmount, reservedAmount }) => Math.max(amount - reservedAmount - shippedAmount, 0),
            format: '0,0',
          },

          { name: 'จำนวนที่กำลังส่ง', dataIndex: 'reservedAmount' },
          {
            name: 'จำนวนที่ส่งแล้ว',
            dataIndex: 'shippedAmount',
            format: '0,0',
          },
        ]}
        rowsPerPage={5}
        data={saleOrderProfile.products}
      ></Table>
      <FlexBox flexDirection="column">
        {hasTasks ? (
          tasks.map((task, index) => {
            const { id, code, status, dueDate, state, subTasks, nextShippingDate, failureShippingMessage } = task
            const isExpanded = activeIndex === index
            const handleExpand = () =>
              setActiveIndex((prev) => {
                if (isExpanded) return undefined
                return index
              })

            return (
              <Accordion
                square
                key={`task-${index}`}
                expanded={isExpanded}
                onChange={handleExpand}
                TransitionProps={{ unmountOnExit: true }}
                className={classes.accordion}
              >
                <AccordionSummary expandIcon={<ExpandMore />} classes={{ content: classes.accordionSummary }}>
                  <TypographyLink href={`/transport-tasks/${id}/profile`} target="_blank" variant="h5" color="default">
                    งานที่ {index + 1} {code}
                  </TypographyLink>
                  <TaskStatusChip status={status.id} label={status.name} />
                  <TypographyWithLabel variant="body2" label="วันที่ขนส่ง:" alignItems="center">
                    {dueDate ? dateFormat(dueDate) : 'ไม่ระบุ'}
                  </TypographyWithLabel>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {(nextShippingDate || failureShippingMessage) && (
                      <Grid item xs={12}>
                        {nextShippingDate && (
                          <Typography variant="body2" color="error">
                            วันที่ต้องส่งใหม่: {dateFormat(nextShippingDate)}
                          </Typography>
                        )}
                        {failureShippingMessage && (
                          <>
                            {nextShippingDate && <span className="ml-4" />}
                            <Typography variant="body2" color="error">
                              เหตุผลที่ยกเลิก: {failureShippingMessage}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    )}
                    {subTasks.map((t, index) => (
                      <Grid item xs={6} md={3} key={`subtask-${index}`}>
                        <TypographyWithLabel label={t.type.name} alignItems="center">
                          <TaskStatusChip label={t.status.name} status={t.status.id} size="small" />
                        </TypographyWithLabel>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <FlexBox flexDirection="column">
                        <Typography>รายการขนส่ง</Typography>
                        <span className="mb-1" />
                        <Table<TransportTaskStateV2_Item>
                          color="primary.dark"
                          cleanSpace
                          pagination={false}
                          size="small"
                          columns={[
                            {
                              name: 'รายการ',
                              render: ({ type, imageUrl, code, name }) => {
                                if (type === TransportTaskItemType.Product) {
                                  return (
                                    <FlexBox>
                                      <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                                        <Photo />
                                      </Avatar>
                                      <FlexBox flexDirection="column" ml={1}>
                                        <Typography variant="body2" color="textSecondary">
                                          {code}
                                        </Typography>
                                        <Typography variant="body2">{name}</Typography>
                                      </FlexBox>
                                    </FlexBox>
                                  )
                                } else {
                                  return (
                                    <Typography variant="body2">
                                      {name} {code}
                                    </Typography>
                                  )
                                }
                              },
                            },
                            {
                              name: 'จำนวน',
                              align: 'right',
                              render: ({ amount, unit }) => (
                                <Typography variant="body2">
                                  {formatNumberInt(amount)} {unit}
                                </Typography>
                              ),
                            },
                          ]}
                          data={state?.items ?? []}
                        />
                      </FlexBox>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
          })
        ) : (
          <FlexBox justifyContent="center" alignItems="center" width="100%" height={100} clone>
            <Card variant="outlined">
              <Typography variant="body2" color="textSecondary">
                ยังไม่มีข้อมูลการส่งสินค้า
              </Typography>
            </Card>
          </FlexBox>
        )}
      </FlexBox>
    </Paper>
  )
}

export default SOTasks
