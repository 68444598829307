import { Box, Grid, isWidthUp, Paper, TablePagination, Typography, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import React, { useContext, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { QuotationUpdateData_Product } from '../../../api/smartbestbuys-api'
import { EmptyCard, FlexBox, SearchTextField, SubTopic } from '../../../custom-components'
import QuotationsProductCard from '../QuotationCreationPage/quotation-presentation/QuotationsProductCard'
import { useQuotationsProductSelectorStyle } from '../QuotationCreationPage/quotation-presentation/useQuotationsProductSelectorStyle'
import { QuotationUpdationFormValue } from './QuotationUpdationFormValue'
import { QuotationUpdationPageContextImpl } from './QuotationUpdationPage'
import { ButtonRadios } from '../../../shared-components'
import * as _ from 'lodash'

interface QuotationUpdationProductSelectorProps {
  width: Breakpoint
}

enum QuotationProductFilterKey {
  All = 1,
  Pair = 2,
}

const QuotationUpdationProductSelector: React.FC<QuotationUpdationProductSelectorProps> = ({ width }) => {
  const { data } = useContext(QuotationUpdationPageContextImpl)
  const { products, profile } = data

  const {
    getValues,
    formState: { errors },
  } = useFormContext<QuotationUpdationFormValue>()

  const { selectedProducts } = getValues()

  const filterTypes = [
    { label: 'ทั้งหมด', value: QuotationProductFilterKey.All },
    { label: 'สินค้าที่เกี่ยวข้อง', value: QuotationProductFilterKey.Pair },
  ]

  // state
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')

  const [productFilterKey, setProductFilterKey] = useState<QuotationProductFilterKey>(QuotationProductFilterKey.All)

  const rowsPerPage = isWidthUp('xl', width) ? 12 : 8
  const styles = useQuotationsProductSelectorStyle()

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const _products = useMemo(() => {
    if (productFilterKey === QuotationProductFilterKey.Pair) {
      if (!selectedProducts) return []
      const _selectedProducts = products.filter((p) => selectedProducts.some((sp) => sp.id === p.id))
      const _allPairedSelectedProductIds = _.union(_selectedProducts.flatMap((p) => p.pairedProductIds))
      if (_allPairedSelectedProductIds.length === 0) return []
      return products.filter((p) => _allPairedSelectedProductIds.includes(p.id))
    }
    return products
  }, [products, selectedProducts, productFilterKey])

  const filtered = _products.filter((p: QuotationUpdateData_Product) => {
    const lowerCase = query.toLowerCase()
    return (
      p.code.toLowerCase().includes(lowerCase) ||
      p.nameLocal.toLowerCase().includes(lowerCase) ||
      p.id.toString() === lowerCase
    )
  })

  const paginate = (products: QuotationUpdateData_Product[]) =>
    products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <>
      <Box
        borderColor={!!errors.selectedProducts ? 'error.main' : undefined}
        border={!!errors.selectedProducts ? 2 : undefined}
        clone
      >
        <Paper id="quotations-product-selector" className={styles.root}>
          <SubTopic>เลือกสินค้าลงในใบเสนอราคา</SubTopic>
          <FlexBox justifyContent="space-between" alignItems="center">
            <SearchTextField
              onChange={(e) => {
                setQuery(e.target.value)
                setPage(0)
              }}
            />
            <ButtonRadios
              items={filterTypes.map((type) => ({ id: type.value, name: type.label }))}
              onChange={(value) => {
                setProductFilterKey(value)
                setPage(0)
              }}
              value={productFilterKey}
              color="secondary"
            />
          </FlexBox>
          <Grid container spacing={2}>
            {paginate(filtered).length > 0 ? (
              paginate(filtered).map((product) => {
                const selectedProduct = selectedProducts.find((sp) => sp.id === product.id)
                return (
                  <Grid key={product.id + product.nameLocal} item xs={12} sm={6} md={3} xl={2}>
                    <QuotationsProductCard
                      product={product}
                      selectedAmount={selectedProduct?.amount}
                      isDealer={profile.customer.isDealer}
                    />
                  </Grid>
                )
              })
            ) : (
              <Grid item xs={12}>
                <EmptyCard
                  emptyText={
                    productFilterKey === QuotationProductFilterKey.All
                      ? 'ไม่มีสินค้า'
                      : 'ไม่มีสินค้าที่เกี่ยวข้องจากสินค้าที่เลือก'
                  }
                />
              </Grid>
            )}
          </Grid>
          <TablePagination
            component="div"
            count={filtered.length}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        </Paper>
      </Box>
      <Box display={!!errors.selectedProducts ? 'block' : 'none'} color="error.main" pl={2} mb={0.5} clone>
        <Typography variant="body2">{errors.selectedProducts?.message}</Typography>
      </Box>
    </>
  )
}

export default withWidth()(QuotationUpdationProductSelector)
