import React, { FC } from 'react'
import ReactPDF, { Text, StyleSheet } from '@react-pdf/renderer'

interface Props {
  head?: boolean
  width?: number
  textAlign?: 'center' | 'right'
}

const PDFTableCell: FC<ReactPDF.TextProps & Props> = (props) => {
  const { textAlign, width, head, ...TextProps } = props

  const styles = StyleSheet.create({
    tr: {
      padding: '4px 8px',
      textAlign: textAlign ? textAlign : undefined,
      width: width ? width : undefined,
      borderColor: head ? '#222021' : undefined,
    },
  })

  const { style, ...otherProps } = TextProps
  return (
    <Text
      style={{
        ...styles.tr,
        ...style,
      }}
      {...otherProps}
    />
  )
}

export default PDFTableCell
