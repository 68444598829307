import { Paper, Box, Typography, Grid, InputAdornment, MenuItem, Checkbox } from '@material-ui/core'
import React, { useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FlexBox, KeyboardDatePickerForm, SubTopic, TextFieldForm } from '../../../custom-components'
import { ButtonRadios, Loading } from '../../../shared-components'
import SOCreationFormValues from '../../pages/SOCreationPage/SOCreationFormValues'
import WarningDialog from '../WarningDialog'
import { paymentConditions, paymentMethods, contactChannels, currencies } from '../../../types'
import RemarkDetail from '../RemarkDetail'
import { PageMode } from '../../pages'
import { ICustomerProfile, QuotationProfile } from '../../../api/smartbestbuys-api'
import { PaymentCondition, PaymentMethod } from '../../../enums'

interface Props {
  pageMode: PageMode
  customerPORefs?: string[]
  quotationProfile?: QuotationProfile
  customerProfile?: ICustomerProfile
}

const SaleOrderForm: React.FC<Props> = (props) => {
  const { pageMode, customerPORefs, quotationProfile, customerProfile } = props
  const [duplicatedAlert, setDuplicatedAlert] = useState(false)
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<SOCreationFormValues>()

  const { paymentMethodId, paymentConditionId, customerPurchaseReference, paymentDueDate } = watch()

  const paymentDueDateRequired = useMemo(() => {
    if (!paymentConditionId || !paymentMethodId) return false

    // payment condition list that need payment due date
    const _paymentConditions = [PaymentCondition.PaymentRequire, PaymentCondition.PayAtDestination]

    // payment method list that need payment due date
    const _paymentMethods = [PaymentMethod.Cash, PaymentMethod.Transfer]

    return _paymentConditions.includes(paymentConditionId) && _paymentMethods.includes(paymentMethodId)
  }, [paymentConditionId, paymentMethodId])

  const [hasNoRefPO, setHasNoRefPO] = useState<boolean>(false)

  const actualPaymentConditions = useMemo(
    () =>
      customerProfile?.creditPeriodDay
        ? paymentConditions
        : paymentConditions.filter((pc) => pc.id !== PaymentCondition.Credit),
    [customerProfile?.creditPeriodDay],
  )

  if (pageMode === PageMode.QUOTATION_ID && !quotationProfile) return <Loading />

  return (
    <Box padding={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>ข้อมูลเพิ่มเติม</SubTopic>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              fullWidth
              control={control}
              name="customerPurchaseReference"
              label="เลขที่การสั่งซื้อของลูกค้า *"
              variant="outlined"
              size="small"
              rules={{ required: 'กรุณาเลือกเลขที่การสั่งซื้อของลูกค้า' }}
              InputLabelProps={hasNoRefPO ? { shrink: true } : undefined}
              onBlur={(e) => {
                if (!e.target.value) return
                const lowerCase = e.target.value.toLowerCase()
                const lowerCasePOs = customerPORefs?.map((po) => (po ? po.toLowerCase() : po))
                setDuplicatedAlert(!!lowerCasePOs && lowerCasePOs.includes(lowerCase))
              }}
            />
            <FlexBox alignItems="center">
              <Checkbox
                size="small"
                checked={hasNoRefPO}
                onChange={() => {
                  setHasNoRefPO((prev) => {
                    !prev
                      ? setValue('customerPurchaseReference', 'ไม่มีPO')
                      : setValue('customerPurchaseReference', undefined)
                    return !prev
                  })
                }}
              />
              <Typography variant="body2" color="textSecondary">
                ลูกค้าไม่มี PO อ้างอิง
              </Typography>
            </FlexBox>
            <WarningDialog
              title="เลขที่การสั่งซื้อของลูกค้านี้เคยถูกสร้างใบสั่งขายแล้ว"
              open={duplicatedAlert}
              onOk={() => setDuplicatedAlert(false)}
              onClose={() => setDuplicatedAlert(false)}
            >
              เลขที่การสั่งซื้อของลูกค้าเลขที่ {customerPurchaseReference} เคยถูกสร้างใบสั่งขายแล้ว
              กรุณาตรวจสอบให้แน่ใจว่าต้องการสร้างใบสั่งขายนี้ต่อ
            </WarningDialog>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              select
              fullWidth
              control={control}
              name="contactChannelId"
              label="ช่องทางการติดต่อ *"
              variant="outlined"
              size="small"
              rules={{ required: 'กรุณาเลือกช่องทางการติดต่อ' }}
              type="number"
              defaultValue={quotationProfile?.contactChannel?.id}
            >
              {contactChannels.map((cc) => (
                <MenuItem key={cc.id} value={cc.id}>
                  {cc.name}
                </MenuItem>
              ))}
            </TextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              select
              fullWidth
              control={control}
              name="currencyId"
              label="สกุลเงิน *"
              variant="outlined"
              size="small"
              type="number"
              rules={{ required: { value: true, message: 'กรุณาใส่อัตราแลกเปลี่ยน' } }}
              error={!!errors.currencyId}
              helperText={errors.currencyId?.message}
            >
              {currencies.map((c) => (
                <MenuItem key={c.name} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </TextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              fullWidth
              control={control}
              name="exchangeRate"
              label="อัตราแลกเปลี่ยน *"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
              }}
              type="number"
              rules={{ required: { value: true, message: 'กรุณาใส่อัตราแลกเปลี่ยน' } }}
              error={!!errors.exchangeRate}
              helperText={errors.exchangeRate?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonRadios
              label="วิธีการชำระเงิน *"
              onChange={(paymentMethodId) => setValue('paymentMethodId', paymentMethodId)}
              items={paymentMethods}
              value={paymentMethodId}
            />
            {errors.paymentMethodId && (
              <Box>
                <Typography variant="caption" color="error" gutterBottom>
                  {errors.paymentMethodId?.message}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonRadios
              label="เงื่อนไขการชำระเงิน *"
              onChange={(paymentConditionId) => setValue('paymentConditionId', paymentConditionId)}
              items={actualPaymentConditions}
              defaultValue={paymentConditionId}
            />
            {errors.paymentConditionId && (
              <Box>
                <Typography variant="caption" color="error" gutterBottom>
                  {errors.paymentConditionId?.message}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyboardDatePickerForm
              control={control}
              label={`วันกำหนดชำระเงิน ${paymentDueDateRequired ? '*' : '(ถ้ามี)'}`}
              name="paymentDueDate"
              value={paymentDueDate}
              defaultValue={null}
              // inputVariant="outlined"
              size="small"
              fullWidth
              error={!!errors.paymentDueDate}
              helperText={errors.paymentDueDate?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              fullWidth
              multiline
              rowsMax={4}
              control={control}
              name="remark"
              label="หมายเหตุ"
              variant="outlined"
              size="small"
              defaultValue={''}
            />
          </Grid>
          <Grid item xs={12}>
            <RemarkDetail />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default SaleOrderForm
