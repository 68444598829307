import { useState, useEffect } from 'react'
import { FetchRequest } from '../api/smartbestbuys-api'
import { fetchSalesReportAPI, SalesReport } from '../api/smartbestbuys-api'

export const useSalesReport = (startDate: Date, endDate: Date): SalesReport | undefined => {
  const [report, setReport] = useState<SalesReport | undefined>(undefined)

  const _fetchSalesReport = async (request: FetchRequest) => {
    const response = await fetchSalesReportAPI(request)
    if (response) {
      setReport(response.data)
    }
  }

  useEffect(() => {
    _fetchSalesReport({ startDate, endDate })
  }, [startDate, endDate])

  return report
}
