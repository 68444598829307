import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { BaseData } from '..'

const path = '/sale-orders/fetch'

export interface FetchSaleOrderRequest {
  startDate: Date
  endDate: Date
}

export const fetchSaleOrders = errorHandler<SaleOrder_TableData[]>(async (request: FetchSaleOrderRequest) => {
  const result = await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
  return result
})

export interface SaleOrder_TableData {
  id: number
  status: BaseData
  paymentStatus: BaseData
  code: string
  customer: SaleOrderRowCustomer
  customerContact?: SaleOrderRowContact
  customerPurchaseReference?: string
  paymentMethod: BaseData
  paymentCondition: BaseData
  total?: number
  productTotal?: number
  stockAvailable: boolean
  isShippingFeeIncludingVat?: boolean
  recCreatedAt: Date
  recCreatedBy: string
  // deprecated
  discountAmountTypeId?: number
  discountAmount?: number
  estimatedShippingFee?: number
  vatIncluded?: boolean
  checklistToCompleteCancel?: {
    reclaimProductBack: boolean // pending to get product back from customer
    createCreditNote: boolean // pending to create credit note for tax invoice
    refundToCustomer: boolean // pending to refund money back to customer
  }
}

export interface SaleOrderRowCustomer {
  id: number
  nameLocal: string
  phoneNumber: string
}

export interface SaleOrderRowContact {
  nameLocal: string
  phoneNumber: string
}
