import { TaskType } from '../../../enums'
import { dateSqlFormat } from '../../../functions'
import { ITransportShippingPaymentDailySummary, ITransportShippingTaskDailySummary } from '../../../hooks'
import { errorGenericHandle, errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import smartAuthAPI2 from '../smartbestbuys-api-2'
import { UserActivityNote } from '../types'
import {
  TransportSubTask,
  TransportTask,
  TransportTaskProfile,
  TransportTaskCreateData,
  TransportTaskCreateRequestBody,
  ITransportTaskUpdateData,
  TransportTaskUpdateRequestBody,
  TransportShipingTaskMobileData,
  FinishShippingTaskRequestBody,
  FinishTransportTaskRequestBody,
  FinishTransportSubTaskRequestBody,
  UpdateShippingAddressInfoRequestBody,
  UpdateMessengerInfoRequestBody,
  TransportTaskCreateDataV2,
  CreateTransportPickupTaskRequestBody,
  ITransportTaskUpdateDataV2,
  IMessengerTaskMobileData,
  FinishPickupTaskRequestBody,
  ITransportTaskTableData,
  GetTransportTaskTableDataRequestQueryParams,
} from './transport-types'

export interface FetchTransportTasksURLParams {
  startDate: Date
  endDate: Date
  typeId?: TaskType
}

export interface GetTransportTaskCreateDataV2QueryParams {
  customerRequisitionId?: string
  saleOrderId?: string
}

export const createTransportTaskAPI = errorHandler<TransportTaskProfile>(
  async (requestBody: TransportTaskCreateRequestBody) => {
    return smartAuthAPI.post('/transport/v1/create', requestBody)
  },
)

export const createTransportPickupTaskAPI = errorHandler<TransportTaskProfile>(
  async (requestBody: CreateTransportPickupTaskRequestBody) => {
    return smartAuthAPI.post('/transport/v1/create-pickup-task', requestBody)
  },
)

export const finishTransportTaskAPI = errorSingleHandler<TransportTaskProfile>(
  async (id: number, requestBody: FinishTransportTaskRequestBody) => {
    return smartAuthAPI.post(`/transport/v1/${id}/finish-task`, requestBody)
  },
)

export const updateTransportTaskAPI = errorSingleHandler<TransportTaskProfile>(
  async (id: number, requestBody: TransportTaskUpdateRequestBody) => {
    return smartAuthAPI.put(`/transport/v1/${id}/update`, requestBody)
  },
)

export const updateTransportPickupTaskAPI = errorSingleHandler<TransportTaskProfile>(
  async (id: number, requestBody: TransportTaskCreateRequestBody) => {
    return smartAuthAPI.put(`/transport/v1/${id}/update-pickup-task`, requestBody)
  },
)

export const fetchTransportTasksAPI = errorGenericHandle<TransportTask[], FetchTransportTasksURLParams>(
  async (urlParams: FetchTransportTasksURLParams) => {
    return smartAuthAPI2.get('/transport/v1/tasks', {
      params: {
        startDate: dateSqlFormat(urlParams.startDate),
        endDate: dateSqlFormat(urlParams.endDate),
        typeId: urlParams.typeId?.toString(),
      },
    })
  },
)

export const getTransportTaskProfileAPI = errorHandler<TransportTaskProfile>(async (id: number) => {
  return smartAuthAPI.get(`transport/v1/${id}/profile`)
})

export const assignPrepairingTasks = errorHandler<TransportTask>(async (id: number) => {
  return smartAuthAPI.post(`transport/v1/${id}/assign-preparing-tasks`)
})

export const transportTaskConfirmWithCustomerAPI = errorHandler<TransportTask>(async (id: number) => {
  return smartAuthAPI.post(`transport/v1/${id}/confirm-with-customer`)
})

export const getTransportTaskCreateData = errorHandler<TransportTaskCreateData>(async (saleOrderId: number) => {
  return smartAuthAPI.get('/transport/v1/create-data', {
    params: {
      saleOrderId,
    },
  })
})

export const getTransportTaskCreateDataV2API = errorGenericHandle<
  TransportTaskCreateDataV2,
  GetTransportTaskCreateDataV2QueryParams
>(async (queryParams: GetTransportTaskCreateDataV2QueryParams) => {
  return smartAuthAPI.get('/transport/v2/create-data', {
    params: {
      customerRequisitionId: !!queryParams.customerRequisitionId
        ? Number(queryParams.customerRequisitionId)
        : undefined,
      saleOrderId: !!queryParams.saleOrderId ? Number(queryParams.saleOrderId) : undefined,
    },
  })
})

export const getTransportTaskUpdateDataAPI = errorHandler<ITransportTaskUpdateData>(async (taskId: number) => {
  return smartAuthAPI.get(`/transport/v1/${taskId}/update-data`)
})

export const getUpdateDataV2API = errorSingleHandler<ITransportTaskUpdateDataV2>(async (id: number) => {
  return smartAuthAPI.get(`transport/v2/${id}/update-data`)
})

export const fetchTransportSubTasksAPI = errorGenericHandle<TransportSubTask<any>[], FetchTransportTasksURLParams>(
  async (urlParams: FetchTransportTasksURLParams) => {
    return smartAuthAPI.get('/transport/v1/sub-tasks', {
      params: {
        startDate: dateSqlFormat(urlParams.startDate),
        endDate: dateSqlFormat(urlParams.endDate),
        typeId: urlParams.typeId?.toString(),
      },
    })
  },
)

export const fetchTransportShippingTasksAPI = errorGenericHandle<TransportShipingTaskMobileData[], { dueDate: string }>(
  async (urlParams: { dueDate: string }) => {
    return smartAuthAPI.get('/transport/v1/shipping-tasks', {
      params: {
        dueDate: urlParams.dueDate,
      },
    })
  },
)

export const cancelTransportTaskAPI = errorSingleHandler<TransportTaskProfile>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`transport/v1/${id}/cancel`, request)
  },
)

interface TransportSubTaskURLParams {
  taskId: number
  subTaskId: number
}

export const confirmTransportSubTaskAPI = errorGenericHandle<TransportSubTask<any>, {}>(
  async (params: TransportSubTaskURLParams) => {
    return smartAuthAPI.post(`transport/v1/${params.taskId}/sub-tasks/${params.subTaskId}/confirm-task`)
  },
)

export const finishTransportSubTaskAPI = errorGenericHandle<TransportSubTask<any>, TransportSubTaskURLParams>(
  async (params: TransportSubTaskURLParams, request: FinishTransportSubTaskRequestBody) => {
    return smartAuthAPI.post(`transport/v1/${params.taskId}/sub-tasks/${params.subTaskId}/finish-task`, request)
  },
)

export const transportShippingDailySummaryTaskAPI = errorHandler<ITransportShippingTaskDailySummary>(
  async (request: { dueDate: Date; typeId: string }) => {
    return smartAuthAPI2.get('/transport/v1/shipping-tasks/daily-summary', {
      params: {
        dueDate: dateSqlFormat(request.dueDate, 'yyyy-MM-dd'),
        typeId: request.typeId,
      },
    })
  },
)

// shipping
export const getTransportShippingTaskAPI = errorSingleHandler<TransportShipingTaskMobileData>(async (id: number) => {
  return smartAuthAPI.get(`transport/v1/shipping-tasks/${id}`)
})

export const confirmShippingTaskAPI = errorSingleHandler<TransportShipingTaskMobileData>(async (id: number) => {
  return smartAuthAPI.post(`transport/v1/shipping-tasks/${id}/confirm-task`)
})

export const noteShippingTaskAsCancelAPI = errorSingleHandler<TransportShipingTaskMobileData>(async (id: number) => {
  return smartAuthAPI.post(`transport/v1/shipping-tasks/${id}/note-as-cancel`)
})

export const noteMessengerTaskACancelAPI = errorSingleHandler<IMessengerTaskMobileData>(async (id: number) => {
  return smartAuthAPI.post(`transport/v1/messenger-tasks/${id}/note-as-cancel`)
})

export const finishShippingTaskAPI = errorSingleHandler<TransportShipingTaskMobileData>(
  async (id: number, requesBody: FinishShippingTaskRequestBody) => {
    return smartAuthAPI.post(`transport/v1/shipping-tasks/${id}/finish-task`, requesBody)
  },
)

export const finishPickupTaskAPI = errorSingleHandler<IMessengerTaskMobileData>(
  async (id: number, requestBody: FinishPickupTaskRequestBody) => {
    return smartAuthAPI.post(`transport/v1/pickup-tasks/${id}/finish-task`, requestBody)
  },
)

export const transportShippingPaymentDailySummaryTaskAPI = errorHandler<ITransportShippingPaymentDailySummary>(
  async (request: { dueDate: Date; typeId: string }) => {
    return smartAuthAPI.get('/transport/v1/shipping-tasks/payment-daily-summary', {
      params: {
        dueDate: dateSqlFormat(request.dueDate, 'yyyy-MM-dd'),
        typeId: request.typeId,
      },
    })
  },
)

export const updateShippingAddressInfoAPI = errorSingleHandler<TransportTaskProfile>(
  async (id: number, requestBody: UpdateShippingAddressInfoRequestBody) => {
    return smartAuthAPI.put(`transport/v1/${id}/update-shipping-address-info`, requestBody)
  },
)

export const updateMessengerInfoAPI = errorSingleHandler<TransportTaskProfile>(
  async (id: number, requestBody: UpdateMessengerInfoRequestBody) => {
    return smartAuthAPI.put(`transport/v1/${id}/update-messenger-info`, requestBody)
  },
)

export const getTransportMessengerTaskAPI = errorSingleHandler<IMessengerTaskMobileData>(async (id: number) => {
  return smartAuthAPI.get(`/transport/v1/messenger-tasks/${id}`)
})

export const fetchMessengerTasksAPI = errorGenericHandle<IMessengerTaskMobileData[], { dueDate: string }>(
  async (urlParams: { dueDate: string }) => {
    return smartAuthAPI.get(`/transport/v1/messenger-tasks`, {
      params: { dueDate: dateSqlFormat(urlParams.dueDate, 'yyyy-MM-dd') },
    })
  },
)

export const getTransportTaskTableDataAPI = errorHandler<ITransportTaskTableData>(
  async (queryParams: GetTransportTaskTableDataRequestQueryParams) => {
    return await smartAuthAPI2.get(`/transport/v1/tasks/table-data`, {
      params: queryParams,
    })
  },
)
