import { Box, Paper, FormControlLabel, Typography } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { CheckboxForm, FlexBox } from '../../../../../../custom-components'
import { calculatePriceBreakdowns } from '../../../../../../sales-system/utils/sale-order-price-calculator'
import { calculateVatInAmount } from '../../../../../../functions'
import SOUpdationFormValue from '../SOUpdationFormValues'
import numeral from 'numeral'

const VatBox: React.FC = () => {
  const { control, getValues } = useFormContext<SOUpdationFormValue>()

  const { vatIncluded, shippingFee, isShippingFeeIncludingVat } = getValues()

  const priceBreakdowns = calculatePriceBreakdowns(getValues())

  const shippingFeeVatAmount = isShippingFeeIncludingVat ? calculateVatInAmount(shippingFee || 0) : 0

  return (
    <Box padding={2} display="flex" justifyContent="space-between" alignItems="center" clone>
      <Paper>
        <FormControlLabel
          control={<CheckboxForm checked={vatIncluded} control={control} name="vatIncluded" />}
          label={<Typography variant="h5">ภาษีมูลค่าเพิ่ม 7 %</Typography>}
        />
        <FlexBox flexDirection="column" alignItems="flex-end">
          <Typography variant="h6" color="textSecondary">
            {vatIncluded
              ? `ภาษีมูลค่าเพิ่ม ${numeral(priceBreakdowns.vatAmount).format('0,0.00')}`
              : 'ไม่มีภาษีมูลค่าเพิ่ม'}
          </Typography>
          {isShippingFeeIncludingVat && (
            <>
              <Box color="text.hint" clone>
                <Typography variant="caption">
                  VAT สินค้า {numeral(priceBreakdowns.vatAmount - shippingFeeVatAmount).format('0,0.00')} บาท
                </Typography>
              </Box>
              <Box color="text.hint" clone>
                <Typography variant="caption">
                  VAT ค่าส่ง {numeral(shippingFeeVatAmount).format('0,0.00')} บาท
                </Typography>
              </Box>
            </>
          )}
        </FlexBox>
      </Paper>
    </Box>
  )
}

export default VatBox
