import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Params {
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
}

export const useStyles = (params: Params) =>
  makeStyles((theme) =>
    createStyles({
      img: {
        objectFit: params.objectFit,
        // [theme.breakpoints.down('sm')]: {
        //   padding: theme.spacing(1),
        // },
      },
    }),
  )
