import { Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { TransportTaskStateV2_Item } from '../../../../api/smartbestbuys-api'
import { Avatar, Table } from '../../../../custom-components'

interface ShippingProductTableProps {
  data: TransportTaskStateV2_Item[]
}

const ShippingProductTable: React.FC<ShippingProductTableProps> = ({ data }) => {
  return (
    <Table<TransportTaskStateV2_Item>
      color="primary.dark"
      size="small"
      columns={[
        {
          name: 'ลำดับ',
          align: 'right',
          render: (_, index) => index + 1,
          fixedWidth: 50,
        },
        {
          name: 'ชื่อสินค้า',
          render: (product) => (
            <div className="customer-requisition-table__product-cell" key={'product-' + product.id}>
              <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                <Photo />
              </Avatar>
              <div className="customer-requisition-table__product-cell__info">
                <Typography color="textSecondary">{product.code}</Typography>
                <Typography>{product.name}</Typography>
              </div>
            </div>
          ),
        },
        {
          name: 'จำนวนที่ส่ง',
          fixedWidth: 150,
          dataIndex: 'amount',
          numeric: true,
          format: '0,0',
        },
      ]}
      data={data}
      cleanSpace
    />
  )
}

export default ShippingProductTable
