import { ProductPrice } from '../../../../../../api/smartbestbuys-api'
import { updateProductPriceAPI } from '../../../../../../api/smartbestbuys-api/product/update-product-prices-api'

export const updateProductPrice = (productId: number, request: ProductPrice[]) => {
  const prices = request.map((productPrice) => ({
    toAmount: productPrice.toAmount,
    fromAmount: productPrice.fromAmount,
    price: productPrice.price,
  }))
  return updateProductPriceAPI(productId, { prices })
}
