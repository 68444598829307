import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { FetchRequest, WarehouseTask } from '..'

const path = '/warehouse/tasks'

export interface FetchWarehouseQueryParams extends FetchRequest {
  typeId?: number
}

export const fetchWarehouseTasks = errorHandler<WarehouseTask[]>(async (request: FetchWarehouseQueryParams) => {
  const result = await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
      typeId: request.typeId,
    },
  })
  return result
})
