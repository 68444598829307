import { Tooltip, TooltipProps } from '@material-ui/core'

export interface ConditionItem {
  showTooltip: boolean
  message: string
}

interface MultipleConditionTooltipProps extends Omit<TooltipProps, 'title'> {
  conditionItems: ConditionItem[]
  showAllPossible?: boolean
}

const MultipleConditionTooltip = ({
  conditionItems,
  showAllPossible,
  children,
  ...tooltipProps
}: MultipleConditionTooltipProps) => {
  if (conditionItems.some((item) => item.showTooltip)) {
    return (
      <Tooltip
        title={
          showAllPossible ? (
            <div>
              {conditionItems
                .reduce<string[]>((showedMessages, item) => {
                  if (item.showTooltip) return [...showedMessages, item.message]
                  return showedMessages
                }, [])
                .map((msg) => (
                  <div>{msg}</div>
                ))}
            </div>
          ) : (
            conditionItems.find((item) => item.showTooltip)?.message ?? ''
          )
        }
        {...tooltipProps}
        style={{ cursor: 'not-allowed' }}
      >
        <div>{children}</div>
      </Tooltip>
    )
  }

  return children
}

export default MultipleConditionTooltip
