import { useContext, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Topic, withLayout } from '../../custom-components'
import SaleDashboard from './sale-orders'
import FinanceDashboard from './finance'
import { useSalesReport } from '../../hooks'
import DashboardContext, { PaymentTransactionInfo } from './DashboardContext'
import { defaultStartDate, defaultEndDate } from '../../data'
import { DateRange, Loading } from '../../shared-components'
import { PageName, PaymentMethod, SOPaymentTransactionStatus } from '../../enums'
import { RootContext } from '../..'

const MarketingSalesDashboardPage: React.FC = (props) => {
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const salesReport = useSalesReport(startDate, endDate)
  const [paymentTransactionInfo, setPaymentTransactionInfo] = useState<PaymentTransactionInfo | undefined>(undefined)
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.MARKETING_SALES_REPORT)

  useEffect(() => {
    if (!salesReport) return
    const { paymentTransactions } = salesReport
    const cashTransactions = paymentTransactions.filter((t) => t.paymentMethodId === PaymentMethod.Cash)
    const transferTransactions = paymentTransactions.filter((t) => t.paymentMethodId === PaymentMethod.Transfer)
    const creditTransactions = paymentTransactions.filter((t) => t.paymentMethodId === PaymentMethod.Credit)
    const chequeTransactions = paymentTransactions.filter((t) => t.paymentMethodId === PaymentMethod.Cheque)
    setPaymentTransactionInfo({
      cash: {
        approved: cashTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.APPROVED),
        pending: cashTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.PENDING),
        canceled: cashTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.CANCELLED),
        rejected: cashTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.DECLINED),
      },
      transfer: {
        approved: transferTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.APPROVED),
        pending: transferTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.PENDING),
        canceled: transferTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.CANCELLED),
        rejected: transferTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.DECLINED),
      },
      credit: {
        approved: creditTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.APPROVED),
        pending: creditTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.PENDING),
        canceled: creditTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.CANCELLED),
        rejected: creditTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.DECLINED),
      },
      cheque: {
        approved: chequeTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.APPROVED),
        pending: chequeTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.PENDING),
        canceled: chequeTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.CANCELLED),
        rejected: chequeTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.DECLINED),
      },
    })
  }, [salesReport])

  return (
    <DashboardContext.Provider value={{ salesReport, paymentTransactionInfo }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Topic>ข้อมูลแดชบอร์ด</Topic>
        </Grid>
        <Grid item xs={12} md={6}>
          <DateRange
            from={startDate}
            to={endDate}
            onChange={(from, to) => {
              setStartDate(from)
              setEndDate(to)
            }}
          />
        </Grid>
      </Grid>
      {!salesReport ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SaleDashboard />
          </Grid>
          <Grid item xs={12}>
            <FinanceDashboard />
          </Grid>
        </Grid>
      )}
    </DashboardContext.Provider>
  )
}

export default withLayout(MarketingSalesDashboardPage)
