import { Box, Typography } from '@material-ui/core'
import { ICustomerProfile_Document, TaxInvoiceState } from '../../../../../../../api/smartbestbuys-api'
import { Link, TColumn } from '../../../../../../../custom-components'
import { calculateExcludingVat, calculateVatInAmount, dateTimeFormat } from '../../../../../../../functions'
import { DocumentStatusChip } from '../../../../../../../shared-components'

const taxInvoiceDocumentColumns: TColumn<ICustomerProfile_Document<TaxInvoiceState>>[] = [
  {
    name: 'สถานะใบกำกับภาษี',
    render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
  },
  {
    name: 'เลขใบกำกับภาษี',
    render: ({ id, code }) => (
      <Box color="primary.dark">
        <Link color="inherit" to={`/tax-invoices/${id}/pdf`} target="_blank">
          {code}
        </Link>
      </Box>
    ),
  },
  {
    name: 'เลขใบแจ้งหนีอ้างอิง',
    render: ({ referenceDocument }) =>
      referenceDocument ? (
        <Box color="secondary.dark">
          <Link color="inherit" to={`/invoices/${referenceDocument.id}/pdf`} target="_blank">
            {referenceDocument?.code}
          </Link>
        </Box>
      ) : (
        <Typography color="textSecondary" variant="body2">
          สร้างจากใบสั่งขาย
        </Typography>
      ),
  },
  {
    name: 'เลขใบสั่งขายอ้างอิง',
    render: ({ saleOrderId, saleOrderCode }) => (
      <Box color="secondary.dark">
        <Link color="inherit" to={`/sale-orders/${saleOrderId}`} target="_blank">
          {saleOrderCode}
        </Link>
      </Box>
    ),
  },
  {
    name: 'วันที่สร้าง',
    render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
  },
  {
    name: 'ยอดก่อน VAT',
    numeric: true,
    format: '0,0.00',
    render: ({ total }) => calculateExcludingVat(total || 0),
  },
  {
    name: 'VAT',
    numeric: true,
    format: '0,0.00',
    render: ({ total }) => calculateVatInAmount(total || 0),
  },
  {
    name: 'ยอดชำระ',
    numeric: true,
    format: '0,0.00',
    dataIndex: 'total',
  },
] as TColumn<ICustomerProfile_Document<TaxInvoiceState>>[]

export default taxInvoiceDocumentColumns
