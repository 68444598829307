export enum DocumentType {
  Invoice = 101,
  ShippingSheet = 103,
  TaxInvoice = 102,
  Receipts = 104,
  BillingNote = 105,
  CreditNote = 106,
}

export enum DocumentPrefix {
  TaxInvoice = 'SI04',
}

export enum DocumentStatus {
  Pending = 1,
  Sent = 2,
  Cancelled = 3,
}
