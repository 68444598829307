import { createStyles, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { FlexBox } from '..'

interface Props {
  color?: string
  isEmpty?: boolean
  emptyText?: string
}

const CustomPaper: React.FC<Props> = (props) => {
  const { color, isEmpty, emptyText, children } = props

  const classes = useStyles(color)()

  return (
    <Paper className={classes.root}>
      {isEmpty ? (
        <FlexBox className={classes.disable} alignItems="center" justifyContent="center">
          <Typography>{emptyText}</Typography>
        </FlexBox>
      ) : (
        children
      )}
    </Paper>
  )
}

const useStyles = (color?: string) =>
  makeStyles((theme) => {
    let backgroundColor = undefined
    let boxShadow = undefined

    switch (color) {
      default:
        backgroundColor = theme.palette.grey[100]
        boxShadow =
          'inset 0px 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0px 1px 1px rgba(0, 0, 0, 0.14), inset 0px 1px 3px rgba(0, 0, 0, 0.12)'
        break
    }

    return createStyles({
      root: {
        backgroundColor,
        boxShadow,
      },
      disable: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.text.secondary,
        height: '100px',
      },
    })
  })

export default CustomPaper
