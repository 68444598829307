import React from 'react'
import { Avatar, Typography } from '@material-ui/core'
import { FlexBox } from '../../../../../custom-components'
import { Close, Edit, Photo } from '@material-ui/icons'
import { useStyles } from './styles'
import { PRVendor } from '../../../../../api/smartbestbuys-api'

interface Props {
  vendor?: PRVendor
  onEdit?: () => void
  onClose?: () => void
  readonly?: boolean
  className?: string
}

const VendorInfoCard: React.FC<Props> = (props: Props) => {
  const { vendor, className, readonly, onEdit, onClose } = props

  const hasVendor = !!vendor

  const styles = useStyles(hasVendor)()

  return (
    <FlexBox
      gridGap={16}
      justifyContent="space-between"
      alignItems="center"
      className={[styles.box, className].join(' ')}
    >
      {hasVendor ? (
        <>
          <Avatar variant="rounded" src={vendor!.profileImageUrl} className={styles.avatar}>
            <Photo />
          </Avatar>
          <FlexBox flexDirection="column" className={styles.vendorNameBox}>
            <Typography variant="body2" className={styles.vendorName}>
              {vendor!.name}
            </Typography>
            <Typography variant="caption" className={styles.vendorCountry}>
              {vendor!.country}
            </Typography>
          </FlexBox>
          {!readonly && (
            <FlexBox justifyContent="space-between" flexDirection="column" className={styles.actions}>
              <Close fontSize="small" className={styles.closeIcon} onClick={() => onClose && onClose()} />
              <Edit fontSize="small" className={styles.editIcon} onClick={() => onEdit && onEdit()} />
            </FlexBox>
          )}
        </>
      ) : (
        <Typography variant="body2" className={styles.noVendor}>
          ไม่มีผู้ขายอ้างอิง
        </Typography>
      )}
    </FlexBox>
  )
}

export default VendorInfoCard
