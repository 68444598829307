import { GridSize } from '@material-ui/core'
import { GridBox, Loading } from '../../shared-components'
import { NivoColorPalette, Statistic } from '../../visaulization-components'

interface StaticBoxProps {
  title: string
  subTitle?: string | React.ReactNode
  value: number
  decimal?: boolean
  color?: NivoColorPalette
  loading?: boolean
  colSpan?: GridSize
}

const StaticBox = (props: StaticBoxProps) => {
  const { loading, value, title, subTitle, decimal, color, colSpan } = props
  return (
    <GridBox item xs={6} md={colSpan || 3} row={6}>
      {loading ? (
        <Loading />
      ) : (
        <Statistic title={title} amount={value} format={decimal ? '0,0.00' : '0,0'} subtitle={subTitle} color={color} />
      )}
    </GridBox>
  )
}

export default StaticBox
