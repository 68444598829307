import { DialogProps } from '@material-ui/core'
import { transportTaskConfirmWithCustomerAPI } from '../../../api/smartbestbuys-api'
import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
} from '../../../shared-components'

interface Props extends DialogProps {
  taskId: number
  onComplete: (data: any) => void
  onFail: () => void
}

const TransportPickupTaskProfileAssignOtherTeamDialog: React.FC<Props> = (props: Props) => {
  // set up props
  const { taskId, onComplete, onFail, ...dialogProps } = props

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันส่งงานให้ฝ่ายที่เกี่ยวข้อง',
    description: `คุณแน่ใจที่จะทำการส่งงานให้ฝ่ายที่เกี่ยวข้องใช่หรือไม่?`,
    submitText: 'ยืนยัน',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: false,
    enableRemark: false,
    allowNewMessage: true,
  }

  const handleSubmit = async () => {
    const response = await transportTaskConfirmWithCustomerAPI(taskId)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.UpdateStatusTask}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default TransportPickupTaskProfileAssignOtherTeamDialog
