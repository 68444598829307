import { CreatedDocument } from '.'
import { errorSingleHandler } from '../../middleware'
import { PriceBreakdownItem, TaxInvoiceItem } from '../document-management'
import smartAuthAPI from '../smartbestbuys-api'

export const createTaxInvoice = errorSingleHandler<CreatedDocument>(
  async (saleOrderId: number, request: TaxInvoiceCreationRequest) => {
    const path = `/sale-orders/${saleOrderId}/create-tax-invoice`
    return await smartAuthAPI.post(path, request)
  },
)

export interface TaxInvoiceCreationRequest {
  invoiceId?: number
  customerId: number
  creditPeriodDay?: number
  total: number
  items: TaxInvoiceItem[]
  priceBreakdowns: PriceBreakdownItem[]
  remark?: string
  soPaymentTransactionId?: number
}
