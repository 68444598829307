import { CreateUserRequestBody } from '../../api/smartbestbuys-api'
import UserCreateFromValue from './create/UserCreateFromValue'

export const mapCreateFromValueToCreateUserRequest = (
  values: UserCreateFromValue,
  selectedPermissionGroupIds: number[],
  selectedPermissionIds: number[],
  allPermissionIdInSelectedGroup: number[],
): CreateUserRequestBody => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    nickName: values.nickName,
    birthDate: values.birthDate,
    phoneNumber: values.phoneNumber,
    email: values.email,
    base64: values.base64,
    username: values.username,
    password: values.password,
    permissionGroupIds: selectedPermissionGroupIds,
    permissionIds: selectedPermissionIds.filter((f) => {
      return !allPermissionIdInSelectedGroup.includes(f)
    }),
  }
}
