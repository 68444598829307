import { updateCustomerAPI } from '../../../../../../api/smartbestbuys-api'
import CustomerUpdationFormValue from './CustomerUpdationFormValues'

export const updateCustomer = (customerId: number, values: CustomerUpdationFormValue) => {
  const requestForm = {
    customerTypeId: values.customerTypeId,
    businessTypeId: values?.businessTypeId ? Number(values.businessTypeId) : undefined,
    nameLocal: values.nameLocal,
    nameEn: values?.nameEn,
    referenceNumber: values?.referenceNumber,
    phoneNumber: values.phoneNumber,
    websiteUrl: values?.websiteUrl,
    creditPeriodDay: values.hasCreditCustomer ? values?.creditPeriodDay : undefined,
    email: values?.email,
    isDealer: values.isDealer,
    shippingDocumentRequireList: values?.shippingDocumentRequireList,
    billingTermAndCondition: values?.billingTermAndCondition,
    paymentTermAndCondition: values?.paymentTermAndCondition,
    saleUserId: values.saleUserId,
  }
  return updateCustomerAPI(customerId, requestForm)
}
