import { Button, Grid, Paper } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EmptyCard, FlexBox, SubTopic } from '../../../../../../custom-components'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../../../../shared-components'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import CustomerProfileContext from '../../CustomerProfileContext'
import { CustomerAddressList } from '../CustomerAddressBox'
import { CustomerBillingAddressCreationDialog } from './CustomerBillingAddressCreationDialog'

interface Props {
  triggerCreateBillingAddress?: boolean
}

const CustomerBillingAddressBox: React.FC<Props> = ({ triggerCreateBillingAddress }) => {
  const history = useHistory()
  const [createCustomerBillingAddrsss, setCreateCustomerBillingAddrsss] = useState(!!triggerCreateBillingAddress)

  const { customerProfile } = useContext(CustomerProfileContext)

  const { nameLocal, billingAddresses, recDeletedAt } = customerProfile

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Write)

  const isActiveCustomer = !recDeletedAt

  const disableWrite = !writePermission.hasPermission || !isActiveCustomer

  return (
    <>
      {createCustomerBillingAddrsss && (
        <CustomerBillingAddressCreationDialog
          open={createCustomerBillingAddrsss}
          onClose={() => {
            setCreateCustomerBillingAddrsss(false)
          }}
          onSuccess={() => {
            if (triggerCreateBillingAddress && customerProfile.billingAddresses?.length === 0)
              history.push(`/sale-orders/${triggerCreateBillingAddress}`)
          }}
        />
      )}
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SubTopic>ที่อยู่เปิดบิล</SubTopic>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexBox justifyContent="flex-end">
              <ConditionTooltip
                title={
                  !writePermission.hasPermission ? writePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                }
                showTooltip={disableWrite}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disableWrite}
                  onClick={() => setCreateCustomerBillingAddrsss(true)}
                >
                  + เพิ่มที่อยู่เปิดบิล
                </Button>
              </ConditionTooltip>
            </FlexBox>
          </Grid>
          {billingAddresses && billingAddresses.length > 0 ? (
            <Grid item xs={12}>
              <CustomerAddressList
                customerName={nameLocal}
                addresses={billingAddresses}
                isActiveCustomer={isActiveCustomer}
                type="billing"
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <EmptyCard emptyText="ยังไม่มีที่อยู่เปิดบิล" />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerBillingAddressBox
