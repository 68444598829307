import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { useMemo, useState } from 'react'
import { defaultEndDate, defaultStartDate } from '../../data'
import moment from 'moment'
import { FlexBox } from '../../custom-components'
import { CSVLink } from 'react-csv'

export interface CSVDataColumn<D> {
  columnName: string
  render: (d: D) => string | number
}

interface Props<D> {
  fileName: string
  loaded: boolean
  data: D[]
  columns: CSVDataColumn<D>[]
  onLoadClick: (startDate: Date, endDate: Date) => void
  onDateChange: () => void
}

export const CSVDownloader = <D extends unknown>({
  fileName: filename,
  loaded,
  data,
  columns,
  onLoadClick,
  onDateChange,
}: Props<D>) => {
  const [open, setOpen] = useState(false)

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const csvData = useMemo(() => {
    return data.map((d) => {
      return columns.reduce((csvData, c) => {
        return {
          ...csvData,
          [c.columnName]: c.render(d),
        }
      }, {})
    })
  }, [data, columns])

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>
          <FlexBox gridGap={8}>
            <TextField
              id="from-date"
              label="จากวันที่"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={moment(startDate).format('YYYY-MM-DD')}
              variant="outlined"
              size="small"
              onChange={(e) => {
                onDateChange()
                const updatedEndDate = moment(e.target.value).startOf('day').toDate()
                setStartDate(updatedEndDate)
              }}
            />
            <TextField
              id="end-date"
              label="ถึงวันที่"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={moment(endDate).format('YYYY-MM-DD')}
              variant="outlined"
              size="small"
              onChange={(e) => {
                onDateChange()
                const updatedEndDate = moment(e.target.value).startOf('day').toDate()
                setEndDate(updatedEndDate)
              }}
            />
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                onLoadClick(startDate, endDate)
              }}
              disabled={loaded}
            >
              โหลดข้อมูลตัวอย่าง
            </Button>
          </FlexBox>
        </DialogTitle>
        <DialogContent>
          <div className="w-full overflow-x-auto">
            <table className="border-collapse border-spacing-0 border border-slate-400">
              <thead>
                <tr>
                  {columns.map((c, index) => {
                    return (
                      <th className="p-1 text-base bg-gray-50" key={`column-${index}`}>
                        {c.columnName}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {data.slice(0, 10).map((d, index) => {
                  return (
                    <tr key={'row-' + index}>
                      {columns.map((c, index) => (
                        <td className="p-1 text-base align-top" key={`column-${index}`}>
                          {c.render(d)}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={() => setOpen(false)}>
            ปิด
          </Button>
          <CSVLink filename={filename} style={{ textDecoration: 'none' }} className="csv-link" data={csvData}>
            <Button color="primary" variant="contained" disabled={!loaded}>
              ดาวน์โหลด
            </Button>
          </CSVLink>
        </DialogActions>
      </Dialog>
      <Button
        color="primary"
        variant="text"
        startIcon={<CloudDownload color="primary" />}
        onClick={() => setOpen(true)}
      >
        ดาวน์โหลด CSV
      </Button>
    </>
  )
}
