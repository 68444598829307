import { useState, useEffect } from 'react'
import { QuotationRowData, fetchQuotations, FetchRequest } from '../../api/smartbestbuys-api'

export const useQuotations = (startDate: Date, endDate: Date): QuotationRowData[] | undefined => {
  const [data, setData] = useState<QuotationRowData[] | undefined>(undefined)

  const _fetchQuotations = async (request: FetchRequest) => {
    const response = await fetchQuotations(request)
    setData(response?.data?.reverse() || [])
  }

  useEffect(() => {
    _fetchQuotations({ startDate, endDate })
  }, [endDate, startDate])

  return data
}
