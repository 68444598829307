import { Checkbox, Grid, Paper, Typography } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { createPurchaseRequisitionApi, PurchaseRequisitionCreateData } from '../../../api/smartbestbuys-api'

import { FlexBox, Topic } from '../../../custom-components'
import { PurchaseRequisitionType } from '../../../enums'
import { SubmitForm } from '../../../shared-components'
import { AttachmentSelectionBox } from './AttachmentSelectionBox'
import { mapPurchaseRequisitionsToCreationFormValues } from './mapper'
import { PurchaseProductInfoVerification } from './PurchaseProductInfo'
import PurchaseProductInfoTable from './PurchaseProductInfoTable'
import PurchaseRequisitionCreateFormValues from './PurchaseRequisitionCreateFormValue'
import { VendorBox } from './VendorBox'

interface Props {
  type: PurchaseRequisitionType
  onPreviousPage: () => void
  createPurchaseRequisitionData: PurchaseRequisitionCreateData
}

const PurchaseRequisitionVerification: React.FC<Props> = (props: Props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const history = useHistory()
  const { type, onPreviousPage, createPurchaseRequisitionData } = props

  const showedConfirmationCheckbox = !!createPurchaseRequisitionData.saleOrderInfo
  const [confirm, setConfirm] = useState(false)

  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<PurchaseRequisitionCreateFormValues>()

  const { attachments, products } = watch()

  const handleSubmit = async () => {
    const request = mapPurchaseRequisitionsToCreationFormValues(
      watch(),
      type,
      createPurchaseRequisitionData.saleOrderInfo?.id,
    )

    const response = await createPurchaseRequisitionApi(request)

    if (response?.status === 201) {
      triggerSnackbar('สร้างใบขอซื้อสำเร็จ')
      history.replace('/purchase-requisitions')
    } else {
      triggerSnackbar('สร้างใบขอซื้อไม่สำเร็จ')
    }
    return true
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>ตรวจทานคำขอซื้อสำหรับซื้อสินค้า/บริการ</Topic>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <PurchaseProductInfoVerification type={type} createData={createPurchaseRequisitionData} />
            </Grid>
            <Grid item xs={4}>
              <VendorBox vendors={createPurchaseRequisitionData.vendors} readonly={true} />
            </Grid>

            <Grid item xs={12}>
              <PurchaseProductInfoTable products={products} />
            </Grid>
            <Grid item xs={12}>
              <AttachmentSelectionBox readonly={true} defaultValue={attachments} />
            </Grid>
          </Grid>
        </Grid>
        {showedConfirmationCheckbox && (
          <Grid item xs={12}>
            <FlexBox clone alignItems="center">
              <Paper>
                <Checkbox
                  checked={confirm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirm(event.target.checked)}
                />
                <Typography variant="h5">ฉันยืนยันว่า ฉันตรวจสอบสต็อกสินค้าเรียบร้อยแล้วว่าสินค้าขาด </Typography>
                <span className="ml-2" />
                <Typography color="error" variant="h5">
                  จำเป็นต้องซื้อเพิ่ม
                </Typography>
              </Paper>
            </FlexBox>
          </Grid>
        )}
        <Grid item xs={12}>
          <SubmitForm
            submitText="ยืนยันสร้าง"
            cancelText="ย้อนกลับไปแก้ไขข้อมูล"
            disableSubmit={showedConfirmationCheckbox && !confirm}
            onCancel={onPreviousPage}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PurchaseRequisitionVerification
