import { Grid, InputAdornment, Paper, Switch, FormControlLabel, Box, Collapse, Typography } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { CheckboxForm, FlexBox, GeneralTextFieldForm, SubTopic } from '../../../../../../custom-components'
import { calculateExcludingVat } from '../../../../../../functions'
import SOUpdationFormValue from '../SOUpdationFormValues'
import numeral from 'numeral'

const ShippingFeeBox: React.FC = () => {
  const { control, setValue, watch } = useFormContext<SOUpdationFormValue>()
  const { isShippingFeeIncludingVat, shippingFee, shippingFeeEnabled } = watch()

  const renderLabel = () => {
    if (isShippingFeeIncludingVat) {
      const shippingFeeExcludingVat = calculateExcludingVat(shippingFee || 0)
      return (
        <Typography variant="body1">
          รวม VAT (ค่าจัดส่งก่อน VAT:&nbsp;
          <Box component="span" color="primary.main">
            {numeral(shippingFeeExcludingVat).format('0,0.00')}
          </Box>
          &nbsp; บาท)
        </Typography>
      )
    }

    return 'รวม VAT'
  }

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FlexBox justifyContent="flex-start">
            <SubTopic>ค่าจัดส่ง</SubTopic>
          </FlexBox>
        </Grid>
        <Grid item xs={6}>
          <FlexBox justifyContent="flex-end">
            <Switch
              size="medium"
              color="primary"
              checked={!!shippingFeeEnabled}
              onClick={() => setValue('shippingFeeEnabled', !shippingFeeEnabled)}
            />
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={!!shippingFeeEnabled}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GeneralTextFieldForm
                  control={control}
                  name="shippingFee"
                  fullWidth
                  label="ค่าจัดส่ง *"
                  type="number"
                  rules={{ required: { value: !!shippingFeeEnabled, message: 'กรุณาใส่ค่าจัดส่ง' } }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box ml={1}>
                  <FormControlLabel
                    control={
                      <CheckboxForm
                        control={control}
                        checked={isShippingFeeIncludingVat}
                        name="isShippingFeeIncludingVat"
                        size="small"
                      />
                    }
                    label={renderLabel()}
                  />
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ShippingFeeBox
