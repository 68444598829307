import { LoginRequest, LoginResponse, login as smartLoginAPI } from '../../api/smartbestbuys-api'

export const smartLogin = async (username: string, password: string): Promise<LoginResponse | undefined> => {
  const request: LoginRequest = {
    username,
    password,
  }

  const response = await smartLoginAPI(request)

  return response?.data
}
