import { Button, MenuItem, Popover } from '@material-ui/core'
import { FlexBox } from '../../../../custom-components'
import { Loading, MultipleConditionTooltip } from '../../../../shared-components'
import { MoreHoriz } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { usePWTickProfileContext } from './PWTicketProfilePageContextProvider'
import { PWTicketProfilePageMenuItemTopic, profilePageText } from './pw-ticket-profile-page-setting'
import { PWTicketCancelDialog, PWTicketConfirmReceivedDialog, PWTicketConfirmReturnedDialog } from './Dialogs'
import { RootContext } from '../../../..'
import { WithdrawalTicketStatus } from '../../../../enums'
import FollowUpPWTicketDialog from '../dialogs/FollowUpPWTicketDialog'
import { useHistory } from 'react-router-dom'

const PWTicketProfilePageActionBox: React.FC = () => {
  const history = useHistory()
  const { profile, pageSetting, updateData } = usePWTickProfileContext()
  const { triggerSnackbar } = useContext(RootContext)

  const {
    actionBox: { confirmReceivedButton, confirmReturnedButton, followUpButton, menu },
  } = pageSetting

  // state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)
  const [openCancelDialog, setCancelDialogOpen] = useState(false)
  const [openConfirmReceivedDialog, setConfirmReceivedDialogOpen] = useState(false)
  const [openConfirmReturnedDialog, setConfirmReturnedDialogOpen] = useState(false)

  if (!profile) return <Loading />

  return (
    <>
      <FollowUpPWTicketDialog
        ticketProfile={profile}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={(updated) => {
          updateData(updated)
          triggerSnackbar(`${profilePageText.dialog.followUpDialog.successText}`)
        }}
        onFail={() => {
          triggerSnackbar(`${profilePageText.dialog.followUpDialog.failText}`)
        }}
      />
      <PWTicketConfirmReceivedDialog
        profile={profile}
        open={openConfirmReceivedDialog}
        onClose={() => setConfirmReceivedDialogOpen(false)}
        onSuccess={(updated) => {
          updateData(updated)
          triggerSnackbar('เบิกสินค้าเรียบร้อย')
          setConfirmReceivedDialogOpen(false)
        }}
        onFail={() => {
          triggerSnackbar('เบิกสินค้าล้มเหลว')
          setConfirmReceivedDialogOpen(false)
        }}
      />
      <PWTicketConfirmReturnedDialog
        profile={profile}
        open={openConfirmReturnedDialog}
        onClose={() => setConfirmReturnedDialogOpen(false)}
        onSuccess={(updated) => {
          updateData(updated)
          triggerSnackbar(
            updated.status.id === WithdrawalTicketStatus.Done
              ? 'คืนสินค้าทั้งหมดแล้ว, ปิดคำขอเบิกสินค้า'
              : 'คืนสินค้าที่เลือกแล้ว',
          )
          setConfirmReturnedDialogOpen(false)
          window.location.reload()
        }}
        onFail={() => {
          triggerSnackbar('คืนสินค้าล้มเหลว')
          setConfirmReturnedDialogOpen(false)
        }}
      />
      <PWTicketCancelDialog
        profile={profile}
        open={openCancelDialog}
        onClose={() => setCancelDialogOpen(false)}
        onSuccess={(updated) => {
          updateData(updated)
          triggerSnackbar('ยกเลิกคำขอเบิกสินค้าสำเร็จ')
          setCancelDialogOpen(false)
        }}
        onFail={() => {
          triggerSnackbar('ยกเลิกคำขอเบิกสินค้าไม่สำเร็จ')
          setCancelDialogOpen(false)
        }}
      />
      <FlexBox gridGap={8}>
        {!confirmReceivedButton.hidden && (
          <Button variant="contained" size="large" color="primary" onClick={() => setConfirmReceivedDialogOpen(true)}>
            {profilePageText.actionBox.confirmReceivedButton.title}
          </Button>
        )}

        {!followUpButton.hidden && (
          <Button variant="contained" size="large" color="primary" onClick={() => setOpen(true)}>
            {profilePageText.actionBox.followUpButton.title}
          </Button>
        )}

        {!confirmReturnedButton.hidden && (
          <Button variant="contained" size="large" color="primary" onClick={() => setConfirmReturnedDialogOpen(true)}>
            {profilePageText.actionBox.confirmReturnedButton.title}
          </Button>
        )}

        {!menu.hidden && (
          <Button variant="contained" size="large" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreHoriz />
          </Button>
        )}
      </FlexBox>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MultipleConditionTooltip
          conditionItems={menu.menuItems[PWTicketProfilePageMenuItemTopic.Edit].tooltipConditions!}
        >
          <MenuItem
            disabled={menu.menuItems[PWTicketProfilePageMenuItemTopic.Edit].disabled}
            onClick={() => history.replace(`/warehouse/product-withdrawal-tickets/${profile.id}/update`)}
          >
            {profilePageText.actionBox.editButton.title}
          </MenuItem>
        </MultipleConditionTooltip>
        <MultipleConditionTooltip
          conditionItems={menu.menuItems[PWTicketProfilePageMenuItemTopic.Cancel].tooltipConditions!}
        >
          <MenuItem
            disabled={menu.menuItems[PWTicketProfilePageMenuItemTopic.Cancel].disabled}
            onClick={() => setCancelDialogOpen(true)}
          >
            {profilePageText.actionBox.cancelButton.title}
          </MenuItem>
        </MultipleConditionTooltip>
      </Popover>
    </>
  )
}

export default PWTicketProfilePageActionBox
