import React from 'react'
import { Avatar as MUIAvatar, AvatarProps } from '@material-ui/core'
import { useStyles } from './styles'

interface Props extends AvatarProps {
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
}

const Avatar: React.FC<Props> = (props) => {
  const { objectFit, ...otherProps } = props
  const classes = useStyles({ objectFit })()

  return <MUIAvatar classes={{ img: classes.img }} {...otherProps} />
}

export default Avatar
