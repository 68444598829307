import { Grid, Paper } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../..'
import { CustomerRequisitionShippingPendingTableData } from '../../api/smartbestbuys-api'
import { FilteredTable, Topic, withLayout } from '../../custom-components'
import { PageName } from '../../enums'
import { dateFormat } from '../../functions'
import { useCRShippingPending } from '../../hooks'
import { Loading, TransportTaskProcessingSignal, TypographyLink } from '../../shared-components'

const CustomerRequisitionShippingPendingListPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION_SHIPPING_PENDING)

  enum TransportTaskStatus {
    HaveTask = 1,
    NotHaveTask = 0,
  }

  const shippingPendingList = useCRShippingPending()

  if (!shippingPendingList) return <Loading />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>รายการพร้อมส่งคืนลูกค้า</Topic>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <FilteredTable<CustomerRequisitionShippingPendingTableData>
            color="primary.dark"
            columns={[
              {
                name: '',
                dataIndex: 'hasTransportTaskProcessing',
                render: ({ hasTransportTaskProcessing }) =>
                  hasTransportTaskProcessing && <TransportTaskProcessingSignal />,
                fixedWidth: 50,
              },
              {
                name: 'เลขที่คำร้อง',
                dataIndex: 'code',
                render: ({ id, code }) => (
                  <TypographyLink href={`/customer-requisitions/${id}/profile`} color="primary.dark" target="_blank">
                    {code}
                  </TypographyLink>
                ),
                fixedWidth: 150,
                sort: true,
              },
              {
                name: 'เลขที่ใบสั่งขายอ้างอิง',
                dataIndex: 'saleOrder',
                render: ({ saleOrder }) => (
                  <TypographyLink href={`/sale-orders/${saleOrder.id}`} color="#C79400" target="_blank">
                    {saleOrder.code}
                  </TypographyLink>
                ),
                fixedWidth: 150,
              },
              {
                name: 'ชื่อลูกค้า',
                dataIndex: 'customer',
                render: ({ customer }) => customer.name,
              },
              {
                name: 'วันที่สร้าง',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                fixedWidth: 150,
                sort: true,
              },
            ]}
            data={shippingPendingList}
            sortable
            filters={[
              {
                name: 'การสร้างงานส่ง',
                values: [
                  { name: 'สร้างแล้ว', value: TransportTaskStatus.HaveTask },
                  { name: 'ยังไม่สร้าง', value: TransportTaskStatus.NotHaveTask },
                ],
                filter: (data: CustomerRequisitionShippingPendingTableData, values: (string | number)[]) =>
                  values.includes(Number(data.hasTransportTaskProcessing)),
              },
            ]}
            disableDateRangeFilter
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withLayout(CustomerRequisitionShippingPendingListPage)
