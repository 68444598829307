import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export interface CustomerUpdationRequest {
  customerTypeId: number
  businessTypeId?: number
  nameLocal: string
  nameEn?: string
  referenceNumber?: string
  phoneNumber?: string
  websiteUrl?: string
  creditPeriodDay?: string
  email?: string
}

export interface UpdatedCustomer {
  id: number
  code: string
  customerTypeId: number
  businessTypeId?: number
  nameLocal: string
  nameEn?: string
  referenceNumber?: string
  phoneNumber?: string
  websiteUrl?: string
  creditPeriodDay?: string
  email?: string
  recCreatedBy: string
  recCreatedAt: Date
  recModifiedBy: string
  recModifiedAt: Date
}

export const updateCustomerAPI = errorSingleHandler<UpdatedCustomer>((id: number, request: CustomerUpdationRequest) => {
  const path = `/customers/${id}`
  return smartAuthAPI.put(path, request)
})
