import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useContext, useMemo } from 'react'
import { RootContext } from '../../../..'
import { updatePermissionGroupDataAPI } from '../../../../api/smartbestbuys-api/data/data-apis'
import {
  PermissionGroupData,
  PermissionGroupDataUpdateRequestBody,
  PermissionTopic,
} from '../../../../api/smartbestbuys-api/data/data-types'
import PermissionGroupForm from '../PermissionGroupForm'
import { PermissionGroupFormValues } from '../PermissionGroupFormValue'
import { PermissionGroup } from '../../../../api/smartbestbuys-api'

interface PermissionGroupUpdateDialogProps extends DialogProps {
  permissionGroup: PermissionGroup
  permissionTopics: PermissionTopic[]
  onComplete?: (data: PermissionGroupData) => void
}

const PermissionGroupUpdateDialog = (props: PermissionGroupUpdateDialogProps) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { permissionGroup, permissionTopics, onComplete, onClose, ...dialogProps } = props

  const defaultValues = useMemo(
    () => {
      return {
        permissionGroupName: permissionGroup.name,
        permissionIds: permissionGroup.permissions?.map((permission) => permission.id) ?? [],
      }
    }, // eslint-disable-next-line
    [permissionGroup?.id],
  )

  const handleSubmit = async (values: PermissionGroupFormValues) => {
    const request: PermissionGroupDataUpdateRequestBody = {
      permissionGroupName: values.permissionGroupName,
      permissionIds: values.permissionIds,
    }
    try {
      const response = await updatePermissionGroupDataAPI(permissionGroup.id, request)
      if (response && response.status === 200) {
        triggerSnackbar('แก้ไขกลุ่มสิทธิ์การใช้งานสำเร็จ')
        if (onComplete) onComplete(response.data)
      } else {
        triggerSnackbar('แก้ไขกลุ่มสิทธิ์การใช้งานไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('แก้ไขกลุ่มสิทธิ์การใช้งานไม่สำเร็จ')
    }
  }

  return (
    <Dialog fullWidth maxWidth="lg" {...dialogProps}>
      <DialogTitle>แก้ไขกลุ่มสิทธิ์การใช้งาน</DialogTitle>
      <DialogContent>
        <PermissionGroupForm
          defaultValues={defaultValues}
          permissionTopics={permissionTopics}
          onSubmit={handleSubmit}
          onCancel={() => {
            onClose && onClose({}, 'escapeKeyDown')
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default PermissionGroupUpdateDialog
