import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, MenuItem } from '@material-ui/core'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../..'
import { cancelCustomerRequisitionAPI, UserActivityNote } from '../../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm } from '../../../../custom-components'
import { PageName, UserAction } from '../../../../enums'
import { useActionMessages } from '../../../../hooks'
import { SubmitForm } from '../../../../shared-components'

interface Props extends DialogProps {
  customerRequisitionId: number
}

interface CancelCustomerRequisitionFormValues {
  actionMessageId: number
  actionMessage: string
  remark?: string
}

const CustomerRequisitionProfileCancelDialog: React.FC<Props> = (props) => {
  const { customerRequisitionId, onClose, ...otherProps } = props
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION)

  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CancelCustomerRequisitionFormValues>()

  const { actionMessageId } = watch()

  const messages = useActionMessages(UserAction.CancelCustomerRequisition)
  const cancelMessages = [{ id: -1, name: 'เพิ่มสาเหตุใหม่' }, ...messages]

  const handleCancel = async (values: CancelCustomerRequisitionFormValues) => {
    const request: UserActivityNote = {
      actionMessageId: values.actionMessageId === -1 ? undefined : values.actionMessageId,
      actionMessage: values.actionMessage,
      remark: values.remark,
    }

    const response = await cancelCustomerRequisitionAPI(customerRequisitionId, request)

    if (response?.status === 200) {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('ยกเลิกคำร้องของลูกค้าแล้ว')
      window.location.reload()
    } else {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('ยกเลิกคำร้องของลูกค้าไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth {...otherProps}>
      <Form onSubmit={handleSubmit(handleCancel)}>
        <DialogTitle>ยกเลิกคำร้อง</DialogTitle>
        <DialogContent>
          <GeneralTextFieldForm
            name="actionMessageId"
            select
            label="สาเหตุที่ยกเลิก *"
            control={control}
            rules={{ required: 'กรุณากรอกสาเหตุของการยกเลิก' }}
          >
            {cancelMessages.map((message) => (
              <MenuItem key={message.id} value={message.id} style={{ color: message.id === -1 ? 'gray' : 'black' }}>
                {message.name}
              </MenuItem>
            ))}
          </GeneralTextFieldForm>
          {actionMessageId === -1 && (
            <GeneralTextFieldForm
              name="actionMessage"
              label="สาเหตุใหม่ *"
              control={control}
              rules={{ required: 'กรุณากรอกสาเหตุของการยกเลิก' }}
            />
          )}
          <GeneralTextFieldForm name="remark" label="หมายเหตุ (ถ้ามี)" control={control} />
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText="ยืนยันการยกเลิก"
            cancelText="ย้อนกลับ"
            onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerRequisitionProfileCancelDialog
