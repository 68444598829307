import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { ButtonRadios, UploadImageCard } from '../../../shared-components'
import { PurchaseProduct } from './PurchaseProductSelector/PurchaseProductSelector'
import { useForm } from 'react-hook-form'
import { GeneralTextFieldForm } from '../../../custom-components'

import './PurchaseProductCreateDialog.less'
import { purchaseProductTypes } from '../../../data/service-product-types'
import { useState } from 'react'

interface Options {
  existingProductCodeList?: string[]
  currency?: string
  isPriceRequired?: boolean
}

interface Props extends DialogProps {
  onDialogSubmit: (product: PurchaseProduct) => void
  product?: PurchaseProduct
  options?: Options
}

const PurchaseProductCreateDialog: React.FC<Props> = (props) => {
  //props & state
  const { onClose, onDialogSubmit, product, options, ...otherProps } = props
  const [showedInputProductCode, setShowedInputProductCode] = useState(!!product?.code)

  const {
    control,
    setValue,
    watch,
    setError,
    trigger,
    formState: { errors },
  } = useForm<PurchaseProduct>({
    defaultValues: product,
  })

  const { amount, price, code } = watch()

  const handleSubmit = async () => {
    await trigger()
    const isExistCode = options?.existingProductCodeList?.find((_code) => _code === code)

    if (isExistCode) {
      setError('code', { type: 'isAlreadyExist', message: 'รหัสสินค้านี้ได้ถูกใช้แล้ว' })
    }

    if (Object.keys(errors).length > 0) {
      return
    } else {
      onDialogSubmit(watch())
      onClose && onClose({}, 'escapeKeyDown')
    }
  }

  //data
  const createProductCodeTypes = [
    { id: 1, value: false, name: 'อัตโนมัติ' },
    { id: 2, value: true, name: 'กำหนดเอง' },
  ]

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <DialogTitle>จัดการข้อมูลสินค้า/บริการ ที่ไม่เคยมีในระบบ</DialogTitle>
      <DialogContent className="flex">
        <Grid container>
          <Grid item xs={12} md={3}>
            <UploadImageCard
              previewOnChange
              title="อัปโหลดรูป สินค้า/บริการ"
              onChange={(image) => setValue('base64', image.base64)}
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Box marginLeft={1.5}>
                  <Typography align="left" variant="caption" color="textSecondary" gutterBottom>
                    รหัสสินค้า/บริการ *
                  </Typography>
                </Box>
                <ButtonRadios
                  items={createProductCodeTypes}
                  onValueChange={(value) => {
                    setValue('code', undefined)
                    setShowedInputProductCode(value as boolean)
                  }}
                  defaultValue={showedInputProductCode}
                />
              </Grid>

              {showedInputProductCode && (
                <Grid item xs={12} md={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="code"
                    rules={{ required: 'กรุณากรอกรหัสสินค้า/บริการ' }}
                    type="text"
                    label="รหัสสินค้า/บริการ *"
                    defaultValue=""
                  />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="name"
                  rules={{ required: 'กรุณากรอกชื่อสินค้า/บริการ' }}
                  type="text"
                  label="ชื่อสินค้า/บริการ *"
                  defaultValue=""
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="unit"
                  type="text"
                  label="หน่วยของสินค้า/บริการ"
                  defaultValue=""
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="typeId"
                  defaultValue=""
                  label="ประเภทสินค้า / บริการ *"
                  rules={{ required: 'กรุณาเลือกประเภทสินค้า/บริการ' }}
                  select
                >
                  {purchaseProductTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </GeneralTextFieldForm>
              </Grid>

              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="amount"
                  rules={{ required: 'กรุณากรอกจำนวน' }}
                  type="number"
                  label="จำนวน *"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">อัน</InputAdornment>,
                  }}
                  defaultValue=""
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="price"
                  type="number"
                  rules={{ required: options?.isPriceRequired ? 'กรุณากรอกจำนวน' : undefined }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{options?.currency || 'บาท'}</InputAdornment>,
                  }}
                  label={`ราคาต่อหน่วย${options?.isPriceRequired ? '*' : ''}`}
                  defaultValue=""
                />
              </Grid>
            </Grid>
            {amount && price && (
              <Typography variant="subtitle2" color="secondary" className="float-right">
                ราคารวม {(amount * price).toLocaleString()} {options?.currency || 'บาท'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="purchase-product-creation-actions">
        <Button id="btn-cancel" color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
          ยกเลิก
        </Button>
        <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
          ยืนยันข้อมูล
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PurchaseProductCreateDialog
