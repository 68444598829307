import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      maxWidth: 200,
    },
    avatar: {
      width: 120,
      height: 100,
    },
    avatarSmall: {
      width: 68,
      height: 68,
    },
    avatarBig: {
      width: 160,
      height: 160,
    },
  }),
)
