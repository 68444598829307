import { Grid, Paper } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { Title } from '../custom-components'
import { SourceFile } from '../types'
import FileCard from './FileCard'
import Space from './Space'
import { UploadCard } from './UploadCard'

interface AttachmentInputBoxProps {
  formProps?: {
    name: string
  }
  onChange?: (images: SourceFile[]) => void
  defaultValues?: SourceFile[]
  limit?: number // default 5
}

const DEFAULT_IMAGE = 5

const AttachmentInputBox = (props: AttachmentInputBoxProps) => {
  const { limit, onChange, defaultValues } = props

  const [files, setFiles] = useState<SourceFile[]>(defaultValues ?? [])

  const actualLimit = useMemo(() => limit ?? DEFAULT_IMAGE, [limit])

  const handleFileChange = (file: SourceFile) => {
    setFiles((prev) => {
      const nextFiles = [...prev, file]
      if (!!onChange) onChange(nextFiles)
      return nextFiles
    })
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)
    if (!!onChange) onChange(updatedFiles)
  }

  return (
    <Paper>
      <Title>แนบไฟล์เพิ่มเติม (สูงสุด {actualLimit} ไฟล์)</Title>
      <Space />
      <Grid container className="pt-3" spacing={2}>
        {files.length < actualLimit && (
          <Grid item xs={12} md={2}>
            <UploadCard onChange={handleFileChange} />
          </Grid>
        )}

        {files.map((file, index) => (
          <Grid item xs={12} md={2} key={file.name + index}>
            <FileCard value={file} onRemove={() => handleRemoveFile(index)} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

export default AttachmentInputBox
