import { IconButton, Typography } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useRef, useState } from 'react'
import { FlexBox } from '../../../../../custom-components'
import { compressImage, fileToBase64 } from '../../../../../functions'
import { FileType, SourceFile } from '../../../../../types'
import PreviewImageDialog from './PreviewImageDialog'

import './UploadCard.less'

interface Props {
  onChange: (file: SourceFile) => void
}


const UploadCard: React.FC<Props> = (props: Props) => {
  const { onChange } = props

  const [openPreview, setOpenPreview] = useState(false)
  const [file, setFile] = useState<SourceFile>()
  const ref = useRef<HTMLInputElement | null>(null)

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files

    if (!fileList?.length) return

    if (!(fileList[0].type.match('image/') || fileList[0].type === 'application/pdf')) return

    const file: SourceFile = {
      name: fileList[0].name,
      base64: '',
      type: FileType.PDF,
    }

    let compressed: File | Blob = fileList[0]

    if (fileList[0].type !== 'application/pdf') {
      compressed = await compressImage(fileList[0], 0.5)
      file.type = FileType.Image
    }
    const compressed64 = await fileToBase64(compressed)
    file.base64 = compressed64
    e.target.value = ''

    if (file.type === FileType.Image) {
      setOpenPreview(true)
      setFile(file)
      return
    }

    onChange(file)
  }

  return (
    <>
      {openPreview && (
        <PreviewImageDialog
          refInput={ref}
          source={file?.base64}
          open={openPreview}
          onClose={() => {
            setOpenPreview(false)
            setFile(undefined)
            onChange(file!)
          }}
        />
      )}
      <FlexBox flexDirection="column" alignItems="center" justifyContent="center" className="upload-card">
        <input
          ref={ref}
          accept="image/*, .pdf"
          style={{ display: 'none' }}
          id="icon-button-file-attachment"
          onChange={handleFileChange}
          type="file"
        />
        <label htmlFor="icon-button-file-attachment">
          <IconButton aria-label="upload picture" component="span">
            <AttachFileIcon style={{ fontSize: 60 }} />
          </IconButton>
        </label>
        <Typography color="primary" align="center" display="block">
          เลือกไฟล์ที่ต้องการ
        </Typography>
      </FlexBox>
    </>
  )
}

export default UploadCard
