import { Card, Grid, Paper } from '@material-ui/core'
import { useContext } from 'react'
import { SORefundRequisition } from '../../../../../api/smartbestbuys-api'
import { FlexBox, SubTopic, TypographyWithLabel } from '../../../../../custom-components'
import { formatNumber } from '../../../../../functions/number-utils'
import { Loading, RefundRequisitionStatusChip } from '../../../../../shared-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'

const SORefundRequisitions: React.FC = () => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)

  if (!saleOrderProfile) return <Loading />

  const { paymentTransactions, refundRequisitions } = saleOrderProfile

  const paidAmount =
    paymentTransactions?.reduce<number>((sum, pt) => sum + (!!pt.statementTimestamp ? pt.amount : 0), 0) ?? 0

  return (
    <Paper>
      <Grid container spacing={2} direction="row-reverse">
        <Grid item xs={12}>
          <SubTopic>ข้อมูลการคืนเงิน</SubTopic>
        </Grid>
        <Grid item xs={12}>
          <FlexBox>
            <TypographyWithLabel label="ยอดที่ชำระมาแล้ว:">{formatNumber(paidAmount)}</TypographyWithLabel>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {refundRequisitions.map((refund) => (
              <Grid item xs={12} key={refund.id}>
                <RefundRequisitionCard refundRequisition={refund} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SORefundRequisitions

interface RefundRequisitionCardProps {
  refundRequisition: SORefundRequisition
}

const RefundRequisitionCard: React.FC<RefundRequisitionCardProps> = (props) => {
  const { refundRequisition } = props

  const { code, status, total } = refundRequisition

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox gridGap={16} alignItems="center">
            <SubTopic>{code}</SubTopic>
            <RefundRequisitionStatusChip status={status.id} label={status.name} />
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <FlexBox alignItems="center">
            <TypographyWithLabel label="ยอดคืนสุทธิ:">{`${formatNumber(total)} บาท`}</TypographyWithLabel>
          </FlexBox>
        </Grid>
      </Grid>
    </Card>
  )
}
