import { Button, Checkbox, Divider, List, ListItem, ListItemText, Popover } from '@material-ui/core'
import { FilterList as FilterIcon, Replay } from '@material-ui/icons'
import { useMemo, useState } from 'react'
import { FlexBox } from '..'
import './Filter.less'

interface FilterOption {
  key: string
  label: string
  values: { label: string; value: number | string | boolean }[]
}

interface FilterProps<T> {
  options: FilterOption[]
  onValueChange: (value: T) => void
}

export interface FilterValues {
  [key: string]: (number | string | boolean)[]
}

const Filter = <T extends FilterValues>(props: FilterProps<T>): JSX.Element => {
  const { options, onValueChange } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'filter-popover' : undefined

  const defaultFilterValues: T = useMemo(
    () => {
      return options.reduce<T>((values, option) => ({ ...values, [option.key]: [] }), {} as T)
    }, // eslint-disable-next-line
    [options.length],
  )

  const [filterValues, setFilterValues] = useState<T>(defaultFilterValues)

  const handleCheckFilterValue = (key: string, value: number | string | boolean) => {
    setFilterValues((current) => {
      let cloned = { ...current }
      const values = cloned[key]
      const index = values.findIndex((v) => v === value)
      if (index > -1) {
        cloned = { ...cloned, [key]: [...values.slice(0, index), ...values.slice(index + 1)] }
      } else {
        cloned = { ...cloned, [key]: [...values, value] }
      }
      onValueChange({ ...cloned })
      return { ...cloned }
    })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-describedby={id}
        size="medium"
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleClick}
        startIcon={<FilterIcon />}
        className="button"
        disableElevation
      >
        ตัวกรอง
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List className="list">
          {options.map((option) => (
            <ListItem key={option.label}>
              <ListItemText
                classes={{
                  secondary: 'list-item',
                }}
                primary={option.label}
                secondary={option.values.map((filterValue) => {
                  const isChecked: boolean = filterValues[option.key].includes(filterValue.value)
                  return (
                    <FlexBox key={filterValue.label} alignItems="center">
                      <Checkbox
                        size="small"
                        checked={isChecked}
                        onChange={() => {
                          handleCheckFilterValue(option.key, filterValue.value)
                        }}
                      />
                      {filterValue.label}
                    </FlexBox>
                  )
                })}
              />
            </ListItem>
          ))}
          <Divider />
          <ListItem key="footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color="primary"
              onClick={() => {
                setFilterValues(defaultFilterValues)
                onValueChange(defaultFilterValues)
              }}
            >
              <Replay />
              <span className="ml-2">ล้างข้อมูล</span>
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default Filter
