import { Box, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { FlexBox } from '..'

interface Props {
  // deprecated, just send as children
  emptyText?: string
  error?: boolean
}

const EmptyCard: React.FC<Props> = ({ error, emptyText, children }) => {
  return (
    <Box borderColor={error && 'error.main'} clone>
      <Paper variant="outlined">
        <FlexBox
          alignItems="center"
          justifyContent="center"
          color={error ? 'error.main' : 'text.secondary'}
          height="106px"
        >
          <Typography>{emptyText ?? children}</Typography>
        </FlexBox>
      </Paper>
    </Box>
  )
}

export default EmptyCard
