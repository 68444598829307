import React, { useState } from 'react'
import { Avatar, Card, Dialog, Typography, ButtonBase } from '@material-ui/core'
import { FlexBox } from '../../custom-components'
import { useStyles } from './styles'

interface Props {
  name: string
  src: string
}

const AttachmentCard: React.FC<Props> = ({ name, src }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const togglePreview = () => setOpen((prev) => !prev)

  return (
    <Card className={classes.card}>
      <Dialog open={open} onClose={togglePreview}>
        <img src={src} alt={name} />
      </Dialog>
      <FlexBox flexDirection="column" alignItems="center" gridGap={8}>
        <ButtonBase onClick={togglePreview}>
          <Avatar className={classes.avatar} src={src} alt={name} variant="square" />
        </ButtonBase>
        <Typography variant="body2">{name}</Typography>
      </FlexBox>
    </Card>
  )
}

export default AttachmentCard
