import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useQuotationsProductSelectorStyle = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
        },
    }),
)
