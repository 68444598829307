import { Box } from '@material-ui/core'
import { ICustomerProfile_Document, InvoiceState } from '../../../../../../../api/smartbestbuys-api'
import { Link, TColumn } from '../../../../../../../custom-components'
import { dateTimeFormat } from '../../../../../../../functions'
import { DocumentStatusChip } from '../../../../../../../shared-components'

const invoiceDocumentColumns: TColumn<ICustomerProfile_Document<InvoiceState>>[] = [
  {
    name: 'สถานะใบแจ้งหนี้',
    render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
  },
  {
    name: 'เลขใบแจ้งหนี้',
    render: ({ id, code }) => (
      <Box color="primary.dark">
        <Link color="inherit" to={`/invoices/${id}/pdf`} target="_blank">
          {code}
        </Link>
      </Box>
    ),
  },
  {
    name: 'เลขใบสั่งขายอ้างอิง',
    render: ({ saleOrderId, saleOrderCode }) => (
      <Box color="secondary.dark">
        <Link color="inherit" to={`/sale-orders/${saleOrderId}`} target="_blank">
          {saleOrderCode}
        </Link>
      </Box>
    ),
  },
  {
    name: 'วันที่สร้าง',
    render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
  },
  {
    name: 'ยอดที่ต้องชำระ',
    numeric: true,
    format: '0,0.00',
    dataIndex: 'total',
  },
] as TColumn<ICustomerProfile_Document<InvoiceState>>[]

export default invoiceDocumentColumns
