import React, { useContext, useState } from 'react'
import { Button, Paper } from '@material-ui/core'
import { FlexBox, SubTopic, Title } from '../../../custom-components'
import { CalendarToday, Edit } from '@material-ui/icons'
import { dateFormat } from '../../../functions'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import { RootContext } from '../../..'
import PurchaseOrderProfileShippingDateDialog from './PurchaseOrderProfileShippingDateDialog'
import { PurchaseOrderStatus } from '../../../enums'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../shared-components'

const VendorAppointmentBox: React.FC = (props) => {
  // context
  const { purchaseOrderProfile, forceReload } = useContext(PurchaseOrderProfilePageContext)
  const { vendorShippingDate } = purchaseOrderProfile
  const { triggerSnackbar } = useContext(RootContext)

  // set up props & state
  const [shippingDateOpen, setShippingDateOpen] = useState(false)

  // data
  const blackListStatusAppointment = [
    PurchaseOrderStatus.Rejected,
    PurchaseOrderStatus.Canceled,
    PurchaseOrderStatus.Received,
  ]

  // calculation
  const isAppointed = !!vendorShippingDate
  const ableToDoAppointment = !blackListStatusAppointment.includes(purchaseOrderProfile.status.id)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Write)

  if (!ableToDoAppointment) {
    return (
      <FlexBox p={3} justifyContent="space-between" alignItems="flex-end" clone>
        <Paper>
          <SubTopic>วันนัดหมายผู้ขาย</SubTopic>
          <Title color={isAppointed ? 'textPrimary' : 'textSecondary'}>
            {!!vendorShippingDate ? dateFormat(vendorShippingDate) : 'ยังไม่ระบุ'}
          </Title>
        </Paper>
      </FlexBox>
    )
  }

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" alignItems="flex-end" clone>
      <Paper>
        <PurchaseOrderProfileShippingDateDialog
          open={shippingDateOpen}
          onClose={() => setShippingDateOpen(false)}
          onComplete={(data) => {
            setShippingDateOpen(false)
            triggerSnackbar(`แก้ไขวันนัดหมายผู้ขายสำเร็จ`)
            forceReload()
          }}
          onFail={() => {
            triggerSnackbar(`แก้ไขวันนัดหมายผู้ขายไม่สำเร็จ`)
          }}
        />

        <SubTopic>วันนัดหมายผู้ขาย</SubTopic>
        {!!vendorShippingDate ? (
          <FlexBox alignItems="flex-end">
            <Title color={isAppointed ? 'textPrimary' : 'textSecondary'}>{dateFormat(vendorShippingDate)}</Title>
            <span className="ml-2" />
            <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
              <Button disabled={!writePermission.hasPermission}>
                <Edit
                  color="secondary"
                  onClick={() => {
                    setShippingDateOpen(true)
                  }}
                />
              </Button>
            </ConditionTooltip>
          </FlexBox>
        ) : (
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <Button
              disabled={!writePermission.hasPermission}
              variant="contained"
              color="primary"
              classes={{ label: 'px-2 leading-none' }}
              onClick={() => {
                setShippingDateOpen(true)
              }}
            >
              <CalendarToday fontSize="small" classes={{ root: 'mr-2' }} /> นัดหมายผู้ขาย
            </Button>
          </ConditionTooltip>
        )}
      </Paper>
    </FlexBox>
  )
}

export default VendorAppointmentBox
