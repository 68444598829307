import { Grid } from '@material-ui/core'
import { useContext } from 'react'
import { Topic } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'
import { TransportTaskCreatePageContext } from './TransportTaskCreatePage'
import TransportTaskSaleOrderProduct from './TransportTaskSaleOrderProduct'
import TransportTaskDocument from './TransportTaskDocument'
import TransportShippingMethodSelectForm from './TransportShippingMethodSelectForm'
import TransportTaskDetailForm from './TransportTaskDetailForm'
import TransportTaskAssigneeUser from './TransportTaskAssigneeUser'
import TransportTaskCustomerShippingAddress from './TransportTaskCustomerShippingInformationForm'

const TransportTaskCreateForm: React.FC = () => {
  const { transportTaskCreateData } = useContext(TransportTaskCreatePageContext)
  const { saleOrderInfo, customerRequisitionInfo } = transportTaskCreateData

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>
          สร้างงานขนส่งสำหรับ
          {!customerRequisitionInfo ? `ใบสั่งขาย ${saleOrderInfo?.code}` : `คำร้อง ${customerRequisitionInfo?.code}`}
        </Topic>
      </Grid>
      <Grid item xs={12}>
        <TransportTaskSaleOrderProduct />
      </Grid>
      {!customerRequisitionInfo && (
        <Grid item xs={12}>
          <TransportTaskDocument />
        </Grid>
      )}
      <Grid item xs={12}>
        <TransportShippingMethodSelectForm />
      </Grid>
      <Grid item xs={12}>
        <TransportTaskDetailForm />
      </Grid>
      <Grid item xs={12}>
        <TransportTaskCustomerShippingAddress />
      </Grid>
      <Grid item xs={12}>
        <TransportTaskAssigneeUser />
      </Grid>
      <Grid item xs={12}>
        <SubmitForm goBack submitText="ตรวจทานข้อมูลขนส่ง" cancelText="ยกเลิก" />
      </Grid>
    </Grid>
  )
}

export default TransportTaskCreateForm
