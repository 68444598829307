import { DialogProps } from '@material-ui/core'
import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import { UpdatePurchaseOrderStatus, callLeaderPurchaseOrder, UserActivityNote } from '../../../api/smartbestbuys-api/'

interface Props extends DialogProps {
  purchaseOrderId: number
  onComplete: (data: UpdatePurchaseOrderStatus) => void
  onFail: () => void
}

const PurchaseOrderProfileCallLeaderDialog: React.FC<Props> = (props: Props) => {
  // set up props
  const { purchaseOrderId, onComplete, onFail, ...dialogProps } = props

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการแจ้งหัวหน้า',
    description: `คุณแน่ใจที่จะแจ้งหัวหน้าให้มาจัดการต่อใช่หรือไม่?`,
    submitText: 'ยืนยัน',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: false,
    enableRemark: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { remark } = values
    const request: UserActivityNote = {
      remark,
    }
    const response = await callLeaderPurchaseOrder(purchaseOrderId, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.CallLeader}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default PurchaseOrderProfileCallLeaderDialog
