import { useState, useEffect } from 'react'
import { getSaleOrderProfile, SaleOrderProfile } from '../api/smartbestbuys-api'

export const useSaleOrderProfile = (id?: number, reload?: boolean): SaleOrderProfile | undefined => {
  const [profile, setProfile] = useState<SaleOrderProfile | undefined>(undefined)

  const _getSaleOrder = async (id: number) => {
    const response = await getSaleOrderProfile(id)
    if (response) {
      const { dueDate, ...saleOrderProfile } = { ...response.data }
      const parsedDueDate = dueDate ? new Date(dueDate) : undefined

      setProfile({
        ...saleOrderProfile,
        dueDate: parsedDueDate,
      })
    }
  }

  useEffect(() => {
    if (id) _getSaleOrder(id)
  }, [id, reload])

  return profile
}
