import { Button, MenuItem, Popover } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import React, { useState } from 'react'
import { FlexBox } from '../../../custom-components'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'

interface QuotationsProfileDotMenuProps {
  onClickEditQuotations: () => void
  onClickCancelQuotations: () => void
}

export const QuotationsProfileDotMenu: React.FC<QuotationsProfileDotMenuProps> = ({
  onClickEditQuotations,
  onClickCancelQuotations,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const menuOpened = Boolean(anchorEl)

  const writeQuoationPermission = getUserPermissionInfo(PermissionEnum.Sales_Quotation_Write)
  const cancelQuoationPermission = getUserPermissionInfo(PermissionEnum.Sales_Quotation_Cancel)

  return (
    <FlexBox>
      <Button variant="contained" size="large" onClick={handleClick}>
        <MoreHoriz />
      </Button>
      <Popover
        open={menuOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ConditionTooltip
          title={writeQuoationPermission.alertMessage}
          showTooltip={!writeQuoationPermission.hasPermission}
        >
          <MenuItem
            disabled={!writeQuoationPermission.hasPermission}
            onClick={() => {
              onClickEditQuotations()
            }}
          >
            แก้ไขใบเสนอราคา
          </MenuItem>
        </ConditionTooltip>
        <ConditionTooltip
          title={cancelQuoationPermission.alertMessage}
          showTooltip={!cancelQuoationPermission.hasPermission}
        >
          <MenuItem
            disabled={!cancelQuoationPermission.hasPermission}
            onClick={() => {
              onClickCancelQuotations()
            }}
          >
            ยกเลิกใบเสนอราคา
          </MenuItem>
        </ConditionTooltip>
      </Popover>
    </FlexBox>
  )
}
