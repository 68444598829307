import { Box, Paper, Typography } from '@material-ui/core'
import numeral from 'numeral'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { calculatePriceBreakdowns } from '../../../../../../sales-system/utils/sale-order-price-calculator'
import SOUpdationFormValue from '../SOUpdationFormValues'

const NetTotalContent: React.FC = () => {
  const { getValues } = useFormContext<SOUpdationFormValue>()
  const priceBreakdowns = calculatePriceBreakdowns(getValues())

  return (
    <Box padding={2} display="flex" justifyContent="space-between" alignItems="center" clone>
      <Paper>
        <Typography variant="h5">ยอดรวมสุทธิ</Typography>

        <Box color="secondary.dark">
          <Typography variant="h6">{`${numeral(priceBreakdowns.total).format('0,0.00')} บาท`}</Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default NetTotalContent
