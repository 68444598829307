import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles, Typography, TypographyProps } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { SaleOrderStatus } from '../../enums'
import { BaseData } from '../../api/smartbestbuys-api/types'

interface Props {
  status: BaseData
  icon?: boolean
  chip?: boolean
}

const useStyles = (status: SaleOrderStatus) =>
  makeStyles((theme) => {
    const soStatusMap = {
      [SaleOrderStatus.Pending]: theme.palette.warning.main,
      [SaleOrderStatus.PendingPayment]: theme.palette.warning.main,
      [SaleOrderStatus.PendingDepositPayment]: theme.palette.warning.main,
      [SaleOrderStatus.PendingDelivery]: theme.palette.warning.main,
      [SaleOrderStatus.Delivering]: theme.palette.warning.main,
      [SaleOrderStatus.WaitingCustomerPickUp]: theme.palette.warning.main,
      [SaleOrderStatus.Closed]: theme.palette.success.main,
      [SaleOrderStatus.ClosedAtShop]: theme.palette.success.main,
      [SaleOrderStatus.Cancelled]: theme.palette.error.main,
    }

    const soStatusIconMap = {
      [SaleOrderStatus.Pending]: theme.palette.warning.darkText,
      [SaleOrderStatus.PendingPayment]: theme.palette.warning.darkText,
      [SaleOrderStatus.PendingDepositPayment]: theme.palette.warning.darkText,
      [SaleOrderStatus.PendingDelivery]: theme.palette.warning.darkText,
      [SaleOrderStatus.Delivering]: theme.palette.warning.darkText,
      [SaleOrderStatus.WaitingCustomerPickUp]: theme.palette.warning.darkText,
      [SaleOrderStatus.Closed]: theme.palette.success.darkText,
      [SaleOrderStatus.ClosedAtShop]: theme.palette.success.darkText,
      [SaleOrderStatus.Cancelled]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: soStatusMap[status],
      },
      icon: {
        color: soStatusIconMap[status],
      },
      typography: {
        color: soStatusMap[status],
      },
    })
  })

const SOStatus: React.FC<Props & Omit<ChipProps, 'icon'> & TypographyProps> = ({
  children,
  status,
  icon,
  chip,
  ...othersProps
}) => {
  const classes = useStyles(status.id)()
  if (chip)
    return (
      <Chip
        classes={{
          icon: classes.icon,
        }}
        className={classes.chip}
        icon={icon ? <Description /> : undefined}
        {...othersProps}
      />
    )

  return (
    <Typography className={classes.typography} variant="body2" {...othersProps}>
      {status.name}
    </Typography>
  )
}

export default SOStatus
