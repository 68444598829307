import React, { useState } from 'react'
import { Avatar, Dialog, ButtonBase } from '@material-ui/core'
import { FlexBox } from '../../custom-components'
import { useStyles } from './styles'

interface Props {
  name: string
  src: string
  size?: 'big'
}

const PhotoDisplay: React.FC<Props> = ({ name, src, size }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const togglePreview = () => setOpen((prev) => !prev)

  return (
    <>
      <Dialog open={open} onClose={togglePreview}>
        <img src={src} alt={name} />
      </Dialog>
      <FlexBox flexDirection="column" alignItems="center" gridGap={8}>
        <ButtonBase onClick={togglePreview}>
          <Avatar className={size === 'big' ? classes.avatarBig : classes.avatar} src={src} variant="square" />
        </ButtonBase>
      </FlexBox>
    </>
  )
}

export default PhotoDisplay
