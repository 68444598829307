import React, { useContext, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Topic, withLayout } from '../custom-components'
import { RootContext } from '..'
import { PageName } from '../enums'

const HomePage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  useEffect(() => setCurrentPage(PageName.HOME), [setCurrentPage])
  return (
    <Grid container spacing={1}>
      <Topic color="primary">Welcome to SmartBestBuys Backoffice</Topic>
    </Grid>
  )
}

export default withLayout(HomePage)
