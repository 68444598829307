import React, { FC } from 'react'
import ReactPDF, { View, StyleSheet } from '@react-pdf/renderer'

interface Props {
  head?: boolean
  width?: number
  textAlign?: 'center' | 'right'
  flexDirection?: 'column'
}

const PDFTableCellView: FC<ReactPDF.ViewProps & Props> = (props) => {
  const { textAlign, width, head, flexDirection, ...viewProps } = props

  const styles = StyleSheet.create({
    tr: {
      padding: '4px 8px',
      textAlign: textAlign ? textAlign : undefined,
      width: width ? width : undefined,
      borderColor: head ? '#222021' : undefined,
      display: 'flex',
      flexDirection: flexDirection ?? 'row',
    },
  })

  const { style, ...otherProps } = viewProps
  return (
    <View
      style={{
        ...styles.tr,
        ...style,
      }}
      {...otherProps}
    />
  )
}

export default PDFTableCellView
