import { PDFProps } from './props';

const borderColorPallete: any = {
  dark: '#6A6969',
  light: '#C5BEC0',
  black: '#222021'
}

export const getViewStyles = (props: PDFProps) => {
  const { borders, borderColor } = props;

  const BORDER_COLOR = borderColor ? borderColorPallete[borderColor] : borderColorPallete.black;

  const getBorderCss = (border: 'bottom' | 'left' | 'right' | 'top') => {
    switch (border) {
      case 'bottom':
        return {
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: BORDER_COLOR
        }
      case 'top':
        return {
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: BORDER_COLOR
        }
      case 'left':
        return {
          borderLeftWidth: 1,
          borderLeftStyle: 'solid',
          borderLeftColor: BORDER_COLOR
        }
      case 'right':
        return {
          borderRightWidth: 1,
          borderRightStyle: 'solid',
          borderRightColor: BORDER_COLOR
        }
      default:
        return {}
    }
  }

  let borderStyles = {}
  borders?.forEach(b => {
    const style = getBorderCss(b);
    borderStyles = {
      ...borderStyles,
      ...style
    }
  }) 

  return {
    ...borderStyles
  }
}