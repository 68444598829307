import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useState } from 'react'
import { contactChannels } from '../../../types'
import { format } from 'date-fns'
import { Loading } from '../../../shared-components'
import { useReportSalesMonthlyDaily } from '../../../hooks/sales-report'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { formatNumber } from '../../../functions/number-utils'

const useStyles = makeStyles({
  table: {
    minWidth: 750,
  },
})

const MarketingSalesGoalMonthlyDashboard: React.FC = (props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const styles = useStyles()

  const sales = useReportSalesMonthlyDaily(selectedDate)
  if (!sales) return <Loading />

  const handleDateChange = async (date: Date | null) => {
    if (date) setSelectedDate(date)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="yyyy"
            views={['year']}
            margin="normal"
            id="date-picker-inline"
            label="เลือกช่วงเวลา"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="spanning table" className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">ปี {format(selectedDate, 'yyyy')}</TableCell>
                <TableCell align="center">ช่องทางการขาย</TableCell>
                {contactChannels.map((row) => (
                  <TableCell align="center">{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sales?.data &&
                sales?.data.map((row, index) => {
                  const month = row.month.toString.length === 1 ? `0${row.month}` : row.month.toString()
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell rowSpan={3}>{format(new Date(`${row.year}-${month}`), 'MMM')}</TableCell>
                        <TableCell align="center">เป้าหมาย</TableCell>
                        {row.contactChannels.map((cc) => {
                          return <TableCell align="center">{formatNumber(cc.salesGoal ?? 0)}</TableCell>
                        })}
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell align="center">ยอดขาย</TableCell>
                        {row.contactChannels.map((cc) => {
                          const successSale = cc.salesTotal > cc.salesGoal
                          return (
                            <TableCell align="center">
                              {' '}
                              <Box color={successSale ? 'success.main' : 'primary.main'} clone>
                                <Typography>{formatNumber(cc.salesTotal ?? 0)}</Typography>
                              </Box>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell align="center">ผลต่าง</TableCell>
                        {row.contactChannels.map((cc) => {
                          const successSale = cc.salesTotal > cc.salesGoal
                          return (
                            <TableCell align="center">
                              {' '}
                              <Box color={successSale ? 'success.main' : 'primary.main'} clone>
                                <Typography>{formatNumber(cc.percentDiff ?? 0)}%</Typography>
                              </Box>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default MarketingSalesGoalMonthlyDashboard
