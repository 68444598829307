import { FC, useState, useEffect, useContext, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { withWidth, isWidthUp, Button, Box, CircularProgress } from '@material-ui/core'
import { withLayout, Grid, Form, Topic } from '../../../custom-components'
import { CustomerCard, Loading } from '../../../shared-components'
import {
  SelectedProductTable,
  SaleOrderForm,
  CustomerPickupOptionBox,
  DepositOptionBox,
  AttachmentArea,
  SaleOrderInfo,
} from '../../components'
import SOCreationFormValues from './SOCreationFormValues'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { CustomerPickUpMethod } from '../../../types'
import { createSaleOrder } from './service'
import { useCustomerProfile } from '../../../hooks'
import { useHistory } from 'react-router'
import { useCustomerPORefs } from '../../../hooks/useCustomerPORefs'
import { RootContext } from '../../..'
import { PageName, PaymentCondition, PaymentMethod } from '../../../enums'
import { useQuotationsProfile } from '../../../hooks/quotation'
import { SelectedProduct } from '../../types'
import './SOCreationPage.less'

interface QueryParams {
  customerId?: number
  contactId?: number
  quotationId?: number
}

interface Props {
  query: QueryParams
  width: Breakpoint
}

export enum PageMode {
  CUSTOMER_ID,
  QUOTATION_ID,
}

const SOCreationPage: FC<Props> = (props) => {
  const [step, setStep] = useState(0)
  const [mode, setMode] = useState<PageMode>(PageMode.CUSTOMER_ID)
  const [loadingQuotation, setLoadingQuotation] = useState(false)
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)

  setCurrentPage(PageName.SALE_ORDER)

  const history = useHistory()

  const { query, width } = props
  // TODO: rename
  const { customerId, contactId, quotationId } = query

  useEffect(() => {
    if (quotationId) setMode(PageMode.QUOTATION_ID)
    if (customerId) setMode(PageMode.CUSTOMER_ID)
  }, [customerId, quotationId])

  const quotationProfile = useQuotationsProfile(quotationId)

  const customerIdParam = useMemo(() => {
    if (mode === PageMode.QUOTATION_ID) return quotationProfile?.customer.id
    return customerId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, mode, quotationProfile?.id])

  const contactIdParam = useMemo(() => {
    if (mode === PageMode.QUOTATION_ID) return quotationProfile?.contact?.id
    return contactId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, mode, quotationProfile?.id])

  const customerProfile = useCustomerProfile(customerIdParam)
  const customerPORefs = useCustomerPORefs(customerIdParam)

  const methods = useForm<SOCreationFormValues>({
    defaultValues: {
      selectedProducts: [],
      vatIncluded: true,
      currencyId: 1,
      exchangeRate: 1,
      customerPickUpMethod: CustomerPickUpMethod.RequestShipping,
      attachments: [],
      depositRequired: false,
      isShippingFeeIncludingVat: false,
    },
  })

  const {
    getValues,
    register,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { isSubmitting },
  } = methods
  const { customerPickUpMethod, selectedProducts } = getValues()

  const { paymentMethodId, paymentConditionId } = watch()

  useEffect(() => {
    register('paymentConditionId', {
      required: { value: true, message: 'กรุณาเลือกเงื่อนไขการชำระเงิน' },
      valueAsNumber: true,
    })
    register('paymentMethodId', {
      required: { value: true, message: 'กรุณาเลือกวิธีการชำระเงิน' },
      valueAsNumber: true,
    })
    register('dueDate', {
      required: {
        value: true,
        message: 'กรุณาเลือกวันที่ลูกค้ารับของ',
      },
      valueAsDate: true,
    })
    register('attachments')

    if (paymentConditionId && paymentMethodId) {
      // payment condition list that need payment due date
      const _paymentConditions = [PaymentCondition.PaymentRequire, PaymentCondition.PayAtDestination]

      // payment method list that need payment due date
      const _paymentMethods = [PaymentMethod.Cash, PaymentMethod.Transfer]

      const paymentDueDateRequired =
        _paymentConditions.includes(paymentConditionId) && _paymentMethods.includes(paymentMethodId)

      register('paymentDueDate', {
        required: {
          value: paymentDueDateRequired,
          message: 'กรุณาเลือกวันกำหนดชำระเงิน',
        },
        valueAsDate: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, customerPickUpMethod, paymentMethodId, paymentConditionId])

  useEffect(() => {
    if (mode === PageMode.QUOTATION_ID && quotationProfile) {
      setLoadingQuotation(true)
      const defaultValueFromQuotationProfile = {
        billingAddressId: quotationProfile.billingAddress?.id,
        selectedProducts: quotationProfile.products.map<SelectedProduct>((it) => ({
          id: it.id,
          code: it.code,
          nameLocal: it.nameLocal,
          nameEn: it.nameEn,
          unitLocal: it.unitLocal,
          unitEn: it.unitEn,
          imageUrl: it.imageUrl,
          amount: it.amount,
          price: it.price,
          stockAmount: it.stockAmount,
        })),
        discount: quotationProfile.discountAmount,
        discountEnabled: !!quotationProfile.discountAmount,
        shippingFee: quotationProfile.estimatedShippingFee,
        shippingFeeEnabled: !!quotationProfile.estimatedShippingFee,
        isShippingFeeIncludingVat: quotationProfile.isShippingFeeIncludingVat,
        vatIncluded: quotationProfile.vatIncluded,
        contactChannelId: quotationProfile.contactChannel?.id,
        exchangeRate: quotationProfile.exchangeRate,
        currencyId: quotationProfile.currency.id,
        paymentMethodId: quotationProfile.paymentMethod.id,
        remark: quotationProfile.remark,
        customerPickUpMethod: CustomerPickUpMethod.RequestShipping,
        depositRequired: false,
        attachments: [],
        dueDate: quotationProfile.dueDate ? new Date(quotationProfile.dueDate) : null,
      }
      reset(defaultValueFromQuotationProfile)
      setLoadingQuotation(false)
    }
  }, [mode, quotationProfile, reset])

  // console.log('watch', watch())
  methods.watch()

  const validateSelectedProducts = (): boolean => {
    if (!selectedProducts.length) {
      setError('selectedProducts', {
        type: 'required',
        message: 'กรุณาเลือกสินค้า',
      })
      scrollTo('#product-selector')
      return false
    } else {
      clearErrors('selectedProducts')
      return true
    }
  }

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  if (!customerProfile) return <Loading />
  if (mode === PageMode.CUSTOMER_ID && !customerId) return <Loading />
  if (loadingQuotation || (mode === PageMode.QUOTATION_ID && !quotationId && !quotationProfile)) return <Loading />

  return (
    <FormProvider {...methods}>
      <Box className="sale-order-create-page-header">
        <Topic>สร้างใบสั่งขาย</Topic>
      </Box>
      <Form
        onSubmit={async (data: SOCreationFormValues) => {
          if (step === 0 && !validateSelectedProducts()) {
            return
          } else {
            setStep(1)
          }

          if (step === 1 && customerProfile) {
            try {
              const response = await createSaleOrder(data, customerProfile, contactIdParam, quotationId)
              if (response?.status === 201) {
                triggerSnackbar('สร้างใบสั่งขายสำเร็จ')
                const createdSaleOrder = response.data
                history.push('/sale-orders/' + createdSaleOrder.id)
              } else {
                triggerSnackbar('สร้างใบสั่งขายไม่สำเร็จ')
              }
            } catch (err) {
              triggerSnackbar('สร้างใบสั่งขายไม่สำเร็จ')
            }
          }
          return true
        }}
        onInvalid={(errors: SOCreationFormValues, e) => {
          if (!validateSelectedProducts()) {
            return
          }

          if (errors.paymentConditionId || errors.paymentMethodId) {
            scrollTo('#sale-order-form', 'center')
          }
        }}
      >
        {
          [
            <Grid container spacing={isWidthUp('sm', width) ? 3 : 2} style={{ marginBottom: 40 }}>
              <Grid item xs={12}>
                <CustomerCard customerProfile={customerProfile} billingAddressSelector contactId={contactIdParam} />
              </Grid>
              <Grid item xs={12}>
                <SelectedProductTable readOnly showPriceBreakdown />
              </Grid>
              <Grid item xs={12} id="sale-order-form">
                <SaleOrderForm
                  pageMode={mode}
                  customerPORefs={customerPORefs}
                  quotationProfile={quotationProfile}
                  customerProfile={customerProfile}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomerPickupOptionBox />
              </Grid>
              <Grid item xs={12}>
                <DepositOptionBox />
              </Grid>
              <Grid item xs={12}>
                <AttachmentArea />
              </Grid>
              <Grid item xs={12} container justify="flex-end">
                <Box mr={2} clone>
                  <Button color="primary">ยกเลิก</Button>
                </Box>
                <Button variant="contained" color="primary" type="submit">
                  ตรวจสอบข้อมูล
                </Button>
              </Grid>
            </Grid>,
            <Grid container spacing={isWidthUp('sm', width) ? 3 : 2} style={{ marginBottom: 40 }}>
              <Grid item xs={12}>
                <CustomerCard customerProfile={customerProfile} />
              </Grid>
              <Grid item xs={12}>
                <SelectedProductTable readOnly showPriceBreakdown />
              </Grid>
              <Grid item xs={12}>
                <SaleOrderInfo customerProfile={customerProfile} />
              </Grid>
              <Grid item xs={12}>
                <AttachmentArea readOnly />
              </Grid>
              <Grid item xs={12} container justify="flex-end">
                <Box mr={2} clone>
                  <Button color="primary" onClick={() => setStep(0)}>
                    ย้อนกลับแก้ไข
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={isSubmitting ? <CircularProgress /> : undefined}
                >
                  ยืนยันข้อมูล
                </Button>
              </Grid>
            </Grid>,
          ][step]
        }
      </Form>
    </FormProvider>
  )
}

export default withWidth()(withLayout(SOCreationPage))
