import { Box, Button, Grid } from '@material-ui/core'
import { Title, FlexBox, EmptyCard } from '../custom-components'
import { PermissionEnum } from '../enums/PermissionEnum'
import FileCard from '../pages/purchase-requisitions/create/AttachmentSelectionBox/FileCard'
import { SourceFile } from '../types'
import { getUserPermissionInfo } from '../utils/permission'
import ConditionTooltip from './ConditionTooltip'
import Space from './Space'

interface Props {
  files: SourceFile[]
  onUpdateButtonClick?: () => void
  children?: JSX.Element
}

// children for dialog now
const AttachmentBox = (props: Props) => {
  const { files, children, onUpdateButtonClick } = props

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_Vendor_Write)

  return (
    <>
      {children}
      <Box p={3}>
        <FlexBox justifyContent="space-between">
          <Title>ไฟล์แนบเพิ่มเติม</Title>
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <Button
              color="secondary"
              variant="contained"
              onClick={onUpdateButtonClick}
              disabled={!writePermission.hasPermission}
            >
              จัดการไฟล์แนบ
            </Button>
          </ConditionTooltip>
        </FlexBox>
        <Space />
        {files.length > 0 ? (
          <Grid container spacing={2}>
            {files.map((file, index) => (
              <Grid item xs={6} md={2} key={index}>
                <FileCard value={file} readonly />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box p={3} clone>
            <EmptyCard>ไม่มีข้อมูลเอกสาร</EmptyCard>
          </Box>
        )}
      </Box>
    </>
  )
}

export default AttachmentBox
