import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { UserActivityNote } from '../types'

export interface ProductStockUpdationRequest {
  transactionTypeId: number
  changedAmount: number
  remark?: string
  userActivity?: UserActivityNote
}

export const updateProductStock = errorSingleHandler<{ stockAmount: number }>(
  (id: number, request: ProductStockUpdationRequest) => {
    const path = `/products/${id}/stock-amount`
    return smartAuthAPI.patch(path, request)
  },
)
