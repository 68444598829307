import { Paper } from '@material-ui/core'
import { PaymentRequisitionUserActivity, PaymentRequisitionUserActivityToTable } from '../api/smartbestbuys-api'
import { SubTopic, Table, TColumn } from '../custom-components'
import { dateTimeFormat } from '../functions'
import { BaseUserActivity } from '../types'

interface Props<T> {
  title: string
  activities: T[]
  customColumns?: TColumn<T>[]
}

const UserActivityTable = <T extends BaseUserActivity>(props: Props<T>) => {
  const { title, activities, customColumns } = props

  return (
    <Paper>
      <SubTopic>{title}</SubTopic>
      <div className="mb-2" />
      <Table<T>
        color="grey.800"
        size="small"
        columns={[
          { name: 'การกระทำ', dataIndex: 'actionName' },
          { name: 'รายละเอียดเพิ่มเติม', dataIndex: 'actionMessage' },
          { name: 'จัดการโดย', dataIndex: 'recCreatedBy' },
          ...(customColumns ?? []),
          {
            name: 'วันที่',
            render: (ua) => dateTimeFormat(ua.recCreatedAt),
          },
          { name: 'หมายเหตุ', dataIndex: 'remark' },
        ]}
        data={activities}
        rowsPerPage={5}
        cleanSpace
      />
    </Paper>
  )
}

export default UserActivityTable

export const mapToUserActivityTableValue = (
  data: PaymentRequisitionUserActivity[],
): PaymentRequisitionUserActivityToTable[] => {
  return data.map((activity) => ({
    id: activity.id,
    actionId: activity.action.id,
    actionName: activity.action.name,
    actionMessageId: activity.actionMessage?.id,
    actionMessage: activity.actionMessage?.name,
    remark: activity.remark,
    recCreatedAt: activity.recCreatedAt,
    recCreatedBy: activity.recCreatedBy,
  }))
}
