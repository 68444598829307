import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { fetchTransportShippingTasksAPI, TransportShipingTaskMobileData } from '../../api/smartbestbuys-api'

import { dateSqlFormat } from '../../functions'

export const useTransportShippingTasks = (
  dueDate: Date,
): [TransportShipingTaskMobileData[], Dispatch<SetStateAction<TransportShipingTaskMobileData[]>>] => {
  const [data, setData] = useState<TransportShipingTaskMobileData[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await fetchTransportShippingTasksAPI({
        dueDate: dateSqlFormat(dueDate, 'yyyy-MM-dd'),
      })
      const data = response?.data ?? []
      setData(data)
    })()
  }, [dueDate])

  return [data, setData]
}
