import {
  ICustomerProfile_Address,
  ICustomerBillingAddress,
  ICustomerProfile_Contact,
} from '../../../../../api/smartbestbuys-api'
import CustomerContactUpdationFormValue from './CustomerContactList/CustomerContactDialog/CustomerContactUpdationDialog/CustomerContactUpdationFormValue'
import BillingDocumentCreationFormValue from './CustomerDocumentBox/BillingDocumentCreationDialog/BillingDocumentCreationDialogValue'
import CustomerShippingAddressCreationFormValue from './CustomerShippingAddressBox/CustomerShippingAddressCreationDialog/CustomerShippingAddressCreationFormValue'
import CustomerShippingAddressUpdationFormValue from './CustomerShippingAddressBox/CustomerShippingAddressCreationDialog/CustomerShippingAddressCreationFormValue'

export const mapCustomerAddressToCreationFormValues = (
  billingAddress: ICustomerProfile_Address,
): CustomerShippingAddressCreationFormValue => {
  const shippingAddress: CustomerShippingAddressCreationFormValue = {
    name: billingAddress.name ? billingAddress.name : `ที่อยู่ `,
    branchName: billingAddress.branchName,
    postalCode: Number(billingAddress.postalCode),
    provinceId: billingAddress.province?.id.toString(),
    districtId: '',
    subDistrictId: '',
    addressLine1: billingAddress.addressLine1,
    phoneNumber: billingAddress.phoneNumber,
    faxNumber: billingAddress.faxNumber,
    googleMapLink: '',
  }

  return shippingAddress
}

export const mapCustomerAddressToUpdationFormValues = (
  address: ICustomerProfile_Address,
  defaultIndexName?: number,
): CustomerShippingAddressUpdationFormValue => {
  const shippingAddress: CustomerShippingAddressUpdationFormValue = {
    name: address.name ? address.name : defaultIndexName ? `ที่อยู่ ${defaultIndexName}` : '',
    branchName: address.branchName,
    postalCode: Number(address.postalCode),
    provinceId: address.province?.id.toString(),
    districtId: address.district?.id.toString(),
    subDistrictId: address.subDistrict?.id.toString(),
    addressLine1: address.addressLine1,
    phoneNumber: address.phoneNumber,
    googleMapLink: address.googleMapLink || '',
  }

  return shippingAddress
}

export const mapBillingDocumentCreationFormToBillingNoteItem = (value: BillingDocumentCreationFormValue) => {
  return value.documents.map((document) => ({
    id: document.id,
    name: document.code,
    amount: 1,
    total: document.total || 0,
    issuedDate: document.recCreatedAt,
    expiredDate:
      'expiredDate' in document.state && document.state?.expiredDate
        ? document.state.expiredDate
        : document.recCreatedAt,
  }))
}

export const mapBillingAddressToFormValue = (value: ICustomerBillingAddress) => {
  return {
    name: value.name,
    branchName: value.branchName,
    provinceId: value.province?.id,
    districtId: value.district?.id,
    subDistrictId: value.subDistrict?.id,
    postalCode: value.postalCode,
    addressLine1: value.addressLine1,
    phoneNumber: value.phoneNumber,
    faxNumber: value.faxNumber,
    overrideCustomerName: value.overrideDocumentData?.customerName,
    overrideAddress: value.overrideDocumentData?.address,
    overrideTaxNumber: value.overrideDocumentData?.taxNumber,
  }
}

export const mapCustomerContactToUpdationFormValue = (
  contact: ICustomerProfile_Contact,
): CustomerContactUpdationFormValue => {
  const { nameLocal, nameEn, nicknameEn, customerDepartment, email, line, facebook, birthdate } = contact
  const customerContactFormValue: any = {
    nickname: contact.nicknameLocal,
    gender: contact.gender || '',
    phoneNumber: contact.phoneNumber,
  }

  if (nameLocal) customerContactFormValue.nameLocal = nameLocal
  if (nameEn) customerContactFormValue.nameEn = nameEn
  if (nicknameEn) customerContactFormValue.nicknameEn = nicknameEn
  if (customerDepartment) customerContactFormValue.customerDepartmentId = customerDepartment.id
  if (email) customerContactFormValue.email = email
  if (line) customerContactFormValue.line = line
  if (facebook) customerContactFormValue.facebook = facebook
  if (birthdate) customerContactFormValue.birthdate = birthdate

  return customerContactFormValue
}
