import { useEffect, useState } from 'react'
import { getAddressDataAPI } from '../../api/smartbestbuys-api/data/data-apis'
import { AddressData } from '../../api/smartbestbuys-api/data/data-types'

export const useAddressData = (): AddressData | undefined => {
  const [value, setValue] = useState<AddressData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getAddressDataAPI()
      if (response?.status === 200) {
        setValue(response?.data)
      }
    })()
  }, [])

  return value
}
