import React, { useState, useContext, useMemo } from 'react'
import { Button, Grid, IconButton, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { Description, Cancel, Edit } from '@material-ui/icons'
import { FlexBox, Link, SubTopic, Table, TColumn } from '../../../../../custom-components'
import { InvoiceCreationDialog } from './InvoiceCreationDialog'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import {
  ConditionTooltip,
  CreditNoteCreationDialog,
  DocumentCancellationDialog,
  DocumentStatusChip,
  Loading,
  MultipleConditionTooltip,
  TaxInvoiceUpdateDialog,
} from '../../../../../shared-components'
import { DocumentStatus, DocumentType, SaleOrderStatus } from '../../../../../enums'
import { CreditNoteState, SaleOrderDocument, SaleOrderProfile } from '../../../../../api/smartbestbuys-api'
import { calculateExcludingVat, calculateVatInAmount, dateTimeFormat } from '../../../../../functions'
import { TaxInvoiceCreationDialog } from './TaxInvoiceCreationDialog'
import { RootContext } from '../../../../..'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'

interface State {
  saleOrderProfile: SaleOrderProfile
  setSelectedDocument: (document: SaleOrderDocument<any>) => void
  setCreateTaxInvoice: (boolean: boolean) => void
  setCancelDocument: (boolean: boolean) => void
  setCreateCreditNote: (boolean: boolean) => void
  setUpdateDocument?: (boolean: boolean) => void
}

const SODocumentList: React.FC = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { saleOrderProfile, reload } = useContext(SaleOrderProfileContext)
  const [createInvoice, setCreateInvoice] = useState(false)
  const [createTaxInvoice, setCreateTaxInvoice] = useState(false)
  const [createCreditNote, setCreateCreditNote] = useState(false)
  const [cancelDocument, setCancelDocument] = useState(false)
  const [updateDocument, setUpdateDocument] = useState(false)
  const [documentType, setDocumentType] = useState(DocumentType.Invoice)
  const [selectedDocument, setSelectedDocument] = useState<SaleOrderDocument<any> | undefined>(undefined)

  const taxInvoices = useMemo(() => {
    return saleOrderProfile?.documents?.filter((d) => d.type.id === DocumentType.TaxInvoice) ?? []
  }, [saleOrderProfile?.documents])

  const creditNotes = useMemo(() => {
    return saleOrderProfile?.documents?.filter((d) => d.type.id === DocumentType.CreditNote) ?? []
  }, [saleOrderProfile?.documents])

  const validCreditNoteTotal = creditNotes.reduce<number>((sumOfTotal, creditNote) => {
    return creditNote.status.id === DocumentStatus.Cancelled ? sumOfTotal : sumOfTotal + (creditNote.total ?? 0)
  }, 0)

  const validTaxInvoiceTotal = taxInvoices.reduce<number>((sumOfTotal, taxInvoice) => {
    return taxInvoice.status.id === DocumentStatus.Cancelled ? sumOfTotal : sumOfTotal + (taxInvoice.total ?? 0)
  }, 0)

  if (!saleOrderProfile) return <Loading />

  const { documents, total, status, checklistToCompleteCancel } = saleOrderProfile

  const isCancelledSO = status.id === SaleOrderStatus.Cancelled

  const ableToIssueCreditNoteWithTaxInvoice = validCreditNoteTotal < validTaxInvoiceTotal

  const ableToCreateTaxInvoice =
    taxInvoices.length === 0 || // Every SO could issue at least 1 tax invoice (supported to SO total = 0)
    validTaxInvoiceTotal - validCreditNoteTotal < total

  const state: State = {
    saleOrderProfile,
    setSelectedDocument,
    setCreateTaxInvoice,
    setCancelDocument,
    setCreateCreditNote,
    setUpdateDocument,
  }

  // permission
  const writeSODocumentPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Write)

  // conditions
  const createCreditNoteConditions = [
    { showTooltip: !writeSODocumentPermission.hasPermission, message: writeSODocumentPermission.alertMessage },
    { showTooltip: !ableToIssueCreditNoteWithTaxInvoice, message: 'สร้างใบลดหนี้ครบยอดใบกำกับภาษีแล้ว' },
    {
      showTooltip: !documents?.find((d) => d.type.id === DocumentType.TaxInvoice),
      message: 'ไม่มีใบกำกับภาษีที่ต้องเปิดใบลดหนี้',
    },
    {
      showTooltip: isCancelledSO && !checklistToCompleteCancel?.createCreditNote,
      message: 'ใบสั่งขายถูกยกเลิกแล้ว',
    },
  ]

  const createTaxInvoiceConditions = [
    { showTooltip: !writeSODocumentPermission.hasPermission, message: writeSODocumentPermission.alertMessage },
    { showTooltip: !ableToCreateTaxInvoice, message: 'สร้างใบกำกับภาษีครบแล้ว' },
    { showTooltip: isCancelledSO, message: 'ใบสั่งขายถูกยกเลิกแล้ว' },
  ]

  return (
    <Paper>
      <InvoiceCreationDialog
        saleOrderProfile={saleOrderProfile}
        open={createInvoice}
        onClose={() => {
          setCreateInvoice(false)
          setDocumentType(DocumentType.Invoice)
        }}
      />
      <TaxInvoiceCreationDialog
        saleOrderProfile={saleOrderProfile}
        open={createTaxInvoice}
        refInvoice={selectedDocument}
        onClose={() => {
          setCreateTaxInvoice(false)
          setSelectedDocument(undefined)
        }}
        onSuccess={() => {
          setCreateTaxInvoice(false)
          setSelectedDocument(undefined)
          setDocumentType(DocumentType.TaxInvoice)
        }}
      />
      <CreditNoteCreationDialog
        open={createCreditNote}
        customerId={saleOrderProfile.customer.id}
        saleOrderProfile={saleOrderProfile}
        taxInvoice={selectedDocument}
        onClose={() => {
          setCreateCreditNote(false)
          setSelectedDocument(undefined)
        }}
        onSuccess={() => {
          setCreateCreditNote(false)
          setSelectedDocument(undefined)
          setDocumentType(DocumentType.CreditNote)
        }}
      />
      {selectedDocument && (
        <DocumentCancellationDialog
          documentInfo={selectedDocument}
          open={cancelDocument}
          onClose={() => setCancelDocument(false)}
          onSuccess={() => {
            triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
            reload()
          }}
          onFail={() => {
            triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
          }}
        />
      )}
      {selectedDocument && (
        <TaxInvoiceUpdateDialog
          saleOrderProfile={saleOrderProfile}
          documentInfo={selectedDocument}
          open={updateDocument}
          onClose={() => setUpdateDocument(false)}
          onSuccess={() => {
            triggerSnackbar('แก้ไขเอกสารสำเร็จ')
            reload()
          }}
          onFail={() => {
            triggerSnackbar('แก้ไขเอกสารไม่สำเร็จ')
            setUpdateDocument(false)
          }}
        />
      )}
      <Grid container spacing={2} direction="row-reverse">
        <Grid item xs={12} md={6}>
          <FlexBox gridGap={8} justifyContent="flex-end">
            <ConditionTooltip
              showTooltip={!writeSODocumentPermission.hasPermission}
              title={writeSODocumentPermission.alertMessage}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Description />}
                onClick={() => setCreateInvoice(true)}
                disabled={!writeSODocumentPermission.hasPermission || isCancelledSO}
              >
                สร้างใบแจ้งหนี้
              </Button>
            </ConditionTooltip>
            <MultipleConditionTooltip conditionItems={createTaxInvoiceConditions}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Description />}
                onClick={() => setCreateTaxInvoice(true)}
                disabled={createTaxInvoiceConditions.some((cond) => cond.showTooltip)}
              >
                สร้างใบกำกับภาษี
              </Button>
            </MultipleConditionTooltip>
            <MultipleConditionTooltip conditionItems={createCreditNoteConditions}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Description />}
                disabled={createCreditNoteConditions.some((cond) => cond.showTooltip)}
                onClick={() => setCreateCreditNote(true)}
              >
                สร้างใบลดหนี้
              </Button>
            </MultipleConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <SubTopic>เอกสารที่เกี่ยวข้อง</SubTopic>
        </Grid>
        <Grid item xs={12}>
          <Paper square style={{ padding: 0 }}>
            <Tabs
              value={documentType}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) => setDocumentType(newValue)}
              aria-label="disabled tabs example"
              centered
              variant="fullWidth"
            >
              <Tab label="ใบแจ้งหนี้" value={DocumentType.Invoice} />
              <Tab label="ใบกำกับภาษี" value={DocumentType.TaxInvoice} />
              <Tab label="ใบลดหนี้" value={DocumentType.CreditNote} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Table<SaleOrderDocument<any>>
            size="small"
            color="primary.dark"
            columns={getDocumentColumn(documentType, state)}
            data={
              documents
                ?.filter((d) => d.type.id === documentType)
                ?.sort((a, b) => new Date(b.recCreatedAt).getTime() - new Date(a.recCreatedAt).getTime()) ?? []
            }
            cleanSpace
            pagination={false}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SODocumentList

const renderDocumentActions = (
  data: SaleOrderDocument<any>,
  onSelect: () => void,
  documentType: DocumentType,
  onCancel: () => void,
  onUpdate?: () => void,
  documents?: SaleOrderDocument<any>[],
) => {
  const isCanceled = data.status.id === DocumentStatus.Cancelled

  // permissions
  const writeSODocumentPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Write)
  const cancelSODocumentPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  switch (documentType) {
    case DocumentType.Invoice:
      const issuedTaxInvoice = documents?.find(
        (d) =>
          d.type.id === DocumentType.TaxInvoice &&
          d.referenceDocument?.id === data.id &&
          d.status.id !== DocumentStatus.Cancelled,
      )

      const createTaxInvoiceConditions = [
        { showTooltip: !writeSODocumentPermission.hasPermission, message: writeSODocumentPermission.alertMessage },
        { showTooltip: !!issuedTaxInvoice, message: `สร้างใบกำกับภาษีแล้ว ${issuedTaxInvoice?.code}` },
        { showTooltip: true, message: `สร้างใบกำกับภาษี` },
      ]

      return isCanceled ? (
        <></>
      ) : (
        <FlexBox gridGap={8}>
          <MultipleConditionTooltip conditionItems={createTaxInvoiceConditions} placement="top">
            <IconButton
              size="small"
              color="secondary"
              onClick={onSelect}
              disabled={createTaxInvoiceConditions.slice(0, 2).some((cond) => cond.showTooltip)}
            >
              <Description fontSize="small" />
            </IconButton>
          </MultipleConditionTooltip>

          <ConditionTooltip
            showTooltip={true}
            title={
              cancelSODocumentPermission.hasPermission ? 'ยกเลิกใบแจ้งหนี้' : cancelSODocumentPermission.alertMessage
            }
            placement="top"
          >
            <IconButton size="small" onClick={onCancel} disabled={!cancelSODocumentPermission.hasPermission}>
              <Cancel fontSize="small" />
            </IconButton>
          </ConditionTooltip>
        </FlexBox>
      )
    case DocumentType.TaxInvoice:
    case DocumentType.CreditNote:
      const issuedCreditNote = documents?.find(
        (d) =>
          d.type.id === DocumentType.CreditNote &&
          d.referenceDocument?.id === data.id &&
          d.status.id !== DocumentStatus.Cancelled,
      )

      const createCreditNoteConditions = [
        { showTooltip: !writeSODocumentPermission.hasPermission, message: writeSODocumentPermission.alertMessage },
        { showTooltip: !!issuedCreditNote, message: `สร้างใบลดหนี้แล้ว ${issuedCreditNote?.code}` },
        { showTooltip: true, message: `สร้างใบลดหนี้` },
      ]

      return (
        <FlexBox gridGap={8}>
          {data.type.id === DocumentType.TaxInvoice && !!data.referenceDocument && isCanceled && (
            <MultipleConditionTooltip conditionItems={createCreditNoteConditions} placement="top">
              <IconButton
                size="small"
                color="secondary"
                onClick={onSelect}
                disabled={createCreditNoteConditions.slice(0, 2).some((cond) => cond.showTooltip)}
              >
                <Description fontSize="small" />
              </IconButton>
            </MultipleConditionTooltip>
          )}
          {!isCanceled && (
            <FlexBox gridGap={8}>
              {data.type.id === DocumentType.TaxInvoice && data.status.id === DocumentStatus.Pending && (
                <Grid item xs={12}>
                  <ConditionTooltip
                    showTooltip={true}
                    title={
                      writeSODocumentPermission.hasPermission
                        ? 'แก้ไขใบกำกับภาษี'
                        : writeSODocumentPermission.alertMessage
                    }
                    placement="top"
                  >
                    <IconButton size="small" onClick={onUpdate} disabled={!writeSODocumentPermission.hasPermission}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </ConditionTooltip>
                </Grid>
              )}
              <Grid item xs={12}>
                <ConditionTooltip
                  showTooltip={true}
                  title={
                    cancelSODocumentPermission.hasPermission ? 'ยกเลิกเอกสาร' : cancelSODocumentPermission.alertMessage
                  }
                  placement="top"
                >
                  <IconButton size="small" onClick={onCancel} disabled={!cancelSODocumentPermission.hasPermission}>
                    <Cancel fontSize="small" />
                  </IconButton>
                </ConditionTooltip>
              </Grid>
            </FlexBox>
          )}
        </FlexBox>
      )
    default:
      return <></>
  }
}

const getDocumentColumn = (documentType: DocumentType, state: State) => {
  const {
    setSelectedDocument,
    setCreateTaxInvoice,
    setCancelDocument,
    setCreateCreditNote,
    setUpdateDocument,
    saleOrderProfile,
  } = state

  const { vatIncluded } = saleOrderProfile

  switch (documentType) {
    case DocumentType.Invoice:
      return [
        {
          name: 'สถานะใบแจ้งหนี้',
          render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
        },
        {
          name: 'เลขใบแจ้งหนี้',
          render: ({ id, code }) => (
            <Link color="primary" to={`/invoices/${id}/pdf`} target="_blank">
              {code}
            </Link>
          ),
        },
        {
          name: 'รายได้อ้างอิง (SPAY)',
          render: ({ paymentTransaction }) =>
            paymentTransaction?.code ? (
              <Typography color="secondary" variant="body2">
                {paymentTransaction.code}
              </Typography>
            ) : (
              <Typography color="textSecondary" variant="body2">
                -
              </Typography>
            ),
        },
        { name: 'วันที่สร้าง', render: (invoice) => dateTimeFormat(invoice.recCreatedAt) },
        { name: 'ยอดที่ต้องชำระ', numeric: true, format: '0,0.00', dataIndex: 'total' },
        {
          name: '',
          render: (data) =>
            renderDocumentActions(
              data,
              () => {
                setSelectedDocument(data)
                setCreateTaxInvoice(true)
              },
              documentType,
              () => {
                setSelectedDocument(data)
                setCancelDocument(true)
              },
              () => {
                setSelectedDocument(data)
              },
              saleOrderProfile.documents,
            ),
        },
      ] as TColumn<SaleOrderDocument<any>>[]
    case DocumentType.TaxInvoice:
      return [
        {
          name: 'สถานะใบกำกับภาษี',
          render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
        },
        {
          name: 'เลขใบกำกับภาษี',
          render: ({ id, code }) => (
            <Link color="primary" to={`/tax-invoices/${id}/pdf`} target="_blank">
              {code}
            </Link>
          ),
        },
        {
          name: 'เลขใบแจ้งหนี้อ้างอิง',
          render: ({ referenceDocument }) =>
            referenceDocument ? (
              <Link color="secondary" to={`/invoices/${referenceDocument.id}/pdf`}>
                {referenceDocument?.code}
              </Link>
            ) : (
              <Typography color="textSecondary" variant="body2">
                สร้างจากใบสั่งขาย
              </Typography>
            ),
        },
        {
          name: 'รายได้อ้างอิง (SPAY)',
          render: ({ paymentTransaction }) =>
            paymentTransaction?.code ? (
              <Typography color="secondary" variant="body2">
                {paymentTransaction.code}
              </Typography>
            ) : (
              <Typography color="textSecondary" variant="body2">
                -
              </Typography>
            ),
        },
        { name: 'วันที่สร้าง', render: (invoice) => dateTimeFormat(invoice.recCreatedAt) },
        {
          name: 'ยอดก่อน VAT',
          numeric: true,
          format: '0,0.00',
          render: ({ total }) => (vatIncluded ? calculateExcludingVat(total || 0) : total),
        },
        {
          name: 'VAT',
          numeric: true,
          format: '0,0.00',
          render: ({ total }) => (vatIncluded ? calculateVatInAmount(total || 0) : 0),
        },
        { name: 'ยอดชำระ', numeric: true, format: '0,0.00', dataIndex: 'total' },
        {
          name: '',
          render: (data) =>
            renderDocumentActions(
              data,
              () => {
                setSelectedDocument(data)
                setCreateCreditNote(true)
              },
              documentType,
              () => {
                setSelectedDocument(data)
                setCancelDocument(true)
              },
              () => {
                setSelectedDocument(data)
                setUpdateDocument && setUpdateDocument(true)
              },
              saleOrderProfile.documents,
            ),
        },
      ] as TColumn<SaleOrderDocument<any>>[]
    default:
      return [
        {
          name: 'สถานะใบลดหนี้',
          render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
        },
        {
          name: 'เลขใบลดหนี้',
          render: ({ id, code }) => (
            <Link color="primary" to={`/credit-notes/${id}/pdf`} target="_blank">
              {code}
            </Link>
          ),
        },
        {
          name: 'เลขใบกำกับภาษีอ้างอิง',
          render: ({ referenceDocument, state }) => {
            const refs = state?.taxInvoiceReferences || []
            if (referenceDocument) {
              return (
                <Link color="secondary" to={`/tax-invoices/${referenceDocument.id}/pdf`}>
                  {referenceDocument.code}
                </Link>
              )
            } else if (refs.length > 0) {
              return (
                <Typography color="textSecondary" variant="body2">
                  {refs.join(',')}
                </Typography>
              )
            }
            return (
              <Typography color="textSecondary" variant="body2">
                สร้างจากใบสั่งขาย
              </Typography>
            )
          },
        },
        { name: 'วันที่สร้าง', render: (invoice) => dateTimeFormat(invoice.recCreatedAt) },
        {
          name: 'ยอดก่อน VAT',
          numeric: true,
          format: '0,0.00',
          render: ({ total }) => calculateExcludingVat(total || 0),
        },
        { name: 'VAT', numeric: true, format: '0,0.00', render: ({ total }) => calculateVatInAmount(total || 0) },
        { name: 'ยอดเงินสุทธิ', numeric: true, format: '0,0.00', dataIndex: 'total' },
        {
          name: '',
          render: (data) =>
            renderDocumentActions(
              data,
              () => {
                setSelectedDocument(data)
                setCreateTaxInvoice(true)
              },
              documentType,
              () => {
                setSelectedDocument(data)
                setCancelDocument(true)
              },
              () => {
                setSelectedDocument(data)
              },
              saleOrderProfile.documents,
            ),
        },
      ] as TColumn<SaleOrderDocument<CreditNoteState>>[]
  }
}
