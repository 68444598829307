import { useState, useEffect } from 'react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { ISalesDailyReport, getReportSalesGoalDailyAPI } from '../../api/smartbestbuys-api'

export const useReportSalesGoalDaily = (date: Date): ISalesDailyReport | undefined => {
  const [data, setData] = useState<ISalesDailyReport | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getReportSalesGoalDailyAPI({
        startDate: format(startOfMonth(date), 'yyyy-MM-dd'),
        endDate: format(endOfMonth(date), 'yyyy-MM-dd'),
      })
      if (response) {
        setData(response?.data)
      }
    })()
  }, [date])

  return data
}
