import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { createVendor, VendorVerifyRequestBody, verifyVendor } from '../../../api/smartbestbuys-api'
import { Form, Grid, Topic, withLayout } from '../../../custom-components'
import { Space, SubmitForm } from '../../../shared-components'
import { SourceFile } from '../../../types'
import { AttachmentSelectionBox } from '../../purchase-requisitions/create/AttachmentSelectionBox'
import { mapVendorCreateFromValuesToRequest } from './mapper'
import VendorAddressForm, { VendorCreateAddressFormValues } from './VendorAddressForm'
import VendorBankAccountForm from './VendorBankAccountForm'
import VendorCreateForm from './VendorCreateForm'

export interface VendorCreateFormValues {
  vendorName: string
  phoneNumber: string
  referenceNumber: string
  websiteUrl?: string
  base64?: string
  email?: string
  currencyId: number
  creditPeriodDay?: number
  scopeWork?: string
  remark?: string
  refPaymentMethodId?: number
  isVatVendor?: boolean
  contact: VendorCreateFormValues_Contact
  address: VendorCreateAddressFormValues
  bankAccount?: VendorCreateFormValues_Address
  attachments?: VendorCreateFormValues_Attachment[]

  enabledBankAccount: boolean
}

export interface VendorCreateFormValues_Contact {
  name: string
  phoneNumber: string
}

export interface VendorCreateFormValues_Attachment {
  base64: string
}

export interface VendorCreateFormValues_Address {
  bankId: number
  accountName: string
  accountNumber: string
  branchName: string
}

const VendorCreatePage: React.FC = () => {
  //context
  const { triggerSnackbar } = useContext(RootContext)
  const history = useHistory()

  // form
  const formMethod = useForm<VendorCreateFormValues>({
    defaultValues: { enabledBankAccount: false, isVatVendor: false },
  })
  const {
    setValue,
    setError,
    formState: { isSubmitting },
  } = formMethod

  // handler
  const handleAddAttachmentsToForm = (attachments: SourceFile[]) => {
    setValue(
      'attachments',
      attachments.map((att) => {
        return {
          ...att,
          base64: att.base64!,
        }
      }),
    )
  }

  const handleSubmit = async (values: VendorCreateFormValues) => {
    const verifyRequest: VendorVerifyRequestBody = {
      referenceNumber: values.referenceNumber,
    }
    const verifyResponse = await verifyVendor(verifyRequest)

    if (!verifyResponse?.data.verified) {
      setError('referenceNumber', {
        type: 'required',
        message: verifyResponse?.data.message,
      })
      return
    }

    const request = mapVendorCreateFromValuesToRequest(values)
    const response = await createVendor(request)

    if (response?.status === 201) {
      triggerSnackbar('สร้างผู้ขายสำเร็จ')
      history.replace(`/vendors`)
    } else {
      triggerSnackbar('สร้างผู้ขายไม่สำเร็จ')
    }
    return true
  }

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={handleSubmit}>
        <Topic>สร้างผู้ขาย</Topic>
        <Space />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VendorCreateForm />
          </Grid>
          <Grid item xs={12}>
            <VendorAddressForm name="address" />
          </Grid>
          <Grid item xs={12}>
            <VendorBankAccountForm name="bankAccount" />
          </Grid>
          <Grid item xs={12}>
            <AttachmentSelectionBox onChange={handleAddAttachmentsToForm} />
          </Grid>
          <Grid item xs={12}>
            <SubmitForm
              submitText="ยืนยันสร้างผู้ขาย"
              cancelText="ยกเลิก"
              onCancel={() => history.replace(`/vendors`)}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default withLayout(VendorCreatePage)
