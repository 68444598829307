import { useEffect, useState } from 'react'
import { BaseData, transportShippingPaymentDailySummaryTaskAPI, TransportTask } from '../../api/smartbestbuys-api'
import { PaymentMethod } from '../../enums'

export interface ITransportShippingPaymentDailySummaryFE {
  tasks: TransportTask[]
  shippingCarPaymentSummaryDataFE: IShippingCarPaymentSummary[] | []
}

export interface IShippingCarPaymentSummary extends IShippingCarPaymentTableData {
  id: number
  name: string
}

interface IShippingCarPaymentTableData {
  cash: number
  transfer: number
  cheque: number
  credit: number
  total: number
  expectedChargeAmount: number
}

export interface ITransportShippingPaymentDailySummary {
  tasks: TransportTask[]
  shippingCarPaymentSummaryData: IShippingCarPaymentSummaryData[] | []
}

interface IShippingCarPaymentSummaryData {
  id: number
  name: string
  transactions: ShippingPaymentTrasaction[]
  expectedChargeAmount: number
}

interface ShippingPaymentTrasaction {
  paymentMethod: BaseData
  amount: number
}

export const calculatePaymentTotal = (datas: IShippingCarPaymentSummary[]): IShippingCarPaymentTableData => {
  const initTotal = {
    cash: 0,
    transfer: 0,
    cheque: 0,
    credit: 0,
    total: 0,
    expectedChargeAmount: 0,
  }
  if (datas.length === 0) return initTotal
  return datas.reduce<IShippingCarPaymentTableData>((summary, data) => {
    summary.cash += data.cash
    summary.transfer += data.transfer
    summary.cheque += data.cheque
    summary.credit += data.credit
    summary.total += data.total
    summary.expectedChargeAmount += data.expectedChargeAmount

    return summary
  }, initTotal)
}

export const toPaymentTable = (
  datas: ITransportShippingPaymentDailySummary,
): ITransportShippingPaymentDailySummaryFE => {
  if (datas.shippingCarPaymentSummaryData.length === 0)
    return { tasks: datas.tasks, shippingCarPaymentSummaryDataFE: [] }

  const shippingCarPaymentSummaryData = datas.shippingCarPaymentSummaryData.map((car) => {
    return {
      id: car.id,
      name: car.name,
      cash: car.transactions
        .filter((p) => p.paymentMethod.id === PaymentMethod.Cash)
        .reduce<number>((summary, data) => {
          return (summary += data.amount)
        }, 0),
      transfer: car.transactions
        .filter((p) => p.paymentMethod.id === PaymentMethod.Transfer)
        .reduce<number>((summary, data) => {
          return (summary += data.amount)
        }, 0),
      cheque: car.transactions
        .filter((p) => p.paymentMethod.id === PaymentMethod.Cheque)
        .reduce<number>((summary, data) => {
          return (summary += data.amount)
        }, 0),
      credit: car.transactions
        .filter((p) => p.paymentMethod.id === PaymentMethod.Credit)
        .reduce<number>((summary, data) => {
          return (summary += data.amount)
        }, 0),
      total: car.transactions.reduce<number>((summary, data) => {
        return (summary += data.amount)
      }, 0),
      expectedChargeAmount: car.expectedChargeAmount,
    }
  })
  return { tasks: datas.tasks, shippingCarPaymentSummaryDataFE: shippingCarPaymentSummaryData }
}

export const useTransportShippingPaymentDailySummary = (
  dueDate: Date,
  typeId: string,
): ITransportShippingPaymentDailySummary => {
  const [data, setData] = useState<ITransportShippingPaymentDailySummary>({
    tasks: [],
    shippingCarPaymentSummaryData: [],
  })

  useEffect(() => {
    ;(async () => {
      const response = await transportShippingPaymentDailySummaryTaskAPI({ dueDate, typeId })
      setData(response?.data || { tasks: [], shippingCarPaymentSummaryData: [] })
    })()
  }, [dueDate, typeId])

  return data
}
