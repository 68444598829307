import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../../..'
import {
  updateCustomerRequisitionConditionAPI,
  UpdateCustomerRequisitionConditionRequestBody,
} from '../../../../api/smartbestbuys-api'
import { Form, KeyboardDateTimePickerForm } from '../../../../custom-components'
import { CustomerRequisitionCondition, PageName } from '../../../../enums'
import { ButtonRadios, Space, SubmitForm } from '../../../../shared-components'

interface Props extends DialogProps {
  customerRequisitionId: number
  saleOrderId: number
}

interface UpdateConditionFormValues {
  conditionId: number
  dueDate?: Date
}

const CustomerRequisitionProfileUpdateConditionDialog: React.FC<Props> = (props) => {
  const { customerRequisitionId, saleOrderId, onClose, ...otherProps } = props
  const history = useHistory()

  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION)

  const customerConditions = [
    {
      name: 'ลูกค้ามาส่งหน้าร้าน',
      id: CustomerRequisitionCondition.CustomerReturnAtSmart,
      value: CustomerRequisitionCondition.CustomerReturnAtSmart,
    },
    {
      name: 'ลูกค้าส่งผ่านขนส่ง',
      id: CustomerRequisitionCondition.CustomerDeliverProduct,
      value: CustomerRequisitionCondition.CustomerDeliverProduct,
    },
    {
      name: 'ไปรับที่ลูกค้า',
      id: CustomerRequisitionCondition.SmartToPickupProduct,
      value: CustomerRequisitionCondition.SmartToPickupProduct,
    },
  ]

  const {
    watch,
    reset,
    control,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<UpdateConditionFormValues>({})

  const { conditionId, dueDate } = watch()

  const handleUpdateConditionForm = async (values: UpdateConditionFormValues) => {
    if (!values.conditionId) {
      setError('conditionId', {
        type: 'required',
        message: 'กรุณาเลือกวิธีรับของจากลูกค้า',
      })
      return
    }

    const request: UpdateCustomerRequisitionConditionRequestBody = {
      conditionId: values.conditionId,
      dueDate: !!values.dueDate
        ? DateTime.fromJSDate(new Date(values.dueDate)).toFormat('yyyy-MM-dd HH:mm')
        : undefined,
    }

    const response = await updateCustomerRequisitionConditionAPI(customerRequisitionId, request)

    if (response?.status === 200) {
      triggerSnackbar('เลือกวิธีรับของจากลูกค้าสำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
      if (request.conditionId === CustomerRequisitionCondition.SmartToPickupProduct) {
        history.push(
          `/transport-tasks/create-pickup-task?customerRequisitionId=${customerRequisitionId}&saleOrderId=${saleOrderId}`,
        )
        return
      }
      window.location.reload()
    } else {
      triggerSnackbar('เลือกวิธีรับของจากลูกค้าไม่สำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
    }
    return true
  }

  return (
    <Dialog fullWidth {...otherProps}>
      <Form onSubmit={handleSubmit(handleUpdateConditionForm)}>
        <DialogTitle>เลือกวิธีรับของจากลูกค้า</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid id="#condition-id-selector" item xs={12}>
              <Typography variant="body2">วิธีรับของจากลูกค้า *</Typography>
              <ButtonRadios
                items={customerConditions}
                onChange={(conditionId) => {
                  setValue('conditionId', conditionId)
                  clearErrors('conditionId')
                }}
                value={conditionId}
              />
              <Space size="small" />
              <Box display={!!errors.conditionId ? 'block' : 'none'} color="error.main" pl={2} mb={0.5} clone>
                <Typography style={{ fontSize: 12 }}>{errors.conditionId?.message}</Typography>
              </Box>
            </Grid>
            {[
              CustomerRequisitionCondition.CustomerReturnAtSmart,
              CustomerRequisitionCondition.CustomerDeliverProduct,
            ].includes(conditionId) && (
              <Grid item xs={12}>
                <KeyboardDateTimePickerForm
                  control={control}
                  label="วันที่ลูกค้ามาคืนสินค้า (ถ้ามี)"
                  name="dueDate"
                  value={dueDate}
                  defaultValue={null}
                  size="small"
                  gutterTop
                  fullWidth
                  error={!!errors.dueDate}
                  helperText={errors.dueDate?.message}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText="ยืนยันวิธีรับของ"
            cancelText="ย้อนกลับ"
            onCancel={() => {
              onClose && onClose({}, 'escapeKeyDown')
              reset()
            }}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerRequisitionProfileUpdateConditionDialog
