import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import { FlexBox, SubTopic, Table } from '../../../custom-components'
import { dateTimeFormat } from '../../../functions'
import { TransportTaskProfilePageContext } from './TransportTaskProfilePage'
import { TransportTask_UserActivity } from '../../../api/smartbestbuys-api'

const TransportTaskProfileUserActivitiesTable: React.FC = () => {
  // context
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const { activities } = transportTaskProfile

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ประวัติการจัดการใบสั่งซื้อ</SubTopic>
        <div className="mb-4" />
        <Table<TransportTask_UserActivity>
          color="grey.800"
          columns={[
            { name: 'การกระทำ', render: ({ action }) => action.name },
            { name: 'รายละเอียดการจัดการ', render: ({ actionMessage }) => actionMessage?.name },
            { name: 'จัดการโดย', dataIndex: 'recCreatedBy', align: 'center' },
            {
              name: 'วันที่',
              dataIndex: 'recCreatedAt',
              render: (activity) => dateTimeFormat(activity.recCreatedAt),
              align: 'center',
            },
            { name: 'หมายเหตุ', dataIndex: 'remark', align: 'center' },
          ]}
          data={activities as TransportTask_UserActivity[]}
          cleanSpace
        />
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskProfileUserActivitiesTable
