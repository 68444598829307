import { useState, useEffect } from 'react'
import { BaseData, getActionMessagesByUserAction } from '../api/smartbestbuys-api'
import { UserAction } from '../enums'
import { getActionMessagesFromLocalStorage, setActionMessageToLocalStorage } from '../functions'

export const useActionMessages = (userAction: UserAction, skip?: boolean): BaseData[] => {
  const [messages, setMessages] = useState<BaseData[]>([])

  const _getActionMessagesByUserAction = async (userAction: UserAction) => {
    const actionMessages = getActionMessagesFromLocalStorage(userAction)
    if (!!actionMessages) {
      setMessages(actionMessages)
    } else {
      const response = await getActionMessagesByUserAction(userAction)
      const actionMessageData = response?.data ?? []
      setMessages(actionMessageData)
      setActionMessageToLocalStorage(userAction, actionMessageData)
    }
  }

  useEffect(() => {
    if (skip) return
    _getActionMessagesByUserAction(userAction)
  }, [userAction, skip])

  return messages
}
