import { useEffect, useState } from 'react'
import { transportShippingDailySummaryTaskAPI, TransportTask } from '../../api/smartbestbuys-api'

export interface ITransportShippingTaskDailySummary {
  tasks: TransportTask[]
  shippingCarSummaryData: IShippingCarSummaryData[]
}

export interface IShippingCarSummaryData extends IShippingCarSummaryTotalData {
  id: number
  name: string
}

interface IShippingCarSummaryTotalData {
  smartTaskAmount: number
  shippingProviderAmount: number
  failedAmount: number
  canceledTaskAmount: number
  totalTaskAmount: number
  expectedChargeAmount: number
  actualChargeAmount: number
}

export const calculateTotal = (datas: IShippingCarSummaryData[]): IShippingCarSummaryTotalData => {
  const initTotal = {
    smartTaskAmount: 0,
    shippingProviderAmount: 0,
    failedAmount: 0,
    canceledTaskAmount: 0,
    totalTaskAmount: 0,
    expectedChargeAmount: 0,
    actualChargeAmount: 0,
  }
  return datas.reduce<IShippingCarSummaryTotalData>((summary, data) => {
    return {
      smartTaskAmount: (summary.smartTaskAmount += data.smartTaskAmount),
      shippingProviderAmount: (summary.shippingProviderAmount += data.shippingProviderAmount),
      failedAmount: (summary.failedAmount += data.failedAmount),
      canceledTaskAmount: (summary.canceledTaskAmount += data.canceledTaskAmount),
      totalTaskAmount: (summary.totalTaskAmount += data.totalTaskAmount),
      expectedChargeAmount: (summary.expectedChargeAmount += data.expectedChargeAmount),
      actualChargeAmount: (summary.actualChargeAmount += data.actualChargeAmount),
    }
  }, initTotal)
}

export const useTransportShippingDailySummary = (
  dueDate: Date,
  typeId: string,
): [ITransportShippingTaskDailySummary, () => void] => {
  const [data, setData] = useState<ITransportShippingTaskDailySummary>({ tasks: [], shippingCarSummaryData: [] })
  const [reload, setReload] = useState(false)

  const forceReload = () => {
    setReload((prev) => !prev)
  }

  useEffect(() => {
    ;(async () => {
      const response = await transportShippingDailySummaryTaskAPI({ dueDate, typeId })
      setData(response?.data || { tasks: [], shippingCarSummaryData: [] })
    })()
    // eslint-disable-next-line
  }, [dueDate.toString(), typeId, reload])

  return [data, forceReload]
}
