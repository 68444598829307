import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import {
  addCustomerBlacklistApi,
  AddCustomerBlacklistRequest,
} from '../../../../../../api/smartbestbuys-api/customer/add-customer-blacklist-api'
import { FlexBox, Form, GeneralTextFieldForm } from '../../../../../../custom-components'
import { SubmitForm } from '../../../../../../shared-components'
import AddCustomerBlacklistFormValue from './AddCustomerBlacklistFormValues'

interface Props extends DialogProps {
  customerId: number
  onComplete: () => void
  onFail: () => void
}

const AddCustomerBlacklistDialog: React.FC<Props> = (props) => {
  const { onClose, onComplete, onFail, customerId, ...otherProps } = props

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AddCustomerBlacklistFormValue>()

  const handleOnSubmit = async (values: AddCustomerBlacklistFormValue) => {
    const requet: AddCustomerBlacklistRequest = {
      remark: values.remark,
    }
    const response = await addCustomerBlacklistApi(customerId, requet)
    if (response?.status === 200) {
      onComplete()
    } else {
      onFail()
    }
    return true
  }

  return (
    <Dialog fullWidth {...otherProps}>
      <Form onSubmit={handleSubmit((data: AddCustomerBlacklistFormValue) => handleOnSubmit(data))}>
        <DialogTitle>{'เพิ่มเข้าบัญชีดำ'}</DialogTitle>
        <DialogContent>
          <FlexBox flexDirection="column" gridGap={8}>
            <GeneralTextFieldForm
              control={control}
              label="เหตุผล *"
              name="remark"
              rules={{ required: { value: true, message: 'กรุณาระบุเหตุผล' } }}
              defaultValue=""
            />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText={`ยืนยัน`}
            cancelText={`ย้อนกลับ`}
            onCancel={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default AddCustomerBlacklistDialog
