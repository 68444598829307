import { BaseData } from '../api/smartbestbuys-api'

export const customerDepartments: BaseData[] = [
  {
    id: 1,
    name: 'เจ้าของกิจการ หรือ กรรมการผู้จัดการ',
  },
  {
    id: 2,
    name: 'ผู้บริหารระดับสูง, CEO, COO, CMO',
  },
  {
    id: 3,
    name: 'ผู้จัดการโครงการ, Project Managers, General Manager',
  },
  {
    id: 4,
    name: 'ผู้บริหารฝ่ายจัดซื้อ , ฝ่ายพัสดุ, ฝ่ายจัดซื้อ',
  },
  {
    id: 5,
    name: 'วิศวกร, นักออกแบบ, สถาปนิก , นายช่างโยธา',
  },
  {
    id: 6,
    name: 'ผู้บริหารด้านความปลอดภัย, ฝ่ายอาคารและสิ่งแวดล้อม, จป, เจ้าหน้าที่รักษาความปลอดภัย',
  },
  {
    id: 7,
    name: 'ฝ่ายขาย',
  },
  {
    id: 8,
    name: 'ฝ่ายซ่อมบำรุง',
  },
]
