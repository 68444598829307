import React, { useState, useContext, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, Link } from '../../custom-components'
import { useSaleOrders } from '../../hooks'
import {
  SOStatusChip,
  SOPaymentStatusChip,
  InsufficientProductSignal,
  SaleOrderCancelByCustomerSignal,
  NotificationHead,
} from '../../shared-components'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import { defaultStartDate, defaultEndDate } from '../../data'
import { NavigationItemKey, PageName } from '../../enums'
import { RootContext } from '../..'
import { SaleOrder_TableData } from '../../api/smartbestbuys-api'
import lodash from 'lodash'
import './SaleOrderPage.less'

export enum SOTableFilterValues {
  // to filter checklist to complete cancel
  ReclaimProductBack = 101,
  RefundToCustomer = 102,
  CeateCreditNote = 103,

  // to filter stock available
  StockUnavailable = 201,
}

const SaleOrdersPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SALE_ORDER)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const saleOrders = useSaleOrders(startDate, endDate)

  const saleOrderStatuses = useMemo(() => {
    return lodash
      .chain(saleOrders)
      .uniqBy('status.id')
      .orderBy('status.id')
      .value()
      .map((so) => {
        return {
          name: so.status.name,
          value: so.status.id,
        }
      })
    // eslint-disable-next-line
  }, [saleOrders.map((so) => so.code).toString()])

  const saleOrderPaymentStatuses = useMemo(() => {
    return lodash
      .chain(saleOrders)
      .uniqBy('paymentStatus.id')
      .orderBy('paymentStatus.id')
      .value()
      .map((so) => {
        return {
          name: so.paymentStatus.name,
          value: so.paymentStatus.id,
        }
      })
    // eslint-disable-next-line
  }, [saleOrders.map((so) => so.code).toString()])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Topic>รายการใบสั่งขาย</Topic>
      </Grid>
      <Grid item xs={12}>
        <NotificationHead navigationKey={NavigationItemKey.SaleOrder} />
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<SaleOrder_TableData>
          color="primary.dark"
          columns={[
            {
              name: '',
              render: ({ status, stockAvailable, checklistToCompleteCancel }) => {
                const isShowChecklist =
                  !!checklistToCompleteCancel &&
                  (checklistToCompleteCancel.reclaimProductBack ||
                    checklistToCompleteCancel.createCreditNote ||
                    checklistToCompleteCancel.refundToCustomer)

                return (
                  <div className="signal-cell">
                    {!isShowChecklist && !stockAvailable && <InsufficientProductSignal />}
                    {isShowChecklist && <SaleOrderCancelByCustomerSignal />}
                  </div>
                )
              },
            },
            {
              name: 'สถานะใบสั่งขาย',
              render: ({ status: { id, name } }) => <SOStatusChip status={id} label={name} />,
            },
            {
              name: 'สถานะชำระเงิน',
              render: ({ paymentStatus: { id, name } }) => <SOPaymentStatusChip status={id} label={name} />,
            },
            {
              name: 'เลขที่ใบสั่งขาย',
              render: ({ id, code }) => (
                <Link color="primary" to={`/sale-orders/${id}`}>
                  {code}
                </Link>
              ),
            },
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
            },
            {
              name: 'ชื่อลูกค้า',
              render: (saleOrder) => saleOrder.customer.nameLocal,
            },
            {
              name: 'เบอร์โทรศัพท์',
              render: (saleOrder) => saleOrder.customer.phoneNumber,
            },
            {
              name: 'เลขที่ PO ของลูกค้า',
              dataIndex: 'customerPurchaseReference',
            },
            {
              name: 'เงื่อนไขการชำระเงิน',
              render: (saleOrder) => saleOrder.paymentCondition.name,
            },
            {
              name: 'ยอดรวมสุทธิ',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
            },
          ]}
          data={saleOrders}
          defaultDateRage={{
            startDate: defaultStartDate,
            endDate: defaultEndDate,
          }}
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          filters={[
            {
              name: 'สถานะใบสั่งขาย',
              values: saleOrderStatuses,
              filter: (data: SaleOrder_TableData, values: (string | number)[]) => {
                return values.includes(data.status.id)
              },
            },
            {
              name: 'สถานะชำระเงิน',
              values: saleOrderPaymentStatuses,
              filter: (data: SaleOrder_TableData, values: (string | number)[]) => {
                return values.includes(data.paymentStatus.id)
              },
            },
            {
              name: 'สินค้า',
              values: [
                {
                  name: 'สินค้าไม่พอ',
                  value: SOTableFilterValues.StockUnavailable,
                },
              ],
              filter: (data: SaleOrder_TableData, values: (string | number)[]) => {
                return values.includes(SOTableFilterValues.StockUnavailable) && !data.stockAvailable
              },
            },
            {
              name: 'สถานะการยกเลิกโดยลูกค้า',
              values: [
                {
                  name: 'มีคำร้องลูกค้าค้างอยู่',
                  value: SOTableFilterValues.ReclaimProductBack,
                },
                {
                  name: 'มีเงินที่ยังไม่ได้คืน',
                  value: SOTableFilterValues.RefundToCustomer,
                },
                {
                  name: 'มีใบกำกับภาษีค้างอยู่',
                  value: SOTableFilterValues.CeateCreditNote,
                },
              ],
              filter: (data: SaleOrder_TableData, values: (string | number)[]) => {
                // user didn't select any filter then returns all
                if (values.length === 0) return true

                // what filter values has
                const watchingReclaimProductBack = values.includes(SOTableFilterValues.ReclaimProductBack)
                const watchingRefundToCustomer = values.includes(SOTableFilterValues.RefundToCustomer)
                const watchingCeateCreditNote = values.includes(SOTableFilterValues.CeateCreditNote)

                // not to return SO didn't have checklist to complete cancel
                if (!data.checklistToCompleteCancel) return false

                // filter logic
                const matchReclaimProductBack =
                  watchingReclaimProductBack && data.checklistToCompleteCancel.reclaimProductBack
                const matchRefundToCustomer =
                  watchingRefundToCustomer && data.checklistToCompleteCancel.refundToCustomer
                const matchCeateCreditNote = watchingCeateCreditNote && data.checklistToCompleteCancel.createCreditNote

                return matchReclaimProductBack || matchRefundToCustomer || matchCeateCreditNote
              },
            },
          ]}
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(SaleOrdersPage)
