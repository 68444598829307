import { CRReceiveProductStatus, CRShippingProductStatus } from '../enums'
import { ReactComponent as WarehouseReceiveProductIconSVG } from '../assets/icons/warehouse-receive-product-icon.svg'
import { ReactComponent as ShippingProductBackToCustomerIconSVG } from '../assets/icons/shipping-product-back-to-customer-icon.svg'
import { SvgIcon } from '@material-ui/core'

import './CustomerRequisitionIcon.less'

const _getCRReceiveProductClassStatus = (status: CRReceiveProductStatus) => {
  switch (status) {
    case CRReceiveProductStatus.InProgress:
      return 'customer-requisition-icon--processing'
    case CRReceiveProductStatus.Done:
      return 'customer-requisition-icon--success'
    // default case should be pending
    default:
      return 'customer-requisition-icon--not-found'
  }
}

const _getCRShippingProductClassStatus = (status: CRShippingProductStatus) => {
  switch (status) {
    case CRShippingProductStatus.SomeDone:
      return 'transport-task-icon--pending'
    case CRShippingProductStatus.InProgree:
      return 'transport-task-icon--processing'
    // default case should be pending
    default:
      return 'customer-requisition-icon--not-found'
  }
}

interface CRReceiveProductStatusIconProps {
  status: CRReceiveProductStatus
  size?: 'small'
}

export const CRReceiveProductStatusIcon = (props: CRReceiveProductStatusIconProps) => {
  const classNames = ['customer-requisition-icon']

  if (props.status) classNames.push(_getCRReceiveProductClassStatus(props.status))
  if (props.size) classNames.push('customer-requisition-icon--small')

  return (
    <span className={classNames.join(' ')}>
      <SvgIcon component={WarehouseReceiveProductIconSVG} viewBox="0 0 35 35" />
    </span>
  )
}

interface CRShippingProductStatusIconProps {
  status: CRShippingProductStatus
  size?: 'small'
}

export const CRShippingProductStatusIcon = (props: CRShippingProductStatusIconProps) => {
  const classNames = ['customer-requisition-icon']

  if (props.status) classNames.push(_getCRShippingProductClassStatus(props.status))
  if (props.size) classNames.push('customer-requisition-icon--small')

  return (
    <span className={classNames.join(' ')}>
      <SvgIcon component={ShippingProductBackToCustomerIconSVG} viewBox="0 0 35 35" />
    </span>
  )
}
