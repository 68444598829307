export enum WarehouseTaskType {
  WarehousePrepareProduct = 4001,
  WarehouseReclaimProduct = 4002,
  PendingCustomerPickup = 4003,

  // purchase
  ReceiveProductFromVendor = 4004,

  // customer requisition
  WaitCustomerShipProduct = 4005,
  WaitCustomerReturnProduct = 4006,
  ReceiveProductBack = 4007,
}

export enum ReceiveProductAction {
  All = 1,
  Partial = 2,
  OverWithGiven = 3,
  RejectRemaining = 4,
  Nothing = 5,
  AlreadyReceived = 200,
}

export enum WarehouseTaskStatus {
  Pending = 1,
  Processing = 2,
  Proceeded = 200,
  PreCancelled = 998,
  Cancelled = 999,
}
