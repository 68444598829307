import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
} from '@material-ui/core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../../../../..'
import { Form, GeneralTextFieldForm, TypographyWithLabel } from '../../../../../../../custom-components'
import CustomerProfileContext from '../../../CustomerProfileContext'
import { mapCustomerAddressToCreationFormValues } from '../../mapper'
import { createCustomerShippingAddress } from '../../service'
import CustomerShippingAddressCreationForm from './CustomerShippingAddressCreationFormValue'
import { useAddressData } from '../../../../../../../hooks/data/useAddressData'
import { District, SubDistrict } from '../../../../../../../api/smartbestbuys-api/data/data-types'

const CustomerShippingAddressCreationDialog: React.FC<DialogProps> = (props) => {
  const { onClose, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)
  const { reload, customerProfile } = useContext(CustomerProfileContext)

  const { id: customerId, billingAddresses } = customerProfile

  const [validPostalCode, setValidPostalCode] = useState(false)
  const [districtFilter, setDistrictFilter] = useState<District[]>([])
  const [subDistrictFilter, setSubDistrictFilter] = useState<SubDistrict[]>([])

  const [importAddressId, setImportAddressId] = useState<number>()

  const addressData = useAddressData()
  // eslint-disable-next-line
  const provinces = useMemo(() => addressData?.provinces ?? [], [(addressData?.provinces ?? []).length])
  // eslint-disable-next-line
  const districts = useMemo(() => addressData?.districts ?? [], [(addressData?.districts ?? []).length])
  // eslint-disable-next-line
  const subDistricts = useMemo(() => addressData?.subDistricts ?? [], [(addressData?.subDistricts ?? []).length])

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<CustomerShippingAddressCreationForm>({
    defaultValues: {
      name: '',
      branchName: 'สำนักงานใหญ่',
      provinceId: '',
      districtId: '',
      subDistrictId: '',
    },
  })

  const { postalCode, provinceId, districtId, subDistrictId } = watch()

  useEffect(() => {
    if (provinceId && !validPostalCode) {
      const districtsFilter = districts.filter((d) => d.provinceId === Number(provinceId))

      if (districtsFilter) {
        setDistrictFilter([...districtsFilter])

        if (importAddressId) {
          const billingAddresse = billingAddresses?.find((ba) => ba.id === importAddressId)
          setValue('districtId', billingAddresse?.district?.id.toString())
        }
      }
    }
    // eslint-disable-next-line
  }, [setValue, provinceId, validPostalCode, importAddressId, billingAddresses])

  useEffect(() => {
    if (subDistrictId && validPostalCode) {
      const initDistrictId = subDistricts.find((sd) => sd.id === Number(subDistrictId))?.districtId
      const district = districts.find((d) => d.id === initDistrictId)

      if (district) {
        setDistrictFilter([district])
        setValue('districtId', initDistrictId?.toString())
      }
    }
    // eslint-disable-next-line
  }, [setValue, subDistrictId, validPostalCode])

  useEffect(() => {
    if (districtId && validPostalCode) {
      const initProvinceId = districts.find((d) => d.id === Number(districtId))?.provinceId
      const province = provinces.find((p) => p.id === initProvinceId)

      if (province) {
        setValue('provinceId', initProvinceId?.toString())
      }
    } else if (districtId && !validPostalCode) {
      const subDistrictsFilter = subDistricts.filter((sd) => sd.districtId === Number(districtId))

      if (subDistrictsFilter) {
        setSubDistrictFilter([...subDistrictsFilter])

        if (importAddressId) {
          const billingAddresse = billingAddresses?.find((ba) => ba.id === importAddressId)
          setValue('subDistrictId', billingAddresse?.subDistrict?.id.toString())
          setImportAddressId(undefined)
        }
      }
    }
    // eslint-disable-next-line
  }, [setValue, districtId, validPostalCode, importAddressId, billingAddresses])

  useEffect(() => {
    if (validPostalCode && postalCode) {
      const newSubDistrictFilter = subDistricts.filter((sd) => sd.postalCode === postalCode || !postalCode)
      const subDistrict = subDistricts.find((sd) => sd.postalCode === Number(postalCode))

      if (newSubDistrictFilter && subDistrict) {
        setSubDistrictFilter([...newSubDistrictFilter])
        setValue('subDistrictId', subDistrict.id.toString())
      }
    }
    // eslint-disable-next-line
  }, [validPostalCode, postalCode, setValue])

  const handleSubmitCustomerShippingAddressCreationForm = async (value: CustomerShippingAddressCreationForm) => {
    const response = await createCustomerShippingAddress(customerId, value)
    if (response?.status === 201) {
      triggerSnackbar('เพิ่มที่อยู่ขนส่งสำเร็จ')
      reload()
      onClose && onClose({}, 'escapeKeyDown')
      reset()
    } else {
      triggerSnackbar('เพิ่มที่อยู่ขนส่งไม่สำเร็จ')
    }
    return true
  }

  const handleOnClickImportAddress = (id: number) => {
    const billingAddress = billingAddresses?.find((el) => el.id === id)

    if (billingAddress) {
      setValidPostalCode(false)
      const shippingAddressValue = mapCustomerAddressToCreationFormValues(billingAddress)

      reset(shippingAddressValue)

      setImportAddressId(id)
    }
  }

  const disabledSubDistrict = !validPostalCode && !provinceId && !districtId
  const disabledDistrict = !!(subDistrictId && validPostalCode) || (!validPostalCode && !provinceId)
  const disabledProvince = !!(districtId && validPostalCode)

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitCustomerShippingAddressCreationForm)}>
        <DialogTitle>เพิ่มที่อยู่ขนส่ง</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {billingAddresses?.length ? (
              <>
                <Grid item xs={12}>
                  <TypographyWithLabel label="เลือกใช้ที่อยู่เดียวกับที่อยู่เปิดบิล" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} direction="row">
                    {billingAddresses?.map((ba, index) => (
                      <Grid item key={ba.id}>
                        <Button onClick={() => handleOnClickImportAddress(ba.id)} variant="outlined" color="primary">
                          {ba.name || `ที่อยู่ ${index + 1}`}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TypographyWithLabel label="รายละเอียดที่อยู่ขนส่ง" />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="name"
                rules={{ required: 'กรุณาใส่ชื่อ' }}
                label="ชื่อเรียกที่อยู่ *"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="branchName" label="สาขา (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="postalCode"
                rules={{ required: 'กรุณาใส่รหัสไปรษณีย์' }}
                type="number"
                label="รหัสไปรษณีย์ *"
                defaultValue=""
                onPostChange={(postalCode) => {
                  if (postalCode) {
                    const subDistrict = subDistricts.find((sd) => sd.postalCode === Number(postalCode))
                    if (subDistrict) {
                      setValidPostalCode(true)
                    } else {
                      setValidPostalCode(false)
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="provinceId"
                rules={{ required: 'กรุณาเลือกจังหวัด' }}
                label="จังหวัด *"
                select
                disabled={disabledProvince}
              >
                {provinces.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="districtId"
                rules={{ required: 'กรุณาเลือกเขตหรืออำเภอ' }}
                label="เขต / อำเภอ *"
                select
                disabled={disabledDistrict}
              >
                {districtFilter.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="subDistrictId"
                rules={{ required: 'กรุณาเลือกแขวงหรือตำบล' }}
                label="แขวง / ตำบล *"
                select
                disabled={disabledSubDistrict}
              >
                {subDistrictFilter.map((element) => {
                  const district = districts.find((d) => d.id === element.districtId)
                  return (
                    <MenuItem key={element.id} value={element.id}>
                      {element.nameLocal} (เขต {district?.nameLocal})
                    </MenuItem>
                  )
                })}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="addressLine1"
                label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
                rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="phoneNumber"
                type="tel"
                rules={{ required: 'กรุณาใส่เบอร์โทร' }}
                label="เบอร์โทร *"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="faxNumber" label="เบอร์แฟกซ์ (ถ้ามี)" defaultValue="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="googleMapLink"
                label="ลิงก์ Google Map (ถ้ามี)"
                defaultValue=""
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            ยืนยันเพิ่มที่อยู่ขนส่ง
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerShippingAddressCreationDialog
