import React, { useContext } from 'react'
import { DialogProps } from '@material-ui/core'
import { RootContext } from '../../..'
import { ConfirmationDialog } from '../../../shared-components'
import { useForm } from 'react-hook-form'
import { GeneralTextFieldForm } from '../../../custom-components'
import { approveQuotationDataAPI } from '../../../api/smartbestbuys-api'

interface Props extends DialogProps {
  quotationId: number
  quotationCode: string
  onSuccess?: () => void
  onClose?: () => void
}

export interface ApproveReviewFormValues {
  remark?: string
}

const QuotationApproveDataDialog: React.FC<Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { quotationId, quotationCode, onSuccess, ...dialogProps } = props

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<ApproveReviewFormValues>()

  const onSubmit = async (values: ApproveReviewFormValues) => {
    const response = await approveQuotationDataAPI(quotationId, {
      remark: values.remark,
    })
    if (response?.status === 200) {
      triggerSnackbar('อนุมัติใบเสนอราคา สำเร็จ')
      if (onSuccess) onSuccess()
    } else {
      triggerSnackbar('อนุมัติใบเสนอราคา ไม่สำเร็จ')
    }
  }

  return (
    <ConfirmationDialog
      title={`ต้องการอนุมัติใบเสนอราคา : ${quotationCode} ใช่หรือไม่`}
      textOk="ยืนยัน"
      isSubmitting={isSubmitting}
      confirmTextOption={{
        veifiedText: 'ยืนยัน',
      }}
      onOk={handleSubmit(onSubmit)}
      {...dialogProps}
    >
      <GeneralTextFieldForm control={control} label="หมายเหตุ" name="remark" />
    </ConfirmationDialog>
  )
}

export default QuotationApproveDataDialog
