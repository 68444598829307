import { Box, IconButton, Typography } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { ICustomerProfile_Document, ReceiptState } from '../../../../../../../api/smartbestbuys-api'
import { Link, TColumn } from '../../../../../../../custom-components'
import { DocumentStatus } from '../../../../../../../enums'
import { dateTimeFormat } from '../../../../../../../functions'
import { DocumentStatusChip } from '../../../../../../../shared-components'

const receiptsDocumentColumns = (onCancel: (data: ICustomerProfile_Document<any>) => void) => {
  return [
    {
      name: 'สถานะใบเสร็จรับเงิน',
      render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
    },
    {
      name: 'เลขใบเสร็จรับเงิน',
      render: ({ id, code }) => (
        <Box color="primary.dark">
          <Link color="inherit" href={`/receipts/${id}/pdf`} target="_blank">
            <Typography variant="body2">{code}</Typography>
          </Link>
        </Box>
      ),
    },
    {
      name: 'วันที่สร้าง',
      render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
    },
    {
      name: 'ยอดเงินสุทธิ',
      numeric: true,
      format: '0,0.00',
      dataIndex: 'total',
    },
    {
      name: '',
      render: (data) =>
        data.status.id === DocumentStatus.Cancelled ? null : (
          <IconButton size="small" onClick={() => onCancel(data)}>
            <Cancel fontSize="small" />
          </IconButton>
        ),
    },
  ] as TColumn<ICustomerProfile_Document<ReceiptState>>[]
}
export default receiptsDocumentColumns
