import { FormProvider, useForm } from 'react-hook-form'
import { Form, GeneralTextFieldForm, Grid } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'

interface Props {
  onSubmit: (values: MyUserCriteriaUpdateFormValues) => void
  onClose: () => void
}

export interface MyUserCriteriaUpdateFormValues {
  currentPassword: string
  password: string
}

export const MyUserCriteriaForm: React.FC<Props> = (props) => {
  const { onSubmit, onClose } = props
  const formMethod = useForm<MyUserCriteriaUpdateFormValues>({
    defaultValues: {
      currentPassword: '',
      password: '',
    },
  })
  const {
    control,
    formState: { isSubmitting },
  } = formMethod

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={(value) => onSubmit(value)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GeneralTextFieldForm
              control={control}
              name="currentPassword"
              label="รหัสผ่านเดิม *"
              rules={{ required: 'กรุณากรอกรหัสผ่านเดิม' }}
            />
          </Grid>
          <Grid item xs={12}>
            <GeneralTextFieldForm
              control={control}
              name="password"
              label="รหัสผ่านใหม่ *"
              rules={{ required: 'กรุณากรอกรหัสผ่านใหม่' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SubmitForm
            submitText="ยืนยันการเปลี่ยนรหัสผ่าน"
            cancelText="ย้อนกลับ"
            disableSubmit={isSubmitting}
            onCancel={onClose}
          />
        </Grid>
      </Form>
    </FormProvider>
  )
}
