import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { withLayout, BreadcumbsList, Topic, FlexBox, CustomSpeedDial } from '../../../../custom-components'
import { Popover, Button, MenuItem, Tabs, Tab, Paper, Grid, Avatar, Tooltip } from '@material-ui/core'
import { MoreHoriz, PictureAsPdf, ContactPhone, Description, Edit } from '@material-ui/icons'
import {
  SaleOrderDetail,
  SOAttachments,
  SOPaymentTransactions,
  SOProductsTable,
  SOTasks,
  SOUserActivities,
  SOStatusBar,
  SODocumentList,
  SaleOrderProfileTodoListToCompleteCancel,
  SaleOrderRequestToCancelDialog,
} from '.'
import { useCustomerPORefs, useCustomerProfile, useSaleOrderProfile } from '../../../../hooks'
import SaleOrderProfileContext from './SaleOrderProfileContext'
import {
  ConditionTooltip,
  CustomerProfileCard,
  Loading,
  MultipleConditionTooltip,
  Space,
} from '../../../../shared-components'
import { SOFollowUpDialog } from './SOFollowUpDialog'
import { RootContext } from '../../../..'
import { DocumentStatus, DocumentType, PageName, SaleOrderStatus, TaskStatus } from '../../../../enums'
import CBASelectionDialog from '../../../../shared-components/CBASelectionDialog/CBASelectionDialog'
import SOPurchaseRequisitionTable from './SOPurchaseRequisitionTable'
import SaleOrderCancelDialog from './SaleOrderCancelDialog'
import { ProductType } from '../../../../enums'
import { TransportTaskItemType } from '../../../../enums/TransportTaskEnums'
import SaleOrderPartialUpdateDialog from './SaleOrderPartialUpdateDialog'
import { getUserPermissionInfo } from '../../../../utils/permission'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { SOCustomerRequisitions } from './SOCustomerRequisition'
import { SORefundRequisitions } from './SORefundRequisitions'
import SaleOrderUpdateAssigneeDialog from './SaleOrderUpdateAssigneeDialog'
import SOPurchaseOrderList from './SOPurchaseOrderList'

interface Props {
  id: number
}

const partialUpdateWhiteList = [
  SaleOrderStatus.PendingPayment,
  SaleOrderStatus.PendingDepositPayment,
  SaleOrderStatus.PendingDelivery,
  SaleOrderStatus.Delivering,
  SaleOrderStatus.WaitingCustomerPickUp,
  SaleOrderStatus.Closed,
  SaleOrderStatus.ClosedAtShop,
]

const processedSaleOrderStatuses = [SaleOrderStatus.Cancelled]

// const processedPurchaseOrderStatuses = [
//   PurchaseOrderStatus.Rejected,
//   PurchaseOrderStatus.Canceled,
//   PurchaseOrderStatus.Received,
// ]

const shippingWhitelist = [SaleOrderStatus.PendingDelivery, SaleOrderStatus.Delivering]

const SaleOrderProfilePage: React.FC<Props> = (props) => {
  const history = useHistory()
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SALE_ORDER)

  // state
  const [reload, setReload] = useState(false)
  const [updateCBA, setUpdateCBA] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [requestToCancelOpen, setRequestToCancelOpen] = useState(false)
  const [partialUpdateOpen, setPartialUpdateOpen] = useState(false)
  const [updateAssigneeOpen, setUpdateAssigneeOpen] = useState(false)

  // hook
  const saleOrderProfile = useSaleOrderProfile(props.id, reload)
  const customerProfile = useCustomerProfile(saleOrderProfile?.customer?.id)
  const customerPORefs = useCustomerPORefs(saleOrderProfile?.customer?.id)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [contactCustomer, setContactCustomer] = useState(false)

  enum TabValue {
    BasicInfos = 0,
    Documents = 1,
    Payments = 2,
    PurchaseOrders = 3,
    Transports = 4,
    CustomerRequisitions = 5,
    Activities = 6,
  }

  const [tabValue, setTabValue] = useState<TabValue>(TabValue.BasicInfos)

  if (!saleOrderProfile || !customerProfile) return <Loading />

  const { customerRequisitions, products } = saleOrderProfile

  const insufficientProduct = saleOrderProfile.products.some((product) => {
    const readyToSell = product.stockAmount - product.allReservedAmount
    const pendingToShipAmount = product.amount - product.reservedAmount - product.shippedAmount
    return product.productTypeId === ProductType.Product && readyToSell < pendingToShipAmount
  })

  const { status, contact, billingAddress, documents, refundRequisitions, assigneeUser } = saleOrderProfile

  const assigneeName = assigneeUser ? assigneeUser.firstName + ' ' + assigneeUser.lastName : saleOrderProfile.createdBy

  const isShowCreateCR = products.some((p) => p.shippedAmount > 0) && ![SaleOrderStatus.Cancelled].includes(status.id)

  const breadcumbsItems = [
    { url: '/sale-orders', name: 'รายการใบสั่งขาย' },
    { name: `ใบสั่งขาย ${saleOrderProfile.code}` },
  ]

  const actions = [
    {
      icon: <PictureAsPdf />,
      name: 'ดูเป็น PDF',
      onClick: () => window.open(`/sale-orders/${saleOrderProfile.id}/pdf`),
    },
    { icon: <ContactPhone />, name: 'ติดตามการขาย', onClick: () => setContactCustomer(true) },
  ]

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // calculate
  // const hasProcessingPurchaseOrder = !!saleOrderProfile.purchaseRequisitions?.find((pr) => {
  //   const purchaseOrders =
  //     pr.purchaseOrders?.filter((po) => !processedPurchaseOrderStatuses.includes(po.status.id)) ?? []
  //   return purchaseOrders.length > 0
  // })
  const showedPurchaseRequisitionLists =
    !!saleOrderProfile.purchaseRequisitions && saleOrderProfile.purchaseRequisitions.length > 0

  const validTaxInvoices =
    documents?.filter((doc) => doc.type.id === DocumentType.TaxInvoice && doc.status.id !== DocumentStatus.Cancelled) ??
    []

  // const hasApprovedPaymentTransaction = paymentTransactions?.some((row: any) =>
  //   [SOPaymentTransactionStatus.PENDING, SOPaymentTransactionStatus.APPROVED].includes(row.status.id),
  // )

  const hasProcessingTransportTasks =
    saleOrderProfile.transportTasks?.some((task) =>
      [TaskStatus.TransportPending, TaskStatus.Processing].includes(task.status.id),
    ) ?? false

  // const isShippedSome = saleOrderProfile.products.some((product: SaleOrderProduct) => product.shippedAmount > 0)

  // const isShowEditMenu = updateWhitelist.includes(saleOrderProfile.status.id) && !isShippedSome
  const isShowPartialEditMenu = partialUpdateWhiteList.includes(saleOrderProfile.status.id)

  // const editTooltipText = () => {
  //   const messages: string[] = []
  //   if (validTaxInvoices.length > 0) messages.push('มีการออกใบกำกับภาษีแล้ว')
  //   if (hasApprovedPaymentTransaction) messages.push('อนุมัติชำระเงินแล้ว')
  //   if (hasProcessingPurchaseOrder) messages.push('มีคำสั่งซื้อค้างอยู่')
  //   if (hasProcessingTransportTasks) messages.push('มีงานจัดส่งค้างอยู่')
  //   return messages.join(', ')
  // }

  const writeSOPermission = getUserPermissionInfo(PermissionEnum.Sales_SO_Write)

  // const ableToUpdate =
  //   !validTaxInvoices.length &&
  //   !hasApprovedPaymentTransaction &&
  //   !hasProcessingPurchaseOrder &&
  //   !hasProcessingTransportTasks &&
  //   writeSOPermission.hasPermission

  const ableToUpdateBillingAddress =
    writeSOPermission.hasPermission && saleOrderProfile.status.id !== SaleOrderStatus.Cancelled

  // const updateSOConditionItems = [
  //   {
  //     showTooltip: !writeSOPermission.hasPermission,
  //     message: writeSOPermission.alertMessage,
  //   },
  //   {
  //     showTooltip: !ableToUpdate,
  //     message: editTooltipText(),
  //   },
  // ]

  const ableToPartialUpdate = !hasProcessingTransportTasks && writeSOPermission.hasPermission

  const ableToUpdateAssigneeUser = writeSOPermission.hasPermission

  const partialEditTooltip = () => {
    const messages: string[] = []
    if (hasProcessingTransportTasks) messages.push('มีงานจัดส่งค้างอยู่')
    return messages.join(', ')
  }

  const partialUpdateSOConditionItems = [
    {
      showTooltip: !writeSOPermission.hasPermission,
      message: writeSOPermission.alertMessage,
    },
    {
      showTooltip: !ableToPartialUpdate,
      message: partialEditTooltip(),
    },
  ]

  const isShowDeliveryMenu = shippingWhitelist.includes(saleOrderProfile.status.id)

  // cancel button activation
  const cancelSOPermission = getUserPermissionInfo(PermissionEnum.Sales_SO_Cancel)
  const ableToCancel = validTaxInvoices.length === 0 && cancelSOPermission.hasPermission
  const isShowCancelMenu = !processedSaleOrderStatuses.includes(saleOrderProfile.status.id)

  const cancelSOConditionItems = [
    {
      showTooltip: !cancelSOPermission.hasPermission,
      message: cancelSOPermission.alertMessage,
    },
    {
      showTooltip: !ableToCancel,
      message: 'ไม่สามารถขอยกเลิกได้ เนื่องจากมีการออกใบกำกับภาษีไปแล้ว',
    },
  ]

  // request to cancel button activation
  const requestToCancelSOPermission = getUserPermissionInfo(PermissionEnum.Sales_Request_To_Cancel)
  const isShowRequestToCancelMenu =
    saleOrderProfile.status.id !== SaleOrderStatus.Cancelled && requestToCancelSOPermission.hasPermission

  const isShowMenu = isShowPartialEditMenu || isShowDeliveryMenu || isShowCancelMenu || isShowRequestToCancelMenu
  // || isShowEditMenu

  const alreadyShippedAllProducts = saleOrderProfile.products.every((product) => {
    const alreadyShipped = product.shippedAmount >= product.amount
    if (alreadyShipped) return alreadyShipped
    // filter only on processing transport tasks
    const reservedAmount =
      saleOrderProfile.transportTasks
        ?.filter((t) => [TaskStatus.TransportPending, TaskStatus.Processing].includes(t.status.id))
        .reduce<number>((reservedAmount, task) => {
          const transportProduct = task.state?.items?.find(
            (item) => item.type === TransportTaskItemType.Product && item.id === product.productId,
          )
          return reservedAmount + (transportProduct?.amount ?? 0)
        }, 0) ?? 0

    return reservedAmount + product.shippedAmount >= product.amount
  })

  // permission
  const writeCRPermission = getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Write)
  const writeTransportPermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)

  // condition items
  const writeTransportConditionItems = [
    { showTooltip: !writeTransportPermission.hasPermission, message: writeTransportPermission.alertMessage },
    { showTooltip: alreadyShippedAllProducts, message: 'สินค้าทั้งหมดได้ถูกเปิดงานจัดส่งแล้ว' },
  ]

  return (
    <SaleOrderProfileContext.Provider
      value={{
        saleOrderProfile,
        customerProfile,
        insufficientProduct,
        reload: () => setReload((prev) => !prev),
      }}
    >
      <CBASelectionDialog
        saleOrderId={saleOrderProfile.id}
        open={updateCBA}
        onClose={() => setUpdateCBA(false)}
        billingAddresses={customerProfile.billingAddresses || []}
        value={saleOrderProfile?.billingAddress?.id}
        onChange={(_) => {
          setReload((prev) => !prev)
          setUpdateCBA(false)
        }}
      />
      <SaleOrderCancelDialog
        saleOrderProfileInfo={saleOrderProfile}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        onComplete={() => {
          setCancelOpen(false)
          setReload((prev) => !prev)
        }}
      />
      <SaleOrderRequestToCancelDialog
        saleOrderProfileInfo={saleOrderProfile}
        open={requestToCancelOpen}
        onClose={() => setRequestToCancelOpen(false)}
        onComplete={() => {
          setRequestToCancelOpen(false)
          setReload((prev) => !prev)
        }}
      />
      <SaleOrderPartialUpdateDialog
        open={partialUpdateOpen}
        onClose={() => {
          setPartialUpdateOpen(false)
          setAnchorEl(null)
        }}
        onComplete={() => {
          setPartialUpdateOpen(false)
          setAnchorEl(null)
          setReload((prev) => !prev)
        }}
        customerPORefs={customerPORefs}
        saleOrderProfile={saleOrderProfile}
        customerProfile={customerProfile}
      />
      <SaleOrderUpdateAssigneeDialog
        open={updateAssigneeOpen}
        onClose={() => {
          setUpdateAssigneeOpen(false)
          setAnchorEl(null)
        }}
        onComplete={() => {
          setUpdateAssigneeOpen(false)
          setAnchorEl(null)
          setReload((prev) => !prev)
        }}
        saleOrderProfile={saleOrderProfile}
      />
      <Grid container spacing={2}>
        <SOFollowUpDialog open={contactCustomer} onClose={() => setContactCustomer(false)} />
        <CustomSpeedDial actions={actions} />
        <Grid item xs={12}>
          <BreadcumbsList items={breadcumbsItems} />
        </Grid>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <FlexBox alignItems="center">
              <Topic>ใบสั่งขาย {saleOrderProfile.code}</Topic>
              <FlexBox alignItems="center" className="pl-4 gap-1 cursor-help">
                <Button
                  disabled={!ableToUpdateAssigneeUser}
                  onClick={() => {
                    setUpdateAssigneeOpen(true)
                  }}
                  endIcon={<Edit color="secondary" />}
                >
                  <Tooltip title="ผู้รับผิดชอบงาน">
                    <Avatar alt="Customer Avatar" style={{ width: 24, height: 24 }}></Avatar>
                  </Tooltip>
                  <span className="ml-2">{assigneeName}</span>
                </Button>
              </FlexBox>
            </FlexBox>
            <FlexBox gridGap={16}>
              {isShowCreateCR && (
                <ConditionTooltip showTooltip={!writeCRPermission.hasPermission} title={writeCRPermission.alertMessage}>
                  <Button
                    variant="outlined"
                    disabled={!writeCRPermission.hasPermission}
                    style={writeCRPermission.hasPermission ? { color: '#C79400', borderColor: '#C79400' } : undefined}
                    size="large"
                    onClick={() => {
                      history.push(
                        `/customer-requisitions/create?saleOrderId=${saleOrderProfile.id}&customerId=${saleOrderProfile.customer.id}`,
                      )
                    }}
                    startIcon={<Description />}
                  >
                    สร้างคำร้องของลูกค้า
                  </Button>
                </ConditionTooltip>
              )}
              {isShowMenu && (
                <Button variant="contained" size="large" onClick={handleClick}>
                  <MoreHoriz />
                </Button>
              )}
            </FlexBox>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {/* <MenuItem>ส่งเมลอีกครั้ง</MenuItem> */}
              {/* {isShowEditMenu && (
                <MultipleConditionTooltip conditionItems={updateSOConditionItems}>
                  <MenuItem
                    disabled={!ableToUpdate}
                    onClick={() => history.push(`/sale-orders/${saleOrderProfile.id}/update`)}
                  >
                    แก้ไขใบสั่งขาย
                  </MenuItem>
                </MultipleConditionTooltip>
              )} */}
              {isShowPartialEditMenu && (
                <MultipleConditionTooltip conditionItems={partialUpdateSOConditionItems}>
                  <MenuItem disabled={!ableToPartialUpdate} onClick={() => setPartialUpdateOpen(true)}>
                    แก้ไขเงื่อนไขใบสั่งขาย
                  </MenuItem>
                </MultipleConditionTooltip>
              )}
              {isShowCancelMenu && (
                <MultipleConditionTooltip conditionItems={cancelSOConditionItems}>
                  <MenuItem
                    onClick={() => setCancelOpen(true)}
                    disabled={cancelSOConditionItems.some((item) => item.showTooltip)}
                  >
                    ยกเลิกใบสั่งขาย
                  </MenuItem>
                </MultipleConditionTooltip>
              )}
              {isShowRequestToCancelMenu && (
                <ConditionTooltip
                  title={requestToCancelSOPermission.alertMessage}
                  showTooltip={!requestToCancelSOPermission.hasPermission}
                >
                  <MenuItem onClick={() => setRequestToCancelOpen(true)}>อนุมัติยกเลิก</MenuItem>
                </ConditionTooltip>
              )}
              {isShowDeliveryMenu && (
                <>
                  <MultipleConditionTooltip conditionItems={writeTransportConditionItems}>
                    <MenuItem
                      disabled={writeTransportConditionItems.some((item) => item.showTooltip)}
                      onClick={() => history.push(`/transport-tasks/${saleOrderProfile.id}/create`)}
                    >
                      สร้างงานจัดส่ง
                    </MenuItem>
                  </MultipleConditionTooltip>
                </>
              )}
            </Popover>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <FlexBox gridGap={8} alignItems="center">
            <SOStatusBar />
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <SaleOrderProfileTodoListToCompleteCancel />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              style={{ borderBottom: 1 }}
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={(_, newValue) => setTabValue(newValue)}
            >
              <Tab label="ข้อมูลพื้นฐาน" value={TabValue.BasicInfos} />
              <Tab label="เอกสารที่เกี่ยวข้อง" value={TabValue.Documents} />
              <Tab label="ข้อมูลการชำระเงิน" value={TabValue.Payments} />
              <Tab label="การสั่งซื้อ" value={TabValue.PurchaseOrders} />
              <Tab label="การขนส่ง" value={TabValue.Transports} />
              <Tab
                label={`คำร้องของลูกค้า(${customerRequisitions?.length ?? 0})`}
                value={TabValue.CustomerRequisitions}
              />
              <Tab label="ประวัติการจัดการใบสั่งขาย" value={TabValue.Activities} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Space />
            {
              [
                <>
                  <Grid item xs={12} style={{ paddingTop: 20 }}>
                    <CustomerProfileCard
                      contactId={contact?.id}
                      customerProfile={customerProfile}
                      billingAddressId={billingAddress?.id}
                      billingAddressAlert={{
                        saleOrderId: saleOrderProfile.id,
                        onClickAlert: ableToUpdateBillingAddress
                          ? () => {
                              setUpdateCBA(true)
                            }
                          : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SOProductsTable />
                  </Grid>
                  {showedPurchaseRequisitionLists && (
                    <Grid item xs={12}>
                      <SOPurchaseRequisitionTable />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SaleOrderDetail />
                  </Grid>
                  <Grid item xs={12}>
                    <SOAttachments />
                  </Grid>
                </>,
                <Grid item xs={12}>
                  <SODocumentList />
                </Grid>,
                <Grid item xs={12}>
                  <SOPaymentTransactions />
                  <Space />
                  {refundRequisitions.length > 0 && <SORefundRequisitions />}
                </Grid>,
                <>
                  <Grid item xs={12}>
                    <SOPurchaseOrderList />
                  </Grid>
                </>,
                <>
                  <Grid item xs={12}>
                    <SOTasks />
                  </Grid>
                </>,
                <Grid item xs={12}>
                  <SOCustomerRequisitions />
                </Grid>,
                <Grid item xs={12}>
                  <SOUserActivities />
                </Grid>,
              ][tabValue]
            }
          </Grid>
        </Grid>
      </Grid>
    </SaleOrderProfileContext.Provider>
  )
}

export default withLayout(SaleOrderProfilePage)
