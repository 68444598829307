import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStylesOld = makeStyles((theme) =>
  createStyles({
    root: {
      borderLeftWidth: theme.spacing(4),
      borderLeftColor: theme.palette.primary.dark,
      borderLeftStyle: 'solid',
      borderRadius: 4,
      padding: theme.spacing(3),
    },
    rootSecondary: {
      borderLeftWidth: theme.spacing(4),
      borderLeftColor: theme.palette.secondary.dark,
      borderLeftStyle: 'solid',
      borderRadius: 4,
      padding: theme.spacing(3),
    },
    profileGrid: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    profile: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
    profileCredit: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      borderWidth: theme.spacing(0.5),
      borderColor: theme.palette.custom?.golden,
      borderStyle: 'solid',
    },
    creditTypo: {
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      paddingButtom: theme.spacing(0.5),
      width: theme.spacing(15),
      textAlign: 'center',
      backgroundColor: theme.palette.custom?.golden,
      borderRadius: 4,
    },
    customerType1Chip: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    },
    customerType2Chip: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.light,
    },
    customerHead: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    contact: {
      padding: theme.spacing(2),
    },
  }),
)

export const useStyles = makeStyles((theme) =>
  createStyles({
    customerInfo: {
      padding: theme.spacing(1),
    },
    customerTitle: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    avatar: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
      },
    },
    creditAvatar: {
      borderWidth: theme.spacing(0.5),
      borderColor: theme.palette.custom?.golden,
      borderStyle: 'solid',
    },
    creditBadge: {
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingButtom: theme.spacing(0.5),
      width: theme.spacing(15),
      textAlign: 'center',
      backgroundColor: theme.palette.custom?.golden,
      borderRadius: 4,
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(10),
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
        marginTop: theme.spacing(1),
      },
    },
    contactInfo: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
  }),
)

export const useCompanyStyle = makeStyles((theme) =>
  createStyles({
    root: {
      borderLeftWidth: theme.spacing(4),
      borderLeftColor: theme.palette.primary.dark,
      borderLeftStyle: 'solid',
      borderRadius: 4,
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        borderLeftWidth: theme.spacing(1),
        padding: theme.spacing(1),
      },
    },
    chip: {
      marginLeft: theme.spacing(2),
      backgroundColor: theme.palette.primary.light,
    },
  }),
)

export const useCustomerStyle = makeStyles((theme) =>
  createStyles({
    root: {
      borderLeftWidth: theme.spacing(4),
      borderLeftColor: theme.palette.secondary.dark,
      borderLeftStyle: 'solid',
      borderRadius: 4,
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        borderLeftWidth: theme.spacing(1),
        padding: theme.spacing(1),
      },
    },
    chip: {
      marginLeft: theme.spacing(2),
      backgroundColor: theme.palette.secondary.light,
    },
  }),
)
