import { Button, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { VendorProfile_PaymentRequisition } from '../../../../api/smartbestbuys-api'
import { FlexBox, Link, Table, Title } from '../../../../custom-components'
import { dateTimeFormat } from '../../../../functions'
import { PaymentRequisitionStatusChip } from '../../../../shared-components'

// TODO: use new concept to pass vendor profile
interface Props {
  vendorId: number
  ableToCreatePayment: boolean
  paymentRequisitions: VendorProfile_PaymentRequisition[]
}

const VendorPaymentRequisitionTable: React.FC<Props> = (props: Props) => {
  const { vendorId, ableToCreatePayment, paymentRequisitions } = props
  const history = useHistory()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FlexBox justifyContent="space-between">
          <Title>รายการที่ต้องทำเบิก</Title>
          <Button
            variant="contained"
            color="primary"
            disabled={!ableToCreatePayment}
            onClick={() => history.push(`/vendors/${vendorId}/create-payment-requisition`)}
          >
            ทำเบิก
          </Button>
        </FlexBox>
      </Grid>
      <Grid item xs={12}>
        <Table<VendorProfile_PaymentRequisition>
          size="small"
          color="primary.dark"
          columns={[
            {
              name: 'ลำดับ',
              render: (_, index) => index + 1,
            },
            {
              name: 'สถานะการทำเบิก',
              dataIndex: 'status',
              render: ({ status }) => <PaymentRequisitionStatusChip status={status.id} label={status.name} />,
            },
            {
              name: 'เลขที่การทำเบิก',
              render: ({ id, code }) => (
                <Link color="primary" to={`/purchase-orders/${id}/profile`}>
                  <Typography variant="body2">{code}</Typography>
                </Link>
              ),
            },
            {
              name: 'เลขที่การทำจ่าย',
              dataIndex: 'paymentCode',
            },
            {
              name: 'ยอดทำเบิก',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
            },
            {
              name: 'หัก ณ ที่จ่ายรวม',
              dataIndex: 'withholdingTaxTotal',
              numeric: true,
              format: '0,0.00',
            },
            {
              name: 'ยอดทำเบิกสุทธิ',
              numeric: true,
              format: '0,0.00',
              render: ({ total, withholdingTaxTotal }) => total - (withholdingTaxTotal ?? 0),
            },
            {
              name: 'วันที่ทำเบิก',
              render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
            },
          ]}
          data={paymentRequisitions}
          cleanSpace
          pagination
        />
      </Grid>
    </Grid>
  )
}

export default VendorPaymentRequisitionTable
