import { dateSqlFormat } from '../../../functions'
import { errorGenericHandle, errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { CreatedInfo, FetchRequest, UserActivityNote } from '../types'
import {
  GetRefundRequisitionCreateDataQueryParams,
  IRefundRequisitionFormData,
  CreateRefundRequisitionRequestBody,
  IRefundRequisitionTableData,
  IRefundRequisitionUpdateData,
  UpdateRefundRequisitionRequestBody,
  IRefundRequisitionProfile,
  ConfirmPaymentRefundRequisitionRequestBody,
} from './refund-requisition-interfaces'

export const getRefundRequisitionsCreateData = errorGenericHandle<
  IRefundRequisitionFormData,
  GetRefundRequisitionCreateDataQueryParams
>(async (params: GetRefundRequisitionCreateDataQueryParams) => {
  return smartAuthAPI.get('/finance/v1/refund-requisitions/create-data', {
    params,
  })
})

export const getRefundRequisitionsUpdateData = errorSingleHandler<IRefundRequisitionUpdateData>(async (id: number) => {
  return smartAuthAPI.get(`/finance/v1/refund-requisitions/${id}/update-data`)
})

export const createRefundRequisition = errorHandler<CreatedInfo>((request: CreateRefundRequisitionRequestBody) => {
  return smartAuthAPI.post('/finance/v1/refund-requisitions/create', request)
})

export const updateRefundRequisition = errorSingleHandler<IRefundRequisitionProfile>(
  (id: number, request: UpdateRefundRequisitionRequestBody) => {
    return smartAuthAPI.put(`/finance/v1/refund-requisitions/${id}/update`, request)
  },
)

export const fetchRefundRequisitions = errorHandler<IRefundRequisitionTableData[]>(async (request: FetchRequest) => {
  return smartAuthAPI.get('/finance/v1/refund-requisitions/fetch', {
    params: {
      startDate: dateSqlFormat(request.startDate),
      endDate: dateSqlFormat(request.endDate),
    },
  })
})

export const getRefundRequisitionProfile = errorSingleHandler<IRefundRequisitionProfile>(
  async (refundRequisitionId: number) => {
    return smartAuthAPI.get(`/finance/v1/refund-requisitions/${refundRequisitionId}/profile`)
  },
)

export const approveRefundRequisitionAPI = errorSingleHandler<IRefundRequisitionProfile>(
  async (id: number, request?: UserActivityNote) => {
    return smartAuthAPI.post(`/finance/v1/refund-requisitions/${id}/approve`, request)
  },
)

export const rejectRefundRequisitionAPI = errorSingleHandler<IRefundRequisitionProfile>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`/finance/v1/refund-requisitions/${id}/reject`, request)
  },
)

export const cancelRefundRequisitionAPI = errorSingleHandler<IRefundRequisitionProfile>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`/finance/v1/refund-requisitions/${id}/cancel`, request)
  },
)

export const confirmPaymentRefundRequisitionAPI = errorSingleHandler<IRefundRequisitionProfile>(
  async (id: number, request: ConfirmPaymentRefundRequisitionRequestBody) => {
    return smartAuthAPI.post(`/finance/v1/refund-requisitions/${id}/confirm`, request)
  },
)
