import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { ProductPrice, ProductPriceRequest } from './types'

export const updateProductPriceAPI = errorSingleHandler<ProductPrice>(
  (id: number, request: SyncProductPriceListRequest) => {
    const path = `/products/${id}/prices`
    return smartAuthAPI.put(path, request)
  },
)

export interface SyncProductPriceListRequest {
  prices: ProductPriceRequest[]
}
