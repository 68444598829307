import {
  IProductAnalyticReportTableData_Data,
  IProductAnalyticReportTableData_SearchParams,
  ProductAnalyticTableDataQueryParams,
  TableDataQueryParams,
  getProductAnalyticTableDataAPI,
} from '../../api/smartbestbuys-api'

import { useTableData } from '../useTablaData'
import moment from 'moment'

type SearchQueryParams = Omit<ProductAnalyticTableDataQueryParams, keyof TableDataQueryParams>

const _buildQueryParams = (_s: IProductAnalyticReportTableData_SearchParams): SearchQueryParams => {
  return {
    startDateTime: moment(_s.startDateTime).startOf('day').toDate().toISOString(),
    endDateTime: moment(_s.endDateTime).endOf('day').toDate().toISOString(),
    grade: _s.grade === 'all' ? undefined : _s.grade,
    karajicMatrixIds:
      !!_s.karajicMatrixIds && _s.karajicMatrixIds.length > 0 ? _s.karajicMatrixIds.join(',') : undefined,
    isDeadStock: _s.isDeadStock ? 'true' : undefined,
    contributionOp: _s.contributionOp === 0 ? undefined : _s.contributionOp?.toString(),
  }
}

export const useProductAnalyticTableData = (defaultSerachParams: IProductAnalyticReportTableData_SearchParams) =>
  useTableData<
    IProductAnalyticReportTableData_Data,
    {},
    IProductAnalyticReportTableData_SearchParams,
    SearchQueryParams
  >(0, 10, defaultSerachParams, getProductAnalyticTableDataAPI, _buildQueryParams)
