import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { isBefore } from 'date-fns'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import { NewWarehouseTask } from '../../../api/smartbestbuys-api'
import { FilteredTable, Topic, withLayout, Link } from '../../../custom-components'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { PageName, TaskStatus, TaskType } from '../../../enums'
import { dateFormat, dateTimeFormat } from '../../../functions'
import { useWarehouseTasksV2 } from '../../../hooks'
import { displayDateWithDiffDay } from '../../../utils/date-utils'
import AlreadyReceivedProductDialog from './AlreadyReceivedProductDialog'
import ReceiveProductDialog from './ReceiveProductDialog'
import './WarehouseTaskListPage.less'

const WarehouseTasksListPage = () => {
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.WAREHOUSE_PENDING_VENDOR_SHIPPING)

  // state
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [tabValue, setTabValue] = useState(TaskStatus.Pending)
  const [selectedReceiveProductTaskId, setSelectedReceiveProductTaskId] = useState<number | undefined>(undefined)
  const [reload, setReload] = useState(false)

  // hook
  const warehouseTasks = useWarehouseTasksV2(startDate, endDate, TaskType.WarehouseReceivedProduct, reload)

  const sortedWarehouseTasks = useMemo(() => {
    const filteredWarehouseTasks = warehouseTasks.filter((data) => data.status.id === tabValue)
    if (tabValue === TaskStatus.Done) {
      return filteredWarehouseTasks.sort((a: NewWarehouseTask, b: NewWarehouseTask): number => {
        if (!!a.finishedAt && !!b.finishedAt) {
          const aDate = new Date(a.finishedAt)
          const bDate = new Date(b.finishedAt)
          return isBefore(aDate, bDate) ? 1 : -1
        }
        return 1
      })
    }
    return filteredWarehouseTasks
    // eslint-disable-next-line
  }, [tabValue, warehouseTasks.map((t) => t.code).toString()])

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    setSelectedReceiveProductTaskId(undefined)
  }

  const renderDialog = () => {
    if (selectedReceiveProductTaskId === undefined) return <></>
    if (tabValue === TaskStatus.Pending) {
      return (
        <ReceiveProductDialog
          open={!!selectedReceiveProductTaskId}
          receiveProductTaskId={selectedReceiveProductTaskId}
          onComplete={() => {
            setReload((prev) => !prev)
          }}
          onClose={() => {
            setSelectedReceiveProductTaskId(undefined)
          }}
        />
      )
    }

    return (
      <AlreadyReceivedProductDialog
        open={!!selectedReceiveProductTaskId}
        receiveProductTaskId={selectedReceiveProductTaskId}
        onClose={() => {
          setSelectedReceiveProductTaskId(undefined)
        }}
      />
    )
  }

  return (
    <Box p={2}>
      {renderDialog()}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Topic>รายการรับสินค้าจากผู้ขาย</Topic>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<NewWarehouseTask>
              color="primary.dark"
              columns={[
                {
                  name: 'เลขที่งานคลัง',
                  dataIndex: 'code',
                  render: (record) => {
                    return (
                      <Box color="primary.dark" className="warehouse-task-table__code" clone>
                        <Typography variant="body2" onClick={() => setSelectedReceiveProductTaskId(record.id)}>
                          {record.code}
                        </Typography>
                      </Box>
                    )
                  },
                },
                {
                  name: 'ใบสั่งซื้ออ้างอิง',
                  dataIndex: 'code',
                  render: ({ purchaseOrderInfo }) => (
                    <Link color="primary" to={`/purchase-orders/${purchaseOrderInfo?.id}/profile`} target="_blank">
                      <Box color="secondary.dark">
                        <Typography variant="body2">{purchaseOrderInfo?.code}</Typography>
                      </Box>
                    </Link>
                  ),
                },
                {
                  ...(tabValue === TaskStatus.Done
                    ? {
                        name: 'วันที่รับสินค้า',
                        render: ({ finishedAt, dueDate: _dueDate }) => {
                          if (!finishedAt) return '-'
                          const finishedAtDate = new Date(finishedAt)
                          if (!!_dueDate) {
                            const dueDate = new Date(_dueDate)
                            return displayDateWithDiffDay(finishedAtDate, dueDate)
                          }
                          return <Typography variant="body2">{dateFormat(finishedAtDate)}</Typography>
                        },
                      }
                    : {
                        name: 'วันที่นัดส่งสินค้า',
                        render: ({ dueDate }) => (!!dueDate ? dateFormat(dueDate) : '-'),
                      }),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  render: ({ purchaseOrderInfo }) => purchaseOrderInfo?.vendorName,
                },
                {
                  ...(tabValue === TaskStatus.Done
                    ? {
                        name: 'รับโดย',
                        dataIndex: 'finishedBy',
                      }
                    : {
                        name: 'วันที่สร้าง',
                        render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                      }),
                },
              ]}
              sortable
              data={sortedWarehouseTasks}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              tabOption={{
                tabs: [
                  {
                    label: `งานที่ต้องรับ (${
                      warehouseTasks.filter((data) => data.status.id === TaskStatus.Pending).length
                    })`,
                    value: TaskStatus.Pending,
                  },
                  { label: 'รับเสร็จสิ้น', value: TaskStatus.Done },
                  { label: 'ยกเลิก', value: TaskStatus.Canceled },
                ],
                value: tabValue,
                onChange: handleTabChange,
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(WarehouseTasksListPage)
