import React, { useContext, useState } from 'react'
import { Grid, Paper, Typography, Box } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, FlexBox, Link } from '../../../custom-components'
import { PaymentRequisition_TableData } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import {
  NavigationItemKey,
  PageName,
  PaymentReceivingProductStatus,
  PaymentRequisitionStatus,
  PaymentRequisitionStatusGroup,
} from '../../../enums'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { usePaymentRequisitions } from '../../../hooks'
import {
  NotificationHead,
  PaymentReceivingProductStatusChip,
  PaymentRequisitionStatusChip,
} from '../../../shared-components'
import { dateTimeFormat } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'

const _filterByStatusGroup = (
  data: PaymentRequisition_TableData,
  statusGroup: PaymentRequisitionStatusGroup,
): boolean => {
  switch (statusGroup) {
    case PaymentRequisitionStatusGroup.Pending:
      return data.status.id === PaymentRequisitionStatus.PendingReview
    case PaymentRequisitionStatusGroup.Approved:
      return [
        PaymentRequisitionStatus.PendingTransfer,
        PaymentRequisitionStatus.PendingCheque,
        PaymentRequisitionStatus.PendingReceipt,
        PaymentRequisitionStatus.PendingStatement,
        PaymentRequisitionStatus.Success,
      ].includes(data.status.id)
    case PaymentRequisitionStatusGroup.Rejected:
      return data.status.id === PaymentRequisitionStatus.Rejected
    case PaymentRequisitionStatusGroup.Canceled:
      return data.status.id === PaymentRequisitionStatus.Canceled
    default:
      return false
  }
}

const PaymentRequisitionListPage: React.FC = (props) => {
  // context & state
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PAYMENT_REQUISITION)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [tabValue, setTabValue] = useState(1)

  // prepare
  const paymentRequisitions = usePaymentRequisitions(startDate, endDate)
  const pendingAmount = paymentRequisitions.filter((data) =>
    _filterByStatusGroup(data, PaymentRequisitionStatusGroup.Pending),
  ).length

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการทำเบิก</Topic>
          </FlexBox>
          <NotificationHead navigationKey={NavigationItemKey.PaymentRequisition} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<PaymentRequisition_TableData>
              color="primary.dark"
              columns={[
                {
                  name: 'สถานะการทำเบิก',
                  dataIndex: 'status',
                  render: ({ status }) => <PaymentRequisitionStatusChip status={status.id} label={status.name} />,
                },
                {
                  name: 'สถานะการรับของ',
                  dataIndex: 'paymentReceivingProductStatus',
                  render: ({ paymentReceivingProductStatus }) => (
                    <PaymentReceivingProductStatusChip status={paymentReceivingProductStatus} />
                  ),
                },
                {
                  name: 'เลขที่การทำเบิก',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/payment-requisitions/${id}/profile`}>
                      <Typography variant="body2">{code}</Typography>
                    </Link>
                  ),
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  dataIndex: 'vendor',
                  render: ({ vendor }) => vendor.name,
                },
                { name: 'ยอดทำเบิก', dataIndex: 'total', numeric: true, format: '0,0.00' },
                { name: 'หัก ณ ที่จ่าย', dataIndex: 'withholdingTaxAmount', numeric: true, format: '0,0.00' },
                {
                  name: 'ยอดทำเบิกสุทธิ',
                  render: ({ total, withholdingTaxAmount }) => total - (withholdingTaxAmount ?? 0),
                  numeric: true,
                  format: '0,0.00',
                },
                {
                  name: 'จำนวนใบสั่งขาย',
                  dataIndex: 'countPurchaseOrder',
                  numeric: true,
                },
                { name: 'วิธีการชำระเงิน', render: ({ paymentMethod }) => paymentMethod.name },
              ]}
              sortable
              data={paymentRequisitions.filter((data) => _filterByStatusGroup(data, tabValue))}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              tabOption={{
                tabs: [
                  {
                    label: `รอตรวจสอบ (${formatNumberInt(pendingAmount)})`,
                    value: PaymentRequisitionStatusGroup.Pending,
                  },
                  { label: 'อนุมัติ', value: PaymentRequisitionStatusGroup.Approved },
                  { label: 'ไม่อนุมัติ', value: PaymentRequisitionStatusGroup.Rejected },
                  { label: 'ยกเลิก', value: PaymentRequisitionStatusGroup.Canceled },
                ],
                value: tabValue,
                onChange: handleTabChange,
              }}
              filters={[
                {
                  name: 'สถานะการรับของ',
                  values: [
                    {
                      name: 'ยังไม่ได้รับของ',
                      value: PaymentReceivingProductStatus.Pending,
                    },
                    {
                      name: 'รับของบางส่วนแล้ว',
                      value: PaymentReceivingProductStatus.Partial,
                    },
                    {
                      name: 'รับของครบถ้วน',
                      value: PaymentReceivingProductStatus.ReceivedAll,
                    },
                  ],
                  filter: (d, values) => values.includes(d.paymentReceivingProductStatus.id),
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(PaymentRequisitionListPage, true)
