import { Grid, InputAdornment, Paper, Typography } from '@material-ui/core'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FlexBox, GeneralTextFieldForm, SubTopic } from '../../../custom-components'
import { CurrenciesType, PurchasePaymentCondition } from '../../../enums'
import { calculatePercentage, calculatePurchaseOrderTotal } from '../../../functions'
import { ButtonRadios } from '../../../shared-components'
import { currencies, purchasePaymentConditions, purchasePaymentMethods } from '../../../types'
import PurchaseOrderCreateFormValues from '../create/PurchaseOrderCreateFormValues'

const PurchaseOrderUpdateFormBox: React.FC = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    register,
    unregister,
  } = useFormContext<PurchaseOrderCreateFormValues>()

  const { paymentConditionId, paymentMethodId, products, discountAmount, vatIncluded, vendor } = watch()

  const productTotal = products.reduce<number>((sum, product) => {
    return sum + product.price! * product.amount
  }, 0)

  const total = calculatePurchaseOrderTotal(productTotal, discountAmount, vatIncluded)

  useEffect(() => {
    register('paymentMethodId', {
      required: 'กรุณาเลือกวิธีการชำระเงิน',
    })
    register('paymentConditionId', {
      required: 'กรุณาเลือกเงื่อนไขการซื้อ',
    })
  }, [register])

  useEffect(() => {
    if (paymentConditionId === PurchasePaymentCondition.DepositRequire) {
      register('depositAmount', {
        required: 'กรุณากรอกยอดมัดจำ',
        min: { value: 0.01, message: 'ยอดมัดจำไม่สามารถกรอกจำนวนติดลบได้' },
      })
    } else {
      unregister('depositAmount')
    }
  }, [paymentConditionId, register, unregister])

  const isTHBCurrency = vendor?.currency?.id === CurrenciesType.THB
  const refPaymentMethod = useMemo(() => vendor?.refPaymentMethod, [vendor?.refPaymentMethod])

  const depositPercentages = [
    { id: 0, name: '30 %', value: 30 },
    { id: 1, name: '50 %', value: 50 },
    { id: 2, name: '70 %', value: 70 },
  ]

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>ข้อมูลเพิ่มเติม</SubTopic>
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonRadios
            label="วิธีการชำระเงิน *"
            items={purchasePaymentMethods}
            onChange={(value) => setValue('paymentMethodId', value)}
            errorMessage={errors.paymentMethodId?.message}
            helperText={!!refPaymentMethod ? `ผู้ขายใช้ “${refPaymentMethod.name}” เป็นวิธีหลัก` : undefined}
            value={paymentMethodId}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonRadios
            label="เงื่อนไขการซื้อ *"
            items={purchasePaymentConditions.filter((condition) => {
              if (condition.id === PurchasePaymentCondition.Credit) return !!vendor?.creditPeriodDay
              return true
            })}
            onChange={(value) => setValue('paymentConditionId', value)}
            errorMessage={errors.paymentConditionId?.message}
            value={paymentConditionId}
          />
        </Grid>
        {paymentConditionId === PurchasePaymentCondition.DepositRequire && (
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="depositAmount"
              type="number"
              label="ยอดมัดจำ *"
              defaultValue=""
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencies.find((currency) => currency.id === vendor?.currency?.id)?.name ?? 'บาท'}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                step: '0.01',
              }}
            />
          </Grid>
        )}
        {paymentConditionId === PurchasePaymentCondition.DepositRequire && (
          <Grid item xs={12}>
            <FlexBox alignItems="center">
              <ButtonRadios
                items={depositPercentages}
                onChange={(value) =>
                  setValue('depositAmount', calculatePercentage(total, depositPercentages[value].value))
                }
              />
              <Typography variant="caption" color="textSecondary" className="pl-2">
                (% คิดจากยอดเต็ม)
              </Typography>
            </FlexBox>
          </Grid>
        )}
        {!isTHBCurrency && (
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="exchangeRate"
              type="number"
              label="อัตราการแลกเปลี่ยนเงิน"
              rules={{ required: 'กรุณากรอกอัตราการแลกเปลี่ยน' }}
              defaultValue=""
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            control={control}
            name="vendorQuotationCode"
            type="text"
            label="เลขใบเสนอราคาของผู้ขาย (ถ้ามี)"
            defaultValue=""
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm control={control} name="remark" type="text" label="หมายเหตุ (ถ้ามี)" defaultValue="" />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PurchaseOrderUpdateFormBox
