import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import { EmptyCard, FlexBox, SubTopic, Table } from '../../../custom-components'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import { PurchaseOrderProfile_Task } from '../../../api/smartbestbuys-api/purchase-order/types'
import { dateFormat, dateTimeFormat } from '../../../functions'
import { TaskStatusChip } from '../../../shared-components'

const PurchaseOrderProfileTaskTable: React.FC = () => {
  // context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { tasks } = purchaseOrderProfile

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ข้อมูลการรับสินค้า</SubTopic>
        <div className="mb-4" />
        {tasks && tasks.length > 0 ? (
          <Table<PurchaseOrderProfile_Task>
            color="primary.dark"
            columns={[
              { name: 'เลขที่งานคลัง', dataIndex: 'code' },
              {
                name: 'สถานะงานรับ',
                dataIndex: 'status',
                render: ({ status }) => <TaskStatusChip status={status.id} label={status.name} />,
              },
              {
                name: 'วันที่ส่งสินค้า',
                dataIndex: 'dueDate',
                render: ({ dueDate }) => (dueDate ? dateFormat(dueDate) : '-'),
              },
              {
                name: 'วันที่สร้าง',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                align: 'center',
              },
            ]}
            data={tasks}
            cleanSpace
          />
        ) : (
          <EmptyCard emptyText="ยังไม่มีข้อมูลการรับสินค้า" />
        )}
      </Paper>
    </FlexBox>
  )
}

export default PurchaseOrderProfileTaskTable
