import { Grid, Paper } from '@material-ui/core'
import { useContext } from 'react'
import { FlexBox, SubTopic } from '../../../custom-components'
import { FileType, SourceFile } from '../../../types'
import FileCard from '../../purchase-requisitions/create/AttachmentSelectionBox/FileCard'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'

const PurchaseOrderProfileAttachmentBox: React.FC = () => {
  // context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { attachments } = purchaseOrderProfile

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>ไฟล์แนบเพิ่มเติม</SubTopic>
            <div className="mb-4" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {!!attachments &&
                attachments.map((attach) => {
                  const sourceFile: SourceFile = {
                    base64: attach.attachmentUrl,
                    name: attach.attachmentUrl,
                    type: FileType.Image,
                  }
                  return (
                    <Grid item xs={12} md={2} key={sourceFile.name}>
                      <FileCard value={sourceFile} readonly />
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </FlexBox>
  )
}

export default PurchaseOrderProfileAttachmentBox
