import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { CustomerRequisitionStatus } from '../../enums'

interface Props {
  status: CustomerRequisitionStatus
  icon?: boolean
}

const useStyles = (status: CustomerRequisitionStatus) =>
  makeStyles((theme) => {
    const crStatusMap = {
      [CustomerRequisitionStatus.PendingToReview]: theme.palette.warning.main,
      [CustomerRequisitionStatus.Approved]: theme.palette.warning.main,
      [CustomerRequisitionStatus.InProgress]: theme.palette.warning.main,
      [CustomerRequisitionStatus.Done]: theme.palette.success.main,
      [CustomerRequisitionStatus.Rejected]: theme.palette.error.main,
      [CustomerRequisitionStatus.Canceled]: theme.palette.error.main,
    }

    const crStatusIconMap = {
      [CustomerRequisitionStatus.PendingToReview]: theme.palette.warning.darkText,
      [CustomerRequisitionStatus.Approved]: theme.palette.warning.darkText,
      [CustomerRequisitionStatus.InProgress]: theme.palette.warning.darkText,
      [CustomerRequisitionStatus.Done]: theme.palette.success.darkText,
      [CustomerRequisitionStatus.Rejected]: theme.palette.error.darkText,
      [CustomerRequisitionStatus.Canceled]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: crStatusMap[status],
      },
      icon: {
        color: crStatusIconMap[status],
      },
    })
  })

const CRStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ children, status, icon, ...othersProps }) => {
  const classes = useStyles(status)()
  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      icon={icon ? <Description /> : undefined}
      {...othersProps}
    />
  )
}

export default CRStatusChip
