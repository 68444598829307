import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import { FlexBox } from '../../custom-components'
import { SubmitProps } from './SubmitForm'
import { ConditionTooltip } from '..'
import { getUserPermissionInfo } from '../../utils/permission'

export interface ConfirmTextOption {
  veifiedText: string
}

export interface SubmitWithConfirmTextProps extends SubmitProps {
  confirmTextOption?: ConfirmTextOption
}

const SubmitWithConfirmTextForm: React.FC<SubmitWithConfirmTextProps> = (props) => {
  // props
  const {
    submitText,
    cancelText,
    onSubmit,
    onCancel,
    hideCancelButton,
    disableSubmit,
    redirectPath,
    confirmTextOption,
    isSubmitting,
    submitPermission,
  } = props

  // state
  const [typo, setTypo] = useState<string>('')
  const [validateOnChange, setValidateOnChange] = useState(false)

  const history = useHistory()

  const handleCancel = () => {
    if (redirectPath) {
      history.push(redirectPath)
    }
    onCancel && onCancel()
  }

  // confirm text
  const verified = validateOnChange ? typo === confirmTextOption?.veifiedText : true
  const ableToSubmit = !!confirmTextOption ? !!typo : true

  if (submitPermission) {
    // permission
    const writePermission = getUserPermissionInfo(submitPermission)

    return (
      <FlexBox
        width="100%"
        style={{ paddingLeft: 14, minHeight: 86 }}
        justifyContent="space-between"
        alignItems="center"
      >
        {confirmTextOption ? (
          <TextField
            style={{ maxWidth: 320, width: '100%' }}
            label={`พิมพ์ “${confirmTextOption.veifiedText}” เพื่อยืนยัน *`}
            onChange={(e) => setTypo(e.target.value)}
            variant="outlined"
            size="small"
            error={!verified}
            helperText={!verified && `กรุณาพิมพ์ “${confirmTextOption.veifiedText}” เพื่อยืนยัน *`}
          />
        ) : (
          <div></div>
        )}
        <FlexBox>
          {!hideCancelButton && (
            <Button style={{ minWidth: 64, marginRight: 8 }} color="primary" onClick={handleCancel}>
              {cancelText || 'ยกเลิก'}
            </Button>
          )}
          <ConditionTooltip
            showTooltip={!!writePermission && !writePermission.hasPermission}
            title={!!writePermission ? writePermission.alertMessage : ''}
          >
            <Button
              style={{ minWidth: 64 }}
              variant="contained"
              color="primary"
              type="submit"
              onClick={(e) => {
                setValidateOnChange(true)
                if (!!confirmTextOption && typo !== confirmTextOption.veifiedText) {
                  e.preventDefault()
                  return
                }
                if (!!onSubmit) onSubmit()
              }}
              disabled={
                isSubmitting || disableSubmit || !ableToSubmit || (!!writePermission && !writePermission.hasPermission)
              }
              startIcon={isSubmitting ? <CircularProgress size={16} /> : undefined}
            >
              {submitText || 'ยืนยัน'}
            </Button>
          </ConditionTooltip>
        </FlexBox>
      </FlexBox>
    )
  }

  return (
    <FlexBox width="100%" style={{ paddingLeft: 14, minHeight: 86 }} justifyContent="space-between" alignItems="center">
      {confirmTextOption ? (
        <TextField
          style={{ maxWidth: 320, width: '100%' }}
          label={`พิมพ์ “${confirmTextOption.veifiedText}” เพื่อยืนยัน *`}
          onChange={(e) => setTypo(e.target.value)}
          variant="outlined"
          size="small"
          error={!verified}
          helperText={!verified && `กรุณาพิมพ์ “${confirmTextOption.veifiedText}” เพื่อยืนยัน *`}
        />
      ) : (
        <div></div>
      )}
      <FlexBox>
        {!hideCancelButton && (
          <Button style={{ minWidth: 64, marginRight: 8 }} color="primary" onClick={handleCancel}>
            {cancelText || 'ยกเลิก'}
          </Button>
        )}
        <Button
          style={{ minWidth: 64 }}
          variant="contained"
          color="primary"
          type="submit"
          onClick={(e) => {
            setValidateOnChange(true)
            if (!!confirmTextOption && typo !== confirmTextOption.veifiedText) {
              e.preventDefault()
              return
            }
            if (!!onSubmit) onSubmit()
          }}
          disabled={isSubmitting || disableSubmit || !ableToSubmit}
          startIcon={isSubmitting ? <CircularProgress size={16} /> : undefined}
        >
          {submitText || 'ยืนยัน'}
        </Button>
      </FlexBox>
    </FlexBox>
  )
}

export default SubmitWithConfirmTextForm
