import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  MenuItem,
  Box,
} from '@material-ui/core'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { RootContext } from '../../..'
import {
  FollowServiceTask,
  PurchaseTaskReceiveServiceRequestBody,
  ReceiveProductTask_PurchaseOrderInfo_Product,
  receiveServiceAPI,
} from '../../../api/smartbestbuys-api'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  KeyboardDatePickerForm,
  Table,
  TypographyWithLabel,
} from '../../../custom-components'
import { PurchaseOrderStatus, TaskType } from '../../../enums'
import { ReceiveServiceAction } from '../../../enums/PurchaseEnums'
import { dateFormat, dateSqlFormat } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { usePurchaseTask } from '../../../hooks'
import {
  Loading,
  PurchaseOrderStatusChip,
  SOStatusChip,
  Space,
  SubmitWithConfirmTextForm,
} from '../../../shared-components'
import PurchaseProductName from '../../purchase-orders/create/SelectedPurchaseOrderProductTable/PurchaseProductName'
import VendorProfileCard from '../../purchase-orders/profile/VendorProfileCard'

interface FormValues {
  receiveProductDetails: ReceiveServiceDetail[]
  nextVendorShippingDate?: string
  remark?: string
}

interface Props extends DialogProps {
  receiveServiceTaskId: number
  onComplete?: (data: FollowServiceTask) => void
  onFailed?: () => void
}

// TODO: move to constants
// NOTE: duplicated with ReceiveProductDialog
const whitelistedStatusToReceive = [
  PurchaseOrderStatus.AppointmentPending,
  PurchaseOrderStatus.ProductPending,
  PurchaseOrderStatus.VendorShippingToCustomer,
  PurchaseOrderStatus.PartialReceived,
]

const ReceiveServiceDialog = (props: Props) => {
  // context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { receiveServiceTaskId, onClose, onComplete, onFailed, ...otherProps } = props

  // prepare data
  const receiveServiceTask = usePurchaseTask(receiveServiceTaskId, TaskType.FollowServiceTask)

  // hook form
  const formMethod = useForm<FormValues>()
  const {
    control,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = formMethod

  const { receiveProductDetails, nextVendorShippingDate } = watch()
  const showNextVendorShippingDate = !!receiveProductDetails?.find(
    (p) =>
      !!p.receiveAction &&
      (p.receiveAction === ReceiveServiceAction.Partial || p.receiveAction === ReceiveServiceAction.Nothing),
  )

  useEffect(() => {
    if (!showNextVendorShippingDate) setValue('nextVendorShippingDate', undefined)
  }, [showNextVendorShippingDate, setValue])

  // effect
  useEffect(() => {
    if (!!receiveServiceTask) {
      const initReceiveProductDetails = receiveServiceTask.purchaseOrderInfo.products.map((product) => {
        return {
          ...product,
          expectedReceivedAmount: Math.max(product.amount - product.receivedAmount, 0),
        } as ReceiveServiceDetail
      })
      // .filter((product) => product.expectedReceivedAmount > 0)
      reset({ receiveProductDetails: initReceiveProductDetails })
    }
  }, [receiveServiceTask, reset])

  // loading
  if (!receiveServiceTask)
    return (
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Loading />
      </Dialog>
    )

  // handler
  const handleSubmit = async (values: FormValues) => {
    const requestBody: PurchaseTaskReceiveServiceRequestBody = {
      nextVendorShippingDate: values.nextVendorShippingDate
        ? dateSqlFormat(values.nextVendorShippingDate, 'yyyy-MM-dd')
        : undefined,
      remark: values.remark,
      results: values.receiveProductDetails.map((p) => {
        const alreadyReceived = p.expectedReceivedAmount === 0
        return {
          purchaseProductId: p.id,
          action: alreadyReceived ? ReceiveServiceAction.AlreadyReceived : p.receiveAction,
          receivedAmount: alreadyReceived ? 0 : p.toReceiveAmount,
          expectedAmount: p.expectedReceivedAmount,
        }
      }),
    }

    const response = await receiveServiceAPI(receiveServiceTaskId, requestBody)
    if (response?.status === 200) {
      triggerSnackbar('ติดตามงานบริการสำเร็จ')
      if (!!onClose) onClose({}, 'backdropClick')
      if (!!onComplete) onComplete(response.data)
    } else {
      triggerSnackbar('ติดตามงานบริการไม่สำเร็จ')
      if (!!onFailed) onFailed()
    }
  }

  const { code, vendorInfo, purchaseOrderInfo, saleOrderInfo } = receiveServiceTask
  const { status } = purchaseOrderInfo

  const ableToReceive = whitelistedStatusToReceive.includes(status.id)

  return (
    <FormProvider {...formMethod}>
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>
            {code} <Space direction="horizontal" />
            <Typography color="textSecondary">งานรับบริการ</Typography>
          </DialogTitle>
          <DialogContent>
            <VendorProfileCard value={vendorInfo} />
            <Space />
            <Grid container>
              <Grid item xs={6}>
                <TypographyWithLabel label="วันที่ส่งสินค้า:">
                  <Typography color="textSecondary">
                    {purchaseOrderInfo.vendorShippingDate ? dateFormat(purchaseOrderInfo.vendorShippingDate) : '-'}
                  </Typography>
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel
                  label="ใบสั่งซื้ออ้างอิง:"
                  labelProps={{
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Typography color="textSecondary">
                    {purchaseOrderInfo.code}
                    <Space direction="horizontal" />
                    <PurchaseOrderStatusChip status={status.id} label={status.name} />
                  </Typography>
                </TypographyWithLabel>
                {!!saleOrderInfo && (
                  <TypographyWithLabel
                    label="ใบสั่งขายอ้างอิง:"
                    labelProps={{
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                      },
                    }}
                  >
                    <Typography color="textSecondary">
                      {saleOrderInfo?.code}
                      <Space direction="horizontal" />
                      <SOStatusChip status={saleOrderInfo?.status.id} label={saleOrderInfo?.status.name} />
                    </Typography>
                  </TypographyWithLabel>
                )}
                {!ableToReceive && (
                  <FlexBox>
                    <Typography color="error" variant="caption">
                      ไม่สามารถรับของได้เนื่องจากสถานะของใบสั่งซื้อเป็น "{status.name}"
                    </Typography>
                  </FlexBox>
                )}
              </Grid>
            </Grid>
            <Space />
            <ReceiveServiceTable />
            <Space />
            <Grid container spacing={2}>
              {showNextVendorShippingDate && (
                <Grid item xs={12} md={6}>
                  <KeyboardDatePickerForm
                    control={control}
                    label="วันที่ส่งสินค้าใหม่ (ถ้ามี)"
                    name="nextVendorShippingDate"
                    size="small"
                    gutterTop
                    value={nextVendorShippingDate}
                    fullWidth
                    defaultValue={null}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm control={control} label="หมายเหตุ (ถ้ามี)" name="remark" fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitWithConfirmTextForm
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              disableSubmit={!ableToReceive}
              isSubmitting={isSubmitting}
              confirmTextOption={{
                veifiedText: 'จำนวนถูกต้องแน่นอน',
              }}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  )
}

export default ReceiveServiceDialog

const grayscaleStyle = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
}

interface ReceiveServiceDetail extends ReceiveProductTask_PurchaseOrderInfo_Product {
  receiveAction: ReceiveServiceAction
  toReceiveAmount: number
  expectedReceivedAmount: number
}

const ReceiveServiceTable = () => {
  // state
  const { control, setValue, watch } = useFormContext<FormValues>()
  const { receiveProductDetails } = watch()

  // handler
  const handleReceiveActionChange = (value: ReceiveServiceAction, index: number) => {
    const target = receiveProductDetails[index]
    target.receiveAction = value
    if (value === ReceiveServiceAction.All) target.toReceiveAmount = target.expectedReceivedAmount
    if (value === ReceiveServiceAction.Nothing) target.toReceiveAmount = 0
    setValue('receiveProductDetails', [
      ...receiveProductDetails.slice(0, index),
      target,
      ...receiveProductDetails.slice(index + 1),
    ])
  }

  return (
    <Table<ReceiveServiceDetail>
      color="primary"
      size="small"
      data={receiveProductDetails}
      columns={[
        { name: 'ลำดับ', align: 'center', fixedWidth: 70, render: (_, index) => index + 1 },
        {
          name: 'ชื่อสินค้า',
          render: (product) => (
            <PurchaseProductName
              style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}
              imageUrl={product.imageUrl}
              code={product.code}
              name={product.name}
            />
          ),
        },
        {
          name: 'จำนวนที่เคยรับแล้ว/จำนวนตามใบสั่งซื้อ',
          fixedWidth: 170,
          align: 'right',
          render: (product) => (
            <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>
              {formatNumberInt(product.receivedAmount)}/{formatNumberInt(product.amount)}
            </Typography>
          ),
        },
        {
          name: 'ผลการรับ',
          fixedWidth: 190,
          render: (product, index) => {
            if (product.expectedReceivedAmount === 0)
              return (
                <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>-</Typography>
              )
            const isFirstTime = product.receivedAmount === 0
            return (
              <GeneralTextFieldForm
                control={control}
                name={`receiveProductDetails.${index}.receiveAction`}
                select
                rules={{ required: 'กรุณาเลือกผลการรับ' }}
                onChange={(e) => !!e.target.value && handleReceiveActionChange(Number(e.target.value), index)}
              >
                <MenuItem value={ReceiveServiceAction.All}>{isFirstTime ? 'รับครบ' : 'รับที่เหลือ'}</MenuItem>
                <MenuItem value={ReceiveServiceAction.Partial}>รับขาด</MenuItem>
                {/* <MenuItem value={ReceiveProductAction.RejectRemaining}>รับขาดแต่ไม่รอรับที่ขาด</MenuItem> */}
                <MenuItem value={ReceiveServiceAction.Nothing}>ไม่มีให้รับ</MenuItem>
              </GeneralTextFieldForm>
            )
          },
        },
        {
          name: 'จำนวนที่รับ',
          fixedWidth: 170,
          align: 'right',
          render: (product, index) => {
            if (product.expectedReceivedAmount === 0)
              return (
                <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>-</Typography>
              )
            if (
              product.receiveAction &&
              [ReceiveServiceAction.Partial, ReceiveServiceAction.RejectRemaining].includes(product.receiveAction)
            ) {
              return (
                <GeneralTextFieldForm
                  control={control}
                  name={`receiveProductDetails.${index}.toReceiveAmount`}
                  type="number"
                  rules={{
                    required: 'กรุณากรอกจำนวน',
                    min: { value: 1, message: 'รับอย่างน้อย 1 หน่วย' },
                  }}
                />
              )
            }

            return '-'
          },
        },
        {
          name: 'จำนวนรับจริง/จำนวนที่ต้องรับ',
          align: 'right',
          fixedWidth: 140,
          render: (product) => {
            if (product.expectedReceivedAmount === 0)
              return (
                <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>
                  รับครบไปแล้ว
                </Typography>
              )
            if (product.toReceiveAmount === undefined)
              return <Typography>?/{formatNumberInt(product.expectedReceivedAmount)}</Typography>
            let textColor = 'textPrimary'
            if (product.toReceiveAmount === product.expectedReceivedAmount) textColor = 'success.main'
            if (product.toReceiveAmount < product.expectedReceivedAmount) textColor = 'error.main'
            if (product.toReceiveAmount > product.expectedReceivedAmount) textColor = 'warning.main'
            return (
              <Box color={textColor} clone>
                <Typography>
                  {`${formatNumberInt(product.toReceiveAmount)}/${formatNumberInt(product.expectedReceivedAmount)}`}
                </Typography>
              </Box>
            )
          },
        },
      ]}
      cleanSpace
      pagination={false}
    />
  )
}
