import { Box, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { Avatar, Table } from '../../../../custom-components'
import { ReceiveProductAction } from '../../../../enums'
import { formatNumberInt } from '../../../../functions/number-utils'
import ReceiveProductActionMessage from './ReceiveProductResultMessage'

export interface ReceiveProductTableData {
  id: number
  code?: string
  name: string
  toReceiveAmount: number
  expectedReceiveAmount: number
  amount: number
  imageUrl?: string
  receivedAction?: ReceiveProductAction
  receivedAmount: number
}

export interface ReceiveProductTableProps {
  data: ReceiveProductTableData[]
}

const grayscaleStyle = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
}

export const ReceiveProductTable: React.FC<ReceiveProductTableProps> = ({ data }) => {
  return (
    <Table<ReceiveProductTableData>
      color="primary.dark"
      size="small"
      columns={[
        {
          name: 'ลำดับ',
          align: 'right',
          render: (_, index) => index + 1,
          fixedWidth: 50,
        },
        {
          name: 'ชื่อสินค้า',
          render: (product) => (
            <div className="customer-requisition-table__product-cell" key={'product-' + product.id}>
              <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                <Photo />
              </Avatar>
              <div className="customer-requisition-table__product-cell__info">
                <Typography color="textSecondary">{product.code}</Typography>
                <Typography>{product.name}</Typography>
              </div>
            </div>
          ),
        },
        {
          name: 'ผลการรับ',
          fixedWidth: 150,
          dataIndex: 'receivedAction',
          render: ({ receivedAction }) => <ReceiveProductActionMessage receivedAction={receivedAction} />,
        },
        {
          name: 'จำนวนที่รับจริง/จำนวนที่ต้องรับ',
          fixedWidth: 150,
          align: 'right',
          format: '0,0',
          render: ({ receivedAction, toReceiveAmount, expectedReceiveAmount }) => {
            if (expectedReceiveAmount === 0)
              return (
                <Typography style={{ ...(expectedReceiveAmount === 0 ? grayscaleStyle : {}) }}>รับครบไปแล้ว</Typography>
              )
            if (toReceiveAmount === undefined)
              return <Typography>?/{formatNumberInt(expectedReceiveAmount)}</Typography>
            let textColor = 'textPrimary'
            if (toReceiveAmount === expectedReceiveAmount) textColor = !!receivedAction ? 'success.main' : 'default'
            if (toReceiveAmount < expectedReceiveAmount) textColor = 'error.main'
            if (toReceiveAmount > expectedReceiveAmount) textColor = 'warning.main'
            return (
              <Box color={textColor} clone>
                <Typography>
                  {`${!!receivedAction ? formatNumberInt(toReceiveAmount) : '?'}/${formatNumberInt(
                    expectedReceiveAmount,
                  )}`}
                </Typography>
              </Box>
            )
          },
        },
      ]}
      data={data}
      cleanSpace
    />
  )
}
