import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { PurchaseOrderPaymentStatus } from '../../enums'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
interface Props {
  status: PurchaseOrderPaymentStatus
  icon?: boolean
}

const useStyles = (status: PurchaseOrderPaymentStatus) =>
  makeStyles((theme) => {
    const chipColors = {
      [PurchaseOrderPaymentStatus.Pending]: theme.palette.warning.main,
      [PurchaseOrderPaymentStatus.Deposited]: theme.palette.warning.main,
      [PurchaseOrderPaymentStatus.Paid]: theme.palette.success.main,
    }

    const iconColors = {
      [PurchaseOrderPaymentStatus.Pending]: theme.palette.warning.darkText,
      [PurchaseOrderPaymentStatus.Deposited]: theme.palette.warning.darkText,
      [PurchaseOrderPaymentStatus.Paid]: theme.palette.success.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: chipColors[status],
      },
      icon: {
        color: iconColors[status],
      },
    })
  })

const PurchaseOrderPaymentStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({
  children,
  status,
  icon,
  ...othersProps
}) => {
  const classes = useStyles(status)()

  return (
    <Chip
      icon={icon ? <AttachMoneyIcon /> : undefined}
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      {...othersProps}
    />
  )
}

export default PurchaseOrderPaymentStatusChip
