import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const makePayment = errorSingleHandler<{ id: number }>(
  async (id: number, request: SOPaymentTransactionCreationRequest) => {
    const path = `/sale-orders/${id}/make-payment`
    return await smartAuthAPI.post(path, request)
  },
)

export interface SOPaymentTransactionCreationRequest {
  paymentMethodId: number
  amount: number
  bankId?: number
  bankAccountId?: number
  paymentAdjustmentTypeId?: number
  paymentAdjustmentAmount?: number
  withholdingAmount?: number
  withholdingTaxDocumentReceived?: boolean
  payslipBase64?: string
  referenceNumber?: string
  paymentTimestamp?: Date
  branchName?: string
  refDocumentIds?: number[]
}
