import _ from 'lodash'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useContext, useState } from 'react'
import { Loading } from '../../../shared-components'
import { FlexBox, GeneralTextField, withLayout } from '../../../custom-components'
import { useProductYearlyReport } from '../../../hooks/sales-report'
import { formatNumber } from '../../../functions/number-utils'
import { RootContext } from '../../..'
import { PageName } from '../../../enums'
import ProductYearlyReportDownloadCSV from './ProductYearlyReportDownloadCSV'
import { Pagination } from '@material-ui/lab'

const headers = [
  { label: 'รายงานการขายสินค้า', key: 'text', colspan: 1 },
  { label: 'หน่วย', key: 'unitLocal', colspan: 1 },
  { label: 'เดือน 1', key: 'month1', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent1', colspan: 1 },
  { label: 'เดือน 2', key: 'month2', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent2', colspan: 1 },
  { label: 'เดือน 3', key: 'month3', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent3', colspan: 1 },
  { label: 'เดือน 4', key: 'month4', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent4', colspan: 1 },
  { label: 'เดือน 5', key: 'month5', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent5', colspan: 1 },
  { label: 'เดือน 6', key: 'month6', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent6', colspan: 1 },
  { label: 'เดือน 7', key: 'month7', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent7', colspan: 1 },
  { label: 'เดือน 8', key: 'month8', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent8', colspan: 1 },
  { label: 'เดือน 9', key: 'month9', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent9', colspan: 1 },
  { label: 'เดือน 10', key: 'month10', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent10', colspan: 1 },
  { label: 'เดือน 11', key: 'month11', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent11', colspan: 1 },
  { label: 'เดือน 12', key: 'month12', colspan: 1 },
  { label: '%การเติบโต', key: 'growPercent12', colspan: 1 },
]

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 3000,
  },
  paginationRoot: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
    position: 'fixed',
    left: 'auto',
    bottom: 0,
    width: '100%',
    background: '#FFFFFF',
  },
}))

const ProductYearlyReportPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.MARKETING_PRODUCT_YEARLY_REPORT)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [currentPageData, setCurrentPageData] = useState<number>(1)
  const [searchKey, setSearchKey] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const styles = useStyles()

  const productReports = useProductYearlyReport(selectedDate, setLoading)
  if (!productReports || loading) return <Loading />

  const deboundSeachKey = _.debounce((code: string) => {
    setSearchKey(code)
    setCurrentPageData(1)
  }, 1000)

  const handleDateChange = async (date: Date | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  const startData = (currentPageData - 1) * 10
  const endData = currentPageData * 10
  const productData = productReports.filter((f) =>
    searchKey ? f.code.includes(searchKey) || f.name.includes(searchKey) : true,
  )

  return (
    <>
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox alignItems="center">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="yyyy"
              views={['year']}
              margin="normal"
              id="date-picker-inline"
              label="เลือกช่วงเวลา"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <span className="ml-4" />
          <GeneralTextField
            type="text"
            name="key"
            onChange={(e) => {
              deboundSeachKey(e.target.value)
            }}
            label="รหัสสินค้า หรือ ชื่อสินค้า"
          />
        </FlexBox>
        {productReports.length > 0 && (
          <span>
            <ProductYearlyReportDownloadCSV productReports={productReports} headers={headers} />
          </span>
        )}
      </FlexBox>
      <Grid container spacing={2} className="mt-4">
        {productData.length > 0 &&
          productData.slice(startData, endData).map((product) => {
            return (
              <Paper className="mt-4">
                <Box className="mt-4 mb-6">
                  <Typography variant="h5">
                    ({product.code}) {product.name}
                  </Typography>
                </Box>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="spanning table" className={styles.table}>
                      <TableHead>
                        <TableRow>
                          {headers.map((row) => (
                            <TableCell align="center" style={{ width: 220 }} colSpan={row.colspan}>
                              {row.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">ปริมาณการขายสินค้า</TableCell>
                          <TableCell align="center">{product.unitLocal ?? '-'}</TableCell>
                          {product?.salesMontlyReport.map((report) => {
                            return (
                              <>
                                <TableCell align="center">{report.salesAmount.value}</TableCell>
                                <TableCell align="center">
                                  <Box
                                    color={report.salesAmount.growthPercent > 0 ? 'success.main' : 'primary.main'}
                                    clone
                                  >
                                    <Typography>{formatNumber(report.salesAmount.growthPercent)}%</Typography>
                                  </Box>
                                </TableCell>
                              </>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">ราคาขาย</TableCell>
                          <TableCell align="center">บาท</TableCell>
                          {product?.salesMontlyReport.map((report) => {
                            return (
                              <>
                                <TableCell align="center">{formatNumber(report.avgSalesPrice.value)}</TableCell>
                                <TableCell align="center">
                                  <Box
                                    color={report.avgSalesPrice.growthPercent > 0 ? 'success.main' : 'primary.main'}
                                    clone
                                  >
                                    <Typography>{formatNumber(report.avgSalesPrice.growthPercent)}%</Typography>
                                  </Box>
                                </TableCell>
                              </>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">ยอดขาย</TableCell>
                          <TableCell align="center">บาท</TableCell>
                          {product?.salesMontlyReport.map((report) => {
                            return (
                              <>
                                <TableCell align="center">{formatNumber(report.salesTotal.value)}</TableCell>
                                <TableCell align="center">
                                  <Box
                                    color={report.salesTotal.growthPercent > 0 ? 'success.main' : 'primary.main'}
                                    clone
                                  >
                                    <Typography>{formatNumber(report.salesTotal.growthPercent)}%</Typography>
                                  </Box>
                                </TableCell>
                              </>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">กำไรขั้นต้น</TableCell>
                          <TableCell align="center">บาท</TableCell>
                          {product?.salesMontlyReport.map((report) => {
                            return (
                              <>
                                <TableCell align="center">{formatNumber(report.grossMargin.value)}</TableCell>
                                <TableCell align="center">
                                  <Box
                                    color={report.grossMargin.growthPercent > 0 ? 'success.main' : 'primary.main'}
                                    clone
                                  >
                                    <Typography>{formatNumber(report.grossMargin.growthPercent)}%</Typography>
                                  </Box>
                                </TableCell>
                              </>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">Margin</TableCell>
                          <TableCell align="center">%</TableCell>
                          {product?.salesMontlyReport.map((report) => {
                            return (
                              <>
                                <TableCell align="center">{formatNumber(report.grossMarginPercent.value)}%</TableCell>
                                <TableCell align="center">
                                  <Box
                                    color={
                                      report.grossMarginPercent.growthPercent > 0 ? 'success.main' : 'primary.main'
                                    }
                                    clone
                                  >
                                    <Typography>{formatNumber(report.grossMarginPercent.growthPercent)}%</Typography>
                                  </Box>
                                </TableCell>
                              </>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">จำนวนสต็อกคงเหลือ</TableCell>
                          <TableCell align="center">{product.unitLocal ?? '-'}</TableCell>
                          {product?.salesMontlyReport.map((report) => {
                            return (
                              <>
                                <TableCell align="center">{report.snapshotStockAmount.value}</TableCell>
                                <TableCell align="center">
                                  <Box
                                    color={
                                      report.snapshotStockAmount.growthPercent > 0 ? 'success.main' : 'primary.main'
                                    }
                                    clone
                                  >
                                    <Typography>{formatNumber(report.snapshotStockAmount.growthPercent)}%</Typography>
                                  </Box>
                                </TableCell>
                              </>
                            )
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">อัตราการซื้อซ้ำ</TableCell>
                          <TableCell align="center">%</TableCell>
                          {product?.salesMontlyReport.map((report) => {
                            return (
                              <>
                                <TableCell align="center">{formatNumber(report.repeatPurchaseRate.value)}</TableCell>
                                <TableCell align="center">
                                  <Box
                                    color={
                                      report.repeatPurchaseRate.growthPercent > 0 ? 'success.main' : 'primary.main'
                                    }
                                    clone
                                  >
                                    <Typography>{formatNumber(report.repeatPurchaseRate.growthPercent)}%</Typography>
                                  </Box>
                                </TableCell>
                              </>
                            )
                          })}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Paper>
            )
          })}
      </Grid>
      {productData.length > 0 && (
        <div className={styles.paginationRoot}>
          <Pagination
            count={Math.ceil(productData.length / Number(10))}
            variant="outlined"
            shape="rounded"
            page={currentPageData}
            onChange={(event, value) => {
              setCurrentPageData(value)
            }}
          />
        </div>
      )}
    </>
  )
}

export default withLayout(ProductYearlyReportPage, true)
