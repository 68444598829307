import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { FilteredTable, FlexBox, GeneralTextField, Link, Topic, withLayout } from '../../../custom-components'
import { useWithdrawalTicketTableData } from '../../../hooks'
import { FollowUpToReturnWithdrawnProductSignal, Loading } from '../../../shared-components'
import { Add } from '@material-ui/icons'
import { IWithdrawalTicketTableData_Data } from '../../../api/smartbestbuys-api/warehouse'
import { PageName, WithdrawalTicketStatus } from '../../../enums'
import { useContext, useState } from 'react'
import { dateFormat } from '../../../functions'
import { RootContext } from '../../..'
import { useHistory } from 'react-router-dom'

const PWTicketTableDataPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  const history = useHistory()
  setCurrentPage(PageName.PRODUCT_WITHDRAWAL_TABLE_DATA)

  // state
  const [tab, setTab] = useState(WithdrawalTicketStatus.Pending)

  const tableData = useWithdrawalTicketTableData({
    pageIndex: 0,
    rowPerPage: 10,
    search: {
      statusIds: [tab],
      code: undefined,
      requestedDate: undefined,
      estimatedReturnedDate: undefined,
      createdBy: undefined,
    },
  })

  if (!tableData) return <Loading />

  const {
    data,
    paginationInfo,
    summary,
    isLoading,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    handleTabChange,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FlexBox justifyContent="space-between" mb={2}>
          <Topic>รายการคำขอเบิกสินค้า</Topic>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={() => history.replace('/warehouse/product-withdrawal-tickets/create')}
          >
            สร้างคำขอเบิกสินค้า
          </Button>
        </FlexBox>
      </Grid>

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <GeneralTextField
                type="text"
                name="code"
                label="เลขที่คำขอเบิกสินค้า"
                onChange={(e) => handleSearchParamChange('code', e.target.value || undefined)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <GeneralTextField
                type="date"
                name="requestedDate"
                label="วันที่ขอเบิกสินค้า"
                onChange={(e) => handleSearchParamChange('requestedDate', e.target.value || undefined)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <GeneralTextField
                type="date"
                name="estimatedReturnedDate"
                label="วันที่คืนสินค้า"
                onChange={(e) => handleSearchParamChange('estimatedReturnedDate', e.target.value || undefined)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <GeneralTextField
                type="text"
                name="createdBy"
                label="ผู้ขอเบิกสินค้า"
                onChange={(e) => handleSearchParamChange('createdBy', e.target.value || undefined)}
              />
            </Grid>
            <Grid item xs={12} sm={9} />
            <Grid item xs={12} sm={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={8} /> : undefined}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <FilteredTable<IWithdrawalTicketTableData_Data>
        color="primary.dark"
        columns={[
          {
            name: '',
            fixedWidth: 30,
            render: ({ estimatedReturnedDate }) =>
              estimatedReturnedDate && estimatedReturnedDate < new Date() ? (
                <FollowUpToReturnWithdrawnProductSignal />
              ) : undefined,
          },
          {
            name: 'เลขที่คำขอเบิกสินค้า',
            dataIndex: 'code',
            render: ({ id, code }) => <Link href={`/warehouse/product-withdrawal-tickets/${id}/profile`}>{code}</Link>,
          },
          {
            name: 'วันที่ขอเบิกสินค้า',
            dataIndex: 'requestedDate',
            render: ({ requestedDate }) => <Typography variant="body2">{dateFormat(requestedDate)}</Typography>,
          },
          {
            name: 'วันที่คาดว่าจะคืนสินค้า',
            dataIndex: 'estimatedReturnedDate',
            render: ({ estimatedReturnedDate }) => (
              <Typography variant="body2">{estimatedReturnedDate ? dateFormat(estimatedReturnedDate) : '-'}</Typography>
            ),
          },
          {
            name: 'ผู้ขอเบิกสินค้า',
            dataIndex: 'recCreatedBy',
          },
          {
            name: 'วันที่สร้าง',
            dataIndex: 'recCreatedAt',
            render: ({ recCreatedAt }) => <Typography variant="body2">{dateFormat(recCreatedAt)}</Typography>,
          },
        ]}
        data={data}
        disableSearch
        disableDateRangeFilter
        pagination
        paginationInfo={paginationInfo}
        onPageChange={handlePageChange}
        onRowPerPageChange={handleRowPerPageChange}
        tabOption={{
          tabs: [
            {
              label: `รอตรวจสอบ (${summary?.amountOfStatus[WithdrawalTicketStatus.Pending] ?? 0})`,
              value: WithdrawalTicketStatus.Pending,
            },
            {
              label: `รอส่งสินค้า (${summary?.amountOfStatus[WithdrawalTicketStatus.Approved] ?? 0})`,
              value: WithdrawalTicketStatus.Approved,
            },
            {
              label: `รอรับสินค้าคืน (${summary?.amountOfStatus[WithdrawalTicketStatus.InProgress] ?? 0})`,
              value: WithdrawalTicketStatus.InProgress,
            },
            {
              label: `จบงาน (${summary?.amountOfStatus[WithdrawalTicketStatus.Done] ?? 0})`,
              value: WithdrawalTicketStatus.Done,
            },
            {
              label: `ยกเลิก (${summary?.amountOfStatus[WithdrawalTicketStatus.Cancelled] ?? 0})`,
              value: WithdrawalTicketStatus.Cancelled,
            },
            {
              label: `ไม่อนุมัติ (${summary?.amountOfStatus[WithdrawalTicketStatus.Rejected] ?? 0})`,
              value: WithdrawalTicketStatus.Rejected,
            },
          ],
          value: tab,
          onChange: (_, value) => {
            setTab(value)
            handleTabChange(Number(value))
          },
        }}
        isLoading={isLoading}
      />
    </Grid>
  )
}

export default withLayout(PWTicketTableDataPage)
