import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../../..'
import { Form, GeneralTextFieldForm } from '../../../../custom-components'
import { useForm } from 'react-hook-form'
import SaleOrderPartialUpdateDialogFromValues from './SaleOrderPartialUpdateDialogFromValues'
import { partialUpdateSaleOrder } from '../../../../api/smartbestbuys-api/sale/sale-apis'
import { mapPartialUpdateFromValuesToSaleOrderPartialUpdateRequest } from './mapper'
import { SaleOrderProfile } from '../../../../api/smartbestbuys-api'
import { useUserData } from '../../../../hooks'

interface Props extends DialogProps {
  saleOrderProfile: SaleOrderProfile
  onComplete?: () => void
}

const SaleOrderUpdateAssigneeDialog: React.FC<Props> = (props: Props) => {
  const { saleOrderProfile, onComplete, onClose, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)

  const users = useUserData()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<SaleOrderPartialUpdateDialogFromValues>({
    defaultValues: {
      customerPurchaseReference: saleOrderProfile.customerPurchaseReference,
      dontHasCustomerPurchaseReference: !saleOrderProfile.customerPurchaseReference,
      customerPickUpMethod: saleOrderProfile.customerPickUpMethod?.id,
      paymentConditionId: saleOrderProfile.paymentCondition.id,
      dueDate: saleOrderProfile.dueDate,
      assigneeUserId: saleOrderProfile.assigneeUser?.id,
    },
  })

  const handleSubmitSaleOrderPartialUpdateForm = async (value: SaleOrderPartialUpdateDialogFromValues) => {
    const request = mapPartialUpdateFromValuesToSaleOrderPartialUpdateRequest(value)

    const response = await partialUpdateSaleOrder(saleOrderProfile.id, request)
    if (response?.status === 200) {
      triggerSnackbar('บันทึกการแก้ไขใบสั่งขายสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('บันทึกการแก้ไขใบสั่งขายไม่สำเร็จ')
      if (!!onClose) onClose({}, 'escapeKeyDown')
    }
  }
  if (!saleOrderProfile) return <></>
  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitSaleOrderPartialUpdateForm)}>
        <DialogTitle>แก้ไขผู้รับผิดชอบใบสั่งขาย</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="assigneeUserId"
                defaultValue=""
                label="ผู้รับผิดชอบงาน"
                select
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.firstName + ' ' + user.lastName}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            บันทึกใบสั่งขาย
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default SaleOrderUpdateAssigneeDialog
