import React, { useState, useEffect } from 'react'
import { Card, Grid, Switch, Collapse } from '@material-ui/core'
import { FlexBox, Title } from '../../custom-components'

interface Props {
  title: string
  onToggle: (enabled: boolean) => void
  enabled?: boolean
}

const SwitchBox: React.FC<Props> = (props) => {
  const { title, children, onToggle, enabled: defaultEnabled } = props
  const [enabled, setEnabled] = useState(defaultEnabled)

  useEffect(() => {
    setEnabled(defaultEnabled)
  }, [defaultEnabled])

  const handleToggle = () =>
    setEnabled((prev) => {
      const updated = !prev
      onToggle(updated)
      return updated
    })

  return (
    <Card variant="outlined">
      <FlexBox justifyContent="space-between" mb={1}>
        <Title>{title}</Title>
        <Switch checked={enabled} onClick={handleToggle} />
      </FlexBox>
      <Collapse in={enabled}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  )
}

export default SwitchBox

SwitchBox.defaultProps = {
  enabled: false,
}
