import { useEffect, useState } from 'react'
import {
  ITaxInvoiceTableData,
  ITaxInvoiceTableData_Data,
  GetTaxInvoiceTableDataQueryParams,
  getTaxInvoiceTableDataAPI,
} from '../api/smartbestbuys-api'
import { IUseTableData, IUseTableDataParams } from '../types/pagination'

export interface ITaxInvoiceTableData_SearchParams {
  statusIds: number[]
  documentCode?: string
  customerName?: string
  saleOrderCode?: string
  hasReceipt?: boolean
}

const buildQueryParams = (
  pageIndex: number,
  rowPerPage: number,
  search: ITaxInvoiceTableData_SearchParams,
  updateCache?: boolean,
): GetTaxInvoiceTableDataQueryParams => {
  const _hasReceipt = search.hasReceipt?.toString()

  return {
    pageIndex: pageIndex.toString(),
    rowPerPage: rowPerPage.toString(),
    updateCache: updateCache?.toString(),
    statusIds: search.statusIds.length > 0 ? search.statusIds.join(',') : undefined,
    documentCode: search.documentCode,
    customerName: search.customerName,
    saleOrderCode: search.saleOrderCode,
    hasReceipt: _hasReceipt === 'false' ? 'false' : undefined,
  }
}

export const useTaxInvoiceTableData = (
  params: IUseTableDataParams<ITaxInvoiceTableData_SearchParams>,
): IUseTableData<ITaxInvoiceTableData_Data, ITaxInvoiceTableData_SearchParams> | undefined => {
  // state
  const [pageIndex, setPageIndex] = useState(params.pageIndex)
  const [rowPerPage, setRowPerPage] = useState(params.rowPerPage)

  const [pendingSearch, setPendingSearch] = useState<ITaxInvoiceTableData_SearchParams>(params.search)
  const [search, setSearch] = useState<ITaxInvoiceTableData_SearchParams>(params.search)
  const [tableData, setTableData] = useState<ITaxInvoiceTableData | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(true)

  const _loadData = async (
    pageIndex: number,
    rowPerPage: number,
    search: ITaxInvoiceTableData_SearchParams,
    updateCache?: boolean,
  ) => {
    const response = await getTaxInvoiceTableDataAPI(buildQueryParams(pageIndex, rowPerPage, search, updateCache))
    if (response?.data) setTableData(response.data || undefined)

    setIsLoading(false)
  }

  // first load
  useEffect(() => {
    _loadData(params.pageIndex, params.rowPerPage, search)
    // eslint-disable-next-line
  }, [])

  // functions
  const handlePageChange = async (_pageIndex: number) => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(_pageIndex, rowPerPage, search)
    setPageIndex(_pageIndex)
  }

  const handleRowPerPageChange = async (_rowPerPage: number) => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(0, _rowPerPage, search)
    setRowPerPage(_rowPerPage)
  }

  const handleSearchParamChange = <T extends keyof ITaxInvoiceTableData_SearchParams>(
    key: T,
    value: ITaxInvoiceTableData_SearchParams[T],
  ) => {
    setPendingSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const handleSearch = async () => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(0, rowPerPage, pendingSearch)
    setSearch(pendingSearch)
  }

  const refetch = async () => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(pageIndex, rowPerPage, search, true)
  }

  return tableData
    ? {
        ...tableData,
        search,
        pendingSearch,
        handlePageChange,
        handleRowPerPageChange,
        handleSearchParamChange,
        handleSearchParamsChange: (s) => {},
        handleSearch,
        refetch,
        isLoading,
      }
    : undefined
}
