import { Box, Grid, Paper } from '@material-ui/core'
import { DateTime } from 'luxon'
import React from 'react'
import { SubTopic, TypographyWithLabel } from '../../../custom-components'
import { CurrencyID, getContactChannelNameById, getCurrencyNameById, getPaymentMethodNameById } from '../../../types'
import { QuotationsTaxInvoiceInformationRemark } from '.'
import { UserData } from '../../../api/smartbestbuys-api/data/data-types'
import { getUserFullNameById } from '../../../types/User'
import { BaseData } from '../../../api/smartbestbuys-api'

interface QuotationsTaxInvoiceInformationProps {
  contactChannelId: number | undefined
  paymentMethodId: number
  currencyId: number
  exchangeRate: number
  expiredDate: Date | string
  remark: string | undefined
  assigneeUserId?: number
  assigneeUser?: BaseData
  users?: UserData[]
  dueDate?: Date
}

const QuotationsTaxInvoiceInformation: React.FC<QuotationsTaxInvoiceInformationProps> = (props) => {
  const {
    contactChannelId,
    paymentMethodId,
    currencyId,
    exchangeRate,
    expiredDate,
    remark,
    assigneeUser,
    assigneeUserId,
    users,
    dueDate,
  } = props

  const expiredDateString =
    expiredDate instanceof Date
      ? DateTime.fromJSDate(expiredDate).toFormat('dd LLL yyyy', { locale: 'th' })
      : expiredDate

  const dueDateString =
    dueDate instanceof Date ? DateTime.fromJSDate(dueDate).toFormat('dd LLL yyyy', { locale: 'th' }) : dueDate

  const infoItems = [
    {
      label: 'ช่องทางการติดต่อ:',
      value: contactChannelId ? getContactChannelNameById(contactChannelId) : 'ไม่ระบุ',
      shown: true,
    },
    {
      label: 'วิธีการชำระเงิน:',
      value: paymentMethodId ? getPaymentMethodNameById(paymentMethodId) : 'ไม่ระบุ',
      shown: true,
    },
    { label: 'สกุลเงิน:', value: currencyId ? getCurrencyNameById(currencyId) : 'ไม่ระบุ', shown: true },
    { label: 'อัตราแลกเปลี่ยน:', value: `${exchangeRate} บาท`, shown: currencyId !== CurrencyID.Baht },
    {
      label: 'วันหมดอายุยืนราคา:',
      value: expiredDateString,
      shown: true,
    },
    {
      label: 'วันที่ลูกค้าต้องการ:',
      value: dueDateString ?? '-',
      shown: true,
    },
    {
      label: 'ผู้เสนอราคา:',
      value: assigneeUser?.id
        ? assigneeUser.name
        : assigneeUserId
        ? getUserFullNameById(assigneeUserId, users as UserData[])
        : 'ไม่ระบุ',
      shown: true,
    },
    { label: 'หมายเหตุ:', value: remark || '-', shown: true },
  ]

  return (
    <Box display="flex" flexDirection="column">
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>ข้อมูลใบเสนอราคา</SubTopic>
          </Grid>
          {infoItems.map(
            (item, index) =>
              item.shown && (
                <Grid item xs={12} md={6} key={index}>
                  <TypographyWithLabel label={item.label}>{item.value}</TypographyWithLabel>
                </Grid>
              ),
          )}
          <Grid item xs={12}>
            <QuotationsTaxInvoiceInformationRemark />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default QuotationsTaxInvoiceInformation
