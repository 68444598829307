import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import numeral from 'numeral'
import React from 'react'
import { useForm } from 'react-hook-form'
import BillingDocumentCreationFormValue from '../../customers/id/profile/CustomerProfilePage/CustomerDocumentBox/BillingDocumentCreationDialog/BillingDocumentCreationDialogValue'
import { createBillingDocument } from '../../customers/id/profile/CustomerProfilePage/service'
import { Form, GeneralTextFieldForm, Table } from '../../../custom-components'
import {
  TaxInvoiceState,
  Document,
  ICustomerProfile_Document,
  ICustomerDocumentState,
  ICustomerProfile,
} from '../../../api/smartbestbuys-api'
import { dateTimeFormat } from '../../../functions'
import { ConditionTooltip, CustomerProfileCard } from '../../../shared-components'
import { DocumentType } from '../../../enums'

interface Props extends DialogProps {
  taxInvoice: Document<any, TaxInvoiceState>
  customerProfile: ICustomerProfile
  onSuccess?: () => void
  onFail?: () => void
}

const TaxInvoiceCreateReceiptDialog: React.FC<Props> = (props) => {
  const { taxInvoice, customerProfile, onSuccess, onFail, onClose, ...otherProps } = props

  const taxInvoiceDocuments = [
    {
      id: taxInvoice.id,
      code: taxInvoice.code,
      recCreatedAt: taxInvoice.recCreatedAt,
      recCreatedBy: taxInvoice.recCreatedBy,
      saleOrderId: taxInvoice.saleOrderInfo.id,
      saleOrderCode: taxInvoice.saleOrderInfo.code,
      type: taxInvoice.type,
      status: taxInvoice.status,
      referenceDocument: taxInvoice?.referenceDocument?.id
        ? {
            id: taxInvoice.referenceDocument.id,
            code: taxInvoice.referenceDocument.code,
            total: taxInvoice.referenceDocument.total,
          }
        : undefined,
      total: taxInvoice.total,
      state: taxInvoice.state,
    },
  ] as ICustomerProfile_Document<ICustomerDocumentState>[]

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<BillingDocumentCreationFormValue>({
    defaultValues: {
      billingAddressId: taxInvoice.billingAddress?.id,
      documents: taxInvoiceDocuments,
    },
  })

  const handleSubmitBillingNoteCreationForm = async (value: BillingDocumentCreationFormValue) => {
    const response = await createBillingDocument(taxInvoice.customerInfo.id, value, DocumentType.Receipts)
    if (response?.status === 201) {
      onSuccess && onSuccess()
      onClose && onClose({}, 'escapeKeyDown')
      reset()
    } else {
      onFail && onFail()
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitBillingNoteCreationForm)}>
        <DialogTitle>สร้างใบเสร็จรับเงิน</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomerProfileCard
                customerProfile={customerProfile}
                billingAddressId={taxInvoice.billingAddress?.id}
                billingAddressAlert={
                  !taxInvoice.billingAddress?.id
                    ? {
                        overrideData: {
                          text: 'ยังไม่มีที่อยู่เปิดบิล',
                          buttonText: `ไปเลือกที่ SO ${taxInvoice.saleOrderInfo.code}`,
                        },
                        saleOrderId: taxInvoice.saleOrderInfo.id,
                        onClickAlert: () => window.open(`sale-orders/${taxInvoice.saleOrderInfo.id}`),
                      }
                    : undefined
                }
                disableBtnCustomerDetail
              />
            </Grid>
            <Grid item xs={6}>
              <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="body2">
                รายละเอียดใบเสร็จรับเงิน'
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table<ICustomerProfile_Document<ICustomerDocumentState>>
                size="small"
                color="primary.dark"
                columns={[
                  {
                    name: 'ลำดับ',
                    render: (_, index) => (
                      <Typography color="textSecondary" variant="body2">
                        {index + 1}
                      </Typography>
                    ),
                  },
                  {
                    name: 'เลขใบกำกับภาษีอ้างอิง',
                    render: ({ code }) => (
                      <Typography color="textSecondary" variant="body2">
                        {code}
                      </Typography>
                    ),
                  },
                  {
                    name: 'วันที่เอกสาร',
                    render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                  },
                  {
                    name: 'วันครบกำหนด',
                    render: ({ state }) =>
                      state && 'expiredDate' in state && state.expiredDate
                        ? dateTimeFormat(state.expiredDate)
                        : undefined,
                  },
                  {
                    name: 'จำนวนเงิน',
                    numeric: true,
                    format: '0,0.00',
                    dataIndex: 'total',
                  },
                ]}
                postCells={
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="right">ยอดรวมสุทธิ</TableCell>
                    <TableCell align="right">{numeral(taxInvoice.total).format('0,0.00')}</TableCell>
                  </TableRow>
                }
                data={taxInvoiceDocuments || []}
                cleanSpace
                pagination={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <ConditionTooltip
            showTooltip={!taxInvoice.billingAddress?.id}
            title="ไม่สามารถสร้างใบเสร็จรับเงินได้ เนื่องจากไม่มีที่อยู่เปิดบิล"
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting || !taxInvoice.billingAddress?.id}
            >
              ยืนยันข้อมูล
            </Button>
          </ConditionTooltip>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default TaxInvoiceCreateReceiptDialog
