import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { updateUserActivationByIdAPI, UpdateUserStatusRequestBody, UserProfile } from '../../../api/smartbestbuys-api'
import { UserActivationUpdateForm, UserActivationUpdateFormValues } from '.'

interface Props extends DialogProps {
  userProfile: UserProfile
  onComplete?: (data: UserProfile) => void
}

export const UserActivationUpdateDialog = (props: Props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { userProfile, onComplete, onClose, ...dialogProps } = props

  const handleSubmit = async (values: UserActivationUpdateFormValues) => {
    const request: UpdateUserStatusRequestBody = {
      status: values.enabled,
    }

    try {
      const response = await updateUserActivationByIdAPI(userProfile.id, request)
      if (response && response.status === 200) {
        triggerSnackbar('แก้ไขสถานะการใช้งานสำเร็จ')
        if (onComplete) onComplete(response.data)
      } else {
        triggerSnackbar('แก้ไขสถานะการใช้งานไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('แก้ไขสถานะการใช้งานไม่สำเร็จ')
    }
  }

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="lg">
      {userProfile.enabled ? (
        <DialogTitle>ปิดใช้งานผู้ใช้นี้</DialogTitle>
      ) : (
        <DialogTitle>เปิดใช้งานผู้ใช้นี้</DialogTitle>
      )}
      <DialogContent>
        <UserActivationUpdateForm
          enabled={userProfile.enabled}
          onSubmit={handleSubmit}
          onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
        />
      </DialogContent>
    </Dialog>
  )
}
