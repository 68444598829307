import { useEffect, useState } from 'react'
import { CustomerShippingAddress, fetchCustomerShippingAddressesAPI } from '../../api/smartbestbuys-api'

export const useCustomerShippingAddresses = (customerId: number): CustomerShippingAddress[] | undefined => {
  const [data, setData] = useState<CustomerShippingAddress[] | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await fetchCustomerShippingAddressesAPI(customerId)
      if (response?.data) {
        setData(response.data.map((d) => new CustomerShippingAddress(d)))
      }
    })()
  }, [customerId])

  return data
}
