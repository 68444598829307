import { Accordion, AccordionDetails, AccordionSummary, Card, Paper, Typography, Grid } from '@material-ui/core'
import { ExpandMore, Photo } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { Avatar, FlexBox, Table, TypographyWithLabel } from '../../../../../custom-components'
import { CustomerRequisitionType } from '../../../../../enums'
import { AttachmentCard, CRStatusChip, Loading } from '../../../../../shared-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { useStyles } from './styles'

interface CRProductTableData {
  id: number
  code: string
  nameLocal: string
  imageUrl?: string
  salesAmount: number
  crAmount?: number
  crShippingAmount?: number
  crShippedAmount?: number
  crReceivedAmount?: number
}

const SOCustomerRequisitions: React.FC = (props) => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  const classes = useStyles()
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined)

  if (!saleOrderProfile) return <Loading />

  const { customerRequisitions } = saleOrderProfile
  const hasCRs = customerRequisitions?.length > 0

  return (
    <Paper>
      <FlexBox flexDirection="column">
        {hasCRs ? (
          customerRequisitions.map((cr, index) => {
            const { code, content, status, type, products, attachments } = cr

            const isExpanded = activeIndex === index
            const handleExpand = () =>
              setActiveIndex(() => {
                if (isExpanded) return undefined
                return index
              })

            const typeName = type.id === CustomerRequisitionType.Claim ? 'เคลม' : 'คืน'

            return (
              <Grid item xs={12} style={{ paddingBottom: 20 }}>
                <Accordion
                  square
                  key={`task-${index}`}
                  expanded={isExpanded}
                  onChange={handleExpand}
                  TransitionProps={{ unmountOnExit: true }}
                  className={classes.accordion}
                >
                  <AccordionSummary expandIcon={<ExpandMore />} classes={{ content: classes.accordionSummary }}>
                    <Typography variant="h5">คำร้องของลูกค้า {code}</Typography>
                    <CRStatusChip status={status.id} label={status.name} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TypographyWithLabel variant="body2" label="ประเภทคำร้อง:" alignItems="center">
                          {type.name}
                        </TypographyWithLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TypographyWithLabel variant="body2" label="รายละเอียด:" alignItems="center">
                          {content}
                        </TypographyWithLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <FlexBox flexDirection="column">
                          <Typography>สินค้าสำหรับการ{typeName}</Typography>
                          <span className="mb-1" />
                          <Table<CRProductTableData>
                            color="primary.dark"
                            size="small"
                            columns={[
                              {
                                name: 'ลำดับ',
                                render: (_, index) => index + 1,
                              },
                              {
                                name: 'ชื่อสินค้า',
                                render: (product) => (
                                  <div
                                    className="sale-order-products-table__product-cell"
                                    key={'product-' + product.id}
                                  >
                                    <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                                      <Photo />
                                    </Avatar>
                                    <div className="sale-order-products-table__product-cell__info">
                                      <Typography color="textSecondary">{product.code}</Typography>
                                      <Typography>{product.nameLocal}</Typography>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: `จำนวนสินค้าที่ต้องการ${typeName}`,
                                numeric: true,
                                render: (product) => (
                                  <Typography variant="body2" color="error">
                                    {product.crAmount}
                                  </Typography>
                                ),
                              },
                              {
                                name: 'จำนวนสินค้ารับมาแล้ว',
                                numeric: true,
                                format: '0,0',
                                dataIndex: 'crReceivedAmount',
                              },
                              {
                                name: 'จำนวนที่ส่งคืนแล้ว',
                                numeric: true,
                                format: '0,0',
                                dataIndex: 'crShippedAmount',
                                hide: type.id !== CustomerRequisitionType.Claim,
                              },
                            ]}
                            data={saleOrderProfile.products
                              .map((product) => {
                                const crProduct = products.find((p) => p.productId === product.productId)

                                return {
                                  id: product.productId,
                                  code: product.code,
                                  nameLocal: product.nameLocal,
                                  imageUrl: product.imageUrl,
                                  salesAmount: product.amount,
                                  crAmount: crProduct?.amount,
                                  crShippingAmount: crProduct?.shippingAmount,
                                  crShippedAmount: crProduct?.shippedAmount,
                                  crReceivedAmount: crProduct?.receivedAmount,
                                }
                              })
                              .filter((product) => !!product.crAmount)}
                            pagination={false}
                            cleanSpace
                          />
                        </FlexBox>
                      </Grid>
                      {attachments.length > 0 && (
                        <Grid item xs={12}>
                          <Typography>ภาพหลักฐาน</Typography>
                          <span className="mb-2" />
                          <FlexBox gridGap={16} flexWrap="wrap">
                            {attachments.map((att, index) => (
                              <AttachmentCard name={`เอกสารแนบ ${index + 1}`} src={att.url} />
                            ))}
                          </FlexBox>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          })
        ) : (
          <FlexBox justifyContent="center" alignItems="center" width="100%" height={100} clone>
            <Card variant="outlined">
              <Typography variant="body2" color="textSecondary">
                ยังไม่มีข้อมูลคำร้องของลูกค้า
              </Typography>
            </Card>
          </FlexBox>
        )}
      </FlexBox>
    </Paper>
  )
}

export default SOCustomerRequisitions
