import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { FetchRequest } from '..'
import { Receipt } from './types'

const path = `/documents/receipts`

export const fetchDocumentReceipts = errorHandler<Receipt[]>(async (request: FetchRequest) => {
  return await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
})
