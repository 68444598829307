import numeral from 'numeral'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'

interface Props<T> {
  data: T[]
  xDataKey: keyof T
  yDataKey: keyof T
}

export const SigleLineChart = <T extends {}>({ data, xDataKey, yDataKey }: Props<T>) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          left: 16,
          right: 16,
          bottom: 48,
        }}
      >
        <XAxis dataKey={xDataKey as string} />
        <YAxis dataKey={yDataKey as string} />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <div className=" bg-gray-100 p-4 rounded-lg shadow-md">
                  Seasonal index of {label} is {numeral(payload[0].value).format('0.00')}
                </div>
              )
            }
            return null
          }}
        />
        <Line type="monotone" dataKey={yDataKey as string} stroke="#c62828" />
      </LineChart>
    </ResponsiveContainer>
  )
}
