import { useState, useEffect } from 'react'
import { ICustomerTableData_Data, fetchCustomerAPI } from '../api/smartbestbuys-api'

export const useCustomers = (): ICustomerTableData_Data[] | undefined => {
  const [data, setData] = useState<ICustomerTableData_Data[] | undefined>(undefined)

  const _fetchCustomers = async () => {
    const response = await fetchCustomerAPI()
    setData(response?.data ?? [])
  }

  useEffect(() => {
    _fetchCustomers()
  }, [])

  return data
}
