import { DateTime } from 'luxon'
import {
  CreatedInfo,
  FetchRequest,
  GetProductTableDataRequestQueryParams,
  PaymentRequisitionProfile,
  UserActivityNote,
} from '..'
import { dateSqlFormat } from '../../../functions'
import { errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import {
  PaymentRequisition_TableData,
  PaymentRequisitionUploadPayslipRequestBody,
  PaymentRequisitionUploadReceiptRequestBody,
  PaymentRequisitionConfirmStatementRequestBody,
  PaymentRequisitionCreateData,
  PaymentRequisitionCreateRequestBody,
  PaymentRequisitionPDFInfo,
  PaymentRequisitionUpdateExchangeRateRequestBody,
  IPaymentRequisitionPayoutDaily,
  IPaymentRequisitionTableData,
} from './types'

export const fetchPaymentRequisitions = errorHandler<PaymentRequisition_TableData[]>(async (request: FetchRequest) => {
  return smartAuthAPI.get('/purchase/v1/payment-requisitions/fetch', {
    params: {
      startDate: dateSqlFormat(request.startDate),
      endDate: dateSqlFormat(request.endDate),
    },
  })
})

export const getPaymentRequisitionProfile = errorSingleHandler<PaymentRequisitionProfile>(
  async (paymentRequisitionId: number) => {
    return smartAuthAPI.get(`/purchase/v1/payment-requisitions/${paymentRequisitionId}/profile`)
  },
)

export const uploadPaymentRequisitionPayslip = errorSingleHandler<PaymentRequisitionProfile>(
  async (paymentRequisitionId: number, requestBody: PaymentRequisitionUploadPayslipRequestBody) => {
    return smartAuthAPI.post(`/purchase/v1/payment-requisitions/${paymentRequisitionId}/upload-payslip`, requestBody)
  },
)

export const approvePaymentRequisition = errorHandler<PaymentRequisitionProfile>(async (id: number) => {
  return smartAuthAPI.post(`/purchase/v1/payment-requisitions/${id}/approve`)
})

export const rejectPaymentRequisition = errorSingleHandler<PaymentRequisitionProfile>(
  async (id: number, requestBody: UserActivityNote) => {
    return smartAuthAPI.post(`/purchase/v1/payment-requisitions/${id}/reject`, requestBody)
  },
)

export const uploadPaymentRequisitionStatement = errorSingleHandler<PaymentRequisitionProfile>(
  async (paymentRequisitionId: number, requestBody: PaymentRequisitionConfirmStatementRequestBody) => {
    return smartAuthAPI.post(`/purchase/v1/payment-requisitions/${paymentRequisitionId}/confirm-statement`, requestBody)
  },
)

export const uploadPaymentRequisitionReceipt = errorSingleHandler<PaymentRequisitionProfile>(
  async (paymentRequisitionId: number, requestBody: PaymentRequisitionUploadReceiptRequestBody) => {
    return smartAuthAPI.post(`/purchase/v1/payment-requisitions/${paymentRequisitionId}/upload-receipt`, requestBody)
  },
)

export const cancelPaymentRequisition = errorSingleHandler<PaymentRequisitionProfile>(
  async (id: number, requestBody: UserActivityNote) => {
    return smartAuthAPI.post(`/purchase/v1/payment-requisitions/${id}/cancel`, requestBody)
  },
)

export const getPaymentRequisitionCreateData = errorHandler<PaymentRequisitionCreateData>(async (vendorId: number) => {
  return smartAuthAPI.get('purchase/v1/payment-requisitions/create-data', {
    params: {
      vendorId,
    },
  })
})

export const getPaymentRequisitionPayoutDaily = errorHandler<IPaymentRequisitionPayoutDaily>(
  async (request: { paymentDate: DateTime }) => {
    return smartAuthAPI.get('/purchase/v1/payment-requisitions/payout-daily', {
      params: { date: request.paymentDate.toFormat('yyyy-MM-dd') },
    })
  },
)

export const createPaymentRequisition = errorHandler<CreatedInfo>((request: PaymentRequisitionCreateRequestBody) => {
  return smartAuthAPI.post('purchase/v1/payment-requisitions/create', request)
})

export const getPaymentRequisitionPDFInfo = errorSingleHandler<PaymentRequisitionPDFInfo>(
  async (paymentRequisitionId: number) => {
    return smartAuthAPI.get(`/purchase/v1/payment-requisitions/${paymentRequisitionId}/pdf-info`)
  },
)

export const updatePaymentRequisitionExchangeRate = errorSingleHandler<PaymentRequisitionProfile>(
  async (paymentRequisitionId: number, request: PaymentRequisitionUpdateExchangeRateRequestBody) => {
    return smartAuthAPI.patch(`/purchase/v1/payment-requisitions/${paymentRequisitionId}/update-exchange-rate`, request)
  },
)

export const getPaymentRequisitionTableDataAPI = errorHandler<IPaymentRequisitionTableData>(
  async (queryParams: GetProductTableDataRequestQueryParams) => {
    return await smartAuthAPI.get(`/purchase/v1/payment-requisitions/table-data`, {
      params: queryParams,
    })
  },
)
