import { createContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PermissionGroup, PermissionTopic } from '../../../api/smartbestbuys-api/user/user-types'
import { withLayout } from '../../../custom-components'
import { useUserCreateData } from '../../../hooks/user/useUserCreateData'
import { Loading } from '../../../shared-components'
import UserCreateForm from './UserCreateForm'
import UserCreateFromValue from './UserCreateFromValue'

interface IUserCreatePageContext {
  permissionGroups: PermissionGroup[]
  permissionTopics: PermissionTopic[]
}

export const UserCreatePageContext = createContext<IUserCreatePageContext>({
  permissionGroups: [] as PermissionGroup[],
  permissionTopics: [] as PermissionTopic[],
})

const UserCreatePage = () => {
  const formMethods = useForm<UserCreateFromValue>()

  // data
  const getUserCreateData = useUserCreateData()

  if (!getUserCreateData) return <Loading />

  return (
    <UserCreatePageContext.Provider
      value={{
        permissionGroups: getUserCreateData.permissionGroups,
        permissionTopics: getUserCreateData.permissionTopics,
      }}
    >
      <FormProvider {...formMethods}>
        <UserCreateForm />
      </FormProvider>
    </UserCreatePageContext.Provider>
  )
}

export default withLayout(UserCreatePage)
