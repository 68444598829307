import { Collapse, Grid, Paper, Switch } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FlexBox, GeneralTextFieldForm, SubTopic } from '../../../custom-components'
import { ButtonRadios } from '../../../shared-components'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'

interface Props {
  defaultValue?: number
}

const PurchaseOrderWithHoldingTaxBox: React.FC<Props> = (props: Props) => {
  //props
  const { defaultValue } = props

  // hook form
  const {
    control,
    setValue,
    watch,
    register,
    unregister,
    formState: { errors },
  } = useFormContext<PurchaseOrderCreateFormValues>()

  // data
  const withholdingTaxPercentages = [
    { label: '1 %', value: 1 },
    { label: '1.5 %', value: 1.5 },
    { label: '3 %', value: 3 },
  ]
  const { withholdingTaxPercentage } = watch()
  const [open, setOpen] = useState(!!withholdingTaxPercentage)

  useEffect(() => {
    if (open) {
      register('withholdingTaxPercentage', {
        required: 'กรุณากรอกจำนวนเปอร์เซ็นต์',
      })
    } else {
      setValue('withholdingTaxPercentage', undefined)
      unregister('withholdingTaxPercentage')
    }
  }, [open, setValue, register, unregister])

  useEffect(() => {
    if (defaultValue) {
      setOpen(true)
      register('withholdingTaxPercentage', {
        required: 'กรุณากรอกจำนวนเปอร์เซ็นต์',
      })
      setValue('withholdingTaxPercentage', defaultValue)
    }
  }, [defaultValue, register, setValue, setOpen])

  return (
    <Paper>
      <FlexBox justifyContent="space-between" alignItems="center">
        <SubTopic>หัก ณ ที่จ่าย</SubTopic>
        <Switch checked={!!open} onClick={() => setOpen((prev) => !prev)} />
      </FlexBox>
      <Collapse in={!!open}>
        <Grid container className="pt-3" spacing={2}>
          <Grid item xs={12}>
            <GeneralTextFieldForm
              control={control}
              name="withholdingTaxPercentage"
              type="number"
              label="จำนวนเปอร์เซ็นต์ *"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonRadios
              items={withholdingTaxPercentages.map((type) => ({ id: type.value, name: type.label }))}
              onChange={(value) => setValue('withholdingTaxPercentage', value)}
              errorMessage={errors.withholdingTaxPercentage?.message}
              value={withholdingTaxPercentage}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  )
}

export default PurchaseOrderWithHoldingTaxBox
