import { Box, Button, Card, MenuItem, Paper, Popover, Typography } from '@material-ui/core'
import { MoreHoriz, Print } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import {
  BreadcumbsList,
  FlexBox,
  Grid,
  SubTopic,
  Title,
  Topic,
  TypographyWithLabel,
  withLayout,
} from '../../../custom-components'
import { PageName, TaskStatus, TaskType, TransportTaskItemType } from '../../../enums'
import { useTransportTaskProfile } from '../../../hooks'
import {
  ConditionTooltip,
  ImageCard,
  Loading,
  MessengerIcon,
  MultipleConditionTooltip,
  ReturnProductIcon,
  Space,
  TaskStatusChip,
} from '../../../shared-components'
import AssigneeUserBox from '../profile/AssigneeUserBox'
import CustomerRequisitionReferenceBox from './CustomerRequisitionReferenceBox'
import SaleOrderReferenceBox from '../profile/SaleOrderReferenceBox'
import TransportTaskProfileCancelDialog from '../profile/TransportTaskProfileCancelDialog'
import { TransportTaskProfilePageContext } from '../profile/TransportTaskProfilePage'
import TransportTaskProfileUserActivitiesTable from '../profile/TransportTaskProfileUserActivitiesTable'
import TransportPickupTaskProfileProductsTable from './TransportPickupTaskProfileProductsTable'
import TransportPickupTaskDetail from './TransportPickupTaskDetail'
import TransportPickupTaskProfileAssignOtherTeamDialog from './TransportPickupTaskProfileSendWorkToOtherTeamDialog'
import ConfirmPickupResultDialog from './ConfirmPickupResultsDialog'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { getUserPermissionInfo } from '../../../utils/permission'

// import { formatNumber } from '../../../functions/number-utils'

interface Props {
  id: number
}

const TransportPickupTaskProfilePage: React.FC<Props> = (props) => {
  // set up props & state & context
  const { id } = props
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.TRANSPORT_PICKUP_TASK)
  const [reload, setReload] = useState(false)

  // call hooks
  const transportTaskProfile = useTransportTaskProfile(id, reload)
  const forceReload = () => {
    setReload((prev) => !prev)
  }

  if (!transportTaskProfile) return <Loading />

  // calculation
  const breadcumbsItems = [
    { url: '/transport-pickup-tasks', name: 'รายการบริการงานรับของ' },
    { name: transportTaskProfile?.code ?? '...' },
  ]
  const { saleOrderInfo, customerRequisitionInfo, relatedTasks, state } = transportTaskProfile

  const pickupTaskState = relatedTasks?.find((rt) => rt.type.id === TaskType.MessengerPickupTask)?.state
  const failureMessages = pickupTaskState?.failureMessages
  const pickupResult = state?.pickupResult

  const products = transportTaskProfile.state?.items.filter((row) => row.type === TransportTaskItemType.Product) ?? []
  //   const documents = transportTaskProfile.state?.items.filter((row) => row.type === TransportTaskItemType.Document) ?? []

  // render
  return (
    <TransportTaskProfilePageContext.Provider
      value={{
        transportTaskProfile: transportTaskProfile,
        forceReload,
      }}
    >
      <Box p={3} clone>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BreadcumbsList items={breadcumbsItems} />
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <FlexBox alignItems="center">
              <Topic>งานบริการรับของ {transportTaskProfile.code}</Topic>
            </FlexBox>
            <TransportTaskProfileMenues />
          </Grid>
          <Grid item xs={12} container justify="space-between" alignItems="center">
            <TransportPickupTaskProfileStatusGroup />
            <TransportPickupTaskProfileActionGroup />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {transportTaskProfile.relatedTasks?.map((t) => {
                const icon = () => {
                  switch (t.type.id) {
                    case TaskType.MessengerPickupTask:
                      return <MessengerIcon status={t.status.id} />
                    case TaskType.WarehouseReceiveProductTask:
                      return <ReturnProductIcon status={t.status.id} />
                    // case TaskType.FinanceReturnDocument:
                    //   return <ReturnDocumentIcon status={t.status.id} />
                    // case TaskType.FinancePrepareDocument:
                    //   return <PrepareDocumentIcon status={t.status.id} />
                  }
                }

                return (
                  <Grid item xs={3}>
                    <Card>
                      <FlexBox justifyContent="space-around" alignItems="center">
                        <FlexBox flexDirection="column">
                          <Title>{t.type.name}</Title>
                          <Space />
                          <Typography color="primary">{t.code}</Typography>
                        </FlexBox>
                        {icon()}
                      </FlexBox>
                    </Card>
                  </Grid>
                )
              })}
              {transportTaskProfile.status.id === TaskStatus.Done && (
                <Grid item xs={12}>
                  <Paper>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SubTopic>รายละเอียดการจบงาน</SubTopic>
                      </Grid>
                      <Grid item xs={6}>
                        <TypographyWithLabel label="ผลการขนส่ง:" color={failureMessages ? 'error' : 'textSecondary'}>
                          {failureMessages ? 'ขนส่งไม่สำเร็จ' : 'ขนส่งสำเร็จ'}
                        </TypographyWithLabel>
                      </Grid>
                      {state?.shippingInfo.useShippingProvider && (
                        <Grid item xs={6}>
                          <TypographyWithLabel label="ขนส่งที่มาจากจัดส่ง:">
                            {state?.shippingInfo.shippingProvider?.name ?? 'ไม่ระบุ'}
                          </TypographyWithLabel>
                        </Grid>
                      )}
                      {!failureMessages && pickupResult && (
                        <>
                          {/* {state?.shippingInfo.useShippingProvider && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ขนส่งที่ใช้ส่งจริง:">
                              {shippingResult.actualShippingProvider?.name}
                            </TypographyWithLabel>
                          </Grid>
                        )} */}
                          {/* {shippingResult.trackingNumber && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="หมายเลขพัสดุ:">
                              {shippingResult.trackingNumber}
                            </TypographyWithLabel>
                          </Grid>
                        )} */}
                          {/* {shippingResult.payAmount && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ค่าขนส่ง:">
                              {formatNumber(shippingResult.payAmount)}
                            </TypographyWithLabel>
                          </Grid>
                        )} */}
                          {/* {state?.shippingInfo.chargeAmount && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ยอดเงินที่ต้องเก็บ:">
                              {formatNumber(state.shippingInfo.chargeAmount)}
                            </TypographyWithLabel>
                          </Grid>
                        )} */}
                          {/* {shippingResult.chargeAmount && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="จำนวนเงินที่เก็บจากลูกค้า:">
                              {formatNumber(shippingResult.chargeAmount)}
                            </TypographyWithLabel>
                          </Grid>
                        )}
                        {shippingResult.paymentMethod && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="วิธีชำระ:">
                              {shippingResult.paymentMethod.name}
                            </TypographyWithLabel>
                          </Grid>
                        )} */}
                          <Grid item xs={6}>
                            <TypographyWithLabel label="หมายเหตุ:">{pickupResult.remark ?? '-'}</TypographyWithLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TypographyWithLabel label="รูปหลักฐาน:"></TypographyWithLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <ImageCard name="หลักฐาน" src={pickupResult.attachmentUrl} />
                          </Grid>
                        </>
                      )}
                      {failureMessages && (
                        <>
                          <Grid item xs={6}>
                            <TypographyWithLabel label="สาเหตุของการรับของไม่สำเร็จ:">
                              {failureMessages.failed}
                            </TypographyWithLabel>
                          </Grid>
                          {/* <Grid item xs={6}>
                          <TypographyWithLabel label="วันที่ตกลงรับของใหม่:">
                            {shippingTaskState?.nextShippingDate ?? 'ไม่มีการนัดหมาย'}
                          </TypographyWithLabel>
                        </Grid> */}
                          <Grid item xs={6}>
                            <TypographyWithLabel label="หมายเหตุ:">{failureMessages.remark ?? '-'}</TypographyWithLabel>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
          {customerRequisitionInfo?.id && (
            <Grid item xs={12}>
              <CustomerRequisitionReferenceBox />
            </Grid>
          )}
          {saleOrderInfo?.id && (
            <Grid item xs={12}>
              <SaleOrderReferenceBox />
            </Grid>
          )}
          <Grid item xs={12}>
            <AssigneeUserBox />
          </Grid>
          <Grid item xs={12}>
            <TransportPickupTaskDetail />
          </Grid>
          {products.length > 0 && (
            <Grid item xs={12}>
              <TransportPickupTaskProfileProductsTable />
            </Grid>
          )}
          {/* {documents.length > 0 && (
            <Grid item xs={12}>
              <TransportTaskProfileDocumentsTable />
            </Grid>
          )} */}
          <Grid item xs={12}>
            <TransportTaskProfileUserActivitiesTable />
          </Grid>
        </Grid>
      </Box>
    </TransportTaskProfilePageContext.Provider>
  )
}

export default withLayout(TransportPickupTaskProfilePage)

const TransportPickupTaskProfileStatusGroup: React.FC = () => {
  // set up props & state
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const { status } = transportTaskProfile

  return (
    <FlexBox alignItems="center">
      <TaskStatusChip size="small" status={status.id} label={status.name} />
    </FlexBox>
  )
}

const TransportPickupTaskProfileActionGroup: React.FC = () => {
  // set up props & state
  const { transportTaskProfile, forceReload } = useContext(TransportTaskProfilePageContext)
  const { triggerSnackbar } = useContext(RootContext)
  const history = useHistory()
  const { status, dueDate, id } = transportTaskProfile
  const [confirmDataDialogOpen, setConfirmDataDialogOpen] = useState(false)
  const [confirmResultOpen, setConfirmResultOpen] = useState(false)
  const showedAssignOtherTeamButton = status.id === TaskStatus.TransportPending
  const warehouseReceiveProductTask = transportTaskProfile.relatedTasks?.find(
    (t) => t.type.id === TaskType.WarehouseReceiveProductTask,
  )
  const showedCompleteTaskButton = status.id === TaskStatus.PendingApproval
  const pickupTask = transportTaskProfile.relatedTasks?.find((t) => t.type.id === TaskType.MessengerPickupTask)
  const pickupTaskState = pickupTask?.state

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)

  // condition items
  const finishTransportPickupTaskConditionItems = [
    {
      showTooltip: !writePermission.hasPermission,
      message: writePermission.alertMessage,
    },
    {
      showTooltip: warehouseReceiveProductTask?.status?.id !== TaskStatus.Done,
      message: 'ทีมคลังยังไม่ได้รับของคืนเข้าคลัง',
    },
  ]

  const confirmWithCustomerConditions = [
    { showTooltip: !writePermission.hasPermission, message: writePermission.alertMessage },
    { showTooltip: !dueDate, message: 'ยังไม่กำหนดวันรับ' },
  ]

  return (
    <FlexBox alignItems="center">
      {!!pickupTask && !!pickupTaskState && (
        <ConfirmPickupResultDialog
          open={confirmResultOpen}
          readOnly
          data={{
            id: transportTaskProfile.id,
            pickupTaskState: pickupTaskState,
            pickupTaskStatus: pickupTask.status,
          }}
          onSuccess={() => {
            forceReload()
            setConfirmResultOpen(false)
          }}
          onClose={() => setConfirmResultOpen(false)}
        />
      )}
      <TransportPickupTaskProfileAssignOtherTeamDialog
        taskId={id}
        open={confirmDataDialogOpen}
        onClose={() => setConfirmDataDialogOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ส่งงานให้ฝ่ายที่เกี่ยวข้องสำเร็จ`)
          forceReload()
          setConfirmDataDialogOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ส่งงานให้ฝ่ายที่เกี่ยวข้องไม่สำเร็จ`)
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => history.push(`/transport-pickup-tasks/${id}/pickup-cover-pdf`)}
      >
        <Print />
        <span className="ml-1">พิมพ์ใบปะหน้า</span>
      </Button>
      {showedAssignOtherTeamButton && (
        <>
          <Space direction="horizontal" />

          <Box color="primary.dark" clone>
            <MultipleConditionTooltip conditionItems={confirmWithCustomerConditions}>
              <Button
                classes={{ label: 'px-4 py-1 leading-none' }}
                variant="contained"
                color="primary"
                disabled={confirmWithCustomerConditions.some((cond) => cond.showTooltip)}
                onClick={() => {
                  setConfirmDataDialogOpen(true)
                }}
              >
                ยืนยันข้อมูลกับลูกค้าเรียบร้อย
              </Button>
            </MultipleConditionTooltip>
          </Box>
        </>
      )}
      {/* disabled={} */}
      {showedCompleteTaskButton && (
        <>
          <Space direction="horizontal" />
          <MultipleConditionTooltip conditionItems={finishTransportPickupTaskConditionItems}>
            <Button
              variant="contained"
              color="primary"
              classes={{ label: 'px-4 py-1 leading-none' }}
              onClick={() => setConfirmResultOpen(true)}
              disabled={finishTransportPickupTaskConditionItems.some((item) => item.showTooltip)}
            >
              จบงานบริการรับของ
            </Button>
          </MultipleConditionTooltip>
        </>
      )}
    </FlexBox>
  )
}

const TransportTaskProfileMenues: React.FC = () => {
  // set up props & state & context
  const { triggerSnackbar } = useContext(RootContext)
  const { transportTaskProfile, forceReload } = useContext(TransportTaskProfilePageContext)
  const { id, code, status } = transportTaskProfile
  const history = useHistory()
  const [cancelOpen, setCancelOpen] = useState(false)
  const statusShowedMenu = [TaskStatus.TransportPending, TaskStatus.Processing]
  const statusEdit = [TaskStatus.TransportPending]
  const showedEditMenuItem = statusEdit.includes(status.id)
  const showedCancelMenuItem = statusShowedMenu.includes(status.id)
  const showedMenu = statusShowedMenu.includes(status.id)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const menuOpened = Boolean(anchorEl)

  // permission
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Cancel)
  const writePermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)

  if (!showedMenu) return <></>

  return (
    <FlexBox>
      <TransportTaskProfileCancelDialog
        taskId={id}
        taskCode={code}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ยกเลิกงานบริการรับของเลขที่ ${code} สำเร็จ`)
          forceReload()
          setCancelOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ยกเลิกงานบริการรับของเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <Button variant="contained" size="large" onClick={handleClick}>
        <MoreHoriz />
      </Button>
      <Popover
        open={menuOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showedEditMenuItem && (
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <MenuItem
              onClick={() => history.push(`/transport-pickup-tasks/${id}/update`)}
              disabled={!writePermission.hasPermission}
            >
              แก้ไขงานบริการรับของ
            </MenuItem>
          </ConditionTooltip>
        )}
        {showedCancelMenuItem && (
          <ConditionTooltip showTooltip={!cancelPermission.hasPermission} title={cancelPermission.alertMessage}>
            <MenuItem onClick={() => setCancelOpen(true)}>ยกเลิกงานบริการรับของ</MenuItem>
          </ConditionTooltip>
        )}
      </Popover>
    </FlexBox>
  )
}
