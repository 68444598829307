import {
  PaymentRequisitionCreateRequestBody,
  PaymentRequisitionItem,
  PaymentRequisitionSelectedItem,
} from '../../../api/smartbestbuys-api'
import { PaymentRequisitionCreateFormValues } from './PaymentRequisitionCreatePage'

export const mapPaymentRequisitionToCreationFormValues = (
  value: PaymentRequisitionCreateFormValues,
  vendorId: number,
): PaymentRequisitionCreateRequestBody => {
  const paidTotal = value.paymentRequisitionItems.reduce<number>((sum, payment) => {
    return sum + Number(payment.payTotal)
  }, 0)

  return {
    vendorId,
    vendorBankAccountId: value.vendorBankAccountId,
    paymentMethodId: value.paymentMethodId,
    total: paidTotal,
    exchangeRate: value.exchangeRate,
    remark: '',
    items: value.paymentRequisitionItems.map(mapPaymentRequisitionSelectedItemToPaymentRequisitionItem),
  }
}

export const mapPaymentRequisitionSelectedItemToPaymentRequisitionItem = (
  data: PaymentRequisitionSelectedItem,
): PaymentRequisitionItem => {
  return {
    purchaseOrderId: data.id,
    amount: 1,
    price: data.payTotal,
    total: data.payTotal,
    withholdingTaxAmount: data.payWithholdingTaxAmount,
    withholdingTaxPercentage: data.payWithholdingTaxPercentage,
  }
}
