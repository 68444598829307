import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export interface CustomerAddressCreationRequest {
  name?: string
  branchName?: string
  addressLine1: string
  phoneNumber: string
  faxNumber?: string
  provinceId?: number
  districtId?: number
  subDistrictId?: number
  postalCode?: string
  googleMapLink?: string
  overrideCustomerName?: string
  overrideAddress?: string
  overrideTaxNumber?: string
}

export interface CreatedCustomerAddress {
  id: number
  customerId: number
  name?: string
  branchName?: string
  addressLine1: string
  phoneNumber: string
  faxNumber?: string
  provinceId?: number
  districtId?: number
  subDistrictId?: number
  postalCode?: string
  googleMapLink?: string
  recCreatedAt: Date
  recCreatedBy: string
}

export const createCustomerBillingAddressAPI = errorSingleHandler<{ id: number }>(
  (id: number, request: CustomerAddressCreationRequest) => {
    const path = `/customers/${id}/create-billing-address`
    return smartAuthAPI.post(path, request)
  },
)

export const createCustomerShippingAddressAPI = errorSingleHandler<CreatedCustomerAddress>(
  (id: number, request: CustomerAddressCreationRequest) => {
    const path = `/customers/${id}/create-shipping-address`
    return smartAuthAPI.post(path, request)
  },
)
