import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { getSaleOrderDailyPatmentAPI } from '../../api/smartbestbuys-api/sale/sale-apis'
import {
  ISaleOrderDailyPaymentTableData,
  ISaleOrderDailyPaymentTransaction,
} from '../../api/smartbestbuys-api/sale/sale-types'
import { PaymentMethod } from '../../enums'
import { isDateBetween } from '../../utils/date-utils'

export interface ISaleOrderDailyPaymentSummary {
  remainingAmount: number
  cash: number
  transfer: number
  credit: number
  cheque: number
  total: number
}

export interface IUseSaleOrderDailyPayment {
  data: ISaleOrderDailyPaymentTableData[]
  summary: ISaleOrderDailyPaymentSummary
}

export const useSaleOrderDailyPayment = (paymentDate: DateTime): IUseSaleOrderDailyPayment => {
  const [data, setData] = useState<ISaleOrderDailyPaymentTableData[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await getSaleOrderDailyPatmentAPI({ paymentDate })
      if (!response?.data) return
      setData(response.data)
    })()
    // eslint-disable-next-line
  }, [paymentDate.toFormat('yyyy-MM-dd')])

  const summary = useMemo(
    () => calculateSaleOrderDailyPaymentTotal(data, paymentDate),
    // eslint-disable-next-line
    [data.length, paymentDate.toFormat('yyyy-MM-dd')],
  )

  return {
    data,
    summary,
  }
}

const _sumPaymentAmount = (transactions: ISaleOrderDailyPaymentTransaction[], paymentMethod: PaymentMethod): number => {
  return transactions
    .filter((payment) => payment.paymentMethod.id === paymentMethod)
    .reduce<number>((total, item) => {
      return (total += item.amount)
    }, 0)
}

export const calculateSaleOrderDailyPaymentTotal = (
  data: ISaleOrderDailyPaymentTableData[],
  paymentDate: DateTime,
): ISaleOrderDailyPaymentSummary => {
  const defaultValue = {
    remainingAmount: 0,
    cash: 0,
    transfer: 0,
    credit: 0,
    cheque: 0,
    total: 0,
  }

  const startOfPaymentDate = paymentDate.startOf('day').toJSDate()

  if (data.length === 0) return defaultValue
  return data.reduce<ISaleOrderDailyPaymentSummary>((summary, data) => {
    summary.remainingAmount += Number(data.remainingAmount)
    summary.total += Number(data.paymentTotalAmount)

    const transactionsOnPaymentDate = data.paymentTransactions.filter((transaction) =>
      isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate.toJSDate()),
    )

    summary.cash += _sumPaymentAmount(transactionsOnPaymentDate, PaymentMethod.Cash)
    summary.transfer += _sumPaymentAmount(transactionsOnPaymentDate, PaymentMethod.Transfer)
    summary.credit += _sumPaymentAmount(transactionsOnPaymentDate, PaymentMethod.Credit)
    summary.cheque += _sumPaymentAmount(transactionsOnPaymentDate, PaymentMethod.Cheque)

    return summary
  }, defaultValue)
}
