import { useState, useEffect } from 'react'
import { BusinessUnit, Categories, SubCategories } from '../../../api/smartbestbuys-api'

export const useBusinessCategoriesInfo = (
  businessUnits: BusinessUnit[],
  businessUnitId?: number,
  categoryId?: number,
): [Categories[], SubCategories[]] => {
  const [categories, setCategories] = useState<Categories[]>([])
  const [subCategories, setSubCategories] = useState<SubCategories[]>([])

  useEffect(() => {
    if (businessUnitId) {
      const businessCategories = businessUnits.find((bu) => bu.id === businessUnitId)?.categories
      const subCategories = businessCategories?.map((c) => c.subCategories).flat()
      setCategories(businessCategories || [])
      setSubCategories(subCategories || [])
    } else {
      setCategories([])
      setSubCategories([])
    }
  }, [businessUnits, businessUnitId])

  useEffect(() => {
    if (categoryId) {
      const filterSubCategory = categories.find((ca) => ca.id === categoryId)?.subCategories
      setSubCategories(filterSubCategory || [])
    }
  }, [categoryId, categories])

  return [categories, subCategories]
}
