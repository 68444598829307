import { Grid, Paper } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../..'
import { IPendingPickupCustomerRequisitionTableData } from '../../api/smartbestbuys-api'
import { FilteredTable, Topic, withLayout } from '../../custom-components'
import { CustomerRequisitionType, PageName } from '../../enums'
import { dateFormat } from '../../functions'
import { useCustomerRequisitionPendingPickup } from '../../hooks'
import { CRStatusChip, Loading, TypographyLink } from '../../shared-components'

const CustomerRequisitionPendingPickupListPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION_PENDING_PICKUP)

  const pendingPickupList = useCustomerRequisitionPendingPickup()

  if (!pendingPickupList) return <Loading />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>รายการคำร้องที่รอไปรับจากลูกค้า</Topic>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <FilteredTable<IPendingPickupCustomerRequisitionTableData>
            color="primary.dark"
            columns={[
              {
                name: 'สถานะคำร้อง',
                render: ({ status }) => <CRStatusChip icon status={status.id} label={status.name} />,
                fixedWidth: 50,
              },
              {
                name: 'เลขที่คำร้อง',
                dataIndex: 'code',
                render: ({ id, code }) => (
                  <TypographyLink href={`/customer-requisitions/${id}/profile`} color="primary.dark" target="_blank">
                    {code}
                  </TypographyLink>
                ),
                fixedWidth: 150,
                sort: true,
              },
              {
                name: 'ประเภท',
                dataIndex: 'type',
                render: ({ type }) => type.name,
              },
              {
                name: 'เลขที่ใบสั่งขายอ้างอิง',
                dataIndex: 'saleOrder',
                render: ({ saleOrder }) => (
                  <TypographyLink href={`/sale-orders/${saleOrder.id}`} color="#C79400" target="_blank">
                    {saleOrder.code}
                  </TypographyLink>
                ),
                fixedWidth: 150,
              },
              {
                name: 'ชื่อลูกค้า',
                dataIndex: 'customer',
                render: ({ customer }) => customer.name,
              },
              {
                name: 'วันที่สร้าง',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                fixedWidth: 150,
                sort: true,
              },
            ]}
            data={pendingPickupList}
            sortable
            filters={[
              {
                name: 'การสร้างงานส่ง',
                values: [
                  { name: 'เคลม', value: CustomerRequisitionType.Claim },
                  { name: 'คืน', value: CustomerRequisitionType.CancelSO },
                ],
                filter: (data: IPendingPickupCustomerRequisitionTableData, values: (string | number)[]) =>
                  values.includes(Number(data.type.id)),
              },
            ]}
            disableDateRangeFilter
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withLayout(CustomerRequisitionPendingPickupListPage)
