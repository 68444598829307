import { DialogProps } from '@material-ui/core'
import { rejectPurchaseRequisition, UserActivityNote } from '../../api/smartbestbuys-api'
import { UserAction } from '../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../shared-components'
import { UpdatedPurchaseRequisitionStatus } from '../../api/smartbestbuys-api/purchase-requisitions/types'

interface Props extends DialogProps {
  purchaseRequisitionId: number
  onComplete: (data: UpdatedPurchaseRequisitionStatus) => void
  onFail: () => void
}

const PurchaseRequisitionRejectDialog: React.FC<Props> = (props: Props) => {
  const { purchaseRequisitionId, onComplete, onFail, ...dialogProps } = props

  const textOption: UserActivityDialog_TextOption = {
    title: 'ไม่อนุมัติคำขอซื้อ',
    remarkText: 'เหตุผลที่ไม่อนุมัติ',
    remarkErrorText: 'กรุณากรอกเหตุผลที่แก้ไขคำขอซื้อ',
    submitText: 'ยืนยันไม่อนุมัติ',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: false,
    enableRemark: true,
    remarkRequired: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const request: UserActivityNote = {
      remark: values.remark,
    }
    const response = await rejectPurchaseRequisition(purchaseRequisitionId, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.RejectPurchaseRequisition}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default PurchaseRequisitionRejectDialog
