import { DialogProps } from '@material-ui/core'
import { cancelTransportTaskAPI, UserActivityNote } from '../../../api/smartbestbuys-api'

import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'

interface Props extends DialogProps {
  taskId: number
  taskCode: string
  onComplete: (data: any) => void
  onFail: () => void
}

const TransportTaskProfileCancelDialog: React.FC<Props> = (props: Props) => {
  // set up props
  const { taskId, taskCode, onComplete, onFail, ...dialogProps } = props

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการยกเลิกงานขนส่ง',
    description: `ยกเลิกงานขนส่งหมายเลข ${taskCode} นี้ใช่หรือไม่?`,
    actionMessageText: 'สาเหตุของการยกเลิก',
    submitText: 'ยืนยันการยกเลิก',
    cancelText: 'ย้อนกลับ',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: false,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { actionMessageId, actionMessage, remark } = values
    const request: UserActivityNote = {
      actionMessageId: actionMessageId !== -1 ? actionMessageId : undefined,
      actionMessage,
      remark,
    }
    const response = await cancelTransportTaskAPI(taskId, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.CancelTask}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default TransportTaskProfileCancelDialog
