import { format } from 'date-fns'
import React, { useContext, useMemo } from 'react'
import { RootContext } from '../..'
import { SaleOrdersShippingPendingRowData } from '../../api/smartbestbuys-api'
import { FilteredTable, Grid, Link, Topic, withLayout } from '../../custom-components'
import { NavigationItemKey, PageName } from '../../enums'
import { useSaleOrdersShippingPending } from '../../hooks'
import thLocale from 'date-fns/locale/th'
import { NotificationHead, SOPaymentStatusChip, TransportTaskProcessingSignal } from '../../shared-components'
import lodash from 'lodash'

const SaleOrdersShippingPendingPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SHIPPING_PENDING)
  const saleOrderShippingPending = useSaleOrdersShippingPending()

  const saleOrderPaymentStatuses = useMemo(() => {
    return lodash
      .chain(saleOrderShippingPending)
      .uniqBy('paymentStatus.id')
      .orderBy('paymentStatus.id')
      .value()
      .map((so) => {
        return {
          name: so.paymentStatus.name,
          value: so.paymentStatus.id,
        }
      })
    // eslint-disable-next-line
  }, [saleOrderShippingPending.map((so) => so.code).toString()])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Topic>รายการพร้อมส่ง ({saleOrderShippingPending.length})</Topic>
      </Grid>
      <Grid item xs={12}>
        <NotificationHead navigationKey={NavigationItemKey.ReadyToShipSaleOrder} />
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<SaleOrdersShippingPendingRowData>
          color="primary.dark"
          columns={[
            {
              name: '',
              render: ({ hasTransportTaskProcessing }) => (
                <div className="signal-cell">{hasTransportTaskProcessing && <TransportTaskProcessingSignal />}</div>
              ),
            },
            {
              name: 'สถานะชำระเงิน',
              render: ({ paymentStatus: { id, name } }) => <SOPaymentStatusChip status={id} label={name} />,
            },
            {
              name: 'เลขที่ใบสั่งขาย',
              render: ({ id, code }) => (
                <Link color="primary" to={`/sale-orders/${id}`}>
                  {code}
                </Link>
              ),
              dataIndex: 'id',
              sort: true,
            },
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
              dataIndex: 'recCreatedAt',
              sort: true,
            },
            {
              name: 'ชื่อลูกค้า',
              render: (saleOrderShippingPending) => saleOrderShippingPending.customerName,
              dataIndex: 'customerName',
              sort: true,
            },
            {
              name: 'เบอร์โทรศัพท์',
              render: (saleOrderShippingPending) => saleOrderShippingPending.customerPhoneNumber,
            },
            {
              name: 'วิธีชำระเงิน',
              render: (saleOrderShippingPending) => saleOrderShippingPending.paymentMethod.name,
            },
            {
              name: 'ยอดรวมสุทธิ',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
            },
          ]}
          data={saleOrderShippingPending.sort((a, b) => b.id - a.id)}
          disableDateRangeFilter
          sortable
          cleanSpace
          filters={[
            {
              name: 'สถานะชำระเงิน',
              values: saleOrderPaymentStatuses,
              filter: (data: SaleOrdersShippingPendingRowData, values: (string | number)[]) => {
                return values.includes(data.paymentStatus.id)
              },
            },
          ]}
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(SaleOrdersShippingPendingPage)
