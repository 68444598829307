import { errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import {
  AddressData,
  ShippingProvider,
  UserData,
  PermissionData,
  PermissionGroupData,
  PermissionGroupDataCreateRequestBody,
  PermissionGroupDataUpdateRequestBody,
} from './data-types'

export const getAddressDataAPI = errorHandler<AddressData>(() => {
  return smartAuthAPI.get('/data/v1/address-data')
})

export const getPermissionDataAPI = errorHandler<PermissionData>(() => {
  return smartAuthAPI.get('/data/v1/permission-data')
})

export const getUserDataAPI = errorHandler<UserData[]>(() => {
  return smartAuthAPI.get('/data/v1/user-data')
})

export const getShippingDataAPI = errorHandler<ShippingProvider[]>(() => {
  return smartAuthAPI.get('/data/v1/shipping-provider-data')
})

export const createPermissionGroupDataAPI = errorHandler<PermissionGroupData>(
  (request: PermissionGroupDataCreateRequestBody) => {
    return smartAuthAPI.post('/data/v1/create-permission-group', request)
  },
)

export const updatePermissionGroupDataAPI = errorSingleHandler<PermissionGroupData>(
  async (id: number, request: PermissionGroupDataUpdateRequestBody) => {
    return smartAuthAPI.put(`/data/v1/${id}/update-permission-group`, request)
  },
)
