import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, NestedValue, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { CustomerRequisitionForm, CustomerRequisitionFormValues, CustomerRequisitionFormValues_Product } from '..'
import { RootContext } from '../../..'
import {
  IGetCustomerRequisitionUpdateData,
  updateCustomerRequisitionAPI,
  UpdateCustomerRequisitionRequestBody,
} from '../../../api/smartbestbuys-api'
import { Form, Grid, Topic, withLayout } from '../../../custom-components'
import { PageName } from '../../../enums'
import { useCustomerRequisitionUpdateData } from '../../../hooks'
import { Loading } from '../../../shared-components'
import { FileType, SourceFile } from '../../../types'

interface Props {
  id: number
}

export type CustomerRequisitionUpdateData = IGetCustomerRequisitionUpdateData

interface ICustomerRequisitionUpdatePageContext {
  customerRequisitionUpdateData: CustomerRequisitionUpdateData
}

export const CustomerRequisitionUpdatePageContext = createContext<ICustomerRequisitionUpdatePageContext>({
  customerRequisitionUpdateData: {} as CustomerRequisitionUpdateData,
})

const CustomerRequisitionUpdatePage: React.FC<Props> = (props) => {
  const { id } = props
  const [updateData, forceReload] = useCustomerRequisitionUpdateData(id)
  const [attachments, setAttachments] = useState<SourceFile[]>()

  const history = useHistory()
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'start' })
    }
  }

  setCurrentPage(PageName.CUSTOMER_REQUISITION_LIST)

  const defaultValues = useMemo(
    () => {
      if (!updateData) {
        forceReload()
        return
      }

      const { customerRequisition, saleOrderInfo } = updateData

      return {
        customerId: customerRequisition.customerId,
        saleOrderId: saleOrderInfo.id,
        typeId: customerRequisition.type.id,
        content: customerRequisition.content ?? '',
        products: customerRequisition.products.map((p) => ({
          saleOrderProductId: p.saleOrderProductId,
          productId: p.productId,
          amount: p.amount,
        })) as NestedValue<Array<CustomerRequisitionFormValues_Product>>,
        attachments:
          customerRequisition.attachments
            ?.filter((att) => !att.customerRequisitionNoteId)
            ?.map((att) => {
              const fileName = att.url.split('/')
              return {
                type: att.url.slice(-4) === '.pdf' ? FileType.PDF : FileType.Image,
                url: att.url,
                name: fileName[fileName.length - 1],
              }
            }) ?? [],
      }
    }, // eslint-disable-next-line
    [!!updateData, props.id],
  )

  useEffect(() => {
    setAttachments(defaultValues?.attachments)
  }, [defaultValues?.attachments])

  const formMethods = useForm<CustomerRequisitionFormValues>()

  if (!updateData) return <Loading />

  const {
    watch,
    setError,
    formState: { isSubmitting },
  } = formMethods

  const handleSubmit = async (values: CustomerRequisitionFormValues) => {
    if (!validateValues(values)) return

    const request: UpdateCustomerRequisitionRequestBody = {
      typeId: values.typeId,
      content: values.content,
      products: values.products
        .filter((p) => !!p.amount && p.amount > 0)
        .map((p) => ({
          productId: p.productId,
          saleOrderProductId: p.saleOrderProductId,
          amount: p.amount as number,
        })),
      attachments: attachments?.map((image) => ({
        url: image.url,
        base64: image.base64,
      })),
    }

    const response = await updateCustomerRequisitionAPI(id, request)
    if (response?.status === 200) {
      triggerSnackbar('แก้ไขคำร้องของลูกค้าสำเร็จ')
      history.push(`/customer-requisitions/${id}/profile`)
    } else {
      triggerSnackbar('แก้ไขคำร้องของลูกค้าไม่สำเร็จ')
      history.push(`/customer-requisitions/${id}/profile`)
    }
    return true
  }

  const handleInvalid = (errors: CustomerRequisitionFormValues) => {
    validateValues(watch(), false)
  }

  const validateValues = (values: CustomerRequisitionFormValues, triggerSubmit?: boolean): boolean => {
    let valid = true

    const hasContent = values.content.length > 0
    const hasSelectedProducts = values.products.some((d) => !!d.amount && d.amount > 0)

    if (!hasContent) {
      setError('content', {
        type: 'required',
        message: 'กรุณากรอกรายละเอียด',
      })
      scrollTo('#cr-info-input-box')
      valid = false
    }

    if (!hasSelectedProducts) {
      setError('products', {
        type: 'required',
        message: 'กรุณาเลือกสินค้าที่จะเคลม',
      })
      scrollTo('#claim-product-selector-box')
      valid = false
    }

    return valid
  }

  return (
    <CustomerRequisitionUpdatePageContext.Provider value={{ customerRequisitionUpdateData: updateData }}>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Topic>แก้ไขคำร้องของลูกค้า</Topic>
            </Grid>
            <CustomerRequisitionForm
              defaultValues={defaultValues}
              onChange={setAttachments}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Form>
      </FormProvider>
    </CustomerRequisitionUpdatePageContext.Provider>
  )
}

export default withLayout(CustomerRequisitionUpdatePage)
