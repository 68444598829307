import _ from 'lodash'
import { roundNumber } from './number-utils'
import { calculateExcludingVat, calculateVatAmount } from './price-calculator'

interface PriceDetail {
    name: string
    amount: number
}

interface GetPricebreakdownParam {
    products: Array<BaseProductDomain>
    discountAmount?: number
    estimatedShippingFee?: number
    vatIncluded: boolean
    isShippingFeeIncludingVat?: boolean
}

interface BaseProductDomain {
    id: number
    code: string
    nameLocal: string
    nameEn?: string
    unitLocal?: string
    unitEn?: string
    imageUrl?: string
    stockAmount: number
    reservedAmount: number
    amount: number
    price: number
}

export const getPricebreakdown = (param: GetPricebreakdownParam): PriceDetail[] => {
    const priceBreakdowns = calculatePriceBreakdowns(param)
    const { productTotal, discount, shippingFee, vatAmount, total } = priceBreakdowns

    return [
        { name: 'รวมเงิน', amount: productTotal },
        { name: 'ส่วนลด', amount: discount },
        { name: 'ค่าขนส่ง', amount: shippingFee },
        { name: 'ยอดหลังหักส่วนลด', amount: productTotal - discount },
        { name: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
        { name: 'ยอดเงินสุทธิ', amount: total },
    ]
}

interface CalculatePriceBreakdownsParam {
    products: Array<BaseProductDomain>
    discountAmount?: number
    estimatedShippingFee?: number
    vatIncluded: boolean
    isShippingFeeIncludingVat?: boolean
}

interface PriceBreakdownResult {
    productTotal: number
    discount: number
    shippingFee: number
    vatAmount: number
    total: number
}

export const calculatePriceBreakdowns = (profile: CalculatePriceBreakdownsParam): PriceBreakdownResult => {
    const {
        products: selectedProducts,
        discountAmount,
        estimatedShippingFee: shippingFeeAmount,
        vatIncluded,
        isShippingFeeIncludingVat,
    } = profile
    const productTotal = _.chain(selectedProducts)
        .map((sp) => roundNumber(sp.amount * sp.price))
        .sum()
        .value()

    const discount = discountAmount ? Number(discountAmount) || 0 : 0
    let shippingFee = shippingFeeAmount ? Number(shippingFeeAmount) || 0 : 0
    if (isShippingFeeIncludingVat) {
        shippingFee = calculateExcludingVat(shippingFee)
    }

    const vatAmount = vatIncluded ? calculateVatAmount(productTotal - discount + shippingFee) : 0
    const total = productTotal - discount + shippingFee + vatAmount

    return {
        productTotal,
        discount,
        shippingFee,
        vatAmount,
        total,
    }
}
