import {
  SaleOrderProfile,
  createTaxInvoice as createTaxInvoiceAPI,
  TaxInvoiceCreationRequest,
  PriceBreakdownItem,
  SaleOrderProduct,
  TaxInvoiceItem,
} from '../../../../api/smartbestbuys-api'
import { DocumentType } from '../../../../enums'
import { calculateExcludingVat, calculateVatAmount } from '../../../../functions'
import { TaxInvoiceCreationFormValues } from './TaxInvoiceCreationDialog'
import _ from 'lodash'

export interface RefInvoice {
  id: number
  total: number
  code: string
}

// TODO: de dup this logic with tax invoice creation in sale order profile
export const createTaxInvoice = (
  saleOrderProfile: SaleOrderProfile,
  values: TaxInvoiceCreationFormValues,
  refInvoice?: RefInvoice,
) => {
  const priceBreakdowns = getTaxInvoicePriceBreakdowns(saleOrderProfile, refInvoice).map(
    (p) =>
      ({
        label: p.label,
        amount: p.amount,
      } as PriceBreakdownItem),
  )
  const request: TaxInvoiceCreationRequest = {
    invoiceId: refInvoice?.id,
    customerId: saleOrderProfile.customer.id,
    creditPeriodDay: saleOrderProfile.customer.creditPeriodDay,
    total: _.last(priceBreakdowns)?.amount || 0,
    items: getTaxInvoiceItems(saleOrderProfile, refInvoice),
    priceBreakdowns,
    remark: values.remark,
  }
  return createTaxInvoiceAPI(saleOrderProfile.id, request)
}

const mapToItem = (product: SaleOrderProduct): TaxInvoiceItem => {
  return {
    id: product.productId,
    name: product.nameLocal,
    code: product.code,
    imageUrl: product.imageUrl,
    amount: product.amount,
    pricePerEach: product.price,
    total: product.amount * product.price,
  }
}

export const getTaxInvoiceItems = (saleOrderProfile: SaleOrderProfile, refInvoice?: RefInvoice): TaxInvoiceItem[] => {
  if (refInvoice) {
    const excludeVatAmount = calculateExcludingVat(refInvoice.total || 0)
    return [
      {
        id: -1,
        name: 'ค่ามัดจำล่วงหน้า',
        code: refInvoice.code,
        amount: 1,
        pricePerEach: excludeVatAmount,
        total: excludeVatAmount,
      },
    ]
  }
  return saleOrderProfile.products?.map(mapToItem) || []
}

export const getTaxInvoicePriceBreakdowns = (saleOrderProfile: SaleOrderProfile, refInvoice?: RefInvoice) => {
  const { discountAmount, estimatedShippingFee, isShippingFeeIncludingVat, vatIncluded, documents } = saleOrderProfile

  const taxInvoiceItems = getTaxInvoiceItems(saleOrderProfile, refInvoice)
  const itemTotal = _.sum(taxInvoiceItems.map((ti) => ti.total))

  const taxInvoices = documents?.filter((d) => d.type.id === DocumentType.TaxInvoice) || []
  const previousTotal = _.sum(taxInvoices.map((ti) => ti.total || 0))

  const depositAmount = calculateExcludingVat(previousTotal)

  const discount = discountAmount || 0
  let shippingFee = estimatedShippingFee || 0
  if (isShippingFeeIncludingVat) {
    shippingFee = calculateExcludingVat(shippingFee)
  }

  let actualTotal = itemTotal - discount + shippingFee
  const totalExcludingAmount = actualTotal - depositAmount

  const vatAmount = vatIncluded ? calculateVatAmount(totalExcludingAmount) : 0

  let total = totalExcludingAmount + vatAmount

  return [
    { label: 'รวมเงิน', amount: itemTotal },
    { label: 'ส่วนลด', amount: discount },
    { label: 'มูลค่าคงเหลือ', amount: actualTotal },
    { label: 'ยอดเงินมัดจำ', amount: depositAmount },
    { label: 'จำนวนเงินหลังหักยอดเงินมัดจำ', amount: totalExcludingAmount },
    { label: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
    { label: 'ยอดเงินสุทธิ', amount: total },
  ]
}
