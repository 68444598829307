import {
  PRSaleOrderInfo,
  PurchaseOrderCreateRequest,
  PurchaseOrderCreateRequest_Product,
  PurchaseOrderUpsertData_VendorInfo,
} from '../../../api/smartbestbuys-api'
import { CurrenciesType } from '../../../enums'
import { calculatePurchaseOrderTotal, dateFormat } from '../../../functions'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'
import { PurchaseOrderProduct } from './PurchaseOrderProductSelector/PurchaseOrderProductSelector'

export const mapPurchaseOrderToCreationFormValues = (
  values: PurchaseOrderCreateFormValues,
  purchaseRequisitionId: number,
  typeId: number,
  products: PurchaseOrderProduct[],
  saleOrderId?: PRSaleOrderInfo,
): PurchaseOrderCreateRequest => {
  const productTotal = products.reduce<number>((sum, product) => {
    return sum + product.price! * product.amount
  }, 0)

  const total = calculatePurchaseOrderTotal(productTotal, values.discountAmount, values.vatIncluded)

  const parseProducts = products.map<PurchaseOrderCreateRequest_Product>((product) => ({
    ...product,
    price: product.price,
    typeId: product.type?.id || product.typeId!,
  }))

  const attachments = values.attachments?.map((attachment) => {
    return {
      // should have base 64
      base64: attachment.base64!,
    }
  })

  const vendor: PurchaseOrderUpsertData_VendorInfo = values.vendor! as PurchaseOrderUpsertData_VendorInfo
  const exchangeRate = vendor?.currency?.id !== CurrenciesType.THB ? values.exchangeRate : 1
  return {
    vendorId: vendor.id,
    currencyId: vendor.currency.id,
    purchaseRequisitionId,
    paymentConditionId: values.paymentConditionId,
    paymentMethodId: values.paymentMethodId,
    typeId,
    saleOrderId: saleOrderId?.id,
    vendorContactId: vendor.contactInfo?.id,
    vendorShippingDate: values.vendorShippingDate && dateFormat(values.vendorShippingDate, 'yyyy-MM-dd'),
    vendorQuotationCode: values.vendorQuotationCode,
    withholdingTaxPercentage: values.withholdingTaxPercentage,
    vatIncluded: !!values.vatIncluded,
    shippingByVendor: values.shippingByVendor,
    depositAmount: values.depositAmount,
    total,
    productTotal,
    discountAmount: values.discountAmount,
    remark: values.remark,
    exchangeRate: exchangeRate ?? 1,
    products: parseProducts,
    attachments,

    shippingAddressInfo: values.shippingByVendor
      ? {
          consigneeName: values.newShippingAddressInfo?.consigneeName!,
          consigneePhoneNumber: values.newShippingAddressInfo?.consigneePhoneNumber!,
          ...(values.sendCustomerShippingAddressById
            ? {
                customerShippingAddressId: values.customerShippingAddressId,
              }
            : {
                customerId: saleOrderId?.customerId,
                name: values.newShippingAddressInfo?.name,
                branchName: values.newShippingAddressInfo?.branchName,
                addressLine1: values.newShippingAddressInfo?.addressLine1!,
                phoneNumber: values.newShippingAddressInfo?.phoneNumber!,
                faxNumber: values.newShippingAddressInfo?.faxNumber,
                provinceId: values.newShippingAddressInfo?.provinceId,
                districtId: values.newShippingAddressInfo?.districtId,
                subDistrictId: values.newShippingAddressInfo?.subDistrictId,
                postalCode: values.newShippingAddressInfo?.postalCode,
                googleMapLink: values.newShippingAddressInfo?.googleMapLink,
              }),
        }
      : undefined,
  }
}
