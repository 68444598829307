import { Box, Grid, MenuItem, Paper, Tab, Tabs, Typography } from '@material-ui/core'

import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IRefundRequisitionFormData_CustomerBankAccount } from '../../../api/smartbestbuys-api'
import { FlexBox, GeneralTextFieldForm, Radio, Title } from '../../../custom-components'
import { banks } from '../../../data'
import { Loading, Space, UploadImageCard } from '../../../shared-components'
import { IRefundRequisitionFormValues, useRefundRequisitionFormContext } from './RefundRequisitionForm'

const CustomerBankSelectBox = () => {
  const { formData } = useRefundRequisitionFormContext()
  const { setValue, register, unregister } = useFormContext<IRefundRequisitionFormValues>()

  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    if (formData) {
      if (formData.customerBankAccounts.length > 0) {
        register('customerBankAccountId', { required: 'กรุณาเลือกบัญชีธนาคารของลูกค้า' })
      } else {
        register('bookBankImageBase64', { required: 'กรุณาอัปโหลดรูป' })
      }
    }
  }, [formData, register])

  if (!formData) return <Loading />

  if (formData.customerBankAccounts.length === 0) {
    return (
      <Paper>
        <Title>บัญชีธนาคารสำหรับรับเงินคืน</Title>
        <Space />
        <CustomerBankForm />
      </Paper>
    )
  }

  return (
    <Paper>
      <Title>บัญชีธนาคารสำหรับรับเงินคืน</Title>
      <Space />
      <Tabs
        style={{
          marginBottom: 4,
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
        }}
        variant="fullWidth"
        textColor="primary"
        value={tabValue}
        onChange={(e, value) => {
          if (value === 1) {
            // reset customerBankAccountId when try add new bank account
            setValue('customerBankAccountId', undefined)

            register('bookBankImageBase64', { required: 'กรุณาอัปโหลดรูป' })
            unregister('customerBankAccountId')
          } else {
            register('customerBankAccountId', { required: 'กรุณาเลือกบัญชีธนาคารของลูกค้า' })
            unregister('bookBankImageBase64')
          }
          setTabValue(value)
        }}
        indicatorColor="primary"
        aria-label="tabs table"
      >
        <Tab label="บัญชีธนาคารที่มีในระบบ" value={0} />
        <Tab label="บัญชีธนาคารใหม่" value={1} />
      </Tabs>
      {[<CustomerBankList data={formData.customerBankAccounts} />, <CustomerBankForm />][tabValue]}
    </Paper>
  )
}

interface CustomerBankListProps {
  data: IRefundRequisitionFormData_CustomerBankAccount[]
}

const CustomerBankList = ({ data }: CustomerBankListProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IRefundRequisitionFormValues>()

  const errorMessage = useMemo(() => errors.customerBankAccountId?.message, [errors.customerBankAccountId])

  const { customerBankAccountId } = watch()

  return (
    <Box p={2} border={!!errorMessage ? 1 : undefined} borderColor={!!errorMessage ? 'error.main' : undefined} clone>
      <Paper>
        <Grid container spacing={2}>
          {data.map((cba, index) => (
            <Grid item xs={12} key={`customer-bank-${index}`}>
              <Radio
                label={`${cba.accountName} ${cba.accountNumber} ${cba.bank.name}`}
                value={cba.id}
                checked={customerBankAccountId === cba.id}
                outlined
                onChange={(e) => {
                  setValue('customerBankAccountId', parseInt(e.target.value))
                }}
                color="primary"
              />
            </Grid>
          ))}
        </Grid>

        {errorMessage && (
          <div className="mt-2">
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          </div>
        )}
      </Paper>
    </Box>
  )
}

const CustomerBankForm = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IRefundRequisitionFormValues>()

  const { bookBankImageBase64 } = watch()

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="center">
            <UploadImageCard
              previewOnChange
              title="หน้าแรกสมุดธนาคาร *"
              defaultSrc={bookBankImageBase64}
              onChange={(image) => setValue('bookBankImageBase64', image.base64)}
              error={!!errors.bookBankImageBase64}
              message={errors.bookBankImageBase64?.message}
            />
          </FlexBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            control={control}
            label="ชื่อบัญชี *"
            name="accountName"
            rules={{
              required: 'กรุณากรอกชื่อบัญชี',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            control={control}
            label="เลขบัญชี *"
            name="accountNumber"
            rules={{
              required: 'กรุณากรอกเลขบัญชี',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm control={control} label="สาขา" name="branchName" />
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            control={control}
            name="bankId"
            label="ธนาคาร *"
            rules={{
              required: 'กรุณาเลือกธนาคาร',
            }}
            select
          >
            {banks.map((b) => (
              <MenuItem key={b.name} value={b.id}>
                <FlexBox flexDirection="column">
                  <div>{b.name}</div>
                </FlexBox>
              </MenuItem>
            ))}
          </GeneralTextFieldForm>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerBankSelectBox
