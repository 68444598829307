import { errorGenericHandle } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export interface CustomerAddressUpdationRequest {
  name?: string
  branchName?: string
  addressLine1: string
  phoneNumber: string
  googleMapLink?: string
  faxNumber?: string
  provinceId?: number
  districtId?: number
  subDistrictId?: number
  postalCode?: string
  overrideCustomerName?: string
  overrideAddress?: string
  overrideTaxNumber?: string
}

export interface UpdatedCustomerAddress {
  id: number
  customerId: number
  name?: string
  branchName?: string
  addressLine1: string
  phoneNumber: string
  faxNumber?: string
  googleMapLink?: string
  provinceId?: number
  districtId?: number
  subDistrictId?: number
  postalCode?: string
  recModifiedAt?: Date
  recModifiedBy?: string
}

export interface CustomerWithBillingAddressURLParams {
  customerId: number
  billingAddressId: number
}

export const updateCustomerBillingAddressAPI = errorGenericHandle<
  { success: boolean },
  CustomerWithBillingAddressURLParams
>((urlParams: CustomerWithBillingAddressURLParams, request: CustomerAddressUpdationRequest) => {
  const path = `/customers/${urlParams.customerId}/billing-address/${urlParams.billingAddressId}/update`
  return smartAuthAPI.put(path, request)
})
