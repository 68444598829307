import { Paper } from '@material-ui/core'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { CustomerRequisitionFormValues } from '.'
import { SubTopic, GeneralTextFieldForm, FlexBox } from '../../../custom-components'
import { ButtonRadios, Loading } from '../../../shared-components'
import { CustomerRequisitionTypeId, CustomerRequisitionTypes } from '../../../types'

interface Props {
  defaultValues?: CustomerRequisitionFormValues
}

const CustomerRequisitionDetailForm: React.FC<Props> = (props) => {
  const { defaultValues } = props

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CustomerRequisitionFormValues>()

  const isUpdateMode = useMemo(
    () => !!defaultValues, // eslint-disable-next-line
    [!!defaultValues],
  )

  useMemo(
    () => {
      setValue('typeId', defaultValues?.typeId ?? CustomerRequisitionTypeId.Claim)
    }, // eslint-disable-next-line
    [defaultValues?.saleOrderId],
  )

  if (isUpdateMode && !defaultValues) return <Loading />

  return (
    <FlexBox
      p={3}
      justifyContent="space-between"
      flexDirection="column"
      border={errors.content ? 1 : undefined}
      borderColor={errors.content ? 'error.main' : undefined}
      clone
    >
      <Paper id="cr-info-input-box">
        <SubTopic>ข้อมูลคำร้อง</SubTopic>
        <div className="mb-4" />
        <ButtonRadios
          label="ประเภทคำร้อง *"
          items={CustomerRequisitionTypes}
          // currently, we do not supported changing CR type
          onValueChange={(value) => {
            if (!!errors.typeId) {
              clearErrors('typeId')
            }
            setValue('typeId', value as number)
          }}
          disableInActive
          defaultValue={defaultValues?.typeId ?? CustomerRequisitionTypeId.Claim}
        />
        <div className="mb-4" />
        <GeneralTextFieldForm
          label="รายละเอียด *"
          control={control}
          name="content"
          rules={{ required: 'กรุณากรอกรายละเอียด' }}
          defaultValue={defaultValues?.content}
        />
      </Paper>
    </FlexBox>
  )
}

export default CustomerRequisitionDetailForm
