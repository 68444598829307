export interface PaymentCondition {
  id: number
  name: string
  nameEn?: string
}

export const paymentConditions: PaymentCondition[] = [
  { id: 1, name: 'ชำระก่อนส่ง' },
  { id: 2, name: 'ลูกค้าเครดิต' },
  { id: 3, name: 'เก็บเงินปลายทาง' },
]

export const purchasePaymentConditions: PaymentCondition[] = [
  { id: 11, name: 'ชำระเงินก่อนส่ง', nameEn: 'Telegraphic Transfer (T/T)' },
  { id: 12, name: 'เครดิต', nameEn: 'Credit Term' },
  { id: 13, name: 'เก็บเงินปลายทาง', nameEn: 'Collect on Delivery' },
  { id: 14, name: 'มัดจำก่อนผลิต', nameEn: 'Deposit before production' },
]

export const getPaymentConditionNameById = (id: number): string => {
  const paymentCondition = paymentConditions.find((pc) => pc.id === id)
  return paymentCondition ? paymentCondition.name : 'unknown'
}

export const getPurchasePaymentConditionNameById = (id: number, english?: boolean): string => {
  const purchasePaymentCondition = purchasePaymentConditions.find((pc) => pc.id === id)
  if (!purchasePaymentCondition) return '-'
  if (english) return purchasePaymentCondition.nameEn ?? '-'
  return purchasePaymentCondition.name
}
