import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: '0 auto',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
      },
    },
    chip: {
      backgroundColor: theme.palette.primary.light,
    },
  }),
)
