import { errorSingleHandler } from '../../middleware'
import { InvoiceItem, PriceBreakdownItem } from '../document-management'
import smartAuthAPI from '../smartbestbuys-api'

export const createInvoice = errorSingleHandler<CreatedDocument>(
  async (saleOrderId: number, request: InvoiceCreationRequest) => {
    const path = `/sale-orders/${saleOrderId}/create-invoice`
    return await smartAuthAPI.post(path, request)
  },
)

export interface InvoiceCreationRequest {
  customerId: number
  total: number
  percentAmount?: number
  items: InvoiceItem[]
  priceBreakdowns: PriceBreakdownItem[]
  remark?: string
  soPaymentTransactionId?: number
}

export interface CreatedDocument {
  id: number
  code: string
  typeId: number
  statusId: number
  saleOrderId?: number
  referenceDocumentId?: number
  content: string
  customerId?: number
  state?: string
  total?: number
  recCreatedAt: Date
  recCreatedBy: string
}
