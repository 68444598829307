import { useState, useEffect } from 'react'
import { fetchWarehouseTasks, FetchWarehouseQueryParams, WarehouseTask } from '../api/smartbestbuys-api'

export const useWarehouseTasks = (startDate: Date, endDate: Date, typeId?: number): WarehouseTask[] => {
  const [tasks, setTasks] = useState<WarehouseTask[]>([])

  const _fetchTasks = async (request: FetchWarehouseQueryParams) => {
    const response = await fetchWarehouseTasks(request)
    setTasks(response?.data || [])
  }

  useEffect(() => {
    _fetchTasks({ startDate, endDate, typeId })
  }, [startDate, endDate, typeId])

  return tasks
}
