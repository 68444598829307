import { Button } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { CSVLink } from 'react-csv'

interface CSVDownloadButtonProps {
  columns: string[]
  data: any[][]
  filename?: string
}

const CSVDownloadButton = (props: CSVDownloadButtonProps) => {
  const { columns, data: input, filename } = props

  const data = [columns, ...input]
  return (
    <CSVLink filename={filename} style={{ textDecoration: 'none' }} className="csv-link" data={data}>
      <Button color="primary" variant="text" startIcon={<CloudDownload color="primary" />}>
        Download CSV
      </Button>
    </CSVLink>
  )
}

export default CSVDownloadButton
