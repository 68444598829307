import { useEffect, useState } from 'react'
import { getShippingDataAPI } from '../../api/smartbestbuys-api/data/data-apis'
import { ShippingProvider } from '../../api/smartbestbuys-api/data/data-types'

export const useShippingProviderData = (): ShippingProvider[] | undefined => {
  const [value, setValue] = useState<ShippingProvider[] | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getShippingDataAPI()
      if (response?.status === 200) {
        setValue(response?.data)
      }
    })()
  }, [])

  return value
}
