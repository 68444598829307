import React, { useContext, useEffect, useMemo, useState } from 'react'
import { RootContext } from '../../../../..'
import { withLayout, BreadcumbsList, Grid, FlexBox, Link } from '../../../../../custom-components'
import { ConditionTooltip, Loading } from '../../../../../shared-components'
import { PageName, ProductType } from '../../../../../enums'
import ProductProfileContext from '../ProductProfileContext'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'
import ProductDeleteDialog from './ProductDeleteDialog'
import { Delete, RateReviewOutlined, RestoreFromTrash, VerifiedUser } from '@material-ui/icons'
import ProductRestoreDialog from './ProductRestoreDialog'
import ProductVerifyDialog from './ProductVerifyDialog'
import { Button, Tab, Tabs } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { add } from 'date-fns'
import { useProductProfileV2 } from '../../../../../hooks/product'
import ProductProfileData from './ProductProfileData'
import ProductProfileDashboard from './ProductProfileDashboard'

interface Props {
  id: number
  width: Breakpoint
}

const ProductProfilePage: React.FC<Props> = (props) => {
  const { id, width } = props
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const [reload, setReload] = useState(false)
  const [deleteProductOpen, setDeleteProductOpen] = useState(false)
  const [restoreProductOpen, setRestoreProductOpen] = useState(false)
  const [verifyProductOpen, setVerifyProductOpen] = useState(false)
  const [startDateTime] = useState(add(new Date(), { years: -1 }))
  const [endDateTime] = useState(new Date())
  const [tabIndex, setTabIndex] = useState<number>(0)
  const productProfile = useProductProfileV2(id, startDateTime, endDateTime, reload)

  useEffect(() => {
    setCurrentPage(PageName.PRODUCT)
  }, [setCurrentPage])

  const title = useMemo(() => {
    return productProfile?.type.id === ProductType.Service ? 'บริการ' : 'สินค้า'
  }, [productProfile?.type.id])

  if (!productProfile) return <Loading />

  const breadcumbsItems = [{ url: '/products', name: 'รายการสินค้าและบริการ' }, { name: productProfile.nameLocal }]

  const deletePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Delete)
  const reviewPermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Review)

  const isShowDeleteButton = deletePermission.hasPermission && !productProfile.recDeletedAt
  const isShowRestoreButton = deletePermission.hasPermission && productProfile.recDeletedAt
  const isShowVerifiedButton =
    reviewPermission.hasPermission && !productProfile.verifiedBy && !productProfile.recDeletedAt
  const isShowReviewButton =
    reviewPermission.hasPermission && productProfile.requestChangedData && !productProfile.recDeletedAt

  const ableToDelete = productProfile.soReservedAmount > 0

  const DeleteButton = () => (
    <ConditionTooltip showTooltip={ableToDelete} title={`${title}ตัวนี้มีจองใน SO อยู่`}>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<Delete />}
        onClick={() => setDeleteProductOpen(true)}
        disabled={ableToDelete}
      >
        ยกเลิกการขาย
      </Button>
    </ConditionTooltip>
  )

  const RestoreButton = () => (
    <Button
      color="primary"
      variant="outlined"
      startIcon={<RestoreFromTrash />}
      onClick={() => setRestoreProductOpen(true)}
    >
      นำกลับมาขาย
    </Button>
  )

  const VerifyButton = () => (
    <ConditionTooltip showTooltip={!!productProfile.requestChangedData} title={`มีการขอแก้ไขข้อมูลสินค้า`}>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<VerifiedUser />}
        onClick={() => setVerifyProductOpen(true)}
        disabled={!!productProfile.requestChangedData}
      >
        ตรวจสอบข้อมูลสินค้า
      </Button>
    </ConditionTooltip>
  )

  const ReviewButton = () => (
    <Link href={`/products/${id}/review-data`}>
      <Button color="primary" variant="outlined" startIcon={<RateReviewOutlined />}>
        รีวิวข้อมูลสินค้า
      </Button>
    </Link>
  )

  return (
    <ProductProfileContext.Provider
      value={{
        productProfile,
        reload: () => setReload((prev) => !prev),
      }}
    >
      {isShowDeleteButton && (
        <ProductDeleteDialog
          productId={id}
          open={deleteProductOpen}
          onClose={() => setDeleteProductOpen(false)}
          onComplete={() => {
            triggerSnackbar(`ยกเลิกการขาย${title} สำเร็จ`)
            setReload((prev) => !prev)
            setDeleteProductOpen(false)
          }}
          onFail={() => {
            triggerSnackbar(`ยกเลิกการขาย${title} ไม่สำเร็จ`)
          }}
        />
      )}
      {isShowRestoreButton && (
        <ProductRestoreDialog
          productId={id}
          open={restoreProductOpen}
          onClose={() => setRestoreProductOpen(false)}
          onComplete={() => {
            triggerSnackbar(`นำ${title}กลับมาขาย สำเร็จ`)
            setReload((prev) => !prev)
            setRestoreProductOpen(false)
          }}
          onFail={() => {
            triggerSnackbar(`นำ${title}กลับมาขาย ไม่สำเร็จ`)
          }}
        />
      )}
      {isShowVerifiedButton && (
        <ProductVerifyDialog
          productId={id}
          profile={productProfile}
          open={verifyProductOpen}
          onClose={() => setVerifyProductOpen(false)}
          onComplete={() => {
            triggerSnackbar(`ตรวจสอบข้อมูลสินค้า สำเร็จ`)
            setReload((prev) => !prev)
            setVerifyProductOpen(false)
          }}
          onFail={() => {
            triggerSnackbar(`ตรวจสอบข้อมูลสินค้า ไม่สำเร็จ`)
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <BreadcumbsList items={breadcumbsItems} />
            <FlexBox alignItems="center">
              {isShowReviewButton && (
                <span className="ml-2">
                  <ReviewButton />
                </span>
              )}
              {isShowVerifiedButton && (
                <span className="ml-2">
                  <VerifyButton />
                </span>
              )}
              {deletePermission.hasPermission && (
                <span className="ml-2">{!!productProfile.recDeletedAt ? <RestoreButton /> : <DeleteButton />}</span>
              )}
            </FlexBox>
          </FlexBox>
        </Grid>

        <Grid item xs={6}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, value) => setTabIndex(value)}
            centered
            variant="fullWidth"
          >
            <Tab label="รายงาน" value={0} />
            <Tab label="ข้อมูลสินค้า" value={1} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {tabIndex === 1 ? <ProductProfileData width={width} /> : <ProductProfileDashboard />}
        </Grid>
      </Grid>
    </ProductProfileContext.Provider>
  )
}

export default withLayout(ProductProfilePage)
