import { DialogProps } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { PurchaseRequisitionInfo, updatePurchaseRequisitionApi } from '../../../api/smartbestbuys-api'
import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import PurchaseRequisitionCreateFormValues from '../create/PurchaseRequisitionCreateFormValue'
import { mapPurchaseRequisitionsToUpdateFormValues } from './mapper'

interface Props extends DialogProps {
  purchaseRequisitionId: number
  onComplete: (data: PurchaseRequisitionInfo) => void
  onFail: () => void
}

const PRConfirmUpdateDialog: React.FC<Props> = (props: Props) => {
  const { purchaseRequisitionId, onComplete, onFail, ...dialogProps } = props

  const { watch } = useFormContext<PurchaseRequisitionCreateFormValues>()

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการแก้ไขคำขอซื้อ',
    remarkText: 'เหตุผลที่แก้ไขคำขอซื้อ',
    remarkErrorText: 'กรุณากรอกเหตุผลที่แก้ไขคำขอซื้อ',
    submitText: 'ยืนยันการแก้ไข',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: false,
    enableRemark: true,
    remarkRequired: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const request = mapPurchaseRequisitionsToUpdateFormValues(watch(), values.remark!)
    const response = await updatePurchaseRequisitionApi(purchaseRequisitionId, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.UpdatePurchaseRequisition}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default PRConfirmUpdateDialog
