import { Grid, IconButton, Paper, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useState } from 'react'
import { UpdatedMyUserCriteria, UserProfile } from '../../../api/smartbestbuys-api'
import { FlexBox, SubTopic, TypographyWithLabel } from '../../../custom-components'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip, Space } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { MyUserCriteriaUpdateDialog, UserCriteriaUpdateDialog } from '../update'

interface Props {
  userProfile: UserProfile
  owner?: boolean
  onUpdate?: (user: UserProfile | UpdatedMyUserCriteria) => void
}

const UserCriteriaCard: React.FC<Props> = (props) => {
  const { userProfile, owner, onUpdate } = props
  const [openCriteriaForm, setOpenCriteriaForm] = useState(false)
  const [openMyCriteriaDialog, setOpenMyCriteriaDialog] = useState(false)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.HR_User_Write)

  return (
    <>
      <MyUserCriteriaUpdateDialog
        open={openMyCriteriaDialog}
        userProfile={userProfile}
        onComplete={(task) => {
          setOpenCriteriaForm(false)
          if (onUpdate) onUpdate(task)
        }}
        onClose={() => setOpenMyCriteriaDialog(false)}
      />
      <UserCriteriaUpdateDialog
        open={openCriteriaForm}
        userId={userProfile.id}
        username={userProfile.username}
        onComplete={(task) => {
          setOpenCriteriaForm(false)
          if (onUpdate) onUpdate(task)
        }}
        onClose={() => setOpenCriteriaForm(false)}
      />
      <Paper>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <SubTopic>ข้อมูลสำหรับเข้าสู่ระบบ</SubTopic>
            {!owner ? (
              <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
                <IconButton
                  size="small"
                  onClick={() => setOpenCriteriaForm(true)}
                  disabled={!writePermission.hasPermission}
                >
                  <Edit color={!writePermission.hasPermission ? 'disabled' : 'secondary'} />
                </IconButton>
              </ConditionTooltip>
            ) : (
              // change your self dont need permission
              <Typography style={{ cursor: 'pointer' }} color="secondary" onClick={() => setOpenMyCriteriaDialog(true)}>
                เปลี่ยนรหัสผ่าน
              </Typography>
            )}
          </FlexBox>
        </Grid>
        <Space />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TypographyWithLabel label="ชื่อผู้ใช้">{userProfile.username}</TypographyWithLabel>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default UserCriteriaCard
