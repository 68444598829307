import { Avatar, Box, Button, Grid, Paper } from '@material-ui/core'
import { FlexBox, SubTopic, Table, TypographyWithLabel } from '../../../../custom-components'
import { PWTicketFormValues, PWTicketFormValuesProductInfo, PWTicketProductModal } from '.'
import { useFormContext } from 'react-hook-form'
import React, { useState } from 'react'
import { Delete, Edit, Photo } from '@material-ui/icons'

const PWTicketFormSelectedProduct: React.FC = () => {
  const { watch, setValue } = useFormContext<PWTicketFormValues>()
  const { products } = watch()

  // state
  const [selectedProduct, setSelectedProduct] = useState<PWTicketFormValuesProductInfo | undefined>(undefined)

  return (
    <Box>
      {!!selectedProduct && (
        <PWTicketProductModal
          open={!!selectedProduct}
          onClose={() => setSelectedProduct(undefined)}
          selectedProduct={selectedProduct}
        />
      )}
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>สินค้าที่ถูกเลือก</SubTopic>
          </Grid>
          <Grid item xs={12}>
            <Table<PWTicketFormValuesProductInfo>
              color="primary.dark"
              size="small"
              columns={[
                {
                  name: 'ลำดับ',
                  fixedWidth: 50,
                  numeric: true,
                  format: '0,0',
                  render: (_, index) => index + 1,
                },
                {
                  name: '',
                  dataIndex: 'imageUrl',
                  fixedWidth: 50,
                  render: ({ code, imageUrl }) => (
                    <Avatar alt={code} src={imageUrl} variant="rounded">
                      <Photo />
                    </Avatar>
                  ),
                },
                {
                  name: 'รายละเอียด',
                  render: ({ code, nameLocal }) => (
                    <Box>
                      <TypographyWithLabel label="รหัสสินค้า:">{code}</TypographyWithLabel>
                      <TypographyWithLabel label="ชื่อสินค้า:">{nameLocal}</TypographyWithLabel>
                    </Box>
                  ),
                },
                {
                  name: 'จำนวนที่ขอเบิก',
                  dataIndex: 'requestToWithdrawnAmount',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: '',
                  fixedWidth: 50,
                  render: (product) => (
                    <FlexBox>
                      <Button onClick={() => setSelectedProduct(product)}>
                        <Edit color="secondary" />
                      </Button>
                      <Button>
                        <Delete
                          color="primary"
                          onClick={() => {
                            const index = products.findIndex((p) => p.id === product.id)
                            if (index !== -1) {
                              setValue(`products.${index}.requestToWithdrawnAmount`, 0)
                            }
                          }}
                        />
                      </Button>
                    </FlexBox>
                  ),
                },
              ]}
              data={products.filter((p) => !!p.requestToWithdrawnAmount && p.requestToWithdrawnAmount !== 0)}
              cleanSpace
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default PWTicketFormSelectedProduct
