import { useMemo } from 'react'

import { Grid, Paper } from '@material-ui/core'

import ProductProfileTopCoSalesProduct from './ProductProfileTopCoSalesProduct'
import ProductProfileQuotationProcessingHistory from './ProductProfileQuotationProcessingHistory'
import { SigleLineChart } from '../../../../../components'
import { SubTopic } from '../../../../../custom-components'
import { useContext } from 'react'
import ProductProfileContext from '../ProductProfileContext'
import { dateFormat } from '../../../../../functions'
import { NivoColorPalette, Statistic } from '../../../../../visaulization-components'
import ProductProfileSaleOrderHistory from './ProductProfileSaleOrderHistory'

const ProductProfileDashboard: React.FC = (props) => {
  const { productProfile } = useContext(ProductProfileContext)
  const { seasonalData, summaryProduct } = productProfile

  const _data = useMemo(() => {
    return seasonalData.map((d) => {
      return {
        date: dateFormat(new Date(d.time * 1000), 'MMM yy'),
        seasonalIndex: d.salesTotal,
      }
    })
  }, [seasonalData])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className="p-4 h-96">
          <SubTopic color="primary">Seasonal Index</SubTopic>
          <div className="mb-6" />
          <SigleLineChart data={_data} xDataKey="date" yDataKey="seasonalIndex" />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Statistic
          title="อัตราหมุนเวียนสินค้าคงเหลือ"
          amount={summaryProduct.turnOverRateRemainningAllProduct}
          subtitle="วัน"
          color={NivoColorPalette.intenseOrange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Statistic
          title="จำนวนวันโดยเฉลี่ยสินค้าคงเหลือ"
          amount={summaryProduct.averageTurnOverDay}
          subtitle="วัน"
          color={NivoColorPalette.orange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Statistic
          title="อัตรากำไรขั้นต้น"
          amount={summaryProduct.grossProfitMarginPercent}
          subtitle="%"
          color={NivoColorPalette.oceanGreen}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductProfileTopCoSalesProduct />
      </Grid>
      <Grid item xs={12}>
        <ProductProfileQuotationProcessingHistory />
      </Grid>
      <Grid item xs={12}>
        <ProductProfileSaleOrderHistory />
      </Grid>
    </Grid>
  )
}

export default ProductProfileDashboard
