import { useEffect, useState } from 'react'
import { getPermissionDataAPI } from '../../api/smartbestbuys-api/data/data-apis'
import { PermissionData } from '../../api/smartbestbuys-api/data/data-types'

const usePermissionData = (): PermissionData | undefined => {
  const [permissions, setPermission] = useState<PermissionData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getPermissionDataAPI()
      if (response?.status === 200) setPermission(response.data)
    })()
  }, [])

  return permissions
}

export default usePermissionData
