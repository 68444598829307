import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  TablePagination,
  Typography,
  isWidthUp,
  withWidth,
} from '@material-ui/core'
import { IProductTableData_Data, IProductProfile, updateProductPairAPI } from '../../../../../api/smartbestbuys-api'
import { useEffect, useState } from 'react'
import { GeneralTextField, Title } from '../../../../../custom-components'
import { useProductTableData } from '../../../../../hooks/product/useProductTableData'
import { Loading } from '../../../../../shared-components'
import { Close, Photo } from '@material-ui/icons'
import './PairProduct.less'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

interface Props extends DialogProps {
  width: Breakpoint
  productProfile: IProductProfile
  onSuccess?: () => void
  onFail?: () => void
}

const PairProductSelectorDialog: React.FC<Props> = (props) => {
  const { width, productProfile, onSuccess, onFail, onClose, ...otherProps } = props

  const [pairProducts, setPairProducts] = useState<
    Pick<IProductTableData_Data, 'id' | 'code' | 'nameLocal' | 'imageUrl'>[]
  >(productProfile.pairedProducts)

  const handleOnClose = () => {
    setPairProducts(
      productProfile.pairedProducts.map((p) => ({
        id: p.id,
        code: p.code,
        nameLocal: p.nameLocal,
        imageUrl: p.imageUrl,
      })),
    )
    onClose && onClose({}, 'escapeKeyDown')
  }

  const handleSubmit = async () => {
    const response = await updateProductPairAPI(productProfile.id, { pairProductIds: pairProducts.map((p) => p.id) })

    if (response?.status === 200) {
      onSuccess && onSuccess()
    } else {
      setPairProducts(productProfile.pairedProducts)
      onFail && onFail()
    }
  }

  const handleSelect = (product: Pick<IProductTableData_Data, 'id' | 'code' | 'nameLocal' | 'imageUrl'>) =>
    setPairProducts((prev) => {
      return [...prev, product]
    })

  const handleDeselect = (product: Pick<IProductTableData_Data, 'id' | 'code' | 'nameLocal' | 'imageUrl'>) =>
    setPairProducts((prev) => {
      return prev.filter((p) => p.id !== product.id)
    })

  const tableData = useProductTableData(4)

  useEffect(
    () => {
      tableData?.handleRowPerPageChange && tableData.handleRowPerPageChange(isWidthUp('xl', width) ? 6 : 4)
    },
    // eslint-disable-next-line
    [!!tableData?.handleRowPerPageChange, width],
  )

  if (!tableData)
    return (
      <Dialog fullWidth maxWidth="lg" onClose={handleOnClose} {...otherProps}>
        <Loading />
      </Dialog>
    )
  const { data, paginationInfo, handlePageChange, handleSearch, handleSearchParamChange } = tableData

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleOnClose} {...otherProps}>
      <DialogTitle>เลือกสินค้าที่เกี่ยวข้อง</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <GeneralTextField
              variant="outlined"
              name="code"
              onChange={(e) => handleSearchParamChange('code', e.target.value)}
              label="รหัสสินค้า"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <GeneralTextField
              variant="outlined"
              name="name"
              onChange={(e) => handleSearchParamChange('name', e.target.value)}
              label="ชื่อสินค้า"
            />
          </Grid>

          <Grid item xs={10} />
          <Grid item xs={2}>
            <Button variant="contained" color="primary" onClick={handleSearch} size="small" fullWidth>
              ค้นหา
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {data.map((product) => {
                const isMainProduct = product.id === productProfile.id
                const isSelectProduct = pairProducts.some((p) => p.id === product.id)

                return (
                  <Grid key={product.id} item xs={12} sm={6} md={3} xl={2}>
                    <Card style={{ opacity: isMainProduct ? '40%' : undefined }}>
                      <Grid item xs={12}>
                        <Avatar
                          alt={product.nameLocal}
                          src={product.imageUrl}
                          variant="rounded"
                          style={{ width: '100%', height: 150, marginBottom: 8 }}
                        >
                          <Photo />
                        </Avatar>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="textSecondary" variant="body2">
                          {product.code}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">{product.nameLocal}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          disabled={isMainProduct}
                          style={{ marginTop: 16 }}
                          onClick={() => {
                            if (isSelectProduct) {
                              handleDeselect(product)
                            } else {
                              handleSelect(product)
                            }
                          }}
                          size="small"
                          fullWidth
                          variant={isSelectProduct ? 'outlined' : 'contained'}
                          color="primary"
                        >
                          {isSelectProduct ? 'นำออก' : 'เลือก'}
                        </Button>
                      </Grid>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TablePagination
              component="div"
              count={paginationInfo.totalData}
              page={paginationInfo.pageIndex}
              onChangePage={(_, pageIndex) => handlePageChange(pageIndex)}
              rowsPerPage={paginationInfo.rowPerPage}
              rowsPerPageOptions={[]}
            />
          </Grid>

          {pairProducts.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title>สินค้าที่เลือก</Title>
                </Grid>
                <Grid container spacing={2}>
                  {pairProducts.map((product) => {
                    return (
                      <Grid item xs={12} md={3}>
                        <Card style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div className="product-pair-dialog__product-cell" key={'product-' + product.id}>
                            <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                              <Photo />
                            </Avatar>
                            <div className="product-pair-dialog__product-cell__info">
                              <Typography color="textSecondary">{product.code}</Typography>
                              <Typography>{product.nameLocal}</Typography>
                            </div>
                          </div>
                          <IconButton aria-label="close" onClick={() => handleDeselect(product)}>
                            <Close />
                          </IconButton>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} variant="outlined" color="primary">
          ย้อนกลับ
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withWidth()(PairProductSelectorDialog)
