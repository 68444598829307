import { Grid, IconButton, Paper } from '@material-ui/core'
import { Create } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { CustomerRequisitionProfileUpdateConditionDialog } from '..'
import { FlexBox, SubTopic, TypographyWithLabel } from '../../../custom-components'
import { TaskType } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { CustomerRequisitionProfileContext } from './CustomerRequisitionProfilePage'

const CustomerRequisitionConditionDetail: React.FC = () => {
  const customerRequisitionProfile = useContext(CustomerRequisitionProfileContext)
  const [openUpdateConditionDialog, setOpenUpdateConditionDialog] = useState<boolean>(false)

  const { id, saleOrderInfo, condition, tasks } = customerRequisitionProfile

  const receiveTask = tasks.find((t) => t.type.id === TaskType.TransportPickupTask)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Write)

  const show = false // todo: waiting for update condition API modification

  return (
    <>
      <CustomerRequisitionProfileUpdateConditionDialog
        customerRequisitionId={id}
        saleOrderId={saleOrderInfo.id}
        open={openUpdateConditionDialog}
        onClose={() => setOpenUpdateConditionDialog(false)}
      />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between" alignItems="center">
              <Grid item xs={12}>
                <SubTopic>รายละเอียดการรับของจากลูกค้า</SubTopic>
              </Grid>
              {show && (
                <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
                  <IconButton
                    disabled={!writePermission.hasPermission}
                    onClick={() => setOpenUpdateConditionDialog(true)}
                  >
                    <Create color={writePermission.hasPermission ? 'secondary' : undefined} />
                  </IconButton>
                </ConditionTooltip>
              )}
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <Grid item xs={12}>
                <TypographyWithLabel label="วิธีรับของจากลูกค้า:">{condition?.name}</TypographyWithLabel>
              </Grid>
              {!!receiveTask?.state?.shippingInfo?.dueDate && (
                <Grid item xs={12}>
                  <TypographyWithLabel label="วันที่ไปรับ:">
                    {receiveTask.state?.shippingInfo?.dueDate}
                  </TypographyWithLabel>
                </Grid>
              )}
            </FlexBox>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerRequisitionConditionDetail
