import {
  // Box,
  Card,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { FlexBox } from '../../custom-components'
import { NivoColorPalette } from '../palette'
import ProgressBar from 'react-customizable-progressbar'
import { useStyles } from './styles'

interface Props {
  title: string
  amount: number
  color?: NivoColorPalette
  rowSize?: number
  unit?: string
  progress: number
  radius?: number
}

const ProgressBarCard: React.FC<Props> = (props) => {
  const { title, color, amount, unit, rowSize, ...progressBarProps } = props
  const classes = useStyles(rowSize || 1)()
  const radius = 11.5 * (rowSize || 1)
  return (
    <FlexBox flexDirection="column" alignItems="center" justifyContent="flex-start" clone>
      <Card className={classes.card}>
        <Typography variant="subtitle1">{title}</Typography>
        <ProgressBar
          radius={radius}
          counterClockwise
          strokeWidth={10}
          strokeColor={color || NivoColorPalette.intenseOrange}
          trackStrokeWidth={10}
          {...progressBarProps}
        >
          <Typography className={classes.indicator}>{`${amount}${unit}`}</Typography>
        </ProgressBar>
      </Card>
    </FlexBox>
  )
}

export default ProgressBarCard
