import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { PaymentRequisitionStatus } from '../../enums'
import { Description } from '@material-ui/icons'

interface Props {
  status: PaymentRequisitionStatus
  icon?: boolean
}

const useStyles = (status: PaymentRequisitionStatus) =>
  makeStyles((theme) => {
    const chipColors = {
      [PaymentRequisitionStatus.PendingReview]: theme.palette.warning.main,
      [PaymentRequisitionStatus.PendingTransfer]: theme.palette.warning.main,
      [PaymentRequisitionStatus.PendingCheque]: theme.palette.warning.main,
      [PaymentRequisitionStatus.PendingReceipt]: theme.palette.warning.main,
      [PaymentRequisitionStatus.PendingStatement]: theme.palette.warning.main,
      [PaymentRequisitionStatus.Success]: theme.palette.success.main,
      [PaymentRequisitionStatus.Rejected]: theme.palette.error.main,
      [PaymentRequisitionStatus.Canceled]: theme.palette.error.main,
    }

    const iconColors = {
      [PaymentRequisitionStatus.PendingReview]: theme.palette.warning.darkText,
      [PaymentRequisitionStatus.PendingTransfer]: theme.palette.warning.darkText,
      [PaymentRequisitionStatus.PendingCheque]: theme.palette.warning.darkText,
      [PaymentRequisitionStatus.PendingReceipt]: theme.palette.warning.darkText,
      [PaymentRequisitionStatus.PendingStatement]: theme.palette.warning.darkText,
      [PaymentRequisitionStatus.Success]: theme.palette.success.darkText,
      [PaymentRequisitionStatus.Rejected]: theme.palette.error.darkText,
      [PaymentRequisitionStatus.Canceled]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: chipColors[status],
      },
      icon: {
        color: iconColors[status],
      },
    })
  })

const PaymentRequisitionStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({
  children,
  status,
  icon,
  ...othersProps
}) => {
  const classes = useStyles(status)()

  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      icon={icon ? <Description /> : undefined}
      className={classes.chip}
      {...othersProps}
    />
  )
}

export default PaymentRequisitionStatusChip
