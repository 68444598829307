import React from 'react'
import { Box, Paper, FormControlLabel, Typography } from '@material-ui/core'
import { CheckboxForm } from '../../custom-components'
import { useFormContext } from 'react-hook-form'
import SOCreationFormValues from '../pages/SOCreationPage/SOCreationFormValues'

const DepositOptionBox: React.FC = (props) => {
  const { control, getValues } = useFormContext<SOCreationFormValues>()
  const { depositRequired } = getValues()

  return (
    <Box padding={2} clone>
      <Paper>
        <FormControlLabel
          control={<CheckboxForm checked={depositRequired} control={control} name="depositRequired" />}
          label={<Typography variant="h5">ต้องการมัดจำก่อนซื้อ</Typography>}
        />
      </Paper>
    </Box>
  )
}

export default DepositOptionBox
