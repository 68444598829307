export enum PurchaseRequisitionStatus {
  PendingReview = 101,
  Approved = 201,
  Rejected = 202,
  Canceled = 999,
}

export enum PurchaseRequisitionType {
  BuyProductAndServices = 1,
  ClaimProduct = 2,
}

export enum PurchaseRequisitionCreateState {
  Form = 1,
  DataVerification = 2,
}

export enum PurchaseProductFilterKey {
  AllProduct = 1,
  SaleOrder = 2,
  PurchaseRequisition = 3,
  PendingPurchase = 4,
}

export enum PurchaseRequisitionTopic {
  InternalPurchase = 1001,
  ForSell = 1002,
  ReStock = 1003,
  ForcastStock = 1004,
  FoundDamangedProduct = 2001,
}
