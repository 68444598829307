import React from 'react'
import { Checkbox, CheckboxProps } from '@material-ui/core'
import { useController } from 'react-hook-form'

type Props = CheckboxProps & {
  control: any
}

const CheckboxForm: React.FC<Props> = (props) => {
  const { control, name, defaultValue, ...checkboxProps } = props
  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name: name as any,
    control,
    defaultValue: defaultValue,
  })

  return <Checkbox {...checkboxProps} {...inputProps} inputRef={ref} />
}

export default CheckboxForm
