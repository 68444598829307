import React, { useContext, useMemo, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Box,
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core'
import { ButtonRadios, ImageIcon, SubmitForm } from '../../../../../../shared-components'
import { useForm, FormProvider } from 'react-hook-form'
import { CheckboxForm, Form, GeneralTextFieldForm } from '../../../../../../custom-components'
import { updateCustomer } from './service'
import { RootContext } from '../../../../../..'
import CustomerProfileContext from '../../CustomerProfileContext'
import CustomerUpdationFormValue from './CustomerUpdationFormValues'
import dealerLogo from '../../../../../../assets/images/dealer-logo.png'
import { customerBusinessTypes } from '../../../../../../constants'
import { useUserData } from '../../../../../../hooks'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'

const CustomerInformationDialog: React.FC<DialogProps> = (props) => {
  const { onClose, ...otherProps } = props

  const userData = useUserData()

  const {
    customerProfile: {
      id,
      nameLocal,
      nameEn,
      customerType,
      creditPeriodDay,
      referenceNumber,
      websiteUrl,
      phoneNumber,
      email,
      businessType,
      isDealer,
      additionalData,
      saleUser,
    },
    reload,
  } = useContext(CustomerProfileContext)
  const { triggerSnackbar } = useContext(RootContext)

  const hasCreditCustomer = (creditPeriodDay || 0) > 0

  const formMethod = useForm<CustomerUpdationFormValue>({
    defaultValues: {
      customerTypeId: customerType.id,
      nameLocal: nameLocal,
      nameEn: nameEn,
      businessTypeId: businessType?.id?.toString() || '',
      referenceNumber: referenceNumber,
      phoneNumber: phoneNumber,
      email: email,
      websiteUrl: websiteUrl,
      hasCreditCustomer: hasCreditCustomer,
      creditPeriodDay: creditPeriodDay?.toString(),
      isDealer,
      shippingDocumentRequireList: additionalData?.shippingDocumentRequireList || '',
      billingTermAndCondition: additionalData?.billingTermAndCondition || '',
      paymentTermAndCondition: additionalData?.paymentTermAndCondition || '',
    },
  })

  const updatePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Update)

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
    control,
    setValue,
    watch,
    reset,
  } = formMethod

  const handleSubmitUpdateCustomerForm = async (values: CustomerUpdationFormValue) => {
    const response = await updateCustomer(id, {
      ...values,
      nameLocal: updatePermission.hasPermission ? values.nameLocal : nameLocal,
      referenceNumber: updatePermission.hasPermission ? values.referenceNumber : referenceNumber,
    })
    if (response?.status === 200) {
      reload()
      onClose && onClose({}, 'backdropClick')
      triggerSnackbar('บันทึกข้อมูลลูกค้าสำเร็จ')
    } else {
      if (response?.status === 400) {
        const errorData = response?.data as any
        if (!!errorData?.data?.referenceNumber)
          setError('referenceNumber', {
            message: errorData.data.referenceNumber,
          })

        if (!!errorData?.data?.email)
          setError('email', {
            message: errorData.data.email,
          })

        if (!!errorData?.data?.phoneNumber)
          setError('phoneNumber', {
            type: 'required',
            message: errorData.data.phoneNumber,
          })
      }
      triggerSnackbar('บันทึกข้อมูลลูกค้าไม่สำเร็จ')
    }

    return true
  }

  const { customerTypeId, isDealer: isDealerWatch } = watch()

  const [isCredit, setIsCredit] = useState(hasCreditCustomer)

  const hasฺBฺusinessType = customerBusinessTypes && customerBusinessTypes?.length > 0

  const handleCheckCredit = () => {
    setIsCredit((prevState) => {
      const next = !prevState
      if (next) {
        setValue('creditPeriodDay', '30')
      } else {
        setValue('creditPeriodDay', undefined)
      }
      return next
    })
  }

  const isInvalid = useMemo(
    () => !!errors.referenceNumber || !!errors.email,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors.referenceNumber?.message, errors.email?.message],
  )

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <FormProvider {...formMethod}>
        <Form onSubmit={handleSubmit(handleSubmitUpdateCustomerForm)}>
          <DialogTitle>แก้ไขข้อมูลลูกค้า</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="nameLocal"
                      rules={{ required: 'กรุณาใส่ชื่อภาษาไทย' }}
                      label="ชื่อภาษาไทย *"
                      disabled={!updatePermission.hasPermission}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm control={control} name="nameEn" label="ชื่อภาษาอังกฤษ (ถ้ามี)" />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box marginLeft={1.5}>
                      <Typography align="left" variant="caption" color="textSecondary" gutterBottom>
                        ประเภทลูกค้า *
                      </Typography>
                    </Box>
                    <ButtonRadios
                      items={customerTypes}
                      onChange={(value) => setValue('customerTypeId', value)}
                      value={customerTypeId}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box mt={1}>
                      <GeneralTextFieldForm
                        control={control}
                        name="businessTypeId"
                        label="ประเภทธุรกิจ *"
                        rules={{ required: 'กรุณาเลือกประเภทธุรกิจ' }}
                        select
                      >
                        <MenuItem key={'default-menu-item-type-business'} value={''}>
                          <Typography color="textSecondary">ไม่เลือก</Typography>
                        </MenuItem>
                        {hasฺBฺusinessType &&
                          customerBusinessTypes?.map(({ id, name, detail }) => (
                            <MenuItem key={id} value={id}>
                              <div>
                                <Typography display="block">{name}</Typography>
                                <Typography className="italic" color="textSecondary">
                                  {detail}
                                </Typography>
                              </div>
                            </MenuItem>
                          ))}
                      </GeneralTextFieldForm>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="referenceNumber"
                      label="เลขที่ผู้เสียภาษี (ถ้ามี)"
                      disabled={!updatePermission.hasPermission}
                      error={!!errors.referenceNumber}
                      helperText={errors.referenceNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="phoneNumber"
                      rules={{ required: 'กรุณากรอกเบอร์โทรศัพท์' }}
                      label="เบอร์โทรศัพท์ *"
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="email"
                      label="อีเมล (ถ้ามี)"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm control={control} name="websiteUrl" label="เว็บไซต์ (ถ้ามี)" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <CheckboxForm
                            size="small"
                            checked={isCredit}
                            control={null}
                            onClick={handleCheckCredit}
                            name="hasCreditCustomer"
                          />
                        }
                        label={!isCredit && <Typography variant="body2">เป็นลูกค้าเครดิต</Typography>}
                      />
                      {isCredit && (
                        <GeneralTextFieldForm
                          control={control}
                          disabled={!isCredit}
                          type="number"
                          name="creditPeriodDay"
                          style={{ width: 100 }}
                          label="จำนวนเครดิต"
                          InputProps={{ startAdornment: <InputAdornment position="start">วัน</InputAdornment> }}
                          rules={{ required: { value: true, message: 'กรุณาใส่จำนวนเครดิต' } }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        control={<CheckboxForm size="small" control={null} checked={isDealerWatch} name="isDealer" />}
                        label={<Typography variant="body2">เป็นดีลเลอร์</Typography>}
                      />
                      <ImageIcon name="dealer" src={dealerLogo} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="shippingDocumentRequireList"
                      fullWidth
                      label="เอกสารที่ใช้ตอนส่งสินค้า (ถ้ามี)"
                      variant="outlined"
                      size="small"
                      rows={4}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="billingTermAndCondition"
                      fullWidth
                      label="ระเบียบการวางบิล (ถ้ามี)"
                      variant="outlined"
                      size="small"
                      rows={4}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="paymentTermAndCondition"
                      fullWidth
                      label="ระเบียบการรับเงิน (ถ้ามี)"
                      variant="outlined"
                      size="small"
                      rows={4}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="saleUserId"
                      label="เซลล์ผู้ดูแล"
                      defaultValue={saleUser?.id}
                      select
                    >
                      {userData?.map((u) => (
                        <MenuItem key={u.id} value={u.id}>
                          <div>
                            {u.firstName} {u.lastName} {u.nickName ? `(${u.nickName})` : ''}
                          </div>
                        </MenuItem>
                      ))}
                    </GeneralTextFieldForm>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box mb={2}>
              <SubmitForm
                submitText="บันทึกการแก้ไข"
                cancelText="ยกเลิก"
                onCancel={() => {
                  onClose && onClose({}, 'escapeKeyDown')
                  reset()
                }}
                isSubmitting={isSubmitting || isInvalid}
              />
            </Box>
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

const customerTypes = [
  { id: 1, name: 'นิติบุคคล' },
  { id: 2, name: 'บุคคลทั่วไป' },
]

export default CustomerInformationDialog
