import { Grid, Paper } from '@material-ui/core'
import React, { useContext } from 'react'
import { EmptyCard, SubTopic } from '../../../../../../custom-components'
import CustomerProfileContext from '../../CustomerProfileContext'
import { CustomerBankAccountCard } from './CustomerBankAccountCard'

const CustomerBankAccountBox: React.FC = () => {
  const { customerProfile } = useContext(CustomerProfileContext)
  const { customerBankAccounts } = customerProfile

  return (
    <>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SubTopic>บัญชีธนาคาร</SubTopic>
          </Grid>
          {customerBankAccounts && customerBankAccounts.length > 0 ? (
            <Grid container spacing={2}>
              {customerBankAccounts.map((bankAccount) => (
                <Grid item xs={12} key={bankAccount.id}>
                  <CustomerBankAccountCard bankAccount={bankAccount} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <EmptyCard emptyText="ยังไม่มีบัญชีธนาคาร" />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerBankAccountBox
