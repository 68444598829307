import React, { useContext, useState } from 'react'
import { Paper, Grid, Card, Typography, Button, Divider, Chip } from '@material-ui/core'
import {
  AttachMoney,
  Edit,
  // ContactSupportOutlined
} from '@material-ui/icons'
import { FlexBox, Link, SubTopic, TypographyWithLabel } from '../../../../../custom-components'
import {
  Loading,
  MultipleConditionTooltip,
  PaymentTransactionStatusChip,
  SOPaymentTransactionDialog,
  SOPaymentUpdateDocumentDialog,
  StatementVerifiedSignal,
  StatementVerifyingSignal,
} from '../../../../../shared-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { SaleOrderStatus, DocumentStatus, SOPaymentTransactionStatus } from '../../../../../enums'
import numeral from 'numeral'
import _ from 'lodash'
import { DocumentState, SaleOrderDocument, SOPaymentTransaction } from '../../../../../api/smartbestbuys-api'
import { SOPaymentFormDialog } from '..'
import { roundNumber } from '../../../../../functions/number-utils'
import { DocumentType } from '../../../../../enums'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'
import SOPaymentTransactionConfirmWithholdingDocumentDialog from '../../../payment-transactions/SOPaymentTransactionsPage/SOPaymentTransactionConfirmWithholdingDocumentDialog'
import { WithholdingTaxDocumentReceivedToggle } from '../../../../../shared-components/Toggle'

const SOPaymentTransactions: React.FC = () => {
  const [makePayment, setMakePayment] = useState(false)

  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  if (!saleOrderProfile) return <Loading />

  const { paymentTransactions, total, documents, status } = saleOrderProfile

  const isCancelledSO = status.id === SaleOrderStatus.Cancelled

  const hasPaymentTransaction = paymentTransactions && paymentTransactions.length > 0
  let pendingAmount = 0
  let remainingAmount = total
  if (paymentTransactions) {
    pendingAmount = _.sum(
      paymentTransactions.filter((pt) => pt.status.id === SOPaymentTransactionStatus.PENDING).map((pt) => pt.amount),
    )
    const validAmount = paymentTransactions.reduce<number>((sum, each) => {
      if ([SOPaymentTransactionStatus.PENDING, SOPaymentTransactionStatus.APPROVED].includes(each.status.id)) {
        return sum + each.amount
      } else {
        return sum
      }
    }, 0)
    remainingAmount = total - roundNumber(validAmount)
  }

  const isShowCreatePaymentButton = !isCancelledSO

  // permission
  const writeSOPaymentPermission = getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Write)

  // tooltip condition
  const createSOPaymentConditions = [
    { showTooltip: !writeSOPaymentPermission.hasPermission, message: writeSOPaymentPermission.alertMessage },
    { showTooltip: pendingAmount > 0, message: 'มีรายการชำระที่รออนุมัติอยู่' },
    {
      showTooltip: remainingAmount <= 0,
      message: 'บันทึกการชำระเงินครบแล้ว',
    },
  ]

  return (
    <Paper>
      <SOPaymentFormDialog open={makePayment} onClose={() => setMakePayment(false)} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SubTopic>ข้อมูลการชำระเงิน</SubTopic>
        </Grid>
        {isShowCreatePaymentButton && (
          <Grid item xs={12} md={6}>
            <FlexBox justifyContent="flex-end" alignItems="center" gridGap={8}>
              <MultipleConditionTooltip conditionItems={createSOPaymentConditions}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setMakePayment(true)}
                  startIcon={<AttachMoney />}
                  disabled={createSOPaymentConditions.some((cond) => cond.showTooltip)}
                >
                  ชำระเงิน
                </Button>
              </MultipleConditionTooltip>
            </FlexBox>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TypographyWithLabel label="ยอดเต็ม:">{numeral(total).format('0,0.00')}</TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={4}>
              <TypographyWithLabel label="ยอดรออนุมัติ:">{numeral(pendingAmount).format('0,0.00')}</TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={4}>
              <TypographyWithLabel label="ยอดค้างชำระ:">
                {numeral(remainingAmount).format('0,0.00')}
              </TypographyWithLabel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container={hasPaymentTransaction} spacing={2}>
            {hasPaymentTransaction ? (
              paymentTransactions?.map((transaction) => (
                <Grid item xs={12} key={transaction.id}>
                  <PaymentTransactionCard
                    transaction={transaction}
                    documents={documents}
                    saleOrderId={saleOrderProfile.id}
                    saleOrderTotal={total || 0}
                  />
                </Grid>
              ))
            ) : (
              <FlexBox justifyContent="center" alignItems="center" width="100%" height={100} clone>
                <Card variant="outlined">
                  <Typography variant="body2" color="textSecondary">
                    ยังไม่มีข้อมูลการชำระเงิน
                  </Typography>
                </Card>
              </FlexBox>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SOPaymentTransactions

interface CardProps {
  transaction: SOPaymentTransaction
  documents?: SaleOrderDocument<DocumentState>[]
  saleOrderTotal: number
  saleOrderId: number
}

const PaymentTransactionCard: React.FC<CardProps> = (props) => {
  const { reload } = useContext(SaleOrderProfileContext)
  const [openSOPaymentTransaction, setOpenSOPaymentTransaction] = useState(false)
  const [selectedConfirmWithholdingDocumentReceived, setSelectedConfirmWithholdingDocumentReceived] = useState<
    { saleOrderId: number; transactionId: number; code: string } | undefined
  >(undefined)
  const toggleDetailDialog = () => setOpenSOPaymentTransaction((prev) => !prev)

  const [openPaymentRefDocument, setOpenPaymentRefDocument] = useState(false)

  const { transaction, saleOrderTotal, saleOrderId, documents } = props
  const {
    code,
    amount,
    paymentAdjustment,
    withholdingAmount = 0,
    withholdingTaxDocumentReceived = false,
    status,
    statementVerifiedAt,
  } = transaction
  const { amount: paymentAdjustmentAmount = 0 } = { ...paymentAdjustment }

  const actual = amount - paymentAdjustmentAmount - withholdingAmount

  const referenceDocumentIds =
    documents
      ?.filter((d) => d.paymentTransaction?.id === transaction.id && d.status.id !== DocumentStatus.Cancelled)
      .map((d) => d.id) ?? []
  const hasReferenceDocument = !!documents && referenceDocumentIds.length > 0

  const writePermission = getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Write)

  const isShowToggle =
    writePermission.hasPermission &&
    !!withholdingAmount &&
    ![SOPaymentTransactionStatus.CANCELLED, SOPaymentTransactionStatus.DECLINED].includes(status.id)

  return (
    <Card>
      <SOPaymentTransactionDialog
        open={openSOPaymentTransaction}
        isFromSaleOrderProfile={true}
        onClose={toggleDetailDialog}
        transaction={transaction}
        documents={documents}
        saleOrderId={saleOrderId}
        total={saleOrderTotal}
        onConfirm={() => {
          reload()
          toggleDetailDialog()
        }}
      />
      <SOPaymentUpdateDocumentDialog
        open={openPaymentRefDocument}
        onClose={() => setOpenPaymentRefDocument(false)}
        saleOrderId={saleOrderId}
        transaction={transaction}
        documents={documents}
        defaultDocumentIds={referenceDocumentIds}
        onConfirm={() => {
          reload()
          setOpenPaymentRefDocument(false)
        }}
      />
      {selectedConfirmWithholdingDocumentReceived && (
        <SOPaymentTransactionConfirmWithholdingDocumentDialog
          parameter={selectedConfirmWithholdingDocumentReceived}
          open={!!selectedConfirmWithholdingDocumentReceived}
          onSuccess={() => {
            setSelectedConfirmWithholdingDocumentReceived(undefined)
            reload()
          }}
          onClose={() => {
            setSelectedConfirmWithholdingDocumentReceived(undefined)
          }}
        />
      )}
      <Grid container direction="row-reverse" spacing={2}>
        <Grid item xs={12} md={6}>
          <FlexBox justifyContent="flex-end">
            {transaction.status.id === SOPaymentTransactionStatus.PENDING && (
              <Button
                startIcon={<Edit />}
                color="secondary"
                variant="contained"
                onClick={() => setOpenPaymentRefDocument(true)}
              >
                แก้ไขเอกสารที่เกี่ยวข้อง
              </Button>
            )}
            <Button color="primary" onClick={toggleDetailDialog}>
              ดูรายละเอียด
            </Button>
          </FlexBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <FlexBox gridGap={16} alignItems="center">
            <SubTopic>{code}</SubTopic>
            <PaymentTransactionStatusChip status={status.id} label={status.name} />
            {status.id === SOPaymentTransactionStatus.APPROVED &&
              (!!statementVerifiedAt ? <StatementVerifiedSignal /> : <StatementVerifyingSignal />)}

            {isShowToggle && (
              <WithholdingTaxDocumentReceivedToggle
                isActive={withholdingTaxDocumentReceived}
                disabled={withholdingTaxDocumentReceived}
                onClick={() =>
                  setSelectedConfirmWithholdingDocumentReceived({
                    saleOrderId,
                    transactionId: transaction.id,
                    code,
                  })
                }
              />
            )}
          </FlexBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="ยอดที่ได้รับจริง">{numeral(actual).format('0,0.00')}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="ยอดชำระ">{numeral(amount).format('0,0.00')}</TypographyWithLabel>
        </Grid>
        {hasReferenceDocument && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FlexBox alignItems="center" gridGap={8} flexWrap>
                <Typography variant="body2">เอกสารที่เกี่ยวข้อง: </Typography>
                {documents
                  ?.filter((d) => d.paymentTransaction?.id === transaction.id)
                  .map((d) => {
                    let path = ''

                    switch (d.type.id) {
                      case DocumentType.Invoice:
                        path = 'invoices'
                        break
                      case DocumentType.TaxInvoice:
                        path = 'tax-invoices'
                        break
                      case DocumentType.Receipts:
                        path = 'receipts'
                        break
                    }

                    return (
                      <Chip
                        size="small"
                        label={
                          <Link href={`/${path}/${d.id}/pdf`} target="_blank">
                            {d.type.name} {d.code}
                          </Link>
                        }
                      />
                    )
                  })}
              </FlexBox>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  )
}
