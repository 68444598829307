import { TextField, TextFieldProps } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import { RegisterOptions, useController } from 'react-hook-form'

export interface IGeneralAutoComplete {
  label: string
  value: number | string
}

type Props = AutocompleteProps<IGeneralAutoComplete, undefined, undefined, undefined> &
  TextFieldProps & {
    control?: any
    rules?: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    onPostChange?: (value: string | number) => void
  }

const GeneralAutoComplete: React.FC<Props> = (props) => {
  const { control, rules, name, onPostChange, ...textFieldProps } = props
  const {
    field: { ref, onChange, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    formState: { errors },
  } = useController({
    name: name as any,
    control,
    rules,
  })

  const error = traverseFormErrors(errors, name?.split('.'))

  return (
    <Autocomplete
      {...textFieldProps}
      getOptionLabel={(option) => option.label}
      onChange={(_, target) => {
        if (!target) return
        onChange(target.value)
        onPostChange && onPostChange(target.value)
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          color="primary"
          margin="dense"
          label={props.label}
          error={!!error}
          helperText={error?.message}
          {...inputProps}
          {...params}
          fullWidth
          size="small"
        />
      )}
    />
  )
}

const traverseFormErrors = (errors: any, name?: string[]): any => {
  if (!errors || !name) return null
  const [head, ...tail] = name
  if (!head) return null
  const value = errors[head]
  if (tail.length === 0) return value
  return traverseFormErrors(value, tail)
}

export default GeneralAutoComplete
