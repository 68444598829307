export interface GenderInfo {
  id: number
  name: string
  value: string
}

export const genderInformation: GenderInfo[] = [
  { id: 1, name: 'ชาย', value: 'M' },
  { id: 2, name: 'หญิง', value: 'F' },
]

export const getGenderByValue = (value?: string): string => {
  const genderValue = genderInformation.find((el) => el.value === value)?.name
  return genderValue ? genderValue : 'unknown'
}
