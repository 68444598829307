import { useState, useEffect } from 'react'
import { fetchPurchaseTasks, FetchTaskRequest, FollowServiceTask_TableData } from '../../api/smartbestbuys-api'
import { TaskType } from '../../enums'

export const usePurchaseTasks = (
  startDate: Date,
  endDate: Date,
  typeId: TaskType,
  reload?: boolean,
): FollowServiceTask_TableData[] => {
  const [data, setData] = useState<FollowServiceTask_TableData[]>([])

  const _fetchPurchaseTasks = async (request: FetchTaskRequest) => {
    const response = await fetchPurchaseTasks(request)
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchPurchaseTasks({ startDate, endDate, typeId })
  }, [startDate, endDate, typeId, reload])

  return data
}
