import React, { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Paper, Box, Switch, Button, BoxProps, InputAdornment, Collapse } from '@material-ui/core'
import _ from 'lodash'
import { useQuotationsDiscountBoxStyle } from '../QuotationCreationPage/quotation-presentation/useQuotationsDiscountBoxStyle'
import { QuotationUpdationFormValue } from './QuotationUpdationFormValue'
import { SubTopic, TextFieldForm } from '../../../custom-components'
import { QuotationUpdationPageContextImpl } from './QuotationUpdationPage'

const discountOptions = [5, 10, 15]

const QuotationUpdationDiscountBox: React.FC = (props) => {
  const { data } = useContext(QuotationUpdationPageContextImpl)
  const { control, watch, setValue } = useFormContext<QuotationUpdationFormValue>()

  useEffect(() => {
    setValue('discountEnabled', data.profile.discountAmount !== undefined && data.profile.discountAmount != null)
    setValue('discount', data.profile.discountAmount)
  }, [data.profile.discountAmount, setValue])

  const { discountEnabled, selectedProducts } = watch()

  const total =
    !!selectedProducts && selectedProducts.length > 0 ? _.sum(selectedProducts.map((p) => p.amount * p.price)) : 0

  const classes = useQuotationsDiscountBoxStyle()

  return (
    <Box display="flex" flexDirection="column" padding={2}>
      {(props: BoxProps) => (
        <Paper className={props.className}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <SubTopic>ส่วนลดสินค้า</SubTopic>
            <Switch checked={!!discountEnabled} onClick={() => setValue('discountEnabled', !discountEnabled)} />
          </Box>
          <Collapse in={!!discountEnabled}>
            <Box mb={2}>
              <TextFieldForm
                control={control}
                name="discount"
                label="ส่วนลด"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                }}
                inputProps={{
                  step: '0.1',
                }}
                type="number"
              />
            </Box>
            <Box>
              {discountOptions.map((d) => (
                <Button
                  key={d}
                  className={classes.percentButton}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => setValue('discount', (total * d) / 100)}
                >
                  {d}%
                </Button>
              ))}
            </Box>
          </Collapse>
        </Paper>
      )}
    </Box>
  )
}

export default QuotationUpdationDiscountBox
