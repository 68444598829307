import { useState, useEffect } from 'react'
import { getUpdatePurchaseOrder, PurchaseOrderUpdateData } from '../../api/smartbestbuys-api'

export const usePurchaseOrderUpdateData = (purchaseOrderId: number): PurchaseOrderUpdateData | undefined => {
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderUpdateData | undefined>()

  const _getUpdatePurchaseOrder = async (purchaseOrderId: number) => {
    const response = await getUpdatePurchaseOrder(purchaseOrderId)
    setPurchaseOrder(response?.data)
  }

  useEffect(() => {
    _getUpdatePurchaseOrder(purchaseOrderId)
  }, [purchaseOrderId])

  return purchaseOrder
}
