import { useState, useEffect } from 'react'
import { fetchQuotationsUpdateData, QuotationUpdateDataResponse } from '../../api/smartbestbuys-api'

export const useQuotationsUpdateData = (id: number, reload?: boolean): QuotationUpdateDataResponse | undefined => {
  const [data, setData] = useState<QuotationUpdateDataResponse | undefined>(undefined)

  const _quotationsCreateData = async (id: number) => {
    const response = await fetchQuotationsUpdateData(id)
    setData(response?.data)
  }

  useEffect(() => {
    _quotationsCreateData(id)
  }, [id, reload])

  return data
}
