import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.background,
      border: `1px solid ${theme.palette.primary.border}`,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
      border: `1px solid ${theme.palette.custom?.stroke}`,
      color: theme.palette.text.disabled,
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.border}`,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  }),
)

export const groupedStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.background,
      border: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
      border: 'none',
      color: theme.palette.text.disabled,
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      border: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  }),
)

export const groupedSecondaryStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      borderColor: theme.palette.secondary.dark,
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.text.disabled,
    },
    active: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }),
)
