import { Box, Grid, IconButton, Paper } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useState } from 'react'
import { VendorProfile_Address } from '../../../../api/smartbestbuys-api'
import { EmptyCard, FlexBox, Title, TypographyWithLabel } from '../../../../custom-components'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { getAddressText } from '../../../../functions'
import { ConditionTooltip, Space } from '../../../../shared-components'
import { getUserPermissionInfo } from '../../../../utils/permission'
import VendorAddressFormDialog from './VendorAddressFormDialog'

interface Props {
  vendorId: number
  address?: VendorProfile_Address
  onUpdate?: () => void
}

const VendorAddressBox = (props: Props) => {
  const { vendorId, address, onUpdate } = props

  // state
  const [dialogOpen, setDialogOpen] = useState(false)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_Vendor_Write)

  return (
    <>
      <VendorAddressFormDialog
        open={dialogOpen}
        vendorId={vendorId}
        onClose={() => setDialogOpen(false)}
        onComplete={() => {
          if (!!onUpdate) onUpdate()
          setDialogOpen(false)
        }}
      />
      <Box p={3}>
        <FlexBox justifyContent="space-between">
          <Title>ข้อมูลที่อยู่</Title>
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <IconButton onClick={() => setDialogOpen(true)} disabled={!writePermission.hasPermission}>
              <Edit color={writePermission.hasPermission ? 'secondary' : 'disabled'} />
            </IconButton>
          </ConditionTooltip>
        </FlexBox>
        <Space />
        {address ? (
          <Paper variant="outlined">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TypographyWithLabel label="รายละเอียดที่อยู่">{getAddressText(address)}</TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="เบอร์โทร">{address.phoneNumber}</TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="เบอร์แฟกซ์">{address.faxNumber ?? '-'}</TypographyWithLabel>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <EmptyCard>ไม่มีข้อมูลที่อยู่</EmptyCard>
        )}
      </Box>
    </>
  )
}

export default VendorAddressBox
