import { Grid } from '@material-ui/core'
import { DateTime } from 'luxon'
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { Topic } from '../../../../custom-components'
import { CustomerProfileCard, SubmitForm } from '../../../../shared-components'
import { QuotationsTaxInvoiceInformation } from '../../QuotationCommon'
import QuotationSelectedProduct from '../quotation-presentation/QuotationSelectedProduct'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import { QuotationsCreationPageContextImpl } from '../QuotationsCreationPage'

interface QuotationsSummarizeFormProps {
  onPreviousPage: () => void
  onSubmit: () => void
}

const QuotationsSummarizeForm: React.FC<QuotationsSummarizeFormProps> = ({ onPreviousPage, onSubmit }) => {
  const { contactId, customerProfile, users } = useContext(QuotationsCreationPageContextImpl)

  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<QuotationsCreateValue>()

  const {
    billingAddressId,
    contactChannelId,
    paymentMethodId,
    currencyId,
    exchangeRate,
    periodDay,
    assigneeUserId,
    remark,
    dueDate,
  } = watch()
  const expiredDate = DateTime.now().plus({ day: periodDay }).toFormat('dd LLL yyyy', { locale: 'th' })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>ตรวจทานใบเสนอราคา</Topic>
      </Grid>
      <Grid item xs={12}>
        <CustomerProfileCard
          contactId={Number(contactId)}
          customerProfile={customerProfile}
          billingAddressId={billingAddressId}
          disableBtnCustomerDetail
        />
      </Grid>
      <Grid item xs={12}>
        <QuotationSelectedProduct readOnly showPriceBreakdown />
      </Grid>
      <Grid item xs={12}>
        <QuotationsTaxInvoiceInformation
          contactChannelId={contactChannelId}
          paymentMethodId={paymentMethodId}
          currencyId={currencyId}
          exchangeRate={exchangeRate}
          expiredDate={expiredDate}
          remark={remark}
          assigneeUserId={assigneeUserId}
          users={users}
          dueDate={dueDate}
        />
      </Grid>
      <Grid item xs={12}>
        <SubmitForm
          submitText="ยืนยันสร้างใบเสนอราคา"
          cancelText="ย้อนกลับไปแก้ไขข้อมูล"
          isSubmitting={isSubmitting}
          onCancel={onPreviousPage}
          onSubmit={() => {
            onSubmit()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default QuotationsSummarizeForm
