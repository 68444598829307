import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Dialog, DialogTitle, DialogContent, Grid, DialogProps, DialogActions } from '@material-ui/core'
import { Form, GeneralTextFieldForm } from '../../../custom-components'
import { SubmitWithConfirmTextForm } from '../../../shared-components'
import {
  PaymentRequisitionProfile,
  PaymentRequisitionUpdateExchangeRateRequestBody,
} from '../../../api/smartbestbuys-api/payment-requisitions/types'
import { updatePaymentRequisitionExchangeRate } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import { formatNumber } from '../../../functions/number-utils'

interface UpdateExchangeRateFormValues {
  newExchangeRate: number
}

interface UpdateExchangeRateDialogProps extends DialogProps {
  paymentRequisitionId: number
  oldExchangeRate?: number
  onSuccess?: (paymentRequisitionProfile: PaymentRequisitionProfile) => void
  onFailed?: () => void
}

const UpdateExchangeRateDialog = (props: UpdateExchangeRateDialogProps) => {
  // root context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { paymentRequisitionId, oldExchangeRate, onSuccess, onFailed, onClose, ...otherProps } = props

  // form hook
  const formMethod = useForm<UpdateExchangeRateFormValues>()
  const {
    control,
    formState: { errors, isSubmitting },
  } = formMethod

  // handle
  const handleSubmit = async (values: UpdateExchangeRateFormValues) => {
    const request: PaymentRequisitionUpdateExchangeRateRequestBody = {
      exchangeRate: values.newExchangeRate,
    }
    const response = await updatePaymentRequisitionExchangeRate(paymentRequisitionId, request)
    if (response?.status === 200) {
      triggerSnackbar('แก้ไขอัตราแลกเปลี่ยนสำเร็จ')
      if (!!onSuccess) onSuccess(response.data)
    } else {
      triggerSnackbar('แก้ไขอัตราแลกเปลี่ยนไม่สำเร็จ')
      if (!!onFailed) onFailed()
    }
  }

  return (
    <FormProvider {...formMethod}>
      <Dialog {...otherProps} fullWidth maxWidth="sm">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>แก้ไขอัตราแลกเปลี่ยน</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GeneralTextFieldForm
                  control={control}
                  name="newExchangeRate"
                  label="อัตราแลกเปลี่ยนใหม่ *"
                  rules={{
                    required: 'กรุณาอัตราแลกเปลี่ยนใหม่',
                  }}
                  error={!!errors.newExchangeRate}
                  helperText={
                    !!errors.newExchangeRate
                      ? errors.newExchangeRate.message
                      : !!oldExchangeRate
                      ? `อัตราแลกเปลี่ยนเดิม ${formatNumber(oldExchangeRate)}`
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitWithConfirmTextForm
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
              submitText="ยืนยันการแก้ไข"
            />
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  )
}
export default UpdateExchangeRateDialog
