import { Link, TypographyWithLabel } from '../../../custom-components'
import { Typography, Card, Grid, Avatar, Divider, Box } from '@material-ui/core'
import { Space } from '../../../shared-components'
import { FlexBox } from '../../../custom-components'
import './UserCard.less'
import { User_TableData } from '../../../api/smartbestbuys-api'

interface Props {
  user: User_TableData
}

const UserCard: React.FC<Props> = ({ user }) => {
  return (
    <Grid item xs={3}>
      <Card>
        <Box position="relative">
          {user.enabled === false && (
            <FlexBox position="absolute" right={0} zIndex={1}>
              <Typography variant="body2" color="textSecondary">
                ปิดใช้งานอยู่
              </Typography>
            </FlexBox>
          )}

          {user.enabled === true ? (
            <FlexBox justifyContent="center">
              <Avatar
                alt={user.firstName.toUpperCase()}
                src={user.imageUrl ?? 'Link'}
                style={{ backgroundColor: 'purple' }}
              ></Avatar>
            </FlexBox>
          ) : (
            <FlexBox justifyContent="center">
              <Avatar alt={user.firstName}></Avatar>
            </FlexBox>
          )}

          <Space />
          <FlexBox justifyContent="center">
            <Link to={`/users/${user.id}/profile`} variant="h6">
              {user.nickName ?? user.firstName}
            </Link>
          </FlexBox>
          <FlexBox justifyContent="center">
            <Typography variant="body1" color="textSecondary">
              {user.firstName} {user.lastName}
            </Typography>
          </FlexBox>

          <Space />
          <Divider />
          <Space />
          <TypographyWithLabel label="ชื่อผู้ใช้งาน:">{user.username}</TypographyWithLabel>
          <Space />
          <TypographyWithLabel label="เบอร์">{user.phoneNumber}</TypographyWithLabel>
          <Space />
          <TypographyWithLabel label="อีเมล">{user.email}</TypographyWithLabel>
        </Box>
      </Card>
    </Grid>
  )
}

export default UserCard
