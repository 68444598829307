import { errorGenericHandle, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { UserActivityNote } from '../types'
import { UpdatedTaskAssignee, UpdateTaskAssigneeRequest, UpdateAssigneeURLParams } from './task-types'
import { Task } from './types'

export const updateTaskAssigneeById = errorGenericHandle<UpdatedTaskAssignee, UpdateAssigneeURLParams>(
  (params: UpdateAssigneeURLParams, request: UpdateTaskAssigneeRequest) => {
    const path = `/tasks/v1/${params.id}/update-assignee`

    return smartAuthAPI.put(path, request, {
      params: {
        typeId: params.typeId,
      },
    })
  },
)

export const noteTaskAsCancelAPI = errorSingleHandler<Task<any>>((id: number, requestBody?: UserActivityNote) => {
  return smartAuthAPI.post(`/tasks/v1/${id}/note-as-cancel`, requestBody)
})
