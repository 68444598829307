import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { useContext, useState } from 'react'
import { RootContext } from '../..'
import {
  DocumentState,
  SaleOrderDocument,
  SOPaymentTransaction,
  updateReferenceDocumentsAPI,
} from '../../api/smartbestbuys-api'
import { FlexBox } from '../../custom-components'
import { DocumentStatus } from '../../enums'

interface Props extends DialogProps {
  saleOrderId: number
  transaction: SOPaymentTransaction
  documents?: SaleOrderDocument<DocumentState>[]
  defaultDocumentIds?: number[]
  onConfirm?: () => void
}

const SOPaymentUpdateDocumentDialog: React.FC<Props> = (props) => {
  const { saleOrderId, transaction, documents, defaultDocumentIds, onConfirm, onClose, ...otherDialogProps } = props
  const { triggerSnackbar } = useContext(RootContext)

  // state
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<number[]>(defaultDocumentIds ?? [])
  const [submitting, setSubmiiting] = useState(false)

  const selectableDocuments = documents?.filter(
    (d) =>
      d.status.id === DocumentStatus.Pending &&
      (!d.paymentTransaction?.id || d.paymentTransaction.id === transaction.id),
  )

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDocumentIds(event.target.value as number[])
  }

  const handleUpdateRefDocument = async () => {
    setSubmiiting(true)
    const response = await updateReferenceDocumentsAPI(
      { id: saleOrderId, transactionId: transaction.id },
      { refDocumentIds: selectedDocumentIds },
    )

    if (response?.status === 200) {
      onConfirm && onConfirm()
      triggerSnackbar('แก้ไขเอกสารที่เกี่ยวข้องสำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
      setSubmiiting(false)
    } else {
      triggerSnackbar('แก้ไขเอกสารที่เกี่ยวข้องไม่สำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
      setSubmiiting(false)
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={() => onClose && onClose({}, 'escapeKeyDown')} {...otherDialogProps}>
      <DialogTitle>แก้ไขเอกสารที่เกี่ยวข้อง</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel style={{ backgroundColor: 'white' }}>เอกสารที่เกี่ยวข้อง</InputLabel>
            <Select
              multiple
              onChange={handleChange}
              value={selectedDocumentIds}
              renderValue={(selected) => (
                <FlexBox gridGap={4} flexWrap="wrap">
                  {documents
                    ?.filter((d) => (selected as number[]).includes(d.id))
                    .map((d) => (
                      <Chip key={d.id} label={`${d.type.name} ${d.code}`} size="small" />
                    ))}
                </FlexBox>
              )}
            >
              {!!selectableDocuments && selectableDocuments.length > 0 ? (
                selectableDocuments.map((d) => (
                  <MenuItem key={d.id} value={d.id}>
                    <Checkbox checked={selectedDocumentIds.indexOf(d.id) > -1} color="primary" />
                    <ListItemText primary={`${d.type.name} ${d.code}`} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem key="no-document" disabled>
                  <Typography variant="body2" color="textSecondary">
                    ไม่มีเอกสารที่สามารถเลือกได้
                  </Typography>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')} variant="outlined">
          ย้อนกลับ
        </Button>
        <Button
          color="primary"
          onClick={handleUpdateRefDocument}
          variant="contained"
          disabled={submitting}
          startIcon={submitting ? <CircularProgress /> : undefined}
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SOPaymentUpdateDocumentDialog
