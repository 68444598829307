import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { DialogProps, Dialog, DialogTitle, DialogContent, DialogActions, Typography, MenuItem } from '@material-ui/core'
import { SubmitForm } from '../SubmitForm'
import { FlexBox, Form, GeneralTextFieldForm } from '../../custom-components'
import { useActionMessages } from '../../hooks'
import { UserAction } from '../../enums'
import { BaseData, cancelDocument, UpdatedDocumentStatus } from '../../api/smartbestbuys-api'
import { RootContext } from '../..'

export interface DocumentCancellationFormValues {
  actionMessageId?: number
  actionMessage?: string
}

interface DocumentProps {
  id: number
  code: string
  type: BaseData
}

interface Props extends DialogProps {
  documentInfo: DocumentProps
  onSuccess?: (updated: UpdatedDocumentStatus) => void
  onFail?: () => void
  messages?: BaseData[]
}

const DocumentCancellationDialog: React.FC<Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { documentInfo, onSuccess, onClose, onFail, messages: inputMessages, ...otherProps } = props
  const { id, code, type } = documentInfo
  const messages = useActionMessages(UserAction.CancelDocument, !!inputMessages)
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = useForm<DocumentCancellationFormValues>()

  const { actionMessageId } = watch()

  const handleCancellationForm = async (data: DocumentCancellationFormValues) => {
    const response = await cancelDocument(id, data)
    if (response?.status === 200) {
      onSuccess && onSuccess(response.data)
      onClose && onClose({}, 'escapeKeyDown')
      if (!onSuccess) triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
    } else {
      if (onFail) {
        onFail()
      } else {
        triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
      }
    }
    return true
  }

  const title = 'ยกเลิก' + type.name
  const description = `ยกเลิก${type.name}หมายเลข ${code} นี้ใช่หรือไม่?`
  return (
    <Dialog fullWidth maxWidth="sm" {...otherProps}>
      <Form onSubmit={handleSubmit(handleCancellationForm)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FlexBox flexDirection="column" gridGap={8}>
            <Typography variant="body1" color="textSecondary">
              {description}
            </Typography>
            <GeneralTextFieldForm
              control={control}
              name="actionMessageId"
              select
              label="สาเหตุ *"
              rules={{ required: { value: true, message: 'กรุณาเลือกสาเหตุ' } }}
              variant="outlined"
              defaultValue=""
            >
              {(inputMessages ?? messages).map((message) => (
                <MenuItem key={message.id} value={message.id}>
                  {message.name}
                </MenuItem>
              ))}
              <MenuItem value={-1}>
                <Typography color="textSecondary">เพิ่มสาเหตุใหม่</Typography>
              </MenuItem>
            </GeneralTextFieldForm>
            {actionMessageId === -1 && (
              <GeneralTextFieldForm
                control={control}
                label="เหตุผลใหม่ *"
                name="actionMessage"
                rules={{ required: { value: true, message: 'กรุณาใส่เหตุผลใหม่' } }}
              />
            )}
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText={`ยืนยันยกเลิก${type.name}`}
            onCancel={() => {
              onClose && onClose({}, 'escapeKeyDown')
              reset()
            }}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default DocumentCancellationDialog
