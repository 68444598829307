import React, { useContext } from 'react'
import { Grid, Button } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, Link, FlexBox } from '../../custom-components'
import { useProducts } from '../../hooks/useProducts'
import { ProductDataRow } from '../../api/smartbestbuys-api'
import { Avatar } from '../../custom-components'
import { Photo } from '@material-ui/icons'
import { Typography, Box } from '@material-ui/core'
import { RootContext } from '../..'
import { PageName } from '../../enums'
import { ConditionTooltip /* NoProductStorageAlertSignal */ } from '../../shared-components'
import { getUserPermissionInfo } from '../../utils/permission'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { useHistory } from 'react-router-dom'

const ProductsPage: React.FC = (props) => {
  const history = useHistory()

  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PRODUCT)

  const products = useProducts()

  const writePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Write)

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการสินค้า</Topic>
            <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push('/products/create')}
                disabled={!writePermission.hasPermission}
              >
                สร้างสินค้า
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>

        <Grid item xs={12}>
          <FilteredTable<ProductDataRow>
            disableDateRangeFilter
            color="primary.dark"
            columns={[
              // {
              //   name: '',
              //   render: ({ storage }) => !storage && <NoProductStorageAlertSignal />,
              // },
              {
                name: 'รายละเอียดสินค้า',
                dataIndex: 'nameLocal',
                render: ({ imageUrl, nameLocal, id, code }) => (
                  <Box display="flex" alignItems="flex-start">
                    <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                      <Photo />
                    </Avatar>
                    {
                      <Box ml={1}>
                        <div>
                          <Link color="primary" to={`/products/${id}/profile`}>
                            {code}
                          </Link>
                        </div>
                        <Typography variant="body2">{nameLocal}</Typography>
                      </Box>
                    }
                  </Box>
                ),
                fixedWidth: 300,
              },
              {
                name: 'หน่วย',
                dataIndex: 'unitLocal',
                fixedWidth: 70,
                sort: true,
              },
              {
                name: 'กลุ่มสินค้า',
                dataIndex: 'grade',
                fixedWidth: 70,
                sort: true,
              },
              {
                name: 'จำนวนสินค้าคงเหลือในสต็อก',
                dataIndex: 'stockAmount',
                render: ({ stockAmount }) => {
                  return Math.max(stockAmount, 0)
                },
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนสินค้าที่กำลังเข้า',
                render: ({ incomingAmount }) => {
                  return Math.max(incomingAmount, 0)
                },
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนสินค้าที่ถูก SO จอง',
                render: ({ soReservedAmount }) => {
                  return Math.max(soReservedAmount, 0)
                },
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนสินค้าที่กำลังส่ง',
                render: ({ reservedAmount }) => {
                  return Math.max(reservedAmount, 0)
                },
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนสินค้าคงเหลือที่หักการจอง',
                render: ({ stockAmount, soReservedAmount }) => {
                  const afterReservedAmount = stockAmount - soReservedAmount
                  return Math.max(afterReservedAmount, 0)
                },
                numeric: true,
                format: '0,0',
              },
              {
                name: 'จำนวนสินค้าที่พร้อมขาย',
                render: ({ stockAmount, reservedAmount }) => {
                  const readyToSellAmount = stockAmount - reservedAmount
                  return Math.max(readyToSellAmount, 0)
                },
                numeric: true,
                format: '0,0',
              },
            ]}
            data={products}
            size="small"
            sortable
            filters={[
              {
                name: 'กลุ่มสินค้า',
                values: [
                  {
                    name: 'A',
                    value: 'A',
                  },
                  {
                    name: 'B',
                    value: 'B',
                  },
                  {
                    name: 'C',
                    value: 'C',
                  },
                  {
                    name: 'ไม่มีกลุ่ม',
                    value: 'X',
                  },
                ],
                filter: (data: ProductDataRow, values: (string | number)[]) => {
                  const grade = data.grade ?? 'X'
                  return values.includes(grade)
                },
              },
            ]}
            cleanSpace
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(ProductsPage)
