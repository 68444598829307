import { PropsWithChildren, createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useProductWithdrawalTicketProfile } from '../../../../hooks'
import { IProductWithdrawalTicketProfile } from '../../../../api/smartbestbuys-api/warehouse'
import { IPWTicketProfilePageSetting, PageState, defaultPageSettings, usePWTicketProfilePageSetting } from '../../..'

// interfaces
interface PWTicketProfilePageParams {
  id: string
}

interface ContextState {
  profile: IProductWithdrawalTicketProfile | undefined
  pageSetting: IPWTicketProfilePageSetting
  isLoading: boolean
  updateData: (data: IProductWithdrawalTicketProfile) => void
}

// context
const Context = createContext<ContextState & PageState>({
  profile: undefined,
  pageSetting: defaultPageSettings,
  isLoading: true,
  updateData: () => {},
})

const PWTicketProfilePageContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { id } = useParams<PWTicketProfilePageParams>()

  const { data, isLoading, updateData } = useProductWithdrawalTicketProfile(Number(id))

  const pageSetting = usePWTicketProfilePageSetting(data)

  return (
    <Context.Provider
      value={{
        profile: data,
        pageSetting,
        isLoading: isLoading,
        updateData,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const usePWTickProfileContext = () => useContext(Context)

export default PWTicketProfilePageContextProvider
