import { smartAPI } from './smartbestbuys-api'
import { errorHandler } from '../middleware'

export interface LoginRequest {
  username: string
  password: string
}

export interface UserLogin {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
}

export interface UserPermission {
  hr: {
    user: {
      read: boolean
      write: boolean
      delete: boolean
    }
  }
  warehouse: {
    product: {
      read: boolean
      report: boolean
      write: boolean
      delete: boolean
      stockManage: boolean
      review: boolean
    }
    task: {
      read: boolean
      write: boolean
    }
    withdrawal: {
      read: boolean
      write: boolean
      review: boolean
      cancel: boolean
      forcedClose: boolean
    }
  }
  finance: {
    document: {
      read: boolean
      write: boolean
      cancel: boolean
    }
    soPayment: {
      read: boolean
      write: boolean
      review: boolean
      verifyStatement: boolean
      cancel: boolean
    }
    task: {
      read: boolean
      write: boolean
    }
  }
  financePay: {
    paymentRequisition: {
      read: boolean
      write: boolean
      cancel: boolean
      review: boolean
    }
    refundRequisition: {
      read: boolean
      write: boolean
      review: boolean
      cancel: boolean
    }
  }
  sales: {
    customer: {
      read: boolean
      write: boolean
      update: boolean
      delete: boolean
      blacklistManage: boolean
    }
    quotation: {
      read: boolean
      report: boolean
      write: boolean
      cancel: boolean
      review: boolean
    }
    saleOrder: {
      read: boolean
      report: boolean
      pricing: boolean
      write: boolean
      cancel: boolean
      requestToCancel: boolean
    }
    salesGoalAlignment: {
      read: boolean
      write: boolean
    }
    saleProfit: {
      read: boolean
    }
  }
  purchase: {
    purchaseRequisition: {
      read: boolean
      write: boolean
      cancel: boolean
      review: boolean
    }
    purchaseOrder: {
      read: boolean
      pricing: boolean
      write: boolean
      cancel: boolean
      review: boolean
      callLeader: boolean
      markUnCancelable: boolean
    }
    vendor: {
      read: boolean
      write: boolean
      review: boolean
      delete: boolean
    }
    task: {
      read: boolean
      write: boolean
    }
  }
  service: {
    transportTask: {
      read: boolean
      report: boolean
      write: boolean
      cancel: boolean
    }
    customerRequisition: {
      read: boolean
      write: boolean
      review: boolean
      cancel: boolean
    }
  }
  marketing: {
    sales: {
      report: boolean
    }
    product: {
      report: boolean
    }
  }
}

export interface LoginResponse {
  token: string
  user: UserLogin
  permission: UserPermission
}

const path = '/users/login'

export const login = errorHandler<LoginResponse>(async (request: LoginRequest) => {
  const { username, password } = request
  return await smartAPI.post(path, {
    username,
    password,
  })
})
