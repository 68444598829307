import { DialogProps } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { PurchaseOrderInfo, updatePurchaseOrder } from '../../../api/smartbestbuys-api'
import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import PurchaseOrderCreateFormValues from '../create/PurchaseOrderCreateFormValues'
import { mapPurchaseOrderToUpdateFormValues } from './mapper'

interface Props extends DialogProps {
  purchaseOrderId: number
  onComplete: (data: PurchaseOrderInfo) => void
  onFail: () => void
}

const POConfirmUpdateDialog: React.FC<Props> = (props: Props) => {
  // prop
  const { purchaseOrderId, onComplete, onFail, ...dialogProps } = props

  //context
  const { watch } = useFormContext<PurchaseOrderCreateFormValues>()
  const { products } = watch()

  const findSalePrice = products.find((product) => !!product.salePrice && product.price > product.salePrice)
  const dialogDescription = !!findSalePrice
    ? 'มีสินค้า/บริการ ราคาเกินจากราคาขาย คุณแน่ใจที่จะแก้ไขใบสั่งซื้อใช่หรือไม่?'
    : ''

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการแก้ไขใบสั่งซื้อ',
    remarkText: 'เหตุผลที่แก้ไขใบสั่งซื้อ',
    remarkErrorText: 'กรุณากรอกเหตุผลที่แก้ไขใบสั่งซื้อ',
    submitText: 'ยืนยันการแก้ไข',
    cancelText: 'ยกเลิก',
    description: dialogDescription,
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: false,
    enableRemark: true,
    remarkRequired: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const request = mapPurchaseOrderToUpdateFormValues(watch(), values.remark!)
    const response = await updatePurchaseOrder(purchaseOrderId, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.UpdatePurchaseOrder}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default POConfirmUpdateDialog
