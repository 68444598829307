import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { FlexBox } from '..'
import imgEmptyTable from '../../assets/images/image-empty.png'
interface Props {
  // deprecated, just send as children
  emptyText?: string
  error?: boolean
}

const Empty: React.FC<Props> = ({ error, emptyText, children }) => {
  return (
    <Box borderColor={error && 'error.main'} clone>
      <FlexBox
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        color={error ? 'error.main' : 'text.secondary'}
        height="70vh"
      >
        <img alt="empty" src={imgEmptyTable} />
        <Typography>{emptyText ?? children}</Typography>
      </FlexBox>
    </Box>
  )
}

export default Empty
