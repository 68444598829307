export enum PaymentRequisitionStatus {
  PendingReview = 101,
  PendingTransfer = 201,
  PendingCheque = 202,
  PendingReceipt = 301,
  PendingStatement = 302,
  Success = 500,
  Rejected = 998,
  Canceled = 999,
}

export enum PurchasePaymentStatusGroup {
  Approved = 1,
  PendingReceipt = 2,
  PendingStatement = 3,
  Success = 4,
  Canceled = 5,
}

export enum PaymentRequisitionStatusGroup {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Canceled = 4,
}

export enum PaymentRequisitionType {
  Request = 1,
  Pay = 2,
}

export enum PaymentReceivingProductStatus {
  Pending = 1,
  Partial = 2,
  ReceivedAll = 3,
}
