import { useState, useEffect } from 'react'
import { getRefundRequisitionsCreateData, IRefundRequisitionFormData } from '../../api/smartbestbuys-api'

export const useRefundRequisitionsCreateData = (customerId: number): IRefundRequisitionFormData | undefined => {
  const [data, setData] = useState<IRefundRequisitionFormData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getRefundRequisitionsCreateData({
        customerId: customerId.toString(),
      })
      setData(response?.data)
    })()
  }, [customerId])

  return data
}
