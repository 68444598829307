import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { PurchaseOrderStatus } from '../../enums'
import { Description } from '@material-ui/icons'
import { BaseData } from '../../api/smartbestbuys-api'

interface Props {
  // deprecated
  status: PurchaseOrderStatus
  statusWithName?: BaseData
  icon?: boolean
}

const useStyles = (status: PurchaseOrderStatus) =>
  makeStyles((theme) => {
    const chipColors = {
      [PurchaseOrderStatus.ReviewPending]: theme.palette.warning.light,
      [PurchaseOrderStatus.PaymentPending]: theme.palette.warning.light,
      [PurchaseOrderStatus.DepositPending]: theme.palette.warning.light,
      [PurchaseOrderStatus.AppointmentPending]: theme.palette.warning.light,
      [PurchaseOrderStatus.ProductPending]: theme.palette.warning.light,
      [PurchaseOrderStatus.VendorShippingToCustomer]: theme.palette.warning.light,
      [PurchaseOrderStatus.PartialReceived]: theme.palette.warning.light,
      [PurchaseOrderStatus.LeaderPending]: theme.palette.warning.light,
      [PurchaseOrderStatus.Received]: theme.palette.success.light,
      [PurchaseOrderStatus.Rejected]: theme.palette.error.light,
      [PurchaseOrderStatus.Canceled]: theme.palette.error.light,
    }

    const iconColors = {
      [PurchaseOrderStatus.ReviewPending]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.PaymentPending]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.DepositPending]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.AppointmentPending]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.ProductPending]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.VendorShippingToCustomer]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.PartialReceived]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.LeaderPending]: theme.palette.warning.darkText,
      [PurchaseOrderStatus.Received]: theme.palette.success.darkText,
      [PurchaseOrderStatus.Rejected]: theme.palette.error.darkText,
      [PurchaseOrderStatus.Canceled]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: chipColors[status],
      },
      icon: {
        color: iconColors[status],
      },
    })
  })

const PurchaseOrderStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({
  children,
  status,
  statusWithName,
  icon,
  label,
  ...othersProps
}) => {
  const statusId: PurchaseOrderStatus = statusWithName?.id ?? status
  const classes = useStyles(statusId)()
  const iconMaps = {
    [PurchaseOrderStatus.ReviewPending]: <Description />,
    [PurchaseOrderStatus.PaymentPending]: <Description />,
    [PurchaseOrderStatus.DepositPending]: <Description />,
    [PurchaseOrderStatus.AppointmentPending]: <Description />,
    [PurchaseOrderStatus.ProductPending]: <Description />,
    [PurchaseOrderStatus.VendorShippingToCustomer]: <Description />,
    [PurchaseOrderStatus.PartialReceived]: <Description />,
    [PurchaseOrderStatus.LeaderPending]: <Description />,
    [PurchaseOrderStatus.Received]: <Description />,
    [PurchaseOrderStatus.Rejected]: <Description />,
    [PurchaseOrderStatus.Canceled]: <Description />,
  }
  return (
    <Chip
      icon={icon ? iconMaps[statusId] : undefined}
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      {...othersProps}
      label={statusWithName?.name ?? label}
    />
  )
}

export default PurchaseOrderStatusChip
