import { SaleOrderStatus } from '../../../enums'
import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const updateSaleOrderAPI = errorSingleHandler<{ id: number }>(
  (id: number, request: SaleOrderUpdationRequest) => {
    const path = `/sale-orders/${id}/update`
    return smartAuthAPI.put(path, request)
  },
)

export interface SaleOrderUpdationRequest {
  contactId?: number
  billingAddressId?: number
  contactChannelId?: number
  customerPurchaseReference?: string
  currencyId: number
  exchangeRate: number
  paymentMethodId: number
  paymentConditionId: number
  paymentStatusId: number
  statusId: SaleOrderStatus
  vatIncluded: boolean
  discountAmount?: number
  discountAmountTypeId?: number
  estimatedShippingDay?: number
  estimatedShippingFee?: number
  depositRequired: boolean
  remark?: string
  dueDate?: string
  creditPeriodDay?: number
  isShippingFeeIncludingVat?: boolean
  attachments?: SaleOrderAttachmentUpdation[]
  products: SaleOrderProductUpdation[]
  total?: number
  customerPickUpMethod?: number
  createdAt: string
}

export interface SaleOrderAttachmentUpdation {
  id: number
  base64?: string
  command?: SQLCommand
}

export interface SaleOrderProductUpdation {
  id: number
  productId: number
  code?: string
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  amount: number
  price: number
  command?: SQLCommand
}

export enum SQLCommand {
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
}
