import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { useContext, useState } from 'react'
import { RootContext } from '../../..'
import { approveRefundRequisitionAPI, IRefundRequisitionProfile } from '../../../api/smartbestbuys-api'
import { UserAction } from '../../../enums'

interface Props extends DialogProps {
  refundRequisitionId: number
  onComplete: (data: IRefundRequisitionProfile) => void
}

const RefundRequisitionApproveDialog: React.FC<Props> = (props) => {
  // todo: set current page
  const { refundRequisitionId, onComplete, onClose, ...dialogProps } = props

  const { triggerSnackbar } = useContext(RootContext)

  // state
  const [submitting, setSubmitting] = useState(false)

  const handleConfirm = async () => {
    setSubmitting(true)
    const response = await approveRefundRequisitionAPI(refundRequisitionId, {
      userAction: UserAction.ApproveRefundRequisition,
    })

    if (response?.status === 200) {
      triggerSnackbar('อนุมัติการคืนเงินสำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
      onComplete(response.data)
    } else {
      triggerSnackbar('อนุมัติการคืนเงินไม่สำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
    }
    setSubmitting(false)
  }

  const handleCancel = () => {
    onClose && onClose({}, 'escapeKeyDown')
  }

  return (
    <Dialog fullWidth {...dialogProps}>
      <DialogTitle>อนุมัติการคืนเงิน</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          ยืนยันอนุมัติใช่หรือไม่?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          ย้อนกลับ
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          disabled={submitting}
          startIcon={submitting ? <CircularProgress /> : undefined}
        >
          ยืนยันอนุมัติ
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefundRequisitionApproveDialog
