import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

import { Form, Topic } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'
import { SourceFile } from '../../../types'
import { AttachmentSelectionBox } from '../../purchase-requisitions/create/AttachmentSelectionBox'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'
import PurchaseOrderDiscountBox from './PurchaseOrderDiscountBox'
import PurchaseRequisitionInfoBox from './PurchaseRequisitionInfoBox/PurchaseRequisitionInfoBox'
import PurchaseOrderInformationFormBox from './PurchaseOrderInformationFormBox'
import { PurchaseOrderProductSelector } from './PurchaseOrderProductSelector'
import PurchaseOrderTotalBox from './PurchaseOrderTotalBox'
import PurchaseOrderVatBox from './PurchaseOrderVatBox'
import PurchaseOrderVendorBox from './PurchaseOrderVendor'
import PurchaseOrderWithHoldingTaxBox from './PurchaseOrderWithHoldingTaxBox'
import SelectedPurchaseOrderProductTable from './SelectedPurchaseOrderProductTable/SelectedPurchaseOrderProductTable'
import VendorShippingInformationBox from './VendorShippingFormBox'
import { useEffect } from 'react'

interface Props {
  onNextPage: () => void
}

const PurchaseOrderCreateForm: React.FC<Props> = (props: Props) => {
  // props
  const { onNextPage } = props

  // hook form
  const { setValue, watch, setError, clearErrors } = useFormContext<PurchaseOrderCreateFormValues>()

  // data
  const { attachments, products, vendor } = watch()

  // handlers
  const handleAddAttachmentsToForm = (attachments: SourceFile[]) => {
    setValue('attachments', attachments)
  }

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const handleSubmit = () => {
    if (!validate()) onNextPage()
  }

  const handleInvalid = (errors: any) => {
    // console.log(errors)
    validate()
  }

  useEffect(() => {
    if (products.length > 0) {
      clearErrors('products')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.length])

  const validate = (): boolean => {
    let error = false
    if (!vendor) {
      error = true
      setError('vendor', {
        type: 'required',
        message: 'กรุณาเลือกผู้ขาย',
      })
      scrollTo('#purchase-order-vendor')
    }

    if (products.length === 0) {
      setError('products', {
        type: 'required',
        message: 'กรุณาเลือกสินค้า/บริการ',
      })
      error = true
      if (!!vendor) scrollTo('#selected-purchase-order-product-table')
    }

    return error
  }

  return (
    <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>สร้างใบสั่งซื้อ</Topic>
        </Grid>
        <Grid item xs={12}>
          <PurchaseRequisitionInfoBox />
        </Grid>
        <Grid item xs={12} id="purchase-order-vendor">
          <PurchaseOrderVendorBox />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrderProductSelector />
        </Grid>
        <Grid item xs={12} id="selected-purchase-order-product-table">
          <SelectedPurchaseOrderProductTable />
        </Grid>
        <Grid item md={6} xs={12}>
          <PurchaseOrderDiscountBox />
        </Grid>
        <Grid item md={6} xs={12}>
          <PurchaseOrderWithHoldingTaxBox />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrderVatBox />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrderTotalBox />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrderInformationFormBox />
        </Grid>
        <Grid item xs={12}>
          <VendorShippingInformationBox />
        </Grid>
        <Grid item xs={12}>
          <AttachmentSelectionBox onChange={handleAddAttachmentsToForm} defaultValue={attachments} />
        </Grid>
        <Grid item xs={12}>
          <SubmitForm goBack submitText={'ตรวจทานสร้างใบสั่งซื้อ'} cancelText="ยกเลิก" />
        </Grid>
      </Grid>
    </Form>
  )
}

export default PurchaseOrderCreateForm
