import React, { useContext, useMemo, useState } from 'react'
import { Topic, withLayout } from '../../../custom-components'
import { Loading } from '../../../shared-components'
import { PageName, TransportTaskItemType } from '../../../enums'
import { RootContext } from '../../..'
import { updateTransportPickupTaskAPI } from '../../../api/smartbestbuys-api'
import { useAddressData, useTransportTaskUpdateDataV2 } from '../../../hooks'
import { useHistory } from 'react-router-dom'
import { TransportPickupTaskFormValues } from '../pickup-forms/TransportPickupTaskFormValues'
import TransportPickupTaskForm from '../pickup-forms/TransportPickupTaskForm'
import { Grid } from '@material-ui/core'

interface Props {
  taskId: number
}

const TransportPickupTaskUpdatePage: React.FC<Props> = (props) => {
  // set up props & state & context
  const { taskId } = props

  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  setCurrentPage(PageName.TRANSPORT_PICKUP_TASK)

  const history = useHistory()

  // state
  const [submitting, setSubmitting] = useState(false)

  // call hooks
  const addressData = useAddressData()
  const updateData = useTransportTaskUpdateDataV2(taskId)

  const defaultValues = useMemo(() => {
    const customerShippingAddress = updateData?.customerShippingAddresses.find(
      (a) => a.id === updateData?.shippingInfo.shippingAddress?.id,
    )

    const messenger = updateData?.users.find(
      (user) => user.id === updateData?.profile.state?.shippingInfo.messengerInfo?.userId,
    )
    const shippingCar = updateData?.shippingCars.find(
      (car) => car.id === updateData?.profile.state?.shippingInfo.messengerInfo?.car?.id,
    )

    return {
      dueDate: !!updateData?.profile.dueDate ? new Date(updateData?.profile.dueDate) : undefined,
      messenger: {
        id: messenger?.id,
        name: messenger?.firstName + ' ' + messenger?.lastName,
      },
      car: shippingCar,
      remark: updateData?.profile.remark,
      consignee: updateData?.profile.state?.shippingInfo.consignee,
      phoneNumber: updateData?.profile.state?.shippingInfo.phoneNumber,
      customerShippingAddress,
      assigneeUserId: updateData?.profile.assignee?.userId,
    }
    // eslint-disable-next-line
  }, [!!updateData])

  if (!updateData) return <Loading />

  const handleConfirmValues = async (values: TransportPickupTaskFormValues) => {
    setSubmitting(true)
    const productItems = updateData.customerRequisitionInfo?.products.map((p) => ({
      type: TransportTaskItemType.Product,
      id: p.id,
      code: p.code,
      name: p.nameLocal,
      amount: p.amount,
      imageUrl: p.imageUrl,
    }))

    const customerShippingAddress = updateData?.customerShippingAddresses.find(
      (a) => a.id === values.customerShippingAddress.id,
    )

    const request = {
      customerId: updateData?.saleOrderInfo?.customerId,
      saleOrderId: updateData?.saleOrderInfo?.id,
      customerRequisitionId: updateData?.customerRequisitionInfo?.id,
      items: productItems,
      dueDate: values.dueDate,
      messenger: values.messenger,
      car: values.car,
      remark: values.remark,
      consignee: values.consignee,
      phoneNumber: values.phoneNumber,
      customerShippingAddress: {
        id: values.customerShippingAddress.id,
        name: customerShippingAddress?.name ?? values.customerShippingAddress.name,
        branchName: customerShippingAddress?.branchName ?? values.customerShippingAddress.branchName,
        addressLine1: customerShippingAddress?.addressLine1 ?? values.customerShippingAddress.addressLine1,
        phoneNumber: customerShippingAddress?.phoneNumber ?? values.customerShippingAddress.phoneNumber,
        faxNumber: customerShippingAddress?.faxNumber ?? values.customerShippingAddress.faxNumber,

        province:
          customerShippingAddress?.province ??
          addressData?.provinces.find((d) => d.id === values.customerShippingAddress.provinceId),
        district:
          customerShippingAddress?.district ??
          addressData?.districts.find((d) => d.id === values.customerShippingAddress.districtId),
        subDistrict:
          customerShippingAddress?.subDistrict ??
          addressData?.subDistricts.find((d) => d.id === values.customerShippingAddress.subDistrictId),

        postalCode: customerShippingAddress?.postalCode ?? values.customerShippingAddress.postalCode,
        googleMapLink: customerShippingAddress?.googleMapLink ?? values.customerShippingAddress.googleMapLink,
      },
      assigneeUserId: values.assigneeUserId,
    }

    const response = await updateTransportPickupTaskAPI(updateData.profile.id, request)
    if (response?.status === 200) {
      triggerSnackbar('แก้ไขงานบริการรับของสำเร็จ')
      history.push(`/transport-pickup-tasks/${response.data.id}/profile`)
    } else {
      triggerSnackbar('แก้ไขงานบริการรับของไม่สำเร็จ')
    }

    setSubmitting(false)
  }

  // render
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>แก้ไขงานขนส่งเลขที่ {updateData.profile.code}</Topic>
      </Grid>
      <Grid item xs={12}>
        <TransportPickupTaskForm
          defaultValues={defaultValues}
          formData={updateData}
          onSubmit={handleConfirmValues}
          submitting={submitting}
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(TransportPickupTaskUpdatePage)
