import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  Card,
  Button,
  Box,
  Divider,
  Chip,
} from '@material-ui/core'
import { Close, Cancel, CheckCircle, Description } from '@material-ui/icons'
import {
  DocumentState,
  FetchSOPaymentTransaction,
  SaleOrderDocument,
  SOPaymentTransaction,
  UpdatedSOPaymentTransactionStatus,
} from '../../api/smartbestbuys-api'
import { FlexBox, Title, TypographyWithLabel, CustomButton, Link } from '../../custom-components'
import {
  ConditionTooltip,
  ImageCard,
  PaymentTransactionStatusChip,
  StatementVerifiedSignal,
  StatementVerifyingSignal,
} from '..'
import { useStyles } from './styles'
import numeral from 'numeral'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import { SOPaymentApproveDialog } from './SOPaymentApproveDialog'
import { SOPaymentRejectDialog } from './SOPaymentRejectDialog'
import { SOPaymentTransactionStatus, UserAction } from '../../enums'
import { SOPaymentStatementVerifyDialog } from './SOPaymentStatementVerifyDialog'
import { DocumentType } from '../../enums'
import { getUserPermissionInfo } from '../../utils/permission'
import { PermissionEnum } from '../../enums/PermissionEnum'

interface Props extends DialogProps {
  transaction: SOPaymentTransaction
  documents?: SaleOrderDocument<DocumentState>[]
  total: number
  saleOrderId: number
  readOnly?: boolean
  isFromSaleOrderProfile?: boolean
  onConfirm?: (data: UpdatedSOPaymentTransactionStatus) => void
  onVerify?: (data: FetchSOPaymentTransaction) => void
}

const SOPaymentTransactionDialog: React.FC<Props> = (props) => {
  const [confirm, setConfirm] = useState(false)
  const [decline, setDecline] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [verify, setVerify] = useState(false)
  const { readOnly, onConfirm, onVerify, isFromSaleOrderProfile } = props
  const classes = useStyles()

  const { transaction, total, saleOrderId, onClose, documents, ...dialogProps } = props
  const {
    code,
    status,
    amount,
    state,
    paymentMethod,
    paymentImageUrl,
    paymentAdjustment,
    withholdingAmount,
    withholdingTaxDocumentReceived,
    bank,
    bankAccount,
    rejectedMessage,
    statementVerifiedAt,
    statementTimestamp,
    recCreatedBy,
  } = transaction

  const { referenceNumber, paymentTimestamp, branchName, verificationRemark } = state

  const hasReferenceDocument =
    !!documents && documents?.filter((d) => d.paymentTransaction?.id === transaction.id).length > 0

  const adjustmentAmount = paymentAdjustment?.amount || 0
  const actualWithholdingAmount = withholdingAmount || 0

  const priceBreakdowns = [
    { label: 'ยอดเต็ม', value: total },
    // { label: 'ยอดค้างชำระ' },
    { label: 'ยอดชำระ', value: amount },
    { label: 'รายการปรับลด', value: adjustmentAmount },
    { label: 'หัก ณ ที่จ่าย', value: actualWithholdingAmount },
    { label: 'ยอดจริงที่ได้รับ', value: amount - adjustmentAmount - actualWithholdingAmount },
  ]

  const handleCloseConfirmDialog = () => setConfirm(false)
  const handleOpenConfirmDialog = () => setConfirm(true)
  const handleCloseRejectDialog = () => setDecline(false)
  const handleOpenRejectDialog = () => setDecline(true)
  const handleCloseCancelDialog = () => setCancel(false)
  const handleOpenCancelDialog = () => setCancel(true)
  const handleOpenVerifyDialog = () => setVerify(true)
  const handleCloseVerifyDialog = () => setVerify(false)

  const verified = !!statementVerifiedAt

  return (
    <Dialog fullWidth maxWidth="lg" {...dialogProps}>
      <SOPaymentApproveDialog
        transaction={transaction}
        saleOrderId={saleOrderId}
        open={confirm}
        onConfirm={onConfirm}
        onClose={handleCloseConfirmDialog}
      />
      <SOPaymentRejectDialog
        transaction={transaction}
        saleOrderId={saleOrderId}
        open={decline}
        onConfirm={onConfirm}
        onClose={handleCloseRejectDialog}
        userAction={UserAction.SODeclinePaymentTransaction}
      />
      <SOPaymentRejectDialog
        transaction={transaction}
        saleOrderId={saleOrderId}
        open={cancel}
        onConfirm={onConfirm}
        onClose={handleCloseCancelDialog}
        userAction={UserAction.SOCancelPaymentTransaction}
      />
      <SOPaymentStatementVerifyDialog
        transaction={transaction}
        saleOrderId={saleOrderId}
        open={verify}
        onVerify={onVerify}
        onClose={handleCloseVerifyDialog}
        userAction={UserAction.SOVerifyPaymentStatement}
      />
      <DialogTitle disableTypography>
        <FlexBox alignItems="center" gridGap={16}>
          <Title>{code}</Title>
          <PaymentTransactionStatusChip status={status.id} label={status.name} />
          {status.id === SOPaymentTransactionStatus.APPROVED && !!verified ? (
            <StatementVerifiedSignal />
          ) : (
            <StatementVerifyingSignal />
          )}
        </FlexBox>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose && onClose({}, 'backdropClick')}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TypographyWithLabel label="วิธีการชำระเงิน:">{paymentMethod.name}</TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="ผู้สร้าง:">{recCreatedBy}</TypographyWithLabel>
              </Grid>
              {paymentImageUrl && (
                <Grid item xs={12}>
                  <ImageCard src={paymentImageUrl} name="payment slip" />
                </Grid>
              )}
              {referenceNumber && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="เลขที่อ้างอิง:">{referenceNumber}</TypographyWithLabel>
                </Grid>
              )}
              {paymentTimestamp && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="วันและเวลาที่โอน:">
                    {format(new Date(paymentTimestamp), 'dd MMM yyyy HH:mm', {
                      locale: thLocale,
                    })}
                  </TypographyWithLabel>
                </Grid>
              )}
              {bankAccount && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="เงินเข้าบัญชี:">
                    <div>{bankAccount.name}</div>
                    <div>
                      ({bankAccount.bankName} {bankAccount.number})
                    </div>
                  </TypographyWithLabel>
                </Grid>
              )}
              {statementTimestamp && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="วันและเวลาที่เงินเข้าบัญชี:">
                    {format(new Date(statementTimestamp), 'dd MMM yyyy HH:mm', {
                      locale: thLocale,
                    })}
                  </TypographyWithLabel>
                </Grid>
              )}
              {bank && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="ธนาคาร:">{bank.name}</TypographyWithLabel>
                </Grid>
              )}
              {branchName && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="สาขา:">{branchName}</TypographyWithLabel>
                </Grid>
              )}
              {paymentAdjustment?.type && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="ประเภทการปรับลด:">{paymentAdjustment.type}</TypographyWithLabel>
                </Grid>
              )}
              {paymentAdjustment?.amount && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="จำนวนเงินการปรับลด:">
                    {numeral(paymentAdjustment.amount).format('0,0.00')}
                  </TypographyWithLabel>
                </Grid>
              )}
              {withholdingAmount && (
                <Grid item xs={12} md={6}>
                  <div className="flex items-center gap-x-2">
                    <TypographyWithLabel label="จำนวนเงินหัก ณ ที่จ่าย :">
                      {numeral(withholdingAmount).format('0,0.00')}
                    </TypographyWithLabel>

                    <Description
                      style={{ fontSize: '1.25rem', color: withholdingTaxDocumentReceived ? '#4CAF50' : '#bdbdbd' }}
                    />
                  </div>
                </Grid>
              )}
              {statementVerifiedAt && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="วันและเวลาที่ตรวจสอบ:">
                    {format(new Date(statementVerifiedAt), 'dd MMM yyyy HH:mm', {
                      locale: thLocale,
                    })}
                  </TypographyWithLabel>
                </Grid>
              )}
              {verificationRemark && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="หมายเหตุการตรวจสอบ:">{verificationRemark}</TypographyWithLabel>
                </Grid>
              )}
              {hasReferenceDocument && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <FlexBox alignItems="center" gridGap={8} flexWrap>
                      <Typography variant="body2">เอกสารที่เกี่ยวข้อง: </Typography>
                      {documents
                        ?.filter((d) => d.paymentTransaction?.id === transaction.id)
                        .map((d) => {
                          let path = ''

                          switch (d.type.id) {
                            case DocumentType.Invoice:
                              path = 'invoices'
                              break
                            case DocumentType.TaxInvoice:
                              path = 'tax-invoices'
                              break
                            case DocumentType.Receipts:
                              path = 'receipts'
                              break
                          }

                          return (
                            <Chip
                              size="small"
                              label={
                                <Link href={`/${path}/${d.id}/pdf`} target="_blank">
                                  {d.type.name} {d.code}
                                </Link>
                              }
                            />
                          )
                        })}
                    </FlexBox>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">สรุปการชำระเงิน</Typography>
              </Grid>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <Grid item xs={12} container spacing={2}>
                    {priceBreakdowns.map((pb) => (
                      <>
                        <Grid item xs={6}>
                          <Typography variant="body2" align="right" display="block">
                            {pb.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" align="right" display="block">
                            {numeral(pb.value).format('0,0.00')}
                          </Typography>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Card>
              </Grid>
              {rejectedMessage && (
                <Grid item xs={12}>
                  <Box color="error.dark">
                    <TypographyWithLabel label="สาเหตุ: ">
                      <Box pl={1} pr={1} bgcolor="error.background">
                        {rejectedMessage}
                      </Box>
                    </TypographyWithLabel>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Actions
        readOnly={readOnly}
        isFromSaleOrderProfile={isFromSaleOrderProfile}
        statementVerifiedAt={statementVerifiedAt}
        status={status.id}
        onConfirm={handleOpenConfirmDialog}
        onDecline={handleOpenRejectDialog}
        onCancel={handleOpenCancelDialog}
        onVerify={handleOpenVerifyDialog}
      />
    </Dialog>
  )
}

interface ActionsProps {
  readOnly?: boolean
  isFromSaleOrderProfile?: boolean
  status: SOPaymentTransactionStatus
  statementVerifiedAt?: Date
  onConfirm: () => void
  onDecline: () => void
  onCancel: () => void
  onVerify: () => void
}

const Actions: React.FC<ActionsProps> = (props) => {
  const { status, statementVerifiedAt, readOnly, onDecline, onConfirm, onCancel, onVerify, isFromSaleOrderProfile } =
    props
  const isApproved = status === SOPaymentTransactionStatus.APPROVED
  const isCancelled = status === SOPaymentTransactionStatus.CANCELLED
  const isDecline = status === SOPaymentTransactionStatus.DECLINED
  const isVerified = status === SOPaymentTransactionStatus.APPROVED && !!statementVerifiedAt

  if (readOnly || isCancelled || isDecline) return <></>

  // permission
  const reviewPermission = getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Review)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Cancel)
  const verifyStatementPermission = getUserPermissionInfo(PermissionEnum.Finance_SOPayment_VerifyStatement)

  if (isApproved) {
    return (
      <DialogActions>
        {!isVerified && (
          <ConditionTooltip showTooltip={!cancelPermission.hasPermission} title={cancelPermission.alertMessage}>
            <Button
              startIcon={<Cancel color={cancelPermission.hasPermission ? 'error' : 'disabled'} />}
              onClick={onCancel}
              disabled={!cancelPermission.hasPermission}
            >
              <Typography color="textSecondary">ยกเลิกการชำระเงิน</Typography>
            </Button>
          </ConditionTooltip>
        )}
        {!isFromSaleOrderProfile && !isVerified && (
          <ConditionTooltip
            showTooltip={!verifyStatementPermission.hasPermission}
            title={verifyStatementPermission.alertMessage}
          >
            <CustomButton
              color="warning"
              variant="contained"
              onClick={onVerify}
              disabled={!verifyStatementPermission.hasPermission}
            >
              ตรวจสอบ STATEMENT
            </CustomButton>
          </ConditionTooltip>
        )}
      </DialogActions>
    )
  }

  if (!reviewPermission.hasPermission) return <DialogActions />

  return (
    <DialogActions>
      <CustomButton color="error" variant="contained" startIcon={<Cancel />} onClick={onDecline}>
        ไม่อนุมัติ
      </CustomButton>
      <CustomButton color="success" variant="contained" startIcon={<CheckCircle />} onClick={onConfirm}>
        อนุมัติ
      </CustomButton>
    </DialogActions>
  )
}

export default SOPaymentTransactionDialog
