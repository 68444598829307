import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core'
import { PermissionGroup, PermissionTopic } from '../../api/smartbestbuys-api'
import { SubTopic } from '../../custom-components'
import UserPermissionCheckbox from './UserPermissionCheckbox'
import UserPermissionGroupCheckbox from './UserPermissionGroupCheckbox'

interface UserUpsertData {
  permissionGroups: PermissionGroup[]
  permissionTopics: PermissionTopic[]
  selectedPermissionIds: number[]
  selectedPermissionGroupIds: number[]
  allPermissionIdInSelectedGroup: number[]
  onPermissionGroupSelect: (permissionGroupIds: number[]) => void
  onPermissionSelect: (permissionIds: number[]) => void
}

const UserPermissionGroupBox: React.FC<UserUpsertData> = (props) => {
  const {
    permissionGroups,
    permissionTopics,
    selectedPermissionIds,
    selectedPermissionGroupIds,
    onPermissionGroupSelect,
    onPermissionSelect,
    allPermissionIdInSelectedGroup,
  } = props

  return (
    <Box p={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>สิทธิ์การใช้งาน</SubTopic>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">กลุ่มสิทธิ์</Typography>
            <div className="mt-2">
              {permissionGroups.map((group, index) => (
                <UserPermissionGroupCheckbox
                  selectedPermissionGroupIds={selectedPermissionGroupIds}
                  onPermissionGroupSelect={onPermissionGroupSelect}
                  permissionGroup={group}
                />
              ))}
            </div>
          </Grid>
          <Divider variant="middle" />
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">สิทธิ์ทั้งหมด</Typography>
            {permissionTopics.map((topic, index) => {
              return (
                <div className="mt-8">
                  <Typography variant="body1">{topic.name}</Typography>
                  <div className="mt-2">
                    {topic.permissions.map((permission, index) => (
                      <UserPermissionCheckbox
                        selectedPermissionIds={selectedPermissionIds}
                        onPermissionSelect={onPermissionSelect}
                        permission={permission}
                        allPermissionIdInSelectedGroup={allPermissionIdInSelectedGroup}
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default UserPermissionGroupBox
