import { DialogProps } from '@material-ui/core'

import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import { UpdatePurchaseOrderStatus, approvePurchaseOrder } from '../../../api/smartbestbuys-api/'

interface Props extends DialogProps {
  purchaseOrderId: number
  onComplete: (data: UpdatePurchaseOrderStatus) => void
  onFail: () => void
}

const PurchaseOrderProfileApproveDialog: React.FC<Props> = (props: Props) => {
  // set up props & state
  const { purchaseOrderId, onComplete, onFail, ...dialogProps } = props

  const textOption: UserActivityDialog_TextOption = {
    title: 'อนุมัติใบสั่งซื้อ',
    description: `ยืนยันอนุมัติใช่หรือไม่?`,
    submitText: 'ยืนยันอนุมัติ',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: false,
    enableRemark: false,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const response = await approvePurchaseOrder(purchaseOrderId)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.ApprovePurchaseOrder}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default PurchaseOrderProfileApproveDialog
