import { useState, useEffect } from 'react'
import { fetchDocumentCreditNotes, FetchRequest, DocumentCreditNote } from '../api/smartbestbuys-api'

export const useDocumentCreditNotes = (startDate: Date, endDate: Date): DocumentCreditNote[] => {
  const [documentCreditNotes, setDocumentTaxInvoice] = useState<DocumentCreditNote[]>([])

  const _fetchDocumentCreditNotes = async (request: FetchRequest) => {
    const response = await fetchDocumentCreditNotes(request)
    setDocumentTaxInvoice(response?.data || [])
  }

  useEffect(() => {
    _fetchDocumentCreditNotes({ startDate, endDate })
  }, [startDate, endDate])

  return documentCreditNotes
}
