import { Paper, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { FlexBox } from '../../../custom-components'
import { calculatePurchaseOrderTotal } from '../../../functions'
import { formatNumber } from '../../../functions/number-utils'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'

const PurchaseOrderTotalBox: React.FC = () => {
  const { watch } = useFormContext<PurchaseOrderCreateFormValues>()

  const { products, discountAmount, vatIncluded, vendor } = watch()

  const currency = vendor?.currency

  const productTotal = products.reduce<number>((sum, product) => {
    return sum + product.price! * product.amount
  }, 0)

  const total = calculatePurchaseOrderTotal(productTotal, discountAmount, vatIncluded)

  return (
    <Paper>
      <FlexBox justifyContent="space-between">
        <Typography variant="h5">ยอดรวมสุทธิ</Typography>
        <Typography variant="h6" color="secondary">
          {`${formatNumber(total)} ${currency?.name ?? 'บาท'}`}
        </Typography>
      </FlexBox>
    </Paper>
  )
}

export default PurchaseOrderTotalBox
