import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../../../../../..'
import { ICustomerProfile_Contact } from '../../../../../../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm } from '../../../../../../../../custom-components'
import { genderInformation } from '../../../../../../../../data'
import CustomerProfileContext from '../../../../CustomerProfileContext'
import { mapCustomerContactToUpdationFormValue } from '../../../mapper'
import { updateCustomerContact } from '../../../service'
import CustomerContactUpdationFormValue from './CustomerContactUpdationFormValue'
import { customerDepartments } from '../../../../../../../../data/customer'

interface Props extends DialogProps {
  contact: ICustomerProfile_Contact
}

const CustomerContactUpdationDialog: React.FC<Props> = (props) => {
  const { onClose, contact, ...otherProps } = props

  const { triggerSnackbar } = useContext(RootContext)
  const {
    reload,
    customerProfile: { id: customerId },
  } = useContext(CustomerProfileContext)

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<CustomerContactUpdationFormValue>()

  useEffect(() => {
    reset(mapCustomerContactToUpdationFormValue(contact))
  }, [contact, reset])

  const handleSubmitCustomerContactUpdationForm = async (value: CustomerContactUpdationFormValue) => {
    const response = await updateCustomerContact(customerId, contact.id, value)
    if (response?.status === 200) {
      triggerSnackbar('แก้ไขผู้ติดต่อสำเร็จ')
      reload()
      onClose && onClose({}, 'escapeKeyDown')
      reset()
    } else {
      triggerSnackbar('แก้ไขผู้ติดต่อไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitCustomerContactUpdationForm)}>
        <DialogTitle>แก้ไขผู้ติดต่อ</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="nameLocal" label="ชื่อจริง - นามสกุล (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="nickname"
                rules={{ required: 'กรุณาใส่ชื่อเล่น' }}
                label="ชื่อเล่น *"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="gender"
                rules={{ required: 'กรุณาเลือกเพศ' }}
                label="เพศ *"
                defaultValue=""
                select
              >
                {genderInformation.map((gender) => (
                  <MenuItem key={gender.id} value={gender.value}>
                    {gender.name}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="customerDepartmentId"
                rules={{ required: 'กรุณาเลือกตำแหน่ง' }}
                label="ตำแหน่ง *"
                select
              >
                {customerDepartments.map((department) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                type="tel"
                control={control}
                name="phoneNumber"
                rules={{
                  required: 'กรุณาใส่เบอร์โทรศัพท์',
                  minLength: { value: 10, message: 'กรุณาใส่เบอร์โทรศัพท์ 10 หลัก' },
                  maxLength: { value: 10, message: 'กรุณาใส่เบอร์โทรศัพท์ 10 หลัก' },
                }}
                label="เบอร์โทรศัพท์ *"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="email" label="อีเมล์ (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="line" label="ไลน์ (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="facebook" label="เฟชบุ๊ก (ถ้ามี)" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            บันทึกการแก้ไข
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerContactUpdationDialog
