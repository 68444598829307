import { ICustomerProfile_Address } from '..'
import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const updateSOBillingAddressAPI = errorSingleHandler<UpdatedSaleOrderBillingAddress>(
  (id: number, request: SaleOrderUpdationBillingAddressRequest) => {
    const path = `/sale-orders/${id}/update/billing-address`
    return smartAuthAPI.patch(path, request)
  },
)

export interface SaleOrderUpdationBillingAddressRequest {
  billingAddressId: number
}

export interface UpdatedSaleOrderBillingAddress {
  id: number
  billingAddress: ICustomerProfile_Address
}
