import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from '@material-ui/core'
import {
  IProductWithdrawalTicketProfile,
  partialUpdateProductWithdrawalTicketByIdAPI,
} from '../../../../api/smartbestbuys-api/warehouse'
import { FlexBox, GeneralTextField } from '../../../../custom-components'
import { UserAction } from '../../../../enums'
import { useState } from 'react'
import { profilePageText } from '../profile'
import { KeyboardDatePicker } from '@material-ui/pickers'

interface Props extends DialogProps {
  ticketProfile: IProductWithdrawalTicketProfile
  onSuccess: (data: IProductWithdrawalTicketProfile) => void
  onFail: () => void
}

const FollowUpPWTicketDialog: React.FC<Props> = (props) => {
  const { ticketProfile, onSuccess, onFail, onClose, ...dialogProps } = props

  const { id, code, estimatedReturnedDate } = ticketProfile

  const { title, content, remarkLabel, cancelButton, confirmButton } = profilePageText.dialog.followUpDialog

  // state
  const [remark, setRemark] = useState<string | undefined>()
  const [estimateReturnDate, setEstimateReturnDate] = useState<Date | undefined>(estimatedReturnedDate)

  const handleCloseDialog = () => {
    onClose && onClose({}, 'escapeKeyDown')
  }

  const handleSubmitDialog = async () => {
    const response = await partialUpdateProductWithdrawalTicketByIdAPI(id, {
      actionId: UserAction.FollowUpProductWithdrawalTicket,
      estimatedReturnedDate: estimateReturnDate,
      remark,
    })

    if (response?.status === 200) {
      onSuccess(response.data)
      onClose && onClose({}, 'escapeKeyDown')
    } else {
      onFail()
      onClose && onClose({}, 'escapeKeyDown')
    }
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        {title} {code}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textPrimary">
          {content}
        </Typography>
        <div className="mt-6" />
        <KeyboardDatePicker
          fullWidth
          label="วันที่คาดว่าจะคืนสินค้า"
          size="small"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          invalidDateMessage="วันที่ไม่ถูกต้อง"
          value={!!estimateReturnDate ? estimateReturnDate : null}
          onChange={(date: any) => setEstimateReturnDate(date)}
        />
        <GeneralTextField label={remarkLabel} onChange={(e) => setRemark(e.target.value || undefined)} />
      </DialogContent>
      <DialogActions>
        <FlexBox justifyContent="flex-end" gridGap={8}>
          <Button variant="outlined" color="primary" onClick={handleCloseDialog}>
            {cancelButton}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmitDialog}>
            {confirmButton}
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default FollowUpPWTicketDialog
