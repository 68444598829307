import { useState, useEffect } from 'react'
import { Receipt, FetchRequest, fetchDocumentReceipts } from '../api/smartbestbuys-api'

export const useDocumentReceipts = (startDate: Date, endDate: Date): Receipt[] => {
  const [documents, setDocuments] = useState<Receipt[]>([])

  const _fetchDocumentReceipts = async (request: FetchRequest) => {
    const response = await fetchDocumentReceipts(request)
    setDocuments(response?.data || [])
  }

  useEffect(() => {
    _fetchDocumentReceipts({ startDate, endDate })
  }, [startDate, endDate])

  return documents
}
