import { useState, useEffect } from 'react'
import { getTransportTaskProfileAPI, TransportTaskProfile } from '../../api/smartbestbuys-api'

export const useTransportTaskProfile = (taskId: number, reload: boolean): TransportTaskProfile | undefined => {
  const [data, setData] = useState<TransportTaskProfile | undefined>(undefined)

  const _getTransportTaskProfile = async (taskId: number) => {
    const response = await getTransportTaskProfileAPI(taskId)
    setData(response?.data)
  }

  useEffect(() => {
    _getTransportTaskProfile(taskId)
  }, [taskId, reload])

  return data
}
