import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../..'
import { updateVendor, VendorProfile, VendorUpdateRequestBody } from '../../../api/smartbestbuys-api'
import { CheckboxForm, Form, GeneralTextFieldForm, SubTopic } from '../../../custom-components'
import { ButtonRadios, Space, UploadImageCard } from '../../../shared-components'
import { currencies, purchasePaymentMethods } from '../../../types'

interface VendorUpdateFormValues {
  profileImageBase64?: string
  name: string
  referenceNumber: string
  currencyId: number
  phoneNumber: string
  scopeWork?: string
  creditPeriodDay?: number
  email?: string
  websiteUrl?: string
  remark?: string
  refPaymentMethodId?: number
  isVatVendor?: boolean
}

interface VendorUpdateFormDialogProps extends DialogProps {
  vendorProfile: VendorProfile
  // TODO: should return vendor profile
  onComplete?: () => void
  onFail?: () => void
}

const VendorUpdateFormDialog = (props: VendorUpdateFormDialogProps) => {
  // context
  const { triggerSnackbar } = useContext(RootContext)

  const { vendorProfile, onClose, onComplete, onFail, ...dialogProps } = props

  // hook form
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<VendorUpdateFormValues>({
    defaultValues: {
      name: vendorProfile.name,
      referenceNumber: vendorProfile.referenceNumber,
      currencyId: vendorProfile.currency.id,
      phoneNumber: vendorProfile.phoneNumber,
      scopeWork: vendorProfile.scopeWork,
      creditPeriodDay: vendorProfile.creditPeriodDay,
      email: vendorProfile.email,
      websiteUrl: vendorProfile.websiteUrl,
      remark: vendorProfile.remark,
      refPaymentMethodId: vendorProfile.refPaymentMethod?.id,
      isVatVendor: vendorProfile?.isVatVendor,
    },
  })

  const { refPaymentMethodId, isVatVendor } = watch()

  // handler
  const handleFormSubmit = async (values: VendorUpdateFormValues) => {
    const requestBody: VendorUpdateRequestBody = {
      name: values.name,
      phoneNumber: values.phoneNumber,
      websiteUrl: values.websiteUrl,
      base64: values.profileImageBase64,
      profileImageUrl: vendorProfile.profileImageUrl,
      email: values.email,
      currencyId: values.currencyId,
      creditPeriodDay: values.creditPeriodDay,
      scopeWork: values.scopeWork,
      remark: values.remark,
      refPaymentMethodId: values.refPaymentMethodId,
      isVatVendor: values.isVatVendor,
    }

    const response = await updateVendor(vendorProfile.id, requestBody)
    if (response?.status === 200) {
      triggerSnackbar('แก้ไขผู้ขายสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('แก้ไขผู้ขายไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} maxWidth="md">
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogTitle>แก้ไขข้อมูลผู้ขาย</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} container justify="center">
              <UploadImageCard
                previewOnChange
                title="ใส่โลโก้ หรือรูปผู้ขาย (ถ้ามี)"
                onChange={(image) => setValue('profileImageBase64', image.base64)}
                defaultSrc={vendorProfile.profileImageUrl}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="name"
                label="ชื่อ *"
                rules={{ required: 'กรุณากรอกชื่อ' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="referenceNumber" label="เลขประจำตัวผู้เสียภาษี" disabled />
            </Grid>
            <Grid item xs={12} md={6}>
              <ButtonRadios
                label="วิธีการจ่ายเงิน (ถ้ามี)"
                items={purchasePaymentMethods}
                onChange={(value) => setValue('refPaymentMethodId', value)}
                value={refPaymentMethodId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="currencyId"
                rules={{ required: 'กรุณากรอกสกุลเงินที่ใช้' }}
                label="สกุลเงินที่ใช้ *"
                select
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency.id} value={currency.id}>
                    {currency.name}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="phoneNumber"
                label="เบอร์โทรศัพท์ *"
                rules={{ required: 'กรุณากรอกเบอร์โทรศัพท์' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="scopeWork" label="ขอบเขตงานที่รับ (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="creditPeriodDay"
                type="number"
                label="ระยะเวลาเครดิต *"
                rules={{ required: 'กรุณากรอกระยะเวลาเครดิต' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="email"
                label="อีเมล *"
                rules={{ required: 'กรุณากรอกอีเมล' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="websiteUrl" label="เว็บไซต์ (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={<CheckboxForm checked={isVatVendor} control={control} name="isVatVendor" color="primary" />}
                label={<SubTopic variant="subtitle1">ผู้ขายจดภาษีมูลค่าเพิ่ม</SubTopic>}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
          >
            ยกเลิก
          </Button>
          <Space direction="horizontal" />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            บันทึกข้อมูลผู้ขาย
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default VendorUpdateFormDialog
