import { useState, useEffect } from 'react'
import { FetchRequest, IRefundRequisitionTableData, fetchRefundRequisitions } from '../../api/smartbestbuys-api'

export const useRefundRequisitions = (startDate: Date, endDate: Date): IRefundRequisitionTableData[] => {
  const [data, setData] = useState<IRefundRequisitionTableData[]>([])

  const _fetchRefundRequisitions = async (request: FetchRequest) => {
    const response = await fetchRefundRequisitions(request)
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchRefundRequisitions({ startDate, endDate })
  }, [endDate, startDate])

  return data
}
