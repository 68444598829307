import { Dialog, DialogContent, DialogTitle, Grid, MenuItem } from '@material-ui/core'
import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { RootContext } from '../../..'
import {
  BaseData,
  TransportTaskProfile,
  updateMessengerInfoAPI,
  UpdateMessengerInfoRequestBody,
} from '../../../api/smartbestbuys-api'
import { shippingCarOptions, shippingCars } from '../../../constants'
import { Form, GeneralTextFieldForm } from '../../../custom-components'
import { useUserData } from '../../../hooks'
import { Loading, SubmitForm } from '../../../shared-components'

interface UpdateMessengerInfoDialogProps {
  taskId: number
  taskCode: string
  messengerUserId: number
  shippingCarId?: number
  onComplete?: (data: TransportTaskProfile) => void
  onClose: () => void
}

export const UpdateMessengerInfoDialog = (props: UpdateMessengerInfoDialogProps) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { taskCode, taskId, onComplete, messengerUserId, shippingCarId, onClose } = props

  const handleSubmit = async (values: MessengerFormSubmitValues) => {
    const request: UpdateMessengerInfoRequestBody = {
      assignee: values.messenger,
      car: values.shippingCar,
    }

    try {
      const response = await updateMessengerInfoAPI(taskId, request)
      if (response && response.status === 200) {
        triggerSnackbar('แก้ไขที่อยู่จัดส่งสำเร็จ')
        if (onComplete) onComplete(response.data)
      } else {
        triggerSnackbar('แก้ไขที่อยู่จัดส่งไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('แก้ไขที่อยู่จัดส่งไม่สำเร็จ')
    }
  }

  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>แก้ไขข้อมูลผู้ส่งของงานเลขที่ {taskCode}</DialogTitle>
      <DialogContent>
        <MessengerForm
          defaultValues={{
            userId: messengerUserId,
            carId: shippingCarId,
          }}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  )
}

interface MessengerFormProps {
  defaultValues?: {
    userId: number
    carId?: number
  }
  onSubmit: (values: MessengerFormSubmitValues) => void
  onCancel: () => void
}

interface MessengerFormSubmitValues {
  messenger: BaseData
  shippingCar?: BaseData
}

interface MessengerFormValues {
  carId?: number
  userId: number
}

const MessengerForm = (props: MessengerFormProps) => {
  const { defaultValues, onSubmit, onCancel } = props
  const users = useUserData()

  const formMethod = useForm<MessengerFormValues>({ defaultValues })
  const {
    control,
    formState: { isSubmitting },
  } = formMethod

  if (!users) return <Loading />

  const handleSubmit = (values: MessengerFormValues) => {
    const { carId, userId } = values
    const user = users?.find((u) => u.id === userId)
    if (!user) throw Error(`not found user ID: ${userId}`)
    onSubmit({
      messenger: {
        id: userId,
        name: `${user.firstName} ${user.lastName}`,
      },
      shippingCar: carId ? shippingCars.find((sc) => sc.id === carId) : undefined,
    })
  }

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="carId" label="เลขทะเบียนรถ (ถ้ามี)" select>
              {shippingCarOptions.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="userId"
              label="ผู้ส่งของ *"
              rules={{ required: 'กรุณาเลือกคนส่งสินค้า' }}
              select
            >
              {users.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName} {element.lastName}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
          <Grid item xs={12}>
            <SubmitForm submitText="ยืนยันการแก้ไข" isSubmitting={isSubmitting} onCancel={onCancel} />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}
