import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      width: 120,
      height: 100,
      borderRadius: 8,
    },
    avatarBig: {
      width: 160,
      height: 160,
      borderRadius: 10,
    },
  }),
)
