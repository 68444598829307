import { Button, Paper, Typography } from '@material-ui/core'
import { useContext, useState } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import { PRVendor, PurchaseOrderUpsertData_VendorInfo } from '../../../api/smartbestbuys-api'
import { FlexBox, SubTopic } from '../../../custom-components'
import { VendorDialog } from '../../purchase-requisitions/create/VendorBox/VendorDialog'
import VendorProfileCard, { mapPurchaseOrderCreateToValue } from '../profile/VendorProfileCard'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'
import { PurchaseOrderCreatePageContext } from './PurchaseOrderCreatePage'

import './PurchaseOrderVendorBox.less'

interface Props {
  readonly?: boolean
}

const PurchaseOrderVendorBox: React.FC<Props> = (props: Props) => {
  // props
  const { readonly } = props
  const [vendorDialog, setVendorDialog] = useState(false)

  // context
  const { purchaseOrderCreate } = useContext(PurchaseOrderCreatePageContext)
  const { vendors } = purchaseOrderCreate

  // hook form
  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<PurchaseOrderCreateFormValues>()

  const { vendor: selectedVendor } = watch()

  const isSelected = !!selectedVendor
  const className = !!errors.vendor ? 'purchase-order-vendor--error' : 'purchase-order-vendor--none'

  return (
    <>
      <VendorDialog
        open={vendorDialog}
        onClose={() => {
          setVendorDialog(false)
        }}
        onVendorChange={(vendor: PRVendor | undefined) => {
          setValue('vatIncluded', !!vendor?.isVatVendor)
        }}
        vendors={vendors as PurchaseOrderUpsertData_VendorInfo[]}
      />
      {isSelected ? (
        <VendorProfileCard
          value={mapPurchaseOrderCreateToValue(selectedVendor as PurchaseOrderUpsertData_VendorInfo)}
          options={
            !readonly
              ? {
                  actionButton: {
                    text: 'เปลี่ยนผู้ขาย',
                    color: 'secondary',
                    onClick: () => {
                      setVendorDialog((prev) => !prev)
                    },
                  },
                }
              : undefined
          }
        />
      ) : (
        <>
          <Paper className={className}>
            <FlexBox justifyContent="space-between">
              <SubTopic>ผู้ขาย</SubTopic>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setVendorDialog((prev) => !prev)
                }}
              >
                เลือกผู้ขาย
              </Button>
            </FlexBox>
          </Paper>
          {!!errors.vendor && <Typography color="error">{(errors.vendor as FieldError).message}</Typography>}
        </>
      )}
    </>
  )
}

export default PurchaseOrderVendorBox
