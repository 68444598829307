import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'

export interface CustomerCreationRequest {
  customerTypeId: number
  businessTypeId?: number
  nameLocal: string
  nameEn?: string
  referenceNumber?: string
  phoneNumber: string
  websiteUrl?: string
  creditPeriodDay?: string
  email?: string
  isDealer?: boolean
}

export interface CreatedCustomer {
  id: number
  code: string
  customerTypeId: number
  businessTypeId?: number
  nameLocal: string
  nameEn?: string
  referenceNumber?: string
  phoneNumber?: string
  websiteUrl?: string
  creditPeriodDay?: string
  email?: string
  recCreatedBy: string
  recCreatedAt: Date
}

export const createCustomerAPI = errorHandler<CreatedCustomer>((request: CustomerCreationRequest) => {
  const path = `/customers`
  return smartAuthAPI.post(path, request)
})
