import { Box, Paper, TableCell, TableRow } from '@material-ui/core'
import numeral from 'numeral'
import React, { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { EmptyCard, SubTopic, Table } from '../../../custom-components'
import { SelectedProduct } from '../../../sales-system/types'
import { Space } from '../../../shared-components'
import { QuotationUpdationFormValue } from './QuotationUpdationFormValue'
import { QuotationSelectedProductColumn } from '../QuotationCreationPage/quotation-presentation/QuotationSelectedProductColumn'
import { calculatePriceBreakdowns } from '../../../sales-system/utils/sale-order-price-calculator'
import { QuotationUpdationPageContextImpl } from './QuotationUpdationPage'

interface QuotationUpdationSelectedProductProps {
  readOnly?: boolean
  showPriceBreakdown?: boolean
}

const QuotationUpdationSelectedProduct: React.FC<QuotationUpdationSelectedProductProps> = ({
  readOnly,
  showPriceBreakdown,
}) => {
  const { data } = useContext(QuotationUpdationPageContextImpl)

  const isDealer = data.profile.customer.isDealer

  const { watch, setValue } = useFormContext<QuotationUpdationFormValue>()

  const values = watch()

  const { selectedProducts } = values

  useEffect(() => {
    const selectedProducts: SelectedProduct[] = data.profile.products.map((p) => {
      const existsProduct = data.products.find((sp) => sp.id === p.id)
      return {
        ...p,
        productNameLocal: existsProduct?.nameLocal,
        productNameEn: existsProduct?.nameEn,
        dealerPrice: existsProduct?.dealerPrice,
      }
    })
    setValue('selectedProducts', selectedProducts)
    // eslint-disable-next-line
  }, [data.profile.products, setValue])

  const priceBreakdowns = getPricebreakdown(values, showPriceBreakdown)

  return (
    <Box p={3} clone>
      <Paper>
        <SubTopic gutterBottom>{readOnly ? 'ข้อมูลสินค้า/บริการ' : 'สินค้า / บริการที่เลือก'}</SubTopic>
        <Space />
        {selectedProducts.length === 0 ? (
          <EmptyCard emptyText="ยังไม่ได้เลือกสินค้า/บริการ" />
        ) : (
          <Table<SelectedProduct>
            columns={QuotationSelectedProductColumn(readOnly, isDealer)}
            data={selectedProducts}
            pagination={false}
            size="small"
            color="primary.dark"
            emptyText="ยังไม่เลือกสินค้า/บริการ"
            postCells={
              <>
                {priceBreakdowns.map((price) => (
                  <TableRow key={price.name}>
                    <TableCell colSpan={3} />
                    <TableCell align="right">{price.name}</TableCell>
                    <TableCell align="right">{numeral(price.amount).format('0,0.00')}</TableCell>
                  </TableRow>
                ))}
              </>
            }
            cleanSpace
          />
        )}
      </Paper>
    </Box>
  )
}

interface PriceDetail {
  name: string
  amount: number
}

const getPricebreakdown = (values: QuotationUpdationFormValue, showPriceBreakdown?: boolean): PriceDetail[] => {
  const priceBreakdowns = calculatePriceBreakdowns(values)

  const { productTotal, discount, shippingFee, vatAmount, total } = priceBreakdowns

  if (!showPriceBreakdown) {
    return [{ name: 'รวมเงิน', amount: productTotal }]
  }

  return [
    { name: 'รวมเงิน', amount: productTotal },
    { name: 'ส่วนลด', amount: discount },
    { name: 'ค่าขนส่ง', amount: shippingFee },
    { name: 'ยอดหลังหักส่วนลด', amount: productTotal - discount },
    { name: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
    { name: 'ยอดเงินสุทธิ', amount: total },
  ]
}

export default QuotationUpdationSelectedProduct
