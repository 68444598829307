import { Box, Grid, Paper } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { FlexBox, GeneralTextFieldForm, KeyboardDatePickerForm, SubTopic } from '../../../custom-components'
import { Space, UploadImageCard } from '../../../shared-components'
import UserCreateFromValue from './UserCreateFromValue'

const UserCreateInfoBox: React.FC = () => {
  const { control, setValue } = useFormContext<UserCreateFromValue>()

  return (
    <Box p={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>ข้อมูลทั่วไป</SubTopic>
            <Space />
            <FlexBox justifyContent="center">
              <UploadImageCard
                previewOnChange
                title="ภาพโปรไฟล์ (ถ้ามี)"
                onChange={(image) => setValue('base64', image.base64)}
              />
            </FlexBox>
            <Space />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="firstName"
              label="ชื่อ *"
              rules={{ required: 'กรุณากรอกชื่อ' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="lastName"
              label="นามสกุล *"
              rules={{ required: 'กรุณากรอกนามสกุล' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="nickName"
              label="ชื่อเล่น *"
              rules={{ required: 'กรุณากรอกชื่อเล่น' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyboardDatePickerForm
              control={control}
              label="วันเกิด (ถ้ามี)"
              name="birthDate"
              size="small"
              gutterTop
              value={``}
              fullWidth
              defaultValue={null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="phoneNumber" label="เบอร์โทรศัพท์ (ถ้ามี)" />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="email" label="อีเมล (ถ้ามี)" />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default UserCreateInfoBox
