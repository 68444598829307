import { DialogProps } from '@material-ui/core'

import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import { cancelRefundRequisitionAPI, IRefundRequisitionProfile } from '../../../api/smartbestbuys-api'
import React, { useContext } from 'react'
import { RootContext } from '../../..'

interface RefundRequisitionInfo {
  id: number
  code: string
}

interface Props extends DialogProps {
  refundRequisitionInfo: RefundRequisitionInfo
  onComplete?: (data: IRefundRequisitionProfile) => void
  onFail?: () => void
}

const RefundRequisitionCancelDialog: React.FC<Props> = (props: Props) => {
  // set up props & state
  const { refundRequisitionInfo, onComplete, onFail, onClose, ...dialogProps } = props

  const { triggerSnackbar } = useContext(RootContext)

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยกเลิกการคืนเงิน',
    submitText: 'ยืนยันการยกเลิก',
    cancelText: 'ย้อนกลับ',
    actionMessageText: 'สาเหตุที่ยกเลิก',
    newActionMessageText: 'สาเหตุใหม่',
    newActionMessageOption: 'เพิ่มสาเหตุใหม่',
    actionMessageErrorText: 'กรุณากรอกสาเหตุของการยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const response = await cancelRefundRequisitionAPI(refundRequisitionInfo.id, {
      userAction: values.actionId,
      actionMessageId: values.actionMessageId === -1 ? undefined : values.actionMessageId,
      actionMessage: values.actionMessage,
      remark: values.remark,
    })

    if (response?.status === 200) {
      triggerSnackbar('ยกเลิกการคืนเงินสำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
      window.location.reload()
    } else {
      triggerSnackbar('ยกเลิกการคืนเงินไม่สำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      onClose={() => onClose && onClose({}, 'escapeKeyDown')}
      fullWidth
      userAction={UserAction.CancelRefundRequisition}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default RefundRequisitionCancelDialog
