import { GridBox, Loading } from '../../shared-components'
import { NivoColorPalette, ProgressBar } from '../../visaulization-components'

interface ProgressBarBoxProps {
  title: string
  value: number
  unit?: string
  color?: NivoColorPalette
  loading?: boolean
  size?: 'big'
}

const ProgressBarBox = (props: ProgressBarBoxProps) => {
  const { title, value, unit, color, size, loading } = props
  let rowSize = 5
  if (size === 'big') rowSize = 6

  return (
    <GridBox item xs={6} md={3} row={6}>
      {loading ? (
        <Loading />
      ) : (
        <ProgressBar title={title} amount={value} color={color} rowSize={rowSize} unit={unit} progress={value} />
      )}
    </GridBox>
  )
}

export default ProgressBarBox
