import {
  CancelProductWithdrawalTicketRequestBody,
  ConfirmReturnedWithdrawnProductRequestBody,
  CreateProductWithdrawalTicketRequestBody,
  FollowupProductWithdrawalTicketRequestBody,
  GetWithdrawalTicketTableDataQueryParams,
  IProductWithdrawalTicket,
  IProductWithdrawalTicketProfile,
  IWithdrawalTicketTableData,
  RejectProductWithdrawalTicketRequestBody,
  UpdateProductWithdrawalTicketRequestBody,
} from '.'
import { errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const getProductWithdrawalTicketProfileAPI = errorSingleHandler<IProductWithdrawalTicketProfile>(
  async (id: number) => {
    return smartAuthAPI.get(`/warehouse/withdrawal-tickets/${id}/profile`)
  },
)

export const getWithdrawalTicketTableDataAPI = errorHandler<IWithdrawalTicketTableData>(
  async (queryParams: GetWithdrawalTicketTableDataQueryParams) => {
    return smartAuthAPI.get(`/warehouse/withdrawal-tickets/table-data`, { params: queryParams })
  },
)

export const createProductWithdrawalTicketAPI = errorHandler<IProductWithdrawalTicket>(
  async (request: CreateProductWithdrawalTicketRequestBody) => {
    return smartAuthAPI.post(`/warehouse/withdrawal-tickets/create`, request)
  },
)

export const updateProductWithdrawalTicketAPI = errorSingleHandler<IProductWithdrawalTicket>(
  async (id: number, request: UpdateProductWithdrawalTicketRequestBody) => {
    return smartAuthAPI.put(`/warehouse/withdrawal-tickets/${id}/update`, request)
  },
)

export const approveProductWithdrawalTicketAPI = errorSingleHandler<IProductWithdrawalTicketProfile>(
  async (id: number) => {
    return smartAuthAPI.post(`/warehouse/withdrawal-tickets/${id}/approve`)
  },
)

export const rejectProductWithdrawalTicketAPI = errorSingleHandler<IProductWithdrawalTicketProfile>(
  async (id: number, request: RejectProductWithdrawalTicketRequestBody) => {
    return smartAuthAPI.post(`/warehouse/withdrawal-tickets/${id}/reject`, request)
  },
)

export const cancelProductWithdrawalTicketAPI = errorSingleHandler<IProductWithdrawalTicketProfile>(
  async (id: number, request: CancelProductWithdrawalTicketRequestBody) => {
    return smartAuthAPI.post(`/warehouse/withdrawal-tickets/${id}/cancel`, request)
  },
)

export const confirmReceivedWithdrawnProductAPI = errorSingleHandler<IProductWithdrawalTicketProfile>(
  async (id: number, request: { remark?: string }) => {
    return smartAuthAPI.post(`/warehouse/withdrawal-tickets/${id}/confirm-received`, request)
  },
)

export const confirmReturnedWithdrawalProductAPI = errorSingleHandler<IProductWithdrawalTicketProfile>(
  async (id: number, request: ConfirmReturnedWithdrawnProductRequestBody) => {
    return smartAuthAPI.post(`/warehouse/withdrawal-tickets/${id}/confirm-returned`, request)
  },
)

export const partialUpdateProductWithdrawalTicketByIdAPI = errorSingleHandler<IProductWithdrawalTicketProfile>(
  async (id: number, request: FollowupProductWithdrawalTicketRequestBody) => {
    return smartAuthAPI.post(`/warehouse/withdrawal-tickets/${id}/partial-update`, request)
  },
)
