import { useState, useEffect } from 'react'
import { getPaymentRequisitionPDFInfo, PaymentRequisitionPDFInfo } from '../../api/smartbestbuys-api'

export const usePaymentRequisitionPDFInfo = (id: number, reload?: boolean) => {
  const [data, setData] = useState<PaymentRequisitionPDFInfo | undefined>(undefined)

  const _getPaymentRequisitionPDFInfo = async (id: number) => {
    const response = await getPaymentRequisitionPDFInfo(id)
    setData(response?.data)
  }

  useEffect(() => {
    _getPaymentRequisitionPDFInfo(id)
  }, [id, reload])

  return data
}
