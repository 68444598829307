import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { ProductPrice, ProductUserAcivity } from './types'
import { BaseData } from '../types'
import { IProduct } from './product-types'

export interface ProductProfile extends IProduct {
  saleAmount: number

  prices: ProductPrice[]
  activities?: ProductUserAcivity[]

  reservedList: ProductReservedInfo[]
  purchaseOrders: ProductProfile_PurchaseOrderInfo[]

  dataScore: number
  pairedProducts: Pick<IProduct, 'id' | 'code' | 'nameLocal' | 'imageUrl'>[]
}

export interface ProductReservedInfo {
  code: string
  amount: number
  reservedAmount: number
  shippedAmount: number
  pendingShippingAmount: number
  customerName: string
}

export interface ProductProfile_PurchaseOrderInfo {
  id: number
  code: string
  status: BaseData
  amount: number
  receivedAmount: number
  recCreatedAt: Date
  recCreatedBy: string
}

export const getProductProfile = errorHandler<ProductProfile>(async (productId: number) => {
  const path = `/products/${productId}/profile`
  return await smartAuthAPI.get(path)
})
