import { Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { PurchaseProductPurchaseHistory } from '../../../../../api/smartbestbuys-api'
import { Table } from '../../../../../custom-components'
import { dateTimeFormat } from '../../../../../functions'
import { PurchaseOrderStatusChip } from '../../../../../shared-components'
import PurchaseOrderCreateFormValues from '../../PurchaseOrderCreateFormValues'

interface Props {
  purchaseProductHistory?: PurchaseProductPurchaseHistory[]
  currency?: string
}

interface PurchaseOrderProductHistoryTableData extends PurchaseProductPurchaseHistory {
  id: number
}

const PurchaseOrderProductHistoryTable: React.FC<Props> = (props: Props) => {
  const { purchaseProductHistory, currency } = props

  const { watch } = useFormContext<PurchaseOrderCreateFormValues>()

  const { vendor } = watch()

  const filterPurchaseProductHistory = purchaseProductHistory?.filter((product) => product.vendor.id === vendor?.id)

  return (
    <Table<PurchaseOrderProductHistoryTableData>
      size="small"
      color="primary.dark"
      columns={[
        {
          name: 'เลขที่ใบสั่งซื้อ',
          fixedWidth: 140,
          render: ({ code }) => code,
        },
        {
          name: 'สถานะใบสั่งซื้อ',
          fixedWidth: 120,
          render: ({ status }) => {
            return <PurchaseOrderStatusChip label={status.name} size="small" status={status.id} />
          },
        },
        {
          name: 'จำนวน',
          fixedWidth: 227,
          numeric: true,
          format: '0,0',
          render: ({ amount }) => amount,
        },
        {
          name: 'ราคาต่อหน่วย',
          fixedWidth: 200,
          numeric: true,
          render: ({ price }) => (
            <Typography variant="body2" className="float-right">
              {price} {currency || 'บาท'}
            </Typography>
          ),
        },
        {
          name: 'วันที่ตกลงกับผู้ขาย',
          fixedWidth: 140,
          render: ({ vendorShippingDate }) => (vendorShippingDate ? dateTimeFormat(vendorShippingDate) : undefined),
        },
      ]}
      data={filterPurchaseProductHistory || []}
      cleanSpace
      pagination
    />
  )
}

export default PurchaseOrderProductHistoryTable
