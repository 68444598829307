import { useState, useEffect } from 'react'
import { FetchPurchaseOrder, fetchPurchaseOrders, FetchRequest } from '../../api/smartbestbuys-api'

export const usePurchaseOrders = (startDate: Date, endDate: Date): FetchPurchaseOrder[] => {
  const [data, setData] = useState<FetchPurchaseOrder[]>([])

  const _fetchPurchaseOrders = async (request: FetchRequest) => {
    const response = await fetchPurchaseOrders(request)
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchPurchaseOrders({ startDate, endDate })
  }, [startDate, endDate])

  return data
}
