/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
} from '@material-ui/core'
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles'
import { withLayout, Topic, Link, FlexBox, GeneralTextField, Table } from '../../custom-components'
import {
  BlackListSignal,
  ConditionTooltip,
  CreditSignal,
  CustomerTierChip,
  ImageIcon,
  Loading,
} from '../../shared-components'
import { RootContext } from '../..'
import { useContext } from 'react'
import { PageName } from '../../enums'
import { getUserPermissionInfo } from '../../utils/permission'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { useCustomerTableData } from '../../hooks/customer/useCustomerTableData'
import { ICustomerTableData_Data } from '../../api/smartbestbuys-api'
import { dateFormat, getUser } from '../../functions'
import dealerLogo from '../../assets/images/dealer-logo.png'
import { useUserData } from '../../hooks'
import { customerBusinessTypes } from '../../constants'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: 440,
    },
    formControl: {
      marginTop: -8,
      width: '100%',
    },
    selectControl: {
      border: '1px solid grey',
      borderRadius: '4px',
      padding: '4px',
    },
    selectLabel: {
      paddingLeft: '4px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  }),
)

function getStyles(value: number, filterTier: number[], theme: Theme) {
  return {
    fontWeight: filterTier.find((row) => row === value)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  }
}

const CustomerTableDataPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER)

  const theme = useTheme()
  const classes = useStyles()

  const userInfo = getUser()

  const tableData = useCustomerTableData(userInfo?.id)
  const userData = useUserData()

  const customerTierOptions = [
    { name: 'Wisdom', value: 3 },
    { name: 'Diamond', value: 2 },
    { name: 'Platinum', value: 1 },
    { name: 'ลูกค้าทั่วไป', value: 0 },
  ]

  const creditOptions = [
    { name: 'ทั้งหมด', value: 'all' },
    { name: 'ลูกค้าเครดิต', value: 'true' },
    { name: 'ไม่ใช่ลูกค้าเครดิต', value: 'false' },
  ]

  const blacklistOptions = [
    { name: 'ทั้งหมด', value: 'all' },
    { name: 'ลูกค้าบัญชีดำ', value: 'true' },
    { name: 'ไม่ใช่ลูกค้าบัญชีดำ', value: 'false' },
  ]

  const dealerOptions = [
    { name: 'ทั้งหมด', value: 'all' },
    { name: 'ลูกค้าดีลเลอร์', value: 'true' },
    { name: 'ไม่ใช่ลูกค้าดีลเลอร์', value: 'false' },
  ]

  if (!tableData || !userData) return <Loading />
  const {
    data,
    paginationInfo,
    pendingSearch,
    isLoading,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
  } = tableData

  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Write, true)
  const deletePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Delete, true)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <Topic>รายการลูกค้า</Topic>
              {deletePermission.hasPermission && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={pendingSearch.isActive === 'false'}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleSearchParamChange('isActive', (!event.target.checked).toString(), true)
                      }}
                      color="primary"
                      className="ml-6"
                    />
                  }
                  label={`แสดงลูกค้าที่ยกเลิก`}
                />
              )}
            </FlexBox>
            <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('/customers/create')}
                disabled={!writePermission.hasPermission}
              >
                สร้างลูกค้า
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="name"
                  onChange={(e) => handleSearchParamChange('name', e.target.value)}
                  label="ชื่อ"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="phoneNumber"
                  onChange={(e) => handleSearchParamChange('phoneNumber', e.target.value)}
                  label="เบอร์โทรศัพท์"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="referenceNumber"
                  onChange={(e) => handleSearchParamChange('referenceNumber', e.target.value)}
                  label="เลขที่ผู้เสียภาษี"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  name="isCredit"
                  label="เครดิต"
                  select
                  onChange={(e) => handleSearchParamChange('isCredit', e.target.value)}
                >
                  {creditOptions.map((credit) => (
                    <MenuItem key={credit.value} value={credit.value}>
                      {credit.name}
                    </MenuItem>
                  ))}
                </GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  name="isBlackList"
                  label="แบล็คลิส"
                  select
                  onChange={(e) => handleSearchParamChange('isBlackList', e.target.value)}
                >
                  {blacklistOptions.map((blacklist) => (
                    <MenuItem key={blacklist.value} value={blacklist.value}>
                      {blacklist.name}
                    </MenuItem>
                  ))}
                </GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  name="isDealer"
                  label="ดีลเลอร์"
                  select
                  onChange={(e) => handleSearchParamChange('isDealer', e.target.value)}
                >
                  {dealerOptions.map((dealer) => (
                    <MenuItem key={dealer.value} value={dealer.value}>
                      {dealer.name}
                    </MenuItem>
                  ))}
                </GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="tier-select-label" style={{ paddingLeft: 16, paddingTop: 4 }}>
                    Tier
                  </InputLabel>
                  <Select
                    labelId="tier-select-label"
                    id="tier-select"
                    multiple
                    value={pendingSearch.tierList ?? []}
                    onChange={({ target: { value } }) => {
                      handleSearchParamChange('tierList', value as number[])
                    }}
                    input={<Input />}
                    MenuProps={MenuProps}
                    className={classes.selectControl}
                    name="tierList"
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as number[]).map((value) => (
                          <Chip
                            className={classes.chip}
                            key={value}
                            label={customerTierOptions.find((row) => row.value === value)?.name}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {customerTierOptions.map((tier) => (
                      <MenuItem
                        key={tier.name}
                        value={tier.value}
                        style={getStyles(tier.value, pendingSearch.tierList as number[], theme)}
                      >
                        {tier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  name="businessTypeId"
                  label="ประเภทธุรกิจลูกค้า"
                  select
                  onChange={(e) => handleSearchParamChange('businessTypeId', e.target.value)}
                >
                  {customerBusinessTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  name="saleUserId"
                  label="เซลล์ผู้รับผิดชอบ"
                  select
                  onChange={(e) => handleSearchParamChange('saleUserId', e.target.value)}
                >
                  <MenuItem key={0} value={0}>
                    ทั้งหมด
                  </MenuItem>
                  {userData.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      <div>
                        {user.firstName} {user.lastName} {user.nickName ? `(${user.nickName})` : ''}
                      </div>
                    </MenuItem>
                  ))}
                </GeneralTextField>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="button"
                  type="submit"
                  startIcon={isLoading ? <CircularProgress size={8} /> : undefined}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<ICustomerTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: '',
                render: ({ isBlacklist, creditPeriodDay, tier, isDealer }) => (
                  <FlexBox gridGap={8}>
                    {tier && <CustomerTierChip tier={tier} size="small" />}
                    {isBlacklist && <BlackListSignal />}
                    {!!creditPeriodDay && <CreditSignal />}
                    {isDealer && <ImageIcon name="dealer" src={dealerLogo} />}
                  </FlexBox>
                ),
                fixedWidth: 80,
              },
              {
                name: 'รายละเอียดลูกค้า',
                render: ({ id, code, nameLocal, businessTypeName, referenceNumber }) => (
                  <>
                    <Link color="primary" href={`/customers/${id}/profile`}>
                      {code}
                    </Link>
                    <div>{nameLocal}</div>
                    {referenceNumber && <div>เลขที่ผู้เสียภาษี: {referenceNumber}</div>}
                    {businessTypeName && <div>ประเภทธุรกิจ: {businessTypeName}</div>}
                  </>
                ),
              },
              {
                name: 'เบอร์โทรศัพท์',
                dataIndex: 'phoneNumber',
                render: ({ phoneNumber }) => {
                  return phoneNumber || '-'
                },
              },
              {
                name: 'อีเมล',
                dataIndex: 'email',
                render: ({ email }) => {
                  return email || '-'
                },
              },
              {
                name: 'วันที่เข้าระบบ',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => {
                  return dateFormat(new Date(recCreatedAt))
                },
              },
            ]}
            data={data}
            size="small"
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withLayout(CustomerTableDataPage)
