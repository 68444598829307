import { Box, Paper, Grid } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { SubTopic, TypographyWithLabel } from '../../custom-components'
import SOCreationFormValues from '../pages/SOCreationPage/SOCreationFormValues'
import { format } from 'date-fns'
import th from 'date-fns/locale/th'
import {
  getContactChannelNameById,
  getCurrencyNameById,
  getPaymentMethodNameById,
  getPaymentConditionNameById,
  getCustomerPickUpMethodNameById,
} from '../../types'
import RemarkDetail from './RemarkDetail'
import thLocale from 'date-fns/locale/th'
import { ICustomerProfile } from '../../api/smartbestbuys-api'

interface Props {
  customerProfile?: ICustomerProfile
}

const SaleOrderInfo: React.FC<Props> = (props) => {
  const { getValues } = useFormContext<SOCreationFormValues>()
  const {
    customerPurchaseReference,
    contactChannelId,
    currencyId,
    exchangeRate,
    paymentMethodId,
    paymentConditionId,
    customerPickUpMethod,
    dueDate,
    depositRequired,
    remark,
    paymentDueDate,
  } = getValues()

  const infoItems = [
    { label: 'เลขที่การสั่งซื้อของลูกค้า:', value: customerPurchaseReference },
    { label: 'ช่องทางการติดต่อ:', value: contactChannelId ? getContactChannelNameById(contactChannelId) : 'ไม่ระบุ' },
    { label: 'สกุลเงิน:', value: currencyId ? getCurrencyNameById(currencyId) : 'ไม่ระบุ' },
    { label: 'อัตราแลกเปลี่ยน:', value: exchangeRate },
    { label: 'วิธีการชำระเงิน:', value: paymentMethodId ? getPaymentMethodNameById(paymentMethodId) : 'ไม่ระบุ' },
    {
      label: 'เงื่อนไขการชำระเงิน:',
      value: paymentConditionId ? getPaymentConditionNameById(paymentConditionId) : 'ไม่ระบุ',
    },
    {
      label: 'วิธีการรับสินค้า:',
      value: customerPickUpMethod ? getCustomerPickUpMethodNameById(customerPickUpMethod) : 'ไม่ระบุ',
    },
    {
      label: 'วันกำหนดชำระเงิน:',
      value: paymentDueDate
        ? format(paymentDueDate, 'dd MMM yyyy', {
            locale: thLocale,
          })
        : 'ไม่ระบุ',
    },
    { label: 'การมัดจำ:', value: depositRequired ? 'ต้องการมัดจำก่อนส่งสินค้า' : 'ไม่ต้องมัดจำ' },
    { label: 'วันที่ลูกค้าต้องการ:', value: dueDate ? format(dueDate, 'dd MMM yyyy', { locale: th }) : 'ไม่ระบุ' },
    { label: 'หมายเหตุ:', value: remark || '-' },
  ]

  return (
    <Box p={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>ข้อมูลใบสั่งขาย</SubTopic>
          </Grid>
          {infoItems.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <TypographyWithLabel label={item.label}>{item.value}</TypographyWithLabel>
            </Grid>
          ))}
          <Grid item xs={12}>
            <RemarkDetail />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default SaleOrderInfo
