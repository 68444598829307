import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { NotiItem, NotiTeam, notiTeamColors } from '../../api/smartbestbuys-api/general/general-types'

interface Props {
  item: NotiItem
}

const useStyles = (team: NotiTeam) =>
  makeStyles(() => {
    return createStyles({
      chip: {
        backgroundColor: notiTeamColors[team],
      },
    })
  })

const NotificationChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ item }: Props) => {
  const classes = useStyles(item.team)()

  return <Chip className={classes.chip} label={item.name} />
}

export default NotificationChip
