import React, { useContext, useMemo, useState } from 'react'
import { Grid, Paper, Typography, Chip, Button } from '@material-ui/core'
import { FlexBox, Avatar, Title, TypographyWithLabel } from '../../../../../../custom-components'
import { Edit } from '@material-ui/icons'
import { useStyles } from './style'
import ProductProfileContext from '../../ProductProfileContext'
import { Photo } from '@material-ui/icons'
import { ProductInformationDialog } from '../ProductInformationDialog'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { ConditionTooltip, DeadStockSignal } from '../../../../../../shared-components'
import { ProductType } from '../../../../../../enums'
import { formatNumber } from '../../../../../../functions/number-utils'
import { formatSizeMMText, formatWeightGramText } from '../../../../../../functions'
import { PendingToReviewProductIcon, VerifyProductIcon } from '../../../../../../shared-components/SmartIcon'
import { CircularProgressWithLabel } from '../../../../ProductTableDataPage'

const ProductProfileCard: React.FC = () => {
  const classes = useStyles()
  const { productProfile } = useContext(ProductProfileContext)
  const [openProductInformationDialog, setOpenProductInformationDialog] = useState(false)

  const {
    code,
    type,
    imageUrl,
    nameLocal,
    nameEn,
    unitLocal,
    unitEn,
    businessUnit,
    category,
    subCategory,
    warrantyPeriodDay,
    leadTime,
    safetyStockPolicy,
    dealerPrice,
    saleAmount,
    brand,
    color,
    material,
    widthInMm,
    heightInMm,
    depthInMm,
    weightInGram,
    warrantyCondition,
    karajicMatrix,
    verifiedBy,
    requestChangedData,
    recDeletedAt,
    dataScore,
  } = productProfile

  const isProductAvailable = !recDeletedAt

  const writePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Write)

  const title = useMemo(() => {
    return type.id === ProductType.Service ? 'บริการ' : 'สินค้า'
  }, [type.id])

  return (
    <Paper style={{ height: '100%' }}>
      {openProductInformationDialog && (
        <ProductInformationDialog
          open={openProductInformationDialog}
          onClose={() => setOpenProductInformationDialog(false)}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Avatar variant="rounded" src={imageUrl} className={classes.avatar} objectFit="contain">
            <Photo />
          </Avatar>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container>
            <Grid item xs={12}>
              <FlexBox justifyContent="space-between">
                <FlexBox gridGap={2} alignItems="center">
                  <Typography variant="h6" color="textSecondary" component="span">
                    {code}
                  </Typography>
                  <Chip label={type.name} size="small" className={classes.chip} />
                  {!isProductAvailable && <Chip label="ยกเลิกการขายแล้ว" size="small" className={classes.chip} />}
                  {!!verifiedBy && <VerifyProductIcon />}
                  {!!requestChangedData && <PendingToReviewProductIcon />}
                  {saleAmount === 0 && <DeadStockSignal />}
                  <CircularProgressWithLabel value={Math.ceil(dataScore)} />
                </FlexBox>
                {isProductAvailable && (
                  <ConditionTooltip
                    title={!!requestChangedData ? 'มีการขอแก้ไขข้อมูลสินค้าอยู่' : writePermission.alertMessage}
                    showTooltip={!!requestChangedData || !writePermission.hasPermission}
                  >
                    <Button
                      color="primary"
                      startIcon={<Edit />}
                      onClick={() => setOpenProductInformationDialog(true)}
                      disabled={!writePermission.hasPermission || !!requestChangedData}
                    >
                      ขอแก้ไขข้อมูล
                    </Button>
                  </ConditionTooltip>
                )}
              </FlexBox>
            </Grid>
            <Grid item xs={12}>
              <Title>{nameLocal}</Title>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{nameEn}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="หน่วย (ภาษาไทย):">{unitLocal || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="หน่วย (English):">{unitEn || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="กลุ่มธุรกิจ:">{businessUnit?.name || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label={`หมวดหมู่${title}:`}>{category?.name || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="หมวดหมู่ย่อย:">{subCategory?.name || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="ระยะเวลาประกัน:">
            {warrantyPeriodDay ? `${warrantyPeriodDay} วัน` : 'ไม่มีประกัน'}
          </TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label={`ระยะการสั่ง${title}เฉลี่ย:`}>{`${leadTime} วัน`}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="นโยบายเก็บ:">{`${safetyStockPolicy} วัน`}</TypographyWithLabel>
        </Grid>
        {!!dealerPrice && (
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ราคาสำหรับ dealer:">{formatNumber(dealerPrice)}</TypographyWithLabel>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="ยี่ห้อ:">{brand || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="สี:">{color || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="วัสดุ:">{material || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="ยาว:">{formatSizeMMText(depthInMm) || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="กว้าง:">{formatSizeMMText(widthInMm) || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="สูง:">{formatSizeMMText(heightInMm) || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="น้ำหนัก:">{formatWeightGramText(weightInGram) || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="เงื่อนไขการรับประกันสินค้า:">{warrantyCondition || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="karajic matrix:">{karajicMatrix?.name || '-'}</TypographyWithLabel>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductProfileCard
