import { SaleOrderPartialUpdateRequest } from '../../../../api/smartbestbuys-api/sale/sale-types'
import SaleOrderPartialUpdateDialogFromValues from './SaleOrderPartialUpdateDialogFromValues'

export const mapPartialUpdateFromValuesToSaleOrderPartialUpdateRequest = (
  value: SaleOrderPartialUpdateDialogFromValues,
): SaleOrderPartialUpdateRequest => {
  return {
    paymentConditionId: value.paymentConditionId,
    customerPickUpMethod: value.customerPickUpMethod,
    customerPurchaseReference: value.dontHasCustomerPurchaseReference ? 'ไม่มี PO' : value.customerPurchaseReference,
    dueDate: value.dueDate?.toISOString(),
    assigneeUserId: value?.assigneeUserId,
  }
}
