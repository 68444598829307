import { Box, Button, Grid, Paper, TablePagination, Typography } from '@material-ui/core'
import { GeneralTextField, SubTopic } from '../../../../custom-components'
import PWTicketProductCard from './PWTicketProductCard'
import { useFormContext } from 'react-hook-form'
import { PWTicketFormValues } from './PWTicketFormValues'
import { IUseTableData } from '../../../../types/pagination'
import { IProductTableData_Data, IProductTableData_SearchParams } from '../../../../api/smartbestbuys-api'

interface Props {
  productTableData: IUseTableData<IProductTableData_Data, IProductTableData_SearchParams, {}>
  defaultValues?: PWTicketFormValues
}

const PWTicketFormProduct: React.FC<Props> = ({ productTableData }) => {
  const {
    formState: { errors },
  } = useFormContext<PWTicketFormValues>()

  const { data, paginationInfo, handlePageChange, handleSearchParamChange, handleSearch } = productTableData

  return (
    <Box
      id="products-selector-input-box"
      border={errors.products ? 'solid' : 'none'}
      style={{ borderColor: 'red' }}
      clone
    >
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>เลือกสินค้าสำหรับเบิก</SubTopic>
            {errors.products && (
              <Typography variant="body2" color="error">
                กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <GeneralTextField
              variant="outlined"
              name="code"
              onChange={(e) => handleSearchParamChange('code', e.target.value)}
              label="รหัสสินค้า"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <GeneralTextField
              variant="outlined"
              name="name"
              onChange={(e) => handleSearchParamChange('name', e.target.value)}
              label="ชื่อสินค้า"
            />
          </Grid>

          <Grid item xs={10} />
          <Grid item xs={2}>
            <Button variant="contained" color="primary" onClick={handleSearch} size="large" fullWidth>
              ค้นหา
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {data.map((product) => {
                return (
                  <Grid key={product.id} item xs={12} sm={6} md={3} xl={2}>
                    <PWTicketProductCard product={product} />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TablePagination
              component="div"
              count={paginationInfo.totalData}
              page={paginationInfo.pageIndex}
              onChangePage={(_, pageIndex) => handlePageChange(pageIndex)}
              rowsPerPage={paginationInfo.rowPerPage}
              rowsPerPageOptions={[]}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default PWTicketFormProduct
