import { useState, useEffect } from 'react'
import { fetchDocumentBillingNotes, BillingNote, FetchRequest } from '../api/smartbestbuys-api'

export const useDocumentBillingNotes = (startDate: Date, endDate: Date): BillingNote[] => {
  const [documents, setDocuments] = useState<BillingNote[]>([])

  const _fetchDocumentBillingNotes = async (request: FetchRequest) => {
    const response = await fetchDocumentBillingNotes(request)
    setDocuments(response?.data || [])
  }

  useEffect(() => {
    _fetchDocumentBillingNotes({ startDate, endDate })
  }, [startDate, endDate])

  return documents
}
