import { FC } from 'react'
import ReactPDF, { View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  table: {
    width: '100%',
  },
})

const PDFTable: FC<ReactPDF.ViewProps> = (props) => {
  const { style, ...otherProps } = props
  return (
    <View
      style={{
        ...styles.table,
        ...style,
      }}
      {...otherProps}
    />
  )
}

export default PDFTable
