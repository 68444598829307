import React, { useContext, useState } from 'react'
import { Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { FilteredTable, Title, Link } from '../../../../../custom-components'
import ProductProfileContext from '../ProductProfileContext'
import { dateFormat } from '../../../../../functions'
import {
  IPurchaseOrder,
  IPurchaseOrderProduct,
  IVendorContactDB,
  IVendorDB,
} from '../../../../../api/smartbestbuys-api/interfaces'

const ProductPurchaseOrderTable: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const { productProfile } = useContext(ProductProfileContext)
  const { pendingReceivedPurchaseOrders, donePurchaseOrders } = productProfile

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>รายงาน PO</Title>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, value) => setTabIndex(value)}
            centered
            variant="fullWidth"
          >
            <Tab label="PO ค้างรับ" value={0} />
            <Tab label="PO ที่รับครบแล้ว" value={1} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {tabIndex === 0 ? (
            <FilteredTable<
              Pick<IPurchaseOrder, 'id' | 'code' | 'status' | 'vendorShippingDate' | 'recCreatedAt'> &
                Pick<
                  IPurchaseOrderProduct,
                  'amount' | 'price' | 'productTotal' | 'receivedAmount' | 'pendingReceivedAmount' | 'incomingAmount'
                > & {
                  vendor: Pick<IVendorDB, 'id' | 'name'>
                  vendorContact: Pick<IVendorContactDB, 'id' | 'name'>
                }
            >
              color="primary.dark"
              columns={[
                {
                  name: 'วันที่บันทึก',
                  render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                },
                {
                  name: 'เลขที่ PO',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/purchase-orders/${id}/profile`}>
                      <Typography variant="body2">{code}</Typography>
                    </Link>
                  ),
                },
                {
                  name: 'ชื่อผู้ติดต่อ',
                  render: ({ vendorContact, vendor }) => <div>{vendorContact?.name ?? vendor.name ?? '-'}</div>,
                },
                {
                  name: 'จำนวน',
                  dataIndex: 'amount',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'ราคา (บาท)',
                  dataIndex: 'price',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'จำนวนเงิน',
                  dataIndex: 'productTotal',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'จำนวนที่ได้รับแล้ว',
                  dataIndex: 'receivedAmount',
                  numeric: true,
                  format: '0,0',
                },
                {
                  hide: true,
                  name: 'จำนวนที่กำลังได้รับ',
                  dataIndex: 'incomingAmount',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'จำนวนที่ค้างรับ',
                  dataIndex: 'pendingReceivedAmount',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'วันที่ต้องได้รับ',
                  render: ({ vendorShippingDate }) => (vendorShippingDate ? dateFormat(vendorShippingDate) : '-'),
                },
              ]}
              data={pendingReceivedPurchaseOrders.sort((a, b) => b.id - a.id)}
              size="small"
              disableDateRangeFilter
              cleanSpace
            />
          ) : (
            <FilteredTable<
              Pick<IPurchaseOrder, 'id' | 'code' | 'status' | 'recCreatedAt'> &
                Pick<IPurchaseOrderProduct, 'amount' | 'price' | 'productTotal'> & {
                  vendor: Pick<IVendorDB, 'id' | 'name'>
                }
            >
              color="primary.dark"
              columns={[
                {
                  name: 'วันที่บันทึก',
                  render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                },
                {
                  name: 'เลขที่ PO',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/purchase-orders/${id}/profile`}>
                      <Typography variant="body2">{code}</Typography>
                    </Link>
                  ),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  render: ({ vendor }) => vendor.name,
                },
                {
                  name: 'จำนวน',
                  dataIndex: 'amount',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'ราคา (บาท)',
                  dataIndex: 'price',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'จำนวนเงินทั้งหมด (บาท)',
                  dataIndex: 'productTotal',
                  numeric: true,
                  format: '0,0',
                },
              ]}
              data={donePurchaseOrders.sort((a, b) => b.id - a.id)}
              size="small"
              disableDateRangeFilter
              cleanSpace
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductPurchaseOrderTable
