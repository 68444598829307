import { Typography, Box, Link } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { MouseEventHandler } from 'react'

interface Props {
  href?: string
  target?: string
  color?: any
  textDecorationColor?: any
  variant?: 'inherit' | Variant | undefined
  onClick?: MouseEventHandler<HTMLSpanElement>
}

export const TypographyLink: React.FC<Props> = (props) => {
  const { href, target, color, textDecorationColor, children, variant, onClick } = props
  if (href)
    return (
      <Link style={{ textDecorationColor }} href={href} target={target} rel="noopener noreferrer">
        <Box width="100%" color={color} clone>
          <Typography className={onClick ? 'cursor-pointer' : ''} variant={variant} onClick={onClick}>
            {children}
          </Typography>
        </Box>
      </Link>
    )

  return (
    <Box width="100%" color={color} clone>
      <Typography className={onClick ? 'cursor-pointer' : ''} variant={variant} onClick={onClick}>
        {children}
      </Typography>
    </Box>
  )
}
