import { useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { defaultEndDate, defaultStartDate } from '../../data'
import { DateType } from '@date-io/type'

interface Props {
  from: Date
  to: Date
  onChange: (startDate: Date, endDate: Date) => void
}

const DateRange: React.FC<Props> = (props: Props) => {
  const { from: inputFrom, to: inpuTo, onChange } = props
  const [from, setFrom] = useState<DateType>(inputFrom || defaultStartDate)
  const [to, setTo] = useState<DateType>(inpuTo || defaultEndDate)

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <KeyboardDatePicker
        size="small"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        invalidDateMessage="วันที่ไม่ถูกต้อง"
        value={from}
        onChange={(date) => date && setFrom(date)}
      />
      <Typography>ถึง</Typography>
      <KeyboardDatePicker
        size="small"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        invalidDateMessage="วันที่ไม่ถูกต้อง"
        value={to}
        onChange={(date) => date && setTo(date)}
      />
      <Button variant="contained" color="primary" onClick={() => onChange(from, to)}>
        OK
      </Button>
    </Box>
  )
}

export default DateRange
