/* eslint-disable react-hooks/exhaustive-deps */
import {
  GetQuotationTableDataRequestSearchParam,
  IQuotationTableData,
  IQuotationTableData_Data,
  getQuotationTableDataAPI,
} from '../../api/smartbestbuys-api'
import { useTableData } from '../useTablaData'

export interface QuotationTableDataSearchParam {
  code?: string
  customerName?: string
  phoneNumber?: string
  statusIds?: number[]
  assigneeUserId?: number
  startDate?: Date
  endDate?: Date
  dueDate?: Date
  isNoSaleOrder?: boolean
  isNeedToFollow?: boolean
  requestChanged?: boolean
}

const _buildRequestParams = (_s: QuotationTableDataSearchParam): GetQuotationTableDataRequestSearchParam => {
  const {
    code,
    customerName,
    phoneNumber,
    statusIds,
    isNoSaleOrder,
    isNeedToFollow,
    requestChanged,
    assigneeUserId,
    startDate,
    endDate,
    dueDate,
  } = _s
  return {
    code: code ? code : '',
    customerName: customerName ? customerName : '',
    phoneNumber: phoneNumber ? phoneNumber : '',
    statusIds: statusIds ? statusIds?.join(',') : '',
    assigneeUserId: assigneeUserId ? assigneeUserId.toString() : '',
    isNoSaleOrder: isNoSaleOrder?.toString(),
    isNeedToFollow: isNeedToFollow?.toString(),
    requestChanged: requestChanged ? requestChanged.toString() : undefined,
    startDate: startDate ? startDate.toISOString() : '',
    endDate: endDate ? endDate.toISOString() : '',
    dueDate: dueDate ? dueDate.toISOString() : '',
  }
}

export const useQuotationTableData = () => {
  return useTableData<
    IQuotationTableData_Data,
    IQuotationTableData['summary'],
    QuotationTableDataSearchParam,
    GetQuotationTableDataRequestSearchParam
  >(
    0,
    10,
    {
      code: '',
      customerName: '',
      phoneNumber: '',
      statusIds: [5] as number[],
      assigneeUserId: undefined,
      isNoSaleOrder: undefined,
      isNeedToFollow: undefined,
      requestChanged: undefined,
      startDate: undefined,
      endDate: undefined,
      dueDate: undefined,
    },
    getQuotationTableDataAPI,
    _buildRequestParams,
  )
}
