import SOCreationFormValues from '../pages/SOCreationPage/SOCreationFormValues'
import { SOPriceBreakdown, SQLCommand } from '../types'
import _ from 'lodash'
import { calculateExcludingVat, calculateVatAmount } from '../../functions'
import SOUpdationFormValue from '../../pages/sale-orders/id/update/SaleOrderUpdationPage/SOUpdationFormValues'
import { QuotationsCreateValue } from '../../pages/quotations/QuotationCreationPage/QuotationsCreateValue'
import { BasePriceBreakdownsDomain } from '../../data'

export const calculatePriceBreakdowns = (
  values: SOCreationFormValues | SOUpdationFormValue | QuotationsCreateValue | BasePriceBreakdownsDomain,
): SOPriceBreakdown => {
  const {
    selectedProducts,
    discount: discountAmount,
    shippingFee: shippingFeeAmount,
    vatIncluded,
    isShippingFeeIncludingVat,
    discountEnabled,
    shippingFeeEnabled,
  } = values
  const productTotal = _.sum(
    selectedProducts.map((sp) => {
      if (sp.command !== SQLCommand.DELETE) {
        return sp.amount * sp.price
      } else {
        return 0
      }
    }),
  )

  const discount = discountEnabled ? Number(discountAmount) || 0 : 0
  let shippingFee = shippingFeeEnabled ? Number(shippingFeeAmount) || 0 : 0
  if (isShippingFeeIncludingVat) {
    shippingFee = calculateExcludingVat(shippingFee)
  }

  const vatAmount = vatIncluded ? calculateVatAmount(productTotal - discount + shippingFee) : 0
  const total = productTotal - discount + shippingFee + vatAmount

  return {
    productTotal,
    discount,
    shippingFee,
    vatAmount,
    total,
  }
}
