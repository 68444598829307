import React from 'react'
import { Avatar, AvatarProps, Chip, createStyles, makeStyles, SvgIcon, Tooltip } from '@material-ui/core'
import { CalendarToday, Phone, Done, AccessTime, MoodBadTwoTone, Description } from '@material-ui/icons'
import { ReactComponent as NoDocumentSvg } from '../../assets/icon/noDocument.svg'
import { ReactComponent as NotiLeaderSvg } from '../../assets/icon/notiLeader.svg'
import { ReactComponent as PRCanceled } from '../../assets/icon/prCanceled.svg'
import { ReactComponent as NoPOAlert } from '../../assets/icon/no-po-alert.svg'
import { ReactComponent as InsufficientProduct } from '../../assets/icon/insufficientProduct.svg'
import { ReactComponent as NoProductStorage } from '../../assets/icon/no-product-storage-alert.svg'
import { ReactComponent as ShippingSvg } from '../../assets/icon/shipping.svg'
import { ReactComponent as Blacklist } from '../../assets/icon/blacklist.svg'
import { ReactComponent as Credit } from '../../assets/icon/credit.svg'
import { ReactComponent as SOCancelAlert } from '../../assets/icons/so-checklist-icon.svg'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import './Signal.less'
import MultipleConditionTooltip, { ConditionItem } from '../MultipleConditionTooltip'

interface SignalProps {
  className?: string
  chip?: boolean
}

const useStyles = () =>
  makeStyles((theme) => {
    return createStyles({
      chip: {
        backgroundColor: theme.palette.error.light,
      },
      icon: {
        color: theme.palette.error.darkText,
      },
    })
  })

export const PurchaseRequisitionCancelSignal: React.FC<SignalProps> = (props) => {
  const classes = useStyles()()
  const { className, chip } = props
  const classNames = ['signal signal-error']
  if (!!className) classNames.push(className)

  if (chip)
    return (
      <Chip
        icon={<PRCanceled />}
        classes={{
          icon: classes.icon,
        }}
        className={classes.chip}
        label="คำขอซื้อถูกยกเลิกแล้ว"
      />
    )

  return (
    <Tooltip title="คำขอซื้อถูกยกเลิกต้องตามต่อ">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={PRCanceled} className="signal__icon" viewBox="0 0 18 18" />
      </Avatar>
    </Tooltip>
  )
}

export const SendToManagerCancelSignal: React.FC<SignalProps> = (props) => {
  const classes = useStyles()()
  const { className, chip } = props
  const classNames = ['signal signal-error']
  if (!!className) classNames.push(className)

  if (chip)
    return (
      <Chip
        icon={<NotiLeaderSvg />}
        classes={{
          icon: classes.icon,
        }}
        className={classes.chip}
        label="ให้หัวหน้าช่วยจัดการ"
      />
    )

  return (
    <Tooltip title="ให้หัวหน้าช่วยจัดการ">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={NotiLeaderSvg} className="signal__icon" viewBox="0 0 18 18" />
      </Avatar>
    </Tooltip>
  )
}

export const AlertDocumentSignal: React.FC<SignalProps> = (props) => {
  const { className } = props
  const classNames = ['signal signal-error']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="เอกสารการเงินยังไม่ครบตามยอดใบสั่งซื้อ">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={NoDocumentSvg} className="signal__icon" viewBox="0 0 18 18" />
      </Avatar>
    </Tooltip>
  )
}

export const AlertShippingDateWithVendorSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal signal-error']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ยังไม่นัดหมายกับผู้ขาย">
      <Avatar className={classNames.join(' ')}>
        <CalendarToday fontSize="small" />
      </Avatar>
    </Tooltip>
  )
}

export const InsufficientProductSignal: React.FC<SignalProps> = (props) => {
  const classes = useStyles()()
  const { className, chip } = props
  const classNames = ['signal signal-error']
  if (!!className) classNames.push(className)

  if (chip)
    return (
      <Chip
        icon={<InsufficientProduct />}
        classes={{
          icon: classes.icon,
        }}
        className={classes.chip}
        label="สินค้าไม่พอ"
      />
    )

  return (
    <Tooltip title="สินค้าไม่พอ">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={InsufficientProduct} className="signal__icon" viewBox="-2 0 24 24" />
      </Avatar>
    </Tooltip>
  )
}

export const FollowCustomerSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal signal-error']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ยังไม่ได้ติดตามลูกค้า">
      <Avatar className={classNames.join(' ')}>
        <Phone fontSize="small" color="action" />
      </Avatar>
    </Tooltip>
  )
}

export const TransportTaskProcessingSignal: React.FC<SignalProps> = (props) => {
  const classes = useStyles()()
  const { className, chip } = props
  const classNames = ['signal signal-success']
  if (!!className) classNames.push(className)

  if (chip)
    return (
      <Chip
        icon={<ShippingSvg />}
        classes={{
          icon: classes.icon,
        }}
        className={classes.chip}
        label="มีการสร้างงานจัดส่งแล้ว"
      />
    )

  return (
    <Tooltip title="มีการสร้างงานจัดส่งแล้ว">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={ShippingSvg} className="signal__icon" viewBox="0 0 24 24" />
      </Avatar>
    </Tooltip>
  )
}

export const NoProductStorageAlertSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal-v2']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ยังไม่ระบุพื้นที่คลัง">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={NoProductStorage} className="signal__icon" viewBox="0 0 16 16" />
      </Avatar>
    </Tooltip>
  )
}

export const StatementVerifiedSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal signal-success']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ตรวจสอบเรียบร้อย">
      <Avatar className={classNames.join(' ')} sizes="small">
        <Done fontSize="small" />
      </Avatar>
    </Tooltip>
  )
}

export const NoPOAlertSignal: React.FC<SignalProps> = (props) => {
  const { className } = props
  const classNames = ['signal-v2']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ยังไม่ได้สร้าง PO">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={NoPOAlert} className="signal__icon" viewBox="0 -2 12 14" />
      </Avatar>
    </Tooltip>
  )
}

export const StatementVerifyingSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal signal-warning']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="รอตรวจสอบ">
      <Avatar className={classNames.join(' ')} sizes="small">
        <AccessTime fontSize="small" />
      </Avatar>
    </Tooltip>
  )
}

export const BlackListSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal-black signal-small']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ติดบัญชีดำ">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={Blacklist} className="signal__icon" viewBox="5 5 14 14" />
      </Avatar>
    </Tooltip>
  )
}

export const CreditSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal-yellow signal-small']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ลูกค้าเครดิต">
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={Credit} className="signal__icon" viewBox="5 5 14 14" />
      </Avatar>
    </Tooltip>
  )
}

interface SaleOrderCancelByCustomerSignalProps {
  creditNotePending?: boolean
  reclaimProductPending?: boolean
  refundPending?: boolean
}

export const SaleOrderCancelByCustomerSignal: React.FC<AvatarProps & SaleOrderCancelByCustomerSignalProps> = (
  props,
) => {
  const { className, reclaimProductPending, refundPending, creditNotePending } = props
  const classNames = ['signal-transparent']
  if (!!className) classNames.push(className)

  const conditions: ConditionItem[] = [
    { message: 'มีคำร้องลูกค้าค้างอยู่', showTooltip: !!reclaimProductPending },
    { message: 'มีเงินที่ยังไม่ได้คืน', showTooltip: !!refundPending },
    { message: 'มีใบกำกับภาษีค้างอยู่', showTooltip: !!creditNotePending },
  ]

  return (
    <MultipleConditionTooltip conditionItems={conditions} showAllPossible>
      <Avatar className={classNames.join(' ')}>
        <SvgIcon component={SOCancelAlert} className="signal__icon" viewBox="0 0 24 21" />
      </Avatar>
    </MultipleConditionTooltip>
  )
}

export const DeadStockSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal-transparent']
  if (!!className) classNames.push(className)

  return (
    <Tooltip title="ไม่ได้ขายมา 3 เดือนแล้ว">
      <Avatar className={classNames.join(' ')} sizes="small">
        <MoodBadTwoTone htmlColor="purple" />
      </Avatar>
    </Tooltip>
  )
}

export const FollowUpToReturnWithdrawnProductSignal: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['signal-transparent']
  if (!!className) classNames.push(className)

  return (
    <Tooltip title="ต้องติดตามสินค้าคืนจากผู้ขอเบิก">
      <Avatar className={classNames.join(' ')} sizes="small">
        <NotificationsActiveIcon color="primary" />
      </Avatar>
    </Tooltip>
  )
}

export const QuotationRequestChangeSignal: React.FC<SignalProps> = (props) => {
  const classes = useStyles()()
  const { className, chip } = props
  const classNames = ['signal signal-error']
  if (!!className) classNames.push(className)

  if (chip)
    return (
      <Chip
        icon={<Description />}
        classes={{
          icon: classes.icon,
        }}
        className={classes.chip}
        label="ข้อมูลใบเสนอราคาไม่ถูกต้อง"
      />
    )

  return (
    <Tooltip title="ข้อมูลใบเสนอราคาไม่ถูกต้อง">
      <Avatar className={classNames.join(' ')}>
        <Description fontSize="small" />
      </Avatar>
    </Tooltip>
  )
}
