import { useContext } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { SubTopic, TypographyWithLabel } from '../../../../custom-components'
import { PurchaseRequisitionType } from '../../../../enums'
import { dateFormat } from '../../../../functions'
import { VendorInfoCard } from '../../../purchase-requisitions/create/VendorBox/VendorInfoCard'
import { PurchaseOrderCreatePageContext } from '../PurchaseOrderCreatePage'
import PurchaseRequisitionProductInfoTable from './PurchaseRequisitionProductInfoTable'
import './PurchaseRequisitionInfoBox.less'
import RelatedPurchaseOrderProductInfoTable from './RelatedPurchaseOrderProductInfoTable'

const PurchaseRequisitionInfoBox: React.FC = () => {
  const { purchaseOrderCreate } = useContext(PurchaseOrderCreatePageContext)
  const { purchaseRequisitionInfo, relatedPRProducts } = purchaseOrderCreate
  const { type, topic, currencyInfo, dueDate, code, referenceVendorInfo: vendor, products } = purchaseRequisitionInfo

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SubTopic>คำขอซื้ออ้างอิง {code}</SubTopic>
            </Grid>
            <Grid item xs={12} md={8}>
              <SubTopic>ข้อมูลคำขอซื้อ</SubTopic>
              <Grid container alignItems="center" spacing={2} className="pt-3">
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel
                    label="ประเภทคำขอซื้อ:"
                    children={
                      type.id === PurchaseRequisitionType.BuyProductAndServices
                        ? 'ซื้อสินค้า/บริการ'
                        : 'เคลมสินค้า/ซ่อมสินค้า'
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="เหตุผลการเปิด:" children={topic.name} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="สกุลเงินที่ใช้:" children={currencyInfo.name} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel
                    label="วันที่ต้องการสินค้า/บริการ:"
                    children={(dueDate && dateFormat(dueDate)) || '-'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} className="divider">
              <Grid container>
                <Grid item xs={12} className="pb-3">
                  <SubTopic>ข้อมูลผู้ขายอ้างอิง</SubTopic>
                </Grid>
                <Grid item xs={12}>
                  <VendorInfoCard vendor={vendor} readonly />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="pt-7">
          <PurchaseRequisitionProductInfoTable products={products} />
        </Grid>
        {!!relatedPRProducts && relatedPRProducts.length > 0 && (
          <Grid item xs={12} className="pt-7">
            <RelatedPurchaseOrderProductInfoTable products={relatedPRProducts} />
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default PurchaseRequisitionInfoBox
