import React, { useContext } from 'react'
import { Avatar, Paper, Typography } from '@material-ui/core'
import { FlexBox, SubTopic, Table } from '../../../custom-components'
import { Photo } from '@material-ui/icons'
import { TransportTaskStateV1_Item } from '../../../api/smartbestbuys-api'
import { TransportTaskProfilePageContext } from './TransportTaskProfilePage'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'

interface Props {
  hideSalePrice?: boolean
}

const TransportTaskProfileProductsTable: React.FC<Props> = (props: Props) => {
  // context
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const products = transportTaskProfile.state?.items.filter(
    (row) => row.type === TransportTaskItemType.Product,
  ) as TransportTaskStateV1_Item[]

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ข้อมูลสินค้าสำหรับขนส่ง</SubTopic>
        <div className="mb-4" />
        <Table<TransportTaskStateV1_Item>
          color="primary.dark"
          columns={[
            { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
            {
              name: 'ชื่อสินค้า/บริการ',
              dataIndex: 'name',
              render: (product) => (
                <div className="purchase-order-products-table__product-cell" key={'product-' + product.id}>
                  <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                    <Photo />
                  </Avatar>
                  <div className="purchase-order-products-table__product-cell__info">
                    <Typography color="textSecondary">{product.code}</Typography>
                    <Typography>{product.name}</Typography>
                  </div>
                </div>
              ),
            },
            { name: 'จำนวนที่ส่งแล้ว', render: () => '-', align: 'right' },
            { name: 'จำนวนที่ขาดส่ง', render: () => '-', align: 'right' },
            {
              name: 'จำนวนที่จะส่ง',
              render: ({ amount }) => amount,
              align: 'right',
            },
          ]}
          data={products}
          pagination={false}
          cleanSpace
        />
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskProfileProductsTable
