import { Grid, Paper } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { PurchaseRequisitionCreateData } from '../../../../api/smartbestbuys-api'
import { SubTopic, TypographyWithLabel } from '../../../../custom-components'

import { PurchaseRequisitionType } from '../../../../enums'
import { dateFormat } from '../../../../functions'
import PurchaseRequisitionCreateFormValues from '../PurchaseRequisitionCreateFormValue'

interface Props {
  type: PurchaseRequisitionType
  createData: PurchaseRequisitionCreateData
}

const PurchaseProductInfoVerification = (props: Props) => {
  const { type, createData } = props
  const { purchaseRequisitionTopics, currencies, saleOrderInfo } = createData
  const { watch } = useFormContext<PurchaseRequisitionCreateFormValues>()
  const { topicId, currencyId, dueDate } = watch()

  return (
    <Paper style={{ height: '100%' }}>
      <SubTopic>ข้อมูลคำขอซื้อ</SubTopic>
      <Grid container alignItems="center" spacing={2} className="pt-3">
        <Grid item xs={12} md={6}>
          <TypographyWithLabel
            label="ประเภทคำขอซื้อ:"
            children={
              type === PurchaseRequisitionType.BuyProductAndServices ? 'ซื้อสินค้า/บริการ' : 'เคลมสินค้า/ซ่อมสินค้า'
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel
            label="เหตุผลการเปิด:"
            children={purchaseRequisitionTopics.find((topic) => topic.id === topicId)?.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel
            label="สกุลเงินที่ใช้:"
            children={currencies.find((currency) => currency.id === currencyId)?.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="วันที่ต้องการสินค้า/บริการ:" children={(dueDate && dateFormat(dueDate)) || '-'} />
        </Grid>
        {!!saleOrderInfo && (
          <>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="ใบสั่งขายอ้างอิง:">{saleOrderInfo.code}</TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="วันที่ลูกค้าต้องการ:">
                {(saleOrderInfo.dueDate && dateFormat(saleOrderInfo.dueDate)) || '-'}
              </TypographyWithLabel>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

export default PurchaseProductInfoVerification
