import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import {
  IProductWithdrawalTicketProfile,
  confirmReceivedWithdrawnProductAPI,
} from '../../../../../api/smartbestbuys-api/warehouse'
import { GeneralTextField } from '../../../../../custom-components'
import { useState } from 'react'

interface Props extends DialogProps {
  profile: IProductWithdrawalTicketProfile
  onSuccess: (updatedProfile: IProductWithdrawalTicketProfile) => void
  onFail: () => void
}

const PWTicketConfirmReceivedDialog: React.FC<Props> = (props) => {
  const { profile, onSuccess, onFail, onClose, ...dialogProps } = props

  const { id, code } = profile

  // state
  const [remark, setRemark] = useState<string | undefined>(undefined)

  const handleClose = () => {
    onClose && onClose({}, 'escapeKeyDown')
  }

  const handleSubmit = async () => {
    const response = await confirmReceivedWithdrawnProductAPI(id, { remark: remark || undefined })

    if (response?.status === 200) {
      onSuccess(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} fullWidth>
      <DialogTitle>ต้องการเบิกสินค้าในคำขอเบิกเลขที่ {code} ใช่ไหม ?</DialogTitle>
      <DialogContent style={{ height: 90 }}>
        <GeneralTextField
          name="remark"
          size="small"
          label="หมายเหตุการเบิกสินค้า (ถ้ามี)"
          onChange={(e) => setRemark(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary" size="large">
          ย้อนกลับ
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary" size="large">
          ยืนยันการเบิกสินค้า
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PWTicketConfirmReceivedDialog
