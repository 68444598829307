import { useState, useEffect } from 'react'
import { getRefundRequisitionsUpdateData, IRefundRequisitionUpdateData } from '../../api/smartbestbuys-api'

export const useRefundRequisitionsUpdateData = (refundId: number): IRefundRequisitionUpdateData | undefined => {
  const [data, setData] = useState<IRefundRequisitionUpdateData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getRefundRequisitionsUpdateData(refundId)
      setData(response?.data)
    })()
  }, [refundId])

  return data
}
