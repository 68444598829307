import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { SQLCommand } from '../../../sales-system/types'
import { BaseData } from '../types'

export interface ProductDataRow {
  id: number
  code: string
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  warrantyPeriodDay?: number
  dealerPrice?: number

  storage?: BaseData
  grade?: string

  stockAmount: number
  reservedAmount: number
  incomingAmount: number
  soReservedAmount: number

  // HACK
  productId?: number
  command?: SQLCommand
}

const path = '/products'

export const fetchProducts = errorHandler<ProductDataRow[]>(async () => {
  return await smartAuthAPI.get(path)
})
