import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 320,
      width: '100%',
    },
  }),
)
