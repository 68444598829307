import { FC, useMemo } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font } from '@react-pdf/renderer'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFRow,
  PDFColumn,
  Loading,
} from '../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../assets/images/shipping-cover-logo.png'
import qrSmartBestBuy from '../../../assets/images/qr-smart-best-buys.png'
import { useTransportTaskProfile } from '../../../hooks'
import numeral from 'numeral'
import { TransportTaskStateV2_Item } from '../../../api/smartbestbuys-api'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 16,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  borderSpaceBetween: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: color.darkGrey,
    borderWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
  },
  borderLayout: {
    borderColor: color.darkGrey,
    borderWidth: 1,
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderLayoutBottom: {
    borderColor: color.darkGrey,
    borderBottomWidth: 1,
    textAlign: 'center',
    paddingBottom: 16,
  },
  companyName: {
    fontFamily: 'THSarabunNewBold',
    fontSize: 18,
    marginBottom: 4,
  },
  headerText: {
    fontFamily: 'THSarabunNewBold',
    fontSize: 20,
    marginBottom: 8,
  },
  textWarning: {
    color: 'rgba(244, 67, 54, 1)',
    fontFamily: 'THSarabunNewBold',
  },
  textNormal: {
    color: 'rgba(0,0,0,1)',
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    width: 120,
    position: 'absolute',
    right: 8,
    top: 8,
  },
  qrCode: {
    width: 60,
  },
  center: {
    textAlign: 'center',
  },
  shippingPayCondition: {
    fontSize: 18,
  },
  districtCode: {
    fontSize: 30,
  },
  headerPdf: {
    fontSize: 18,
    textAlign: 'right',
    marginTop: 5,
    marginBottom: 5,
  },
  headerPOPdf: {
    fontSize: 18,
    textAlign: 'right',
    marginTop: 5,
    marginBottom: 5,
    color: 'rgba(244, 67, 54, 1)',
  },
})

const ShippingCoverPDFPage: FC<RouteComponentProps<any>> = (props) => {
  const { id } = props.match.params
  const profile = useTransportTaskProfile(id, true)
  const poRemark = useMemo(() => profile?.relatedPO?.map((po) => po.code)?.join(', ') ?? '-', [profile])

  if (profile) {
    document.title = 'ใบปะหน้า'

    return (
      <SoraPDFViewer>
        <Document>
          <Page size="A4" style={styles.page}>
            {/* PDF */}
            <Text style={styles.headerPdf}>เลขที่ใบสั่งขาย {profile.saleOrderInfo?.code}</Text>
            <Text style={styles.headerPOPdf}>เลขที่ PO อ้างอิง : {poRemark}</Text>
            <PDFRow style={styles.borderSpaceBetween} fixed>
              <PDFView>
                <Text style={styles.companyName}>ผู้ส่ง บริษัท สมาร์ทเบสท์บายส์ จำกัด</Text>
                <PDFView>
                  <Text>519/174-176 ม.6 ซ.ประชาอุทิศ121 ข.ทุ่งครุ</Text>
                  <Text>ข.ทุ่งครุ กทม. 10140 โทร 02-462-8272,094-496-9331</Text>
                </PDFView>
              </PDFView>

              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFRow>

            {profile.state?.shippingInfo?.useShippingProvider && (
              <PDFRow style={{ textAlign: 'center', marginBottom: -12 }} fixed>
                <PDFColumn style={styles.borderLayout} columnSpan={12}>
                  <PDFView>
                    <Text style={styles.headerText}>
                      {profile.state?.shippingInfo.shippingProvider?.name ?? 'ไม่ระบุขนส่ง'}
                    </Text>
                  </PDFView>
                </PDFColumn>
              </PDFRow>
            )}

            <PDFRow fixed>
              <PDFColumn style={styles.borderLayout} columnSpan={9.6}>
                <PDFView>
                  <Text style={styles.headerText}>
                    ผู้รับ {profile.state?.shippingInfo.consignee} {profile.state?.shippingInfo.phoneNumber}
                  </Text>
                  <PDFView>
                    <Text style={styles.headerText}>{profile.saleOrderInfo?.customerName}</Text>
                    <Text style={styles.headerText}>{profile.state?.shippingInfo.shippingAddress?.text}</Text>
                  </PDFView>
                </PDFView>
              </PDFColumn>
              <PDFColumn style={styles.borderLayout} columnSpan={2.2}>
                <Text style={styles.shippingPayCondition}>
                  {profile.state?.shippingInfo.payShippingFeeBySmart ? 'เก็บเงินต้นทาง' : 'เก็บเงินปลายทาง'}
                </Text>
              </PDFColumn>
            </PDFRow>
            <PDFTable>
              {/* Head Table */}
              <PDFTableRow bottomLine topLine>
                <PDFTableCell head width={44} textAlign="center">
                  ลำดับ
                </PDFTableCell>
                <PDFTableCell head width={447}>
                  รายละเอียด
                </PDFTableCell>
                <PDFTableCell head width={64} textAlign="right">
                  จำนวน
                </PDFTableCell>
              </PDFTableRow>
              {/* Content Table */}
              {profile.state?.items
                .filter((item) => item.type === TransportTaskItemType.Product)
                .map((item: TransportTaskStateV2_Item, index: number) => (
                  <PDFTableRow bottomLine>
                    <PDFTableCell width={44} textAlign="center">
                      {index + 1}
                    </PDFTableCell>
                    <PDFTableCell width={447}>
                      {item.code} {item.name}
                    </PDFTableCell>
                    <PDFTableCell width={64} textAlign="right">
                      {numeral(item.amount).format('0,0')}
                    </PDFTableCell>
                  </PDFTableRow>
                ))}
            </PDFTable>
            {!!profile.state?.shippingInfo.remark && (
              <PDFRow style={styles.spaceBetween} fixed>
                <PDFView>
                  <Text style={styles.textNormal}>หมายเหตุ: {profile.state?.shippingInfo.remark}</Text>
                </PDFView>
              </PDFRow>
            )}
            <PDFRow style={styles.spaceBetween} fixed>
              <PDFView>
                <Text style={styles.textWarning}>หากลูกค้าได้รับสินค้า รบกวนถ่ายวิดีโอก่อนแกะสินค้าภายใน 1-2 วัน</Text>
                <Text style={{ ...styles.textWarning, marginBottom: 16 }}>
                  หลังจากได้รับสินค้า จำนวนสินค้าไม่ครบ ชำรุด รบกวนแจ้งกลับทันทีภายใน 3 วันทำการ
                </Text>
                <Text style={styles.textWarning}>
                  **** หากไม่มีการแจ้งกลับภายใน 3 วัน และส่งสินค้าคืนภายใน7วันทำการ
                </Text>
                <Text style={styles.textWarning}>จะถือว่าผิดเงื่อนไขการเคลมสินค้าทางบริษัทฯ ****</Text>
              </PDFView>
              <Image style={styles.qrCode} src={qrSmartBestBuy} />
            </PDFRow>
          </Page>
        </Document>
      </SoraPDFViewer>
    )
  } else {
    return <Loading />
  }
}

export default ShippingCoverPDFPage
