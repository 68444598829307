import React, { useContext } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, FlexBox, Link } from '../../../custom-components'
import { PendingPaymentPurchaseOrder } from '../../../api/smartbestbuys-api'
import { Box } from '@material-ui/core'
import { RootContext } from '../../..'
import { PageName } from '../../../enums'
import { usePendingPaymentPurchaseOrders } from '../../../hooks'
import { PurchaseOrderPaymentStatusChip, PurchaseOrderStatusChip } from '../../../shared-components'
import { dateFormat, dateTimeFormat } from '../../../functions'

const PendingPaymentPurchaseOrderListPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PENDING_PAYMENT_PURCHASE_ORDER)

  const rawData = usePendingPaymentPurchaseOrders()
  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการที่ต้องทำเบิก</Topic>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<PendingPaymentPurchaseOrder>
              color="primary.dark"
              columns={[
                {
                  name: 'สถานะใบสั่งซื้อ',
                  dataIndex: 'status',
                  render: ({ status }) => <PurchaseOrderStatusChip status={status.id} label={status.name} />,
                },
                {
                  name: 'สถานะการจ่ายเงิน',
                  dataIndex: 'paymentStatus',
                  render: ({ paymentStatus }) => (
                    <PurchaseOrderPaymentStatusChip status={paymentStatus.id} label={paymentStatus.name} />
                  ),
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                },
                {
                  name: 'วันที่ต้องทำเบิก',
                  render: ({ paymentDueDate }) =>
                    paymentDueDate ? dateFormat(paymentDueDate) : <Typography color="textSecondary">-</Typography>,
                },
                {
                  name: 'เลขที่ใบสั่งซื้อ',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/purchase-orders/${id}/profile`}>
                      <Typography variant="body2">{code}</Typography>
                    </Link>
                  ),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  render: ({ vendorInfo }) => (
                    <Link color="primary" to={`/vendors/${vendorInfo.id}/profile`}>
                      <Box color="secondary.dark">
                        <Typography variant="body2">{vendorInfo.name}</Typography>
                      </Box>
                    </Link>
                  ),
                },
                { name: 'ยอดรวมสุทธิ', dataIndex: 'total', numeric: true, format: '0,0.00' },
              ]}
              sortable
              data={rawData}
              disableDateRangeFilter
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(PendingPaymentPurchaseOrderListPage)
