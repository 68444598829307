import { FlexBox, Topic } from '../../../../custom-components'
import { Loading, ProductWithdrawalTicketStatusChip } from '../../../../shared-components'
import { usePWTickProfileContext } from './PWTicketProfilePageContextProvider'
import PWTicketProfilePageActionBox from './PWTicketProfilePageActionBox'
import { profilePageText } from './pw-ticket-profile-page-setting'

const PWTicketProfilePageHeader = () => {
  const { profile } = usePWTickProfileContext()
  if (!profile) return <Loading />

  const { header } = profilePageText

  return (
    <FlexBox justifyContent="space-between" alignItems="center" gridGap={8}>
      <Topic>
        {header.title} {profile.code}
      </Topic>
      <ProductWithdrawalTicketStatusChip status={profile.status.id} label={profile.status.name} />
      <div className="flex-grow" />
      <PWTicketProfilePageActionBox />
    </FlexBox>
  )
}

export default PWTicketProfilePageHeader
