import { useState, useEffect } from 'react'
import { getUserCreateDataAPI } from '../../api/smartbestbuys-api/user/user-apis'
import { UserCreateData } from '../../api/smartbestbuys-api/user/user-types'

export const useUserCreateData = (): UserCreateData | undefined => {
  const [data, setData] = useState<UserCreateData | undefined>()

  useEffect(() => {
    ;(async () => {
      const response = await getUserCreateDataAPI()
      if (response?.data) {
        setData(response?.data || [])
      }
    })()
  }, [])

  return data
}
