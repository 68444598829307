import { FlexBox } from '../../custom-components'
import { NavigationItemKey } from '../../enums'
import { loadNotification } from '../../functions'
import NotificationChip from './NotificationChip'

interface Props {
  navigationKey: NavigationItemKey
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ navigationKey }: Props) => {
  const notification = loadNotification()

  return (
    <FlexBox gridGap={4}>
      {notification
        .navigationItemNoticeAmount(navigationKey)
        .filter((item) => item.amount > 0)
        .map((item, index) => (
          <NotificationChip key={`noti-chip-${index}`} item={item} />
        ))}
    </FlexBox>
  )
}
