import { useState, useEffect } from 'react'
import { getUpdatePurchaseRequisitions, PurchaseRequisitionUpdateData } from '../api/smartbestbuys-api'

export const usePurchaseRequisitionUpdateData = (purchaseRequisitionId: number): PurchaseRequisitionUpdateData | undefined => {
  const [purchaseRequisitions, setPurchaseRequisitions] = useState<PurchaseRequisitionUpdateData | undefined>()

  const _getUpdatePurchaseRequisitions = async (purchaseRequisitionId: number) => {
    const response = await getUpdatePurchaseRequisitions(purchaseRequisitionId)
    setPurchaseRequisitions(response?.data)
  }

  useEffect(() => {
    _getUpdatePurchaseRequisitions(purchaseRequisitionId)
  }, [purchaseRequisitionId])

  return purchaseRequisitions
}
