import { useState, useEffect } from 'react'
import { FetchRequest, PaymentRequisition_TableData } from '../../api/smartbestbuys-api'
import { fetchPaymentRequisitions } from '../../api/smartbestbuys-api'

export const usePaymentRequisitions = (startDate: Date, endDate: Date): PaymentRequisition_TableData[] => {
  const [data, setData] = useState<PaymentRequisition_TableData[]>([])

  const _fetchPaymentRequisitions = async (request: FetchRequest) => {
    const response = await fetchPaymentRequisitions(request)
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchPaymentRequisitions({ startDate, endDate })
  }, [startDate, endDate])

  return data
}
