import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'
import { useContext, useState } from 'react'
import { RootContext } from '../../../..'
import { approveCustomerRequisitionAPI } from '../../../../api/smartbestbuys-api'
import { PageName } from '../../../../enums'

interface Props extends DialogProps {
  customerRequisitionId: number
}

const CustomerRequisitionProfileApproveDialog: React.FC<Props> = (props) => {
  const { customerRequisitionId, onClose, ...otherProps } = props
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION)

  // state
  const [submitting, setSubmitting] = useState(false)

  const handleApprove = async () => {
    setSubmitting(true)
    const response = await approveCustomerRequisitionAPI(customerRequisitionId)

    if (response?.status === 200) {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('อนุมัติคำร้องของลูกค้าสำเร็จ')
      window.location.reload()
    } else {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('อนุมัติคำร้องของลูกค้าไม่สำเร็จ')
    }

    setSubmitting(false)
  }

  return (
    <Dialog fullWidth {...otherProps}>
      <DialogTitle>อนุมัติคำร้อง</DialogTitle>
      <DialogContent>ยืนยันอนุมัติใช่หรือไม่</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
          ย้อนกลับ
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleApprove}
          disabled={submitting}
          startIcon={submitting ? <CircularProgress /> : undefined}
        >
          ยืนยันอนุมัติ
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomerRequisitionProfileApproveDialog
