import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { ProductUpdationRequest, UpdatedProduct } from './types'

export const updateProductInformationAPI = errorSingleHandler<UpdatedProduct>(
  (id: number, request: ProductUpdationRequest) => {
    const path = `/products/${id}/update`
    return smartAuthAPI.put(path, request)
  },
)
