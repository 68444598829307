import { useState, useEffect } from 'react'
import { PurchaseRequisitionProfile, getPurchaseRequisitionProfile } from '../../api/smartbestbuys-api'

export const usePurchaseRequisitionProfile = (id: number, reload?: boolean): PurchaseRequisitionProfile | undefined => {
  const [profile, setProfile] = useState<PurchaseRequisitionProfile | undefined>(undefined)

  const _getActionMessagesByUserAction = async (id: number) => {
    const response = await getPurchaseRequisitionProfile(id)
    setProfile(response?.data)
  }

  useEffect(() => {
    _getActionMessagesByUserAction(id)
  }, [id, reload])

  return profile
}
