import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { FetchRequest, BaseData, DocumentCustomerInfo, DocumentSaleOrderInfo } from '..'
import { PriceBreakdownItem, ReferenceDocument } from './types'

const path = `/documents/credit-notes`

export const fetchDocumentCreditNotes = errorHandler<DocumentCreditNote[]>(async (request: FetchRequest) => {
  return await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
})

export interface DocumentCreditNote {
  id: number
  code: string
  type: BaseData
  status: BaseData
  referenceDocument?: ReferenceDocument
  content?: CreditNoteContent
  state?: CreditNoteState
  customerInfo?: DocumentCustomerInfo
  saleOrderInfo?: DocumentSaleOrderInfo
  total?: number
  recCreatedAt: Date
  recCreatedBy: string
  recModifiedAt?: Date
  recModifiedBy?: string
}

export interface CreditNoteState {
  items: CreditNoteItem[]
  priceBreakdowns: PriceBreakdownItem[]
  taxInvoiceReferences?: string[]
  remark?: string
}

export interface CreditNoteItem {
  id: number
  name: string
  code?: string
  imageUrl?: string
  amount: number
  // map from price per each (from content)
  discountPerEach: number
  // map from total (from content)
  discountTotal: number
}

export interface CreditNoteContent {
  customerInfo: DocumentCustomerInfo
  saleOrderInfo: DocumentSaleOrderInfo
  taxInvoiceInfo: TaxInvoiceInfoContent
  items: CreditNoteContentItem[]
  priceBreakdown: CreditNotePriceBreakdown
}

export interface CreditNoteContentItem {
  id: number
  name: string
  code?: string
  imageUrl?: string
  amount: number
  pricePerEach: number
  total: number
}

export interface CreditNotePriceBreakdown {
  itemTotal: number
  vatAmount: number
  total: number
}

export interface TaxInvoiceInfoContent {
  id: number
  code: string
  totalExcludedVat: number
  total: number
  createdAt: Date
}
