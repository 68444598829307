export interface ContactChannel {
  id: number
  name: string
}

export const contactChannels: ContactChannel[] = [
  { id: 10, name: 'Sale Inbound' },
  { id: 11, name: 'Sale Outbound' },
  { id: 12, name: 'Sale Force' },
  { id: 13, name: 'หน้าร้าน สาขา ทุ่งครุ' },
  { id: 14, name: 'Website' },
  { id: 15, name: 'ออกบูธ' },
  { id: 16, name: 'Bartercard' },
  { id: 17, name: 'Tiktok' },
  { id: 18, name: 'Shoppee' },
  { id: 19, name: 'Lazada' },
  { id: 20, name: 'Officemate' },
  { id: 21, name: 'Homepro' },
  { id: 22, name: 'NocNoc' },
  { id: 23, name: 'Shop24' },
]

export const getContactChannelNameById = (id: number): string => {
  const contactChannel = contactChannels.find((pc) => pc.id === id)
  return contactChannel ? contactChannel.name : 'unknown'
}
