import { Avatar, AvatarProps, Tooltip } from '@material-ui/core'
import { AssignmentLate, VerifiedUser } from '@material-ui/icons'

import './SmartIcon.less'

export const PendingToReviewProductIcon: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['smart-icon']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="มีการขอแก้ไขข้อมูลสินค้า">
      <Avatar sizes="small" className={classNames.join(' ')}>
        <AssignmentLate htmlColor="#FF9800" />
      </Avatar>
    </Tooltip>
  )
}

export const VerifyProductIcon: React.FC<AvatarProps> = (props) => {
  const { className } = props
  const classNames = ['smart-icon']
  if (!!className) classNames.push(className)
  return (
    <Tooltip title="ตรวจสอบข้อมูลสินค้าแล้ว">
      <Avatar sizes="small" className={classNames.join(' ')}>
        <VerifiedUser htmlColor="#4CAF50" />
      </Avatar>
    </Tooltip>
  )
}
