import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { FetchRequest, SOPaymentTransaction } from '..'

const path = '/sale-orders/payment-transactions/fetch'

export const fetchSOTransactions = errorHandler<FetchSOPaymentTransaction[]>(async (request: FetchRequest) => {
  const result = await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
  return result
})

export interface FetchSOPaymentTransaction extends SOPaymentTransaction {
  saleOrderInfo: FetchSOPaymentSaleOrderInfo
  customerInfo: FetchSOPaymentCustomerInfo
}

export interface FetchSOPaymentSaleOrderInfo {
  id: number
  code: string
  total: number
}

export interface FetchSOPaymentCustomerInfo {
  name: string
  phoneNumber: string
}
