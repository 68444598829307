import { Chip, Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import numeral from 'numeral'

import { Avatar, FlexBox, SubTopic, Table } from '../../../custom-components'
import { PurchaseProduct } from './PurchaseProductSelector/PurchaseProductSelector'

import './SelectedPurchaseProductTable.less'

interface Props {
  products: PurchaseProduct[]
}

interface PurchaseProductInfoTableData extends PurchaseProduct {
  id: number
  isNew: boolean
}

const PurchaseProductInfoTable = (props: Props) => {
  const { products } = props

  const parserProducts = products.map((product) => ({
    ...product,
    id: product.id || -1,
    isNew: !product.id && !product.productId,
  }))

  const total = products.reduce<number | undefined>((sum, product) => {
    if (!sum || !product.price) return undefined
    return sum + product.price * product.amount
  }, 0)

  return (
    <Paper className="flex flex-col">
      <SubTopic>ข้อมูลสินค้า/บริการ</SubTopic>
      <Table<PurchaseProductInfoTableData>
        size="small"
        color="primary.dark"
        columns={[
          {
            name: 'ลำดับ',
            fixedWidth: 72,
            render: (_, index) => (
              <FlexBox justifyContent="space-around">
                <Typography color="textSecondary" variant="body2">
                  {index + 1}
                </Typography>
              </FlexBox>
            ),
          },
          {
            name: 'ชื่อสินค้า/บริการ',
            fixedWidth: 417,
            render: ({ imageUrl, name, code, isNew }) => (
              <FlexBox alignItems="flex-start">
                <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                  <Photo />
                </Avatar>
                <FlexBox flexDirection="column" ml={1} className="purchase-product-header">
                  <FlexBox gridGap={8}>
                    <Typography variant="body2" className="purchase-product-header__code">
                      {code}
                    </Typography>
                    {isNew && <Chip label="ใหม่" style={{ backgroundColor: '#FF5F52' }} size="small" />}
                  </FlexBox>
                  <Typography variant="body2" className="purchase-product-header__name">
                    {name}
                  </Typography>
                </FlexBox>
              </FlexBox>
            ),
          },
          {
            name: 'จำนวน',
            render: ({ amount }) => (
              <Typography color="textPrimary" variant="body2">
                {amount}
              </Typography>
            ),
            numeric: true,
          },
          {
            name: 'ราคาต่อหน่วย',
            render: ({ price }) =>
              price ? (
                numeral(price).format('0,0.00')
              ) : (
                <Typography color="textSecondary" variant="body2">
                  ไม่ระบุ
                </Typography>
              ),
            numeric: true,
          },
          {
            name: 'ราคารวม',
            render: ({ price, amount }) =>
              price && amount ? (
                numeral(price * amount).format('0,0.00')
              ) : (
                <Typography color="textSecondary" variant="body2">
                  คำนวณไม่ได้
                </Typography>
              ),
            numeric: true,
          },
        ]}
        postCells={
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell align="right">รวมเงิน</TableCell>
            <TableCell align="right">
              <Typography color={!!total ? 'textPrimary' : 'textSecondary'} variant="body2">
                {!!total ? numeral(total).format('0,0.00') : 'คำนวณไม่ได้'}
              </Typography>
            </TableCell>
          </TableRow>
        }
        data={parserProducts}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default PurchaseProductInfoTable
