import { Grid, IconButton, Paper, Typography } from '@material-ui/core'
import { Check, Edit } from '@material-ui/icons'
import { useState } from 'react'
import { UserProfile } from '../../../api/smartbestbuys-api'
import { FlexBox, SubTopic } from '../../../custom-components'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import usePermissionData from '../../../hooks/data/usePermissionData'
import { ConditionTooltip, Loading, Space } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { UserPermissionUpdateDialog } from '../update'

interface Props {
  userProfile: UserProfile
  readOnly?: boolean
  onUpdate?: (user: UserProfile) => void
}

const UserPermissionCard: React.FC<Props> = (props) => {
  const { userProfile, readOnly, onUpdate } = props
  const [openUpdateUserPermission, setOpenUpdateUserPermission] = useState(false)

  const permissions = usePermissionData()
  if (!permissions) return <Loading />

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.HR_User_Write)

  return (
    <>
      <UserPermissionUpdateDialog
        open={openUpdateUserPermission}
        userProfile={userProfile}
        permissionGroups={permissions.permissionGroups}
        permissionTopics={permissions.permissionTopics}
        onComplete={(user) => {
          setOpenUpdateUserPermission(false)
          if (onUpdate) onUpdate(user)
        }}
        onClose={() => setOpenUpdateUserPermission(false)}
      />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <SubTopic>สิทธิ์การใช้งาน</SubTopic>
              {!readOnly && (
                <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
                  <IconButton size="small" disabled={!writePermission.hasPermission}>
                    <Edit
                      onClick={() => setOpenUpdateUserPermission(true)}
                      color={!writePermission.hasPermission ? 'disabled' : 'secondary'}
                    />
                  </IconButton>
                </ConditionTooltip>
              )}
            </FlexBox>
          </Grid>
          {userProfile.permissionGroups.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    กลุ่มสิทธิ์
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {userProfile.permissionGroups.map((group) => (
                      <Grid key={group.id} item xs={3}>
                        <Typography variant="body2">
                          {<Check fontSize="small" style={{ color: '#32de84' }} />} {group.name}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {userProfile.permissionTopics.length > 0 ? (
              userProfile.permissionTopics.map((topic) => {
                return (
                  <Grid key={topic.id} item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      {topic.name}
                    </Typography>
                    <Space />
                    <Grid container spacing={2}>
                      {topic.permissions.map((permission) => {
                        return (
                          <Grid key={permission.id} item xs={3}>
                            <Typography variant="body2">
                              {<Check fontSize="small" style={{ color: '#32de84' }} />} {permission.name}
                            </Typography>
                          </Grid>
                        )
                      })}
                    </Grid>
                    <Space />
                  </Grid>
                )
              })
            ) : (
              <Typography color="textSecondary">ไม่มีสิทธิ์การใช้งาน</Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default UserPermissionCard
