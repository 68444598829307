import React, { useContext, useMemo, useState } from 'react'
import { Grid, Button, TextField, Chip, FormControlLabel, FormGroup, Checkbox, Paper, Switch } from '@material-ui/core'
import { withLayout, Topic, Link, GeneralTextField, FilteredTable, FlexBox } from '../../custom-components'
import { Box } from '@material-ui/core'
import { RootContext } from '../..'
import { NavigationItemKey, PageName, SOPaymentStatus, SaleOrderStatus } from '../../enums'
import {
  FollowCustomerSignal,
  InsufficientProductSignal,
  NotificationHead,
  SOPaymentStatusChip,
  SOStatusChip,
  SaleOrderCancelByCustomerSignal,
  TransportTaskProcessingSignal,
} from '../../shared-components'
import { Autocomplete } from '@material-ui/lab'
import { useSaleOrderTableData } from '../../hooks/sales/useSaleOrderTableData'
import { ISaleOrderTableData_Data, ISaleOrderTableData_SearchParams } from '../../api/smartbestbuys-api/sale/sale-types'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import moment from 'moment'
import { formatNumber } from '../../functions/number-utils'
import { useUserData } from '../../hooks'

const soStatusOptions = [
  { label: 'รอตรวจสอบ', value: SaleOrderStatus.Pending.toString() },
  { label: 'รอชำระเงิน', value: SaleOrderStatus.PendingPayment.toString() },
  { label: 'รอชำระเงินมัดจำ', value: SaleOrderStatus.PendingDepositPayment.toString() },
  { label: 'รอทำการจัดส่ง', value: SaleOrderStatus.PendingDelivery.toString() },
  { label: 'จัดส่งบางส่วน', value: SaleOrderStatus.Delivering.toString() },
  { label: 'นัดรับหน้าร้าน', value: SaleOrderStatus.WaitingCustomerPickUp.toString() },
  { label: 'ปิดการขาย', value: SaleOrderStatus.Closed.toString() },
  { label: 'ปิดการขายหน้าร้าน', value: SaleOrderStatus.ClosedAtShop.toString() },
  { label: 'ยกเลิก', value: SaleOrderStatus.Cancelled.toString() },
]

const soPaymentStatusOptions = [
  { label: 'รอการชำระเงิน', value: SOPaymentStatus.Pending.toString() },
  { label: 'ชำระมัดจำแล้ว', value: SOPaymentStatus.Deposit.toString() },
  { label: 'ชำระแล้ว', value: SOPaymentStatus.Paid.toString() },
]

enum Page {
  Canceled = -1,
  NoPO = 0,
  HasPO = 1,
  NormalNoTransportTask = 2,
  Debt = 3,
  Complete = 4,
  NormalHasTransportTask = 5,
}

const _activeSOStatus = [
  SaleOrderStatus.Pending,
  SaleOrderStatus.PendingPayment,
  SaleOrderStatus.PendingDepositPayment,
  SaleOrderStatus.PendingDelivery,
  SaleOrderStatus.Delivering,
  SaleOrderStatus.WaitingCustomerPickUp,
]

const _closedSOStatus = [SaleOrderStatus.Closed, SaleOrderStatus.ClosedAtShop]

const buildSearchCriteriaByPage = (page: Page): Partial<ISaleOrderTableData_SearchParams> => {
  switch (page) {
    case Page.Canceled:
      return {
        stockReadyToShip: undefined,
        hasActivePO: undefined,
        statusList: [SaleOrderStatus.Cancelled],
        paymentStatusList: undefined,
        hasActiveShippingTask: undefined,
        needToFollow: undefined,
      }
    case Page.NoPO:
      return {
        stockReadyToShip: false,
        hasActivePO: false,
        statusList: _activeSOStatus,
        paymentStatusList: undefined,
        creditNotePending: undefined,
        reclaimProductPending: undefined,
        refundPending: undefined,
        hasActiveShippingTask: undefined,
        needToFollow: undefined,
      }
    case Page.HasPO:
      return {
        stockReadyToShip: false,
        hasActivePO: true,
        statusList: _activeSOStatus,
        paymentStatusList: undefined,
        creditNotePending: undefined,
        reclaimProductPending: undefined,
        refundPending: undefined,
        hasActiveShippingTask: undefined,
        needToFollow: undefined,
      }
    case Page.NormalNoTransportTask:
      return {
        stockReadyToShip: true,
        hasActivePO: undefined,
        statusList: _activeSOStatus,
        paymentStatusList: undefined,
        creditNotePending: undefined,
        reclaimProductPending: undefined,
        refundPending: undefined,
        hasActiveShippingTask: false,
        needToFollow: undefined,
      }
    case Page.NormalHasTransportTask:
      return {
        stockReadyToShip: true,
        hasActivePO: undefined,
        statusList: _activeSOStatus,
        paymentStatusList: undefined,
        creditNotePending: undefined,
        reclaimProductPending: undefined,
        refundPending: undefined,
        hasActiveShippingTask: true,
        needToFollow: undefined,
      }
    case Page.Debt:
      return {
        stockReadyToShip: undefined,
        hasActivePO: undefined,
        statusList: _closedSOStatus,
        paymentStatusList: [SOPaymentStatus.Pending, SOPaymentStatus.Deposit],
        creditNotePending: undefined,
        reclaimProductPending: undefined,
        refundPending: undefined,
        hasActiveShippingTask: undefined,
        needToFollow: undefined,
      }
    case Page.Complete:
      return {
        stockReadyToShip: undefined,
        hasActivePO: undefined,
        statusList: _closedSOStatus,
        paymentStatusList: [SOPaymentStatus.Paid],
        creditNotePending: undefined,
        reclaimProductPending: undefined,
        refundPending: undefined,
        hasActiveShippingTask: undefined,
        needToFollow: undefined,
      }
  }
}

const SaleOrderTableDataPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SALE_ORDER)

  const [tabValue, setTabValue] = useState<Page>(Page.NormalNoTransportTask)
  const [searchOpened, setSearchOpened] = useState<boolean>(false)
  const assigneeUser = useUserData()
  const tableData = useSaleOrderTableData(buildSearchCriteriaByPage(Page.NormalNoTransportTask))

  const flagSearchData = useMemo(() => {
    const {
      startDate,
      endDate,
      code,
      customerName,
      phoneNumber,
      email,
      assigneeUserId,
      customerPurchaseReference,
      statusList,
      paymentStatusList,
      creditNotePending,
      reclaimProductPending,
      refundPending,
    } = (tableData?.pendingSearch ??
      buildSearchCriteriaByPage(Page.NormalNoTransportTask)) as ISaleOrderTableData_SearchParams

    let isSearch: boolean =
      (!!startDate ||
        !!endDate ||
        !!code ||
        !!customerName ||
        !!phoneNumber ||
        !!email ||
        !!assigneeUserId ||
        !!customerPurchaseReference) ??
      false

    // complete is not have select paymentStatusList
    if (tabValue !== Page.Complete) isSearch = isSearch || (!!paymentStatusList && paymentStatusList?.length > 0)

    if (tabValue === Page.Canceled) {
      isSearch =
        isSearch ||
        (!!creditNotePending && creditNotePending.toString() === 'true') ||
        (!!reclaimProductPending && reclaimProductPending.toString() === 'true') ||
        (!!refundPending && refundPending.toString() === 'true')
    } else {
      isSearch = isSearch || (!!statusList && statusList?.length > 0)
    }

    return isSearch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableData?.pendingSearch), tabValue])

  const tabs = useMemo(() => {
    return [
      {
        label: (
          <>
            <div>SO ถูกยกเลิก ({tableData?.summary?.canceled.count ?? 0})</div>
            <div>
              {!!tableData?.summary?.canceled ? formatNumber(tableData?.summary?.canceled.sellTotal ?? 0) : 0} บาท
            </div>
          </>
        ),
        value: Page.Canceled,
      },
      {
        label: (
          <>
            <div>SO ไม่พร้อมส่ง / ไม่มี PO ({tableData?.summary?.noPO.count ?? 0})</div>
            <div>{!!tableData?.summary?.noPO ? formatNumber(tableData?.summary.noPO?.sellTotal ?? 0) : 0} บาท</div>
          </>
        ),
        value: Page.NoPO,
      },
      {
        label: (
          <>
            <div>SO ไม่พร้อมส่ง / มี PO ({tableData?.summary?.hasPO.count ?? 0})</div>
            <div>{!!tableData?.summary?.hasPO ? formatNumber(tableData?.summary?.hasPO.sellTotal ?? 0) : 0} บาท</div>
          </>
        ),
        value: Page.HasPO,
      },
      {
        label: (
          <>
            <div>SO พร้อมส่ง ({tableData?.summary?.normalNoTransportTask.count ?? 0})</div>
            <div>
              {!!tableData?.summary?.normalNoTransportTask
                ? formatNumber(tableData?.summary?.normalNoTransportTask.sellTotal ?? 0) ?? 0
                : 0}{' '}
              บาท
            </div>
          </>
        ),
        value: Page.NormalNoTransportTask,
      },
      {
        label: (
          <>
            <div>SO กำลังจัดส่ง ({tableData?.summary?.normalHasTransportTask.count ?? 0})</div>
            <div>
              {!!tableData?.summary?.normalHasTransportTask
                ? formatNumber(tableData?.summary?.normalHasTransportTask.sellTotal ?? 0) ?? 0
                : 0}{' '}
              บาท
            </div>
          </>
        ),
        value: Page.NormalHasTransportTask,
      },
      {
        label: (
          <>
            <div>SO ส่งแล้ว / ยังไม่ได้รับเงิน ({tableData?.summary?.debt.count ?? 0})</div>
            <div>{!!tableData?.summary?.debt ? formatNumber(tableData?.summary?.debt.sellTotal ?? 0) : 0} บาท</div>
          </>
        ),
        value: Page.Debt,
      },
      {
        label: (
          <>
            <div>SO ส่งแล้ว / รับเงินแล้ว ({tableData?.summary?.complete.count ?? 0})</div>
            <div>
              {!!tableData?.summary?.complete ? formatNumber(tableData?.summary?.complete.sellTotal ?? 0) : 0} บาท
            </div>
          </>
        ),
        value: Page.Complete,
      },
    ]
  }, [tableData?.summary])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!tableData) return

    const _pendingSearch = tableData.pendingSearch
    const _defaultSearch = buildSearchCriteriaByPage(tabValue)

    const _pendingStatusList = _pendingSearch.statusList
    const _pendingPaymentStatusList = _pendingSearch.paymentStatusList

    tableData.handleSearchParamsChange?.(
      {
        ..._pendingSearch,
        statusList:
          !_pendingStatusList || _pendingStatusList.length === 0 ? _defaultSearch.statusList : _pendingStatusList,
        paymentStatusList:
          !_pendingPaymentStatusList || _pendingPaymentStatusList.length === 0
            ? _defaultSearch.paymentStatusList
            : _pendingPaymentStatusList,
      },
      true,
    )
  }

  const _soStatusOptions = useMemo(() => {
    switch (tabValue) {
      case Page.Canceled:
        return undefined
      case Page.NoPO:
      case Page.HasPO:
      case Page.NormalNoTransportTask:
        return soStatusOptions.filter((o) => _activeSOStatus.includes(Number(o.value)))
      case Page.Debt:
      case Page.Complete:
        return soStatusOptions.filter((o) => _closedSOStatus.includes(Number(o.value)))
      default:
        return soStatusOptions
    }
  }, [tabValue])

  const _soPaymentStatusOptions = useMemo(() => {
    switch (tabValue) {
      case Page.Debt:
        return soPaymentStatusOptions.filter((o) =>
          [SOPaymentStatus.Pending, SOPaymentStatus.Deposit].includes(Number(o.value)),
        )
      case Page.Complete:
        return undefined
      default:
        return soPaymentStatusOptions
    }
  }, [tabValue])

  return (
    <Box p={2}>
      <Paper>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FlexBox alignItems="center">
              <Topic>รายการใบสั่งขาย</Topic>
              <FormControlLabel
                control={
                  <Switch
                    checked={searchOpened}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchOpened(event.target.checked)
                    }}
                    color="secondary"
                    className="ml-6"
                  />
                }
                label={
                  <div className="flex">
                    <span>ค้นหารายละเอียดเพิ่มเติม</span>
                    {flagSearchData && <div className="ml-1 w-2 h-2 bg-red-500 rounded-full" />}
                  </div>
                }
              />
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <NotificationHead navigationKey={NavigationItemKey.SaleOrder} />
          </Grid>
          <Grid item xs={12}>
            {searchOpened && (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="date"
                      name="startDate"
                      onChange={(e) => tableData?.handleSearchParamChange('startDate', e.target.value)}
                      label="วันที่"
                      defaultValue={
                        tableData?.pendingSearch?.startDate
                          ? moment(tableData?.pendingSearch?.startDate).format('YYYY-MM-DD')
                          : undefined
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="date"
                      name="endDate"
                      onChange={(e) => tableData?.handleSearchParamChange('endDate', e.target.value)}
                      label="ถึงวันที่"
                      defaultValue={
                        tableData?.pendingSearch?.endDate
                          ? moment(tableData?.pendingSearch?.endDate).format('YYYY-MM-DD')
                          : undefined
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="code"
                      onChange={(e) => tableData?.handleSearchParamChange('code', e.target.value)}
                      label="รหัสใบสั่งขาย"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="customerName"
                      onChange={(e) => tableData?.handleSearchParamChange('customerName', e.target.value)}
                      label="ชื่อลูกค้า"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="phoneNumber"
                      onChange={(e) => tableData?.handleSearchParamChange('phoneNumber', e.target.value)}
                      label="เบอร์โทรศัพท์"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="email"
                      onChange={(e) => tableData?.handleSearchParamChange('email', e.target.value)}
                      label="อีเมล"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      id="tags-standard"
                      size="small"
                      options={assigneeUser?.map((opt) => opt.id) as number[]}
                      getOptionLabel={(opt) => {
                        const foundUser = assigneeUser?.find((user) => user.id === opt)
                        return foundUser ? `${foundUser.firstName} ${foundUser.lastName}` : ''
                      }}
                      onChange={(event, newValue) => {
                        tableData?.handleSearchParamChange('assigneeUserId', newValue || undefined)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="outlined"
                          label="ผู้รับผิดชอบ"
                          placeholder="ผู้รับผิดชอบ"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="customerPurchaseReference"
                      onChange={(e) => tableData?.handleSearchParamChange('customerPurchaseReference', e.target.value)}
                      label="เลข PO อ้างอิง"
                    />
                  </Grid>
                  {_soPaymentStatusOptions && (
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        size="small"
                        getOptionLabel={(option) => {
                          return _soPaymentStatusOptions.find((row) => row.value === option)?.label ?? ''
                        }}
                        options={_soPaymentStatusOptions.map((option) => option.value)}
                        defaultValue={[]}
                        value={tableData?.pendingSearch.paymentStatusList?.map((s) => s.toString()) ?? []}
                        renderTags={(value: string[], getTagProps) => {
                          return value.map((option: string, index: number) => (
                            <Chip
                              variant="outlined"
                              label={_soPaymentStatusOptions.find((row) => row.value === option)?.label}
                              {...getTagProps({ index })}
                            />
                          ))
                        }}
                        onChange={(event, newValue) => {
                          tableData?.handleSearchParamChange(
                            'paymentStatusList',
                            newValue.map((v) => Number(v)),
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            label="สถานะชำระเงิน"
                            placeholder="สถานะชำระเงิน"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {_soStatusOptions && (
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        size="small"
                        getOptionLabel={(option) => {
                          return _soStatusOptions.find((row) => row.value === option)?.label ?? ''
                        }}
                        options={_soStatusOptions.map((option) => option.value)}
                        defaultValue={[]}
                        value={tableData?.pendingSearch.statusList?.map((s) => s.toString()) ?? []}
                        renderTags={(value: string[], getTagProps) => {
                          return value.map((option: string, index: number) => (
                            <Chip
                              variant="outlined"
                              label={_soStatusOptions.find((row) => row.value === option)?.label}
                              {...getTagProps({ index })}
                            />
                          ))
                        }}
                        onChange={(event, newValue) => {
                          tableData?.handleSearchParamChange(
                            'statusList',
                            newValue.map((v) => Number(v)),
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            label="สถานะใบสั่งขาย"
                            placeholder="สถานะใบสั่งขาย"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {[Page.NormalNoTransportTask, Page.NoPO, Page.HasPO].includes(tabValue) && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <FlexBox>
                          <FormGroup row className="mt-1">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => tableData?.handleSearchParamChange('needToFollow', e.target.checked)}
                                  checked={tableData?.pendingSearch?.needToFollow || false}
                                />
                              }
                              label="ใบสั่งขายที่ ต้องติดตามลูกค้า"
                            />
                          </FormGroup>
                        </FlexBox>
                      </Grid>
                    </>
                  )}
                  {tabValue === Page.Canceled && (
                    <>
                      <Grid item xs={12}>
                        <FlexBox>
                          <FormGroup row className="mt-1">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    tableData?.handleSearchParamChange('reclaimProductPending', e.target.checked)
                                  }
                                  checked={tableData?.pendingSearch?.reclaimProductPending}
                                />
                              }
                              label="ยังไม่ได้ของคืน"
                            />
                          </FormGroup>
                          <FormGroup row className="mt-1">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    tableData?.handleSearchParamChange('creditNotePending', e.target.checked)
                                  }
                                  checked={tableData?.pendingSearch?.creditNotePending}
                                />
                              }
                              label="ยังไม่ออกใบลดหนี้"
                            />
                          </FormGroup>
                          <FormGroup row className="mt-1">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    tableData?.handleSearchParamChange('refundPending', e.target.checked)
                                  }
                                  checked={tableData?.pendingSearch?.refundPending}
                                />
                              }
                              label="ยังไม่ได้คืนเงินลูกค้า"
                            />
                          </FormGroup>
                        </FlexBox>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
                  <Grid item xs={12} sm={10}></Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="button"
                      type="submit"
                    >
                      ค้นหา
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
            <FilteredTable<ISaleOrderTableData_Data>
              color="primary.dark"
              columns={[
                {
                  name: '',
                  render: ({
                    status,
                    stockReadyToShip,
                    creditNotePending,
                    reclaimProductPending,
                    refundPending,
                    activeShippingTaskAmount,
                    needToFollow,
                  }) => {
                    const alertProcessingTransportTask =
                      activeShippingTaskAmount && tabValue === Page.NormalHasTransportTask
                    let showTagSaleOrder
                    // for funnel canceled
                    if (
                      status.id === SaleOrderStatus.Cancelled &&
                      (creditNotePending || reclaimProductPending || refundPending)
                    ) {
                      showTagSaleOrder = (
                        <div className="signal-cell">
                          <SaleOrderCancelByCustomerSignal
                            creditNotePending={!!creditNotePending}
                            reclaimProductPending={!!reclaimProductPending}
                            refundPending={!!refundPending}
                          />
                        </div>
                      )
                    }

                    // if completely close then show nothing
                    if (_activeSOStatus.includes(status.id)) {
                      showTagSaleOrder = (
                        <div className="signal-cell">{!stockReadyToShip && <InsufficientProductSignal />}</div>
                      )
                    }

                    return (
                      <FlexBox alignItems="center" gridGap={4}>
                        {showTagSaleOrder}
                        {alertProcessingTransportTask && (
                          <div className="signal-cell">
                            {activeShippingTaskAmount && <TransportTaskProcessingSignal />}
                          </div>
                        )}
                        {needToFollow && <FollowCustomerSignal className="mr-1" />}
                      </FlexBox>
                    )
                  },
                },
                {
                  name: 'สถานะใบสั่งขาย',
                  render: ({ status: { id, name } }) => <SOStatusChip status={id} label={name} />,
                },
                {
                  name: 'สถานะชำระเงิน',
                  render: ({ paymentStatus: { id, name } }) => <SOPaymentStatusChip status={id} label={name} />,
                },
                {
                  name: 'เลขที่ใบสั่งขาย',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/sale-orders/${id}`}>
                      {code}
                    </Link>
                  ),
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) =>
                    format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
                },
                {
                  name: 'ผู้รับผิดชอบ',
                  render: ({ assignee }) => (
                    <div>{assignee?.id ? assignee.firstName + ' ' + assignee.lastName : ''}</div>
                  ),
                },
                {
                  name: 'ชื่อลูกค้า',
                  render: (saleOrder) => saleOrder.customer.nameLocal,
                },
                {
                  name: 'เบอร์โทรศัพท์',
                  render: (saleOrder) => saleOrder.customer.phoneNumber,
                },
                {
                  name: 'อีเมล',
                  render: (saleOrder) => saleOrder.customer.email,
                },
                {
                  name: 'เลขที่ PO ของลูกค้า',
                  dataIndex: 'customerPurchaseReference',
                },
                {
                  name: 'เงื่อนไขการชำระเงิน',
                  render: (saleOrder) => saleOrder.paymentCondition.name,
                },
                {
                  name: 'ยอดรวมสุทธิ',
                  dataIndex: 'total',
                  numeric: true,
                  format: '0,0.00',
                },
              ]}
              data={tableData?.data ?? []}
              paginationInfo={tableData?.paginationInfo}
              onPageChange={tableData?.handlePageChange}
              onRowPerPageChange={tableData?.handleRowPerPageChange}
              cleanSpace
              size="small"
              disableDateRangeFilter
              disableSearch
              tabOption={{
                tabs: tabs,
                value: tabValue,
                onChange: (_, newValue) => {
                  setTabValue(newValue)
                  tableData?.handleSearchParamsChange?.(buildSearchCriteriaByPage(newValue), true)
                  // flagSearchData(newValue)
                },
              }}
              isLoading={!tableData || tableData.isLoading}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default withLayout(SaleOrderTableDataPage, true)
