import { useState, useEffect } from 'react'
import { getPurchaseProductHistory, PurchaseProductPurchaseHistory } from '../api/smartbestbuys-api'

export const usePurchaseProductHistory = (purchaseProductId?: number): PurchaseProductPurchaseHistory[] | undefined => {
  const [data, setData] = useState<PurchaseProductPurchaseHistory[] | undefined>()

  const _getPurchaseProductHistory = async (purchaseProductId: number) => {
    const response = await getPurchaseProductHistory(purchaseProductId)
    setData(response?.data)
  }

  useEffect(() => {
    if (purchaseProductId) {
      _getPurchaseProductHistory(purchaseProductId)
    } else {
      setData(undefined)
    }
  }, [purchaseProductId])

  return data
}
