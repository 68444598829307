import { Card, Typography } from '@material-ui/core'
import { ResponsiveBar, BarDatum } from '@nivo/bar'
import { FlexBox, Title } from '../../custom-components'
import { ButtonRadios } from '../../shared-components'
import { NivoColorPalette } from '../palette'
import { useStyles, nivoTheme } from './styles'
import numeral from 'numeral'

export enum BarDateGroupFilter {
  Daily = 'dd MMM yy',
  Weekly = `'สัปดาห์ที่' wo / yy`,
  Monthly = 'MMM yy',
}

interface Props<T extends BarDatum> {
  title: string
  head?: React.ReactNode
  keys: string[]
  colors?: NivoColorPalette[]
  data: T[]
  indexBy: keyof T
  textMapping?: {
    [k in keyof Partial<T>]: string
  }
  xLegend?: string
  yLegend?: string
  grouped?: boolean
  onDateGroupFilterChange?: (dateGroupFilter: BarDateGroupFilter) => void
}

const Bar = <T extends BarDatum>(props: Props<T>) => {
  const styles = useStyles()
  const { title, keys, colors, data, indexBy, xLegend, yLegend, textMapping, grouped, onDateGroupFilterChange, head } =
    props
  return (
    <Card className={styles.card}>
      {head ? head : <Title>{title}</Title>}
      <div
        style={{
          height: '87.5%',
        }}
      >
        <ResponsiveBar
          data={data}
          keys={keys}
          groupMode={grouped ? 'grouped' : 'stacked'}
          indexBy={indexBy as string}
          margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          theme={nivoTheme}
          colors={colors}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xLegend,
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          axisLeft={{
            format: (value) => numeral(value).format('0,0a'),
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLegend,
            legendPosition: 'middle',
            legendOffset: -50,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelFormat=",.2s"
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          tooltip={({ id, value }) => (
            <Card>
              <Typography variant="subtitle1">
                {textMapping ? textMapping[id] : id}: {numeral(value).format('0,0.00')}
              </Typography>
            </Card>
          )}
          valueFormat={(value) => numeral(value).format('0,0.00')}
          legendLabel={({ id }) => `${textMapping ? textMapping[id] : id}`}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
      <FlexBox justifyContent="flex-end">
        <ButtonRadios
          color="secondary"
          items={[
            { id: 1, value: BarDateGroupFilter.Daily, name: 'รายวัน' },
            { id: 2, value: BarDateGroupFilter.Weekly, name: 'รายสัปดาห์' },
            { id: 3, value: BarDateGroupFilter.Monthly, name: 'รายเดือน' },
          ]}
          onValueChange={(value) => onDateGroupFilterChange && onDateGroupFilterChange(value as BarDateGroupFilter)}
          grouped
          defaultValue={BarDateGroupFilter.Monthly}
        />
      </FlexBox>
    </Card>
  )
}

export default Bar
