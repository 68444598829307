import { errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import smartAuthAPI2 from '../smartbestbuys-api-2'
import { UserActivityNote } from '../types'
import { GetCustomerTableDataRequestQueryParam, ICustomerShippingAddress, ICustomerTableData } from './customer-types'
import { ICreditor } from './types'

export const fetchCustomerShippingAddressesAPI = errorSingleHandler<ICustomerShippingAddress[]>(
  (customerId: number) => {
    return smartAuthAPI.get(`/customers/${customerId}/shipping-addresses`)
  },
)

export const fetchCustomerCreditorAPI = errorHandler<ICreditor[]>(() => {
  return smartAuthAPI.get(`/finance/v1/creditors`)
})

export const getCustomerTableDataAPI = errorHandler<ICustomerTableData>(
  async (queryParams: GetCustomerTableDataRequestQueryParam) => {
    return await smartAuthAPI2.get(`/customers/table-data`, {
      params: queryParams,
    })
  },
)

export const deleteCustomerAPI = errorSingleHandler<{ success: boolean }>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.patch(`customers/${id}/delete`, request)
  },
)

export const restoreCustomerAPI = errorSingleHandler<{ success: boolean }>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.patch(`customers/${id}/restore`, request)
  },
)
