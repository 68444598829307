import { useState, useEffect } from 'react'
import { getCreatePurchaseOrder, PurchaseOrderCreateData } from '../api/smartbestbuys-api'

export const usePurchaseOrderCreateData = (purchaseRequisitionId: number): PurchaseOrderCreateData | undefined => {
  const [data, setData] = useState<PurchaseOrderCreateData | undefined>()

  const _getCreatePurchaseOrder = async (purchaseRequisitionId: number) => {
    const response = await getCreatePurchaseOrder(purchaseRequisitionId)
    setData(response?.data)
  }

  useEffect(() => {
    _getCreatePurchaseOrder(purchaseRequisitionId)
  }, [purchaseRequisitionId])

  return data
}
