import { useContext, useState } from 'react'
import { RootContext } from '../../..'
import { DocumentStatus, NavigationItemKey, PageName, SaleOrderStatus, UserAction } from '../../../enums'
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Chip,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { InfoOutlined, Cancel, Description } from '@material-ui/icons'
import numeral from 'numeral'
import { BaseData, ITaxInvoiceTableData_Data } from '../../../api/smartbestbuys-api'
import { Topic, FlexBox, withLayout, Link, Table, GeneralTextField } from '../../../custom-components'
import { dateTimeFormat, calculateExcludingVat } from '../../../functions'
import { formatNumber } from '../../../functions/number-utils'
import {
  NotificationHead,
  Space,
  DocumentStatusChip,
  SOStatus,
  DocumentCancellationDialog,
  Loading,
  SOPaymentStatus,
  TaxInvoiceCSVDownloader,
  MultipleConditionTooltip,
} from '../../../shared-components'
import { useActionMessages, useCustomerProfile, useDocument, useTaxInvoiceTableData } from '../../../hooks'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { Autocomplete } from '@material-ui/lab'
import { documentStatuses } from '../../../constants'
import TaxInvoiceCreateReceiptDialog from './TaxInvoiceCreateReceiptDialog'
import { TaxInvoiceState, Document } from '../../../api/smartbestbuys-api'

const TaxInvoiceTableDataPage = () => {
  const { setCurrentPage } = useContext(RootContext)

  setCurrentPage(PageName.TAX_INVOICE)

  const actionMessages = useActionMessages(UserAction.CancelDocument)

  const tableData = useTaxInvoiceTableData({
    pageIndex: 0,
    rowPerPage: 10,
    search: {
      statusIds: [],
      documentCode: undefined,
      customerName: undefined,
      saleOrderCode: undefined,
      hasReceipt: undefined,
    },
  })

  if (!tableData) return <Loading />
  const { paginationInfo, isLoading, handleSearch, handlePageChange, handleRowPerPageChange, handleSearchParamChange } =
    tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Topic>รายการใบกำกับภาษี</Topic>
          <TaxInvoiceCSVDownloader />
        </FlexBox>
      </Grid>
      <Grid item xs={12}>
        <NotificationHead navigationKey={NavigationItemKey.TaxInvoice} />
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <GeneralTextField
                type="text"
                name="documentCode"
                onChange={(e) => handleSearchParamChange('documentCode', e.target.value)}
                label="รหัสใบกำกับภาษี"
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <GeneralTextField
                type="text"
                name="customerName"
                onChange={(e) => handleSearchParamChange('customerName', e.target.value)}
                label="ชื่อลูกค้า"
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <GeneralTextField
                type="text"
                name="saleOrderCode"
                onChange={(e) => handleSearchParamChange('saleOrderCode', e.target.value)}
                label="รหัสใบสั่งขาย"
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3} xl={2}>
              <Autocomplete<number, true>
                multiple
                id="tags-standard"
                size="small"
                options={documentStatuses.map((d) => d.id)}
                getOptionLabel={(value) => documentStatuses.find((ds) => ds.id === value)?.name ?? '--'}
                defaultValue={[]}
                renderTags={(ids: number[], getTagProps) => {
                  return ids.map((_id: number, index: number) => (
                    <Chip
                      variant="outlined"
                      label={documentStatuses.find((ds) => ds.id === _id)?.name ?? '--'}
                      {...getTagProps({ index })}
                    />
                  ))
                }}
                onChange={(event, newValue) => {
                  handleSearchParamChange('statusIds', newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} margin="dense" variant="outlined" label="สถานะ" placeholder="สถานะ" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3} className="flex items-center">
              <FormControlLabel
                control={<Checkbox onChange={(e) => handleSearchParamChange('hasReceipt', !e.target.checked)} />}
                label="ยังไม่ออกใบเสร็จ"
              />
            </Grid>
          </Grid>
          <div className="mt-2 flex justify-end w-full">
            <Button
              className="w-40"
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={8} /> : undefined}
            >
              ค้นหา
            </Button>
          </div>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Table<ITaxInvoiceTableData_Data>
          color="primary.dark"
          columns={[
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
            },
            {
              name: 'เลขใบกำกับภาษี',
              csvRender: ({ code }) => code,
              render: ({ id, code }) => (
                <Link color="primary" to={`/tax-invoices/${id}/pdf`} target="_blank">
                  {code}
                </Link>
              ),
            },
            {
              name: 'ประเภทใบกำกับภาษี',
              csvRender: ({ isDeposit }) => (isDeposit ? 'มัดจำ' : 'ทั่วไป'),
              render: ({ isDeposit }) => (isDeposit ? 'มัดจำ' : 'ทั่วไป'),
            },
            {
              name: 'เลขใบเสร็จรับเงิน | ใบวางบิล',
              csvRender: ({ receipt, billingNote }) => [receipt?.code ?? '--', billingNote?.code ?? '--'].join(' | '),
              render: ({ receipt, billingNote }) => (
                <Typography color="textSecondary" variant="body2">
                  {[receipt?.code ?? '--', billingNote?.code ?? '--'].join(' | ')}
                </Typography>
              ),
            },
            {
              name: 'เลขใบสั่งขายอ้างอิง',
              csvRender: ({ saleOrder }) => saleOrder?.code,
              render: ({ saleOrder }) => (
                <Link color="secondary" to={`/sale-orders/${saleOrder?.id}`}>
                  {saleOrder?.code}
                </Link>
              ),
            },
            {
              name: 'รายได้อ้างอิง (SPAY)',
              csvRender: ({ paymentCode }) => paymentCode,
              render: ({ paymentCode }) =>
                paymentCode ? (
                  <Typography color="secondary" variant="body2">
                    {paymentCode}
                  </Typography>
                ) : (
                  <Typography color="textSecondary" variant="body2">
                    -
                  </Typography>
                ),
            },
            {
              name: 'ชื่อลูกค้า',
              render: ({ customer, billingAddress }) =>
                billingAddress?.overrideDocumentData?.customerName || customer?.nameLocal || '------',
            },
            {
              name: 'เลขที่ผู้เสียภาษี',
              render: ({ customer, billingAddress }) =>
                billingAddress?.overrideDocumentData?.taxNumber || customer?.referenceNumber || '------',
            },
            {
              name: 'สาขา',
              render: ({ billingAddress }) => billingAddress?.branchName || '------',
            },
            {
              name: 'ยอดก่อน VAT',
              csvRender: ({ total, saleOrder, status }) => {
                if (total === undefined) return '--'
                if (status.id === DocumentStatus.Cancelled) return '0.00'
                if (!saleOrder?.vatIncluded) return formatNumber(total)
                return formatNumber(calculateExcludingVat(total))
              },
              render: ({ total, saleOrder }) => {
                if (total === undefined) return 'ไม่สามารถคำนวณได้'
                if (!saleOrder?.vatIncluded) return total
                return calculateExcludingVat(total)
              },
              numeric: true,
              format: '0,0.00',
              hide: true,
            },
            {
              name: 'VAT',
              csvRender: ({ total, saleOrder, status }) => {
                if (total === undefined || status.id === DocumentStatus.Cancelled || !saleOrder?.vatIncluded)
                  return '0.00'
                const vatFreeAmount = calculateExcludingVat(total)
                return formatNumber(total - vatFreeAmount)
              },
              render: ({ total, saleOrder }) => {
                if (total === undefined || !saleOrder?.vatIncluded) return 0
                const vatFreeAmount = calculateExcludingVat(total)
                return formatNumber(total - vatFreeAmount)
              },
              numeric: true,
              format: '0,0.00',
              hide: true,
            },
            {
              name: 'ยอดเงินสุทธิ',
              dataIndex: 'total',
              csvRender: ({ total }) => formatNumber(total ?? 0),
              render: ({ total, saleOrder }) => {
                if (!saleOrder?.vatIncluded)
                  return (
                    <div className="flex items-center justify-end">
                      <span>{numeral(total).format('0,0.00')}</span>
                    </div>
                  )

                const vatFreeAmount = calculateExcludingVat(total ?? 0)
                return (
                  <Tooltip
                    title={
                      <div style={{ color: 'white' }}>
                        <FlexBox justifyContent="space-between">
                          <span className="font-bold">ยอดก่อน vat:</span>
                          <Space direction="horizontal" />
                          <span>{formatNumber(vatFreeAmount)}</span>
                        </FlexBox>
                        <FlexBox justifyContent="space-between">
                          <span className="font-bold">vat:</span>
                          <Space direction="horizontal" />
                          <span>{formatNumber((total ?? 0) - vatFreeAmount)}</span>
                        </FlexBox>
                        <FlexBox justifyContent="space-between">
                          <span className="font-bold">ยอดเงินสุทธิ:</span>
                          <Space direction="horizontal" />
                          <span>{formatNumber(total ?? 0)}</span>
                        </FlexBox>
                      </div>
                    }
                    placement="top-end"
                  >
                    <div className="flex items-center justify-end">
                      <span>{numeral(total).format('0,0.00')}</span>
                      <InfoOutlined style={{ fontSize: '1rem', marginLeft: 2 }} />
                    </div>
                  </Tooltip>
                )
              },
            },
            {
              name: 'สถานะใบกำกับภาษี',
              csvRender: ({ status: { name } }) => name,
              render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
            },
            {
              name: 'สถานะใบสั่งขาย | จ่ายเงิน',
              csvRender: ({ saleOrder }) => {
                if (!saleOrder) return '-'
                const { status, paymentStatus } = saleOrder
                return `${status?.name ?? '--'} | ${paymentStatus?.name ?? '--'}`
              },
              render: ({ saleOrder }) => {
                if (!saleOrder) return '-'
                const { paymentStatus, status } = saleOrder
                return [
                  status ? (
                    <SOStatus status={status} />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      --
                    </Typography>
                  ),
                  <Typography variant="body2" color="textSecondary">
                    {` | `}
                  </Typography>,
                  paymentStatus ? (
                    <SOPaymentStatus status={paymentStatus} />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      --
                    </Typography>
                  ),
                ]
              },
            },
            {
              name: '',
              csvRender: () => {},
              render: (taxInvoice) => (
                <FlexBox alignItems="center">
                  <CreateReceiptFromTaxInvoice
                    taxInvoice={taxInvoice}
                    receiptCode={taxInvoice?.receipt?.code}
                    onSuccess={() => {
                      if (!!tableData.refetch) tableData.refetch()
                    }}
                  />
                  <Action
                    actionMessages={actionMessages}
                    taxInvoice={taxInvoice}
                    onCancel={() => {
                      if (!!tableData.refetch) tableData.refetch()
                    }}
                  />
                </FlexBox>
              ),
            },
          ]}
          data={tableData.data}
          size="small"
          paginationInfo={paginationInfo}
          onPageChange={handlePageChange}
          onRowPerPageChange={handleRowPerPageChange}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(TaxInvoiceTableDataPage, true)

const Action = ({
  actionMessages,
  taxInvoice,
  onCancel,
}: {
  actionMessages: BaseData[]
  taxInvoice: ITaxInvoiceTableData_Data
  onCancel: () => void
}) => {
  // context
  const { triggerSnackbar } = useContext(RootContext)

  // state
  const [open, setOpen] = useState(false)

  // permission
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  if (!cancelPermission.hasPermission || taxInvoice.status.id === DocumentStatus.Cancelled) return <></>

  return (
    <>
      <FlexBox gridGap={8}>
        <Tooltip title="ยกเลิกใบกำกับภาษี">
          <IconButton size="small" onClick={() => setOpen(true)}>
            <Cancel fontSize="small" />
          </IconButton>
        </Tooltip>
      </FlexBox>
      <DocumentCancellationDialog
        messages={actionMessages}
        documentInfo={taxInvoice}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={() => {
          triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
          onCancel()
        }}
        onFail={() => {
          triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
        }}
      />
    </>
  )
}

const CreateReceiptFromTaxInvoice = ({
  taxInvoice,
  receiptCode,
  onSuccess,
}: {
  taxInvoice: ITaxInvoiceTableData_Data
  receiptCode?: string
  onSuccess: () => void
}) => {
  const [taxInvoiceId, setTaxInvoiceId] = useState<number>(0)
  const [customerId, setCustomerId] = useState<number>(0)
  const getTaxInvoice = useDocument<TaxInvoiceState>(taxInvoiceId) as Document<any, TaxInvoiceState>
  const customerProfile = useCustomerProfile(customerId)

  // context
  const { triggerSnackbar } = useContext(RootContext)

  // state
  const [open, setOpen] = useState(false)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Write)
  if (!writePermission.hasPermission || taxInvoice.status.id === DocumentStatus.Cancelled || receiptCode) return <></>

  const isCancelSO = taxInvoice.saleOrder?.status.id === SaleOrderStatus.Cancelled

  const conditions = [
    { showTooltip: isCancelSO, message: 'ใบสั่งขายถูกยกเลิกแล้ว ไม่สามารถสร้างใบเสร็จรับเงินได้' },
    { showTooltip: true, message: 'สร้างใบเสร็จรับเงิน' },
  ]

  return (
    <>
      <FlexBox gridGap={8}>
        <MultipleConditionTooltip conditionItems={conditions}>
          <IconButton
            size="small"
            disabled={isCancelSO}
            onClick={() => {
              setTaxInvoiceId(taxInvoice.id)
              setCustomerId(taxInvoice.customer?.id as number)
              setOpen(true)
            }}
          >
            <Description fontSize="small" />
          </IconButton>
        </MultipleConditionTooltip>
      </FlexBox>
      {getTaxInvoice && customerProfile && (
        <TaxInvoiceCreateReceiptDialog
          taxInvoice={getTaxInvoice}
          customerProfile={customerProfile}
          open={open}
          onClose={() => setOpen(false)}
          onSuccess={() => {
            triggerSnackbar('สร้างใบเสร็จรับเงินสำเร็จ')
            setOpen(false)
            onSuccess()
          }}
          onFail={() => {
            triggerSnackbar('สร้างใบเสร็จรับเงินไม่สำเร็จ')
          }}
        />
      )}
    </>
  )
}
