import { errorGenericHandle } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export interface CustomerWithContactURLParams {
  customerId: number
  contactId: number
}

export interface CustomerContactUpdationRequest {
  nameLocal?: string
  nameEn?: string
  nicknameLocal: string
  nicknameEn?: string
  customerDepartmentId?: number
  phoneNumber: string
  email?: string
  line?: string
  facebook?: string
  birthdate?: Date
  gender?: string
}

export interface UpdatedCustomerContactResult {
  id: number
  customer_id: number
  name_local?: string
  name_en?: string
  nickname_local: string
  nickname_en?: string
  position_name?: string
  phone_number: string
  email?: string
  line?: string
  facebook?: string
  birthdate?: Date
  gender?: string
  rec_modified_at: Date
  rec_modified_by: string
}

export const updateCustomerContactAPI = errorGenericHandle<UpdatedCustomerContactResult, CustomerWithContactURLParams>(
  (urlParams: CustomerWithContactURLParams, request: CustomerContactUpdationRequest) => {
    const path = `/customers/${urlParams.customerId}/contact/${urlParams.contactId}/update`
    return smartAuthAPI.put(path, request)
  },
)
