import React, { useMemo, useState } from 'react'
import { Avatar, Card, Dialog, ButtonBase, Typography } from '@material-ui/core'
import { FlexBox } from '../../custom-components'
import { useStyles } from './styles'

interface Props {
  name: string
  src: string
  size?: 'big' | 'small'
  showName?: boolean
}

const ImageCard: React.FC<Props> = ({ name, src, size, showName }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const togglePreview = () => setOpen((prev) => !prev)

  const avatarClassName: string = useMemo((): string => {
    switch (size) {
      case 'big':
        return classes.avatarBig
      case 'small':
        return classes.avatarSmall
      default:
        return classes.avatar
    }
  }, [classes.avatar, classes.avatarBig, classes.avatarSmall, size])

  return (
    <Card className={classes.card} variant="outlined">
      <Dialog open={open} onClose={togglePreview}>
        <img src={src} alt={name} />
      </Dialog>
      <FlexBox flexDirection="column" alignItems="center" gridGap={8}>
        <ButtonBase onClick={togglePreview}>
          <Avatar className={avatarClassName} src={src} alt={name} variant="square" />
        </ButtonBase>
        {showName && <Typography variant="body2">{name}</Typography>}
      </FlexBox>
    </Card>
  )
}

export default ImageCard
