import { Grid, Paper } from '@material-ui/core'

import { useFormContext } from 'react-hook-form'

import { GeneralTextFieldForm, Title } from '../../../custom-components'
import { IRefundRequisitionFormValues } from './RefundRequisitionForm'

const RefundRequisitionDetailBox = () => {
  const { control } = useFormContext<IRefundRequisitionFormValues>()

  return (
    <Paper>
      <Title>ข้อมูลเพิ่มเติม</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm control={control} label="หมายเหตุ" name="remark" />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default RefundRequisitionDetailBox
