import { Grid, Typography, Tabs, Tab, Box, Paper } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { CustomerShippingAddress } from '../../api/smartbestbuys-api'
import { GeneralTextFieldForm, EmptyCard, Radio, Form } from '../../custom-components'
import { useCustomerShippingAddresses } from '../../hooks/customer'
import { IShippingAddressFormValues } from './shipping-address-form-values'
import { NewShippingAddressForm } from './NewShippingAddressForm'
import Space from '../Space'
import { Loading } from '../Loading'
import { SubmitForm } from '../SubmitForm'
import { AddressData } from '../../api/smartbestbuys-api/data/data-types'
import { useAddressData } from '../../hooks'

interface ShippingAddressFormProps {
  customerId: number
  defaultValues?: {
    consigneeName?: string
    consigneePhoneNumber?: string
    shippingAddressId?: number
  }
  onSubmit: (
    values: IShippingAddressFormValues,
    shippingAddresses: CustomerShippingAddress[],
    addressData: AddressData,
  ) => void
  onCancel: () => void
}

export const ShippingAddressForm = (props: ShippingAddressFormProps) => {
  const { customerId, defaultValues, onSubmit, onCancel } = props

  // state
  const [tabValue, setTabValue] = useState(0)
  const [isNew, setIsNew] = useState<boolean>(true)

  const customerShippingAddresses = useCustomerShippingAddresses(customerId)
  const addressData = useAddressData()

  const method = useForm<IShippingAddressFormValues>({
    defaultValues: defaultValues
      ? {
          consigneeName: defaultValues.consigneeName,
          consigneePhoneNumber: defaultValues.consigneePhoneNumber,
          id: defaultValues.shippingAddressId,
        }
      : {},
  })

  const {
    control,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
  } = method
  const { id, consigneeName, consigneePhoneNumber } = watch()

  // effect
  useEffect(() => {
    if (!customerShippingAddresses) return
    if (customerShippingAddresses.length === 0) {
      setTabValue(1)
      setIsNew(true)
    } else {
      setTabValue(0)
      setIsNew(false)
    }
    // eslint-disable-next-line
  }, [customerShippingAddresses?.length])

  useEffect(() => {
    if (isNew) {
      unregister('id')
    } else {
      register('id', { required: 'กรุณาเลือกที่อยู่ของลูกค้า' })
      setValue('id', defaultValues?.shippingAddressId)
      clearErrors()
    }
    // eslint-disable-next-line
  }, [isNew, unregister, register])

  if (!customerShippingAddresses || !addressData) return <Loading />

  return (
    <FormProvider {...method}>
      <Form onSubmit={(values) => onSubmit(values, customerShippingAddresses, addressData)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="consigneeName"
              label="ชื่อ/ตำแหน่งผู้รับ *"
              rules={{ required: 'กรุณาชื่อ/ตำแหน่งผู้รับ' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="consigneePhoneNumber"
              label="เบอร์โทรผู้รับ *"
              rules={{ required: 'กรุณาเบอร์โทรผู้รับ' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">เลือกที่อยู่ขนส่ง</Typography>
            <Space />
            <Tabs
              style={{
                marginBottom: 4,
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
              }}
              variant="fullWidth"
              textColor="primary"
              value={tabValue}
              onChange={(e, value) => {
                const registerForm = value === 1
                setIsNew(registerForm)
                setTabValue(value)
                if (registerForm) {
                  setValue('id', undefined)
                } else {
                  reset({
                    consigneeName,
                    consigneePhoneNumber,
                  })
                }
              }}
              indicatorColor="primary"
              aria-label="tabs table"
            >
              <Tab label="ที่อยู่ขนส่งที่มีในระบบ" value={0} />
              <Tab label="ที่อยู่ขนส่งใหม่" value={1} />
            </Tabs>
            {
              [
                <ShippingAddressRadios
                  customerAddresses={customerShippingAddresses.slice(0, 5) ?? []}
                  value={id}
                  onChange={(addressId) => setValue('id', addressId)}
                  errorMessage={errors.id?.message}
                />,
                <NewShippingAddressForm addressData={addressData} />,
              ][tabValue]
            }
          </Grid>

          <Grid item xs={12}>
            <SubmitForm submitText="ยืนยันการแก้ไข" disableSubmit={isSubmitting} onCancel={onCancel} />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default ShippingAddressForm

interface Props {
  customerAddresses: CustomerShippingAddress[]
  value?: number
  onChange?: (value: number) => void
  errorMessage?: string
}

const ShippingAddressRadios = (props: Props) => {
  const { customerAddresses, value, onChange, errorMessage } = props
  return (
    <Box p={2} borderColor={errorMessage ? 'error.main' : undefined} clone>
      <Paper variant="outlined">
        <Grid container spacing={2}>
          {customerAddresses.length > 0 ? (
            customerAddresses.map((address) => (
              <Grid item xs={12} key={address.id}>
                <Radio
                  label={address.toString()}
                  value={address.id}
                  checked={value === address.id}
                  outlined
                  onChange={(e) => {
                    if (!!onChange) onChange(Number(e.target.value))
                  }}
                  color="primary"
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} key="empty card">
              <EmptyCard>ลูกค้ายังไม่มีที่อยู่จัดส่ง</EmptyCard>
            </Grid>
          )}
        </Grid>
        <Space />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Paper>
    </Box>
  )
}
