import { errorHandler, errorSingleHandler, errorGenericHandle } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import {
  CustomerRequisitionProfile,
  UpdateCustomerRequisitionConditionRequestBody,
  CreateCustomerRequisitionNoteRequestBody,
  IPendingPickupCustomerRequisitionTableData,
  ICustomerRequisitionProfile_Task,
  CustomerRequisitionShippingPendingTableData,
  CustomerRequisition,
  CreateCustomerRequisitionRequestBody,
  IGetCustomerRequisitionFormData,
  IGetCustomerRequisitionUpdateData,
  UpdateCustomerRequisitionRequestBody,
  ReceiveCRProductRequestBody,
} from './customer-requisition-interfaces'
import { UserActivityNote } from '..'

export const getCustomerRequisitionProfileAPI = errorHandler<CustomerRequisitionProfile>(async (id: number) => {
  return smartAuthAPI.get(`/customer-requisitions/v1/${id}/profile`)
})

export const updateCustomerRequisitionConditionAPI = errorSingleHandler<CustomerRequisitionProfile>(
  async (id: number, request: UpdateCustomerRequisitionConditionRequestBody) => {
    return smartAuthAPI.patch(`/customer-requisitions/v1/${id}/update-condition`, request)
  },
)

export const createCustomerRequisitionNoteAPI = errorSingleHandler<CustomerRequisitionProfile>(
  async (id: number, request: CreateCustomerRequisitionNoteRequestBody) => {
    return smartAuthAPI.post(`/customer-requisitions/v1/${id}/create-note`, request)
  },
)

export const getCustomerRequisitionsShippingPendingAPI = errorHandler<CustomerRequisitionShippingPendingTableData[]>(
  async () => {
    return smartAuthAPI.get(`/customer-requisitions/v1/shipping-pending`)
  },
)

export const getCustomerRequisitionsPendingPickupAPI = errorHandler<IPendingPickupCustomerRequisitionTableData[]>(
  async () => {
    return smartAuthAPI.get(`/customer-requisitions/v1/pending-pickup`)
  },
)

export const cancelCustomerRequisitionAPI = errorSingleHandler<CustomerRequisitionProfile>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`/customer-requisitions/v1/${id}/cancel`, request)
  },
)

export const approveCustomerRequisitionAPI = errorHandler<CustomerRequisitionProfile>(async (id: number) => {
  return smartAuthAPI.post(`/customer-requisitions/v1/${id}/approve`)
})

export const rejectCustomerRequisitionAPI = errorSingleHandler<CustomerRequisitionProfile>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`/customer-requisitions/v1/${id}/reject`, request)
  },
)

export const finishReceiveCRProductTaskAPI = errorGenericHandle<
  ICustomerRequisitionProfile_Task,
  { id: number; taskId: number }
>(async (urlParams: { id: number; taskId: number }, request: ReceiveCRProductRequestBody) => {
  return smartAuthAPI.post(`/customer-requisitions/v1/${urlParams.id}/tasks/${urlParams.taskId}/finish-task`, request)
})

export const fetchCustomerRequisitionsAPI = errorHandler<CustomerRequisition[]>(() => {
  return smartAuthAPI.get('/customer-requisitions/v1/')
})

export const createCustomerRequisitionAPI = errorHandler<CustomerRequisition>(
  async (request: CreateCustomerRequisitionRequestBody) => {
    return smartAuthAPI.post('/customer-requisitions/v1/create', request)
  },
)

export const getCustomerRequisitionCreateDataAPI = errorHandler<IGetCustomerRequisitionFormData>(
  async (saleOrderId: string) => {
    return smartAuthAPI.get('/customer-requisitions/v1/create-data', {
      params: { saleOrderId: Number(saleOrderId) },
    })
  },
)

export const updateCustomerRequisitionAPI = errorSingleHandler<CustomerRequisition>(
  async (id: number, request: UpdateCustomerRequisitionRequestBody) => {
    return smartAuthAPI.put(`/customer-requisitions/v1/${id}/update`, request)
  },
)

export const getCustomerRequisitionUpdateDataAPI = errorHandler<IGetCustomerRequisitionUpdateData>(
  async (id: string) => {
    return smartAuthAPI.get(`/customer-requisitions/v1/${id}/update-data`)
  },
)
