import { useEffect, useState } from 'react'
import { getCustomerRequisitionUpdateDataAPI, IGetCustomerRequisitionUpdateData } from '../api/smartbestbuys-api'

export const useCustomerRequisitionUpdateData = (
  id: number,
): [IGetCustomerRequisitionUpdateData | undefined, () => void] => {
  const [updateData, setUpdateData] = useState<IGetCustomerRequisitionUpdateData | undefined>(undefined)

  const [reload, setReload] = useState(false)

  const forceReload = () => {
    setReload((prev) => !prev)
  }

  useEffect(
    () => {
      ;(async () => {
        const response = await getCustomerRequisitionUpdateDataAPI(id)
        const data = response?.data

        setUpdateData(data)
      })()
    },
    // eslint-disable-next-line
    [id, reload],
  )

  return [updateData, forceReload]
}
