import { Grid } from '@material-ui/core'
import { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { RootContext } from '../../..'
import { createTransportPickupTaskAPI } from '../../../api/smartbestbuys-api'
import { Topic, withLayout } from '../../../custom-components'
import { PageName } from '../../../enums'
import { TransportTaskCreateState, TransportTaskItemType } from '../../../enums/TransportTaskEnums'
import { useAddressData } from '../../../hooks'
import { useTransportTaskCreateDataV2 } from '../../../hooks/transport/useTransportTaskCreateDataV2'
import { Loading } from '../../../shared-components'
import TransportPickupTaskForm from '../pickup-forms/TransportPickupTaskForm'
import { TransportPickupTaskFormValues } from '../pickup-forms/TransportPickupTaskFormValues'
import TransportPickupTaskVerificationForm from '../pickup-forms/TransportPickupTaskVerificationForm'

interface QueryParams {
  customerRequisitionId?: string
  saleOrderId?: string
}

interface Props {
  query: QueryParams
}

export const TransportPickupTaskCreatePage: FC<Props> = (props: Props) => {
  const { customerRequisitionId, saleOrderId } = props.query
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)

  // state
  const [state, setState] = useState<TransportTaskCreateState>(TransportTaskCreateState.Form)
  const [formValues, setFormValues] = useState<TransportPickupTaskFormValues>()
  const [submitting, setSubmitting] = useState(false)

  setCurrentPage(PageName.TRANSPORT_PICKUP_TASK)

  // call hooks
  const history = useHistory()
  const addressData = useAddressData()
  const createData = useTransportTaskCreateDataV2(customerRequisitionId, saleOrderId)

  if (!createData || !addressData) return <Loading />

  const handleConfirmValues = async (values: TransportPickupTaskFormValues) => {
    setSubmitting(true)
    const productItems = createData.customerRequisitionInfo?.products.map((p) => ({
      type: TransportTaskItemType.Product,
      id: p.id,
      code: p.code,
      name: p.nameLocal,
      amount: p.amount,
      imageUrl: p.imageUrl,
    }))

    const customerShippingAddress = createData.customerShippingAddresses.find(
      (a) => a.id === values.customerShippingAddress.id,
    )

    const request = {
      customerId: createData.saleOrderInfo?.customerId,
      saleOrderId: createData.saleOrderInfo?.id,
      customerRequisitionId: createData.customerRequisitionInfo?.id,
      items: productItems,
      dueDate: values.dueDate,
      messenger: values.messenger,
      car: values.car,
      remark: values.remark,
      consignee: values.consignee,
      phoneNumber: values.phoneNumber,

      customerShippingAddress: {
        id: values.customerShippingAddress.id,
        name: customerShippingAddress?.name ?? values.customerShippingAddress.name,
        branchName: customerShippingAddress?.branchName ?? values.customerShippingAddress.branchName,
        addressLine1: customerShippingAddress?.addressLine1 ?? values.customerShippingAddress.addressLine1,
        phoneNumber: customerShippingAddress?.phoneNumber ?? values.customerShippingAddress.phoneNumber,
        faxNumber: customerShippingAddress?.faxNumber ?? values.customerShippingAddress.faxNumber,

        province:
          customerShippingAddress?.province ??
          addressData?.provinces.find((d) => d.id === values.customerShippingAddress.provinceId),
        district:
          customerShippingAddress?.district ??
          addressData?.districts.find((d) => d.id === values.customerShippingAddress.districtId),
        subDistrict:
          customerShippingAddress?.subDistrict ??
          addressData?.subDistricts.find((d) => d.id === values.customerShippingAddress.subDistrictId),

        postalCode: customerShippingAddress?.postalCode ?? values.customerShippingAddress.postalCode,
        googleMapLink: customerShippingAddress?.googleMapLink ?? values.customerShippingAddress.googleMapLink,
      },

      assigneeUserId: values.assigneeUserId,
    }

    const response = await createTransportPickupTaskAPI(request)
    if (response?.status === 201) {
      triggerSnackbar('สร้างงานบริการรับของสำเร็จ')
      history.push(`/transport-pickup-tasks`)
    } else {
      triggerSnackbar('สร้างงานบริการรับของไม่สำเร็จ')
    }

    setSubmitting(false)
  }

  // render
  return (
    <Grid container spacing={2}>
      {!formValues || state === TransportTaskCreateState.Form ? (
        <>
          <Grid item xs={12}>
            <Topic>สร้างงานบริการรับสินค้าสำหรับคำขอลูกค้า {createData?.customerRequisitionInfo?.code}</Topic>
          </Grid>
          <Grid item xs={12}>
            <TransportPickupTaskForm
              formData={createData}
              onSubmit={(values) => {
                setFormValues(values)
                setState(TransportTaskCreateState.DataVerification)
              }}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <TransportPickupTaskVerificationForm
            formData={createData}
            formValues={formValues}
            onSubmit={(values) => handleConfirmValues(values)}
            onBack={() => {
              setState(TransportTaskCreateState.Form)
            }}
            submitting={submitting}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default withLayout(TransportPickupTaskCreatePage)
