import React, { FC } from 'react'
import ReactPDF, { View, StyleSheet, Text } from '@react-pdf/renderer'
import { PDFProps } from './props'
import { getViewStyles } from './styles'

interface Props {
  label: string
  color?: 'primary' | 'error'
  direction?: 'row'
  labelWidth?: number
  dataWidth?: number
  noMargin?: boolean
  align?: string
}

// TODO: should come from configuration something.
const pallete = {
  black: '#222021',
  darkGrey: '#6A6969',
  primary: '#E83872',
  error: '#CF505F',
}

const PDFInfo: FC<ReactPDF.ViewProps & Props & PDFProps> = (props) => {
  const { noMargin, labelWidth, dataWidth, direction, color, label, children, borders, borderColor, ...viewProps } =
    props

  const commonStyles = getViewStyles({
    borders,
    borderColor,
  })

  const styles = StyleSheet.create({
    infoBox: {
      display: 'flex',
      flexDirection: direction ? 'row' : 'column',
      justifyContent: direction ? 'space-between' : undefined,
      marginBottom: noMargin ? 0 : 4,
    },
    label: {
      width: labelWidth ? labelWidth : undefined,
      color: pallete.black,
      fontFamily: 'THSarabunNewBold',
    },
    data: {
      textAlign: direction ? 'right' : 'left',
      width: dataWidth ? dataWidth : undefined,
      color: color ? pallete[color] : pallete.black,
    },
  })
  const { style, ...otherProps } = viewProps
  return (
    <View
      style={{
        ...commonStyles,
        ...styles.infoBox,
        ...style,
      }}
      {...otherProps}
    >
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.data}>{children}</Text>
    </View>
  )
}

export default PDFInfo
