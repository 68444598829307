export const fileToBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader() // Read file content on file loaded event
    reader.onload = function (event: any) {
      resolve(event.target.result)
    } // Convert data to base64
    reader.onerror = function (event: any) {
      console.log(`Can't convert file`)
      reject('')
    }
    reader.readAsDataURL(file)
  })
}
