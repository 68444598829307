import { useEffect, useState } from 'react'
import { Collapse, Grid, Paper, Switch } from '@material-ui/core'

import { FlexBox, SubTopic } from '../../../../custom-components'
import FileCard from './FileCard'
import { SourceFile } from '../../../../types'
import { UploadCard } from './UploadCard'

interface Props {
  onChange?: (files: SourceFile[]) => void
  readonly?: boolean
  defaultValue?: SourceFile[]
}

const AttachmentSelectionBox = (props: Props) => {
  const { onChange, readonly, defaultValue } = props

  const [open, setOpen] = useState(!!defaultValue)
  const [files, setFiles] = useState<SourceFile[]>(defaultValue || [])

  useEffect(() => {
    if (!!defaultValue && defaultValue.length > 0) {
      setOpen(true)
      setFiles(defaultValue)
    }
  }, [defaultValue, setOpen])

  const handleFileChange = (file: SourceFile) => {
    setFiles((prev) => {
      const nextFiles = [...prev, file]
      if (!!onChange) onChange(nextFiles)
      return nextFiles
    })
  }

  const handleCollapseAttachment = () => {
    const isOpenAction = !open
    if (!!onChange) onChange(isOpenAction ? files : [])
    setOpen((prev) => !prev)
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)
    if (!!onChange) onChange(updatedFiles)
  }

  return (
    <Paper>
      <FlexBox justifyContent="space-between" alignItems="center">
        <SubTopic>ไฟล์แนบเพิ่มเติม</SubTopic>
        {!readonly && <Switch checked={!!open} onClick={() => handleCollapseAttachment()} />}
      </FlexBox>
      <Collapse in={!!open}>
        <Grid container className="pt-3" spacing={2}>
          {!readonly && (
            <Grid item xs={12} md={2}>
              <UploadCard onChange={handleFileChange} />
            </Grid>
          )}
          {files.map((file, index) => (
            <Grid item xs={12} md={2} key={file.name + index}>
              <FileCard value={file} onRemove={() => handleRemoveFile(index)} readonly={readonly} />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Paper>
  )
}

export default AttachmentSelectionBox
