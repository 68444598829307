import { Box, MenuItem, Paper } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { District, SubDistrict } from '../../../api/smartbestbuys-api/data/data-types'
import { GeneralTextFieldForm, Grid, Title } from '../../../custom-components'
import { useAddressData } from '../../../hooks/data/useAddressData'
import { ButtonRadios, Space } from '../../../shared-components'
import { VendorCreateFormValues } from './VendorCreatePage'

enum CustomerFormType {
  Local = 1,
  OtherCountry = 2,
}

interface VendorAddressFormProps {
  name?: string
}

export interface VendorCreateAddressFormValues {
  addressLine1: string
  provinceId?: number
  districtId?: number
  subDistrictId?: number
  phoneNumber: string
  faxNumber?: string
  postalCode?: string
  country?: string
}

const VendorAddressForm = (props: VendorAddressFormProps) => {
  // props
  const { name } = props

  // form hook
  const { control, watch, setValue } = useFormContext<VendorCreateFormValues>()

  const { address } = watch()
  const { postalCode, provinceId, districtId, subDistrictId } = { ...address }

  const addressData = useAddressData()
  // eslint-disable-next-line
  const provinces = useMemo(() => addressData?.provinces ?? [], [(addressData?.provinces ?? []).length])
  // eslint-disable-next-line
  const districts = useMemo(() => addressData?.districts ?? [], [(addressData?.districts ?? []).length])
  // eslint-disable-next-line
  const subDistricts = useMemo(() => addressData?.subDistricts ?? [], [(addressData?.subDistricts ?? []).length])

  const subDistrictOptions: SubDistrict[] = useMemo(() => {
    if (!postalCode) return [] as SubDistrict[]
    return subDistricts.filter((sd) => sd.postalCode.toString() === postalCode)
    // eslint-disable-next-line
  }, [postalCode])

  const selectedSubDistrict: SubDistrict | undefined = useMemo(() => {
    return subDistricts.find((sd) => sd.id === subDistrictId)
    // eslint-disable-next-line
  }, [subDistrictId])

  const districtOptions: District[] = useMemo(() => {
    if (!selectedSubDistrict?.districtId) return [] as District[]
    return districts.filter((d) => d.id === selectedSubDistrict.districtId)
    // eslint-disable-next-line
  }, [selectedSubDistrict?.districtId])

  const selectedDistrict: District | undefined = useMemo(() => {
    const district = districts.find((d) => d.id === selectedSubDistrict?.districtId)
    setValue(`${name}.districtId` as unknown as keyof VendorCreateFormValues, district?.id)
    return district
    // eslint-disable-next-line
  }, [selectedSubDistrict?.districtId, setValue, name])

  useEffect(() => {
    const provice = provinces.find((v) => v.id === selectedDistrict?.provinceId)
    setValue(`${name}.provinceId` as unknown as keyof VendorCreateFormValues, provice?.id)
    // eslint-disable-next-line
  }, [selectedDistrict?.provinceId, setValue, name])

  // state
  const [formType, setFormType] = useState<number>(CustomerFormType.Local)

  if (formType === CustomerFormType.OtherCountry) {
    return (
      <Box p={2} clone>
        <Paper variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>ข้อมูลที่อยู่</Title>
              <Space />
            </Grid>
            <Grid item xs={12} md={6}>
              <ButtonRadios
                label="ประเทศ *"
                defaultValue={CustomerFormType.Local}
                value={formType}
                onValueChange={(value) => setFormType(Number(value))}
                items={[
                  { id: 1, value: CustomerFormType.Local, name: 'ประเทศไทย' },
                  { id: 2, value: CustomerFormType.OtherCountry, name: 'ต่างประเทศ' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                key="country"
                control={control}
                name={`${name}.country`}
                label="ประเทศ *"
                rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                key="addressLine1"
                control={control}
                name={`${name}.addressLine1`}
                label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
                rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                key="phoneNumber"
                control={control}
                name={`${name}.phoneNumber`}
                label="เบอร์โทร *"
                rules={{ required: 'กรุณากรอกเบอร์โทร' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                key="faxNumber"
                control={control}
                name={`${name}.faxNumber`}
                label="เบอร์แฟกซ์ (ถ้ามี)"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )
  }

  return (
    <Box p={2} clone>
      <Paper variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>ข้อมูลที่อยู่</Title>
            <Space />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonRadios
              label="ประเทศ *"
              defaultValue={CustomerFormType.Local}
              value={formType}
              onValueChange={(value) => setFormType(Number(value))}
              items={[
                { id: 1, value: CustomerFormType.Local, name: 'ประเทศไทย' },
                { id: 2, value: CustomerFormType.OtherCountry, name: 'ต่างประเทศ' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name={`${name}.postalCode`} label="รหัสไปรษณีย์" />
          </Grid>

          {provinceId && (
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.provinceId`}
                label="จังหวัด"
                value={provinceId}
                disabled={true}
                select
              >
                {provinces.map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          )}
          {districtId && (
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.districtId`}
                label="เขต / อำเภอ"
                value={districtId}
                disabled={true}
                select
              >
                {districtOptions.map((d) => (
                  <MenuItem key={d.id} value={d.id}>
                    {d.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.subDistrictId`}
              label="แขวง / ตำบล"
              disabled={subDistrictOptions.length === 0}
              select
            >
              {subDistrictOptions.map((sd) => (
                <MenuItem key={sd.id} value={sd.id}>
                  {sd.nameLocal} ({districts.find((d) => d.id === sd.districtId)?.nameLocal ?? '-'})
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.addressLine1`}
              label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
              rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.phoneNumber`}
              label="เบอร์โทร *"
              rules={{ required: 'กรุณากรอกเบอร์โทร' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name={`${name}.faxNumber`} label="เบอร์แฟกซ์ (ถ้ามี)" />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default VendorAddressForm
