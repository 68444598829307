import { Grid, Paper, IconButton, Box } from '@material-ui/core'
import { Create, Delete } from '@material-ui/icons'
import React, { useState } from 'react'
import { ICustomerBillingAddress } from '../../../../../../api/smartbestbuys-api'
import { FlexBox, Title, TypographyWithLabel } from '../../../../../../custom-components'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { getAddressText } from '../../../../../../functions'
import { ConditionTooltip } from '../../../../../../shared-components'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import { CustomerBillingAddressUpdationDialog } from '../CustomerBillingAddressBox/CustomerBillingAddrssUpdationDialog'
import { CustomerShippingAddressUpdationDialog } from '../CustomerShippingAddressBox/CustomerShippingAddressUpdationDialog'
interface Props {
  index: number
  customerName?: string
  address: ICustomerBillingAddress
  type: 'shipping' | 'billing'
  isActiveCustomer: boolean
}

const CustomerAddressItem: React.FC<Props> = (props) => {
  const { index, address, type, customerName, isActiveCustomer } = props

  const { name, branchName, phoneNumber, faxNumber, overrideDocumentData } = address

  const [openEditDialog, setOpenEditDialog] = useState(false)

  // permission
  const updatePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Update)
  const deletePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Delete)

  const disableUpdate = !updatePermission.hasPermission || !isActiveCustomer
  const disableDelete = !deletePermission.hasPermission || !isActiveCustomer

  return (
    <Paper variant="outlined">
      {type === 'shipping' ? (
        <CustomerShippingAddressUpdationDialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          defaultAddress={address}
        />
      ) : (
        <CustomerBillingAddressUpdationDialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          defaultAddress={address}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Title>{name || `ที่อยู่ ${index}`}</Title>
        </Grid>
        <Grid item xs={12} md={6}>
          <FlexBox justifyContent="flex-end" gridGap={16}>
            <ConditionTooltip
              title={
                !updatePermission.hasPermission ? updatePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
              }
              showTooltip={disableUpdate}
            >
              <IconButton color="primary" size="small" onClick={() => setOpenEditDialog(true)} disabled={disableUpdate}>
                <Create />
              </IconButton>
            </ConditionTooltip>
            <ConditionTooltip
              title={
                !deletePermission.hasPermission ? deletePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
              }
              showTooltip={disableDelete}
            >
              <Box color="text.secondary">
                <IconButton color="inherit" size="small" disabled={disableDelete}>
                  <Delete />
                </IconButton>
              </Box>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <TypographyWithLabel label="รายละเอียดที่อยู่:">{getAddressText(address)}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={4}>
          <TypographyWithLabel label="สาขา:">{branchName || '-'}</TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={4}>
          <TypographyWithLabel label="เบอร์โทร:">{phoneNumber || '-'}</TypographyWithLabel>
        </Grid>
        {type === 'billing' && (
          <Grid item xs={12} md={4}>
            <TypographyWithLabel label="เบอร์แฟกซ์:">{faxNumber || '-'}</TypographyWithLabel>
          </Grid>
        )}
        {type === 'billing' && (
          <>
            <Grid item xs={12}>
              <Title>ข้อมูลที่เกี่ยวข้องบนเอกสาร</Title>
            </Grid>
            <Grid item xs={12} md={4}>
              <TypographyWithLabel label="ชื่อลูกค้าบนเอกสาร:">
                {overrideDocumentData?.customerName || customerName}
              </TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <TypographyWithLabel label="รายละเอียดที่อยู่บนเอกสาร:">
                {overrideDocumentData?.address || getAddressText(address)}
              </TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <TypographyWithLabel label="เลขที่ผู้เสียภาษีลูกค้าบนเอกสาร:">
                {overrideDocumentData?.taxNumber ?? '-'}
              </TypographyWithLabel>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

export default CustomerAddressItem
