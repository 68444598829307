import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Backdrop } from '@material-ui/core'
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(10),
    },
    tooltip: {
      width: 'max-content',
    },
  }),
)

interface SpeedDialActionItem {
  icon?: JSX.Element
  name: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  hidden?: boolean
}

interface Props {
  actions?: SpeedDialActionItem[]
}

const CustomSpeedDial: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const { actions = [] } = props

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validActions = actions.filter((it) => !it.hidden)

  return (
    <>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        hidden={validActions.length === 0}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {validActions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.onClick}
            classes={{
              staticTooltipLabel: classes.tooltip,
            }}
          />
        ))}
      </SpeedDial>
    </>
  )
}

export default CustomSpeedDial
