import { Box, Paper } from '@material-ui/core'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import { TransportTask } from '../../../api/smartbestbuys-api'
import { FilteredTable, Link, Topic, withLayout } from '../../../custom-components'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { PageName, TaskStatus, TaskType } from '../../../enums'
import { dateFormat, dateTimeFormat, getV3Url } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { useTransportTasks } from '../../../hooks/transport'
import { MessengerIcon, PrepareProductIcon, Space, TaskStatusChip } from '../../../shared-components'
import './TransportTaskListPage.less'

const TransportPickupTaskListPage = () => {
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.TRANSPORT_PICKUP_TASK)

  // state
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [tabValue, setTabValue] = useState<TaskStatus>(TaskStatus.TransportPending)

  const transportPickupTasks = useTransportTasks(startDate, endDate, TaskType.TransportPickupTask)
  const pendingAmount = useMemo(
    () => transportPickupTasks.filter((t) => t.status.id === TaskStatus.TransportPending).length,
    // eslint-disable-next-line
    [transportPickupTasks.map((t) => t.status.id).join(',')],
  )

  const pendingApprovalAmount = useMemo(
    () => transportPickupTasks.filter((t) => t.status.id === TaskStatus.PendingApproval).length,
    // eslint-disable-next-line
    [transportPickupTasks.map((t) => t.status.id).join(',')],
  )

  return (
    <Box p={2}>
      <Topic>รายการบริการงานรับของ</Topic>
      <Space />
      <Box p={2} clone>
        <Paper>
          <FilteredTable<TransportTask>
            color="primary.dark"
            columns={[
              {
                name: 'สถานะงานรับสินค้า',
                render: ({ status }) => <TaskStatusChip size="small" status={status.id} label={status.name} />,
                csvRender: ({ status }) => status.name,
              },
              {
                name: 'เลขที่งานรับสินค้า',
                render: ({ id, code }) =>
                  code.startsWith('TSx') ? (
                    <Link color="primary" to={`/transport-pickup-tasks/${id}/profile`}>
                      {code}
                    </Link>
                  ) : (
                    <Link color="textSecondary" target="_blank" href={getV3Url('/transport-tasks')}>
                      {code}
                    </Link>
                  ),
                csvRender: ({ code }) => code,
                sort: true,
              },
              {
                name: 'เลขที่คำร้องอ้างอิง',
                render: ({ customerRequisitionInfo }) =>
                  customerRequisitionInfo ? (
                    <Link color="secondary" to={`/customer-requisitions/${customerRequisitionInfo.id}/profile`}>
                      {customerRequisitionInfo.code}
                    </Link>
                  ) : (
                    '-'
                  ),
                csvRender: ({ customerRequisitionInfo }) => customerRequisitionInfo?.code,
                sort: true,
              },
              {
                name: 'วันที่ต้องไปรับ',
                dataIndex: 'dueDate',
                render: ({ dueDate }) => (dueDate ? dateFormat(dueDate) : 'ยังไม่ระบุ'),
                sort: true,
              },
              { name: 'ชื่อลูกค้า', render: ({ saleOrderInfo }) => saleOrderInfo?.customerName ?? '-' },
              {
                name: 'สถานะงานที่เกี่ยวข้อง',
                render: ({ relatedTasks }) => {
                  const pickupTask = relatedTasks?.find((t) => t.type.id === TaskType.MessengerPickupTask)
                  const warehouseReceiveProductTask = relatedTasks?.find(
                    (t) => t.type.id === TaskType.WarehouseReceiveProductTask,
                  )
                  return (
                    <div className="transport-task-icon-group">
                      <MessengerIcon status={pickupTask?.status.id} />
                      <PrepareProductIcon status={warehouseReceiveProductTask?.status.id} />
                    </div>
                  )
                },
                csvRender: ({ relatedTasks }) => {
                  const pickupTask = relatedTasks?.find((t) => t.type.id === TaskType.MessengerPickupTask)
                  const warehouseReceiveProductTask = relatedTasks?.find(
                    (t) => t.type.id === TaskType.WarehouseReceiveProductTask,
                  )
                  return `สถานะงานรับสินค้า: ${
                    pickupTask ? pickupTask?.status.name : 'ยังไม่ถูกสร้าง'
                  } / สถานะงานรับสินค้าคืนคลัง: ${
                    warehouseReceiveProductTask ? warehouseReceiveProductTask?.status.id : 'ยังไม่ถูกสร้าง'
                  }`
                },
              },
              {
                name: 'วันที่สร้าง',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                sort: true,
              },
            ]}
            sortable
            downloadAsCsv
            csvFilename="รายการงานรับของ"
            data={transportPickupTasks.filter((t) => t.status.id === tabValue)}
            defaultDateRage={{
              startDate: defaultStartDate,
              endDate: defaultEndDate,
            }}
            onDateChange={(startDate, endDate) => {
              setStartDate(startDate)
              setEndDate(endDate)
            }}
            cleanSpace
            tabOption={{
              tabs: [
                {
                  label: `รอยืนยันข้อมูลรับของ (${formatNumberInt(pendingAmount)})`,
                  value: TaskStatus.TransportPending,
                },
                { label: 'กำลังดำเนินการ', value: TaskStatus.Processing },
                { label: `รอจบงาน (${formatNumberInt(pendingApprovalAmount)})`, value: TaskStatus.PendingApproval },
                { label: 'จบงานแล้ว', value: TaskStatus.Done },
                { label: 'งานที่ยกเลิก', value: TaskStatus.Canceled },
              ],
              value: tabValue,
              onChange: (_, newValue) => setTabValue(newValue),
            }}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default withLayout(TransportPickupTaskListPage)
