import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import React from 'react'
import { RegisterOptions, useController } from 'react-hook-form'

type Props = Omit<KeyboardDatePickerProps, 'onChange'> & {
  control: any
  // TODO: refactor
  rules?: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  gutterTop?: boolean
}

const KeyboardDatePickerForm: React.FC<Props> = (props) => {
  const { control, name, defaultValue, rules, gutterTop, style, ...keyboardDateProps } = props
  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name: name as any,
    control,
    defaultValue: defaultValue,
    rules,
  })

  return (
    <KeyboardDatePicker
      style={{ marginTop: gutterTop ? 8 : undefined, ...style }}
      inputVariant="outlined"
      format="dd/MM/yyyy"
      invalidDateMessage="วันที่ไม่ถูกต้อง"
      {...keyboardDateProps}
      {...inputProps}
      inputRef={ref}
    />
  )
}

export default KeyboardDatePickerForm
