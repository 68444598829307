import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { getPaymentRequisitionPayoutDaily, IPaymentRequisitionPayoutDaily } from '../../api/smartbestbuys-api'

export const usePaymentRequisitionsPayoutDailyPayment = (
  paymentDate: DateTime,
): IPaymentRequisitionPayoutDaily | undefined => {
  const [data, setData] = useState<IPaymentRequisitionPayoutDaily | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getPaymentRequisitionPayoutDaily({ paymentDate })
      setData(response?.data)
    })()
    // eslint-disable-next-line
  }, [paymentDate.toFormat('yyyy-MM-dd')])

  return data
}
