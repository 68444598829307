import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Paper, Grid, Box, FormControlLabel, InputAdornment, MenuItem, Typography } from '@material-ui/core'
import { Title, CheckboxForm, TextFieldForm } from '../../../../custom-components'

import CustomerCreationFormValue from '../CustomerCreationFormValues'
import { ButtonRadios, ImageIcon } from '../../../../shared-components'
import dealerLogo from '../../../../assets/images/dealer-logo.png'
import { customerBusinessTypes } from '../../../../constants'

// import { DragAndDropPhoto } from './DragAndDropPhoto'

const CustomerCreationInput: React.FC = () => {
  const customerFormContext = useFormContext<CustomerCreationFormValue>()

  const {
    control,
    formState: { errors },
    setValue,
  } = customerFormContext

  const [isCredit, setIsCredit] = useState(false)

  const hasฺBฺusinessType = customerBusinessTypes && customerBusinessTypes?.length > 0

  const handleCheckCredit = () => {
    setIsCredit((prevState) => {
      const next = !prevState
      if (next) {
        setValue('creditPeriodDay', '30')
      } else {
        setValue('creditPeriodDay', undefined)
      }
      return next
    })
  }

  return (
    <Box p={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>ข้อมูลลูกค้า</Title>
          </Grid>
          {/* <Grid item xs={12}>
            <DragAndDropPhoto context={customerFormContext} />
          </Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="thaiName"
                  rules={{ required: 'กรุณาใส่ชื่อภาษาไทย' }}
                  fullWidth
                  label="ชื่อภาษาไทย *"
                  variant="outlined"
                  size="small"
                  error={!!errors.thaiName}
                  helperText={errors.thaiName?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="englishName"
                  fullWidth
                  label="ชื่อภาษาอังกฤษ (ถ้ามี)"
                  variant="outlined"
                  size="small"
                  error={!!errors.englishName}
                  helperText={errors.englishName?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ButtonRadios
                  label="ประเภทลูกค้า *"
                  items={[
                    { id: 1, value: 1, name: 'นิติบุคคล' },
                    { id: 2, value: 2, name: 'บุคคลทั่วไป' },
                  ]}
                  onValueChange={(value) => setValue('customerTypeId', Number(value))}
                  errorMessage={errors.customerTypeId?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mt={1}>
                  <TextFieldForm
                    control={control}
                    name="typeOfBusiness"
                    fullWidth
                    select
                    label="ประเภทธุรกิจ *"
                    rules={{ required: 'กรุณาเลือกประเภทธุรกิจ' }}
                    variant="outlined"
                    size="small"
                    error={!!errors.typeOfBusiness}
                    helperText={errors.typeOfBusiness?.message}
                  >
                    <MenuItem key={'default-menu-item-type-business'} value={undefined}>
                      <Typography color="textSecondary">ไม่เลือก</Typography>
                    </MenuItem>
                    {hasฺBฺusinessType &&
                      customerBusinessTypes?.map(({ id, name, detail }) => (
                        <MenuItem key={id} value={id}>
                          <div>
                            <Typography display="block">{name}</Typography>
                            <Typography className="italic" color="textSecondary">
                              {detail}
                            </Typography>
                          </div>
                        </MenuItem>
                      ))}
                  </TextFieldForm>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="numberOfTaxpayer"
                  fullWidth
                  label="เลขที่ผู้เสียภาษี (ถ้ามี)"
                  variant="outlined"
                  size="small"
                  error={!!errors.numberOfTaxpayer}
                  helperText={errors.numberOfTaxpayer?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="phoneNumber"
                  rules={{ required: 'กรุณากรอกเบอร์โทรศัพท์' }}
                  fullWidth
                  label="เบอร์โทรศัพท์ *"
                  variant="outlined"
                  size="small"
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="emailAddress"
                  fullWidth
                  label="อีเมล (ถ้ามี)"
                  variant="outlined"
                  size="small"
                  error={!!errors.emailAddress}
                  helperText={errors.emailAddress?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="websiteAddress"
                  fullWidth
                  label="เว็บไซต์ (ถ้ามี)"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <CheckboxForm
                        size="small"
                        checked={isCredit}
                        control={null}
                        onClick={handleCheckCredit}
                        name="hasCreditCustomer"
                      />
                    }
                    label={!isCredit && <Typography variant="body2">เป็นลูกค้าเครดิต</Typography>}
                  />
                  {isCredit && (
                    <TextFieldForm
                      control={control}
                      disabled={!isCredit}
                      name="creditPeriodDay"
                      type="number"
                      label="จำนวนเครดิต"
                      variant="outlined"
                      size="small"
                      rules={{
                        required: { value: true, message: 'กรุณาใส่จำนวนเครดิต' },
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">วัน</InputAdornment>,
                      }}
                      error={!!errors.creditPeriodDay}
                      helperText={errors.creditPeriodDay?.message}
                      style={{ width: 100 }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={<CheckboxForm size="small" control={null} name="isDealer" />}
                    label={<Typography variant="body2">เป็นดีลเลอร์</Typography>}
                  />
                  <ImageIcon name="dealer" src={dealerLogo} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="shippingDocumentRequireList"
                  fullWidth
                  label="เอกสารที่ใช้ตอนส่งสินค้า (ถ้ามี)"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="billingTermAndCondition"
                  fullWidth
                  label="ระเบียบการวางบิล (ถ้ามี)"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldForm
                  control={control}
                  name="paymentTermAndCondition"
                  fullWidth
                  label="ระเบียบการรับเงิน (ถ้ามี)"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default CustomerCreationInput
