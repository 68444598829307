import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler, errorSingleHandler } from '../../middleware'
import { ReceiveProductRequestBody, UpdatedTaskStatus, WarehouseTask } from '..'
import { WarehouseTaskType } from '../../../enums'
import {
  FetchTaskRequest,
  GetWarehouseTaskTableDataQueryParams,
  IWarehouseTaskTableData,
  NewWarehouseTask,
  SingleWarehouseTask,
} from './warehouse-types'
import { dateSqlFormat } from '../../../functions'

export interface GiveProductToCustomerRequest {
  typeId: WarehouseTask
}

export const giveProductToCustomer = errorSingleHandler<UpdatedTaskStatus>(async (id: number) => {
  const path = `/warehouse/tasks/${id}/give-product-to-customer`
  return smartAuthAPI.patch(path, {
    typeId: WarehouseTaskType.PendingCustomerPickup,
  })
})

export const getWarehouseTask = errorSingleHandler<SingleWarehouseTask>(async (id: number) => {
  return smartAuthAPI.get(`/warehouse/v1/tasks/${id}`, {
    params: {
      typeId: WarehouseTaskType.ReceiveProductFromVendor,
    },
  })
})

export const getWarehouseTaskV2API = errorSingleHandler<NewWarehouseTask>(async (id: number, typeId?: number) => {
  return smartAuthAPI.get(`/warehouse/v2/tasks/${id}`, {
    params: {
      typeId: typeId ?? WarehouseTaskType.ReceiveProductFromVendor,
    },
  })
})

export const receiveProductAPI = errorSingleHandler<SingleWarehouseTask>(
  async (id: number, requestBody: ReceiveProductRequestBody) => {
    return smartAuthAPI.post(`/warehouse/v1/tasks/${id}/receive-product`, requestBody)
  },
)

export const fetchWarehouseTasksV2 = errorHandler<NewWarehouseTask[]>(async (request: FetchTaskRequest) => {
  return smartAuthAPI.get('/warehouse/v2/tasks', {
    params: {
      typeId: request.typeId,
      startDate: dateSqlFormat(request.startDate),
      endDate: dateSqlFormat(request.endDate),
    },
  })
})

export const getWarehouseTaskTableDataAPI = errorHandler<IWarehouseTaskTableData>(
  async (queryParams: GetWarehouseTaskTableDataQueryParams) => {
    return smartAuthAPI.get('warehouse/v1/table-data', {
      params: queryParams,
    })
  },
)
