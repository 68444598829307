import { makeStyles, createStyles } from '@material-ui/core/styles'
import { NivoColorPalette } from '../palette'

export const useStyles = (color?: NivoColorPalette) =>
  makeStyles((theme) =>
    createStyles({
      card: {
        height: '100%',
        padding: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
        },
      },
      head: {
        backgroundColor: color || NivoColorPalette.default,
        color: theme.palette.text.primary,

        '&:first-child': {
          borderTopLeftRadius: theme.shape.borderRadius,
        },
        '&:last-child': {
          borderTopRightRadius: theme.shape.borderRadius,
        },
      },
    }),
  )
