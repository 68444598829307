import { useEffect, useState } from 'react'
import { getCustomerRequisitionCreateDataAPI, IGetCustomerRequisitionFormData } from '../api/smartbestbuys-api'

export const useCustomerRequisitionCreateData = (saleOrderId: number): IGetCustomerRequisitionFormData | undefined => {
  const [createData, setCreateData] = useState<IGetCustomerRequisitionFormData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getCustomerRequisitionCreateDataAPI(saleOrderId)
      const data = response?.data ?? ({} as IGetCustomerRequisitionFormData)

      setCreateData(data)
    })()
  }, [saleOrderId])

  return createData
}
