import { Grid } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { Form, Topic, withLayout } from '../../../custom-components'
import { usePurchaseOrderUpdateData } from '../../../hooks'
import { Loading, SubmitForm } from '../../../shared-components'
import { SourceFile } from '../../../types'
import { AttachmentSelectionBox } from '../../purchase-requisitions/create/AttachmentSelectionBox'
import PurchaseOrderCreateFormValues from '../create/PurchaseOrderCreateFormValues'
import { PurchaseOrderCreatePageContext } from '../create/PurchaseOrderCreatePage'
import PurchaseOrderDiscountBox from '../create/PurchaseOrderDiscountBox'
import PurchaseRequisitionInfoBox from '../create/PurchaseRequisitionInfoBox/PurchaseRequisitionInfoBox'
import { PurchaseOrderProductSelector } from '../create/PurchaseOrderProductSelector'
import PurchaseOrderTotalBox from '../create/PurchaseOrderTotalBox'
import PurchaseOrderVatBox from '../create/PurchaseOrderVatBox'
import PurchaseOrderVendorBox from '../create/PurchaseOrderVendor'
import PurchaseOrderWithHoldingTaxBox from '../create/PurchaseOrderWithHoldingTaxBox'
import SelectedPurchaseOrderProductTable from '../create/SelectedPurchaseOrderProductTable'
import { mapPurchaseOrderDataToFormValues } from './mapper'
import POConfirmUpdateDialog from './POConfirmUpdateDialog'
import PurchaseOrderUpdateFormBox from './PurchaseOrderUpdateFormBox'

interface Props {
  id: number
}

const PurchaseOrderUpdatePage: React.FC<Props> = (props: Props) => {
  // props
  const { id } = props

  // state
  const [confirmOpen, setConfirmOpen] = useState(false)

  // context
  const { triggerSnackbar } = useContext(RootContext)
  const history = useHistory()

  const updatePurchaseOrderData = usePurchaseOrderUpdateData(id)

  // hook form
  const formMethod = useForm<PurchaseOrderCreateFormValues>({
    defaultValues: {
      products: [],
    },
  })
  const {
    watch,
    setValue,
    setError,
    reset,
    formState: { isSubmitting },
  } = formMethod

  // data
  const { attachments, products } = watch()

  // handlers
  const handleAddAttachmentsToForm = (attachments: SourceFile[]) => {
    setValue('attachments', attachments)
  }

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const handleSubmit = () => {
    if (!validate()) {
      setConfirmOpen(true)
    }
  }

  const handleInvalid = () => {
    validate()
  }
  const validate = (): boolean => {
    let error = false

    if (products.length === 0) {
      setError('products', {
        type: 'required',
        message: 'กรุณาเลือกสินค้า/บริการ',
      })
      error = true
      scrollTo('#selected-purchase-order-product-table')
    }

    return error
  }

  useEffect(() => {
    if (!!updatePurchaseOrderData) {
      reset(mapPurchaseOrderDataToFormValues(updatePurchaseOrderData))
    }
  }, [reset, updatePurchaseOrderData])

  if (!updatePurchaseOrderData) return <Loading />

  return (
    <PurchaseOrderCreatePageContext.Provider
      value={{
        purchaseOrderCreate: { ...updatePurchaseOrderData },
      }}
    >
      <FormProvider {...formMethod}>
        <POConfirmUpdateDialog
          purchaseOrderId={id}
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onComplete={() => {
            setConfirmOpen(false)
            triggerSnackbar(`บันทึกใบสั่งซื้อสำเร็จ`)
            history.replace(`/purchase-orders/${id}/profile`)
          }}
          onFail={() => {
            setConfirmOpen(false)
            triggerSnackbar(`บันทึกใบสั่งซื้อไม่สำเร็จ`)
          }}
        />
        <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Topic>แก้ไขใบสั่งซื้อ</Topic>
            </Grid>
            <Grid item xs={12}>
              <PurchaseRequisitionInfoBox />
            </Grid>
            <Grid item xs={12} id="purchase-order-vendor">
              <PurchaseOrderVendorBox readonly />
            </Grid>
            <Grid item xs={12}>
              <PurchaseOrderProductSelector />
            </Grid>
            <Grid item xs={12} id="selected-purchase-order-product-table">
              <SelectedPurchaseOrderProductTable />
            </Grid>
            <Grid item md={6} xs={12}>
              <PurchaseOrderDiscountBox defaultValue={updatePurchaseOrderData.purchaseOrderInfo.discountAmount} />
            </Grid>
            <Grid item md={6} xs={12}>
              <PurchaseOrderWithHoldingTaxBox
                defaultValue={updatePurchaseOrderData.purchaseOrderInfo.withholdingTaxPercentage}
              />
            </Grid>
            <Grid item xs={12}>
              <PurchaseOrderVatBox />
            </Grid>
            <Grid item xs={12}>
              <PurchaseOrderTotalBox />
            </Grid>
            <Grid item xs={12}>
              <PurchaseOrderUpdateFormBox />
            </Grid>
            <Grid item xs={12}>
              <AttachmentSelectionBox onChange={handleAddAttachmentsToForm} defaultValue={attachments} />
            </Grid>
            <Grid item xs={12}>
              <SubmitForm
                goBack
                submitText={'บันทึกใบสั่งซื้อ'}
                cancelText="ยกเลิก"
                redirectPath={`/purchase-orders/${id}/profile`}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </PurchaseOrderCreatePageContext.Provider>
  )
}

export default withLayout(PurchaseOrderUpdatePage)
