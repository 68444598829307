import { Grid, Paper } from '@material-ui/core'
import { DateTime } from 'luxon'
import { useContext, useMemo, useState } from 'react'
import { WarehouseReceiveProductBackTaskDialog } from '..'
import { RootContext } from '../../..'
import { NewWarehouseTask } from '../../../api/smartbestbuys-api'
import { FilteredTable, Topic, withLayout } from '../../../custom-components'
import { CustomerRequisitionType, PageName, TaskStatus, TaskType } from '../../../enums'
import { dateFormat } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { useWarehouseTasksV2 } from '../../../hooks'
import { TaskStatusChip, TypographyLink } from '../../../shared-components'
import AlreadyReceivedProductBackDialog from './AlreadyReceivedProductBackDialog'

const WarehouseReceiveProductBackTasksListPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.WAREHOUSE_RECEIVE_PRODUCT_BACK_TASK)

  const [selected, setSelected] = useState<{ id: number; typeId?: number } | undefined>(undefined)
  const [tabValue, setTabValue] = useState<TaskStatus>(TaskStatus.Pending)

  const warehouseTasks = useWarehouseTasksV2(
    new Date(DateTime.local().minus({ days: 365 }).toFormat('yyyy-MM-dd')),
    new Date(DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd')),
  )

  const warehouseReceiveProductBackTasks = warehouseTasks.filter(
    (t) =>
      !!t.type &&
      [
        TaskType.WaitingCustomerShipProduct,
        TaskType.WaitingCustomerReturnProduct,
        TaskType.WarehouseReceiveProductTask,
      ].includes(t.type.id),
  )

  const pendingAmount = useMemo(
    () => warehouseReceiveProductBackTasks.filter((t) => t.status.id === TaskStatus.Pending).length,
    // eslint-disable-next-line
    [warehouseReceiveProductBackTasks.map((t) => t.status.id).join(',')],
  )

  const rejectedAmount = useMemo(
    () => warehouseReceiveProductBackTasks.filter((t) => t.status.id === TaskStatus.PreCancel).length,
    // eslint-disable-next-line
    [warehouseReceiveProductBackTasks.map((t) => t.status.id).join(',')],
  )

  const taskTypeFilter = [
    { name: 'ลูกค้าส่งผ่านขนส่ง', value: TaskType.WaitingCustomerShipProduct },
    { name: 'ลูกค้ามาส่งที่หน้าร้าน', value: TaskType.WaitingCustomerReturnProduct },
    { name: 'SMART ไปรับ', value: TaskType.WarehouseReceiveProductTask },
  ]

  const customerRequisitionTypeFilter = [
    { name: 'เคลม', value: CustomerRequisitionType.Claim },
    { name: 'คืน', value: CustomerRequisitionType.CancelSO },
  ]

  const renderDialog = () => {
    if (selected === undefined) return <></>
    if (tabValue === TaskStatus.Pending) {
      return (
        <WarehouseReceiveProductBackTaskDialog
          open={!!selected}
          warehouseTask={selected!}
          onClose={() => {
            setSelected(undefined)
          }}
        />
      )
    }

    return (
      <AlreadyReceivedProductBackDialog
        open={!!selected}
        receiveProductTaskId={selected?.id!}
        receiveProductTaskTypeId={selected?.typeId!}
        onClose={() => {
          setSelected(undefined)
        }}
      />
    )
  }

  return (
    <>
      {renderDialog()}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>รายการงานรับสินค้าเคลม/คืน</Topic>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<NewWarehouseTask>
              color="primary.dark"
              columns={[
                {
                  name: 'สถานะงาน',
                  dataIndex: 'status',
                  fixedWidth: 100,
                  render: ({ status }) => <TaskStatusChip status={status.id} label={status.name} />,
                },
                {
                  name: 'รูปแบบการรับ',
                  dataIndex: 'type',
                  fixedWidth: 150,
                  render: ({ type }) => type?.name,
                },
                {
                  name: 'ประเภทคำร้อง',
                  fixedWidth: 20,
                  render: ({ customerRequisitionInfo }) => customerRequisitionInfo?.type.name,
                },
                {
                  name: 'เลขที่งานคลัง',
                  dataIndex: 'code',
                  fixedWidth: 100,
                  sort: true,
                  render: ({ id, code, type }) => (
                    <TypographyLink
                      variant="body2"
                      color="primary.dark"
                      onClick={() => setSelected({ id, typeId: type?.id })}
                    >
                      {code}
                    </TypographyLink>
                  ),
                },
                {
                  name: 'เลขที่คำร้องอ้างอิง',
                  dataIndex: 'customerRequisitionInfo',
                  fixedWidth: 170,
                  sort: true,
                  render: ({ customerRequisitionInfo }) => (
                    <TypographyLink
                      color="#C79400"
                      variant="body2"
                      href={`/customer-requisitions/${customerRequisitionInfo?.id}/profile`}
                      target="_blank"
                    >
                      {customerRequisitionInfo?.code}
                    </TypographyLink>
                  ),
                },
                {
                  name: 'ชื่อลูกค้า',
                  render: ({ customerRequisitionInfo }) => customerRequisitionInfo?.customer.name,
                },
                {
                  name: 'วันที่สร้าง',
                  dataIndex: 'recCreatedAt',
                  fixedWidth: 150,
                  sort: true,
                  render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                },
              ]}
              sortable
              data={warehouseReceiveProductBackTasks
                .filter((t) => t.status.id === tabValue)
                .sort((a, b) => b.id - a.id)}
              disableDateRangeFilter
              cleanSpace
              filters={[
                {
                  name: 'รูปแบบการรับ',
                  values: taskTypeFilter,
                  filter: (data: NewWarehouseTask, values: (string | number)[]) =>
                    !!data.type && values.includes(data.type.id),
                },
                {
                  name: 'ประเภทคำร้อง',
                  values: customerRequisitionTypeFilter,
                  filter: (data: NewWarehouseTask, values: (string | number)[]) =>
                    !!data.customerRequisitionInfo && values.includes(data.customerRequisitionInfo.type.id),
                },
              ]}
              tabOption={{
                tabs: [
                  {
                    label: `งานที่ต้องรับ (${formatNumberInt(pendingAmount)})`,
                    value: TaskStatus.Pending,
                  },
                  { label: 'รับสินค้าเสร็จสิ้น', value: TaskStatus.Done },
                  {
                    label: `งานที่ถูกยกเลิก (${formatNumberInt(rejectedAmount)})`,
                    value: TaskStatus.PreCancel,
                  },
                  { label: 'งานที่ยกเลิก', value: TaskStatus.Canceled },
                ],
                value: tabValue,
                onChange: (_, newValue) => setTabValue(newValue),
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default withLayout(WarehouseReceiveProductBackTasksListPage)
