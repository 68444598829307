import RefundRequisitionForm, { IRefundRequisitionFormValues } from './form/RefundRequisitionForm'
import { withLayout } from '../../custom-components'
import { useRefundRequisitionsUpdateData } from '../../hooks'
import { createContext, useContext } from 'react'
import {
  IRefundRequisitionFormData,
  updateRefundRequisition,
  UpdateRefundRequisitionRequestBody,
} from '../../api/smartbestbuys-api'
// import { useHistory } from 'react-router-dom'
import { RootContext } from '../..'
import { Loading } from '../../shared-components'
import { useHistory } from 'react-router-dom'

interface IRefundRequisitionUpdatePageContext {
  formData: IRefundRequisitionFormData | undefined
}

export const RefundRequisitionUpdatePageContext = createContext<IRefundRequisitionUpdatePageContext>({
  formData: undefined,
})

interface Props {
  id: number
}

const RefundRequisitionUpdatePage = (props: Props) => {
  // todo: set current page
  const { id } = props

  const { triggerSnackbar } = useContext(RootContext)
  const history = useHistory()

  const updateData = useRefundRequisitionsUpdateData(id)

  if (!updateData) return <Loading />

  const { refundRequisition, ...formData } = updateData

  const handleSubmit = async (values: IRefundRequisitionFormValues) => {
    const request: UpdateRefundRequisitionRequestBody = {
      customerBankAccountId: values.customerBankAccountId,
      customerBankAccount: !values.customerBankAccountId
        ? {
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            branchName: values.branchName,
            bookBankImageBase64: values.bookBankImageBase64,
            bankId: values.bankId,
          }
        : undefined,
      total: values.debtSaleOrders.reduce((sum, dso) => sum + dso.total, 0),
      remark: values.remark,
      saleOrders: values.debtSaleOrders.map((dso) => ({
        id: dso.id,
        total: dso.total,
      })),
      attachments: values.attachments.map((att) => ({
        url: att.url,
        base64: att.base64,
      })),
    }

    const response = await updateRefundRequisition(id, request)

    if (response?.status === 200) {
      triggerSnackbar('แก้ไขการทำเบิกคืนเงินสำเร็จ')
      history.replace(`/finance/refund-requisitions/${id}/profile`)
    } else {
      triggerSnackbar('แก้ไขการทำเบิกคืนเงินไม่สำเร็จ')
    }
    return true
  }

  return <RefundRequisitionForm onSubmit={handleSubmit} formData={formData} refundRequisition={refundRequisition} />
}

export default withLayout(RefundRequisitionUpdatePage)

export const useRefundRequisitionUpdatePageContext = () => {
  return useContext(RefundRequisitionUpdatePageContext)
}
