import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { ICustomerProfile_TransportTask } from '../../../../../../../api/smartbestbuys-api'
import { FlexBox, TypographyWithLabel } from '../../../../../../../custom-components'
import { dateFormat } from '../../../../../../../functions'
import { TaskStatusChip } from '../../../../../../../shared-components'
interface Props {
  transportTask: ICustomerProfile_TransportTask
}

const CustomerTransportationCard: React.FC<Props> = (props) => {
  const { transportTask } = props

  const { code, status, dueDate, subTasks } = transportTask

  return (
    <>
      <Paper variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={12} container spacing={2}>
            <Grid item md={10}>
              <FlexBox gridGap={8} alignItems="center" justifyContent="flex-start">
                <Typography variant="subtitle2">{code}</Typography>
                <TaskStatusChip label={status.name} status={status.id} />
              </FlexBox>
            </Grid>
            <Grid item md={2}>
              {dueDate ? (
                <TypographyWithLabel label="วันที่ขนส่ง:" variant="body2">
                  {dateFormat(dueDate.toString())}
                </TypographyWithLabel>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {subTasks.map((subTask) => (
            <Grid item xs={4} key={subTask.id}>
              <FlexBox gridGap={8} alignItems="center" justifyContent="flex-start">
                <Typography variant="body2">{subTask.type.name}:</Typography>
                <TaskStatusChip label={subTask.status.name} status={subTask.status.id} />
              </FlexBox>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerTransportationCard
