import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Close, Photo } from '@material-ui/icons'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { RootContext } from '../../..'
import {
  BaseData,
  finishReceiveCRProductTaskAPI,
  finishTransportSubTaskAPI,
  NewWarehouseTask_CustomerRequisitionProduct,
  ReceiveCRProductRequestBody,
} from '../../../api/smartbestbuys-api'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  SubTopic,
  Table,
  TypographyWithLabel,
  Avatar,
} from '../../../custom-components'
import { PageName, ReceiveProductAction, TaskType } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { formatNumberInt } from '../../../functions/number-utils'
import { useWarehouseTaskV2 } from '../../../hooks'
import { CustomerProfileCard, Loading, SubmitWithConfirmTextForm, TaskStatusChip } from '../../../shared-components'

interface Props extends DialogProps {
  warehouseTask: { id: number; typeId?: number }
}

interface ConfirmReceiveProductBackTaskFormValues {
  receiveProductDetails: ReceiveProductDetail[]
}

interface ReceiveProductDetail extends NewWarehouseTask_CustomerRequisitionProduct {
  receiveAction: ReceiveProductAction
  toReceiveAmount: number
  expectedReceivedAmount: number
}

const WarehouseReceiveProductBackTaskDialog: React.FC<Props> = (props) => {
  const { warehouseTask, onClose, ...otherProps } = props
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.WAREHOUSE_RECEIVE_PRODUCT_BACK_TASK)

  const warehouseTaskInfo = useWarehouseTaskV2(warehouseTask.id, warehouseTask.typeId)

  const formMethods = useForm<ConfirmReceiveProductBackTaskFormValues>()
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = formMethods

  useEffect(() => {
    if (!!warehouseTaskInfo) {
      // treat init data for warehouse task type: 4005, 4006
      const initReceiveProductDetails =
        warehouseTaskInfo.customerRequisitionInfo?.products?.map((product) => {
          return {
            ...product,
            expectedReceivedAmount: Math.max(product.amount - product.receivedAmount, 0),
          } as ReceiveProductDetail
        }) ?? []
      reset({ receiveProductDetails: initReceiveProductDetails })

      // treat init data for warehouse task type: 4007
      if (warehouseTaskInfo.type.id === TaskType.WarehouseReceiveProductTask) {
        const initReceiveProductDetails =
          warehouseTaskInfo.customerRequisitionInfo?.products?.map((product) => {
            return {
              ...product,
              receiveAction: ReceiveProductAction.All,
              toReceiveAmount: product.amount,
              expectedReceivedAmount: Math.max(product.amount - product.receivedAmount, 0),
            } as ReceiveProductDetail
          }) ?? []
        reset({ receiveProductDetails: initReceiveProductDetails })
      }
    }
  }, [warehouseTaskInfo, reset])

  if (!warehouseTaskInfo)
    return (
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Loading />
      </Dialog>
    )

  const { id, code, status, type, customerRequisitionInfo, customerInfo, parentTaskInfo } = warehouseTaskInfo

  if (!customerRequisitionInfo || !customerInfo)
    return (
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Loading />
      </Dialog>
    )

  const handleSubmitForm = async (values: ConfirmReceiveProductBackTaskFormValues) => {
    const request: ReceiveCRProductRequestBody = {
      products: values.receiveProductDetails.map((p) => {
        const alreadyReceived = p.expectedReceivedAmount === 0
        return {
          id: p.productId,
          action: alreadyReceived ? ReceiveProductAction.AlreadyReceived : p.receiveAction,
          receivedAmount: alreadyReceived ? 0 : p.toReceiveAmount,
        }
      }),
    }

    const response =
      type.id === TaskType.WarehouseReceiveProductTask
        ? await finishTransportSubTaskAPI({ taskId: parentTaskInfo?.id!, subTaskId: id })
        : await finishReceiveCRProductTaskAPI({ id: customerRequisitionInfo.id, taskId: id }, request)
    if (response?.status === 200) {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('ยืนยันรับสินค้าสำเร็จ')
      window.location.reload()
    } else {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('ยืนยันรับสินค้าไม่สำเร็จ')
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Dialog fullWidth maxWidth="lg" {...otherProps}>
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
          <DialogTitle>
            <FlexBox justifyContent="space-between" alignItems="cneter">
              <FlexBox gridGap={16} alignItems="center">
                <SubTopic>{code}</SubTopic>
                <Typography variant="body2" color="textSecondary">
                  งานรับสินค้าเคลม/คืน
                </Typography>
                <TaskStatusChip status={status.id} label={status.name} />
              </FlexBox>
              <IconButton onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
                <Close fontSize="small" />
              </IconButton>
            </FlexBox>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomerProfileCard customerProfile={customerInfo} />
              </Grid>
              <Grid item xs={12}>
                <FlexBox justifyContent="space-between">
                  <Grid item xs={6}>
                    <TypographyWithLabel label="รูปแบบการรับ:">{type?.name}</TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="ประเภทการรับ:">
                      {customerRequisitionInfo?.type.name}
                    </TypographyWithLabel>
                  </Grid>
                </FlexBox>
              </Grid>
              <Grid item xs={12}>
                <TypographyWithLabel label="เลขคำร้องอ้างอิง:">{customerRequisitionInfo?.code}</TypographyWithLabel>
              </Grid>
              <Grid item xs={12}>
                <ReceiveProductTable taskType={warehouseTaskInfo.type} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitWithConfirmTextForm
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
              confirmTextOption={{
                veifiedText: 'ยืนยัน',
              }}
              submitPermission={PermissionEnum.Warehouse_Task_Write}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  )
}

export default WarehouseReceiveProductBackTaskDialog

const grayscaleStyle = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
}

interface TableProps {
  taskType: BaseData
}

const ReceiveProductTable: React.FC<TableProps> = ({ taskType }) => {
  const { control, setValue, watch } = useFormContext<ConfirmReceiveProductBackTaskFormValues>()
  const { receiveProductDetails } = watch()

  const handleReceiveActionChange = (value: ReceiveProductAction, index: number) => {
    const target = receiveProductDetails[index]
    target.receiveAction = value
    if (value === ReceiveProductAction.All) target.toReceiveAmount = target.expectedReceivedAmount
    if (value === ReceiveProductAction.Nothing) target.toReceiveAmount = 0
    setValue('receiveProductDetails', [
      ...receiveProductDetails.slice(0, index),
      target,
      ...receiveProductDetails.slice(index + 1),
    ])
  }

  return (
    <>
      <Typography variant="body2">รายละเอียดการรับสินค้า</Typography>
      <Table<ReceiveProductDetail>
        color="primary"
        size="small"
        columns={[
          {
            name: 'ลำดับ',
            numeric: true,
            format: '0,0',
            fixedWidth: 50,
            render: (_, index) => index + 1,
          },
          {
            name: 'ชื่อสินค้า',
            render: ({ imageUrl, nameLocal, code }) => (
              <FlexBox alignItems="flex-start">
                <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                  <Photo />
                </Avatar>
                <FlexBox flexDirection="column" ml={1} className="receive-product-header">
                  <FlexBox gridGap={8}>
                    <Typography variant="body2" className="receive-product-header__code">
                      {code}
                    </Typography>
                  </FlexBox>
                  <Typography variant="body2" className="receive-product-header__name">
                    {nameLocal}
                  </Typography>
                </FlexBox>
              </FlexBox>
            ),
          },
          {
            name: 'จำนวนที่เคยรับแล้ว/จำนวนตามคำร้อง',
            fixedWidth: 170,
            align: 'right',
            render: (product) => (
              <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>
                {formatNumberInt(product.receivedAmount)}/{formatNumberInt(product.amount)}
              </Typography>
            ),
          },
          {
            name: 'ผลการรับ',
            fixedWidth: 190,
            render: (product, index) => {
              if (product.expectedReceivedAmount === 0)
                return (
                  <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>-</Typography>
                )
              const isFirstTime = product.receivedAmount === 0
              return (
                <GeneralTextFieldForm
                  disabled={taskType.id === TaskType.WarehouseReceiveProductTask}
                  control={control}
                  name={`receiveProductDetails.${index}.receiveAction`}
                  select
                  rules={{ required: 'กรุณาเลือกผลการรับ' }}
                  onChange={(e) => !!e.target.value && handleReceiveActionChange(Number(e.target.value), index)}
                >
                  <MenuItem value={ReceiveProductAction.All}>{isFirstTime ? 'รับครบ' : 'รับที่เหลือ'}</MenuItem>
                  <MenuItem value={ReceiveProductAction.Partial}>รับขาด</MenuItem>
                  <MenuItem value={ReceiveProductAction.Nothing}>ไม่มีให้รับ</MenuItem>
                </GeneralTextFieldForm>
              )
            },
          },
          {
            name: 'จำนวนที่รับ',
            fixedWidth: 170,
            align: 'right',
            render: (product, index) => {
              if (taskType.id === TaskType.WarehouseReceiveProductTask)
                return (
                  <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>
                    {product.amount}
                  </Typography>
                )

              if (product.expectedReceivedAmount === 0)
                return (
                  <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>-</Typography>
                )
              if (product.receiveAction === ReceiveProductAction.All)
                return <Typography>{product.expectedReceivedAmount}</Typography>
              if (
                product.receiveAction &&
                [ReceiveProductAction.Partial, ReceiveProductAction.RejectRemaining].includes(product.receiveAction)
              ) {
                return (
                  <GeneralTextFieldForm
                    control={control}
                    name={`receiveProductDetails.${index}.toReceiveAmount`}
                    type="number"
                    rules={{
                      required: 'กรุณากรอกจำนวน',
                      min: { value: 1, message: 'รับอย่างน้อย 1 หน่วย' },
                      max: {
                        value: product.expectedReceivedAmount,
                        message: 'กรุณาอย่าใส่จำนวนเกินจำนวนที่ต้องรับ',
                      },
                    }}
                  />
                )
              }

              return '-'
            },
          },
          {
            name: 'จำนวนรับจริง/จำนวนที่ต้องรับ',
            align: 'right',
            fixedWidth: 140,
            render: (product) => {
              if (product.expectedReceivedAmount === 0)
                return (
                  <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>
                    รับครบไปแล้ว
                  </Typography>
                )
              if (product.toReceiveAmount === undefined)
                return <Typography>?/{formatNumberInt(product.expectedReceivedAmount)}</Typography>
              let textColor = 'textPrimary'
              if (product.toReceiveAmount === product.expectedReceivedAmount) textColor = 'success.main'
              if (product.toReceiveAmount < product.expectedReceivedAmount) textColor = 'error.main'
              if (product.toReceiveAmount > product.expectedReceivedAmount) textColor = 'warning.main'
              return (
                <Box color={textColor} clone>
                  <Typography>
                    {`${formatNumberInt(product.toReceiveAmount)}/${formatNumberInt(product.expectedReceivedAmount)}`}
                  </Typography>
                </Box>
              )
            },
          },
        ]}
        data={receiveProductDetails}
        cleanSpace
      />
    </>
  )
}
