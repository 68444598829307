import { DialogProps } from '@material-ui/core'

import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import {
  DeletedPurchaseOrderDocumentResponse,
  DeletePurchaseOrderDocumentURLParams,
  PurchaseOrderProfile_Document,
} from '../../../api/smartbestbuys-api/purchase-order/types'
import { deletePurchaseOrderDocument, UserActivityNote } from '../../../api/smartbestbuys-api'
import { useContext } from 'react'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'

interface Props extends DialogProps {
  value: PurchaseOrderProfile_Document
  onComplete: (data: DeletedPurchaseOrderDocumentResponse) => void
  onFail: () => void
}

const PurchaseOrderProfileDeleteDocumentDialog: React.FC<Props> = (props: Props) => {
  // set up props & context
  const { value: document, onComplete, onFail, ...dialogProps } = props
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { id } = purchaseOrderProfile

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการลบเอกสารทางการเงิน',
    description: `ลบเอกสาร ${document.type.name} ${document.referenceNumber} นี้ใช่หรือไม่?`,
    actionMessageText: 'สาเหตุของการลบเอกสาร',
    submitText: 'ยืนยันการลบ',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { actionMessageId, actionMessage, remark } = values
    const urlParams: DeletePurchaseOrderDocumentURLParams = {
      id,
      documentId: document.id,
    }
    const request: UserActivityNote = {
      actionMessageId: actionMessageId !== -1 ? actionMessageId : undefined,
      actionMessage,
      remark,
    }
    const response = await deletePurchaseOrderDocument(urlParams, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.DeletePurchaseOrderDocument}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default PurchaseOrderProfileDeleteDocumentDialog
