import { Avatar, Paper, Typography } from '@material-ui/core'
import { FlexBox, SubTopic, Table } from '../../../custom-components'
import { Photo } from '@material-ui/icons'
import './TransportPickupTaskProduct.less'
import { TransportTaskCreateDataV2_CustomerRequisitionProduct } from '../../../api/smartbestbuys-api'
import { TransportPickupTaskFormData } from './TransportPickupTaskForm'

interface Props {
  formData: TransportPickupTaskFormData
}

const TransportPickupTaskProduct: React.FC<Props> = ({ formData }) => {
  // use context
  const products = formData.customerRequisitionInfo?.products.map((p) => ({ ...p, selectedAmount: undefined })) ?? []

  // todo: future common use with purchase from vendor
  // state
  // const [selected, setSelected] = useState<number[]>([])

  // react hook form
  // const {
  //   watch,
  //   control,
  //   setValue,
  //   clearErrors,
  //   formState: { errors },
  // } = useFormContext<TransportPickupTaskCreateFormValues>()

  // const { selectedProducts } = watch()

  // render
  return (
    <FlexBox
      p={3}
      justifyContent="space-between"
      flexDirection="column"
      // todo: future common use with purchase from vendor
      // border={errors.selectedProducts ? 1 : undefined}
      // borderColor={errors.selectedProducts ? 'error.main' : undefined}
      clone
    >
      <Paper id="shipping-product-selector-box">
        <SubTopic>รายการสินค้า</SubTopic>
        <div className="mb-4" />
        <Table<TransportTaskCreateDataV2_CustomerRequisitionProduct & { selectedAmount: number | undefined }>
          color="primary.dark"
          size="small"
          columns={[
            {
              name: 'ชื่อสินค้า',
              render: (product) => (
                <div className="transport-task-products-table__product-cell" key={'product-' + product.id}>
                  <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                    <Photo />
                  </Avatar>
                  <div className="transport-task-products-table__product-cell__info">
                    <Typography variant="body2" color="textSecondary">
                      {product.code}
                    </Typography>
                    <Typography variant="body2">{product.nameLocal}</Typography>
                  </div>
                </div>
              ),
            },
            {
              name: 'จำนวนที่รับ',
              fixedWidth: 200,
              render: (product) => (
                <Typography color="textPrimary" variant="body2">
                  {product.amount}
                </Typography>
              ),
            },
            // todo: future common use with purchase from vendor
            // {
            //   name: 'จำนวนที่จะรับ',
            //   fixedWidth: 200,
            //   render: (product, index) => {
            //     if (!selected.includes(product.id))
            //       return (
            //         <Typography color="textSecondary" variant="body2">
            //           ไม่ได้เลือก
            //         </Typography>
            //       )

            //     return (
            //       <GeneralTextFieldForm
            //         key={`selectedProducts.${index}.amount`}
            //         margin="none"
            //         control={control}
            //         name={`selectedProducts.${index}.amount`}
            //         rules={{
            //           required: selected?.includes(product.id) ? 'กรุณากรอกจำนวน' : undefined,
            //           max: {
            //             value: product.amount,
            //             message: 'จำนวนรับเกินจำนวนคำขอลูกค้า',
            //           },
            //         }}
            //         onPostChange={() => {
            //           if (!!errors.selectedProducts) {
            //             clearErrors('selectedProducts')
            //           }
            //         }}
            //         type="number"
            //         placeholder="กรอกจำนวนที่จะรับ"
            //       />
            //     )
            //   },
            // },
          ]}
          data={products}
          pagination={false}
          // todo: future common use with purchase from vendor
          // selectable
          // onChange={setSelected}
          // onSelect={(productId) => {
          //   if (!!errors.selectedProducts) {
          //     clearErrors('selectedProducts')
          //   }
          //   const index = selectedProducts.findIndex((p) => p.id === productId)
          //   setValue(`selectedProducts.${index}.selectedAmount`, selectedProducts[index].amount)
          // }}
          // onDeSelect={(productId) => {
          //   const index = selectedProducts.findIndex((p) => p.id === productId)
          //   setValue(`selectedProducts.${index}.selectedAmount`, undefined)
          // }}
          // hideSelectAll
          cleanSpace
        />
      </Paper>
    </FlexBox>
  )
}

export default TransportPickupTaskProduct
