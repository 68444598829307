import { Box, Grid, Paper } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useState } from 'react'
import { PaymentRequisitionProfile } from '../../api/smartbestbuys-api'
import { BaseData } from '../../api/smartbestbuys-api/types'
import { VendorBankAccount } from '../../api/smartbestbuys-api/vendor/vendor-types'
import { TypographyWithLabel, Title, FlexBox } from '../../custom-components'
import { CurrenciesType } from '../../enums'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { formatNumber } from '../../functions/number-utils'
import { Space } from '../../shared-components'
import { getUserPermissionInfo } from '../../utils/permission'
import UpdateExchangeRateDialog from './profile/UpdateExchangeRateDialog'
export interface PaymentRequisitionInformation {
  id?: number
  paymentMethod: BaseData
  vendorBankAccount: VendorBankAccount
  withholdingTaxAmount?: number
  exchangeRate: number
  total: number
  currency: BaseData
}

interface Props {
  paymentRequisitionInformation: PaymentRequisitionInformation
  onExchangeRateUpdate?: (profile: PaymentRequisitionProfile) => void
}

const PaymentRequisitionInformationBox = (props: Props) => {
  // props
  const {
    paymentRequisitionInformation: {
      id,
      paymentMethod,
      vendorBankAccount,
      withholdingTaxAmount,
      exchangeRate,
      total,
      currency,
    },
    onExchangeRateUpdate,
  } = props

  // state
  const [dialogOpen, setDialogOpen] = useState(false)

  // prepare data
  // TODO: ask p tei about payment conversion
  // const withholdingTaxTotalConverted = roundNumber(withholdingTaxTotal * exchangeRate)
  // const totalConverted = roundNumber(total * exchangeRate)
  // const finalTotalConverted = totalConverted - withholdingTaxTotalConverted

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Write)

  const fields = [
    { label: 'วิธีจ่ายเงิน:', value: paymentMethod.name },
    { label: 'บัญชีธนาคาร:', value: `${vendorBankAccount.bank.name} ${vendorBankAccount.accountNumber}` },
    {
      label: 'อัตราแลกเปลี่ยน:',
      value: `${formatNumber(exchangeRate)} ${currency.name}`,
      suffix:
        !!id && writePermission.hasPermission ? (
          <Edit
            style={{ fontSize: '1.125rem', cursor: 'pointer' }}
            color="secondary"
            onClick={() => setDialogOpen(true)}
          />
        ) : undefined,
      hidden: currency.id === CurrenciesType.THB,
    },
    { label: 'ยอดทำเบิก:', value: `${formatNumber(total)} ${currency.name}` },
    {
      label: 'ยอดทำเบิกสุทธิ:',
      value: `${formatNumber(total - (withholdingTaxAmount ?? 0))} ${currency.name}`,
      textColor: 'secondary.dark',
    },
  ]

  if (!!withholdingTaxAmount) {
    fields.splice(4, 0, { label: 'หัก ณ ที่จ่ายทั้งหมด:', value: formatNumber(withholdingTaxAmount) })
  }

  return (
    <>
      {currency.id !== CurrenciesType.THB && !!id && (
        <UpdateExchangeRateDialog
          paymentRequisitionId={id}
          oldExchangeRate={exchangeRate}
          open={dialogOpen}
          onSuccess={(updated) => {
            if (!!onExchangeRateUpdate) onExchangeRateUpdate(updated)
            setDialogOpen(false)
          }}
          onClose={() => setDialogOpen(false)}
        />
      )}
      <Box p={3} clone>
        <Paper>
          <Title>ข้อมูลสรุปการทำเบิก</Title>
          <Space />
          <Grid container spacing={1}>
            {fields
              .filter((field) => !field.hidden)
              .map((field, index) => {
                return (
                  <Grid item xs={12} md={6} key={index}>
                    <TypographyWithLabel label={field.label}>
                      <FlexBox color={field.textColor} alignItems="center">
                        <span>{field.value}</span>
                        <Space direction="horizontal" />
                        <span> {field.suffix}</span>
                      </FlexBox>
                    </TypographyWithLabel>
                  </Grid>
                )
              })}
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

export default PaymentRequisitionInformationBox

export const mapPaymentRequisitionProfileToInformationValue = (
  data: PaymentRequisitionProfile,
): PaymentRequisitionInformation => {
  return {
    id: data.id,
    paymentMethod: data.paymentMethod,
    vendorBankAccount: data.vendorInfo.bankAccount,
    withholdingTaxAmount: data.withholdingTaxAmount,
    exchangeRate: data.exchangeRate,
    total: data.total,
    currency: data.vendorInfo.currency,
  }
}
