import React, { useState, useRef } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Grid, Modal, Backdrop, Fade, Typography, TextField, Button, InputAdornment } from '@material-ui/core'
import numeral from 'numeral'
import { useStyles } from './styles'
import { Table, Title, Avatar } from '../../../../../custom-components'
import { SelectedProduct } from '../../../../types'
import SOCreationFormValues from '../../../../pages/SOCreationPage/SOCreationFormValues'
import { useProductsPricing } from '../../../../../hooks'
import { Photo } from '@material-ui/icons'
import { ProductDataRow, ProductPrice } from '../../../../../api/smartbestbuys-api'
import { formatNumber } from '../../../../../functions/number-utils'

interface Props {
  open: boolean
  onClose: () => void
  product: ProductDataRow | SelectedProduct
  isDealer?: boolean
}

//TODO: refactor
const ProductModal: React.FC<Props> = (props) => {
  const { product, onClose, isDealer } = props
  const { id, code, nameLocal, unitLocal, stockAmount, imageUrl, dealerPrice } = product
  const { reservedAmount } = product as ProductDataRow
  const { amount: initAmount, price: initPrice } = product as SelectedProduct
  const styles = useStyles()
  const pricing = useProductsPricing(id)
  const priceInputRef = useRef<HTMLInputElement>()

  const { control, setValue, getValues } = useFormContext<SOCreationFormValues>()
  const { append } = useFieldArray<SOCreationFormValues>({
    control,
    name: 'selectedProducts',
  })
  const { selectedProducts } = getValues()

  const [amount, setAmount] = useState<number | undefined>(initAmount)
  const [price, setPrice] = useState<number | undefined>(initPrice)

  const handleClick = () => {
    const index = selectedProducts.findIndex((p) => p.id === product.id)

    if (index === -1) {
      const { code, nameLocal, nameEn, unitLocal, unitEn, imageUrl, stockAmount, dealerPrice } = product
      const selectedProduct: SelectedProduct = {
        id: product.id,
        code,
        nameLocal,
        nameEn,
        unitLocal,
        unitEn,
        imageUrl,
        amount: amount || 0,
        price: price || 0,
        stockAmount,
        dealerPrice,
      }
      append(selectedProduct as any, { shouldFocus: false })
      onClose()
    } else {
      const targetProduct = selectedProducts[index]
      setValue(`selectedProducts.${index}`, {
        ...targetProduct,
        amount: amount || 0,
        price: price || 0,
      })
      onClose()
    }
  }

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={styles.modal}
      {...props}
    >
      <Fade in={props.open}>
        <Grid container className={styles.grid} spacing={2}>
          <Grid item xs={12}>
            <Title>เลือกราคาและจำนวน</Title>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item sm={12} md={2}>
              <Avatar variant="rounded" className={styles.avatar} src={imageUrl} objectFit="contain">
                <Photo />
              </Avatar>
            </Grid>
            <Grid item sm={12} md={7}>
              <Typography variant="subtitle2" component="div" gutterBottom>
                {code}
              </Typography>
              <Typography variant="body2" component="div" gutterBottom>
                {nameLocal}
              </Typography>
              {!!dealerPrice && (
                <Typography variant="body2" component="div" gutterBottom>
                  ราคาสำหรับ Dealer {formatNumber(dealerPrice)}
                </Typography>
              )}
              <Typography variant="body1" component="div" color="secondary" gutterBottom>
                เหลือ {numeral(stockAmount - reservedAmount).format('0,0')}
              </Typography>
            </Grid>
            <Grid item sm={12} md={3} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  defaultValue={amount}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setAmount(e.target.value ? Number(e.target.value) : undefined)}
                  onBlur={(e) => {
                    const amount = Number(e.target.value)
                    if (isDealer && !!dealerPrice) {
                      setPrice(dealerPrice)
                    } else {
                      const foundPrice = pricing.find((price) => price.fromAmount <= amount && amount <= price.toAmount)
                      if (foundPrice) {
                        setPrice(foundPrice.price)
                      }
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      priceInputRef.current?.select()
                    }
                  }}
                  type="number"
                  label="จำนวน"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{unitLocal || 'ชิ้น'}</InputAdornment>,
                    inputProps: { min: 1 },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={priceInputRef}
                  fullWidth
                  defaultValue={price}
                  value={price}
                  // focused={price !== undefined}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setPrice(e.target.value ? Number(e.target.value) : undefined)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleClick()
                    }
                  }}
                  type="number"
                  label="ราคาต่อหน่วย"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                    inputProps: { min: 1 },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} container justify="flex-end">
                <Typography variant="body2" color="secondary" gutterBottom>
                  ราคารวม {numeral((amount || 0) * (price || 0)).format('0,0.00')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Title>ช่วงราคาตามจำนวน</Title>
          </Grid>
          <Grid item xs={12}>
            <Table<ProductPrice>
              color="primary"
              columns={[
                {
                  name: 'จำนวนที่',
                  render: (data: ProductPrice) =>
                    `${numeral(data.fromAmount).format('0,0')} - ${numeral(data.toAmount).format('0,0')}`,
                },
                { name: 'ราคาต่อหน่วย', dataIndex: 'price', numeric: true, format: '0,0.00' },
                {
                  name: 'ราคารวม',
                  dataIndex: 'price',
                  numeric: true,
                  render: (data: ProductPrice) =>
                    `${numeral(data.fromAmount * data.price).format('0,0.00')} - ${numeral(
                      data.toAmount * data.price,
                    ).format('0,0.00')}`,
                },
              ]}
              data={pricing}
              pagination={false}
              cleanSpace
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button color="primary" onClick={onClose}>
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!price || price < 1 || !amount || amount < 1}
              onClick={handleClick}
            >
              ยืนยันข้อมูล
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  )
}

export default ProductModal
