import React, { FC } from 'react';
import ReactPDF, { View, StyleSheet } from '@react-pdf/renderer';
import { getViewStyles } from './styles';
import { PDFProps } from './props';

interface Props {
  columnSpan: number,
  direction?: 'row',
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between' | 'space-evenly'
}

const PDFColumn: FC<ReactPDF.ViewProps & PDFProps & Props> = (props) => {
  const { columnSpan, direction, align, ...viewProps } = props;

  const commonStyles = getViewStyles(props);

  const styles = StyleSheet.create({
    col: {
      display: 'flex',
      flexDirection: direction,
      justifyContent: align,
      width: 555 / 12 * columnSpan
    }
  })

  const { style, ...otherProps } = viewProps;
  return (
    <View style={{
      ...commonStyles,
      ...styles.col,
      ...style,
    }} {...otherProps} />
  );
}

export default PDFColumn;