import { Box, Paper, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { QuotationProfile_RefSaleOrder } from '../../../api/smartbestbuys-api/quotation/quotation-profile-types'
import { EmptyCard, FlexBox, Link, SubTopic, Table } from '../../../custom-components'
import { dateTimeFormat } from '../../../functions'
import { SOPaymentStatusChip, SOStatusChip, Space } from '../../../shared-components'
import { QuotationsProfilePageContextImpl } from './QuotationsProfilePage'

const SaleOrderOfQuotationsList: React.FC = () => {
  const { profile } = useContext(QuotationsProfilePageContextImpl)

  return (
    <Box p={3} clone>
      <Paper>
        <FlexBox justifyContent="space-between">
          <SubTopic gutterBottom>รายการใบสั่งขายที่สร้างจากใบเสนอราคานี้</SubTopic>
        </FlexBox>
        <Space />
        {profile.refSaleOrders?.length === 0 ? (
          <EmptyCard emptyText="ยังไม่เคยสร้างใบสั่งขายจากใบเสนอราคานี้" />
        ) : (
          <Table<QuotationProfile_RefSaleOrder>
            size="small"
            color="primary.dark"
            columns={[
              /* TODO : wait this stockAvailable flag. when have, please implement it */
              //   {
              //     name: '',
              //     render: ({ stockAvailable }) =>
              //       !!profile.am && (
              //         <IconChip
              //           icon={
              //             <Box color="error.darkText">
              //               <EmptyBoxIcon fontSize="small" color="inherit" />
              //             </Box>
              //           }
              //           color="error.light"
              //         />
              //       ),
              //   },
              {
                name: 'สถานะใบสั่งขาย',
                render: ({ status }) => <SOStatusChip status={status.id} label={status.name} />,
              },
              {
                name: 'สถานะชำระเงิน',
                render: ({ paymentStatus }) => (
                  <SOPaymentStatusChip status={paymentStatus.id} label={paymentStatus.name} />
                ),
              },
              {
                name: 'เลขที่ใบสั่งขาย',
                render: ({ id, code }) => (
                  <Box color="primary.dark">
                    <Link color="inherit" href={`/sale-orders/${id}/profile`} target="_blank">
                      <Typography variant="body2">{code}</Typography>
                    </Link>
                  </Box>
                ),
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
              {
                name: 'วิธีชำระเงิน',
                render: ({ paymentMethod }) => paymentMethod.name,
              },
              {
                name: 'ยอดรวมสุทธิ',
                numeric: true,
                format: '0,0.00',
                dataIndex: 'total',
              },
            ]}
            data={profile.refSaleOrders || []}
            cleanSpace
            pagination
          />
        )}
      </Paper>
    </Box>
  )
}

export default SaleOrderOfQuotationsList
