/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  GetPaymentRequisitionTableDataRequestQueryParam,
  IPaymentRequisitionTableData,
  IPaymentRequisitionTableData_Data,
  getPaymentRequisitionTableDataAPI,
} from '../../api/smartbestbuys-api'
import { IUseTableData, IUseTableDataParams } from '../../types/pagination'
import { getPaymentRequisitionStatusByGroup } from '../../functions'

export interface GetPaymentRequisitionTableDataSearchParams {
  code?: string
  paymentCode?: string
  vendorName?: string
  statusGroupId?: number
}

const mapDataToGetPaymentRequisitionTableDataQueryParam = (
  pageIndex: number,
  rowPerPage: number,
  search: GetPaymentRequisitionTableDataSearchParams,
): GetPaymentRequisitionTableDataRequestQueryParam => {
  return {
    pageIndex: pageIndex.toString(),
    rowPerPage: rowPerPage.toString(),
    code: search.code,
    vendorName: search.vendorName,
    statusIds: search.statusGroupId ? getPaymentRequisitionStatusByGroup(search.statusGroupId).join(',') : '',
  }
}

interface IUseTableDataPaymentRequisition
  extends IUseTableData<IPaymentRequisitionTableData_Data, GetPaymentRequisitionTableDataSearchParams> {
  handleSearchGroup: (groupId: number) => void
}

export const usePaymentRequisitionTableData = (
  params: IUseTableDataParams<GetPaymentRequisitionTableDataSearchParams>,
): IUseTableDataPaymentRequisition | undefined => {
  // state
  const [pendingSearch, setPendingSearch] = useState<GetPaymentRequisitionTableDataSearchParams>(params.search)
  const [search, setSearch] = useState<GetPaymentRequisitionTableDataSearchParams>(params.search)
  const [rowPerPage, setRowPerPage] = useState(params.rowPerPage)
  const [tableData, setTableData] = useState<IPaymentRequisitionTableData | undefined>(undefined)

  // first load
  useEffect(() => {
    ;(async () => {
      const queryParams = mapDataToGetPaymentRequisitionTableDataQueryParam(params.pageIndex, params.rowPerPage, search)
      const response = await getPaymentRequisitionTableDataAPI(queryParams)
      setTableData(response?.data || undefined)
    })()
  }, [])

  // functions
  const handlePageChange = async (_pageIndex: number) => {
    if (!tableData) return

    const queryParams = mapDataToGetPaymentRequisitionTableDataQueryParam(_pageIndex, rowPerPage, search)
    const response = await getPaymentRequisitionTableDataAPI(queryParams)
    setTableData(response?.data || undefined)
  }

  const handleRowPerPageChange = async (_rowPerPage: number) => {
    if (!tableData) return
    const queryParams = mapDataToGetPaymentRequisitionTableDataQueryParam(0, _rowPerPage, search)
    const response = await getPaymentRequisitionTableDataAPI(queryParams)
    setTableData(response?.data || undefined)
    setRowPerPage(_rowPerPage)
  }

  const handleSearchParamChange = <T extends keyof GetPaymentRequisitionTableDataSearchParams>(
    key: T,
    value: GetPaymentRequisitionTableDataSearchParams[T],
  ) => {
    setPendingSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const handleSearchGroup = async (statusGroupId: number) => {
    setPendingSearch((prev) => {
      return {
        ...prev,
        statusGroupId,
      }
    })
    const queryParams = mapDataToGetPaymentRequisitionTableDataQueryParam(0, rowPerPage, {
      ...pendingSearch,
      statusGroupId,
    })
    const response = await getPaymentRequisitionTableDataAPI(queryParams)
    setTableData(response?.data || undefined)
    setSearch({
      ...pendingSearch,
      statusGroupId,
    })
  }

  const handleSearch = async () => {
    const queryParams = mapDataToGetPaymentRequisitionTableDataQueryParam(0, rowPerPage, pendingSearch)
    const response = await getPaymentRequisitionTableDataAPI(queryParams)
    setTableData(response?.data || undefined)
    setSearch(pendingSearch)
  }

  return tableData
    ? {
        ...tableData,
        summary: tableData.summary,
        pendingSearch,
        handlePageChange,
        handleSearchGroup,
        handleRowPerPageChange,
        handleSearchParamChange,
        handleSearchParamsChange: (s) => {},
        handleSearch,
      }
    : undefined
}
