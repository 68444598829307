export enum ShippingMethod {
  Smart = 'SMART',
  Express = 'ขนส่งอื่น',
  None = 'ไม่ระบุขนส่ง',
}

export enum ShippingMethodID {
  Smart = 1,
  Express = 2,
  None = 3,
}

export const shippingMethods = [
  { id: ShippingMethodID.Smart, name: 'SMART', value: ShippingMethod.Smart },
  { id: ShippingMethodID.Express, name: 'ขนส่งอื่น', value: ShippingMethod.Express },
  { id: ShippingMethodID.None, name: 'ไม่ระบุขนส่ง', value: ShippingMethod.None },
]

export enum ShippingPaymentCondition {
  Smart = 1,
  Customer = 2,
}

export const shippingPaymentConditions = [
  { id: ShippingPaymentCondition.Smart, name: 'ต้นทาง', value: ShippingPaymentCondition.Smart },
  { id: ShippingPaymentCondition.Customer, name: 'ปลายทาง', value: ShippingPaymentCondition.Customer },
]
