import { Grid, Paper } from '@material-ui/core'
import { ICreditor } from '../../api/smartbestbuys-api'
import { withLayout, Topic, FilteredTable, Link, FlexBox } from '../../custom-components'
import { BlackListSignal, CreditSignal, Loading } from '../../shared-components'
import { useCustomerCreditors } from '../../hooks'
import { RootContext } from '../..'
import { useContext } from 'react'
import { PageName } from '../../enums'

const CustomerCreditorListPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CREDITOR)

  const customers = useCustomerCreditors()

  if (!customers) return <Loading />

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>รายการลูกค้าที่เราติดหนี้</Topic>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<ICreditor>
            disableDateRangeFilter
            color="primary.dark"
            columns={[
              {
                name: '',
                render: ({ isBlacklist, isCredit }) => (
                  <FlexBox gridGap={8}>
                    {isBlacklist && <BlackListSignal />}
                    {!!isCredit && <CreditSignal />}
                  </FlexBox>
                ),
              },
              {
                name: 'รหัสลูกค้า',
                render: ({ id, code }) => (
                  <Link color="primary" href={`/customers/${id}/profile`}>
                    {code}
                  </Link>
                ),
              },
              {
                name: 'ชื่อ',
                dataIndex: 'name',
              },
              {
                name: 'เบอร์โทรศัพท์',
                dataIndex: 'phoneNumber',
              },
              {
                name: 'อีเมล',
                dataIndex: 'email',
              },
              {
                name: 'ยอดหนี้รวม',
                dataIndex: 'debtTotal',
                numeric: true,
                format: '0,0.00',
              },
            ]}
            data={customers}
            size="small"
            filters={[
              {
                name: 'สถานะลูกค้าเครดิต',
                values: [
                  { name: 'เป็นลูกค้าเครดิต', value: 1 },
                  { name: 'ไม่ใช่เป็นลูกค้าเครดิต', value: 0 },
                ],
                filter: (data: ICreditor, values: (string | number)[]): boolean => {
                  return !data.isCredit ? values.includes(0) : values.includes(1)
                },
              },
              {
                name: 'สถานะติดบัญชีดำ',
                values: [
                  { name: 'เป็นลูกค้าติดบัญชีดำ', value: 1 },
                  { name: 'ไม่เป็นลูกค้าติดบัญชีดำ', value: 0 },
                ],
                filter: (data: ICreditor, values: (string | number)[]): boolean => {
                  return !data.isBlacklist ? values.includes(0) : values.includes(1)
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withLayout(CustomerCreditorListPage)
