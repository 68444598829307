import { Avatar, Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { Create, Photo } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { ICustomerRequisitionProfile_Product } from '../../../api/smartbestbuys-api'
import { FlexBox, SubTopic, Table, TColumn, TypographyWithLabel } from '../../../custom-components'
import { formatNumberInt } from '../../../functions/number-utils'
import { ConditionTooltip, ImageCard, PhotoDisplay, Space } from '../../../shared-components'
import { CustomerRequisitionProfileContext } from './CustomerRequisitionProfilePage'
import './CustomerRequisitionDetail.less'
import { CustomerRequisitionType } from '../../../enums'
import { format } from 'date-fns'
import locale from 'date-fns/locale/th'
import { CustomerRequisitionNoteDialog } from '..'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const CustomerRequisitionDetail: React.FC = () => {
  const customerRequisitonProfile = useContext(CustomerRequisitionProfileContext)
  const { id } = customerRequisitonProfile

  const [open, setOpen] = useState<boolean>(false)

  const hasAttachments =
    customerRequisitonProfile.attachments.filter((att) => !att.customerRequisitionNoteId).length > 0

  const hasNotes = customerRequisitonProfile.notes.length > 0

  const isClaimCR = customerRequisitonProfile.type.id === CustomerRequisitionType.Claim

  const columns = [
    {
      name: 'ลำดับ',
      render: (_, index) => index + 1,
      numeric: true,
      format: '0,0',
      fixedWidth: 50,
    },
    {
      name: 'ชื่อสินค้า',
      render: (product) => (
        <div className="customer-requisition-table__product-cell" key={'product-' + product.productId}>
          <Avatar alt={product.productNameLocal} src={product.productImageUrl} variant="rounded">
            <Photo />
          </Avatar>
          <div className="customer-requisition-table__product-cell__info">
            <Typography color="textSecondary">{product.productCode}</Typography>
            <Typography>{product.productNameLocal}</Typography>
          </div>
        </div>
      ),
    },
    {
      name: 'จำนวนที่ขาย',
      render: ({ salesAmount }) => (
        <div style={{ alignContent: 'right' }}>
          <Typography variant="body2">{formatNumberInt(salesAmount)} ชิ้น</Typography>
        </div>
      ),
    },
    {
      name: isClaimCR ? 'จำนวนที่จะเคลม' : 'จำนวนที่จะคืน',
      render: ({ amount }) => (
        <Typography variant="body2" color="error">
          {formatNumberInt(amount)} ชิ้น
        </Typography>
      ),
    },
    {
      name: 'จำนวนที่รับมาแล้ว',
      render: ({ amount, receivedAmount }) => {
        let textColor = 'textPrimary'
        if (receivedAmount === amount) textColor = 'success.main'
        if (receivedAmount < amount) textColor = 'error.main'
        if (receivedAmount > amount) textColor = 'warning.main'

        return (
          <Box color={textColor} clone>
            <Typography variant="body2">{formatNumberInt(receivedAmount)} ชิ้น</Typography>
          </Box>
        )
      },
    },
    {
      hide: !isClaimCR,
      name: 'จำนวนที่ส่งคืนแล้ว',
      dataIndex: 'shippedAmount',
      render: ({ amount, shippedAmount }) => {
        let textColor = 'textPrimary'
        if (shippedAmount === amount) textColor = 'success.main'
        if (shippedAmount < amount) textColor = 'error.main'
        if (shippedAmount > amount) textColor = 'warning.main'

        return (
          <Box color={textColor} clone>
            <Typography variant="body2">{formatNumberInt(shippedAmount)} ชิ้น</Typography>
          </Box>
        )
      },
    },
  ] as TColumn<ICustomerRequisitionProfile_Product & { id: number }>[]

  // permission
  const writeCRPermission = getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Write)

  return (
    <>
      <CustomerRequisitionNoteDialog customerRequisitionId={id} open={open} onClose={() => setOpen(false)} />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <SubTopic>รายละเอียดคำร้อง</SubTopic>
              <ConditionTooltip title={writeCRPermission.alertMessage} showTooltip={!writeCRPermission.hasPermission}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Create />}
                  onClick={() => setOpen((prev) => !prev)}
                  disabled={!writeCRPermission.hasPermission}
                >
                  จดบันทึก
                </Button>
              </ConditionTooltip>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <TypographyWithLabel label="ประเภท:">{customerRequisitonProfile.type.name}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12}>
            <TypographyWithLabel label="รายละเอียด:">{customerRequisitonProfile.content}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">{isClaimCR ? 'สินค้าสำหรับเคลม' : 'สินค้าสำหรับคืน'}</Typography>
            <Table<ICustomerRequisitionProfile_Product & { id: number }>
              color="primary.dark"
              size="small"
              columns={columns.filter((c) => !c.hide)}
              data={customerRequisitonProfile.products.map((p) => ({ id: p.productId, ...p }))}
              cleanSpace
              pagination={false}
            />
          </Grid>
          {hasAttachments && (
            <Grid item xs={12}>
              <Typography variant="body2">ภาพหลักฐาน</Typography>
              <Space size="small" />
              <FlexBox gridGap={8}>
                {customerRequisitonProfile.attachments
                  .filter((att) => !att.customerRequisitionNoteId)
                  .map((att, index) => (
                    <ImageCard name={`ภาพหลักฐาน ${index + 1}`} src={att.url} showName />
                  ))}
              </FlexBox>
            </Grid>
          )}
          {hasNotes && (
            <Grid item xs={12}>
              <Typography variant="body2">บันทึกเพิ่มเติม</Typography>
              <Space size="small" />
              {customerRequisitonProfile.notes.map((note, index) => (
                <>
                  <Box id={`note-${index + 1}`} p={1} style={{ backgroundColor: '#00000014' }}>
                    <FlexBox justifyContent="space-between">
                      <Typography variant="body2">{note.recCreatedBy}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {format(new Date(note.recCreatedAt), 'dd/MM/yyyy HH:mm', { locale })}
                      </Typography>
                    </FlexBox>
                    <Space size="small" />
                    <Typography variant="body2" color="textSecondary">
                      {note.content}
                    </Typography>
                    <Space size="small" />
                    <FlexBox gridGap={10}>
                      {customerRequisitonProfile.attachments
                        .filter((att) => att.customerRequisitionNoteId === note.id)
                        .map((att, index) => (
                          <PhotoDisplay src={att.url} size="big" name={index.toString()} />
                        ))}
                    </FlexBox>
                  </Box>
                  <Space size="small" />
                </>
              ))}
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerRequisitionDetail
