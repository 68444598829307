import { Button, Grid, MenuItem, Paper, Popover, Typography } from '@material-ui/core'
import { ArrowForward, MoreHoriz } from '@material-ui/icons'
import { createContext, useContext, useState } from 'react'
import {
  CRStatusBar,
  CustomerRequisitionConditionDetail,
  CustomerRequisitionProfileCancelDialog,
  CustomerRequisitionDetail,
  CustomerRequisitionTask,
  CustomerRequisitionUserActivity,
} from '.'
import { CustomerRequisitionProfile } from '../../../api/smartbestbuys-api'
import { BreadcumbsList, FlexBox, Link, SubTopic, Topic, withLayout } from '../../../custom-components'
import { CustomerRequisitionStatus, PageName, TaskStatus, TaskType } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { useCustomerProfile } from '../../../hooks'
import { useCustomerRequisitionProfile } from '../../../hooks/useCustomerRequisitionProfile'
import { CustomerProfileCard, Loading, MultipleConditionTooltip } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { RootContext } from '../../..'
import { useHistory } from 'react-router-dom'

interface Props {
  id: number
}

export const CustomerRequisitionProfileContext = createContext<CustomerRequisitionProfile>(
  {} as CustomerRequisitionProfile,
)

const updateWhitelist = [CustomerRequisitionStatus.PendingToReview]

const CustomerRequisitionProfilePage: React.FC<Props> = (props) => {
  const { id } = props
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION_LIST)

  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [customerRequisitionProfile] = useCustomerRequisitionProfile(id)
  const customerProfile = useCustomerProfile(customerRequisitionProfile?.customerInfo?.id)

  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false)

  if (!customerRequisitionProfile || !customerProfile) return <Loading />

  const { code, status, saleOrderInfo, condition } = customerRequisitionProfile

  const ableToUpdate = updateWhitelist.includes(status.id)

  const invalidStatus = [CustomerRequisitionStatus.Canceled, CustomerRequisitionStatus.Rejected].includes(status.id)

  const hasDoneWarehouse = customerRequisitionProfile.tasks.some(
    (task) =>
      task.status.id === TaskStatus.Done &&
      [TaskType.WaitingCustomerShipProduct, TaskType.WaitingCustomerReturnProduct].includes(task.type.id),
  )

  const hasTransportPickupTask = customerRequisitionProfile.tasks.some(
    (task) =>
      ![TaskStatus.Failed, TaskStatus.Canceled, TaskStatus.PreCancel].includes(task.status.id) &&
      [TaskType.TransportPickupTask].includes(task.type.id),
  )

  const ableToCancel = !invalidStatus || !hasDoneWarehouse || !hasTransportPickupTask

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Write)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Write)

  // condition items
  const updateConditionItems = [
    { showTooltip: !writePermission.hasPermission, message: writePermission.alertMessage },
    { showTooltip: !ableToUpdate, message: 'อยู่ในสถานะที่แก้ไขไม่ได้' },
  ]

  const cancelConditionItems = [
    { showTooltip: !cancelPermission.hasPermission, message: cancelPermission.alertMessage },
    { showTooltip: invalidStatus, message: 'อยู่ในสถานะที่ยกเลิกไม่ได้' },
    { showTooltip: hasTransportPickupTask, message: 'มีงานบริการรับของค้างอยู่ไม่สามารถยกเลิกได้' },
    { showTooltip: hasDoneWarehouse, message: 'มีการรับของมาแล้วไม่สามารถยกเลิกได้' },
  ]

  const isShowMenu = ableToCancel || ableToUpdate

  if (!customerRequisitionProfile || !customerProfile) return <Loading />

  const breadcumbsItems = [
    { url: '/customer-requisitions', name: 'รายการคำร้องของลูกค้า' },
    { name: `คำร้องของลูกค้า ${code}` },
  ]

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <CustomerRequisitionProfileCancelDialog
        customerRequisitionId={id}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog((prev) => !prev)}
      />
      <CustomerRequisitionProfileContext.Provider value={customerRequisitionProfile}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BreadcumbsList items={breadcumbsItems} />
          </Grid>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <Topic>คำร้องของลูกค้า {code}</Topic>
              {isShowMenu && (
                <Button variant="contained" size="large" onClick={handleClick}>
                  <MoreHoriz />
                </Button>
              )}
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MultipleConditionTooltip conditionItems={updateConditionItems}>
                  <MenuItem
                    disabled={updateConditionItems.some((item) => item.showTooltip)}
                    onClick={() => history.push(`/customer-requisitions/${id}/update`)}
                  >
                    แก้ไขคำร้อง
                  </MenuItem>
                </MultipleConditionTooltip>
                <MultipleConditionTooltip conditionItems={cancelConditionItems}>
                  <MenuItem
                    disabled={cancelConditionItems.some((item) => item.showTooltip)}
                    onClick={() => setOpenCancelDialog(true)}
                  >
                    ยกเลิกคำร้อง
                  </MenuItem>
                </MultipleConditionTooltip>
              </Popover>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between" alignItems="center">
              <CRStatusBar />
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <FlexBox p={3} justifyContent="space-between" alignItems="center" clone>
              <Paper>
                <SubTopic>ใบสั่งขายอ้างอิง {saleOrderInfo.code}</SubTopic>
                <FlexBox gridGap={16} justifyContent="flex-end" alignItems="center" clone>
                  <Link color="primary" href={`/sale-orders/${saleOrderInfo?.id}`} target="_blank">
                    <Typography>ไปที่หน้ารายละเอียดใบสั่งขาย</Typography>
                    <ArrowForward />
                  </Link>
                </FlexBox>
              </Paper>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <CustomerProfileCard customerProfile={customerProfile} />
          </Grid>
          {!!condition && (
            <Grid item xs={12}>
              <CustomerRequisitionConditionDetail />
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomerRequisitionDetail />
          </Grid>
          <Grid item xs={12}>
            <CustomerRequisitionTask />
          </Grid>
          <Grid item xs={12}>
            <CustomerRequisitionUserActivity />
          </Grid>
        </Grid>
      </CustomerRequisitionProfileContext.Provider>
    </>
  )
}

export default withLayout(CustomerRequisitionProfilePage)
