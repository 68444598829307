import { useContext, useMemo, useState } from 'react'
import { Box, Paper } from '@material-ui/core'
import { TransportProductPreparingTask } from '../../../api/smartbestbuys-api'
import { FilteredTable, Topic, withLayout } from '../../../custom-components'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { NavigationItemKey, PageName, TaskStatus, TaskType } from '../../../enums'
import { dateFormat, getV3Url } from '../../../functions'
import { useTransportSubTasks } from '../../../hooks/transport'
import { NotificationHead, Space, TypographyLink } from '../../../shared-components'
import { formatNumberInt } from '../../../functions/number-utils'
import { RootContext } from '../../..'
import PrepareProductTaskDialog from '../components/PrepareProductTaskDialog'

const PrepareProductTaskListPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.WAREHOUSE_PREPARE_TO_SHIP)

  // state
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [tabValue, setTabValue] = useState<TaskStatus>(TaskStatus.Pending)
  const [selected, setSelected] = useState<TransportProductPreparingTask | undefined>(undefined)

  const [subTasks, setSubTasks] = useTransportSubTasks(startDate, endDate, TaskType.WarehousePrepareProduct)

  const pendingAmount = useMemo(
    () => subTasks.filter((t) => t.status.id === TaskStatus.Pending).length,
    // eslint-disable-next-line
    [subTasks.map((t) => t.status.id).join(',')],
  )

  const processingAmount = useMemo(
    () => subTasks.filter((t) => t.status.id === TaskStatus.Processing).length,
    // eslint-disable-next-line
    [subTasks.map((t) => t.status.id).join(',')],
  )

  return (
    <Box p={3}>
      {selected && (
        <PrepareProductTaskDialog
          data={selected}
          open={true}
          onClose={() => setSelected(undefined)}
          onSuccess={(data) => {
            setSubTasks((prev) => {
              const index = prev.findIndex((p) => p.id === data.id)
              prev[index] = {
                ...prev[index],
                status: data.status,
              }
              return prev
            })

            setSelected(undefined)
          }}
        />
      )}
      <Topic>งานเตรียมสินค้าสำหรับขนส่ง</Topic>
      <NotificationHead navigationKey={NavigationItemKey.PrepareProductToShip} />
      <Space />
      <Paper>
        <FilteredTable<TransportProductPreparingTask>
          columns={[
            {
              name: 'เลขที่งานเตรียมสินค้า',
              dataIndex: 'code',
              sort: true,
              fixedWidth: 170,
              render: (task) =>
                task.code.startsWith('WHx') ? (
                  <TypographyLink variant="body2" color="primary.dark" onClick={() => setSelected(task)}>
                    {task.code}
                  </TypographyLink>
                ) : (
                  <TypographyLink
                    variant="body2"
                    color="text.secondary"
                    href={getV3Url('/warehouse-tasks')}
                    target="_blank"
                  >
                    {task.code}
                  </TypographyLink>
                ),
            },
            {
              name: 'ใบสั่งขายอ้างอิง',
              fixedWidth: 170,
              render: (task) => {
                const { saleOrderInfo } = task.parent
                if (!saleOrderInfo) return 'loading...'
                return (
                  <TypographyLink
                    variant="body2"
                    color="secondary.dark"
                    href={`/sale-orders/${saleOrderInfo.id}/profile`}
                    target="_blank"
                  >
                    {saleOrderInfo.code}
                  </TypographyLink>
                )
              },
            },
            { name: 'ชื่อลูกค้า', render: (task) => task.parent.saleOrderInfo?.customerName },
            {
              name: 'วันที่สร้าง',
              fixedWidth: 150,
              dataIndex: 'dueDate',
              render: (task) => dateFormat(task.recCreatedAt),
              sort: true,
            },
            {
              name: 'วันที่ส่งสินค้า',
              fixedWidth: 150,
              dataIndex: 'dueDate',
              render: (task) => (task.dueDate ? dateFormat(task.dueDate) : 'ยังไม่ระบุ'),
              sort: true,
            },
          ]}
          data={subTasks.filter((t) => t.status.id === tabValue)}
          sortable
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          color="primary.dark"
          cleanSpace
          tabOption={{
            tabs: [
              {
                label: `งานที่ต้องเตรียม (${formatNumberInt(pendingAmount)})`,
                value: TaskStatus.Pending,
              },
              {
                label: `งานที่กำลังเตรียม (${formatNumberInt(processingAmount)})`,
                value: TaskStatus.Processing,
              },
              { label: 'เตรียมสินค้าเสร็จสิ้น', value: TaskStatus.Done },
              {
                label: `งานถูกยกเลิก`,
                value: TaskStatus.PreCancel,
              },
              { label: 'งานที่ยกเลิก', value: TaskStatus.Canceled },
            ],
            value: tabValue,
            onChange: (_, newValue) => setTabValue(newValue),
          }}
        />
      </Paper>
    </Box>
  )
}

export default withLayout(PrepareProductTaskListPage)
