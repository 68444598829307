import { useEffect, useState } from 'react'
import { getUpdateDataV2API, ITransportTaskUpdateDataV2, TransportTaskUpdateDataV2 } from '../../api/smartbestbuys-api'

export const useTransportTaskUpdateDataV2 = (id: number): TransportTaskUpdateDataV2 | undefined => {
  const [data, setData] = useState<TransportTaskUpdateDataV2 | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getUpdateDataV2API(id)
      const data = response?.data ?? ({} as ITransportTaskUpdateDataV2)
      setData(new TransportTaskUpdateDataV2(data))
    })()
  }, [id])

  return data
}
