import {
  IPurchaseOrderTableData_Data,
  getPurchaseOrderTableDataAPI,
  GetPurchaseOrderTableDataRequestQueryParams,
} from '../api/smartbestbuys-api'

import { useTableData } from './useTablaData'

export interface IPurchaseOrderTableData_SearchParams {
  purchaseOrderStatusIds: number[]
  paymentStatusIds: number[]
  isPRCancelled?: number
  isNoVendorAppointment?: number
  isNoDocument?: number
  customerAppointmentDate?: Date
  vendorShippingDate?: Date
  vendorName?: string
  code?: string
  saleOrderCode?: string
  createdBy?: string
}

const buildQueryParams = (
  search: IPurchaseOrderTableData_SearchParams,
): GetPurchaseOrderTableDataRequestQueryParams => {
  return {
    purchaseOrderStatusIds:
      search.purchaseOrderStatusIds.length > 0 ? search.purchaseOrderStatusIds.join(',') : undefined,
    paymentStatusIds: search.paymentStatusIds.length > 0 ? search.paymentStatusIds.join(',') : undefined,
    isPRCancelled: search.isPRCancelled ? search.isPRCancelled.toString() : undefined,
    isNoVendorAppointment: search.isNoVendorAppointment ? search.isNoVendorAppointment.toString() : undefined,
    isNoDocument: search.isNoDocument ? search.isNoDocument.toString() : undefined,
    customerAppointmentDate: search.customerAppointmentDate ? search.customerAppointmentDate.toISOString() : undefined,
    vendorShippingDate: search.vendorShippingDate ? search.vendorShippingDate.toISOString() : undefined,
    vendorName: search.vendorName,
    code: search.code,
    saleOrderCode: search.saleOrderCode,
    createdBy: search.createdBy,
  }
}

export const usePurchaseOrderTableData = () =>
  useTableData<
    IPurchaseOrderTableData_Data,
    {},
    IPurchaseOrderTableData_SearchParams,
    GetPurchaseOrderTableDataRequestQueryParams
  >(
    0,
    10,
    {
      purchaseOrderStatusIds: [],
      paymentStatusIds: [],
    },
    getPurchaseOrderTableDataAPI,
    buildQueryParams,
  )
