export enum TransportTaskCreateState {
  Form = 1,
  DataVerification = 2,
}

export enum TransportTaskItemType {
  Product = 1,
  Document = 2,
}

export enum TransportTaskStatus {
  TransportPending = 3100,
  Processing = 2,
  TransportPendingVerify = 3300,
  Processed = 200,
  Cancelled = 999,
}
