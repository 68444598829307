import { Grid, Paper } from '@material-ui/core'
import numeral from 'numeral'
import { useFormContext } from 'react-hook-form'
import { SubTopic, TypographyWithLabel } from '../../../custom-components'
import { calculatePercentage, dateFormat } from '../../../functions'
import { currencies } from '../../../types'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'

const PurchaseOrderInformationBox: React.FC = () => {
  const { watch } = useFormContext<PurchaseOrderCreateFormValues>()

  const {
    paymentMethodId,
    paymentConditionId,
    vendorShippingDate,
    vendorQuotationCode,
    depositAmount,
    withholdingTaxPercentage,
    products,
    remark,
    vendor,
  } = watch()

  const productTotal = products.reduce<number>((sum, product) => {
    return sum + product.price * product.amount
  }, 0)
  const currency = currencies.find((currency) => currency.id === vendor?.currency?.id)?.name ?? 'บาท'

  const withholdingTax = withholdingTaxPercentage ? calculatePercentage(productTotal, withholdingTaxPercentage) : 0

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>ข้อมูลใบสั่งซื้อ</SubTopic>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="วิธีการชำระเงิน:" children={paymentMethodId} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="เงื่อนไขการซื้อ:" children={paymentConditionId} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="สกุลเงินที่ใช้:" children={currency} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel
            label="วันนัดหมายผู้ขาย:"
            children={(vendorShippingDate && dateFormat(vendorShippingDate)) || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="เลขใบเสนอราคาของผู้ขาย:" children={vendorQuotationCode || '-'} />
        </Grid>
        {depositAmount && (
          <Grid item xs={12} md={6}>
            <TypographyWithLabel
              label="มัดจำ:"
              children={`${numeral(depositAmount).format('0,0.00')} (${numeral(
                (depositAmount / productTotal) * 100,
              ).format('0,0.00')}%)`}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TypographyWithLabel
            label="หัก ณ ที่จ่าย:"
            children={
              (withholdingTaxPercentage &&
                `${numeral(withholdingTax).format('0,0.00')} ${currency} (${numeral(withholdingTax).format(
                  '0,0.00',
                )}%)`) ||
              'ไม่มีหัก ณ ที่จ่าย'
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="หมายเหตุ:" children={remark || '-'} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PurchaseOrderInformationBox
