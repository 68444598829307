import { makeStyles, createStyles } from '@material-ui/core/styles'

export const gridStyles = (row: number) =>
  makeStyles((theme) =>
    createStyles({
      grid1: {
        width: '100%',
        height: 40 * row,

        [theme.breakpoints.down('sm')]: {
          height: 40 * row,
        },
      },
    }),
  )
