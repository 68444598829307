import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  withWidth,
  isWidthUp,
  Drawer as MuiDrawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { useStyles } from './styles'

import navigationItems from './navigationItems'
import { RootContext } from '../..'
import { useNotification } from '../../hooks'
import { NotiItem } from '../../api/smartbestbuys-api/general/general-types'
import { NotificationBadgeMenu } from '../../shared-components'

interface DrawerProps {
  open: boolean
  width: Breakpoint
  onClose: () => void
}

const Drawer: React.FC<DrawerProps> = (props) => {
  const { currentPage } = useContext(RootContext)
  const { open, width, onClose } = props

  const history = useHistory()
  const classes = useStyles()
  const isSmUp = isWidthUp('sm', width)

  const notification = useNotification()

  const [navOpen, setNavOpen] = useState<string>('')

  return (
    <MuiDrawer
      className={classes.drawer}
      variant={isSmUp ? 'persistent' : 'temporary'}
      onClose={onClose}
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {isSmUp && <Toolbar />}
      <List component="nav" aria-labelledby="navigation-bar">
        {navigationItems
          .filter((item) => item.render)
          .map((item) => {
            const { key, name, icon, path, href, children, pageName, suffixIcon } = item
            const isInChildren = children?.find((c) => c.pageName === currentPage)

            const notiItems = notification.navigationItemNoticeAmount(key)

            return (
              <div key={key}>
                <ListItem
                  button
                  key={key}
                  onClick={(_) => {
                    if (href) {
                      window.open(href)
                    } else {
                      if (path) {
                        history.push(path)
                      } else {
                        setNavOpen((prev) => (prev === item.name ? '' : item.name))
                      }
                    }
                  }}
                >
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText
                    primary={
                      <div className="flex-wrap">
                        <span className="mr-2 inline-flex items-center gap-2">
                          {name} {suffixIcon}
                        </span>
                        {notiItems.reduce<NotiItem[]>(reducer, []).map((notiItem: NotiItem, index: number) => {
                          return <NotificationBadgeMenu key={`noti-${index}`} item={notiItem} />
                        })}
                      </div>
                    }
                    primaryTypographyProps={{
                      color: currentPage === pageName ? 'primary' : 'textPrimary',
                    }}
                  />
                </ListItem>
                {children && (
                  <Collapse in={navOpen === item.name || !!isInChildren} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {children
                        .filter((item) => item.render)
                        .map((child) => {
                          const { key, name, icon, href, pageName: childPageName, path, suffixIcon } = child
                          const notiItems = notification.navigationItemNoticeAmount(key)

                          return (
                            <ListItem
                              button
                              key={key}
                              className={classes.nestedItem}
                              onClick={(_) => {
                                if (href) {
                                  window.open(href)
                                } else {
                                  path && history.push(path)
                                }
                              }}
                            >
                              {icon && <ListItemIcon>{icon}</ListItemIcon>}
                              <ListItemText
                                primary={
                                  <div className="flex-wrap">
                                    <span className="mr-2 inline-flex items-center gap-2">
                                      {name} {suffixIcon}
                                    </span>
                                    {notiItems
                                      .reduce<NotiItem[]>(reducer, [])
                                      .map((notiItem: NotiItem, index: number) => {
                                        return <NotificationBadgeMenu key={`noti-${index}`} item={notiItem} />
                                      })}
                                  </div>
                                }
                                primaryTypographyProps={{
                                  color: currentPage === childPageName ? 'primary' : 'textPrimary',
                                }}
                              />
                            </ListItem>
                          )
                        })}
                    </List>
                  </Collapse>
                )}
              </div>
            )
          })}
      </List>
    </MuiDrawer>
  )
}

const DrawerWithWidth = withWidth()(Drawer)

export default DrawerWithWidth

const reducer = (items: NotiItem[], each: NotiItem) => {
  const _index = items.findIndex((item) => item.team === each.team)

  if (_index !== -1) {
    items[_index].amount += each.amount
    items[_index].name = [items[_index].name, each.name].join('\n')

    return items
  }

  return [...items, each]
}
