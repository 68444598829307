import { BaseData } from '..'
import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const approveSOPayment = (saleOderId: number) =>
  errorSingleHandler<UpdatedSOPaymentTransactionStatus>(async (id: number) => {
    const path = `/sale-orders/${saleOderId}/payments/${id}/approve`
    return await smartAuthAPI.patch(path, {})
  })

export interface UpdatedSOPaymentTransactionStatus {
  id: number
  amount: number
  status: BaseData
  rejectedMessage?: string
  rejectedAt?: Date
}
