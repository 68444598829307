import { useEffect, useState } from 'react'
import { getMyUserProfileAPI, UserProfile } from '../../api/smartbestbuys-api'
import { getProfileFromLocalStorage, setProfileToLocalStorage } from '../../functions'

const useMyUserProfile = (): [UserProfile | undefined, () => void] => {
  const [myProfile, setMyProfile] = useState<UserProfile | undefined>(undefined)
  const [reload, setReload] = useState(false)

  const forceReload = () => {
    setReload((prev) => !prev)
  }

  useEffect(
    () => {
      ;(async () => {
        const profile = getProfileFromLocalStorage()

        if (profile) {
          setMyProfile(profile)
        } else {
          const response = await getMyUserProfileAPI()
          if (response?.data) {
            setMyProfile(response?.data)
            setProfileToLocalStorage(response?.data)
          }
        }
      })()
    }, // eslint-disable-next-line
    [reload],
  )
  return [myProfile, forceReload]
}

export default useMyUserProfile
