import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { updateUserCriteriaByIdAPI, UpdateUserCriteriaRequestBody, UserProfile } from '../../../api/smartbestbuys-api'
import { UserCriteriaUpdateForm, UserCriteriaUpdateFormValues } from '.'

interface Props extends DialogProps {
  userId: number
  username: string
  onComplete?: (data: UserProfile) => void
}

export const UserCriteriaUpdateDialog = (props: Props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { userId, username, onComplete, onClose, ...dialogProps } = props

  const handleSubmit = async (values: UserCriteriaUpdateFormValues) => {
    const request: UpdateUserCriteriaRequestBody = {
      username: values.username,
      password: values.password,
    }

    try {
      const response = await updateUserCriteriaByIdAPI(userId, request)
      if (response && response.status === 200) {
        triggerSnackbar('แก้ไขข้อมูลสำหรับเข้าสู่ระบบสำเร็จ')
        if (onComplete) {
          onComplete(response.data)
          onClose && onClose({}, 'escapeKeyDown')
        }
      } else {
        triggerSnackbar('แก้ไขข้อมูลสำหรับเข้าสู่ระบบไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('แก้ไขข้อมูลสำหรับเข้าสู่ระบบไม่สำเร็จ')
    }
  }
  return (
    <Dialog {...dialogProps} fullWidth maxWidth="lg">
      <DialogTitle>แก้ไขข้อมูลสำหรับเข้าสู่ระบบ</DialogTitle>
      <DialogContent>
        <UserCriteriaUpdateForm
          username={username}
          onSubmit={handleSubmit}
          onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
        />
      </DialogContent>
    </Dialog>
  )
}
