import { Card, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { Title, FlexBox } from '../../custom-components'
import { NivoColorPalette } from '../palette'
import { StyledTableHeadRow, StyledTableHeadCell } from './StyledComponents'
import { useStyles } from './styles'

interface Column<T> {
  title: string
  key?: keyof T
  render?: (data: T, index: number) => React.ReactNode
}
interface Props<T> {
  title: string
  columns: Column<T>[]
  data: T[]
  color?: NivoColorPalette
}

const TableData = <T extends unknown>(props: Props<T>) => {
  const { title, columns, data, color } = props

  const classes = useStyles(color)()

  return (
    <FlexBox clone flexDirection="column" gridGap={16}>
      <Card className={classes.card}>
        <Title>{title}</Title>
        <Table>
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableHeadCell className={classes.head}>ลำดับ</StyledTableHeadCell>
              {columns.map((col) => (
                <StyledTableHeadCell key={col.title} className={classes.head}>
                  {col.title}
                </StyledTableHeadCell>
              ))}
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {data.map((d, rowIndex) => (
              <TableRow key={`data-${rowIndex + 1}`}>
                <TableCell>{rowIndex + 1}</TableCell>
                {columns.map((col, colIndex) => {
                  const { render, key } = col
                  return (
                    <TableCell key={`data-${rowIndex}-${colIndex + 1}`}>
                      {render ? render(d, rowIndex) : d[key!]}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </FlexBox>
  )
}

export default TableData
