import { Box, Grid, Paper } from '@material-ui/core'
import { IRefundRequisitionProfile } from '../../../api/smartbestbuys-api'
import { FlexBox, SubTopic, TypographyWithLabel } from '../../../custom-components'
import { ImageCard, Space } from '../../../shared-components'

export interface RefundRequisitionProfilePayslipBoxProps {
  profile: IRefundRequisitionProfile
}

const RefundRequisitionProfilePayslipBox = (props: RefundRequisitionProfilePayslipBoxProps) => {
  const {
    profile: { paymentImageUrl, paymentTimestamp, total, smartBankAccount, paymentRemark },
  } = props

  if (!paymentImageUrl || !smartBankAccount) return <></>

  return (
    <Box p={3} clone>
      <Paper>
        <SubTopic>หลักฐานการโอนเงินคืน</SubTopic>
        <Space />
        <Grid container spacing={2}>
          <Grid xs={3}>
            <ImageCard size="big" name="receipt" src={paymentImageUrl} />
          </Grid>
          <Grid xs={9} style={{ height: '100%' }}>
            <FlexBox flexDirection="column" gridGap={16}>
              <TypographyWithLabel label="วัน-เวลาที่โอนเงิน:">{paymentTimestamp}</TypographyWithLabel>
              <TypographyWithLabel label="จำนวนเงิน:">{total}</TypographyWithLabel>
              <TypographyWithLabel label="บัญชีที่โอนคืน:">{`${smartBankAccount.bank.name} ${smartBankAccount.accountNumber} ${smartBankAccount.name}`}</TypographyWithLabel>
              <TypographyWithLabel label="หมายเหตุ:">{paymentRemark}</TypographyWithLabel>
            </FlexBox>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default RefundRequisitionProfilePayslipBox
