import { Grid } from '@material-ui/core'
import { useEffect, useState, useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'

import { Form, Topic, withLayout } from '../../../custom-components'
import { PurchaseRequisitionTopic } from '../../../enums/PurchaseRequisitionEnums'
import { usePurchaseRequisitionUpdateData } from '../../../hooks'
import { Loading, SubmitForm } from '../../../shared-components'
import { SourceFile } from '../../../types'
import { AttachmentSelectionBox } from '../create/AttachmentSelectionBox'
import { PurchaseProductInfo } from '../create/PurchaseProductInfo'
import PurchaseProductSelector from '../create/PurchaseProductSelector/PurchaseProductSelector'
import PurchaseRequisitionCreateFormValues from '../create/PurchaseRequisitionCreateFormValue'
import SelectedPurchaseProductTable from '../create/SelectedPurchaseProductTable'
import { VendorBox } from '../create/VendorBox'

import { mapPurchaseRequisitionDataToFormValues } from './mapper'
import PRConfirmUpdateDialog from './PRConfirmUpdateDialog'

interface Props {
  id: number
}

const PurchaseRequisitionUpdatePage = (props: Props) => {
  const { id } = props

  const { triggerSnackbar } = useContext(RootContext)
  const updatePurchaseRequisitionData = usePurchaseRequisitionUpdateData(id)

  const formMethod = useForm<PurchaseRequisitionCreateFormValues>({
    defaultValues: {
      products: [],
      currencyId: 1,
    },
  })

  const {
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = formMethod

  const { currencyId, attachments } = watch()

  const [confirmOpen, setConfirmOpen] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (!!updatePurchaseRequisitionData) {
      reset(mapPurchaseRequisitionDataToFormValues(updatePurchaseRequisitionData))
    }
  }, [reset, updatePurchaseRequisitionData])

  if (!updatePurchaseRequisitionData) return <Loading />

  const rop = updatePurchaseRequisitionData.purchaseRequisitionInfo.topic.id === PurchaseRequisitionTopic.ReStock

  const handleAddAttachmentsToForm = (attachments: SourceFile[]) => {
    setValue('attachments', attachments)
  }

  const existCodeLists = updatePurchaseRequisitionData.products
    .filter((product) => !!product.code)
    .map((product) => product.code!)

  return (
    <>
      <FormProvider {...formMethod}>
        <PRConfirmUpdateDialog
          purchaseRequisitionId={id}
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onComplete={() => {
            setConfirmOpen(false)
            triggerSnackbar(`บันทึกคำขอซื้อสำเร็จ`)
            history.replace(`/purchase-requisitions/${id}/profile`)
          }}
          onFail={() => {
            setConfirmOpen(false)
            triggerSnackbar(`บันทึกคำขอซื้อไม่สำเร็จ`)
          }}
        />
        <Form onSubmit={() => setConfirmOpen(true)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Topic>แก้ไขคำขอซื้อสำหรับซื้อสินค้าภายใน</Topic>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <PurchaseProductInfo
                    purchaseRequisitionTopics={updatePurchaseRequisitionData.purchaseRequisitionTopics}
                    currencies={updatePurchaseRequisitionData.currencies}
                    isPRRefFromSaleOrder={!!updatePurchaseRequisitionData.saleOrderInfo}
                    rop={rop}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VendorBox vendors={updatePurchaseRequisitionData.vendors} />
                </Grid>
                <Grid item xs={12}>
                  <PurchaseProductSelector
                    productData={updatePurchaseRequisitionData.products}
                    saleOrderProductsData={updatePurchaseRequisitionData.soProducts}
                    rop={rop}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectedPurchaseProductTable
                    existingProductCodeList={existCodeLists}
                    currency={
                      updatePurchaseRequisitionData.currencies.find((currency) => currency.id === currencyId)?.name
                    }
                    rop={rop}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AttachmentSelectionBox onChange={handleAddAttachmentsToForm} defaultValue={attachments} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SubmitForm
                submitText="บันทึกคำขอซื้อ"
                cancelText="ยกเลิก"
                redirectPath={`/purchase-requisitions/${id}/profile`}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </>
  )
}

export default withLayout(PurchaseRequisitionUpdatePage)
