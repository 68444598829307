import { useState, useEffect } from 'react'
import { endOfYear, format, startOfYear } from 'date-fns'
import { ISalesMonthlyReport, getReportSalesMonthlyDailyAPI } from '../../api/smartbestbuys-api'

export const useReportSalesMonthlyDaily = (date: Date): ISalesMonthlyReport | undefined => {
  const [data, setData] = useState<ISalesMonthlyReport | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getReportSalesMonthlyDailyAPI({
        startMonthYear: format(startOfYear(date), 'yyyy-MM'),
        endMonthYear: format(endOfYear(date), 'yyyy-MM'),
      })
      if (response) {
        setData(response?.data)
      }
    })()
  }, [date])

  return data
}
