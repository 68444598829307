import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers'
import React from 'react'
import { RegisterOptions, useController } from 'react-hook-form'

type Props = Omit<KeyboardDateTimePickerProps, 'onChange'> & {
  control: any
  // TODO: refactor
  rules?: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  gutterTop?: boolean
}

const KeyboardDateTimePickerForm: React.FC<Props> = (props) => {
  const { control, name, defaultValue, rules, gutterTop, style, ...keyboardDateProps } = props
  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name: name as any,
    control,
    defaultValue: defaultValue,
    rules,
  })

  return (
    <KeyboardDateTimePicker
      style={{
        marginTop: gutterTop ? 8 : undefined,
        ...(style ?? {}),
      }}
      inputVariant="outlined"
      format="dd/MM/yyyy HH:mm"
      invalidDateMessage="วันที่ไม่ถูกต้อง"
      inputRef={ref}
      {...keyboardDateProps}
      {...inputProps}
    />
  )
}

export default KeyboardDateTimePickerForm
