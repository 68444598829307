import { PaymentRequisitionStatus, PaymentRequisitionStatusGroup } from '../enums'

export const getPaymentRequisitionStatusByGroup = (statusGroup: PaymentRequisitionStatusGroup): number[] => {
  switch (statusGroup) {
    case PaymentRequisitionStatusGroup.Pending:
      return [PaymentRequisitionStatus.PendingReview]
    case PaymentRequisitionStatusGroup.Approved:
      return [
        PaymentRequisitionStatus.PendingTransfer,
        PaymentRequisitionStatus.PendingCheque,
        PaymentRequisitionStatus.PendingReceipt,
        PaymentRequisitionStatus.PendingStatement,
        PaymentRequisitionStatus.Success,
      ]
    case PaymentRequisitionStatusGroup.Rejected:
      return [PaymentRequisitionStatus.Rejected]
    case PaymentRequisitionStatusGroup.Canceled:
      return [PaymentRequisitionStatus.Canceled]
    default:
      return []
  }
}
