import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import { FlexBox, Link, SubTopic } from '../../../custom-components'
import { ArrowForward } from '@material-ui/icons'
import { TransportTaskProfilePageContext } from './TransportTaskProfilePage'

const SaleOrderReferenceBox: React.FC = (props) => {
  // context
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const { saleOrderInfo } = transportTaskProfile

  // render
  // add TODO: develop to support canceled case
  return (
    <FlexBox p={3} justifyContent="space-between" alignItems="center" clone>
      <Paper>
        <SubTopic>ใบสั่งขายอ้างอิง {saleOrderInfo?.code}</SubTopic>
        <Link color="primary" to={`/sale-orders/${saleOrderInfo?.id}`} target="_blank">
          <span className="mr-2">ไปที่หน้ารายละเอียดใบสั่งขาย</span> <ArrowForward fontSize="small" />
        </Link>
      </Paper>
    </FlexBox>
  )
}

export default SaleOrderReferenceBox
