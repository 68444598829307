import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import { IRefundRequisitionTableData } from '../../../api/smartbestbuys-api'
import { FilteredTable, Link, Topic, withLayout } from '../../../custom-components'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { PageName, RefundStatus } from '../../../enums'
import { dateTimeFormat } from '../../../functions'
import { isBefore } from 'date-fns'
import { useRefundRequisitions } from '../../../hooks/finance'
import { RefundRequisitionStatusChip } from '../../../shared-components'

const RefundRequisitionsListPage = () => {
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.FINANCE_REFUND_REQUISITION_LIST)

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [tabValue, setTabValue] = useState(RefundStatus.Pending)

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const refundRequisitions = useRefundRequisitions(startDate, endDate)

  const sortedRefundRequisitions = useMemo(() => {
    const filteredRefundRequisitions = refundRequisitions.filter((data) => data.status.id === tabValue)
    if (tabValue === RefundStatus.Pending) {
      return filteredRefundRequisitions.sort(
        (a: IRefundRequisitionTableData, b: IRefundRequisitionTableData): number => {
          if (!!a.recCreatedAt && !!b.recCreatedAt) {
            const aDate = new Date(a.recCreatedAt)
            const bDate = new Date(b.recCreatedAt)
            return isBefore(aDate, bDate) ? 1 : -1
          }
          return 1
        },
      )
    }
    return filteredRefundRequisitions
    // eslint-disable-next-line
  }, [tabValue, refundRequisitions.map((t) => t.code).toString()])

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Topic>รายการคืนเงิน</Topic>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<IRefundRequisitionTableData>
              color="primary.dark"
              columns={[
                {
                  name: 'สถานะการคืนเงิน',
                  dataIndex: 'status',
                  render: (record) => {
                    return <RefundRequisitionStatusChip status={record.status.id} label={record.status.name} />
                  },
                },
                {
                  name: 'เลขที่การคืนเงิน',
                  dataIndex: 'id',
                  render: (record) => (
                    <Link color="primary" to={`/finance/refund-requisitions/${record.id}/profile`}>
                      <Typography variant="body2">{record.code}</Typography>
                    </Link>
                  ),
                },
                {
                  name: 'ชื่อลูกค้า',
                  dataIndex: 'customer',
                  render: ({ customer }) => customer.name,
                },
                {
                  name: 'เบอร์โทรศัพท์ลูกค้า',
                  dataIndex: 'customer',
                  render: ({ customer }) => customer.phoneNumber,
                },
                {
                  name: 'ยอดที่ต้องคืน',
                  dataIndex: 'total',
                  numeric: true,
                  format: '0,0.00',
                },
                {
                  name: 'วันที่สร้าง',
                  render: (record) => dateTimeFormat(record.recCreatedAt),
                },
              ]}
              sortable
              data={sortedRefundRequisitions}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              tabOption={{
                tabs: [
                  {
                    label: `รอตรวจสอบ (${
                      refundRequisitions.filter((data) => data.status.id === RefundStatus.Pending).length
                    })`,
                    value: RefundStatus.Pending,
                  },
                  {
                    label: `อนุมัติและรอคืนเงิน (${
                      refundRequisitions.filter((data) => data.status.id === RefundStatus.Approve).length
                    })`,
                    value: RefundStatus.Approve,
                  },
                  { label: 'คืนเงินเสร็จสิ้น', value: RefundStatus.Done },
                  { label: 'ไม่อนุมัติ', value: RefundStatus.Failed },
                  { label: 'ยกเลิก', value: RefundStatus.Canceled },
                ],
                value: tabValue,
                onChange: handleTabChange,
              }}
              cleanSpace
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(RefundRequisitionsListPage, true)
