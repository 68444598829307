import { Box, Grid, Paper, Table, TableCell, TableRow, Typography } from '@material-ui/core'
import ProductProfileContext from '../ProductProfileContext'
import { useContext } from 'react'
import { Photo } from '@material-ui/icons'
import { Avatar, FlexBox, Link } from '../../../../../custom-components'
import { formatNumber } from '../../../../../functions/number-utils'

const ProductProfileTopCoSalesProduct: React.FC = (props) => {
  const { productProfile } = useContext(ProductProfileContext)
  const { topCoSalesProduct } = productProfile
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            สินค้าที่ถูกขายควบคู่ด้วยเยอะที่สุด 3 อันดับแรกภายใน 1 ปี
          </Typography>
        </Grid>
        <div className="mb-8" />
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {topCoSalesProduct.map((product) => (
              <Grid key={product.id} item xs={12} md={4}>
                <Box display="flex" alignItems="flex-start" className="ml-4">
                  <Avatar variant="rounded" src={product.imageUrl} objectFit="contain">
                    <Photo />
                  </Avatar>
                  {
                    <FlexBox ml={1} flexDirection="column">
                      <Link color="primary" to={`/products/${product.id}/profile`}>
                        {product.code}
                      </Link>

                      <Typography variant="body2">{product.nameLocal}</Typography>
                    </FlexBox>
                  }
                </Box>

                <Table width="100%" className="mt-2">
                  <TableRow>
                    <TableCell>จำนวนขายรวม</TableCell>
                    <TableCell>{formatNumber(product.totaAmount)}</TableCell>
                    <TableCell>{product.unitLocal ?? '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ราคาเฉลี่ย</TableCell>
                    <TableCell>{formatNumber(product.averagePrice)}</TableCell>
                    <TableCell>บาท</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ยอดขายรวม</TableCell>
                    <TableCell>{formatNumber(product.salesProductTotal)}</TableCell>
                    <TableCell>บาท</TableCell>
                  </TableRow>
                </Table>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductProfileTopCoSalesProduct
