import { Grid } from '@material-ui/core'
import { Loading, SubmitForm } from '../../../shared-components'
import CustomerRequisitionProductForm from './CustomerRequisitionProductForm'
import CustomerRequisitionDetailForm from './CutomerRequisitionDetailForm'
import { CustomerRequisitionFormValues } from '.'
import { useMemo } from 'react'
import AttachmentInputBox from '../../../shared-components/AttachmentInputBox'
import { SourceFile } from '../../../types'

interface Props {
  isSubmitting?: boolean
  defaultValues?: CustomerRequisitionFormValues
  onChange?: (data: SourceFile[]) => void
}

const CustomerRequisitionForm: React.FC<Props> = (props) => {
  const { isSubmitting, defaultValues, onChange } = props

  const isUpdateMode = useMemo(
    () => !!defaultValues,
    // eslint-disable-next-line
    [!!defaultValues],
  )

  if (isUpdateMode && !defaultValues) return <Loading />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomerRequisitionDetailForm defaultValues={defaultValues} />
      </Grid>
      <Grid item xs={12}>
        <CustomerRequisitionProductForm defaultValues={defaultValues} />
      </Grid>
      <Grid item xs={12}>
        <AttachmentInputBox onChange={onChange} defaultValues={defaultValues?.attachments} limit={2} />
      </Grid>
      <Grid item xs={12}>
        <SubmitForm
          goBack
          submitText={isUpdateMode ? 'บันทึกคำร้องของลูกค้า' : 'ยืนยันสร้างคำร้องของลูกค้า'}
          cancelText="ย้อนกลับ"
          isSubmitting={isSubmitting}
        />
      </Grid>
    </Grid>
  )
}

export default CustomerRequisitionForm
