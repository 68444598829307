import { BaseContactDomain, BaseCustomerDomain, BaseCustomerProfileDomain } from '.'
import { ICustomerProfile_Address } from '../../api/smartbestbuys-api'

export const mapCustomerProfileDomain = (
  baseCustomerDomain: BaseCustomerDomain,
  billingAddresses?: Array<ICustomerProfile_Address>,
  baseContactDomains?: Array<BaseContactDomain>,
): BaseCustomerProfileDomain => {
  return {
    ...baseCustomerDomain,
    contacts: baseContactDomains
      ? baseContactDomains.map((it) => {
          return {
            ...it,
          }
        })
      : undefined,
    billingAddresses: billingAddresses
      ? billingAddresses.map((it) => {
          return {
            ...it,
          }
        })
      : undefined,
  }
}
