import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { endOfDay, startOfDay } from 'date-fns'
import {
  FetchRequest,
  BaseData,
  DocumentCustomerInfo,
  DocumentSaleOrderInfo,
  ICustomerAddressOverrideDocumentState,
} from '..'
import { PriceBreakdownItem, ReferenceDocument } from './types'

const path = `/documents/tax-invoices`

export const fetchDocumentTaxInvoices = errorHandler<TaxInvoiceTableData[]>(async (request: FetchRequest) => {
  return await smartAuthAPI.get(path, {
    params: {
      startDate: startOfDay(request.startDate).toISOString(),
      endDate: endOfDay(request.endDate).toISOString(),
    },
  })
})

export interface TaxInvoiceTableData {
  id: number
  code: string
  type: BaseData
  status: BaseData
  referenceDocument?: ReferenceDocument
  content?: TaxInvoiceContent
  state?: TaxInvoiceState
  customerInfo?: TaxInvoiceTableData_CustomerInfo
  billingAddress?: {
    branchName?: string
    overrideDocumentData?: ICustomerAddressOverrideDocumentState
  }
  saleOrderInfo?: TaxInvoiceTableData_SaleOrderInfo
  total?: number
  isDeposit?: boolean
  recCreatedAt: Date
  recCreatedBy: string
  recModifiedAt?: Date
  recModifiedBy?: string
  refReceipts: TaxInvoiceTableData_MiniDocumentInfo[]
  refBillingNotes: TaxInvoiceTableData_MiniDocumentInfo[]
  refSaleOrderPaymentInfo?: TaxInvoiceTableData_RefSaleOrderPaymentInfo
}

export interface TaxInvoiceTableData_CustomerInfo {
  id?: number
  name: string
  referenceNumber?: string
  phoneNumber: string
  email?: string
  creditPeriodDay?: number
}

export interface TaxInvoiceTableData_SaleOrderInfo {
  id?: number
  code: string
  customerPurchaseReference?: string
  createdAt: Date
  paymentStatus?: BaseData
  status?: BaseData
  vatIncluded: boolean
}

export interface TaxInvoiceTableData_MiniDocumentInfo {
  id: number
  code: string
  status: BaseData
  type: BaseData
}

export interface TaxInvoiceTableData_RefSaleOrderPaymentInfo {
  id: number
  code: string
  amount: number
}

export interface DocumentTaxInvoice {
  id: number
  code: string
  type: BaseData
  status: BaseData
  saleOrderId?: number
  referenceDocumentId?: number
  content?: TaxInvoiceContent
  state?: TaxInvoiceState
  customerInfo?: DocumentCustomerInfo
  saleOrderInfo?: DocumentSaleOrderInfo
  total?: number
  recCreatedAt: Date
  recCreatedBy: string
  recModifiedAt?: Date
  recModifiedBy?: string

  refReceipts: MiniDocumentInfo[]
  refBillingNotes: MiniDocumentInfo[]
}

export interface MiniDocumentInfo {
  id: number
  code: string
  status: BaseData
  type: BaseData
}

export interface TaxInvoiceContent {
  customerInfo: DocumentCustomerInfo
  saleOrderInfo: DocumentSaleOrderInfo
  items: TaxInvoiceItem[]
  priceBreakdown: TaxInvoicePriceBreakdown
}

export interface TaxInvoicePriceBreakdown {
  itemTotal: number
  discountAmount: number
  totalIncludingDiscount: number
  depositAmount: number
  totalWithoutDeposit: number
  vatAmount: number
  total: number
}

export interface TaxInvoiceState {
  expiredDate?: Date
  items: TaxInvoiceItem[]
  priceBreakdowns: PriceBreakdownItem[]
  remark?: string
}

export interface TaxInvoiceItem {
  id: number
  name: string
  code?: string
  imageUrl?: string // option
  amount: number
  pricePerEach: number
  total: number
}
