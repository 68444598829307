import React, { useContext } from 'react'
import { Grid, Button, TextField, Chip } from '@material-ui/core'
import { withLayout, Topic, Link, FlexBox, Table, GeneralTextField, TypographyWithLabel } from '../../custom-components'
import { IProductROPTableData_Data } from '../../api/smartbestbuys-api'
import { Avatar } from '../../custom-components'
import { Add, Photo } from '@material-ui/icons'
import { Typography, Box } from '@material-ui/core'
import { RootContext } from '../..'
import { NavigationItemKey, PageName, ProductGrade } from '../../enums'
import { Loading, NotificationHead, ProductGradeChip } from '../../shared-components'
import { getUserPermissionInfo } from '../../utils/permission'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { Autocomplete } from '@material-ui/lab'
import { formatNumber, formatNumberInt } from '../../functions/number-utils'
import { useProductROPTableData } from '../../hooks/product'
import { dateFormat } from '../../functions'
import { productGrades } from '../../constants'

const ProductWithROPTableDataPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PRODUCT_REORDER_POINT)

  const tableData = useProductROPTableData()

  if (!tableData) return <Loading />
  const {
    data,
    paginationInfo,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    isLoading,
  } = tableData

  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Write)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการสินค้าที่ควรซื้อเพิ่ม</Topic>
            {writePermission.hasPermission && (
              <Link href="/products/pending-to-purchase/create-purchase-requisition" target="_blank">
                <Button variant="contained" color="primary" startIcon={<Add />}>
                  สร้างใบขอซื้อ
                </Button>
              </Link>
            )}
          </FlexBox>
          <NotificationHead navigationKey={NavigationItemKey.ReorderPoint} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="name"
                  onChange={(e) => handleSearchParamChange('name', e.target.value)}
                  label="ชื่อ"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="code"
                  onChange={(e) => handleSearchParamChange('code', e.target.value)}
                  label="รหัสสินค้า"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  size="small"
                  options={productGrades.map((option) => option.value)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange('gradeList', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="กลุ่มสินค้า"
                      placeholder="กลุ่มสินค้า"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<IProductROPTableData_Data>
            color="primary.dark"
            columns={[
              // {
              //   name: '',
              //   render: ({ storage }) => !storage && <NoProductStorageAlertSignal />,
              // },
              {
                name: 'รายละเอียดสินค้า',
                dataIndex: 'nameLocal',
                render: ({ imageUrl, nameLocal, nameEn, id, code, grade }) => (
                  <Box display="flex" alignItems="flex-start">
                    <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                      <Photo />
                    </Avatar>
                    {
                      <FlexBox ml={1} flexDirection="column">
                        <Link color="primary" to={`/products/${id}/profile`}>
                          {code} {grade && <ProductGradeChip grade={grade as ProductGrade} size="small" />}
                        </Link>

                        <Typography variant="body2">{nameLocal}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {nameEn}
                        </Typography>
                      </FlexBox>
                    }
                  </Box>
                ),
                fixedWidth: 300,
              },
              {
                name: 'ข้อมูลคลังสินค้า',
                render: ({
                  stockAmount,
                  reservedAmount,
                  soReservedAmount,
                  readyToSellAmount,
                  incomingAmount,
                  quotationProductAmount,
                }) => {
                  const stockAvailableSaleColor = readyToSellAmount > 0 ? 'success.main' : 'error.main'

                  return (
                    <FlexBox flexDirection="column">
                      <TypographyWithLabel label="จำนวนพร้อมขาย:">
                        <Box color={stockAvailableSaleColor} clone>
                          <Typography variant="body2">{formatNumberInt(Math.max(readyToSellAmount, 0))}</Typography>
                        </Box>
                      </TypographyWithLabel>
                      <TypographyWithLabel label="จำนวนในคลัง:">
                        {formatNumberInt(Math.max(stockAmount - reservedAmount, 0))}
                      </TypographyWithLabel>
                      <TypographyWithLabel label="จำนวนจอง:">
                        {formatNumberInt(Math.max(soReservedAmount, 0))}
                      </TypographyWithLabel>
                      <TypographyWithLabel label="จำนวนที่กำลังเข้า:">
                        {formatNumberInt(Math.max(incomingAmount, 0))}
                      </TypographyWithLabel>
                      <TypographyWithLabel label="จำนวนที่เสนอราคา:">
                        {formatNumberInt(Math.max(quotationProductAmount, 0))}
                      </TypographyWithLabel>
                    </FlexBox>
                  )
                },
                fixedWidth: 200,
              },
              {
                name: 'ข้อมูลสั่งซื้อ',
                render: ({ reorderPoint, purchaseAmount, unitLocal, leadTime }) => (
                  <FlexBox flexDirection="column">
                    <TypographyWithLabel label="จุดที่ต้องซื้อ:">
                      <Box color="error.main" clone>
                        <Typography variant="body2">{formatNumber(Math.max(reorderPoint, 0))}</Typography>
                      </Box>
                    </TypographyWithLabel>
                    <TypographyWithLabel label="ปริมาณการซื้อต่อครั้ง:">
                      {formatNumberInt(Math.max(purchaseAmount, 0))} {unitLocal}
                    </TypographyWithLabel>
                    <TypographyWithLabel label="lead time:">
                      {formatNumberInt(Math.max(leadTime, 0))} วัน
                    </TypographyWithLabel>
                  </FlexBox>
                ),
                fixedWidth: 300,
              },
              {
                name: 'ข้อมูลเพิ่มเติม',
                render: ({
                  averageSaleAmount,
                  safetyStock,
                  safetyStockPolicy,
                  maxStockAmount,
                  unitLocal,
                  createQTWithInsufficientStockRate,
                  createSOWithInsufficientStockRate,
                }) => (
                  <FlexBox flexDirection="column">
                    <TypographyWithLabel label="ยอดขายเฉลี่ยต่อวัน:">
                      <Typography variant="body2" className="font-bold">
                        {formatNumber(Math.max(averageSaleAmount, 0))}
                      </Typography>
                    </TypographyWithLabel>
                    <TypographyWithLabel label="Safety Stock:">
                      {formatNumberInt(Math.max(safetyStock, 0))} {unitLocal}
                    </TypographyWithLabel>
                    <TypographyWithLabel label="นโยบายการเก็บ:">
                      {formatNumberInt(Math.max(safetyStockPolicy, 0))} วัน
                    </TypographyWithLabel>
                    <TypographyWithLabel label="ค่าสูงสุดในคลัง:">
                      {formatNumberInt(Math.max(maxStockAmount, 0))}
                    </TypographyWithLabel>

                    {createQTWithInsufficientStockRate !== undefined && (
                      <TypographyWithLabel label="อัตราสินค้าขาดสต็อก QT:">
                        {formatNumber(Math.max(createQTWithInsufficientStockRate * 100, 0))}%
                      </TypographyWithLabel>
                    )}
                    {createSOWithInsufficientStockRate !== undefined && (
                      <TypographyWithLabel label="อัตราสินค้าขาดสต็อก SO:">
                        {formatNumber(Math.max(createSOWithInsufficientStockRate * 100, 0))}%
                      </TypographyWithLabel>
                    )}
                  </FlexBox>
                ),
                fixedWidth: 300,
              },
              {
                name: 'หมดแน่ๆวันที่',
                fixedWidth: 200,
                render: ({ estimateOutOfStockDate, averageSaleAmount }) => {
                  let element
                  if (averageSaleAmount === 0) {
                    element = (
                      <Box color="error.main" clone>
                        <Typography variant="body2">มีโอกาส deadstock สูง</Typography>
                      </Box>
                    )
                  } else if (estimateOutOfStockDate) {
                    element = <Typography variant="body2">{dateFormat(new Date(estimateOutOfStockDate))}</Typography>
                  } else {
                    element = '-'
                  }

                  return element
                },
              },
            ]}
            data={data}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(ProductWithROPTableDataPage)
