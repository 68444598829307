import React, { useState, useContext, useEffect } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, FlexBox, Link } from '../../../custom-components'
import { DocumentCancellationDialog, DocumentStatusChip } from '../../../shared-components'
import { defaultStartDate, defaultEndDate } from '../../../data'
import { useDocumentCreditNotes } from '../../../hooks'
import { dateTimeFormat } from '../../../functions/date-formatter'
import { calculateExcludingVat } from '../../../functions/price-calculator'
import { RootContext } from '../../..'
import { DocumentStatus, PageName } from '../../../enums'
import { DocumentCreditNote } from '../../../api/smartbestbuys-api'
import { Cancel } from '@material-ui/icons'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import { formatNumber } from '../../../functions/number-utils'
import SaleOrderProfileContext from '../../sale-orders/id/SaleOrderProfilePage/SaleOrderProfileContext'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const DocumentCreditNotePage: React.FC = (props) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const { reload } = useContext(SaleOrderProfileContext)
  setCurrentPage(PageName.CREDIT_NOTE)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [idToCancelId, setIdToCancel] = useState<number | undefined>(undefined)
  const creditNotes = useDocumentCreditNotes(startDate, endDate)

  const [data, setData] = useState<DocumentCreditNote[]>([])

  useEffect(() => {
    if (creditNotes.length > 0) {
      setData(creditNotes)
    }
  }, [creditNotes])

  // permission
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>รายการใบลดหนี้</Topic>
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<DocumentCreditNote>
          color="primary.dark"
          columns={[
            {
              name: 'สถานะใบลดหนี้',
              render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
              csvRender: ({ status: { name } }) => name,
            },
            {
              name: 'เลขใบลดหนี้',
              render: ({ id, code }) => (
                <Link color="primary" to={`/credit-notes/${id}/pdf`} target="_blank">
                  {code}
                </Link>
              ),
              csvRender: ({ code }) => code,
            },
            {
              name: 'เลขใบกำกับภาษีอ้างอิง',
              render: (taxInvoice) => {
                const { state, referenceDocument } = taxInvoice
                const { taxInvoiceReferences } = { ...state }
                const { id, code } = { ...referenceDocument }

                if (id && code) {
                  return (
                    <Link color="secondary" to={`/tax-invoices/${id}/pdf`} target="_blank">
                      {code}
                    </Link>
                  )
                }

                return taxInvoiceReferences?.join(',')
              },
              csvRender: (taxInvoice) => {
                const { state, referenceDocument } = taxInvoice
                const { taxInvoiceReferences } = { ...state }
                const { id, code } = { ...referenceDocument }

                if (id && code) {
                  return code
                }

                return taxInvoiceReferences?.join(',')
              },
            },
            {
              name: 'ชื่อลูกค้า',
              render: ({ customerInfo }) => customerInfo?.name,
              csvRender: ({ customerInfo }) => customerInfo?.name,
            },
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              csvRender: ({ recCreatedAt }) =>
                format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
            },
            {
              name: 'ยอดก่อน VAT',
              render: (taxInvoice) =>
                taxInvoice.total ? calculateExcludingVat(taxInvoice.total) : 'ไม่สามารถคำนวณได้',
              numeric: true,
              format: '0,0.00',
              csvRender: (taxInvoice) =>
                taxInvoice.total ? formatNumber(calculateExcludingVat(taxInvoice.total)) : '--',
            },
            {
              name: 'VAT',
              render: (taxInvoice) => {
                const { total } = taxInvoice
                const vatFreeAmount = calculateExcludingVat(total || 0)
                return (total || 0) - vatFreeAmount
              },
              numeric: true,
              format: '0,0.00',
              csvRender: ({ total }) => {
                const vatFreeAmount = calculateExcludingVat(total ?? 0)
                return formatNumber((total ?? 0) - vatFreeAmount)
              },
            },
            {
              name: 'ยอดเงินสุทธิ',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
              csvRender: ({ total }) => formatNumber(total ?? 0),
            },
            {
              name: '',
              csvRender: () => {},
              render: (taxInvoice) => {
                // TODO: support issued credit note and credit note dialog
                const isCancelled = taxInvoice.status.id === DocumentStatus.Cancelled
                if (isCancelled) {
                  return <></>
                }

                return (
                  <>
                    <DocumentCancellationDialog
                      documentInfo={taxInvoice}
                      open={idToCancelId === taxInvoice.id}
                      onClose={() => {
                        setIdToCancel(undefined)
                      }}
                      onSuccess={(updatedData) => {
                        triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
                        reload()
                        let newData = data
                        const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                        if (findIndex !== -1) {
                          const found = newData[findIndex]
                          const updated = {
                            ...found,
                            ...updatedData,
                          }
                          newData[findIndex] = updated
                          setData([...newData])
                        }
                      }}
                      onFail={() => {
                        triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
                      }}
                    />
                    {cancelPermission.hasPermission && (
                      <FlexBox gridGap={8}>
                        <IconButton size="small" onClick={() => setIdToCancel(taxInvoice.id)}>
                          <Cancel fontSize="small" />
                        </IconButton>
                      </FlexBox>
                    )}
                  </>
                )
              },
            },
          ]}
          data={data}
          defaultDateRage={{
            startDate: defaultStartDate,
            endDate: defaultEndDate,
          }}
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          size="small"
          csvFilename={`รายการใบลดหนี้_${format(new Date(), 'dd_MMM_yyyy', { locale: thLocale })}.csv`}
          downloadAsCsv
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(DocumentCreditNotePage)
