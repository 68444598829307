import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { ICustomerProfile } from './types'

export interface AddCustomerWhitelistRequest {
  remark: string
}

export const addCustomerWhitelistApi = errorSingleHandler<ICustomerProfile>(
  (id: number, request: AddCustomerWhitelistRequest) => {
    const path = `customers/${id}/whitelist`
    return smartAuthAPI.post(path, request)
  },
)
