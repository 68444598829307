import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { purchaseOrderTakeNoteAPI } from '../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm } from '../../../custom-components'
import { Loading } from '../../../shared-components'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'

interface Props extends DialogProps {
  onSuccess: () => void
  onFail: () => void
}

interface PurchaseOrderTakeNoteFormValues {
  remark: string
}

const PurchaseOrderTakeNoteDialog: React.FC<Props> = (props) => {
  const { onClose, onSuccess, onFail } = props
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)

  const formMethods = useForm<PurchaseOrderTakeNoteFormValues>()
  const {
    control,
    formState: { isSubmitting },
  } = formMethods

  if (!purchaseOrderProfile) return <Loading />

  const onSubmit = async (values: PurchaseOrderTakeNoteFormValues) => {
    const response = await purchaseOrderTakeNoteAPI(purchaseOrderProfile.id, { remark: values.remark })
    if (response?.status === 200) {
      onSuccess()
    } else {
      onFail()
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <FormProvider {...formMethods}>
        <Form onSubmit={onSubmit}>
          <DialogTitle>บันทึกใบสั่งซื้อ</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GeneralTextFieldForm
                  control={control}
                  name="remark"
                  label="รายละเอียด *"
                  rules={{ required: { value: true, message: 'กรุณาใส่รายละเอียด' } }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => onClose && onClose({}, 'backdropClick')}>
              ยกเลิก
            </Button>
            <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
              ยืนยัน
            </Button>
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

export default PurchaseOrderTakeNoteDialog
