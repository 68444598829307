import numeral from 'numeral'

export const formatNumber = (num: number) => numeral(num).format('0,0.00')
export const formatNumberInt = (num: number) => numeral(num).format('0,0')

// deprecated
export const roundNumber = (num: number, rawDecimalPoints?: number): number => {
  const decimalPoints = rawDecimalPoints || 2
  const x = num * Math.pow(10, decimalPoints)
  const rounded = Math.round(x)
  return rounded / Math.pow(10, decimalPoints)
}

export const toDecimal = (num: number, decimal?: number) => parseFloat(num.toFixed(decimal ?? 2))

export const calculatePercent = (num0: number, num1: number) => formatNumber(((num0 - num1) / num0) * 100).concat('%')
