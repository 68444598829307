import React, { useContext } from 'react'
import { Avatar, Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import { FlexBox, SubTopic, Table, TColumn } from '../../../custom-components'
import { Photo } from '@material-ui/icons'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import { PurchaseOrderProfile_Product } from '../../../api/smartbestbuys-api/purchase-order/types'
import numeral from 'numeral'

import './PurchaseOrderProfileProductsTable.less'
import { formatNumber } from '../../../functions/number-utils'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

interface Props {
  hideSalePrice?: boolean
}

const PurchaseOrderProfileProductsTable: React.FC<Props> = (props: Props) => {
  // props
  const { hideSalePrice } = props

  // context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { products, total, productTotal, discountAmount, vatIncluded } = purchaseOrderProfile

  // calculation
  const exactDiscountAmount = discountAmount ?? 0
  const sameTotal = productTotal === total
  const priceBreakdowns = [
    { label: 'รวมเงิน', value: sameTotal ? 0 : productTotal },
    { label: 'ส่วนลด', value: exactDiscountAmount },
    { label: 'ภาษีมูลค่าเพิ่ม 7 %', value: vatIncluded ? total - productTotal - exactDiscountAmount : 0 },
    { label: 'ยอดเงินสุทธิ', value: total },
  ]

  const salePriceColumns = hideSalePrice
    ? []
    : [
        {
          name: 'ราคาที่เราขายต่อหน่วย',
          render: ({ salePrice }) => (!!salePrice ? formatNumber(salePrice) : '-'),
          align: 'right',
        } as TColumn<PurchaseOrderProfile_Product>,
      ]

  // permission
  const pricingPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Pricing)

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ข้อมูลสินค้า/บริการ</SubTopic>
        <div className="mb-4" />
        <Table<PurchaseOrderProfile_Product>
          color="primary.dark"
          columns={[
            ...([
              { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
              {
                name: 'ชื่อสินค้า/บริการ',
                dataIndex: 'name',
                render: (product) => (
                  <div className="purchase-order-products-table__product-cell" key={'product-' + product.id}>
                    <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                      <Photo />
                    </Avatar>
                    <div className="purchase-order-products-table__product-cell__info">
                      <Typography color="textSecondary">{product.code}</Typography>
                      <Typography>{product.name}</Typography>
                    </div>
                  </div>
                ),
              },
            ] as TColumn<PurchaseOrderProfile_Product>[]),
            ...salePriceColumns,
            ...([
              { name: 'จำนวน', dataIndex: 'amount', numeric: true, format: '0,0' },
              {
                name: 'ราคาต่อหน่วย',
                dataIndex: 'price',
                numeric: true,
                format: '0,0.00',
                hide: !pricingPermission.hasPermission,
              },
              {
                name: 'ราคารวม',
                render: (product) => product.amount * product.price,
                numeric: true,
                format: '0,0.00',
                hide: !pricingPermission.hasPermission,
              },
            ] as TColumn<PurchaseOrderProfile_Product>[]),
          ]}
          data={products}
          postCells={
            <>
              {pricingPermission.hasPermission &&
                priceBreakdowns
                  .filter((item) => item.value > 0)
                  .map((item) => (
                    <TableRow>
                      <TableCell colSpan={hideSalePrice ? 3 : 4} />
                      <TableCell align="right">{item.label}</TableCell>
                      <TableCell align="right">{numeral(item.value).format('0,0.00')}</TableCell>
                    </TableRow>
                  ))}
            </>
          }
          pagination={false}
          cleanSpace
        />
      </Paper>
    </FlexBox>
  )
}

export default PurchaseOrderProfileProductsTable
