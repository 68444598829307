export interface SourceFile {
  id?: number
  base64?: string
  url?: string
  name: string
  type: FileType
}

export enum FileType {
  Image = 0,
  PDF = 1,
}
