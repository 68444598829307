import { BaseData } from '../api/smartbestbuys-api'
import { ProductType } from '../enums'

export const productTypes: BaseData[] = [
  { id: ProductType.Product, name: 'สินค้า' },
  { id: ProductType.Service, name: 'บริการ' },
]

export const productGrades = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
]
