import { declineSOPayment, cancelSOPayment, UserActivityInfoRequest } from '../../../api/smartbestbuys-api'
import { UserAction } from '../../../enums'
import { RejectFormValues } from './SOPaymentRejectDialog'

export const rejectPaymentTransaction = (
  saleOrderId: number,
  transationId: number,
  action: UserAction.SOCancelPaymentTransaction | UserAction.SODeclinePaymentTransaction,
  values: RejectFormValues,
) => {
  const { actionMessage, actionMessageId } = values
  const request: UserActivityInfoRequest = {
    actionMessageId: actionMessageId === -1 ? undefined : actionMessageId,
    actionMessage,
  }

  if (action === UserAction.SODeclinePaymentTransaction) return declineSOPayment(saleOrderId)(transationId, request)
  else return cancelSOPayment(saleOrderId)(transationId, request)
}
