import { Grid, Paper, Tab, Tabs } from '@material-ui/core'
import { useContext, useState } from 'react'
import { EmptyPickupTaskList, EmptyShippingTaskList, PickupTaskList, ShippingTaskList } from '..'
import { SubTopic } from '../../../custom-components'
import { TaskType } from '../../../enums'
import { CustomerRequisitionProfileContext } from './CustomerRequisitionProfilePage'

const CustomerRequisitionTask: React.FC = () => {
  const customerRequisitionProfile = useContext(CustomerRequisitionProfileContext)

  const crReceivingTaskTypes = [
    TaskType.TransportPickupTask,
    TaskType.WaitingCustomerReturnProduct,
    TaskType.WaitingCustomerShipProduct,
  ]

  const crShippingTaskTypes = [TaskType.TransportScheduleTask]

  const [tabIndex, setTabIndex] = useState<number>(0)

  const crReceivingTasks = customerRequisitionProfile.tasks.filter((t) => crReceivingTaskTypes.includes(t.type.id))

  const crShippingTasks = customerRequisitionProfile.tasks.filter((t) => crShippingTaskTypes.includes(t.type.id))

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>สรุปงานที่เกี่ยวข้อง</SubTopic>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, value) => setTabIndex(value)}
            aria-label="disabled tabs example"
            centered
            variant="fullWidth"
          >
            <Tab label={`งานรับ (${crReceivingTasks.length})`} value={0} />
            <Tab label={`งานส่ง (${crShippingTasks.length})`} value={1} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {tabIndex === 0 && <Grid>{crReceivingTasks.length > 0 ? <PickupTaskList /> : <EmptyPickupTaskList />}</Grid>}
          {tabIndex === 1 && (
            <Grid>{crShippingTasks.length > 0 ? <ShippingTaskList /> : <EmptyShippingTaskList />}</Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerRequisitionTask
