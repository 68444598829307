import React from 'react'
import { TextFieldProps } from '@material-ui/core'
import { useController, RegisterOptions } from 'react-hook-form'
import { GeneralTextField } from '../TextField'

type Props = TextFieldProps & {
  control: any
  // TODO: refactor
  rules?: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  onPostChange?: (value: string | number) => void
}

const GeneralTextFieldForm: React.FC<Props> = (props) => {
  const { control, rules, name, type, onPostChange, defaultValue, ...textFieldProps } = props
  const {
    field: { ref, onChange, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    formState: { errors },
  } = useController({
    name: name as any,
    control,
    defaultValue: defaultValue,
    rules,
  })

  const error = traverseFormErrors(errors, name?.split('.'))

  return (
    <GeneralTextField
      type={type}
      onChange={(e: any) => {
        if (type === 'number' && e.target.value !== '') {
          onChange(Number(e.target.value))
          onPostChange && onPostChange(Number(e.target.value))
        } else {
          onChange(e.target.value)
          onPostChange && onPostChange(e.target.value)
        }
      }}
      error={!!error}
      helperText={error?.message}
      {...inputProps}
      {...textFieldProps}
      inputRef={ref}
    />
  )
}

const traverseFormErrors = (errors: any, name?: string[]): any => {
  if (!errors || !name) return null
  const [head, ...tail] = name
  if (!head) return null
  const value = errors[head]
  if (tail.length === 0) return value
  return traverseFormErrors(value, tail)
}

export default GeneralTextFieldForm
