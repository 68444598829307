export enum PurchaseOrderStatus {
  ReviewPending = 101,
  PaymentPending = 102,
  DepositPending = 103,
  AppointmentPending = 201,
  ProductPending = 202,
  VendorShippingToCustomer = 211,
  PartialReceived = 301,
  Received = 302,
  LeaderPending = 901,
  Rejected = 998,
  Canceled = 999,
}

export enum PurchaseOrderPaymentStatus {
  Pending = 1,
  Deposited = 2,
  Paid = 3,
}

export enum PurchaseProductType {
  Product = 1,
  Service = 2,
}

export enum PurchaseOrderCreateState {
  Form = 1,
  DataVerification = 2,
}
