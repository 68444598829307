import { errorGenericHandle } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { CustomerAddressUpdationRequest, UpdatedCustomerAddress } from './update-customer-billing-address-api'
export interface CustomerWithShippingAddressURLParams {
  customerId: number
  shippingAddressId: number
}

export const updateCustomerShippingAddressAPI = errorGenericHandle<
  UpdatedCustomerAddress,
  CustomerWithShippingAddressURLParams
>((urlParams: CustomerWithShippingAddressURLParams, request: CustomerAddressUpdationRequest) => {
  const path = `/customers/${urlParams.customerId}/shipping-address/${urlParams.shippingAddressId}/update`
  return smartAuthAPI.put(path, request)
})
