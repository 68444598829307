import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, MenuItem } from '@material-ui/core'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../..'
import { rejectCustomerRequisitionAPI, UserActivityNote } from '../../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm } from '../../../../custom-components'
import { PageName, UserAction } from '../../../../enums'
import { useActionMessages } from '../../../../hooks'
import { SubmitForm } from '../../../../shared-components'

interface Props extends DialogProps {
  customerRequisitionId: number
}

interface RejectCustomerRequisitionFormValues {
  actionMessageId: number
  actionMessage: string
  remark?: string
}

const CustomerRequisitionProfileRejectDialog: React.FC<Props> = (props) => {
  const { customerRequisitionId, onClose, ...OtherProps } = props
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION)

  const messages = useActionMessages(UserAction.RejectCustomerRequisition)

  const rejectMessages = [{ id: -1, name: 'เพิ่มสาเหตุใหม่' }, ...messages]

  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RejectCustomerRequisitionFormValues>()

  const { actionMessageId } = watch()

  const handleReject = async (values: RejectCustomerRequisitionFormValues) => {
    const request: UserActivityNote = {
      actionMessageId: values.actionMessageId === -1 ? undefined : values.actionMessageId,
      actionMessage: values.actionMessage,
      remark: values.remark,
    }

    const response = await rejectCustomerRequisitionAPI(customerRequisitionId, request)

    if (response?.status === 200) {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('ไม่อนมุติคำร้องของลูกค้าแล้ว')
      window.location.reload()
    } else {
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('ไม่อนมุติคำร้องของลูกค้าไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth {...OtherProps}>
      <Form onSubmit={handleSubmit(handleReject)}>
        <DialogTitle>ไม่อนุมัติคำร้อง</DialogTitle>
        <DialogContent>
          <GeneralTextFieldForm
            name="actionMessageId"
            select
            label="สาเหตุที่ไม่อนุมัติ *"
            control={control}
            rules={{ required: 'กรุณากรอกสาเหตุที่ไม่อนุมัติ' }}
          >
            {rejectMessages.map((message) => (
              <MenuItem key={message.id} value={message.id} style={{ color: message.id === -1 ? 'gray' : 'black' }}>
                {message.name}
              </MenuItem>
            ))}
          </GeneralTextFieldForm>
          {actionMessageId === -1 && (
            <GeneralTextFieldForm
              name="actionMessage"
              label="สาเหตุใหม่ *"
              control={control}
              rules={{ required: 'กรุณากรอกสาเหตุที่ไม่อนุมัติ' }}
            />
          )}
          <GeneralTextFieldForm name="remark" label="หมายเหตุ (ถ้ามี)" control={control} />
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText="ยืนยันไม่อนุมัติ"
            cancelText="ย้อนกลับ"
            onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerRequisitionProfileRejectDialog
