import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { BaseData } from '../../api/smartbestbuys-api'
import { CustomerTier } from '../../enums'

interface Props {
  tier: BaseData
  icon?: boolean
}

const useStyles = (tier: CustomerTier) =>
  makeStyles(() => {
    const chipColors = {
      [CustomerTier.Platinum]: 'linear-gradient(90deg, #D4DCE2 0%, #FFFFFF 29.69%, #D4DCE2 100%)',
      [CustomerTier.Diamond]:
        'linear-gradient(90deg, #C7D6FF 0%, #FFFFFF 23.44%, #CEEDFF 56.77%, #C7D6FF 100%, #C7D6FF 100%)',
      [CustomerTier.Wisdom]: 'linear-gradient(90deg, #B98635 0%, #F0DEA2 34.9%, #B98635 100%)',
    }

    return createStyles({
      chip: {
        background: chipColors[tier],
      },
    })
  })

const CustomerTierChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ children, tier, icon, ...othersProps }) => {
  const classes = useStyles(tier.id)()

  return <Chip className={classes.chip} label={tier.name} {...othersProps} />
}

export default CustomerTierChip
