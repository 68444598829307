import { Card, Grid, Typography } from '@material-ui/core'
import { FlexBox } from '../../../../custom-components'

const EmptyShippingTaskList: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <FlexBox justifyContent="center" alignItems="center" width="100%" height={100} clone>
        <Card variant="outlined">
          <Typography variant="body2" color="textSecondary">
            ยังไม่มีงานขนส่ง
          </Typography>
        </Card>
      </FlexBox>
    </Grid>
  )
}

export default EmptyShippingTaskList
