import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Typography } from '@material-ui/core'
import { FlexBox } from '../../custom-components'
import { styles, groupedStyles, groupedSecondaryStyles } from './style'
interface ButtonRadio {
  name: string
  // deprecated to use as value
  id: number
  // TODO: migrate to use value instead id
  value?: number | string | boolean
}

interface Props {
  label?: string
  // deprecated
  onChange?: (value: number) => void
  // TODO: refactor to use this
  onValueChange?: (value: number | string | boolean) => void
  items: ButtonRadio[]
  value?: number | string
  defaultValue?: number | string | boolean
  disableInActive?: boolean
  grouped?: boolean
  size?: 'small' | 'medium' | 'large'
  errorMessage?: string
  helperText?: string
  color?: 'secondary'
}

// TODO : support color button
const ButtonRadios: React.FC<Props> = (props) => {
  const {
    label,
    items,
    defaultValue,
    value: forcedValue,
    disableInActive,
    grouped,
    size,
    onChange,
    errorMessage,
    helperText,
    color,
    onValueChange,
  } = props
  const [value, setValue] = useState<number | string | boolean | undefined>(defaultValue)
  const classes = styles()
  const groupedClasses = groupedStyles()
  const groupedClassesSecondary = groupedSecondaryStyles()

  const styleMapping = {
    default: groupedClasses,
    secondary: groupedClassesSecondary,
  }

  useEffect(() => {
    if (forcedValue) setValue(forcedValue)
  }, [forcedValue, setValue])

  const handleClick = (input: number) => {
    setValue(input)
    onChange && onChange(input)
  }

  const handleValueChange = (newValue: number | string | boolean) => {
    setValue(newValue)
    onValueChange && onValueChange(newValue)
  }

  const showItems = items.map((item) => {
    const itemValue = item.value ?? item.id
    const isActive = value === itemValue
    return (
      <Button
        key={item.id}
        size={size || 'small'}
        disableRipple
        onClick={() => {
          handleClick(item.id)
          if (item.value !== undefined) handleValueChange(item.value)
        }}
        color={color || 'primary'}
        classes={
          grouped
            ? {
                root: isActive ? styleMapping[color || 'default'].active : styleMapping[color || 'default'].root,
                disabled: styleMapping[color || 'default'].disabled,
              }
            : {
                root: isActive ? classes.active : classes.root,
                disabled: classes.disabled,
              }
        }
        disabled={disableInActive && item.id !== value}
      >
        {item.name}
      </Button>
    )
  })

  return (
    <FlexBox flexDirection="column">
      {label && (
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {label}
        </Typography>
      )}
      <FlexBox gridGap={8} flexWrap="wrap">
        {grouped ? <ButtonGroup>{showItems}</ButtonGroup> : showItems}
      </FlexBox>
      {!!errorMessage && (
        <Typography variant="caption" color="error" gutterBottom style={{ margin: '4px 0px 0px' }}>
          {errorMessage}
        </Typography>
      )}
      {helperText && (
        <Typography variant="caption" color="textSecondary" gutterBottom style={{ margin: '4px 0px 0px' }}>
          {helperText}
        </Typography>
      )}
    </FlexBox>
  )
}

export default ButtonRadios
