import { DialogProps, Typography } from '@material-ui/core'

import { UserAction } from '../../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../../shared-components'
import { UserActivityNote, requestToCancelSaleOrderAPI } from '../../../../api/smartbestbuys-api'
import { useContext } from 'react'
import { RootContext } from '../../../..'

interface SaleOrderProfileInfo {
  id: number
  code: string
}

interface Props extends DialogProps {
  saleOrderProfileInfo: SaleOrderProfileInfo
  onComplete?: (data: { id: number }) => void
  onFail?: () => void
}

const SaleOrderRequestToCancelDialog: React.FC<Props> = (props: Props) => {
  // set up props & state
  const { saleOrderProfileInfo, onComplete, onFail, ...dialogProps } = props

  //context
  const { triggerSnackbar } = useContext(RootContext)

  const textOption: UserActivityDialog_TextOption = {
    title: (
      <Typography variant="h5" color="error">
        การอนุมัติยกเลิก ควรกดโดย ผู้จัดการเท่านั้น !!!
      </Typography>
    ),
    description: (
      <Typography variant="h6" color="error">
        คุณเป็นผู้จัดการ .... ใช่มั้ย ..... !!
      </Typography>
    ),
    submitText: 'ยืนยันยกเลิก',
    cancelText: 'ย้อนกลับ',
    actionMessageText: 'สาเหตุของการยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: false,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { actionMessageId, actionMessage, remark } = values
    const requestBody: UserActivityNote = {
      userAction: UserAction.CancelSaleOrderByCustomer,
      actionMessageId: actionMessageId !== -1 ? actionMessageId : undefined,
      actionMessage,
      remark,
    }
    const response = await requestToCancelSaleOrderAPI(saleOrderProfileInfo.id, requestBody)

    if (response?.status === 200) {
      triggerSnackbar('ยกเลิกการใบสั่งขายสำเร็จ')
      if (!!onComplete) onComplete(response.data)
    } else {
      triggerSnackbar('ยกเลิกการใบสั่งขายไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.CancelSaleOrder}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default SaleOrderRequestToCancelDialog
