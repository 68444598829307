import { useState, useEffect } from 'react'
import { fetchCreationInfoAPI, ProductCreationInfo } from '../api/smartbestbuys-api'

export const useProductCreation = (): ProductCreationInfo | undefined => {
  const [productCreationInfo, setProductCreationInfo] = useState<ProductCreationInfo>()

  const _fetchCreationInfoAPI = async () => {
    const response = await fetchCreationInfoAPI()
    setProductCreationInfo(response?.data)
  }

  useEffect(() => {
    _fetchCreationInfoAPI()
  }, [])

  return productCreationInfo
}
