import { Card, Grid, Typography, Divider, Avatar, Box } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { useMemo } from 'react'
import { TransportTask } from '../../../api/smartbestbuys-api'
import { FlexBox, TypographyWithLabel } from '../../../custom-components'
import { ProductType, TaskStatus, TaskType } from '../../../enums'
import { formatNumber } from '../../../functions/number-utils'
import { TypographyLink, Space, TaskStatusChip, CustomerTierChip } from '../../../shared-components'

interface Props {
  task: TransportTask
}

export const TransportPaymentCard = (props: Props) => {
  const { task } = props

  const shippingTask = task.relatedTasks?.find((t) => t.type.id === TaskType.MessengerShippingTask)
  const expectedChargeAmount = task.state?.shippingInfo.chargeAmount
  const actualChargeAmount = shippingTask?.state.shippingResult?.chargeAmount
  const paymentMethod = shippingTask?.state.shippingResult?.paymentMethod

  const isCanceledTask = task.status.id === TaskStatus.Canceled

  const failureMessage = useMemo(
    () => {
      return shippingTask?.state.failureMessages?.failed ?? shippingTask?.state.failureMessages?.preCanceled
    }, // eslint-disable-next-line
    [shippingTask?.id],
  )

  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="top">
            <TypographyLink
              href={`/sale-orders/${task.saleOrderInfo?.id}`}
              target="_blank"
              variant="h6"
              color="primary"
              textDecorationColor="primary"
            >
              {task.saleOrderInfo?.code}
            </TypographyLink>
            <FlexBox>
              <FlexBox flexDirection="column" justifyContent="right">
                <TypographyLink
                  href={`/transport-tasks/${task.id}/profile`}
                  target="_blank"
                  variant="body2"
                  color="#828282"
                  textDecorationColor="#828282"
                >
                  {task.code}
                </TypographyLink>
                {isCanceledTask && (
                  <TypographyWithLabel label="เหตุผล:" labelProps={{ color: 'primary' }} color="primary">
                    {task.canceledMessage}
                  </TypographyWithLabel>
                )}
              </FlexBox>

              <Space size="small" direction="horizontal" />
              {/* {task.status.id === TaskStatus.Canceled && ( */}
              <TaskStatusChip status={task.status.id} label={task.status.name} />
              {/* )} */}
            </FlexBox>
          </FlexBox>
        </Grid>

        <Grid item xs={12}>
          <span className="mr-2">{task.saleOrderInfo?.customerName ?? 'ไม่เจอ!!'} </span>
          {task.saleOrderInfo?.tier && <CustomerTierChip tier={task.saleOrderInfo.tier} />}
          <Space size="small" />
        </Grid>

        <Grid item xs={12}>
          <TypographyWithLabel label="ข้อมูลลูกค้า:">
            {task.state?.shippingInfo.consignee ?? 'ไม่ได้ระบุ'} {task.state?.shippingInfo.phoneNumber}
          </TypographyWithLabel>
        </Grid>

        <Grid item xs={6}>
          <TypographyWithLabel label="ชื่อผู้ส่งของ:">
            {task.state?.shippingInfo.messengerInfo?.name ?? 'ไม่ได้ระบุ'}
          </TypographyWithLabel>
          <Space size="small" />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TypographyWithLabel label="ยอดที่ต้องรับชำระ:" color={expectedChargeAmount ? 'textPrimary' : 'textSecondary'}>
          {expectedChargeAmount ? `${formatNumber(expectedChargeAmount)} บาท` : 'ไม่ต้องเก็บเงินเพิ่มเติม'}
        </TypographyWithLabel>
        <Space size="small" />
      </Grid>

      {shippingTask && (
        <>
          <Space />
          <Divider />
          <Space size="small" />

          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <span className="font-bold">รายละเอียดงานส่งของ</span>
            </FlexBox>
            <Space size="small" />
          </Grid>

          <Grid item xs={12}>
            <TypographyWithLabel label="สถานะการส่งของ:">
              <TaskStatusChip size="small" status={shippingTask.status.id} label={shippingTask.status.name} />
            </TypographyWithLabel>
            <Space size="small" />
          </Grid>

          {failureMessage && (
            <Grid item xs={12}>
              <TypographyWithLabel label="เหตุผล:" color="primary">
                {failureMessage}
              </TypographyWithLabel>
              <Space size="small" />
            </Grid>
          )}

          {shippingTask?.state.failureMessages?.remark && (
            <Grid item xs={12}>
              <TypographyWithLabel label="หมายเหตุ:" color="primary">
                {shippingTask?.state.failureMessages?.remark}
              </TypographyWithLabel>
              <Space size="small" />
            </Grid>
          )}

          {!!expectedChargeAmount && !!actualChargeAmount && !!paymentMethod && (
            <Grid item xs={12}>
              <TypographyWithLabel label="ยอดที่เก็บตามจริง:">
                <FlexBox gridGap={5}>
                  <Box color={actualChargeAmount === expectedChargeAmount ? 'success.main' : 'error.main'}>
                    {formatNumber(actualChargeAmount)} บาท
                  </Box>
                  ({paymentMethod.name})
                </FlexBox>
              </TypographyWithLabel>
              <Space size="small" />
            </Grid>
          )}

          {shippingTask?.state.shippingResult?.remark && (
            <Grid item xs={12}>
              <TypographyWithLabel label="หมายเหตุ:">{shippingTask?.state.shippingResult?.remark}</TypographyWithLabel>
              <Space size="small" />
            </Grid>
          )}

          <Space />
          <Divider />
          <Space />
        </>
      )}

      <Space />

      {(task.state?.items.filter((item) => item.type === ProductType.Product) ?? []).length > 0 && (
        <>
          <FlexBox justifyContent="space-between">
            <span className="font-bold">รายการสินค้า</span>
          </FlexBox>
          <Space size="small" />
          {task.state?.items
            .filter((item) => item.type === ProductType.Product)
            .map((product) => (
              <>
                <div className="transport-shipping-daily-summary-products__product-item" key={'product-' + product.id}>
                  <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                    <Photo />
                  </Avatar>
                  <div className="transport-shipping-daily-summary-products__product-item__info">
                    <FlexBox justifyContent="space-between">
                      <Typography color="textSecondary">{product.code}</Typography>
                      <Typography>
                        {product.amount} {product.unit ?? 'อัน'}
                      </Typography>
                    </FlexBox>
                    <Typography color="textSecondary">{product.name}</Typography>
                  </div>
                </div>
                <Space size="small" />
                <Divider />
              </>
            ))}
        </>
      )}
      {(task.state?.items.filter((item) => item.type === ProductType.Service) ?? []).length > 0 && (
        <>
          <Space />
          <FlexBox justifyContent="space-between">
            <span className="font-bold">รายการเอกสาร</span>
          </FlexBox>
          <Space size="small" />
          {task.state?.items
            .filter((item) => item.type === ProductType.Service)
            .map((document) => (
              <>
                <FlexBox justifyContent="space-between">
                  <Typography color="textSecondary">
                    {document.name} {document.code}
                  </Typography>
                  <Typography>{document.amount} ฉบับ</Typography>
                </FlexBox>

                <Space size="small" />
                <Divider />
              </>
            ))}
        </>
      )}
    </Card>
  )
}
