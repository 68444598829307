import { Typography } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PermissionTopic } from '../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm, Grid } from '../../../custom-components'
import { Space, SubmitForm } from '../../../shared-components'
import UserPermissionCheckbox from '../UserPermissionCheckbox'
import { PermissionGroupFormValues } from './PermissionGroupFormValue'

interface PermissionGroupFormProps {
  permissionTopics: PermissionTopic[]
  defaultValues?: PermissionGroupFormValues
  onSubmit: (values: PermissionGroupFormValues) => void
  onCancel: () => void
}

const PermissionGroupForm = (props: PermissionGroupFormProps) => {
  const { permissionTopics, defaultValues, onSubmit, onCancel } = props
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<number[]>(defaultValues?.permissionIds ?? [])
  const formMethod = useForm<PermissionGroupFormValues>()

  const isUpdateMode = useMemo(
    () => !!defaultValues, // eslint-disable-next-line
    [!!defaultValues],
  )

  const {
    control,
    setValue,
    formState: { isSubmitting },
  } = formMethod

  return (
    <FormProvider {...formMethod}>
      <Form
        onSubmit={(values) => {
          onSubmit(values)
        }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">ข้อมูลกลุ่มสิทธิ์</Typography>
          <div style={{ paddingTop: 10, paddingLeft: 10 }}>
            <GeneralTextFieldForm
              style={{ width: 500 }}
              control={control}
              name="permissionGroupName"
              label="ชื่อกลุ่มสิทธิ์ *"
              defaultValue={defaultValues?.permissionGroupName}
              rules={{ required: 'กรุณากรอกชื่อกลุ่มสิทธิ์' }}
            />
          </div>
          <Space />
          <Grid item xs={12}>
            <Typography variant="subtitle1">สิทธิ์ทั้งหมด</Typography>
            {permissionTopics.map((topic, index) => {
              return (
                <div className="mt-8" style={{ paddingLeft: 10 }}>
                  <Typography variant="body1" color="textSecondary">
                    {topic.name}
                  </Typography>
                  <div className="mt-2">
                    {topic.permissions.map((permission, index) => (
                      <UserPermissionCheckbox
                        selectedPermissionIds={selectedPermissionIds}
                        onPermissionSelect={(value) => setSelectedPermissionIds(value)}
                        permission={permission}
                        allPermissionIdInSelectedGroup={[]}
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </Grid>
          <Space />
          <Grid item xs={12}>
            <SubmitForm
              submitText={isUpdateMode ? 'ยืนยันแก้ไขกลุ่มสิทธิ์การใช้งาน' : 'ยืนยันเพิ่มกลุ่มสิทธิ์การใช้งาน'}
              cancelText="ย้อนกลับ"
              onSubmit={() => setValue('permissionIds', selectedPermissionIds)}
              disableSubmit={isSubmitting}
              onCancel={onCancel}
            />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default PermissionGroupForm
