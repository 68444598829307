import React from 'react'
import { Avatar, Button, Chip, Grid, Paper, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CustomerProfileCardProps } from './types'
import { useStyles } from '../style'
import { BlackListSignal, ImageIcon, Loading, Space } from '../..'
import { FlexBox, Link, SubTopic, Title, TypographyWithLabel } from '../../../custom-components'
import { ArrowForward, PermIdentity, DesktopWindows, LocalPhone, Email, Edit } from '@material-ui/icons'
import { getAddressText } from '../../../functions'
import { BillingAddressRadioGroup } from './BillingAddressRadioGroup'
import dealerLogo from '../../../assets/images/dealer-logo.png'

const CustomerProfileCard: React.FC<CustomerProfileCardProps> = (props) => {
  const {
    contactId,
    customerProfile,
    customerBankAccount,
    billingAddressId,
    billingAddressSelect,
    onBillingAddressChange,
    disableBtnCustomerDetail,
    billingAddressAlert,
  } = props

  const {
    code,
    customerType,
    creditPeriodDay,
    referenceNumber,
    phoneNumber,
    email,
    websiteUrl,
    contacts,
    billingAddresses,
    isBlacklist,
    isDealer,
    blacklistRemark,
  } = {
    ...customerProfile,
  }

  const contact = contactId ? contacts?.find((c) => c.id === Number(contactId)) : undefined

  const billingAddress = billingAddressId ? billingAddresses?.find((ba) => ba.id === billingAddressId) : undefined
  const hasOnlyOneAddress = billingAddresses?.length === 1
  if (billingAddresses && hasOnlyOneAddress && billingAddressSelect && onBillingAddressChange && !billingAddressId)
    onBillingAddressChange(billingAddresses[0].id)

  const isCredit = (creditPeriodDay || 0) > 0

  const classes = useStyles(customerType?.id, isCredit)()

  if (!customerProfile) return <Loading />

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Avatar alt="Customer Avatar" className={classes.avatar}></Avatar>
              {isCredit && (
                <Typography className={classes.creditBadge} variant="body2" component="div">
                  ลูกค้าเครดิต <br /> {customerProfile.creditPeriodDay} วัน
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={9} className={classes.gridItem}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="row-reverse">
                    {!disableBtnCustomerDetail ? (
                      <Grid item xs={12} md={6}>
                        <FlexBox gridGap={16} justifyContent="flex-end" height={32} clone>
                          <Link color="primary" href={`/customers/${customerProfile.id}/profile`} target="_blank">
                            <Typography>ไปที่หน้ารายละเอียดลูกค้า</Typography>
                            <ArrowForward />
                          </Link>
                        </FlexBox>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={!disableBtnCustomerDetail ? 6 : 12}>
                      <FlexBox gridGap={16} alignItems="center" height={32}>
                        <Typography variant="h6" color="textSecondary" component="span">
                          {code}
                        </Typography>
                        <Chip label={customerType?.name} size="small" className={classes.chip} />
                        {isDealer && (
                          <span className="ml-2">
                            <ImageIcon name="dealer" src={dealerLogo} />
                          </span>
                        )}
                        {isBlacklist && (
                          <>
                            <BlackListSignal />
                            <Typography noWrap={true} style={{ overflow: 'visible' }}>
                              {blacklistRemark}
                            </Typography>
                          </>
                        )}
                      </FlexBox>
                    </Grid>
                    <Grid item xs={12}>
                      <Title>{customerProfile.nameLocal}</Title>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {referenceNumber && (
                      <Grid item xs={12} md={6}>
                        <TypographyWithLabel icon={<PermIdentity fontSize="small" />} label="เลขประจำตัวผู้เสียภาษี:">
                          {referenceNumber}
                        </TypographyWithLabel>
                      </Grid>
                    )}
                    {websiteUrl && (
                      <Grid item xs={12} md={6}>
                        <TypographyWithLabel icon={<DesktopWindows fontSize="small" />} label="เว็บไซต์:">
                          {websiteUrl}
                        </TypographyWithLabel>
                      </Grid>
                    )}
                    {phoneNumber && (
                      <Grid item xs={12} md={6}>
                        <TypographyWithLabel icon={<LocalPhone fontSize="small" />} label="เบอร์โทร:">
                          {phoneNumber}
                        </TypographyWithLabel>
                      </Grid>
                    )}
                    {email && (
                      <Grid item xs={12} md={6}>
                        <TypographyWithLabel icon={<Email fontSize="small" />} label="อีเมล:">
                          {email}
                        </TypographyWithLabel>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {contact && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SubTopic>ผู้ติดต่อ</SubTopic>
              </Grid>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SubTopic>{contact.nicknameLocal}</SubTopic>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TypographyWithLabel icon={<LocalPhone fontSize="small" />} label="เบอร์โทร:">
                        {contact.phoneNumber}
                      </TypographyWithLabel>
                    </Grid>
                    {contact.email && (
                      <Grid item xs={12} md={6}>
                        <TypographyWithLabel icon={<Email fontSize="small" />} label="อีเมล:">
                          {contact.email}
                        </TypographyWithLabel>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}

        {billingAddressSelect && onBillingAddressChange && billingAddresses?.length ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SubTopic>ที่อยู่เปิดบิล</SubTopic>
              </Grid>
              <Grid item xs={12}>
                {hasOnlyOneAddress ? (
                  <Paper variant="outlined">
                    <Typography variant="body2" color="textSecondary">
                      {getAddressText(billingAddress)}
                    </Typography>
                  </Paper>
                ) : (
                  <BillingAddressRadioGroup
                    options={billingAddresses || []}
                    value={billingAddressId}
                    onChange={onBillingAddressChange}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : billingAddress ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FlexBox justifyContent="space-between" alignItems="center">
                  <SubTopic>ที่อยู่เปิดบิล</SubTopic>
                  {billingAddressAlert?.onClickAlert && (
                    <Button color="secondary" onClick={billingAddressAlert.onClickAlert} startIcon={<Edit />}>
                      เลือกที่อยู่เปิดบิล
                    </Button>
                  )}
                </FlexBox>
              </Grid>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Typography variant="body2" color="textSecondary">
                    {getAddressText(billingAddress)}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          billingAddressAlert && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SubTopic>ที่อยู่เปิดบิล</SubTopic>
                </Grid>
                <Grid item xs={12}>
                  {billingAddressAlert.overrideData ? (
                    <FlexBox gridGap={8}>
                      <Alert severity="warning">{billingAddressAlert.overrideData.text}</Alert>
                      {billingAddressAlert.onClickAlert && (
                        <Button variant="contained" color="secondary" onClick={billingAddressAlert.onClickAlert}>
                          {billingAddressAlert.overrideData.buttonText}
                        </Button>
                      )}
                    </FlexBox>
                  ) : billingAddresses?.length === 0 ? (
                    <FlexBox gridGap={8}>
                      <Alert severity="warning">
                        ยังไม่ได้เลือกที่อยู่เปิดบิลสำหรับใบสั่งขายนี้ กรุณาเลือกที่อยู่เปิดบิล
                      </Alert>
                      <Button
                        variant="contained"
                        color="secondary"
                        href={`/customers/${customerProfile.id}/profile?triggerCreateBillingAddress=${billingAddressAlert.saleOrderId}`}
                        endIcon={<ArrowForward />}
                      >
                        สร้างที่อยู่เปิดบิล
                      </Button>
                    </FlexBox>
                  ) : (
                    <FlexBox gridGap={8}>
                      <Alert severity="warning">ลูกค้านี้ยังไม่มีที่อยู่เปิดบิล</Alert>
                      {billingAddressAlert.onClickAlert && (
                        <Button variant="contained" color="secondary" onClick={billingAddressAlert.onClickAlert}>
                          เลือกที่อยู่เปิดบิล
                        </Button>
                      )}
                    </FlexBox>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )
        )}

        {customerBankAccount && (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <SubTopic>บัญชีธนาคาร</SubTopic>
            </Grid>
            <Space size="small" />
            <Grid item xs={12}>
              <Paper variant="outlined">
                <FlexBox gridGap={24}>
                  <Typography variant="body2">{customerBankAccount.accountNumber}</Typography>
                  <Typography variant="body2">{customerBankAccount.accountName}</Typography>
                  <Typography variant="body2"> {customerBankAccount.bank.name}</Typography>
                </FlexBox>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default CustomerProfileCard
