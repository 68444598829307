import { Paper, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import numeral from 'numeral'
import { PRSaleOrderProduct } from '../../../api/smartbestbuys-api'

import { Avatar, FlexBox, SubTopic, Table } from '../../../custom-components'

import './SelectedPurchaseProductTable.less'

interface Props {
  saleOrderCode: string
  products: PRSaleOrderProduct[]
}

const PRSaleOrderProductsTable = (props: Props) => {
  const { saleOrderCode, products } = props

  return (
    <Paper className="flex flex-col">
      <SubTopic>ข้อมูลสินค้าจากใบสั่งขาย {saleOrderCode}</SubTopic>
      <div className="mb-8" />
      <Table<PRSaleOrderProduct>
        size="small"
        color="secondary.dark"
        columns={[
          {
            name: 'ลำดับ',
            fixedWidth: 72,
            render: (_, index) => (
              <FlexBox justifyContent="space-around">
                <Typography color="textSecondary" variant="body2">
                  {index + 1}
                </Typography>
              </FlexBox>
            ),
          },
          {
            name: 'ชื่อสินค้า/บริการ',
            fixedWidth: 417,
            render: ({ imageUrl, nameLocal, code }) => (
              <FlexBox alignItems="flex-start">
                <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                  <Photo />
                </Avatar>
                <FlexBox flexDirection="column" ml={1} className="purchase-product-header">
                  <FlexBox gridGap={8}>
                    <Typography variant="body2" className="purchase-product-header__code">
                      {code}
                    </Typography>
                  </FlexBox>
                  <Typography variant="body2" className="purchase-product-header__name">
                    {nameLocal}
                  </Typography>
                </FlexBox>
              </FlexBox>
            ),
          },
          {
            name: 'พร้อมขาย',
            numeric: true,
            render: ({ stockAmount, reservedAmount }) => (
              <Typography color="textPrimary" variant="body2">
                {numeral(stockAmount - reservedAmount).format('0,0')}
              </Typography>
            ),
          },
          {
            name: 'จำนวนขาย',
            numeric: true,
            format: '0,0',
            dataIndex: 'amount',
          },
          {
            name: 'จำนวนที่ต้องซื้อเพิ่ม',
            numeric: true,
            format: '0,0',
            render: ({ stockAmount, reservedAmount, shippedAmount, amount }) => {
              const readyStockAmount = stockAmount - reservedAmount
              const pendingShippingAmount = amount - shippedAmount
              if (pendingShippingAmount < readyStockAmount) return 0
              return pendingShippingAmount - readyStockAmount
            },
          },
        ]}
        data={products}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default PRSaleOrderProductsTable
