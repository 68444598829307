import { Paper } from '@material-ui/core'
import { ICustomerProfile, ICustomerProfile_RefundRequisition } from '../../../../../../api/smartbestbuys-api'
import { EmptyCard, FilteredTable, Link, Topic } from '../../../../../../custom-components'
import { dateFormat } from '../../../../../../functions'
import { RefundRequisitionStatusChip } from '../../../../../../shared-components'
import { RefundStatus } from '../../../../../../enums'

interface Props {
  customerProfile: ICustomerProfile
}

const CustomerRefundRequistion: React.FC<Props> = (props) => {
  const { customerProfile } = props

  if (!customerProfile.refundRequisitions || customerProfile.refundRequisitions.length === 0)
    return (
      <Paper>
        <EmptyCard emptyText="ไม่มีรายการคืนเงิน" />
      </Paper>
    )

  return (
    <Paper>
      <Topic>รายการคืนเงิน</Topic>
      <FilteredTable<ICustomerProfile_RefundRequisition>
        color="grey.800"
        columns={[
          {
            name: '',
            dataIndex: 'status',
            fixedWidth: 50,
            render: ({ status }) => (
              <RefundRequisitionStatusChip
                status={status.id}
                label={status.id === RefundStatus.Approve ? 'รอยืนยันโอนเงิน' : status.name}
              />
            ),
          },
          {
            name: 'เลขที่การคืนเงิน',
            dataIndex: 'code',
            render: ({ id, code }) => (
              <Link href={`/finance/refund-requisitions/${id}/profile`} target="_blank">
                {code}
              </Link>
            ),
          },
          {
            name: 'ใบสั่งขายที่เกี่ยวข้อง',
            render: ({ saleOrders }) => saleOrders.map((so) => so.code).join(' | '),
            fixedWidth: 450,
          },
          {
            name: 'วันที่สร้าง',
            dataIndex: 'recCreatedAt',
            render: ({ recCreatedAt }) => dateFormat(new Date(recCreatedAt)),
          },
          {
            name: 'สร้างโดย',
            dataIndex: 'recCreatedBy',
          },
          {
            name: 'ยอดคืน',
            format: '0,0.00',
            numeric: true,
            dataIndex: 'total',
          },
        ]}
        data={customerProfile.refundRequisitions ?? []}
        disableDateRangeFilter
      />
    </Paper>
  )
}

export default CustomerRefundRequistion
