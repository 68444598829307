import { FormProvider, useForm } from 'react-hook-form'
import { Form, GeneralTextFieldForm, Grid } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'

interface Props {
  username: string
  onSubmit: (values: UserCriteriaUpdateFormValues) => void
  onCancel: () => void
}

export interface UserCriteriaUpdateFormValues {
  username: string
  password: string
}

export const UserCriteriaUpdateForm: React.FC<Props> = (props) => {
  const { username, onSubmit, onCancel } = props
  const formMethod = useForm<UserCriteriaUpdateFormValues>({
    defaultValues: {
      username,
      password: '',
    },
  })
  const {
    control,
    formState: { isSubmitting },
  } = formMethod

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={(value) => onSubmit(value)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GeneralTextFieldForm
              control={control}
              name="username"
              label="ชื่อผู้ใช้ *"
              rules={{ required: 'กรุณากรอกชื่อผู้ใช้' }}
            />
          </Grid>
          <Grid item xs={12}>
            <GeneralTextFieldForm
              control={control}
              name="password"
              label="รหัสผ่าน *"
              rules={{ required: 'กรุณากรอกรหัสผ่าน' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SubmitForm
            submitText="ยืนยันการแก้ไขข้อมูล"
            cancelText="ย้อนกลับ"
            disableSubmit={isSubmitting}
            onCancel={onCancel}
          />
        </Grid>
      </Form>
    </FormProvider>
  )
}
