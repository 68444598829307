import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { ICustomerProfile_CustomerBankAccount } from '../../../../../../../api/smartbestbuys-api'
import { ImageCard } from '../../../../../../../shared-components'

interface Props {
  bankAccount: ICustomerProfile_CustomerBankAccount
}

const CustomerBankAccountCard: React.FC<Props> = (props) => {
  const { bankAccount } = props

  const { bank, bookBankImageUrl, accountName, accountNumber } = bankAccount

  return (
    <>
      <Paper variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={2} md={2} container justify="flex-start">
            <ImageCard size="small" name="receipt" src={bookBankImageUrl} />
          </Grid>
          <Grid item xs={10} md={10} container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{bank.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{accountNumber}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{accountName}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerBankAccountCard
