import { DialogProps } from '@material-ui/core'

import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import { cancelQuotationsProfile, QuotationCancelRequest } from '../../../api/smartbestbuys-api'
import { useContext } from 'react'
import { QuotationsProfilePageContextImpl } from './QuotationsProfilePage'
import { RootContext } from '../../..'

interface QuotationsProfileCancelDialogProps extends DialogProps {
  toggleReload: () => void
}

const QuotationsProfileCancelDialog: React.FC<QuotationsProfileCancelDialogProps> = (
  props: QuotationsProfileCancelDialogProps,
) => {
  // set up props
  const { toggleReload, onClose, ...dialogProps } = props

  const { triggerSnackbar } = useContext(RootContext)

  const { profile } = useContext(QuotationsProfilePageContextImpl)

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการยกเลิกใบเสนอราคา',
    description: `ยกเลิกใบเสนอราคาหมายเลข ${profile.code} นี้ใช่หรือไม่?`,
    actionMessageText: 'สาเหตุของการยกเลิก',
    submitText: 'ยืนยันการยกเลิก',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: false,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { actionMessageId, actionMessage, remark } = values
    const request: QuotationCancelRequest = {
      actionMessageId: actionMessageId !== -1 ? actionMessageId : undefined,
      actionMessage,
      remark,
    }
    const response = await cancelQuotationsProfile(profile.id, request)
    if (response?.status === 200) {
      triggerSnackbar('ยกเลิกใบเสนอราคาสำเร็จ')
      toggleReload()
      if (!!onClose) onClose({}, 'escapeKeyDown')
    } else {
      triggerSnackbar('ยกเลิกใบเสนอราคาไม่สำเร็จ')
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.CancelQuotation}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}

export default QuotationsProfileCancelDialog
