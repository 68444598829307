import {
  AttachmentUpdateForUpload,
  PRProductForUpsert,
  PRUpdateRequest,
  PurchaseRequisitionUpdateData,
} from '../../../api/smartbestbuys-api'
import { dateFormat } from '../../../functions'
import { FileType, SourceFile } from '../../../types'
import PurchaseRequisitionCreateFormValues from '../create/PurchaseRequisitionCreateFormValue'

export const mapPurchaseRequisitionDataToFormValues = (
  purchaseRequisitionData: PurchaseRequisitionUpdateData,
): PurchaseRequisitionCreateFormValues => {
  const attachments = purchaseRequisitionData.purchaseRequisitionInfo?.attachments?.map<SourceFile>((attachment) => {
    const fileName = attachment.attachmentUrl.split('/')
    return {
      type: attachment.attachmentUrl.slice(-4) === '.pdf' ? FileType.PDF : FileType.Image,
      base64: attachment.attachmentUrl,
      name: fileName[fileName.length - 1],
    }
  })

  const purchaseRequisitionProducts = purchaseRequisitionData.purchaseRequisitionInfo.products.map((product) => {
    const findProduct = purchaseRequisitionData.products.find((item) => product.refProductInfo?.id === item.productId)
    return !!findProduct ? { ...product, recommendPurchaseAmount: findProduct.recommendPurchaseAmount } : product
  })

  const purchaseRequisitionValues: PurchaseRequisitionCreateFormValues = {
    products: purchaseRequisitionProducts as any,
    currencyId: purchaseRequisitionData.purchaseRequisitionInfo.currencyInfo.id,
    dueDate: purchaseRequisitionData.purchaseRequisitionInfo.dueDate,
    vendor: purchaseRequisitionData.purchaseRequisitionInfo.referenceVendorInfo
      ? {
          ...purchaseRequisitionData.purchaseRequisitionInfo.referenceVendorInfo,
          country: purchaseRequisitionData.vendors.find(
            (vendor) => vendor.id === purchaseRequisitionData.purchaseRequisitionInfo.referenceVendorInfo?.id,
          )?.country,
        }
      : undefined,
    topicId: purchaseRequisitionData.purchaseRequisitionInfo.topic.id,
    attachments,
  }

  return purchaseRequisitionValues
}

export const mapPurchaseRequisitionsToUpdateFormValues = (
  values: PurchaseRequisitionCreateFormValues,
  updateRemark: string,
): PRUpdateRequest => {
  const total = values.products.reduce<number | undefined>((sum, product) => {
    if (sum === undefined || !product.price) return undefined
    return sum + product.price * product.amount
  }, 0)

  const products = values.products.map<PRProductForUpsert>((product) => ({
    ...product,
    amount: product.amount,
    price: product.price ? product.price : undefined,
    typeId: product.type?.id || product.typeId!,
  }))

  const attachments = values.attachments?.map<AttachmentUpdateForUpload>((attachment) => {
    if (attachment.base64!.includes('https://smartbestbuys-s3.s3-ap-southeast-1.amazonaws.com')) {
      return {
        imageUrl: attachment.base64,
      }
    }

    return {
      base64: attachment.base64,
    }
  })

  return {
    ...values,
    updateRemark,
    dueDate: values.dueDate && dateFormat(values.dueDate, 'yyyy-MM-dd'),
    attachments,
    total,
    products,
    referenceVendorId: values.vendor?.id,
  }
}
