import { Button, Chip, IconButton, Paper, Typography } from '@material-ui/core'
import { Delete, Edit, Photo, AddCircle } from '@material-ui/icons'
import React, { useState } from 'react'
import { Control, useFormContext } from 'react-hook-form'

import { Avatar, FlexBox, GeneralTextFieldForm, SubTopic, Table, TColumn } from '../../../custom-components'
import { formatNumber } from '../../../functions/number-utils'
import PurchaseProductCreateDialog from './PurchaseProductCreateDialog'
import { PurchaseProduct, PurchaseProductSelectorFormValues } from './PurchaseProductSelector/PurchaseProductSelector'

import './SelectedPurchaseProductTable.less'

interface Props {
  existingProductCodeList?: string[]
  currency?: string
  rop?: boolean
}

interface SelectedPurchaseProductTableData extends PurchaseProduct {
  id: number
  isNew: boolean
}

const SelectedPurchaseProductTable: React.FC<Props> = (props: Props) => {
  const { existingProductCodeList, currency, rop } = props

  const [openProductCreationDialog, setOpenProductCreationDialog] = useState(false)
  const [product, setProduct] = useState<PurchaseProduct & { index: number }>()

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<PurchaseProductSelectorFormValues>()

  const { products: selectedProducts } = watch()

  const parserProducts: SelectedPurchaseProductTableData[] = selectedProducts.map((product) => {
    return {
      ...product,
      id: product.id || -1,
      isNew: !product.id && !product.productId,
    }
  })

  const handleRemoveProduct = (index: number) => {
    selectedProducts.splice(index, 1)
    setValue('products', selectedProducts)
  }

  const handleEditProduct = (index: number) => {
    setProduct({ ...selectedProducts[index], index })
    setOpenProductCreationDialog(true)
  }

  const handleSubmitNewPurchaseProduct = (formValue: PurchaseProduct) => {
    if (product) {
      selectedProducts[product.index] = formValue
      setValue('products', selectedProducts)
    } else {
      setValue('products', [...selectedProducts, formValue])
    }
    setProduct(undefined)
  }

  const columns = _rendorColumns(control, handleEditProduct, handleRemoveProduct, !!rop)

  return (
    <Paper className="flex flex-col">
      {openProductCreationDialog && (
        <PurchaseProductCreateDialog
          open={openProductCreationDialog}
          onClose={() => {
            setOpenProductCreationDialog(false)
            setProduct(undefined)
          }}
          onDialogSubmit={handleSubmitNewPurchaseProduct}
          product={product}
          options={{ existingProductCodeList, currency }}
        />
      )}
      <FlexBox justifyContent="space-between" alignItems="center" className="pb-6">
        <SubTopic>สินค้า/บริการ ที่เลือก</SubTopic>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddCircle />}
          onClick={() => {
            setOpenProductCreationDialog((prev) => !prev)
          }}
        >
          เพิ่มสินค้า/บริการ ที่ไม่เคยมีในระบบ
        </Button>
      </FlexBox>
      <Table<SelectedPurchaseProductTableData>
        size="small"
        color="primary.dark"
        columns={columns}
        data={parserProducts || []}
        cleanSpace
        pagination={false}
      />
      {!!errors.products && <Typography color="error">{errors.products.message}</Typography>}
    </Paper>
  )
}

export default SelectedPurchaseProductTable

const _rendorColumns = (
  control: Control<PurchaseProductSelectorFormValues>,
  handleEditProduct: (index: number) => void,
  handleRemoveProduct: (index: number) => void,
  rop: boolean,
) => {
  const defaultColumns = [
    {
      name: 'ลำดับ',
      fixedWidth: 72,
      render: (_, index) => (
        <FlexBox justifyContent="space-around">
          <Typography color="textSecondary" variant="body2">
            {index + 1}
          </Typography>
        </FlexBox>
      ),
    },
    {
      name: 'ชื่อสินค้า/บริการ',
      fixedWidth: 350,
      render: ({ imageUrl, name, code, isNew }) => (
        <FlexBox alignItems="flex-start">
          <Avatar variant="rounded" src={imageUrl} objectFit="contain">
            <Photo />
          </Avatar>
          <FlexBox flexDirection="column" ml={1} className="purchase-product-header">
            <FlexBox gridGap={8}>
              <Typography variant="body2" className="purchase-product-header__code">
                {code}
              </Typography>
              {isNew && <Chip label="ใหม่" style={{ backgroundColor: '#FF5F52' }} size="small" />}
            </FlexBox>
            <Typography variant="body2" className="purchase-product-header__name">
              {name}
            </Typography>
          </FlexBox>
        </FlexBox>
      ),
    },
    {
      name: 'จำนวน',
      fixedWidth: 190,
      render: ({ amount }, index) => (
        <GeneralTextFieldForm
          control={control}
          name={`products.${index}.amount`}
          rules={{ required: 'กรุณากรอกจำนวน' }}
          defaultValue={amount || ''}
          type="number"
        />
      ),
    },
    {
      name: 'ราคาต่อหน่วย',
      fixedWidth: 235,
      render: ({ price }, index) => (
        <GeneralTextFieldForm
          control={control}
          name={`products.${index}.price`}
          defaultValue={price || ''}
          type="number"
        />
      ),
    },
    {
      name: '',
      fixedWidth: 56,
      render: ({ isNew }, index) => {
        return (
          isNew && (
            <IconButton onClick={() => handleEditProduct(index)}>
              <Edit />
            </IconButton>
          )
        )
      },
    },
    {
      name: '',
      fixedWidth: 56,
      render: (_, index) => (
        <IconButton onClick={() => handleRemoveProduct(index)}>
          <Delete color="inherit" />
        </IconButton>
      ),
    },
  ] as TColumn<SelectedPurchaseProductTableData>[]
  if (rop) {
    let clonedColumns = [...defaultColumns]
    clonedColumns.splice(2, 0, {
      name: 'จำนวนที่ควรซื้อต่อครั้ง',
      fixedWidth: 120,
      render: ({ recommendPurchaseAmount }) => (
        <FlexBox justifyContent="space-around">
          <Typography color="textSecondary" variant="body2">
            {recommendPurchaseAmount ? formatNumber(recommendPurchaseAmount) : '-'}
          </Typography>
        </FlexBox>
      ),
    })
    return clonedColumns
  }
  return defaultColumns
}
