import { BaseData } from '../api/smartbestbuys-api'
import { DocumentStatus, PurchaseOrderPaymentStatus, PurchaseOrderStatus, QuotationStatus } from '../enums'

export const transportTaskStatuses: BaseData[] = [
  { id: 3100, name: 'รอยืนยันข้อมูลจัดส่ง' },
  { id: 2, name: 'กำลังดำเนินการ' },
  { id: 3300, name: 'รอจบงาน' },
  { id: 200, name: 'จบงาน' },
  { id: 999, name: 'ยกเลิก' },
]

export const messengerStatuses: BaseData[] = [
  { id: 1, name: 'รอดำเนินการ' },
  { id: 2, name: 'กำลังดำเนินการ' },
  { id: 200, name: 'จบงาน' },
  { id: 400, name: 'จบงานไม่สำเร็จ' },
  { id: 998, name: 'ถูกยกเลิก' },
  { id: 999, name: 'ยกเลิก' },
]

export const isShippingProviderTypes: BaseData[] = [
  { id: 0, name: 'ขนส่งโดย SMART' },
  { id: 1, name: 'ขนส่งโดยขนส่งอื่น' },
]

export const documentStatuses: BaseData[] = [
  { id: DocumentStatus.Pending, name: 'รอส่งให้ลูกค้า' },
  { id: DocumentStatus.Sent, name: 'ส่งให้ลูกค้า' },
  { id: DocumentStatus.Cancelled, name: 'ยกเลิกแล้ว' },
]

export const purchaseOrderStatuses: BaseData[] = [
  { id: PurchaseOrderStatus.ReviewPending, name: 'รออนุมัติ' },
  { id: PurchaseOrderStatus.PaymentPending, name: 'รอชำระ' },
  { id: PurchaseOrderStatus.DepositPending, name: 'รอมัดจำ' },
  { id: PurchaseOrderStatus.AppointmentPending, name: 'รอนัดวันส่งกับผู้ขาย' },
  { id: PurchaseOrderStatus.ProductPending, name: 'รอสินค้ามาส่ง' },
  { id: PurchaseOrderStatus.VendorShippingToCustomer, name: 'รอผู้ขายส่งของให้ลูกค้า' },
  { id: PurchaseOrderStatus.PartialReceived, name: 'รับของบางส่วน' },
  { id: PurchaseOrderStatus.Received, name: 'รับของเรียบร้อย' },
  { id: PurchaseOrderStatus.LeaderPending, name: 'ส่งเรื่องให้หัวหน้าช่วยยกเลิก' },
  { id: PurchaseOrderStatus.Rejected, name: 'ไม่อนุมัติ' },
  { id: PurchaseOrderStatus.Canceled, name: 'ยกเลิก' },
]

export const purchaseOrderPaymentStatuses: BaseData[] = [
  { id: PurchaseOrderPaymentStatus.Pending, name: 'ยังไม่ชำระ' },
  { id: PurchaseOrderPaymentStatus.Deposited, name: 'ชำระมัดจำแล้ว' },
  { id: PurchaseOrderPaymentStatus.Paid, name: 'ชำระครบถ้วน' },
]

export const quotationStatuses = [
  { id: QuotationStatus.Pending, name: 'รออนุมัติจากลูกค้า' },
  { id: QuotationStatus.Approve, name: 'ลูกค้าอนุมัติ' },
  { id: QuotationStatus.Cancelled, name: 'ยกเลิกใบเสนอราคา' },
  { id: QuotationStatus.Rejected, name: 'ลูกค้าไม่อนุมัติ' },
]

export const purchaseRequisitionStatues: BaseData[] = [
  { id: 101, name: 'รอตรวจสอบ' },
  { id: 201, name: 'อนุมัติ' },
  { id: 202, name: 'ไม่อนุมัติ' },
  { id: 999, name: 'ยกเลิกแล้ว' },
]

export const purchaseRequisitionTopics: BaseData[] = [
  { id: 1001, name: 'ซื้อใช้ภายในองค์กร' },
  { id: 1002, name: 'ซื้อเพื่อนำสินค้าขาย' },
  { id: 1003, name: 'เติมสินค้าเข้าคลังเมื่อถึงจุดที่ต้องซื้อ' },
  { id: 1004, name: 'เติมสินค้าสำรองเผื่อการขาย' },
  { id: 2001, name: 'พบสินค้าเสียหาย' },
]

export const warehouseTaskStatuses: BaseData[] = [
  { id: 200, name: 'จบงาน' },
  { id: 999, name: 'ยกเลิก' },
]
