export enum PageName {
  HOME = 1,
  // CUSTOMER
  CUSTOMER = 2,
  QUOTATION = 3,
  SALE_ORDER = 4,
  INVOICE = 5,
  TAX_INVOICE = 6,
  RECEIPT = 7,
  BILLING_NOTE = 8,
  CREDIT_NOTE = 9,
  TRANSPORT = 10,
  TRANSPORT_PICKUP = 11,
  WAREHOUSE_PREPARE_TO_SHIP = 33333,
  WAREHOUSE_PEDNING_CUSTOMER_PICKUP = 33334,
  WAREHOUSE_PENDING_VENDOR_SHIPPING = 33335,
  WAREHOUSE_RETURN_PRODUCT_TASK = 33336,
  WAREHOUSE_RECEIVE_PRODUCT_BACK_TASK = 33337,
  SO_PAYMENT = 12,
  SO_DEBTOR = 13,
  USER = 14,
  // PRODUCT
  PRODUCT = 15,
  PRODUCT_TRANSACTION = 1500001,
  PRODUCT_ANALYTIC = 1500002,
  PRODUCT_MUST_TO_PURCHASE = 1500003,
  //
  FINANCE = 16,
  VENDOR = 17,
  PRODUCT_REORDER_POINT = 18,
  SHIPPING_PENDING = 19,
  SALE_ORDER_DAILY_PAYMENT = 20,
  PERMISSION = 21,

  // CUSTOMER REQUISITION
  CUSTOMER_REQUISITION = 220001,
  CUSTOMER_REQUISITION_PENDING_PICKUP = 220002,
  CUSTOMER_REQUISITION_SHIPPING_PENDING = 220003,
  CUSTOMER_REQUISITION_LIST = 220004,

  // CREDITOR
  CREDITOR = 230001,

  // SETTING
  SETTING = 24,
  SALES_GOAL_ALIGNMENT = 240001,

  // PURCHASE
  PURCHASE_REQUISITION = 90001,
  PURCHASE_ORDER = 91001,
  PENDING_PAYMENT_PURCHASE_ORDER = 91002,
  PAYMENT_REQUISITION = 92001,
  PURCHASE_PAYMENT = 92011,
  PURCHASE_FOLLOW_SERVICE_TASK = 93001,
  // FINANCE
  FINANCE_PREPARE_DOCUMENT_TASK = 400001,
  FINANCE_RETURN_DOCUMENT_TASK = 400002,
  FINANCE_TRANSPORT_PAYMENT_DAILY_SUMMARY = 400005,
  FINANCE_REFUND_REQUISITION_LIST = 400006,
  FINANCE_PAYOUT_DAILY_PAYMENT = 400007,
  // MESSENGER
  MESSENGER_TASK = 400003,
  MESSENGER_TASK_DAILY_SUMMARY = 400004,

  // TRANSPORT PICKUP
  TRANSPORT_PICKUP_TASK = 110001,
  TRANSPORT_PICKUP_SUMMARY = 110002,
  MESSENGER_PICKUP_TASK = 110003,
  MESSENGER_PICKUP_TASK_DAILY_SUMMARY = 110004,

  // MARKETING
  MARKETING = 120001,
  MARKETING_SALES_REPORT = 120002,
  MARKETING_SALES_GOAL_REPORT = 120003,
  MARKETING_PRODUCT_DASHBOARD_REPORT = 120004,
  MARKETING_PRODUCT_YEARLY_REPORT = 120005,

  // PRODUCT WITHDRAWAL
  PRODUCT_WITHDRAWAL_TABLE_DATA = 130001,
}
