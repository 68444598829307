import { DialogProps } from '@material-ui/core'
import { cancelPurchaseRequisition, UserActivityNote } from '../../api/smartbestbuys-api'
import { UserAction } from '../../enums'
import { UserActivityDialog, UserActivityDialog_TextOption, UserActivityValues } from '../../shared-components'
import { UpdatedPurchaseRequisitionStatus } from '../../api/smartbestbuys-api/purchase-requisitions/types'

interface Props extends DialogProps {
  purchaseRequisitionId: number
  purchaseRequisitionCode: string
  onComplete: (data: UpdatedPurchaseRequisitionStatus) => void
  onFail: () => void
}

const PurchaseRequisitionCancelationDialog: React.FC<Props> = (props: Props) => {
  const { purchaseRequisitionId, purchaseRequisitionCode, onComplete, onFail, ...dialogProps } = props

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการยกเลิกคำขอซื้อ',
    description: `ยกเลิกคำขอซื้อหมายเลข ${purchaseRequisitionCode} นี้ใช่หรือไม่?`,
    actionMessageText: 'สาเหตุของการยกเลิก',
    submitText: 'ยืนยันการยกเลิก',
    cancelText: 'ย้อนกลับ',
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { actionMessageId, actionMessage, remark } = values
    const request: UserActivityNote = {
      actionMessageId: actionMessageId !== -1 ? actionMessageId : undefined,
      actionMessage,
      remark,
    }
    const response = await cancelPurchaseRequisition(purchaseRequisitionId, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.CancelPurchaseRequisition}
      textOption={textOption}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default PurchaseRequisitionCancelationDialog
