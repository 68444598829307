import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Paper, Grid } from '@material-ui/core'

import { Form, Topic, withLayout } from '../../../custom-components'
import { SubmitForm, SwitchBox, ModifyPriceRange, Loading } from '../../../shared-components'
import { createProductService } from './service'
import { RootContext } from '../../..'

import ProductCreationFormValue from './ProductCreationFormValues'
import { ProductInformationInput } from './ProductCreationForm'
import { useProductCreation } from '../../../hooks/useProductCreation'

const ProductCreationPage: React.FC = () => {
  const formMethod = useForm<ProductCreationFormValue>({
    defaultValues: {
      priceRange: [],
      typeOfCodeProduct: 1,
      leadTime: 7,
      safetyStockPolicy: 7,
      dealerPrice: 0,
    },
  })

  const {
    setValue,
    watch,
    formState: { isSubmitting },
  } = formMethod

  const { triggerSnackbar } = useContext(RootContext)
  const productCreationInfo = useProductCreation()

  const { priceRange } = watch()
  const history = useHistory()
  if (!productCreationInfo) return <Loading />
  const { businessUnits, productStorages } = productCreationInfo

  const handleSubmitProductForm = async (values: ProductCreationFormValue) => {
    const response = await createProductService(values)
    if (response?.status === 201) {
      triggerSnackbar('สร้างสินค้าสำเร็จ')
      history.replace(`${response?.data.id}/profile`)
    } else {
      triggerSnackbar('สร้างไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
    }
    return true
  }

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={handleSubmitProductForm}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Topic>สร้างสินค้า</Topic>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <ProductInformationInput businessUnits={businessUnits} productStorages={productStorages} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SwitchBox
              title="สร้างช่วงราคา"
              onToggle={(enabled) => {
                setValue('hasPriceRange', enabled)
                if (enabled && priceRange.length === 0) {
                  setValue(`priceRange`, [
                    {
                      id: 1,
                      type: 1,
                      fromAmount: 1,
                      toAmount: 6,
                      price: 0,
                    },
                  ])
                }
              }}
              enabled={priceRange.length > 0}
            >
              <ModifyPriceRange />
            </SwitchBox>
          </Grid>
          {/* <Grid item xs={12}>
            <Paper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title>สต็อกเริ่มต้น</Title>
                </Grid>
                <Grid item xs={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="productStockAmount"
                    rules={{ required: 'กรุณากรอกจำนวนสินค้าในสต็อก' }}
                    type="number"
                    label="จำนวนสินค้าในสต็อก *"
                    defaultValue=""
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid> */}
          <Grid item xs={12}>
            <SubmitForm submitText="ยืนยันสร้างสินค้า" cancelText="ยกเลิก" isSubmitting={isSubmitting} />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default withLayout(ProductCreationPage)
