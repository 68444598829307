import { Card, Grid, Paper, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { useMemo } from 'react'
import { TransportTaskCreateDataV2_CustomerRequisitionProduct } from '../../../api/smartbestbuys-api'
import { Avatar, FlexBox, Table, Title, Topic, TypographyWithLabel } from '../../../custom-components'
import { dateFormat, getAddressText } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { Space, SubmitForm } from '../../../shared-components'
import { TransportPickupTaskFormData } from './TransportPickupTaskForm'
import { TransportPickupTaskFormValues } from './TransportPickupTaskFormValues'

interface Props {
  submitting?: boolean
  formData: TransportPickupTaskFormData
  formValues: TransportPickupTaskFormValues
  onSubmit: (values: TransportPickupTaskFormValues) => void
  onBack: () => void
}

const TransportPickupTaskVerificationForm = (props: Props) => {
  const { submitting, onBack, formData, formValues, onSubmit } = props

  const { users, customerShippingAddresses, customerRequisitionInfo } = formData

  const values = formValues

  const assigneeUser = useMemo(() => {
    return users.find((u) => u.id === values.assigneeUserId)
    // eslint-disable-next-line
  }, [values.assigneeUserId])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>ตรวจทานรายละเอียดงานบริการรับของสำหรับคำขอลูกค้า {customerRequisitionInfo?.code}</Topic>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <FlexBox justifyContent="space-between">
            <Title>ผู้รับผิดชอบงาน</Title>
            <Typography variant="body1" color="textSecondary">
              {assigneeUser?.firstName} {assigneeUser?.lastName}
            </Typography>
          </FlexBox>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Title>รายละเอียดงานบริการรับของ</Title>
          <Space />
          <Grid container spacing={2}>
            {!!values.dueDate && (
              <Grid item xs={6}>
                <TypographyWithLabel label="วันที่รับสินค้า:">{dateFormat(values.dueDate)}</TypographyWithLabel>
              </Grid>
            )}
            <Grid item xs={6}>
              <TypographyWithLabel label="เลขทะเบียนรถ:">{values.car?.name ?? 'ไม่ระบุ'}</TypographyWithLabel>
            </Grid>
            <Grid item xs={6}>
              <TypographyWithLabel label="ผู้ขนส่งของ:">{values.messenger.name}</TypographyWithLabel>
            </Grid>
            {!!values.remark && (
              <Grid item xs={6}>
                <TypographyWithLabel label="หมายเหตุ:">{values.remark}</TypographyWithLabel>
              </Grid>
            )}
          </Grid>
          <Space />
          <Card variant="outlined">
            <Title>รายละเอียดที่อยู่</Title>
            <Space />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TypographyWithLabel label="รายละเอียดที่อยู่:">
                  {getAddressText(
                    values.customerShippingAddress.id
                      ? customerShippingAddresses.find((address) => address.id === values.customerShippingAddress.id)
                      : values.customerShippingAddress,
                  )}
                </TypographyWithLabel>
              </Grid>

              <Grid item xs={6}>
                <TypographyWithLabel label="เบอร์โทร:">{values.phoneNumber}</TypographyWithLabel>
              </Grid>
              {!!values.customerShippingAddress.googleMapLink && (
                <Grid item xs={6}>
                  <TypographyWithLabel label="ลิงก์ googlemap:">
                    {values.customerShippingAddress.googleMapLink}
                  </TypographyWithLabel>
                </Grid>
              )}
              <Grid item xs={6}>
                <TypographyWithLabel label="ชื่อผู้ติดต่อ:">{values.consignee}</TypographyWithLabel>
              </Grid>
            </Grid>
          </Card>
        </Paper>
      </Grid>
      {!!customerRequisitionInfo?.products && customerRequisitionInfo.products.length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <Title>ข้อมูลสินค้า</Title>
            <Space />
            <Table<TransportTaskCreateDataV2_CustomerRequisitionProduct>
              color="primary.dark"
              columns={[
                { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70, align: 'center' },
                {
                  name: 'ชื่อสินค้า',
                  render: ({ imageUrl, nameLocal, code }) => (
                    <FlexBox alignItems="flex-start">
                      <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                        <Photo />
                      </Avatar>
                      <FlexBox flexDirection="column" ml={1}>
                        <Typography variant="body2" color="textSecondary">
                          {code}
                        </Typography>
                        <Typography variant="body2">{nameLocal}</Typography>
                      </FlexBox>
                    </FlexBox>
                  ),
                },
                { name: 'จำนวนที่รับ', align: 'right', render: ({ amount }) => formatNumberInt(amount) },
              ]}
              data={customerRequisitionInfo.products}
              cleanSpace
              size="small"
              pagination={false}
            />
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <SubmitForm
          submitText="สร้างงานบริการรับของ"
          onSubmit={() => onSubmit(formValues)}
          cancelText="ยกเลิก"
          onCancel={onBack}
          isSubmitting={submitting}
        />
      </Grid>
    </Grid>
  )
}

export default TransportPickupTaskVerificationForm
