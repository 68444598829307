import React, { PropsWithChildren, useState } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import {
  Container,
  withWidth,
  isWidthDown,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Avatar,
  Select,
  MenuItem,
} from '@material-ui/core'
import { AccountBox, ExitToApp, Menu as MenuIcon } from '@material-ui/icons'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { useStyles } from './styles'
import BackofficeDrawer from './Drawer'
import ScrollToTop from './ScrollToTop'
import { version } from '../../../package.json'
import { clearSession, getUser } from '../../functions'
import { FlexBox, Link } from '..'
import useMyUserProfile from '../../hooks/user/useMyUserProfile'

interface LayoutProps {
  width: Breakpoint
  fullWidth: boolean
}

const Layout: React.FC<PropsWithChildren<LayoutProps>> = (props) => {
  const { width, fullWidth, children } = props
  const [openDrawer, setOpenDrawer] = useState(!isWidthDown('sm', width))
  const classes = useStyles()

  const userInfo = getUser()

  //hook
  const [userProfile] = useMyUserProfile()

  const _closeDrawer = () => setOpenDrawer(false)
  const _toggleDrawer = () => setOpenDrawer((prev) => !prev)

  return (
    <>
      <React.Fragment>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Box display="flex" alignItems="center">
              <IconButton color="inherit" aria-label="open drawer" onClick={_toggleDrawer} edge="start">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className={classes.appName}>
                SmartBestBuys Backoffice
                <Typography className={classes.versionCaption} variant="caption">
                  v{version}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end" width={200} gridGap={10}>
              <Avatar
                alt={userInfo?.firstName.toLocaleUpperCase() ?? ''}
                src={userProfile?.imageUrl ?? 'No Link'}
                style={{ background: 'purple' }}
              ></Avatar>
              <FlexBox>
                <Select
                  className={classes.myProfile}
                  displayEmpty
                  renderValue={() => userInfo?.firstName.toLocaleUpperCase() ?? ''}
                  style={{ color: 'white' }}
                >
                  <MenuItem>
                    <Link
                      color="textPrimary"
                      variant="body2"
                      style={{ cursor: 'pointer' }}
                      href="/users/my-profile"
                      target="_blank"
                    >
                      <AccountBox /> ดูโปรไฟล์
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      color="textPrimary"
                      variant="body2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => clearSession()}
                    >
                      <ExitToApp /> ออกจากระบบ
                    </Link>
                  </MenuItem>
                </Select>
              </FlexBox>
            </Box>
          </Toolbar>
        </AppBar>
        <Toolbar id="top" />
        <BackofficeDrawer open={openDrawer} onClose={_closeDrawer} />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: openDrawer,
          })}
        >
          {fullWidth ? (
            <div className={classes.container}>{children}</div>
          ) : (
            <Container className={classes.container}>{children ?? ''}</Container>
          )}
        </main>
        <ScrollToTop />
      </React.Fragment>
    </>
  )
}

const LayoutWithWidth = withWidth()(Layout)

export default LayoutWithWidth

export const withLayout = <T extends unknown>(Component: React.FC<any & { query: T }>, fullWidth: boolean = false) => {
  const _parseToQueryParams = (searchParams: URLSearchParams): any => {
    const keys = searchParams.keys()
    let parsed = {}

    while (1) {
      const next = keys.next()
      if (next.done) break
      const key = next.value
      const value = searchParams.get(key)
      parsed = {
        ...parsed,
        [key]: value,
      }
    }

    return parsed
  }

  return (props: any) => {
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const parsed = _parseToQueryParams(query)

    return (
      <LayoutWithWidth fullWidth={fullWidth}>
        <Component {...props} query={parsed} />
      </LayoutWithWidth>
    )
  }
}
