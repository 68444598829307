import { Button, Grid, Paper } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ICustomerProfile, ICustomerProfile_DebtSaleOrder } from '../../../../../../api/smartbestbuys-api'
import { FlexBox, Topic, FilteredTable, EmptyCard } from '../../../../../../custom-components'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { dateTimeFormat } from '../../../../../../functions'
import { ConditionTooltip } from '../../../../../../shared-components'
import { getUserPermissionInfo } from '../../../../../../utils/permission'

interface Props {
  customerProfile: ICustomerProfile
}

const CustomerDebtSaleOrder: React.FC<Props> = (props) => {
  const { customerProfile } = props

  const history = useHistory()

  const isActiveCustomer = !customerProfile.recDeletedAt
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_RefundRequisition_Write)
  const disableWrite = !writePermission.hasPermission || !isActiveCustomer

  // find sale orders that is already refunded
  let refendedSaleOrders: any[] = []
  customerProfile.refundRequisitions?.forEach((refund) => {
    const saleOrders = refund.saleOrders

    refendedSaleOrders = [...refendedSaleOrders, ...saleOrders]
  })

  // filter dept sale orders
  const debtSaleOrders = customerProfile.debtSaleOrders?.filter((dept) => {
    const refundedTotal = refendedSaleOrders
      .filter((so) => so.id === dept.id)
      .reduce<number>((sum, each) => sum + each.refundAmount, 0)

    return refundedTotal ? dept.debtTotal > refundedTotal : true
  })

  if (!debtSaleOrders || debtSaleOrders.length === 0) {
    return (
      <Paper>
        <EmptyCard emptyText="ไม่มีรายการหนี้" />
      </Paper>
    )
  }

  return (
    <Paper>
      <Grid container style={{ padding: '10px' }}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <Topic>ใบสั่งขายที่เป็นหนี้</Topic>
            <ConditionTooltip
              title={
                !writePermission.hasPermission ? writePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
              }
              showTooltip={disableWrite}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Description />}
                onClick={() => history.push(`/customers/${customerProfile.id}/request-to-refund`)}
                disabled={disableWrite}
              >
                ทำเบิกคืนเงิน
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<ICustomerProfile_DebtSaleOrder>
            color="grey.800"
            columns={[
              {
                name: 'เลขที่ใบสั่งขาย',
                dataIndex: 'code',
              },
              {
                name: 'ชื่อผู้ติดต่อ',
                render: ({ contactName }) => contactName ?? '-',
              },
              {
                name: 'เบอร์ผู้ติดต่อ',
                render: ({ contactPhoneNumber }) => contactPhoneNumber ?? '-',
              },
              {
                name: 'หมายเหตุการยกเลิก',
                dataIndex: 'remark',
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
              {
                name: 'สร้างโดย',
                dataIndex: 'recCreatedBy',
              },
              {
                name: 'ยอดหนี้รวม',
                dataIndex: 'debtTotal',
                numeric: true,
                format: '0,0.00',
              },
            ]}
            disableDateRangeFilter
            data={debtSaleOrders ?? []}
            size="small"
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerDebtSaleOrder
