import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Button, Collapse, Grid, InputAdornment, Paper, Switch } from '@material-ui/core'
import { FlexBox, SubTopic, GeneralTextFieldForm } from '../../../../../../custom-components'
import SOUpdationFormValue from '../SOUpdationFormValues'
import _ from 'lodash'
import { SQLCommand } from '../../../../../../sales-system/types'

const ProductDiscountBox: React.FC = () => {
  const { control, watch, setValue } = useFormContext<SOUpdationFormValue>()

  const { discountEnabled, selectedProducts } = watch()

  const validProducts = selectedProducts.filter((sp) => sp.command !== SQLCommand.DELETE)

  const total = !!validProducts && validProducts.length > 0 ? _.sum(validProducts.map((p) => p.amount * p.price)) : 0

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FlexBox justifyContent="flex-start">
            <SubTopic>ส่วนลดสินค้า</SubTopic>
          </FlexBox>
        </Grid>
        <Grid item xs={6}>
          <FlexBox justifyContent="flex-end">
            <Switch
              size="medium"
              color="primary"
              checked={!!discountEnabled}
              onClick={() => setValue('discountEnabled', !discountEnabled)}
            />
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={!!discountEnabled}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GeneralTextFieldForm
                  control={control}
                  name="discount"
                  fullWidth
                  label="ส่วนลด *"
                  rules={{ required: { value: !!discountEnabled, message: 'กรุณาใส่ราคาส่วนลด' } }}
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} direction="row" justify="flex-start">
                  {discountOptions.map((d) => (
                    <Grid item>
                      <Button
                        key={d}
                        // className={classes.percentButton}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => setValue('discount', (total * d) / 100)}
                      >
                        {d}%
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  )
}

const discountOptions = [5, 10, 15]

export default ProductDiscountBox
