import { BaseData } from '../api/smartbestbuys-api'

export const undefinedShippingCar: BaseData = {
  id: -1,
  name: 'ยังไม่ระบุรถ',
}

export const unknownShippingCar: BaseData = {
  id: 99,
  name: 'อื่นๆ',
}

export const shippingCars: BaseData[] = [
  { id: 1, name: 'ฒฎ 6172' },
  { id: 2, name: 'ฒอ 706' },
  { id: 3, name: 'ฒม 7752' },
  { id: 4, name: '1 ฒช 9464' },
  { id: 5, name: '1 ฒว 9417' },
  { id: 6, name: '2 ฒต 8819' },
  { id: 7, name: '2 ขพ 6518' },
  { id: 8, name: '2 ขฎ 2628' },
  { id: 9, name: 'นนย 228' },
  { id: 10, name: '3ฒฌ 8519' },
  { id: 11, name: '2ฒค 2853' },
]

export const shippingCarOptions: BaseData[] = [...shippingCars, undefinedShippingCar, unknownShippingCar]
