import { IconButton, Paper, Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import numeral from 'numeral'
import { useState } from 'react'
import { PaymentRequisitionSelectedItem } from '../../../api/smartbestbuys-api'
import { SubTopic, Table, TColumn } from '../../../custom-components'
import { PurchaseOrderStatusChip } from '../../../shared-components'
import PaymentRequisitionSelectedDialog from './PaymentRequisitionSelectedDialog'

interface Props {
  onChange?: (items: PaymentRequisitionSelectedItem[]) => void
  value: PaymentRequisitionSelectedItem[]
  readOnly?: boolean
}

const PaymentRequisitionItemTable: React.FC<Props> = (props: Props) => {
  // props and state
  const { value, onChange, readOnly } = props
  const [selected, setSelected] = useState<PaymentRequisitionSelectedItem>()

  const handleSelect = (data: PaymentRequisitionSelectedItem) => {
    setSelected(data)
  }
  const handleRemove = (data: PaymentRequisitionSelectedItem) => {
    const clonedValue = [...value]
    clonedValue.splice(
      value.findIndex((v) => v.id === data.id),
      1,
    )
    if (!!onChange) onChange([...clonedValue])
  }

  return (
    <Paper>
      {!!selected && (
        <PaymentRequisitionSelectedDialog
          open={!!selected}
          value={selected}
          onComplete={(data) => {
            setSelected(undefined)
            const index = value.findIndex((v) => v.id === data.id)
            if (!!onChange) onChange([...value.slice(0, index), data, ...value.slice(index + 1)])
          }}
          onClose={() => {
            setSelected(undefined)
          }}
        />
      )}
      <SubTopic>ข้อมูลสินค้า/บริการ</SubTopic>
      <div className="mb-4" />
      <Table<PaymentRequisitionSelectedItem>
        color="primary.dark"
        columns={buildColumns(handleSelect, handleRemove, readOnly)}
        data={value}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default PaymentRequisitionItemTable

const buildColumns = (
  onSelect: (data: PaymentRequisitionSelectedItem) => void,
  onRemove: (data: PaymentRequisitionSelectedItem) => void,
  readOnly?: boolean,
): TColumn<PaymentRequisitionSelectedItem>[] => {
  const paymentRequisitionItemColumns = [
    { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
    {
      name: 'สถานะใบสั่งซื้อ',
      render: ({ status }) => <PurchaseOrderStatusChip status={status.id} label={status.name} />,
    },
    {
      name: 'เลขที่ใบสั่งซื้อ',
      dataIndex: 'code',
      render: ({ code }) => (
        <Typography variant="body2" color="primary">
          {code}
        </Typography>
      ),
    },
    {
      name: 'ยอดทำเบิก',
      render: ({ payTotal }) => payTotal,
      numeric: true,
      format: '0,0.00',
    },
    {
      name: 'หัก ณ ที่จ่าย',
      render: ({ payWithholdingTaxAmount, payWithholdingTaxPercentage }) => {
        const data = !!payWithholdingTaxAmount
          ? `${numeral(payWithholdingTaxAmount).format('0,0.00')} (${payWithholdingTaxPercentage}%)`
          : '-'
        return data
      },
    },
    {
      name: 'ยอดทำเบิกสุทธิ',
      render: ({ payTotal, payWithholdingTaxAmount }) => {
        return !!payWithholdingTaxAmount ? payTotal - payWithholdingTaxAmount : payTotal
      },
      numeric: true,
      format: '0,0.00',
    },
  ] as TColumn<PaymentRequisitionSelectedItem>[]

  if (!!readOnly) return paymentRequisitionItemColumns
  return [
    ...paymentRequisitionItemColumns,
    {
      name: '',
      fixedWidth: 56,
      render: (record) => {
        return (
          <IconButton style={{ padding: '0px' }} color="secondary" onClick={() => onSelect(record)}>
            <Edit />
          </IconButton>
        )
      },
    },
    {
      name: '',
      fixedWidth: 56,
      render: (record) => (
        <IconButton
          style={{ padding: '0px' }}
          onClick={() => {
            onRemove(record)
          }}
        >
          <Delete color="inherit" />
        </IconButton>
      ),
    },
  ] as TColumn<PaymentRequisitionSelectedItem>[]
}
