import { Tooltip, TooltipProps } from '@material-ui/core'

interface ConditionTooltipProps extends TooltipProps {
  showTooltip: boolean
}

const ConditionTooltip = ({ showTooltip, children, ...tooltipProps }: ConditionTooltipProps) => {
  if (showTooltip) {
    return (
      <Tooltip {...tooltipProps} style={{ cursor: 'not-allowed' }}>
        <div>{children}</div>
      </Tooltip>
    )
  }

  return children
}

export default ConditionTooltip
