import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { withLayout, FilteredTable, Topic, Link, FlexBox } from '../../../custom-components'
import {
  DocumentCancellationDialog,
  DocumentStatusChip,
  SOStatus,
  Space,
  SOPaymentStatus,
  NotificationHead,
} from '../../../shared-components'
import { defaultStartDate, defaultEndDate } from '../../../data'
import { useDocumentTaxInvoices } from '../../../hooks/useDocumentTaxInvoices'
import { dateTimeFormat } from '../../../functions/date-formatter'
import { calculateExcludingVat } from '../../../functions/price-calculator'
import { RootContext } from '../../..'
import { DocumentStatus, NavigationItemKey, PageName } from '../../../enums'
import { TaxInvoiceTableData } from '../../../api/smartbestbuys-api'
import { Cancel } from '@material-ui/icons'
import lodash from 'lodash'
import SaleOrderProfileContext from '../../sale-orders/id/SaleOrderProfilePage/SaleOrderProfileContext'
import numeral from 'numeral'
import { formatNumber } from '../../../functions/number-utils'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const DocumentTaxInvoicesPage: React.FC = (props) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const { reload } = useContext(SaleOrderProfileContext)
  setCurrentPage(PageName.TAX_INVOICE)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [idToCancelId, setIdToCancel] = useState<number | undefined>(undefined)
  const taxInvoices = useDocumentTaxInvoices(startDate, endDate)

  const [data, setData] = useState<TaxInvoiceTableData[]>([])

  useEffect(() => {
    if (taxInvoices.length > 0) {
      setData(taxInvoices)
    }
  }, [taxInvoices])

  const documentStatuses = useMemo(() => {
    return lodash
      .chain(taxInvoices)
      .uniqBy('status.id')
      .orderBy('status.id')
      .value()
      .map((doc) => {
        return {
          name: doc.status.name,
          value: doc.status.id,
        }
      })
    // eslint-disable-next-line
  }, [taxInvoices.map((doc) => doc.code).toString()])

  // permission
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>รายการใบกำกับภาษี</Topic>
      </Grid>
      <Grid item xs={12}>
        <NotificationHead navigationKey={NavigationItemKey.TaxInvoice} />
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<TaxInvoiceTableData>
          color="primary.dark"
          columns={[
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
            },
            {
              name: 'เลขใบกำกับภาษี',
              csvRender: ({ code }) => code,
              render: ({ id, code }) => (
                <Link color="primary" to={`/tax-invoices/${id}/pdf`} target="_blank">
                  {code}
                </Link>
              ),
            },
            {
              name: 'เลขใบเสร็จรับเงิน | ใบวางบิล',
              csvRender: (taxInvoice) => {
                const validReceipts = taxInvoice.refReceipts
                  .filter((doc) => doc.status.id !== DocumentStatus.Cancelled)
                  .map((doc) => doc.code)
                const validBillingNotes = taxInvoice.refBillingNotes
                  .filter((doc) => doc.status.id !== DocumentStatus.Cancelled)
                  .map((doc) => doc.code)
                return [validReceipts[0] ?? '--', validBillingNotes[0] ?? '--'].join(' | ')
              },
              render: (taxInvoice) => {
                const validReceipts = taxInvoice.refReceipts
                  .filter((doc) => doc.status.id !== DocumentStatus.Cancelled)
                  .map((doc) => doc.code)
                const validBillingNotes = taxInvoice.refBillingNotes
                  .filter((doc) => doc.status.id !== DocumentStatus.Cancelled)
                  .map((doc) => doc.code)
                return (
                  <Typography color="textSecondary" variant="body2">
                    {[validReceipts[0] ?? '--', validBillingNotes[0] ?? '--'].join(' | ')}
                  </Typography>
                )
              },
            },
            {
              name: 'เลขใบสั่งขายอ้างอิง',
              csvRender: (taxInvoice) => taxInvoice?.saleOrderInfo?.code,
              render: (taxInvoice) => (
                <Link color="secondary" to={`/sale-orders/${taxInvoice.saleOrderInfo?.id}`}>
                  {taxInvoice.saleOrderInfo?.code}
                </Link>
              ),
            },
            {
              name: 'รายได้อ้างอิง (SPAY)',
              csvRender: (refSaleOrderPaymentInfo) => refSaleOrderPaymentInfo?.code,
              render: ({ refSaleOrderPaymentInfo }) =>
                refSaleOrderPaymentInfo?.code ? (
                  <Typography color="secondary" variant="body2">
                    {refSaleOrderPaymentInfo.code}
                  </Typography>
                ) : (
                  <Typography color="textSecondary" variant="body2">
                    -
                  </Typography>
                ),
            },
            {
              name: 'ชื่อลูกค้า',
              render: ({ customerInfo }) => customerInfo?.name,
            },
            {
              name: 'สาขา',
              render: ({ state }) => state,
            },
            {
              name: 'เลขที่ผู้เสียภาษี',
              render: ({ customerInfo }) => customerInfo?.referenceNumber,
            },
            {
              name: 'ยอดก่อน VAT',
              csvRender: (taxInvoice) =>
                taxInvoice.total ? formatNumber(calculateExcludingVat(taxInvoice.total)) : '--',
              render: (taxInvoice) =>
                taxInvoice.total ? calculateExcludingVat(taxInvoice.total) : 'ไม่สามารถคำนวณได้',
              numeric: true,
              format: '0,0.00',
              hide: true,
            },
            {
              name: 'VAT',
              csvRender: ({ total }) => {
                const vatFreeAmount = calculateExcludingVat(total ?? 0)
                return formatNumber((total ?? 0) - vatFreeAmount)
              },
              render: (taxInvoice) => {
                const { total } = taxInvoice
                const vatFreeAmount = calculateExcludingVat(total ?? 0)
                return (total ?? 0) - vatFreeAmount
              },
              numeric: true,
              format: '0,0.00',
              hide: true,
            },
            {
              name: 'ยอดเงินสุทธิ',
              dataIndex: 'total',
              csvRender: ({ total }) => formatNumber(total ?? 0),
              render: ({ total }) => {
                const vatFreeAmount = calculateExcludingVat(total ?? 0)
                return (
                  <Tooltip
                    title={
                      <div style={{ color: 'white' }}>
                        <FlexBox justifyContent="space-between">
                          <span className="font-bold">ยอดก่อน vat:</span>
                          <Space direction="horizontal" />
                          <span>{formatNumber(vatFreeAmount)}</span>
                        </FlexBox>
                        <FlexBox justifyContent="space-between">
                          <span className="font-bold">vat:</span>
                          <Space direction="horizontal" />
                          <span>{formatNumber((total ?? 0) - vatFreeAmount)}</span>
                        </FlexBox>
                        <FlexBox justifyContent="space-between">
                          <span className="font-bold">ยอดเงินสุทธิ:</span>
                          <Space direction="horizontal" />
                          <span>{formatNumber(total ?? 0)}</span>
                        </FlexBox>
                      </div>
                    }
                    placement="top-end"
                  >
                    <div className="flex items-center justify-end">
                      <span>{numeral(total).format('0,0.00')}</span>
                      <InfoOutlined style={{ fontSize: '1rem', marginLeft: 2 }} />
                    </div>
                  </Tooltip>
                )
              },
            },
            {
              name: 'สถานะใบกำกับภาษี',
              csvRender: ({ status: { name } }) => name,
              render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
            },
            {
              name: 'สถานะใบสั่งขาย | จ่ายเงิน',
              csvRender: ({ saleOrderInfo }) => {
                if (!saleOrderInfo) return '-'
                const { status, paymentStatus } = saleOrderInfo
                return `${status?.name ?? '--'} | ${paymentStatus?.name ?? '--'}`
              },
              render: ({ saleOrderInfo }) => {
                if (!saleOrderInfo) return '-'
                const { paymentStatus, status } = saleOrderInfo
                return [
                  status ? (
                    <SOStatus status={status} />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      --
                    </Typography>
                  ),
                  <Typography variant="body2" color="textSecondary">
                    {` | `}
                  </Typography>,
                  paymentStatus ? (
                    <SOPaymentStatus status={paymentStatus} />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      --
                    </Typography>
                  ),
                ]
              },
            },
            {
              name: '',
              csvRender: () => {},
              render: (taxInvoice) => {
                // TODO: support issued credit note and credit note dialog
                const isCancelled = taxInvoice.status.id === DocumentStatus.Cancelled
                return isCancelled ? (
                  <></>
                ) : (
                  <>
                    <DocumentCancellationDialog
                      documentInfo={taxInvoice}
                      open={idToCancelId === taxInvoice.id}
                      onClose={() => {
                        setIdToCancel(undefined)
                      }}
                      onSuccess={(updatedData) => {
                        triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
                        reload()
                        let newData = data
                        const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                        if (findIndex !== -1) {
                          const found = newData[findIndex]
                          const updated = {
                            ...found,
                            ...updatedData,
                          }
                          newData[findIndex] = updated
                          setData([...newData])
                        }
                      }}
                      onFail={() => {
                        triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
                      }}
                    />
                    {cancelPermission.hasPermission && (
                      <FlexBox gridGap={8}>
                        <Tooltip title="ยกเลิกใบกำกับภาษี">
                          <IconButton size="small" onClick={() => setIdToCancel(taxInvoice.id)}>
                            <Cancel fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </FlexBox>
                    )}
                  </>
                )
              },
            },
          ]}
          data={data}
          filters={[
            {
              name: 'สถานะใบกำกับภาษี',
              values: documentStatuses,
              filter: (data: TaxInvoiceTableData, values: (string | number)[]) => values.includes(data.status.id),
            },
          ]}
          defaultDateRage={{
            startDate: defaultStartDate,
            endDate: defaultEndDate,
          }}
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          size="small"
          csvFilename="รายการใบกำกับภาษี"
          downloadAsCsv
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(DocumentTaxInvoicesPage)
