import { format } from 'date-fns'
import locale from 'date-fns/locale/th'

export const dateFormat = (dateString: Date | string, pattern?: string) => {
  try {
    return format(new Date(dateString), pattern || 'dd MMM yyyy', { locale })
  } catch (error) {
    console.log(error)
    return 'invalid'
  }
}

export const dateTimeFormat = (dateString: Date | string, pattern?: string) => {
  try {
    return format(new Date(dateString), pattern || 'dd MMM yyyy HH:mm', { locale })
  } catch (error) {
    console.log(error)
    return 'invalid'
  }
}

export const dateSqlFormat = (dateString: Date | string, pattern?: 'yyyy-MM-dd') => {
  try {
    return format(new Date(dateString), pattern || 'yyyy-MM-dd HH:mm:ss', { locale })
  } catch (error) {
    console.log(error)
    return 'invalid'
  }
}

// TODO: modify function to support local modification
export const dateFormatEnglish = (dateString: Date | string, pattern?: string) => {
  try {
    return format(new Date(dateString), pattern || 'dd MMM yyyy')
  } catch (error) {
    console.log(error)
    return 'invalid'
  }
}
