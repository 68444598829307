import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../..'
import {
  UpdatedMyUserCriteria,
  updateMyUserCriteriaAPI,
  UpdateMyUserCriteriaRequestBody,
  UserProfile,
} from '../../../api/smartbestbuys-api'
import { MyUserCriteriaForm, MyUserCriteriaUpdateFormValues } from '.'

interface Props extends DialogProps {
  userProfile: UserProfile
  onComplete?: (data: UpdatedMyUserCriteria) => void
}

export const MyUserCriteriaUpdateDialog = (props: Props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { onComplete, onClose, ...dialogProps } = props

  const handleSubmit = async (values: MyUserCriteriaUpdateFormValues) => {
    const request: UpdateMyUserCriteriaRequestBody = {
      currentPassword: values.currentPassword,
      password: values.password,
    }

    try {
      const response = await updateMyUserCriteriaAPI(request)
      if (response && response.status === 200) {
        triggerSnackbar('เปลี่ยนรหัสผ่านสำเร็จ')
        if (onComplete) {
          onComplete(response.data)
          onClose && onClose({}, 'escapeKeyDown')
        }
      } else {
        triggerSnackbar('เปลี่ยนรหัสผ่านไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('เปลี่ยนรหัสผ่านไม่สำเร็จ')
    }
  }
  return (
    <Dialog {...dialogProps} fullWidth maxWidth="lg">
      <DialogTitle>เปลี่ยนรหัสผ่าน</DialogTitle>
      <DialogContent>
        <MyUserCriteriaForm onSubmit={handleSubmit} onClose={() => onClose && onClose({}, 'escapeKeyDown')} />
      </DialogContent>
    </Dialog>
  )
}
