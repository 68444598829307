import RefundRequisitionForm, { IRefundRequisitionFormValues } from './form/RefundRequisitionForm'
import { withLayout } from '../../custom-components'
import { useRefundRequisitionsCreateData } from '../../hooks'
import { useContext } from 'react'
import { createRefundRequisition, CreateRefundRequisitionRequestBody } from '../../api/smartbestbuys-api'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../..'
import { Loading } from '../../shared-components'

interface Props {
  customerId: number
}

const RefundRequisitionCreatePage = (props: Props) => {
  // todo: set current page
  const { customerId } = props

  const { triggerSnackbar } = useContext(RootContext)
  const history = useHistory()

  const formData = useRefundRequisitionsCreateData(customerId)

  if (!formData) return <Loading />

  const handleSubmit = async (values: IRefundRequisitionFormValues) => {
    const request: CreateRefundRequisitionRequestBody = {
      customerId,
      customerBankAccountId: values.customerBankAccountId,
      customerBankAccount: !values.customerBankAccountId
        ? {
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            branchName: values.branchName,
            bookBankImageBase64: values.bookBankImageBase64,
            bankId: values.bankId,
          }
        : undefined,
      total: values.debtSaleOrders.reduce((sum, dso) => sum + dso.total, 0),
      remark: values.remark,
      saleOrders: values.debtSaleOrders.map((dso) => ({
        id: dso.id,
        total: dso.total,
      })),
      attachments: values.attachments.map((att) => ({
        base64: att.base64!, // risk to broke
      })),
    }

    const response = await createRefundRequisition(request)

    if (response?.status === 201) {
      triggerSnackbar('ทำเบิกคืนเงินสำเร็จ')
      const { id } = response.data
      history.replace(`/finance/refund-requisitions/${id}/profile`)
    } else {
      triggerSnackbar('ทำเบิกคืนเงินไม่สำเร็จ')
    }
    return true
  }

  return <RefundRequisitionForm onSubmit={handleSubmit} formData={formData} />
}

export default withLayout(RefundRequisitionCreatePage)
