import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { FlexBox, GeneralTextField } from '../../../custom-components'
import { useEffect, useState } from 'react'
import { getPreviousDate } from '../../../utils/date-utils'
import { useQuotationReport, useUserData } from '../../../hooks'
import { Autocomplete } from '@material-ui/lab'
import { GridBox, Loading } from '../../../shared-components'
import { ISalesQuotationReport } from '../../../api/smartbestbuys-api'
import { formatNumberInt } from '../../../functions/number-utils'
import { NivoColorPalette, Statistic } from '../../../visaulization-components'

const QuotationReportBox: React.FC = () => {
  const [salesName, setSalesName] = useState<string | null>(null)

  const report = useQuotationReport(getPreviousDate(new Date(), 30), new Date())
  const users = useUserData()

  const [filteredData, setFilteredData] = useState<ISalesQuotationReport | undefined>(undefined)

  useEffect(
    () => {
      if (report.data) {
        if (salesName) setFilteredData(report.data[salesName])
        else {
          setFilteredData(report.data['all'])
        }
      }
    }, // eslint-disable-next-line
    [report.data, salesName],
  )

  if (!report || !users) return <Loading />

  const { pendingSearch, handleSearchParamsChange, handleSearch } = report

  return (
    <Box>
      <FlexBox justifyContent="flex-end" gridGap={8} alignItems="center">
        <GeneralTextField
          type="date"
          name="startDate"
          required
          onChange={(e) => handleSearchParamsChange('startDate', e.target.value)}
          label="จาก"
          defaultValue={
            pendingSearch?.startDate ? new Date(pendingSearch.startDate).toISOString().slice(0, 10) : undefined
          }
          InputLabelProps={{ shrink: true }}
        />
        <GeneralTextField
          type="date"
          name="endDate"
          required
          onChange={(e) => handleSearchParamsChange('endDate', e.target.value)}
          label="ถึง"
          defaultValue={pendingSearch?.endDate ? new Date(pendingSearch.endDate).toISOString().slice(0, 10) : undefined}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          size="medium"
          style={{ width: 100, height: 40 }}
          className="button"
          disabled={!pendingSearch.startDate || !pendingSearch.endDate}
        >
          ค้นหา
        </Button>
      </FlexBox>
      <FlexBox gridGap={8} alignItems="center">
        <Autocomplete
          id="sales-name"
          size="small"
          style={{ width: 300 }}
          options={users.map((user) => user.firstName + ' ' + user.lastName)}
          onChange={(_, newValue) => setSalesName(newValue)}
          renderInput={(params) => (
            <TextField {...params} margin="dense" variant="outlined" label="ชื่อฝ่ายขาย" placeholder="ชื่อฝ่ายขาย" />
          )}
        />
      </FlexBox>

      <div className="mb-2" />

      <Grid container spacing={2}>
        <GridBox item xs={6} md={3} row={6}>
          <Statistic
            title="ยอดรวมใบเสนอราคา"
            amount={filteredData?.quotationTotal ?? 0}
            format="0,0.00"
            subtitle={
              <Typography>
                <span>จำนวนใบเสนอราคา </span>
                <Box component="span" color={NivoColorPalette.oceanGreen}>
                  {formatNumberInt(filteredData?.quotationAmount ?? 0)}
                </Box>
                <span> ใบ</span>
              </Typography>
            }
            color={NivoColorPalette.oceanGreen}
          />
        </GridBox>

        <GridBox item xs={6} md={3} row={6}>
          <Statistic
            title="ยอดรวมใบเสนอราคาที่ยกเลิก"
            amount={filteredData?.cancelledQuotationTotal ?? 0}
            format="0,0.00"
            subtitle={
              <Typography>
                <span>จำนวนใบเสนอราคาที่ยกเลิก </span>
                <Box component="span" color={NivoColorPalette.intenseOrange}>
                  {formatNumberInt(filteredData?.cancelledQuotationAmount ?? 0)}
                </Box>
                <span> ใบ</span>
              </Typography>
            }
            color={NivoColorPalette.intenseOrange}
          />
        </GridBox>
      </Grid>
    </Box>
  )
}

export default QuotationReportBox
