import React from 'react'
import { Tooltip, createStyles, makeStyles } from '@material-ui/core'
import { NotiItem, notiTeamColors } from '../../api/smartbestbuys-api/general/general-types'

interface Props {
  item: NotiItem
}

const useStyles = (color: string) =>
  makeStyles((theme) => {
    return createStyles({
      badge: {
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '50%',
        fontSize: 12,
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color,
        display: 'inline-flex',
        marginRight: 2,
      },
      tooltip: {
        whiteSpace: 'pre-line',
      },
    })
  })

const NotificationBadgeMenu: React.FC<Props> = ({ item }: Props) => {
  const classes = useStyles(notiTeamColors[item.team])()

  if (item.amount <= 0) return <></>

  if (!item.name) return <span className={classes.badge}>{item.amount > 100 ? '99+' : item.amount}</span>

  return (
    <Tooltip title={item.name} classes={{ popper: classes.tooltip }}>
      <span className={classes.badge}>{item.amount > 100 ? '99+' : item.amount}</span>
    </Tooltip>
  )
}

export default NotificationBadgeMenu
