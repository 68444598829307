import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  DialogActions,
} from '@material-ui/core'
import { FollowServiceTask } from '../../../api/smartbestbuys-api'
import { FlexBox, Table, TypographyWithLabel } from '../../../custom-components'
import { TaskType } from '../../../enums'
import { ReceiveServiceAction } from '../../../enums/PurchaseEnums'
import { dateFormat } from '../../../functions'

import { formatNumberInt } from '../../../functions/number-utils'
import { usePurchaseTask } from '../../../hooks'
import { Loading, Space, TaskStatusChip } from '../../../shared-components'
import PurchaseProductName from '../../purchase-orders/create/SelectedPurchaseOrderProductTable/PurchaseProductName'
import VendorProfileCard from '../../purchase-orders/profile/VendorProfileCard'

interface Props extends DialogProps {
  receiveServiceTaskId: number
}

const AlreadyReceivedServiceDialog = (props: Props) => {
  // props
  const { receiveServiceTaskId, ...otherProps } = props

  // prepare data
  const receiveServiceTask = usePurchaseTask(receiveServiceTaskId, TaskType.FollowServiceTask)

  // loading
  if (!receiveServiceTask)
    return (
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Loading />
      </Dialog>
    )

  const { status, code, vendorInfo, purchaseOrderInfo, saleOrderInfo, state, finishedAt, finishedBy } =
    receiveServiceTask

  return (
    <Dialog {...otherProps} fullWidth maxWidth="md">
      <DialogTitle>
        <FlexBox alignItems="center">
          {code} <Space direction="horizontal" />
          <Typography color="textSecondary">งานรับบริการ</Typography>
          <Space direction="horizontal" />
          <TaskStatusChip status={status.id} label={status.name} />
        </FlexBox>
      </DialogTitle>
      <DialogContent>
        <VendorProfileCard value={vendorInfo} />
        <Space />
        <ReceiveServiceResultTable receiveProductTask={receiveServiceTask} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="วันที่ส่งสินค้า:">
              {finishedAt ? dateFormat(finishedAt) : '-'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ใบสั่งซื้ออ้างอิง:">{purchaseOrderInfo.code}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="รับโดย:">{finishedBy}</TypographyWithLabel>
          </Grid>
          {saleOrderInfo && (
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="ใบสั่งขายอ้างอิง::">{saleOrderInfo.code}</TypographyWithLabel>
            </Grid>
          )}
          {state.nextVendorShippingDate && (
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="วันที่ส่งสินค้าใหม่:">
                {dateFormat(state.nextVendorShippingDate)}
              </TypographyWithLabel>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="หมายเหตุ:">{state.remark ?? '-'}</TypographyWithLabel>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default AlreadyReceivedServiceDialog

interface ReceiveServiceResultTableProps {
  receiveProductTask: FollowServiceTask
}

interface ReceivedServiceDetail {
  id: number
  name: string
  code: string
  imageUrl: string
  receiveAction: ReceiveServiceAction
  receivedAmount?: number
  expectedAmount?: number
}

const ReceiveServiceResultTable = (props: ReceiveServiceResultTableProps) => {
  const { receiveProductTask } = props
  const {
    purchaseOrderInfo: { products },
    state,
  } = receiveProductTask
  const { results } = state

  const receviedProductDetail: ReceivedServiceDetail[] = products.map((product) => {
    const result = results?.find((r) => r.purchaseProductId === product.id)
    if (!!result)
      return {
        id: product.id,
        name: product.name,
        code: product.code,
        imageUrl: product.imageUrl,
        receiveAction: result.action,
        receivedAmount: result.receivedAmount,
        expectedAmount: result.expectedAmount,
      } as ReceivedServiceDetail

    return {
      name: product.name,
      code: product.code,
      imageUrl: product.imageUrl,
      receiveAction: ReceiveServiceAction.Nothing,
    } as ReceivedServiceDetail
  })

  return (
    <Table<ReceivedServiceDetail>
      color="primary"
      size="small"
      data={receviedProductDetail}
      columns={[
        { name: 'ลำดับ', align: 'center', fixedWidth: 70, render: (_, index) => index + 1 },
        {
          name: 'ชื่อสินค้า',
          render: (product) => (
            <PurchaseProductName imageUrl={product.imageUrl} code={product.code} name={product.name} />
          ),
        },
        {
          name: 'ผลการรับ',
          fixedWidth: 190,
          render: (product, index) => {
            switch (product.receiveAction) {
              case ReceiveServiceAction.All:
                return <Typography>รับครบ/รับที่เหลือ</Typography>
              case ReceiveServiceAction.Partial:
                return <Typography>รับขาด</Typography>
              case ReceiveServiceAction.RejectRemaining:
                return <Typography>รับขาดแต่ไม่รอรับที่ขาด</Typography>
              case ReceiveServiceAction.Nothing:
                return <Typography>ไม่มีให้รับ</Typography>
              case ReceiveServiceAction.AlreadyReceived:
                return <Typography>รับครบไปแล้ว</Typography>
              default:
                return <Typography>-</Typography>
            }
          },
        },
        {
          name: 'จำนวนรับจริง/จำนวนที่ต้องรับ',
          align: 'right',
          fixedWidth: 140,
          render: (product) => {
            if (product.receiveAction === ReceiveServiceAction.AlreadyReceived)
              return <Typography color="textSecondary">-</Typography>
            if (product.receivedAmount === undefined || product.expectedAmount === undefined)
              return <Typography color="textSecondary">-</Typography>
            let textColor = 'textPrimary'
            if (product.receivedAmount === product.expectedAmount) textColor = 'success.main'
            if (product.receivedAmount < product.expectedAmount) textColor = 'error.main'
            if (product.receivedAmount > product.expectedAmount) textColor = 'warning.main'
            return (
              <Box color={textColor} clone>
                <Typography>
                  {`${formatNumberInt(product.receivedAmount)}/${formatNumberInt(product.expectedAmount)}`}
                </Typography>
              </Box>
            )
          },
        },
      ]}
      cleanSpace
      pagination={false}
    />
  )
}
