/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { useContext, useState } from 'react'
import { RootContext } from '../../..'
import { PageName, QuotationStatus } from '../../../enums'
import { useQuotationTableData } from '../../../hooks/quotation/useQuotationTableData'
import {
  FollowCustomerSignal,
  Loading,
  QuotationRequestChangeSignal,
  QuotationStatusChip,
} from '../../../shared-components'
import { FilteredTable, FlexBox, GeneralTextField, Link, Topic, withLayout } from '../../../custom-components'
import { IQuotationTableData_Data } from '../../../api/smartbestbuys-api'
import { dateFormat, dateTimeFormat } from '../../../functions'
import { Description } from '@material-ui/icons'
import numeral from 'numeral'
import { Autocomplete } from '@material-ui/lab'
import QuotationReportBox from './QuotationReportBox'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { useUserData } from '../../../hooks'
import { formatNumber } from '../../../functions/number-utils'

const QuotationTableData = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.QUOTATION)

  const tableData = useQuotationTableData()
  const assigneeUser = useUserData()

  // state
  const [searchOpened, setSearchOpened] = useState<boolean>(false)
  const [checkNoSaleOrder, setCheckNoSaleOrder] = useState(false)
  const [checkNeedToFollow, setCheckNeedToFollow] = useState(false)
  const [tabValue, setTabValue] = useState(QuotationStatus.PendingReview)

  if (!tableData || !assigneeUser) return <Loading />
  const {
    data,
    paginationInfo,
    isLoading,
    summary,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    pendingSearch,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  const handleOnChangeNoSaleOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckNoSaleOrder(event.target.checked)
    handleSearchParamChange('isNoSaleOrder', event.target.checked)
  }

  const handleOnChangeNeedToFollow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckNeedToFollow(event.target.checked)
    handleSearchParamChange('isNeedToFollow', event.target.checked)
  }

  const handleOnCheckRequestChangedQuotation = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('requestChanged', event.target.checked)
  }

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: QuotationStatus) => {
    setTabValue(newValue)
    handleSearchParamChange('statusIds', [newValue], true)
  }

  const reportPermission = getUserPermissionInfo(PermissionEnum.Sales_Quotation_Report)

  return (
    <>
      {reportPermission.hasPermission && (
        <Paper className="mb-4">
          <Topic>รายงานใบเสนอราคา</Topic>
          <QuotationReportBox />
        </Paper>
      )}

      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <Topic>รายการใบเสนอราคา</Topic>
              <FormControlLabel
                control={
                  <Switch
                    checked={searchOpened}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchOpened(event.target.checked)
                    }}
                    color="secondary"
                    className="ml-6"
                  />
                }
                label="ค้นหารายละเอียดเพิ่มเติม"
              />
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            {searchOpened && (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="name"
                      onChange={(e) => handleSearchParamChange('code', e.target.value)}
                      label="เลขใบเสนอราคา"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="referenceNumber"
                      onChange={(e) => handleSearchParamChange('customerName', e.target.value)}
                      label="ชื่อลูกค้า"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="text"
                      name="phoneNumber"
                      onChange={(e) => handleSearchParamChange('phoneNumber', e.target.value)}
                      label="เบอร์โทรศัพท์"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      id="tags-standard"
                      size="small"
                      options={assigneeUser.map((opt) => opt.id)}
                      getOptionLabel={(opt) => {
                        const foundUser = assigneeUser.find((user) => user.id === opt)
                        return foundUser ? `${foundUser.firstName} ${foundUser.lastName}` : ''
                      }}
                      onChange={(event, newValue) => {
                        handleSearchParamChange('assigneeUserId', newValue || undefined)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="outlined"
                          label="ผู้รับผิดชอบ"
                          placeholder="ผู้รับผิดชอบ"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="date"
                      name="startDate"
                      label="วันที่สร้าง (ตั้งแต่)"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) =>
                        handleSearchParamChange('startDate', e.target.value ? new Date(e.target.value) : undefined)
                      }
                    ></GeneralTextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="date"
                      name="endDate"
                      label="วันที่สร้าง (ถึง)"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) =>
                        handleSearchParamChange('endDate', e.target.value ? new Date(e.target.value) : undefined)
                      }
                    ></GeneralTextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GeneralTextField
                      type="date"
                      name="dueDate"
                      label="วันที่ลูกค้าต้องการ"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) =>
                        handleSearchParamChange('dueDate', e.target.value ? new Date(e.target.value) : undefined)
                      }
                    ></GeneralTextField>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FormGroup row style={{ marginTop: '8px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkNoSaleOrder}
                            onChange={handleOnChangeNoSaleOrder}
                            name="isNoSaleOrder"
                          />
                        }
                        label="ยังไม่มีการเปิดใบสั่งขาย"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkNeedToFollow}
                            onChange={handleOnChangeNeedToFollow}
                            name="isNeedToFollow"
                          />
                        }
                        label="ใบเสนอราคาที่ต้องติดตาม"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pendingSearch.requestChanged}
                            onChange={handleOnCheckRequestChangedQuotation}
                            name="requestChanged"
                          />
                        }
                        label="ใบเสนอราคาที่ต้องแก้ไข"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
                  <Grid item xs={12} sm={10}></Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="button"
                      type="submit"
                      disabled={isLoading}
                      startIcon={isLoading ? <CircularProgress size={16} /> : undefined}
                    >
                      ค้นหา
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
            <FilteredTable<IQuotationTableData_Data>
              color="primary.dark"
              columns={[
                {
                  name: '',
                  render: (data) => {
                    return (
                      <>
                        {data.needToFollow && <FollowCustomerSignal className="mr-1" />}
                        {data.requestChanged && <QuotationRequestChangeSignal />}
                      </>
                    )
                  },
                },
                {
                  name: 'สถานะใบเสนอราคา',
                  render: ({ status: { id, name } }) => <QuotationStatusChip status={id} label={name} />,
                },
                {
                  name: 'การเปิดใบสั่งขาย',
                  render: (data) => {
                    if (data.activeSaleOrderAmount > 0) {
                      return (
                        <Tooltip title={`เปิดใบสั่งขายแล้ว ${data.activeSaleOrderAmount} ใบ`}>
                          <Description fontSize="large" className="document__icon-green" />
                        </Tooltip>
                      )
                    } else {
                      return (
                        <Tooltip title="ยังไม่เปิดใบสั่งขาย">
                          <Description fontSize="large" color="disabled" />
                        </Tooltip>
                      )
                    }
                  },
                },
                {
                  name: 'เลขที่ใบเสนอราคา',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/quotations/${id}/profile`}>
                      {code}
                    </Link>
                  ),
                },
                {
                  name: 'วันที่ลูกค้าต้องการ',
                  render: ({ dueDate }) => (dueDate ? dateFormat(dueDate) : '-'),
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                },
                {
                  name: 'ผู้รับผิดชอบ',
                  render: ({ assignee }) => (assignee ? `${assignee.firstName} ${assignee.lastName}` : '-'),
                },
                {
                  name: 'ชื่อลูกค้า',
                  render: (data) => data.customer.nameLocal,
                },
                {
                  name: 'เบอร์โทรศัพท์',
                  render: (data) => data.customer.phoneNumber,
                },
                {
                  name: 'วิธีชำระเงิน',
                  render: (data) => data.paymentMethod.name,
                },
                {
                  name: 'ยอดรวมสุทธิ',
                  render: (data) => numeral(data.total).format('0,0.00'),
                },
              ]}
              tabOption={{
                tabs: [
                  {
                    label: (
                      <>
                        <div>
                          รอตรวจสอบ ({!!summary ? summary.status[QuotationStatus.PendingReview]?.count ?? 0 : 0})
                        </div>
                        <div>
                          {!!summary ? formatNumber(summary.status[QuotationStatus.PendingReview]?.total ?? 0) : 0} บาท
                        </div>
                      </>
                    ),
                    value: QuotationStatus.PendingReview,
                  },
                  {
                    label: (
                      <>
                        <div>
                          รอลูกค้าอนุมัติ ({!!summary ? summary.status[QuotationStatus.Pending]?.count ?? 0 : 0})
                        </div>
                        <div>
                          {!!summary ? formatNumber(summary.status[QuotationStatus.Pending]?.total ?? 0) : 0} บาท
                        </div>
                      </>
                    ),
                    value: QuotationStatus.Pending,
                  },
                  {
                    label: (
                      <>
                        <div>อนุมัติ ({!!summary ? summary?.status[QuotationStatus.Approve]?.count ?? 0 : 0})</div>
                        <div>
                          {!!summary ? formatNumber(summary.status[QuotationStatus.Approve]?.total ?? 0) : 0} บาท
                        </div>
                      </>
                    ),
                    value: QuotationStatus.Approve,
                  },
                  {
                    label: (
                      <>
                        <div>ไม่อนุมัติ ({!!summary ? summary?.status[QuotationStatus.Rejected]?.count ?? 0 : 0})</div>
                        <div>
                          {!!summary ? formatNumber(summary.status[QuotationStatus.Rejected]?.total ?? 0) : 0} บาท
                        </div>
                      </>
                    ),
                    value: QuotationStatus.Rejected,
                  },
                  {
                    label: (
                      <>
                        <div>ยกเลิก ({!!summary ? summary?.status[QuotationStatus.Cancelled]?.count ?? 0 : 0})</div>
                        <div>
                          {!!summary ? formatNumber(summary?.status[QuotationStatus.Cancelled]?.total ?? 0) : 0} บาท
                        </div>
                      </>
                    ),
                    value: QuotationStatus.Cancelled,
                  },
                ],
                value: tabValue,
                onChange: handleTabChange,
              }}
              disableDateRangeFilter
              disableSearch
              isLoading={isLoading}
              data={data}
              size="small"
              paginationInfo={paginationInfo}
              onPageChange={handlePageChange}
              onRowPerPageChange={handleRowPerPageChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default withLayout(QuotationTableData, true)
