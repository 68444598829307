import { useState, useEffect } from 'react'
import { fetchDocumentTaxInvoices, FetchRequest, TaxInvoiceTableData } from '../api/smartbestbuys-api'

export const useDocumentTaxInvoices = (startDate: Date, endDate: Date): TaxInvoiceTableData[] => {
  const [documentTaxInvoices, setDocumentTaxInvoice] = useState<TaxInvoiceTableData[]>([])

  const _fetchDocumentTaxInvoices = async (request: FetchRequest) => {
    const response = await fetchDocumentTaxInvoices(request)
    setDocumentTaxInvoice(response?.data || [])
  }

  useEffect(() => {
    _fetchDocumentTaxInvoices({ startDate, endDate })
  }, [startDate, endDate])

  return documentTaxInvoices
}
