import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { FlexBox } from '../../../custom-components'
interface Props {
  labels: string[]
  values: string[]
  color: 'primary.dark' | 'secondary.dark'
}

const PayoutDailyPaymentContext: React.FC<Props> = (props) => {
  const { labels, values, color } = props

  return (
    <>
      <Paper variant="outlined">
        <Grid container spacing={2}>
          {labels.map((label, index) => {
            if (index === 0) {
              return (
                <>
                  <Grid item md={6}>
                    <Typography variant="h5">{label}</Typography>
                  </Grid>
                  <Grid item md={6}>
                    <FlexBox justifyContent="flex-end" color={color}>
                      <Typography variant="h5">{values[index]}</Typography>
                    </FlexBox>
                  </Grid>
                </>
              )
            }

            return (
              <>
                <Grid item md={6}>
                  <Typography variant="subtitle2">{label}</Typography>
                </Grid>
                <Grid item md={6}>
                  <FlexBox justifyContent="flex-end">
                    <Typography variant="subtitle2">{values[index]}</Typography>
                  </FlexBox>
                </Grid>
              </>
            )
          })}
        </Grid>
      </Paper>
    </>
  )
}

export default PayoutDailyPaymentContext
