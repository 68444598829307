import { Grid } from '@material-ui/core'
import React from 'react'
import { ICustomerProfile_Address } from '../../../../../../api/smartbestbuys-api'

import CustomerAddressItem from './CustomerAddressItem'

interface Props {
  customerName?: string
  addresses: ICustomerProfile_Address[]
  type: 'shipping' | 'billing'
  isActiveCustomer: boolean
}

const CustomerAddressList: React.FC<Props> = (props) => {
  const { customerName, addresses, type, isActiveCustomer } = props

  return (
    <Grid container spacing={2}>
      {addresses.map((address, index) => (
        <Grid item xs={12} key={address.id}>
          <CustomerAddressItem
            index={index + 1}
            customerName={customerName}
            address={address}
            type={type}
            isActiveCustomer={isActiveCustomer}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default CustomerAddressList
