import { useState, useEffect } from 'react'
import { getPurchaseTask, SinglePurchaseTask } from '../../api/smartbestbuys-api'
import { TaskType } from '../../enums'

export const usePurchaseTask = (id: number, typeId?: TaskType) => {
  const [data, setData] = useState<SinglePurchaseTask | undefined>(undefined)

  const _getPurchaseTask = async (id: number, typeId?: number) => {
    const response = await getPurchaseTask(id, typeId)
    setData(response?.data)
  }

  useEffect(() => {
    _getPurchaseTask(id, typeId)
  }, [id, typeId])

  return data
}
