import React, { useContext } from 'react'
import { Grid, Button, Paper } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, Link, FlexBox } from '../../custom-components'
import { ProductWithROPInfo } from '../../api/smartbestbuys-api'
import { Avatar } from '../../custom-components'
import { Add, Photo } from '@material-ui/icons'
import { Typography, Box } from '@material-ui/core'
import { RootContext } from '../..'
import { NavigationItemKey, PageName } from '../../enums'
import { useProductsWithROPInfo } from '../../hooks/product'
import { formatNumber, formatNumberInt } from '../../functions/number-utils'
import { NotificationHead } from '../../shared-components'

const PendingToPurchaseListPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PRODUCT_REORDER_POINT)

  const products = useProductsWithROPInfo()

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการสินค้าที่ควรซื้อเพิ่ม</Topic>
            <Link href="/products/pending-to-purchase/create-purchase-requisition" target="_blank">
              <Button variant="contained" color="primary" startIcon={<Add />}>
                สร้างใบขอซื้อ
              </Button>
            </Link>
          </FlexBox>
          <NotificationHead navigationKey={NavigationItemKey.ReorderPoint} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<ProductWithROPInfo>
              disableDateRangeFilter
              color="primary.dark"
              columns={[
                {
                  name: 'รหัสสินค้า',
                  dataIndex: 'code',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/products/${id}/profile`}>
                      {code}
                    </Link>
                  ),
                  fixedWidth: 120,
                },
                {
                  name: 'รายละเอียดสินค้า',
                  dataIndex: 'name',
                  render: ({ imageUrl, name }) => (
                    <Box display="flex" alignItems="flex-start">
                      <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                        <Photo />
                      </Avatar>
                      {
                        <Box ml={1}>
                          <Typography variant="body2">{name}</Typography>
                        </Box>
                      }
                    </Box>
                  ),
                  fixedWidth: 300,
                },
                {
                  name: 'กลุ่มสินค้า',
                  dataIndex: 'grade',
                  fixedWidth: 70,
                  sort: true,
                },
                {
                  name: 'จำนวนสต็อกคงเหลือ',
                  dataIndex: 'stockAmount',
                  sort: true,
                  render: ({ stockAmount, reorderPoint }) => {
                    const color = stockAmount > reorderPoint ? 'success.main' : 'error.main'
                    return (
                      <Box color={color} clone>
                        <Typography variant="body2">{formatNumberInt(stockAmount)}</Typography>
                      </Box>
                    )
                  },
                },
                {
                  name: 'จุดที่ต้องซื้อ',
                  dataIndex: 'reorderPoint',
                  sort: true,
                  render: ({ reorderPoint }) => (
                    <Box color="error.main" clone>
                      <Typography variant="body2">{formatNumber(reorderPoint)}</Typography>
                    </Box>
                  ),
                },
                {
                  name: 'ยอดขายเฉลี่ยต่อวัน',
                  dataIndex: 'averageSaleAmount',
                  numeric: true,
                  format: '0,0.00',
                },
                {
                  name: 'Safety Stock',
                  dataIndex: 'safetyStock',
                  numeric: true,
                  format: '0,0.00',
                },
                {
                  name: 'ค่าสูงสุดในคลัง',
                  dataIndex: 'maxStockAmount',
                  numeric: true,
                  format: '0,0.00',
                },
                {
                  name: 'ปริมาณการสั่งต่อครั้ง',
                  dataIndex: 'purchaseAmount',
                  numeric: true,
                  format: '0,0.00',
                },
                {
                  name: 'Lead Time',
                  dataIndex: 'leadTime',
                  numeric: true,
                  format: '0,0',
                },
                {
                  name: 'นโยบายเก็บ Safety Stock',
                  dataIndex: 'safetyStockPolicy',
                  numeric: true,
                  format: '0,0',
                },
              ]}
              filters={[
                {
                  name: 'กลุ่มสินค้า',
                  values: [
                    {
                      name: 'A',
                      value: 'A',
                    },
                    {
                      name: 'B',
                      value: 'B',
                    },
                    {
                      name: 'C',
                      value: 'C',
                    },
                    {
                      name: 'ไม่มีกลุ่ม',
                      value: 'X',
                    },
                  ],
                  filter: (data: ProductWithROPInfo, values: (string | number)[]) => {
                    const grade = data.grade ?? 'X'
                    return values.includes(grade)
                  },
                },
                {
                  name: 'ตัวเลือกอื่นๆ',
                  values: [{ name: 'สินค้าที่ถึงจุดสั่งซื้อ', value: 1 }],
                  filter: (data: ProductWithROPInfo, values: (string | number)[]) => {
                    const filterUnderReorderpoint = values.includes(1)
                    if (filterUnderReorderpoint) return data.haveToPurchase
                    return true
                  },
                },
              ]}
              data={products}
              size="small"
              sortable
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(PendingToPurchaseListPage)
