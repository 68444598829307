import { Paper } from '@material-ui/core'
import { IRefundRequisitionProfile_Activity } from '../../../api/smartbestbuys-api'
import { FilteredTable, SubTopic } from '../../../custom-components'
import { dateTimeFormat } from '../../../functions'

interface Props {
  saleOrderActivities: IRefundRequisitionProfile_Activity[]
}

const RefundRequisitionSaleOrderActivityTable: React.FC<Props> = (props: Props) => {
  // props and state
  const { saleOrderActivities } = props

  return (
    <Paper>
      <SubTopic>ประวัติการจัดการการคืนเงิน</SubTopic>
      <div className="mb-4" />
      <FilteredTable<IRefundRequisitionProfile_Activity>
        disableDateRangeFilter
        size="small"
        color="grey.800"
        columns={[
          {
            name: 'การกระทำ',
            dataIndex: 'actionName',
          },
          {
            name: 'รายละเอียดการจัดการ',
            dataIndex: 'actionMessage',
          },
          {
            name: 'จัดการโดย',
            dataIndex: 'recCreatedBy',
          },
          { name: 'วันที่', render: (record) => dateTimeFormat(record.recCreatedAt) },
          {
            name: 'หมายเหตุ',
            dataIndex: 'remark',
          },
        ]}
        data={saleOrderActivities}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default RefundRequisitionSaleOrderActivityTable
