import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { FetchRequest, DocumentCustomerInfo } from '..'
import { PriceBreakdownItem, BillingNote } from './types'

const path = `/documents/billing-notes`

export const fetchDocumentBillingNotes = errorHandler<BillingNote[]>(async (request: FetchRequest) => {
  return await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
})

// deprecated
export interface BillingNoteContent {
  customerInfo: DocumentCustomerInfo
  items: BillingNoteContentItem[]
  priceBreakdown: BillingPriceBreakDown
}

export interface BillingPriceBreakDown {
  itemTotal: number
  total: number
}

export interface BillingNoteContentItem {
  name: string
  amount: number
  pricePerEach: number
  total: number
  createdAt: string
  dueDate: string
}

export interface BillingNoteState {
  items: BillingNoteItem[]
  priceBreakdowns: PriceBreakdownItem[]
  remark?: string
}

export interface BillingNoteItem {
  id: number
  name: string
  amount: number
  total: number
  issuedDate: Date
  expiredDate: Date
}
