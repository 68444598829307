import {
  BillingNoteItem,
  createCustomerBillingAddressAPI,
  createCustomerContactAPI,
  createCustomerShippingAddressAPI,
  CustomerAddressCreationRequest,
  CustomerAddressUpdationRequest,
  CustomerContactCreationRequest,
  CustomerContactUpdationRequest,
  ICustomerProfile_Document,
  ICustomerDocumentState,
  CustomerWithBillingAddressURLParams,
  updateCustomerBillingAddressAPI,
  updateCustomerShippingAddressAPI,
  CustomerWithContactURLParams,
  updateCustomerContactAPI,
} from '../../../../../api/smartbestbuys-api'
import { DocumentType } from '../../../../../enums'
import {
  createBillingDocumentAPI,
  CustomerBillingDocumentCreationRequest,
} from '../../../../../api/smartbestbuys-api/customer/create-billing-document-api'
import CustomerBillingAddressCreationFormValue from './CustomerBillingAddressBox/CustomerBillingAddressCreationDialog/CustomerBillingAddressCreationFormValue'
import CustomerShippingAddressCreationForm from './CustomerShippingAddressBox/CustomerShippingAddressCreationDialog/CustomerShippingAddressCreationFormValue'
import BillingDocumentCreationFormValue from './CustomerDocumentBox/BillingDocumentCreationDialog/BillingDocumentCreationDialogValue'
import CustomerShippingAddressUpdationFormValue from './CustomerShippingAddressBox/CustomerShippingAddressUpdationDialog/CustomerShippingAddressUpdationFormValue'
import { mapBillingDocumentCreationFormToBillingNoteItem } from './mapper'
import _ from 'lodash'
import CustomerBillingAddressUpdationFormValue from './CustomerBillingAddressBox/CustomerBillingAddrssUpdationDialog/CustomerBillingAddressUpdationFormValue'
import CustomerContactCreationFormValue from './CustomerContactList/CustomerContactDialog/CustomerContactCreationDialog/CustomerContactCreationFormValue'
import CustomerContactUpdationFormValue from './CustomerContactList/CustomerContactDialog/CustomerContactUpdationDialog/CustomerContactUpdationFormValue'

export const createCustomerContact = (customerId: number, values: CustomerContactCreationFormValue) => {
  const request: CustomerContactCreationRequest = {
    nameLocal: values.nameLocal,
    nameEn: undefined,
    nicknameLocal: values.nickname,
    nicknameEn: undefined,
    customerDepartmentId: values.customerDepartmentId,
    phoneNumber: values.phoneNumber,
    email: values.email,
    line: values.line,
    facebook: values.facebook,
    birthdate: undefined,
    gender: values.gender,
  }

  return createCustomerContactAPI(customerId, request)
}

export const updateCustomerContact = (
  customerId: number,
  contactId: number,
  value: CustomerContactUpdationFormValue,
) => {
  const urlParams: CustomerWithContactURLParams = {
    customerId,
    contactId,
  }
  const request: CustomerContactUpdationRequest = {
    nameLocal: value.nameLocal,
    nameEn: value.nameEn,
    nicknameLocal: value.nickname,
    nicknameEn: value.nicknameEn,
    customerDepartmentId: value.customerDepartmentId,
    phoneNumber: value.phoneNumber,
    email: value.email,
    line: value.line,
    facebook: value.facebook,
    birthdate: value.birthdate,
    gender: value.gender,
  }
  return updateCustomerContactAPI(urlParams, request)
}

export const createCustomerBillingAddress = (customerId: number, values: CustomerBillingAddressCreationFormValue) => {
  const request: CustomerAddressCreationRequest = {
    name: values.name,
    branchName: values.branchName,
    addressLine1: values.addressLine1,
    phoneNumber: values.phoneNumber,
    faxNumber: values.faxNumber,
    provinceId: values.provinceId ? Number(values.provinceId) : undefined,
    districtId: values.districtId ? Number(values.districtId) : undefined,
    subDistrictId: values.subDistrictId ? Number(values.subDistrictId) : undefined,
    postalCode: values.postalCode?.toString(),
    googleMapLink: undefined,
    overrideCustomerName: values.overrideCustomerName,
    overrideAddress: values.overrideAddress,
    overrideTaxNumber: values.overrideTaxNumber,
  }

  return createCustomerBillingAddressAPI(customerId, request)
}

export const createCustomerShippingAddress = (customerId: number, values: CustomerShippingAddressCreationForm) => {
  const request: CustomerAddressCreationRequest = {
    name: values.name,
    branchName: values.branchName,
    addressLine1: values.addressLine1,
    phoneNumber: values.phoneNumber,
    faxNumber: values.faxNumber,
    provinceId: values.provinceId ? Number(values.provinceId) : undefined,
    districtId: values.districtId ? Number(values.districtId) : undefined,
    subDistrictId: values.subDistrictId ? Number(values.subDistrictId) : undefined,
    postalCode: values.postalCode?.toString(),
    googleMapLink: values.googleMapLink,
  }

  return createCustomerShippingAddressAPI(customerId, request)
}

export const createBillingDocument = (
  customerId: number,
  value: BillingDocumentCreationFormValue,
  documentType: DocumentType.Receipts | DocumentType.BillingNote,
) => {
  const billingNoteItems: BillingNoteItem[] = mapBillingDocumentCreationFormToBillingNoteItem(value)

  const request: CustomerBillingDocumentCreationRequest = {
    documentType: documentType === DocumentType.Receipts ? DocumentType.Receipts : DocumentType.BillingNote,
    billingAddressId: value.billingAddressId,
    items: billingNoteItems,
    priceBreakdowns: [
      {
        label: 'ยอดเงินสุทธิ',
        amount: calculateNetAmount(value.documents),
      },
    ],
    remark: value?.remark,
  }

  return createBillingDocumentAPI(customerId, request)
}

export const calculateNetAmount = (documents: ICustomerProfile_Document<ICustomerDocumentState>[]) => {
  return _.sum(documents?.map((df) => df.total || 0))
}

export const updateCustomerBillingAddress = (
  customerId: number,
  billingAddressId: number,
  values: CustomerBillingAddressUpdationFormValue,
) => {
  const request: CustomerAddressUpdationRequest = {
    name: values.name,
    branchName: values.branchName,
    addressLine1: values.addressLine1,
    phoneNumber: values.phoneNumber,
    faxNumber: values.faxNumber,
    provinceId: values.provinceId ? Number(values.provinceId) : undefined,
    districtId: values.districtId ? Number(values.districtId) : undefined,
    subDistrictId: values.subDistrictId ? Number(values.subDistrictId) : undefined,
    postalCode: values.postalCode?.toString(),
    overrideCustomerName: values.overrideCustomerName,
    overrideAddress: values.overrideAddress,
    overrideTaxNumber: values.overrideTaxNumber,
  }

  const urlParams: CustomerWithBillingAddressURLParams = {
    customerId,
    billingAddressId,
  }

  return updateCustomerBillingAddressAPI(urlParams, request)
}

export const updateCustomerShippingAddress = (
  customerId: number,
  shippingAddressId: number,
  shippingAddress: CustomerShippingAddressUpdationFormValue,
) => {
  const request: CustomerAddressUpdationRequest = {
    name: shippingAddress.name,
    branchName: shippingAddress.branchName,
    addressLine1: shippingAddress.addressLine1,
    phoneNumber: shippingAddress.phoneNumber,
    provinceId: shippingAddress.provinceId ? Number(shippingAddress.provinceId) : undefined,
    districtId: shippingAddress.districtId ? Number(shippingAddress.districtId) : undefined,
    subDistrictId: shippingAddress.subDistrictId ? Number(shippingAddress.subDistrictId) : undefined,
    postalCode: shippingAddress.postalCode?.toString(),
    googleMapLink: shippingAddress.googleMapLink,
  }
  return updateCustomerShippingAddressAPI({ customerId, shippingAddressId }, request)
}
