/* eslint-disable react-hooks/exhaustive-deps */
import {
  GetProductROPTableDataSearchParams,
  IProductROPTableData_Data,
  IProductROPTableData_SearchParams,
  getProductROPPaginationAPI,
} from '../../api/smartbestbuys-api'
import { useTableData } from '../useTablaData'

const _buildQueryParams = (_s: IProductROPTableData_SearchParams): GetProductROPTableDataSearchParams => {
  return {
    name: _s.name,
    code: _s.code,
    gradeList: _s?.gradeList?.join(','),
  }
}

export const useProductROPTableData = () =>
  useTableData<IProductROPTableData_Data, {}, IProductROPTableData_SearchParams, GetProductROPTableDataSearchParams>(
    0,
    10,
    { gradeList: [] },
    getProductROPPaginationAPI,
    _buildQueryParams,
  )
