import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { fetchTransportSubTasksAPI, FetchTransportTasksURLParams, TransportSubTask } from '../../api/smartbestbuys-api'
import { TaskType } from '../../enums'

export const useTransportSubTasks = <T extends TransportSubTask<any>>(
  startDate: Date,
  endDate: Date,
  typeId: TaskType,
): [T[], Dispatch<SetStateAction<T[]>>] => {
  const [data, setData] = useState<T[]>([])

  useEffect(() => {
    ;(async () => {
      const request: FetchTransportTasksURLParams = {
        startDate,
        endDate,
        typeId,
      }
      const response = await fetchTransportSubTasksAPI(request)
      const data = (response?.data ?? []) as T[]
      setData(data)
    })()
  }, [startDate, endDate, typeId])

  return [data, setData]
}
