import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Avatar,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { ExpandMore, Photo } from '@material-ui/icons'
import numeral from 'numeral'
import { ChangeEvent, useContext, useState } from 'react'
import { SOPurchaseOrder, SOPurchaseOrderProduct } from '../../../../api/smartbestbuys-api'
import { Table, Title, TypographyWithLabel } from '../../../../custom-components'
import { dateTimeFormat } from '../../../../functions'
import { NotificationHead, PurchaseOrderStatusChip, Space } from '../../../../shared-components'
import SaleOrderProfileContext from './SaleOrderProfileContext'
import './SOPurchaseOrdersDialog.less'
import { NavigationItemKey } from '../../../../enums'

interface Props extends DialogProps {
  purchaseRequisitionId: number
  onClose: () => void
}

const SOPurchaseOrdersDialog = (props: Props) => {
  // props & context
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  const { purchaseRequisitionId, onClose, ...otherProps } = props
  const purchaseRequisition = saleOrderProfile?.purchaseRequisitions!.find((pr) => pr.id === purchaseRequisitionId)

  // hook
  const [expanded, setExpanded] = useState('')

  const handleChange = (panel: any) => (event: ChangeEvent<{}>, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Dialog {...otherProps} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography variant="h6">ใบสั่งซื้อของคำขอซื้อ {purchaseRequisition?.code}</Typography>
      </DialogTitle>
      <DialogContent className="purchase-order__paper">
        {purchaseRequisition?.purchaseOrders!.map((purchaseOrder) => {
          return (
            <div className="purchase-order__card">
              <Accordion
                expanded={expanded === `key${purchaseOrder.id}`}
                onChange={handleChange(`key${purchaseOrder.id}`)}
              >
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h6">ใบสั่งซื้อที่</Typography>
                  <span className="ml-6" />
                  <Box color="primary.dark">
                    <Typography variant="h6">{purchaseOrder.code}</Typography>
                  </Box>
                  <span className="ml-6" />
                  <PurchaseOrderStatusChip status={purchaseOrder.status.id} label={purchaseOrder.status.name} />
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid xs={12} md={4}>
                      <TypographyWithLabel label="สร้างโดย:">{purchaseOrder.recCreatedBy}</TypographyWithLabel>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <TypographyWithLabel label="ตรวจสอบโดย:">{purchaseOrder.latestVerifiedBy}</TypographyWithLabel>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <TypographyWithLabel label="วันที่สร้าง:">
                        {dateTimeFormat(purchaseOrder.recCreatedAt)}
                      </TypographyWithLabel>
                    </Grid>
                    <Grid xs={12}>
                      <SOPurchaseOrderProductsTable purchaseOrder={purchaseOrder} />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="medium" color="primary" onClick={onClose}>
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SOPurchaseOrdersDialog

interface SOPurchaseOrderProductsTableProps {
  purchaseOrder: SOPurchaseOrder
}

const SOPurchaseOrderProductsTable = (props: SOPurchaseOrderProductsTableProps) => {
  //props
  const { purchaseOrder } = props

  // calculate
  const total = purchaseOrder.products.reduce<number>((sum, product) => {
    return sum + product.price * product.amount
  }, 0)
  const priceBreakdowns = [{ label: 'รวมเงิน', value: total }]

  // render
  return (
    <Box pt={3}>
      <Title>รายการสินค้า</Title>
      <NotificationHead navigationKey={NavigationItemKey.Product} />
      <Space />
      <Table<SOPurchaseOrderProduct>
        color="primary.dark"
        columns={[
          { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
          {
            name: 'ชื่อสินค้า',
            dataIndex: 'name',
            render: (product) => (
              <div className="purchase-order-products-table__product-cell" key={'product-' + product.id}>
                <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                  <Photo />
                </Avatar>
                <div className="purchase-order-products-table__product-cell__info">
                  <Typography color="textSecondary">{product.code}</Typography>
                  <Typography>{product.name}</Typography>
                </div>
              </div>
            ),
          },
          { name: 'ราคาต่อหน่วย', dataIndex: 'price', numeric: true, format: '0,0' },
          { name: 'จำนวน', dataIndex: 'amount', numeric: true, format: '0,0' },
          { name: 'ราคารวม', render: (product) => product.amount * product.price, numeric: true, format: '0,0.00' },
        ]}
        data={purchaseOrder.products}
        postCells={
          <>
            {priceBreakdowns
              .filter((item) => item.value > 0)
              .map((item) => (
                <TableRow>
                  <TableCell colSpan={3} />
                  <TableCell align="right">{item.label}</TableCell>
                  <TableCell align="right">{numeral(item.value).format('0,0.00')}</TableCell>
                </TableRow>
              ))}
          </>
        }
        pagination={false}
        cleanSpace
      />
    </Box>
  )
}
