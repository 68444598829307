import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { TaskStatus } from '../../enums'

interface Props {
  status: TaskStatus
  icon?: boolean
}

const useStyles = (status: TaskStatus) =>
  makeStyles((theme) => {
    const bgColorMap = {
      [TaskStatus.Pending]: theme.palette.warning.light,
      [TaskStatus.Processing]: theme.palette.warning.light,
      [TaskStatus.Done]: theme.palette.success.light,
      [TaskStatus.Failed]: theme.palette.error.light,
      [TaskStatus.PreCancel]: theme.palette.error.light,
      [TaskStatus.Canceled]: theme.palette.error.light,
      [TaskStatus.TransportPending]: theme.palette.warning.light,
      [TaskStatus.TransportDataPrepared]: theme.palette.warning.light,
      [TaskStatus.ReadyToShip]: theme.palette.warning.light,
      [TaskStatus.Shipping]: theme.palette.warning.light,
      [TaskStatus.PendingApproval]: theme.palette.warning.light,
    }

    const colorMap = {
      [TaskStatus.Pending]: theme.palette.warning.darkText,
      [TaskStatus.Processing]: theme.palette.warning.darkText,
      [TaskStatus.Done]: theme.palette.success.darkText,
      [TaskStatus.Failed]: theme.palette.error.darkText,
      [TaskStatus.PreCancel]: theme.palette.error.darkText,
      [TaskStatus.Canceled]: theme.palette.error.darkText,
      [TaskStatus.TransportPending]: theme.palette.warning.darkText,
      [TaskStatus.TransportDataPrepared]: theme.palette.warning.darkText,
      [TaskStatus.ReadyToShip]: theme.palette.warning.darkText,
      [TaskStatus.Shipping]: theme.palette.warning.darkText,
      [TaskStatus.PendingApproval]: theme.palette.warning.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: bgColorMap[status],
        color: colorMap[status],
      },
      icon: {
        color: colorMap[status],
      },
    })
  })

const TaskStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ children, status, icon, ...othersProps }) => {
  const classes = useStyles(status)()
  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      icon={icon ? <AttachMoney /> : undefined}
      {...othersProps}
    />
  )
}

export default TaskStatusChip
