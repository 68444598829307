import React from 'react'
import { Button, ButtonProps, makeStyles, createStyles } from '@material-ui/core'

type Color = 'success' | 'error' | 'warning' | 'grey' | 'black'

interface Props extends Omit<ButtonProps, 'color'> {
  color?: Color
  classNames?: string[]
}

const CustomButton: React.FC<Props> = (props) => {
  const { color, classNames: inputClassNames, ...otherProps } = props
  const classes = useStyles(color)()
  const classNames = inputClassNames || []
  return <Button className={[classes.root, ...classNames].join(' ')} {...otherProps} />
}

export default CustomButton

const useStyles = (color?: Color) =>
  makeStyles((theme) => {
    let backgroundColor = undefined
    let hoverBackgroundColor = undefined
    let textColor = undefined

    switch (color) {
      case 'error':
        backgroundColor = theme.palette.error.main
        textColor = theme.palette.error.contrastText
        hoverBackgroundColor = theme.palette.error.dark
        break
      case 'success':
        backgroundColor = theme.palette.success.main
        textColor = theme.palette.success.contrastText
        hoverBackgroundColor = theme.palette.success.dark
        break
      case 'warning':
        backgroundColor = theme.palette.warning.main
        textColor = theme.palette.warning.contrastText
        hoverBackgroundColor = theme.palette.warning.dark
        break
      case 'grey':
        backgroundColor = theme.palette.grey[300]
        textColor = theme.palette.grey[600]
        hoverBackgroundColor = theme.palette.grey[400]
        break
      case 'black':
        backgroundColor = theme.palette.grey[900]
        textColor = theme.palette.getContrastText(theme.palette.grey[900])
        hoverBackgroundColor = theme.palette.grey[800]
        break
      default:
        break
    }

    return createStyles({
      root: {
        backgroundColor,
        color: textColor,
        '&:hover': {
          backgroundColor: hoverBackgroundColor,
        },
      },
    })
  })
