import { DialogProps } from '@material-ui/core'

import { UserAction } from '../../../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../../../shared-components'
import { IStatusResponse, UserActivityNote, approveRequestChangeProductAPI } from '../../../../../api/smartbestbuys-api'

interface Props extends DialogProps {
  productId: number
  onComplete: (data: IStatusResponse) => void
  onFail: () => void
}

const ProductReviewApproveDialog: React.FC<Props> = (props: Props) => {
  // set up props
  const { productId, onComplete, onFail, ...dialogProps } = props

  const textOption: UserActivityDialog_TextOption = {
    title: 'อนุมัติการขอแก้ไขข้อมูลสินค้า',
    description: `คุณต้องการอนุมัติการขอแก้ไขข้อมูลสินค้า นี้ใช่หรือไม่?`,
    actionMessageText: 'สาเหตุของการให้แก้ไข',
    submitText: 'ยืนยัน',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: true,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const { actionMessageId, actionMessage, remark } = values
    const request: UserActivityNote = {
      actionMessageId: actionMessageId !== -1 ? actionMessageId : undefined,
      actionMessage,
      remark,
    }
    const response = await approveRequestChangeProductAPI(productId, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.ConfirmUpdateProduct}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default ProductReviewApproveDialog
