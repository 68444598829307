import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = (rowSize: number) =>
  makeStyles((theme) =>
    createStyles({
      card: {
        height: '100%',
        padding: theme.spacing(3),
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
        },
      },
      indicator: {
        display: 'flex',
        fontSize: Math.max(rowSize * 6, 12),
        justifyContent: 'center',
        textAlign: 'center',
        position: 'absolute',
        top: `calc(50% - ${Math.max(rowSize * 6, 12) * 0.9}px)`,
        width: '100%',
        height: '100%',
        margin: '0 auto',
        userSelect: 'none',
      },
    }),
  )
