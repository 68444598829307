import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useStyles } from '../styles'
import { TaskStatusChip } from '../../../../shared-components'
import { ICustomerRequisitionProfile_Task } from '../../../../api/smartbestbuys-api'
import { ReceiveProductTable, ReceiveProductTableData } from '..'

interface WarehouseReceiveProductTaskProps {
  isExpanded: boolean
  handleExpand: () => void
  task: ICustomerRequisitionProfile_Task
  productTableData: ReceiveProductTableData[]
}

const WarehouseReceiveProductTask: React.FC<WarehouseReceiveProductTaskProps> = ({
  isExpanded,
  handleExpand,
  task,
  productTableData,
}) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} key={task.id}>
      <Accordion
        square
        key={task.id.toString()}
        expanded={isExpanded}
        onChange={handleExpand}
        TransitionProps={{ unmountOnExit: true }}
        className={classes.accordion}
      >
        <AccordionSummary expandIcon={<ExpandMore />} classes={{ content: classes.accordionSummary }}>
          <Typography variant="h5">{task.code}</Typography>
          <TaskStatusChip status={task.status.id} label={task.status.name} />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">รายการขนส่ง</Typography>
              <ReceiveProductTable data={productTableData} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default WarehouseReceiveProductTask
