import React, { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import { FilteredTable, FlexBox, Topic, withLayout, Link } from '../../../custom-components'
import { Add } from '@material-ui/icons'

import { PurchaseRequisitionDataRow } from '../../../api/smartbestbuys-api'
import { usePurchaseRequisitions } from '../../../hooks'
import PRPurchaseListCreateDialog from './PRPurchaseListCreateDialog/PRPurchaseListCreateDialog'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { dateFormat, dateTimeFormat } from '../../../functions'
import {
  PurchaseRequisitionStatusChip,
  NoPOAlertSignal,
  ConditionTooltip,
  NotificationHead,
} from '../../../shared-components'
import { NavigationItemKey, PageName } from '../../../enums'
import { RootContext } from '../../..'
import lodash from 'lodash'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const PurchaseRequisitionListPage: React.FC = () => {
  const history = useHistory()

  const readPermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Read)

  if (!readPermission.hasPermission) {
    history.push('/')
  }

  const [createPRPurchaseList, setCreatePRPurchaseList] = useState(false)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const purchaseRequisitions = usePurchaseRequisitions(startDate, endDate)
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PURCHASE_REQUISITION)

  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Write)

  const purchaseRequisitionStatuses = useMemo(() => {
    return lodash
      .chain(purchaseRequisitions)
      .uniqBy('status.id')
      .orderBy('status.id')
      .value()
      .map((pr) => {
        return {
          name: pr.status.name,
          value: pr.status.id,
        }
      })
    // eslint-disable-next-line
  }, [purchaseRequisitions.map((pr) => pr.code).toString()])

  const purchaseRequisitionTopics = useMemo(() => {
    return lodash
      .chain(purchaseRequisitions)
      .uniqBy('topic.id')
      .orderBy('topic.id')
      .value()
      .map((pr) => {
        return {
          name: pr.topic.name,
          value: pr.topic.id,
        }
      })
    // eslint-disable-next-line
  }, [purchaseRequisitions.map((pr) => pr.code).toString()])

  return (
    <>
      <PRPurchaseListCreateDialog
        open={createPRPurchaseList}
        onClose={() => {
          setCreatePRPurchaseList(false)
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการคำขอซื้อ</Topic>
            <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
              <Button
                disabled={!writePermission.hasPermission}
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => history.push('/purchase-requisitions/create')}
              >
                สร้างคำขอชื้อ
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <NotificationHead navigationKey={NavigationItemKey.PurchaseRequisition} />
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<PurchaseRequisitionDataRow>
            color="primary.dark"
            columns={[
              {
                name: '',
                render: ({ hasAvailablePO }) => (
                  <div className="signal-cell">{!hasAvailablePO && <NoPOAlertSignal />}</div>
                ),
              },
              {
                name: 'สถานะคำขอซื้อ',
                render: ({ status }) => (
                  <PurchaseRequisitionStatusChip size="small" status={status.id} label={status.name} />
                ),
              },
              {
                name: 'เลขที่คำขอซื้อ',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/purchase-requisitions/${id}/profile`}>
                    {code}
                  </Link>
                ),
                dataIndex: 'id',
                sort: true,
              },
              {
                name: 'ประเภทคำขอซื้อ',
                render: ({ type: { name } }) => name,
              },
              {
                name: 'เหตุผล',
                render: ({ topic: { name } }) => name,
              },
              {
                name: 'สร้างโดย',
                dataIndex: 'recCreatedBy',
              },
              {
                name: 'ตรวจสอบโดย',
                dataIndex: 'latestVerifiedBy',
              },
              {
                name: 'วันที่ต้องการ สินค้า/บริการ',
                dataIndex: 'dueDate',
                render: ({ dueDate }) => {
                  if (dueDate) {
                    return dateFormat(dueDate)
                  }
                },
                sort: true,
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                dataIndex: 'recCreatedAt',
                sort: true,
              },
              {
                name: 'เลขที่ SO อ้างอิง',
                render: ({ saleOrderInfo }) => {
                  if (saleOrderInfo) {
                    const { id, code } = saleOrderInfo
                    return (
                      <Link color="secondary" to={`/sale-orders/${id}`}>
                        {code}
                      </Link>
                    )
                  }
                },
                dataIndex: 'saleOrderInfo',
                sort: true,
              },
            ]}
            filters={[
              {
                name: 'สถานะคำขอซื้อ',
                values: purchaseRequisitionStatuses,
                filter: (data: PurchaseRequisitionDataRow, values: (string | number)[]) =>
                  values.includes(data.status.id),
              },
              {
                name: 'เหตุผล',
                values: purchaseRequisitionTopics,
                filter: (data: PurchaseRequisitionDataRow, values: (string | number)[]) =>
                  values.includes(data.topic.id),
              },
            ]}
            sortable={true}
            data={purchaseRequisitions}
            defaultDateRage={{
              startDate: defaultStartDate,
              endDate: defaultEndDate,
            }}
            onDateChange={(startDate, endDate) => {
              setStartDate(startDate)
              setEndDate(endDate)
            }}
            size="small"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default withLayout(PurchaseRequisitionListPage)
