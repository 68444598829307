import React, { useContext } from 'react'
import { Grid, Button, MenuItem, Chip, TextField, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { withLayout, Topic, Link, FlexBox, GeneralTextField, FilteredTable } from '../../custom-components'
import { IProductAnalyticReportTableData_Data } from '../../api/smartbestbuys-api'
import { Avatar } from '../../custom-components'
import { Photo } from '@material-ui/icons'
import { Typography, Box } from '@material-ui/core'
import { RootContext } from '../..'
import { ContributionOp, PageName, ProductGrade } from '../../enums'
import { DeadStockSignal, Loading, ProductGradeChip } from '../../shared-components'
import { useProductAnalyticTableData } from '../../hooks/product/useProductAnalyticTableData'
import { format, add } from 'date-fns'
import { Autocomplete } from '@material-ui/lab'
import { productKarajicMatrixs } from '../../functions'

const ProductAnalyticTableDataPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PRODUCT_ANALYTIC)

  const productGradesOption = [
    { label: 'ทั้งหมด', value: 'all' },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
  ]

  const contributionOption = [
    { label: 'ทั้งหมด', value: 0 },
    { label: '%Sale > %Contribution', value: ContributionOp.LessThan },
    { label: '%Sale < %Contribution', value: ContributionOp.Greater },
    { label: '%Sale = % Contribution', value: ContributionOp.Equals },
  ]

  const tableData = useProductAnalyticTableData({
    startDateTime: format(
      add(new Date(), {
        months: -3,
      }),
      'yyyy-MM-dd',
    ),
    endDateTime: format(new Date(), 'yyyy-MM-dd'),
    grade: '',
    karajicMatrixIds: undefined,
    isDeadStock: undefined,
    contributionOp: undefined,
  })

  if (!tableData) return <Loading />
  const {
    data,
    paginationInfo,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    isLoading,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox alignItems="center">
            <Topic>วิเคราะห์รายการสินค้า</Topic>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="date"
                  name="startDateTime"
                  onChange={(e) => tableData?.handleSearchParamChange('startDateTime', e.target.value)}
                  label="วันที่"
                  defaultValue={tableData?.pendingSearch?.startDateTime}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="date"
                  name="endDateTime"
                  onChange={(e) => tableData?.handleSearchParamChange('endDateTime', e.target.value)}
                  label="ถึงวันที่"
                  defaultValue={tableData?.pendingSearch?.endDateTime}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  name="grade"
                  label="เกรด"
                  select
                  onChange={(e) => handleSearchParamChange('grade', e.target.value)}
                >
                  {productGradesOption.map((grade) => (
                    <MenuItem key={grade.value} value={grade.value}>
                      {grade.label}
                    </MenuItem>
                  ))}
                </GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  name="contribution"
                  label="contribution"
                  select
                  onChange={(e) => handleSearchParamChange('contributionOp', Number(e.target.value))}
                >
                  {contributionOption.map((contribution) => (
                    <MenuItem key={contribution.value} value={contribution.value}>
                      {contribution.label}
                    </MenuItem>
                  ))}
                </GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  size="small"
                  getOptionLabel={(option) => {
                    return productKarajicMatrixs.find((row) => row.id.toString() === option)?.name ?? ''
                  }}
                  options={productKarajicMatrixs.map((option) => option.id.toString())}
                  defaultValue={[]}
                  value={tableData?.pendingSearch.karajicMatrixIds?.map((s) => s.toString()) ?? []}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={productKarajicMatrixs.find((row) => row.id.toString() === option)?.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    tableData?.handleSearchParamChange(
                      'karajicMatrixIds',
                      newValue.map((v) => Number(v)),
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="karajicMatrix"
                      placeholder="karajicMatrix"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormGroup row className="mt-1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => tableData?.handleSearchParamChange('isDeadStock', e.target.checked)}
                        checked={tableData?.pendingSearch?.isDeadStock}
                      />
                    }
                    label="สินค้าที่ไม่มีการขายเกิน 3 เดือน"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <FilteredTable<IProductAnalyticReportTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: '',
                render: ({ isDeadStock }) => (
                  <FlexBox alignItems="center">{isDeadStock && <DeadStockSignal />}</FlexBox>
                ),
                fixedWidth: 50,
              },
              {
                name: `รหัสสินค้า`,
                dataIndex: 'code',
                render: ({ id, code, imageUrl, grade, nameLocal }) => (
                  <Box display="flex" alignItems="flex-start">
                    <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                      <Photo />
                    </Avatar>
                    {
                      <FlexBox ml={1} flexDirection="column">
                        <Link color="primary" to={`/products/${id}/profile`}>
                          {code} {grade && <ProductGradeChip grade={grade as ProductGrade} size="small" />}
                        </Link>

                        <Typography variant="body2">{nameLocal}</Typography>
                      </FlexBox>
                    }
                  </Box>
                ),
              },
              {
                name: 'หมวด',
                dataIndex: 'grade',
                fixedWidth: 70,
                align: 'center',
              },
              {
                name: `Karajic Matrix`,
                render: ({ karajicMatrix }) => <>{karajicMatrix?.name ?? '-'}</>,
                align: 'center',
              },
              {
                name: `จำนวน`,
                dataIndex: 'saleOrderAmount',
                numeric: true,
                format: '0,0',
                align: 'center',
              },
              {
                name: `ยอดขาย`,
                dataIndex: 'salesTotal',
                numeric: true,
                format: '0,0',
              },
              {
                name: `%Sale`,
                render: ({ salesTotalPercent }) => <>{`${salesTotalPercent}%`}</>,
                align: 'center',
              },
              {
                name: `กำไรขั้นต้น`,
                dataIndex: 'grossProfit',
                numeric: true,
                format: '0,0',
              },
              {
                name: `อัตรากำไรขั้นต้น`,
                render: ({ grossProfitMarginPercent }) => <>{`${grossProfitMarginPercent}%`}</>,
                align: 'center',
              },
              {
                name: `รวมมูลค่าสินค้าคงเหลือ`,
                dataIndex: 'snapshotStockValue',
                numeric: true,
                format: '0,0',
              },
              {
                name: `อัตราหมุนเวียนสินค้าคงเหลือ`,
                dataIndex: 'turnOverRate',
                numeric: true,
                format: '0,0',
              },
              {
                name: `%Contribution`,
                render: ({ contributionPercent }) => <>{`${contributionPercent}%`}</>,
                align: 'center',
              },
            ]}
            data={data}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            disableDateRangeFilter
            disableSearch
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(ProductAnalyticTableDataPage, true)
