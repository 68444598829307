import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { ICustomerProfile } from './types'

export interface AddCustomerBlacklistRequest {
  remark: string
}

export const addCustomerBlacklistApi = errorSingleHandler<ICustomerProfile>(
  (id: number, request: AddCustomerBlacklistRequest) => {
    const path = `customers/${id}/blacklist`
    return smartAuthAPI.post(path, request)
  },
)
