import React, { useContext } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { FlexBox, GeneralAutoComplete, GeneralTextFieldForm, SubTopic } from '../../../custom-components'
import { TransportTaskUpdatePageContext } from './TransportTaskUpdatePage'
import { useFormContext } from 'react-hook-form'
import TransportTaskUpdateFormValues from './TransportTaskUpdateFormValues'
import { ButtonRadios, Space } from '../../../shared-components'
import { ShippingMethod, shippingMethods } from '../../../types'
import { ShippingProviderData } from '../../../api/smartbestbuys-api/data/data-types'

const TransportShippingMethodSelectForm: React.FC = () => {
  // context
  const { transportTaskUpdateData: transportTaskCreateData } = useContext(TransportTaskUpdatePageContext)
  const { shippingProviders } = transportTaskCreateData

  const shippingProvidersWithNewProviderCreation = [...shippingProviders, { id: -1, name: 'เพิ่มขนส่งใหม่' }]

  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<TransportTaskUpdateFormValues>()

  const { shippingMethod, shippingProviderId, shippingProviderName } = watch()

  // render
  return (
    <FlexBox
      p={3}
      justifyContent="space-between"
      flexDirection="column"
      borderColor={errors.selectedProducts ? 'error.main' : undefined}
      clone
    >
      <Paper id="shipping-method-selector-box">
        <SubTopic>เลือกขนส่ง</SubTopic>
        <div className="mb-4" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ButtonRadios
              label="วิธีการขนส่ง *"
              items={shippingMethods}
              value={shippingMethod}
              onValueChange={(value) => {
                if (!!errors.shippingMethod) {
                  clearErrors('shippingMethod')
                }
                setValue('shippingMethod', value as string)
              }}
            />
          </Grid>
          {shippingMethod === ShippingMethod.Express && (
            <>
              <Grid item xs={12} md={6}>
                <GeneralAutoComplete
                  id="transport-shipping-method-select-box"
                  control={control}
                  name="shippingProviderId"
                  value={
                    shippingProviderName && shippingProviderId
                      ? { label: shippingProviderName, value: shippingProviderId }
                      : undefined
                  }
                  onPostChange={(value) => {
                    setValue(
                      'shippingProviderName',
                      shippingProviders.find((row: ShippingProviderData) => row.id === value)?.name,
                    )
                  }}
                  options={shippingProvidersWithNewProviderCreation.map((sp) => {
                    return {
                      label: sp.name,
                      value: sp.id,
                    }
                  })}
                  rules={{ required: 'กรุณาเลือกขนส่ง' }}
                  renderInput={(params) => params}
                  label="เลือกขนส่ง *"
                ></GeneralAutoComplete>
              </Grid>

              {shippingProviderId === -1 && (
                <>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm control={control} name="shippingProviderName" label="ชื่อขนส่งใหม่" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="shippingProviderDetail"
                      label="รายละเอียดเพิ่มเติม (ถ้ามี)"
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        {errors.shippingMethod && (
          <>
            <Space />
            <Typography color="error" variant="caption">
              {errors.shippingMethod.message}
            </Typography>
          </>
        )}
      </Paper>
    </FlexBox>
  )
}

export default TransportShippingMethodSelectForm
