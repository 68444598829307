import { Avatar, createStyles, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'

interface Props {
  icon: React.ReactNode
  color?: string
}

const IconChip: React.FC<Props> = ({ icon, color }) => {
  const classes = useStyles(color)()
  return <Avatar className={classes.chip}>{icon}</Avatar>
}

const useStyles = (color?: string) =>
  makeStyles((theam) => {
    return createStyles({
      chip: {
        width: theam.spacing(4),
        height: theam.spacing(4) - 1,
        backgroundColor: color ? _.get(theam.palette, color) : theam.palette.primary.background,
      },
    })
  })

export default IconChip
