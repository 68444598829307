import { useEffect, useState } from 'react'
import { getUserProfileByIdAPI, UserProfile } from '../../api/smartbestbuys-api'

const useUserProfile = (id: number): [UserProfile | undefined, () => void] => {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined)
  const [reload, setReload] = useState(false)

  const forceReload = () => {
    setReload((prev) => !prev)
  }

  useEffect(() => {
    ;(async () => {
      const response = await getUserProfileByIdAPI(id)
      if (response?.data) {
        setUserProfile(response?.data)
      }
    })()
  }, [id, reload])
  return [userProfile, forceReload]
}

export default useUserProfile
