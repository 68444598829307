import { Button, MenuItem, Popover } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { BaseData, PaymentRequisitionProfile } from '../../../api/smartbestbuys-api'
import { PaymentRequisitionStatus } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import PaymentRequisitionCancelDialog from './PaymentRequisitionCancelDialog'

interface PaymentRequisitionInfo {
  id: number
  code: string
  status: BaseData
  paymentCode?: string
}

interface Props {
  paymentRequisitionInfo: PaymentRequisitionInfo
  onCancelSuccess: (data: PaymentRequisitionProfile) => void
}

const PaymentRequisitionProfileMenu: React.FC<Props> = (props: Props) => {
  //props
  const { paymentRequisitionInfo, onCancelSuccess } = props

  //data
  const whiteListStatus = [
    PaymentRequisitionStatus.PendingReview,
    PaymentRequisitionStatus.PendingTransfer,
    PaymentRequisitionStatus.PendingCheque,
  ]

  // state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false)
  const menuOpened = Boolean(anchorEl)
  const showedCancelButton = !!whiteListStatus.find((status) => status === paymentRequisitionInfo.status.id)

  // handle
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  // permission
  const cancelPermission = getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Cancel)

  return (
    <>
      <PaymentRequisitionCancelDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        paymentRequisitionInfo={paymentRequisitionInfo}
        onComplete={(data) => {
          setOpenCancelDialog(false)
          onCancelSuccess(data)
        }}
      />
      <Button variant="contained" size="large" onClick={handleClick}>
        <MoreHoriz />
      </Button>
      <Popover
        open={menuOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!!paymentRequisitionInfo.paymentCode && (
          <>
            <MenuItem>
              <Link target="_blank" to={`/payment-requisitions/${paymentRequisitionInfo.id}/pdf/receipt`}>
                ดูใบสำคัญรับเงิน
              </Link>
            </MenuItem>
            <MenuItem>
              <Link target="_blank" to={`/payment-requisitions/${paymentRequisitionInfo.id}/pdf/payment-voucher`}>
                ดูใบสำคัญจ่าย
              </Link>
            </MenuItem>
            {/* <MenuItem>
              <Link target="_blank" to={`/payment-requisitions/${paymentRequisitionInfo.id}/pdf/certificate-receipt`}>
                ดูใบรับรองแทนใบเสร็จรับเงิน
              </Link>
            </MenuItem> */}
          </>
        )}
        {showedCancelButton && (
          <ConditionTooltip showTooltip={!cancelPermission.hasPermission} title={cancelPermission.alertMessage}>
            <MenuItem disabled={!cancelPermission.hasPermission} onClick={() => setOpenCancelDialog(true)}>
              ยกเลิกการทำเบิก
            </MenuItem>
          </ConditionTooltip>
        )}
      </Popover>
    </>
  )
}

export default PaymentRequisitionProfileMenu
