import { UserActivityRequest } from '..'
import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const createSOUserActivity = errorSingleHandler<{ id: number }>(
  async (id: number, request: UserActivityRequest) => {
    const path = `/sale-orders/${id}/activity`
    return await smartAuthAPI.post(path, request)
  },
)
