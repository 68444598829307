import { Button, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Check, Edit } from '@material-ui/icons'
import { useState } from 'react'
import { PermissionGroup, PermissionTopic } from '../../../../api/smartbestbuys-api'
import { PermissionGroupData } from '../../../../api/smartbestbuys-api/data/data-types'
import { FlexBox, Grid } from '../../../../custom-components'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { ConditionTooltip, Space } from '../../../../shared-components'
import { getUserPermissionInfo } from '../../../../utils/permission'
import { PermissionGroupUpdateDialog } from '../update'

interface PermissionGroupDialogProps extends DialogProps {
  permissionGroup: PermissionGroup
  permissionTopics: PermissionTopic[]
  onUpdate?: (data: PermissionGroupData) => void
}

const PermissionGroupDialog = (props: PermissionGroupDialogProps) => {
  const { permissionGroup, permissionTopics, onUpdate, onClose, ...dialogProps } = props

  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)

  const filteredPermissions = permissionTopics
    .reduce<PermissionTopic[]>((summary, topic) => {
      return [
        ...summary,
        {
          id: topic.id,
          name: topic.name,
          permissions: topic.permissions.filter((permission) =>
            permissionGroup.permissions?.some((groupPermission) => groupPermission.id === permission.id),
          ),
        },
      ]
    }, [])
    .filter((topic) => topic.permissions.length > 0)

  const writePermission = getUserPermissionInfo(PermissionEnum.HR_User_Write)

  return (
    <>
      <PermissionGroupUpdateDialog
        open={openUpdateDialog}
        permissionGroup={permissionGroup}
        permissionTopics={permissionTopics}
        onComplete={(data) => {
          if (onUpdate) onUpdate(data)
          setOpenUpdateDialog(false)
        }}
        onClose={() => setOpenUpdateDialog(false)}
      />
      <Dialog {...dialogProps} fullWidth maxWidth="md">
        <DialogContent>
          <FlexBox justifyContent="space-between">
            <DialogTitle>กลุ่มสิทธิ์: {permissionGroup.name}</DialogTitle>
            <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
              <IconButton style={{ padding: 0 }} color="secondary" disabled={!writePermission.hasPermission}>
                <Edit
                  onClick={() => {
                    setOpenUpdateDialog(true)
                  }}
                />
              </IconButton>
            </ConditionTooltip>
          </FlexBox>
          {filteredPermissions.map((topic) => {
            return (
              <Grid container spacing={2}>
                <Grid key={topic.id} item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {topic.name}
                  </Typography>
                </Grid>
                {topic.permissions.map((permission) => {
                  return (
                    <Grid key={permission.id} item xs={3}>
                      <Typography variant="body2">
                        {<Check fontSize="small" style={{ color: '#32de84' }} />} {permission.name}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            )
          })}
          <Space />
          <FlexBox justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onClose && onClose({}, 'escapeKeyDown')
              }}
            >
              ตกลง
            </Button>
          </FlexBox>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PermissionGroupDialog
