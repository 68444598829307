import { useState, useEffect } from 'react'
import { PurchaseOrderPDFInfo } from '../../api/smartbestbuys-api/purchase-order/types'
import { getPurchaseOrderPDFInfo } from '../../api/smartbestbuys-api/purchase-order/apis'

export const usePurchaseOrderPDFInfo = (id: number, reload?: boolean) => {
  const [data, setData] = useState<PurchaseOrderPDFInfo | undefined>(undefined)

  const _getPurchaseOrderPDFInfo = async (id: number) => {
    const response = await getPurchaseOrderPDFInfo(id)
    setData(response?.data)
  }

  useEffect(() => {
    _getPurchaseOrderPDFInfo(id)
  }, [id, reload])

  return data
}
