import React, { useContext, useState } from 'react'
import { Button, Grid, Typography, Paper } from '@material-ui/core'
import numeral from 'numeral'
import { Edit } from '@material-ui/icons'
import { FlexBox, Table, Title } from '../../../../../../custom-components'
import ProductProfileContext from '../../ProductProfileContext'
import { ProductPrice } from '../../../../../../api/smartbestbuys-api/product'
import { ConditionTooltip } from '../../../../../../shared-components'
import { RootContext } from '../../../../../..'
import imgEmptyTable from '../../../../../../assets/images/image-empty-table.png'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { ProductEditPriceRangeDialog } from '.'

interface Props {
  isProductAvailable: boolean
}

const ProductPriceRange: React.FC<Props> = (props) => {
  const { isProductAvailable } = props
  const [edit, setEdit] = useState<boolean>(false)
  const {
    productProfile: { prices },
    reload,
  } = useContext(ProductProfileContext)
  const { triggerSnackbar } = useContext(RootContext)

  const writePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Write)

  const handleSuccess = () => {
    triggerSnackbar('บันทึกข้อมูลช่วงราคาตามจำนวนสำเร็จ')
    setEdit(false)
    reload()
  }

  const handleFail = () => {
    triggerSnackbar('บันทึกข้อมูลช่วงราคาตามจำนวนไม่สำเร็จ')
    setEdit(false)
  }

  const viewPriceRangeTable = () => (
    <>
      {isProductAvailable && (
        <FlexBox justifyContent="flex-end">
          <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
            <Button
              color="primary"
              startIcon={<Edit />}
              onClick={() => {
                setEdit(true)
              }}
              disabled={!writePermission.hasPermission}
            >
              ปรับราคา
            </Button>
          </ConditionTooltip>
        </FlexBox>
      )}
      <Table<ProductPrice>
        color="primary.dark"
        columns={[
          {
            name: 'จำนวนที่',
            dataIndex: 'toAmount',
            fixedWidth: 200,
            render: (rowData) => (
              <Typography variant="body2">
                {numeral(rowData.fromAmount).format('0,0')}&nbsp;-&nbsp;{numeral(rowData.toAmount).format('0,0')}
              </Typography>
            ),
          },
          {
            name: 'ราคาต่อหน่วย',
            dataIndex: 'price',
            numeric: true,
            fixedWidth: 300,
            format: '0,0.00',
          },
          {
            name: 'ราคารวม',
            dataIndex: 'price',
            numeric: true,
            render: (rowData) => (
              <Typography variant="body2">
                {numeral(rowData.fromAmount * rowData.price).format('0,0.00')}&nbsp;-&nbsp;
                {numeral(rowData.toAmount * rowData.price).format('0,0.00')}
              </Typography>
            ),
          },
        ]}
        data={prices}
        size="small"
        pagination={false}
        emptyImage={imgEmptyTable}
        emptyText="ยังไม่มีช่วงราคา"
        cleanSpace
      />
    </>
  )

  return (
    <>
      <ProductEditPriceRangeDialog
        open={edit}
        onClose={() => setEdit(false)}
        onSuccess={handleSuccess}
        onFail={handleFail}
      />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>ช่วงราคาตามจำนวน</Title>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {viewPriceRangeTable()}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ProductPriceRange
