import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { WithdrawalTicketStatus } from '../../enums'

interface Props {
  status: WithdrawalTicketStatus
  icon?: boolean
}

const useStyles = (status: WithdrawalTicketStatus) =>
  makeStyles((theme) => {
    const wtStatusMap = {
      [WithdrawalTicketStatus.Pending]: theme.palette.warning.main,
      [WithdrawalTicketStatus.Approved]: theme.palette.warning.main,
      [WithdrawalTicketStatus.InProgress]: theme.palette.warning.main,
      [WithdrawalTicketStatus.Done]: theme.palette.success.main,
      [WithdrawalTicketStatus.Rejected]: theme.palette.error.main,
      [WithdrawalTicketStatus.Cancelled]: theme.palette.error.main,
    }

    const wtStatusIconMap = {
      [WithdrawalTicketStatus.Pending]: theme.palette.warning.darkText,
      [WithdrawalTicketStatus.Approved]: theme.palette.warning.darkText,
      [WithdrawalTicketStatus.InProgress]: theme.palette.warning.darkText,
      [WithdrawalTicketStatus.Done]: theme.palette.success.darkText,
      [WithdrawalTicketStatus.Rejected]: theme.palette.error.darkText,
      [WithdrawalTicketStatus.Cancelled]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: wtStatusMap[status],
      },
      icon: {
        color: wtStatusIconMap[status],
      },
    })
  })

const ProductWithdrawalTicketStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({
  children,
  status,
  icon,
  ...othersProps
}) => {
  const classes = useStyles(status)()
  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      icon={icon ? <Description /> : undefined}
      {...othersProps}
    />
  )
}

export default ProductWithdrawalTicketStatusChip
