import { Box, Collapse, MenuItem, Paper, Switch } from '@material-ui/core'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FlexBox, GeneralTextFieldForm, Grid, Title } from '../../../custom-components'
import { banks } from '../../../data'
import { VendorCreateFormValues } from './VendorCreatePage'

interface VendorBankAccountFormProps {
  name?: string
}

const VendorBankAccountForm = (props: VendorBankAccountFormProps) => {
  // context & props
  const { control, setValue } = useFormContext<VendorCreateFormValues>()
  const { name } = props

  // state
  const [open, setOpen] = useState(false)

  const handleCollapseAttachment = () => {
    setOpen((prev) => {
      setValue('enabledBankAccount', !prev)
      return !prev
    })
  }

  return (
    <Box p={3} clone>
      <Paper>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Title>ข้อมูลบัญชีธนาคาร</Title>
          <Switch checked={open} onClick={() => handleCollapseAttachment()} />
        </FlexBox>
        {!!open && (
          <Collapse in={open}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  key="accountName"
                  control={control}
                  name={`${name}.accountName`}
                  label="ชื่อบัญชี *"
                  rules={{ required: 'กรุณากรอกชื่อบัญชี' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  key="accountNumber"
                  control={control}
                  name={`${name}.accountNumber`}
                  label="เลขบัญชี *"
                  rules={{ required: 'กรุณากรอกเลขบัญชี' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name={`${name}.bankId`}
                  label="ธนาคาร *"
                  rules={{ required: 'กรุณากรอกธนาคาร' }}
                  select
                >
                  {banks.map((bank) => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </GeneralTextFieldForm>
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  key="branchName"
                  control={control}
                  name={`${name}.branchName`}
                  label="สาขา *"
                  rules={{ required: 'กรุณากรอกสาขา' }}
                />
              </Grid>
            </Grid>
          </Collapse>
        )}
      </Paper>
    </Box>
  )
}

export default VendorBankAccountForm
