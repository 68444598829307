import React, { useContext, useState } from 'react'
import { IconButton, Paper, Typography } from '@material-ui/core'
import { FlexBox, SubTopic } from '../../../custom-components'
import { Edit } from '@material-ui/icons'
import { TransportTaskProfilePageContext } from './TransportTaskProfilePage'
import TransportTaskProfileAssigneeUpdateDialog from './TransportTaskProfileAssigneeUpdateDialog'
import { RootContext } from '../../..'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../shared-components'

const AssigneeUserBox: React.FC = (props) => {
  // context && state
  const { transportTaskProfile, forceReload } = useContext(TransportTaskProfilePageContext)
  const { triggerSnackbar } = useContext(RootContext)
  const [updateAssigneeOpen, setUpdateAssigneeOpen] = useState<boolean>(false)

  // permission
  const writeTransportTaskPermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" alignItems="center" clone>
      <Paper>
        <TransportTaskProfileAssigneeUpdateDialog
          open={updateAssigneeOpen}
          onClose={() => setUpdateAssigneeOpen(false)}
          onComplete={(data) => {
            triggerSnackbar(`แก้ไขผู้รับผิดชอบสำเร็จ`)
            forceReload()
            setUpdateAssigneeOpen(false)
          }}
          onFail={() => {
            triggerSnackbar(`แก้ไขผู้รับผิดชอบสำเร็จ`)
          }}
        />
        <SubTopic>ผู้รับผิดชอบงาน</SubTopic>
        <FlexBox alignItems="center">
          <Typography variant="body1" color="textSecondary">
            {transportTaskProfile.assignee
              ? `${transportTaskProfile.assignee.firstName} ${transportTaskProfile.assignee.lastName}`
              : 'ยังไม่ระบุ'}
          </Typography>
          {/* add TODO: the button will be shown if this transport task updatable / implement functionality later */}
          <span className="ml-1">
            <ConditionTooltip
              showTooltip={!writeTransportTaskPermission.hasPermission}
              title={writeTransportTaskPermission.alertMessage}
            >
              <IconButton
                disabled={!writeTransportTaskPermission.hasPermission}
                size="small"
                color="secondary"
                onClick={() => setUpdateAssigneeOpen(true)}
              >
                <Edit />
              </IconButton>
            </ConditionTooltip>
          </span>
        </FlexBox>
      </Paper>
    </FlexBox>
  )
}

export default AssigneeUserBox
