import { DialogProps } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { IRefundRequisitionProfile, rejectRefundRequisitionAPI } from '../../../api/smartbestbuys-api'
import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'

interface Props extends DialogProps {
  refundRequisitionId: number
  onComplete: (data: IRefundRequisitionProfile) => void
}

const RefundRequisitionRejectDialog: React.FC<Props> = (props) => {
  const { refundRequisitionId, onComplete, onClose, ...dialogProps } = props

  const { triggerSnackbar } = useContext(RootContext)

  const textOption: UserActivityDialog_TextOption = {
    title: 'ไม่อนุมัติการคืนเงิน',
    submitText: 'ยืนยันไม่อนุมัติ',
    cancelText: 'ย้อนกลับ',
    actionMessageText: 'สาเหตุที่ไม่อนุมัติ',
    newActionMessageText: 'สาเหตุใหม่',
    newActionMessageOption: 'เพิ่มสาเหตุใหม่',
    actionMessageErrorText: 'กรุณากรอกสาเหตุของการไม่อนุมัติ',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: true,
  }

  const handleSubmitForm = async (values: UserActivityValues) => {
    const response = await rejectRefundRequisitionAPI(refundRequisitionId, {
      userAction: values.actionId,
      actionMessageId: values.actionMessageId === -1 ? undefined : values.actionMessageId,
      actionMessage: values.actionMessage,
      remark: values.remark,
    })

    if (response?.status === 200) {
      triggerSnackbar('ไม่อนุมัติการคืนเงินแล้ว')
      onClose && onClose({}, 'escapeKeyDown')
      onComplete(response.data)
    } else {
      triggerSnackbar('ดำเนินการไม่สำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      onClose={() => onClose && onClose({}, 'escapeKeyDown')}
      fullWidth
      userAction={UserAction.RejectRefundRequisition}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmitForm}
    />
  )
}

export default RefundRequisitionRejectDialog
