import { useContext, useState } from 'react'
import { ButtonRadios, GridBox, Loading } from '../../../shared-components'
import { Bar, NivoColorPalette, Statistic, Line, ProgressBar } from '../../../visaulization-components'
import { BarDateGroupFilter } from '../../../visaulization-components/Bar/Bar'
import DashboardContext from '../DashboardContext'
import _ from 'lodash'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import { roundNumber } from '../../../functions/number-utils'
import { PaymentMethod, SOPaymentTransactionStatus } from '../../../enums'
import { getPaymentMethodNameById } from '../../../types'
import { FlexBox, Title } from '../../../custom-components'

export const CashFlowBar = () => {
  const [displayOption, setDisplayOption] = useState(1)
  const { salesReport } = useContext(DashboardContext)
  const [dateGroupFilter, setDateGroupFilter] = useState<BarDateGroupFilter>(BarDateGroupFilter.Monthly)
  if (!salesReport)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { paymentTransactions } = salesReport
  const showAsTotal = displayOption === 1
  const mappedData = _.chain(paymentTransactions.filter((t) => t.statusId === SOPaymentTransactionStatus.APPROVED))
    .groupBy((d) => format(new Date(d.createdAt), dateGroupFilter, { locale: thLocale }))
    .map((values, key) => {
      return {
        cash: values
          .filter((v) => v.paymentMethodId === PaymentMethod.Cash)
          .reduce<number>((sum, each) => sum + roundNumber(each.amount), 0),
        transfer: values
          .filter((v) => v.paymentMethodId === PaymentMethod.Transfer)
          .reduce<number>((sum, each) => sum + roundNumber(each.amount), 0),
        credit: values
          .filter((v) => v.paymentMethodId === PaymentMethod.Credit)
          .reduce<number>((sum, each) => sum + roundNumber(each.amount), 0),
        cheque: values
          .filter((v) => v.paymentMethodId === PaymentMethod.Cheque)
          .reduce<number>((sum, each) => sum + roundNumber(each.amount), 0),
        total: values.reduce<number>((sum, each) => sum + roundNumber(each.amount), 0),
        t: key,
      }
    })
    .value()

  return (
    <GridBox item xs={12} row={16}>
      <Bar<{ cash: number; transfer: number; credit: number; cheque: number; total: number; t: string }>
        title="รายงานกระแสเงินสดรับ"
        head={
          <FlexBox justifyContent="space-between">
            <Title>รายงานกระแสเงินสดรับ</Title>
            <ButtonRadios
              items={[
                { id: 1, value: 1, name: 'ยอดรวม' },
                { id: 2, value: 2, name: 'ยอดรวมแต่ละช่องทาง' },
              ]}
              onValueChange={(value) => setDisplayOption(Number(value))}
              grouped
              defaultValue={displayOption}
            />
          </FlexBox>
        }
        keys={showAsTotal ? ['total'] : ['cash', 'transfer', 'credit', 'cheque']}
        colors={
          showAsTotal
            ? [NivoColorPalette.oceanGreen]
            : [
                NivoColorPalette.oceanGreen,
                NivoColorPalette.orange,
                NivoColorPalette.intenseOrange,
                NivoColorPalette.yellow,
              ]
        }
        indexBy="t"
        data={mappedData.slice(mappedData.length - 12, mappedData.length)}
        yLegend="ยอดขาย"
        textMapping={{
          cash: getPaymentMethodNameById(PaymentMethod.Cash),
          transfer: getPaymentMethodNameById(PaymentMethod.Transfer),
          credit: getPaymentMethodNameById(PaymentMethod.Credit),
          cheque: getPaymentMethodNameById(PaymentMethod.Cheque),
          total: 'ยอดรวม',
        }}
        onDateGroupFilterChange={setDateGroupFilter}
      />
    </GridBox>
  )
}

export const TotalAmountStat = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { paymentTransactions } = salesReport
  const value = paymentTransactions
    .filter((pt) => pt.statusId === SOPaymentTransactionStatus.APPROVED)
    .reduce<number>((sum, transaction) => sum + roundNumber(transaction.amount), 0)
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic
        title="ยอดรวมกระแสเงินสดรับ"
        amount={value}
        format="0,0.00"
        subtitle="บาท"
        color={NivoColorPalette.oceanGreen}
      />
    </GridBox>
  )
}

export const CashTotalAmountStat = () => {
  const { paymentTransactionInfo } = useContext(DashboardContext)
  if (!paymentTransactionInfo)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { cash } = paymentTransactionInfo
  const amount = cash.approved.reduce<number>((sum, transaction) => sum + roundNumber(transaction.amount), 0)
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic
        title="ยอดรวม (เงินสด)"
        amount={amount}
        format="0,0.00"
        subtitle="บาท"
        color={NivoColorPalette.orange}
      />
    </GridBox>
  )
}

export const TransferedTotalAmountStat = () => {
  const { paymentTransactionInfo } = useContext(DashboardContext)
  if (!paymentTransactionInfo)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { transfer } = paymentTransactionInfo
  const amount = transfer.approved.reduce<number>((sum, transaction) => sum + roundNumber(transaction.amount), 0)
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic title="ยอดรวม (โอน)" amount={amount} format="0,0.00" subtitle="บาท" color={NivoColorPalette.yellow} />
    </GridBox>
  )
}

export const CreditCardTotalAmountStat = () => {
  const { paymentTransactionInfo } = useContext(DashboardContext)
  if (!paymentTransactionInfo)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { credit } = paymentTransactionInfo
  const amount = credit.approved.reduce<number>((sum, transaction) => sum + roundNumber(transaction.amount), 0)
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic
        title="ยอดรวม (บัตรเครดิต / เดบิต)"
        amount={amount}
        format="0,0.00"
        subtitle="บาท"
        color={NivoColorPalette.intenseOrange}
      />
    </GridBox>
  )
}

export const ChequeTotalAmountStat = () => {
  const { paymentTransactionInfo } = useContext(DashboardContext)
  if (!paymentTransactionInfo)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { cheque } = paymentTransactionInfo
  const amount = cheque.approved.reduce<number>((sum, transaction) => sum + roundNumber(transaction.amount), 0)
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic
        title="ยอดรวม​ (เช็ค)"
        amount={amount}
        format="0,0.00"
        subtitle="บาท"
        color={NivoColorPalette.default}
      />
    </GridBox>
  )
}

export const DebtTotalLineGraph = () => (
  <GridBox item xs={12} md={8} row={12}>
    <Line title="ยอดหนี้" />
  </GridBox>
)

export const LateDebtTotalLineGraph = () => (
  <GridBox item xs={12} md={8} row={12}>
    <Line title="% ลูกหนี้ค้างจ่ายเกินกำหนด" />
  </GridBox>
)

export const CashFlowTotal = () => (
  <GridBox item xs={6} md={12} row={6}>
    <Statistic title="ยอดรวมกระแสเงินสดรับ" amount={300000} subtitle="บาท" color={NivoColorPalette.default} />
  </GridBox>
)

export const CustomerCreditRatioProgressBar = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { creditCustomerPercentage } = salesReport
  const rounded = roundNumber(creditCustomerPercentage)
  return (
    <GridBox item xs={6} md={3} row={6}>
      <ProgressBar
        title="อัตราส่วนลูกค้าเครดิต"
        amount={rounded}
        color={NivoColorPalette.oceanGreen}
        rowSize={5}
        unit="%"
        progress={rounded}
      />
    </GridBox>
  )
}

export const DSOLineGraph = () => (
  <GridBox item xs={6} md={12} row={6}>
    <ProgressBar
      title="DSO ระยะเวลาในการเก็บหนี้ถัวเฉลี่ย"
      amount={75}
      color={NivoColorPalette.intenseOrange}
      rowSize={6}
      unit="%"
      progress={75}
    />
  </GridBox>
)
