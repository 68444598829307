export enum CustomerPickUpMethod {
  RequestShipping = 1,
  PickUpWithTime = 2,
  PickUpAtShop = 3,
}

export interface CustomerPickUpMethodDetail {
  id: CustomerPickUpMethod
  name: string
  warningMessage?: string
}

export const customerPickUpMethods: CustomerPickUpMethodDetail[] = [
  { id: CustomerPickUpMethod.RequestShipping, name: 'จัดส่งถึงที่' },
  {
    id: CustomerPickUpMethod.PickUpWithTime,
    name: 'นัดรับหน้าร้าน',
    warningMessage: 'งานให้ฝ่ายคลังจะถูกสร้างโดยอัตโนมัติหลังจากสร้างใบสั่งขายสำเร็จ',
  },
  // {
  //   id: CustomerPickUpMethod.PickUpAtShop,
  //   name: 'รับหน้าร้านทันที (ลูกค้ารับของแล้ว)',
  //   warningMessage: 'สินค้าจะถูกตัดสต๊อกโดยอัตโนมัติหลังจากสร้างใบสั่งขายสำเร็จ',
  // },
]

export const getCustomerPickUpMethodNameById = (id: number): string => {
  const customerPickUpMethod = customerPickUpMethods.find((pc) => pc.id === id)
  return customerPickUpMethod ? customerPickUpMethod.name : 'unknown'
}
