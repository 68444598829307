import React from 'react'
import { Grid, MenuItem, Paper } from '@material-ui/core'
import {
  FlexBox,
  GeneralAutoComplete,
  GeneralTextFieldForm,
  KeyboardDatePickerForm,
  SubTopic,
} from '../../../custom-components'
import { useFormContext } from 'react-hook-form'
import { ShippingCarData, UserData } from '../../../api/smartbestbuys-api/data/data-types'
import { Space } from '../../../shared-components'
import { TransportPickupTaskFormValues } from './TransportPickupTaskFormValues'
import { TransportPickupTaskFormData } from './TransportPickupTaskForm'

interface Props {
  formData: TransportPickupTaskFormData
}

const TransportPickupTaskDetailForm: React.FC<Props> = ({ formData }) => {
  // form data
  const { users, shippingCars } = formData

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<TransportPickupTaskFormValues>()

  const { dueDate, car, messenger } = watch()

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>รายละเอียดงานรับของ</SubTopic>
        <Space />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <KeyboardDatePickerForm
              control={control}
              label="วันที่รับสินค้า *"
              name="dueDate"
              fullWidth
              gutterTop
              value={dueDate}
              size="small"
              rules={{ required: { value: true, message: 'กรุณาเลือกวันที่จะรับสินค้า' } }}
              defaultValue={null}
              error={!!errors.dueDate}
              helperText={errors.dueDate?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="carId"
              label="เลขทะเบียนรถ (ถ้ามี)"
              onChange={(e: any) => {
                setValue('car', {
                  id: e.target.value,
                  name: shippingCars.find((row: ShippingCarData) => row.id === e.target.value)?.name as string,
                })
              }}
              value={car?.id}
              select
            >
              {shippingCars.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralAutoComplete
              id="transport-task-details-select-box"
              control={control}
              name="messenger"
              onPostChange={(value) => {
                const user = users.find((row: UserData) => row.id === value)
                if (!user) return
                setValue('messenger', {
                  id: user?.id,
                  name: user?.firstName + ' ' + user?.lastName,
                })
              }}
              options={users.map((user) => {
                return {
                  label: user?.firstName + ' ' + user?.lastName,
                  value: user.id,
                }
              })}
              rules={{ required: 'กรุณาเลือกคนรับสินค้า' }}
              renderInput={(params) => params}
              label="ผู้รับของ *"
              value={{
                label: messenger?.name,
                value: messenger?.id,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
          </Grid>
        </Grid>
      </Paper>
    </FlexBox>
  )
}

export default TransportPickupTaskDetailForm
