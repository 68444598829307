import React, { useContext } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Form, GeneralTextFieldForm } from '../../../../../../../../custom-components'
import { RootContext } from '../../../../../../../..'
import CustomerProfileContext from '../../../../CustomerProfileContext'
import { createCustomerContact } from '../../../service'
import { genderInformation } from '../../../../../../../../data'
import CustomerContactCreationFormValue from './CustomerContactCreationFormValue'
import { customerDepartments } from '../../../../../../../../data/customer'

const CustomerContactCreationDialog: React.FC<DialogProps> = (props) => {
  const { onClose, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)
  const {
    reload,
    customerProfile: { id: customerId },
  } = useContext(CustomerProfileContext)

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<CustomerContactCreationFormValue>({
    defaultValues: {
      gender: '',
    },
  })

  const handleSubmitCustomerContactCreationForm = async (value: CustomerContactCreationFormValue) => {
    const response = await createCustomerContact(customerId, value)
    if (response?.status === 201) {
      triggerSnackbar('เพิ่มผู้ติดต่อสำเร็จ')
      reload()
      onClose && onClose({}, 'escapeKeyDown')
      reset()
    } else {
      triggerSnackbar('เพิ่มผู้ติดต่อไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitCustomerContactCreationForm)}>
        <DialogTitle>เพิ่มผู้ติดต่อ</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="nickname"
                rules={{ required: 'กรุณาใส่ชื่อผู้ติดต่อ' }}
                label="ชื่อผู้ติดต่อ *"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="gender"
                rules={{ required: 'กรุณาเลือกเพศ' }}
                label="เพศ *"
                select
              >
                {genderInformation.map((gender) => (
                  <MenuItem key={gender.id} value={gender.value}>
                    {gender.name}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="customerDepartmentId"
                rules={{ required: 'กรุณาเลือกตำแหน่ง' }}
                label="ตำแหน่ง *"
                select
              >
                {customerDepartments.map((department) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                type="tel"
                control={control}
                name="phoneNumber"
                rules={{
                  required: 'กรุณาใส่เบอร์โทรศัพท์',
                  minLength: { value: 10, message: 'กรุณาใส่เบอร์โทรศัพท์ 10 หลัก' },
                  maxLength: { value: 10, message: 'กรุณาใส่เบอร์โทรศัพท์ 10 หลัก' },
                }}
                label="เบอร์โทรศัพท์ *"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="email" label="อีเมล์ (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="line" label="ไลน์ (ถ้ามี)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="facebook" label="เฟชบุ๊ก (ถ้ามี)" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            ยืนยันเพิ่มผู้ติดต่อ
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerContactCreationDialog
