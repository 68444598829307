import { useState, useEffect } from 'react'
import { getCustomerProfile, ICustomerProfile } from '../api/smartbestbuys-api'

export const useCustomerProfile = (id?: number, reload?: boolean): ICustomerProfile | undefined => {
  const [profile, setProfile] = useState<ICustomerProfile | undefined>(undefined)

  const _getCustomer = async (id: number) => {
    const response = await getCustomerProfile(id)
    setProfile(response?.data)
  }

  useEffect(() => {
    if (id) _getCustomer(id)
  }, [id, reload])

  return profile
}
