import { Box, Grid, Paper } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { GeneralTextFieldForm, SubTopic } from '../../../custom-components'
import UserCreateFromValue from './UserCreateFromValue'

const UserCreateLoginBox: React.FC = () => {
  const { control } = useFormContext<UserCreateFromValue>()

  return (
    <Box p={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>ข้อมูลเข้าสู่ระบบ</SubTopic>
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              id="user-login-box"
              control={control}
              name="username"
              label="ชื่อผู้ใช้ *"
              rules={{ required: 'กรุณากรอกชื่อผู้ใช้' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="password"
              label="รหัสผ่าน *"
              rules={{ required: 'กรุณากรอกรหัสผ่าน' }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default UserCreateLoginBox
