import { SOPaymentFormValue } from './types'
import {
  SOPaymentTransactionCreationRequest,
  makePayment as makePaymentAPI,
} from '../../../../../../api/smartbestbuys-api'

export const makePayment = (saleOrderId: number, values: SOPaymentFormValue) => {
  const { hasPaymentAdjustment, hasWithhodingTax } = values
  const request: SOPaymentTransactionCreationRequest = {
    paymentMethodId: values.paymentMethodId,
    amount: values.amount,
    bankId: values.bankId,
    bankAccountId: values.bankAccountId,
    paymentAdjustmentTypeId: hasPaymentAdjustment ? values.paymentAdjustmentTypeId : undefined,
    paymentAdjustmentAmount: hasPaymentAdjustment ? values.paymentAdjustmentAmount : undefined,
    withholdingAmount: hasWithhodingTax ? values.withholdingAmount : undefined,
    withholdingTaxDocumentReceived: hasWithhodingTax ? values.withholdingTaxDocumentReceived : undefined,
    payslipBase64: values.payslipBase64,
    referenceNumber: values.referenceNumber,
    paymentTimestamp: values.paymentTimestamp ? new Date(values.paymentTimestamp) : undefined,
    branchName: values.branchName,
    refDocumentIds: values.refDocumentIds,
  }

  return makePaymentAPI(saleOrderId, request)
}
