import { Typography } from '@material-ui/core'
import React from 'react'

const QuotationsTaxInvoiceInformationRemark: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary">
      *** ซื้อครบ 499 บาท ส่งมอบสินค้าภายในกรุงเทพ และ ปริมณฑล ฟรี!!
      <br />
      *** ต่างจังหวัด ลูกค้าเป็นผู้ออกค่าขนส่ง ทางบริษัทฯมีบริการส่งสินค้าตามบริษัทขนส่งที่ลูกค้ากำหนด
      <br />
      *** กรุณาตรวจสอบรายการสินค้าก่อนการสั่งซื้อ ทางบริษัทฯไมมีนโยบายรับคืนสินค้าหากมีการเปิดใบสั่งซื้อมาแล้ว
      <br />
      *** นโยบายการคืนสินค้า
      <br />
      1. บริษัทฯ ยินดีรับคืนสินค้าจากปัญหาการสั่งซื้อ หรือจัดส่งผิดพลาด จากบริษัทฯ ภายใน 5 วันทำการ
      นับจากวันที่ลูกค้าได้รับมอบสินค้า โดยสินค้าและหีบห่อบรรจุภัณฑ์ จะต้องอยู่ในสภาพสมบูรณ์ ไม่มีร่องรอยการใช้งาน
      ทางลูกค้าต้องมีใบส่งสินค้าจากการสั่งซื้อเพื่ออ้างอิงวันที่การสั่งซื้อ ทั้งนี้ ทางบริษัทฯ
      ขอสงวนสิทธิ์ไม่รับประกันสินค้า หรือ คืนสินค้าหากไม่มีใบส่งสินค้าในทุกกรณี
      <br />
      2. สินค้าที่ลูกค้าสั่งผลิตตามแบบโดยเฉพาะเจาะจง หรือที่บริษัทฯ จัดหาให้ลูกค้าเป็นการเฉพาะ บริษัทฯ
      ขอสงวนสิทธิ์ในการรับคืนสินค้าดังกล่าว
    </Typography>
  )
}

export default QuotationsTaxInvoiceInformationRemark
