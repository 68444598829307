import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'

import { PurchaseRequisitionStatus } from '../../enums'
import { Description } from '@material-ui/icons'

interface Props {
  status: PurchaseRequisitionStatus
  icon?: boolean
}

const useStyles = (status: PurchaseRequisitionStatus) =>
  makeStyles((theme) => {
    const chipColors = {
      [PurchaseRequisitionStatus.Approved]: theme.palette.success.light,
      [PurchaseRequisitionStatus.Canceled]: theme.palette.error.light,
      [PurchaseRequisitionStatus.PendingReview]: theme.palette.warning.light,
      [PurchaseRequisitionStatus.Rejected]: theme.palette.error.light,
    }

    const iconColors = {
      [PurchaseRequisitionStatus.Approved]: theme.palette.success.darkText,
      [PurchaseRequisitionStatus.Canceled]: theme.palette.error.darkText,
      [PurchaseRequisitionStatus.PendingReview]: theme.palette.warning.darkText,
      [PurchaseRequisitionStatus.Rejected]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: chipColors[status],
      },
      icon: {
        color: iconColors[status],
      },
    })
  })

const PurchaseRequisitionStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({
  children,
  icon,
  status,
  ...othersProps
}) => {
  const classes = useStyles(status)()
  const image = {
    [PurchaseRequisitionStatus.Approved]: <Description />,
    [PurchaseRequisitionStatus.Canceled]: <Description />,
    [PurchaseRequisitionStatus.PendingReview]: <Description />,
    [PurchaseRequisitionStatus.Rejected]: <Description />,
  }

  return (
    <Chip
      icon={icon ? image[status] : undefined}
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      {...othersProps}
    />
  )
}

export default PurchaseRequisitionStatusChip
