import { createMuiTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { red, amber } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    custom: {
      golden: '#D1B000',
      stroke: fade('#000000', 0.23),
    },
    primary: {
      main: red[800],
      light: '#FF5F52',
      dark: '#8E0000',
      border: fade(red[800], 0.5),
      background: fade(red[800], 0.08),
      darkText: 'black',
    },
    secondary: {
      main: amber.A400,
      dark: '#C79400',
      light: '#F88078',
      border: fade(amber.A400, 0.5),
      background: fade(amber.A400, 0.08),
      darkText: 'black',
    },
    warning: {
      main: '#FF9800',
      dark: '#C77700',
      light: '#FFB547',
      border: fade('#FF9800', 0.5),
      background: fade('#FF9800', 0.08),
      darkText: '#663D00',
    },
    error: {
      main: red[500],
      light: '#F88078',
      dark: '#E31B0C',
      border: fade(red[500], 0.5),
      background: fade(red[500], 0.08),
      darkText: '#5D0C28',
      contrastText: 'white',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
      border: fade(red[500], 0.5),
      background: '#EDF7ED',
      darkText: '#1E4620',
      contrastText: 'white',
    },
    info: {
      main: '#E91E63',
      dark: '#B0003A',
      darkText: '#E91E63',
      contrastText: 'white',
    },
  },
  typography: {
    fontFamily: "'Kanit', sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        minWidth: 'unset',
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiContainer: {
      root: {
        marginLeft: 'unset',
      },
      disableGutters: {
        padding: 8,
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    // TODO: remove this theme
    MuiPaper: {
      root: {
        padding: 16,
      },
    },
    MuiAppBar: {
      root: {
        padding: 0,
      },
    },
    MuiDrawer: {
      paper: {
        padding: 0,
      },
    },
    MuiDialog: {
      paper: {
        padding: 0,
      },
    },
    MuiPopover: {
      paper: {
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        paddingRight: 24,
      },
    },
    MuiMenu: {
      paper: {
        padding: 0,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
  },
  props: {
    MuiContainer: {
      maxWidth: 'lg',
      disableGutters: true,
    },
    MuiTypography: {
      variantMapping: {
        subtitle1: 'span',
        subtitle2: 'span',
        body1: 'span',
        body2: 'span',
      },
    },
    MuiTextField: {
      color: 'primary',
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
  },
})

export default theme
