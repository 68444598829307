import { useState } from 'react'
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@material-ui/core'
import { Clear, Edit, Delete, Photo } from '@material-ui/icons'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Avatar, TColumn } from '../../../../../../custom-components'
import { SelectedUpdationProduct, SQLCommand } from '../../../../../../sales-system/types'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import SOUpdationFormValue from '../SOUpdationFormValues'
import { ProductModal } from '../ProductSelector/ProductCard/ProductModal'

export const columns: (
  styles: ClassNameMap<any>,
  readOnly?: boolean,
  isDealer?: boolean,
) => TColumn<SelectedUpdationProduct>[] = (styles, readOnly, isDealer) => [
  {
    name: 'ชื่อสินค้า',
    dataIndex: 'nameLocal',
    fixedWidth: 300,
    render: (product: SelectedUpdationProduct, index: number) => (
      <ProductName key={product.id} product={product} index={index} readOnly={readOnly} />
    ),
  },
  {
    name: 'พร้อมขาย',
    // dataIndex: 'stockAmount',
    numeric: true,
    format: '0,0',
    fixedWidth: 100,
    render: (_: SelectedUpdationProduct) => 'ไม่มีข้อมูล',
  },
  {
    name: 'ราคาต่อหน่วย',
    dataIndex: 'price',
    numeric: true,
    format: '0,0.00',
    fixedWidth: 100,
  },
  {
    name: 'จำนวน',
    dataIndex: 'amount',
    numeric: true,
    format: '0,0',
    fixedWidth: 100,
  },
  {
    name: 'ราคารวม',
    numeric: true,
    format: '0,0.00',
    fixedWidth: 100,
    render: (data: SelectedUpdationProduct) => data.price * data.amount,
  },
  ...(readOnly
    ? []
    : [
        {
          name: '',
          fixedWidth: 100,
          render: (data: SelectedUpdationProduct, index: number) => (
            <Action product={data} index={index} isDealer={isDealer} />
          ),
        },
      ]),
]

//TODO: have to refactor this file
const ProductName: React.FC<{ product: SelectedUpdationProduct; index: number; readOnly?: boolean }> = (props) => {
  const { getValues, setValue, control } = useFormContext<SOUpdationFormValue>()
  const { selectedProducts } = getValues()
  const [editable, setEditable] = useState(false)
  const { index, readOnly } = props
  const product = selectedProducts[index]
  const { imageUrl, nameLocal } = product

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Box display="flex" alignItems="flex-start">
        <Avatar variant="rounded" src={imageUrl} objectFit="contain">
          <Photo />
        </Avatar>
        {!editable && (
          <Box ml={1}>
            <Typography variant="body2">{nameLocal}</Typography>
          </Box>
        )}
      </Box>
      {editable ? (
        <>
          <Controller
            name={`selectedProducts.${index}.nameLocal` as const}
            control={control}
            defaultValue={nameLocal}
            render={({ field }) => (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    const { command, ...others } = product
                    selectedProducts[index] = {
                      ...others,
                      nameLocal: field.value,
                      command: command ? command : SQLCommand.UPDATE,
                    }
                    setValue('selectedProducts', selectedProducts)
                    setEditable(false)
                  }
                }}
                {...field}
              />
            )}
          />
          <IconButton color="secondary" size="small" onClick={() => setEditable(false)}>
            <Clear />
          </IconButton>
        </>
      ) : (
        !readOnly && (
          <IconButton color="secondary" size="small" onClick={() => setEditable(true)}>
            <Edit />
          </IconButton>
        )
      )}
    </Box>
  )
}

const Action: React.FC<{ product: SelectedUpdationProduct; index: number; isDealer?: boolean }> = (props) => {
  const { getValues, setValue, control } = useFormContext<SOUpdationFormValue>()
  const { remove } = useFieldArray<SOUpdationFormValue>({
    control,
    name: 'selectedProducts',
  })
  const { selectedProducts } = getValues()
  const { product, index, isDealer } = props
  const [open, setOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  return (
    <Box display="flex" justifyContent="space-around">
      <IconButton color="primary" size="small" onClick={() => setOpen(true)}>
        <Edit />
      </IconButton>
      <IconButton size="small" onClick={() => setConfirmOpen(true)}>
        <Delete />
      </IconButton>
      {open && <ProductModal open={open} onClose={() => setOpen(false)} product={product} isDealer={isDealer} />}
      {confirmOpen && (
        <Dialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">ยืนยันการนำสินค้าออกจากใบสั่งขาย</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              คุณแน่ใจที่จะทำการนำสินค้าออกจากใบสั่งขายนี้ใช่หรือไม่?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmOpen(false)}>ยกเลิก</Button>
            <Button
              onClick={() => {
                const targetProduct = selectedProducts[index]
                const isNew = targetProduct.command === SQLCommand.CREATE

                if (isNew) {
                  remove(index)
                } else {
                  setValue(`selectedProducts.${index}`, {
                    ...selectedProducts[index],
                    command: SQLCommand.DELETE,
                  })
                }

                setConfirmOpen(false)
              }}
              color="primary"
            >
              นำสินค้าออก
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}
