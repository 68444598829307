import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import { FlexBox, Link, SubTopic } from '../../../custom-components'
import { ArrowForward } from '@material-ui/icons'
import { TransportTaskProfilePageContext } from '../profile/TransportTaskProfilePage'

const CustomerRequisitionReferenceBox: React.FC = (props) => {
  // context
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const { customerRequisitionInfo } = transportTaskProfile

  // render
  // add TODO: develop to support canceled case
  return (
    <FlexBox p={3} justifyContent="space-between" alignItems="center" clone>
      <Paper>
        <SubTopic>คำขอลูกค้าอ้างอิง {customerRequisitionInfo?.code}</SubTopic>
        <Link color="primary" to={`/customer-requisitions/${customerRequisitionInfo?.id}/profile`} target="_blank">
          <span className="mr-2">ไปที่หน้ารายละเอียดคำขอลูกค้า</span> <ArrowForward fontSize="small" />
        </Link>
      </Paper>
    </FlexBox>
  )
}

export default CustomerRequisitionReferenceBox
