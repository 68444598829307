import React, { useState } from 'react'
import { Paper, Typography, Chip, Button } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { useStyles } from './styles'
import numeral from 'numeral'
import ProductModal from './ProductModal/ProductModal'
import { useFormContext } from 'react-hook-form'
import SOUpdationFormValue from '../../SOUpdationFormValues'
import { Avatar } from '../../../../../../../custom-components'
import { SelectedUpdationProduct } from '../../../../../../../sales-system/types'
import { ProductDataRow } from '../../../../../../../api/smartbestbuys-api'
import { SQLCommand } from '../../../../../../../api/smartbestbuys-api/sale/update-sale-order-api'

interface Props {
  product: ProductDataRow | SelectedUpdationProduct
  selectedAmount?: number
  isDealer?: boolean
}

const ProductCard: React.FC<Props> = (props) => {
  const { getValues, setValue } = useFormContext<SOUpdationFormValue>()
  const { selectedProducts } = getValues()

  const styles = useStyles()
  const [open, setOpen] = useState(false)

  const { product, selectedAmount, isDealer } = props
  const { id, nameLocal, code, stockAmount, unitLocal, imageUrl, command } = product
  const { reservedAmount } = product as ProductDataRow
  const isSelected = selectedAmount && selectedAmount > 0 && command !== SQLCommand.DELETE

  const handleClick = () => {
    if (isSelected) {
      let newSelectedProducts = selectedProducts
      const index = newSelectedProducts.findIndex((p) => p.productId === id)
      const targetProduct = newSelectedProducts[index]
      const isNew = targetProduct.command === SQLCommand.CREATE
      if (isNew) {
        newSelectedProducts.splice(index, 1)
        setValue('selectedProducts', newSelectedProducts)
      } else {
        setValue(`selectedProducts.${index}`, {
          ...targetProduct,
          command: SQLCommand.DELETE,
        })
      }
    } else {
      setOpen(true)
    }
  }

  return (
    <>
      <Paper className={styles.root}>
        <Typography variant="body2">{code}</Typography>
        <div className={styles.image}>
          <Avatar variant="rounded" className={styles.avatar} src={imageUrl} objectFit="contain">
            <Photo style={{ fontSize: 120 }} />
          </Avatar>
          {isSelected && (
            <Chip
              className={styles.chip}
              size="small"
              color="secondary"
              label={`เลือกแล้ว ${selectedAmount} ${unitLocal || ''}`}
            />
          )}
        </div>
        <Typography variant="body2" className={styles.productName}>
          {nameLocal}
        </Typography>
        <Typography className={styles.stockAmount} variant="caption">
          พร้อมขาย {numeral(stockAmount - reservedAmount).format('0,0')} {unitLocal || 'ชิ้น'}
        </Typography>
        <Button variant={isSelected ? 'outlined' : 'contained'} size="small" color="primary" onClick={handleClick}>
          {isSelected ? 'นำออกจากใบสั่งขาย' : 'เลือกเข้าใบสั่งขาย'}
        </Button>
      </Paper>
      {open && <ProductModal open={open} onClose={() => setOpen(false)} product={product} isDealer={isDealer} />}
    </>
  )
}

export default ProductCard
