import React, { FC } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font } from '@react-pdf/renderer'
import numeral from 'numeral'
import { ThaiBaht } from 'thai-baht-text-ts'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
  PDFTableCellView,
} from '../../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../../assets/images/new-logo.png'
import { useDocument, useSaleOrderProfile } from '../../../../../hooks'
import { dateFormat, getAddressText } from '../../../../../functions'
import { InvoiceState } from '../../../../../api/smartbestbuys-api'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 52,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 252,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 12,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
})

const formatInteger = (number: number) => numeral(number).format('0,0')
const formatDecimal = (number: number) => numeral(number).format('0,0.00')

const InvoicePDFPage: FC<RouteComponentProps<any>> = (props) => {
  const { id } = props.match.params
  const invoice = useDocument<InvoiceState>(id)
  const saleOrderProfile = useSaleOrderProfile(invoice?.saleOrderInfo.id)

  if (!invoice || !saleOrderProfile) return <Loading />
  document.title = `ใบแจ้งหนี้ ${invoice.code}`
  const { billingAddress } = invoice

  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>
                    0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>ที่อยู่: </Text>519/174-6 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ กรุงเทพฯ
                    10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>โทร: </Text>02-1147006
                    </Text>
                    <Text>
                      <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>อีเมล: </Text>
                      sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="เลขที่" direction="row" dataWidth={88} noMargin>
                {invoice.code}
              </PDFInfo>
              <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                {dateFormat(invoice.recCreatedAt)}
              </PDFInfo>
              <PDFInfo label="อ้างอิงใบสั่งซื้อ" direction="row" dataWidth={88} noMargin>
                {saleOrderProfile.code}
              </PDFInfo>
              <PDFInfo label="เลขที่ PO ของลูกค้า" direction="row" dataWidth={88} noMargin>
                {saleOrderProfile.customerPurchaseReference}
              </PDFInfo>
              <PDFInfo label="เงื่อนไขชำระเงิน" direction="row" dataWidth={88} noMargin>
                {saleOrderProfile.paymentCondition.name}
              </PDFInfo>
              <PDFInfo label="ชื่อผู้นำเสนอ" direction="row" dataWidth={88} noMargin>
                {saleOrderProfile.createdBy}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            ใบแจ้งหนี้
          </Text>
          {/* SubHeader */}
          <PDFRow borderColor="light">
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ชื่อลูกค้า">
                {billingAddress?.overrideDocumentData?.customerName || invoice.customerInfo.name}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เลขประจำตัวผู้เสียภาษี ">
                {billingAddress?.overrideDocumentData?.taxNumber || invoice.customerInfo.referenceNumber || '-'}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={12}>
              <PDFInfo label="ที่อยู่">
                {billingAddress?.overrideDocumentData?.address || getAddressText(billingAddress)}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรศัพท์">{invoice.customerInfo.phoneNumber || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={8}>
              <PDFInfo label="โทรสาร">{billingAddress?.faxNumber || '-'}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <PDFTable>
            {/* Head Table */}
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                ลำดับ
              </PDFTableCell>
              <PDFTableCell head width={223}>
                รายละเอียด
              </PDFTableCell>
              <PDFTableCell head width={72} textAlign="right">
                จำนวน
              </PDFTableCell>
              <PDFTableCell head width={72} textAlign="right">
                ราคาต่อหน่วย
              </PDFTableCell>
              <PDFTableCell head width={72} textAlign="right">
                ส่วนลด
              </PDFTableCell>
              <PDFTableCell head width={72} textAlign="right">
                {'จำนวนเงิน '}
              </PDFTableCell>
            </PDFTableRow>
            {/* Content Table */}
            {invoice.state?.items.map((item, index) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCellView width={223}>
                  <Text>{item.name}</Text>
                </PDFTableCellView>
                <PDFTableCell width={72} textAlign="right">
                  {formatInteger(item.amount)}
                </PDFTableCell>
                <PDFTableCell width={72} textAlign="right">
                  {formatDecimal(item.pricePerEach || 0)}
                </PDFTableCell>
                <PDFTableCell width={72} textAlign="right">
                  {0}
                </PDFTableCell>
                <PDFTableCell width={72} textAlign="right">
                  {formatDecimal(item.total || 0)}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          {/* Footer Table */}
          <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(invoice.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="หมายเหตุ">{invoice.state?.remark || '-'}</PDFInfo>
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {invoice?.state?.priceBreakdowns.map((item) => (
                <PDFInfo label={item.label} direction="row" dataWidth={112}>
                  {formatDecimal(item.amount)}
                </PDFInfo>
              ))}
            </PDFView>
          </PDFView>

          <PDFRow wrap={false}>
            <PDFColumn columnSpan={6} style={{ marginBottom: 8 }}>
              <PDFSignatureView
                width={120}
                title={`ได้รับเอกสารตามรายการด้านบนได้ถูกต้องแล้ว
            ลงนาม/ประทับตรา (ถ้ามี) ผู้รับเอกสาร `}
              />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFSignatureView
                width={120}
                title={`ในนามบริษัท สมาร์ทเบสท์บายส์จำกัด 
            ลงนามผู้มีอำนาจ `}
                endorser={invoice.recCreatedBy}
                date={invoice.recCreatedAt}
              />
            </PDFColumn>
          </PDFRow>

          <PDFPagination />
        </Page>
      </Document>
    </SoraPDFViewer>
  )
}

export default InvoicePDFPage
