import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MUILink, LinkProps } from '@material-ui/core'

interface Props extends LinkProps {
  to?: string
}

const Link: React.FC<Props> = (props) => {
  const { to, ...linkProps } = props
  if (to) {
    return <MUILink component={RouterLink} to={to} {...linkProps} />
  }
  return <MUILink {...linkProps} />
}

export default Link
