import React from 'react'
import { Typography } from '@material-ui/core'

const RemarkDetail: React.FC = () => (
  <Typography variant="body2" color="textSecondary">
    *** ซื้อครบ 499 บาท ส่งมอบสินค้าภายในกรุงเทพ และ ปริมณฑล ฟรี!!
    <br />
    *** ต่างจังหวัด ลูกค้าเป็นผู้ออกค่าขนส่ง ทางบริษัทฯมีบริการส่งสินค้าตามบริษัทขนส่งที่ลูกค้ากำหนด
    <br />
    *** กรุณาตรวจสอบรายการสินค้าก่อนการสั่งซื้อ ทางบริษัทฯไมมีนโยบายรับคืนสินค้าหากมีการเปิดใบสั่งซื้อมาแล้ว
  </Typography>
)

export default RemarkDetail
