import { Avatar } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useStyles } from './styles'

interface Props {
  src: string
  name: string
  size?: 'big' | 'small'
  variant?: 'circle' | 'circular' | 'rounded' | 'square'
}

const ImageIcon: React.FC<Props> = ({ name, src, size = 'small', variant = 'circle' }) => {
  const classes = useStyles()

  const avatarClassName: string = useMemo((): string => {
    switch (size) {
      case 'small':
        return classes.avatarSmall
      default:
        return classes.avatar
    }
  }, [classes.avatar, classes.avatarSmall, size])

  return <Avatar className={avatarClassName} src={src} alt={name} />
}

export default ImageIcon
