import React, { useContext, useState, useMemo } from 'react'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core'
import { FlexBox, Form, GeneralTextFieldForm, TypographyWithLabel } from '../../../custom-components'
import {
  ButtonRadios,
  ImageCard,
  SubmitForm,
  SubmitWithConfirmTextForm,
  UploadImageCard,
} from '../../../shared-components'
import { paymentMethods } from '../../../types'
import { FormProvider, useForm } from 'react-hook-form'
import { useShippingProviderData } from '../../../hooks'
import {
  BaseData,
  finishTransportTaskAPI,
  FinishTransportTaskRequestBody,
  IShippingInfo,
  TransportSubTaskState,
  TransportTaskStateV2_ShippingResult,
} from '../../../api/smartbestbuys-api'
import { formatNumber } from '../../../functions/number-utils'
import { RootContext } from '../../..'
import { TaskStatus } from '../../../enums'

interface Values {
  chargeAmount?: number
  trackingNumber?: string
  shippingProviderId?: number
  payAmount?: number
  shippingPaymentCondition?: number
  paymentMethodId?: number
  remark?: string
  image1?: string
  image2?: string
  image3?: string
}

interface Props {
  data: {
    id: number
    shippingInfo: IShippingInfo
    shippingTaskStatus: BaseData
    shippingTaskState: TransportSubTaskState & {
      shippingResult?: TransportTaskStateV2_ShippingResult
      nextShippingDate?: string
    }
  }

  readOnly?: boolean

  onSuccess?: (data: any) => void
  onFail?: () => void
}

const ConfirmShippingResultDialog: React.FC<Props & DialogProps> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)

  const { data, readOnly: readOnlyProp, onSuccess, onFail, onClose, ...dialogProps } = props
  const { shippingInfo, shippingTaskStatus, shippingTaskState } = data

  const [readOnly, setReadOnly] = useState(readOnlyProp)

  const { shippingResult, failureMessages } = shippingTaskState

  const shippingTaskResult = useMemo(() => {
    return {
      chargeAmount: shippingResult?.chargeAmount,
      trackingNumber: shippingResult?.trackingNumber,
      shippingProviderId: shippingResult?.actualShippingProvider?.id,
      payAmount: shippingResult?.payAmount,
      shippingPaymentCondition: shippingResult?.payAmount ? 1 : undefined,
      paymentMethodId: shippingResult?.paymentMethod?.id,
      remark: shippingResult?.remark,
      image1: shippingResult?.attachedImages?.find((_, index) => index === 0),
      image2: shippingResult?.attachedImages?.find((_, index) => index === 1),
      image3: shippingResult?.attachedImages?.find((_, index) => index === 2),
    }
  }, [shippingResult])

  const formMethod = useForm<Values>({
    defaultValues: shippingTaskResult,
  })
  const {
    control,
    setValue,
    formState: { isSubmitting, errors },
    watch,
    reset,
  } = formMethod

  const shippingProviders = useShippingProviderData()

  const { paymentMethodId, shippingPaymentCondition, image1, image2, image3 } = watch()

  const handleSubmit = async (values: Values) => {
    const requestBody: FinishTransportTaskRequestBody = {
      shippingResult: {
        attachedImages: [values.image1, values.image2, values.image3].filter((image) => !!image) as string[],
        remark: values.remark,
        paymentMethod: paymentMethods.find((pm) => pm.id === values.paymentMethodId),

        chargeAmount: values.chargeAmount,
        trackingNumber: values.trackingNumber,
        actualShippingProvider: shippingProviders?.find((sp) => sp.id === values.shippingProviderId),
        payAmount: values.shippingPaymentCondition === 1 ? values.payAmount : undefined,
      },
    }

    const response = await finishTransportTaskAPI(data.id, requestBody)
    if (response?.status === 200) {
      if (onSuccess) onSuccess(response.data)
      triggerSnackbar('จบงานสำเร็จ')
    } else {
      if (onFail) onFail()
      triggerSnackbar('จบงานไม่สำเร็จ')
    }
  }

  const handleSubmitWithShippingResult = async () => {
    const requestBody: FinishTransportTaskRequestBody = {
      shippingResult: shippingTaskStatus.id === TaskStatus.Done ? shippingResult : undefined,
    }

    const response = await finishTransportTaskAPI(data.id, requestBody)
    if (response?.status === 200) {
      if (onSuccess) onSuccess(response.data)
      triggerSnackbar('จบงานสำเร็จ')
    } else {
      if (onFail) onFail()
      triggerSnackbar('จบงานไม่สำเร็จ')
    }
  }

  const shippingBySmart = !shippingInfo.useShippingProvider
  const isChargeCustomer = !!shippingInfo.chargeAmount

  const handleEditButtonClick = () => {
    setReadOnly((cur) => {
      const isCancelToEdit = !cur
      if (isCancelToEdit) {
        reset(shippingTaskResult)
      }
      return !cur
    })
  }

  return (
    <Dialog {...dialogProps} maxWidth="md">
      <DialogTitle>
        <FlexBox justifyContent="space-between">
          <span>จบงานขนส่ง</span>
          <Button size="small" variant="contained" color="secondary" onClick={handleEditButtonClick}>
            {readOnly ? 'แก้ไขรายละเอียด' : 'ยกเลิกการแก้ไข'}
          </Button>
        </FlexBox>
      </DialogTitle>
      {readOnly ? (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TypographyWithLabel label="ผลการขนส่ง:" color={failureMessages ? 'error' : 'textSecondary'}>
                  {failureMessages ? 'ขนส่งไม่สำเร็จ' : 'ขนส่งสำเร็จ'}
                </TypographyWithLabel>
              </Grid>
              {shippingInfo.useShippingProvider && (
                <Grid item xs={6}>
                  <TypographyWithLabel label="ขนส่งที่มาจากจัดส่ง:">
                    {shippingInfo.shippingProvider?.name ?? 'ไม่ระบุ'}
                  </TypographyWithLabel>
                </Grid>
              )}
              {!failureMessages && shippingResult && (
                <>
                  {shippingInfo.useShippingProvider && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="ขนส่งที่ใช้ส่งจริง:">
                        {shippingResult.actualShippingProvider?.name}
                      </TypographyWithLabel>
                    </Grid>
                  )}
                  {shippingResult.trackingNumber && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="หมายเลขพัสดุ:">{shippingResult.trackingNumber}</TypographyWithLabel>
                    </Grid>
                  )}
                  {shippingResult.payAmount && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="ค่าขนส่ง:">
                        {formatNumber(shippingResult.payAmount)}
                      </TypographyWithLabel>
                    </Grid>
                  )}
                  {shippingInfo.chargeAmount && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="ยอดเงินที่ต้องเก็บ:">
                        {formatNumber(shippingInfo.chargeAmount)}
                      </TypographyWithLabel>
                    </Grid>
                  )}
                  {shippingResult.chargeAmount && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="จำนวนเงินที่เก็บจากลูกค้า:">
                        {formatNumber(shippingResult.chargeAmount)}
                      </TypographyWithLabel>
                    </Grid>
                  )}
                  {shippingResult.paymentMethod && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="วิธีชำระ:">{shippingResult.paymentMethod.name}</TypographyWithLabel>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <TypographyWithLabel label="หมายเหตุ:">{shippingResult.remark ?? '-'}</TypographyWithLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TypographyWithLabel label="รูปหลักฐาน:"></TypographyWithLabel>
                  </Grid>
                  <Grid item xs={12}>
                    {shippingResult.attachedImages?.map((image, index) => (
                      <ImageCard key={index} name={`หลักฐาน ${index + 1}`} src={image} />
                    ))}
                  </Grid>
                </>
              )}
              {failureMessages && (
                <>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="สาเหตุของการส่งของไม่สำเร็จ:">
                      {failureMessages.failed}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="วันที่ต้องจัดส่งใหม่:">
                      {shippingTaskState.nextShippingDate ?? 'ไม่มีการนัดหมาย'}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="หมายเหตุ:">{failureMessages.remark ?? '-'}</TypographyWithLabel>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitWithConfirmTextForm
              confirmTextOption={
                !failureMessages
                  ? {
                      veifiedText: 'ยืนยัน',
                    }
                  : undefined
              }
              onSubmit={handleSubmitWithShippingResult}
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
            />
          </DialogActions>
        </>
      ) : (
        <FormProvider {...formMethod}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={1}>
                {shippingBySmart ? (
                  <>
                    <Grid item xs={12}>
                      <TypographyWithLabel
                        label="ยอดเงินที่ต้องเก็บ:"
                        color={isChargeCustomer ? 'primary' : 'textSecondary'}
                      >
                        {isChargeCustomer ? `${formatNumber(shippingInfo.chargeAmount!)} บาท` : 'ไม่ต้องเก็บเงิน'}
                      </TypographyWithLabel>
                    </Grid>
                    {isChargeCustomer && (
                      <>
                        <Grid item xs={6}>
                          <ButtonRadios
                            label="วิธีการชำระเงินของลูกค้า *"
                            items={paymentMethods}
                            value={paymentMethodId}
                            onChange={(id) => setValue('paymentMethodId', id)}
                          />
                          {errors.paymentMethodId?.message && (
                            <Typography variant="caption" color="error">
                              {errors.paymentMethodId?.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <GeneralTextFieldForm
                            control={control}
                            name="chargeAmount"
                            type="number"
                            label="จำนวนเงินที่เก็บจากลูกค้า *"
                            rules={{ required: 'กรุณากรอกจำนวนเงินที่เก็บลูกค้าได้' }}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <TypographyWithLabel label="ขนส่งที่เลือกจากฝ่ายบริการ:" color="textSecondary">
                        {shippingInfo.shippingProvider?.name}
                      </TypographyWithLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <GeneralTextFieldForm
                        control={control}
                        name="trackingNumber"
                        label="หมายเลขพัสดุ *"
                        rules={{ required: 'กรุณากรอกหมายเลขพัสดุ' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <GeneralTextFieldForm
                        control={control}
                        name="shippingProviderId"
                        label="ขนส่งที่ใช้จริง *"
                        rules={{ required: 'กรุณาเลือกขนส่งที่ใช้' }}
                        select
                      >
                        {shippingProviders?.map((shippingProvider) => (
                          <MenuItem key={shippingProvider.id} value={shippingProvider.id}>
                            <div>
                              <div>{shippingProvider.name}</div>
                              {shippingProvider.detail && <div>({shippingProvider.detail})</div>}
                            </div>
                          </MenuItem>
                        ))}
                      </GeneralTextFieldForm>
                    </Grid>
                    <Grid item xs={6}>
                      <ButtonRadios
                        label="รูปแบบการเก็บเงิน *"
                        items={[
                          { id: 1, value: 1, name: 'ต้นทาง' },
                          { id: 2, value: 2, name: 'ปลายทาง' },
                        ]}
                        value={shippingPaymentCondition}
                        onValueChange={(value) => setValue('shippingPaymentCondition', value as number)}
                      />
                    </Grid>
                    {(!shippingPaymentCondition || shippingPaymentCondition === 1) && (
                      <Grid item xs={6}>
                        <GeneralTextFieldForm
                          type="number"
                          control={control}
                          name="payAmount"
                          label="ค่าขนส่ง *"
                          rules={{ required: 'กรุณากรอกค่าขนส่ง' }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <ButtonRadios
                        label="วิธีชำระค่าขนส่ง *"
                        items={[
                          { id: 1, name: 'เงินสด' },
                          { id: 2, name: 'โอน' },
                        ]}
                        value={paymentMethodId}
                        onChange={(id) => setValue('paymentMethodId', id)}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={6}>
                  <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
                </Grid>
                <Grid item xs={12}>
                  <FlexBox justifyContent="center" gridGap={8}>
                    <UploadImageCard
                      uniqKey="image1"
                      title="อัปโหลดรูปหลักฐาน (ถ้ามี)"
                      defaultSrc={image1}
                      onChange={({ base64 }) => setValue('image1', base64)}
                    />
                    <UploadImageCard
                      uniqKey="image2"
                      title="อัปโหลดรูปหลักฐาน (ถ้ามี)"
                      defaultSrc={image2}
                      onChange={({ base64 }) => setValue('image2', base64)}
                    />
                    <UploadImageCard
                      uniqKey="image3"
                      title="อัปโหลดรูปหลักฐาน (ถ้ามี)"
                      defaultSrc={image3}
                      onChange={({ base64 }) => setValue('image3', base64)}
                    />
                  </FlexBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SubmitForm
                submitText="ยืนยันจบงาน"
                onCancel={() => {
                  if (!!onClose) onClose({}, 'escapeKeyDown')
                }}
                isSubmitting={isSubmitting}
              />
            </DialogActions>
          </Form>
        </FormProvider>
      )}
    </Dialog>
  )
}

export default ConfirmShippingResultDialog
