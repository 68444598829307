interface Props {
  direction?: 'horizontal'
  size?: 'small'
}

const Space = (props: Props) => {
  const { size, direction } = props
  if (direction === 'horizontal') return <span className={size === 'small' ? 'ml-2' : 'ml-4'} />
  return <div className={size === 'small' ? 'mb-2' : 'mb-4'} />
}

export default Space
