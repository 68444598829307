import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import numeral from 'numeral'
import { useContext, useEffect, useMemo } from 'react'
import { NestedValue, useForm } from 'react-hook-form'
import { CustomerProfileCard, Space, SubmitForm } from '..'
import { RootContext } from '../..'
import {
  PriceBreakdownItem,
  RawDocument,
  SaleOrderDocument,
  SaleOrderProduct,
  SaleOrderProfile,
  SOPaymentTransaction,
  TaxInvoiceItem,
  partialUpdateDocumentAPI,
  PartialUpdateDocumentRequestBody,
} from '../../api/smartbestbuys-api'
import { FlexBox, Form, GeneralTextFieldForm, Table } from '../../custom-components'
import { DocumentStatus, DocumentType, SOPaymentTransactionStatus } from '../../enums'
import SaleOrderProfileContext from '../../pages/sale-orders/id/SaleOrderProfilePage/SaleOrderProfileContext'
import {
  getTaxInvoiceItems,
  getTaxInvoicePriceBreakdowns,
  mapToItem,
} from '../../pages/sale-orders/id/SaleOrderProfilePage/SODocumentList/TaxInvoiceCreationDialog/service'
import _ from 'lodash'

interface UpdateTaxInvoiceFormValues {
  referenceDocumentId?: number
  customerId: number
  total: number
  items: Omit<TaxInvoiceItem, 'id'>[]
  priceBreakdowns: PriceBreakdownItem[]
  remark?: string
  soPaymentTransactionId?: number
  products: NestedValue<SaleOrderProduct[]>
}

interface TaxInvoiceState {
  expiredDate: Date
  items?: TaxInvoiceItem[]
  remark?: string
}

interface Props extends DialogProps {
  saleOrderProfile: SaleOrderProfile
  documentInfo: SaleOrderDocument<TaxInvoiceState>
  onSuccess?: (updated: RawDocument) => void
  onFail?: () => void
}

const TaxInvoiceUpdateDialog: React.FC<Props> = (props) => {
  const { saleOrderProfile, documentInfo, onSuccess, onFail, onClose, ...otherDialogProp } = props
  const { customerProfile } = useContext(SaleOrderProfileContext)

  const { triggerSnackbar } = useContext(RootContext)

  const { documents, paymentTransactions } = saleOrderProfile
  const { id, code, type, paymentTransaction, state } = documentInfo

  const {
    control,
    watch,
    reset,
    setError,
    clearErrors,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<UpdateTaxInvoiceFormValues>({
    defaultValues: {
      soPaymentTransactionId: paymentTransaction?.id,
      products: saleOrderProfile.products,
      remark: state?.remark,
    },
  })

  const { products: productInputs } = watch()

  const refInvoice = useMemo(
    () => documents?.find((d) => d.id === documents?.find((d) => d.id === id)?.referenceDocument?.id),
    // eslint-disable-next-line
    [id],
  )

  const isFromInvoice = useMemo(
    () => !!refInvoice, // eslint-disable-next-line
    [id, refInvoice],
  )

  const productTotal = useMemo(() => {
    return !isFromInvoice
      ? productInputs.reduce<number>((sum, product) => sum + product.amount * product.price, 0)
      : undefined
  }, [isFromInvoice, productInputs])

  const availablePaymentTransactions =
    paymentTransactions?.filter((row: SOPaymentTransaction) =>
      [SOPaymentTransactionStatus.PENDING, SOPaymentTransactionStatus.APPROVED].includes(row.status.id),
    ) ?? []

  useEffect(
    () => {
      const { documents } = saleOrderProfile
      const validTaxInvoices =
        documents?.filter(
          (d) =>
            d.type.id === DocumentType.TaxInvoice &&
            !d.referenceDocument &&
            d.status.id !== DocumentStatus.Cancelled &&
            d.id !== id,
        ) ?? []

      if (validTaxInvoices.length === 0) return

      reset({
        products: saleOrderProfile.products.map((sp) => {
          const productInCurrentDocument = state?.items
          const _currentAmount = productInCurrentDocument?.find((p) => p.id === sp.productId)?.amount ?? 0

          return {
            ...sp,
            amount: _currentAmount,
          }
        }),
      })
    }, // eslint-disable-next-line
    [reset, state, paymentTransaction, saleOrderProfile, id],
  )

  const handleConfirm = async (values: UpdateTaxInvoiceFormValues) => {
    if (!isFromInvoice && values.products.every((p) => Number(p.amount) === 0)) {
      setError('products', { type: 'required', message: 'ไม่สามารถกรอกสินค้าเป็น 0 ทุกตัว' })
      return
    }

    const priceBreakdowns = getTaxInvoicePriceBreakdowns(saleOrderProfile, refInvoice, productTotal).map((p) => ({
      label: p.label,
      amount: p.amount,
    }))

    const newTaxInvoiceState = {
      expiredDate: state?.expiredDate,
      items: !isFromInvoice
        ? values.products.filter((p) => p.amount > 0).map(mapToItem)
        : getTaxInvoiceItems(saleOrderProfile, refInvoice),
      priceBreakdowns: priceBreakdowns,
      remark: values.remark,
    }

    const request: PartialUpdateDocumentRequestBody = {
      total: _.last(priceBreakdowns)?.amount || 0,
      state: newTaxInvoiceState,
      refSaleOrderPaymentId: values.soPaymentTransactionId,
      referenceDocumentId: values.referenceDocumentId,
    }

    const response = await partialUpdateDocumentAPI(id, request)
    if (response?.status === 200) {
      onSuccess && onSuccess(response?.data)
      if (!!onSuccess) {
        triggerSnackbar('แก้ไขเอกสารสำเร็จ')
        onClose && onClose({}, 'escapeKeyDown')
      }
    } else {
      triggerSnackbar('แก้ไขเอกสารไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={() => onClose && onClose({}, 'escapeKeyDown')} {...otherDialogProp}>
      <Form onSubmit={handleSubmit(handleConfirm)}>
        <DialogTitle>
          แก้ไข{type.name} {code}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <CustomerProfileCard
              customerProfile={customerProfile}
              billingAddressId={saleOrderProfile.billingAddress?.id}
              disableBtnCustomerDetail
            />
          </Grid>
          <FlexBox gridGap={16}>
            <Grid item xs={12}>
              <GeneralTextFieldForm
                control={control}
                name="soPaymentTransactionId"
                label="รายได้อ้างอิง (SPAY)"
                defaultValue={paymentTransaction?.id}
                select
                disabled={availablePaymentTransactions.length === 0}
                helperText={availablePaymentTransactions.length === 0 ? 'ไม่พบการชำระเงิน' : undefined}
              >
                {availablePaymentTransactions.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.code}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12}>
              <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" defaultValue={state?.remark} />
            </Grid>
          </FlexBox>
          <Space size="small" />
          <Grid item xs={12}>
            <Table<{ id: number } & TaxInvoiceItem>
              color="primary.dark"
              size="small"
              columns={[
                {
                  name: 'ลำดับ',
                  render: (_, index) => index + 1,
                },
                { name: 'ชื่อสินค้า', dataIndex: 'name' },
                {
                  name: 'ราคาต่อหน่วย',
                  fixedWidth: 180,
                  numeric: true,
                  format: '0,0.00',
                  dataIndex: 'pricePerEach',
                },
                {
                  name: 'จำนวน',
                  fixedWidth: 200,
                  numeric: isFromInvoice,
                  align: 'center',
                  format: isFromInvoice ? '0,0' : undefined,
                  dataIndex: 'amount',
                  render: (item, index) => {
                    if (isFromInvoice) return item.amount
                    const _saleProduct = saleOrderProfile.products.find((p) => p.productId === item.id)
                    const amountInAllDocuments = _saleProduct?.amountInDocument ?? 0
                    const amountInCurrentDocument = state?.items?.find((p) => p.id === item.id)?.amount ?? 0

                    const remainingAmount = item.amount - amountInAllDocuments + amountInCurrentDocument
                    return (
                      <GeneralTextFieldForm
                        control={control}
                        type="number"
                        name={`products.${index}.amount`}
                        margin="none"
                        rules={{
                          min: { value: 0, message: 'จำนวนไม่สามารถติด -' },
                          max: { value: remainingAmount, message: 'จำนวนเกิน' },
                          required: 'กรุณากรอกจำนวนสินค้า',
                        }}
                        onPostChange={() => {
                          if (errors.products) clearErrors('products')
                        }}
                        InputProps={{
                          endAdornment: (
                            <Typography color="textSecondary" variant="body1">
                              ({numeral(remainingAmount).format('0,0')})
                            </Typography>
                          ),
                        }}
                      />
                    )
                  },
                },
                {
                  name: 'ราคารวม',
                  fixedWidth: 180,
                  numeric: true,
                  format: '0,0.00',
                  dataIndex: 'total',
                  render: (item) => {
                    if (isFromInvoice) return item.amount * item.pricePerEach
                    const targetProduct = productInputs.find((input) => input.productId === item.id)
                    return (targetProduct?.amount ?? 0) * item.pricePerEach
                  },
                },
              ]}
              cleanSpace
              pagination={false}
              data={getTaxInvoiceItems(saleOrderProfile, refInvoice) as any}
              postCells={
                saleOrderProfile && (
                  <>
                    {getTaxInvoicePriceBreakdowns(saleOrderProfile, refInvoice, productTotal).map((price) => {
                      const { label, amount } = price
                      return (
                        <TableRow key={label}>
                          <TableCell colSpan={3} />
                          <TableCell align="right">{label}</TableCell>
                          <TableCell align="right">{numeral(amount).format('0,0.00')}</TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                )
              }
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText="แก้ไข"
            onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default TaxInvoiceUpdateDialog
