import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = (isSelected: boolean | undefined) =>
  makeStyles((theme) => {
    return createStyles({
      root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        outline: isSelected ? '1px solid #FFC400' : 'none',
      },
      image: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        width: '100%',
        height: 150,
      },
      vendorName: {
        height: 40,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },
      rating: {
        display: 'flex',
        alignSelf: 'end',
        alignItems: 'center',
      },
      starIcon: {
        width: '16px',
        color: '#D1B000',
      },
    })
  })
