import { useState, useEffect } from 'react'
import { getCustomerPORefs } from '../api/smartbestbuys-api'

export const useCustomerPORefs = (id?: number): string[] => {
  const [poRefs, setPORefs] = useState<string[]>([])

  const _getCustomerPORefs = async (id: number) => {
    const response = await getCustomerPORefs(id)

    setPORefs(response?.data || [])
  }

  useEffect(() => {
    if (id) _getCustomerPORefs(id)
  }, [id])

  return poRefs
}
