import { Button, Grid, Paper } from '@material-ui/core'
import { ICustomerTableData_Data } from '../../api/smartbestbuys-api'
import { withLayout, Topic, FilteredTable, Link, FlexBox } from '../../custom-components'
import { BlackListSignal, ConditionTooltip, CustomerTierChip, CreditSignal, Loading } from '../../shared-components'
import { useCustomers } from '../../hooks'
import { RootContext } from '../..'
import { useContext } from 'react'
import { PageName } from '../../enums'
import { getUserPermissionInfo } from '../../utils/permission'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { dateFormat } from '../../functions'

const CustomerListPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER)

  const customers = useCustomers()

  if (!customers) return <Loading />

  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Write)

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <Topic>รายการลูกค้า</Topic>
            <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('/customers/create')}
                disabled={!writePermission.hasPermission}
              >
                สร้างลูกค้า
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<ICustomerTableData_Data>
            disableDateRangeFilter
            color="primary.dark"
            columns={[
              {
                name: '',
                render: ({ isBlacklist, creditPeriodDay, tier }) => (
                  <FlexBox gridGap={8}>
                    {tier && <CustomerTierChip tier={tier} />}
                    {isBlacklist && <BlackListSignal />}
                    {!!creditPeriodDay && <CreditSignal />}
                  </FlexBox>
                ),
                fixedWidth: 80,
              },
              {
                name: 'รหัสลูกค้า',
                render: ({ id, code }) => (
                  <Link color="primary" href={`/customers/${id}/profile`}>
                    {code}
                  </Link>
                ),
              },
              {
                name: 'ชื่อ',
                dataIndex: 'nameLocal',
              },
              {
                name: 'เบอร์โทรศัพท์',
                dataIndex: 'phoneNumber',
                render: ({ phoneNumber }) => phoneNumber || '-',
              },
              {
                name: 'อีเมล',
                dataIndex: 'email',
                render: ({ email }) => email || '-',
              },
              {
                name: 'เลขที่เสียภาษี',
                dataIndex: 'referenceNumber',
                render: ({ referenceNumber }) => referenceNumber || '-',
              },
              {
                name: 'วันที่เข้าระบบ',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => {
                  return dateFormat(new Date(recCreatedAt))
                },
              },
            ]}
            data={customers}
            size="small"
            filters={[
              {
                name: 'สถานะลูกค้าเครดิต',
                values: [
                  { name: 'เป็นลูกค้าเครดิต', value: 1 },
                  { name: 'ไม่ใช่เป็นลูกค้าเครดิต', value: 0 },
                ],
                filter: (data: ICustomerTableData_Data, values: (string | number)[]): boolean => {
                  return !data.creditPeriodDay ? values.includes(0) : values.includes(1)
                },
              },
              {
                name: 'สถานะติดบัญชีดำ',
                values: [
                  { name: 'เป็นลูกค้าติดบัญชีดำ', value: 1 },
                  { name: 'ไม่เป็นลูกค้าติดบัญชีดำ', value: 0 },
                ],
                filter: (data: ICustomerTableData_Data, values: (string | number)[]): boolean => {
                  return !data.isBlacklist ? values.includes(0) : values.includes(1)
                },
              },
              {
                name: 'ระดับลูกค้า',
                values: [
                  { name: 'Wisdom', value: 3 },
                  { name: 'Diamond', value: 2 },
                  { name: 'Platinum', value: 1 },
                  { name: 'ลูกค้าทั่วไป', value: 0 },
                ],
                filter: (data: ICustomerTableData_Data, values: (string | number)[]) => {
                  if (!data.tier) {
                    return values.includes(0)
                  }

                  return values.includes(data.tier.id)
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withLayout(CustomerListPage)
