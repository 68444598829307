import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import numeral from 'numeral'
import { useContext, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import thLocale from 'date-fns/locale/th'
import { RootContext } from '../../..'
import {
  FetchSOPaymentTransaction,
  SOPaymentTransaction,
  SOPaymentTransactionState,
  VerifyPaymentStatementRequestBody,
} from '../../../api/smartbestbuys-api'
import {
  FlexBox,
  Form,
  Grid,
  KeyboardDateTimePickerForm,
  TextFieldForm,
  TypographyWithLabel,
} from '../../../custom-components'
import { bankAccounts } from '../../../data'
import { SubmitForm } from '../../SubmitForm'
import { verifyPaymentStatement } from './service'
import { PaymentMethod } from '../../../enums'
import { format } from 'date-fns'

interface SOPaymentStatementVerifyFormValue {
  statementTimestamp?: string
  bankAccountId?: number
  state?: SOPaymentTransactionState
  verificationRemark?: string
}

interface Props extends DialogProps {
  transaction: SOPaymentTransaction
  saleOrderId: number
  onVerify?: (data: FetchSOPaymentTransaction) => void
  userAction: number
}

const SOPaymentStatementVerifyDialog: React.FC<Props> = (props) => {
  const { transaction, saleOrderId, onVerify, onClose, userAction, ...dialogProps } = props
  const {
    id,
    code,
    amount,
    paymentAdjustment,
    withholdingAmount,
    bankAccount,
    state,
    paymentMethod,
    paymentTimestamp,
  } = transaction

  const adjustmentAmount = paymentAdjustment?.amount || 0
  const actualWithholdingAmount = withholdingAmount || 0
  const priceBreakdowns = [
    { label: 'ยอดชำระ', value: amount },
    { label: 'รายการปรับลด', value: adjustmentAmount },
    { label: 'หัก ณ ที่จ่าย', value: actualWithholdingAmount },
    { label: 'ยอดจริงที่ได้รับ', value: amount - adjustmentAmount - actualWithholdingAmount },
  ]

  const handleTextChange = (e: { target: { value: string } }) => setText(e.target.value)

  const defaultValues: SOPaymentStatementVerifyFormValue = useMemo(
    () => {
      return {
        bankAccountId: bankAccount?.id,
        state: state,
        verificationRemark: undefined,
      }
    }, // eslint-disable-next-line
    [saleOrderId, id],
  )

  const methods = useForm<SOPaymentStatementVerifyFormValue>({
    defaultValues: defaultValues,
  })

  const { triggerSnackbar } = useContext(RootContext)
  const [text, setText] = useState('')
  const mismatched = text !== 'ยืนยัน'

  const handleSubmit = async (values: SOPaymentStatementVerifyFormValue) => {
    const apiValues: VerifyPaymentStatementRequestBody = {
      ...values,
      statementTimestamp: values.statementTimestamp ? new Date(values.statementTimestamp) : undefined,
    }
    // console.log(apiValues)
    const response = await verifyPaymentStatement(saleOrderId, id, apiValues)
    if (response?.status === 200) {
      triggerSnackbar('ตรวจสอบ Statement สำเร็จ')
      onVerify && onVerify(response?.data ?? {})
      onClose && onClose({}, 'escapeKeyDown')
    } else {
      triggerSnackbar('ตรวจสอบ Statement ไม่สำเร็จ')
    }
    return true
  }

  const {
    control,
    formState: { isSubmitting, errors },
    watch,
  } = methods

  const { statementTimestamp } = watch()

  return (
    <Dialog fullWidth maxWidth="lg" {...dialogProps}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <DialogTitle>ตรวจสอบ Statement</DialogTitle>
          <DialogContent>
            <Box>
              <Typography variant="body1" color="textSecondary">
                <Box component="span" color="success.dark">
                  ยืนยันการตรวจสอบ Statement
                </Box>{' '}
                การจ่ายเงินหมายเลข {code} นี้ใช่หรือไม่?
              </Typography>

              {paymentMethod.id === PaymentMethod.Transfer && paymentTimestamp && (
                <Box className="my-3">
                  <TypographyWithLabel label="วันและเวลาที่โอน:">
                    {format(new Date(paymentTimestamp), 'dd MMM yyyy HH:mm', {
                      locale: thLocale,
                    })}
                  </TypographyWithLabel>
                </Box>
              )}
            </Box>
            <FlexBox justifyContent="space-between">
              <FlexBox justifyContent="space-between" width={800}>
                <Grid container spacing={2} style={{ paddingLeft: 0 }}>
                  <Grid item xs={6}>
                    <TextFieldForm
                      control={control}
                      rules={{ required: 'กรุณาเลือกบัญชีธนาคาร' }}
                      name="bankAccountId"
                      select
                      label="บัญชีธนาคาร *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    >
                      {bankAccounts.map((bankAccount) => (
                        <MenuItem key={bankAccount.id} value={bankAccount.id}>
                          {`${bankAccount.bankName} ${bankAccount.number} (${bankAccount.name})`}
                        </MenuItem>
                      ))}
                    </TextFieldForm>
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDateTimePickerForm
                      gutterTop
                      control={control}
                      label="กรุณาเลือกวัน เวลาที่นำเงินเข้าธนาคาร *"
                      name="statementTimestamp"
                      value={statementTimestamp}
                      defaultValue={null}
                      rules={{
                        required: 'กรุณาเลือกวัน เวลาที่นำเงินเข้าธนาคาร',
                      }}
                      size="small"
                      fullWidth
                      error={!!errors.statementTimestamp}
                      helperText={errors.statementTimestamp?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldForm
                      label="หมายเหตุ"
                      control={control}
                      name="verificationRemark"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    ></TextFieldForm>
                  </Grid>
                </Grid>
              </FlexBox>
              <FlexBox width={400} justifyContent="space-between">
                <FlexBox width={80}>
                  <Divider orientation="vertical" />
                </FlexBox>
                <FlexBox>
                  <Card variant="outlined">
                    <Grid item xs={12} container spacing={2}>
                      {priceBreakdowns.map((pb) => (
                        <>
                          <Grid item xs={6}>
                            <Typography variant="body2" align="right" display="block">
                              {pb.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" align="right" display="block">
                              {numeral(pb.value).format('0,0.00')}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Card>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox position="absolute" width={350}>
              <TextField
                label={`พิมพ์ “ยืนยัน” เพื่อยืนยันตรวจสอบ`}
                variant="outlined"
                onChange={handleTextChange}
                fullWidth
                margin="dense"
              />
            </FlexBox>
          </DialogContent>
          <DialogActions>
            <SubmitForm
              disableSubmit={mismatched}
              submitText="ยืนยันการตรวจสอบ"
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

export default SOPaymentStatementVerifyDialog
