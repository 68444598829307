import React, { FC } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font, View } from '@react-pdf/renderer'
import numeral from 'numeral'
import { ThaiBaht } from 'thai-baht-text-ts'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
} from '../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../assets/images/logo_v2.png'
import { dateFormat, getAddressText } from '../../../../functions'
import { PaymentRequisitionPDFInfo_Item } from '../../../../api/smartbestbuys-api'
import { usePaymentRequisitionPDFInfo } from '../../../../hooks'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  center: {
    textAlign: 'center',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 78,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  title: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.darkGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 300,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 12,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
})

const PaymentRequisitionReceiptPDFPage: FC<RouteComponentProps<any>> = (props) => {
  const { id: paymentRequisitionId } = props.match.params
  const paymentRequisitionPDFInfo = usePaymentRequisitionPDFInfo(paymentRequisitionId)
  if (!paymentRequisitionPDFInfo) return <Loading />

  const { vendorInfo, items } = paymentRequisitionPDFInfo

  document.title = `ใบสำคัญรับเงิน ${paymentRequisitionPDFInfo.paymentCode}`

  // calculation
  const priceBreakdowns = [{ label: 'รวมเงิน', value: paymentRequisitionPDFInfo.total }]
  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>ที่อยู่: </Text>519/174-6 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ กรุงเทพฯ
                    10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>โทร: </Text>02-114-8050, 087-022-4003, 084-544-1710
                    </Text>
                    <Text>
                      <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>อีเมล: </Text>sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="เลขที่" direction="row" dataWidth={88} noMargin>
                {paymentRequisitionPDFInfo.paymentCode ?? paymentRequisitionPDFInfo.code}
              </PDFInfo>
              <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                {dateFormat(paymentRequisitionPDFInfo.recCreatedAt)}
              </PDFInfo>

              <PDFInfo label="ผู้จัดทำเอกสาร" direction="row" dataWidth={88} noMargin>
                {paymentRequisitionPDFInfo.recCreatedBy}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            ใบสำคัญรับเงิน
          </Text>
          {/* SubHeader */}
          <PDFRow borderColor="light">
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ข้าพเจ้า">{vendorInfo.name}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เลขประจำตัวผู้เสียภาษี">{vendorInfo.referenceNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ที่อยู่">{getAddressText(vendorInfo.address)}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ได้รับเงินจาก">บริษัท สมาร์ท เบสท์บายส์ จำกัด</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <PDFTable>
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                ลำดับ
              </PDFTableCell>
              <PDFTableCell head width={428}>
                รายละเอียด
              </PDFTableCell>
              <PDFTableCell head width={83} textAlign="right">
                จำนวนเงิน
              </PDFTableCell>
            </PDFTableRow>

            {items.map((item: PaymentRequisitionPDFInfo_Item, index: number) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCell width={428}>
                  <Text>{item.purchaseOrderCode}</Text>
                </PDFTableCell>
                <PDFTableCell width={83} textAlign="right">
                  {numeral(item.total).format('0,0.00')}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(paymentRequisitionPDFInfo.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="หมายเหตุ" color="error">
                  {!!paymentRequisitionPDFInfo.remark ? paymentRequisitionPDFInfo.remark : '-'}
                </PDFInfo>
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {priceBreakdowns
                .filter((item) => item.value > 0)
                .map((price) => (
                  <PDFInfo key={price.label} label={price.label} direction="row" dataWidth={160}>
                    {numeral(price.value).format('0,0.00')}
                  </PDFInfo>
                ))}
            </PDFView>
          </PDFView>

          <View wrap={false}>
            <PDFRow>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="ผู้จ่ายเงิน" date={paymentRequisitionPDFInfo.recCreatedAt} />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="ผู้รับเงิน" />
              </PDFColumn>
            </PDFRow>
          </View>

          <PDFPagination />
        </Page>
      </Document>
    </SoraPDFViewer>
  )
}

export default PaymentRequisitionReceiptPDFPage
