import React from 'react'
import { Avatar, Button, Card, Typography } from '@material-ui/core'
import { Photo, Star } from '@material-ui/icons'

import { useStyles } from './styles'
import { PRVendor } from '../../../../../api/smartbestbuys-api'
import { FlexBox } from '../../../../../custom-components'

interface Props {
  vendor: PRVendor
  onSelect: (vendor: any) => void
  isSelected?: boolean
}

const VendorCard: React.FC<Props> = (props) => {
  const { isSelected, onSelect, vendor } = props

  const styles = useStyles(isSelected)()

  const handleClick = () => {
    if (isSelected) {
      onSelect(undefined)
    } else {
      onSelect(vendor)
    }
  }

  return (
    <Card className={styles.root}>
      <div className={styles.image}>
        <Avatar variant="rounded" src={vendor.profileImageUrl} className={styles.avatar}>
          <Photo />
        </Avatar>
      </div>
      <Typography variant="body2" className={styles.vendorName}>
        {vendor.name}
      </Typography>
      <FlexBox justifyContent="space-between">
        <Typography variant="caption" color="textSecondary">
          {vendor.country}
        </Typography>
        <Typography variant="body2" className={styles.rating}>
          <Star className={styles.starIcon} />
          {vendor.averageRating}
        </Typography>
      </FlexBox>
      <Button variant={isSelected ? 'outlined' : 'contained'} size="small" color="secondary" onClick={handleClick}>
        {!isSelected ? 'เลือกผู้ขายนี้' : 'กำลังเลือกผู้ขายนี้'}
      </Button>
    </Card>
  )
}

export default VendorCard
