import { useState, useEffect } from 'react'
import {
  getCreatePurchaseRequisitions,
  PurchaseRequisitionCreateData,
  PurchaseRequisitionUpsertDataParams,
} from '../api/smartbestbuys-api/'

export const usePurchaseRequisitionCreateData = (
  saleOrderId?: number,
  rop?: boolean,
): PurchaseRequisitionCreateData | undefined => {
  const [purchaseRequisitions, setPurchaseRequisitions] = useState<PurchaseRequisitionCreateData | undefined>()

  const _getCreatePurchaseRequisitions = async (saleOrderId?: number, rop?: boolean) => {
    const queryParams: PurchaseRequisitionUpsertDataParams = {
      saleOrderId,
      rop: rop?.toString(),
    }
    const response = await getCreatePurchaseRequisitions(queryParams)
    setPurchaseRequisitions(response?.data)
  }

  useEffect(() => {
    _getCreatePurchaseRequisitions(saleOrderId, rop)
  }, [saleOrderId, rop])

  return purchaseRequisitions
}
