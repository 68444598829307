import React, { useState, useContext } from 'react'
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { BillingAddressRadioGroup } from '../CustomerProfile/CustomerProfileCard/BillingAddressRadioGroup'
import { FlexBox } from '../../custom-components'
import { ICustomerProfile_Address } from '../../api/smartbestbuys-api'
import { RootContext } from '../..'
import { updateSOBillingAddress } from './service'

interface Props {
  saleOrderId: number
  billingAddresses: ICustomerProfile_Address[]
  value?: number
  onChange: (id: number) => void
}

const CBASelectionDialog: React.FC<DialogProps & Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { saleOrderId, billingAddresses, value, onChange, onClose, ...dialogProps } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selected, setSelected] = useState<number | undefined>(value)

  return (
    <Dialog {...dialogProps} onClose={onClose} maxWidth="md">
      <DialogTitle>เลือกที่อยู่เปิดบิล</DialogTitle>
      <DialogContent>
        <BillingAddressRadioGroup options={billingAddresses} value={selected} onChange={setSelected} />
      </DialogContent>
      <DialogActions>
        <FlexBox justifyContent="right">
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!selected || isSubmitting}
            onClick={async () => {
              if (!selected) {
                triggerSnackbar('เลือกที่อยู่เปิดบิลให้ถูกต้อง')
                return
              }
              setIsSubmitting(true)
              const response = await updateSOBillingAddress(saleOrderId, selected)
              if (response?.status === 200) {
                triggerSnackbar('แก้ไขที่อยู่สำเร็จ')
                const { billingAddress } = response.data
                onChange(billingAddress.id)
                setIsSubmitting(false)
              } else {
                setIsSubmitting(false)
                triggerSnackbar('แก้ไขที่อยู่ไม่สำเร็จ')
              }
            }}
          >
            ยืนยันเลือกที่อยู่
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default CBASelectionDialog
