import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import {
  Grid,
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableRow,
  TableCell,
  InputAdornment,
  Typography,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'
import SaleOrderProfileContext from '../../SaleOrderProfileContext'
import { ButtonRadios, CustomerProfileCard, DocumentStatusChip } from '../../../../../../shared-components'
import { GeneralTextFieldForm, Table, Form } from '../../../../../../custom-components'
import {
  SaleOrderDocument,
  SaleOrderProduct,
  SaleOrderProfile,
  SOPaymentTransaction,
} from '../../../../../../api/smartbestbuys-api'
import { calculateActualTotal, createInvoice, getInvoicePriceBreakdowns, getPaymentCondition } from './service'
import numeral from 'numeral'
import { RootContext } from '../../../../../..'
import {
  calculateAsPercentage,
  calculateExcludingVat,
  calculatePercentage,
  dateTimeFormat,
} from '../../../../../../functions'
import { DocumentStatus, DocumentType, SOPaymentTransactionStatus } from '../../../../../../enums'

export interface InvoiceCreationFormValues {
  paymentCondition?: number
  amount: number
  depositAmount: number
  percentAmount?: number
  remark?: string
  products: SaleOrderProduct[]
  soPaymentTransactionId?: number
}

interface Props {
  saleOrderProfile: SaleOrderProfile
}

const InvoiceCreationDialog: React.FC<Props & DialogProps> = (props) => {
  const { saleOrderProfile, onClose, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)
  const { customerProfile, reload } = useContext(SaleOrderProfileContext)
  const {
    control,
    getValues,
    setValue,
    formState: { isSubmitting },
    watch,
    reset,
    handleSubmit,
  } = useForm<InvoiceCreationFormValues>({
    defaultValues: {
      depositAmount: 0,
      products: saleOrderProfile.products,
    },
  })

  const { paymentCondition, percentAmount } = watch()
  const { documents, paymentTransactions } = saleOrderProfile
  // todo: remove if no need
  // const isPartialCreate = useMemo(() => paymentCondition === 2, [paymentCondition])

  const availablePaymentTransactions =
    paymentTransactions?.filter((row: SOPaymentTransaction) =>
      [SOPaymentTransactionStatus.PENDING, SOPaymentTransactionStatus.APPROVED].includes(row.status.id),
    ) ?? []

  const handleSubmitInvoiceForm = async (values: InvoiceCreationFormValues) => {
    const response = await createInvoice(saleOrderProfile, values)
    if (response?.status === 201) {
      triggerSnackbar('สร้างใบแจ้งหนี้สำเร็จ')
      reload()
      onClose && onClose({}, 'escapeKeyDown')
      reset({})
    } else {
      triggerSnackbar('สร้างใบแจ้งหนี้ไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitInvoiceForm)}>
        <DialogTitle>สร้างใบแจ้งหนี้</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomerProfileCard
                customerProfile={customerProfile}
                billingAddressId={saleOrderProfile.billingAddress?.id}
                disableBtnCustomerDetail
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ButtonRadios
                    label="รูปแบบชำระชำระเงิน *"
                    items={getPaymentCondition(saleOrderProfile)}
                    onChange={(value) => {
                      setValue('paymentCondition', value)
                      const validInvoices =
                        saleOrderProfile?.documents?.filter(
                          (d) =>
                            d.type.id === DocumentType.Invoice && d.status.id !== DocumentStatus.Cancelled && d.total,
                        ) || []

                      const { vatIncluded } = saleOrderProfile
                      const depositAmount = validInvoices.reduce<number>((sum, invoice) => {
                        if (!invoice.total) return sum
                        return sum + (vatIncluded ? calculateExcludingVat(invoice.total) : invoice.total)
                      }, 0)

                      const actualTotal = calculateActualTotal(saleOrderProfile)
                      switch (value) {
                        case 1:
                          reset({
                            ...getValues(),
                            amount: actualTotal,
                            depositAmount: 0,
                            percentAmount: undefined,
                          })
                          break
                        case 2:
                          reset({
                            ...getValues(),
                            amount: undefined,
                            depositAmount,
                            percentAmount: undefined,
                          })
                          break
                        case 3:
                          reset({
                            ...getValues(),
                            amount: actualTotal - depositAmount,
                            depositAmount,
                            percentAmount: undefined,
                          })
                          break
                        default:
                          break
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="soPaymentTransactionId"
                    label="รายได้อ้างอิง (SPAY)"
                    select
                    disabled={availablePaymentTransactions.length === 0}
                    helperText={availablePaymentTransactions.length === 0 ? 'ไม่พบการชำระเงิน' : undefined}
                  >
                    {availablePaymentTransactions.map((element) => (
                      <MenuItem key={element.id} value={element.id}>
                        {element.code}
                      </MenuItem>
                    ))}
                  </GeneralTextFieldForm>
                </Grid>
                <Grid item xs={12} md={6}>
                  <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" />
                </Grid>
                <Grid item xs={12}>
                  {paymentCondition && (
                    <Table<SaleOrderProduct>
                      color="primary.dark"
                      size="small"
                      columns={[
                        {
                          name: 'ลำดับ',
                          fixedWidth: 80,
                          align: 'center',
                          render: (_, index) => index + 1,
                        },
                        { name: 'ชื่อสินค้า', dataIndex: 'nameLocal' },
                        { name: 'ราคาต่อหน่วย', fixedWidth: 180, numeric: true, format: '0,0.00', dataIndex: 'price' },
                        {
                          name: 'จำนวน',
                          fixedWidth: 200,
                          // numeric: !isPartialCreate, // todo: remove if no need
                          numeric: true,
                          format: '0,0',
                          dataIndex: 'amount',
                          render: (product, index) => {
                            // todo: remove if no need
                            // if (isPartialCreate)
                            //   return (
                            //     <GeneralTextFieldForm
                            //       control={control}
                            //       margin="none"
                            //       name={`products.${index}.amount`}
                            //       rules={{
                            //         required: 'กรุณากรอกจำนวน',
                            //         min: { value: 0, message: 'ตัวเลขติดลบ' },
                            //         max: { value: product.amount, message: 'เกินจำนวนที่เปิดได้' },
                            //       }}
                            //       InputProps={{
                            //         endAdornment: (
                            //           <Typography color="textSecondary" variant="body1">
                            //             ({product.amount})
                            //           </Typography>
                            //         ),
                            //       }}
                            //     />
                            //   )

                            return product.amount
                          },
                        },
                        {
                          name: 'ราคารวม',
                          fixedWidth: 180,
                          numeric: true,
                          format: '0,0.00',
                          render: (product) => {
                            let _actualAmount = product.amount
                            // todo: remove if no need
                            // if (isPartialCreate) {
                            //   _actualAmount =
                            //     productInputs.find((input) => input.id === product.id)?.amount ?? product.amount
                            // }

                            return _actualAmount * product.price
                          },
                        },
                      ]}
                      cleanSpace
                      pagination={false}
                      data={saleOrderProfile.products || []}
                      postCells={
                        saleOrderProfile && (
                          <>
                            {getInvoicePriceBreakdowns(saleOrderProfile, getValues()).map((price) => {
                              const { label, amount, input, options } = price
                              if (input) {
                                return (
                                  <TableRow key={label}>
                                    <TableCell colSpan={4} align="right">
                                      {label}
                                    </TableCell>
                                    <TableCell align="right">
                                      <GeneralTextFieldForm
                                        control={control}
                                        name={input}
                                        type="number"
                                        inputProps={{
                                          step: '0.01',
                                        }}
                                        rules={{ required: { value: true, message: 'กรุณาระบุยอดเงิน' } }}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                                        }}
                                        onPostChange={(value) => {
                                          const calculatedPercentage = calculateAsPercentage(
                                            Number(value),
                                            saleOrderProfile.total,
                                          )
                                          if (options) {
                                            const index = options.findIndex((option) => option === calculatedPercentage)
                                            if (index !== -1) {
                                              setValue('percentAmount', options[index])
                                            } else {
                                              setValue('percentAmount', undefined)
                                            }
                                          }
                                        }}
                                      />
                                      {options && (
                                        <ButtonRadios
                                          label=""
                                          items={options.map((option) => ({
                                            id: option,
                                            name: option.toString() + '%',
                                          }))}
                                          value={percentAmount}
                                          onChange={(value) => {
                                            setValue('percentAmount', value)
                                            setValue(
                                              'amount',
                                              calculatePercentage(calculateActualTotal(saleOrderProfile), value),
                                            )
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              }
                              return (
                                <TableRow key={label}>
                                  <TableCell colSpan={3} />
                                  <TableCell align="right">{label}</TableCell>
                                  <TableCell align="right">{numeral(amount).format('0,0.00')}</TableCell>
                                </TableRow>
                              )
                            })}
                          </>
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2">ประวัติใบแจ้งหนี้จากใบสั่งขาย {saleOrderProfile.code}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table<SaleOrderDocument<any>>
                    size="small"
                    color="primary.dark"
                    columns={[
                      {
                        name: 'สถานะใบแจ้งหนี้',
                        render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
                      },
                      { name: 'เลขใบแจ้งหนี้', dataIndex: 'code' },
                      { name: 'วันที่สร้าง', render: (invoice) => dateTimeFormat(invoice.recCreatedAt) },
                      { name: 'ยอดที่ต้องชำระ', numeric: true, format: '0,0.00', dataIndex: 'total' },
                    ]}
                    data={documents?.filter((d) => d.type.id === DocumentType.Invoice) || []}
                    cleanSpace
                    pagination={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            ยืนยันข้อมูล
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default InvoiceCreationDialog
