import React from 'react'
import { Paper, TableCell, TableRow } from '@material-ui/core'
import { Table } from '../../../custom-components'
import { useStyles } from './styles'
import { SelectedProduct } from '../../types'
import { columns } from './columns'
import SubTopic from '../../../custom-components/Typography/SubTopic'
import { useFormContext } from 'react-hook-form'
import SOCreationFormValues from '../../pages/SOCreationPage/SOCreationFormValues'
import numeral from 'numeral'
import { calculatePriceBreakdowns } from '../../utils/sale-order-price-calculator'

interface Props {
  readOnly?: boolean
  showPriceBreakdown?: boolean
  isDealer?: boolean
}

const SelectedProductTable: React.FC<Props> = (props) => {
  const { readOnly, showPriceBreakdown, isDealer } = props
  const { getValues } = useFormContext<SOCreationFormValues>()

  const values = getValues()
  const { selectedProducts } = values

  const styles = useStyles()

  const priceBreakdowns = getPricebreakdown(values, showPriceBreakdown)

  return (
    <Paper className={styles.root}>
      <SubTopic gutterBottom>ข้อมูลสินค้าที่เลือก</SubTopic>
      <Table<SelectedProduct>
        columns={columns(styles, readOnly, isDealer)}
        data={selectedProducts}
        pagination={false}
        size="small"
        color="primary"
        emptyText="ยังไม่เลือกสินค้า/บริการ"
        postCells={
          <>
            {priceBreakdowns.map((price) => (
              <TableRow key={price.name}>
                <TableCell colSpan={3} />
                <TableCell align="right">{price.name}</TableCell>
                <TableCell align="right">{numeral(price.amount).format('0,0.00')}</TableCell>
              </TableRow>
            ))}
          </>
        }
        cleanSpace
      />
    </Paper>
  )
}

interface PriceDetail {
  name: string
  amount: number
}

const getPricebreakdown = (values: SOCreationFormValues, showPriceBreakdown?: boolean): PriceDetail[] => {
  const priceBreakdowns = calculatePriceBreakdowns(values)

  const { productTotal, discount, shippingFee, vatAmount, total } = priceBreakdowns

  if (!showPriceBreakdown) {
    return [{ name: 'รวมเงิน', amount: productTotal }]
  }

  return [
    { name: 'รวมเงิน', amount: productTotal },
    { name: 'ส่วนลด', amount: discount },
    { name: 'ค่าขนส่ง', amount: shippingFee },
    { name: 'ยอดหลังหักส่วนลด', amount: productTotal - discount },
    { name: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
    { name: 'ยอดเงินสุทธิ', amount: total },
  ]
}

export default SelectedProductTable
