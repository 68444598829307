import React, { useContext, useMemo, useState } from 'react'
import {
  Grid,
  Button,
  TextField,
  Chip,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  CircularProgress,
  CircularProgressProps,
} from '@material-ui/core'
import { withLayout, Topic, Link, FlexBox, GeneralTextField, FilteredTable } from '../../custom-components'
import { IProductTableData_Data } from '../../api/smartbestbuys-api'
import { Avatar } from '../../custom-components'
import { Photo } from '@material-ui/icons'
import { Typography, Box } from '@material-ui/core'
import { RootContext } from '../..'
import { PageName, ProductGrade, ProductMode, ProductType } from '../../enums'
import {
  ConditionTooltip /* NoProductStorageAlertSignal */,
  DeadStockSignal,
  Loading,
  ProductGradeChip,
} from '../../shared-components'
import { getUserPermissionInfo } from '../../utils/permission'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { useHistory } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { useProductTableData } from '../../hooks/product/useProductTableData'
import { productGrades } from '../../constants'
import { PendingToReviewProductIcon, VerifyProductIcon } from '../../shared-components/SmartIcon'
import theme from '../../theme'

export const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  const themeColor =
    props.value < 50
      ? theme.palette.primary.main
      : props.value < 80
      ? theme.palette.warning.main
      : theme.palette.success.main
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress style={{ color: themeColor, width: 36, height: 36 }} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  )
}

const ProductTableDataPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PRODUCT)

  const history = useHistory()

  // state
  const [tabValue, setTabValue] = useState(ProductType.Product)

  const tableData = useProductTableData()

  const productTypeName = useMemo(() => {
    return tabValue === ProductType.Product ? 'สินค้า' : 'บริการ'
  }, [tabValue])

  if (!tableData) return <Loading />
  const {
    data,
    paginationInfo,
    pendingSearch,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    isLoading,
  } = tableData

  const writePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Write)
  const deletePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Delete)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  const handleOnCheckPendingVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('isPendingVerified', event.target.checked ? 'true' : 'false')
  }

  const handleOnCheckRequestChangedData = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('isRequestChangedData', event.target.checked ? 'true' : 'false')
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <FlexBox alignItems="center">
              <Topic>สินค้าและบริการ</Topic>
              {deletePermission.hasPermission && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={pendingSearch.mode === ProductMode.Archive}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.checked ? ProductMode.Archive : ProductMode.Available
                        handleSearchParamChange('mode', value, true)
                      }}
                      color="primary"
                      className="ml-6"
                    />
                  }
                  label={`แสดง${productTypeName}ที่ยกเลิกขาย`}
                />
              )}
            </FlexBox>
            <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push('/products/create')}
                disabled={!writePermission.hasPermission}
              >
                สร้าง{productTypeName}
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="name"
                  onChange={(e) => handleSearchParamChange('name', e.target.value)}
                  label="ชื่อ"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="code"
                  onChange={(e) => handleSearchParamChange('code', e.target.value)}
                  label={`รหัส${productTypeName}`}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  size="small"
                  options={productGrades.map((option) => option.value)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange('gradeList', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label={`กลุ่ม${productTypeName}`}
                      placeholder={`กลุ่ม${productTypeName}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pendingSearch.isPendingVerified === 'true'}
                        onChange={handleOnCheckPendingVerify}
                      />
                    }
                    label="สินค้าที่รอตรวจสอบข้อมูล"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pendingSearch.isRequestChangedData === 'true'}
                        onChange={handleOnCheckRequestChangedData}
                      />
                    }
                    label="สินค้าที่มีการขอแก้ไข"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <FilteredTable<IProductTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: '',
                render: ({ saleAmount, requestChangedData, verifiedBy, dataScore }) => (
                  <FlexBox alignItems="center">
                    {!!verifiedBy && <VerifyProductIcon />}
                    {!!requestChangedData && <PendingToReviewProductIcon />}
                    {saleAmount === 0 && <DeadStockSignal />}
                    <CircularProgressWithLabel value={Math.ceil(dataScore)} />
                  </FlexBox>
                ),
                fixedWidth: 50,
              },
              {
                name: `รายละเอียด${productTypeName}`,
                dataIndex: 'nameLocal',
                render: ({ imageUrl, nameLocal, nameEn, id, code, grade }) => (
                  <Box display="flex" alignItems="flex-start">
                    <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                      <Photo />
                    </Avatar>
                    {
                      <FlexBox ml={1} flexDirection="column">
                        <Link color="primary" to={`/products/${id}/profile`}>
                          {code} {grade && <ProductGradeChip grade={grade as ProductGrade} size="small" />}
                        </Link>

                        <Typography variant="body2">{nameLocal}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {nameEn}
                        </Typography>
                      </FlexBox>
                    }
                  </Box>
                ),
                fixedWidth: tabValue === ProductType.Product ? 300 : 750,
              },
              {
                name: 'หน่วย',
                dataIndex: 'unitLocal',
                fixedWidth: 70,
                sort: true,
                align: 'center',
              },
              {
                name: `จำนวน${productTypeName}คงเหลือในสต็อก`,
                render: ({ stockAmount, reservedAmount }) => {
                  const remainningStockAmount = stockAmount - reservedAmount
                  return Math.max(remainningStockAmount, 0)
                },
                numeric: true,
                format: '0,0',
                hide: tabValue === ProductType.Service,
              },
              {
                name: `จำนวน${productTypeName}ที่กำลังเข้า`,
                dataIndex: 'incomingAmount',
                numeric: true,
                format: '0,0',
                hide: tabValue === ProductType.Service,
              },
              {
                name: `จำนวน${productTypeName}ที่ถูกเปิด SO`,
                dataIndex: 'soReservedAmount',
                numeric: true,
                format: '0,0',
              },
              {
                name: `จำนวน${productTypeName}ที่กำลังส่ง`,
                dataIndex: 'reservedAmount',
                numeric: true,
                format: '0,0',
                hide: tabValue === ProductType.Service,
              },
              {
                name: `จำนวน${productTypeName}ที่ถูกยืม`,
                dataIndex: 'withdrawnAmount',
                numeric: true,
                format: '0,0',
                hide: tabValue === ProductType.Service,
              },
              {
                name: `จำนวน${productTypeName}ที่พร้อมขาย`,
                render: ({ stockAmount, soReservedAmount }) => {
                  const afterReservedAmount = stockAmount - soReservedAmount
                  return Math.max(afterReservedAmount, 0)
                },
                numeric: true,
                format: '0,0',
                hide: tabValue === ProductType.Service,
              },
              // {
              //   name: `จำนวน${productTypeName}ที่พร้อมขาย`,
              //   render: ({ stockAmount, reservedAmount }) => {
              //     const readyToSellAmount = stockAmount - reservedAmount
              //     return Math.max(readyToSellAmount, 0)
              //   },
              //   numeric: true,
              //   format: '0,0',
              //   hide: tabValue === ProductType.Service,
              // },
            ]}
            data={data}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            disableDateRangeFilter
            disableSearch
            tabOption={{
              tabs: [
                {
                  label: 'สินค้า',
                  value: ProductType.Product,
                },
                {
                  label: 'บริการ',
                  value: ProductType.Service,
                },
              ],
              value: tabValue,
              onChange: (_, value) => {
                setTabValue(value)
                handleSearchParamChange('typeIds', [value], true)
              },
            }}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(ProductTableDataPage)
