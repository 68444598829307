import React, { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { PurchaseRequisitionCreateState, PurchaseRequisitionTopic, PurchaseRequisitionType } from '../../../enums'

import PurchaseRequisitionCreateFormValues from './PurchaseRequisitionCreateFormValue'
import PurchaseRequisitionCreateForm from './PurchaseRequisitionCreateForm'
import PurchaseRequisitionVerification from './PurchaseRequisitionVerification'
import { usePurchaseRequisitionCreateData } from '../../../hooks/usePurchaseRequisitionCreateData'
import { withLayout } from '../../../custom-components'

interface LocationState {
  type: PurchaseRequisitionType
}

interface Props {
  saleOrderId?: number
  rop?: boolean
}

const PurchaseRequisitionCreatePage: React.FC<Props> = ({ saleOrderId, rop }) => {
  const location = useLocation<LocationState>()
  const { type } = location.state || { type: PurchaseRequisitionType.BuyProductAndServices }
  const [state, setState] = useState(PurchaseRequisitionCreateState.Form)

  const formMethod = useForm<PurchaseRequisitionCreateFormValues>({
    defaultValues: {
      products: [],
      currencyId: 1,
    },
  })

  const createPurchaseRequisitionData = usePurchaseRequisitionCreateData(saleOrderId, rop)

  useEffect(() => {
    const { reset, watch } = formMethod
    const { topicId } = watch()

    if (!!createPurchaseRequisitionData?.saleOrderInfo && topicId !== PurchaseRequisitionTopic.ForSell) {
      reset({
        products: [],
        currencyId: 1,
        topicId: PurchaseRequisitionTopic.ForSell,
        dueDate: createPurchaseRequisitionData?.saleOrderInfo?.dueDate
          ? new Date(createPurchaseRequisitionData.saleOrderInfo.dueDate)
          : undefined,
      })
    } else if (!!rop && topicId !== PurchaseRequisitionTopic.ReStock) {
      reset({
        products: [],
        currencyId: 1,
        topicId: PurchaseRequisitionTopic.ReStock,
      })
    } else {
    }
  }, [createPurchaseRequisitionData?.saleOrderInfo, rop, formMethod])

  if (!createPurchaseRequisitionData) return <div>Loading</div>

  return (
    <FormProvider {...formMethod}>
      {state === PurchaseRequisitionCreateState.Form ? (
        <PurchaseRequisitionCreateForm
          type={type}
          rop={!!rop}
          onNextPage={() => setState(PurchaseRequisitionCreateState.DataVerification)}
          createPurchaseRequisitionData={createPurchaseRequisitionData}
        />
      ) : (
        <PurchaseRequisitionVerification
          type={type}
          onPreviousPage={() => setState(PurchaseRequisitionCreateState.Form)}
          createPurchaseRequisitionData={createPurchaseRequisitionData}
        />
      )}
      <div className="mt-16" />
    </FormProvider>
  )
}

export default withLayout(PurchaseRequisitionCreatePage)
