import { Typography } from '@material-ui/core'
import { FlexBox } from '../custom-components'

interface Props {
  actual: string | number
  expected: string | number | undefined
}

const ComparedTypography: React.FC<Props> = (props: Props) => {
  const { actual, expected } = props
  const isSame = !expected || actual === expected
  return (
    <FlexBox flexDirection="column">
      <Typography variant="body2" className={!isSame ? 'line-through' : ''} color={'textSecondary'}>
        {props.actual}
      </Typography>
      {!isSame && (
        <Typography variant="body2" color="primary">
          {props.expected}
        </Typography>
      )}
    </FlexBox>
  )
}

export default ComparedTypography
