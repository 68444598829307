import React from 'react'
import { Box, Paper, Typography, Grid } from '@material-ui/core'
import { SubTopic, KeyboardDatePickerForm } from '../../custom-components'
import { useFormContext } from 'react-hook-form'
import SOCreationFormValues from '../pages/SOCreationPage/SOCreationFormValues'
import { customerPickUpMethods, CustomerPickUpMethod } from '../../types'
import { ButtonRadios } from '../../shared-components'

const CustomerPickupOptionBox: React.FC = (props) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<SOCreationFormValues>()
  const { customerPickUpMethod, dueDate } = getValues()

  const activeMethod = customerPickUpMethods.find((cpm) => cpm.id === customerPickUpMethod)

  return (
    <Box padding={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>การรับสินค้า</SubTopic>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <ButtonRadios
                label="วิธีการรับสินค้า *"
                value={customerPickUpMethod}
                items={customerPickUpMethods}
                onChange={(id) => setValue('customerPickUpMethod', id)}
              />
              {activeMethod?.warningMessage && (
                <Box color="warning.dark" mt={0.5}>
                  <Typography variant="body2">{activeMethod.warningMessage}</Typography>
                </Box>
              )}
            </Box>
          </Grid>
          {customerPickUpMethod !== CustomerPickUpMethod.PickUpAtShop && (
            <Grid item xs={12} md={6}>
              <KeyboardDatePickerForm
                control={control}
                label={'วันที่ลูกค้าต้องการ *'}
                name="dueDate"
                value={dueDate}
                defaultValue={null}
                // inputVariant="outlined"
                size="small"
                fullWidth
                error={!!errors.dueDate}
                helperText={errors.dueDate?.message}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  )
}

export default CustomerPickupOptionBox
