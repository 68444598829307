import {
  SaleOrderProduct,
  SaleOrderProfile,
  createCreditNote as createCreditNoteAPI,
  TaxInvoiceItem,
  SaleOrderDocument,
  TaxInvoiceState,
} from '../../api/smartbestbuys-api'
import { calculateExcludingVat, calculatePriceBreakdownsFromSOProfile, calculateVatAmount } from '../../functions'
import { CreditNoteFormValues } from './CreditNoteCreationDialog'
import _ from 'lodash'
import { CreditNoteCreationRequest, CreditNoteItem } from '../../api/smartbestbuys-api'

export const createCreditNote = (
  values: CreditNoteFormValues,
  saleOrderProfile: SaleOrderProfile,
  selectedItemList: number[],
  taxInvoiceIds: number[],
  taxInvoice?: SaleOrderDocument<TaxInvoiceState>,
) => {
  const priceBreakdowns = getCreditNotePriceBreakdown(values, saleOrderProfile, selectedItemList, !!taxInvoice)
  const { documents } = saleOrderProfile
  const selectedTaxInvoices = documents?.filter((d) => taxInvoiceIds.includes(d.id))
  const request: CreditNoteCreationRequest = {
    taxInvoiceId: taxInvoice?.id,
    customerId: saleOrderProfile.customer.id,
    total: _.last(priceBreakdowns)?.amount || 0,
    items: values.items.filter((d) => selectedItemList.includes(d.id)),
    priceBreakdowns,
    taxInvoiceReferences: selectedTaxInvoices?.map((d) => d.code) || [],
    remark: values.remark,
    actionMessageId: values.actionMessageId,
  }

  return createCreditNoteAPI(saleOrderProfile.id, request)
}

export const mapToCreditNoteItem = (product: SaleOrderProduct): CreditNoteItem => {
  return {
    id: product.productId,
    name: product.nameLocal,
    code: product.code,
    imageUrl: product.imageUrl,
    amount: product.amount,
    discountPerEach: product.price,
    discountTotal: product.amount * product.price,
  }
}

export const mapTaxInvoiceItemToCreditNoteItem = (taxInvoiceItem: TaxInvoiceItem, index: number): CreditNoteItem => {
  const { pricePerEach, total, ...others } = taxInvoiceItem
  return {
    ...others,
    id: index + 1,
    discountPerEach: pricePerEach,
    discountTotal: total,
  }
}

export const getCreditNotePriceBreakdown = (
  values: CreditNoteFormValues,
  saleOrderProfile: SaleOrderProfile,
  selectedItemList: number[],
  isFromTaxInvoice: boolean,
) => {
  const { discount, total } = calculatePriceBreakdownsFromSOProfile(saleOrderProfile)
  const { items, shippingFee } = values
  const discountTotal = _.sum(
    items.filter((p) => selectedItemList.includes(p.id)).map((p) => p.discountPerEach * p.amount),
  )
  const documentTotal = isFromTaxInvoice ? discountTotal : discountTotal + shippingFee - discount

  const vatDiscount = saleOrderProfile.vatIncluded ? calculateVatAmount(documentTotal) : 0

  if (isFromTaxInvoice) {
    return [
      { label: 'มูลค่าตามเอกสารเดิม', amount: discountTotal },
      { label: 'มูลค่าที่ถูกต้อง', amount: 0 },
      { label: 'ผลต่าง', amount: discountTotal },
      { label: 'รวมเป็นเงิน', amount: discountTotal },
      { label: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatDiscount },
      { label: 'ยอดเงินสุทธิ', amount: discountTotal + vatDiscount },
    ]
  } else {
    const totalExcludingVat = calculateExcludingVat(total)

    return [
      { label: 'ลดค่าขนส่ง', amount: shippingFee, editable: { name: 'shippingFee' } },
      { label: 'ส่วนลดเดิม', amount: discount },
      { label: 'มูลค่าตามเอกสารเดิม', amount: totalExcludingVat },
      { label: 'มูลค่าที่ถูกต้อง', amount: totalExcludingVat - documentTotal },
      { label: 'ผลต่าง', amount: documentTotal },
      { label: 'รวมเป็นเงิน', amount: documentTotal },
      { label: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatDiscount },
      { label: 'ยอดเงินสุทธิ', amount: documentTotal + vatDiscount },
    ]
  }
}
