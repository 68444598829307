import React, { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { mapCustomerProfileDomain } from '../../../data'
import { CustomerProfileCard } from '../../../shared-components'
import { QuotationUpdationFormValue } from './QuotationUpdationFormValue'
import { QuotationUpdationPageContextImpl } from './QuotationUpdationPage'

const QuotationUpdationCustomerProfileCard: React.FC = () => {
  const { data } = useContext(QuotationUpdationPageContextImpl)
  const { watch, setValue } = useFormContext<QuotationUpdationFormValue>()

  const { profile, billingAddresses: billingSelection } = data
  const { billingAddress } = profile

  const { billingAddressId } = watch()

  useEffect(() => {
    if (billingAddress) {
      setValue('billingAddressId', billingAddress.id)
    }
  }, [billingAddress, setValue])

  const customerProfile = mapCustomerProfileDomain(
    profile.customer,
    billingSelection,
    profile.contact ? [profile.contact] : [],
  )

  return (
    <CustomerProfileCard
      contactId={profile.contact?.id}
      customerProfile={customerProfile}
      billingAddressId={billingAddressId}
      billingAddressSelect
      disableBtnCustomerDetail
      onBillingAddressChange={(id: number) => setValue('billingAddressId', id)}
    />
  )
}

export default QuotationUpdationCustomerProfileCard
