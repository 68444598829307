import React from 'react'
import { useFormContext } from 'react-hook-form'

type Props<T> = {
  onSubmit: (data: T, event?: any) => void
  onInvalid?: (errors: T, event?: any) => void
  ref?: any
}

const Form: React.FC<Props<any>> = (props) => {
  const { onSubmit, onInvalid, ...othersProps } = props
  const methods = useFormContext()
  const { handleSubmit } = { ...methods }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (methods) {
          handleSubmit(onSubmit, onInvalid)()
        } else {
          onSubmit(e)
        }
      }}
      {...othersProps}
    />
  )
}

export const FormRender: React.FC = (props) => {
  const { children } = props
  const methods = useFormContext()
  return (
    <>
      {React.Children.map(children, (child: any) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
              },
            })
          : child
      })}
    </>
  )
}

export default Form
