import { Grid } from '@material-ui/core'
import { createContext, useContext, useEffect, useState } from 'react'
import { FormProvider, NestedValue, useForm } from 'react-hook-form'
import { IRefundRequisition, IRefundRequisitionFormData } from '../../../api/smartbestbuys-api'

import { Form, Topic } from '../../../custom-components'
import { CustomerProfileCard, SubmitForm } from '../../../shared-components'
import AttachmentInputBox from '../../../shared-components/AttachmentInputBox'
import { FileType, SourceFile } from '../../../types'

import CustomerBankSelectBox from './CustomerBankSelectBox'
import DebtSaleOrderSelectBox from './DebtSaleOrderSelectBox'
import RefundRequisitionDetailBox from './RefundRequistionInputsBox'

export interface IRefundRequisitionFormValues_DebtSaleOrder {
  id: number
  code: string
  total: number
}

export interface IRefundRequisitionFormValues {
  debtSaleOrders: NestedValue<IRefundRequisitionFormValues_DebtSaleOrder[]>
  attachments: SourceFile[]
  customerBankAccountId?: number
  remark?: string

  // for new bank
  bankId: number
  accountName: string
  accountNumber: string
  branchName?: string
  bookBankImageBase64: string
}

interface IRefundRequisitionFormContext {
  formData: IRefundRequisitionFormData | undefined
  refundRequisition?: IRefundRequisition
}

export const RefundRequisitionFormContext = createContext<IRefundRequisitionFormContext>({
  formData: undefined,
  refundRequisition: undefined,
})

interface Props {
  formData: IRefundRequisitionFormData
  refundRequisition?: IRefundRequisition
  onSubmit: (values: IRefundRequisitionFormValues) => void
}

const RefundRequisitionForm = (props: Props) => {
  const { formData, refundRequisition } = props
  const [attachments, setAttachments] = useState<SourceFile[]>(
    refundRequisition?.attachments.map((file) => {
      const fileName = file.url.split('/')
      return {
        url: file.url,
        type: file.url.slice(-4) === '.pdf' ? FileType.PDF : FileType.Image,
        name: fileName[fileName.length - 1],
      }
    }) ?? [],
  )
  const formMethods = useForm<IRefundRequisitionFormValues>({
    defaultValues: {
      debtSaleOrders: [],
      attachments: [],
      remark: refundRequisition?.remark,
    },
  })

  const {
    watch,
    setError,
    reset,
    formState: { isSubmitting },
  } = formMethods

  const title = !!refundRequisition ? `แก้ไขการคืนเงิน ${refundRequisition.code}` : 'ทำเบิกคืนเงิน'
  const submitText = !!refundRequisition ? 'บันทึกการแก้ไข' : 'ยืนยันทำเบิกคืนเงิน'

  // set default values
  useEffect(() => {
    if (!refundRequisition) return
    reset({
      debtSaleOrders: refundRequisition.saleOrders.map((so) => ({
        id: so.id,
        code: so.code,
        total: so.total,
      })),
      customerBankAccountId: refundRequisition.customerBankAccountId,
      remark: refundRequisition?.remark,
    })
  }, [refundRequisition, reset])

  const handleSubmit = (values: IRefundRequisitionFormValues) => {
    const { debtSaleOrders } = values
    if (debtSaleOrders.length === 0) {
      setError('debtSaleOrders', { message: 'กรุณาเลือกใบสั่งซื้อที่ต้องคืนเงิน' })
      scrollTo('#debt-sale-orders-select-box')
      return
    }

    props.onSubmit({ ...values, attachments })
  }

  const handleInvalid = (errors: IRefundRequisitionFormValues) => {
    const values = watch()

    const { debtSaleOrders } = values
    if (debtSaleOrders.length === 0) {
      setError('debtSaleOrders', { message: 'กรุณาเลือกใบสั่งซื้อที่ต้องคืนเงิน' })
      scrollTo('#debt-sale-orders-select-box')
    }
  }

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  return (
    <RefundRequisitionFormContext.Provider
      value={{
        refundRequisition,
        formData,
      }}
    >
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Topic>{title}</Topic>
            </Grid>
            <Grid item xs={12}>
              <CustomerProfileCard customerProfile={formData.customer} />
            </Grid>
            <Grid item xs={12}>
              <DebtSaleOrderSelectBox />
            </Grid>
            <Grid item xs={12}>
              <RefundRequisitionDetailBox />
            </Grid>
            <Grid item xs={12}>
              <CustomerBankSelectBox />
            </Grid>
            <Grid item xs={12}>
              <AttachmentInputBox limit={3} onChange={setAttachments} defaultValues={attachments} />
            </Grid>
            <Grid item xs={12}>
              <SubmitForm isSubmitting={isSubmitting} submitText={submitText} cancelText="ย้อนกลับ" goBack />
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </RefundRequisitionFormContext.Provider>
  )
}

export default RefundRequisitionForm

export const useRefundRequisitionFormContext = () => {
  return useContext(RefundRequisitionFormContext)
}
