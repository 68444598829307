import { FetchSOPaymentTransaction, SOPaymentTransactionState } from '.'
import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export interface VerifyPaymentStatementRequestBody {
  statementTimestamp: Date | undefined
  bankAccountId?: number
  state?: SOPaymentTransactionState
  verificationRemark?: string
}

export const verifyPaymentStatementAPI = (saleOrderId: number) =>
  errorSingleHandler<FetchSOPaymentTransaction>(
    async (transactionId: number, request: VerifyPaymentStatementRequestBody) => {
      return await smartAuthAPI.post(`/sale-orders/${saleOrderId}/payments/${transactionId}/verify-statement`, request)
    },
  )
