import React, { useContext, useState } from 'react'
import { Grid, Paper, Typography, Box } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, FlexBox, Link } from '../../../custom-components'
import { PaymentRequisition_TableData } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import {
  NavigationItemKey,
  PageName,
  PaymentReceivingProductStatus,
  PaymentRequisitionStatus,
  PurchasePaymentStatusGroup,
} from '../../../enums'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { usePaymentRequisitions } from '../../../hooks'
import {
  NotificationHead,
  PaymentReceivingProductStatusChip,
  PaymentRequisitionStatusChip,
} from '../../../shared-components'
import { dateTimeFormat } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'

const pendingStatuses = [PaymentRequisitionStatus.PendingTransfer, PaymentRequisitionStatus.PendingCheque]

const PurchasePaymentListPage: React.FC = (props) => {
  // context & state
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PURCHASE_PAYMENT)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [tabValue, setTabValue] = useState(1)

  const allPaymentRequisitions = usePaymentRequisitions(startDate, endDate)

  const purchasePayments = allPaymentRequisitions.filter((pr) => !!pr.paymentCode)

  const approvedAmount = purchasePayments.filter((pp) => pendingStatuses.includes(pp.status.id)).length
  const pendingReceiptAmount = purchasePayments.filter(
    (pp) => pp.status.id === PaymentRequisitionStatus.PendingReceipt,
  ).length
  const pendingStatementAmount = purchasePayments.filter(
    (pp) => pp.status.id === PaymentRequisitionStatus.PendingStatement,
  ).length

  // handler
  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการทำจ่าย</Topic>
          </FlexBox>
          <NotificationHead navigationKey={NavigationItemKey.PurchasePayment} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<PaymentRequisition_TableData>
              color="primary.dark"
              columns={[
                {
                  name: 'สถานะการจ่าย',
                  dataIndex: 'status',
                  render: ({ status }) => <PaymentRequisitionStatusChip status={status.id} label={status.name} />,
                },
                {
                  name: 'สถานะการรับของ',
                  dataIndex: 'paymentReceivingProductStatus',
                  render: ({ paymentReceivingProductStatus }) => (
                    <PaymentReceivingProductStatusChip status={paymentReceivingProductStatus} />
                  ),
                },
                {
                  name: 'เลขที่การทำจ่าย',
                  render: ({ id, paymentCode }) => (
                    <Link color="primary" to={`/purchase-payments/${id}/profile`}>
                      <Typography variant="body2">{paymentCode}</Typography>
                    </Link>
                  ),
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  dataIndex: 'vendor',
                  render: ({ vendor }) => vendor.name,
                },
                { name: 'ยอดทำเบิก', dataIndex: 'total', numeric: true, format: '0,0.00' },
                { name: 'หัก ณ ที่จ่าย', dataIndex: 'withholdingTaxAmount', numeric: true, format: '0,0.00' },
                {
                  name: 'ยอดทำเบิกสุทธิ',
                  render: ({ total, withholdingTaxAmount }) => total - (withholdingTaxAmount ?? 0),
                  numeric: true,
                  format: '0,0.00',
                },
                {
                  name: 'จำนวนใบสั่งขาย',
                  dataIndex: 'countPurchaseOrder',
                  numeric: true,
                },
                { name: 'วิธีการชำระเงิน', render: ({ paymentMethod }) => paymentMethod.name },
              ]}
              sortable
              data={purchasePayments.filter((data) => _filterByStatusGroup(data, tabValue))}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              tabOption={{
                tabs: [
                  {
                    label: `รอยืนยัน (${formatNumberInt(approvedAmount)})`,
                    value: PurchasePaymentStatusGroup.Approved,
                  },
                  {
                    label: `รอใบเสร็จจากผู้ขาย (${formatNumberInt(pendingReceiptAmount)})`,
                    value: PurchasePaymentStatusGroup.PendingReceipt,
                  },
                  {
                    label: `รอตรวจ Statement (${formatNumberInt(pendingStatementAmount)})`,
                    value: PurchasePaymentStatusGroup.PendingStatement,
                  },
                  { label: 'ทำจ่ายเสร็จสิ้น', value: PurchasePaymentStatusGroup.Success },
                  { label: 'ยกเลิก', value: PurchasePaymentStatusGroup.Canceled },
                ],
                value: tabValue,
                onChange: handleTabChange,
              }}
              filters={[
                {
                  name: 'สถานะการรับของ',
                  values: [
                    {
                      name: 'ยังไม่ได้รับของ',
                      value: PaymentReceivingProductStatus.Pending,
                    },
                    {
                      name: 'รับของบางส่วนแล้ว',
                      value: PaymentReceivingProductStatus.Partial,
                    },
                    {
                      name: 'รับของครบถ้วน',
                      value: PaymentReceivingProductStatus.ReceivedAll,
                    },
                  ],
                  filter: (d, values) => values.includes(d.paymentReceivingProductStatus.id),
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(PurchasePaymentListPage, true)

const _filterByStatusGroup = (data: PaymentRequisition_TableData, statusGroup: PurchasePaymentStatusGroup): boolean => {
  switch (statusGroup) {
    case PurchasePaymentStatusGroup.Approved:
      return pendingStatuses.includes(data.status.id)
    case PurchasePaymentStatusGroup.PendingReceipt:
      return data.status.id === PaymentRequisitionStatus.PendingReceipt
    case PurchasePaymentStatusGroup.PendingStatement:
      return data.status.id === PaymentRequisitionStatus.PendingStatement
    case PurchasePaymentStatusGroup.Success:
      return data.status.id === PaymentRequisitionStatus.Success
    case PurchasePaymentStatusGroup.Canceled:
      return data.status.id === PaymentRequisitionStatus.Canceled
    default:
      return false
  }
}
