import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  DialogActions,
} from '@material-ui/core'
import { CRReceiveProductTaskState, NewWarehouseTask } from '../../../api/smartbestbuys-api'
import { FlexBox, Table, TypographyWithLabel } from '../../../custom-components'
import { ReceiveProductAction, WarehouseTaskType } from '../../../enums/WarehousEnums'
import { dateFormat } from '../../../functions'

import { formatNumberInt } from '../../../functions/number-utils'
import { useWarehouseTaskV2 } from '../../../hooks'
import { CustomerProfileCard, Loading, Space, TaskStatusChip } from '../../../shared-components'
import PurchaseProductName from '../../purchase-orders/create/SelectedPurchaseOrderProductTable/PurchaseProductName'

interface Props extends DialogProps {
  receiveProductTaskId: number
  receiveProductTaskTypeId: number
}

const AlreadyReceivedProductBackDialog = (props: Props) => {
  // props
  const { receiveProductTaskId, receiveProductTaskTypeId, ...otherProps } = props

  // prepare data
  const receiveProductTask = useWarehouseTaskV2(receiveProductTaskId, receiveProductTaskTypeId)

  // loading
  if (!receiveProductTask)
    return (
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Loading />
      </Dialog>
    )

  console.log(receiveProductTask)

  const { status, code, customerInfo, customerRequisitionInfo, type, finishedAt, finishedBy } = receiveProductTask

  return (
    <Dialog {...otherProps} fullWidth maxWidth="md">
      <DialogTitle>
        <FlexBox alignItems="center">
          {code} <Space direction="horizontal" />
          <Typography color="textSecondary">งานรับสินค้าเคลม/คืน</Typography>
          <Space direction="horizontal" />
          <TaskStatusChip status={status.id} label={status.name} />
        </FlexBox>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomerProfileCard customerProfile={customerInfo} />
          </Grid>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <Grid item xs={6}>
                <TypographyWithLabel label="รูปแบบการรับ:">{type?.name}</TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="ประเภทการรับ:">{customerRequisitionInfo?.type.name}</TypographyWithLabel>
              </Grid>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <TypographyWithLabel label="เลขคำร้องอ้างอิง:">{customerRequisitionInfo?.code}</TypographyWithLabel>
          </Grid>
          <ReceiveProductResultTable receiveProductTask={receiveProductTask} />
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="วันที่รับสินค้า:">
              {finishedAt ? dateFormat(finishedAt) : '-'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="รับโดย:">{finishedBy}</TypographyWithLabel>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default AlreadyReceivedProductBackDialog

interface ReceiveProductResultTableProps {
  receiveProductTask: NewWarehouseTask
}

interface ReceivedProductDetail {
  id: number
  name: string
  code: string
  imageUrl: string
  receiveAction: ReceiveProductAction
  receivedAmount?: number
  expectedAmount?: number
}

const ReceiveProductResultTable = (props: ReceiveProductResultTableProps) => {
  const { receiveProductTask } = props
  const { customerRequisitionInfo, state, type } = receiveProductTask

  const crState = state as CRReceiveProductTaskState

  const receviedProductDetail: ReceivedProductDetail[] =
    customerRequisitionInfo?.products?.map((product) => {
      const crReceivedResults = crState?.results
      const result = crReceivedResults?.find((r) => r.crProductId === product.productId)
      if (!!result)
        return {
          id: product.id,
          name: product.nameLocal,
          code: product.code,
          imageUrl: product.imageUrl,
          receiveAction: result.action,
          receivedAmount: result.receivedAmount,
          expectedAmount: result.expectedAmount,
        } as ReceivedProductDetail

      // task 4007 is supported only receiving all products
      if (type.id === WarehouseTaskType.ReceiveProductBack) {
        return {
          name: product.nameLocal,
          code: product.code,
          imageUrl: product.imageUrl,
          receiveAction: product.receivedAmount !== 0 ? ReceiveProductAction.All : ReceiveProductAction.Nothing,
          receivedAmount: product.receivedAmount,
          expectedAmount: product.amount,
        } as ReceivedProductDetail
      }

      return {
        name: product.nameLocal,
        code: product.code,
        imageUrl: product.imageUrl,
        receiveAction: ReceiveProductAction.Nothing,
      } as ReceivedProductDetail
    }) ?? []

  return (
    <Table<ReceivedProductDetail>
      color="primary"
      size="small"
      data={receviedProductDetail}
      columns={[
        { name: 'ลำดับ', align: 'center', fixedWidth: 70, render: (_, index) => index + 1 },
        {
          name: 'ชื่อสินค้า',
          render: (product) => (
            <PurchaseProductName imageUrl={product.imageUrl} code={product.code} name={product.name} />
          ),
        },
        {
          name: 'ผลการรับ',
          fixedWidth: 190,
          render: (product, index) => {
            switch (product.receiveAction) {
              case ReceiveProductAction.All:
                return <Typography>รับครบ/รับที่เหลือ</Typography>
              case ReceiveProductAction.Partial:
                return <Typography>รับขาด</Typography>
              case ReceiveProductAction.OverWithGiven:
                return <Typography>ได้ของแถม</Typography>
              case ReceiveProductAction.RejectRemaining:
                return <Typography>รับขาดแต่ไม่รอรับที่ขาด</Typography>
              case ReceiveProductAction.Nothing:
                return <Typography>ไม่ได้รับ</Typography>
              case ReceiveProductAction.AlreadyReceived:
                return <Typography>รับครบไปแล้ว</Typography>
              default:
                return <Typography>-</Typography>
            }
          },
        },
        {
          name: 'จำนวนรับจริง/จำนวนที่ต้องรับ',
          align: 'right',
          fixedWidth: 140,
          render: (product) => {
            if (product.receiveAction === ReceiveProductAction.AlreadyReceived)
              return <Typography color="textSecondary">-</Typography>
            if (product.receivedAmount === undefined || product.expectedAmount === undefined)
              return <Typography color="textSecondary">-</Typography>
            let textColor = 'textPrimary'
            if (product.receivedAmount === product.expectedAmount) textColor = 'success.main'
            if (product.receivedAmount < product.expectedAmount) textColor = 'error.main'
            if (product.receivedAmount > product.expectedAmount) textColor = 'warning.main'
            return (
              <Box color={textColor} clone>
                <Typography>
                  {`${formatNumberInt(product.receivedAmount)}/${formatNumberInt(product.expectedAmount)}`}
                </Typography>
              </Box>
            )
          },
        },
      ]}
      cleanSpace
      pagination={false}
    />
  )
}
