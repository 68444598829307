import { useState } from 'react'
import { DocumentCreditNote, fetchDocumentCreditNotes } from '../../api/smartbestbuys-api'
import { calculateExcludingVat, dateTimeFormat } from '../../functions'
import { formatNumber } from '../../functions/number-utils'

import { CSVDataColumn, CSVDownloader } from './CSVDownloader'

export const CreditNoteCSVDownloader = () => {
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<DocumentCreditNote[]>([])

  const handleLoadClick = async (startDate: Date, endDate: Date) => {
    try {
      const response = await fetchDocumentCreditNotes({
        startDate,
        endDate,
      })

      setData(response?.data ?? [])
      setLoaded(true)
    } catch (e) {
      setData([])
    }
  }

  return (
    <CSVDownloader
      fileName="รายการใบลดหนี้"
      loaded={loaded}
      data={data}
      columns={_columns}
      onLoadClick={handleLoadClick}
      onDateChange={() => setLoaded(false)}
    />
  )
}

const _columns: CSVDataColumn<DocumentCreditNote>[] = [
  {
    columnName: 'วันที่สร้าง',
    render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
  },
  { columnName: 'เลขใบลดหนี้', render: ({ code }) => code },
  {
    columnName: 'เลขใบกำกับภาษีอ้างอิง',
    render: ({ state }) => (state?.taxInvoiceReferences ? state?.taxInvoiceReferences.join(',') : '--'),
  },
  {
    columnName: 'ชื่อลูกค้า',
    render: ({ customerInfo }) => customerInfo?.overrideDocumentData?.customerName || customerInfo?.name || '--',
  },
  {
    columnName: 'ยอดก่อน VAT',
    render: ({ total }) => (total ? formatNumber(calculateExcludingVat(total)) : 0),
  },
  {
    columnName: 'VAT',
    render: ({ total }) => {
      const _total = total ?? 0
      return formatNumber(_total - calculateExcludingVat(_total))
    },
  },
  { columnName: 'ยอดเงินสุทธิ', render: ({ total }) => (total ? formatNumber(total) : 0) },
  { columnName: 'สถานะใบลดหนี้', render: ({ status: { name } }) => name },
]
