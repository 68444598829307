import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import {
  Grid,
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CustomerProfileCard, DocumentStatusChip, Loading } from '../../../../shared-components'
import { GeneralTextFieldForm, Table, Form } from '../../../../custom-components'
import { DocumentInvoice, SaleOrderDocument, TaxInvoiceItem } from '../../../../api/smartbestbuys-api'
import { createTaxInvoice, getTaxInvoiceItems, getTaxInvoicePriceBreakdowns, RefInvoice } from './service'
import numeral from 'numeral'
import { RootContext } from '../../../..'
import { dateTimeFormat } from '../../../../functions'
import { DocumentStatus, DocumentType } from '../../../../enums'
import { useCustomerProfile, useSaleOrderProfile } from '../../../../hooks'
import _ from 'lodash'

export interface TaxInvoiceCreationFormValues {
  remark?: string
}

interface Props extends DialogProps {
  invoice: DocumentInvoice
}

const TaxInvoiceCreationDialog: React.FC<Props> = (props) => {
  const { invoice, onClose, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<TaxInvoiceCreationFormValues>()
  const saleOrderProfile = useSaleOrderProfile(invoice.saleOrderInfo?.id)
  const customerProfile = useCustomerProfile(invoice.customerInfo?.id)

  if (!saleOrderProfile || !customerProfile) return <Loading />

  const refInvoice: RefInvoice = {
    id: invoice.id,
    code: invoice.code,
    total: invoice.total || 0,
  }
  const { documents, total } = saleOrderProfile

  const taxInvoices = documents?.filter((d) => d.type.id === DocumentType.TaxInvoice) || []
  const availableTaxInvoices = taxInvoices.filter((d) => d.status.id !== DocumentStatus.Cancelled)
  const ableToCreateTaxInvoice =
    availableTaxInvoices.length === 0 || _.sum(availableTaxInvoices.map((d) => d.total || 0)) < total

  const handleSubmitInvoiceForm = async (values: TaxInvoiceCreationFormValues) => {
    const response = await createTaxInvoice(saleOrderProfile, values, refInvoice)
    if (response?.status === 201) {
      triggerSnackbar('สร้างใบกำกับภาษีสำเร็จ')
      // reload()
      onClose && onClose({}, 'escapeKeyDown')
    } else {
      triggerSnackbar('สร้างใบกำกับภาษีไม่สำเร็จ')
    }
    return true
  }

  let title = refInvoice ? `สร้างใบกำกับภาษีจากใบแจ้งหนี้ ${refInvoice.code}` : 'สร้างกำกับภาษี'
  if (ableToCreateTaxInvoice) {
    title = 'สร้างใบกำกับภาษีครบแล้ว'
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitInvoiceForm)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {ableToCreateTaxInvoice ? (
              <>
                <Grid item xs={12}>
                  <CustomerProfileCard
                    customerProfile={customerProfile}
                    billingAddressId={saleOrderProfile.billingAddress?.id}
                    disableBtnCustomerDetail
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" />
                    </Grid>
                    <Grid item xs={12}>
                      <Table<{ id: number } & TaxInvoiceItem>
                        color="primary.dark"
                        size="small"
                        columns={[
                          {
                            name: 'ลำดับ',
                            render: (_, index) => index + 1,
                          },
                          { name: 'ชื่อสินค้า', dataIndex: 'name' },
                          { name: 'ราคาต่อหน่วย', numeric: true, format: '0,0.00', dataIndex: 'pricePerEach' },
                          { name: 'จำนวน', numeric: true, format: '0,0', dataIndex: 'amount' },
                          {
                            name: 'ราคารวม',
                            numeric: true,
                            format: '0,0.00',
                            dataIndex: 'total',
                          },
                        ]}
                        cleanSpace
                        pagination={false}
                        data={getTaxInvoiceItems(saleOrderProfile, refInvoice) as any}
                        postCells={
                          saleOrderProfile && (
                            <>
                              {getTaxInvoicePriceBreakdowns(saleOrderProfile, refInvoice).map((price) => {
                                const { label, amount } = price
                                return (
                                  <TableRow key={label}>
                                    <TableCell colSpan={3} />
                                    <TableCell align="right">{label}</TableCell>
                                    <TableCell align="right">{numeral(amount).format('0,0.00')}</TableCell>
                                  </TableRow>
                                )
                              })}
                            </>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Alert severity="error">ใบกำกับภาษีทั้งหมดของใบสั่งขายนี้ถูกสร้างครบแล้ว</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2">ประวัติใบกำกับภาษีจากใบสั่งขาย {saleOrderProfile.code}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table<SaleOrderDocument<any>>
                    size="small"
                    color="primary.dark"
                    columns={[
                      {
                        name: 'สถานะใบกำกับภาษี',
                        render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
                      },
                      { name: 'เลขใบแจ้งหนี้', dataIndex: 'code' },
                      { name: 'วันที่สร้าง', render: (invoice) => dateTimeFormat(invoice.recCreatedAt) },
                      { name: 'ยอดที่ต้องชำระ', numeric: true, format: '0,0.00', dataIndex: 'total' },
                    ]}
                    data={taxInvoices}
                    cleanSpace
                    pagination={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {ableToCreateTaxInvoice ? (
          <DialogActions>
            <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
              ยกเลิก
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress /> : undefined}
            >
              ยืนยันข้อมูล
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button color="primary" variant="contained" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
              ตกลง
            </Button>
          </DialogActions>
        )}
      </Form>
    </Dialog>
  )
}

export default TaxInvoiceCreationDialog
