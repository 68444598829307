import { Box, FormControlLabel, MenuItem, Paper } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { CheckboxForm, FlexBox, GeneralTextFieldForm, Grid, SubTopic, Title } from '../../../custom-components'
import { ButtonRadios, Space, UploadImageCard } from '../../../shared-components'
import { currencies, purchasePaymentMethods } from '../../../types'
import { VendorCreateFormValues } from './VendorCreatePage'

const VendorCreateForm = () => {
  // context
  const { setValue, control, watch } = useFormContext<VendorCreateFormValues>()
  const { isVatVendor } = watch()

  return (
    <Box p={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>ข้อมูลผู้ขาย</Title>
            <Space />
            <FlexBox justifyContent="center">
              <UploadImageCard
                previewOnChange
                title="ใส่โลโก้ หรือรูปผู้ขาย (ถ้ามี)"
                onChange={(image) => setValue('base64', image.base64)}
              />
            </FlexBox>
            <Space />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="vendorName"
              label="ชื่อ *"
              rules={{ required: 'กรุณากรอกชื่อ' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="referenceNumber"
              label="เลขประจำตัวผู้เสียภาษี *"
              rules={{ required: 'กรุณากรอกเลขประจำตัวผู้เสียภาษี' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonRadios
              label="วิธีการจ่ายเงิน (ถ้ามี)"
              items={purchasePaymentMethods}
              onChange={(value) => setValue('refPaymentMethodId', value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="currencyId"
              rules={{ required: 'กรุณากรอกสกุลเงินที่ใช้' }}
              label="สกุลเงินที่ใช้ *"
              select
            >
              {currencies.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {currency.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="phoneNumber"
              label="เบอร์โทรศัพท์ *"
              rules={{ required: 'กรุณากรอกเบอร์โทรศัพท์' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="scopeWork" label="ขอบเขตงานที่รับ (ถ้ามี)" />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="creditPeriodDay"
              type="number"
              label="ระยะเวลาเครดิต *"
              rules={{ required: 'กรุณากรอกระยะเวลาเครดิต' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="email"
              label="อีเมล *"
              rules={{ required: 'กรุณากรอกอีเมล' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="websiteUrl" label="เว็บไซต์ (ถ้ามี)" />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="contact.name"
              label="ชื่อผู้ติดต่อ *"
              rules={{ required: 'กรุณากรอกชื่อผู้ติดต่อ' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="contact.phoneNumber"
              label="เบอร์โทรศัพท์ผู้ติดต่อ *"
              rules={{ required: 'กรุณากรอกเบอร์โทรศัพท์ผู้ติดต่อ' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={<CheckboxForm checked={isVatVendor} control={control} name="isVatVendor" color="primary" />}
              label={<SubTopic variant="subtitle1">ผู้ขายจดภาษีมูลค่าเพิ่ม</SubTopic>}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default VendorCreateForm
