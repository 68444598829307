import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, Link, FlexBox } from '../../../custom-components'
import { DocumentCancellationDialog, DocumentStatusChip } from '../../../shared-components'
import { defaultStartDate, defaultEndDate } from '../../../data'
import { useDocumentInvoices } from '../../../hooks/useDocumentInvoices'
import { dateTimeFormat } from '../../../functions/date-formatter'
import { DocumentInvoice } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import { DocumentStatus, PageName } from '../../../enums'
import { Description, Cancel } from '@material-ui/icons'
import { TaxInvoiceCreationDialog } from './TaxInvoiceCreationDialog'
import lodash from 'lodash'
import SaleOrderProfileContext from '../../sale-orders/id/SaleOrderProfilePage/SaleOrderProfileContext'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const DocumentInvoicesPage: React.FC = (props) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const { reload } = useContext(SaleOrderProfileContext)
  setCurrentPage(PageName.INVOICE)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [idToCancelId, setIdToCancel] = useState<number | undefined>(undefined)
  const [idToCreateTaxInvoice, setIdToCreateTaxInvoice] = useState<number | undefined>(undefined)
  const invoices = useDocumentInvoices(startDate, endDate)

  const [data, setData] = useState<DocumentInvoice[]>([])

  useEffect(() => {
    if (invoices.length > 0) {
      setData(invoices)
    }
  }, [invoices])

  const documentStatuses = useMemo(() => {
    return lodash
      .chain(invoices)
      .uniqBy('status.id')
      .orderBy('status.id')
      .value()
      .map((doc) => {
        return {
          name: doc.status.name,
          value: doc.status.id,
        }
      })
    // eslint-disable-next-line
  }, [invoices.map((doc) => doc.code).toString()])

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Write)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>รายการใบแจ้งหนี้</Topic>
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<DocumentInvoice>
          color="primary.dark"
          columns={[
            {
              name: 'สถานะใบแจ้งหนี้',
              render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
            },
            {
              name: 'เลขใบแจ้งหนี้',
              render: ({ id, code }) => (
                <Link color="primary" to={`/invoices/${id}/pdf`} target="_blank">
                  {code}
                </Link>
              ),
            },
            {
              name: 'เลขใบสั่งขายอ้างอิง',
              render: (invoice) => (
                <Link color="secondary" to={`/sale-orders/${invoice.saleOrderInfo?.id}`} target="_blank">
                  {invoice.saleOrderInfo?.code}
                </Link>
              ),
            },
            {
              name: 'รายได้อ้างอิง (SPAY)',
              render: ({ refSaleOrderPaymentInfo }) =>
                refSaleOrderPaymentInfo?.code ? (
                  <Typography color="secondary" variant="body2">
                    {refSaleOrderPaymentInfo.code}
                  </Typography>
                ) : (
                  <Typography color="textSecondary" variant="body2">
                    -
                  </Typography>
                ),
            },
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
            },
            {
              name: 'ชื่อลูกค้า',
              render: (documentInvoice) => documentInvoice.customerInfo?.name,
            },
            {
              name: 'ยอดที่ต้องชำระ',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
            },
            {
              name: '',
              render: (invoice) => {
                // TODO: support issued tax invoice
                const isCancelled = invoice.status.id === DocumentStatus.Cancelled
                if (isCancelled) {
                  return <></>
                }
                const isSelected = invoice.id === idToCreateTaxInvoice
                return (
                  <>
                    <DocumentCancellationDialog
                      documentInfo={invoice}
                      open={idToCancelId === invoice.id}
                      onClose={() => {
                        setIdToCancel(undefined)
                      }}
                      onSuccess={(updatedData) => {
                        triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
                        reload()
                        let newData = data
                        const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                        if (findIndex !== -1) {
                          const found = newData[findIndex]
                          const updated = {
                            ...found,
                            ...updatedData,
                          }
                          newData[findIndex] = updated
                          setData([...newData])
                        }
                      }}
                      onFail={() => {
                        triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
                      }}
                    />
                    {isSelected && (
                      <TaxInvoiceCreationDialog
                        invoice={invoice}
                        open={isSelected}
                        onClose={() => {
                          setIdToCreateTaxInvoice(undefined)
                        }}
                      />
                    )}
                    <FlexBox gridGap={8}>
                      {writePermission.hasPermission && (
                        <Tooltip title="สร้างใบกำกับภาษี" placement="top">
                          <span>
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => setIdToCreateTaxInvoice(invoice.id)}
                            >
                              <Description fontSize="small" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                      {cancelPermission.hasPermission && (
                        <IconButton size="small" onClick={() => setIdToCancel(invoice.id)}>
                          <Cancel fontSize="small" />
                        </IconButton>
                      )}
                    </FlexBox>
                  </>
                )
              },
            },
          ]}
          data={data}
          filters={[
            {
              name: 'สถานะใบกำกับภาษี',
              values: documentStatuses,
              filter: (data: DocumentInvoice, values: (string | number)[]) => values.includes(data.status.id),
            },
          ]}
          defaultDateRage={{
            startDate: defaultStartDate,
            endDate: defaultEndDate,
          }}
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(DocumentInvoicesPage)
