import { errorHandler } from '../../middleware'
import { FetchRequest } from '../types'
import smartAuthAPI from '../smartbestbuys-api'
import { format } from 'date-fns'

export const fetchSalesReportAPI = errorHandler<SalesReport>((request: FetchRequest) => {
  return smartAuthAPI.get('/sale-orders/report', {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
})

export interface SalesReport {
  data: SalesData[]
  successRate: number
  saleOrdersTotal: number
  canceledSaleOrderTotal: number
  saleOrderAmount: number
  salesTotalAverage: number
  canceledRate: number
  canceledMessagesWithCount: SOCanceledMessageWithAmount[]
  paymentTransactions: SalesPaymentTransaction[]
  creditCustomerPercentage: number
  taxInvoiceCanceledPercentage: number
  newCustomerSaleAmount: number
  newCustomerSaleTotal: number
  oldCustomerSaleTotal: number
  debtTotal: number
  latedDebtTotal: number
  productCategories: ProductCategoryStatistic[]
}

export interface SalesData {
  total: number
  createdAt: Date
}

export interface SalesPaymentTransaction {
  id: number
  statusId: number
  paymentMethodId: number
  amount: number
  createdAt: Date
}

export interface SOCanceledMessageWithAmount {
  name: string
  amount: number
}

export interface ProductCategoryStatistic {
  id: number
  name: string
  total: number
  percentage: number
}
