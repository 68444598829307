import { S3Client, PutObjectCommand, PutObjectCommandInput, PutObjectCommandOutput } from '@aws-sdk/client-s3'
import FileType from 'file-type'

export const uploadPublicFile = async (base64: string, key: string): Promise<string | undefined> => {
  const REGION = process.env.REACT_APP_REGION
  const BUCKET = process.env.REACT_APP_BUCKET
  const URL = process.env.REACT_APP_URL
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_S3_CLIENT_ACCESS_KEY_ID ?? '',
      secretAccessKey: process.env.REACT_APP_S3_CLIENT_SECRET_ACCESS_KEY ?? '',
    },
  })

  try {
    let cleanBase64 = base64
    if (cleanBase64.indexOf(',') !== -1) {
      cleanBase64 = cleanBase64.split(',')[1]
    }

    const buffer = Buffer.from(cleanBase64, 'base64')
    const fileType = await FileType.fromBuffer(buffer)
    const fileName = `${key}.${fileType?.ext}`
    const input: PutObjectCommandInput = {
      ACL: 'public-read',
      Bucket: BUCKET,
      Key: fileName,
      Body: buffer,
    }
    const response = await client.send<PutObjectCommandInput, PutObjectCommandOutput>(new PutObjectCommand(input))
    if (response.$metadata.httpStatusCode === 200) {
      return `${URL}/${fileName}`
    } else {
      return undefined
    }
  } catch (error) {
    console.log('s3Client api', error)
    throw error
  }
}
