import { Grid } from '@material-ui/core'
import { useContext, useState } from 'react'
import { TransportPickupTask } from '..'
import { ReceiveProductAction, TaskStatus, TaskType, WarehouseTaskType } from '../../../../enums'
import { Loading } from '../../../../shared-components'
import { CustomerRequisitionProfileContext } from '../CustomerRequisitionProfilePage'
import { ReceiveProductTableData } from './ReceiveProductTable'
import WarehouseReceiveProductTask from './WarehouseReceiveProductTask'

const PickupTaskList: React.FC = () => {
  const customerRequisitionProfile = useContext(CustomerRequisitionProfileContext)
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined)

  if (!customerRequisitionProfile) return <Loading />

  const { tasks } = customerRequisitionProfile

  const crReceivingTasks = tasks.filter((t) =>
    [TaskType.TransportPickupTask, TaskType.WaitingCustomerReturnProduct, TaskType.WaitingCustomerShipProduct].includes(
      t.type.id,
    ),
  )

  return (
    <Grid container spacing={2}>
      {crReceivingTasks.map((task, index) => {
        const isExpanded: boolean = activeIndex === index
        const handleExpand = () =>
          setActiveIndex((prev) => {
            if (isExpanded) return undefined
            return index
          })

        let productTableData
        if (task.type.id === TaskType.TransportPickupTask) {
          // task type: 3002
          const childrenTasks = tasks.filter((t) => t.parentTaskId === task.id)

          const receiveProductBackTask = childrenTasks.find((t) => t.type.id === WarehouseTaskType.ReceiveProductBack)

          const isSuccessReceiveProductBack =
            !!receiveProductBackTask && receiveProductBackTask.status.id === TaskStatus.Done

          productTableData =
            task?.state?.items?.map<ReceiveProductTableData>((item) => {
              const crProduct = customerRequisitionProfile.products.find((p) => p.productId === item.id)

              return {
                id: item.id,
                code: item.code,
                name: item.name,
                toReceiveAmount: item.amount,
                expectedReceiveAmount: item.amount,
                amount: item.amount,
                imageUrl: item.imageUrl,
                receivedAction: isSuccessReceiveProductBack ? ReceiveProductAction.All : undefined,
                receivedAmount: (isSuccessReceiveProductBack ? crProduct?.receivedAmount : 0) ?? 0,
              }
            }) ?? []

          return (
            <TransportPickupTask
              isExpanded={isExpanded}
              handleExpand={handleExpand}
              task={task}
              childrenTasks={childrenTasks}
              productTableData={productTableData}
            />
          )
        } else {
          // task type: 4005, 4006
          productTableData =
            customerRequisitionProfile.products.map<ReceiveProductTableData>((p) => {
              const { state } = task
              const result = state?.results?.find((r) => r.crProductId === p.productId)

              return {
                id: p.productId,
                code: p.productCode,
                name: p.productNameLocal,
                toReceiveAmount: result?.receivedAmount ?? 0,
                expectedReceiveAmount: result?.expectedAmount ?? p.amount - p.receivedAmount,
                amount: p.amount,
                imageUrl: p.productImageUrl,
                receivedAction: result?.action,
                receivedAmount: p.receivedAmount,
              }
            }) ?? []

          return (
            <WarehouseReceiveProductTask
              isExpanded={isExpanded}
              handleExpand={handleExpand}
              task={task}
              productTableData={productTableData}
            />
          )
        }
      })}
    </Grid>
  )
}

export default PickupTaskList
