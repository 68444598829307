import React, { useContext, useState } from 'react'
import { Grid } from '@material-ui/core'
import { BreadcumbsList, CustomButton, FlexBox, withLayout } from '../../../../../custom-components'
import { PageName } from '../../../../../enums'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'
import { RootContext } from '../../../../..'
import { useProductPreviewData } from '../../../../../hooks/product'
import { Loading } from '../../../../../shared-components'
import ProductReviewTableData from './ProductReviewTableData'
import { Cancel, CheckCircle } from '@material-ui/icons'
import ProductReviewApproveDialog from './ProductReviewApproveDialog'
import { useHistory } from 'react-router-dom'
import ProductReviewRejectDialog from './ProductReviewRejectDialog'

interface Props {
  id: number
}

const ProductReviewDataPage: React.FC<Props> = (props) => {
  const { id } = props
  const history = useHistory()
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const [reload, setReload] = useState(false)
  const previewData = useProductPreviewData(id, reload)

  const [approveOpen, setApproveOpen] = useState(false)
  const [rejectOpen, setRejectOpen] = useState(false)

  setCurrentPage(PageName.PRODUCT)

  if (!previewData) return <Loading />

  const breadcumbsItems = [
    { url: '/products', name: 'รายการสินค้าและบริการ' },
    { name: previewData?.rawData?.nameLocal },
  ]

  const reviewPermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Review)
  const isShowReviewButton =
    reviewPermission.hasPermission && previewData.rawData.requestChangedData && !previewData.rawData.recDeletedAt

  return (
    <Grid container spacing={2}>
      {isShowReviewButton && (
        <>
          <ProductReviewApproveDialog
            productId={id}
            open={approveOpen}
            onClose={() => setApproveOpen(false)}
            onComplete={() => {
              triggerSnackbar(`แก้ไขข้อมูลสินค้า สำเร็จ`)
              setReload((prev) => !prev)
              setApproveOpen(false)
              history.push(`/products/${id}/profile`)
            }}
            onFail={() => {
              triggerSnackbar(`แก้ไขข้อมูลสินค้า ไม่สำเร็จ`)
            }}
          />
          <ProductReviewRejectDialog
            productId={id}
            open={rejectOpen}
            onClose={() => setRejectOpen(false)}
            onComplete={() => {
              triggerSnackbar(`ปฏิเสธการแก้ไขข้อมูลสินค้า สำเร็จ`)
              setReload((prev) => !prev)
              setRejectOpen(false)
              history.push(`/products/${id}/profile`)
            }}
            onFail={() => {
              triggerSnackbar(`ปฏิเสธการแก้ไขข้อมูลสินค้า ไม่สำเร็จ`)
            }}
          />
        </>
      )}
      <Grid item xs={12}>
        <FlexBox justifyContent="space-between" alignItems="center">
          <BreadcumbsList items={breadcumbsItems} />
          {reviewPermission && (
            <div>
              <CustomButton
                variant="contained"
                color="success"
                style={{ marginRight: '4px' }}
                onClick={() => setApproveOpen(true)}
                startIcon={<CheckCircle fontSize="small" />}
              >
                อนุมัติ
              </CustomButton>
              <CustomButton
                variant="contained"
                color="error"
                onClick={() => setRejectOpen(true)}
                startIcon={<Cancel fontSize="small" />}
              >
                ไม่อนุมัติ
              </CustomButton>
            </div>
          )}
        </FlexBox>
      </Grid>
      <Grid item xs={12}>
        <ProductReviewTableData previewData={previewData} />
      </Grid>
    </Grid>
  )
}

export default withLayout(ProductReviewDataPage)
