import { SvgIcon } from '@material-ui/core'
import React from 'react'
import { CustomeIconProps } from '../interfaceProps'
import { ReactComponent as EmptyBoxSvg } from './emptyBox.svg'

const EmptyBoxIcon: React.FC<CustomeIconProps> = (props) => {
  return <SvgIcon component={EmptyBoxSvg} viewBox="0 0 26 25" {...props} />
}

export default EmptyBoxIcon
