import { UserPermission } from '../api/smartbestbuys-api'
import { PermissionEnum } from '../enums/PermissionEnum'
import { getPermission } from '../functions'

export interface PermissionInfo {
  hasPermission: boolean
  alertMessage: string
}

export const getUserPermissionInfo = (permission: PermissionEnum, mockPermission?: boolean): PermissionInfo => {
  // get from local storage
  const userPermission = getPermission()

  if (!userPermission)
    return {
      hasPermission: false,
      alertMessage: '!!!',
    }

  const permissionInfo = getPermissionWithAlertMessage(userPermission, permission)

  return {
    hasPermission: mockPermission ?? permissionInfo.hasPermission,
    alertMessage: permissionInfo.alertMessage,
  }
}

const getPermissionWithAlertMessage = (userPermission: UserPermission, permission: PermissionEnum): PermissionInfo => {
  try {
    switch (permission) {
      case PermissionEnum.HR_User_Read:
        return {
          hasPermission: userPermission.hr.user.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลผู้ใช้',
        }
      case PermissionEnum.HR_User_Write:
        return {
          hasPermission: userPermission.hr.user.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการผู้ใช้งาน',
        }
      case PermissionEnum.HR_User_Delete:
        return {
          hasPermission: userPermission.hr.user.delete,
          alertMessage: 'ไม่มีสิทธิ์ลบผู้ใช้',
        }
      case PermissionEnum.Warehouse_Product_Read:
        return {
          hasPermission: userPermission.warehouse.product.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลสินค้า',
        }
      case PermissionEnum.Warehouse_Product_Report:
        return {
          hasPermission: userPermission.warehouse.product.report,
          alertMessage: 'ไม่มีสิทธิ์ดูรายงานสินค้า',
        }
      case PermissionEnum.Warehouse_Product_Write:
        return {
          hasPermission: userPermission.warehouse.product.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลสินค้า',
        }
      case PermissionEnum.Warehouse_Product_Delete:
        return {
          hasPermission: userPermission.warehouse.product.delete,
          alertMessage: 'ไม่มีสิทธิ์ลบสินค้า',
        }
      case PermissionEnum.Warehouse_Product_StockManage:
        return {
          hasPermission: userPermission.warehouse.product.stockManage,
          alertMessage: 'ไม่มีสิทธิ์จัดการสต็อกสินค้า',
        }
      case PermissionEnum.Warehouse_Product_Review:
        return {
          hasPermission: userPermission.warehouse.product.review,
          alertMessage: 'ไม่มีสิทธิ์รีวิวข้อมูลสินค้า',
        }
      case PermissionEnum.Warehouse_Task_Read:
        return {
          hasPermission: userPermission.warehouse.task.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลงานคลัง',
        }
      case PermissionEnum.Warehouse_Task_Write:
        return {
          hasPermission: userPermission.warehouse.task.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลงานคลัง',
        }
      case PermissionEnum.Finance_Document_Read:
        return {
          hasPermission: userPermission.finance.document.read,
          alertMessage: 'ไม่มีสิทธิ์ดูรายการเอกสารการเงิน',
        }
      case PermissionEnum.Finance_Document_Write:
        return {
          hasPermission: userPermission.finance.document.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลเอกสารทางการเงิน',
        }
      case PermissionEnum.Finance_Document_Cancel:
        return {
          hasPermission: userPermission.finance.document.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกเอกสารทางการเงิน',
        }
      case PermissionEnum.Finance_SOPayment_Read:
        return {
          hasPermission: userPermission.finance.soPayment.read,
          alertMessage: 'ไม่มีสิทธิ์ดูรายการรายได้',
        }
      case PermissionEnum.Finance_SOPayment_Write:
        return {
          hasPermission: userPermission.finance.soPayment.write,
          alertMessage: 'ไม่มีสิทธิ์บันทึกการชำระเงิน',
        }
      case PermissionEnum.Finance_SOPayment_Review:
        return {
          hasPermission: userPermission.finance.soPayment.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบการชำระเงิน',
        }
      case PermissionEnum.Finance_SOPayment_VerifyStatement:
        return {
          hasPermission: userPermission.finance.soPayment.verifyStatement,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบ',
        }
      case PermissionEnum.Finance_SOPayment_Cancel:
        return {
          hasPermission: userPermission.finance.soPayment.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกรายการชำระเงิน',
        }
      case PermissionEnum.Finance_Task_Read:
        return {
          hasPermission: userPermission.finance.task.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลงานบัญชี',
        }
      case PermissionEnum.Finance_Task_Write:
        return {
          hasPermission: userPermission.finance.task.write,
          alertMessage: 'ไม่มีสิทธิ์ดูรายการเจ้าหนี้',
        }
      case PermissionEnum.FinancePay_PaymentRequisition_Read:
        return {
          hasPermission: userPermission.financePay.paymentRequisition.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลการทำเบิก',
        }
      case PermissionEnum.FinancePay_PaymentRequisition_Write:
        return {
          hasPermission: userPermission.financePay.paymentRequisition.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลการทำเบิก',
        }
      case PermissionEnum.FinancePay_PaymentRequisition_Cancel:
        return {
          hasPermission: userPermission.financePay.paymentRequisition.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกการทำเบิก',
        }
      case PermissionEnum.FinancePay_PaymentRequisition_Review:
        return {
          hasPermission: userPermission.financePay.paymentRequisition.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบการทำเบิก',
        }
      case PermissionEnum.FinancePay_RefundRequisition_Read:
        return {
          hasPermission: userPermission.financePay.refundRequisition.read,
          alertMessage: 'ไม่มีสิทธิ์ดูรายการขอคืนเงิน',
        }
      case PermissionEnum.FinancePay_RefundRequisition_Write:
        return {
          hasPermission: userPermission.financePay.refundRequisition.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลรายการขอคืนเงิน',
        }
      case PermissionEnum.FinancePay_RefundRequisition_Review:
        return {
          hasPermission: userPermission.financePay.refundRequisition.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบการขอคืนเงิน',
        }
      case PermissionEnum.FinancePay_RefundRequisition_Cancel:
        return {
          hasPermission: userPermission.financePay.refundRequisition.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกรายการขอคืนเงิน',
        }
      case PermissionEnum.Sales_Customer_Read:
        return {
          hasPermission: userPermission.sales.customer.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลลูกค้า',
        }
      case PermissionEnum.Sales_Customer_Write:
        return {
          hasPermission: userPermission.sales.customer.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลลูกค้า',
        }
      case PermissionEnum.Sales_Customer_Delete:
        return {
          hasPermission: userPermission.sales.customer.delete,
          alertMessage: 'ไม่มีสิทธิ์ลบลูกค้า',
        }
      case PermissionEnum.Sales_Customer_BlacklistManage:
        return {
          hasPermission: userPermission.sales.customer.blacklistManage,
          alertMessage: 'ไม่มีสิทธิ์จัดการลูกค้า',
        }
      case PermissionEnum.Sales_Customer_Update:
        return {
          hasPermission: userPermission.sales.customer.update,
          alertMessage: 'ไม่มีสิทธิ์แก้ไขลูกค้า',
        }
      case PermissionEnum.Sales_Quotation_Read:
        return {
          hasPermission: userPermission.sales.quotation.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลใบเสนอราคา',
        }
      case PermissionEnum.Sales_Quotation_Report:
        return {
          hasPermission: userPermission.sales.quotation.report,
          alertMessage: 'ไม่มีสิทธิ์ดูรายงานใบเสนอราคา',
        }
      case PermissionEnum.Sales_Quotation_Write:
        return {
          hasPermission: userPermission.sales.quotation.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลใบเสนอราคา',
        }
      case PermissionEnum.Sales_Quotation_Cancel:
        return {
          hasPermission: userPermission.sales.quotation.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกใบเสนอราคา',
        }
      case PermissionEnum.Sales_Quotation_ReviewData:
        return {
          hasPermission: userPermission.sales.quotation.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบใบเสนอราคา',
        }
      case PermissionEnum.Sales_SO_Read:
        return {
          hasPermission: userPermission.sales.saleOrder.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลใบสั่งขาย',
        }
      case PermissionEnum.Sales_SO_Report:
        return {
          hasPermission: userPermission.sales.saleOrder.report,
          alertMessage: 'ไม่มีสิทธิ์ดูรายงานใบสั่งขาย',
        }
      case PermissionEnum.Sales_SO_Pricing:
        return {
          hasPermission: userPermission.sales.saleOrder.pricing,
          alertMessage: 'ไม่มีสิทธิ์ดูราคาใบสั่งขาย',
        }
      case PermissionEnum.Sales_SO_Write:
        return {
          hasPermission: userPermission.sales.saleOrder.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลใบสั่งขาย',
        }
      case PermissionEnum.Sales_SO_Cancel:
        return {
          hasPermission: userPermission.sales.saleOrder.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกใบสั่งขาย',
        }
      case PermissionEnum.Sales_Request_To_Cancel:
        return {
          hasPermission: userPermission.sales.saleOrder.requestToCancel,
          alertMessage: 'ไม่มีสิทธิ์อนุมัติยกเลิกใบสั่งขาย',
        }
      case PermissionEnum.Sales_GoalAligntment_Read:
        return {
          hasPermission: userPermission.sales.salesGoalAlignment.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลเป้าการขาย',
        }
      case PermissionEnum.Sales_GoalAligntment_Write:
        return {
          hasPermission: userPermission.sales.salesGoalAlignment.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการเป้าการขาย',
        }
      case PermissionEnum.Sales_Profit_Read:
        return {
          hasPermission: userPermission.sales.saleProfit.read,
          alertMessage: 'ไม่มีสิทธิ์ดูกำไร',
        }
      case PermissionEnum.Purchase_PR_Read:
        return {
          hasPermission: userPermission.purchase.purchaseRequisition.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลคำขอซื้อ',
        }
      case PermissionEnum.Purchase_PR_Write:
        return {
          hasPermission: userPermission.purchase.purchaseRequisition.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลคำขอซื้อ',
        }
      case PermissionEnum.Purchase_PR_Cancel:
        return {
          hasPermission: userPermission.purchase.purchaseRequisition.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกคำขอซื้อ',
        }
      case PermissionEnum.Purchase_PR_Review:
        return {
          hasPermission: userPermission.purchase.purchaseRequisition.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบคำขอซื้อ',
        }
      case PermissionEnum.Purchase_PO_Read:
        return {
          hasPermission: userPermission.purchase.purchaseOrder.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลใบสั่งซื้อ',
        }
      case PermissionEnum.Purchase_PO_Pricing:
        return {
          hasPermission: userPermission.purchase.purchaseOrder.pricing,
          alertMessage: 'ไม่มีสิทธิ์ดูราคาใบสั่งซื้อ',
        }
      case PermissionEnum.Purchase_PO_Write:
        return {
          hasPermission: userPermission.purchase.purchaseOrder.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลใบสั่งซื้อ',
        }
      case PermissionEnum.Purchase_PO_Cancel:
        return {
          hasPermission: userPermission.purchase.purchaseOrder.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกใบสั่งซื้อ',
        }
      case PermissionEnum.Purchase_PO_Review:
        return {
          hasPermission: userPermission.purchase.purchaseOrder.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบใบสั่งซื้อ',
        }
      case PermissionEnum.Purchase_PO_CallLeader:
        return {
          hasPermission: userPermission.purchase.purchaseOrder.callLeader,
          alertMessage: 'ไม่มีสิทธิ์แจ้งหัวหน้าให้จัดการใบสั่งซื้อ',
        }
      case PermissionEnum.Purchase_PO_MarkUnCancelable:
        return {
          hasPermission: userPermission.purchase.purchaseOrder.markUnCancelable,
          alertMessage: 'ไม่มีสิทธิ์บันทึกว่าผู้ขายไม่ให้ยกเลิก',
        }
      case PermissionEnum.Purchase_Vendor_Read:
        return {
          hasPermission: userPermission.purchase.vendor.read,
          alertMessage: 'ไม่มีสิทธิ์ดูรายการผู้ขาย',
        }
      case PermissionEnum.Purchase_Vendor_Write:
        return {
          hasPermission: userPermission.purchase.vendor.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลผู้ขาย',
        }
      case PermissionEnum.Purchase_Vendor_Review:
        return {
          hasPermission: userPermission.purchase.vendor.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบผู้ขาย',
        }
      case PermissionEnum.Purchase_Vendor_Delete:
        return {
          hasPermission: userPermission.purchase.vendor.delete,
          alertMessage: 'ไม่มีสิทธิ์ลบผู้ขาย',
        }
      case PermissionEnum.Purchase_Task_Read:
        return {
          hasPermission: userPermission.purchase.task.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลงานจัดซื้อ',
        }
      case PermissionEnum.Purchase_Task_Write:
        return {
          hasPermission: userPermission.purchase.task.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลงานจัดซื้อ',
        }
      case PermissionEnum.Service_TransportTask_Read:
        return {
          hasPermission: userPermission.service.transportTask.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลงานจัดส่ง',
        }
      case PermissionEnum.Service_TransportTask_Report:
        return {
          hasPermission: userPermission.service.transportTask.report,
          alertMessage: 'ไม่มีสิทธิ์ดูรายงานงานจัดส่ง',
        }
      case PermissionEnum.Service_TransportTask_Write:
        return {
          hasPermission: userPermission.service.transportTask.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลงานจัดส่ง',
        }
      case PermissionEnum.Service_TransportTask_Cancel:
        return {
          hasPermission: userPermission.service.transportTask.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกข้อมูลงานจัดส่ง',
        }
      case PermissionEnum.Service_CustomerRequisition_Read:
        return {
          hasPermission: userPermission.service.customerRequisition.read,
          alertMessage: 'ไม่มีสิทธิ์ดูข้อมูลคำขอลูกค้า',
        }
      case PermissionEnum.Service_CustomerRequisition_Write:
        return {
          hasPermission: userPermission.service.customerRequisition.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลคำขอลูกค้า',
        }
      case PermissionEnum.Service_CustomerRequisition_Review:
        return {
          hasPermission: userPermission.service.customerRequisition.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบคำขอลูกค้า',
        }
      case PermissionEnum.Service_CustomerRequisition_Cancel:
        return {
          hasPermission: userPermission.service.customerRequisition.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกคำขอลูกค้า',
        }
      case PermissionEnum.Marketing_Sales_Report:
        return {
          hasPermission: userPermission.marketing.sales.report,
          alertMessage: 'ไม่มีสิทธิ์ดูรายงานการขาย',
        }
      case PermissionEnum.Marketing_Product_Report:
        return {
          hasPermission: userPermission.marketing.product.report,
          alertMessage: 'ไม่มีสิทธิ์ดูรายงานสินค้า',
        }
      case PermissionEnum.Warehouse_WithdrawalTicket_Read:
        return {
          hasPermission: userPermission.warehouse.withdrawal.read,
          alertMessage: 'ไม่มีสิทธิ์ดูคำขอเบิก',
        }
      case PermissionEnum.Warehouse_WithdrawalTicket_Write:
        return {
          hasPermission: userPermission.warehouse.withdrawal.write,
          alertMessage: 'ไม่มีสิทธิ์จัดการข้อมูลคำขอเบิกสินค้า',
        }
      case PermissionEnum.Warehouse_WithdrawalTicket_Cancel:
        return {
          hasPermission: userPermission.warehouse.withdrawal.cancel,
          alertMessage: 'ไม่มีสิทธิ์ยกเลิกคำขอเบิกสินค้า',
        }
      case PermissionEnum.Warehouse_WithdrawalTicket_Review:
        return {
          hasPermission: userPermission.warehouse.withdrawal.review,
          alertMessage: 'ไม่มีสิทธิ์ตรวจสอบคำขอเบิกสินค้า',
        }
      case PermissionEnum.Warehouse_WithdrawalTicket_ForcedClose:
        return {
          hasPermission: userPermission.warehouse.withdrawal.forcedClose,
          alertMessage: 'ไม่มีสิทธิ์บังคับปิดคำขอเบิกสินค้า',
        }
      default:
        return {
          hasPermission: false,
          alertMessage: '',
        }
    }
  } catch (error) {
    return {
      hasPermission: true,
      alertMessage: '',
    }
  }
}
