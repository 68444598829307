import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useQuotationsProductModalStyle = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(8),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(8, 0),
            },
        },
        grid: {
            maxWidth: theme.breakpoints.values.md,
            height: 'fit-content',
            background: 'white',
            borderRadius: 4,
            padding: theme.spacing(2, 3),
        },
        avatar: {
            width: 120,
            height: 120,
        },
    }),
)
