import { UserAction } from '../../../../../enums'
import { SOFollowUpFormValues } from './types'
import {
  createSOUserActivity as createSOUserActivityAPI,
  cancelSaleOrderAPI,
  UserActivityRequest,
  UserActivityInfoRequest,
} from '../../../../../api/smartbestbuys-api'

export const createSOUserActivity = (saleOrderId: number, userAction: UserAction, values: SOFollowUpFormValues) => {
  const request: UserActivityRequest = {
    actionId: userAction,
    ...values,
  }

  return createSOUserActivityAPI(saleOrderId, request)
}

export const cancelSaleOrder = (saleOrderId: number, values: SOFollowUpFormValues) => {
  const { actionMessageId, ...others } = values
  const request: UserActivityInfoRequest = {
    actionMessageId: actionMessageId === -1 ? undefined : actionMessageId,
    ...others,
  }
  return cancelSaleOrderAPI(saleOrderId, request)
}
