import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ICustomerProfile_Quotation } from '../../../../../../api/smartbestbuys-api'
import { Link, Table } from '../../../../../../custom-components'
import { dateTimeFormat } from '../../../../../../functions'
import { QuotationStatusChip } from '../../../../../../shared-components'

const renderCustomerQuotationTable = (quotations?: ICustomerProfile_Quotation[]) => {
  return (
    <Table<ICustomerProfile_Quotation>
      size="small"
      color="primary.dark"
      columns={[
        {
          name: 'สถานะใบเสนอราคา',
          render: ({ status }) => <QuotationStatusChip status={status.id} label={status.name} />,
        },
        {
          name: 'เลขที่ใบเสนอราคา',
          render: ({ id, code }) => (
            <Box color="primary.dark">
              <Link color="inherit" href={`/quotations/${id}/profile`} target="_blank">
                <Typography variant="body2">{code}</Typography>
              </Link>
            </Box>
          ),
        },
        {
          name: 'วันที่สร้าง',
          render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
        },
        {
          name: 'วิธีชำระเงิน',
          render: ({ paymentMethod }) => paymentMethod.name,
        },
        {
          name: 'ยอดรวมสุทธิ',
          numeric: true,
          format: '0,0.00',
          dataIndex: 'total',
        },
      ]}
      data={quotations || []}
      cleanSpace
      pagination
    />
  )
}

export default renderCustomerQuotationTable
