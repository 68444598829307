import React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'

// TODO: we need to set theme for this case, refactor all code then we can remove this
const GeneralTextField: React.FC<TextFieldProps> = (props) => (
  <TextField
    fullWidth
    variant="outlined"
    color="primary"
    margin="dense"
    size="small"
    inputProps={{ key: props.key, ...props.inputProps }}
    {...props}
  />
)

export default GeneralTextField
