import React from 'react'
import { Grid, GridProps } from '@material-ui/core'
import { gridStyles } from './styles'

interface Props {
  row: number
}

const GridBox: React.FC<Props & GridProps> = (props) => {
  const { row, children, ...gridProps } = props
  const classNames = gridStyles(row)()
  return (
    <Grid className={classNames.grid1} {...gridProps}>
      {children}
    </Grid>
  )
}

export default GridBox
