import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { Avatar, FlexBox, SubTopic, Table, TypographyWithLabel } from '../../../../custom-components'
import { dateFormat } from '../../../../functions'
import { Loading } from '../../../../shared-components'
import { usePWTickProfileContext } from './PWTicketProfilePageContextProvider'
import { WithdrawalTicketStatus } from '../../../../enums'
import { formatNumberInt } from '../../../../functions/number-utils'
import { IProductWithdrawalTicketProduct } from '../../../../api/smartbestbuys-api/warehouse'
import { Photo } from '@material-ui/icons'
import { profilePageText } from './pw-ticket-profile-page-setting'
import { useContext, useState } from 'react'
import { PWTicketApproveDialog, PWTicketRejectDialog } from './Dialogs'
import { RootContext } from '../../../..'

const PWTicketProfilePageDataBox = () => {
  const { profile, pageSetting, updateData } = usePWTickProfileContext()
  const { triggerSnackbar } = useContext(RootContext)

  // state
  const [openApproveDialog, setOpenApproveDialog] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)

  if (!profile) return <Loading />

  const { recCreatedBy, requestedDate, estimatedReturnedDate, actionMessage, remark, products, status } = profile

  const {
    dataBox: { detail, productTable },
  } = profilePageText

  const { reviewActionBox } = pageSetting

  return (
    <>
      <PWTicketApproveDialog
        profile={profile}
        open={openApproveDialog}
        onClose={() => setOpenApproveDialog(false)}
        onSuccess={(updated) => {
          updateData(updated)
          triggerSnackbar('คำขอเบิกสินค้าถูกอนุมัติแล้ว')
          setOpenApproveDialog(false)
        }}
        onFail={() => {
          triggerSnackbar('ล้มเหลว')
          setOpenApproveDialog(false)
        }}
      />
      <PWTicketRejectDialog
        profile={profile}
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        onSuccess={(updated) => {
          updateData(updated)
          triggerSnackbar('ไม่อนุมัติคำขอเบิกสินค้าแล้ว')
          setOpenRejectDialog(false)
        }}
        onFail={() => {
          triggerSnackbar('ล้มเหลว')
          setOpenRejectDialog(false)
        }}
      />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>{detail.title}</SubTopic>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TypographyWithLabel label={detail.requester}>{recCreatedBy}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TypographyWithLabel label={detail.requestedDate}>{dateFormat(requestedDate)}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TypographyWithLabel label={detail.estimatedReturnedDate}>
              {estimatedReturnedDate ? dateFormat(estimatedReturnedDate) : '-'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TypographyWithLabel label={detail.withdrawalReason}>
              <span style={{ color: 'red' }}>{actionMessage.name}</span>
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TypographyWithLabel label={detail.remark}>
              <span style={{ color: remark ? 'red' : undefined }}>{remark ?? '-'}</span>
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12}>
            <SubTopic>{productTable.title}</SubTopic>
            <div className="mb-4" />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Table<IProductWithdrawalTicketProduct>
                  color="primary.dark"
                  size="small"
                  columns={[
                    {
                      name: '',
                      fixedWidth: 20,
                      render: (_, index) => index + 1,
                    },
                    {
                      name: '',
                      fixedWidth: 80,
                      dataIndex: 'imageUrl',
                      render: ({ imageUrl, nameLocal }) => (
                        <Avatar variant="rounded" src={imageUrl} alt={nameLocal} style={{ width: 80, height: 80 }}>
                          <Photo />
                        </Avatar>
                      ),
                    },
                    {
                      name: productTable.header.productDetail,
                      render: ({ code, nameLocal }) => (
                        <div>
                          <TypographyWithLabel label={productTable.detail.code}>{code}</TypographyWithLabel>
                          <TypographyWithLabel label={productTable.detail.nameLocal}>{nameLocal}</TypographyWithLabel>
                        </div>
                      ),
                    },
                    {
                      name: productTable.header.withdrawnAmount,
                      dataIndex: 'amount',
                      fixedWidth: 150,
                      numeric: true,
                      format: '0,0',
                    },
                    {
                      name: productTable.header.returnedAmount,
                      dataIndex: 'returnedAmount',
                      fixedWidth: 150,
                      numeric: true,
                      render: ({ amount, returnedAmount }) => (
                        <Typography
                          style={{
                            color: [WithdrawalTicketStatus.InProgress, WithdrawalTicketStatus.Done].includes(status.id)
                              ? amount === returnedAmount
                                ? '#3B873E'
                                : '#E31B0C'
                              : undefined,
                          }}
                        >
                          {formatNumberInt(returnedAmount)}
                        </Typography>
                      ),
                    },
                  ]}
                  data={products}
                  cleanSpace
                />
              </Grid>
            </Grid>
          </Grid>

          {!reviewActionBox.hidden && (
            <Grid item xs={12}>
              <FlexBox justifyContent="flex-end" gridGap={8}>
                <Button
                  variant="contained"
                  size="large"
                  style={{ backgroundColor: '#4CAF50', color: 'white' }}
                  onClick={() => setOpenApproveDialog(true)}
                >
                  อนุมัติ
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{ backgroundColor: '#f44336', color: 'white' }}
                  onClick={() => setOpenRejectDialog(true)}
                >
                  ไม่อนุมัติ
                </Button>
              </FlexBox>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default PWTicketProfilePageDataBox
