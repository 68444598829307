import { useState, useContext } from 'react'
import { Typography, Box } from '@material-ui/core'
import { BarDatum } from '@nivo/bar'
import { GridBox, Loading } from '../../../shared-components'
import { Bar, Statistic, NivoColorPalette, TableData, ProgressBar } from '../../../visaulization-components'
import DashboardContext from '../DashboardContext'
import _ from 'lodash'
import { format } from 'date-fns'
import { BarDateGroupFilter } from '../../../visaulization-components/Bar/Bar'
import thLocale from 'date-fns/locale/th'
import { roundNumber } from '../../../functions/number-utils'
import { ProductCategoryStatistic } from '../../../api/smartbestbuys-api'

interface SellTotalItem extends BarDatum {
  total: number
  t: string
}

interface Temp {
  name: string
  amount: number
}

export const SalesTotalBarGraph = () => {
  const { salesReport } = useContext(DashboardContext)
  const [dateGroupFilter, setDateGroupFilter] = useState<BarDateGroupFilter>(BarDateGroupFilter.Monthly)

  if (!salesReport)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { data } = salesReport
  const mappedData = _.chain(data)
    .groupBy((d) => format(new Date(d.createdAt), dateGroupFilter, { locale: thLocale }))
    .map((values, key) => {
      return {
        total: values.reduce<number>((sum, each) => sum + roundNumber(each.total), 0),
        t: key,
      }
    })
    .value()
  return (
    <GridBox item xs={12} row={12}>
      <Bar<SellTotalItem>
        title="รายงานยอดขาย"
        keys={['total']}
        colors={[NivoColorPalette.yellow]}
        indexBy="t"
        data={mappedData.slice(mappedData.length - 12, mappedData.length)}
        yLegend="ยอดขาย"
        textMapping={{
          total: 'ยอดรวมสุทธิ',
        }}
        onDateGroupFilterChange={setDateGroupFilter}
      />
    </GridBox>
  )
}

export const ProductSubCategoryPercentageReportTable = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { productCategories } = salesReport
  return (
    <GridBox item xs={12} md={8} row={13}>
      <TableData<ProductCategoryStatistic>
        title="อัตราส่วนยอดขายต่อกลุ่มสินค้า"
        columns={[
          {
            title: 'ชื่อกลุ่มสินค้า',
            key: 'name',
          },
          {
            title: 'อัตราส่วนยอดขาย',
            key: 'percentage',
            render: (data) => `${roundNumber(data.percentage)}%`,
          },
        ]}
        data={productCategories}
      />
    </GridBox>
  )
}

export const NewCustomerStat = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={12} row={1}>
        <Loading />
      </GridBox>
    )
  const { newCustomerSaleAmount, data } = salesReport
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic
        title="จำนวนลูกค้าใหม่"
        amount={newCustomerSaleAmount}
        subtitle={
          <Typography>
            <span>จากลูกค้าทั้งหมด </span>
            <Box component="span" color={NivoColorPalette.intenseOrange}>
              {data.length}
            </Box>
            <span> ราย</span>
          </Typography>
        }
        color={NivoColorPalette.oceanGreen}
      />
    </GridBox>
  )
}

export const ReturnedRateProgressBar = () => (
  <GridBox item xs={12} row={6}>
    <ProgressBar
      title="อัตราส่วนลูกค้ากลับมาซื้อซ้ำ"
      amount={75}
      color={NivoColorPalette.orange}
      rowSize={6}
      unit="%"
      progress={75}
    />
  </GridBox>
)

export const SaleOrderSuccessRateBarGraph = () => (
  <GridBox item xs={12} row={12}>
    <Bar<SellTotalItem>
      title="รายงานอัตราความสำเร็จของใบบันทึกคำสั่งซื้อ"
      keys={['total']}
      colors={[NivoColorPalette.intenseOrange]}
      indexBy="t"
      data={[
        {
          total: 100000,
          t: '05/21',
        },
        {
          total: 20000,
          t: '06/21',
        },
      ]}
      yLegend="ยอดขาย"
      textMapping={{
        total: 'ยอดรวมสุทธิ',
      }}
    />
  </GridBox>
)

export const SaleOrderSuccessRateProgressBar = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={6} md={3} row={6}>
        <Loading />
      </GridBox>
    )
  const { successRate } = salesReport
  const rounded = roundNumber(successRate)
  return (
    <GridBox item xs={6} md={3} row={6}>
      <ProgressBar
        title="อัตราความสำเร็จของใบสั่งขาย"
        amount={rounded}
        color={NivoColorPalette.oceanGreen}
        rowSize={5}
        unit="%"
        progress={rounded}
      />
    </GridBox>
  )
}

export const SaleOrderTotalAmountStat = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={6} md={3} row={6}>
        <Loading />
      </GridBox>
    )
  const { saleOrdersTotal } = salesReport
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic
        title="ยอดรวมของใบสั่งขาย"
        amount={saleOrdersTotal}
        format="0,0.00"
        subtitle="บาท"
        color={NivoColorPalette.orange}
      />
    </GridBox>
  )
}

export const SaleOrderAmountStat = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={6} md={3} row={6}>
        <Loading />
      </GridBox>
    )
  const { saleOrderAmount } = salesReport
  return (
    <GridBox item xs={6} md={3} row={6}>
      <Statistic
        title="จำนวนใบสั่งขาย"
        amount={saleOrderAmount}
        format="0,0"
        subtitle="บาท"
        color={NivoColorPalette.intenseOrange}
      />
    </GridBox>
  )
}

export const SaleOrderCancellationRateProgressBar = () => {
  const { salesReport } = useContext(DashboardContext)
  if (!salesReport)
    return (
      <GridBox item xs={6} md={3} row={6}>
        <Loading />
      </GridBox>
    )
  const { canceledRate } = salesReport
  const rounded = roundNumber(canceledRate)

  return (
    <GridBox item xs={6} md={3} row={6}>
      <ProgressBar
        title="อัตราการยกเลิกใบสั่งขาย"
        amount={rounded}
        color={NivoColorPalette.intenseOrange}
        rowSize={5}
        unit="%"
        progress={rounded}
      />
    </GridBox>
  )
}

export const SaleOrderCancellationReportTable = () => (
  <GridBox item xs={12} row={12}>
    <TableData<Temp>
      color={NivoColorPalette.intenseOrange}
      title="รายการเหตุผลที่ยกเลิกใบสั่งขาย"
      columns={[
        {
          key: 'name',
          title: 'เหตุผล',
        },
        {
          key: 'amount',
          title: 'จำนวนที่ยกเลิก',
        },
        {
          key: 'amount',
          title: 'คิดเป็น %',
        },
      ]}
      data={[]}
    />
  </GridBox>
)
