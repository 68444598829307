import React, { useState } from 'react'
import { Paper, Typography, Chip, Button } from '@material-ui/core'
import { Avatar } from '../../../../custom-components'
import { Photo } from '@material-ui/icons'
import numeral from 'numeral'
import { useFormContext } from 'react-hook-form'
import { QuotationCreateData_Product } from '../../../../api/smartbestbuys-api'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import { useQuotationsProductCardStyle } from './useQuotationsProductCardStyle'
import QuotationsProductModal from './QuotationsProductModal'

interface QuotationsProductCardProps {
  product: QuotationCreateData_Product
  selectedAmount?: number
  isDealer?: boolean
}

const QuotationsProductCard: React.FC<QuotationsProductCardProps> = (props) => {
  const { getValues, setValue } = useFormContext<QuotationsCreateValue>()
  const { selectedProducts } = getValues()

  const styles = useQuotationsProductCardStyle()
  const [open, setOpen] = useState(false)

  const { product, selectedAmount, isDealer } = props
  const { id, nameLocal, code, stockAmount, unitLocal, imageUrl, reservedAmount } = product
  const isSelected = selectedAmount && selectedAmount > 0

  const handleClick = () => {
    if (isSelected) {
      let newSelectedProducts = selectedProducts
      const index = newSelectedProducts.findIndex((p) => p.id === id)
      newSelectedProducts.splice(index, 1)
      setValue('selectedProducts', newSelectedProducts)
    } else {
      setOpen(true)
    }
  }

  return (
    <>
      <Paper className={styles.root}>
        <Typography variant="body2">{code}</Typography>
        <div className={styles.image}>
          <Avatar variant="rounded" className={styles.avatar} src={imageUrl} objectFit="contain">
            <Photo style={{ fontSize: 120 }} />
          </Avatar>
          {isSelected && (
            <Chip
              className={styles.chip}
              size="small"
              color="secondary"
              label={`เลือกแล้ว ${selectedAmount} ${unitLocal || ''}`}
            />
          )}
        </div>
        <Typography variant="body2" className={styles.productName}>
          {nameLocal}
        </Typography>
        <Typography className={styles.stockAmount} variant="caption">
          พร้อมขาย {numeral(stockAmount - reservedAmount).format('0,0')} {unitLocal || 'ชิ้น'}
        </Typography>
        <Button variant={isSelected ? 'outlined' : 'contained'} size="small" color="primary" onClick={handleClick}>
          {isSelected ? 'นำออกจากใบเสนอราคา' : 'เลือกเข้าใบเสนอราคา'}
        </Button>
      </Paper>
      {open && (
        <QuotationsProductModal open={open} onClose={() => setOpen(false)} product={product} isDealer={isDealer} />
      )}
    </>
  )
}

export default QuotationsProductCard
