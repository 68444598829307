import { Box, Typography } from '@material-ui/core'
import { CreditNoteState, ICustomerProfile_Document } from '../../../../../../../api/smartbestbuys-api'
import { Link, TColumn } from '../../../../../../../custom-components'
import { dateTimeFormat } from '../../../../../../../functions'
import { DocumentStatusChip } from '../../../../../../../shared-components'

const creditNoteDocumentColumns: TColumn<ICustomerProfile_Document<CreditNoteState>>[] = [
  {
    name: 'สถานะใบลดหนี้',
    render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
  },
  {
    name: 'เลขใบลดหนี้',
    render: ({ id, code }) => (
      <Box color="primary.dark">
        <Link color="inherit" to={`/credit-notes/${id}/pdf`} target="_blank">
          {code}
        </Link>
      </Box>
    ),
  },
  {
    name: 'เลขใบกำกับภาษีอ้างอิง',
    render: ({ referenceDocument, state }) => {
      const refs = state?.taxInvoiceReferences || []
      if (referenceDocument) {
        return (
          <Box color="secondary.dark">
            <Link color="inherit" to={`/tax-invoices/${referenceDocument.id}/pdf`} target="_blank">
              {referenceDocument.code}
            </Link>
          </Box>
        )
      }
      if (refs.length > 0) {
        return (
          <Box color="secondary.dark">
            <Typography variant="body2">{refs.join(',')}</Typography>
          </Box>
        )
      }
      return (
        <Typography variant="body2" color="textSecondary">
          สร้างจากใบสั่งขาย
        </Typography>
      )
    },
  },
  {
    name: 'ใบสั่งขายอ้างอิง',
    render: ({ saleOrderId, saleOrderCode }) => (
      <Box color="secondary.dark">
        <Link color="inherit" to={`/sale-orders/${saleOrderId}/profile`} target="_blank">
          {saleOrderCode}
        </Link>
      </Box>
    ),
  },
  {
    name: 'วันที่สร้าง',
    render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
  },
  {
    name: 'ยอดเงินสุทธิ',
    numeric: true,
    format: '0,0.00',
    dataIndex: 'total',
  },
] as TColumn<ICustomerProfile_Document<CreditNoteState>>[]

export default creditNoteDocumentColumns
