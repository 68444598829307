/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { FlexBox, Topic, withLayout } from '../../../custom-components'
import { RootContext } from '../../..'
import { NavigationItemKey, PageName } from '../../../enums'
import { ConditionTooltip, Loading, NotificationHead } from '../../../shared-components'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { contactChannels } from '../../../types'
import { add, endOfMonth, format, startOfMonth } from 'date-fns'
import {
  ISalesGoalDaily,
  SalesGoalDailyRequest,
  SetSalesGoalDailyRequestBody,
  getSalesGoalDailyAPI,
  setSalesGoalDailyAPI,
} from '../../../api/smartbestbuys-api'
import { Edit } from '@material-ui/icons'
import EditSalesGoalAlignmentDialog from './EditSalesGoalAlignmentDialog'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

interface ISalesGoalAlignment {
  id?: number
  contactChannelId: number
  salesGoal: number
}

interface ISalesGoalDailyForm {
  date: Date
  salesGoalByChannel: ISalesGoalAlignment[]
}

export interface IEditSalesGoal {
  date: Date
  contactChannelId: number
  salesGoal: number
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  cell: {
    minWidth: '130px',
  },
  loading: {
    position: 'absolute',
    zIndex: 5,
    opacity: '30%',
    backgroundColor: 'whitesmoke',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const SalesGoalAlignmentPage: React.FC = (props) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const classes = useTableStyles()

  setCurrentPage(PageName.SALES_GOAL_ALIGNMENT)

  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [editSalesGoal, setEditSalesGoal] = useState<IEditSalesGoal | null>(null)
  const [salesGoalDailyData, setSalesGoalDailyData] = useState<ISalesGoalDailyForm[]>([])
  const [openEditSalesGoalDialog, setOpenEditSalesGoalDialog] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (selectedDate) {
        const response = await getSalesGoalDailyAPI({
          startDate: format(startOfMonth(selectedDate), 'yyyy-MM-dd'),
          endDate: format(endOfMonth(selectedDate), 'yyyy-MM-dd'),
        })

        setSalesGoalDailyData(handleGetSalesGoalDaily(selectedDate, response?.data ?? []))
      }
    })()
  }, [selectedDate, reload])

  const handleDateChange = async (date: Date | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  const handleGetSalesGoalDaily = (date: Date, salesGoal: ISalesGoalDaily[]): ISalesGoalDailyForm[] => {
    let salesGoalSetting: ISalesGoalDailyForm[] = []

    let startDate = startOfMonth(date)
    const endDate = endOfMonth(date)

    while (endDate >= startDate) {
      salesGoalSetting.push({
        date: new Date(startDate),
        salesGoalByChannel: contactChannels.map((cc) => {
          const salesGoalData = salesGoal.find(
            (sg) =>
              format(new Date(sg.salesGoalDate), 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd') &&
              sg.contactChannel.id === cc.id,
          )

          return {
            id: salesGoalData?.id ?? undefined,
            contactChannelId: cc.id,
            salesGoal: salesGoalData?.salesGoal ?? 10000,
          }
        }),
      })

      startDate = add(startDate, {
        days: +1,
      })
    }

    return salesGoalSetting
  }

  const handleSubmitSalesGoalAlignmentForm = async () => {
    setIsSaving(true)
    const data: SetSalesGoalDailyRequestBody = {
      requests: salesGoalDailyData.reduce<SalesGoalDailyRequest[]>((salesGoal, currentSalesGoal) => {
        const salesGoalByDate = currentSalesGoal.salesGoalByChannel.map((row) => ({
          id: row?.id,
          salesGoalDate: format(currentSalesGoal.date, 'yyyy-MM-dd'),
          contactChannelId: row.contactChannelId,
          salesGoal: row.salesGoal,
        }))
        return [...salesGoal, ...salesGoalByDate]
      }, []),
    }

    const response = await setSalesGoalDailyAPI(data)
    if (response?.status === 201) {
      triggerSnackbar('ตั้งค่าเป้าการขายสำเร็จ')
      setReload((prev) => !prev)
      setIsSaving(false)
    } else {
      triggerSnackbar('ตั้งค่าเป้าการขายไม่สำเร็จ')
      setIsSaving(false)
    }
  }

  if (!selectedDate) return <Loading />

  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_GoalAligntment_Write)

  return (
    <Paper className={classes.root}>
      {isSaving && (
        <Card className={classes.loading}>
          <CircularProgress color="secondary" />

          <Typography color="secondary">กรุณารอสักครู่...</Typography>
        </Card>
      )}
      {openEditSalesGoalDialog && (
        <EditSalesGoalAlignmentDialog
          open={openEditSalesGoalDialog}
          onSuccess={(salesGoal) => {
            const salesGoalData = editSalesGoal as IEditSalesGoal
            const salesGoalDaily = salesGoalDailyData.map((sgd) => {
              if (sgd.date === salesGoalData.date) {
                return {
                  date: sgd.date,
                  salesGoalByChannel: sgd.salesGoalByChannel.map((sgc) => {
                    if (sgc.contactChannelId === salesGoalData.contactChannelId) {
                      return {
                        ...sgc,
                        salesGoal,
                      }
                    } else {
                      return sgc
                    }
                  }),
                }
              } else {
                return sgd
              }
            })
            setSalesGoalDailyData(salesGoalDaily)
            setEditSalesGoal(null)
            setOpenEditSalesGoalDialog(false)
            setIsSaving(false)
          }}
          onClose={() => {
            setEditSalesGoal(null)
            setOpenEditSalesGoalDialog(false)
            setIsSaving(false)
          }}
          salesGoalData={editSalesGoal as IEditSalesGoal}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>ตั้งเป้าการขาย</Topic>
        </Grid>
        <Grid item xs={12}>
          <NotificationHead navigationKey={NavigationItemKey.SalesGoalAlignment} />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="yyyy/MM"
              views={['year', 'month']}
              margin="normal"
              id="date-picker-inline"
              label="ช่วงที่ต้องการตั้งเป้าการขาย"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.cell}>
                    {' '}
                    วันที่{' '}
                  </TableCell>
                  {contactChannels.map((cc) => (
                    <TableCell align="center" className={classes.cell}>
                      {cc.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {salesGoalDailyData.map((row) => (
                  <TableRow key={`${row.date}`}>
                    <TableCell align="center" className={classes.cell}>
                      {format(row.date, 'dd/MM/yyyy')}
                    </TableCell>
                    {row.salesGoalByChannel.map((c) => (
                      <TableCell align="center" className={classes.cell}>
                        <FlexBox alignItems="center" justifyContent="center">
                          <div className="mr-1">{c.salesGoal}</div>
                          <ConditionTooltip
                            title={writePermission.alertMessage}
                            showTooltip={!writePermission.hasPermission}
                          >
                            <IconButton
                              style={{ padding: '0px' }}
                              size="small"
                              color="secondary"
                              disabled={!writePermission.hasPermission || isSaving}
                              onClick={() => {
                                setEditSalesGoal({
                                  date: row.date,
                                  contactChannelId: c.contactChannelId,
                                  salesGoal: c.salesGoal,
                                })
                                setOpenEditSalesGoalDialog(true)
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </ConditionTooltip>
                        </FlexBox>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={11}></Grid>
        <Grid item xs={1}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!writePermission.hasPermission || isSaving}
            onClick={handleSubmitSalesGoalAlignmentForm}
          >
            ยืนยันข้อมูล
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withLayout(SalesGoalAlignmentPage, true)
