import { useEffect, useState } from 'react'
import { SingleWarehouseTask } from '../../api/smartbestbuys-api/warehouse/warehouse-types'
import { getWarehouseTask } from '../../api/smartbestbuys-api/warehouse/warehouse-apis'

export const useWarehouseTask = (id: number) => {
  const [data, setData] = useState<SingleWarehouseTask | undefined>(undefined)

  const _getWarehouseTask = async (id: number) => {
    const response = await getWarehouseTask(id)
    setData(response?.data)
  }

  useEffect(() => {
    _getWarehouseTask(id)
  }, [id])

  return data
}
