import React, { FC } from 'react';
import ReactPDF, { View, StyleSheet } from '@react-pdf/renderer';

interface Props {
  bottomLine?: boolean
  topLine?: boolean
}

const PDFTableRow: FC<ReactPDF.ViewProps & Props> = (props) => {
  const { bottomLine, topLine, ...viewProps } = props;

  const styles = StyleSheet.create({
    td: {
      display: 'flex',
      flexDirection: 'row',
      borderBottomWidth: bottomLine ? 1 : undefined,
      borderBottomColor: bottomLine ? '#6A6969' : undefined,
      borderTopWidth: topLine ? 1 : undefined,
      borderTopColor: topLine ? '#6A6969' : undefined,
    }
  })

  const { style, ...otherProps } = viewProps;
  return (
    <View style={{
      ...styles.td,
      ...style,
    }} {...otherProps} />
  );
}

export default PDFTableRow;