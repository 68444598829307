import { useState, useEffect } from 'react'
import { fetchProducts, ProductDataRow } from '../api/smartbestbuys-api'

export const useProducts = (): ProductDataRow[] => {
  const [products, setProducts] = useState<ProductDataRow[]>([])

  const _fetchProducts = async () => {
    const response = await fetchProducts()
    setProducts(response?.data || [])
  }

  useEffect(() => {
    _fetchProducts()
  }, [])

  return products
}
