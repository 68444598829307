import { EmptyCard, withLayout } from '../../../custom-components'
import { Grid, Button } from '@material-ui/core'
import { Topic, FlexBox } from '../../../custom-components'
import { Add } from '@material-ui/icons'
import UserCard from './UserCard'
import { useUsers } from '../../../hooks/user'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ConditionTooltip, Space } from '../../../shared-components'
import Filter, { FilterValues } from '../../../custom-components/Filter/Filter'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

interface UserListFilterValues extends FilterValues {
  enabled: boolean[]
}

const UserListPage: React.FC = () => {
  const history = useHistory()

  const [filterValues, setFilterValues] = useState<UserListFilterValues | undefined>(undefined)

  // hooks
  const users = useUsers()

  const filteredUsers = useMemo(
    () => {
      if (!filterValues || filterValues.enabled.length === 0) {
        return users
      }
      return users.filter((user) => filterValues.enabled.includes(user.enabled))
    }, // eslint-disable-next-line
    [users.length, JSON.stringify(filterValues)],
  )

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.HR_User_Write)

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการผู้ใช้งานในระบบ</Topic>
            <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => history.push('/users/create')}
                disabled={!writePermission.hasPermission}
              >
                เพิ่มผู้ใช้งาน
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
      </Grid>
      <Space />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Filter<UserListFilterValues>
            options={[
              {
                label: 'สถานะผู้ใช้',
                key: 'enabled',
                values: [
                  { label: 'เปิดใช้งานอยู่', value: true },
                  { label: 'ปิดใช้งานอยู่', value: false },
                ],
              },
            ]}
            onValueChange={(value) => setFilterValues(value)}
          />
        </Grid>
      </Grid>
      <Space />
      <Grid container spacing={4} item xs={12}>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user) => <UserCard key={user.id} user={user} />)
        ) : (
          <Grid item xs={12} key="empty card">
            <EmptyCard>ไม่มีบัญชีผู้ใช้</EmptyCard>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default withLayout(UserListPage)
