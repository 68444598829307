import { Grid } from '@material-ui/core'
import { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { createPurchaseOrderApi } from '../../../api/smartbestbuys-api'

import { Topic } from '../../../custom-components'
import { ConfirmationDialog, SubmitForm } from '../../../shared-components'
import { AttachmentSelectionBox } from '../../purchase-requisitions/create/AttachmentSelectionBox'
import { mapPurchaseOrderToCreationFormValues } from './mapper'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'
import { PurchaseOrderCreatePageContext } from './PurchaseOrderCreatePage'
import PurchaseRequisitionInfoBox from './PurchaseRequisitionInfoBox/PurchaseRequisitionInfoBox'
import PurchaseOrderInformationBox from './PurchaseOrderInformationBox'
import PurchaseOrderVendorBox from './PurchaseOrderVendor'
import SelectedPurchaseOrderProductInfoTable from './SelectedPurchaseOrderProductTable/SelectedPurchaseOrderProductInfoTable'

interface Props {
  onPreviousPage: () => void
  purchaseRequisitionId: number
}

const PurchaseOrderVerification: React.FC<Props> = (props: Props) => {
  // props
  const { onPreviousPage, purchaseRequisitionId } = props

  // state
  const [warningOpened, setWarningOpened] = useState(false)

  // url
  const history = useHistory()

  // context
  const { purchaseOrderCreate } = useContext(PurchaseOrderCreatePageContext)
  const { triggerSnackbar } = useContext(RootContext)
  const { purchaseRequisitionInfo } = purchaseOrderCreate
  const { type, saleOrderInfo } = purchaseRequisitionInfo

  // hook form
  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<PurchaseOrderCreateFormValues>()

  // data
  const values = watch()
  const { attachments, products } = values

  // handles
  const handleSubmit = async () => {
    const request = mapPurchaseOrderToCreationFormValues(
      values,
      purchaseRequisitionId,
      type.id,
      products,
      saleOrderInfo,
    )

    const response = await createPurchaseOrderApi(request)
    if (response?.status === 201) {
      triggerSnackbar('สร้างใบสั่งซื้อสำเร็จ')
      history.replace(`/purchase-orders/${response.data.id}/profile`)
    } else {
      triggerSnackbar('สร้างใบสั่งซื้อไม่สำเร็จ')
    }
    return true
  }

  return (
    <>
      <ConfirmationDialog
        open={warningOpened}
        onClose={() => setWarningOpened(false)}
        title="ยืนยันยอมให้สินค้า/บริการ ที่จะซื้อราคาเกินจากราคาขาย"
        textOk="ยืนยัน"
        onOk={handleSubmit}
        disabledCloseOnDim
        isSubmitting={isSubmitting}
      >
        มีสินค้า/บริการ ราคาเกินจากราคาขาย คุณแน่ใจที่จะสร้างใบสั่งซื้อใช่หรือไม่?
      </ConfirmationDialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>ตรวจทานคำขอซื้อสำหรับซื้อสินค้า</Topic>
        </Grid>
        <Grid item xs={12}>
          <PurchaseRequisitionInfoBox />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrderVendorBox readonly />
        </Grid>
        <Grid item xs={12}>
          <SelectedPurchaseOrderProductInfoTable />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrderInformationBox />
        </Grid>
        <Grid item xs={12}>
          <AttachmentSelectionBox readonly defaultValue={attachments} />
        </Grid>
        <Grid item xs={12}>
          <SubmitForm
            submitText="ยืนยันสร้าง"
            cancelText="ย้อนกลับไปแก้ไขข้อมูล"
            isSubmitting={isSubmitting}
            onCancel={onPreviousPage}
            onSubmit={() => {
              const foundOverPrice = !!values.products?.find((p) => !!p.salePrice && p.salePrice < p.price)

              if (foundOverPrice) {
                setWarningOpened(true)
              } else {
                handleSubmit()
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PurchaseOrderVerification
