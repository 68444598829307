import { Tooltip, TooltipProps } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import './InformationTooltip.less'

export const InformationTooltip: React.FC<Omit<TooltipProps, 'children'>> = (props) => {
  return (
    <Tooltip {...props}>
      <Info className="information-icon" />
    </Tooltip>
  )
}
