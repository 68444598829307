import React, { useContext } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { FlexBox, GeneralAutoComplete, SubTopic } from '../../../custom-components'
import { TransportTaskCreatePageContext } from './TransportTaskCreatePage'
import { useFormContext } from 'react-hook-form'
import TransportTaskCreateFormValues from './TransportTaskCreateFormValues'

const TransportTaskAssigneeUser: React.FC = () => {
  // context
  const { transportTaskCreateData } = useContext(TransportTaskCreatePageContext)
  const { users } = transportTaskCreateData

  const { control } = useFormContext<TransportTaskCreateFormValues>()

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ผู้รับผิดชอบงาน</SubTopic>
        <div className="mb-4" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <GeneralAutoComplete
              control={control}
              renderInput={(params) => params}
              name="assigneeUserId"
              label="ผู้รับผิดชอบงาน *"
              rules={{ required: 'เลือกผู้รับผิดชอบงาน' }}
              options={users.map((user) => {
                return {
                  label: user?.firstName + ' ' + user?.lastName,
                  value: user.id,
                }
              })}
            ></GeneralAutoComplete>
          </Grid>
        </Grid>
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskAssigneeUser
