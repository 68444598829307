import { useState, useEffect } from 'react'
import { fetchPendingPaymentPurchaseOrders, PendingPaymentPurchaseOrder } from '../../api/smartbestbuys-api'

export const usePendingPaymentPurchaseOrders = (): PendingPaymentPurchaseOrder[] => {
  const [data, setData] = useState<PendingPaymentPurchaseOrder[]>([])

  const _fetchPendingPaymentPurchaseOrders = async () => {
    const response = await fetchPendingPaymentPurchaseOrders()
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchPendingPaymentPurchaseOrders()
  }, [])

  return data
}
