import { FormProvider, useForm } from 'react-hook-form'
import { PermissionGroup, PermissionTopic } from '../../../api/smartbestbuys-api'
import { Form, Grid } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'
import UserPermissionGroupBox from '../UserPermissionGroupBox'

interface UserPermissionUpdateFormValues {
  permissionGroupIds: number[]
  permissionIds: number[]
}

interface Props {
  permissionGroups: PermissionGroup[]
  permissionTopics: PermissionTopic[]
  selectedPermissionGroupIds: number[]
  selectedPermissionIds: number[]
  onPermissionGroupSelect: (permissionGroupIds: number[]) => void
  onPermissionSelect: (permissionIds: number[]) => void
  allPermissionIdInSelectedGroup: number[]
  onSubmit: (values: UserPermissionUpdateFormValues) => void
  onCancel: () => void
}

const UserPermissionUpdateForm = (props: Props) => {
  const {
    permissionGroups,
    permissionTopics,
    selectedPermissionGroupIds,
    selectedPermissionIds,
    onPermissionGroupSelect,
    onPermissionSelect,
    allPermissionIdInSelectedGroup,
    onSubmit,
    onCancel,
  } = props

  const formMethods = useForm<UserPermissionUpdateFormValues>({
    defaultValues: {
      permissionGroupIds: selectedPermissionGroupIds,
      permissionIds: selectedPermissionIds,
    },
  })

  const {
    formState: { isSubmitting },
  } = formMethods

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={(values) => onSubmit(values)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserPermissionGroupBox
              permissionGroups={permissionGroups}
              permissionTopics={permissionTopics}
              selectedPermissionGroupIds={selectedPermissionGroupIds}
              selectedPermissionIds={selectedPermissionIds}
              onPermissionGroupSelect={onPermissionGroupSelect}
              onPermissionSelect={onPermissionSelect}
              allPermissionIdInSelectedGroup={allPermissionIdInSelectedGroup}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitForm
              submitText="ยืนยันการแก้ไข"
              cancelText="ย้อนกลับ"
              disableSubmit={isSubmitting}
              onCancel={onCancel}
            />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default UserPermissionUpdateForm
