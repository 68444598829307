import React, { useContext, useEffect, useMemo } from 'react'
import { NestedValue, useForm } from 'react-hook-form'
import {
  Grid,
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import SaleOrderProfileContext from '../../SaleOrderProfileContext'
import { CustomerProfileCard, DocumentStatusChip } from '../../../../../../shared-components'
import { GeneralTextFieldForm, Table, Form } from '../../../../../../custom-components'
import {
  SaleOrderDocument,
  SaleOrderProduct,
  SaleOrderProfile,
  TaxInvoiceItem,
  createTaxInvoice as createTaxInvoiceAPI,
  TaxInvoiceCreationRequest,
  SOPaymentTransaction,
} from '../../../../../../api/smartbestbuys-api'
import { getTaxInvoiceItems, getTaxInvoicePriceBreakdowns, mapToItem } from './service'
import numeral from 'numeral'
import { RootContext } from '../../../../../..'
import { dateTimeFormat } from '../../../../../../functions'
import { DocumentStatus, DocumentType, SOPaymentTransactionStatus } from '../../../../../../enums'
import _ from 'lodash'

export interface TaxInvoiceCreationFormValues {
  taxInvoiceCreationMethod?: number
  amount: number
  depositAmount: number
  percentAmount?: number
  remark?: string
  products: NestedValue<SaleOrderProduct[]>
  soPaymentTransactionId?: number
}

interface Props extends DialogProps {
  saleOrderProfile: SaleOrderProfile
  refInvoice?: SaleOrderDocument<any>
  onSuccess: () => void
}

const TaxInvoiceCreationDialog: React.FC<Props> = (props) => {
  const { saleOrderProfile, refInvoice, onClose, onSuccess, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)
  const { customerProfile, reload } = useContext(SaleOrderProfileContext)
  const {
    control,
    watch,
    formState: { isSubmitting, errors },
    setError,
    clearErrors,
    handleSubmit,
    reset,
  } = useForm<TaxInvoiceCreationFormValues>({
    defaultValues: {
      depositAmount: 0,
      products: saleOrderProfile.products,
    },
  })

  const { products: productInputs } = watch()
  const { documents, total, paymentTransactions } = saleOrderProfile

  const availablePaymentTransactions =
    paymentTransactions?.filter((row: SOPaymentTransaction) =>
      [SOPaymentTransactionStatus.PENDING, SOPaymentTransactionStatus.APPROVED].includes(row.status.id),
    ) ?? []

  const taxInvoices = documents?.filter((d) => d.type.id === DocumentType.TaxInvoice) || []
  const availableTaxInvoices = taxInvoices.filter((d) => d.status.id !== DocumentStatus.Cancelled)

  const [title, isFromInvoice, ableToCreateTaxInvoice] = useMemo(() => {
    const totalOfTaxInvoices = availableTaxInvoices.reduce<number>(
      (sum, taxinvoice) => sum + (taxinvoice.total ?? 0),
      0,
    )

    const validCreditNotes =
      documents?.filter((d) => d.type.id === DocumentType.CreditNote && d.status.id !== DocumentStatus.Cancelled) ?? []

    const totalOfCreditNotes = validCreditNotes.reduce<number>((sum, creditNote) => sum + (creditNote.total ?? 0), 0)

    const ableToCreateTaxInvoice = !availableTaxInvoices.length || totalOfTaxInvoices - totalOfCreditNotes < total

    if (!ableToCreateTaxInvoice) {
      return ['สร้างใบกำกับภาษีครบแล้ว', !!refInvoice, ableToCreateTaxInvoice]
    }
    return [
      !!refInvoice ? `สร้างใบกำกับภาษีจากใบแจ้งหนี้ ${refInvoice.code}` : 'สร้างกำกับภาษี',
      !!refInvoice,
      ableToCreateTaxInvoice,
    ]
  }, [availableTaxInvoices, documents, refInvoice, total])

  const productTotal = useMemo(() => {
    return !isFromInvoice
      ? productInputs.reduce<number>((sum, product) => sum + product.amount * product.price, 0)
      : undefined
  }, [isFromInvoice, productInputs])

  useEffect(() => {
    const { products, documents } = saleOrderProfile
    const validCreditNotes =
      documents?.filter((d) => d.type.id === DocumentType.CreditNote && d.status.id !== DocumentStatus.Cancelled) ?? []

    const taxInvoiceCodesInCreditNote = validCreditNotes.reduce<string[]>((taxInvoiceCodes, each) => {
      const refs = each.state?.taxInvoiceReferences || []
      const referenceDocument = each.referenceDocument

      return [...taxInvoiceCodes, ...refs, referenceDocument].filter((id) => id !== undefined)
    }, [])

    const validTaxInvoices =
      documents?.filter(
        (d) =>
          d.type.id === DocumentType.TaxInvoice &&
          !d.referenceDocument &&
          d.status.id !== DocumentStatus.Cancelled &&
          !taxInvoiceCodesInCreditNote.includes(d.code),
      ) ?? []

    if (validTaxInvoices.length === 0) return

    const productsWithRemainingAmount = validTaxInvoices.reduce<SaleOrderProduct[]>(
      (currentProducts, taxInvoice) => {
        const documentProducts = taxInvoice.state?.items ?? []

        documentProducts.forEach((dp: { id: number; amount: number }) => {
          const index = currentProducts.findIndex((cp) => cp.productId === dp.id)
          if (index > -1) {
            const _currentAmount = currentProducts[index].amountInDocument ?? 0
            currentProducts[index].amountInDocument = _currentAmount + dp.amount
          }
        })

        return currentProducts
      },
      products.map((p) => {
        p.amountInDocument = 0
        return p
      }),
    )

    reset({
      products: productsWithRemainingAmount.map((p) => {
        return {
          ...p,
          amount: p.amount - (p.amountInDocument ?? 0),
        }
      }),
    })
  }, [reset, saleOrderProfile])

  const handleSubmitInvoiceForm = async (values: TaxInvoiceCreationFormValues) => {
    // all product couldn't be zero

    if (!isFromInvoice && values.products.every((p) => Number(p.amount) === 0)) {
      setError('products', { type: 'required', message: 'ไม่สามารถกรอกสินค้าเป็น 0 ทุกตัว' })
      return
    }

    const priceBreakdowns = getTaxInvoicePriceBreakdowns(saleOrderProfile, refInvoice, productTotal).map((p) => ({
      label: p.label,
      amount: p.amount,
    }))

    const request: TaxInvoiceCreationRequest = {
      invoiceId: refInvoice?.id,
      customerId: saleOrderProfile.customer.id,
      creditPeriodDay: saleOrderProfile.customer.creditPeriodDay,
      total: _.last(priceBreakdowns)?.amount || 0,
      items: !isFromInvoice
        ? values.products.filter((p) => p.amount > 0).map(mapToItem)
        : getTaxInvoiceItems(saleOrderProfile, refInvoice),
      priceBreakdowns,
      soPaymentTransactionId: values.soPaymentTransactionId,
      remark: values.remark,
    }

    const response = await createTaxInvoiceAPI(saleOrderProfile.id, request)
    if (response?.status === 201) {
      triggerSnackbar('สร้างใบกำกับภาษีสำเร็จ')
      reload()
      onSuccess()
    } else {
      triggerSnackbar('สร้างใบกำกับภาษีไม่สำเร็จ')
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitInvoiceForm)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {ableToCreateTaxInvoice ? (
              <>
                <Grid item xs={12}>
                  <CustomerProfileCard
                    customerProfile={customerProfile}
                    billingAddressId={saleOrderProfile.billingAddress?.id}
                    disableBtnCustomerDetail
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <GeneralTextFieldForm
                        control={control}
                        name="soPaymentTransactionId"
                        label="รายได้อ้างอิง (SPAY)"
                        select
                        disabled={availablePaymentTransactions.length === 0}
                        helperText={availablePaymentTransactions.length === 0 ? 'ไม่พบการชำระเงิน' : undefined}
                      >
                        {availablePaymentTransactions.map((element) => (
                          <MenuItem key={element.id} value={element.id}>
                            {element.code}
                          </MenuItem>
                        ))}
                      </GeneralTextFieldForm>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" />
                    </Grid>
                    <Grid item xs={12}>
                      <Table<{ id: number } & TaxInvoiceItem>
                        color="primary.dark"
                        size="small"
                        columns={[
                          {
                            name: 'ลำดับ',
                            render: (_, index) => index + 1,
                          },
                          { name: 'ชื่อสินค้า', dataIndex: 'name' },
                          {
                            name: 'ราคาต่อหน่วย',
                            fixedWidth: 180,
                            numeric: true,
                            format: '0,0.00',
                            dataIndex: 'pricePerEach',
                          },
                          {
                            name: 'จำนวน',
                            fixedWidth: 200,
                            numeric: isFromInvoice,
                            align: 'center',
                            format: isFromInvoice ? '0,0' : undefined,
                            dataIndex: 'amount',
                            render: (item, index) => {
                              if (isFromInvoice) return item.amount
                              const _saleProduct = saleOrderProfile.products.find((p) => p.productId === item.id)
                              const remainingAmount = item.amount - (_saleProduct?.amountInDocument ?? 0)
                              return (
                                <GeneralTextFieldForm
                                  control={control}
                                  type="number"
                                  name={`products.${index}.amount`}
                                  margin="none"
                                  rules={{
                                    min: { value: 0, message: 'จำนวนไม่สามารถติด -' },
                                    max: { value: remainingAmount, message: 'จำนวนเกิน' },
                                    required: 'กรุณากรอกจำนวนสินค้า',
                                  }}
                                  onPostChange={() => {
                                    if (errors.products) clearErrors('products')
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <Typography color="textSecondary" variant="body1">
                                        ({numeral(remainingAmount).format('0,0')})
                                      </Typography>
                                    ),
                                  }}
                                />
                              )
                            },
                          },
                          {
                            name: 'ราคารวม',
                            fixedWidth: 180,
                            numeric: true,
                            format: '0,0.00',
                            dataIndex: 'total',
                            render: (item) => {
                              if (isFromInvoice) return item.amount * item.pricePerEach
                              const targetProduct = productInputs.find((input) => input.productId === item.id)
                              return (targetProduct?.amount ?? 0) * item.pricePerEach
                            },
                          },
                        ]}
                        cleanSpace
                        pagination={false}
                        data={getTaxInvoiceItems(saleOrderProfile, refInvoice) as any}
                        postCells={
                          saleOrderProfile && (
                            <>
                              {getTaxInvoicePriceBreakdowns(saleOrderProfile, refInvoice, productTotal).map((price) => {
                                const { label, amount } = price
                                return (
                                  <TableRow key={label}>
                                    <TableCell colSpan={3} />
                                    <TableCell align="right">{label}</TableCell>
                                    <TableCell align="right">{numeral(amount).format('0,0.00')}</TableCell>
                                  </TableRow>
                                )
                              })}
                            </>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Alert severity="error">ใบกำกับภาษีทั้งหมดของใบสั่งขายนี้ถูกสร้างครบแล้ว</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2">ประวัติใบกำกับภาษีจากใบสั่งขาย {saleOrderProfile.code}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table<SaleOrderDocument<any>>
                    size="small"
                    color="primary.dark"
                    columns={[
                      {
                        name: 'สถานะใบกำกับภาษี',
                        render: ({ status }) => <DocumentStatusChip status={status.id} label={status.name} />,
                      },
                      {
                        name: 'เลขใบกำกับภาษี',
                        dataIndex: 'code',
                        render: (doc) => `${doc.code} ${doc.referenceDocument ? '(ใบมัดจำ)' : ''}`,
                      },
                      { name: 'วันที่สร้าง', render: (invoice) => dateTimeFormat(invoice.recCreatedAt) },
                      { name: 'ยอดที่ต้องชำระ', numeric: true, format: '0,0.00', dataIndex: 'total' },
                    ]}
                    data={taxInvoices}
                    cleanSpace
                    pagination={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!!errors.products && (
              <Grid item xs={12} container justify="flex-end">
                <Typography color="error" variant="body2" align="right">
                  {errors.products.message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {ableToCreateTaxInvoice ? (
          <DialogActions>
            <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
              ยกเลิก
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={isSubmitting || !!errors.products}>
              ยืนยันข้อมูล
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button color="primary" variant="contained" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
              ตกลง
            </Button>
          </DialogActions>
        )}
      </Form>
    </Dialog>
  )
}

export default TaxInvoiceCreationDialog
