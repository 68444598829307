export enum NivoColorPalette {
  default = '#E8C1A0',
  softOrange = '#E8C1A0',
  intenseOrange = '#F47560',
  orange = '#E8A838',
  yellow = '#F1E15B',
  oceanGreen = '#61CDBB',
  softOceanGreen = '#97E3D5',
}

export const colorSet = [
  NivoColorPalette.softOrange,
  NivoColorPalette.intenseOrange,
  NivoColorPalette.orange,
  NivoColorPalette.yellow,
  NivoColorPalette.oceanGreen,
  NivoColorPalette.softOceanGreen,
]
