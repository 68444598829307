import { setPermission, setToken, setUser } from '../../functions'
import { smartLogin } from './service'
import { FormikHelpers } from 'formik'
import * as yup from 'yup'

export interface LoginForm {
  username: string
  password: string
}

export const initialValues: LoginForm = {
  username: '',
  password: '',
}

export const validationSchema = yup.object().shape({
  username: yup.string().required('กรุณาใส่ชื่อผู้ใช้'),
  password: yup.string().required('กรุณาใส่รหัสผ่าน'),
})

export const onSubmit = async (values: LoginForm, formik: FormikHelpers<LoginForm>) => {
  const { setFieldError } = formik
  const { username, password } = values

  const loginResponse = await smartLogin(username, password)

  if (loginResponse?.token) {
    setToken(loginResponse?.token)
    setUser(loginResponse?.user)
    setPermission(loginResponse?.permission)

    window.location.href = '/'
    return
  }
  setFieldError('password', 'รหัสผิดพลาด')
}
