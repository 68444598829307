import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { ProductGrade } from '../../enums'

interface Props {
  grade: ProductGrade
  icon?: boolean
}

const useStyles = (grade: ProductGrade) =>
  makeStyles(() => {
    const chipColors = {
      [ProductGrade.A]: '#EDB343',
      [ProductGrade.B]: '#D1D1D1 ',
      [ProductGrade.C]: '#AD9573',
    }

    return createStyles({
      chip: {
        background: chipColors[grade],
        fontWeight: 'bold',
        fontSize: '12px',
        height: 'auto',
      },
    })
  })

const ProductGradeChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ children, grade, icon, ...othersProps }) => {
  const classes = useStyles(grade)()

  return <Chip className={classes.chip} label={grade} {...othersProps} />
}

export default ProductGradeChip
