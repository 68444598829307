import React from 'react'
import { Fab, Zoom, useScrollTrigger } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons'

interface Props {
  window?: () => Window
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 100,
    },
  }),
)

const ScrollToTop: React.FC<Props> = (props) => {
  const { window } = props
  const classes = useStyles()

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const goToTop = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#top')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={goToTop} role="presentation" className={classes.root}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  )
}

export default ScrollToTop
