import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, TextField } from '@material-ui/core'
import { IEditSalesGoal } from './SalesGoalAlignmentPage'
import { contactChannels } from '../../../types'
import { format } from 'date-fns'
import { useState } from 'react'

interface Props extends DialogProps {
  salesGoalData: IEditSalesGoal
  onSuccess: (salesGoal: number) => void
}

const EditSalesGoalAlignmentDialog: React.FC<Props> = (props) => {
  const { salesGoalData, onSuccess, onClose, ...otherProps } = props
  const [salesGoal, setSalesGoal] = useState<number>(salesGoalData.salesGoal)

  const contactChannel = contactChannels.find((f) => f.id === salesGoalData.contactChannelId)

  return (
    <Dialog fullWidth {...otherProps}>
      <DialogTitle>
        ตั้งค่าเป้าการขาย วันที่ {format(salesGoalData.date, 'dd/MM/yyyy')} ช่องทาง {contactChannel?.name}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="เป้าการขาย"
          type="number"
          value={salesGoal}
          onChange={(e) => {
            setSalesGoal(Number(e.target.value))
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
          ยกเลิก
        </Button>
        <Button type="submit" color="primary" variant="contained" onClick={() => onSuccess(salesGoal)}>
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditSalesGoalAlignmentDialog
