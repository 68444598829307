import { useFormContext } from 'react-hook-form'
import { PWTicketFormValues } from './PWTicketFormValues'
import {
  GeneralAutoComplete,
  GeneralTextFieldForm,
  KeyboardDatePickerForm,
  SubTopic,
} from '../../../../custom-components'
import { Grid, Paper } from '@material-ui/core'
import { IGeneralAutoComplete } from '../../../../custom-components/TextField/GeneralAutoComplete'

interface Props {
  actionMessageOptions: IGeneralAutoComplete[]
}

const PWTicketFormDetail: React.FC<Props> = ({ actionMessageOptions }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<PWTicketFormValues>()

  const { actionMessageId } = watch()

  const defaultActionMessage = actionMessageOptions.find((opt) => opt.value === actionMessageId)

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>รายละเอียดคำขอเบิก</SubTopic>
        </Grid>
        <Grid item xs={12} sm={6}>
          <GeneralAutoComplete
            id="action-message-id-selector"
            control={control}
            name="actionMessageId"
            options={actionMessageOptions}
            renderInput={(params) => params}
            rules={{ required: 'กรุณาเลือกเหตุผล' }}
            label="เลือกเหตุผลสำหรับคำขอเบิก *"
            defaultValue={defaultActionMessage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyboardDatePickerForm
            control={control}
            label="วันที่ต้องการสินค้า *"
            name="requestedDate"
            format="dd/MM/yyyy"
            value={null}
            rules={{ required: { value: true, message: 'กรุณาเลือกวันที่ต้องการสินค้า' } }}
            defaultValue={null}
            error={!!errors?.requestedDate}
            helperText={errors.requestedDate?.message}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyboardDatePickerForm
            control={control}
            label="วันที่คาดว่าจะคืนสินค้า (ถ้ามี)"
            name="estimatedReturnedDate"
            value={null}
            defaultValue={null}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PWTicketFormDetail
