import { useEffect, useState } from 'react'
import { CustomerRequisition, fetchCustomerRequisitionsAPI } from '../api/smartbestbuys-api'

export const useCustomerRequisitions = (): CustomerRequisition[] => {
  const [customerRequisitions, setCustomerRequisitions] = useState<CustomerRequisition[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await fetchCustomerRequisitionsAPI()
      const data = response?.data || []
      setCustomerRequisitions(data)
    })()
  }, [customerRequisitions.length])

  return customerRequisitions
}
