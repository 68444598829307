import { useEffect, useState } from 'react'
import { getSaleOrderShippingPending, SaleOrdersShippingPendingRowData } from '../api/smartbestbuys-api'

export const useSaleOrdersShippingPending = (): SaleOrdersShippingPendingRowData[] => {
  const [saleOrdersShippingPending, setSaleOrdersShippingPending] = useState<SaleOrdersShippingPendingRowData[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await getSaleOrderShippingPending()
      setSaleOrdersShippingPending(response?.data || [])
    })()
  }, [])

  return saleOrdersShippingPending
}
