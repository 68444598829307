import React, { useState, useRef } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Grid, Modal, Backdrop, Fade, Typography, TextField, Button, InputAdornment } from '@material-ui/core'
import numeral from 'numeral'
import { Photo } from '@material-ui/icons'
import { ProductPrice, QuotationCreateData_Product } from '../../../../api/smartbestbuys-api'
import { useQuotationsProductModalStyle } from './useQuotationsProductModalStyle'
import { useProductsPricing } from '../../../../hooks'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import { SelectedProduct } from '../../../../sales-system/types'
import { Avatar, FlexBox, Table, Title } from '../../../../custom-components'

interface QuotationsProductModalProps {
  open: boolean
  onClose: () => void
  product: QuotationCreateData_Product | SelectedProduct
  isDealer?: boolean
}

//TODO: refactor
const QuotationsProductModal: React.FC<QuotationsProductModalProps> = (props) => {
  const { product, onClose, isDealer } = props
  const { id, unitLocal, stockAmount, dealerPrice } = product
  const { amount: initAmount, price: initPrice } = product as SelectedProduct
  const { reservedAmount } = product as QuotationCreateData_Product
  const styles = useQuotationsProductModalStyle()
  const pricing = useProductsPricing(id)
  const priceInputRef = useRef<HTMLInputElement>()

  const { control, setValue, getValues, clearErrors } = useFormContext<QuotationsCreateValue>()
  const { append } = useFieldArray<QuotationsCreateValue>({
    control,
    name: 'selectedProducts',
  })
  const { selectedProducts } = getValues()

  const [amount, setAmount] = useState<number | undefined>(initAmount)
  const [price, setPrice] = useState<number | undefined>(initPrice)
  const [nameLocalState, setNameLocal] = useState<string | undefined>(product.nameLocal)

  const handleClick = () => {
    clearErrors('selectedProducts')
    const index = selectedProducts.findIndex((p) => p.id === product.id)
    if (index === -1) {
      const { code, nameLocal, nameEn, unitLocal, unitEn, imageUrl, stockAmount, dealerPrice } = product
      const selectedProduct: SelectedProduct = {
        id: product.id,
        code,
        nameLocal: nameLocalState || nameLocal,
        nameEn,
        unitLocal,
        unitEn,
        imageUrl,
        amount: amount || 0,
        price: price || 0,
        stockAmount,
        dealerPrice,
      }
      append(selectedProduct as SelectedProduct, { shouldFocus: false })
    } else {
      const { productNameLocal, nameLocal } = product
      const targetProduct = selectedProducts[index]
      setValue(`selectedProducts.${index}`, {
        ...targetProduct,
        nameLocal: nameLocalState ?? productNameLocal ?? nameLocal,
        amount: amount ?? 0,
        price: price ?? 0,
      })
    }
    onClose()
  }

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={styles.modal}
      {...props}
    >
      <Fade in={props.open}>
        <Grid container className={styles.grid} spacing={2}>
          <Grid item xs={12}>
            <Title>กรอกรายละเอียดสินค้า/บริการที่เลือก</Title>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={3}>
              <div className="relative flex flex-col items-center pr-3">
                <Avatar variant="rounded" src={product?.imageUrl} style={{ height: '150px', width: '100%' }}>
                  <Photo style={{ fontSize: 120 }} />
                </Avatar>
              </div>
            </Grid>
            <Grid item xs={12} md={9} className="md:pr-3">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} className="md:pr-3">
                  <FlexBox flexDirection="column">
                    <Typography color="textSecondary">รหัสสินค้า/บริการ</Typography>
                    <Typography color="textSecondary">{product.code}</Typography>
                  </FlexBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={nameLocalState}
                    variant="outlined"
                    size="small"
                    label="ชื่อสินค้า/บริการ สำหรับใบเสนอราคา"
                    onChange={(e) => setNameLocal(e.target.value ? e.target.value : undefined)}
                    helperText={`ชื่อสินค้าในระบบ: ${product.productNameLocal ?? product.nameLocal}`}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="md:pr-3">
                  <TextField
                    fullWidth
                    defaultValue={amount}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setAmount(e.target.value ? Number(e.target.value) : undefined)}
                    onBlur={(e) => {
                      const amount = Number(e.target.value)

                      if (isDealer && !!dealerPrice) {
                        setPrice(dealerPrice)
                      } else {
                        const foundPrice = pricing.find(
                          (price) => price.fromAmount <= amount && amount <= price.toAmount,
                        )
                        if (foundPrice) {
                          setPrice(foundPrice.price)
                        }
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        priceInputRef.current?.select()
                      }
                    }}
                    type="number"
                    label="จำนวน"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{unitLocal || 'ชิ้น'}</InputAdornment>,
                      inputProps: { min: 1 },
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    inputRef={priceInputRef}
                    fullWidth
                    defaultValue={price}
                    value={price}
                    // focused={price !== undefined}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setPrice(e.target.value ? Number(e.target.value) : undefined)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleClick()
                      }
                    }}
                    type="number"
                    label="ราคาต่อหน่วย"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                      inputProps: { min: 1 },
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2">
                    คงเหลือในสต็อก {numeral(stockAmount - reservedAmount).format('0,0')} อัน
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FlexBox justifyContent="end">
                    <Typography variant="body2" color="secondary" gutterBottom>
                      ราคารวม {numeral((amount || 0) * (price || 0)).format('0,0.00')}
                    </Typography>
                  </FlexBox>
                </Grid>
                {!!dealerPrice && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2">ราคาสำหรับ dealer : {dealerPrice}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">ช่วงราคาตามจำนวน</Typography>
          </Grid>
          <Grid item xs={12}>
            <Table<ProductPrice>
              color="primary"
              columns={[
                {
                  name: 'จำนวนที่',
                  render: (data: ProductPrice) =>
                    `${numeral(data.fromAmount).format('0,0')} - ${numeral(data.toAmount).format('0,0')}`,
                },
                { name: 'ราคาต่อหน่วย', dataIndex: 'price', numeric: true, format: '0,0.00' },
                {
                  name: 'ราคารวม',
                  dataIndex: 'price',
                  numeric: true,
                  render: (data: ProductPrice) =>
                    `${numeral(data.fromAmount * data.price).format('0,0.00')} - ${numeral(
                      data.toAmount * data.price,
                    ).format('0,0.00')}`,
                },
              ]}
              data={pricing}
              pagination={false}
              cleanSpace
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button color="primary" onClick={onClose}>
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!amount || amount < 1 || !price || price < 1}
              onClick={handleClick}
            >
              ยืนยันข้อมูล
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  )
}

export default QuotationsProductModal
