export enum PaymentMethod {
  Cash = 1,
  Transfer = 2,
  Credit = 3,
  Cheque = 4,
}

export enum PaymentCondition {
  PaymentRequire = 1,
  Credit = 2,
  PayAtDestination = 3,
}

export enum PurchasePaymentCondition {
  PaymentRequire = 11,
  Credit = 12,
  PayAsDestination = 13,
  DepositRequire = 14,
}
