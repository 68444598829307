import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'

const SubTopic: React.FC<TypographyProps> = (props) => (
  <Typography variant="h5" {...props}>
    {props.children}
  </Typography>
)

export default SubTopic
