import { useEffect, useState } from "react"
import { fetchQuotationsProfile } from "../../api/smartbestbuys-api"
import { QuotationProfile } from "../../api/smartbestbuys-api/quotation/quotation-profile-types"

export const useQuotationsProfile = (id?: number, reload?: boolean): QuotationProfile | undefined => {
    const [data, setData] = useState<QuotationProfile | undefined>(undefined)

    const _fetchQuotationsProfile = async (id: number) => {
        const response = await fetchQuotationsProfile(id)
        setData(response?.data)
    }

    useEffect(() => {
        if (id) _fetchQuotationsProfile(id)
    }, [id, reload])

    return data
}
