import React, { useContext, useMemo, useEffect } from 'react'
import { FormControlLabel, Grid, MenuItem, Paper, Typography } from '@material-ui/core'
import {
  CheckboxForm,
  FlexBox,
  GeneralAutoComplete,
  GeneralTextFieldForm,
  KeyboardDatePickerForm,
  Link,
  SubTopic,
  TypographyWithLabel,
} from '../../../custom-components'
import { TransportTaskCreatePageContext } from './TransportTaskCreatePage'
import { useFormContext } from 'react-hook-form'
import TransportTaskCreateFormValues from './TransportTaskCreateFormValues'
import { ShippingCarData, UserData } from '../../../api/smartbestbuys-api/data/data-types'
import { ButtonRadios, Loading, Space } from '../../../shared-components'
import { ShippingMethod, shippingPaymentConditions } from '../../../types'
import { PaymentCondition } from '../../../enums'
import { formatNumber } from '../../../functions/number-utils'

const TransportTaskDetailForm: React.FC = () => {
  // context
  const { transportTaskCreateData } = useContext(TransportTaskCreatePageContext)
  const { users, shippingCars, saleOrderInfo, purchaseOrders } = transportTaskCreateData

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<TransportTaskCreateFormValues>()

  if (!saleOrderInfo) return <Loading />

  const { total, paidAmount } = saleOrderInfo
  const payAtDestination = saleOrderInfo.paymentCondition.id === PaymentCondition.PayAtDestination

  const { dueDate, shippingMethod, chargeAmount, skipChargeAmount } = watch()
  const shippingBySmart = shippingMethod && shippingMethod === ShippingMethod.Smart
  const shippingByProvider = shippingMethod && shippingMethod !== ShippingMethod.Smart

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const remainingTotal = useMemo(() => {
    return total - paidAmount
    // eslint-disable-next-line
  }, [shippingBySmart, payAtDestination, saleOrderInfo.total, saleOrderInfo.paidAmount])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (shippingBySmart && payAtDestination) {
      setValue('chargeAmount', total - paidAmount)
    }
    // eslint-disable-next-line
  }, [shippingBySmart, payAtDestination, saleOrderInfo.total, saleOrderInfo.paidAmount])

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>รายละเอียดงานขนส่ง</SubTopic>
        <Space />
        <Grid container spacing={2}>
          {shippingBySmart && (
            <Grid item xs={12}>
              <TypographyWithLabel label="ยอดที่ลูกค้าค้างจ่าย:" alignItems="center">
                <Typography color="primary">{formatNumber(remainingTotal)} </Typography>
                <Typography>จาก {formatNumber(total)} บาท</Typography>
              </TypographyWithLabel>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <KeyboardDatePickerForm
              control={control}
              label="วันที่ส่งสินค้า *"
              name="dueDate"
              fullWidth
              gutterTop
              size="small"
              rules={{ required: { value: true, message: 'กรุณาเลือกวันที่ส่งสินค้า' } }}
              value={dueDate}
              defaultValue={null}
              error={!!errors.dueDate}
              helperText={errors.dueDate?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="carId"
              label="เลขทะเบียนรถ (ถ้ามี)"
              onChange={(e: any) => {
                setValue('car', {
                  id: e.target.value,
                  name: shippingCars.find((row: ShippingCarData) => row.id === e.target.value)?.name as string,
                })
              }}
              select
            >
              {shippingCars.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralAutoComplete
              id="transport-task-details-select-box"
              control={control}
              name="messengerId"
              onPostChange={(value) => {
                const user = users.find((row: UserData) => row.id === value)
                if (!user) return
                setValue('messenger', {
                  id: user?.id,
                  name: user?.firstName + ' ' + user?.lastName,
                })
              }}
              options={users.map((user) => {
                return {
                  label: user?.firstName + ' ' + user?.lastName,
                  value: user.id,
                }
              })}
              rules={{ required: 'กรุณาเลือกคนส่งสินค้า' }}
              renderInput={(params) => params}
              label="ผู้ส่งของ *"
            ></GeneralAutoComplete>
          </Grid>

          {shippingBySmart && (
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="chargeAmount"
                disabled={payAtDestination}
                value={skipChargeAmount ? 0 : payAtDestination ? remainingTotal : chargeAmount}
                label={payAtDestination ? 'ยอดเงินที่ต้องเรียกเก็บ' : 'ยอดเงินที่ต้องเรียกเก็บ (ถ้ามี)'}
                rules={{
                  required: { value: payAtDestination, message: 'กรุณาระบุยอดเงินเนื่องจากต้องเก็บเงินปลายทาง' },
                }}
                type="number"
              />
              {payAtDestination && (
                <FormControlLabel
                  label="ไม่ต้องเก็บเงินสำหรับการส่งนี้"
                  control={
                    <CheckboxForm
                      size="small"
                      checked={skipChargeAmount}
                      control={control}
                      name="skipChargeAmount"
                      color="primary"
                    />
                  }
                />
              )}
            </Grid>
          )}

          {shippingByProvider && (
            <Grid item xs={12} md={6}>
              <ButtonRadios
                label="รูปแบบการจ่ายเงินให้ขนส่ง *"
                items={shippingPaymentConditions}
                onValueChange={(value) => {
                  setValue('shippingPaymentCondition', Number(value))
                }}
                size="small"
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
            <Typography color="primary" variant="caption" component="div">
              PO อ้างอิง{' '}
              {purchaseOrders.map((po, index) => {
                return (
                  <Link color="primary" to={`/purchase-orders/${po.id}/profile`} target="_blank">
                    <Typography variant="caption">
                      {po.code}
                      {purchaseOrders.length !== index + 1 ? ' , ' : ''}
                    </Typography>
                  </Link>
                )
              })}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskDetailForm
