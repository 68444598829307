import {
  Avatar,
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@material-ui/core'
import numeral from 'numeral'
import { useRef, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { PaymentRequisitionSelectedItem, PurchaseOrderDocument } from '../../../api/smartbestbuys-api'
import { FlexBox, GeneralTextFieldForm, TypographyWithLabel } from '../../../custom-components'
import { calculateExcludingVat, calculateVatInAmount } from '../../../functions'
import { formatNumber, roundNumber, toDecimal } from '../../../functions/number-utils'
import { ButtonRadios, PDFAvatar, PurchaseOrderStatusChip, SubmitForm, Space } from '../../../shared-components'
import { FileType } from '../../../types'
// TODO: move component to shared component
import PreviewImageDialog from '../../purchase-requisitions/create/AttachmentSelectionBox/UploadCard/PreviewImageDialog'
import './PaymentRequisitionSelectedDialog.less'

interface Props extends DialogProps {
  value: PaymentRequisitionSelectedItem
  onComplete: (data: PaymentRequisitionSelectedItem) => void
  onClose: () => void

  currency?: string
}

interface PaymentRequisitionItemSelectedFormValues {
  payTotal: number
  payWithholdingTaxPercentage: number
  payWithholdingTaxAmount: number
}

const PaymentRequisitionSelectedDialog: React.FC<Props> = (props: Props) => {
  // props & state
  const { value, currency, onComplete, onClose, ...otherProps } = props

  // calculation
  const paidTotal = !!value.paymentTransactions
    ? value.paymentTransactions.reduce<number>((sum, payment) => {
        return sum + payment.total
      }, 0)
    : 0
  const remainingTotal = value.total - paidTotal

  const defaultPaymentType = value.payTotal < remainingTotal ? 2 : 1

  // states
  const [paymentType, setPaymentType] = useState<number>(defaultPaymentType)
  const [enableWithholdingTax, setEnableWithholdingTax] = useState<boolean>(!!value.payWithholdingTaxPercentage)
  const [imageDocument, setOpenImageDocument] = useState('')

  // ref
  const ref = useRef<HTMLInputElement | null>(null)

  // react hook form
  const {
    control,
    formState: { isSubmitting },
    setValue,
    watch,
    handleSubmit,
  } = useForm<PaymentRequisitionItemSelectedFormValues>({
    defaultValues: {
      payTotal: value.payTotal ?? remainingTotal ?? 0,
      payWithholdingTaxAmount: value.payWithholdingTaxAmount ?? 0,
      payWithholdingTaxPercentage: value.payWithholdingTaxPercentage ?? 0,
    },
  })
  const { payTotal, payWithholdingTaxPercentage, payWithholdingTaxAmount } = watch()

  // calculate for display
  const depositAmountPOPercent = !!value.depositAmount ? roundNumber((value.depositAmount / value.total) * 100) : 0
  const withholdingTaxAmount = useMemo(() => {
    const productTotalWithDiscount = value.productTotal - value.discountAmount
    return !!value.withholdingTaxPercentage ? (productTotalWithDiscount * value.withholdingTaxPercentage) / 100 : 0
  }, [value.productTotal, value.discountAmount, value.withholdingTaxPercentage])

  const [payTotalExcludingVat, vatAmountFromPayTotal] = useMemo(() => {
    if (!payTotal) return [0, 0]
    if (value.vatAmount > 0) {
      return [calculateExcludingVat(payTotal), toDecimal(calculateVatInAmount(payTotal))]
    }

    return [payTotal, 0]
  }, [payTotal, value.vatAmount])

  const currencyText = currency ?? 'บาท'

  const calculateWithholdingTaxAmount = (
    payTotal: number,
    withholdingTaxPercentage: number,
    enableWithholdingTax: boolean,
  ) => {
    const taxPercentage = !!Number(withholdingTaxPercentage) ? Number(withholdingTaxPercentage) : 0
    if (!enableWithholdingTax || !taxPercentage) return 0
    const vatIncuded = value.vatAmount > 0
    const totalExcludingVat = vatIncuded ? calculateExcludingVat(payTotal) : payTotal
    const withholdingTaxAmount = (totalExcludingVat * taxPercentage) / 100
    return toDecimal(withholdingTaxAmount)
  }

  const onDialogSubmit = async (values: PaymentRequisitionItemSelectedFormValues) => {
    const { payTotal, payWithholdingTaxPercentage, payWithholdingTaxAmount } = values
    if (enableWithholdingTax) {
      onComplete({ ...value, payTotal, payWithholdingTaxAmount, payWithholdingTaxPercentage })
    } else {
      onComplete({ ...value, payTotal, payWithholdingTaxAmount: undefined, payWithholdingTaxPercentage: undefined })
    }
  }

  return (
    <Dialog {...otherProps} maxWidth="md" fullWidth>
      {!!imageDocument && (
        <PreviewImageDialog
          refInput={ref}
          source={imageDocument}
          open={!!imageDocument}
          onClose={() => {
            setOpenImageDocument('')
          }}
          readOnly
        />
      )}
      <DialogTitle>กรอกราคาสำหรับทำเบิก {value.code}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={8} className="pr-4">
            <Typography variant="subtitle1">ข้อมูลจากใบสั่งซื้อ</Typography>
            <Space />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TypographyWithLabel label="สถานะใบสั่งซื้อ">
                  <PurchaseOrderStatusChip status={value.status.id} label={value.status.name} />
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="มัดจำ">
                  {!!value.depositAmount ? (
                    <>
                      {formatNumber(value.depositAmount)} {currencyText} ({depositAmountPOPercent})%
                    </>
                  ) : (
                    <>ไม่มีมัดจำ</>
                  )}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="ยอดรวมใบสั่งซื้อ">
                  {formatNumber(value.total)} {currencyText}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="หัก ณ ที่จ่าย">
                  {!!withholdingTaxAmount ? (
                    <>
                      {formatNumber(withholdingTaxAmount)} {currencyText} ({value.withholdingTaxPercentage}%)
                    </>
                  ) : (
                    <>ไม่มีหัก ณ ที่จ่าย</>
                  )}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="ยอดที่เคยทำเบิกแล้ว">
                  {formatNumber(paidTotal)} {currencyText}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="ยอดรอทำเบิก">
                  {formatNumber(remainingTotal)} {currencyText}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="วันที่ต้องจ่าย">-</TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="หมายเหตุ">{value.remark}</TypographyWithLabel>
              </Grid>
            </Grid>
            <Space />
            <Typography variant="subtitle1">เลือกรูปแบบการทำเบิก</Typography>
            <Space />
            <ButtonRadios
              onChange={(id) => {
                setPaymentType(id)
                if (id === 1) {
                  const withholdingTaxAmount = calculateWithholdingTaxAmount(
                    remainingTotal,
                    payWithholdingTaxPercentage,
                    enableWithholdingTax,
                  )

                  setValue('payTotal', remainingTotal)
                  setValue('payWithholdingTaxAmount', withholdingTaxAmount)
                }
              }}
              items={[
                { id: 1, name: 'ยอดเต็ม/ยอดที่เหลือ' },
                { id: 2, name: 'ยอดมัดจำ' },
              ]}
              grouped
              defaultValue={paymentType}
            />
            <Space />
            {paymentType === 2 && (
              <>
                <GeneralTextFieldForm
                  control={control}
                  name="payTotal"
                  label="ยอดทำเบิก"
                  rules={{
                    required: { value: true, message: 'กรุณากรอกยอดทำเบิก' },
                    max: { value: remainingTotal, message: 'ไม่สามารถทำเบิก > ยอดรอทำเบิกได้' },
                  }}
                  variant="outlined"
                  value={payTotal}
                  hidden
                  onChange={(e: any) => {
                    const withholdingTaxAmount = calculateWithholdingTaxAmount(
                      e.target.value,
                      payWithholdingTaxPercentage,
                      enableWithholdingTax,
                    )

                    setValue('payTotal', e.target.value)
                    setValue('payWithholdingTaxAmount', withholdingTaxAmount)
                  }}
                />

                <Space />
                <ButtonRadios
                  onValueChange={(value) => {
                    const numValue = Number(value)
                    const payTotal = remainingTotal * numValue
                    const withholdingTaxAmount = calculateWithholdingTaxAmount(
                      payTotal,
                      payWithholdingTaxPercentage,
                      enableWithholdingTax,
                    )

                    setValue('payTotal', toDecimal(remainingTotal * numValue))
                    setValue('payWithholdingTaxAmount', withholdingTaxAmount)
                  }}
                  items={[
                    { id: 1, value: 0.3, name: '30%' },
                    { id: 2, value: 0.5, name: '50%' },
                    { id: 3, value: 0.7, name: '70%' },
                  ]}
                  value={payTotal / remainingTotal}
                />
                <Space />
              </>
            )}
            <Card>
              <FlexBox justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">หัก ณ ที่จ่าย</Typography>
                <Switch
                  checked={enableWithholdingTax}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEnableWithholdingTax(event.target.checked)
                  }}
                  color="secondary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </FlexBox>
              {enableWithholdingTax && (
                <Grid container>
                  <Grid item xs={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="payWithholdingTaxPercentage"
                      label="จำนวนเปอร์เซ็นต์"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      rules={{
                        required: { value: true, message: 'เมื่อเปิดการใช้งานนี้ กรุณากรอกจำนวนเปอร์เซ็นต์' },
                      }}
                      variant="outlined"
                      value={payWithholdingTaxPercentage}
                      onChange={(e: any) => {
                        setValue('payWithholdingTaxPercentage', e.target.value)
                        const calculate = calculateWithholdingTaxAmount(payTotal, e.target.value, enableWithholdingTax)
                        setValue('payWithholdingTaxAmount', calculate)
                      }}
                    />
                  </Grid>
                  <Grid container item xs={6} justify="flex-end" alignItems="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      คิดเป็น {formatNumber(payWithholdingTaxAmount)} {currencyText}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
          <Box className="document-box" clone>
            <Grid item md={4}>
              <Typography variant="subtitle1">เอกสารทางการเงิน</Typography>
              <Space />
              {value.documents.map((document) => (
                <DocumentCard
                  document={document}
                  onClick={(data: PurchaseOrderDocument) => setOpenImageDocument(data.attachmentUrl)}
                />
              ))}
              <Divider />
              <Space />
              <Typography variant="subtitle1">สรุปยอด</Typography>
              <Space />
              <Card>
                <FlexBox justifyContent="space-between">
                  <Typography variant="body2">ยอดที่ต้องการทำเบิก</Typography>
                  <Typography variant="body2">
                    {formatNumber(payTotalExcludingVat)} {currencyText}
                  </Typography>
                </FlexBox>
                <Space />
                {vatAmountFromPayTotal > 0 && (
                  <>
                    <FlexBox justifyContent="space-between">
                      <Typography variant="body2">ภาษีมูลค่าเพิ่ม</Typography>
                      <Typography variant="body2">
                        {formatNumber(vatAmountFromPayTotal)} {currencyText}
                      </Typography>
                    </FlexBox>
                    <Space />
                  </>
                )}
                {enableWithholdingTax && (
                  <FlexBox justifyContent="space-between">
                    <Typography variant="body2">ยอดหัก ณ ที่จ่าย</Typography>
                    <Typography variant="body2">
                      {enableWithholdingTax ? formatNumber(payWithholdingTaxAmount) : '0.00'} {currencyText}
                    </Typography>
                  </FlexBox>
                )}
              </Card>
              <Space />
              <FlexBox justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" className="pl-4">
                  ยอดทำเบิกสุทธิ
                </Typography>
                <Box color="secondary.dark">
                  <Typography variant="h6" className="pr-4">
                    {formatNumber(enableWithholdingTax ? payTotal - payWithholdingTaxAmount : payTotal)} {currencyText}
                  </Typography>
                </Box>
              </FlexBox>
            </Grid>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SubmitForm
          submitText="ยืนยัน"
          cancelText="ยกเลิก"
          onSubmit={() => {
            handleSubmit(onDialogSubmit)()
          }}
          onCancel={() => {
            if (!!onClose) onClose()
          }}
          isSubmitting={isSubmitting}
          submitButtonType="button"
        />
      </DialogActions>
    </Dialog>
  )
}

export default PaymentRequisitionSelectedDialog

interface DocumentCardProps {
  document: PurchaseOrderDocument
  onClick: (data: PurchaseOrderDocument) => void

  currency?: string
}

const DocumentCard: React.FC<DocumentCardProps> = (props: DocumentCardProps) => {
  // prop
  const { document, currency, onClick } = props

  const currencyText = currency ?? 'บาท'

  const attachmentFileType = document.attachmentUrl.slice(-4) === '.pdf' ? FileType.PDF : FileType.Image
  const isImageFile = attachmentFileType === FileType.Image
  return (
    <Card variant="outlined" className="mb-4">
      <FlexBox>
        {isImageFile ? (
          <Avatar
            className="document-image"
            variant="square"
            sizes=""
            src={document.attachmentUrl}
            onClick={() => onClick(document)}
          />
        ) : (
          <PDFAvatar src={document.attachmentUrl} />
        )}
        <FlexBox flexDirection="column" className="pl-4">
          <Typography variant="body2">{document.type.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {document.referenceNumber}
          </Typography>
          <Box color="secondary.dark" clone>
            <Typography variant="body2">
              {numeral(document.total).format('0,0.00')} {currencyText}
            </Typography>
          </Box>
        </FlexBox>
      </FlexBox>
    </Card>
  )
}
