import { useState, useEffect } from 'react'
import { IProductProfile, getProductProfileAPIV2 } from '../../api/smartbestbuys-api'
import { endOfDay, format, startOfDay } from 'date-fns'

export const useProductProfileV2 = (
  id: number,
  startDateTime: Date,
  endDateTime: Date,
  reload?: boolean,
): IProductProfile | undefined => {
  const [profile, setProfile] = useState<IProductProfile | undefined>(undefined)

  const _getProductProfile = async (id: number) => {
    const response = await getProductProfileAPIV2(id, {
      startDateTime: format(startOfDay(startDateTime), 'yyyy-MM-dd HH:mm:ss'),
      endDateTime: format(endOfDay(endDateTime), 'yyyy-MM-dd HH:mm:ss'),
    })
    setProfile(response?.data)
  }

  useEffect(() => {
    if (id && startDateTime && endDateTime) _getProductProfile(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, startDateTime, endDateTime, reload])

  return profile
}
