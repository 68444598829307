import React from 'react'
import { Box, Paper, FormControlLabel, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import numeral from 'numeral'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import { FlexBox, CheckboxForm, SubTopic } from '../../../../custom-components'
import { calculateVatInAmount } from '../../../../functions'
import { calculatePriceBreakdowns } from '../../../../sales-system/utils/sale-order-price-calculator'

const QuotationsVatBox: React.FC = () => {
  const { control, getValues } = useFormContext<QuotationsCreateValue>()
  const values = getValues()
  const { vatIncluded, shippingFee, isShippingFeeIncludingVat } = values

  const shippingFeeVatAmount = isShippingFeeIncludingVat ? calculateVatInAmount(shippingFee || 0) : 0

  const priceBreakdowns = calculatePriceBreakdowns(getValues())
  return (
    <Box padding={2} display="flex" justifyContent="space-between" clone>
      <Paper>
        <FormControlLabel
          control={<CheckboxForm checked={vatIncluded} control={control} name="vatIncluded" color="primary" />}
          label={<SubTopic variant="h5">ภาษีมูลค่าเพิ่ม 7 %</SubTopic>}
        />
        <FlexBox flexDirection="column" alignItems="flex-end">
          <Typography variant="h6" color="textSecondary">
            {vatIncluded
              ? `ภาษีมูลค่าเพิ่ม ${numeral(priceBreakdowns.vatAmount).format('0,0.00')}`
              : 'ไม่มีภาษีมูลค่าเพิ่ม'}
          </Typography>
          {isShippingFeeIncludingVat && (
            <>
              <Box color="text.hint" clone>
                <Typography variant="caption">
                  VAT สินค้า {numeral(priceBreakdowns.vatAmount - shippingFeeVatAmount).format('0,0.00')} บาท
                </Typography>
              </Box>
              <Box color="text.hint" clone>
                <Typography variant="caption">
                  VAT ค่าส่ง {numeral(shippingFeeVatAmount).format('0,0.00')} บาท
                </Typography>
              </Box>
            </>
          )}
        </FlexBox>
      </Paper>
    </Box>
  )
}

export default QuotationsVatBox
