import { Breadcrumbs, Typography } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import React from 'react'
import { Link } from '..'

export interface BreadcrumbsItem {
  url?: string
  name: string
}

interface Props {
  items: BreadcrumbsItem[]
}

const BreadcumbsList: React.FC<Props> = (props) => (
  <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
    {props.items.map((item) =>
      item.url ? (
        <Link key={item.name} color="inherit" to={item.url}>
          {item.name}
        </Link>
      ) : (
        <Typography key={item.name} color="textPrimary">
          {item.name}
        </Typography>
      ),
    )}
  </Breadcrumbs>
)

export default BreadcumbsList
