import React, { useContext, useState } from 'react'

import SaleOrderProfileContext from './SaleOrderProfileContext'
import { Loading, PurchaseOrderStatusChip, TypographyLink } from '../../../../shared-components'
import { FlexBox, Table, TypographyWithLabel } from '../../../../custom-components'
import { dateFormat, dateTimeFormat } from '../../../../functions'
import { IUserPurchaseOrderActivity } from '../../../../api/smartbestbuys-api'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Paper,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { getUserPermissionInfo } from '../../../../utils/permission'
import { PermissionEnum } from '../../../../enums/PermissionEnum'

export const useStyles = makeStyles((theme) =>
  createStyles({
    accordion: {
      padding: theme.spacing(1),
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  }),
)

const SOPurchaseOrderList: React.FC = (props) => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  const classes = useStyles()

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined)

  const poReadPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Read)

  if (!saleOrderProfile) return <Loading />

  const hasPurchaseOrders = saleOrderProfile.purchaseOrders.length > 0

  return (
    <Paper>
      <FlexBox flexDirection="column">
        {hasPurchaseOrders ? (
          saleOrderProfile.purchaseOrders.map((purchaseOrder, index) => {
            const { id, code, status, vendorShippingDate, activities } = purchaseOrder
            const isExpanded = activeIndex === index
            const handleExpand = () =>
              setActiveIndex((prev) => {
                if (isExpanded) return undefined
                return index
              })

            return (
              <Accordion
                square
                key={`purchaseOrder-${index}`}
                expanded={isExpanded}
                onChange={handleExpand}
                TransitionProps={{ unmountOnExit: true }}
                className={classes.accordion}
              >
                <AccordionSummary expandIcon={<ExpandMore />} classes={{ content: classes.accordionSummary }}>
                  {poReadPermission.hasPermission ? (
                    <TypographyLink
                      href={`/purchase-orders/${id}/profile`}
                      target="_blank"
                      variant="h5"
                      color="default"
                    >
                      ใบสั่งซื้อ {code}
                    </TypographyLink>
                  ) : (
                    <Typography variant="h5"> ใบสั่งซื้อ {code} </Typography>
                  )}

                  <PurchaseOrderStatusChip status={status.id} label={status.name} />
                  <TypographyWithLabel variant="body2" label="วันนัดหมายผู้ขาย:" alignItems="center">
                    {vendorShippingDate ? dateFormat(vendorShippingDate) : 'ไม่ระบุ'}
                  </TypographyWithLabel>
                </AccordionSummary>
                {/* <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FlexBox flexDirection="column">
                        <Typography>ประวัติการจัดการใบสั่งซื้อ</Typography>
                        <span className="mb-1" />
                        <Table<
                          Pick<
                            IUserPurchaseOrderActivity,
                            'id' | 'action' | 'actionMessage' | 'remark' | 'recCreatedAt' | 'recCreatedBy'
                          >
                        >
                          color="grey.800"
                          columns={[
                            { name: 'การกระทำ', render: ({ action }) => action.name },
                            { name: 'รายละเอียดการจัดการ', dataIndex: 'actionMessage' },
                            { name: 'จัดการโดย', dataIndex: 'recCreatedBy', align: 'center' },
                            {
                              name: 'วันที่',
                              dataIndex: 'recCreatedAt',
                              render: (activity) => dateTimeFormat(activity.recCreatedAt),
                              align: 'center',
                            },
                            { name: 'หมายเหตุ', dataIndex: 'remark', align: 'center' },
                          ]}
                          data={activities}
                          cleanSpace
                        />
                      </FlexBox>
                    </Grid>
                  </Grid>
                </AccordionDetails> */}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FlexBox flexDirection="column">
                        <Typography>รายการขนส่ง</Typography>
                        <span className="mb-1" />
                        <Table<
                          Pick<
                            IUserPurchaseOrderActivity,
                            'id' | 'action' | 'actionMessage' | 'remark' | 'recCreatedAt' | 'recCreatedBy'
                          >
                        >
                          color="grey.800"
                          columns={[
                            { name: 'การกระทำ', render: ({ action }) => action.name },
                            { name: 'รายละเอียดการจัดการ', render: ({ actionMessage }) => actionMessage?.name ?? '' },
                            { name: 'จัดการโดย', dataIndex: 'recCreatedBy', align: 'center' },
                            {
                              name: 'วันที่',
                              dataIndex: 'recCreatedAt',
                              render: (activity) => dateTimeFormat(activity.recCreatedAt),
                              align: 'center',
                            },
                            { name: 'หมายเหตุ', dataIndex: 'remark', align: 'center' },
                          ]}
                          data={activities}
                          cleanSpace
                        />
                      </FlexBox>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
          })
        ) : (
          <FlexBox justifyContent="center" alignItems="center" width="100%" height={100} clone>
            <Card variant="outlined">
              <Typography variant="body2" color="textSecondary">
                ยังไม่มีข้อมูลการสั่งซื้อ
              </Typography>
            </Card>
          </FlexBox>
        )}
      </FlexBox>
    </Paper>
  )
}

export default SOPurchaseOrderList
