import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import {
  ConfirmReturnedWithdrawnProductRequestBody,
  IProductWithdrawalTicketProduct,
  IProductWithdrawalTicketProfile,
  confirmReturnedWithdrawalProductAPI,
} from '../../../../../api/smartbestbuys-api/warehouse'
import { Avatar, FlexBox, GeneralTextFieldForm, Table } from '../../../../../custom-components'
import { Photo } from '@material-ui/icons'
import { formatNumberInt } from '../../../../../functions/number-utils'
import { ReceiveProductAction } from '../../../../../enums'
import { UseFormReturn, useForm } from 'react-hook-form'
import { useState } from 'react'

interface ReturnProductDetail extends IProductWithdrawalTicketProduct {
  expectedReturnAmount: number
  toReturnedAmount?: number
  returnAction?: number
}
interface ReturnedProductFormValues {
  returnedProducts: ReturnProductDetail[]
  checked?: boolean
  remark?: string
}

interface Props extends DialogProps {
  profile: IProductWithdrawalTicketProfile
  onSuccess: (updatedProfile: IProductWithdrawalTicketProfile) => void
  onFail: () => void
}

const PWTicketConfirmReturnedDialog: React.FC<Props> = (props) => {
  const { profile, onSuccess, onFail, onClose, ...dialogProps } = props

  const { id, code, products } = profile

  const returnedProducts = products.map((p) => {
    const expectedReturnAmount = p.amount - p.returnedAmount
    const returnAction = expectedReturnAmount === 0 ? ReceiveProductAction.AlreadyReceived : undefined

    return {
      ...p,
      expectedReturnAmount,
      returnAction,
    }
  })

  const formMethods = useForm<ReturnedProductFormValues>({
    defaultValues: { returnedProducts, checked: false },
  })

  const { handleSubmit } = formMethods

  const handleClose = () => {
    onClose && onClose({}, 'backdropClick')
  }

  const handleSubmitDialog = async (values: ReturnedProductFormValues) => {
    const request: ConfirmReturnedWithdrawnProductRequestBody = {
      closeTicketAccepted: values.checked ?? false,
      remark: values.remark,
      products: values.returnedProducts
        .filter((p) => !!p.toReturnedAmount && p.toReturnedAmount > 0)
        .map((p) => ({
          productId: p.productId,
          returnedAmount: p.toReturnedAmount!, // risk to broke
        })),
    }

    const response = await confirmReturnedWithdrawalProductAPI(id, request)
    if (response?.status === 200) {
      onSuccess(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} maxWidth="lg" fullWidth>
      <DialogTitle>รับสินค้าคืนของคำขอเบิกเลขที่ {code}</DialogTitle>
      <DialogContent>
        <ReturnProductTable formMethods={formMethods} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary" size="large">
          ย้อนกลับ
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleSubmitDialog)} color="primary" size="large">
          ยืนยันการรับสินค้าคืน
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PWTicketConfirmReturnedDialog

const grayscaleStyle = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
}

interface TableProps {
  formMethods: UseFormReturn<ReturnedProductFormValues>
}

const ReturnProductTable: React.FC<TableProps> = ({ formMethods }) => {
  // state
  const [openWarning, setOpenWarning] = useState(false)

  // form
  const { control, setValue, watch } = formMethods

  const { returnedProducts, checked } = watch()

  const handleReceiveActionChange = (value: ReceiveProductAction, index: number) => {
    const target = returnedProducts[index]
    target.returnAction = value
    if (value === ReceiveProductAction.All) target.toReturnedAmount = target.expectedReturnAmount
    if (value === ReceiveProductAction.Nothing) target.toReturnedAmount = 0

    // setValue
    setValue('returnedProducts', [...returnedProducts.slice(0, index), target, ...returnedProducts.slice(index + 1)])
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('checked', event.target.checked)
    setOpenWarning(event.target.checked)
  }

  return (
    <>
      <WarningDialog open={openWarning} onClose={() => setOpenWarning(false)} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>รายละเอียดการรับสินค้า</Typography>
        </Grid>
        <Grid item xs={12}>
          <Table<ReturnProductDetail>
            color="primary"
            size="small"
            columns={[
              {
                name: 'ลำดับ',
                numeric: true,
                format: '0,0',
                fixedWidth: 50,
                render: (_, index) => index + 1,
              },
              {
                name: 'ชื่อสินค้า',
                render: ({ imageUrl, nameLocal, code }) => (
                  <FlexBox alignItems="flex-start">
                    <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                      <Photo />
                    </Avatar>
                    <FlexBox flexDirection="column" ml={1} className="receive-product-header">
                      <FlexBox gridGap={8}>
                        <Typography variant="body2" className="receive-product-header__code">
                          {code}
                        </Typography>
                      </FlexBox>
                      <Typography variant="body2" className="receive-product-header__name">
                        {nameLocal}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                ),
              },
              {
                name: 'จำนวนที่เคยรับแล้ว/จำนวนตามคำขอเบิก',
                fixedWidth: 170,
                align: 'right',
                render: (product) => (
                  <Typography style={{ ...(product.expectedReturnAmount === 0 ? grayscaleStyle : {}) }}>
                    {formatNumberInt(product.returnedAmount)}/{formatNumberInt(product.amount)}
                  </Typography>
                ),
              },
              {
                name: 'ผลการรับ',
                fixedWidth: 190,
                render: (product, index) => {
                  if (product.expectedReturnAmount === 0)
                    return (
                      <Typography style={{ ...(product.expectedReturnAmount === 0 ? grayscaleStyle : {}) }}>
                        -
                      </Typography>
                    )
                  const isFirstTime = product.returnedAmount === 0
                  return (
                    <GeneralTextFieldForm
                      control={control}
                      name={`returnedProducts.${index}.receiveAction`}
                      select
                      rules={{ required: 'กรุณาเลือกผลการรับ' }}
                      onChange={(e) => !!e.target.value && handleReceiveActionChange(Number(e.target.value), index)}
                    >
                      <MenuItem value={ReceiveProductAction.All}>{isFirstTime ? 'รับครบ' : 'รับที่เหลือ'}</MenuItem>
                      <MenuItem value={ReceiveProductAction.Partial}>รับขาด</MenuItem>
                      <MenuItem value={ReceiveProductAction.Nothing}>ไม่มีให้รับ</MenuItem>
                    </GeneralTextFieldForm>
                  )
                },
              },
              {
                name: 'จำนวนที่รับ',
                fixedWidth: 170,
                align: 'right',
                render: (product, index) => {
                  if (product.expectedReturnAmount === 0)
                    return (
                      <Typography style={{ ...(product.expectedReturnAmount === 0 ? grayscaleStyle : {}) }}>
                        -
                      </Typography>
                    )
                  if (product.returnAction === ReceiveProductAction.All)
                    return <Typography>{product.expectedReturnAmount}</Typography>
                  if (
                    product.returnAction &&
                    [ReceiveProductAction.Partial, ReceiveProductAction.RejectRemaining].includes(product.returnAction)
                  ) {
                    return (
                      <GeneralTextFieldForm
                        control={control}
                        name={`returnedProducts.${index}.toReturnedAmount`}
                        type="number"
                        rules={{
                          required: 'กรุณากรอกจำนวน',
                          min: { value: 1, message: 'รับอย่างน้อย 1 หน่วย' },
                          max: {
                            value: product.expectedReturnAmount,
                            message: 'กรุณาอย่าใส่จำนวนเกินจำนวนที่ต้องรับ',
                          },
                        }}
                      />
                    )
                  }

                  return '-'
                },
              },
              {
                name: 'จำนวนรับจริง/จำนวนที่ต้องรับ',
                align: 'right',
                fixedWidth: 140,
                render: (product) => {
                  if (product.expectedReturnAmount === 0)
                    return (
                      <Typography style={{ ...(product.expectedReturnAmount === 0 ? grayscaleStyle : {}) }}>
                        รับครบไปแล้ว
                      </Typography>
                    )
                  if (product.toReturnedAmount === undefined)
                    return <Typography>?/{formatNumberInt(product.expectedReturnAmount)}</Typography>
                  let textColor = 'textPrimary'
                  if (product.toReturnedAmount === product.expectedReturnAmount) textColor = 'success.main'
                  if (product.toReturnedAmount < product.expectedReturnAmount) textColor = 'error.main'
                  if (product.toReturnedAmount > product.expectedReturnAmount) textColor = 'warning.main'
                  return (
                    <Box color={textColor} clone>
                      <Typography>
                        {`${formatNumberInt(product.toReturnedAmount)}/${formatNumberInt(
                          product.expectedReturnAmount,
                        )}`}
                      </Typography>
                    </Box>
                  )
                },
              },
            ]}
            data={returnedProducts}
            pagination={false}
            cleanSpace
          />
        </Grid>
        <Grid item xs={12}>
          <GeneralTextFieldForm name="remark" control={control} label="หมายเหตุเพิ่มเติม" style={{ width: '50%' }} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} name="forcedClose" />}
            label="ต้องการปิดคำขอเบิกทันที"
          />
        </Grid>
      </Grid>
    </>
  )
}

const WarningDialog: React.FC<DialogProps> = (props) => {
  const { onClose } = props

  return (
    <Dialog {...props} fullWidth>
      <DialogTitle>
        <span style={{ color: '#E31B0C', fontSize: '32px' }}>คำเตือน!!!</span>
      </DialogTitle>
      <DialogContent style={{ fontSize: '20px' }}>
        การปิดคำขอเบิกทันทีจะทำให้ <span style={{ color: '#E31B0C' }}>ไม่สามารถรับของจากคำขอนี้ได้อีก</span>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose && onClose({}, 'backdropClick')
          }}
          variant="outlined"
          color="primary"
        >
          ย้อนกลับ
        </Button>
      </DialogActions>
    </Dialog>
  )
}
