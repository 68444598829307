import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import {
  createPaymentRequisition,
  PaymentRequisitionCreateData,
  PaymentRequisitionSelectedItem,
} from '../../../api/smartbestbuys-api'
import { Form, Grid, Topic, withLayout } from '../../../custom-components'
import { usePaymentRequisitionCreateData } from '../../../hooks'
import { Loading, SubmitForm } from '../../../shared-components'
import PaymentRequisitionItemTable from './PaymentRequisitionItemTable'
import PaymentRequisitionSelectorBox from './PaymentRequisitionSelectorBox'
import PaymentRequisitionFormBox from './PaymentRequisitionFormBox'
import PaymentRequisitionTotalBox from './PaymentRequisitionTotalBox'
import VendorProfileCard from '../../purchase-orders/profile/VendorProfileCard'
import PaymentRequisitionInformationBox, { PaymentRequisitionInformation } from '../PaymentRequisitionInformationBox'
import { paymentMethods } from '../../../types'
import { useHistory } from 'react-router-dom'
import { mapPaymentRequisitionToCreationFormValues } from './mapper'
import { RootContext } from '../../..'
import PaymentRequisitionSelectedDialog from './PaymentRequisitionSelectedDialog'

interface QueryParams {
  purchaseOrderId?: number
}

interface Props {
  vendorId: number
  query: QueryParams
}

export interface PaymentRequisitionCreateFormValues {
  paymentMethodId: number
  vendorBankAccountId: number
  exchangeRate: number
  paymentRequisitionItems: PaymentRequisitionSelectedItem[]
}

const PaymentRequisitionCreatePage: React.FC<Props> = (props: Props) => {
  // props
  const { vendorId, query } = props
  const { purchaseOrderId } = query

  // context
  const { triggerSnackbar } = useContext(RootContext)

  // state
  const [pageIndex, setPageIndex] = useState(0)

  // hook
  const history = useHistory()

  // hook form
  const formMethod = useForm<PaymentRequisitionCreateFormValues>({
    defaultValues: {
      exchangeRate: 1,
      paymentRequisitionItems: [],
    },
  })
  const { register, watch, setError } = formMethod

  useEffect(() => {
    register('paymentRequisitionItems', {})
  }, [register])

  // function
  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  // prepare data
  const paymentRequisitionCreateData = usePaymentRequisitionCreateData(vendorId)
  if (!paymentRequisitionCreateData) return <Loading />

  // handle
  const handleSubmit = async (values: PaymentRequisitionCreateFormValues) => {
    if (!validateFormValues(values)) return
    if (pageIndex === 0) setPageIndex(1)
    if (pageIndex === 1) {
      const request = mapPaymentRequisitionToCreationFormValues(watch(), vendorId)
      const response = await createPaymentRequisition(request)

      if (response?.status === 201) {
        triggerSnackbar('สร้างใบทำเบิกสำเร็จ')
        history.replace(`/payment-requisitions`)
      } else {
        triggerSnackbar('สร้างใบทำเบิกไม่สำเร็จ')
      }
      return true
    }
  }

  const handleInvalid = () => {
    validateFormValues(watch())
  }

  // manual validate values
  const validateFormValues = (values: PaymentRequisitionCreateFormValues): boolean => {
    const { paymentRequisitionItems } = values
    if (!paymentRequisitionItems || paymentRequisitionItems.length === 0) {
      setError('paymentRequisitionItems', {
        type: 'required',
        message: 'กรุณาเลือกสินค้า/บริการ',
      })
      scrollTo('#selector-box')
      return false
    }
    return true
  }

  const handleBack = () => {
    if (pageIndex === 0) history.goBack()
    if (pageIndex === 1) setPageIndex(0)
  }

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
        {
          [
            <FormPage
              paymentRequisitionCreateData={paymentRequisitionCreateData}
              purchaseOrderId={purchaseOrderId}
              onBack={handleBack}
            />,
            <VerificationPage paymentRequisitionCreateData={paymentRequisitionCreateData} onBack={handleBack} />,
          ][pageIndex]
        }
      </Form>
    </FormProvider>
  )
}

export default withLayout(PaymentRequisitionCreatePage)

interface PageProps {
  paymentRequisitionCreateData: PaymentRequisitionCreateData
  purchaseOrderId?: number
  onBack: () => void
}

const FormPage = (props: PageProps) => {
  // props
  const { paymentRequisitionCreateData, purchaseOrderId, onBack } = props
  const { vendorInfo } = paymentRequisitionCreateData

  // hook
  const history = useHistory()

  // react hook form
  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<PaymentRequisitionCreateFormValues>()
  const { paymentRequisitionItems } = watch()

  const [selected, setSelected] = useState<PaymentRequisitionSelectedItem | undefined>()

  // prepare
  const isSinglePO = !!purchaseOrderId
  const purchaseOrderInfos = paymentRequisitionCreateData.purchaseOrderInfos.filter(
    (purchaseOrder) =>
      purchaseOrder.total >
      purchaseOrder.paymentTransactions.reduce<number>((sum, payment) => {
        return sum + payment.total
      }, 0),
  )

  // effect
  useEffect(() => {
    if (!!purchaseOrderId && (!paymentRequisitionItems || paymentRequisitionItems.length === 0)) {
      const found = purchaseOrderInfos.find((po) => po.id === Number(purchaseOrderId))
      if (!!found) {
        const paidAmount = found.paymentTransactions.reduce<number>((sum, transaction) => sum + transaction.total, 0)
        setSelected({
          ...found,
          payTotal: found.total - paidAmount,
        })
      }
    }
    // eslint-disable-next-line
  }, [setSelected, purchaseOrderId])

  // handle
  const handlePaymentRequisitionSelectedItemsChange = (value: PaymentRequisitionSelectedItem[]) => {
    if (value.length > 0) {
      clearErrors('paymentRequisitionItems')
    }
    setValue('paymentRequisitionItems', [...value])
  }

  return (
    <>
      {!!selected && (
        <PaymentRequisitionSelectedDialog
          open={!!selected}
          value={selected}
          onComplete={(data) => {
            setSelected(undefined)
            handlePaymentRequisitionSelectedItemsChange([data])
          }}
          onClose={() =>
            !!purchaseOrderId ? history.push(`/purchase-orders/${purchaseOrderId}/profile`) : history.goBack()
          }
        />
      )}
      <Grid style={{ marginBottom: 24 }} container spacing={2}>
        <Grid item xs={12}>
          <Topic>ทำเบิก</Topic>
        </Grid>
        <Grid item xs={12}>
          <VendorProfileCard value={vendorInfo} />
        </Grid>
        {!isSinglePO && (
          <Grid item xs={12} id="selector-box">
            <PaymentRequisitionSelectorBox
              currency={paymentRequisitionCreateData.vendorInfo.currency}
              purchaseOrderInfos={purchaseOrderInfos}
              onChange={handlePaymentRequisitionSelectedItemsChange}
              value={paymentRequisitionItems ?? []}
              errorMessage={!!errors.paymentRequisitionItems ? 'กรุณาเลือกใบสั่งซื้อเพื่อทำการเบิก' : undefined}
            />
          </Grid>
        )}
        {!!paymentRequisitionItems && paymentRequisitionItems.length > 0 && (
          <>
            <Grid item xs={12}>
              <PaymentRequisitionItemTable
                value={paymentRequisitionItems ?? []}
                onChange={handlePaymentRequisitionSelectedItemsChange}
              />
            </Grid>
            <Grid item xs={12}>
              <PaymentRequisitionTotalBox
                selectedItems={paymentRequisitionItems ?? []}
                currency={paymentRequisitionCreateData.vendorInfo.currency}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <PaymentRequisitionFormBox vendorInfo={vendorInfo} />
        </Grid>
        <Grid item xs={12}>
          <SubmitForm goBack submitText="ต่อไป" onCancel={onBack} />
        </Grid>
      </Grid>
    </>
  )
}

const VerificationPage = (props: PageProps) => {
  // props
  const { paymentRequisitionCreateData, onBack } = props
  const { vendorInfo } = paymentRequisitionCreateData
  const { bankAccounts } = vendorInfo

  // react hook form
  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<PaymentRequisitionCreateFormValues>()
  const { paymentMethodId, vendorBankAccountId, exchangeRate, paymentRequisitionItems } = watch()

  const withholdingTaxTotal = !!paymentRequisitionItems
    ? paymentRequisitionItems.reduce<number>((sum, payment) => {
        return sum + (!!payment.payWithholdingTaxAmount ? payment.payWithholdingTaxAmount : 0)
      }, 0)
    : 0

  const paidTotal = !!paymentRequisitionItems
    ? paymentRequisitionItems.reduce<number>((sum, payment) => {
        return sum + Number(payment.payTotal)
      }, 0)
    : 0

  const mapToInformationBox = (): PaymentRequisitionInformation => {
    return {
      paymentMethod: paymentMethods.find((pm) => pm.id === paymentMethodId)!,
      vendorBankAccount: bankAccounts.find((vb) => vb.id === vendorBankAccountId)!,
      withholdingTaxAmount: withholdingTaxTotal, // TODO: calcuclate from items
      exchangeRate,
      total: paidTotal, // TODO: calculate from items,
      currency: vendorInfo.currency,
    }
  }

  return (
    <Grid style={{ marginBottom: 24 }} container spacing={2}>
      <Grid item xs={12}>
        <Topic>ทำเบิก</Topic>
      </Grid>
      <Grid item xs={12}>
        <VendorProfileCard value={vendorInfo} />
      </Grid>
      {!!paymentRequisitionItems && paymentRequisitionItems.length > 0 && (
        <Grid item xs={12}>
          <PaymentRequisitionItemTable value={paymentRequisitionItems ?? []} readOnly={true} />
        </Grid>
      )}
      <Grid item xs={12}>
        <PaymentRequisitionInformationBox paymentRequisitionInformation={mapToInformationBox()} />
      </Grid>
      <Grid item xs={12}>
        <SubmitForm
          submitText="ยืนยันสร้าง"
          cancelText="ย้อนกลับไปแก้ไขข้อมูล"
          onCancel={onBack}
          isSubmitting={isSubmitting}
        />
      </Grid>
    </Grid>
  )
}
