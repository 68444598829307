import { Paper, Typography } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'
import React, { useState } from 'react'
import { PaymentRequisitionProfile } from '../../../api/smartbestbuys-api'
import { CustomButton, FlexBox, Grid, SubTopic } from '../../../custom-components'
import PaymentRequisitionApproveDialog from './PaymentRequisitionApproveDialog'
import PaymentRequisitionRejectDialog from './PaymentRequisitionRejectDialog'

import './PaymentRequisitionVerification.less'

interface PaymentRequisitionInfo {
  verificationList?: string[]
  id: number
  code: string
}

interface Props {
  paymentRequisitionInfo: PaymentRequisitionInfo
  onApproved?: (data: PaymentRequisitionProfile) => void
  onRejected?: (data: PaymentRequisitionProfile) => void
}

const PaymentRequisitionVerificationBox: React.FC<Props> = (props: Props) => {
  // props
  const { paymentRequisitionInfo, onApproved, onRejected } = props
  const { verificationList } = paymentRequisitionInfo

  //state
  const [approveOpen, setApproveOpen] = useState(false)
  const [rejectOpen, setRejectOpen] = useState(false)

  return (
    <Paper>
      <PaymentRequisitionApproveDialog
        open={approveOpen}
        onClose={() => setApproveOpen(false)}
        onComplete={onApproved}
        paymentRequisitionInfo={paymentRequisitionInfo}
      />
      <PaymentRequisitionRejectDialog
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onComplete={onRejected}
        paymentRequisitionInfo={paymentRequisitionInfo}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} className="payment-requisition-verification__check-list__header">
          <SubTopic>ข้อมูลที่ต้องรีวิว</SubTopic>
          <FlexBox>
            <CustomButton variant="contained" color="error" onClick={() => setRejectOpen(true)} classNames={['pr-2']}>
              <Cancel fontSize="small" className="mr-2" /> ไม่อนุมัติ
            </CustomButton>
            <span className="mr-2" />
            <CustomButton variant="contained" color="success" onClick={() => setApproveOpen(true)}>
              <CheckCircle fontSize="small" className="mr-2" /> อนุมัติ
            </CustomButton>
          </FlexBox>
        </Grid>

        <Grid item xs={12}>
          <ul className="payment-requisition-verification__check-list__list">
            {verificationList?.map((item, index) => (
              <li key={`list-${index}`} className="ml-8">
                <Typography color="textSecondary">{item}</Typography>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PaymentRequisitionVerificationBox
