import { useEffect, useState } from 'react'
import {
  getCustomerRequisitionsPendingPickupAPI,
  IPendingPickupCustomerRequisitionTableData,
} from '../../api/smartbestbuys-api'

export const useCustomerRequisitionPendingPickup = (): IPendingPickupCustomerRequisitionTableData[] | undefined => {
  const [data, setData] = useState<IPendingPickupCustomerRequisitionTableData[] | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getCustomerRequisitionsPendingPickupAPI()
      const data = response?.data ?? []

      setData(data)
    })()
  }, [data?.length])

  return data
}
