import React from 'react'
import { Avatar, Box, Button, Grid, Paper } from '@material-ui/core'
import {
  PurchaseOrderProfile,
  PurchaseOrderUpsertData_VendorInfo,
  VendorContactInfo,
} from '../../../api/smartbestbuys-api/purchase-order/types'
import './VendorProfileCard.less'
import { FlexBox, SubTopic, Title, TypographyWithLabel } from '../../../custom-components'
import {
  AccessAlarmsTwoTone,
  BusinessTwoTone,
  CommentTwoTone,
  MonetizationOnTwoTone,
  DesktopWindowsTwoTone,
  EmailTwoTone,
  LocalPhoneTwoTone,
  PermIdentityTwoTone,
  Star,
  LocalAtmTwoTone,
  AttachMoney,
} from '@material-ui/icons'
import { BaseData } from '../../../api/smartbestbuys-api'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { getUserPermissionInfo } from '../../../utils/permission'
import { ConditionTooltip } from '../../../shared-components'

interface VendorProfileCardValue {
  id: number
  profileImageUrl?: string
  code: string
  name: string
  referenceNumber: string
  averageRating?: number
  phoneNumber: string
  email?: string
  websiteUrl?: string
  scopeWork?: string
  remark?: string
  creditPeriodDay?: number
  refPaymentMethod?: BaseData
  isVatVendor?: boolean

  purchasedAmount?: number
  // TODO: this one should be mandatory
  currency?: BaseData
  contactInfo?: VendorContactInfo
}

interface ActionButtonProps {
  text: string
  color?: 'secondary'
  suffixIcon?: JSX.Element
  onClick: () => void
}

interface Options {
  actionButton?: ActionButtonProps
}

interface Props {
  value: VendorProfileCardValue
  options?: Options
}

const VendorProfileCard: React.FC<Props> = (props) => {
  // context
  const { value, options } = props
  const { actionButton } = { ...options }

  // prepare data
  const fields = [
    { icon: <PermIdentityTwoTone />, label: 'เลขประจำตัวผู้เสียภาษี:', value: value.referenceNumber },
    { icon: <LocalPhoneTwoTone />, label: 'เบอร์โทร:', value: value.phoneNumber },
    { icon: <EmailTwoTone />, label: 'อีเมล:', value: value.email },
    { icon: <DesktopWindowsTwoTone />, label: 'เว็บไซต์:', value: value.websiteUrl },
    { icon: <BusinessTwoTone />, label: 'ขอบเขตงานที่รับ:', value: value.scopeWork },
    { icon: <MonetizationOnTwoTone />, label: 'สกุลเงินที่ใช้:', value: value.currency?.name },
    {
      icon: <AccessAlarmsTwoTone />,
      label: 'ระยะเวลาเครดิต:',
      value: !!value.creditPeriodDay ? value.creditPeriodDay + ' วัน' : undefined,
    },
    { icon: <LocalAtmTwoTone />, label: 'วิธีชำระเงินอ้างอิง:', value: value.refPaymentMethod?.name },
    { icon: <CommentTwoTone />, label: 'หมายเหตุ:', value: value.remark },
    {
      icon: <AttachMoney />,
      label: 'จดภาษีมูลค่าเพิ่ม:',
      value: value.isVatVendor !== undefined ? (value.isVatVendor ? 'จด' : 'ไม่จด') : undefined,
    },
  ]

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_Vendor_Write)

  // render
  return (
    <Box p={3} clone>
      <Paper id="vendor-profile-card">
        <Grid container>
          <Grid item xs={12} md={3}>
            <Avatar
              className="Avatar vendor-profile"
              alt="vendor profile"
              variant="square"
              src={value.profileImageUrl}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={12} container justify="space-between" alignItems="center">
                <Title id="vendor-profile-card__code-title" color="textSecondary">
                  {value.code} <Star fontSize="small" /> - {/** TODO: waiting from API */}
                </Title>
                {!!actionButton && (
                  <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
                    <Button
                      id="vendor-profile-card__action-button"
                      color={actionButton.color ?? 'primary'}
                      onClick={actionButton.onClick}
                      disabled={!writePermission.hasPermission}
                    >
                      {actionButton.text}
                      {actionButton.suffixIcon}
                    </Button>
                  </ConditionTooltip>
                )}
              </Grid>
              <Grid item xs={12}>
                <SubTopic>{value.name}</SubTopic>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {fields
                    .filter((field) => !!field.value)
                    .map(({ value, ...others }, index) => (
                      <Grid item xs={6} key={`field-${index}`}>
                        <TypographyWithLabel {...others}>{value}</TypographyWithLabel>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!!value.contactInfo && (
            <Grid item xs={12} id="vendor-profile-card__contact-info">
              <Title id="vendor-profile-card__contact-info__title">ผู้ติดต่อ</Title>
              <FlexBox p={2} clone>
                <Paper variant="outlined">
                  <Grid container>
                    <Grid item xs={7}>
                      <Title>{value.contactInfo.name}</Title>
                    </Grid>
                    <Grid item xs={5}>
                      <TypographyWithLabel icon={<LocalPhoneTwoTone />} label="เบอร์โทร:">
                        {value.contactInfo.phoneNumber}
                      </TypographyWithLabel>
                    </Grid>
                  </Grid>
                </Paper>
              </FlexBox>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  )
}

export default VendorProfileCard

export const mapPurchaseOrderProfileToValue = (purchaseOrderProfile: PurchaseOrderProfile): VendorProfileCardValue => {
  return {
    id: purchaseOrderProfile.vendorInfo.id,
    profileImageUrl: purchaseOrderProfile.vendorInfo.profileImageUrl,
    code: purchaseOrderProfile.vendorInfo.code,
    name: purchaseOrderProfile.vendorInfo.name,
    referenceNumber: purchaseOrderProfile.vendorInfo.referenceNumber,
    averageRating: purchaseOrderProfile.vendorInfo.averageRating,
    phoneNumber: purchaseOrderProfile.vendorInfo.phoneNumber,
    email: purchaseOrderProfile.vendorInfo.email,
    websiteUrl: purchaseOrderProfile.vendorInfo.websiteUrl,
    scopeWork: purchaseOrderProfile.vendorInfo.scopeWork,
    remark: purchaseOrderProfile.vendorInfo.remark,
    creditPeriodDay: purchaseOrderProfile.vendorInfo.creditPeriodDay,
    purchasedAmount: purchaseOrderProfile.vendorInfo.purchasedAmount,
    currency: purchaseOrderProfile.vendorInfo.currencyInfo,
    contactInfo: purchaseOrderProfile.vendorInfo.contactInfo,
  }
}

export const mapPurchaseOrderCreateToValue = (
  purchaseOrderCreate: PurchaseOrderUpsertData_VendorInfo,
  currency?: BaseData,
): VendorProfileCardValue => {
  return {
    id: purchaseOrderCreate.id,
    profileImageUrl: purchaseOrderCreate.profileImageUrl,
    code: purchaseOrderCreate.code,
    name: purchaseOrderCreate.name,
    referenceNumber: purchaseOrderCreate.referenceNumber,
    averageRating: purchaseOrderCreate.averageRating,
    phoneNumber: purchaseOrderCreate.phoneNumber,
    email: purchaseOrderCreate.email,
    websiteUrl: purchaseOrderCreate.websiteUrl,
    scopeWork: purchaseOrderCreate.scopeWork,
    remark: purchaseOrderCreate.remark,
    contactInfo: purchaseOrderCreate.contactInfo,
    currency: currency ?? purchaseOrderCreate.currency,
    creditPeriodDay: purchaseOrderCreate.creditPeriodDay,
    isVatVendor: purchaseOrderCreate.isVatVendor,
  }
}
