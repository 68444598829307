import { Grid, Topic, withLayout } from '../../../custom-components'
import useMyUserProfile from '../../../hooks/user/useMyUserProfile'
import { Loading } from '../../../shared-components'
import UserActivationCard from './UserActivationCard'
import UserCriteriaCard from './UserCriteriaCard'
import UserInformationCard from './UserInformationCard'
import UserPermissionCard from './UserPermissionCard'

const MyUserProfilePage = () => {
  const [myProfile, forceReload] = useMyUserProfile()
  if (!myProfile) return <Loading />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>โปรไฟล์</Topic>
      </Grid>
      <Grid item xs={12}>
        <UserInformationCard userProfile={myProfile} onUpdate={forceReload} />
      </Grid>
      <Grid item xs={12}>
        <UserCriteriaCard userProfile={myProfile} owner={true} onUpdate={forceReload} />
      </Grid>
      <Grid item xs={12}>
        <UserPermissionCard userProfile={myProfile} readOnly={true} />
      </Grid>
      <Grid item xs={12}>
        <UserActivationCard userProfile={myProfile} readOnly={true} onUpdate={forceReload} />
      </Grid>
    </Grid>
  )
}

export default withLayout(MyUserProfilePage)
