import { FC } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font } from '@react-pdf/renderer'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
  PDFTableCellView,
} from '../../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../../assets/images/new-logo.png'
import { useDocument } from '../../../../../hooks'
import numeral from 'numeral'
import { ThaiBaht } from 'thai-baht-text-ts'
import { BillingNoteState } from '../../../../../api/smartbestbuys-api'
import { dateFormat, getAddressText } from '../../../../../functions'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 52,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 272,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 12,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 12,
    marginBottom: 4,
    marginTop: 4,
  },
})

const formatDecimal = (number: number) => numeral(number).format('0,0.00')

const BillingNotePDF: FC<RouteComponentProps<any>> = (props) => {
  const { id } = props.match.params
  const billingNote = useDocument<BillingNoteState>(id)

  if (billingNote) {
    document.title = `ใบวางบิล ${billingNote?.code}`

    const { billingAddress } = billingNote

    return (
      <SoraPDFViewer>
        <Document>
          <Page size="A4" style={styles.page}>
            {/* Header */}
            <PDFRow style={styles.spaceBetween} fixed>
              <PDFColumn columnSpan={2}>
                <Image style={styles.logo} src={smartBestBuysLogo} />
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <PDFView style={styles.contactPointBox}>
                  <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                  <PDFView style={styles.textMini}>
                    <Text>www.smartbestbuys.com</Text>
                    <Text>
                      <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>
                      0105548090347
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ที่อยู่: </Text>519/174-6 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ
                      กรุงเทพฯ 10140
                    </Text>
                    <PDFView style={styles.spaceBetween}>
                      <Text>
                        <Text style={styles.bold}>โทร: </Text>02-1147006
                      </Text>
                      <Text>
                        <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                      </Text>
                    </PDFView>
                    <PDFView style={styles.spaceBetween}>
                      <Text>
                        <Text style={styles.bold}>อีเมล: </Text>
                        sale@smartbestbuys.com
                      </Text>
                      <Text>
                        <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                      </Text>
                    </PDFView>
                  </PDFView>
                </PDFView>
              </PDFColumn>
              <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
                <PDFInfo label="เลขที่" direction="row" dataWidth={88} noMargin>
                  {billingNote.code}
                </PDFInfo>
                <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                  {dateFormat(billingNote.recCreatedAt)}
                </PDFInfo>
              </PDFColumn>
            </PDFRow>
            {/* Title */}
            <Text style={styles.topic} fixed>
              ใบวางบิล
            </Text>
            {/* SubHeader */}
            <PDFRow borderColor="light">
              <PDFColumn columnSpan={8}>
                <PDFInfo label="ชื่อลูกค้า">
                  {billingAddress?.overrideDocumentData?.customerName || billingNote.customerInfo.name}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={4}>
                <PDFInfo label="เลขประจำตัวผู้เสียภาษี">
                  {billingAddress?.overrideDocumentData?.taxNumber || billingNote.customerInfo.referenceNumber}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={12}>
                <PDFInfo label="ที่อยู่">
                  {!!billingAddress
                    ? billingAddress.overrideDocumentData?.address || getAddressText(billingAddress)
                    : billingNote.customerInfo.address}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={4}>
                <PDFInfo label="โทรศัพท์">{billingNote.customerInfo.phoneNumber}</PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={8}>
                <PDFInfo label="โทรสาร">{billingAddress?.faxNumber || '-'}</PDFInfo>
              </PDFColumn>
            </PDFRow>

            <PDFTable>
              {/* Head Table */}
              <PDFTableRow bottomLine topLine>
                <PDFTableCell head width={44} textAlign="center">
                  ลำดับ
                </PDFTableCell>
                <PDFTableCell head width={231}>
                  เลขที่ใบกำกับภาษีอ้างอิง
                </PDFTableCell>
                <PDFTableCell head width={88} textAlign="center">
                  วันที่เอกสาร
                </PDFTableCell>
                <PDFTableCell head width={88} textAlign="center">
                  วันครบกำหนด
                </PDFTableCell>
                <PDFTableCell head width={100} textAlign="right">
                  จำนวนเงิน
                </PDFTableCell>
              </PDFTableRow>
              {/* Content Table */}
              {billingNote.state?.items.map((item, index) => (
                <PDFTableRow bottomLine>
                  <PDFTableCell width={44} textAlign="center">
                    {index + 1}
                  </PDFTableCell>
                  <PDFTableCellView width={231}>
                    <Text>{item.name}</Text>
                  </PDFTableCellView>
                  {/* TODO: add createAt date + expired date */}
                  <PDFTableCell width={88} textAlign="center">
                    {item.issuedDate ? dateFormat(item.issuedDate) : undefined}
                  </PDFTableCell>
                  <PDFTableCell width={88} textAlign="center">
                    {item.expiredDate ? dateFormat(item.expiredDate) : undefined}
                  </PDFTableCell>
                  <PDFTableCell width={100} textAlign="right">
                    {formatDecimal(item.total || 0)}
                  </PDFTableCell>
                </PDFTableRow>
              ))}
            </PDFTable>

            {/* Footer Table */}
            <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
              <PDFView>
                <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                  <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(billingNote.total || 0)}</PDFInfo>
                </PDFView>
                <PDFView style={styles.tableFooter}>
                  <PDFInfo label="หมายเหตุ">{billingNote.state?.remark || '-'}</PDFInfo>
                </PDFView>
              </PDFView>
              <PDFView style={styles.summaryBox}>
                {billingNote.state?.priceBreakdowns.map((item) => (
                  <PDFInfo key={item.label} label={item.label} direction="row" dataWidth={188}>
                    {numeral(item.amount).format('0,0.00')}
                  </PDFInfo>
                ))}
              </PDFView>
            </PDFView>

            <PDFView bottomLine="lightGrey" style={{ padding: '8px 0px', fontSize: 12, lineHeight: 1.2 }}>
              <Text>
                1. ใบเสร็จจะสมบูรณ์ ต่อเมื่อได้รับเงินเรียบร้อยแล้ว
                หรือการชำระโดยเช็คใบเสร็จจะสมบูรณ์ต่อเมื่อได้เรียกเก็บจากธนาคารแล้ว
              </Text>
              <Text>2. โปรดสั่งจ่ายเช็คและขีดคร่อมในนามบริษัทฯ เท่านั้น</Text>
              <Text>
                3. กรณีโอนชำระผ่านธนาคาร กรุณายืนยันหลักฐานการโอนชำระทุกครั้ง เพื่อความถูกต้องในการออกใบเสร็จรับเงิน{' '}
              </Text>
            </PDFView>

            <PDFRow>
              <PDFColumn columnSpan={3}>
                <Text style={{ fontFamily: 'THSarabunNewBold', marginBottom: 8 }}>วันนัดชำระ</Text>
                <Text style={{ marginBottom: 8 }}>วันที่ ......../......../............</Text>
                <Text>เวลา ...... : .......</Text>
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="ผู้รับวางบิล" />
              </PDFColumn>
              <PDFColumn columnSpan={3} borders={['left']} borderColor={'light'} style={{ paddingLeft: 12 }}>
                <PDFSignatureView
                  title="ผู้วางบิล"
                  endorser={billingNote.recCreatedBy}
                  date={billingNote.recCreatedAt}
                />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView
                  title="ผู้อนุมัติ"
                  endorser={billingNote.recCreatedBy}
                  date={billingNote.recCreatedAt}
                />
              </PDFColumn>
            </PDFRow>

            <PDFPagination />
          </Page>
        </Document>
      </SoraPDFViewer>
    )
  } else {
    return <Loading />
  }
}

export default BillingNotePDF
