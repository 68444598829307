import React from 'react'

import { useHistory } from 'react-router-dom'

import { Grid, Button, CircularProgress } from '@material-ui/core'
import { ConditionTooltip } from '..'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { getUserPermissionInfo } from '../../utils/permission'

export interface SubmitProps {
  isSubmitting?: boolean
  submitText?: string
  onSubmit?: () => void
  cancelText?: string
  onCancel?: () => void
  hideCancelButton?: boolean
  redirectPath?: string
  disableSubmit?: boolean
  submitPermission?: PermissionEnum
  submitButtonType?: 'button'
  goBack?: boolean
}

const SubmitForm: React.FC<SubmitProps> = (props) => {
  const {
    submitText,
    cancelText,
    onSubmit,
    onCancel,
    hideCancelButton,
    goBack,
    disableSubmit,
    redirectPath,
    isSubmitting,
    submitButtonType,
    submitPermission,
  } = props

  const history = useHistory()

  const handleCancel = () => {
    if (redirectPath) {
      history.push(redirectPath)
      return
    }
    if (goBack) history.goBack()
    onCancel && onCancel()
  }

  if (submitPermission) {
    // permission
    const writePermission = getUserPermissionInfo(submitPermission)

    return (
      <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
        {!hideCancelButton && (
          <Grid item>
            <Button color="primary" onClick={handleCancel}>
              {cancelText || 'ยกเลิก'}
            </Button>
          </Grid>
        )}
        <Grid item>
          <ConditionTooltip
            showTooltip={!!writePermission && !writePermission.hasPermission}
            title={!!writePermission ? writePermission?.alertMessage : ''}
          >
            <Button
              startIcon={isSubmitting ? <CircularProgress size={16} /> : undefined}
              variant="contained"
              color="primary"
              type={submitButtonType ?? 'submit'}
              onClick={onSubmit}
              disabled={isSubmitting || disableSubmit || (!!writePermission && !writePermission.hasPermission)}
            >
              {submitText || 'ยืนยัน'}
            </Button>
          </ConditionTooltip>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
      {!hideCancelButton && (
        <Grid item>
          <Button color="primary" onClick={handleCancel}>
            {cancelText || 'ยกเลิก'}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          startIcon={isSubmitting ? <CircularProgress size={16} /> : undefined}
          variant="contained"
          color="primary"
          type={submitButtonType ?? 'submit'}
          onClick={onSubmit}
          disabled={isSubmitting || disableSubmit}
        >
          {submitText || 'ยืนยัน'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SubmitForm
