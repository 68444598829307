import { useState, useEffect } from 'react'
import { format, startOfYear } from 'date-fns'
import { IProductYearlyReport, getProductYearlyReportAPI } from '../../api/smartbestbuys-api'

export const useProductYearlyReport = (date: Date, setLoading: any): IProductYearlyReport[] | undefined => {
  const [data, setData] = useState<IProductYearlyReport[] | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const response = await getProductYearlyReportAPI({
        targetYear: format(startOfYear(date), 'yyyy'),
      })
      if (response) {
        setData(response?.data)
      }
      setLoading(false)
    })()
  }, [date, setLoading])

  return data
}
