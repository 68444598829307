import React, { useContext, useState } from 'react'
import { Paper, Grid, Button, Tooltip, Typography } from '@material-ui/core'
import { EmptyCard, FlexBox, Link, SubTopic, Table } from '../../../../custom-components'
import { ConditionTooltip, Loading, PurchaseRequisitionStatusChip } from '../../../../shared-components'
import SaleOrderProfileContext from './SaleOrderProfileContext'
import { dateFormat, dateTimeFormat } from '../../../../functions'
import { Description } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { SaleOrderStatus } from '../../../../enums'
import { SOProfilePurchaseRequisition } from '../../../../api/smartbestbuys-api'
import './SOPurchaseRequisitionTable.less'
import SOPurchaseOrdersDialog from './SOPurchaseOrdersDialog'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { getUserPermissionInfo } from '../../../../utils/permission'

const SOPurchaseRequisitionTable: React.FC = (props) => {
  //context & state
  const { saleOrderProfile /* insufficientProduct */ } = useContext(SaleOrderProfileContext)
  const [purchaseRequisitionIdReferencePO, setPurchaseRequisitionIdReferencePO] = useState(0)
  const history = useHistory()
  if (!saleOrderProfile) {
    return <Loading />
  }

  const prReadPermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Read)
  const prWritePermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Write)

  //calculate
  const soStatusGroupNotOpenPR = [SaleOrderStatus.Closed, SaleOrderStatus.ClosedAtShop, SaleOrderStatus.Cancelled]
  const showedCreatePurchaseRequisitionButton = !soStatusGroupNotOpenPR.includes(saleOrderProfile.status.id)
  // TODO: uncomment after refactor product system
  // && insufficientProduct
  const purchaseRequisitions = !!saleOrderProfile.purchaseRequisitions ? saleOrderProfile.purchaseRequisitions : []
  return (
    <Paper>
      {!!purchaseRequisitionIdReferencePO && (
        <SOPurchaseOrdersDialog
          open={!!purchaseRequisitionIdReferencePO}
          purchaseRequisitionId={purchaseRequisitionIdReferencePO}
          onClose={() => {
            setPurchaseRequisitionIdReferencePO(0)
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <SubTopic>รายการคำขอซื้อของใบสั่งขายนี้</SubTopic>
            {showedCreatePurchaseRequisitionButton && (
              <ConditionTooltip title={prWritePermission.alertMessage} showTooltip={!prWritePermission.hasPermission}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Description />}
                  disabled={!prWritePermission.hasPermission}
                  onClick={() => history.push(`/sale-orders/${saleOrderProfile.id}/create-purchase-requisition`)}
                >
                  สร้างคำขอชื้อ
                </Button>
              </ConditionTooltip>
            )}
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          {purchaseRequisitions.length > 0 ? (
            <Table<SOProfilePurchaseRequisition>
              color="primary.dark"
              size="small"
              columns={[
                {
                  name: 'สถานะคำขอซื้อ',
                  render: ({ status }) => (
                    <PurchaseRequisitionStatusChip size="small" status={status.id} label={status.name} />
                  ),
                },
                {
                  name: 'เลขที่คำขอซื้อ',
                  render: ({ id, code }) => {
                    return prReadPermission.hasPermission ? (
                      <Link color="primary" to={`/purchase-requisitions/${id}/profile`}>
                        {code}
                      </Link>
                    ) : (
                      <Typography>{code}</Typography>
                    )
                  },
                  dataIndex: 'id',
                  sort: true,
                },
                {
                  name: 'การเปิดใบสั่งซื้อ',
                  render: ({ id, purchaseOrders }) => {
                    const document =
                      !!purchaseOrders && purchaseOrders.length > 0 ? (
                        <Tooltip title="เปิดดูใบสั่งซื้อ">
                          <Description
                            fontSize="default"
                            className="document__icon-green"
                            onClick={() => setPurchaseRequisitionIdReferencePO(id)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="ยังไม่มีการเปิดใบสั่งซื้อ">
                          <Description fontSize="default" className="document__icon-grey" />
                        </Tooltip>
                      )
                    return document
                  },
                  align: 'center',
                },
                {
                  name: 'สร้างโดย',
                  dataIndex: 'recCreatedBy',
                },
                {
                  name: 'ตรวจสอบโดย',
                  render: ({ latestVerifiedBy }) => {
                    const latestVerify = !!latestVerifiedBy ? latestVerifiedBy : '-'
                    return latestVerify
                  },
                },
                {
                  name: 'วันที่ต้องการ สินค้า/บริการ',
                  dataIndex: 'dueDate',
                  render: ({ dueDate }) => {
                    if (dueDate) {
                      return dateFormat(dueDate)
                    }
                  },
                  sort: true,
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                  dataIndex: 'recCreatedAt',
                  sort: true,
                },
              ]}
              data={purchaseRequisitions}
              pagination={false}
              cleanSpace
            />
          ) : (
            <EmptyCard emptyText="ยังไม่มีคำขอซื้อ" />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SOPurchaseRequisitionTable
