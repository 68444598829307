import { useContext } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import { CustomerRequisitionProfileContext } from './CustomerRequisitionProfilePage'
import { Loading } from '../../../shared-components'
import { FilteredTable, SubTopic } from '../../../custom-components'
import { ICustomerRequisitionProfile_Activity } from '../../../api/smartbestbuys-api'

const CustomerRequisitionUserActivity: React.FC = (props) => {
  const customeRequisitionProfile = useContext(CustomerRequisitionProfileContext)

  if (!customeRequisitionProfile) {
    return <Loading />
  }

  const { activities } = customeRequisitionProfile

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>ประวัติการจัดการคำร้อง</SubTopic>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<ICustomerRequisitionProfile_Activity>
            color="grey.800"
            size="small"
            columns={[
              { name: 'รายละเอียดการจัดการ', render: ({ action }) => action.name },
              { name: 'รายละเอียดเพิ่มเติม', render: ({ actionMessage }) => actionMessage?.name, hide: true },
              { name: 'จัดการโดย', dataIndex: 'recCreatedBy' },
              {
                name: 'วันที่',
                render: ({ recCreatedAt }) => format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
              },
              { name: 'หมายเหตุ', dataIndex: 'remark' },
            ]}
            disableDateRangeFilter
            data={activities || []}
            cleanSpace
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerRequisitionUserActivity
