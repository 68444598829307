import React from 'react'
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Card,
  CardMedia,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { SubTopic } from '../../custom-components'
import { AttachFile, Cancel } from '@material-ui/icons'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import SOCreationFormValues, { SOAttachment } from '../pages/SOCreationPage/SOCreationFormValues'
import { fileToBase64, compressImage } from '../../functions'

const useStyles = makeStyles((theme) =>
  createStyles({
    uploadCard: {
      width: 160,
      height: 200,
      border: `1px dashed ${theme.palette.primary.main}`,
    },
    card: {
      width: 160,
      height: 200,
      padding: theme.spacing(2),
      position: 'relative',
    },
    media: {
      height: 0,
      paddingTop: '80%', // 16:9
    },
    mediaRemoveButton: {
      position: 'absolute',
      right: -12,
      top: -12,
    },
    input: {
      display: 'none',
    },
    root: {
      position: 'relative',
    },
    loadingMessage: {
      position: 'absolute',
      zIndex: 5,
      top: 0,
      left: 0,
      opacity: '80%',
      backgroundColor: 'whitesmoke',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
)

interface Props {
  readOnly?: boolean
}

const limit = 5

const AttachmentArea: React.FC<Props> = (props) => {
  const { readOnly } = props
  const classes = useStyles()
  const { watch, setValue, formState } = useFormContext<SOCreationFormValues>()

  const { attachments } = watch()

  const hasAttachments = attachments && attachments.length > 0
  const overLimit = attachments && attachments.length >= limit

  return (
    <Box padding={2} clone>
      <Paper className={classes.root}>
        {formState.isSubmitting && (
          <Card className={classes.loadingMessage}>
            <CircularProgress color="secondary" className="w-5 h-5" />
            <span className="mr-2" />
            <Typography color="secondary" variant="h5">
              กำลังอัปโหลดรูปภาพ...
            </Typography>
          </Card>
        )}
        <SubTopic gutterBottom>แนบไฟล์เพิ่มเติม</SubTopic>
        <Box display="flex" overflow="auto" gridRowGap={16} flexWrap="wrap">
          {!readOnly && !overLimit && (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mr={2} clone>
              <Card className={classes.uploadCard}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="icon-button-file"
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    const fileList = e.target.files
                    // console.log(attachments, fileList)
                    if (fileList) {
                      const newAttachments: SOAttachment[] = await Promise.all(
                        Array.from(fileList).map(async (file) => {
                          const compressed = await compressImage(file, 0.5)
                          const compressed64 = await fileToBase64(compressed)
                          return {
                            name: file.name,
                            src: compressed64,
                          }
                        }),
                      )
                      if (attachments && attachments.length > 0) {
                        const newFiles = _.uniqBy([...attachments, ...newAttachments], 'name')
                        setValue('attachments', [...newFiles])
                        return
                      }
                      setValue('attachments', newAttachments)
                    }
                  }}
                  type="file"
                />
                <label htmlFor="icon-button-file">
                  <IconButton aria-label="upload picture" component="span">
                    <AttachFile style={{ fontSize: 60 }} />
                  </IconButton>
                </label>
                <Typography color="primary">เลือกไฟล์ภาพที่ต้องการ</Typography>
              </Card>
            </Box>
          )}
          {hasAttachments &&
            attachments?.map((file, index) => {
              return (
                <Box key={file.name} mr={2} clone>
                  <Card variant="outlined" className={classes.card}>
                    {!readOnly && (
                      <IconButton
                        className={classes.mediaRemoveButton}
                        aria-label="remove picture"
                        onClick={() => {
                          let updatedAttatchments = attachments
                          updatedAttatchments.splice(index, 1)
                          setValue('attachments', updatedAttatchments)
                        }}
                      >
                        <Cancel fontSize="large" />
                      </IconButton>
                    )}
                    <CardMedia className={classes.media} image={file.src} title={file.name} />
                    <Typography color="primary">{file.name}</Typography>
                  </Card>
                </Box>
              )
            })}
        </Box>
      </Paper>
    </Box>
  )
}
export default AttachmentArea
