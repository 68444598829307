import { FormControlLabel, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { CheckboxForm } from '../../../../../../custom-components'
import SOUpdationFormValue from '../SOUpdationFormValues'

const DepositOptionBox: React.FC = () => {
  const { control, getValues } = useFormContext<SOUpdationFormValue>()
  const { depositRequired } = getValues()

  return (
    <Paper>
      <FormControlLabel
        control={<CheckboxForm checked={depositRequired} control={control} name="depositRequired" />}
        label={<Typography variant="h5">ต้องการมัดจำก่อนซื้อ</Typography>}
      />
    </Paper>
  )
}

export default DepositOptionBox
