import React, { useContext } from 'react'
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core'
import { FlexBox, Form, GeneralTextFieldForm, TypographyWithLabel } from '../../../custom-components'
import { ImageCard, SubmitForm, SubmitWithConfirmTextForm, UploadImageCard } from '../../../shared-components'
import { FormProvider, useForm } from 'react-hook-form'
import {
  BaseData,
  finishTransportTaskAPI,
  FinishTransportTaskRequestBody,
  TransportShipingTaskState,
  TransportSubTaskState,
} from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import { TaskStatus } from '../../../enums'

interface Values {
  remark?: string
  attachmentUrl?: string
}

interface Props {
  data: {
    id: number
    pickupTaskStatus: BaseData
    pickupTaskState: TransportSubTaskState & TransportShipingTaskState
  }

  readOnly: boolean

  onSuccess?: (data: any) => void
  onFail?: () => void
}

const ConfirmPickupResultDialog: React.FC<Props & DialogProps> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { data, readOnly, onSuccess, onFail, onClose, ...dialogProps } = props
  const { pickupTaskStatus, pickupTaskState } = data

  const { failureMessages, pickupResult } = pickupTaskState

  const formMethod = useForm<Values>({
    defaultValues: {
      remark: pickupResult?.remark,
      attachmentUrl: pickupResult?.attachmentUrl,
    },
  })

  const {
    control,
    setValue,
    formState: { isSubmitting },
  } = formMethod

  const handleSubmit = async (values: Values) => {
    const requestBody: FinishTransportTaskRequestBody = {
      pickupResult: {
        remark: values.remark,
        attachmentUrl: values.attachmentUrl ? values.attachmentUrl : '', // todo: create component for upload picture @front end
      },
    }

    const response = await finishTransportTaskAPI(data.id, requestBody)
    if (response?.status === 200) {
      if (onSuccess) onSuccess(response.data)
      triggerSnackbar('จบงานสำเร็จ')
    } else {
      if (onFail) onFail()
      triggerSnackbar('จบงานไม่สำเร็จ')
    }
    return true
  }

  const handleSubmitWithPickupResult = async () => {
    const requestBody: FinishTransportTaskRequestBody = {
      pickupResult: pickupTaskStatus.id === TaskStatus.Done ? pickupResult : undefined,
    }

    const response = await finishTransportTaskAPI(data.id, requestBody)
    if (response?.status === 200) {
      if (onSuccess) onSuccess(response.data)
      triggerSnackbar('จบงานสำเร็จ')
    } else {
      if (onFail) onFail()
      triggerSnackbar('จบงานไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="md">
      {readOnly ? (
        <>
          <DialogTitle>จบงานขนส่ง</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TypographyWithLabel label="ผลการขนส่ง:" color={failureMessages ? 'error' : 'textSecondary'}>
                  {failureMessages ? 'ขนส่งไม่สำเร็จ' : 'ขนส่งสำเร็จ'}
                </TypographyWithLabel>
              </Grid>
              {!failureMessages && !!pickupResult && (
                <>
                  <Grid item xs={12}>
                    {!!pickupResult.remark && (
                      <TypographyWithLabel label="หมายเหตุ: ">{pickupResult.remark}</TypographyWithLabel>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <ImageCard name="หลักฐาน" src={pickupResult.attachmentUrl} />
                  </Grid>
                </>
              )}
              {failureMessages && (
                <>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="สาเหตุของการส่งของไม่สำเร็จ:">
                      {failureMessages.failed}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="หมายเหตุ:">{failureMessages.remark ?? '-'}</TypographyWithLabel>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitWithConfirmTextForm
              confirmTextOption={
                !failureMessages
                  ? {
                      veifiedText: 'ยืนยัน',
                    }
                  : undefined
              }
              onSubmit={handleSubmitWithPickupResult}
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        </>
      ) : (
        <FormProvider {...formMethod}>
          <Form onSubmit={handleSubmit}>
            <DialogTitle>จบงานรับของ</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
                </Grid>
                <Grid item xs={12}>
                  <FlexBox justifyContent="center" gridGap={8}>
                    <UploadImageCard
                      title="อัปโหลดรูปหลักฐาน (ถ้ามี)"
                      defaultSrc={pickupResult?.attachmentUrl}
                      onChange={({ base64 }) => setValue('attachmentUrl', base64)}
                    />
                  </FlexBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SubmitForm
                submitText="ยืนยันจบงาน"
                onCancel={() => {
                  if (!!onClose) onClose({}, 'escapeKeyDown')
                }}
                isSubmitting={isSubmitting}
              />
            </DialogActions>
          </Form>
        </FormProvider>
      )}
    </Dialog>
  )
}

export default ConfirmPickupResultDialog
