import {
  GetSaleOrderTableDataRequestQueryParam,
  ISaleOrderTableDataSummary,
  ISaleOrderTableData_Data,
  ISaleOrderTableData_SearchParams,
} from '../../api/smartbestbuys-api/sale/sale-types'
import { getSaleOrderTableDataAPI } from '../../api/smartbestbuys-api/sale/sale-apis'
import { useTableData } from '../useTablaData'
import { TableDataQueryParams } from '../../api/smartbestbuys-api'
import moment from 'moment'

type SearchQueryParams = Omit<GetSaleOrderTableDataRequestQueryParam, keyof TableDataQueryParams>

const buildQueryParams = (search: ISaleOrderTableData_SearchParams): SearchQueryParams => {
  return {
    code: search.code,
    customerName: search.customerName,
    phoneNumber: search.phoneNumber,
    email: search.email,
    assigneeUserId: search.assigneeUserId?.toString(),
    customerPurchaseReference: search.customerPurchaseReference,
    statusList: search.statusList?.join(','),
    paymentStatusList: search.paymentStatusList?.join(','),
    stockReadyToShip: search.stockReadyToShip?.toString(),
    creditNotePending: search.creditNotePending ? 'true' : undefined,
    reclaimProductPending: search.reclaimProductPending ? 'true' : undefined,
    refundPending: search.refundPending ? 'true' : undefined,
    hasActivePO: search.hasActivePO?.toString(),
    hasActiveShippingTask:
      search.hasActiveShippingTask !== undefined ? search.hasActiveShippingTask.toString() : undefined,
    startDate: search.startDate ? moment(search.startDate).startOf('day').toDate().toISOString() : undefined,
    endDate: search.endDate ? moment(search.endDate).endOf('day').toDate().toISOString() : undefined,
    needToFollow: search.needToFollow ? 'true' : undefined,
  }
}

export const useSaleOrderTableData = (defaultSerachParams: Partial<ISaleOrderTableData_SearchParams>) =>
  useTableData<
    ISaleOrderTableData_Data,
    ISaleOrderTableDataSummary,
    ISaleOrderTableData_SearchParams,
    SearchQueryParams
  >(0, 10, defaultSerachParams, getSaleOrderTableDataAPI, buildQueryParams)
