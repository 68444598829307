import React from 'react'
import { Typography, CardContent, CardHeader, Box, Card } from '@material-ui/core'
import { SubTopic, FlexBox } from '../../custom-components'

interface Props {
    label: string
    detail: string | number
    color?: string
    unit?: string
}

const StockCard: React.FC<Props> = ({ label, detail, unit, color }) => {
    return (
        <Card>
            <CardHeader title={label} titleTypographyProps={{ variant: "subtitle2" }} style={{ padding: '0 0 16px 0', textAlign: 'center' }} />
            <CardContent style={{ padding: 0 }}>
                <FlexBox flexDirection="row" gridGap={16} alignItems="center" justifyContent="center">
                    <Box color={color}>
                        <SubTopic>{detail}</SubTopic>
                    </Box>
                    {unit && <Typography variant="subtitle1">{unit}</Typography>}
                </FlexBox>
            </CardContent>
        </Card >
    )
}

export default StockCard
