import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import {
  SOStatusChip,
  SOPaymentStatusChip,
  InsufficientProductSignal,
  SONeedToFollowChip,
} from '../../../../../shared-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { SaleOrderStatus } from '../../../../../enums'

const SOStatusBar: React.FC = (props) => {
  const { saleOrderProfile, insufficientProduct } = useContext(SaleOrderProfileContext)

  if (!saleOrderProfile) {
    return <div>Loading</div>
  }

  return (
    <Box display="flex" gridGap={16}>
      <SOStatusChip icon status={saleOrderProfile.status.id} label={saleOrderProfile?.status.name} />
      {saleOrderProfile.status.id !== SaleOrderStatus.Cancelled && (
        <SOPaymentStatusChip
          icon
          status={saleOrderProfile.paymentStatus.id}
          label={saleOrderProfile?.paymentStatus.name}
        />
      )}
      {insufficientProduct && <InsufficientProductSignal chip={true} />}
      {!!saleOrderProfile.needToFollow && <SONeedToFollowChip />}
    </Box>
  )
}

export default SOStatusBar
