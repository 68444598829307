import { useState, useEffect } from 'react'
import { fetchProductsWithROPInfo, ProductWithROPInfo } from '../../api/smartbestbuys-api/product'

export const useProductsWithROPInfo = (): ProductWithROPInfo[] => {
  const [data, setData] = useState<ProductWithROPInfo[]>([])

  const _fetchProductsWithROPInfo = async () => {
    const response = await fetchProductsWithROPInfo()
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchProductsWithROPInfo()
  }, [])

  return data
}
