import { createContext } from 'react'
import { SaleOrderProfile, ICustomerProfile } from '../../../../api/smartbestbuys-api'

interface ContextProps {
  saleOrderProfile?: SaleOrderProfile
  customerProfile?: ICustomerProfile
  insufficientProduct: boolean
  reload: () => void
}

const init = {
  saleOrderProfile: undefined,
  customerProfile: undefined,
  insufficientProduct: false,
  reload: () => {},
}

const SaleOrderProfileContext = createContext<ContextProps>(init)

export default SaleOrderProfileContext
