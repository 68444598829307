import React from 'react'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { useCustomerStyle, useCompanyStyle } from './styles'
import { CustomerType } from '../../enums'
import CustomerInfo from './CustomerInfo'
import CustomerContactInfo from './CustomerContactInfo'
import { SubTopic, Radio } from '../../custom-components'
import { useFormContext } from 'react-hook-form'
import { getAddressText } from '../../functions'
import { ICustomerProfile } from '../../api/smartbestbuys-api'

interface Props {
  customerProfile?: ICustomerProfile
  contactId?: number
  billingAddressSelector?: boolean
}

const CustomerCard: React.FC<Props> = (props) => {
  const { customerProfile, contactId, billingAddressSelector } = props
  const customerStyles = useCustomerStyle()
  const companyStyles = useCompanyStyle()
  const { getValues, setValue } = useFormContext()
  const { billingAddressId } = getValues()

  const billingAddresses = customerProfile?.billingAddresses
  const hasBillingAddress = billingAddresses && billingAddresses?.length > 0

  const customerContact = customerProfile?.contacts?.find((cc) => cc.id === Number(contactId))

  // TODO: Download component
  if (!customerProfile) return <Paper>CUSTOMER PROFILE LOADING</Paper>

  const styles = customerProfile.customerType.id === CustomerType.Company ? companyStyles : customerStyles

  return (
    <Paper className={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomerInfo profile={customerProfile} />
        </Grid>
        {customerContact && <CustomerContactInfo customerContact={customerContact} />}
        {!billingAddressSelector && billingAddressId && (
          <>
            <Grid item xs={12}>
              <SubTopic>ที่อยู่เปิดบิล</SubTopic>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} clone>
                <Paper variant="outlined">
                  <Typography variant="body2">
                    {getAddressText(billingAddresses?.find((ba) => ba.id === billingAddressId))}
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </>
        )}
        {billingAddressSelector && hasBillingAddress && (
          <Grid item xs={12}>
            <SubTopic>ที่อยู่เปิดบิล</SubTopic>
          </Grid>
        )}
        {billingAddressSelector &&
          hasBillingAddress &&
          billingAddresses?.map((address) => (
            <Grid item xs={12} key={getAddressText(address)}>
              <Radio
                label={getAddressText(address)}
                value={address.id}
                outlined
                checked={billingAddressId === address.id}
                onChange={(e) => setValue('billingAddressId', Number(e.target.value))}
                color="primary"
              />
            </Grid>
          ))}
      </Grid>
    </Paper>
  )
}

export default CustomerCard
