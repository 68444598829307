import { useContext } from 'react'
import { RootContext } from '../../..'
import { BreadcumbsList, Grid, withLayout } from '../../../custom-components'
import { PageName } from '../../../enums'
import { useUserProfile } from '../../../hooks/user'
import { Loading } from '../../../shared-components'
import { UserCriteriaCard, UserInformationCard, UserPermissionCard, UserActivationCard } from '.'

interface Props {
  id: number
}

const UserProfilePage: React.FC<Props> = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.USER)

  //hook
  const [userProfile, forceReload] = useUserProfile(props.id)
  if (!userProfile) return <Loading />

  const breadcumbsItems = [{ url: '/users', name: 'รายการผู้ใช้ในระบบ' }, { name: userProfile.nickName }]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BreadcumbsList items={breadcumbsItems} />
      </Grid>
      <Grid item xs={12}>
        <UserInformationCard userProfile={userProfile} onUpdate={forceReload} />
      </Grid>
      <Grid item xs={12}>
        <UserCriteriaCard userProfile={userProfile} onUpdate={forceReload} />
      </Grid>
      <Grid item xs={12}>
        <UserPermissionCard userProfile={userProfile} onUpdate={forceReload} />
      </Grid>
      <Grid item xs={12}>
        <UserActivationCard userProfile={userProfile} onUpdate={forceReload} />
      </Grid>
    </Grid>
  )
}

export default withLayout(UserProfilePage)
