import { Button, MenuItem, Popover } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BaseData, IRefundRequisitionProfile } from '../../../api/smartbestbuys-api'
import { RefundStatus } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { MultipleConditionTooltip } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import RefundRequisitionCancelDialog from './RefundRequisitionCancelDialog'

interface RefundRequisitionInfo {
  id: number
  code: string
  status: BaseData
}

interface Props {
  refundRequisitionInfo: RefundRequisitionInfo
  onCancelSuccess: (data: IRefundRequisitionProfile) => void
}

const RefundRequisitionProfileMenu: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  //props
  const { refundRequisitionInfo, onCancelSuccess } = props

  // state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false)
  const menuOpened = Boolean(anchorEl)

  const ableToUpdate = RefundStatus.Pending === refundRequisitionInfo.status.id

  const ableToCancel = ![RefundStatus.Canceled, RefundStatus.Failed, RefundStatus.Done].includes(
    refundRequisitionInfo.status.id,
  )

  const showMenu = ableToUpdate || ableToCancel

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_RefundRequisition_Write)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.FinancePay_RefundRequisition_Cancel)

  // condition items
  const updateConditionItems = [
    { showTooltip: !writePermission.hasPermission, message: writePermission.alertMessage },
    { showTooltip: !ableToUpdate, message: 'อยู่ในสถานะที่แก้ไขไม่ได้' },
  ]

  const cancelConditionItems = [
    { showTooltip: !cancelPermission.hasPermission, message: cancelPermission.alertMessage },
    { showTooltip: !ableToCancel, message: 'อยู่ในสถานะที่ยกเลิกไม่ได้' },
  ]

  // handle
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <RefundRequisitionCancelDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        refundRequisitionInfo={refundRequisitionInfo}
        onComplete={(data) => {
          setOpenCancelDialog(false)
          onCancelSuccess(data)
        }}
      />
      {showMenu && (
        <>
          <Button variant="contained" size="large" onClick={handleClick}>
            <MoreHoriz />
          </Button>
          <Popover
            open={menuOpened}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MultipleConditionTooltip conditionItems={updateConditionItems}>
              <MenuItem
                onClick={() => history.push(`/finance/refund-requisitions/${refundRequisitionInfo.id}/update`)}
                disabled={updateConditionItems.some((item) => item.showTooltip)}
              >
                แก้ไขการคืนเงิน
              </MenuItem>
            </MultipleConditionTooltip>
            <MultipleConditionTooltip conditionItems={cancelConditionItems}>
              <MenuItem
                onClick={() => setOpenCancelDialog(true)}
                disabled={cancelConditionItems.some((item) => item.showTooltip)}
              >
                ยกเลิกการทำเบิก
              </MenuItem>
            </MultipleConditionTooltip>
          </Popover>
        </>
      )}
    </>
  )
}

export default RefundRequisitionProfileMenu
