import React, { useContext } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { FlexBox, GeneralAutoComplete, SubTopic } from '../../../custom-components'
import { TransportTaskUpdatePageContext } from './TransportTaskUpdatePage'
import { useFormContext } from 'react-hook-form'
import TransportTaskUpdateFormValues from './TransportTaskUpdateFormValues'
import { UserData } from '../../../api/smartbestbuys-api/data/data-types'

const TransportTaskAssigneeUser: React.FC = () => {
  // context
  const { transportTaskUpdateData: transportTaskCreateData } = useContext(TransportTaskUpdatePageContext)
  const { users } = transportTaskCreateData

  const { control, setValue, watch } = useFormContext<TransportTaskUpdateFormValues>()
  const { assigneeUser } = watch()
  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ผู้รับผิดชอบงาน</SubTopic>
        <div className="mb-4" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <GeneralAutoComplete
              control={control}
              renderInput={(params) => params}
              name="assigneeUserId"
              label="ผู้รับผิดชอบงาน"
              rules={{ required: 'เลือกผู้รับผิดชอบงาน' }}
              options={users.map((user) => {
                return {
                  label: user?.firstName + ' ' + user?.lastName,
                  value: user.id,
                }
              })}
              onPostChange={(value) => {
                const user = users.find((row: UserData) => row.id === value)
                if (!user) return
                setValue('assigneeUser', {
                  id: user?.id,
                  name: user?.firstName + ' ' + user?.lastName,
                })
              }}
              value={{
                label: assigneeUser.name,
                value: assigneeUser.id,
              }}
            ></GeneralAutoComplete>
          </Grid>
        </Grid>
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskAssigneeUser
