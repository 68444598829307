import React from 'react'
import { DialogProps, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { SubmitWithConfirmTextForm } from '../SubmitForm'
import { ConfirmTextOption } from '../SubmitForm/SubmitWithConfirmTextForm'

interface Props extends DialogProps {
  title: string
  textOk: string
  onOk?: () => void
  disabledCloseOnDim?: boolean
  disabledCloseButton?: boolean
  disableOkButton?: boolean
  isSubmitting?: boolean
  confirmTextOption?: ConfirmTextOption
}

const ConfirmationDialog: React.FC<Props> = (props) => {
  const {
    disabledCloseOnDim,
    onClose,
    title,
    textOk,
    onOk,
    disableOkButton,
    isSubmitting,
    children,
    confirmTextOption,
    ...otherProps
  } = props
  return (
    <Dialog
      onClose={(e, reason) => {
        if (!disabledCloseOnDim && onClose) {
          onClose(e, reason)
        }
      }}
      fullWidth
      maxWidth="sm"
      {...otherProps}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SubmitWithConfirmTextForm
          onCancel={() => {
            if (!!onClose) onClose({}, 'escapeKeyDown')
          }}
          onSubmit={() => onOk && onOk()}
          isSubmitting={isSubmitting}
          disableSubmit={disableOkButton || isSubmitting}
          confirmTextOption={confirmTextOption}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
