import React, { useState, useEffect, useContext } from 'react'
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
} from '@material-ui/core'
import { Form, GeneralTextFieldForm, Table } from '../../custom-components'
import { useActionMessages, useCustomerProfile, useSaleOrderProfile } from '../../hooks'
import { CustomerProfileCard, Loading } from '..'
import { SubmitForm } from '../SubmitForm'
import { NestedValue, useForm } from 'react-hook-form'
import {
  calculateExcludingVat,
  calculatePriceBreakdownsFromSOProfile,
  calculateVatInAmount,
  dateFormat,
} from '../../functions'
import { CreditNoteItem, SaleOrderDocument, SaleOrderProfile, TaxInvoiceState } from '../../api/smartbestbuys-api'
import { DocumentType, UserAction } from '../../enums'
import numeral from 'numeral'
import _ from 'lodash'
import {
  createCreditNote,
  getCreditNotePriceBreakdown,
  mapTaxInvoiceItemToCreditNoteItem,
  mapToCreditNoteItem,
} from './service'
import { Alert } from '@material-ui/lab'
import { RootContext } from '../..'
import SaleOrderProfileContext from '../../pages/sale-orders/id/SaleOrderProfilePage/SaleOrderProfileContext'

interface Props extends DialogProps {
  customerId: number
  saleOrderProfile?: SaleOrderProfile
  saleOrderId?: number
  taxInvoice?: SaleOrderDocument<TaxInvoiceState>
  onSuccess: () => void
}

export interface CreditNoteFormValues {
  remark?: string
  items: NestedValue<CreditNoteItem[]>
  shippingFee: number
  total: number
  actionMessageId: number
}

const CreditNoteCreationDialog: React.FC<Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { reload } = useContext(SaleOrderProfileContext)

  const messages = useActionMessages(UserAction.CreateCreditNote)

  const {
    onSuccess,
    onClose,
    customerId,
    saleOrderId,
    children,
    saleOrderProfile: rawSaleOrderProfile,
    taxInvoice,
    ...otherProps
  } = props

  const {
    control,
    reset,
    watch,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CreditNoteFormValues>()

  const [selectedList, setSelectedList] = useState<number[]>([])
  const [selectedItemList, setSelectedItemList] = useState<number[]>([])

  const saleOrderProfile = useSaleOrderProfile(saleOrderId) || rawSaleOrderProfile
  const customerProfile = useCustomerProfile(customerId)

  const isFromTaxInvoice = !!taxInvoice

  useEffect(() => {
    if (isFromTaxInvoice) {
      const items = taxInvoice?.state?.items.map(mapTaxInvoiceItemToCreditNoteItem) || []
      reset({
        items,
      })
      setSelectedItemList(items.map((p) => p.id))
    } else if (saleOrderProfile) {
      const priceBreakdown = calculatePriceBreakdownsFromSOProfile(saleOrderProfile)
      const items = saleOrderProfile.products.map(mapToCreditNoteItem)
      reset({
        items,
        shippingFee: priceBreakdown.shippingFee,
      })
      setSelectedItemList(items.map((p) => p.id))
    }
  }, [reset, isFromTaxInvoice, taxInvoice, saleOrderProfile, setSelectedItemList])

  if (!saleOrderProfile) return <Loading />

  const { documents, total } = saleOrderProfile
  const { items } = watch()

  const taxInvoices = documents?.filter((d) => d.type.id === DocumentType.TaxInvoice) || []
  const taxInvoiceTotal = _.sum(taxInvoices.map((tx) => (selectedList.includes(tx.id) ? tx.total || 0 : 0)))

  const handleSubmitCreditNoteForm = async (values: CreditNoteFormValues) => {
    const response = await createCreditNote(values, saleOrderProfile, selectedItemList, selectedList, taxInvoice)
    if (response?.status === 201) {
      triggerSnackbar('สร้างใบลดหนี้สำเร็จ')
      reload()
      onSuccess()
    } else {
      triggerSnackbar('สร้างใบลดหนี้ไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="lg" {...otherProps}>
      {!saleOrderProfile || !customerProfile ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit(handleSubmitCreditNoteForm)}>
          <DialogTitle>สร้างใบลดหนี้จากใบกำกับภาษีหมายเลข </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomerProfileCard
                  customerProfile={customerProfile}
                  billingAddressId={saleOrderProfile.billingAddress?.id}
                  disableBtnCustomerDetail
                />
              </Grid>
              <Grid item xs={12}>
                {taxInvoice ? (
                  <Typography variant="body1">เลือกใบกำกับภาษีอ้างอิง: {taxInvoice.code}</Typography>
                ) : (
                  <>
                    <Typography variant="body1">เลือกใบกำกับภาษีอ้างอิง</Typography>
                    <Table<SaleOrderDocument<TaxInvoiceState>>
                      columns={[
                        {
                          name: 'ลำดับ',
                          render: (_, index) => index + 1,
                        },
                        {
                          name: 'เลขใบกำกับภาษีอ้างอิง',
                          dataIndex: 'code',
                        },
                        {
                          name: 'วันที่เอกสาร',
                          fixedWidth: 150,
                          render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                        },
                        {
                          name: 'วันครบกำหนด',
                          fixedWidth: 150,
                          render: ({ state }) => (state?.expiredDate ? dateFormat(state.expiredDate) : '-'),
                        },
                        {
                          name: 'ยอดก่อน VAT',
                          fixedWidth: 150,
                          numeric: true,
                          format: '0,0.00',
                          render: ({ total }) => calculateExcludingVat(total || 0),
                        },
                        {
                          name: 'VAT',
                          fixedWidth: 150,
                          numeric: true,
                          format: '0,0.00',
                          render: ({ total }) => calculateVatInAmount(total || 0),
                        },
                        {
                          name: 'จำนวนเงิน',
                          fixedWidth: 150,
                          dataIndex: 'total',
                          numeric: true,
                          format: '0,0.00',
                        },
                      ]}
                      postCells={
                        <>
                          <TableRow>
                            <TableCell colSpan={6} />
                            <TableCell align="right">ยอดรวมสุทธิ</TableCell>
                            <TableCell align="right">{numeral(taxInvoiceTotal).format('0,0.00')}</TableCell>
                          </TableRow>

                          {taxInvoiceTotal !== total && (
                            <TableRow>
                              <TableCell colSpan={6} />
                              <TableCell colSpan={2}>
                                <Alert severity="error">ยอดเงินไม่ตรงกับใบสั่งขาย กรุณาตรวจสอบ</Alert>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      }
                      data={taxInvoices}
                      color="primary.dark"
                      size="small"
                      pagination={false}
                      cleanSpace
                      selectable
                      onChange={setSelectedList}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">รายละเอียดใบลดหนี้</Typography>
                <Table<CreditNoteItem>
                  columns={[
                    {
                      name: 'ลำดับ',
                      render: (_, index) => index + 1,
                    },
                    {
                      name: 'ชื่อสินค้า',
                      dataIndex: 'name',
                    },
                    {
                      name: 'ส่วนลดต่อหน่วย',
                      fixedWidth: 150,
                      numeric: isFromTaxInvoice,
                      format: '0,0.00',
                      render: ({ discountPerEach }, index) =>
                        isFromTaxInvoice ? (
                          discountPerEach
                        ) : (
                          <GeneralTextFieldForm
                            control={control}
                            name={`items.${index}.discountPerEach`}
                            type="number"
                          />
                        ),
                    },
                    {
                      name: 'จำนวน',
                      fixedWidth: 150,
                      numeric: isFromTaxInvoice,
                      format: '0,0.00',
                      render: ({ amount }, index) =>
                        isFromTaxInvoice ? (
                          amount
                        ) : (
                          <GeneralTextFieldForm control={control} name={`items.${index}.amount`} type="number" />
                        ),
                    },
                    {
                      name: 'ราคารวม',
                      fixedWidth: 100,
                      numeric: true,
                      format: '0,0.00',
                      render: ({ discountPerEach, amount }) => discountPerEach * amount,
                    },
                  ]}
                  postCells={
                    <>
                      {getCreditNotePriceBreakdown(
                        getValues(),
                        saleOrderProfile,
                        selectedItemList,
                        isFromTaxInvoice,
                      ).map((item) => {
                        if (!isFromTaxInvoice && item.editable) {
                          return (
                            <TableRow key={item.label}>
                              <TableCell colSpan={isFromTaxInvoice ? 3 : 4} />
                              <TableCell align="right">{item.label}</TableCell>
                              <TableCell align="right" style={{ width: 150 }}>
                                <GeneralTextFieldForm
                                  control={control}
                                  name="shippingFee"
                                  type="number"
                                  inputProps={{ style: { textAlign: 'right' }, steps: '0.01' }}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        }
                        return (
                          <TableRow key={item.label}>
                            <TableCell colSpan={isFromTaxInvoice ? 3 : 4} />
                            <TableCell align="right">{item.label}</TableCell>
                            <TableCell align="right">{numeral(item.amount).format('0,0.00')}</TableCell>
                          </TableRow>
                        )
                      })}
                    </>
                  }
                  data={items || []}
                  color="primary.dark"
                  size="small"
                  pagination={false}
                  cleanSpace
                  selectable={!isFromTaxInvoice}
                  selected={selectedItemList}
                  onChange={setSelectedItemList}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  name="actionMessageId"
                  select
                  label="สาเหตุ *"
                  control={control}
                  rules={{ required: 'กรุณาเลือกสาเหตุของการสร้างใบลดหนี้' }}
                >
                  {messages.map((message) => (
                    <MenuItem
                      key={message.id}
                      value={message.id}
                      style={{ color: message.id === -1 ? 'gray' : 'black' }}
                    >
                      {message.name}
                    </MenuItem>
                  ))}
                </GeneralTextFieldForm>
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitForm
              submitText="ยืนยันข้อมูล"
              onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
              disableSubmit={selectedList.length === 0 && !isFromTaxInvoice}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        </Form>
      )}
    </Dialog>
  )
}

export default CreditNoteCreationDialog
