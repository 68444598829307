import { DateTime } from 'luxon'
import { QuotationCreationRequest, QuotationProductCreationRequest } from '../../../api/smartbestbuys-api'
import { SelectedProduct } from '../../../sales-system/types'
import { calculatePriceBreakdowns } from '../../../sales-system/utils/sale-order-price-calculator'
import { CurrencyID } from '../../../types'
import { QuotationsCreateValue } from './QuotationsCreateValue'
import moment from 'moment'

export const mapQuotationsCreateValueToQuotationCreationRequest = (
  customerId: number,
  contactId: number | undefined,
  form: QuotationsCreateValue,
): QuotationCreationRequest => {
  const priceBreakdown = calculatePriceBreakdowns(form)
  return {
    customerId,
    contactId,
    billingAddressId: form.billingAddressId,
    contactChannelId: form.contactChannelId,
    expiredDate: DateTime.now().plus({ day: form.periodDay }).toFormat('yyyy-MM-dd'),
    currencyId: form.currencyId,
    exchangeRate: form.currencyId === CurrencyID.Baht ? 1 : form.exchangeRate,
    paymentMethodId: form.paymentMethodId,
    vatIncluded: form.vatIncluded ? 1 : 0,
    discountAmount: form.discount,
    discountAmountTypeId: 1,
    estimatedShippingDay: undefined,
    estimatedShippingFee: form.shippingFee,
    dueDate: form.dueDate ? moment(form.dueDate).format('YYYY-MM-DD') : undefined,
    remark: form.remark,
    total: priceBreakdown.total,
    isShippingFeeIncludingVat: form.isShippingFeeIncludingVat === true,
    products: mapSelectedProductToNetworkProducts(form.selectedProducts),
    assigneeUserId: form.assigneeUserId,
  }
}

const mapSelectedProductToNetworkProducts = (
  selectedProducts: Array<SelectedProduct>,
): Array<QuotationProductCreationRequest> => {
  return selectedProducts.map(
    (it): QuotationProductCreationRequest => ({
      id: it.id,
      nameLocal: it.nameLocal,
      nameEn: it.nameEn,
      unitLocal: it.unitLocal,
      unitEn: it.unitEn,
      imageUrl: it.imageUrl,
      amount: it.amount,
      price: it.price,
    }),
  )
}
