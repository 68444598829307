import React, { useMemo, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { FilteredTableProps } from './types'
import { FilterList as FilterIcon, Replay } from '@material-ui/icons'
import { defaultEndDate, defaultStartDate } from '../../data'
import numeral from 'numeral'
import { CSVDownloadButton } from '../../components'
import {
  TextField,
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Checkbox,
  Divider,
} from '@material-ui/core'
import moment from 'moment'
import { Table } from '.'
import { Grid } from '..'
import { FlexBox } from '../Box'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    filterBar: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      paddingBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      minWidth: '100%',
    },
    button: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    list: {
      maxWidth: 300,
      minWidth: 240,
    },
    listText: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 500,
    },
    filterButton: {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    tabShadow: {
      marginBottom: '4px',
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    },
    popover: {
      width: 500,
    },
  }),
)

interface TFilterSelectedItems<T> {
  name: string
  values: (string | number)[]
  filter: (data: T, values: (number | string)[]) => boolean
}

const FilteredTable = <T extends { id: number }>(props: FilteredTableProps<T>): JSX.Element => {
  const classes = useStyles()

  const {
    filters,
    onDateChange,
    onFilter,
    data,
    defaultDateRage,
    disableSearchInput,
    disableDateRangeFilter,
    downloadAsCsv,
    columns,
    csvFilename,
    tabOption,
    disableSearch,
    ...tableProps
  } = props

  const [startDate, setStartDate] = useState<Date>(defaultDateRage?.startDate || defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(defaultDateRage?.endDate || defaultEndDate)
  const [query, setQuery] = useState('')
  const [forcePageIndex, setForcePageIndex] = useState<number | undefined>(undefined)
  const defaultFilterValues = useMemo(
    () =>
      filters?.map((f) => {
        return { ...f, values: [] } as TFilterSelectedItems<T>
      }),
    // eslint-disable-next-line
    [],
  )
  const [filterValues, setFilterValues] = useState<TFilterSelectedItems<T>[] | undefined>(defaultFilterValues)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setForcePageIndex(undefined)
    setAnchorEl(null)
  }

  const filterByQuery = (data: T) => {
    try {
      return onFilter ? onFilter(data) : JSON.stringify(data).includes(query)
    } catch (error) {
      return true
    }
  }

  // filter stuff
  const onCheckFilterValue = (name: string, value: number | string) => {
    setForcePageIndex(0)
    setFilterValues((prev) => {
      if (!prev) return prev
      const index = prev.findIndex((f) => f.name === name)
      const filterValue = prev[index]
      const valueIndex = filterValue.values.findIndex((v) => v === value)
      if (valueIndex !== -1) {
        const updatedValues = [...filterValue.values]
        updatedValues.splice(valueIndex, 1)
        return [
          ...prev.slice(0, index),
          { ...filterValue, values: updatedValues },
          ...prev.slice(index + 1, prev.length),
        ] as TFilterSelectedItems<T>[]
      } else {
        const updatedValues = [...filterValue.values]
        updatedValues.push(value)
        return [
          ...prev.slice(0, index),
          { ...filterValue, values: updatedValues },
          ...prev.slice(index + 1, prev.length),
        ] as TFilterSelectedItems<T>[]
      }
    })
  }

  const filteredData = useMemo(() => {
    const filteredByQueryData = data.filter(filterByQuery)
    if (!filterValues) return filteredByQueryData

    return filterValues.reduce<T[]>((results, filterItem) => {
      return results.filter((data) =>
        filterItem.values.length > 0 ? filterItem.filter(data, filterItem.values) : true,
      )
    }, filteredByQueryData)
    // eslint-disable-next-line
  }, [
    query,
    // eslint-disable-next-line
    data.map((d) => d.id).toString(),
    // eslint-disable-next-line
    filterValues?.map((fv) => fv.values.map((v) => v.toString()).join('')).toString() ?? '',
  ])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const processedData = data.map((rowData, rowIndex) =>
    columns.map((column) => {
      const { numeric, csvRender, render, format } = column
      if (csvRender) return csvRender(rowData, rowIndex)
      if (render) return render(rowData, rowIndex)
      const cellValue = rowData[column.dataIndex as keyof T]

      return numeric && format ? numeral(cellValue).format(format) : cellValue
    }),
  )

  return (
    <div className={classes.root}>
      <Grid className={classes.filterBar} container spacing={1}>
        {!disableDateRangeFilter && (
          <>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                id="date"
                label="วันที่"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(startDate).format('YYYY-MM-DD')}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  const updatedStartDate = moment(e.target.value).startOf('day').toDate()
                  onDateChange && onDateChange(updatedStartDate, endDate)
                  setStartDate(updatedStartDate)
                  setForcePageIndex(0)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                id="date"
                label="ถึงวันที่"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(endDate).format('YYYY-MM-DD')}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  const updatedEndDate = moment(e.target.value).endOf('day').toDate()
                  onDateChange && onDateChange(startDate, updatedEndDate)
                  setEndDate(updatedEndDate)
                  setForcePageIndex(0)
                }}
              />
            </Grid>
          </>
        )}
        {!disableSearch && (
          <Grid item xs={12} sm={9} md={4}>
            <TextField
              fullWidth
              label="ค้นหา"
              variant="outlined"
              size="small"
              onChange={({ target: { value } }) => {
                setQuery(value)
                setForcePageIndex(0)
              }}
            />
          </Grid>
        )}
        {filters && (
          <Grid item xs={12} sm={3} md={2}>
            <Button
              aria-describedby={id}
              size="medium"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClick}
              startIcon={<FilterIcon />}
              className={classes.button}
              disableElevation
            >
              ตัวกรอง
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{
                paper: classes.popover,
              }}
            >
              <List className={classes.list}>
                {filters.map((filterItem) => (
                  <ListItem key={filterItem.name}>
                    <ListItemText
                      classes={{
                        secondary: classes.listText,
                      }}
                      primary={filterItem.name}
                      secondary={filterItem.values.map((filterItemValue) => {
                        const filterItemValues = filterValues?.find((fv) => fv.name === filterItem.name)?.values ?? []
                        const isChecked = filterItemValues.includes(filterItemValue.value)
                        return (
                          <FlexBox key={filterItemValue.value} alignItems="center">
                            <Checkbox
                              checked={isChecked}
                              onChange={() => onCheckFilterValue(filterItem.name, filterItemValue.value)}
                            />{' '}
                            {filterItemValue.name} (
                            {data.filter((d) => filterItem.filter(d, [filterItemValue.value])).length})
                          </FlexBox>
                        )
                      })}
                    />
                  </ListItem>
                ))}
                <Divider />
                <ListItem key="footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button color="primary" onClick={() => setFilterValues(defaultFilterValues)}>
                    <Replay />
                    <span className="ml-2">ล้างข้อมูล</span>
                  </Button>
                </ListItem>
              </List>
            </Popover>
          </Grid>
        )}
        {downloadAsCsv && (
          <Grid item xs={12} sm={3} md={2}>
            <CSVDownloadButton
              filename={csvFilename}
              columns={columns.map((column) => column.name)}
              data={processedData}
            />
          </Grid>
        )}
      </Grid>
      {!!tabOption && (
        <Tabs
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          value={tabOption.value}
          onChange={tabOption.onChange}
          aria-label="tabs table"
          className={classes.tabShadow}
        >
          {tabOption.tabs.map((tab) => (
            <Tab {...tab} key={tab.value} />
          ))}
        </Tabs>
      )}
      <Table<T>
        data={filteredData}
        columns={columns.filter((col) => !col.hide)}
        {...tableProps}
        forcePageIndex={forcePageIndex}
        setForcePageIndex={setForcePageIndex}
      />
    </div>
  )
}

export default FilteredTable
