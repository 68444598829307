import React, { FC } from 'react';
import ReactPDF, { View, StyleSheet } from '@react-pdf/renderer';

interface Props {
  bottomLine?: 'darkGrey' | 'lightGrey'
}

// TODO: should come from configuration something.
const pallete = {
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0'
}

const PDFView: FC<ReactPDF.ViewProps & Props> = (props) => {
  const { bottomLine, ...viewProps } = props;

  const styles = StyleSheet.create({
    td: {
      borderBottomWidth: bottomLine ? 1 : undefined,
      borderBottomColor: bottomLine ? pallete[bottomLine] : undefined
    }
  })

  const { style, ...otherProps } = viewProps;
  return (
    <View style={{
      ...styles.td,
      ...style,
    }} {...otherProps} />
  );
}

export default PDFView;