import { useState } from 'react'
import { TaxInvoiceTableData, fetchDocumentTaxInvoices } from '../../api/smartbestbuys-api'
import { calculateExcludingVat, dateTimeFormat } from '../../functions'
import { DocumentStatus } from '../../enums'
import { formatNumber } from '../../functions/number-utils'

import { CSVDataColumn, CSVDownloader } from './CSVDownloader'

export const TaxInvoiceCSVDownloader = () => {
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<TaxInvoiceTableData[]>([])

  const handleLoadClick = async (startDate: Date, endDate: Date) => {
    try {
      const response = await fetchDocumentTaxInvoices({
        startDate,
        endDate,
      })

      setData(response?.data ?? [])
      setLoaded(true)
    } catch (e) {
      setData([])
    }
  }

  return (
    <CSVDownloader
      fileName="รายการใบกำกับภาษี"
      loaded={loaded}
      data={data}
      columns={_columns}
      onLoadClick={handleLoadClick}
      onDateChange={() => setLoaded(false)}
    />
  )
}

const _columns: CSVDataColumn<TaxInvoiceTableData>[] = [
  {
    columnName: 'วันที่สร้าง',
    render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
  },
  { columnName: 'เลขใบกำกับภาษี', render: ({ code }) => code },
  { columnName: 'ประเภทใบกำกับภาษี', render: ({ isDeposit }) => (isDeposit ? 'มัดจำ' : 'ทั่วไป') },
  {
    columnName: 'เลขใบเสร็จรับเงิน',
    render: ({ refReceipts }) =>
      refReceipts.filter((doc) => doc.status.id !== DocumentStatus.Cancelled).map((doc) => doc.code)[0] ?? '--',
  },
  {
    columnName: 'เลขที่ใบวางบิล',
    render: ({ refBillingNotes }) =>
      refBillingNotes.filter((doc) => doc.status.id !== DocumentStatus.Cancelled).map((doc) => doc.code)[0] ?? '--',
  },
  { columnName: 'เลขใบสั่งขายอ้างอิง', render: ({ saleOrderInfo }) => saleOrderInfo?.code ?? '--' },
  {
    columnName: 'รายได้อ้างอิง (SPAY)',
    render: ({ refSaleOrderPaymentInfo }) => refSaleOrderPaymentInfo?.code ?? '--',
  },
  {
    columnName: 'ชื่อลูกค้า',
    render: ({ customerInfo, billingAddress }) =>
      billingAddress?.overrideDocumentData?.customerName || customerInfo?.name || '--',
  },
  {
    columnName: 'เลขที่ผู้เสียภาษี',
    render: ({ customerInfo, billingAddress }) =>
      billingAddress?.overrideDocumentData?.taxNumber || customerInfo?.referenceNumber || '--',
  },
  { columnName: 'สาขา', render: ({ billingAddress }) => billingAddress?.branchName ?? '--' },
  {
    columnName: 'ยอดก่อน VAT',
    render: ({ total, saleOrderInfo, status }) => {
      if (total === undefined || status.id === DocumentStatus.Cancelled) return 0
      if (!saleOrderInfo?.vatIncluded) return formatNumber(total)
      return formatNumber(calculateExcludingVat(total))
    },
  },
  {
    columnName: 'VAT',
    render: ({ total, saleOrderInfo, status }) => {
      if (total === undefined || status.id === DocumentStatus.Cancelled || !saleOrderInfo?.vatIncluded) return 0
      const totalWithoutVat = calculateExcludingVat(total)
      return formatNumber(total - totalWithoutVat)
    },
  },
  {
    columnName: 'ยอดเงินสุทธิ',
    render: ({ total, status }) => {
      if (total === undefined || status.id === DocumentStatus.Cancelled) return '0.00'
      return formatNumber(total)
    },
  },
  { columnName: 'สถานะใบกำกับภาษี', render: ({ status: { name } }) => name },
  { columnName: 'สถานะใบสั่งขาย', render: ({ saleOrderInfo }) => saleOrderInfo?.status?.name ?? '--' },
  { columnName: 'สถานะจ่ายเงิน', render: ({ saleOrderInfo }) => saleOrderInfo?.paymentStatus?.name ?? '--' },
]
