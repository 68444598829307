import { Typography } from '@material-ui/core'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { VendorProfile_HistoryPurchaseOrder } from '../../../../api/smartbestbuys-api'
import { FilteredTable, Grid, Link, Title } from '../../../../custom-components'
import { dateTimeFormat } from '../../../../functions'
import { PurchaseOrderPaymentStatusChip, PurchaseOrderStatusChip } from '../../../../shared-components'

interface Props {
  historyPurchaseOrders: VendorProfile_HistoryPurchaseOrder[]
}

type VendorHistoryPurchaseOrderData = VendorProfile_HistoryPurchaseOrder

const VendorPurchasesHistoryTable: React.FC<Props> = (props: Props) => {
  // props
  const { historyPurchaseOrders } = props

  // state
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const filterByDateRange = (vendor: VendorProfile_HistoryPurchaseOrder) => {
    if (!startDate || !endDate) return true
    const createdMoment = moment(vendor.recCreatedAt)
    const startDateMoment = moment(startDate)
    const endDateMoment = moment(endDate)

    return createdMoment.isSameOrAfter(startDateMoment) && createdMoment.isSameOrBefore(endDateMoment)
  }

  const filteredData = useMemo(
    () => historyPurchaseOrders.filter(filterByDateRange),
    // eslint-disable-next-line
    [startDate, endDate],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title>ประวัติใบสั่งซื้อจากผู้ขายนี้</Title>
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<VendorHistoryPurchaseOrderData>
          size="small"
          color="primary.dark"
          columns={[
            {
              name: 'สถานะใบสั่งซื้อ',
              fixedWidth: 140,
              render: ({ status }) => {
                return <PurchaseOrderStatusChip label={status.name} size="small" status={status.id} />
              },
            },
            {
              name: 'สถานะการจ่ายเงิน',
              fixedWidth: 140,
              render: ({ paymentStatus }) => (
                <PurchaseOrderPaymentStatusChip status={paymentStatus.id} label={paymentStatus.name} />
              ),
            },
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
            },
            {
              name: 'เลขที่ใบสั่งซื้อ',
              render: ({ id, code }) => (
                <Link color="primary" to={`/purchase-orders/${id}/profile`} target="_blank">
                  <Typography variant="body2">{code}</Typography>
                </Link>
              ),
            },
            {
              name: 'ยอดรวมสุทธิ',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
            },
            {
              name: 'ยอดจ่ายแล้ว',
              dataIndex: 'totalPayment',
              numeric: true,
              format: '0,0.00',
            },
            {
              name: 'ยอดค้างจ่าย',
              dataIndex: 'totalBalance',
              numeric: true,
              format: '0,0.00',
            },
            {
              name: 'การทำเบิก',
              dataIndex: 'paymentRequisitionStatus',
            },
          ]}
          data={filteredData}
          sortable={true}
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          cleanSpace
          pagination
        />
      </Grid>
    </Grid>
  )
}

export default VendorPurchasesHistoryTable
