import { Grid } from '@material-ui/core'
import { createContext, useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import {
  createCustomerRequisitionAPI,
  CreateCustomerRequisitionRequestBody,
  IGetCustomerRequisitionFormData,
} from '../../../api/smartbestbuys-api'
import { Form, Topic, withLayout } from '../../../custom-components'
import { PageName } from '../../../enums'
import { useCustomerRequisitionCreateData } from '../../../hooks'
import { Loading } from '../../../shared-components'
import { CustomerRequisitionTypeId, SourceFile } from '../../../types'
import { CustomerRequisitionForm, CustomerRequisitionFormValues } from '../forms'

export type CustomerRequisitionCreateData = IGetCustomerRequisitionFormData

interface ICustomerRequisitonCreatePageContext {
  customerRequisitionCreateData: CustomerRequisitionCreateData
}

export const CustomerRequisitonCreatePageContext = createContext<ICustomerRequisitonCreatePageContext>({
  customerRequisitionCreateData: {} as CustomerRequisitionCreateData,
})

interface QueryParams {
  saleOrderId: number
  customerId: number
}

interface Props {
  query: QueryParams
}

const CustomerRequisitionCreatePage: React.FC<Props> = (props) => {
  const { saleOrderId, customerId } = props.query
  const history = useHistory()
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const [attachments, setAttachments] = useState<SourceFile[]>()

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'start' })
    }
  }

  setCurrentPage(PageName.SALE_ORDER)

  const formMethods = useForm<CustomerRequisitionFormValues>({
    defaultValues: {
      typeId: CustomerRequisitionTypeId.Claim,
      products: [],
    },
  })

  const {
    setError,
    watch,
    formState: { isSubmitting },
  } = formMethods

  const createData = useCustomerRequisitionCreateData(saleOrderId)

  if (!createData) return <Loading />

  const handleSubmit = async (values: CustomerRequisitionFormValues) => {
    if (!validateValues(values)) return

    const request: CreateCustomerRequisitionRequestBody = {
      customerId: customerId,
      saleOrderId: saleOrderId,
      typeId: values.typeId,
      content: values.content,
      products: values.products
        .filter((p) => !!p.amount && p.amount > 0)
        .map((p) => ({
          productId: p.productId,
          saleOrderProductId: p.saleOrderProductId,
          amount: p.amount as number,
        })),
      base64Images: attachments?.map((att) => att.base64!),
    }

    const response = await createCustomerRequisitionAPI(request)
    if (response?.status === 201) {
      triggerSnackbar('สร้างคำร้องของลูกค้าสำเร็จ')
      history.push(`/customer-requisitions/${response.data.id}/profile`)
    } else {
      triggerSnackbar('สร้างคำร้องของลูกค้าไม่สำเร็จ')
    }
    return true
  }

  const handleInvalid = (errors: CustomerRequisitionFormValues) => {
    validateValues(watch(), false)
  }

  const validateValues = (values: CustomerRequisitionFormValues, triggerSubmit?: boolean): boolean => {
    let valid = true

    const hasContent = values.content.length > 0
    const hasSelectedProducts = values.products.some((d) => !!d.amount && d.amount > 0)

    if (!hasContent) {
      setError('content', {
        type: 'required',
        message: 'กรุณากรอกรายละเอียด',
      })
      scrollTo('#cr-info-input-box')
      valid = false
    }

    if (!hasSelectedProducts) {
      setError('products', {
        type: 'required',
        message: 'กรุณาเลือกสินค้าที่จะเคลม',
      })
      scrollTo('#claim-product-selector-box')
      valid = false
    }

    return valid
  }

  return (
    <CustomerRequisitonCreatePageContext.Provider value={{ customerRequisitionCreateData: createData }}>
      <FormProvider {...formMethods}>
        <Grid item xs={12}>
          <Topic>สร้างคำร้องของลูกค้าของใบสั่งขาย {createData.saleOrderInfo.code}</Topic>
        </Grid>
        <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
          <CustomerRequisitionForm onChange={setAttachments} isSubmitting={isSubmitting} />
        </Form>
      </FormProvider>
    </CustomerRequisitonCreatePageContext.Provider>
  )
}

export default withLayout(CustomerRequisitionCreatePage)
