import React, { useContext } from 'react'
import { Button, fade, makeStyles, Paper, Typography } from '@material-ui/core'
import { ArrowForward, Description } from '@material-ui/icons'
import { FlexBox, Link, SubTopic } from '../../../custom-components'
import { SaleOrderStatus } from '../../../enums'
import { ConditionTooltip, SOStatusChip } from '../../../shared-components'
import { QuotationsProfilePageContextImpl } from './QuotationsProfilePage'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { useHistory } from 'react-router-dom'
import { red } from '@material-ui/core/colors'

const _activeSOStatus = [
  SaleOrderStatus.Pending,
  SaleOrderStatus.PendingPayment,
  SaleOrderStatus.PendingDepositPayment,
  SaleOrderStatus.PendingDelivery,
  SaleOrderStatus.Delivering,
  SaleOrderStatus.WaitingCustomerPickUp,
]

const _doneSOStatus = [SaleOrderStatus.Closed, SaleOrderStatus.ClosedAtShop]

const useStyles = makeStyles({
  paperWarning: {
    background: fade('#FF9800', 0.08),
  },
  subTopicWarning: {
    color: '#663D00',
  },
  paperSuccess: {
    background: '#EDF7ED',
  },
  subTopicSuccess: {
    color: '#1E4620',
  },
  paperDanger: {
    background: fade(red[500], 0.08),
  },
  subTopicDanger: {
    color: '#5D0C28',
  },
})

const SaleOrderRefBox: React.FC = (props) => {
  const classes = useStyles()
  const history = useHistory()
  // context
  const { profile } = useContext(QuotationsProfilePageContextImpl)
  const writeSOPermission = getUserPermissionInfo(PermissionEnum.Sales_SO_Write)
  // calculation
  const saleOrderSorted = profile.refSaleOrders?.sort((a, b) => b.id - a.id).slice(0, 1) || []
  const hasSaleOrder = saleOrderSorted.length > 0
  const subTopicSaleOrder = hasSaleOrder ? saleOrderSorted[0].code : 'ยังไม่สร้างใบสั่งขาย'
  const saleOrderStatus = hasSaleOrder ? saleOrderSorted[0].status : undefined

  const hasActiveSO = hasSaleOrder && _activeSOStatus.includes(saleOrderSorted[0].status.id)
  const hasDoneSO = hasSaleOrder && _doneSOStatus.includes(saleOrderSorted[0].status.id)
  const cssPaperHasSaleOrder = hasActiveSO ? undefined : hasDoneSO ? classes.paperSuccess : classes.paperDanger
  const cssSubTopicHasSaleOrder = hasActiveSO ? undefined : hasDoneSO ? classes.subTopicSuccess : classes.subTopicDanger

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" alignItems="center" clone>
      <Paper
        classes={{
          root: !hasSaleOrder ? classes.paperWarning : cssPaperHasSaleOrder,
        }}
      >
        <SubTopic
          classes={{
            root: !hasSaleOrder ? classes.subTopicWarning : cssSubTopicHasSaleOrder,
          }}
        >
          {subTopicSaleOrder}
          {hasSaleOrder && (
            <span className="ml-4">
              <SOStatusChip status={saleOrderStatus?.id as number} label={saleOrderStatus?.name} />
            </span>
          )}
        </SubTopic>
        {hasSaleOrder ? (
          <Link className="flex items-center" color="primary" href={`/sale-orders/${saleOrderSorted[0].id}`}>
            <Typography
              classes={{
                root: !hasSaleOrder ? classes.subTopicWarning : cssSubTopicHasSaleOrder,
              }}
            >
              ไปที่หน้ารายละเอียดคำสั่งซื้อ
            </Typography>
            <ArrowForward
              classes={{
                root: !hasSaleOrder ? classes.subTopicWarning : cssSubTopicHasSaleOrder,
              }}
              className="ml-2"
            />
          </Link>
        ) : (
          <ConditionTooltip title={writeSOPermission.alertMessage} showTooltip={!writeSOPermission.hasPermission}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Description />}
              onClick={() => history.push(`/sale-orders/create?quotationId=${profile.id}`)}
              disabled={!writeSOPermission.hasPermission}
            >
              สร้างใบสั่งขาย
            </Button>
          </ConditionTooltip>
        )}
      </Paper>
    </FlexBox>
  )
}

export default SaleOrderRefBox
