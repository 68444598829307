import { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../..'
import { DocumentStatus, PageName, UserAction } from '../../../enums'
import { useBillingNoteTableData } from '../../../hooks/useBillingNoteTableData'
import { DocumentCancellationDialog, DocumentStatusChip, Loading } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { Box, Button, Chip, CircularProgress, Grid, IconButton, TextField } from '@material-ui/core'
import { FlexBox, GeneralTextField, Link, Table, Topic, withLayout } from '../../../custom-components'
import { Autocomplete } from '@material-ui/lab'
import { dateTimeFormat } from '../../../functions'
import { Cancel } from '@material-ui/icons'
import { IDocumentTableData_Data } from '../../../api/smartbestbuys-api'
import { documentStatuses } from '../../../constants'
import { useActionMessages } from '../../../hooks'

const BillingNoteTableDataPage: React.FC = (props) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  setCurrentPage(PageName.BILLING_NOTE)

  const tableData = useBillingNoteTableData({
    pageIndex: 0,
    rowPerPage: 10,
    search: { documentCode: '', customerName: '', statusIds: [] },
  })

  const messages = useActionMessages(UserAction.CancelDocument)

  const [idToCancelId, setIdToCancel] = useState<number | undefined>(undefined)
  const [billingNotes, setBillingNotes] = useState<IDocumentTableData_Data[]>([])

  useEffect(() => {
    if (tableData?.data) setBillingNotes(tableData.data)
  }, [tableData?.data])

  if (!tableData) return <Loading />
  const { paginationInfo, isLoading, handlePageChange, handleRowPerPageChange, handleSearch, handleSearchParamChange } =
    tableData

  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Topic>รายการใบวางบิล</Topic>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="documentCode"
                  onChange={(e) => handleSearchParamChange('documentCode', e.target.value)}
                  label="เลขใบวางบิล"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="customerName"
                  onChange={(e) => handleSearchParamChange('customerName', e.target.value)}
                  label="ชื่อลูกค้า"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  size="small"
                  options={documentStatuses.map((option) => option.name)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((label: string, index: number) => (
                      <Chip variant="outlined" label={label} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange(
                      'statusIds',
                      documentStatuses.filter((opt) => newValue.includes(opt.name)).map((opt) => opt.id),
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="สถานะใบวางบิล"
                      placeholder="สถานะใบวางบิล"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="button"
                  type="submit"
                  disabled={isLoading}
                  startIcon={isLoading ? <CircularProgress size={8} /> : undefined}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<IDocumentTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: 'สถานะใบวางบิล',
                render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
              },
              {
                name: 'เลขใบวางบิล',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/billing-notes/${id}/pdf`} target="_blank">
                    {code}
                  </Link>
                ),
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
              {
                name: 'ชื่อลูกค้า',
                render: ({ customer, billingAddress }) =>
                  billingAddress?.overrideDocumentData?.customerName || customer?.nameLocal || '-',
              },
              {
                name: 'ยอดเงินสุทธิ',
                dataIndex: 'total',
                numeric: true,
                format: '0,0.00',
              },
              {
                name: '',
                render: (billingNote) => {
                  // TODO: support issued credit note and credit note dialog
                  const isCancelled = billingNote.status.id === DocumentStatus.Cancelled
                  return isCancelled ? (
                    <></>
                  ) : (
                    <>
                      <DocumentCancellationDialog
                        messages={messages}
                        documentInfo={billingNote}
                        open={idToCancelId === billingNote.id}
                        onClose={() => {
                          setIdToCancel(undefined)
                        }}
                        onSuccess={(updatedData) => {
                          triggerSnackbar('ยกเลิกใบวางบิลสำเร็จ')
                          let newData = billingNotes
                          const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                          if (findIndex !== -1) {
                            const found = newData[findIndex]
                            const updated = {
                              ...found,
                              ...updatedData,
                            }
                            newData[findIndex] = updated
                            setBillingNotes([...newData])
                          }
                        }}
                        onFail={() => {
                          triggerSnackbar('ยกเลิกใบวางบิลไม่สำเร็จ')
                        }}
                      />
                      {cancelPermission.hasPermission && (
                        <FlexBox gridGap={8}>
                          <IconButton size="small" onClick={() => setIdToCancel(billingNote.id)}>
                            <Cancel fontSize="small" />
                          </IconButton>
                        </FlexBox>
                      )}
                    </>
                  )
                },
              },
            ]}
            data={billingNotes}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(BillingNoteTableDataPage)
