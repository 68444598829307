import { withStyles, Theme, createStyles } from '@material-ui/core/styles'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

export const StyledTableHeadRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      overflow: 'hidden',
    },
  }),
)(TableRow)

export const StyledTableHeadCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      borderRadius: `${theme.spacing(0.5)} 0`,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)
