import { useState, useEffect } from 'react'
import { fetchTransportTasksAPI, FetchTransportTasksURLParams, TransportTask } from '../../api/smartbestbuys-api'
import { TaskType } from '../../enums'

export const useTransportTasks = (startDate: Date, endDate: Date, typeId: TaskType): TransportTask[] => {
  const [data, setData] = useState<TransportTask[]>([])

  const _fetchTransportTasks = async (urlParams: FetchTransportTasksURLParams) => {
    const response = await fetchTransportTasksAPI(urlParams)
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchTransportTasks({ startDate, endDate, typeId })
  }, [startDate, endDate, typeId])

  return data
}
