import { Button, Card, Grid, Paper } from '@material-ui/core'
import numeral from 'numeral'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { EmptyCard, FlexBox, SubTopic, TypographyWithLabel } from '../../../custom-components'
import { PaymentRequisitionStatus, PurchaseOrderPaymentStatus, PurchaseOrderStatus } from '../../../enums'
import { PaymentRequisitionStatusChip } from '../../../shared-components'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'

const blacklistedStatus = [PurchaseOrderStatus.Rejected, PurchaseOrderStatus.Canceled]

const blacklistedPaymentReqStatuses = [PaymentRequisitionStatus.Rejected, PaymentRequisitionStatus.Canceled]

const PurchaseOrderProfilePaymentRequisitionBox: React.FC = () => {
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { id, payments, total, status, paymentStatus, documents, vendorInfo } = purchaseOrderProfile

  // hook
  const history = useHistory()

  // to wait data for api
  const paid =
    payments?.reduce((sum, currentValue) => {
      if (blacklistedPaymentReqStatuses.includes(currentValue.status.id)) return sum
      return sum + currentValue.total
    }, 0) ?? 0
  const remainTotal = total - paid
  const isPayment = !remainTotal
  const documentTotal = documents?.reduce<number>((sum, document) => sum + document.total, 0) ?? 0
  const showedPaymentButton =
    !blacklistedStatus.includes(status.id) &&
    paymentStatus.id !== PurchaseOrderPaymentStatus.Paid &&
    documentTotal > paid

  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <FlexBox justifyContent="space-between">
          <SubTopic>ข้อมูลการทำเบิก/ทำจ่าย</SubTopic>
          {showedPaymentButton && (
            <Button
              onClick={() => history.push(`/vendors/${vendorInfo.id}/create-payment-requisition?purchaseOrderId=${id}`)}
              variant="contained"
              color="primary"
              disabled={isPayment}
              classes={{ label: 'px-2 leading-none' }}
            >
              ทำเบิก
            </Button>
          )}
        </FlexBox>
        <div className="mb-4" />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TypographyWithLabel label="ยอดเต็ม">{numeral(total).format('0,0.00')}</TypographyWithLabel>
          </Grid>
          <Grid item xs={4}>
            <TypographyWithLabel label="ยอดจ่ายแล้ว">{numeral(paid).format('0,0.00')}</TypographyWithLabel>
          </Grid>
          <Grid item xs={4}>
            <TypographyWithLabel label="ยอดค้างจ่าย">{numeral(remainTotal).format('0,0.00')}</TypographyWithLabel>
          </Grid>
        </Grid>
        <div className="mb-4" />
        {!!payments && payments.length > 0 ? (
          payments.map((payment) => {
            return (
              <Card variant="outlined">
                <PaymentRequisitionStatusChip status={payment.status.id} label={payment.status.name} />
                <div className="mb-4" />
                <Grid container spacing={3}>
                  <Grid item xs={6} container alignItems="center">
                    <TypographyWithLabel label="เลขใบทำเบิก:">{payment.code}</TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6} container alignItems="center">
                    <TypographyWithLabel label="เลขใบทำจ่าย:">
                      {payment.code ? payment.paymentCode : 'ยังไม่ทำจ่าย'}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6} container alignItems="center">
                    <TypographyWithLabel label="ยอดใบทำเบิกของใบสั่งซื้อนี้:">
                      {numeral(payment.total).format('0,0.00')}
                    </TypographyWithLabel>
                  </Grid>
                </Grid>
              </Card>
            )
          })
        ) : (
          <EmptyCard emptyText="ยังไม่มีข้อมูลการทำเบิก/ทำจ่าย" />
        )}
      </Paper>
    </FlexBox>
  )
}

export default PurchaseOrderProfilePaymentRequisitionBox
