import { UpdatedSOPaymentTransactionStatus } from '.'
import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { UserActivityInfoRequest } from '../user-activity'

export const cancelSOPayment = (saleOderId: number) =>
  errorSingleHandler<UpdatedSOPaymentTransactionStatus>(async (id: number, request: UserActivityInfoRequest) => {
    const path = `/sale-orders/${saleOderId}/payments/${id}/cancel`
    return await smartAuthAPI.patch(path, request)
  })
