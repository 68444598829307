import { useState, useEffect } from 'react'
import { QuotationCreateData, quotationsCreateDataAPI } from '../../api/smartbestbuys-api'

export const useQuotationsCreateData = (customerId: number, contactId?: number): QuotationCreateData | undefined => {
  const [data, setData] = useState<QuotationCreateData | undefined>(undefined)

  const _quotationsCreateData = async (customerId: number, contactId?: number) => {
    const response = await quotationsCreateDataAPI({
      customerId: customerId.toString(),
      contactId: contactId?.toString(),
    })
    setData(response?.data)
  }

  useEffect(() => {
    _quotationsCreateData(customerId, contactId)
  }, [customerId, contactId])

  return data
}
