import { Box, Button, Card, Grid, IconButton, Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import { FlexBox, Table, Title, TypographyWithLabel } from '../../../custom-components'
import { Loading, SearchBar, Space } from '../../../shared-components'
import numeral from 'numeral'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { IRefundRequisitionFormData_SaleOrder } from '../../../api/smartbestbuys-api'
import {
  IRefundRequisitionFormValues,
  IRefundRequisitionFormValues_DebtSaleOrder,
  useRefundRequisitionFormContext,
} from './RefundRequisitionForm'
import { Delete } from '@material-ui/icons'
import { useMemo } from 'react'

const DebtSaleOrderSelectBox = () => {
  const { formData } = useRefundRequisitionFormContext()
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<IRefundRequisitionFormValues>()
  const {
    fields: debtSOs,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'debtSaleOrders',
    keyName: 'selectBox',
  })

  const debtTotal = useMemo(() => debtSOs.reduce((prev, dso) => prev + dso.total, 0), [debtSOs])
  const errorMessage = useMemo(() => errors.debtSaleOrders?.message, [errors.debtSaleOrders])

  if (!formData) return <Loading />

  const handleSelect = (saleOrder: IRefundRequisitionFormData_SaleOrder) => {
    const index = debtSOs.findIndex((dso) => dso.id === saleOrder.id)
    if (index === -1) {
      if (!!errorMessage) clearErrors('debtSaleOrders')
      append({ id: saleOrder.id, code: saleOrder.code, total: saleOrder.debtTotal })
    } else {
      remove(index)
    }
  }

  return (
    <>
      <Box p={2} border={!!errorMessage ? 1 : undefined} borderColor={!!errorMessage ? 'error.main' : undefined} clone>
        <Paper id="debt-sale-orders-select-box">
          <Title>รายการใบสั่งขายที่เป็นหนี้</Title>
          <Space />
          <SearchBar onChange={console.log} />
          <Space />
          <Grid container spacing={2}>
            {formData.saleOrders.map((so) => {
              const isSelected = debtSOs.some((dso) => dso.id === so.id)

              return (
                <Grid key={`so-${so.id}`} item xs={12} md={4}>
                  <Card>
                    <FlexBox flexDirection="column" gridGap={4}>
                      <Typography variant="body2">{so.code}</Typography>
                      <TypographyWithLabel label="ยอดหนี้รวม" color="textSecondary" justifyContent="space-between">
                        {numeral(so.debtTotal).format('0,0.00')}
                      </TypographyWithLabel>
                      <TypographyWithLabel
                        label="หมายเหตุการยกเลิก"
                        color="textSecondary"
                        justifyContent="space-between"
                      >
                        {so.canceledMessage} {so.canceledRemark}
                      </TypographyWithLabel>
                      <Button
                        variant={isSelected ? 'outlined' : 'contained'}
                        color="primary"
                        size="small"
                        onClick={() => handleSelect(so)}
                      >
                        {isSelected ? 'นำออกจากรายการคืนเงิน' : 'เลือกเพื่อคืนเงิน'}
                      </Button>
                    </FlexBox>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
          {errorMessage && (
            <div className="mt-2">
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            </div>
          )}
        </Paper>
      </Box>
      {debtSOs.length > 0 && <Space />}
      {debtSOs.length > 0 && (
        <Paper>
          <Title>รายการใบสั่งขายสำหรับคืนเงิน</Title>
          <Space />
          <Table<IRefundRequisitionFormValues_DebtSaleOrder>
            columns={[
              {
                name: 'ลำดับ',
                render: (_, index) => index + 1,
                fixedWidth: 100,
              },
              {
                name: 'เลขที่ใบสั่งขาย',
                dataIndex: 'code',
              },
              {
                name: 'ยอดที่ต้องคืน',
                dataIndex: 'total',
                numeric: true,
                format: '0,0.00',
                fixedWidth: 200,
              },
              {
                name: '',
                render: (_, index) => (
                  <IconButton size="small" onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                ),
                fixedWidth: 100,
              },
            ]}
            data={debtSOs}
            postCells={
              <TableRow key="summary">
                <TableCell colSpan={3} align="right">
                  {numeral(debtTotal).format('0,0.00')}
                </TableCell>
              </TableRow>
            }
            size="small"
            cleanSpace
            pagination={false}
            color="primary.dark"
          ></Table>
        </Paper>
      )}
    </>
  )
}

export default DebtSaleOrderSelectBox
