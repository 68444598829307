import { Collapse, Grid, Paper, Switch } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FlexBox, GeneralTextFieldForm, SubTopic } from '../../../custom-components'
import { calculatePercentage } from '../../../functions'
import { ButtonRadios } from '../../../shared-components'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'

interface Props {
  defaultValue?: number
}

const PurchaseOrderDiscountBox: React.FC<Props> = (props: Props) => {
  //props
  const { defaultValue } = props

  // hook form
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    unregister,
    register,
  } = useFormContext<PurchaseOrderCreateFormValues>()

  // data
  const discountPercentages = [
    { label: '5 %', value: 5 },
    { label: '10 %', value: 10 },
    { label: '15 %', value: 15 },
  ]
  const { discountAmount, products } = watch()

  const total = products.reduce<number>((sum, product) => {
    return sum + product.price * product.amount
  }, 0)

  // state
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      register('discountAmount', {
        required: 'กรุณากรอกส่วนลด',
      })
    } else {
      setValue('discountAmount', undefined)
      unregister('discountAmount')
    }
  }, [open, setValue, register, unregister])

  useEffect(() => {
    if (defaultValue) {
      setOpen(true)
      register('discountAmount', {
        required: 'กรุณากรอกส่วนลด',
      })
      setValue('discountAmount', defaultValue)
    }
  }, [defaultValue, register, setValue, setOpen])

  return (
    <Paper>
      <FlexBox justifyContent="space-between" alignItems="center">
        <SubTopic>ส่วนลด</SubTopic>
        <Switch checked={!!open} onClick={() => setOpen((prev) => !prev)} />
      </FlexBox>
      <Collapse in={!!open}>
        <Grid container className="pt-3" spacing={2}>
          <Grid item xs={12}>
            <GeneralTextFieldForm
              control={control}
              name="discountAmount"
              type="number"
              label="ส่วนลด *"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonRadios
              items={discountPercentages.map((type) => ({ id: type.value, name: type.label }))}
              onChange={(value) => setValue('discountAmount', calculatePercentage(total, value))}
              errorMessage={errors.discountAmount?.message}
              value={discountAmount}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  )
}

export default PurchaseOrderDiscountBox
