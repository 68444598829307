import React, { useMemo } from 'react'
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions, MenuItem, Typography } from '@material-ui/core'
import { SubmitForm } from '..'
import { FlexBox, Form, GeneralTextFieldForm } from '../../custom-components'
import { UserAction } from '../../enums'
import { useActionMessages } from '../../hooks'
import { useForm } from 'react-hook-form'

export interface UserActivityValues {
  actionId: number
  actionMessage?: string
  actionMessageId: number
  remark?: string
}

export interface UserActivityDialog_TextOption {
  title: string | JSX.Element
  description?: string | JSX.Element
  actionMessageText?: string
  newActionMessageText?: string
  newActionMessageOption?: string
  actionMessageErrorText?: string
  remarkText?: string
  remarkErrorText?: string
  submitText?: string
  cancelText?: string
}

export interface UserActivityDialog_Option {
  enableActionMessage: boolean
  enableRemark: boolean
  remarkRequired?: boolean
  allowNewMessage: boolean
}

export interface UserActivityDialogProps extends DialogProps {
  userAction: UserAction
  textOption?: UserActivityDialog_TextOption
  onDialogSubmit: (values: UserActivityValues) => void
  option?: UserActivityDialog_Option
}

const UserActivityDialog: React.FC<UserActivityDialogProps> = (props) => {
  const { textOption, option, userAction, onDialogSubmit, onClose, children, ...otherProps } = props
  const messages = useActionMessages(userAction)
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<UserActivityValues>({
    defaultValues: {
      actionId: userAction,
    },
  })

  const { actionMessageId } = watch()
  const enableActionMessage = !option || option.enableActionMessage
  const enableRemark = !option || option.enableRemark
  const isRemarkRequired = enableRemark && !!option?.remarkRequired

  const renderRemarkText = useMemo(() => {
    let text = 'หมายเหตุ'
    if (textOption?.remarkText) text = textOption.remarkText
    return text.concat(isRemarkRequired ? ' *' : ' (ถ้ามี)')
  }, [textOption?.remarkText, isRemarkRequired])

  return (
    <Dialog {...otherProps}>
      <Form onSubmit={handleSubmit(onDialogSubmit)}>
        <DialogTitle>{textOption?.title || 'หัวข้อ'}</DialogTitle>
        <DialogContent>
          <FlexBox flexDirection="column" gridGap={8}>
            {!!textOption?.description && (
              <Typography variant="body1" color="textSecondary">
                {textOption.description}
              </Typography>
            )}
            {enableActionMessage && (
              <>
                <GeneralTextFieldForm
                  control={control}
                  name="actionMessageId"
                  select
                  label={!!textOption?.actionMessageText ? textOption.actionMessageText + ' *' : 'เหตุผลในการกระทำ *'}
                  rules={{
                    required: {
                      value: true,
                      message: !!textOption?.actionMessageErrorText
                        ? textOption.actionMessageErrorText
                        : 'กรุณาเลือกเหตุผล',
                    },
                  }}
                  variant="outlined"
                  defaultValue=""
                >
                  {messages.map((message) => (
                    <MenuItem key={message.id} value={message.id}>
                      {message.name}
                    </MenuItem>
                  ))}
                  {option?.allowNewMessage && (
                    <MenuItem value={-1}>
                      <Typography color="textSecondary">
                        {!!textOption?.newActionMessageOption ? textOption.newActionMessageOption : 'เพิ่มเหตุผลใหม่'}
                      </Typography>
                    </MenuItem>
                  )}
                </GeneralTextFieldForm>
                {actionMessageId === -1 && (
                  <GeneralTextFieldForm
                    control={control}
                    label={!!textOption?.newActionMessageText ? `${textOption.newActionMessageText} *` : 'เหตุผลใหม่ *'}
                    name="actionMessage"
                    rules={{
                      required: {
                        value: true,
                        message: !!textOption?.actionMessageErrorText
                          ? textOption.actionMessageErrorText
                          : 'กรุณาใส่เหตุผลใหม่',
                      },
                    }}
                  />
                )}
              </>
            )}
            {children}
            {enableRemark && (
              <GeneralTextFieldForm
                control={control}
                rules={{
                  required: {
                    value: isRemarkRequired,
                    message: textOption?.remarkErrorText ?? 'กรุณากรอกหมายเหตุ',
                  },
                }}
                label={renderRemarkText}
                name="remark"
                defaultValue=""
              />
            )}
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText={textOption?.submitText}
            cancelText={textOption?.cancelText}
            onCancel={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default UserActivityDialog
