import { useState, useEffect } from 'react'
import {
  getTransportTaskCreateDataV2API,
  GetTransportTaskCreateDataV2QueryParams,
  TransportTaskCreateDataV2,
} from '../../api/smartbestbuys-api'

export const useTransportTaskCreateDataV2 = (
  customerRequisitionId: string | undefined,
  saleOrderId: string | undefined,
): TransportTaskCreateDataV2 | undefined => {
  const [data, setData] = useState<TransportTaskCreateDataV2 | undefined>()

  useEffect(() => {
    ;(async () => {
      const queryParams: GetTransportTaskCreateDataV2QueryParams = {
        customerRequisitionId: !!customerRequisitionId ? customerRequisitionId : undefined,
        saleOrderId: !!saleOrderId ? saleOrderId : undefined,
      }
      const response = await getTransportTaskCreateDataV2API(queryParams)
      const data = response?.data ?? ({} as TransportTaskCreateDataV2)
      setData(data)
    })()
  }, [customerRequisitionId, saleOrderId])

  return data
}
