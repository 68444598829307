import { TransportTaskItemType } from '../../../../enums/TransportTaskEnums'
import {
  RequestDocument,
  TransportTaskCreateFormValues_Document,
  TransportTaskCreateFormValues_OtherDocument,
} from '../../../../pages/transport/create/TransportTaskCreateFormValues'
import { ShippingMethod } from '../../../../types'
import { ICustomerProfile_Address } from '../../customer'
import { ShippingProviderData, ShippingCarData, UserData, AddressData, ShippingProvider } from '../../data/data-types'
import { TaskAssignee } from '../../tasks'
import {
  IShippingInfo,
  ITransportTaskUpdateData,
  TransportTaskProfile,
  TransportTaskStateV2,
  TransportTaskUpdateData_Document,
  TransportTaskUpdateData_RelatedDocument,
  TransportTaskUpdateData_RelatedTask,
  TransportTaskUpdateData_SaleOrderInfo,
  TransportTaskUpdateData_SaleOrderProduct,
} from '../transport-types'

export class TransportTaskUpdateData {
  public profile: TransportTaskProfile
  public saleOrderInfo: TransportTaskUpdateData_SaleOrderInfo
  public products: TransportTaskUpdateData_SaleOrderProduct[]
  public documents: TransportTaskUpdateData_Document[]
  public relatedTasks: TransportTaskUpdateData_RelatedTask[]
  public relatedDocuments: TransportTaskUpdateData_RelatedDocument[]
  public customerShippingAddresses: ICustomerProfile_Address[]
  public customerBillingAddresses: ICustomerProfile_Address[]
  public shippingProviders: ShippingProviderData[]
  public shippingCars: ShippingCarData[]
  public users: UserData[]
  public address: AddressData

  constructor(_data: ITransportTaskUpdateData) {
    this.profile = _data.profile
    this.saleOrderInfo = _data.saleOrderInfo
    this.products = _data.products
    this.documents = _data.documents
    this.relatedTasks = _data.relatedTasks
    this.relatedDocuments = _data.relatedDocuments
    this.customerShippingAddresses = _data.customerShippingAddresses
    this.customerBillingAddresses = _data.customerBillingAddresses
    this.shippingProviders = _data.shippingProviders
    this.shippingCars = _data.shippingCars
    this.users = _data.users
    this.address = _data.address
  }

  get transportTaskState(): TransportTaskStateV2 {
    return this.profile.state!
  }

  get shippingInfo(): IShippingInfo {
    return this.transportTaskState.shippingInfo
  }

  get productsWithShippingAmount(): TransportTaskUpdateData_SaleOrderProduct[] {
    const shippingProducts =
      this.transportTaskState.items.filter((item) => item.type === TransportTaskItemType.Product) ?? []
    return this.products.map((p) => ({
      ...p,
      amount: shippingProducts.find((sp) => sp.id === p.id)?.amount,
    }))
  }

  get documentsWithShippingAmount(): {
    documents: TransportTaskCreateFormValues_Document[]
    othersDocuments: TransportTaskCreateFormValues_OtherDocument[]
    requestedDocuments: RequestDocument[]
  } {
    const itemDocuments = this.transportTaskState.items.filter((item) => item.type === TransportTaskItemType.Document)
    const shippingDocuments = this.documents.map((d) => ({
      ...d,
      amount: itemDocuments.find((item) => d.id === item.id)?.amount,
    }))

    const shippingOtherDocuments = this.transportTaskState.items
      .filter((item) => item.type === TransportTaskItemType.Document && !item.code && !item.requestChange)
      .map((item) => ({
        id: item.id,
        name: item.name,
        amount: item.amount,
        selected: true,
      }))

    const requestedDocuments: RequestDocument[] = this.transportTaskState.items
      .filter((item) => !!item.requestChange)
      .map((item) => ({
        typeId: -item.id,
        name: item.name,
        requestChange: true,
      }))

    return {
      documents: shippingDocuments.filter((d) => !!d.code),
      othersDocuments: shippingOtherDocuments,
      requestedDocuments,
    }
  }

  get shippingMethod(): ShippingMethod {
    if (!this.shippingInfo.useShippingProvider) return ShippingMethod.Smart
    if (!this.shippingInfo.shippingProvider) return ShippingMethod.None
    return ShippingMethod.Express
  }

  public setCustomerBillingAddresses = (_customerBillingAddresses: ICustomerProfile_Address[]) => {
    this.customerBillingAddresses = _customerBillingAddresses
  }

  get shippingProvider(): ShippingProvider | undefined {
    return this.shippingInfo.shippingProvider
  }

  get customerId(): number | undefined {
    return this.saleOrderInfo.customerId
  }

  get assignee(): TaskAssignee | undefined {
    return this.profile.assignee
  }

  get dueDate(): Date | undefined {
    return this.profile.dueDate
  }

  get consignee(): { name: string; phoneNumber: string } {
    return {
      name: this.shippingInfo.consignee,
      phoneNumber: this.shippingInfo.phoneNumber,
    }
  }
}
