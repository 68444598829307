import { Link, Paper, Typography } from '@material-ui/core'
import { PaymentRequisitionProfileItem } from '../../../api/smartbestbuys-api'
import { SubTopic, Table } from '../../../custom-components'
import { formatNumber } from '../../../functions/number-utils'
import { PurchaseOrderStatusChip } from '../../../shared-components'

interface Props {
  items: PaymentRequisitionProfileItem[]
}

const PaymentRequisitionItemTable: React.FC<Props> = (props: Props) => {
  // props and state
  const { items } = props

  return (
    <Paper>
      <SubTopic>ข้อมูลสินค้า/บริการ</SubTopic>
      <div className="mb-4" />
      <Table<PaymentRequisitionProfileItem>
        color="primary.dark"
        columns={[
          { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
          {
            name: 'สถานะ',
            render: ({ purchaseOrderInfo: { status } }) => (
              <PurchaseOrderStatusChip status={status.id} statusWithName={status} />
            ),
          },
          {
            name: 'เลขที่ใบสั่งซื้อ',
            render: ({ purchaseOrderInfo }) => (
              <Link href={`/purchase-orders/${purchaseOrderInfo.id}/profile`} target="_blank">
                <Typography variant="body2" color="primary">
                  {purchaseOrderInfo.code}
                </Typography>
              </Link>
            ),
          },
          {
            name: 'ยอดทำเบิก',
            dataIndex: 'total',
            numeric: true,
            format: '0,0.00',
          },
          {
            name: 'หัก ณ ที่จ่าย',
            render: ({ withholdingTaxAmount, withholdingTaxPercentage }) => {
              const data =
                !!withholdingTaxAmount && !!withholdingTaxPercentage
                  ? `${formatNumber(withholdingTaxAmount)} (${formatNumber(withholdingTaxPercentage)}%)`
                  : '-'
              return data
            },
          },
          {
            name: 'ยอดทำเบิกสุทธิ',
            render: ({ total, withholdingTaxAmount }) => {
              return !!withholdingTaxAmount ? total - withholdingTaxAmount : total
            },
            numeric: true,
            format: '0,0.00',
          },
        ]}
        data={items}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default PaymentRequisitionItemTable
