/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from 'react'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Box,
} from '@material-ui/core'
import { UpdatedTaskStatus, WarehouseProduct, WarehouseTask } from '../../api/smartbestbuys-api'
import { TaskStatusChip } from '../StatusChip'
import { Avatar, FlexBox, Link, Table, TypographyWithLabel } from '../../custom-components'
import { SubmitForm } from '../SubmitForm'
import { dateFormat } from '../../functions'
import { TaskStatus } from '../../enums'
import { CustomerProfileCard } from '../CustomerProfile'
import { useCustomerProfile } from '../../hooks'
import { Loading, ConfirmationDialog } from '..'
import { confirmToGiveProduct } from './service'
import { RootContext } from '../..'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { Photo } from '@material-ui/icons'

interface Props extends DialogProps {
  task: WarehouseTask
  onSuccess: (updated: UpdatedTaskStatus) => void
}

const WarehouseTaskDialog: React.FC<Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { task, onSuccess, onClose, ...otherProps } = props
  const { code, status, state } = task
  const [confirm, setConfirm] = useState(false)
  const [openPreviewImage, setOpenPreviewImage] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined)
  const [submitting, setSubmitting] = useState(false)

  const togglePreview = () => setOpenPreviewImage((prev) => !prev)
  const customerProfile = useCustomerProfile(task.customerInfo?.id)

  const isPending = status.id === TaskStatus.Pending

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => !isPending && onClose && onClose(e, reason)}
      {...otherProps}
    >
      <Dialog open={openPreviewImage} onClose={togglePreview}>
        <img src={previewImage} />
      </Dialog>
      <ConfirmationDialog
        open={confirm}
        title={`ยืนยันส่งมอบสินค้าให้ลูกค้า ของใบสั่งขายเลขที่ ${task?.saleOrderInfo?.code}`}
        textOk="ยืนยันส่งมอบสินค้าให้ลูกค้าแล้ว"
        onOk={async () => {
          setSubmitting(true)
          const response = await confirmToGiveProduct(task.id)
          if (response?.status === 200) {
            triggerSnackbar('ส่งมอบสำเร็จ')
            onClose && onClose({}, 'escapeKeyDown')
            onSuccess(response.data)
          } else {
            triggerSnackbar('ส่งมอบไม่สำเร็จ')
            setSubmitting(false)
          }
        }}
        onClose={() => setConfirm(false)}
        isSubmitting={submitting}
        confirmTextOption={{
          veifiedText: 'ยืนยัน',
        }}
      >
        <Typography color="primary">ตรวจสอบสินค้าและส่งมอบให้ลูกค้าแล้วใช่หรือไม่ ?</Typography>
      </ConfirmationDialog>
      {customerProfile ? (
        <>
          <DialogTitle>
            <FlexBox gridGap={16}>
              {code} <TaskStatusChip status={status.id} label={status.name} />
            </FlexBox>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomerProfileCard customerProfile={customerProfile} disableBtnCustomerDetail />
              </Grid>
              <Grid item xs={12}>
                <TypographyWithLabel label="เลขที่ใบสั่งขาย:" variant="body2" color="secondary">
                  {task?.saleOrderInfo?.code}
                </TypographyWithLabel>

                <Typography variant="body1" gutterBottom>
                  รายละเอียดสินค้า
                </Typography>
                <Table<WarehouseProduct>
                  columns={[
                    { name: 'ลำดับ', render: (_, index) => index + 1 },
                    {
                      name: `รายละเอียดสินค้า`,
                      render: ({ id, code, imageUrl, nameLocal }) => (
                        <Box display="flex" alignItems="flex-start">
                          <Avatar
                            variant="rounded"
                            src={imageUrl}
                            objectFit="contain"
                            onClick={() => {
                              if (imageUrl) {
                                togglePreview()
                                setPreviewImage(imageUrl)
                              }
                            }}
                          >
                            <Photo />
                          </Avatar>
                          {
                            <FlexBox ml={1} flexDirection="column">
                              <Link color="primary" to={`/products/${id}/profile`}>
                                {code}
                              </Link>

                              <Typography variant="body2">{nameLocal}</Typography>
                            </FlexBox>
                          }
                        </Box>
                      ),
                    },
                    { name: 'จำนวน', dataIndex: 'amount' },
                  ]}
                  data={state?.products || []}
                  size="small"
                  color="primary.dark"
                  cleanSpace
                  pagination={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {task.dueDate && (
                  <TypographyWithLabel label="วันที่นัดรับ">{dateFormat(task.dueDate)}</TypographyWithLabel>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          {isPending && (
            <DialogActions>
              <SubmitForm
                onSubmit={() => {
                  setConfirm(true)
                }}
                submitText="ส่งมอบให้ลูกค้า"
                onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
                cancelText="ยกเลิก"
                submitPermission={PermissionEnum.Warehouse_Task_Write}
              />
            </DialogActions>
          )}
        </>
      ) : (
        <Loading />
      )}
    </Dialog>
  )
}

export default WarehouseTaskDialog
