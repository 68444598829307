import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'

const Topic: React.FC<TypographyProps> = (props) => (
  <Typography variant="h4" {...props}>
    {props.children}
  </Typography>
)

export default Topic
