import { Box, Paper } from '@material-ui/core'
import React, { useContext } from 'react'
import { QuotationProfile_Activity } from '../../../api/smartbestbuys-api/quotation'
import { dateFormat } from '../../../functions'
import { UserActivityTable } from '../../../shared-components'
import { QuotationsProfilePageContextImpl } from './QuotationsProfilePage'

const QuotationsManagementHistoryList: React.FC = () => {
  const { profile } = useContext(QuotationsProfilePageContextImpl)

  return (
    <Box p={3} clone>
      <Paper>
        <UserActivityTable<QuotationProfile_Activity>
          title="ประวัติการจัดการใบเสนอราคา"
          activities={profile.activities ?? []}
          customColumns={[
            {
              name: 'วันนัดครั้งถัดไป',
              dataIndex: 'nextFollowSchedule',
              render: ({ nextFollowSchedule }) => (nextFollowSchedule ? dateFormat(new Date(nextFollowSchedule)) : '-'),
            },
          ]}
        />
      </Paper>
    </Box>
  )
}

export default QuotationsManagementHistoryList
