import { useState, useEffect } from 'react'
import { fetchWarehouseTasksV2 } from '../../api/smartbestbuys-api'
import { NewWarehouseTask } from '../../api/smartbestbuys-api'
import { TaskType } from '../../enums'

export const useWarehouseTasksV2 = (
  startDate: Date,
  endDate: Date,
  typeId?: TaskType,
  reload?: boolean,
): NewWarehouseTask[] => {
  const [data, setData] = useState<NewWarehouseTask[]>([])

  useEffect(
    () => {
      ;(async () => {
        const response = await fetchWarehouseTasksV2({ startDate, endDate, typeId })
        const data = response?.data ?? []

        setData(data)
      })()
    }, // eslint-disable-next-line
    [startDate.toString(), endDate.toString(), typeId, reload],
  )

  return data
}
