import {
  GetSOPaymentTransactionTableDataRequestQueryParams,
  ISOPaymentTransactionTableData,
  SaleOrderPartialUpdateSOPaymentTransactionParams,
  SaleOrderPartialUpdateSOPaymentTransactionRequestBody,
  SaleOrderWithPaymentTransactionParams,
} from '..'
import { errorGenericHandle, errorHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import smartAuthAPI2 from '../smartbestbuys-api-2'

export const updateReferenceDocumentsAPI = errorGenericHandle<void, SaleOrderWithPaymentTransactionParams>(
  async (urlParams: SaleOrderWithPaymentTransactionParams, request: { refDocumentIds: number[] }) => {
    return smartAuthAPI.post(
      `/sale-orders/${urlParams.id}/payments/${urlParams.transactionId}/update-ref-documents`,
      request,
    )
  },
)

export const getSOPaymentTransactionTableDataAPI = errorHandler<ISOPaymentTransactionTableData>(
  async (queryParams: GetSOPaymentTransactionTableDataRequestQueryParams) => {
    return await smartAuthAPI2.get(`/sale-orders/payment-transactions/table-data`, {
      params: queryParams,
    })
  },
)

export const partialUpdateSOPaymentTransaction = errorGenericHandle<
  boolean,
  SaleOrderPartialUpdateSOPaymentTransactionParams
>(
  async (
    params: SaleOrderPartialUpdateSOPaymentTransactionParams,
    request: SaleOrderPartialUpdateSOPaymentTransactionRequestBody,
  ) => {
    return await smartAuthAPI.patch(
      `/sale-orders/${params.id}/payments/${params.transactionId}/partial-update`,
      request,
    )
  },
)
