import React, { useState, useContext } from 'react'
import { DialogProps } from '@material-ui/core'
import { ConfirmationDialog } from '../../../../shared-components'
import { RootContext } from '../../../..'
import { partialUpdateSOPaymentTransaction } from '../../../../api/smartbestbuys-api'

interface Props extends DialogProps {
  parameter: { saleOrderId: number; transactionId: number; code: string }
  onSuccess?: () => void
  onClose?: () => void
}

const SOPaymentTransactionConfirmWithholdingDocumentDialog: React.FC<Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const [submitting, setSubmitting] = useState(false)
  const { parameter, onSuccess, ...dialogProps } = props

  return (
    <ConfirmationDialog
      title={`ยืนยันรับเอกสาร หักภาษี ณ ที่จ่าย : ${parameter.code}`}
      textOk="ยืนยัน"
      isSubmitting={submitting}
      confirmTextOption={{
        veifiedText: 'ยืนยัน',
      }}
      onOk={async () => {
        setSubmitting(true)
        const response = await partialUpdateSOPaymentTransaction(
          {
            id: parameter.saleOrderId.toString(),
            transactionId: parameter.transactionId.toString(),
          },
          {
            withholdingTaxDocumentReceived: true,
          },
        )
        if (response?.status === 200) {
          triggerSnackbar('รับเอกสาร หัก ณ ที่จ่าย สำเร็จ')
          setSubmitting(false)
          if (onSuccess) onSuccess()
        } else {
          triggerSnackbar('รับเอกสาร หัก ณ ที่จ่าย ไม่สำเร็จ')
          setSubmitting(false)
        }
      }}
      {...dialogProps}
    ></ConfirmationDialog>
  )
}

export default SOPaymentTransactionConfirmWithholdingDocumentDialog
