import { errorHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { INotification } from './general-types'
import { DateTime } from 'luxon'

export const getNotificationAPI = errorHandler<INotification>(async () => {
  const startDate = DateTime.now().minus({ month: 1 }).toJSDate()
  const endDate = DateTime.now().toJSDate()

  return smartAuthAPI.get('/v1/notification', {
    params: {
      startDate,
      endDate,
    },
  })
})
