import { Tooltip, useTheme } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { useMemo } from 'react'

interface Props {
  isActive?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const WithholdingTaxDocumentReceivedToggle = ({ isActive = false, onClick, disabled }: Props) => {
  const theme = useTheme()

  const css = useMemo(() => {
    if (isActive) {
      return {
        bgColor: theme.palette.success.light,
        textColor: theme.palette.success.darkText,
      }
    }

    return {
      bgColor: theme.palette.grey[200],
      textColor: theme.palette.grey[400],
    }
  }, [theme, isActive])

  return (
    <Tooltip title={isActive ? 'รับเอกสารหัก ณ ที่จ่ายแล้ว' : 'รับเอกสารหัก ณ ที่จ่าย'}>
      <div
        onClick={disabled ? undefined : onClick}
        className="w-8 h-8 rounded-full flex items-center justify-center"
        style={{ backgroundColor: css.bgColor, cursor: disabled ? 'help' : 'pointer' }}
      >
        <Description style={{ fontSize: '1.25rem', color: css.textColor }} />
      </div>
    </Tooltip>
  )
}
