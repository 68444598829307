import { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../..'
import { DocumentStatus, PageName, UserAction } from '../../../enums'
import { useInvoiceTableData } from '../../../hooks/useInvoiceTableData'
import { DocumentCancellationDialog, DocumentStatusChip, Loading } from '../../../shared-components'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { FlexBox, GeneralTextField, Link, Table, Topic, withLayout } from '../../../custom-components'
import { Autocomplete } from '@material-ui/lab'
import { dateTimeFormat } from '../../../functions'
import SaleOrderProfileContext from '../../sale-orders/id/SaleOrderProfilePage/SaleOrderProfileContext'
import { TaxInvoiceCreationDialog } from './TaxInvoiceCreationDialog'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { Cancel, Description } from '@material-ui/icons'
import { IDocumentTableData_Data } from '../../../api/smartbestbuys-api'
import { documentStatuses } from '../../../constants'
import { useActionMessages } from '../../../hooks'

const InvoiceTableDataPage: React.FC = (props) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  setCurrentPage(PageName.INVOICE)

  const { reload } = useContext(SaleOrderProfileContext)

  // state
  const [idToCancelId, setIdToCancel] = useState<number | undefined>(undefined)
  const [idToCreateTaxInvoice, setIdToCreateTaxInvoice] = useState<number | undefined>(undefined)
  const [invoices, setInvoices] = useState<IDocumentTableData_Data[]>([])

  const tableData = useInvoiceTableData({
    pageIndex: 0,
    rowPerPage: 10,
    search: { documentCode: '', saleOrderCode: '', soPaymentCode: '', customerName: '', statusIds: [] },
  })
  const messages = useActionMessages(UserAction.CancelDocument)

  useEffect(() => {
    if (tableData?.data) setInvoices(tableData.data)
  }, [tableData?.data])

  if (!tableData) return <Loading />
  const {
    data,
    paginationInfo,
    isLoading,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  const writePermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Write)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Topic>รายการใบแจ้งหนี้</Topic>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="documentCode"
                  onChange={(e) => handleSearchParamChange('documentCode', e.target.value)}
                  label="เลขใบแจ้งหนี้"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="saleOrderCode"
                  onChange={(e) => handleSearchParamChange('saleOrderCode', e.target.value)}
                  label="เลขใบสั่งขายอ้างอิง"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="customerName"
                  onChange={(e) => handleSearchParamChange('customerName', e.target.value)}
                  label="ชื่อลูกค้า"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  size="small"
                  options={documentStatuses.map((option) => option.name)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((label: string, index: number) => (
                      <Chip variant="outlined" label={label} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange(
                      'statusIds',
                      documentStatuses.filter((opt) => newValue.includes(opt.name)).map((opt) => opt.id),
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="สถานะใบแจ้งหนี้"
                      placeholder="สถานะใบแจ้งหนี้"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="button"
                  type="submit"
                  disabled={isLoading}
                  startIcon={isLoading ? <CircularProgress size={8} /> : undefined}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<IDocumentTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: 'สถานะใบแจ้งหนี้',
                render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
              },
              {
                name: 'เลขใบแจ้งหนี้',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/invoices/${id}/pdf`} target="_blank">
                    {code}
                  </Link>
                ),
              },
              {
                name: 'เลขใบสั่งขายอ้างอิง',
                render: (invoice) => (
                  <Link color="secondary" to={`/sale-orders/${invoice.saleOrder?.id}`} target="_blank">
                    {invoice.saleOrder?.code}
                  </Link>
                ),
              },
              {
                name: 'รายได้อ้างอิง (SPAY)',
                render: ({ paymentCode }) =>
                  paymentCode ? (
                    <Typography color="secondary" variant="body2">
                      {paymentCode}
                    </Typography>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      -
                    </Typography>
                  ),
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
              {
                name: 'ชื่อลูกค้า',
                render: ({ customer, billingAddress }) =>
                  billingAddress?.overrideDocumentData?.customerName || customer?.nameLocal || '-',
              },
              {
                name: 'ยอดที่ต้องชำระ',
                dataIndex: 'total',
                numeric: true,
                format: '0,0.00',
              },
              {
                name: '',
                render: (invoice) => {
                  // TODO: support issued tax invoice
                  const isCancelled = invoice.status.id === DocumentStatus.Cancelled
                  if (isCancelled) {
                    return <></>
                  }
                  const isSelected = invoice.id === idToCreateTaxInvoice
                  return (
                    <>
                      <DocumentCancellationDialog
                        messages={messages}
                        documentInfo={invoice}
                        open={idToCancelId === invoice.id}
                        onClose={() => {
                          setIdToCancel(undefined)
                        }}
                        onSuccess={(updatedData) => {
                          triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
                          reload()
                          let newData = data
                          const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                          if (findIndex !== -1) {
                            const found = newData[findIndex]
                            const updated = {
                              ...found,
                              ...updatedData,
                            }
                            newData[findIndex] = updated
                            setInvoices([...newData])
                          }
                        }}
                        onFail={() => {
                          triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
                        }}
                      />
                      {isSelected && (
                        <TaxInvoiceCreationDialog
                          invoice={{
                            id: invoice.id,
                            code: invoice.code,
                            type: invoice.type,
                            status: invoice.status,
                            total: invoice.total,
                            recCreatedAt: invoice.recCreatedAt,
                            recCreatedBy: invoice.recCreatedBy,
                            saleOrderInfo: invoice.saleOrder
                              ? {
                                  id: invoice.saleOrder.id,
                                  code: invoice.saleOrder.code,
                                  createdAt: new Date(), // data is not used but feeding for interface matching
                                  status: {
                                    id: -1, // data is not used but feeding for interface matching
                                    name: 'mock', // data is not used but feeding for interface matching
                                  },
                                }
                              : undefined,
                            customerInfo: invoice.customer
                              ? {
                                  id: invoice.customer.id,
                                  name: invoice.customer.nameLocal,
                                  referenceNumber: 'mock', // data is not used but feeding for interface matching
                                  address: 'mock', // data is not used but feeding for interface matching
                                  phoneNumber: 'mock', // data is not used but feeding for interface matching
                                  email: 'mock', // data is not used but feeding for interface matching
                                  creditPeriodDay: -1, // data is not used but feeding for interface matching
                                }
                              : undefined,
                          }}
                          open={isSelected}
                          onClose={() => {
                            setIdToCreateTaxInvoice(undefined)
                          }}
                        />
                      )}
                      <FlexBox gridGap={8}>
                        {writePermission.hasPermission && (
                          <Tooltip title="สร้างใบกำกับภาษี" placement="top">
                            <span>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setIdToCreateTaxInvoice(invoice.id)}
                              >
                                <Description fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        {cancelPermission.hasPermission && (
                          <IconButton size="small" onClick={() => setIdToCancel(invoice.id)}>
                            <Cancel fontSize="small" />
                          </IconButton>
                        )}
                      </FlexBox>
                    </>
                  )
                },
              },
            ]}
            data={invoices}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(InvoiceTableDataPage)
