import { CustomerPickUpMethod } from '../../../types'
import { errorHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const createSaleOrder = errorHandler<SaleOrderCreationResponse>(async (request: SaleOrderCreationRequest) => {
  const path = `/sale-orders`
  return await smartAuthAPI.post(path, request)
})

export interface SaleOrderCreationRequest {
  quotationId?: number
  customerId: number
  contactId?: number
  billingAddressId?: number
  contactChannelId?: number
  customerPurchaseReference?: string
  currencyId: number
  exchangeRate: number
  paymentMethodId: number
  paymentConditionId: number
  vatIncluded: boolean
  discountAmount?: number
  discountAmountTypeId?: number
  estimatedShippingDay?: number
  estimatedShippingFee?: number
  depositRequired: boolean
  remark?: string
  dueDate?: Date
  customerPickUpMethod?: CustomerPickUpMethod
  creditPeriodDay?: number
  isShippingFeeIncludingVat?: boolean
  attachments?: SaleOrderAttachmentCreation[]
  products: SaleOrderProductCreation[]
  total?: number
}

export interface SaleOrderAttachmentCreation {
  base64?: string
  attachmentUrl?: string
}

export interface SaleOrderProductCreation {
  id: number
  code?: string
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  amount: number
  price: number
}

export interface SaleOrderCreationResponse {
  id: number
}
