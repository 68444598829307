import { Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { PurchaseProductPurchaseHistory } from '../../../../../api/smartbestbuys-api'
import { Table } from '../../../../../custom-components'
import { dateTimeFormat } from '../../../../../functions'
import PurchaseOrderCreateFormValues from '../../PurchaseOrderCreateFormValues'

interface Props {
  purchaseProductHistory?: PurchaseProductPurchaseHistory[]
  currency?: string
}

interface PurchaseOrderProductOpeningTableData extends PurchaseProductPurchaseHistory {
  id: number
}

const PurchaseOrderProductOpeningTable: React.FC<Props> = (props: Props) => {
  const { purchaseProductHistory, currency } = props

  const { watch } = useFormContext<PurchaseOrderCreateFormValues>()

  const { vendor } = watch()

  const filterPurchaseProductHistory = purchaseProductHistory?.filter((product) => product.vendor.id !== vendor?.id)

  return (
    <Table<PurchaseOrderProductOpeningTableData>
      size="small"
      color="primary.dark"
      columns={[
        {
          name: 'เลขที่ใบสั่งซื้อ',
          fixedWidth: 140,
          dataIndex: 'code'
        },
        {
          name: 'ผู้ขาย',
          fixedWidth: 140,
          render: ({ vendor }) => vendor.name,
        },
        {
          name: 'จำนวน',
          fixedWidth: 127,
          numeric: true,
          format: '0,0',
          dataIndex: 'amount'
        },
        {
          name: 'จำนวนที่รับแล้ว',
          fixedWidth: 147,
          numeric: true,
          format: '0,0',
          dataIndex: 'receivedAmount'
        },
        {
          name: 'ราคาต่อหน่วย',
          fixedWidth: 200,
          numeric: true,
          render: ({ price }) => (
            <Typography variant="body2" className="float-right">
              {price} {currency || 'บาท'}
            </Typography>
          ),
        },
        {
          name: 'วันที่ผู้ขายเริ่มส่ง',
          fixedWidth: 140,
          render: ({ vendorShippingDate }) => (vendorShippingDate ? dateTimeFormat(vendorShippingDate) : undefined),
        },
        {
          name: 'วันที่ผู้ขายเริ่มส่ง',
          fixedWidth: 140,
          render: ({ recCreatedAt }) => (recCreatedAt ? dateTimeFormat(recCreatedAt) : undefined),
        },
      ]}
      data={filterPurchaseProductHistory || []}
      cleanSpace
      pagination
    />
  )
}

export default PurchaseOrderProductOpeningTable
