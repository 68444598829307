import { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogProps,
  DialogActions,
  InputAdornment,
  MenuItem,
} from '@material-ui/core'
import { FlexBox, Form, GeneralTextFieldForm, KeyboardDatePickerForm } from '../../../custom-components'
import { SubmitWithConfirmTextForm, UploadImageCard } from '../../../shared-components'
import {
  PaymentRequisitionProfile,
  PaymentRequisitionUploadReceiptRequestBody,
} from '../../../api/smartbestbuys-api/payment-requisitions/types'
import { BaseData, uploadPaymentRequisitionReceipt } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import { DocumentType } from '../../../enums'

interface UploadReceiptFormValues {
  documentTypeId: number
  base64: string
  receiptCode: string
  receiptTotal?: number
  receiptDate?: Date
}

interface UploadReceiptDialogProps extends DialogProps {
  paymentRequisitionId: number
  isSinglePurchaseOrder: boolean
  onSuccess?: (paymentRequisitionProfile: PaymentRequisitionProfile) => void
  onFailed?: () => void
}

// document types which can upload as purchase order document
const documentTypes: BaseData[] = [
  { id: 102, name: 'ใบกำกับภาษี' },
  { id: 104, name: 'ใบเสร็จรับเงิน' },
]

const UploadReceiptDialog = (props: UploadReceiptDialogProps) => {
  // root context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { paymentRequisitionId, isSinglePurchaseOrder, onSuccess, onFailed, onClose, ...otherProps } = props

  // form hook
  const formMethod = useForm<UploadReceiptFormValues>({
    defaultValues: {
      documentTypeId: !isSinglePurchaseOrder ? DocumentType.Receipts : undefined,
    },
  })
  const {
    control,
    setValue,
    formState: { errors, isSubmitting },
    register,
  } = formMethod

  // handle
  const handleSubmit = async (values: UploadReceiptFormValues) => {
    const request: PaymentRequisitionUploadReceiptRequestBody = {
      documentTypeId: values.documentTypeId,
      base64: values.base64,
      receiptCode: values.receiptCode,
      receiptDate: values.receiptDate,
      total: values.receiptTotal,
    }
    // console.log(request)
    const response = await uploadPaymentRequisitionReceipt(paymentRequisitionId, request)
    if (response?.status === 200) {
      triggerSnackbar('อัปโหลดใบเสร็จสำเร็จ')
      if (!!onSuccess) onSuccess(response.data)
    } else {
      triggerSnackbar('อัปโหลดใบเสร็จไม่สำเร็จ')
      if (!!onFailed) onFailed()
    }
    return true
  }

  // effect
  useEffect(() => {
    register('base64', { required: { value: true, message: 'กรุณาแนบรูปหลักฐาน' } })
  }, [register])

  return (
    <FormProvider {...formMethod}>
      <Dialog {...otherProps} fullWidth maxWidth="sm">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>อัปโหลดใบเสร็จจากผู้ขาย</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FlexBox justifyContent="center">
                  <UploadImageCard
                    previewOnChange
                    title="อัปโหลดใบเสร็จหรือใบแทนใบเสร็จ *"
                    onChange={(image) => setValue('base64', image.base64)}
                    error={!!errors.base64}
                    message={errors.base64?.message}
                  />
                </FlexBox>
              </Grid>
              <Grid item xs={12} md={6} className="md:pr-3">
                <GeneralTextFieldForm
                  control={control}
                  name="documentTypeId"
                  select
                  label="ชื่อเรียกเอกสาร"
                  rules={{ required: { value: true, message: 'กรุณาเลือกประเภทเอกสาร' } }}
                  variant="outlined"
                  defaultValue=""
                  disabled={!isSinglePurchaseOrder}
                >
                  {documentTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </GeneralTextFieldForm>
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="receiptCode"
                  label="เลขที่เอกสาร *"
                  rules={{
                    required: 'กรุณากรอกสาขา',
                  }}
                  error={!!errors.receiptCode}
                  helperText={!!errors.receiptCode && errors.receiptCode.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="receiptTotal"
                  label="จำนวนเงิน *"
                  type="number"
                  inputProps={{
                    step: '0.01',
                  }}
                  rules={{
                    required: 'กรุณากรอกจำนวนเงิน',
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                  }}
                  error={!!errors.receiptTotal}
                  helperText={!!errors.receiptTotal && errors.receiptTotal.message}
                />
              </Grid>
              <Grid item xs={12} md={6} className="md:pr-3 pt-2">
                <KeyboardDatePickerForm
                  control={control}
                  label="วันที่ออกเอกสาร"
                  name="receiptDate"
                  value={null}
                  size="small"
                  rules={{ required: { value: true, message: 'กรุณาเลือกวันที่ออกเอกสาร' } }}
                  defaultValue={null}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitWithConfirmTextForm
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
              confirmTextOption={{
                veifiedText: 'เอกสารถูกต้อง',
              }}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  )
}
export default UploadReceiptDialog
