import React, { useContext } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { FlexBox, SubTopic } from '../../../../../custom-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { Loading, AttachmentCard } from '../../../../../shared-components'

const SOAttachments: React.FC = (props) => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  if (!saleOrderProfile) return <Loading />

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>ไฟล์แนบเพิ่มเติม</SubTopic>
        </Grid>
        <Grid item xs={12}>
          <FlexBox gridGap={16} flexWrap="wrap">
            {saleOrderProfile.attachments?.map((att, index) => (
              <AttachmentCard name={`เอกสารแนบ ${index + 1}`} src={att.attachmentUrl} />
            ))}
          </FlexBox>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SOAttachments
