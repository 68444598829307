import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid } from '@material-ui/core'

import { ProductType } from '../../../../../enums'
import { IProductProfile, IStatusResponse, verifyProductDataAPI } from '../../../../../api/smartbestbuys-api'
import { TypographyWithLabel } from '../../../../../custom-components'
import { formatSizeMMText, formatWeightGramText } from '../../../../../functions'
import { formatNumber } from '../../../../../functions/number-utils'

interface Props extends DialogProps {
  productId: number
  onComplete: (data: IStatusResponse) => void
  onFail: () => void
  profile: IProductProfile
}

const ProductRestoreDialog: React.FC<Props> = (props: Props) => {
  // set up props
  const { productId, profile, onComplete, onFail, onClose, ...dialogProps } = props

  const handleSubmit = async () => {
    const response = await verifyProductDataAPI(productId)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
  }

  const handleClose = () => {
    if (!!onClose) onClose({}, 'escapeKeyDown')
  }

  const title = profile?.type.id === ProductType.Service ? 'บริการ' : 'สินค้า'

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>ตรวจสอบข้อมูลสินค้า</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ชื่อสินค้า (ภาษาไทย):">{profile.nameLocal || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ชื่อสินค้า (English):">{profile.nameEn || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="หน่วย (ภาษาไทย):">{profile.unitLocal || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="หน่วย (English):">{profile.unitEn || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="กลุ่มธุรกิจ:">{profile.businessUnit?.name || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label={`หมวดหมู่${title}:`}>{profile.category?.name || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="หมวดหมู่ย่อย:">{profile.subCategory?.name || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ระยะเวลาประกัน:">
              {profile.warrantyPeriodDay ? `${profile.warrantyPeriodDay} วัน` : 'ไม่มีประกัน'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label={`ระยะการสั่ง${title}เฉลี่ย:`}>{`${profile.leadTime} วัน`}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="นโยบายเก็บ:">{`${profile.safetyStockPolicy} วัน`}</TypographyWithLabel>
          </Grid>
          {!!profile.dealerPrice && (
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="ราคาสำหรับ dealer:">{formatNumber(profile.dealerPrice)}</TypographyWithLabel>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ยี่ห้อ:">{profile.brand || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="สี:">{profile.color || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="วัสดุ:">{profile.material || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ยาว:">{formatSizeMMText(profile.depthInMm) || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="กว้าง:">{formatSizeMMText(profile.widthInMm) || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="สูง:">{formatSizeMMText(profile.heightInMm) || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="น้ำหนัก:">
              {formatWeightGramText(profile.weightInGram) || '-'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="เงื่อนไขการรับประกันสินค้า:">
              {profile.warrantyCondition || '-'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="karajic matrix:">{profile.karajicMatrix?.name || '-'}</TypographyWithLabel>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button color="primary" onClick={handleClose}>
              ยกเลิก
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              ยืนยัน
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ProductRestoreDialog
