import { BillingNoteItem, PriceBreakdownItem, ReceiptItem } from '..'
import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { DocumentType } from '../../../enums'

export interface CustomerBillingDocumentCreationRequest {
  documentType: DocumentType.Receipts | DocumentType.BillingNote
  billingAddressId: number
  items: BillingNoteItem[] | ReceiptItem[]
  priceBreakdowns: PriceBreakdownItem[]
  remark?: string
}

interface CreatedDocument {
  id: number
  code: string
  typeId: number
  statusId: number
  saleOrderId?: number
  referenceDocumentId?: number
  content: string
  customerId?: number
  state?: string
  total?: number
  recCreatedAt: Date
  recCreatedBy: string
}

export const createBillingDocumentAPI = errorSingleHandler<CreatedDocument>(
  (id: number, request: CustomerBillingDocumentCreationRequest) => {
    const path = `/customers/${id}/create-billing-document`
    return smartAuthAPI.post(path, request)
  },
)
