import { useContext } from 'react'
import { RootContext } from '../../..'
import { NavigationItemKey, PageName, PurchaseOrderStatus } from '../../../enums'
import { usePurchaseOrderTableData } from '../../../hooks/usePurchaseOrderTableData'
import {
  AlertDocumentSignal,
  AlertShippingDateWithVendorSignal,
  InformationTooltip,
  Loading,
  NotificationHead,
  PurchaseOrderPaymentStatusChip,
  PurchaseOrderStatusChip,
  PurchaseRequisitionCancelSignal,
  SendToManagerCancelSignal,
} from '../../../shared-components'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { GeneralTextField, Link, Table, Topic, withLayout } from '../../../custom-components'
import { Autocomplete } from '@material-ui/lab'
import { IPurchaseOrderTableData_Data } from '../../../api/smartbestbuys-api'
import { dateFormat, dateTimeFormat } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { purchaseOrderPaymentStatuses, purchaseOrderStatuses } from '../../../constants'
import { useHistory } from 'react-router-dom'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const PurchaseOrderTableDataPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PURCHASE_ORDER)

  const history = useHistory()

  const readPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Read)

  if (!readPermission.hasPermission) {
    history.push('/')
  }

  const tableData = usePurchaseOrderTableData()

  if (!tableData) return <Loading />
  const {
    data,
    paginationInfo,
    pendingSearch,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    isLoading,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  const handleOnCheckPRCancelled = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('isPRCancelled', event.target.checked ? 1 : 0)
  }

  const handleOnCheckNoVendorAppointment = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('isNoVendorAppointment', event.target.checked ? 1 : 0)
  }

  const handleOnCheckNoDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('isNoDocument', event.target.checked ? 1 : 0)
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Topic>รายการใบสั่งซื้อ</Topic>
          <NotificationHead navigationKey={NavigationItemKey.PurchaseOrder} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="code"
                  onChange={(e) => handleSearchParamChange('code', e.target.value)}
                  label="เลขที่ใบสั่งซื้อ"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="saleOrderCode"
                  onChange={(e) => handleSearchParamChange('saleOrderCode', e.target.value)}
                  label="เลขที่ใบสั่งขายอ้างอิง"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="vendorName"
                  onChange={(e) => handleSearchParamChange('vendorName', e.target.value)}
                  label="ชื่อผู้ขาย"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="date"
                  name="customerAppointmentDate"
                  onChange={(e) =>
                    handleSearchParamChange(
                      'customerAppointmentDate',
                      e.target.value ? new Date(e.target.value) : undefined,
                    )
                  }
                  label="วันที่ลูกค้าต้องการ"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="date"
                  name="vendorShippingDate"
                  onChange={(e) =>
                    handleSearchParamChange('vendorShippingDate', e.target.value ? new Date(e.target.value) : undefined)
                  }
                  label="วันที่ตกลงกับผู้ขาย"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="status-tags-standard"
                  size="small"
                  options={purchaseOrderStatuses.map((option) => option.name)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((label: string, index: number) => (
                      <Chip variant="outlined" label={label} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange(
                      'purchaseOrderStatusIds',
                      purchaseOrderStatuses.filter((opt) => newValue.includes(opt.name)).map((opt) => opt.id),
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="สถานะใบสั่งซื้อ"
                      placeholder="สถานะใบสั่งซื้อ"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="payment-tags-standard"
                  size="small"
                  options={purchaseOrderPaymentStatuses.map((option) => option.name)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((label: string, index: number) => (
                      <Chip variant="outlined" label={label} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange(
                      'paymentStatusIds',
                      purchaseOrderPaymentStatuses.filter((opt) => newValue.includes(opt.name)).map((opt) => opt.id),
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="สถานะการจ่ายเงิน"
                      placeholder="สถานะการจ่ายเงิน"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="createdBy"
                  onChange={(e) => handleSearchParamChange('createdBy', e.target.value)}
                  label="ชื่อผู้สร้างใบสั่งซื้อ"
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox checked={pendingSearch.isPRCancelled === 1} onChange={handleOnCheckPRCancelled} />
                    }
                    label="คำขอซื้อถูกยกเลิก"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pendingSearch.isNoVendorAppointment === 1}
                        onChange={handleOnCheckNoVendorAppointment}
                      />
                    }
                    label="ยังไม่ได้นัดหมายผู้ขาย"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={pendingSearch.isNoDocument === 1} onChange={handleOnCheckNoDocument} />}
                    label="ยังไม่อัปโหลดเอกสาร"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<IPurchaseOrderTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: '',
                render: ({ isPurchaseRequisitionCanceled, status, vendorShippingDate, total, documentTotal }) =>
                  ![PurchaseOrderStatus.Rejected, PurchaseOrderStatus.Canceled].includes(status.id) && (
                    <div className="signal-cell">
                      {isPurchaseRequisitionCanceled && (
                        <PurchaseRequisitionCancelSignal className="signal-cell__signal" />
                      )}
                      {status.id === PurchaseOrderStatus.LeaderPending && (
                        <SendToManagerCancelSignal className="signal-cell__signal" />
                      )}
                      {!vendorShippingDate && <AlertShippingDateWithVendorSignal className="signal-cell__signal" />}
                      {total > (documentTotal ?? 0) && <AlertDocumentSignal className="signal-cell__signal" />}
                    </div>
                  ),
              },
              {
                name: 'สถานะใบสั่งซื้อ',
                dataIndex: 'status',
                render: ({ status }) => <PurchaseOrderStatusChip status={status.id} label={status.name} />,
              },
              {
                name: 'สถานะการจ่ายเงิน',
                dataIndex: 'paymentStatus',
                render: ({ paymentStatus }) => (
                  <PurchaseOrderPaymentStatusChip status={paymentStatus.id} label={paymentStatus.name} />
                ),
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
              {
                name: 'วันที่ตกลงกับผู้ขาย',
                render: ({ vendorShippingDate }) =>
                  vendorShippingDate ? (
                    dateFormat(vendorShippingDate)
                  ) : (
                    <Typography color="textSecondary">ยังไม่นัดหมาย</Typography>
                  ),
              },
              {
                name: 'วันที่ลูกค้าต้องการ',
                render: ({ purchaseRequisition }) =>
                  purchaseRequisition?.dueDate ? (
                    dateFormat(purchaseRequisition?.dueDate)
                  ) : (
                    <Typography color="textSecondary">ไม่ระบุ</Typography>
                  ),
              },
              {
                name: 'เลขที่ใบสั่งซื้อ',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/purchase-orders/${id}/profile`}>
                    <Typography variant="body2">{code}</Typography>
                  </Link>
                ),
              },
              {
                name: 'ชื่อผู้ขาย',
                dataIndex: 'vendor',
                render: ({ vendor }) => vendor.name,
              },
              { name: 'ยอดรวมสุทธิ', dataIndex: 'total', numeric: true, format: '0,0.00' },
              {
                name: 'จำนวนเอกสานทางการเงินอ้างอิง',
                dataIndex: 'documentAmount',
                align: 'center',
                render: ({ documentAmount }) => (!!documentAmount ? formatNumberInt(documentAmount) : '-'),
              },
              {
                name: 'เลขที่ใบสั่งขายอ้างอิง',
                dataIndex: 'saleOrder',
                render: ({ saleOrder }) =>
                  saleOrder ? (
                    <Link color="secondary" to={`/sale-orders/${saleOrder.id}/profile`}>
                      <Typography variant="body2">{saleOrder.code}</Typography>
                    </Link>
                  ) : (
                    '-'
                  ),
              },
              {
                name: 'ผู้สร้างใบสั่งซื้อ',
                dataIndex: 'recCreatedBy',
                render: ({ recCreatedBy }) => recCreatedBy,
              },
              {
                name: '',
                render: ({ remark, purchaseRequisition, recCreatedBy, latestVerifiedBy }) => {
                  return (
                    <InformationTooltip
                      title={
                        <ul>
                          <li>เหตุผล: {!!remark ? remark : '-'} </li>
                          <li>PR อ้างอิง: {purchaseRequisition?.code} </li>
                          <li>สร้างโดย: {recCreatedBy} </li>
                          <li>ตรวจสอบโดย: {!!latestVerifiedBy ? latestVerifiedBy : '-'} </li>
                        </ul>
                      }
                    />
                  )
                },
              },
            ]}
            data={data}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(PurchaseOrderTableDataPage, true)
