import { BaseData } from '../api/smartbestbuys-api'
import { ProductKarajicMatrix, ProductUnitSize, ProductUnitWeight } from '../enums'

export const productKarajicMatrixs: BaseData[] = [
  { id: ProductKarajicMatrix.Critical, name: 'Critical' },
  { id: ProductKarajicMatrix.BotttleNeck, name: 'Botttle Neck' },
  { id: ProductKarajicMatrix.Routine, name: 'Routine' },
  { id: ProductKarajicMatrix.Leverage, name: 'Leverage' },
]

export const sizeOfUnitProducts = [
  { id: ProductUnitSize.Meter, name: 'เมตร' },
  { id: ProductUnitSize.Centimeter, name: 'เซนติเมตร' },
  { id: ProductUnitSize.Millimeter, name: 'มิลลิเมตร' },
]

export const weightOfUnitProducts = [
  { id: ProductUnitWeight.KiloGram, name: 'กิโลกรัม' },
  { id: ProductUnitWeight.Gram, name: 'กรัม' },
]

export const formatSizeMMText = (size?: number): string => {
  if (!!size && size > 0) {
    let newSize = size.toString()
    let text = ''
    const length = newSize.length

    // m
    if (length >= 4) {
      const subSize = newSize.substring(0, length - 3)
      text += subSize + ' ม. '
      newSize = newSize.substring(length - 3, length)
    }

    // cm
    if (newSize.length > 1 && newSize.length <= 3 && newSize !== '000') {
      const subSize = newSize.substring(0, newSize.length - 1)
      text += subSize + ' ซม. '
    }

    // mm
    if (newSize.substring(newSize.length - 1) !== '0') {
      text += newSize.substring(newSize.length - 1) + ' มม. '
    }

    return text
  } else {
    return '-'
  }
}

export const formatWeightGramText = (size?: number): string => {
  if (!!size && size > 0) {
    let newSize = size.toString()
    let text = ''
    const length = newSize.length

    // kg
    if (length >= 4) {
      const subSize = newSize.substring(0, length - 3)
      text += subSize + ' กก. '
      newSize = newSize.substring(length - 3, length)
    }
    // g
    if (newSize.length <= 3 && newSize !== '000') {
      const subSize = newSize.substring(0, newSize.length)
      text += subSize + ' ก. '
    }

    return text
  } else {
    return '-'
  }
}

export const calculateProductUnitSizeId = (size: number): ProductUnitSize => {
  const length = size.toString().length

  if (length >= 4) {
    return ProductUnitSize.Meter
  } else if (length > 1 && length <= 3) {
    return ProductUnitSize.Centimeter
  } else {
    return ProductUnitSize.Millimeter
  }
}

export const calculateProductUnitWeightId = (weight: number): ProductUnitWeight => {
  const length = weight.toString().length

  if (length >= 4) {
    return ProductUnitWeight.KiloGram
  } else {
    return ProductUnitWeight.Gram
  }
}
