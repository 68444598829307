import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import numeral from 'numeral'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import { calculatePriceBreakdowns } from '../../../../sales-system/utils/sale-order-price-calculator'
import { SubTopic } from '../../../../custom-components'
import { currencies } from '../../../../types'

const QuotationsTotalBox: React.FC = () => {
  const { watch } = useFormContext<QuotationsCreateValue>()

  const { currencyId } = watch()

  const priceBreakdowns = calculatePriceBreakdowns(watch())

  return (
    <Box padding={2} display="flex" justifyContent="space-between" alignItems="center" clone>
      <Paper>
        <SubTopic variant="h5">ยอดรวมสุทธิ</SubTopic>

        <Box color="secondary.dark">
          <Typography variant="h6">{`${numeral(priceBreakdowns.total).format('0,0.00')} ${
            currencies.find((item) => item.id === currencyId)?.name || 'บาท'
          }`}</Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default QuotationsTotalBox
