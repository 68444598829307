import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { useContext } from 'react'
import { NestedValue, useFieldArray, useForm } from 'react-hook-form'
import { RootContext } from '..'
import { updateVendorAttachments, VendorUpdateAttachmentRequestBody } from '../api/smartbestbuys-api'
import { Form } from '../custom-components'
import { Space } from '.'
import { SourceFile } from '../types'
import { UploadCard } from '../pages/purchase-requisitions/create/AttachmentSelectionBox/UploadCard'
import FileCard from '../pages/purchase-requisitions/create/AttachmentSelectionBox/FileCard'

interface AttachmentSelectorFormValues {
  attachments: NestedValue<SourceFile[]>
}

// TODO: reuse ??
interface VendorAttachmentUpdateDialogProps extends DialogProps {
  vendorId: number
  defaultValues?: SourceFile[]
  // TODO: should return vendor profile
  onComplete?: () => void
  onFail?: () => void
}

const MAX = 5

const VendorAttachmentUpdateDialog = (props: VendorAttachmentUpdateDialogProps) => {
  // context
  const { triggerSnackbar } = useContext(RootContext)

  const { vendorId, defaultValues, onClose, onComplete, onFail, ...dialogProps } = props

  // hook form
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AttachmentSelectorFormValues>({
    defaultValues: {
      attachments: defaultValues,
    },
  })
  const { fields: attachments, append, remove } = useFieldArray({ control, name: 'attachments' })

  // handler
  const handleFormSubmit = async (values: AttachmentSelectorFormValues) => {
    const request: VendorUpdateAttachmentRequestBody = {
      attachments: values.attachments.map((att) => {
        return {
          id: att.id,
          base64: att.base64,
          imageUrl: att.url,
        }
      }),
    }

    // console.log(request)
    const response = await updateVendorAttachments(vendorId, request)
    if (response?.status === 200) {
      triggerSnackbar('จัดการไฟล์แนบผู้ขายสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('จัดการไฟล์แนบผู้ขายไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  // handler
  const handleFileChange = (file: SourceFile) => {
    append(file)
  }

  const handleRemoveFile = (index: number) => {
    remove(index)
  }

  return (
    <>
      <Dialog {...dialogProps} fullWidth maxWidth="lg">
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogTitle>จัดการไฟล์แนบ</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {(!attachments || attachments?.length < MAX) && (
                <Grid item xs={12} md={2}>
                  <UploadCard onChange={handleFileChange} />
                </Grid>
              )}
              {attachments?.map((file, index) => (
                <Grid item xs={12} md={2} key={file.name + index}>
                  <FileCard value={file} onRemove={() => handleRemoveFile(index)} />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
            >
              ยกเลิก
            </Button>
            <Space direction="horizontal" />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress /> : undefined}
            >
              บันทึกการจัดการไฟล์แนบ
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default VendorAttachmentUpdateDialog
