import { Box, Paper, Typography, Grid } from '@material-ui/core'
import { BaseData, PaymentRequisitionSelectedItem } from '../../../api/smartbestbuys-api'
import { TypographyWithLabel } from '../../../custom-components'
import { formatNumber } from '../../../functions/number-utils'

interface Props {
  selectedItems: PaymentRequisitionSelectedItem[]
  currency: BaseData
}

const PaymentRequisitionTotalBox: React.FC<Props> = (props: Props) => {
  // props
  const { selectedItems, currency } = props

  //calculation
  const paidTotal = selectedItems.reduce<number>((sum, payment) => {
    return sum + Number(payment.payTotal)
  }, 0)

  const withholdingTaxTotal = selectedItems.reduce<number>((sum, payment) => {
    return sum + (!!payment.payWithholdingTaxAmount ? payment.payWithholdingTaxAmount : 0)
  }, 0)

  return (
    <Paper>
      <Typography variant="h5">ยอดรวม</Typography>
      <div className="mb-4" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="ยอดทำเบิก:">
            {formatNumber(paidTotal)} {currency.name}
          </TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="หัก ณ ที่จ่ายทั้งหมด:">
            {formatNumber(withholdingTaxTotal)} {currency.name}
          </TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel label="ยอดทำเบิกสุทธิ:">
            <Box color="secondary.dark">
              {formatNumber(paidTotal - withholdingTaxTotal)} {currency.name}
            </Box>
          </TypographyWithLabel>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PaymentRequisitionTotalBox
