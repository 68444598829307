import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../..'
import { createVendorAddress, VendorCreateAddressRequestBody } from '../../../../api/smartbestbuys-api'
import { District, SubDistrict } from '../../../../api/smartbestbuys-api/data/data-types'
import { Form, GeneralTextFieldForm, Grid } from '../../../../custom-components'
import { useAddressData } from '../../../../hooks/data/useAddressData'
import { ButtonRadios, Space } from '../../../../shared-components'
import { VendorCreateAddressFormValues } from '../../create/VendorAddressForm'

// TODO: change on customer address form to use same inteface
enum AddressFormType {
  Local = 1,
  OtherCountry = 2,
}

interface VendorAddressFormDialogProps extends DialogProps {
  vendorId: number
  onComplete?: () => void
  onFail?: () => void
}

const VendorAddressFormDialog = (props: VendorAddressFormDialogProps) => {
  const { vendorId, onComplete, onFail, onClose, ...dialogProps } = props

  // context
  const { triggerSnackbar } = useContext(RootContext)

  // state
  const [formType, setFormType] = useState<number>(AddressFormType.Local)

  // form hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<VendorCreateAddressFormValues>()

  const { postalCode, provinceId, districtId, subDistrictId } = watch()

  const addressData = useAddressData()
  // eslint-disable-next-line
  const provinces = useMemo(() => addressData?.provinces ?? [], [(addressData?.provinces ?? []).length])
  // eslint-disable-next-line
  const districts = useMemo(() => addressData?.districts ?? [], [(addressData?.districts ?? []).length])
  // eslint-disable-next-line
  const subDistricts = useMemo(() => addressData?.subDistricts ?? [], [(addressData?.subDistricts ?? []).length])

  // memo
  const subDistrictOptions: SubDistrict[] = useMemo(() => {
    if (!postalCode) return [] as SubDistrict[]
    return subDistricts.filter((sd) => sd.postalCode.toString() === postalCode)
    // eslint-disable-next-line
  }, [postalCode])

  const selectedSubDistrict: SubDistrict | undefined = useMemo(() => {
    return subDistricts.find((sd) => sd.id === subDistrictId)
    // eslint-disable-next-line
  }, [subDistrictId])

  const districtOptions: District[] = useMemo(() => {
    if (!selectedSubDistrict?.districtId) return [] as District[]
    return districts.filter((d) => d.id === selectedSubDistrict.districtId)
    // eslint-disable-next-line
  }, [selectedSubDistrict?.districtId])

  const selectedDistrict: District | undefined = useMemo(() => {
    const district = districts.find((d) => d.id === selectedSubDistrict?.districtId)
    setValue('districtId', district?.id)
    return district
    // eslint-disable-next-line
  }, [selectedSubDistrict?.districtId, setValue])

  // effect
  useEffect(() => {
    const provice = provinces.find((v) => v.id === selectedDistrict?.provinceId)
    setValue('provinceId', provice?.id)
    // eslint-disable-next-line
  }, [selectedDistrict?.provinceId, setValue])

  // handlers
  const handleDialogSubmit = async (values: VendorCreateAddressFormValues) => {
    const request: VendorCreateAddressRequestBody =
      formType === AddressFormType.Local
        ? {
            addressLine1: values.addressLine1,
            provinceId: values.provinceId,
            districtId: values.districtId,
            subDistrictId: values.subDistrictId,
            phoneNumber: values.phoneNumber,
            faxNumber: values.faxNumber,
            postalCode: values.postalCode,
          }
        : {
            addressLine1: values.addressLine1,
            phoneNumber: values.phoneNumber,
            faxNumber: values.faxNumber,
            country: values.country,
          }

    const response = await createVendorAddress(vendorId, request)

    if (response?.status === 201) {
      triggerSnackbar('แก้ไขที่อยู่ผู้ขายสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('แก้ไขที่อยู่ผู้ขายไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} onClose={onClose} fullWidth maxWidth="md">
      <Form onSubmit={handleSubmit(handleDialogSubmit)}>
        <DialogTitle>แก้ไขข้อมูลที่อยู่</DialogTitle>
        <DialogContent>
          {formType === AddressFormType.OtherCountry ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ButtonRadios
                  label="ประเทศ *"
                  defaultValue={AddressFormType.Local}
                  value={formType}
                  onValueChange={(value) => setFormType(Number(value))}
                  items={[
                    { id: 1, value: AddressFormType.Local, name: 'ประเทศไทย' },
                    { id: 2, value: AddressFormType.OtherCountry, name: 'ต่างประเทศ' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  key="country"
                  control={control}
                  name="country"
                  label="ประเทศ *"
                  rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  key="addressLine1"
                  control={control}
                  name="addressLine1"
                  label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
                  rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  key="phoneNumber"
                  control={control}
                  name="phoneNumber"
                  label="เบอร์โทร *"
                  rules={{ required: 'กรุณากรอกเบอร์โทร' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm key="faxNumber" control={control} name="faxNumber" label="เบอร์แฟกซ์ (ถ้ามี)" />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ButtonRadios
                  label="ประเทศ *"
                  defaultValue={AddressFormType.Local}
                  value={formType}
                  onValueChange={(value) => setFormType(Number(value))}
                  items={[
                    { id: 1, value: AddressFormType.Local, name: 'ประเทศไทย' },
                    { id: 2, value: AddressFormType.OtherCountry, name: 'ต่างประเทศ' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm control={control} name="postalCode" label="รหัสไปรษณีย์" />
              </Grid>

              {provinceId && (
                <Grid item xs={12} md={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="provinceId"
                    label="จังหวัด"
                    value={provinceId}
                    disabled={true}
                    select
                  >
                    {provinces.map((v) => (
                      <MenuItem key={v.id} value={v.id}>
                        {v.nameLocal}
                      </MenuItem>
                    ))}
                  </GeneralTextFieldForm>
                </Grid>
              )}
              {districtId && (
                <Grid item xs={12} md={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="districtId"
                    label="เขต / อำเภอ"
                    value={districtId}
                    disabled={true}
                    select
                  >
                    {districtOptions.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.nameLocal}
                      </MenuItem>
                    ))}
                  </GeneralTextFieldForm>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="subDistrictId"
                  label="แขวง / ตำบล"
                  disabled={subDistrictOptions.length === 0}
                  select
                >
                  {subDistrictOptions.map((sd) => (
                    <MenuItem key={sd.id} value={sd.id}>
                      {sd.nameLocal} ({districts.find((d) => d.id === sd.districtId)?.nameLocal ?? '-'})
                    </MenuItem>
                  ))}
                </GeneralTextFieldForm>
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="addressLine1"
                  label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
                  rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="phoneNumber"
                  label="เบอร์โทร *"
                  rules={{ required: 'กรุณากรอกเบอร์โทร' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm control={control} name="faxNumber" label="เบอร์แฟกซ์ (ถ้ามี)" />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
          >
            ยกเลิก
          </Button>
          <Space direction="horizontal" />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            บันทึกข้อมูลที่อยู่
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default VendorAddressFormDialog
