import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../..'
import { createVendorContact, VendorCreateContactRequestBody } from '../../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm } from '../../../../custom-components'
import { Space } from '../../../../shared-components'

interface VendorUpdateFormValues {
  name: string
  phoneNumber: string
}

interface VendorContactCreateFormDialogProps extends DialogProps {
  vendorId: number
  onComplete?: () => void
  onFail?: () => void
}

const VendorContactCreateFormDialog = (props: VendorContactCreateFormDialogProps) => {
  // context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { vendorId, onClose, onComplete, onFail, ...dialogProps } = props

  // hook form
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<VendorUpdateFormValues>()

  // handler
  const handleFormSubmit = async (values: VendorUpdateFormValues) => {
    const request: VendorCreateContactRequestBody = {
      name: values.name,
      phoneNumber: values.phoneNumber,
    }

    const response = await createVendorContact(vendorId, request)
    if (response?.status === 201) {
      triggerSnackbar('เปลี่ยนแปลงผู้ติดต่อสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('เปลี่ยนแปลงผู้ติดต่อไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="sm">
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        {/* TODO: change to เพิ่มผู้ติดต่อ when support multiple contact */}
        <DialogTitle>เปลี่ยนผู้ติดต่อ</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="name"
                label="ชื่อ *"
                rules={{ required: 'กรุณากรอกชื่อ' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="phoneNumber"
                label="เบอร์โทรศัพท์ *"
                rules={{ required: 'กรุณากรอกเบอร์โทรศัพท์' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
          >
            ยกเลิก
          </Button>
          <Space direction="horizontal" />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            ยืนยันเปลี่ยนผู้ติดต่อ
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default VendorContactCreateFormDialog
