import { useState, useEffect } from 'react'
import { getSaleOrderDocumentsAPI } from '../../api/smartbestbuys-api/sale/sale-apis'
import { SaleOrderDocumentInfo } from '../../api/smartbestbuys-api/sale/sale-types'

export const useSaleOrderDocuments = (saleOrderId?: number): SaleOrderDocumentInfo[] => {
  const [data, setData] = useState<SaleOrderDocumentInfo[]>([])

  useEffect(() => {
    ;(async () => {
      if (saleOrderId) {
        const response = await getSaleOrderDocumentsAPI(saleOrderId)
        setData(response?.data ?? [])
      }
    })()
  }, [saleOrderId])

  return data
}
