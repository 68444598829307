import { AxiosResponse } from 'axios'

export const errorHandler = <T>(handler: any) => {
  return async (req?: any): Promise<AxiosResponse<T> | null> => {
    try {
      const response = await handler(req)
      // console.log(response)
      return response
    } catch (error: any) {
      console.log(error?.response?.data)
      return error?.response?.data
    }
  }
}

export const errorSingleHandler = <T>(handler: any) => {
  return async (id: number, req?: any): Promise<AxiosResponse<T> | null> => {
    try {
      const response = await handler(id, req)
      // console.log(response)
      return response
    } catch (error: any) {
      console.log(error)
      return error?.response
    }
  }
}

export const errorGenericHandle = <T, U>(handler: any) => {
  return async (urlParams: U, req?: any): Promise<AxiosResponse<T> | null> => {
    try {
      const response = await handler(urlParams, req)
      // console.log(response)
      return response
    } catch (error: any) {
      console.log(error)
      return error?.response
    }
  }
}
