import { Button } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { BaseData, PaymentRequisitionProfile, PaymentRequisitionProfileItem } from '../../../api/smartbestbuys-api'
import { VendorBankAccount } from '../../../api/smartbestbuys-api/vendor/vendor-types'
import { FlexBox } from '../../../custom-components'
import { PaymentRequisitionStatus } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip, Space } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import ConfirmStatementDialog from './ConfirmStatementDialog'
import UploadPurchasePayslipDialog from './UploadPurchasePayslipDialog'
import UploadReceiptDialog from './UploadReceiptDialog'

const blackListedStatus = [
  PaymentRequisitionStatus.PendingReview,
  PaymentRequisitionStatus.Rejected,
  PaymentRequisitionStatus.Canceled,
  PaymentRequisitionStatus.Success,
]

// ref from PaymentRequisitionProfile
interface PaymentRequisitionInfo {
  id: number
  status: BaseData
  paymentMethod: BaseData
  withdrawalAt?: Date
  vendorInfo: { bankAccount: VendorBankAccount }
  total: number
  withholdingTaxAmount?: number
  items: PaymentRequisitionProfileItem[]
}

interface Props {
  paymentRequisitionInfo: PaymentRequisitionInfo
  onUploadPaySlipSuccess?: (data: PaymentRequisitionProfile) => void
  onUploadStatementSuccess?: (data: PaymentRequisitionProfile) => void
  onUploadReceiptSuccess?: (data: PaymentRequisitionProfile) => void
}

const PaymentRequistionActionsBox = (props: Props) => {
  // props
  const { paymentRequisitionInfo, onUploadPaySlipSuccess, onUploadReceiptSuccess, onUploadStatementSuccess } = props

  // state
  const [uploadPaySlipOpen, setUploadPaySlipOpen] = useState(false)
  const [uploadReceiptOpen, setUploadReceiptOpen] = useState(false)
  const [uploadStatementOpen, setUploadStatementOpen] = useState(false)

  // prepare data
  const {
    status: { id: statusId },
    withdrawalAt,
    items,
  } = paymentRequisitionInfo

  const isSinglePurchaseOrder = useMemo(() => items.length === 1, [items.length])

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Write)

  // black listed then return empty
  if (blackListedStatus.includes(statusId)) return <></>

  if (statusId === PaymentRequisitionStatus.PendingTransfer || statusId === PaymentRequisitionStatus.PendingCheque) {
    return (
      <>
        <UploadPurchasePayslipDialog
          open={uploadPaySlipOpen}
          paymentRequisitionInfo={{
            id: paymentRequisitionInfo.id,
            paymentMethod: paymentRequisitionInfo.paymentMethod,
            vendorInfo: paymentRequisitionInfo.vendorInfo,
            total: paymentRequisitionInfo.total,
            withholdingTaxAmount: paymentRequisitionInfo.withholdingTaxAmount,
          }}
          onSuccess={(data) => {
            if (!!onUploadPaySlipSuccess) onUploadPaySlipSuccess(data)
            setUploadPaySlipOpen(false)
          }}
          onClose={() => setUploadPaySlipOpen(false)}
        />
        {statusId === PaymentRequisitionStatus.PendingTransfer ? (
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <Button variant="contained" color="primary" onClick={() => setUploadPaySlipOpen(true)}>
              ยืนยันการโอนเงิน
            </Button>
          </ConditionTooltip>
        ) : (
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <Button variant="contained" color="primary" onClick={() => setUploadPaySlipOpen(true)}>
              ยืนยันการออกเช็ค
            </Button>
          </ConditionTooltip>
        )}
      </>
    )
  }

  return (
    <>
      <UploadReceiptDialog
        open={uploadReceiptOpen}
        onClose={() => setUploadReceiptOpen(false)}
        paymentRequisitionId={paymentRequisitionInfo.id}
        isSinglePurchaseOrder={isSinglePurchaseOrder}
        onSuccess={(data) => {
          if (!!onUploadReceiptSuccess) onUploadReceiptSuccess(data)
          setUploadReceiptOpen(false)
        }}
      />
      <ConfirmStatementDialog
        open={uploadStatementOpen}
        onClose={() => setUploadStatementOpen(false)}
        paymentRequisitionInfo={{
          id: paymentRequisitionInfo.id,
          finalTotal: paymentRequisitionInfo.total,
        }}
        onSuccess={(data) => {
          if (!!onUploadStatementSuccess) onUploadStatementSuccess(data)
          setUploadStatementOpen(false)
        }}
      />
      <FlexBox>
        <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
          <Button
            disabled={!writePermission.hasPermission}
            variant="contained"
            color="primary"
            onClick={() => setUploadReceiptOpen(true)}
          >
            อัปโหลดเอกสารทางการเงินจากผู้ขาย
          </Button>
        </ConditionTooltip>
        <Space direction="horizontal" />
        {!withdrawalAt && (
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <Button
              disabled={!writePermission.hasPermission}
              variant="contained"
              color="primary"
              onClick={() => setUploadStatementOpen(true)}
            >
              ตรวจสอบ Statement
            </Button>
          </ConditionTooltip>
        )}
      </FlexBox>
    </>
  )
}

export default PaymentRequistionActionsBox
