import React from 'react'
import { TextField, InputAdornment, TextFieldProps } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { useStyles } from './styles'

const SearchTextField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles()
  return (
    <TextField
      className={classes.root}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      placeholder="ค้นหา.."
      size="small"
      onKeyPress={(e) => {
        if (e.key === 'Enter') e.preventDefault()
      }}
      {...props}
    />
  )
}

export default SearchTextField
