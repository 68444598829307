import React, { useContext, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, Grid, Typography } from '@material-ui/core'
import { Form, FlexBox, GeneralTextFieldForm } from '../../../../../../custom-components'
import { SubmitForm } from '../../../../../../shared-components'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../../../..'
import ProductProfileContext from '../../ProductProfileContext'
import { UpdateProductStockAmountRequestBody, updateProductStockAmount } from '../../../../../../api/smartbestbuys-api'

export interface EditStockDialogForm {
  stockAmount: number
  remark: string
}

const ProductEditStockModal: React.FC<DialogProps> = (props) => {
  const { onClose } = props
  const { triggerSnackbar } = useContext(RootContext)
  const { productProfile, reload } = useContext(ProductProfileContext)

  const methods = useForm<EditStockDialogForm>({
    defaultValues: {
      remark: '',
    },
  })

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
    register,
    trigger,
  } = methods

  const { stockAmount } = watch()

  useEffect(() => {
    register('stockAmount', { min: { value: 0, message: 'กรุณากรอกจำนวนที่ไม่ติดลบ' }, max: undefined })
    if (isSubmitted) trigger('stockAmount') // eslint-disable-next-line
  }, [stockAmount])

  const handleOnSubmit = async (values: UpdateProductStockAmountRequestBody) => {
    const response = await updateProductStockAmount(productProfile.id, values)
    if (response?.status === 200) {
      reload()
      onClose && onClose({}, 'escapeKeyDown')
      triggerSnackbar('อัปเดตสต๊อกสำเร็จ')
    } else {
      triggerSnackbar('อัปเดตสต๊อกไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth {...props}>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle>แก้ไขจำนวนสต็อกคงเหลือในคลัง</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FlexBox flexDirection="column" gridGap={16}>
                <Typography variant="body2">จำนวนสต๊อกในระบบ : {productProfile.stockAmount}</Typography>
                <Typography variant="body2">จำนวนสต๊อกที่ถูกต้อง</Typography>
                <FlexBox gridGap={16} alignItems="flex-start" style={{ height: '100%' }}>
                  <GeneralTextFieldForm
                    control={control}
                    type="number"
                    name="stockAmount"
                    label="จำนวน"
                    margin="none"
                    onPostChange={(stockAmount) => {
                      setValue('stockAmount', Math.floor(Number(stockAmount)))
                    }}
                  />
                </FlexBox>
                <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" margin="none" />
              </FlexBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText="บันทึก"
            onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
            cancelText="ยกเลิก"
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ProductEditStockModal
