/* eslint-disable react-hooks/exhaustive-deps */
import {
  GetCustomerTableDataSearchParams,
  ICustomerTableData_Data,
  getCustomerTableDataAPI,
} from '../../api/smartbestbuys-api'
import { useTableData } from '../useTablaData'

export interface CustomerTableDataSearchParam {
  name?: string
  phoneNumber?: string
  referenceNumber?: string
  isCredit?: string
  isBlackList?: string
  isDealer?: string
  tierList: number[]
  isActive?: string
  businessTypeId?: string
  saleUserId?: string
  userId?: number
}

const buildQueryParams = (search: CustomerTableDataSearchParam): GetCustomerTableDataSearchParams => {
  return {
    name: search.name,
    phoneNumber: search.phoneNumber,
    referenceNumber: search.referenceNumber,
    isCredit: search.isCredit === 'all' ? undefined : search?.isCredit,
    isBlacklist: search.isBlackList === 'all' ? undefined : search?.isBlackList,
    isDealer: search.isDealer === 'all' ? undefined : search?.isDealer,
    tierList: search.tierList.length > 0 ? search.tierList.join(',') : undefined,
    isActive: !search.isActive ? 'true' : search.isActive,
    businessTypeId: search.businessTypeId ? Number(search.businessTypeId) : undefined,
    saleUserId: search.saleUserId ? Number(search.saleUserId) : undefined,
  }
}

export const useCustomerTableData = (userId: number | undefined) =>
  useTableData<ICustomerTableData_Data, {}, CustomerTableDataSearchParam, GetCustomerTableDataSearchParams>(
    0,
    10,
    {
      tierList: [],
      isActive: 'true',
      userId,
    },
    getCustomerTableDataAPI,
    buildQueryParams,
  )
