import { BaseData, TransportTask } from '../api/smartbestbuys-api'
import { undefinedShippingCar, unknownShippingCar } from '../constants'
import { TaskStatus } from '../enums'

export const getShippingCar = (task: TransportTask): BaseData => {
  const shippingCar = task.state?.shippingInfo.messengerInfo?.car
  if (!shippingCar) {
    if (task.status.id === TaskStatus.TransportPending) return undefinedShippingCar
    return unknownShippingCar
  }
  return shippingCar
}
