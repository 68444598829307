import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@nivo/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      height: '100%',
      padding: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
  }),
)

export const nivoTheme: Theme = {
  axis: {
    legend: {
      text: {
        fontSize: 14,
        fontFamily: "'Kanit', sans-serif",
      },
    },
    ticks: {
      text: {
        fontSize: 12,
        fontFamily: "'Kanit', sans-serif",
      },
    },
  },
  legends: {
    text: {
      fontFamily: "'Kanit', sans-serif",
    },
  },
}
