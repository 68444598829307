import { DialogProps } from '@material-ui/core'

import { UserAction } from '../../../enums'
import {
  UserActivityDialog,
  UserActivityDialog_Option,
  UserActivityDialog_TextOption,
  UserActivityValues,
} from '../../../shared-components'
import { requestChangeQuotationAPI } from '../../../api/smartbestbuys-api'
import { useContext } from 'react'
import { RootContext } from '../../..'

interface Props extends DialogProps {
  quotationId: number
  quotationCode: string
  onSuccess?: () => void
}

const QuotationRequestChangeDialog: React.FC<Props> = (props: Props) => {
  // set up props & state
  const { quotationId, quotationCode, onSuccess, ...dialogProps } = props

  //context
  const { triggerSnackbar } = useContext(RootContext)

  const textOption: UserActivityDialog_TextOption = {
    title: 'แจ้งให้กลับไปแก้ไขใบเสนอราคา',
    description: `ยืนยันให้กลับไปแก้ไขใบเสนอราคา ${quotationCode} ใช่หรือไม่?`,
    submitText: 'ยืนยัน',
    cancelText: 'ยกเลิก',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    remarkRequired: true,
    allowNewMessage: false,
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const response = await requestChangeQuotationAPI(quotationId, values)
    if (response?.status === 200) {
      triggerSnackbar('ขอแก้ไขข้อมูลในเสนอราคาแล้ว')
      if (onSuccess) onSuccess()
    } else {
      triggerSnackbar('ระบบเกิดข้อผิดพลาด')
    }
    return true
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.RequestChangeQuotation}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
    />
  )
}

export default QuotationRequestChangeDialog
