import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { DateTime } from 'luxon'
import React from 'react'
import { TransportTask_RelatedPO } from '../../../api/smartbestbuys-api'
import { BaseData } from '../../../api/smartbestbuys-api/types'
import { FlexBox, TypographyWithLabel } from '../../../custom-components'
import { SubmitWithConfirmTextForm, TaskStatusChip, TypographyLink } from '../../../shared-components'
import { SubmitWithConfirmTextProps } from '../../../shared-components/SubmitForm/SubmitWithConfirmTextForm'

export interface ITransportSubTaskDialogData {
  id: number
  code: string
  status: BaseData
  type: BaseData
  saleOrderId?: number
  saleOrderCode?: string
  customerName?: string
  salesName?: string
  dueDate?: DateTime
  providerName?: string
  messenger?: string
  car?: string
  useShippingProdiver?: boolean
  remark?: string
  relatedPO?: TransportTask_RelatedPO[]
}

export interface ITransportSubTaskDialogOptions {
  submitFormProps: SubmitWithConfirmTextProps
}

interface Props {
  data: ITransportSubTaskDialogData | any
  options?: ITransportSubTaskDialogOptions
  submitting?: boolean
}

const TransportSubTaskDialog: React.FC<DialogProps & Props> = (props) => {
  const { data, options, children, onClose, submitting, ...dialogProps } = props
  const {
    type,
    status,
    saleOrderId,
    saleOrderCode,
    customerName,
    salesName,
    dueDate,
    providerName,
    messenger,
    car,
    useShippingProdiver,
    remark,
    relatedPO,
  } = data

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="md">
      <DialogTitle>
        <FlexBox alignItems="center" gridGap={8}>
          <Typography variant="h6">{data.code}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {type.name}
          </Typography>
          <TaskStatusChip status={status.id} label={status.name} size="small" />
        </FlexBox>

        {onClose && (
          <IconButton
            onClick={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {saleOrderCode && (
            <Grid item xs={6}>
              <TypographyWithLabel label="เลขที่ใบสั่งขาย:">
                <TypographyLink color="secondary.main" href={`/sale-orders/${saleOrderId}/profile`} target="_blank">
                  {saleOrderCode}
                </TypographyLink>
              </TypographyWithLabel>
            </Grid>
          )}
          {customerName && (
            <Grid item xs={6}>
              <TypographyWithLabel label="ชื่อลูกค้า:">{customerName}</TypographyWithLabel>
            </Grid>
          )}
          {dueDate && (
            <Grid item xs={6}>
              <TypographyWithLabel label="วันที่ส่งสินค้า:">
                {dueDate.setLocale('th').toFormat('dd MMM yyyy')}
              </TypographyWithLabel>
            </Grid>
          )}
          {salesName && (
            <Grid item xs={6}>
              <TypographyWithLabel label="ฝ่ายขายผู้สร้าง:">{salesName}</TypographyWithLabel>
            </Grid>
          )}
          <Grid item xs={6}>
            <TypographyWithLabel label="วิธีขนส่ง:">
              {useShippingProdiver ? 'ขนส่งอื่น' : 'SMART BEST BUYS'}
            </TypographyWithLabel>
          </Grid>
          {providerName && (
            <Grid item xs={6}>
              <TypographyWithLabel label="ขนส่งที่เลือกใช้:">{providerName}</TypographyWithLabel>
            </Grid>
          )}
          {messenger && (
            <Grid item xs={6}>
              <TypographyWithLabel label="ผู้ส่งของ:">{messenger}</TypographyWithLabel>
            </Grid>
          )}
          {car && (
            <Grid item xs={6}>
              <TypographyWithLabel label="ทะเบียนรถ:">{car}</TypographyWithLabel>
            </Grid>
          )}
          {remark && (
            <Grid item xs={6}>
              <TypographyWithLabel label="หมายเหตุ:">{remark}</TypographyWithLabel>
            </Grid>
          )}
          {!!relatedPO && relatedPO.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">เลขที่ใบสั่งซื้อที่เกี่ยวข้อง</Typography>
              </Grid>
              <Grid item xs={12}>
                {relatedPO.map((po: any, index: number, arr: any[]) => {
                  return (
                    <>
                      <TypographyLink variant="body2" href={`/purchase-orders/${po.id}/profile`} target="_blank">
                        {po.code}
                      </TypographyLink>
                      {index !== arr.length - 1 && (
                        <Typography variant="body2" color="textSecondary">
                          {', '}
                        </Typography>
                      )}
                    </>
                  )
                })}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SubmitWithConfirmTextForm
          {...options?.submitFormProps}
          onCancel={() => {
            if (!!onClose) onClose({}, 'escapeKeyDown')
          }}
          isSubmitting={submitting}
        />
      </DialogActions>
    </Dialog>
  )
}

export default TransportSubTaskDialog
