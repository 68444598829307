import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { SaleOrderStatus } from '../../enums'

interface Props {
  status: SaleOrderStatus
  icon?: boolean
}

const useStyles = (status: SaleOrderStatus) =>
  makeStyles((theme) => {
    const soStatusMap = {
      [SaleOrderStatus.Pending]: theme.palette.warning.light,
      [SaleOrderStatus.PendingPayment]: theme.palette.warning.light,
      [SaleOrderStatus.PendingDepositPayment]: theme.palette.warning.light,
      [SaleOrderStatus.PendingDelivery]: theme.palette.warning.light,
      [SaleOrderStatus.Delivering]: theme.palette.warning.light,
      [SaleOrderStatus.WaitingCustomerPickUp]: theme.palette.warning.light,
      [SaleOrderStatus.Closed]: theme.palette.success.light,
      [SaleOrderStatus.ClosedAtShop]: theme.palette.success.light,
      [SaleOrderStatus.Cancelled]: theme.palette.error.light,
    }

    const soStatusIconMap = {
      [SaleOrderStatus.Pending]: theme.palette.warning.darkText,
      [SaleOrderStatus.PendingPayment]: theme.palette.warning.darkText,
      [SaleOrderStatus.PendingDepositPayment]: theme.palette.warning.darkText,
      [SaleOrderStatus.PendingDelivery]: theme.palette.warning.darkText,
      [SaleOrderStatus.Delivering]: theme.palette.warning.darkText,
      [SaleOrderStatus.WaitingCustomerPickUp]: theme.palette.warning.darkText,
      [SaleOrderStatus.Closed]: theme.palette.success.darkText,
      [SaleOrderStatus.ClosedAtShop]: theme.palette.success.darkText,
      [SaleOrderStatus.Cancelled]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: soStatusMap[status],
      },
      icon: {
        color: soStatusIconMap[status],
      },
    })
  })

const SOStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ children, status, icon, ...othersProps }) => {
  const classes = useStyles(status)()
  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      icon={icon ? <Description /> : undefined}
      {...othersProps}
    />
  )
}

export default SOStatusChip
