import { Grid } from '@material-ui/core'
import { useContext } from 'react'
import { Topic } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'
import { TransportTaskUpdatePageContext } from './TransportTaskUpdatePage'
import TransportTaskSaleOrderProduct from './TransportTaskSaleOrderProduct'
import TransportTaskDocument from './TransportTaskDocument'
import TransportShippingMethodSelectForm from './TransportShippingMethodSelectForm'
import TransportTaskDetailForm from './TransportTaskDetailForm'
import TransportTaskAssigneeUser from './TransportTaskAssigneeUser'
import TransportTaskCustomerShippingAddress from './TransportTaskCustomerShippingInformationForm'
import { useFormContext } from 'react-hook-form'
import TransportTaskUpdateFormValues from './TransportTaskUpdateFormValues'

const TransportTaskUpdateForm: React.FC = () => {
  const { transportTaskUpdateData } = useContext(TransportTaskUpdatePageContext)

  const {
    formState: { isSubmitting },
  } = useFormContext<TransportTaskUpdateFormValues>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>แก้ไขงานขนส่งเลขที่ {transportTaskUpdateData.profile.code}</Topic>
      </Grid>
      <Grid item xs={12}>
        <TransportTaskSaleOrderProduct />
      </Grid>
      <Grid item xs={12}>
        <TransportTaskDocument />
      </Grid>
      <Grid item xs={12}>
        <TransportShippingMethodSelectForm />
      </Grid>
      <Grid item xs={12}>
        <TransportTaskDetailForm />
      </Grid>
      <Grid item xs={12}>
        <TransportTaskCustomerShippingAddress />
      </Grid>
      <Grid item xs={12}>
        <TransportTaskAssigneeUser />
      </Grid>
      <Grid item xs={12}>
        <SubmitForm goBack submitText="ยืนยันการแก้ไข" cancelText="ยกเลิก" isSubmitting={isSubmitting} />
      </Grid>
    </Grid>
  )
}

export default TransportTaskUpdateForm
