import { Card, Grid, Paper, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Avatar, FlexBox, Table, Title, Topic, TypographyWithLabel } from '../../../custom-components'
import { dateFormat, getAddressText } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { Space, SubmitForm } from '../../../shared-components'
import { ShippingPaymentCondition } from '../../../types'
import TransportTaskCreateFormValues, { TransportTaskCreateFormValues_Product } from './TransportTaskCreateFormValues'
import { TransportTaskCreatePageContext } from './TransportTaskCreatePage'

interface Props {
  onBack: () => void
}

const TransportVerificationForm = (props: Props) => {
  const { onBack } = props

  const { transportTaskCreateData } = useContext(TransportTaskCreatePageContext)
  const { users, customerShippingAddresses, saleOrderInfo, customerRequisitionInfo } = transportTaskCreateData

  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<TransportTaskCreateFormValues>()

  const values = watch()

  const assigneeUser = useMemo(() => {
    return users.find((u) => u.id === values.assigneeUserId)
    // eslint-disable-next-line
  }, [values.assigneeUserId])

  const allSelectedDocuments: { id: number; name: string; amount: number }[] = useMemo(() => {
    const documentItems = values.selectedDocuments
      .filter((d) => !!d.amount && d.amount > 0)
      .map((d) => ({
        id: d.id,
        name: `${d.type.name} ${d.code}`,
        amount: d.amount!,
      }))

    const otherDocumentItems = values.selectedOtherDocuments
      .filter((d) => d.selected && !!d.amount && d.amount > 0)
      .map((d) => ({
        id: d.id,
        name: d.name,
        amount: d.amount!,
      }))

    const requestDocuments = values.requestedDocuments
      .filter((d) => d.requestChange)
      .map((d) => ({
        id: -d.typeId,
        name: d.name,
        amount: 1,
      }))

    return [...documentItems, ...otherDocumentItems, ...requestDocuments]
    // eslint-disable-next-line
  }, [
    values.selectedDocuments.length,
    values.selectedOtherDocuments.length,
    // eslint-disable-next-line
    values.requestedDocuments.filter((d) => d.requestChange).length,
  ])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>
          ตรวจทานรายละเอียดงานขนส่งสำหรับ{' '}
          {!customerRequisitionInfo ? `ใบสั่งขาย ${saleOrderInfo?.code}` : `คำร้อง ${customerRequisitionInfo?.code}`}
        </Topic>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <FlexBox justifyContent="space-between">
            <Title>ผู้รับผิดชอบงาน</Title>
            <Typography variant="body1" color="textSecondary">
              {assigneeUser?.firstName} {assigneeUser?.lastName}
            </Typography>
          </FlexBox>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Title>รายละเอียดขนส่ง</Title>
          <Space />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TypographyWithLabel label="วิธีการขนส่ง:">{values.shippingMethod}</TypographyWithLabel>
            </Grid>
            {!!values.shippingProviderName && (
              <Grid item xs={6}>
                <TypographyWithLabel label="ขนส่งที่เลือก:">{values.shippingProviderName}</TypographyWithLabel>
              </Grid>
            )}
            {!!values.shippingProviderDetail && (
              <Grid item xs={6}>
                <TypographyWithLabel label="รายละเอียดเพิ่มเติม:">{values.shippingProviderDetail}</TypographyWithLabel>
              </Grid>
            )}
            {!!values.dueDate && (
              <Grid item xs={6}>
                <TypographyWithLabel label="วันที่ส่งสินค้า:">{dateFormat(values.dueDate)}</TypographyWithLabel>
              </Grid>
            )}

            <Grid item xs={6}>
              <TypographyWithLabel label="เลขทะเบียนรถ:">{values.car?.name ?? 'ไม่ระบุ'}</TypographyWithLabel>
            </Grid>

            <Grid item xs={6}>
              <TypographyWithLabel label="ผู้ส่งของ:">{values.messenger.name}</TypographyWithLabel>
            </Grid>
            {!!values.chargeAmount && (
              <Grid item xs={6}>
                <TypographyWithLabel label="ยอดเงินที่ต้องเรียกเก็บ:">
                  {formatNumberInt(values.chargeAmount)}
                </TypographyWithLabel>
              </Grid>
            )}
            {!!values.shippingPaymentCondition && (
              <Grid item xs={6}>
                <TypographyWithLabel label="รูปแบบการชำระค่าขนส่ง:">
                  {values.shippingPaymentCondition === ShippingPaymentCondition.Smart ? 'ต้นทาง' : 'ปลายทาง'}
                </TypographyWithLabel>
              </Grid>
            )}
            <Grid item xs={6}>
              <TypographyWithLabel label="หมายเหตุ:">{values.remark}</TypographyWithLabel>
            </Grid>
          </Grid>
          <Space />
          <Card variant="outlined">
            <Title>รายละเอียดที่อยู่จัดส่ง</Title>
            <Space />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TypographyWithLabel label="รายละเอียดที่อยู่:">
                  {getAddressText(
                    values.customerShippingAddress.id
                      ? customerShippingAddresses.find((address) => address.id === values.customerShippingAddress.id)
                      : values.customerShippingAddress,
                  )}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="เบอร์โทร:">{values.phoneNumber}</TypographyWithLabel>
              </Grid>
              {!!values.customerShippingAddress.googleMapLink && (
                <Grid item xs={6}>
                  <TypographyWithLabel label="ลิงก์ googlemap:">
                    {values.customerShippingAddress.googleMapLink}
                  </TypographyWithLabel>
                </Grid>
              )}
              <Grid item xs={6}>
                <TypographyWithLabel label="ชื่อผู้รับ:">{values.consignee}</TypographyWithLabel>
              </Grid>
            </Grid>
          </Card>
        </Paper>
      </Grid>
      {values.selectedProducts.length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <Title>ข้อมูลสินค้าสำหรับขนส่ง</Title>
            <Space />
            <Table<TransportTaskCreateFormValues_Product>
              color="primary.dark"
              columns={[
                { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70, align: 'center' },
                {
                  name: 'ชื่อสินค้า/บริการ',
                  render: ({ imageUrl, nameLocal, code }) => (
                    <FlexBox alignItems="flex-start">
                      <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                        <Photo />
                      </Avatar>
                      <FlexBox flexDirection="column" ml={1}>
                        <Typography variant="body2" color="textSecondary">
                          {code}
                        </Typography>
                        <Typography variant="body2">{nameLocal}</Typography>
                      </FlexBox>
                    </FlexBox>
                  ),
                },
                { name: 'จำนวนที่จะส่ง', align: 'right', render: ({ amount }) => formatNumberInt(amount!) },
              ]}
              data={values.selectedProducts.filter((product) => !!product.amount && product.amount > 0)}
              cleanSpace
              size="small"
              pagination={false}
            />
          </Paper>
        </Grid>
      )}
      {allSelectedDocuments.length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <Title>รายการเอกสารสำหรับขนส่ง</Title>
            <Space />
            <Table<{ id: number; name: string; amount: number }>
              color="secondary.dark"
              columns={[
                { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70, align: 'center' },
                { name: 'ชื่อเอกสาร', dataIndex: 'name' },
                // { name: 'จำนวนที่เคยส่งแล้ว', render: (_, index) => index + 1 },
                { name: 'จำนวนที่จะส่ง', align: 'right', render: ({ amount }) => `${amount} ฉบับ` },
              ]}
              data={allSelectedDocuments}
              cleanSpace
              size="small"
              pagination={false}
            />
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <SubmitForm submitText="สร้างงานขนส่ง" cancelText="ยกเลิก" onCancel={onBack} isSubmitting={isSubmitting} />
      </Grid>
    </Grid>
  )
}

export default TransportVerificationForm
