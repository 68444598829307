import { makeStyles, createStyles } from '@material-ui/core/styles'

const drawerWidth = 240

export const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      background: theme.palette.background.default,
      minHeight: 'calc(100vh - 64px)',
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    contentShift: {
      [theme.breakpoints.up('sm')]: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      },
    },
    appName: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    versionCaption: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        fontSize: 12,
        marginLeft: theme.spacing(0),
      },
    },
    nestedItem: {
      paddingLeft: theme.spacing(4),
    },
    container: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    myProfile: {
      margin: theme.spacing(1),
      minWidth: 'auto',
    },
    badge: {
      marginTop: -2,
    },
  }),
)
