import { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../..'
import { DocumentStatus, PageName, UserAction } from '../../../enums'
import { useCreditNoteTableData } from '../../../hooks/useCreditNoteTableData'
import {
  CreditNoteCSVDownloader,
  DocumentCancellationDialog,
  DocumentStatusChip,
  Loading,
} from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { Box, Button, Chip, CircularProgress, Grid, IconButton, TextField } from '@material-ui/core'
import { FlexBox, GeneralTextField, Link, Table, Topic, withLayout } from '../../../custom-components'
import { Autocomplete } from '@material-ui/lab'
import SaleOrderProfileContext from '../../sale-orders/id/SaleOrderProfilePage/SaleOrderProfileContext'
import { calculateExcludingVat, dateTimeFormat } from '../../../functions'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import { formatNumber } from '../../../functions/number-utils'
import { Cancel } from '@material-ui/icons'
import { IDocumentTableData_Data } from '../../../api/smartbestbuys-api'
import { documentStatuses } from '../../../constants'
import { useActionMessages } from '../../../hooks'

const CreditNoteTableDataPage: React.FC = (props) => {
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  setCurrentPage(PageName.CREDIT_NOTE)

  const { reload } = useContext(SaleOrderProfileContext)

  // state
  const [idToCancelId, setIdToCancel] = useState<number | undefined>(undefined)
  const [creditNotes, setCreditNotes] = useState<IDocumentTableData_Data[]>([])

  const tableData = useCreditNoteTableData({
    pageIndex: 0,
    rowPerPage: 10,
    search: { statusIds: [] },
  })
  const messages = useActionMessages(UserAction.CancelDocument)

  useEffect(() => {
    if (tableData?.data) setCreditNotes(tableData.data)
  }, [tableData?.data])

  if (!tableData) return <Loading />
  const {
    data,
    paginationInfo,
    isLoading,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
  } = tableData

  const cancelPermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Cancel)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <Topic>รายการใบลดหนี้</Topic>
            <CreditNoteCSVDownloader />
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="documentCode"
                  onChange={(e) => handleSearchParamChange('documentCode', e.target.value)}
                  label="เลขใบลดหนี้"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="refTaxInvoiceCode"
                  onChange={(e) => handleSearchParamChange('refTaxInvoiceCode', e.target.value)}
                  label="เลขใบกำกับภาษีอ้างอิง"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="customerName"
                  onChange={(e) => handleSearchParamChange('customerName', e.target.value)}
                  label="ชื่อลูกค้า"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  size="small"
                  options={documentStatuses.map((option) => option.name)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((label: string, index: number) => (
                      <Chip variant="outlined" label={label} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange(
                      'statusIds',
                      documentStatuses.filter((opt) => newValue.includes(opt.name)).map((opt) => opt.id),
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="สถานะใบลดหนี้"
                      placeholder="สถานะใบลดหนี้"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="button"
                  type="submit"
                  disabled={isLoading}
                  startIcon={isLoading ? <CircularProgress size={8} /> : undefined}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<IDocumentTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: 'สถานะใบลดหนี้',
                render: ({ status: { id, name } }) => <DocumentStatusChip status={id} label={name} />,
                csvRender: ({ status: { name } }) => name,
              },
              {
                name: 'เลขใบลดหนี้',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/credit-notes/${id}/pdf`} target="_blank">
                    {code}
                  </Link>
                ),
                csvRender: ({ code }) => code,
              },
              {
                name: 'เลขใบกำกับภาษีอ้างอิง',
                render: (creditNote) => {
                  const { state } = creditNote

                  return state?.taxInvoiceReferences ? state?.taxInvoiceReferences?.join(',') : '-'
                },
                csvRender: (creditNote) => {
                  const { state } = creditNote

                  return state?.taxInvoiceReferences ? state?.taxInvoiceReferences?.join(',') : '-'
                },
              },
              {
                name: 'ชื่อลูกค้า',
                render: ({ customer, billingAddress }) =>
                  billingAddress?.overrideDocumentData?.customerName || customer?.nameLocal || '-',
                csvRender: ({ customer, billingAddress }) =>
                  billingAddress?.overrideDocumentData?.customerName || customer?.nameLocal || '-',
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                csvRender: ({ recCreatedAt }) =>
                  format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
              },
              {
                name: 'ยอดก่อน VAT',
                render: (creditNote) =>
                  creditNote.total ? calculateExcludingVat(creditNote.total) : 'ไม่สามารถคำนวณได้',
                numeric: true,
                format: '0,0.00',
                csvRender: (taxInvoice) =>
                  taxInvoice.total ? formatNumber(calculateExcludingVat(taxInvoice.total)) : '--',
              },
              {
                name: 'VAT',
                render: (creditNote) => {
                  const { total } = creditNote
                  const vatFreeAmount = calculateExcludingVat(total || 0)
                  return (total || 0) - vatFreeAmount
                },
                numeric: true,
                format: '0,0.00',
                csvRender: ({ total }) => {
                  const vatFreeAmount = calculateExcludingVat(total ?? 0)
                  return formatNumber((total ?? 0) - vatFreeAmount)
                },
              },
              {
                name: 'ยอดเงินสุทธิ',
                dataIndex: 'total',
                numeric: true,
                format: '0,0.00',
                csvRender: ({ total }) => formatNumber(total ?? 0),
              },
              {
                name: '',
                csvRender: () => {},
                render: (creditNote) => {
                  // TODO: support issued credit note and credit note dialog
                  const isCancelled = creditNote.status.id === DocumentStatus.Cancelled
                  if (isCancelled) {
                    return <></>
                  }

                  return (
                    <>
                      <DocumentCancellationDialog
                        messages={messages}
                        documentInfo={creditNote}
                        open={idToCancelId === creditNote.id}
                        onClose={() => {
                          setIdToCancel(undefined)
                        }}
                        onSuccess={(updatedData) => {
                          triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
                          reload()
                          let newData = data
                          const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                          if (findIndex !== -1) {
                            const found = newData[findIndex]
                            const updated = {
                              ...found,
                              ...updatedData,
                            }
                            newData[findIndex] = updated
                            setCreditNotes([...newData])
                          }
                        }}
                        onFail={() => {
                          triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
                        }}
                      />
                      {cancelPermission.hasPermission && (
                        <FlexBox gridGap={8}>
                          <IconButton size="small" onClick={() => setIdToCancel(creditNote.id)}>
                            <Cancel fontSize="small" />
                          </IconButton>
                        </FlexBox>
                      )}
                    </>
                  )
                },
              },
            ]}
            data={creditNotes}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(CreditNoteTableDataPage)
