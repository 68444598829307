import { useContext, useEffect, useState } from 'react'
import { Paper, Box, Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { EmptyCard, GeneralTextFieldForm, Radio, SubTopic } from '../../../custom-components'
import { CustomerAddressForm, Space } from '../../../shared-components'
import { useFormContext } from 'react-hook-form'
import TransportTaskCreateFormValues from './TransportTaskCreateFormValues'
import { TransportTaskCreatePageContext } from './TransportTaskCreatePage'
import { ICustomerProfile_Address } from '../../../api/smartbestbuys-api/customer/types'
import { getAddressText } from '../../../functions/get-address-text'

export const TransportTaskCustomerShippingInformationForm: React.FC = () => {
  // context
  const { transportTaskCreateData } = useContext(TransportTaskCreatePageContext)
  const { customerShippingAddresses, customerBillingAddresses } = transportTaskCreateData

  // form hook
  const {
    control,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext<TransportTaskCreateFormValues>()
  const { customerShippingAddress, sendCustomerShippingAddressById } = watch()

  // state
  const [tabValue, setTabValue] = useState(0)

  // effect
  useEffect(() => {
    if (sendCustomerShippingAddressById) {
      register('customerShippingAddress.id', { required: 'กรุณาเลือกที่อยู่ของลูกค้า' })
    } else {
      unregister('customerShippingAddress.id')
    }
  }, [sendCustomerShippingAddressById, clearErrors, register, unregister])

  return (
    <Box p={3} clone>
      <Paper>
        <SubTopic>ข้อมูลที่อยู่สำหรับขนส่ง</SubTopic>
        <Space />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="consignee"
              label="ชื่อ/ตำแหน่งผู้รับ *"
              rules={{ required: 'กรุณาชื่อ/ตำแหน่งผู้รับ' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="phoneNumber"
              label="เบอร์โทรผู้รับ *"
              rules={{ required: 'กรุณาเบอร์โทรผู้รับ' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">เลือกที่อยู่ขนส่ง</Typography>
            <Space />
            <Tabs
              style={{
                marginBottom: 4,
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
              }}
              variant="fullWidth"
              textColor="primary"
              value={tabValue}
              onChange={(e, value) => {
                setValue('sendCustomerShippingAddressById', value === 0)
                setTabValue(value)
              }}
              indicatorColor="primary"
              aria-label="tabs table"
            >
              <Tab label="ที่อยู่ขนส่งที่มีในระบบ" value={0} />
              <Tab label="ที่อยู่ขนส่งใหม่" value={1} />
            </Tabs>
            {
              [
                <ShippingAddressRadios
                  customerAddresses={customerShippingAddresses?.sort((a, b) => b.id - a.id) ?? []}
                  value={customerShippingAddress?.id}
                  onChange={(addressId) => setValue('customerShippingAddress.id', addressId)}
                  errorMessage={errors.customerShippingAddress?.id?.message}
                />,
                <CustomerAddressForm name="customerShippingAddress" refCustomerAddresses={customerBillingAddresses} />,
              ][tabValue]
            }
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default TransportTaskCustomerShippingInformationForm

interface Props {
  customerAddresses: ICustomerProfile_Address[]
  value?: number
  onChange?: (value: number) => void
  errorMessage?: string
}

const ShippingAddressRadios = (props: Props) => {
  const { customerAddresses, value, onChange, errorMessage } = props
  return (
    <Box p={2} borderColor={errorMessage ? 'error.main' : undefined} clone>
      <Paper variant="outlined">
        <Grid container spacing={2}>
          {customerAddresses.length > 0 ? (
            customerAddresses.map((address) => (
              <Grid item xs={12} key={getAddressText(address)}>
                <Radio
                  label={getAddressText(address)}
                  value={address.id}
                  checked={value === address.id}
                  outlined
                  onChange={(e) => {
                    if (!!onChange) onChange(Number(e.target.value))
                  }}
                  color="primary"
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} key="empty card">
              <EmptyCard>ลูกค้ายังไม่มีที่อยู่จัดส่ง</EmptyCard>
            </Grid>
          )}
        </Grid>
        <Space />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Paper>
    </Box>
  )
}
