import { useEffect, useState } from 'react'
import { CustomerRequisitionProfile, getCustomerRequisitionProfileAPI } from '../api/smartbestbuys-api'

export const useCustomerRequisitionProfile = (id: number): [CustomerRequisitionProfile | undefined, () => void] => {
  const [profile, setProfile] = useState<CustomerRequisitionProfile | undefined>(undefined)
  const [reload, setReload] = useState(false)

  const forceReload = () => {
    setReload((prev) => !prev)
  }

  useEffect(() => {
    ;(async () => {
      const response = await getCustomerRequisitionProfileAPI(id)
      const data = response?.data

      setProfile(data)
    })()
  }, [id, reload])

  return [profile, forceReload]
}
