import smartAuthAPI from '../smartbestbuys-api'
import { errorSingleHandler } from '../../middleware'
import { UserActivityInfoRequest } from '../user-activity'
import { BaseData } from '..'

export const cancelDocument = errorSingleHandler<UpdatedDocumentStatus>(
  async (documentId: number, request: UserActivityInfoRequest) => {
    const path = `/documents/${documentId}/cancel`
    return await smartAuthAPI.patch(path, request)
  },
)

export interface UpdatedDocumentStatus {
  id: number
  status: BaseData
  cancelledMessageName?: string
  cancelledAt?: Date
}
