import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Paper, Typography } from '@material-ui/core'
import { FlexBox, GeneralTextFieldForm, SubTopic, Table } from '../../../custom-components'
import { CheckCircle, Photo } from '@material-ui/icons'
import { TransportTaskUpdateData_SaleOrderProduct } from '../../../api/smartbestbuys-api'
import { useFormContext } from 'react-hook-form'
import TransportTaskUpdateFormValues from './TransportTaskUpdateFormValues'
import './TransportTaskSaleOrderProduct.less'
import { TransportTaskUpdatePageContext } from './TransportTaskUpdatePage'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'
import { TaskStatus } from '../../../enums'

interface ProductWithTaskReservedAmount {
  id: number
  taskReservedAmount: number
}

const TransportTaskSaleOrderProduct: React.FC = (props) => {
  //context
  const { transportTaskUpdateData } = useContext(TransportTaskUpdatePageContext)

  // state
  const [selected, setSelected] = useState<number[]>([])

  // react hook form
  const {
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<TransportTaskUpdateFormValues>()

  const { selectedProducts } = watch()

  const productsWithReservedAmount: ProductWithTaskReservedAmount[] = useMemo(() => {
    return selectedProducts.map((product) => {
      return {
        id: product.id,
        taskReservedAmount: transportTaskUpdateData.relatedTasks
          .filter((t) => [TaskStatus.TransportPending, TaskStatus.Processing].includes(t.status.id))
          .filter((t) => t.id !== transportTaskUpdateData.profile.id)
          .reduce<number>((reservedAmount, task) => {
            const taskProduct = task.state?.items?.find(
              (item: any) => item.type === TransportTaskItemType.Product && item.id === product.id,
            )
            return reservedAmount + (taskProduct?.amount ?? 0)
          }, 0),
      }
    })
    // eslint-disable-next-line
  }, [selectedProducts.length])

  useEffect(() => {
    setSelected(selectedProducts.filter((p) => !!p.amount && p.amount > 0).map((p) => p.id))
    // eslint-disable-next-line
  }, [selectedProducts.length])

  // render
  return (
    <FlexBox
      p={3}
      justifyContent="space-between"
      flexDirection="column"
      border={errors.selectedProducts ? 1 : undefined}
      borderColor={errors.selectedProducts ? 'error.main' : undefined}
      clone
    >
      <Paper id="shipping-product-selector-box">
        <SubTopic>รายการสินค้า / บริการสำหรับขนส่ง</SubTopic>
        <div className="mb-4" />
        <Table<TransportTaskUpdateData_SaleOrderProduct & { taskReservedAmount: number }>
          color="primary.dark"
          size="small"
          columns={[
            {
              name: 'ชื่อสินค้า/บริการ',
              render: (product) => (
                <div className="transport-task-products-table__product-cell" key={'product-' + product.id}>
                  <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                    <Photo />
                  </Avatar>
                  <div className="transport-task-products-table__product-cell__info">
                    <Typography variant="body2" color="textSecondary">
                      {product.code}
                    </Typography>
                    <Typography variant="body2">{product.nameLocal}</Typography>
                  </div>
                </div>
              ),
            },
            {
              name: 'จำนวนที่ส่งแล้ว',
              numeric: true,
              format: '0,0',
              render: (product) => product.shippedAmount,
            },
            {
              name: 'จำนวนที่กำลังจัดส่ง',
              numeric: true,
              format: '0,0',
              render: (product) => product.taskReservedAmount,
            },
            {
              name: 'จำนวนที่ขาดส่ง',
              numeric: true,
              format: '0,0',
              render: (product) => {
                const reservedAndShippedAmount = product.shippedAmount + product.taskReservedAmount
                if (reservedAndShippedAmount >= product.salesAmount) return '-'
                return product.salesAmount - product.shippedAmount - product.taskReservedAmount
              },
            },
            {
              name: 'จำนวนที่จะส่ง',
              fixedWidth: 200,
              render: (product, index) => {
                const reservedAndShippedAmount = product.shippedAmount + product.taskReservedAmount
                if (reservedAndShippedAmount >= product.salesAmount)
                  return (
                    <Box color="success.main" clone>
                      <CheckCircle color="disabled" />
                    </Box>
                  )

                if (!selected.includes(product.id))
                  return (
                    <Typography color="textSecondary" variant="body2">
                      ไม่ได้เลือก
                    </Typography>
                  )

                return (
                  <GeneralTextFieldForm
                    key={`selectedProducts.${index}.amount`}
                    margin="none"
                    control={control}
                    name={`selectedProducts.${index}.amount`}
                    rules={{
                      required: selected?.includes(product.id) ? 'กรุณากรอกจำนวน' : undefined,
                      max: {
                        value: product.salesAmount - reservedAndShippedAmount,
                        message: 'จำนวนส่งเกินจำนวนขาด',
                      },
                    }}
                    onPostChange={() => {
                      if (!!errors.selectedProducts) {
                        clearErrors('selectedProducts')
                      }
                    }}
                    type="number"
                    inputProps={{ onWheel: (event) => event.currentTarget.blur() }}
                    placeholder="กรอกจำนวนที่จะส่ง"
                  />
                )
              },
            },
          ]}
          data={selectedProducts.map((p) => {
            return {
              ...p,
              taskReservedAmount: productsWithReservedAmount.find((pr) => pr.id === p.id)?.taskReservedAmount ?? 0,
            }
          })}
          pagination={false}
          selected={selectedProducts.filter((p) => !!p.amount && p.amount > 0).map((p) => p.id)}
          selectable
          onChange={setSelected}
          onSelect={() => {
            if (!!errors.selectedProducts) {
              clearErrors('selectedProducts')
            }
          }}
          onDeSelect={(id) => {
            const index = selectedProducts.findIndex((p) => p.id === id)
            setValue(`selectedProducts.${index}.amount`, undefined)
          }}
          hideSelectAll
          hideCheckbox={(product) => product.shippedAmount + product.taskReservedAmount >= product.salesAmount}
          inActive={(product) => product.shippedAmount + product.taskReservedAmount >= product.salesAmount}
          cleanSpace
        />
        {errors.selectedProducts && (
          <Typography color="error" variant="caption">
            {errors.selectedProducts.message}
          </Typography>
        )}
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskSaleOrderProduct
