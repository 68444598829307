import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler, errorSingleHandler } from '../../middleware'
import {
  CreateUserRequestBody,
  User,
  UserCreateData,
  PermissionData,
  UpdateUserCriteriaRequestBody,
  UpdateUserInfoRequestBody,
  UpdateUserStatusRequestBody,
  UserPermissionUpdateRequestBody,
  UserProfile,
  User_TableData,
  UpdateMyUserCriteriaRequestBody,
  UpdatedMyUserCriteria,
} from '.'

export const getUserCreateDataAPI = errorHandler<UserCreateData>(async () => {
  return smartAuthAPI.get(`users/v1/create-data`)
})

export const createUserAPI = errorHandler<User>((request: CreateUserRequestBody) => {
  return smartAuthAPI.post('users/v1/create', request)
})

export const getUsersAPI = errorHandler<User_TableData[]>(() => {
  return smartAuthAPI.get(`/users/v1/fetch`)
})

export const getUserProfileByIdAPI = errorHandler<UserProfile>((id: number) => {
  return smartAuthAPI.get(`/users/v1/${id}/profile`)
})

export const updateUserActivationByIdAPI = errorSingleHandler<UserProfile>(
  async (id: number, requestBody: UpdateUserStatusRequestBody) => {
    return smartAuthAPI.put(`/users/v1/${id}/update-status`, requestBody)
  },
)

export const updateUserCriteriaByIdAPI = errorSingleHandler<UserProfile>(
  async (id: number, requestBody: UpdateUserCriteriaRequestBody) => {
    return smartAuthAPI.put(`/users/v1/${id}/update-criteria`, requestBody)
  },
)

export const updateUserInformationByIdAPI = errorSingleHandler<UserProfile>(
  async (id: number, requestBody: UpdateUserInfoRequestBody) => {
    return smartAuthAPI.put(`/users/v1/${id}/update-info`, requestBody)
  },
)

export const updateUserPermissionByIdAPI = errorSingleHandler<UserProfile>(
  async (id: number, requestBody: UserPermissionUpdateRequestBody) => {
    return smartAuthAPI.put(`/users/v1/${id}/update-permission`, requestBody)
  },
)

export const getPermissionDataAPI = errorHandler<PermissionData>(async () => {
  return smartAuthAPI.get('/data/v1/permission-data')
})

export const getMyUserProfileAPI = errorHandler<UserProfile>(async () => {
  const response = await smartAuthAPI.get('users/v1/me/profile')
  return response
})

export const updateMyUserInfoAPI = errorHandler<UserProfile>(async (requestBody: UpdateUserInfoRequestBody) => {
  return smartAuthAPI.put('users/v1/me/update-info', requestBody)
})

export const updateMyUserCriteriaAPI = errorHandler<UpdatedMyUserCriteria>(
  async (requestBody: UpdateMyUserCriteriaRequestBody) => {
    return smartAuthAPI.patch('users/v1/me/update-criteria', requestBody)
  },
)
