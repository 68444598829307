import { Button, Grid, Paper, Tab, Tabs } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { RootContext } from '../../../../../..'
import { BaseData, ICustomerProfile_Document, ICustomerDocumentState } from '../../../../../../api/smartbestbuys-api'
import { FlexBox, SubTopic, Table } from '../../../../../../custom-components'
import { DocumentType } from '../../../../../../enums'
import { ConditionTooltip, DocumentCancellationDialog } from '../../../../../../shared-components'
import CustomerProfileContext from '../../CustomerProfileContext'
import { BillingDocumentCreationDialog } from './BillingDocumentCreationDialog'
import { generateDocumentColumns } from './generateDocumentColumns'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'

interface SelectedDocument {
  id: number
  code: string
  type: BaseData
}

const CustomerDocumentBox: React.FC = () => {
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.TaxInvoice)

  const [createBillingNote, setCreateBillingNote] = useState(false)
  const [createReceipts, setCreateReceipts] = useState(false)

  const [selectedDocument, setSelectedDocument] = useState<SelectedDocument>()
  const [cancelDocument, setCancelDocument] = useState<boolean>(false)

  const { triggerSnackbar } = useContext(RootContext)
  const { reload, customerProfile } = useContext(CustomerProfileContext)

  const { documents, recDeletedAt } = customerProfile

  const handleOnCancelBillingDocument = (data: ICustomerProfile_Document<any>) => {
    const document = {
      id: data.id,
      code: data.code,
      type: data.type,
    }

    setSelectedDocument(document)
    setCancelDocument(true)
  }

  const isActiveCustomer = !recDeletedAt
  const writePermission = getUserPermissionInfo(PermissionEnum.Finance_Document_Write)
  const disableWrite = !writePermission.hasPermission || !isActiveCustomer
  return (
    <>
      <BillingDocumentCreationDialog
        open={createBillingNote}
        onClose={() => {
          setCreateBillingNote(false)
        }}
        onSuccess={() => {
          triggerSnackbar('สร้างใบวางบิลสำเร็จ')
          reload()
        }}
        onFail={() => {
          triggerSnackbar('สร้างใบวางบิลไม่สำเร็จ')
        }}
        customerProfile={customerProfile}
        documentTypeCretion={DocumentType.BillingNote}
      />

      <BillingDocumentCreationDialog
        open={createReceipts}
        onClose={() => {
          setCreateReceipts(false)
        }}
        onSuccess={() => {
          triggerSnackbar('สร้างใบเสร็จรับเงินสำเร็จ')
          reload()
        }}
        onFail={() => {
          triggerSnackbar('สร้างใบเสร็จรับเงินไม่สำเร็จ')
        }}
        customerProfile={customerProfile}
        documentTypeCretion={DocumentType.Receipts}
      />

      {selectedDocument && (
        <DocumentCancellationDialog
          documentInfo={selectedDocument}
          open={cancelDocument}
          onClose={() => setCancelDocument(false)}
          onSuccess={() => {
            triggerSnackbar('ยกเลิกเอกสารสำเร็จ')
            reload()
          }}
          onFail={() => {
            triggerSnackbar('ยกเลิกเอกสารไม่สำเร็จ')
          }}
        />
      )}

      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SubTopic>เอกสารของลูกค้า</SubTopic>
          </Grid>
          <Grid item xs={6}>
            <FlexBox gridGap={8} justifyContent="flex-end">
              <ConditionTooltip
                title={
                  !writePermission.hasPermission ? writePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                }
                showTooltip={disableWrite}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Description />}
                  onClick={() => setCreateBillingNote(true)}
                  disabled={disableWrite}
                >
                  สร้างใบวางบิล
                </Button>
              </ConditionTooltip>
              <ConditionTooltip
                title={
                  !writePermission.hasPermission ? writePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                }
                showTooltip={disableWrite}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Description />}
                  onClick={() => setCreateReceipts(true)}
                  disabled={disableWrite}
                >
                  สร้างใบเสร็จรับเงิน
                </Button>
              </ConditionTooltip>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <Paper square style={{ padding: 0 }}>
              <Tabs
                value={documentType}
                indicatorColor="primary"
                textColor="primary"
                onChange={(_, value) => setDocumentType(value)}
                aria-label="disabled tabs example"
                centered
                variant="fullWidth"
              >
                <Tab label="ใบกำกับภาษี" value={DocumentType.TaxInvoice} />
                <Tab label="ใบเสร็จรับเงิน" value={DocumentType.Receipts} />
                <Tab label="ใบแจ้งหนี้" value={DocumentType.Invoice} />
                <Tab label="ใบวางบิล" value={DocumentType.BillingNote} />
                <Tab label="ใบลดหนี้" value={DocumentType.CreditNote} />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Table<ICustomerProfile_Document<ICustomerDocumentState>>
              size="small"
              color="primary.dark"
              columns={generateDocumentColumns(documentType, handleOnCancelBillingDocument)}
              data={
                documents
                  ?.filter((d) => d.type.id === documentType)
                  ?.sort((a, b) => new Date(b.recCreatedAt).getTime() - new Date(a.recCreatedAt).getTime()) ?? []
              }
              cleanSpace
              pagination
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerDocumentBox
