import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { getTransportShippingTaskAPI, TransportShipingTaskMobileData } from '../../api/smartbestbuys-api'

export const useTransportShippingTask = (
  taskId: number,
): [
  TransportShipingTaskMobileData | undefined,
  Dispatch<SetStateAction<TransportShipingTaskMobileData | undefined>>,
] => {
  const [data, setData] = useState<TransportShipingTaskMobileData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getTransportShippingTaskAPI(taskId)
      setData(response?.data)
    })()
  }, [taskId])

  return [data, setData]
}
