import { useEffect, useState } from 'react'
import {
  getInvoiceTableDataAPI,
  IInvoiceTableData,
  IDocumentSearchParams,
  GetDocumentTableDataQueryParams,
  IDocumentTableData_Data,
} from '../api/smartbestbuys-api'
import { IUseTableData, IUseTableDataParams } from '../types/pagination'

const buildQueryParams = (
  pageIndex: number,
  rowPerPage: number,
  search: IDocumentSearchParams,
): GetDocumentTableDataQueryParams => {
  return {
    pageIndex: pageIndex.toString(),
    rowPerPage: rowPerPage.toString(),
    documentCode: search?.documentCode,
    saleOrderCode: search?.saleOrderCode,
    soPaymentCode: search?.soPaymentCode,
    customerName: search?.customerName,
    statusIds: search.statusIds.length > 0 ? search?.statusIds?.join(',') : undefined,
  }
}

export const useInvoiceTableData = (
  params: IUseTableDataParams<IDocumentSearchParams>,
): IUseTableData<IDocumentTableData_Data, IDocumentSearchParams> | undefined => {
  // state
  const [pageIndex, setPageIndex] = useState(params.pageIndex)
  const [rowPerPage, setRowPerPage] = useState(params.rowPerPage)

  const [pendingSearch, setPendingSearch] = useState<IDocumentSearchParams>(params.search)
  const [search, setSearch] = useState<IDocumentSearchParams>(params.search)
  const [tableData, setTableData] = useState<IInvoiceTableData | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(true)

  const _loadData = async (pageIndex: number, rowPerPage: number, search: IDocumentSearchParams) => {
    const response = await getInvoiceTableDataAPI(buildQueryParams(pageIndex, rowPerPage, search))
    if (response?.data) setTableData(response.data || undefined)

    setIsLoading(false)
  }

  // first load
  useEffect(() => {
    _loadData(params.pageIndex, params.rowPerPage, search)
    // eslint-disable-next-line
  }, [])

  // functions
  const handlePageChange = async (_pageIndex: number) => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(_pageIndex, rowPerPage, search)
    setPageIndex(_pageIndex)
  }

  const handleRowPerPageChange = async (_rowPerPage: number) => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(0, _rowPerPage, search)
    setRowPerPage(_rowPerPage)
  }

  const handleSearchParamChange = <T extends keyof IDocumentSearchParams>(key: T, value: IDocumentSearchParams[T]) => {
    setPendingSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const handleSearch = async () => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(0, rowPerPage, pendingSearch)
    setSearch(pendingSearch)
  }

  const refetch = async () => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(pageIndex, rowPerPage, search)
  }

  return tableData
    ? {
        ...tableData,
        search,
        pendingSearch,
        handlePageChange,
        handleRowPerPageChange,
        handleSearchParamChange,
        handleSearchParamsChange: (s) => {},
        handleSearch,
        refetch,
        isLoading,
      }
    : undefined
}
