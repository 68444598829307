import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CustomerType } from '../../enums'

export const useStyles = (customerType?: CustomerType, isCredit?: boolean) =>
  makeStyles((theme) => {
    const borderColor =
      customerType === CustomerType.Company ? theme.palette.primary.dark : theme.palette.secondary.dark

    const chipBgColor =
      customerType === CustomerType.Company ? theme.palette.primary.light : theme.palette.secondary.light

    return createStyles({
      root: {
        borderLeftWidth: theme.spacing(4),
        borderLeftStyle: 'solid',
        borderColor,
      },
      avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        borderWidth: isCredit ? theme.spacing(0.5) : 0,
        borderColor: theme.palette.custom?.golden,
        borderStyle: 'solid',
        [theme.breakpoints.down('md')]: {
          width: theme.spacing(10),
          height: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
          margin: '0 auto',
        },
      },
      creditBadge: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingButtom: theme.spacing(0.5),
        width: theme.spacing(15),
        textAlign: 'center',
        backgroundColor: theme.palette.custom?.golden,
        borderRadius: 4,
        [theme.breakpoints.down('md')]: {
          width: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
          margin: '0 auto',
          marginTop: theme.spacing(1),
        },
      },
      chip: {
        backgroundColor: chipBgColor,
      },
      gridItem: {
        height: 'fit-content',
      },
    })
  })
