import { useMemo, useState } from 'react'
import { Box, Paper, Tab, Tabs } from '@material-ui/core'
import {
  VendorContactInfo,
  VendorProfile,
  VendorProfile_Activity,
  VendorProfile_Address,
} from '../../../../api/smartbestbuys-api'
import VendorContactBox from './VendorContactBox'
import VendorAddressBox from './VendorAddressBox'
import VendorBankAccountBox from './VendorBankAccountBox'
import AttachmentBox from '../../../../shared-components/AttachmentBox'
import { UserActivityTable } from '../../../../shared-components'
import { FileType } from '../../../../types'
import VendorAttachmentUpdateDialog from '../../../../shared-components/VendorAttachmentUpdateDialog'

enum TabValue {
  Contact = 0,
  Address = 1,
  BankAccount = 2,
  Attachment = 3,
  Activity = 4,
}

interface Props {
  vendorProfile: VendorProfile
  onContactUpdate?: () => void
  onAddressUpdate?: () => void
  onBankAccountUpdate?: () => void
  onAttachmentsUpdate?: () => void
}

const VendorMoreInformationBox: React.FC<Props> = (props: Props) => {
  const { vendorProfile, onContactUpdate, onAddressUpdate, onBankAccountUpdate, onAttachmentsUpdate } = props
  const { contact, address, banks, attachments, activities } = vendorProfile

  // state
  const [updateAttachmentDialogOpen, setUpdateAttachmentDialogOpen] = useState(false)
  const [tabValue, setTabValue] = useState(TabValue.Contact)

  // memo
  const contactInfo = useMemo<VendorContactInfo | undefined>(() => {
    if (!contact) return undefined
    return contact[0]
    // eslint-disable-next-line
  }, [contact?.length ?? 0])

  const addressInfo = useMemo<VendorProfile_Address | undefined>(() => {
    if (!address) return undefined
    return address[0]
    // eslint-disable-next-line
  }, [address?.length ?? 0])

  const attachmentSourceFiles =
    attachments?.map((att, index) => {
      return {
        id: att.id,
        url: att.attachmentUrl,
        type: FileType.Image,
        name: 'attachement ' + (index + 1),
      }
    }) ?? []

  return (
    <Box p={0} clone>
      <Paper>
        <Tabs
          style={{ borderBottom: 1 }}
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={(_, newValue) => setTabValue(newValue)}
        >
          <Tab label="ข้อมูลผู้ติดต่อ" value={TabValue.Contact} />
          <Tab label="ข้อมูลที่อยู่" value={TabValue.Address} />
          <Tab label="ข้อมูลบัญชีธนาคาร" value={TabValue.BankAccount} />
          <Tab label="ไฟล์แนบเพิ่มเติม" value={TabValue.Attachment} />
          <Tab label="ประวัติคะแนนผู้ขาย" value={TabValue.Activity} />
        </Tabs>
        {
          [
            <VendorContactBox vendorId={vendorProfile.id} contactInfo={contactInfo} onUpdate={onContactUpdate} />,
            <VendorAddressBox vendorId={vendorProfile.id} address={addressInfo} onUpdate={onAddressUpdate} />,
            <VendorBankAccountBox vendorId={vendorProfile.id} bankAccounts={banks} onUpdate={onBankAccountUpdate} />,
            <AttachmentBox
              files={attachmentSourceFiles}
              onUpdateButtonClick={() => setUpdateAttachmentDialogOpen(true)}
            >
              <VendorAttachmentUpdateDialog
                open={updateAttachmentDialogOpen}
                vendorId={vendorProfile.id}
                defaultValues={attachmentSourceFiles}
                onClose={() => setUpdateAttachmentDialogOpen(false)}
                onComplete={() => {
                  setUpdateAttachmentDialogOpen(false)
                  if (!!onAttachmentsUpdate) onAttachmentsUpdate()
                }}
              />
            </AttachmentBox>,
            <UserActivityTable<VendorProfile_Activity> title="ประวัติคะแนนผู้ขาย" activities={activities ?? []} />,
          ][tabValue]
        }
      </Paper>
    </Box>
  )
}

export default VendorMoreInformationBox
