import { useContext, useState } from 'react'
import { Grid, Paper, Tab, Tabs } from '@material-ui/core'
import { Topic, withLayout } from '../../custom-components'
import { PageName } from '../../enums'
import { RootContext } from '../..'
import MarketingSalesGoalDailyDashboard from './sale-orders/MarketingSalesGoalDailyDashboard'
import MarketingSalesGoalMonthlyDashboard from './sale-orders/MarketingSalesGoalMonthlyDashboard'

const MarketingSalesGoalDashboardPage: React.FC = (props) => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.MARKETING_SALES_GOAL_REPORT)

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>ข้อมูลแดชบอร์ด เป้าการขาย</Topic>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, value) => setTabIndex(value)}
            centered
            variant="fullWidth"
          >
            <Tab label="รายงานการขายรายวัน" value={0} />
            <Tab label="รายงานการขาย แบ่งตามช่องทางการติดต่อ" value={1} />
          </Tabs>
        </Grid>
      </Grid>
      {tabIndex === 1 ? <MarketingSalesGoalMonthlyDashboard /> : <MarketingSalesGoalDailyDashboard />}
    </Paper>
  )
}

export default withLayout(MarketingSalesGoalDashboardPage, true)
