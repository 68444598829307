import { Typography } from '@material-ui/core'
import { ReceiveProductAction } from '../../../../enums'

interface ReceiveProductActionMessageProps {
  receivedAction?: ReceiveProductAction
}

const ReceiveProductActionMessage: React.FC<ReceiveProductActionMessageProps> = ({ receivedAction }) => {
  switch (receivedAction) {
    case ReceiveProductAction.All:
      return <Typography>รับครบ/รับที่เหลือ</Typography>
    case ReceiveProductAction.Partial:
      return <Typography>รับขาด</Typography>
    case ReceiveProductAction.OverWithGiven:
      return <Typography>ได้ของแถม</Typography>
    case ReceiveProductAction.RejectRemaining:
      return <Typography>รับขาดแต่ไม่รอรับที่ขาด</Typography>
    case ReceiveProductAction.Nothing:
      return <Typography>ไม่ได้รับ</Typography>
    case ReceiveProductAction.AlreadyReceived:
      return <Typography>รับครบไปแล้ว</Typography>
    default:
      return <Typography>-</Typography>
  }
}

export default ReceiveProductActionMessage
