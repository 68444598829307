import { Avatar, Button, Chip, DialogProps, Paper, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import React, { cloneElement, useEffect, useState } from 'react'
import { useStyles } from './styles'

interface Props<T> {
  code?: string
  name: string
  imageUrl?: string
  selected?: boolean
  btnTextSelected?: string
  btnText?: string
  onClick?: () => void
  dialogComponent?: React.ReactElement<T & DialogProps>
  dialogComponentProps?: T
  isSelectedClick?: boolean
  chipText?: string
  descriptionText?: string
}

const PurchaseProductCard = <T extends {}>(props: Props<T>) => {
  const {
    code,
    imageUrl,
    name,
    onClick,
    btnTextSelected,
    btnText,
    selected,
    chipText,
    descriptionText,
    isSelectedClick,
  } = props

  const styles = useStyles()
  const [open, setOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    setIsSelected(!!selected)
  }, [selected])

  const _handleClick = () => {
    if (!isSelected) {
      isSelectedClick && setIsSelected(true)
      setOpen(true)
    } else {
      setIsSelected(false)
      setOpen(false)
    }
    onClick && onClick()
  }

  const renderModal = () => {
    if (props.dialogComponent && props.dialogComponentProps && React.isValidElement(props.dialogComponent)) {
      return cloneElement<T & DialogProps>(props.dialogComponent, {
        open,
        onClose: () => setOpen(false),
        ...props.dialogComponentProps,
      })
    }
  }

  return (
    <>
      <Paper className={styles.root}>
        <Typography variant="body2">{code}</Typography>
        <div className={styles.image}>
          <Avatar variant="rounded" src={imageUrl} className={styles.avatar}>
            <Photo style={{ fontSize: 120 }} />
          </Avatar>
          {chipText && <Chip label={chipText} className={styles.chip} />}
        </div>
        <Typography variant="body2" className={styles.subtitle}>
          {name}
        </Typography>
        {descriptionText && (
          <Typography variant="body2" className={styles.descriptionText}>
            {descriptionText}
          </Typography>
        )}
        <Button variant={isSelected ? 'outlined' : 'contained'} size="small" color="primary" onClick={_handleClick}>
          {!isSelected ? btnTextSelected || 'เลือกเข้าคำขอซื้อ' : btnText || 'นำออกจากคำขอซื้อ'}
        </Button>
      </Paper>
      {open && props?.dialogComponent ? renderModal() : null}
    </>
  )
}

export default PurchaseProductCard
