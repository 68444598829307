import { useState, useEffect } from 'react'
import { fetchSaleOrders, SaleOrder_TableData, FetchSaleOrderRequest } from '../api/smartbestbuys-api'

export const useSaleOrders = (startDate: Date, endDate: Date): SaleOrder_TableData[] => {
  const [saleOrders, setSaleOrder] = useState<SaleOrder_TableData[]>([])

  const _fetchSaleOrder = async (request: FetchSaleOrderRequest) => {
    const response = await fetchSaleOrders(request)
    setSaleOrder(response?.data || [])
  }

  useEffect(() => {
    _fetchSaleOrder({ startDate, endDate })
  }, [startDate, endDate])

  return saleOrders
}
