import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useState } from 'react'
import { useReportSalesGoalDaily } from '../../../hooks/sales-report'
import { Loading } from '../../../shared-components'
import { formatNumber } from '../../../functions/number-utils'
import { format } from 'date-fns'

const useStyles = makeStyles({
  table: {
    minWidth: 750,
  },
})

const MarketingSalesGoalDailyDashboard: React.FC = (props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const styles = useStyles()

  const sales = useReportSalesGoalDaily(selectedDate)
  if (!sales) return <Loading />

  const tableHeadFirst = [
    { label: 'วัน', colspan: 2 },
    { label: 'ยอดขาย', colspan: 3 },
    { label: 'กำไร', colspan: 3 },
    { label: 'เป้าหมายรายเดือน', colspan: 2 },
    { label: formatNumber(sales?.summary?.totalSalesGoal ?? 0), colspan: 2 },
  ]

  const tableHeadSecord = [
    {
      label: 'วันที่',
    },
    {
      label: 'วัน',
    },
    {
      label: 'ยอดขายรายวัน',
    },
    {
      label: 'เป้าหมายรายวัน',
    },
    {
      label: '%',
    },
    {
      label: 'กำไรขั้นต้น',
    },
    {
      label: 'GP%',
    },
    {
      label: 'กำไรสะสม',
    },
    {
      label: 'ยอดขายสะสม',
    },
    {
      label: 'เป้าสะสม',
    },
    {
      label: 'ผลต่าง',
    },
    {
      label: '%',
    },
  ]

  const handleDateChange = async (date: Date | null) => {
    if (date) setSelectedDate(date)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="yyyy/MM"
            views={['year', 'month']}
            margin="normal"
            id="date-picker-inline"
            label="เลือกช่วงเวลา"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table" className={styles.table}>
            <TableHead>
              <TableRow>
                {tableHeadFirst.map((row) => (
                  <TableCell align="center" colSpan={row.colspan}>
                    {row.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {tableHeadSecord.map((row) => (
                  <TableCell align="center">{row.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sales?.data &&
                sales?.data.map((row, index) => {
                  const successSale = row.salesTotal > row.salesGoal
                  const successAccumSale = row.accumSalesTotal > row.accumSalesGoal
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">{format(new Date(row.date), 'dd/MM/yyyy')}</TableCell>
                      <TableCell align="center">{format(new Date(row.date), 'iiii')}</TableCell>
                      <TableCell align="center">
                        <Box color={successSale ? 'success.main' : 'primary.main'} clone>
                          <Typography>{formatNumber(row.salesTotal ?? 0)}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{formatNumber(row.salesGoal ?? 0)}</TableCell>
                      <TableCell align="center">
                        <Box color={successSale ? 'success.main' : 'primary.main'} clone>
                          <Typography>{formatNumber(row.percentDiffSalesAndSalesGoal ?? 0)}%</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{formatNumber(row.grossProfit ?? 0)}</TableCell>
                      <TableCell align="center">{formatNumber(row.grossProfitPercent ?? 0)}%</TableCell>
                      <TableCell align="center">{formatNumber(row.accumGrossProfit ?? 0)}</TableCell>
                      <TableCell align="center">
                        <Box color={successAccumSale ? 'success.main' : 'primary.main'} clone>
                          <Typography>{formatNumber(row.accumSalesTotal ?? 0)}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{formatNumber(row.accumSalesGoal ?? 0)}</TableCell>
                      <TableCell align="center">
                        <Box color={successAccumSale ? 'success.main' : 'primary.main'} clone>
                          <Typography>{formatNumber(row.diffAccumSalesAndSalesGoal ?? 0)}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box color={successAccumSale ? 'success.main' : 'primary.main'} clone>
                          <Typography> {formatNumber(row.percentDiffAccumSalesAndSalesGoal ?? 0)}%</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default MarketingSalesGoalDailyDashboard
