import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../..'
import { uploadPublicFile } from '../../../api/aws-api'
import { confirmPaymentRefundRequisitionAPI, IRefundRequisitionProfile } from '../../../api/smartbestbuys-api'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  KeyboardDateTimePickerForm,
  TypographyWithLabel,
} from '../../../custom-components'
import { bankAccounts } from '../../../data'
import { formatNumber } from '../../../functions/number-utils'
import { Space, SubmitForm, UploadImageCard } from '../../../shared-components'

interface Props extends DialogProps {
  refundRequisitionProfile: IRefundRequisitionProfile
  onComplete: (data: IRefundRequisitionProfile) => void
}

interface RefundRequisitionUploadSlipFormValue {
  base64Image: string
  paymentTimeStamp: Date
  amount: number
  smartBankAccountId: number
  paymentRemark?: string
}

const RefundRequisitionUploadSlipDialog: React.FC<Props> = (props) => {
  const { refundRequisitionProfile, onClose, onComplete, ...otherDialogProps } = props

  const { id, total, customerBankAccount } = refundRequisitionProfile

  const { triggerSnackbar } = useContext(RootContext)

  const {
    watch,
    register,
    getValues,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<RefundRequisitionUploadSlipFormValue>()

  const { paymentTimeStamp } = watch()

  useEffect(() => {
    register('base64Image', { required: 'กรุณาเลือกหลักฐานการโอน' })
  }, [register])

  const handleSubmitForm = async (values: RefundRequisitionUploadSlipFormValue) => {
    let imageUrl
    try {
      imageUrl = await uploadPublicFile(
        values.base64Image,
        `refund-requisitions/${id}/payslip_${DateTime.now().toFormat('x')}`,
      )
    } catch (error) {
      return
    }

    if (!imageUrl) {
      triggerSnackbar('อัปโหลดรูปไม่สำเร็จ')
      return
    }

    const request = {
      paymentImageUrl: imageUrl,
      paymentTimestamp: DateTime.fromJSDate(new Date(values.paymentTimeStamp)).toMillis(),
      smartBankAccountId: values.smartBankAccountId,
      paymentRemark: values.paymentRemark,
    }

    const response = await confirmPaymentRefundRequisitionAPI(id, request)
    if (response?.status === 200) {
      onComplete(response.data)
      triggerSnackbar('ยืนยันการโอนเงินสำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
    } else {
      triggerSnackbar('ยืนยันการโอนเงินไม่สำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={() => onClose && onClose({}, 'escapeKeyDown')} {...otherDialogProps}>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <DialogTitle>ยืนยันการโอนเงินคืน</DialogTitle>
        <DialogContent>
          <FlexBox>
            <Grid item xs={12}>
              <TypographyWithLabel label="จำนวนเงินที่ต้องโอนคืน:">{formatNumber(total)} บาท</TypographyWithLabel>
            </Grid>
            <Grid item xs={12}>
              <TypographyWithLabel label="บัญชีธนาคาร:">
                <div>
                  {customerBankAccount.bank.name} {customerBankAccount.accountNumber}
                </div>
                <div>{customerBankAccount.accountName}</div>
              </TypographyWithLabel>
            </Grid>
          </FlexBox>
          <Space />
          <FlexBox justifyContent="center">
            <UploadImageCard
              previewOnChange
              title="อัปโหลดรูปหลักฐาน *"
              onChange={(image) => {
                setValue('base64Image', image.base64)
              }}
              error={!!errors.base64Image}
              message={errors.base64Image?.message}
              defaultSrc={getValues('base64Image')}
              uploading={isSubmitting}
            />
          </FlexBox>
          <Space />
          <FlexBox gridGap={16}>
            <Grid item xs={12}>
              <KeyboardDateTimePickerForm
                label="วัน-เวลาที่โอนเงิน *"
                control={control}
                name="paymentTimeStamp"
                value={paymentTimeStamp}
                defaultValue={null}
                size="small"
                gutterTop
                fullWidth
                rules={{
                  required: 'กรุณากรอกวันที่ออกเช็ค',
                }}
                error={!!errors.paymentTimeStamp}
                helperText={errors.paymentTimeStamp?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <GeneralTextFieldForm
                label="จำนวนเงิน *"
                control={control}
                name="amount"
                rules={{
                  required: 'กรุณากรอกจำนวนเงิน',
                  validate: {
                    matchTotal: (value) => Number(value) === total || 'กรุณากรอกให้ตรงกับเงินที่ต้องโอนคืน',
                  },
                }}
                error={!!errors.amount}
                helperText={!!errors.amount ? errors.amount.message : undefined}
              />
            </Grid>
          </FlexBox>
          <FlexBox gridGap={16}>
            <Grid item xs={12}>
              <GeneralTextFieldForm
                label="บัญชีที่โอนคืน *"
                control={control}
                name="smartBankAccountId"
                rules={{ required: 'กรุณาเลือกธนาคาร' }}
                select
              >
                {bankAccounts.map((c) => (
                  <MenuItem key={c.name} value={c.id}>
                    <FlexBox flexDirection="column">
                      <div>{c.bankName}</div>
                      <div>{c.name}</div>
                      <Typography color="primary">{c.number}</Typography>
                    </FlexBox>
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12}>
              <GeneralTextFieldForm label="หมายเหตุ (ถ้ามี)" control={control} name="paymentRemark" />
            </Grid>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            isSubmitting={isSubmitting}
            onCancel={() => {
              onClose && onClose({}, 'escapeKeyDown')
              reset()
            }}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default RefundRequisitionUploadSlipDialog
