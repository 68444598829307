import { useState, useEffect } from 'react'
import { fetchPurchaseRequisitions, FetchRequest, PurchaseRequisitionDataRow } from '../api/smartbestbuys-api'

export const usePurchaseRequisitions = (startDate: Date, endDate: Date): PurchaseRequisitionDataRow[] => {
  const [purchaseRequisitions, setPurchaseRequisitions] = useState<PurchaseRequisitionDataRow[]>([])

  const _fetchPurchaseRequisitions = async (request: FetchRequest) => {
    const response = await fetchPurchaseRequisitions(request)
    setPurchaseRequisitions(response?.data || [])
  }

  useEffect(() => {
    _fetchPurchaseRequisitions({ startDate, endDate })
  }, [startDate, endDate])

  return purchaseRequisitions
}
