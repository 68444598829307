import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import { FollowServiceTask_TableData } from '../../../api/smartbestbuys-api'
import { FilteredTable, Topic, withLayout, Link } from '../../../custom-components'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { NavigationItemKey, PageName, TaskStatus, TaskType } from '../../../enums'
import { dateFormat, dateTimeFormat } from '../../../functions'
import { usePurchaseTasks } from '../../../hooks/purchase/usePurchaseTasks'
import AlreadyReceivedServiceDialog from './AlreadyReceivedServiceDialog'
import ReceiveServiceDialog from './ReceiveServiceDialog'
import './FollowServiceTaskListPage.less'
import { isBefore } from 'date-fns'
import { displayDateWithDiffDay } from '../../../utils/date-utils'
import { NotificationHead, SOStatusChip } from '../../../shared-components'

const FollowServiceTaskListPage = () => {
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PURCHASE_FOLLOW_SERVICE_TASK)

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [tabValue, setTabValue] = useState(TaskStatus.Pending)
  const [selectedReceiveServiceTaskId, setSelectedReceiveServiceTaskId] = useState<number | undefined>(undefined)
  const [reload, setReload] = useState(false)

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    setSelectedReceiveServiceTaskId(undefined)
  }

  const renderDialog = () => {
    if (selectedReceiveServiceTaskId === undefined) return <></>
    if (tabValue === TaskStatus.Pending) {
      return (
        <ReceiveServiceDialog
          open={!!selectedReceiveServiceTaskId}
          receiveServiceTaskId={selectedReceiveServiceTaskId}
          onComplete={() => {
            setReload((prev) => !prev)
          }}
          onClose={() => {
            setSelectedReceiveServiceTaskId(undefined)
          }}
        />
      )
    }

    return (
      <AlreadyReceivedServiceDialog
        open={!!selectedReceiveServiceTaskId}
        receiveServiceTaskId={selectedReceiveServiceTaskId}
        onClose={() => {
          setSelectedReceiveServiceTaskId(undefined)
        }}
      />
    )
  }

  const purchaseTasks = usePurchaseTasks(startDate, endDate, TaskType.FollowServiceTask, reload)

  const sortedPurchaseTasks = useMemo(() => {
    const filteredPurchaseTasks = purchaseTasks.filter((data) => data.status.id === tabValue)
    if (tabValue === TaskStatus.Done) {
      return filteredPurchaseTasks.sort((a: FollowServiceTask_TableData, b: FollowServiceTask_TableData): number => {
        if (!!a.finishedAt && !!b.finishedAt) {
          const aDate = new Date(a.finishedAt)
          const bDate = new Date(b.finishedAt)
          return isBefore(aDate, bDate) ? 1 : -1
        }
        return 1
      })
    }
    return filteredPurchaseTasks
    // eslint-disable-next-line
  }, [tabValue, purchaseTasks.map((t) => t.code).toString()])

  return (
    <Box p={2}>
      {renderDialog()}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Topic>รายงานการติดตามบริการ</Topic>
        </Grid>
        <Grid item xs={12}>
          <NotificationHead navigationKey={NavigationItemKey.FollowServiceTask} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<FollowServiceTask_TableData>
              color="primary.dark"
              columns={[
                {
                  name: 'เลขที่งานคลัง',
                  dataIndex: 'code',
                  render: (record) => {
                    return (
                      <Box color="primary.dark" className="follow-service-task-table__code" clone>
                        <Typography variant="body2" onClick={() => setSelectedReceiveServiceTaskId(record.id)}>
                          {record.code}
                        </Typography>
                      </Box>
                    )
                  },
                },
                {
                  name: 'ใบสั่งซื้ออ้างอิง',
                  dataIndex: 'code',
                  render: ({ purchaseOrderInfo }) => (
                    <Link color="primary" to={`/purchase-orders/${purchaseOrderInfo?.id}/profile`} target="_blank">
                      <Box color="secondary.dark">
                        <Typography variant="body2">{purchaseOrderInfo?.code}</Typography>
                      </Box>
                    </Link>
                  ),
                },
                {
                  name: 'ใบสั่งขายอ้างอิง',
                  render: ({ saleOrderInfo }) => (
                    <Link color="primary" to={`/sale-orders/${saleOrderInfo?.id}`} target="_blank">
                      <Box color="secondary.dark">
                        <Typography variant="body2">{saleOrderInfo?.code}</Typography>
                      </Box>
                    </Link>
                  ),
                },
                {
                  name: 'สถานะใบสั่งขาย',
                  dataIndex: 'status',
                  render: ({ saleOrderInfo }) =>
                    saleOrderInfo ? (
                      <SOStatusChip status={saleOrderInfo?.status.id} label={saleOrderInfo?.status.name} />
                    ) : (
                      <></>
                    ),
                },
                {
                  ...(tabValue === TaskStatus.Done
                    ? {
                        name: 'วันที่รับบริการ',
                        render: ({ finishedAt, dueDate: _dueDate }) => {
                          if (!finishedAt) return '-'
                          const finishedAtDate = new Date(finishedAt)
                          if (!!_dueDate) {
                            const dueDate = new Date(_dueDate)
                            return displayDateWithDiffDay(finishedAtDate, dueDate)
                          }
                          return <Typography variant="body2">{dateFormat(finishedAtDate)}</Typography>
                        },
                      }
                    : {
                        name: 'วันที่นัดรับบริการ',
                        render: ({ dueDate }) => (!!dueDate ? dateFormat(dueDate) : '-'),
                      }),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  render: ({ purchaseOrderInfo }) => purchaseOrderInfo?.vendorName,
                },
                {
                  ...(tabValue === TaskStatus.Done
                    ? {
                        name: 'รับโดย',
                        dataIndex: 'finishedBy',
                      }
                    : {
                        name: 'วันที่สร้าง',
                        render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                      }),
                },
              ]}
              sortable
              data={sortedPurchaseTasks}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              tabOption={{
                tabs: [
                  {
                    label: `งานที่ต้องรับ (${
                      purchaseTasks.filter((data) => data.status.id === TaskStatus.Pending).length
                    })`,
                    value: TaskStatus.Pending,
                  },
                  { label: 'รับเสร็จสิ้น', value: TaskStatus.Done },
                  { label: 'ยกเลิก', value: TaskStatus.Canceled },
                ],
                value: tabValue,
                onChange: handleTabChange,
              }}
              cleanSpace
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(FollowServiceTaskListPage)
