import { Box, Grid, IconButton, Paper } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useMemo, useState } from 'react'
import {
  BaseData,
  PaymentRequisitionProfile,
  PaymentRequisitionProfileItem,
  PaymentRequisitionState,
} from '../../../api/smartbestbuys-api'
import { EmptyCard } from '../../../custom-components'
import { Title, TypographyWithLabel } from '../../../custom-components/Typography'
import { PaymentRequisitionStatus } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { dateFormat } from '../../../functions'
import { formatNumber } from '../../../functions/number-utils'
import { ConditionTooltip, ImageCard } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import UploadReceiptDialog from './UploadReceiptDialog'

const blacklistedStatusToUploadReceipt = [
  PaymentRequisitionStatus.Success,
  PaymentRequisitionStatus.Rejected,
  PaymentRequisitionStatus.Canceled,
]

interface PaymentRequisitionInfo {
  id: number
  status: BaseData
  state?: PaymentRequisitionState
  items: PaymentRequisitionProfileItem[]
}

interface Props {
  paymentRequisitionInfo: PaymentRequisitionInfo
  // TODO: should not return whole profile
  onUploadReceiptSuccess?: (data: PaymentRequisitionProfile) => void
}

// document types which can upload as purchase order document
const documentTypes: BaseData[] = [
  { id: 102, name: 'ใบกำกับภาษี' },
  { id: 104, name: 'ใบเสร็จรับเงิน' },
]

const PaymentRequisitionReceiptBox: React.FC<Props> = (props: Props) => {
  // props
  const { paymentRequisitionInfo, onUploadReceiptSuccess } = props
  const { id, state, items } = paymentRequisitionInfo

  // state
  const [uploadReceiptOpen, setUploadReceiptOpen] = useState(false)

  // data
  const receiptInfo = state?.receiptInfo
  const showedReUploadReceipt =
    !blacklistedStatusToUploadReceipt.includes(paymentRequisitionInfo.status.id) && !!receiptInfo

  const isSinglePurchaseOrder = useMemo(() => items.length === 1, [items.length])
  const documentTypeName = documentTypes.find((documentType) => documentType.id === receiptInfo?.documentTypeId)?.name

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Write)

  return (
    <Box p={3} clone className="h-full">
      <Paper>
        <UploadReceiptDialog
          open={uploadReceiptOpen}
          onClose={() => setUploadReceiptOpen(false)}
          paymentRequisitionId={id}
          isSinglePurchaseOrder={isSinglePurchaseOrder}
          onSuccess={(data) => {
            if (!!onUploadReceiptSuccess) onUploadReceiptSuccess(data)
            setUploadReceiptOpen(false)
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} container justify="space-between">
            <Title>เอกสารทางการเงินจากผู้ขาย</Title>
            {!!showedReUploadReceipt && (
              <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
                <IconButton
                  disabled={!writePermission.hasPermission}
                  aria-label="re upload receipt"
                  color="secondary"
                  onClick={() => setUploadReceiptOpen(true)}
                >
                  <Edit />
                </IconButton>
              </ConditionTooltip>
            )}
          </Grid>
          <Grid item xs={12}>
            {!!receiptInfo ? (
              <Grid container spacing={1}>
                <Grid item xs={12} container justify="center">
                  <ImageCard size="big" name="receipt" src={receiptInfo.receiptImageUrl} />
                </Grid>
                {documentTypeName && (
                  <Grid item xs={12} md={6}>
                    <TypographyWithLabel label="ประเภทเอกสาร:">{documentTypeName}</TypographyWithLabel>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="เลขที่เอกสาร:">{receiptInfo.receiptCode}</TypographyWithLabel>
                </Grid>
                {receiptInfo.receiptDate && (
                  <Grid item xs={12} md={6}>
                    <TypographyWithLabel label="วันที่เอกสาร:">
                      {dateFormat(receiptInfo.receiptDate)}
                    </TypographyWithLabel>
                  </Grid>
                )}
                {receiptInfo.total && (
                  <Grid item xs={12} md={6}>
                    <TypographyWithLabel label="จำนวนเงิน:">{formatNumber(receiptInfo.total)}</TypographyWithLabel>
                  </Grid>
                )}
              </Grid>
            ) : (
              <EmptyCard error emptyText="ยังไม่ได้อัปโหลดเอกสารทางการเงินจากผู้ขาย" />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default PaymentRequisitionReceiptBox
