import { BreadcumbsList, CustomSpeedDial, withLayout } from '../../../../custom-components'
import { Grid } from '@material-ui/core'
import { Loading, UserActivityTable } from '../../../../shared-components'
import { useContext, useMemo } from 'react'
import { PageName } from '../../../../enums'
import { RootContext } from '../../../..'
import PWTicketProfilePageContextProvider, { usePWTickProfileContext } from './PWTicketProfilePageContextProvider'
import PWTicketProfilePageHeader from './PWTicketProfilePageHeader'
import PWTicketProfilePageDataBox from './PWTicketProfilePageDataBox'
import { profilePageText } from './pw-ticket-profile-page-setting'
import { PictureAsPdf } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const PWTicketProfilePage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PRODUCT_WITHDRAWAL_TABLE_DATA)

  const { isLoading, profile } = usePWTickProfileContext()
  const history = useHistory()

  const breadcumbsItems = useMemo(
    () => [
      {
        url: '/warehouse/product-withdrawal-tickets',
        name: 'รายการคำขอเบิกสินค้า',
      },
      {
        name: `คำขอเบิกสินค้าเลขที่ ${profile?.code}`,
      },
    ],
    [profile],
  )

  if (!profile || isLoading) return <Loading />

  const { activities } = profile

  return (
    <>
      <Grid container spacing={4}>
        <CustomSpeedDial
          actions={[
            {
              icon: <PictureAsPdf />,
              name: 'ดูเป็น PDF',
              onClick: () => history.push(`/warehouse/product-withdrawal-tickets/${profile.id}/pdf`),
            },
          ]}
        />
        <Grid item xs={12}>
          <BreadcumbsList items={breadcumbsItems} />
        </Grid>
        <Grid item xs={12}>
          <PWTicketProfilePageHeader />
        </Grid>
        <Grid item xs={12}>
          <PWTicketProfilePageDataBox />
        </Grid>
        <Grid item xs={12}>
          <UserActivityTable<any>
            title={profilePageText.activity.title}
            activities={activities.map((act) => ({
              ...act,
              actionName: act.action.name,
              actionMessage: act.actionMessage?.name,
            }))}
          />
        </Grid>
      </Grid>
    </>
  )
}

const PWTicketProfileWithContext = () => {
  return (
    <PWTicketProfilePageContextProvider>
      <PWTicketProfilePage />
    </PWTicketProfilePageContextProvider>
  )
}

export default withLayout(PWTicketProfileWithContext)
