import { BreadcumbsList, FlexBox, Grid, Topic, TypographyWithLabel, withLayout } from '../../../custom-components'
import { Loading, PaymentRequisitionStatusChip, UserActivityTable } from '../../../shared-components'
import { usePaymentRequisitionProfile } from '../../../hooks/purchase/usePaymentRequisitionProfile'
import PaymentRequisitionProfileMenu from './PaymentRequisitionProfileMenu'
import VendorProfileCard from '../../purchase-orders/profile/VendorProfileCard'
import PaymentRequisitionInformationBox, {
  mapPaymentRequisitionProfileToInformationValue,
} from '../PaymentRequisitionInformationBox'
import { PaymentRequisitionUserActivityToTable } from '../../../api/smartbestbuys-api'
import { mapToUserActivityTableValue } from '../../../shared-components/UserActivityTable'
import PaymentRequistionActionsBox from './PaymentRequisitionActionsBox'
import PayslipBox from './PayslipBox'
import PaymentRequisitionVerificationBox from './PaymentRequisitionVerificationBox'
import { Box } from '@material-ui/core'
import { PaymentMethod, PaymentRequisitionStatus, PaymentRequisitionType } from '../../../enums'
import PaymentRequisitionStatementBox from './PaymentRequisitionStatementBox'
import PaymentRequisitionReceiptBox from './PaymentRequisitionReceiptBox'
import PaymentRequisitionItemTable from './PaymentRequisitionItemTable'

interface Props {
  paymentRequisitionId: number
  typeId: number
}

const PaymentRequisitionProfilePage = (props: Props) => {
  // props
  const { paymentRequisitionId, typeId } = props

  // prepare data
  const [paymentRequisitionProfile, setPaymentRequisitionProfile] = usePaymentRequisitionProfile(paymentRequisitionId)

  // pending load data
  if (!paymentRequisitionProfile) return <Loading />
  const textAlertFailed =
    paymentRequisitionProfile.status.id === PaymentRequisitionStatus.Rejected
      ? 'สาเหตุที่ไม่อนุมัติ'
      : 'สาเหตุที่ยกเลิก'
  // data
  const isRequest = typeId === PaymentRequisitionType.Request
  const listPageName = isRequest ? 'รายการทำเบิก' : 'รายการทำจ่าย'
  const url = isRequest ? '/payment-requisitions' : '/purchase-payments'
  const topic = isRequest
    ? `ทำเบิก ${paymentRequisitionProfile.code}`
    : `ทำจ่าย ${paymentRequisitionProfile.paymentCode}`

  const breadcumbsItems = [{ url, name: listPageName }, { name: topic }]

  const showUploadPaySlip = paymentRequisitionProfile.status.id !== PaymentRequisitionStatus.PendingReview
  const showUploadStatement =
    paymentRequisitionProfile.status.id !== PaymentRequisitionStatus.PendingReview &&
    paymentRequisitionProfile.paymentMethod.id === PaymentMethod.Cheque
  const showedUploadReceiptBox = paymentRequisitionProfile.status.id !== PaymentRequisitionStatus.PendingReview

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BreadcumbsList items={breadcumbsItems} />
      </Grid>
      <Grid item xs={12} container justify="space-between">
        <Topic>{topic}</Topic>
        <PaymentRequisitionProfileMenu
          paymentRequisitionInfo={{
            id: paymentRequisitionProfile.id,
            code: paymentRequisitionProfile.code,
            status: paymentRequisitionProfile.status,
            paymentCode: paymentRequisitionProfile.paymentCode,
          }}
          onCancelSuccess={setPaymentRequisitionProfile}
        />
      </Grid>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <FlexBox alignItems="center">
          <PaymentRequisitionStatusChip
            icon
            status={paymentRequisitionProfile.status.id}
            label={paymentRequisitionProfile.status.name}
          />
          <span className="mr-4" />
          {!!paymentRequisitionProfile.addtionalData?.activityRemark && (
            <TypographyWithLabel label={textAlertFailed}>
              <Box color="error.main">{paymentRequisitionProfile.addtionalData.activityRemark}</Box>
            </TypographyWithLabel>
          )}
        </FlexBox>
        <PaymentRequistionActionsBox
          paymentRequisitionInfo={{
            id: paymentRequisitionProfile.id,
            status: paymentRequisitionProfile.status,
            paymentMethod: paymentRequisitionProfile.paymentMethod,
            withdrawalAt: paymentRequisitionProfile.withdrawalAt,
            vendorInfo: paymentRequisitionProfile.vendorInfo,
            total: paymentRequisitionProfile.total,
            withholdingTaxAmount: paymentRequisitionProfile.withholdingTaxAmount,
            items: paymentRequisitionProfile.items,
          }}
          onUploadPaySlipSuccess={setPaymentRequisitionProfile}
          onUploadStatementSuccess={setPaymentRequisitionProfile}
          onUploadReceiptSuccess={setPaymentRequisitionProfile}
        />
      </Grid>
      {paymentRequisitionProfile.status.id === PaymentRequisitionStatus.PendingReview && (
        <Grid item xs={12}>
          <PaymentRequisitionVerificationBox
            paymentRequisitionInfo={{
              id: paymentRequisitionProfile.id,
              code: paymentRequisitionProfile.code,
              verificationList: paymentRequisitionProfile.verificationList,
            }}
            onApproved={setPaymentRequisitionProfile}
            onRejected={setPaymentRequisitionProfile}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <VendorProfileCard value={paymentRequisitionProfile.vendorInfo} />
      </Grid>
      {showUploadPaySlip && (
        <Grid item xs={7}>
          <PayslipBox
            paymentRequisitionInfo={{
              id: paymentRequisitionProfile.id,
              status: paymentRequisitionProfile.status,
              paymentMethod: paymentRequisitionProfile.paymentMethod,
              state: paymentRequisitionProfile.state,
              vendorInfo: paymentRequisitionProfile.vendorInfo,
              total: paymentRequisitionProfile.total,
              withholdingTaxAmount: paymentRequisitionProfile.withholdingTaxAmount,
            }}
            onUploadPaySlipSuccess={setPaymentRequisitionProfile}
          />
        </Grid>
      )}
      {showedUploadReceiptBox && (
        <Grid item xs={5}>
          <PaymentRequisitionReceiptBox
            paymentRequisitionInfo={{
              id: paymentRequisitionProfile.id,
              state: paymentRequisitionProfile.state,
              status: paymentRequisitionProfile.status,
              items: paymentRequisitionProfile.items,
            }}
            onUploadReceiptSuccess={setPaymentRequisitionProfile}
          />
        </Grid>
      )}
      {showUploadStatement && (
        <Grid item xs={12}>
          <PaymentRequisitionStatementBox
            paymentRequisitionInfo={{
              id: paymentRequisitionProfile.id,
              total: paymentRequisitionProfile.total,
              state: paymentRequisitionProfile.state,
              status: paymentRequisitionProfile.status,
            }}
            onUploadStatementSuccess={setPaymentRequisitionProfile}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <PaymentRequisitionItemTable items={paymentRequisitionProfile.items} />
      </Grid>
      <Grid item xs={12}>
        <PaymentRequisitionInformationBox
          paymentRequisitionInformation={mapPaymentRequisitionProfileToInformationValue(paymentRequisitionProfile)}
          onExchangeRateUpdate={(updatedProfile) => setPaymentRequisitionProfile(updatedProfile)}
        />
      </Grid>
      <Grid item xs={12}>
        <UserActivityTable<PaymentRequisitionUserActivityToTable>
          title="ประวัติการจัดการคำขอซื้อ"
          activities={mapToUserActivityTableValue(paymentRequisitionProfile.activity) ?? []}
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(PaymentRequisitionProfilePage)
