import { Box, Button, Card, Grid, isWidthUp, Paper, Typography, withWidth, TablePagination } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import numeral from 'numeral'
import { useState } from 'react'
import {
  PaymentRequisitionCreateData_PurchaseOrderInfo,
  PaymentRequisitionSelectedItem,
  BaseData,
} from '../../../api/smartbestbuys-api'
import { ButtonRadios, Space } from '../../../shared-components'
import { FlexBox, SearchTextField, SubTopic } from '../../../custom-components'
import PaymentRequisitionSelectedDialog from './PaymentRequisitionSelectedDialog'
import { dateFormat } from '../../../functions'

interface Props {
  currency: BaseData
  purchaseOrderInfos: PaymentRequisitionCreateData_PurchaseOrderInfo[]
  onChange: (items: PaymentRequisitionSelectedItem[]) => void
  value: PaymentRequisitionSelectedItem[]
  width: Breakpoint
  errorMessage?: string
}

const PaymentRequisitionSelectorBox = (props: Props) => {
  // props && state
  const { currency, purchaseOrderInfos, width, value, onChange, errorMessage } = props
  const [query, setQuery] = useState('')
  const [filterReadyToPayValue, setFilterReadyToPayValue] = useState(true)
  const [page, setPage] = useState(0)
  const [selected, setSelected] = useState<PaymentRequisitionSelectedItem>()

  const rowsPerPage = isWidthUp('xl', width) ? 12 : 8

  // handlers && calculation
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const filteredPurchaseOrders = () =>
    purchaseOrderInfos.filter((p) => {
      const queryMatch = p.code.toLowerCase().includes(query.toLowerCase())
      const filterReadyToPayMatch = p.readyToPay === filterReadyToPayValue
      return filterReadyToPayMatch && queryMatch
    })

  const paginate = (purchaseOrderInfos: PaymentRequisitionCreateData_PurchaseOrderInfo[]) =>
    purchaseOrderInfos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <Box borderColor="error.main" clone>
      <Paper variant={!!errorMessage ? 'outlined' : undefined}>
        {!!selected && (
          <PaymentRequisitionSelectedDialog
            open={!!selected}
            value={selected}
            onComplete={(data) => {
              setSelected(undefined)
              onChange([...value, data])
            }}
            onClose={() => {
              setSelected(undefined)
            }}
            currency={currency.name}
          />
        )}
        <SubTopic>เลือกใบสั่งซื้อที่ต้องการทำเบิก</SubTopic>
        <div className="mb-4" />
        <FlexBox justifyContent="space-between">
          <SearchTextField
            onChange={(e) => {
              setQuery(e.target.value)
              setPage(0)
            }}
          />
          <ButtonRadios
            items={[
              { id: 1, value: true, name: 'ที่ต้องทำเบิก' },
              { id: 2, value: false, name: 'ยังไม่ถึงเวลา' },
            ]}
            onValueChange={(value) => {
              setFilterReadyToPayValue(value as boolean)
            }}
            defaultValue={filterReadyToPayValue}
            color="secondary"
            grouped
          />
        </FlexBox>
        <div className="mb-4" />
        <Grid container spacing={2}>
          {paginate(filteredPurchaseOrders() as PaymentRequisitionCreateData_PurchaseOrderInfo[]).map(
            (purchaseOrder) => (
              <Grid item xs={12} sm={6} md={3} xl={2}>
                <Card>
                  <Typography variant="body2">{purchaseOrder.code}</Typography>
                  <div className="mb-2" />
                  <FlexBox justifyContent="space-between">
                    <Typography variant="body2">ยอดรอทำเบิก</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {numeral(
                        purchaseOrder.total -
                          purchaseOrder.paymentTransactions.reduce<number>((sum, payment) => {
                            return sum + payment.total
                          }, 0),
                      ).format('0,0.00')}{' '}
                      {currency.name}
                    </Typography>
                  </FlexBox>
                  <div className="mb-2" />
                  <FlexBox justifyContent="space-between">
                    <Typography variant="body2">วันที่ต้องจ่าย</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {!!purchaseOrder.paymentDueDate ? dateFormat(purchaseOrder.paymentDueDate) : '-'}
                    </Typography>
                  </FlexBox>
                  <div className="mb-2" />
                  <FlexBox justifyContent="space-between">
                    <Typography variant="body2">เอกสารทางการเงิน</Typography>
                    {purchaseOrder.documents.length > 0 ? (
                      <Typography variant="body2" color="textSecondary">
                        {purchaseOrder.documents.length} ใบ
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="error">
                        ยังไม่ได้อัปโหลด
                      </Typography>
                    )}
                  </FlexBox>
                  <div className="mb-2" />
                  {!!value.find((item) => item.id === purchaseOrder.id) ? (
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => {
                        value.splice(
                          value.findIndex((v) => v.id === purchaseOrder.id),
                          1,
                        )
                        onChange([...value])
                      }}
                      fullWidth
                    >
                      นำออกจากรายการทำเบิก
                    </Button>
                  ) : purchaseOrder.documents.length > 0 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setSelected({ ...purchaseOrder, payTotal: purchaseOrder.total })}
                      fullWidth
                    >
                      เลือกเพื่อทำเบิก
                    </Button>
                  ) : (
                    <Button variant="contained" disabled fullWidth>
                      รออัปโหลดเอกสาร
                    </Button>
                  )}
                </Card>
              </Grid>
            ),
          )}
        </Grid>
        <TablePagination
          component="div"
          count={filteredPurchaseOrders().length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
        {errorMessage && (
          <>
            <Space />
            <Typography color="error">กรุณาเลือกใบสั่งซื้อเพื่อทำการเบิก</Typography>
          </>
        )}
      </Paper>
    </Box>
  )
}

export default withWidth()(PaymentRequisitionSelectorBox)
