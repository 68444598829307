import React, { useEffect, useState } from 'react'
import {
  Button,
  createStyles,
  Box,
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { Topic } from '../../custom-components'
import { useFormik } from 'formik'
import { LoginForm, initialValues, validationSchema, onSubmit } from './login-formik'
import { Visibility } from '@material-ui/icons'
import { SESSION_KEY, SESSION_KEY_PREFIX } from '../../functions'

const useStyles = makeStyles((theme) =>
  createStyles({
    bg: {
      width: '100%',
      height: '100vh',
      background: 'url(/login-bg.png)',
      backgroundSize: 'auto 110%',
      backgroundPosition: 'right',
    },
  }),
)

const LoginPage: React.FC = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles()
  const formik = useFormik<LoginForm>({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    const keyAmount = window.localStorage.length
    for (let i = 0; i < keyAmount; i++) {
      const _key = window.localStorage.key(i)
      if (_key?.startsWith(SESSION_KEY_PREFIX) && _key !== SESSION_KEY) {
        window.localStorage.removeItem(_key)
      }
    }
  }, [])

  const { errors, handleSubmit } = formik

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={8} className={classes.bg}></Grid>
        <Box height="100vh" width="100%" clone>
          <Grid item xs={12} md={4} container alignItems="center" justify="center">
            <Grid item xs={12} container justify="center" spacing={2}>
              <Grid item xs={12}>
                <Topic variant="h3">SMART Best Buys Backoffice</Topic>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="username"
                  variant="outlined"
                  label="ชื่อผู้เข้าใช้"
                  error={errors.username !== undefined}
                  helperText={errors.username}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="password"
                  variant="outlined"
                  label="รหัสผ่าน"
                  error={errors.password !== undefined}
                  helperText={errors.password}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={showPassword ? 'ปิดรหัสผ่าน' : 'แสดงรหัสผ่าน'}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            <Visibility color={showPassword ? 'primary' : undefined} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button fullWidth variant="contained" color="primary" type="submit">
                  เข้าสู่ระบบ
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </form>
  )
}

export default LoginPage
