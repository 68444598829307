import { Grid } from '@material-ui/core'
import { SubmitForm } from '../../../shared-components'
import TransportPickupTaskDetailForm from './TransportPickupTaskDetailForm'
import TransportPickupTaskAssigneeUserForm from './TransportPickupTaskAssigneeUserForm'
import TransportPickupTaskCustomerShippingAddressForm from './TransportPickupTaskCustomerShippingInformationForm'
import TransportPickupTaskProduct from './TransportPickupTaskProductForm'
import { FormProvider, useForm } from 'react-hook-form'
import { TransportPickupTaskFormValues } from './TransportPickupTaskFormValues'
import { Form } from '../../../custom-components'
import {
  TransportTaskCreateDataV2_SaleOrderInfo,
  TransportTaskCreateDataV2_CustomerRequisitionInfo,
  TransportTaskCreateData_Document,
  TransportTaskCreateData_RelatedTask,
  TransportTaskCreateData_RelatedDocument,
  ICustomerProfile_Address,
} from '../../../api/smartbestbuys-api'
import {
  ShippingProviderData,
  ShippingCarData,
  UserData,
  AddressData,
  Province,
  District,
  SubDistrict,
} from '../../../api/smartbestbuys-api/data/data-types'

export interface TransportPickupTaskFormData {
  saleOrderInfo?: TransportTaskCreateDataV2_SaleOrderInfo
  customerRequisitionInfo?: TransportTaskCreateDataV2_CustomerRequisitionInfo
  documents: TransportTaskCreateData_Document[]
  relatedTasks: TransportTaskCreateData_RelatedTask[]
  relatedDocuments: TransportTaskCreateData_RelatedDocument[]
  customerShippingAddresses: ICustomerProfile_Address[]
  customerBillingAddresses: ICustomerProfile_Address[]
  shippingProviders: ShippingProviderData[]
  shippingCars: ShippingCarData[]
  users: UserData[]
  address: AddressData
}

interface Props {
  formData: TransportPickupTaskFormData
  defaultValues?: {
    dueDate?: Date
    messenger?: {
      id?: number
      name?: string
    }
    car?: {
      id?: number
      name?: string
    }
    remark?: string
    consignee?: string
    phoneNumber?: string
    customerShippingAddress?: {
      id?: number
      name?: string
      branchName?: string
      addressLine1?: string
      phoneNumber?: string
      faxNumber?: string
      province?: Province
      district?: District
      subDistrict?: SubDistrict
      postalCode?: string
      googleMapLink?: string
      provinceId?: number
      districtId?: number
      subDistrictId?: number
    }
    assigneeUserId?: number
  }
  onSubmit: (values: TransportPickupTaskFormValues) => void
  submitting?: boolean
}

const TransportPickupTaskForm: React.FC<Props> = ({ defaultValues, formData, onSubmit, submitting }) => {
  // form
  const formMethods = useForm<TransportPickupTaskFormValues>({
    defaultValues: {
      dueDate: defaultValues?.dueDate,
      messenger: defaultValues?.messenger,
      car: defaultValues?.car,
      consignee: defaultValues?.consignee,
      phoneNumber: defaultValues?.phoneNumber,
      remark: defaultValues?.remark,
      customerShippingAddress: {
        id: defaultValues?.customerShippingAddress?.id,
        name: defaultValues?.customerShippingAddress?.name,
        branchName: defaultValues?.customerShippingAddress?.branchName,
        addressLine1: defaultValues?.customerShippingAddress?.addressLine1,
        phoneNumber: defaultValues?.customerShippingAddress?.phoneNumber,
        faxNumber: defaultValues?.customerShippingAddress?.faxNumber,
        province: defaultValues?.customerShippingAddress?.province,
        district: defaultValues?.customerShippingAddress?.district,
        subDistrict: defaultValues?.customerShippingAddress?.subDistrict,
        postalCode: defaultValues?.customerShippingAddress?.postalCode,
        googleMapLink: defaultValues?.customerShippingAddress?.googleMapLink,
        provinceId: defaultValues?.customerShippingAddress?.provinceId,
        districtId: defaultValues?.customerShippingAddress?.districtId,
        subDistrictId: defaultValues?.customerShippingAddress?.subDistrictId,
      },
    },
  })
  const { setError, handleSubmit } = formMethods

  // validation
  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const validateValues = (values: TransportPickupTaskFormValues, triggerSubmit?: boolean): boolean => {
    let valid = true
    if (!values.customerShippingAddress) {
      setError('customerShippingAddress', {
        type: 'required',
        message: 'กรุณาเลือกที่อยู่สำหรับขนส่ง',
      })
      scrollTo('#customer-shipping-address-selector-box')
      valid = false
    }

    // if (valid && triggerSubmit) handleSubmit(values)
    return valid
  }

  // submit handler
  const handleSubmitFormValues = (values: TransportPickupTaskFormValues) => {
    if (!validateValues(values)) return

    onSubmit && onSubmit(values)
  }

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(handleSubmitFormValues)} {...formMethods}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TransportPickupTaskProduct formData={formData} />
          </Grid>
          <Grid item xs={12}>
            <TransportPickupTaskDetailForm formData={formData} />
          </Grid>
          <Grid item xs={12}>
            <TransportPickupTaskCustomerShippingAddressForm formData={formData} />
          </Grid>
          <Grid item xs={12}>
            <TransportPickupTaskAssigneeUserForm formData={formData} />
          </Grid>
          <Grid item xs={12}>
            <SubmitForm
              goBack
              submitText={!!defaultValues ? 'แก้ไขข้อมูลงานบริการรับของ' : 'ตรวจทานข้อมูลงานบริการรับของ'}
              cancelText="ยกเลิก"
              isSubmitting={submitting}
            />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default TransportPickupTaskForm
