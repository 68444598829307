import { Box, Card, CardMedia, createStyles, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import { AttachFile, Cancel } from '@material-ui/icons'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { SubTopic } from '../../../../../../custom-components'
import { SOAttachment } from '../SOUpdationFormValues'
import SOUpdationFormValue from '../SOUpdationFormValues'
import { compressImage, fileToBase64 } from '../../../../../../functions'
import _ from 'lodash'
import { SQLCommand } from '../../../../../../api/smartbestbuys-api/sale/update-sale-order-api'

const DEFINE_LIMIT = 2

interface Props {
  readOnly?: boolean
}

const AttachmentArea: React.FC<Props> = (props) => {
  const { readOnly } = props
  const classes = useStyles()
  const { control, watch, setValue } = useFormContext<SOUpdationFormValue>()
  const { remove } = useFieldArray<SOUpdationFormValue>({
    control,
    name: 'attachments',
  })

  const { attachments } = watch()

  const validAttachments = attachments?.filter((a) => a.command !== SQLCommand.DELETE)

  const hasAttachments = validAttachments && validAttachments.length > 0
  const overLimit = validAttachments && validAttachments.length >= DEFINE_LIMIT

  return (
    <Paper>
      <SubTopic gutterBottom>แนบไฟล์เพิ่มเติม</SubTopic>
      <Box display="flex" overflow="auto" gridRowGap={16} flexWrap="wrap">
        {!readOnly && !overLimit && (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mr={2} clone>
            <Card className={classes.uploadCard}>
              <input
                accept="image/*"
                className={classes.input}
                id="icon-button-file"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  const fileList = e.target.files
                  if (fileList) {
                    const newAttachments: SOAttachment[] = await Promise.all(
                      Array.from(fileList).map(async (file) => {
                        const compressed = await compressImage(file, 0.5)
                        const compressed64 = await fileToBase64(compressed)
                        return {
                          id: 0,
                          name: file.name,
                          src: compressed64,
                          command: SQLCommand.CREATE,
                        }
                      }),
                    )
                    if (attachments && attachments.length > 0) {
                      const newFiles = _.uniqBy([...attachments, ...newAttachments], 'name')
                      setValue('attachments', [...newFiles])
                      return
                    }
                    setValue('attachments', newAttachments)
                  }
                }}
                type="file"
              />
              <label htmlFor="icon-button-file">
                <IconButton aria-label="upload picture" component="span">
                  <AttachFile style={{ fontSize: 60 }} />
                </IconButton>
              </label>
              <Typography color="primary">เลือกไฟล์ภาพที่ต้องการ</Typography>
            </Card>
          </Box>
        )}
        {hasAttachments &&
          attachments?.map((file, index) => {
            if (file.command === SQLCommand.DELETE) return <></>
            return (
              <Box key={file.name} mr={2} clone>
                <Card variant="outlined" className={classes.card}>
                  {!readOnly && (
                    <IconButton
                      className={classes.mediaRemoveButton}
                      aria-label="remove picture"
                      onClick={() => {
                        let updatedAttachments = attachments
                        const target = updatedAttachments[index]
                        const isNew = target.command === SQLCommand.CREATE
                        if (isNew) {
                          remove(index)
                        } else {
                          setValue(`attachments.${index}`, {
                            ...target,
                            command: SQLCommand.DELETE,
                          })
                        }
                      }}
                    >
                      <Cancel fontSize="large" />
                    </IconButton>
                  )}
                  <CardMedia className={classes.media} image={file.src} title={file.name} />
                  <Typography color="primary">{file.name}</Typography>
                </Card>
              </Box>
            )
          })}
      </Box>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    uploadCard: {
      width: 160,
      height: 200,
      border: `1px dashed ${theme.palette.primary.main}`,
    },
    card: {
      width: 160,
      height: 200,
      padding: theme.spacing(2),
      position: 'relative',
    },
    media: {
      height: 0,
      paddingTop: '80%', // 16:9
    },
    mediaRemoveButton: {
      position: 'absolute',
      right: -12,
      top: -12,
    },
    input: {
      display: 'none',
    },
  }),
)

export default AttachmentArea
