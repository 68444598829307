import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, MenuItem, Typography } from '@material-ui/core'

import { UserAction } from '../../../enums'
import { SubmitForm, UserActivityDialog_TextOption } from '../../../shared-components'
import { finishShippingTaskAPI, FinishShippingTaskRequestBody } from '../../../api/smartbestbuys-api'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { FlexBox, Form, GeneralTextFieldForm, KeyboardDatePickerForm } from '../../../custom-components'
import { useForm } from 'react-hook-form'
import { useActionMessages } from '../../../hooks'
import { DateTime } from 'luxon'

interface Props extends DialogProps {
  data: { id: number }
  onSuccess?: (data: any) => void
  onFail?: () => void
}

interface Values {
  actionMessageId: number
  remark?: string
  nextShippingDate: Date | null
}

const ConfirmShippingFailureDialog: React.FC<Props> = (props: Props) => {
  // set up props & state
  const { data, onSuccess, onFail, onClose, ...dialogProps } = props

  //context
  const { triggerSnackbar } = useContext(RootContext)
  const messages = useActionMessages(UserAction.ProcessedFailureTask)

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<Values>()

  const { actionMessageId } = watch()

  const textOption: UserActivityDialog_TextOption = {
    title: 'ยืนยันการส่งของไม่สำเร็จ',
    submitText: 'ยืนยันจบงาน',
    cancelText: 'ย้อนกลับ',
    actionMessageText: 'สาเหตุของการส่งของไม่สำเร็จ',
  }

  const handleSubmitForm = async (values: Values) => {
    const { actionMessageId, remark, nextShippingDate } = values

    const requestBody: FinishShippingTaskRequestBody = {
      action: 2,
      userActivityInfo: {
        actionMessageId,
        remark,
      },
      nextShippingDate: nextShippingDate ? DateTime.fromJSDate(nextShippingDate).toFormat('yyyy-MM-dd') : undefined,
    }
    const response = await finishShippingTaskAPI(data.id, requestBody)
    if (response?.status === 200) {
      triggerSnackbar('จบงานสำเร็จ')
      if (!!onSuccess) onSuccess(response.data)
    } else {
      triggerSnackbar('จบงานไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="sm">
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <DialogTitle>{textOption?.title || 'หัวข้อ'}</DialogTitle>
        <DialogContent>
          <FlexBox flexDirection="column" gridGap={8}>
            {!!textOption?.description && (
              <Typography variant="body1" color="textSecondary">
                {textOption.description}
              </Typography>
            )}
            <GeneralTextFieldForm
              control={control}
              name="actionMessageId"
              select
              label={!!textOption?.actionMessageText ? textOption.actionMessageText + ' *' : 'เหตุผลในการกระทำ *'}
              rules={{ required: { value: true, message: 'กรุณาเลือกเหตุผล' } }}
              variant="outlined"
              defaultValue=""
            >
              {messages.map((message) => (
                <MenuItem key={message.id} value={message.id}>
                  {message.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
            <KeyboardDatePickerForm
              control={control}
              name="nextShippingDate"
              label="วันที่ต้องจัดส่งใหม่ (ถ้ามี)"
              value={null}
              defaultValue={null}
              size="small"
            />
            <GeneralTextFieldForm
              control={control}
              rules={{
                required: {
                  value: actionMessageId === 315,
                  message: textOption?.remarkErrorText ?? 'กรุณากรอกหมายเหตุ',
                },
              }}
              label="หมายเหตุ"
              name="remark"
              defaultValue=""
            />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText={textOption?.submitText}
            cancelText={textOption?.cancelText}
            onCancel={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ConfirmShippingFailureDialog
