import React, { useContext, useState } from 'react'
import { Grid, Paper, Button } from '@material-ui/core'
import { Title, FlexBox } from '../../../../../../custom-components'
import { Edit } from '@material-ui/icons'
import ProductProfileContext from '../../ProductProfileContext'
import { MultipleConditionTooltip, StockCard } from '../../../../../../shared-components'
import { ProductEditStockDialog } from '../ProductEditStockDialog'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { ProductType } from '../../../../../../enums'

const ProductProfileStock: React.FC = () => {
  const { productProfile } = useContext(ProductProfileContext)
  const [openProductStockDialog, setOpenProductStockDialog] = useState<boolean>(false)
  const {
    reservedAmount,
    stockAmount,
    incomingAmount,
    soReservedAmount,
    withdrawnAmount,
    storage,
    unitLocal,
    recDeletedAt,
  } = productProfile

  const stockManagePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_StockManage)

  const remainingStockAmount = stockAmount - reservedAmount
  const afterReservedAmount = stockAmount - soReservedAmount

  const isProductAvailable = !recDeletedAt

  const isService = productProfile.type.id === ProductType.Service

  // condition items
  const updateConditionItems = [
    { showTooltip: !stockManagePermission.hasPermission, message: stockManagePermission.alertMessage },
    { showTooltip: reservedAmount > 0, message: 'ไม่สามารถแก้ไขสต๊อกได้เนื่องจากมีสินค้าที่กำลังส่งอยู่' },
  ]

  return (
    <Paper style={{ height: '100%' }}>
      {openProductStockDialog && (
        <ProductEditStockDialog
          open={openProductStockDialog}
          onClose={() => {
            setOpenProductStockDialog(false)
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox alignItems="center" justifyContent="space-between">
            <Title>ข้อมูลสต๊อก</Title>
            {isProductAvailable && !isService && (
              <MultipleConditionTooltip conditionItems={updateConditionItems}>
                <Button
                  color="primary"
                  startIcon={<Edit />}
                  onClick={() => setOpenProductStockDialog(true)}
                  disabled={!stockManagePermission.hasPermission || reservedAmount > 0}
                >
                  แก้ไขจำนวนสต็อกคงเหลือในคลัง
                </Button>
              </MultipleConditionTooltip>
            )}
          </FlexBox>
        </Grid>
        {!isService && (
          <>
            {/* <Grid item xs={12} md={6}>
              <StockCard
                label="จำนวนสินค้าที่พร้อมขาย"
                detail={Math.max(readyToSellAmount, 0)}
                unit={unitLocal}
                color="success.main"
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <StockCard
                label="จำนวนสินค้าคงเหลือในสต๊อก"
                detail={Math.max(remainingStockAmount, 0)}
                unit={unitLocal}
                color="primary.main"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StockCard
                label="จำนวนสินค้าที่กำลังส่ง"
                detail={Math.max(reservedAmount, 0)}
                unit={unitLocal}
                color="secondary.main"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StockCard
                label="จำนวนสินค้าที่กำลังเข้า"
                detail={Math.max(incomingAmount, 0)}
                unit={unitLocal}
                color="error.main"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <StockCard
            label={`จำนวน${!isService ? 'สินค้า' : 'บริการ'}ที่ถูกเปิด SO`}
            detail={Math.max(soReservedAmount, 0)}
            unit={unitLocal}
            color="error.main"
          />
        </Grid>
        {!isService && (
          <>
            <Grid item xs={12} md={6}>
              <StockCard
                label="จำนวนสินค้าที่พร้อมขาย"
                detail={Math.max(afterReservedAmount, 0)}
                unit={unitLocal}
                color="success.main"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StockCard
                label={`จำนวนสินค้าที่ถูกยืม`}
                detail={Math.max(withdrawnAmount, 0)}
                unit={unitLocal}
                color="error.main"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StockCard label="พื้นที่เก็บสินค้า" detail={storage?.name || '-'} color="info.main" />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

export default ProductProfileStock
