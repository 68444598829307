/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { IProduct, IProductChangeData } from '../../../../../api/smartbestbuys-api'

import './ProductReviewTableData.less'
import { formatNumber } from '../../../../../functions/number-utils'
import { formatSizeMMText, formatWeightGramText } from '../../../../../functions'

interface Props {
  previewData: IProductChangeData
}

type ProductLabelKeys = keyof Pick<
  IProduct,
  | 'nameLocal'
  | 'nameEn'
  | 'unitLocal'
  | 'unitEn'
  | 'businessUnit'
  | 'category'
  | 'subCategory'
  | 'warrantyPeriodDay'
  | 'storage'
  | 'imageUrl'
  | 'leadTime'
  | 'safetyStockPolicy'
  | 'dealerPrice'
  | 'brand'
  | 'color'
  | 'material'
  | 'widthInMm'
  | 'heightInMm'
  | 'depthInMm'
  | 'weightInGram'
  | 'warrantyCondition'
  | 'karajicMatrix'
>

const labels: Record<ProductLabelKeys, string> = {
  nameLocal: 'ชื่อสินค้า (ภาษาไทย)',
  nameEn: 'ชื่อสินค้า (English)',
  unitLocal: 'หน่วย (ภาษาไทย)',
  unitEn: 'หน่วย (English)',
  businessUnit: 'กลุ่มธุรกิจ',
  category: 'หมวดหมู่สินค้า',
  subCategory: 'หมวดหมู่ย่อย',
  warrantyPeriodDay: 'ระยะเวลาประกัน',
  storage: 'พื้นที่เก็บสินค้า',
  imageUrl: 'รูปสินค้า',
  leadTime: 'ระยะเวลาการสั่งเฉลี่ย',
  safetyStockPolicy: 'นโยบายเก็บ',
  dealerPrice: 'ราคาสำหรับ dealer',
  brand: 'ยี่ห้อ',
  color: 'สี',
  material: 'วัสดุ',
  widthInMm: 'กว้าง',
  heightInMm: 'สูง',
  depthInMm: 'ยาว',
  weightInGram: 'น้ำหนัก',
  warrantyCondition: 'เงื่อนไขการรับประกัน',
  karajicMatrix: 'karajic matrix',
}

const ProductReservedListTable: React.FC<Props> = (props) => {
  const { rawData, requestChangedData } = props.previewData
  console.log('rawData : ', rawData)
  console.log('requestChangedData : ', requestChangedData)
  const product = [
    {
      label: labels.imageUrl,
      value: rawData.imageUrl,
      newValue: requestChangedData.imageUrl,
      type: 'image',
    },
    {
      label: labels.nameLocal,
      value: rawData.nameLocal,
      newValue: requestChangedData.nameLocal,
      type: 'text',
    },
    {
      label: labels.nameEn,
      value: rawData.nameEn,
      newValue: requestChangedData.nameEn,
      type: 'text',
    },
    {
      label: labels.unitLocal,
      value: rawData?.unitLocal,
      newValue: requestChangedData.unitLocal,
      type: 'text',
    },
    {
      label: labels.unitEn,
      value: rawData.unitEn,
      newValue: requestChangedData.unitEn,
      type: 'text',
    },
    {
      label: labels.businessUnit,
      value: rawData.businessUnit?.name,
      newValue: requestChangedData.businessUnit?.name,
      type: 'text',
    },
    {
      label: labels.category,
      value: rawData.category?.name,
      newValue: requestChangedData.category?.name,
      type: 'text',
    },
    {
      label: labels.subCategory,
      value: rawData.subCategory?.name,
      newValue: requestChangedData.subCategory?.name,
      type: 'text',
    },
    {
      label: labels.warrantyPeriodDay,
      value: rawData.warrantyPeriodDay ? `${rawData.warrantyPeriodDay} วัน` : undefined,
      newValue: requestChangedData.warrantyPeriodDay ? `${requestChangedData.warrantyPeriodDay} วัน` : undefined,
      type: 'text',
    },
    {
      label: labels.storage,
      value: rawData.storage?.name,
      newValue: requestChangedData.storage?.name,
      type: 'text',
    },
    {
      label: labels.leadTime,
      value: `${rawData.leadTime} วัน`,
      newValue: requestChangedData?.leadTime ? `${requestChangedData.leadTime} วัน` : undefined,
      type: 'text',
    },
    {
      label: labels.safetyStockPolicy,
      value: `${rawData.safetyStockPolicy} วัน`,
      newValue: requestChangedData?.safetyStockPolicy ? `${requestChangedData.safetyStockPolicy} วัน` : undefined,
      type: 'text',
    },
    {
      label: labels.dealerPrice,
      value: rawData.dealerPrice ? formatNumber(rawData.dealerPrice) : undefined,
      newValue: requestChangedData.dealerPrice ? formatNumber(requestChangedData.dealerPrice) : undefined,
      type: 'text',
    },
    {
      label: labels.brand,
      value: rawData.brand,
      newValue: requestChangedData.brand,
      type: 'text',
    },
    {
      label: labels.color,
      value: rawData.color,
      newValue: requestChangedData.color,
      type: 'text',
    },
    {
      label: labels.material,
      value: rawData.material,
      newValue: requestChangedData.material,
      type: 'text',
    },
    {
      label: labels.widthInMm,
      value: rawData.widthInMm ? formatSizeMMText(rawData.widthInMm) : undefined,
      newValue: requestChangedData.widthInMm ? formatSizeMMText(requestChangedData.widthInMm) : undefined,
      type: 'text',
    },
    {
      label: labels.heightInMm,
      value: rawData.heightInMm ? formatSizeMMText(rawData.heightInMm) : undefined,
      newValue: requestChangedData.heightInMm ? formatSizeMMText(requestChangedData.heightInMm) : undefined,
      type: 'text',
    },
    {
      label: labels.depthInMm,
      value: rawData.depthInMm ? formatSizeMMText(rawData.depthInMm) : undefined,
      newValue: requestChangedData.depthInMm ? formatSizeMMText(requestChangedData.depthInMm) : undefined,
      type: 'text',
    },
    {
      label: labels.weightInGram,
      value: rawData.weightInGram ? formatWeightGramText(rawData.weightInGram) : undefined,
      newValue: requestChangedData.weightInGram ? formatWeightGramText(requestChangedData.weightInGram) : undefined,
      type: 'text',
    },
    {
      label: labels.warrantyCondition,
      value: rawData.warrantyCondition,
      newValue: requestChangedData.warrantyCondition,
      type: 'text',
    },
    {
      label: labels.karajicMatrix,
      value: rawData.karajicMatrix?.name,
      newValue: requestChangedData.karajicMatrix?.name,
      type: 'text',
    },
  ]
  console.log('productMap', product)
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <table className="review-table">
            <thead>
              <tr>
                <th className="review-table__title-name-data">ชื่อข้อมูล</th>
                <th className="review-table__title-present-data">ข้อมูลปัจจุบัน</th>
                <th className="review-table__title-changed-data">ข้อมูลที่แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              {product.map((p) => {
                const isChangedData = p.value !== p.newValue
                return (
                  <tr key={p.value}>
                    <td className="review-table__name-data">
                      <Typography variant="body1">{p.label}</Typography>
                    </td>
                    <td className={isChangedData ? 'review-table__present-data--alert' : 'review-table__present-data'}>
                      {p.type === 'image' && !!p.value ? (
                        <img src={p.value} className="h-20 w-auto" />
                      ) : (
                        <Typography key={p.value} variant="body1">
                          {p.value || '-'}
                        </Typography>
                      )}
                    </td>
                    <td className={isChangedData ? 'review-table__changed-data--alert' : 'review-table__changed-data'}>
                      {p.type === 'image' && !!p.newValue ? (
                        <img src={p.newValue} className="h-20 w-auto" />
                      ) : (
                        <Typography key={p.newValue} variant="body1">
                          {p.newValue || '-'}
                        </Typography>
                      )}
                    </td>
                  </tr>
                )
              }, {})}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductReservedListTable
