import { Box, Grid, IconButton, Paper, Tooltip } from '@material-ui/core'
import { useContext, useState } from 'react'
import { RootContext } from '../../..'
import { CustomerRequisition } from '../../../api/smartbestbuys-api'
import { FilteredTable, Link, Topic, withLayout } from '../../../custom-components'
import {
  CRReceiveProductStatus,
  CRShippingProductStatus,
  CustomerRequisitionStatus,
  CustomerRequisitionType,
  PageName,
} from '../../../enums'
import { dateTimeFormat } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { useCustomerRequisitions } from '../../../hooks'
import { CRStatusChip, CRShippingProductStatusIcon, CRReceiveProductStatusIcon } from '../../../shared-components'
import './CustomerRequisitionListPage.less'

const CustomerRequisitionListPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION_LIST)

  // tab value is related with customer requisition status
  const [tabValue, setTabValue] = useState<CustomerRequisitionStatus>(CustomerRequisitionStatus.PendingToReview)

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const customerRequisitions = useCustomerRequisitions()

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>รายการคำร้องของลูกค้า</Topic>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<CustomerRequisition>
              color="primary.dark"
              columns={[
                {
                  name: 'สถานะคำร้อง',
                  render: ({ status }) => <CRStatusChip icon status={status.id} label={status.name} />,
                  fixedWidth: 150,
                },
                {
                  name: 'เลขที่คำร้อง',
                  dataIndex: 'code',
                  render: ({ id, code }) => (
                    <Link href={`/customer-requisitions/${id}/profile`} target="_blank" variant="body2" color="primary">
                      {code}
                    </Link>
                  ),
                  fixedWidth: 100,
                  sort: true,
                },
                {
                  name: 'เลขที่ใบสั่งขายอ้างอิง',
                  dataIndex: 'id',
                  render: ({ saleOrder }) => (
                    <Link
                      href={`/sale-orders/${saleOrder.id}/profile`}
                      target="_blank"
                      variant="body2"
                      color="secondary"
                    >
                      {saleOrder.code}
                    </Link>
                  ),
                  fixedWidth: 100,
                  sort: true,
                },
                {
                  name: 'ประเภท',
                  render: ({ type }) => type.name,
                  fixedWidth: 50,
                },

                {
                  name: 'สถานะการรับ/ส่งคืนสินค้า',
                  render: ({ receiveStatus, shippingStatus }) => (
                    <div className="customer-requisition-task-icon-group">
                      {!!receiveStatus && (
                        <Tooltip title={receiveStatus.name}>
                          <IconButton>
                            <CRReceiveProductStatusIcon status={receiveStatus.id} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!!shippingStatus && (
                        <Tooltip title={shippingStatus.name}>
                          <IconButton>
                            <CRShippingProductStatusIcon status={shippingStatus.id} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  ),
                  fixedWidth: 150,
                  hide: tabValue !== CustomerRequisitionStatus.InProgress,
                },
                {
                  name: 'ชื่อลูกค้า',
                  render: ({ customer }) => customer.name,
                },

                {
                  name: 'วันที่สร้าง',
                  dataIndex: 'recCreatedAt',
                  render: ({ recCreatedAt }) => dateTimeFormat(new Date(recCreatedAt)),
                  fixedWidth: 150,
                  sort: true,
                },
              ]}
              cleanSpace
              data={customerRequisitions.filter((cr) => cr.status.id === tabValue)}
              sortable={true}
              tabOption={{
                tabs: [
                  {
                    label: `รอตรวจสอบ (${formatNumberInt(
                      customerRequisitions.filter((cr) => cr.status.id === CustomerRequisitionStatus.PendingToReview)
                        .length,
                    )})`,
                    value: CustomerRequisitionStatus.PendingToReview,
                  },
                  {
                    label: 'อนุมัติและรอตกลงเงื่อนไข',
                    value: CustomerRequisitionStatus.Approved,
                  },
                  {
                    label: 'กำลังดำเนินการ',
                    value: CustomerRequisitionStatus.InProgress,
                  },
                  {
                    label: 'จบงาน',
                    value: CustomerRequisitionStatus.Done,
                  },
                  {
                    label: 'ไม่อนุมัติ',
                    value: CustomerRequisitionStatus.Rejected,
                  },
                  {
                    label: 'ยกเลิก',
                    value: CustomerRequisitionStatus.Canceled,
                  },
                ],
                value: tabValue,
                onChange: handleTabChange,
              }}
              disableDateRangeFilter={true}
              filters={[
                {
                  name: 'ประเภท',
                  values: [
                    {
                      name: 'เคลม',
                      value: CustomerRequisitionType.Claim,
                    },
                    {
                      name: 'คืน',
                      value: CustomerRequisitionType.CancelSO,
                    },
                  ],
                  filter: (data, values) => values.includes(data.type.id),
                },
                {
                  name: 'สถานะการรับสินค้า',
                  values: [
                    {
                      name: 'รอรับสินค้าคืน',
                      value: CRReceiveProductStatus.Pending,
                    },
                    {
                      name: 'กำลังรับสินค้าคืน',
                      value: CRReceiveProductStatus.InProgress,
                    },
                    {
                      name: 'รับสินค้าคืนเรียบร้อย',
                      value: CRReceiveProductStatus.Done,
                    },
                  ],
                  filter: (data, values) => (!!data.receiveStatus ? values.includes(data.receiveStatus.id) : false),
                },
                {
                  name: 'สถานะการส่งคืนสินค้า',
                  values: [
                    {
                      name: 'รอส่งสินค้าคืน',
                      value: CRShippingProductStatus.Pending,
                    },
                    {
                      name: 'กำลังส่งสินค้าคืน',
                      value: CRShippingProductStatus.InProgree,
                    },
                  ],
                  filter: (data, values) => (!!data.shippingStatus ? values.includes(data.shippingStatus.id) : false),
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(CustomerRequisitionListPage)
