import React, { FC } from 'react';
import { Text } from '@react-pdf/renderer';

const PDFPagination: FC = (_) => (
  <Text render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)}
    fixed
    style={{ textAlign: 'right', position: 'absolute', bottom: 10, left: 20 }}
  />
);

export default PDFPagination;