import React, { FC } from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { dateFormat } from '../../functions'

interface Props {
  title: string
  endorser?: string
  width?: number
  date?: Date
  english?: boolean
  titlePaddingBottom?: number
}

const styles = StyleSheet.create({
  signerName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    paddingBottom: 4,
  },
  dotLine: {
    borderBottom: 1,
    borderBottomStyle: 'dotted',
    paddingBottom: 8,
    paddingTop: 4,
    alignSelf: 'center',
  },
  date: {
    alignSelf: 'center',
  },
  dateValue: {
    letterSpacing: 0.5,
  },
})

const SignatureView: FC<Props> = ({ title, width, date, english, titlePaddingBottom, endorser }) => (
  <>
    <Text style={{ paddingBottom: titlePaddingBottom || 16, width: '100%', textAlign: 'center' }}>{title}</Text>

    <Text style={{ ...styles.dotLine, width: width ?? 100 }}></Text>

    <View
      style={{
        ...styles.signerName,
        width: width ?? 100,
      }}
    >
      <Text>(</Text>
      {endorser && <Text>{endorser}</Text>}
      <Text>)</Text>
    </View>

    <Text style={styles.date}>
      {english ? '' : 'วันที่ '}
      {date ? <Text style={styles.dateValue}>{dateFormat(date, 'dd/MM/yyyy')}</Text> : '......./......./...........'}
    </Text>
  </>
)

export default SignatureView
