import {
  ProductUpdationRequest,
  RequestToUpdateProductRequestBody,
  requestToUpdateProductInformationAPI,
  updateProductInformationAPI,
} from '../../../../../../api/smartbestbuys-api'
import ProductInformationFormValue from './ProductUpdateFormValues'

const parseProductUpdationFormValue = (value: ProductInformationFormValue): ProductUpdationRequest => {
  return {
    headerId: value.headerId,
    nameLocal: value.productNameTh,
    nameEn: value?.productNameEn,
    unitLocal: value.productUnitTh,
    unitEn: value?.productUnitEn,
    businessUnitId: value?.businessId ? Number(value?.businessId) : undefined,
    categoryId: value?.categoryProductId ? Number(value.categoryProductId) : undefined,
    subCategoryId: value?.subCategoryProductId ? Number(value.subCategoryProductId) : undefined,
    warrantyPeriodDay: value.productWarranty,
    storageId: value?.storageProductId ? Number(value.storageProductId) : undefined,
    productImageBase64: value?.productImageBase64,
    imageUrl: value?.imageUrl,
    leadTime: value.leadTime,
    safetyStockPolicy: value.safetyStockPolicy,
    dealerPrice: value?.dealerPrice,
    brand: value.brand,
    color: value.color,
    material: value.material,
    widthInMm: value?.widthInMm ? value.widthInMm * value.unitSizeId : 0,
    heightInMm: value?.heightInMm ? value.heightInMm * value.unitSizeId : 0,
    depthInMm: value?.depthInMm ? value.depthInMm * value.unitSizeId : 0,
    weightInGram: value?.weightInGram ? value.weightInGram * value.unitWeightId : 0,
    warrantyCondition: value.warrantyCondition,
    karajicMatrixId: value.karajicMatrixId,
  }
}

export const updateProductInformation = (value: ProductInformationFormValue) => {
  const parseFormValue = parseProductUpdationFormValue(value)
  return updateProductInformationAPI(value.id, parseFormValue)
}

const parseProductRequestToUpdateFormValue = (
  value: ProductInformationFormValue,
): RequestToUpdateProductRequestBody => {
  return {
    nameLocal: value.productNameTh,
    nameEn: value?.productNameEn,
    unitLocal: value.productUnitTh,
    unitEn: value?.productUnitEn,
    businessUnitId: value?.businessId ? Number(value?.businessId) : null,
    categoryId: value?.categoryProductId ? Number(value.categoryProductId) : null,
    subCategoryId: value?.subCategoryProductId ? Number(value.subCategoryProductId) : null,
    warrantyPeriodDay: value.productWarranty,
    storageId: value?.storageProductId ? Number(value.storageProductId) : null,
    productImageBase64: value?.productImageBase64,
    imageUrl: value?.imageUrl,
    leadTime: value.leadTime,
    safetyStockPolicy: value.safetyStockPolicy,
    dealerPrice: value?.dealerPrice,
    brand: value.brand,
    color: value.color,
    material: value.material,
    widthInMm: value?.widthInMm ? value.widthInMm * value.unitSizeId : 0,
    heightInMm: value?.heightInMm ? value.heightInMm * value.unitSizeId : 0,
    depthInMm: value?.depthInMm ? value.depthInMm * value.unitSizeId : 0,
    weightInGram: value?.weightInGram ? value.weightInGram * value.unitWeightId : 0,
    warrantyCondition: value.warrantyCondition,
    karajicMatrixId: value.karajicMatrixId ? value.karajicMatrixId : null,
  }
}

export const requestToUpdateProductInformation = (value: ProductInformationFormValue) => {
  const parseFormValue = parseProductRequestToUpdateFormValue(value)
  return requestToUpdateProductInformationAPI(value.id, parseFormValue)
}
