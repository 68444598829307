import { Box, Paper, Switch, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { useState } from 'react'
import { UserProfile } from '../../../api/smartbestbuys-api'
import { FlexBox, Grid as CustomGrid, SubTopic } from '../../../custom-components'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import { UserActivationUpdateDialog } from '../update'

const GreenSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#32de84',
    '&:hover': {
      backgroundColor: '#32de84',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#32de84',
  },
}))

interface Props {
  userProfile: UserProfile
  readOnly?: boolean
  onUpdate?: (user: UserProfile) => void
}

const UserActivationCard: React.FC<Props> = (props) => {
  const { userProfile, readOnly, onUpdate } = props
  const [openActivationForm, setOpenActivationForm] = useState(false)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.HR_User_Write)

  return (
    <>
      <UserActivationUpdateDialog
        open={openActivationForm}
        userProfile={userProfile}
        onComplete={(task) => {
          setOpenActivationForm(false)
          if (onUpdate) onUpdate(task)
        }}
        onClose={() => {
          setOpenActivationForm(false)
        }}
      />
      <Paper>
        <FlexBox justifyContent="space-between">
          <CustomGrid item xs={4}>
            <SubTopic variant="h5">สถานะการใช้งาน</SubTopic>
          </CustomGrid>
          <CustomGrid item xs={3}>
            <FlexBox justifyContent="end">
              <CustomGrid item xs={6} alignItems="center">
                {userProfile.enabled ? (
                  <Typography variant="body2" style={{ color: '#32de84' }}>
                    <Box paddingTop={1}>เปิดการใช้งานอยู่</Box>
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    <Box paddingTop={1}>ปิดการใช้งานอยู่</Box>
                  </Typography>
                )}
              </CustomGrid>
              {!readOnly && (
                <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
                  <GreenSwitch
                    checked={userProfile.enabled}
                    disabled={!writePermission.hasPermission}
                    onClick={() => setOpenActivationForm(true)}
                  />
                </ConditionTooltip>
              )}
            </FlexBox>
          </CustomGrid>
        </FlexBox>
      </Paper>
    </>
  )
}

export default UserActivationCard
