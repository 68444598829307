import { errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { UserActivityInfoRequest } from '../user-activity'

export const cancelSaleOrderAPI = errorSingleHandler<{ id: number }>(
  async (id: number, request: UserActivityInfoRequest) => {
    const path = `/sale-orders/${id}/cancel`
    return await smartAuthAPI.patch(path, request)
  },
)

export const requestToCancelSaleOrderAPI = errorSingleHandler<{ id: number }>(
  async (id: number, request: UserActivityInfoRequest) => {
    return smartAuthAPI.post(`/sale-orders/${id}/request-to-cancel`, request)
  },
)
