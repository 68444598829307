import React, { useContext, useState } from 'react'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { FlexBox, Form, GeneralTextFieldForm, TypographyWithLabel } from '../../../custom-components'
import { ButtonRadios, SubmitForm, UploadImageCard } from '../../../shared-components'
import { paymentMethods } from '../../../types'
import { FormProvider, useForm } from 'react-hook-form'
import { useShippingProviderData } from '../../../hooks'
import { finishShippingTaskAPI, FinishShippingTaskRequestBody } from '../../../api/smartbestbuys-api'
import { formatNumber } from '../../../functions/number-utils'
import { RootContext } from '../../..'
import { ImageSource } from '../../../shared-components/UploadImageCard/UploadImageCard'

interface Values {
  chargeAmount?: number
  trackingNumber?: string
  shippingProviderId?: number
  payAmount?: number
  shippingPaymentCondition?: number
  paymentMethodId?: number
  remark?: string
  image1?: string
  image2?: string
  image3?: string
}

interface Props {
  data: {
    id: number
    shippingInfo: {
      useShippingProvider: boolean
      chargeAmount?: number
      shippingProvider?: string
      payShippingFeeBySmart?: boolean
    }
  }

  onSuccess?: (data: any) => void
  onFail?: () => void
}

const ConfirmShippingResultDialog: React.FC<Props & DialogProps> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { data, onSuccess, onFail, onClose, ...dialogProps } = props

  // state
  const [attachedImages, setAttachedImages] = useState<(Partial<ImageSource> & { id: number })[]>([
    { id: 1 },
    { id: 2 },
    { id: 3 },
  ])

  const { shippingInfo } = data

  const formMethod = useForm<Values>({
    defaultValues: {
      shippingPaymentCondition: shippingInfo.payShippingFeeBySmart ? 1 : 2,
    },
  })
  const {
    control,
    setValue,
    formState: { isSubmitting, errors },
    watch,
  } = formMethod

  const shippingProviders = useShippingProviderData()

  const { paymentMethodId, shippingPaymentCondition } = watch()

  const handleSubmit = async (values: Values) => {
    const requestBody: FinishShippingTaskRequestBody = {
      action: 1,
      shippingResult: {
        attachedImages: attachedImages.filter((a) => !!a.base64).map((a) => a.base64) as string[],
        remark: values.remark,
        paymentMethod: paymentMethods.find((pm) => pm.id === values.paymentMethodId),

        chargeAmount: values.chargeAmount,
        trackingNumber: values.trackingNumber,
        actualShippingProvider: shippingProviders?.find((sp) => sp.id === values.shippingProviderId),
        payAmount: values.shippingPaymentCondition === 1 ? values.payAmount : undefined,
      },
    }
    const response = await finishShippingTaskAPI(data.id, requestBody)
    if (response?.status === 200) {
      if (onSuccess) onSuccess(response.data)
      triggerSnackbar('จบงานสำเร็จ')
    } else {
      if (onFail) onFail()
      triggerSnackbar('จบงานไม่สำเร็จ')
    }
    return true
  }

  const shippingBySmart = !shippingInfo.useShippingProvider
  const isChargeCustomer = !!shippingInfo.chargeAmount

  const handleImageChange = (f: ImageSource & { id: number }, index: number) => {
    setAttachedImages((prev) => {
      prev[index] = f
      return [...prev]
    })
  }

  return (
    <Dialog {...dialogProps} maxWidth="md">
      <FormProvider {...formMethod}>
        <Form onSubmit={handleSubmit}>
          <DialogTitle>จบงานส่งของ</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {shippingBySmart ? (
                <>
                  <Grid item xs={12}>
                    <TypographyWithLabel
                      label="ยอดเงินที่ต้องเก็บ:"
                      color={isChargeCustomer ? 'primary' : 'textSecondary'}
                    >
                      {isChargeCustomer ? `${formatNumber(shippingInfo.chargeAmount!)} บาท` : 'ไม่ต้องเก็บเงิน'}
                    </TypographyWithLabel>
                  </Grid>
                  {isChargeCustomer && (
                    <>
                      <Grid item xs={6}>
                        <ButtonRadios
                          label="วิธีการชำระเงินของลูกค้า *"
                          items={paymentMethods}
                          value={paymentMethodId}
                          onChange={(id) => setValue('paymentMethodId', id)}
                        />
                        {errors.paymentMethodId?.message && (
                          <Typography variant="caption" color="error">
                            {errors.paymentMethodId?.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <GeneralTextFieldForm
                          control={control}
                          name="chargeAmount"
                          type="number"
                          label="จำนวนเงินที่เก็บจากลูกค้า *"
                          rules={{
                            required: 'กรุณากรอกจำนวนเงินที่เก็บลูกค้าได้',
                            max: shippingInfo.chargeAmount
                              ? { value: shippingInfo.chargeAmount, message: 'ไม่สามารถเก็บเงินเกินจากที่ระบุไว้' }
                              : undefined,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <TypographyWithLabel label="ขนส่งที่เลือกจากฝ่ายบริการ:" color="textSecondary">
                      {shippingInfo.shippingProvider}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="trackingNumber"
                      label="หมายเลขพัสดุ *"
                      rules={{ required: 'กรุณากรอกหมายเลขพัสดุ' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GeneralTextFieldForm
                      control={control}
                      name="shippingProviderId"
                      label="ขนส่งที่ใช้จริง *"
                      rules={{ required: 'กรุณาเลือกขนส่งที่ใช้' }}
                      select
                    >
                      {shippingProviders?.map((shippingProvider) => (
                        <MenuItem key={shippingProvider.id} value={shippingProvider.id}>
                          <div>
                            <div>{shippingProvider.name}</div>
                            {shippingProvider.detail && <div>({shippingProvider.detail})</div>}
                          </div>
                        </MenuItem>
                      ))}
                    </GeneralTextFieldForm>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonRadios
                      label="รูปแบบการเก็บเงิน *"
                      items={[
                        { id: 1, value: 1, name: 'ต้นทาง' },
                        { id: 2, value: 2, name: 'ปลายทาง' },
                      ]}
                      value={shippingPaymentCondition}
                      onValueChange={(value) => setValue('shippingPaymentCondition', value as number)}
                    />
                  </Grid>
                  {(!shippingPaymentCondition || shippingPaymentCondition === 1) && (
                    <Grid item xs={6}>
                      <GeneralTextFieldForm
                        type="number"
                        control={control}
                        name="payAmount"
                        label="ค่าขนส่ง *"
                        rules={{ required: 'กรุณากรอกค่าขนส่ง' }}
                      />
                    </Grid>
                  )}
                  {(!shippingPaymentCondition || shippingPaymentCondition === 1) && (
                    <Grid item xs={6}>
                      <ButtonRadios
                        label="วิธีชำระค่าขนส่ง *"
                        items={[
                          { id: 1, name: 'เงินสด' },
                          { id: 2, name: 'โอน' },
                        ]}
                        value={paymentMethodId}
                        onChange={(id) => setValue('paymentMethodId', id)}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={6}>
                <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ (ถ้ามี)" />
              </Grid>
              <Grid item xs={12}>
                <FlexBox justifyContent="center" gridGap={8}>
                  {attachedImages.map((attached, index) => {
                    return (
                      <UploadImageCard
                        uniqKey={attached.id}
                        defaultSrc={attached.base64}
                        title={`อัปโหลดรูปหลักฐาน *`}
                        onChange={(img) => handleImageChange({ ...attached, ...img }, index)}
                      />
                    )
                  })}
                </FlexBox>
                {attachedImages.length === 0 && <Typography color="error">** กรุณาเลือกรูปอย่างน้อย 1 รูป</Typography>}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitForm
              submitText="ยืนยันจบงาน"
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting || attachedImages.length === 0}
            />
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

export default ConfirmShippingResultDialog
