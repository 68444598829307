import { DialogProps, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { DateTime } from 'luxon'
import React, { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import {
  confirmTransportSubTaskAPI,
  finishTransportSubTaskAPI,
  TransportProductPreparingTask,
  TransportTaskStateV2_Item,
} from '../../../api/smartbestbuys-api'
import { noteTaskAsCancelAPI } from '../../../api/smartbestbuys-api/tasks/task-apis'
import { Avatar, FlexBox, Table } from '../../../custom-components'
import { TaskStatus } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'
import TransportSubTaskDialog, {
  ITransportSubTaskDialogData,
  ITransportSubTaskDialogOptions,
} from './TransportSubTaskDialog'

interface Props {
  data: TransportProductPreparingTask
  onSuccess?: (data: TransportProductPreparingTask) => void
  onFail?: () => void
}

const PrepareProductTaskDialog: React.FC<DialogProps & Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)

  const { data, onSuccess, onFail } = props

  // state
  const [submitting, setSubmitting] = useState(false)

  const subTaskData: ITransportSubTaskDialogData = {
    id: data.id,
    code: data.code,
    status: {
      id: data.status.id,
      name: data.status.name,
    },
    type: {
      id: data.type.id,
      name: data.type.name,
    },
    saleOrderId: data.parent.saleOrderInfo?.id,
    saleOrderCode: data.parent.saleOrderInfo?.code,
    customerName: data.parent.saleOrderInfo?.customerName,
    salesName: data.parent.saleOrderInfo?.createdBy,
    dueDate: data.parent.dueDate ? DateTime.fromJSDate(new Date(data.parent.dueDate)) : undefined,
    providerName: data.parent.state?.shippingInfo?.shippingProvider?.name,
    messenger: data.parent.state?.shippingInfo?.messengerInfo?.name,
    car: data.parent.state?.shippingInfo?.messengerInfo?.car?.name,
    useShippingProdiver: data.parent.state?.shippingInfo?.useShippingProvider,
    remark: data.parent.state?.shippingInfo?.remark,
    relatedPO: data.parent.relatedPO,
  }

  const submitText = useMemo(() => {
    switch (data.status.id) {
      case TaskStatus.Pending:
        return 'ยืนยันรับงาน'
      case TaskStatus.Processing:
        return 'ยืนยันเตรียมสินค้าเสร็จสิ้น'
      case TaskStatus.PreCancel:
        return 'รับทราบการยกเลิกงาน'
      default:
        return undefined
    }
  }, [data.status.id])

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      switch (data.status.id) {
        case TaskStatus.Pending:
          const confirmResponse = await confirmTransportSubTaskAPI({ taskId: data.parent.id, subTaskId: data.id })
          if (confirmResponse?.status === 200) {
            triggerSnackbar(`รับงานเลขที่ ${data.code} สำเร็จ`)
            if (onSuccess) onSuccess(confirmResponse.data)
          } else {
            triggerSnackbar(`รับงานเลขที่ ${data.code} ไม่สำเร็จ`)
            if (onFail) onFail()
            setSubmitting(false)
          }
          return true
        case TaskStatus.Processing:
          const finishResponse = await finishTransportSubTaskAPI({ taskId: data.parent.id, subTaskId: data.id })
          if (finishResponse?.status === 200) {
            triggerSnackbar(`เตรียมสินค้างานเลขที่ ${data.code} สำเร็จ`)
            if (onSuccess) onSuccess(finishResponse.data)
          } else {
            triggerSnackbar(`เตรียมสินค้างานเลขที่ ${data.code} ไม่สำเร็จ`)
            if (onFail) onFail()
            setSubmitting(false)
          }
          return true
        case TaskStatus.PreCancel:
          const notedResponse = await noteTaskAsCancelAPI(data.id)
          if (notedResponse?.status === 200) {
            triggerSnackbar(`ยกเลิกงานเลขที่ ${data.code} สำเร็จ`)
            // NOTE: note as cancel task is not return like subtask
            if (onSuccess) onSuccess(notedResponse.data as TransportProductPreparingTask)
          } else {
            triggerSnackbar(`ยกเลิกงานเลขที่ ${data.code} ไม่สำเร็จ`)
            if (onFail) onFail()
            setSubmitting(false)
          }
          return true
        default:
          if (onSuccess) onSuccess(data)
          return true
      }
    } catch (error) {
      console.log(error)
    }
  }

  const options: ITransportSubTaskDialogOptions = {
    submitFormProps: {
      confirmTextOption:
        data.status.id === TaskStatus.Processing
          ? {
              veifiedText: 'ยืนยัน',
            }
          : undefined,
      hideCancelButton: true,
      submitText,
      onSubmit: handleSubmit,
      submitPermission: PermissionEnum.Warehouse_Task_Write,
    },
  }

  return (
    <TransportSubTaskDialog {...props} data={subTaskData} options={options} submitting={submitting}>
      <Typography variant="subtitle2">รายละเอียดสินค้าที่ต้องเตรียม</Typography>
      <Table<TransportTaskStateV2_Item>
        color="primary.dark"
        size="small"
        cleanSpace
        pagination={false}
        columns={[
          { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70 },
          {
            name: 'ชื่อสินค้า/บริการ',
            render: ({ code, name, imageUrl }) => (
              <FlexBox>
                <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                  <Photo />
                </Avatar>
                <FlexBox ml={1} flexDirection="column">
                  <Typography variant="body2" color="textSecondary">
                    {code}
                  </Typography>
                  <Typography variant="body2">{name}</Typography>
                </FlexBox>
              </FlexBox>
            ),
          },
          {
            name: 'จำนวนที่จะส่ง',
            dataIndex: 'amount',
            numeric: true,
            format: '0,0',
          },
        ]}
        data={data.parent.state?.items.filter((item) => item.type === TransportTaskItemType.Product) ?? []}
      />
    </TransportSubTaskDialog>
  )
}

export default PrepareProductTaskDialog
