import React, { useContext, useMemo, useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, FlexBox, Link } from '../../../custom-components'
import { FetchPurchaseOrder } from '../../../api/smartbestbuys-api'
import { Box } from '@material-ui/core'
import { RootContext } from '../../..'
import { NavigationItemKey, PageName, PurchaseOrderStatus } from '../../../enums'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { usePurchaseOrders } from '../../../hooks'
import {
  AlertShippingDateWithVendorSignal,
  InformationTooltip,
  PurchaseOrderPaymentStatusChip,
  PurchaseOrderStatusChip,
  PurchaseRequisitionCancelSignal,
  SendToManagerCancelSignal,
  AlertDocumentSignal,
  NotificationHead,
} from '../../../shared-components'
import { dateFormat, dateTimeFormat } from '../../../functions'
import './PurchaseOrderListPage.less'
import { formatNumberInt } from '../../../functions/number-utils'
import lodash from 'lodash'

enum SpecialStatus {
  PRCanceled = 1,
  PendingLeaderHelp = 2,
  PendingShippingDate = 3,
  DocumentMissing = 4,
}

const PurchaseOrderListPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PURCHASE_ORDER)

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const rawData = usePurchaseOrders(startDate, endDate)

  const purchaseOrderStatuses = useMemo(() => {
    return lodash
      .chain(rawData)
      .uniqBy('status.id')
      .orderBy('status.id')
      .value()
      .map((po) => {
        return {
          name: po.status.name,
          value: po.status.id,
        }
      })
    // eslint-disable-next-line
  }, [rawData.map((po) => po.code).toString()])

  const purchaseOrderPaymentStatuses = useMemo(() => {
    return lodash
      .chain(rawData)
      .uniqBy('paymentStatus.id')
      .orderBy('paymentStatus.id')
      .value()
      .map((po) => {
        return {
          name: po.paymentStatus.name,
          value: po.paymentStatus.id,
        }
      })
    // eslint-disable-next-line
  }, [rawData.map((po) => po.code).toString()])

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการใบสั่งซื้อ</Topic>
          </FlexBox>
          <NotificationHead navigationKey={NavigationItemKey.PurchaseOrder} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<FetchPurchaseOrder>
              color="primary.dark"
              columns={[
                {
                  name: '',
                  render: ({ isPurchaseRequisitionCanceled, status, vendorShippingDate, total, documentTotal }) =>
                    ![PurchaseOrderStatus.Rejected, PurchaseOrderStatus.Canceled].includes(status.id) && (
                      <div className="signal-cell">
                        {isPurchaseRequisitionCanceled && (
                          <PurchaseRequisitionCancelSignal className="signal-cell__signal" />
                        )}
                        {status.id === PurchaseOrderStatus.LeaderPending && (
                          <SendToManagerCancelSignal className="signal-cell__signal" />
                        )}
                        {!vendorShippingDate && <AlertShippingDateWithVendorSignal className="signal-cell__signal" />}
                        {total > (documentTotal ?? 0) && <AlertDocumentSignal className="signal-cell__signal" />}
                      </div>
                    ),
                },
                {
                  name: 'สถานะใบสั่งซื้อ',
                  dataIndex: 'status',
                  render: ({ status }) => <PurchaseOrderStatusChip status={status.id} label={status.name} />,
                },
                {
                  name: 'สถานะการจ่ายเงิน',
                  dataIndex: 'paymentStatus',
                  render: ({ paymentStatus }) => (
                    <PurchaseOrderPaymentStatusChip status={paymentStatus.id} label={paymentStatus.name} />
                  ),
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                },
                {
                  name: 'วันที่ตกลงกับผู้ขาย',
                  render: ({ vendorShippingDate }) =>
                    vendorShippingDate ? (
                      dateFormat(vendorShippingDate)
                    ) : (
                      <Typography color="textSecondary">ยังไม่นัดหมาย</Typography>
                    ),
                },
                {
                  name: 'วันที่ลูกค้าต้องการ',
                  render: ({ purchaseRequisitionInfo }) =>
                    purchaseRequisitionInfo.dueDate ? (
                      dateFormat(purchaseRequisitionInfo.dueDate)
                    ) : (
                      <Typography color="textSecondary">ไม่ระบุ</Typography>
                    ),
                },
                {
                  name: 'เลขที่ใบสั่งซื้อ',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/purchase-orders/${id}/profile`}>
                      <Typography variant="body2">{code}</Typography>
                    </Link>
                  ),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  dataIndex: 'vendor',
                  render: ({ vendor }) => vendor.name,
                },
                { name: 'ยอดรวมสุทธิ', dataIndex: 'total', numeric: true, format: '0,0.00' },
                {
                  name: 'จำนวนเอกสานทางการเงินอ้างอิง',
                  dataIndex: 'documentAmount',
                  align: 'center',
                  render: ({ documentAmount }) => (!!documentAmount ? formatNumberInt(documentAmount) : '-'),
                },
                {
                  name: 'เลขที่ใบสั่งขายอ้างอิง',
                  dataIndex: 'saleOrderInfo',
                  render: ({ saleOrderInfo }) =>
                    saleOrderInfo ? (
                      <Link color="secondary" to={`/sale-orders/${saleOrderInfo.id}/profile`}>
                        <Typography variant="body2">{saleOrderInfo.code}</Typography>
                      </Link>
                    ) : (
                      '-'
                    ),
                },
                {
                  name: '',
                  render: ({ remark, purchaseRequisitionInfo, recCreatedBy, latestVerifiedBy }) => {
                    return (
                      <InformationTooltip
                        title={
                          <ul>
                            <li>เหตุผล: {!!remark ? remark : '-'} </li>
                            <li>PR อ้างอิง: {purchaseRequisitionInfo.code} </li>
                            <li>สร้างโดย: {recCreatedBy} </li>
                            <li>ตรวจสอบโดย: {!!latestVerifiedBy ? latestVerifiedBy : '-'} </li>
                          </ul>
                        }
                      />
                    )
                  },
                },
              ]}
              filters={[
                {
                  name: 'สถานะใบสั่งซื้อ',
                  values: purchaseOrderStatuses,
                  filter: (data: FetchPurchaseOrder, values: (string | number)[]) => values.includes(data.status.id),
                },
                {
                  name: 'สถานะการชำระเงิน',
                  values: purchaseOrderPaymentStatuses,
                  filter: (data: FetchPurchaseOrder, values: (string | number)[]) =>
                    values.includes(data.paymentStatus.id),
                },
                {
                  name: 'สถานะพิเศษ',
                  values: [
                    {
                      name: 'คำขอซื้อถูกยกเลิก',
                      value: SpecialStatus.PRCanceled,
                    },
                    {
                      name: 'ให้หัวหน้าช่วยจัดการ',
                      value: SpecialStatus.PendingLeaderHelp,
                    },
                    {
                      name: 'ยังไม่ได้นัดหมายผู้ขาย',
                      value: SpecialStatus.PendingShippingDate,
                    },
                    {
                      name: 'ยังไม่อัปโหลดเอกสาร',
                      value: SpecialStatus.DocumentMissing,
                    },
                  ],
                  filter: (data: FetchPurchaseOrder, values: (string | number)[]) => {
                    const { isPurchaseRequisitionCanceled, status, vendorShippingDate, total, documentTotal } = data
                    if ([PurchaseOrderStatus.Rejected, PurchaseOrderStatus.Canceled].includes(status.id)) return false
                    return (
                      (values.includes(SpecialStatus.PRCanceled) && isPurchaseRequisitionCanceled) ||
                      (values.includes(SpecialStatus.PendingLeaderHelp) &&
                        status.id === PurchaseOrderStatus.LeaderPending) ||
                      (values.includes(SpecialStatus.PendingShippingDate) && !vendorShippingDate) ||
                      (values.includes(SpecialStatus.DocumentMissing) && total > (documentTotal ?? 0))
                    )
                  },
                },
              ]}
              sortable
              data={rawData}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              cleanSpace
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(PurchaseOrderListPage)
