import {
  Backdrop,
  Button,
  Fade,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { Avatar, Title } from '../../../../custom-components'
import { Photo } from '@material-ui/icons'
import { useFormContext } from 'react-hook-form'
import { PWTicketFormValues, PWTicketFormValuesProductInfo } from './PWTicketFormValues'
import numeral from 'numeral'
import { useState } from 'react'

interface Props {
  open: boolean
  onClose: () => void
  selectedProduct: PWTicketFormValuesProductInfo
}

const PWTicketProductModal: React.FC<Props> = ({ open, onClose, selectedProduct }) => {
  const classes = PWTicketProductModalStyle()

  const { code, nameLocal, imageUrl, unitLocal, stockAmount, reservedAmount } = selectedProduct

  const { watch, setValue, clearErrors } = useFormContext<PWTicketFormValues>()

  const { products } = watch()

  // state
  const [amount, setAmount] = useState<number | undefined>(undefined)

  const handleClick = () => {
    if (!amount) return

    if (products) {
      const index = products.findIndex((p) => p.id === selectedProduct.id)
      const clone = [...products]

      if (index !== -1) {
        clone[index].requestToWithdrawnAmount = amount
        setValue('products', clone)
      } else {
        setValue('products', [
          ...clone,
          {
            ...selectedProduct,
            requestToWithdrawnAmount: amount,
          },
        ])
      }
    } else {
      setValue('products', [
        {
          ...selectedProduct,
          requestToWithdrawnAmount: amount,
        },
      ])
    }

    clearErrors('products')
    onClose()
  }

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={open}>
        <Grid container className={classes.grid} spacing={2}>
          <Grid item xs={12}>
            <Title>เลือกจำนวน</Title>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item sm={12} md={2}>
              <Avatar variant="rounded" className={classes.avatar} src={imageUrl} objectFit="contain">
                <Photo />
              </Avatar>
            </Grid>
            <Grid item sm={12} md={7}>
              <Typography variant="subtitle2" component="div" gutterBottom>
                {code}
              </Typography>
              <Typography variant="body2" component="div" gutterBottom>
                {nameLocal}
              </Typography>
              <Typography variant="body1" component="div" color="secondary" gutterBottom>
                เหลือ {numeral(stockAmount - reservedAmount).format('0,0')}
              </Typography>
            </Grid>
            <Grid item sm={12} md={3} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="product"
                  variant="outlined"
                  size="small"
                  type="number"
                  label="จำนวน"
                  onChange={(e) => {
                    setAmount(e.target.value ? Number(e.target.value) : undefined)
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{unitLocal || 'ชิ้น'}</InputAdornment>,
                    inputProps: { min: 1 },
                  }}
                  defaultValue={selectedProduct.requestToWithdrawnAmount}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button color="primary" onClick={onClose} style={{ marginRight: 8 }}>
              ยกเลิก
            </Button>
            <Button variant="contained" color="primary" disabled={!amount || amount === 0} onClick={handleClick}>
              ยืนยันข้อมูล
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  )
}

export default PWTicketProductModal

const PWTicketProductModalStyle = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(8, 0),
      },
    },
    grid: {
      maxWidth: theme.breakpoints.values.md,
      height: 'fit-content',
      background: 'white',
      borderRadius: 4,
      padding: theme.spacing(2, 3),
    },
    avatar: {
      width: 120,
      height: 120,
    },
  }),
)
