import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  CreatedDocument,
  ICustomerProfile_Document,
  ICustomerDocumentState,
  ICustomerProfile,
} from '../../../../../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm, Table } from '../../../../../../../custom-components'
import { dateTimeFormat } from '../../../../../../../functions'
import { DocumentType } from '../../../../../../../enums'
import { CustomerProfileCard } from '../../../../../../../shared-components'
import BillingDocumentCreationFormValue from './BillingDocumentCreationDialogValue'
import { Alert } from '@material-ui/lab'
import { calculateNetAmount, createBillingDocument } from '../../service'

interface Props extends DialogProps {
  customerProfile: ICustomerProfile
  documentTypeCretion: DocumentType.Receipts | DocumentType.BillingNote
  onSuccess?: (createdBillingDocument: CreatedDocument) => void
  onFail?: () => void
}

const BillingDocumentCreationDialog: React.FC<Props> = (props) => {
  const { customerProfile, documentTypeCretion, onSuccess, onFail, onClose, ...otherProps } = props

  const [isEmptyBillingDetail, setIsEmptyBillingDetail] = useState<boolean>(false)

  const { documents } = customerProfile

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
    setValue,
    reset,
    register,
  } = useForm<BillingDocumentCreationFormValue>()

  const { billingAddressId, documents: documentsInForm } = watch()

  const billingDetailsData = documents
    ?.filter(
      (d) =>
        (d.type.id === DocumentType.TaxInvoice || d.type.id === DocumentType.CreditNote) &&
        ((documentTypeCretion === DocumentType.Receipts && !d.receiptRefCode) ||
          (documentTypeCretion === DocumentType.BillingNote && !d.billingNoteRefCode)) &&
        d.total !== undefined,
    )
    .map((d) => (d.type.id === DocumentType.CreditNote ? { ...d, total: (d.total || 0) * -1 } : d))

  const ableToCreateBillingDocument = billingDetailsData && billingDetailsData.length > 0

  const netAmount = calculateNetAmount(documentsInForm)

  useEffect(() => {
    register('billingAddressId', {
      required: 'กรุณาเลือกที่อยู่เปิดบิล',
    })
    register('documents', {
      required: 'กรุณาเลือกเอกสารอ้างอิง',
    })
  }, [register])

  useEffect(() => {
    if (ableToCreateBillingDocument) setIsEmptyBillingDetail(false)
    else setIsEmptyBillingDetail(true)
  }, [ableToCreateBillingDocument, billingDetailsData])

  const handleOnSelecteBillingDetail = (selected: number[]) => {
    if (billingDetailsData) {
      const selectedItem = billingDetailsData.filter((bd) => selected.includes(bd.id))
      setValue('documents', selectedItem)
    }
  }

  const handleSubmitBillingNoteCreationForm = async (value: BillingDocumentCreationFormValue) => {
    const response = await createBillingDocument(customerProfile.id, value, documentTypeCretion)
    if (response?.status === 201) {
      onSuccess && onSuccess(response.data)
      onClose && onClose({}, 'escapeKeyDown')
      reset()
    } else {
      onFail && onFail()
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitBillingNoteCreationForm)}>
        <DialogTitle>
          {documentTypeCretion === DocumentType.BillingNote && <>{'สร้างใบวางบิล'}</>}
          {documentTypeCretion === DocumentType.Receipts && <>{'สร้างใบเสร็จรับเงิน'}</>}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {isEmptyBillingDetail ? (
              <Grid item xs={12}>
                <Typography color="error" variant="body1">
                  ไม่พบ
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  ใบกำกับภาษีหรีอใบลดหนี้ที่สามารถนำมาเปิดเอกสารได้ กรุณาตรวจสอบ
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <CustomerProfileCard
                    customerProfile={customerProfile}
                    billingAddressId={billingAddressId}
                    billingAddressSelect
                    disableBtnCustomerDetail
                    onBillingAddressChange={(id: number) => setValue('billingAddressId', id)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <GeneralTextFieldForm control={control} name="remark" label="หมายเหตุ" />
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textPrimary" variant="body2">
                    {documentTypeCretion === DocumentType.BillingNote && <>{'รายละเอียดใบวางบิล'}</>}
                    {documentTypeCretion === DocumentType.Receipts && <>{'รายละเอียดใบเสร็จรับเงิน'}</>}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table<ICustomerProfile_Document<ICustomerDocumentState>>
                    selectable
                    size="small"
                    color="primary.dark"
                    columns={[
                      {
                        name: 'ลำดับ',
                        render: (_, index) => (
                          <Typography color="textSecondary" variant="body2">
                            {index + 1}
                          </Typography>
                        ),
                      },
                      {
                        name: 'เลขใบกำกับภาษีอ้างอิง',
                        render: ({ code }) => (
                          <Typography color="textSecondary" variant="body2">
                            {code}
                          </Typography>
                        ),
                      },
                      {
                        name: 'วันที่เอกสาร',
                        render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                      },
                      {
                        name: 'วันครบกำหนด',
                        render: ({ state }) =>
                          state && 'expiredDate' in state && state.expiredDate
                            ? dateTimeFormat(state.expiredDate)
                            : undefined,
                      },
                      {
                        name: 'จำนวนเงิน',
                        numeric: true,
                        format: '0,0.00',
                        dataIndex: 'total',
                      },
                    ]}
                    postCells={
                      <TableRow>
                        <TableCell colSpan={4} />
                        <TableCell align="right">ยอดรวมสุทธิ</TableCell>
                        <TableCell align="right">{numeral(netAmount).format('0,0.00')}</TableCell>
                      </TableRow>
                    }
                    data={billingDetailsData || []}
                    cleanSpace
                    pagination={false}
                    onChange={handleOnSelecteBillingDetail}
                  />
                </Grid>
              </>
            )}
            {errors?.billingAddressId && (
              <Grid item xs={12}>
                <Alert severity="error">{errors?.billingAddressId?.message}</Alert>
              </Grid>
            )}
            {errors?.documents && (
              <Grid item xs={12}>
                <Alert severity="error">{errors?.documents?.message}</Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {isEmptyBillingDetail ? (
            <Button color="primary" variant="contained" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
              ตกลง
            </Button>
          ) : (
            <>
              <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
                ยกเลิก
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={isSubmitting || isEmptyBillingDetail}>
                ยืนยันข้อมูล
              </Button>
            </>
          )}
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default BillingDocumentCreationDialog
