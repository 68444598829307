import { useContext } from 'react'
import { Add, Photo } from '@material-ui/icons'
import { FlexBox, GeneralTextField, Link, Topic, withLayout, Table, Avatar } from '../../custom-components'
import { RootContext } from '../..'
import { NavigationItemKey, PageName } from '../../enums'
import { useProductMustToPurchaseTableData } from '../../hooks/product'
import { Loading, NotificationHead } from '../../shared-components'
import { Box, Button, Chip, TextField, Typography, Grid } from '@material-ui/core'
import { getUserPermissionInfo } from '../../utils/permission'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { productGrades } from '../../constants'
import { Autocomplete } from '@material-ui/lab'
import { ProductMustToPurchase } from '../../api/smartbestbuys-api'
import { dateFormat } from '../../functions'
import { formatNumber } from '../../functions/number-utils'

const ProductMustToPurchaseTableDataPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PRODUCT_MUST_TO_PURCHASE)

  const tableData = useProductMustToPurchaseTableData()

  if (!tableData) return <Loading />

  const {
    data,
    paginationInfo,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    isLoading,
  } = tableData

  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Write)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการสินค้าที่ต้องสั่งซื้อ</Topic>
            {writePermission.hasPermission && (
              <Link href="/products/pending-to-purchase/create-purchase-requisition" target="_blank">
                <Button variant="contained" color="primary" startIcon={<Add />}>
                  สร้างใบขอซื้อ
                </Button>
              </Link>
            )}
          </FlexBox>
          <NotificationHead navigationKey={NavigationItemKey.ProductMustToPurchase} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="nameLocal"
                  onChange={(e) => handleSearchParamChange('nameLocal', e.target.value)}
                  label="ชื่อ"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="code"
                  onChange={(e) => handleSearchParamChange('code', e.target.value)}
                  label="รหัสสินค้า"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  size="small"
                  options={productGrades.map((option) => option.value)}
                  defaultValue={[]}
                  renderTags={(value: string[], getTagProps) => {
                    return value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }}
                  onChange={(event, newValue) => {
                    handleSearchParamChange('grades', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="กลุ่มสินค้า"
                      placeholder="กลุ่มสินค้า"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<ProductMustToPurchase.Data>
            color="primary.dark"
            columns={[
              {
                name: 'หมวด',
                render: ({ grade }) => grade ?? '-',
              },
              {
                name: 'หมดแน่ๆวันที่',
                fixedWidth: 200,
                render: ({ estimateOutOfStockDate, averageSaleAmount }) => {
                  let element
                  if (averageSaleAmount === 0) {
                    element = (
                      <Box color="error.main" clone>
                        <Typography variant="body2">มีโอกาส deadstock สูง</Typography>
                      </Box>
                    )
                  } else if (estimateOutOfStockDate) {
                    element = <Typography variant="body2">{dateFormat(new Date(estimateOutOfStockDate))}</Typography>
                  } else {
                    element = '-'
                  }

                  return element
                },
              },
              {
                name: 'รายละเอียดสินค้า',
                dataIndex: 'nameLocal',
                render: ({ imageUrl, nameLocal, id, code }) => (
                  <Box display="flex" alignItems="flex-start">
                    <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                      <Photo />
                    </Avatar>
                    {
                      <FlexBox ml={1} flexDirection="column">
                        <Link color="primary" to={`/products/${id}/profile`}>
                          {code}
                        </Link>

                        <Typography variant="body2">{nameLocal}</Typography>
                      </FlexBox>
                    }
                  </Box>
                ),
                fixedWidth: 300,
              },
              {
                name: 'จำนวนที่ต้องสั่ง',
                render: ({ actualPurchaseAmount }) => actualPurchaseAmount,
              },
              {
                name: 'ราคาต้นทุน',
                render: ({ cost }) => formatNumber(cost),
              },
              {
                name: 'ค่าเงิน',
                render: ({ currency }) => currency?.name ?? '-',
              },
              {
                name: 'รวม',
                render: ({ purchaseTotal }) => formatNumber(purchaseTotal),
              },
              {
                name: 'ชื่อผู้ขาย',
                render: ({ lastedVendorBuy }) => lastedVendorBuy?.name ?? '-',
              },
            ]}
            data={data}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(ProductMustToPurchaseTableDataPage)
