import React, { useContext, useEffect, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router'
import { CustomerCreationInput } from './CustomerCreationForm'
import CustomerCreationFormValue from './CustomerCreationFormValues'
import { withLayout, Topic, Grid, Form } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components/SubmitForm'
import { createCustomerService } from './service'
import { RootContext } from '../../..'
import { PageName } from '../../../enums'

const CustomerCreationPage: React.FC = () => {
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  const history = useHistory()

  setCurrentPage(PageName.CUSTOMER)
  const formMethod = useForm<CustomerCreationFormValue>()

  const {
    register,
    setError,
    formState: { isSubmitting, errors },
  } = formMethod

  const handleSubmitCustomerForm = async (values: CustomerCreationFormValue) => {
    if (isSubmitting) return
    const response = await createCustomerService(values)
    if (response?.status === 201) {
      triggerSnackbar('สร้างลูกค้าสำเร็จ')
      const customer = response.data
      history.push('/customers/' + customer.id + '/profile')
    } else {
      if (response?.status === 400) {
        const errorData = response?.data as any

        if (!!errorData?.data?.referenceNumber)
          setError('numberOfTaxpayer', {
            message: errorData.data.referenceNumber,
          })

        if (!!errorData?.data?.email)
          setError('emailAddress', {
            message: errorData.data.email,
          })

        if (!!errorData?.data?.phoneNumber)
          setError('phoneNumber', {
            type: 'required',
            message: errorData.data.phoneNumber,
          })
      }
      triggerSnackbar('สร้างลูกค้าไม่สำเร็จ')
    }
    return true
  }

  // effect
  useEffect(() => {
    register('customerTypeId', {
      required: 'กรุณาเลือกประเภทลุกค้า',
    })
  }, [register])

  const isInvalid = useMemo(
    () => !!errors.numberOfTaxpayer || !!errors.emailAddress,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors.numberOfTaxpayer?.message, errors.emailAddress?.message],
  )

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={handleSubmitCustomerForm}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Topic>สร้างลูกค้า</Topic>
          </Grid>
          <Grid item xs={12}>
            <CustomerCreationInput />
          </Grid>
          <Grid item xs={12}>
            <SubmitForm
              submitText="ยืนยันสร้างลูกค้า"
              cancelText="ยกเลิก"
              redirectPath="/customers"
              disableSubmit={isSubmitting || isInvalid}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default withLayout(CustomerCreationPage)
