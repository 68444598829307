import { createContext } from 'react'
import { IProductProfile } from '../../../../api/smartbestbuys-api'
interface ContextProps {
  productProfile: IProductProfile
  reload: () => void
}

const init = {
  productProfile: {} as IProductProfile,
  reload: () => {},
}

const ProductProfileContext = createContext<ContextProps>(init)

export default ProductProfileContext
