import React from 'react'
import { Avatar } from '@material-ui/core'
import { Link } from '../custom-components'
import { PictureAsPdf } from '@material-ui/icons'

interface Props {
  src: string
}

const PDFAvatar: React.FC<Props> = ({ src }) => {
  return (
    <Link href={src}>
      <Avatar variant="square" sizes="large">
        <PictureAsPdf />
      </Avatar>
    </Link>
  )
}

export default PDFAvatar
