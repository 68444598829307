import { ProductDataRow, SaleOrderProduct, SaleOrderProfile } from '../../../../../api/smartbestbuys-api'
import SOUpdationFormValue from './SOUpdationFormValues'

const mapToFormValues = (saleOrderProfile: SaleOrderProfile, products: ProductDataRow[]): SOUpdationFormValue => {
  const discountCondition = saleOrderProfile.discountAmount && saleOrderProfile.discountAmount > 0
  const shippingFeeCondition = saleOrderProfile.estimatedShippingFee && saleOrderProfile.estimatedShippingFee > 0

  const discountAmount =
    saleOrderProfile?.discountAmountType?.id === 1
      ? saleOrderProfile.discountAmount
      : (saleOrderProfile.discountAmount || 0) * saleOrderProfile.productTotal

  const attachments = saleOrderProfile?.attachments
    ? saleOrderProfile.attachments.map(({ id, attachmentUrl }) => {
        const name = attachmentUrl.split('/').pop() || 'NO NAME'
        return {
          id,
          name,
          src: attachmentUrl,
        }
      })
    : undefined

  const formValue: SOUpdationFormValue = {
    billingAddressId: saleOrderProfile.billingAddress?.id,
    selectedProducts: [
      ...saleOrderProfile?.products.map((soProduct: SaleOrderProduct) => {
        const productData = products.find((product) => product.id === soProduct.productId)
        return {
          ...soProduct,
          dealerPrice: productData?.dealerPrice ?? 0,
        }
      }),
    ] as any,

    discount: discountCondition ? discountAmount : undefined,
    discountEnabled: discountCondition ? true : false,

    shippingFee: shippingFeeCondition ? saleOrderProfile.estimatedShippingFee : undefined,
    shippingFeeEnabled: shippingFeeCondition ? true : false,
    isShippingFeeIncludingVat: saleOrderProfile.isShippingFeeIncludingVat,

    vatIncluded: saleOrderProfile.vatIncluded,

    customerPurchaseReference: saleOrderProfile.customerPurchaseReference,
    contactChannelId: saleOrderProfile.contactChannel?.id,
    currencyId: saleOrderProfile.currency.id,
    exchangeRate: saleOrderProfile.exchangeRate,

    paymentMethodId: saleOrderProfile.paymentMethod.id,
    paymentConditionId: saleOrderProfile.paymentCondition.id,

    dueDate: saleOrderProfile.dueDate,
    remark: saleOrderProfile.remark,

    creditPeriodDay: saleOrderProfile.customer.creditPeriodDay,

    customerPickUpMethod: saleOrderProfile.customerPickUpMethod?.id,
    depositRequired: saleOrderProfile.depositRequired,
    createdAt: saleOrderProfile.createdAt,
    attachments,
  }

  return formValue
}

export default mapToFormValues
