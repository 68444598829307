import { useEffect, useState } from 'react'
import { getPaymentRequisitionCreateData, PaymentRequisitionCreateData } from '../../api/smartbestbuys-api'

export const usePaymentRequisitionCreateData = (vendorId: number): PaymentRequisitionCreateData | undefined => {
  const [data, setData] = useState<PaymentRequisitionCreateData | undefined>()

  const _getPaymentRequisitionCreateData = async (vendorId: number) => {
    const response = await getPaymentRequisitionCreateData(vendorId)
    setData(response?.data)
  }

  useEffect(() => {
    _getPaymentRequisitionCreateData(vendorId)
  }, [vendorId])

  return data
}
