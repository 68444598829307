/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Grid, Paper } from '@material-ui/core'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import { PageName, TaskStatus, TaskType, TransportTaskStatus } from '../../../enums'
import { useTransportTaskTableData } from '../../../hooks/transport/useTransportTaskTableData'
import {
  Loading,
  MessengerIcon,
  PrepareDocumentIcon,
  PrepareProductIcon,
  ReturnDocumentIcon,
  ReturnProductIcon,
  TaskStatusChip,
} from '../../../shared-components'
import { FilteredTable, GeneralTextField, Link, Topic, withLayout } from '../../../custom-components'
import { TransportTask } from '../../../api/smartbestbuys-api'
import { formatNumberInt } from '../../../functions/number-utils'
import { dateFormat, dateTimeFormat, getV3Url } from '../../../functions'

const statusText = {
  [TransportTaskStatus.TransportPending]: 'รอยืนยันข้อมูลจัดส่ง',
  [TransportTaskStatus.Processing]: 'กำลังดำเนินการ',
  [TransportTaskStatus.TransportPendingVerify]: 'รอจบงาน',
  [TransportTaskStatus.Processed]: 'จบงานแล้ว',
  [TransportTaskStatus.Cancelled]: 'งานที่ยกเลิก',
}

const tabValues = [
  TransportTaskStatus.TransportPending,
  TransportTaskStatus.Processing,
  TransportTaskStatus.TransportPendingVerify,
  TransportTaskStatus.Processed,
  TransportTaskStatus.Cancelled,
]

const TransportTaskTableDataPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.TRANSPORT)

  // state
  const [tabValue, setTabValue] = useState(TaskStatus.TransportPending)

  const tableData = useTransportTaskTableData()

  // memo
  const tabs = useMemo(() => {
    return tabValues.map((s) => {
      const amount = tableData?.summary?.amountOfStatus[s]
      const showAmount = [
        TransportTaskStatus.TransportPending,
        TransportTaskStatus.Processing,
        TransportTaskStatus.TransportPendingVerify,
      ].includes(s)
      return {
        label: amount && showAmount ? `${statusText[s]} (${formatNumberInt(amount)})` : statusText[s],
        value: s,
      }
    })
  }, [tableData])

  if (!tableData) return <Loading />

  const {
    data,
    paginationInfo,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    summary,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>รายการบริการงานขนส่ง</Topic>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="code"
                  onChange={(e) => handleSearchParamChange('code', e.target.value)}
                  label="เลขที่งานจัดส่ง"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="saleOrderCode"
                  onChange={(e) => handleSearchParamChange('saleOrderCode', e.target.value)}
                  label="เลขที่ใบสั่งขายอ้างอิง"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="customerName"
                  onChange={(e) => handleSearchParamChange('customerName', e.target.value)}
                  label="ชื่อลูกค้า"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="date"
                  name="deliverDate"
                  label="วันที่ต้องจัดส่ง"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleSearchParamChange('deliverDate', e.target.value ? new Date(e.target.value) : undefined)
                  }
                ></GeneralTextField>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <FilteredTable<TransportTask>
            color="primary.dark"
            columns={[
              {
                name: 'สถานะงานจัดส่ง',
                render: ({ status }) => <TaskStatusChip size="small" status={status.id} label={status.name} />,
              },
              {
                name: 'เลขที่งานจัดส่ง',
                render: ({ id, code }) =>
                  code.startsWith('TSx') ? (
                    <Link color="primary" to={`/transport-tasks/${id}/profile`}>
                      {code}
                    </Link>
                  ) : (
                    <Link color="textSecondary" target="_blank" href={getV3Url('/transport-tasks')}>
                      {code}
                    </Link>
                  ),
              },
              {
                name: 'เลขที่ใบสั่งขายอ้างอิง',
                render: ({ saleOrderInfo }) =>
                  saleOrderInfo ? (
                    <Link color="secondary" to={`/sale-orders/${saleOrderInfo.id}/profile`}>
                      {saleOrderInfo.code}
                    </Link>
                  ) : (
                    '-'
                  ),
              },
              {
                name: 'วันที่ต้องจัดส่ง',
                dataIndex: 'dueDate',
                render: ({ dueDate }) => (dueDate ? dateFormat(dueDate) : 'ยังไม่ระบุ'),
              },
              { name: 'ชื่อลูกค้า', render: ({ saleOrderInfo }) => saleOrderInfo?.customerName ?? '-' },
              {
                name: 'สถานะงานเตรียมสินค้า',
                render: ({ relatedTasks }) => {
                  const prepareProduct = relatedTasks?.find((t) => t.type.id === TaskType.WarehousePrepareProduct)
                  const prepareDocument = relatedTasks?.find((t) => t.type.id === TaskType.FinancePrepareDocument)
                  const shippingTask = relatedTasks?.find((t) => t.type.id === TaskType.MessengerShippingTask)
                  const returnProduct = relatedTasks?.find((t) => t.type.id === TaskType.WarehouseReclaimProduct)
                  const returnDocument = relatedTasks?.find((t) => t.type.id === TaskType.FinanceReturnDocument)
                  return (
                    <div className="transport-task-icon-group">
                      <PrepareProductIcon status={prepareProduct?.status.id} />
                      <PrepareDocumentIcon status={prepareDocument?.status.id} />
                      <MessengerIcon status={shippingTask?.status.id} />
                      <ReturnProductIcon status={returnProduct?.status.id} />
                      <ReturnDocumentIcon status={returnDocument?.status.id} />
                    </div>
                  )
                },
              },
              {
                name: 'วันที่สร้าง',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
            ]}
            data={data}
            size="small"
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            disableDateRangeFilter
            disableSearch
            tabOption={{
              tabs: tabs,
              value: tabValue,
              onChange: (_, newValue) => {
                setTabValue(newValue)
                handleSearchParamChange('statusIds', [newValue], true)
              },
            }}
            isLoading={tableData.isLoading}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withLayout(TransportTaskTableDataPage)
