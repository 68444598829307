import { useEffect, useState } from 'react'
import {
  CustomerRequisitionShippingPendingTableData,
  getCustomerRequisitionsShippingPendingAPI,
} from '../api/smartbestbuys-api'

export const useCRShippingPending = (): CustomerRequisitionShippingPendingTableData[] | undefined => {
  const [data, setData] = useState<CustomerRequisitionShippingPendingTableData[] | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getCustomerRequisitionsShippingPendingAPI()
      const data = response?.data ?? []

      setData(data)
    })()
  }, [data?.length])

  return data
}
