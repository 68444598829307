import { NavigationItemKey } from '../../../enums'

export interface INotification {
  myShippingTask: number
  sales: {
    readyToShip: number
    outdatedFollowServiceTask: number
  }
  finance: {
    // AR
    pendingPaymentSO: number
    latedPaymentSO: number
    closeToPayDueDateSO: number
    pendingTaxInvoiceSO: number
    prepareDocumentTask: number
    returnDocumentTask: number
    pendingReceiptTaxInvoice: number
    // AP
    pendingPAY: number
    pendingTransferPAY: number
    pendingChecquePAY: number
    pendingReceiptPAY: number
    pendingStatementPAY: number
  }
  transport: {
    pendingTask: number
    todayPendingTask: number
  }
  warehouse: {
    prepareProductToDeliverTask: number
    returnProductTask: number
    waitingCustomerPickup: number
    storageUnAvailableProduct: number
  }
  purchases: {
    pendingPR: number
    approvedWithNoPO: number
    pendingPO: number
    pendingDealVendorPO: number
    waitingProductPO: number
    // PO which PR that canceled is danger
    dangerPO: number
    // product which meet to re-order point
    stockAlertProduct: number
  }
}

export enum NotiTeam {
  Unknown,
  Sale,
  Transport,
  Warehouse,
  Purchase,
  FinanceAP,
  FinanceAR,
}

export const notiTeamColors: Record<NotiTeam, string> = {
  [NotiTeam.Unknown]: '#000',
  [NotiTeam.Sale]: '#FF7518',
  [NotiTeam.Transport]: '#4169E1',
  [NotiTeam.Warehouse]: '#FF3131',
  [NotiTeam.Purchase]: '#ffc400',
  [NotiTeam.FinanceAP]: '#03AC13',
  [NotiTeam.FinanceAR]: '#FFC0CB',
}

export interface NotiItem {
  name: string
  amount: number
  team: NotiTeam
}

export class Notification {
  private notificationData?: INotification
  constructor(_notificationData?: INotification) {
    this.notificationData = _notificationData
  }

  getNotiItem = <T extends keyof INotification>(
    team: NotiTeam,
    notiKey: keyof INotification,
    subKey?: keyof INotification[T],
  ): NotiItem => {
    if (!this.notificationData) return { amount: 0, team: NotiTeam.Unknown, name: '-' }
    let amount
    if (!subKey) {
      amount = this.notificationData[notiKey]
    } else {
      // !!! warning type unsafe
      const _x = this.notificationData[notiKey] as any
      amount = _x[subKey]
    }

    return {
      amount,
      team,
      name: `${getNotiTitle(notiKey, subKey)} ${amount} รายการ`,
    }
  }

  navigationItemNoticeAmount = (key: NavigationItemKey): NotiItem[] => {
    if (!this.notificationData) return []

    switch (key) {
      // Sale
      case NavigationItemKey.Sale:
        return [
          this.getNotiItem<'sales'>(NotiTeam.Transport, 'sales', 'readyToShip'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'pendingPaymentSO'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'pendingTaxInvoiceSO'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'pendingReceiptTaxInvoice'),
        ]
      case NavigationItemKey.SaleOrder:
        return [
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'pendingPaymentSO'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'pendingTaxInvoiceSO'),
        ]
      case NavigationItemKey.ReadyToShipSaleOrder:
        return [this.getNotiItem<'sales'>(NotiTeam.Transport, 'sales', 'readyToShip')]
      case NavigationItemKey.TaxInvoice:
        return [this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'pendingReceiptTaxInvoice')]
      // Transport
      case NavigationItemKey.Transport:
        return [
          this.getNotiItem<'transport'>(NotiTeam.Transport, 'transport', 'pendingTask'),
          this.getNotiItem<'transport'>(NotiTeam.Transport, 'transport', 'todayPendingTask'),
          this.getNotiItem(NotiTeam.Transport, 'myShippingTask'),
        ]
      case NavigationItemKey.TransportTask:
        return [this.getNotiItem<'transport'>(NotiTeam.Transport, 'transport', 'pendingTask')]
      case NavigationItemKey.TransportShippingSummary:
        return [this.getNotiItem<'transport'>(NotiTeam.Transport, 'transport', 'todayPendingTask')]
      case NavigationItemKey.ShippingTask:
        return [this.getNotiItem(NotiTeam.Transport, 'myShippingTask')]
      // Warehouse
      case NavigationItemKey.Warehouse:
        return [
          // this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'storageUnAvailableProduct'),
          this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'waitingCustomerPickup'),
          this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'prepareProductToDeliverTask'),
          this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'returnProductTask'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'stockAlertProduct'),
        ]
      case NavigationItemKey.ReorderPoint:
        return [this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'stockAlertProduct')]
      case NavigationItemKey.Product:
        return [
          // this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'storageUnAvailableProduct'),
        ]
      case NavigationItemKey.CustomerPickupProduct:
        return [this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'waitingCustomerPickup')]
      case NavigationItemKey.PrepareProductToShip:
        return [this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'prepareProductToDeliverTask')]
      case NavigationItemKey.ReturnProduct:
        return [this.getNotiItem<'warehouse'>(NotiTeam.Warehouse, 'warehouse', 'returnProductTask')]
      // Purchase
      case NavigationItemKey.Purchase:
        return [
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'pendingPR'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'approvedWithNoPO'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'pendingDealVendorPO'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'waitingProductPO'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'dangerPO'),
          this.getNotiItem<'sales'>(NotiTeam.Purchase, 'sales', 'outdatedFollowServiceTask'),
        ]
      case NavigationItemKey.PurchaseRequisition:
        return [
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'pendingPR'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'approvedWithNoPO'),
        ]
      case NavigationItemKey.PurchaseOrder:
        return [
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'pendingDealVendorPO'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'waitingProductPO'),
          this.getNotiItem<'purchases'>(NotiTeam.Purchase, 'purchases', 'dangerPO'),
        ]
      case NavigationItemKey.FollowServiceTask:
        return [this.getNotiItem<'sales'>(NotiTeam.Purchase, 'sales', 'outdatedFollowServiceTask')]
      // Finance
      case NavigationItemKey.Finance:
        return [
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingPAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingTransferPAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingChecquePAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingReceiptPAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingStatementPAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'closeToPayDueDateSO'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'latedPaymentSO'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'prepareDocumentTask'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'returnDocumentTask'),
        ]
      case NavigationItemKey.PaymentRequisition:
        return [this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingPAY')]
      case NavigationItemKey.PurchasePayment:
        return [
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingTransferPAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingChecquePAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingReceiptPAY'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAP, 'finance', 'pendingStatementPAY'),
        ]
      case NavigationItemKey.SalesDebtors:
        return [
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'closeToPayDueDateSO'),
          this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'latedPaymentSO'),
        ]
      case NavigationItemKey.PrepareDocument:
        return [this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'prepareDocumentTask')]
      case NavigationItemKey.ReturnDocument:
        return [this.getNotiItem<'finance'>(NotiTeam.FinanceAR, 'finance', 'returnDocumentTask')]

      default:
        return []
    }
  }

  toJsonString = (): string => {
    return JSON.stringify(this.notificationData ?? '{}')
  }
}

const getNotiTitle = <T extends keyof INotification[keyof INotification]>(
  notiKey: keyof INotification,
  subKey?: keyof T,
) => {
  if (!subKey) {
    return _getRootNotiTitle(notiKey)
  }

  switch (notiKey) {
    case 'sales':
      return _getSalesNotiTitle(subKey as keyof INotification['sales'])
    case 'finance':
      return _getFinanceNotiTitle(subKey as keyof INotification['finance'])
    case 'transport':
      return _getTransportNotiTitle(subKey as keyof INotification['transport'])
    case 'warehouse':
      return _getWarehouseNotiTitle(subKey as keyof INotification['warehouse'])
    case 'purchases':
      return _getPurchasesNotiTitle(subKey as keyof INotification['purchases'])
    default:
      return '-'
  }
}

const _getRootNotiTitle = (notiKey: keyof INotification) => {
  switch (notiKey) {
    case 'myShippingTask':
      return 'งานส่งของฉัน'
    default:
      return '-'
  }
}

const _getSalesNotiTitle = (subNotiKey: keyof INotification['sales']) => {
  switch (subNotiKey) {
    case 'readyToShip':
      return 'รายการพร้อมส่ง'
    case 'outdatedFollowServiceTask':
      return 'ติดตามบริการล่าช้า'
    default:
      return '-'
  }
}

const _getFinanceNotiTitle = (subNotiKey: keyof INotification['finance']) => {
  switch (subNotiKey) {
    // AR
    case 'pendingPaymentSO':
      return 'รอลูกค้าจ่ายเงิน'
    case 'latedPaymentSO':
      return 'เลยกำหนดวันจ่ายเงิน'
    case 'closeToPayDueDateSO':
      return 'ใกล้ถึงวันที่จ่ายเงิน'
    case 'pendingTaxInvoiceSO':
      return 'ยังไม่ออกใบกำกับภาษี'
    case 'prepareDocumentTask':
      return 'งานเตรียมเอกสาร'
    case 'returnDocumentTask':
      return 'งานเรียกคืนเอกสาร'
    case 'pendingReceiptTaxInvoice':
      return 'ยังไม่ออกใบเสร็จรับเงิน'
    // AP
    case 'pendingPAY':
      return 'รอทำเบิก'
    case 'pendingTransferPAY':
      return 'รอโอน'
    case 'pendingChecquePAY':
      return 'รอเช็ค'
    case 'pendingReceiptPAY':
      return 'รออัพโหลดใบเสร็จ'
    case 'pendingStatementPAY':
      return 'รอตรวจสอบ statement'
    default:
      return '-'
  }
}
const _getTransportNotiTitle = (subNotiKey: keyof INotification['transport']) => {
  switch (subNotiKey) {
    case 'pendingTask':
      return 'งานบริการ'
    case 'todayPendingTask':
      return 'งานบริการวันนี้'
    default:
      return '-'
  }
}

const _getWarehouseNotiTitle = (subNotiKey: keyof INotification['warehouse']) => {
  switch (subNotiKey) {
    case 'prepareProductToDeliverTask':
      return 'งานเตรียมสินค้า'
    case 'returnProductTask':
      return 'งานเรียกคืนสินค้า'
    case 'waitingCustomerPickup':
      return 'งานรอลูกค้ามารับหน้าร้าน'
    case 'storageUnAvailableProduct':
      return 'สินค้ายังไม่ระบุคลัง'
    default:
      return '-'
  }
}

const _getPurchasesNotiTitle = (subNotiKey: keyof INotification['purchases']) => {
  switch (subNotiKey) {
    case 'pendingPR':
      return 'รอตรวจสอบคำขอซื้อ'
    case 'approvedWithNoPO':
      return 'รอเปิด PO'
    case 'pendingPO':
      return 'รอตรวจสอบใบสั่งซื้อ'
    case 'pendingDealVendorPO':
      return 'รอตกลงวันกับ Vendor'
    case 'waitingProductPO':
      return 'รอสินค้าเข้า'
    // PO which PR that canceled is danger
    case 'dangerPO':
      return 'คำขอซื้อถูกยกเลิก และยังมี PO'
    // product which meet to re-order point
    case 'stockAlertProduct':
      return 'สินค้าคงเหลือต่ำกว่าจุด ROP'
    default:
      return '-'
  }
}

//   {
//     amount: this.notificationData.finance.pendingPaymentSO,
//     team: NotiTeam.FinanceAR,
//     name: `รอชำระเงิน (${this.notificationData.finance.pendingPaymentSO})`,
//   },
//   {
//     amount: this.notificationData.finance.pendingTaxInvoiceSO,
//     team: NotiTeam.FinanceAR,
//     name: `ไม่มีใบกำกับภาษี (${this.notificationData.finance.pendingTaxInvoiceSO})`,
//   },
//   {
//     amount: this.notificationData.finance.pendingReceiptTaxInvoice,
//     team: NotiTeam.FinanceAR,
//     name: `ไม่มีใบเสร็จ (${this.notificationData.finance.pendingReceiptTaxInvoice})`,
//   },
// ]
// case NavigationItemKey.SaleOrder:
//   return [
//     { amount: this.notificationData.finance.pendingPaymentSO, color: NotiTeam.FinanceAR },
//     { amount: this.notificationData.finance.pendingTaxInvoiceSO, color: NotiTeam.FinanceAR },
//   ]
// case NavigationItemKey.ReadyToShipSaleOrder:
//   return [{ amount: this.notificationData.sales.readyToShip, color: NotiTeam.Transport }]
// case NavigationItemKey.TaxInvoice:
//   return [
//     { amount: this.notificationData.finance.pendingReceiptTaxInvoice, color: NotiTeam.FinanceAR },
//   ]

// // Transport
// case NavigationItemKey.Transport:
//   return [
//     {
//       amount:
//         this.notificationData.transport.pendingTask +
//         this.notificationData.transport.todayPendingTask +
//         this.notificationData.myShippingTask,
//       color: NotiTeam.Transport,
//     },
//   ]
// case NavigationItemKey.TransportTask:
//   return [{ amount: this.notificationData.transport.pendingTask, color: NotiTeam.Transport }]
// case NavigationItemKey.TransportShippingSummary:
//   return [{ amount: this.notificationData.transport.todayPendingTask, color: NotiTeam.Transport }]
// case NavigationItemKey.ShippingTask:
//   return [{ amount: this.notificationData.myShippingTask, color: NotiTeam.Transport }]

// // Warehouse
// case NavigationItemKey.Warehouse:
//   return [
//     {
//       amount:
//         // this.notificationData.warehouse.storageUnAvailableProduct +
//         this.notificationData.warehouse.waitingCustomerPickup +
//         this.notificationData.warehouse.prepareProductToDeliverTask +
//         this.notificationData.warehouse.returnProductTask,
//       color: NotiTeam.Warehouse,
//     },
//     { amount: this.notificationData.purchases.stockAlertProduct, color: NotiTeam.Purchase },
//   ]
// case NavigationItemKey.ReorderPoint:
//   return [{ amount: this.notificationData.purchases.stockAlertProduct, color: NotiTeam.Purchase }]
// case NavigationItemKey.Product:
//   return [
//     {
//       amount: 0, // this.notificationData.warehouse.storageUnAvailableProduct,
//       color: NotiTeam.Warehouse,
//     },
//   ]
// case NavigationItemKey.CustomerPickupProduct:
//   return [
//     { amount: this.notificationData.warehouse.waitingCustomerPickup, color: NotiTeam.Warehouse },
//   ]
// case NavigationItemKey.PrepareProductToShip:
//   return [
//     {
//       amount: this.notificationData.warehouse.prepareProductToDeliverTask,
//       color: NotiTeam.Warehouse,
//     },
//   ]
// case NavigationItemKey.ReturnProduct:
//   return [{ amount: this.notificationData.warehouse.returnProductTask, color: NotiTeam.Warehouse }]

// // Purchase
// case NavigationItemKey.Purchase:
//   return [
//     {
//       amount:
//         this.notificationData.purchases.pendingPR +
//         this.notificationData.purchases.approvedWithNoPO +
//         this.notificationData.purchases.pendingDealVendorPO +
//         this.notificationData.purchases.waitingProductPO +
//         this.notificationData.purchases.dangerPO +
//         this.notificationData.sales.outdatedFollowServiceTask,
//       color: NotiTeam.Purchase,
//     },
//   ]
// case NavigationItemKey.PurchaseRequisition:
//   return [
//     {
//       amount: this.notificationData.purchases.pendingPR + this.notificationData.purchases.approvedWithNoPO,
//       color: NotiTeam.Purchase,
//     },
//   ]
// case NavigationItemKey.PurchaseOrder:
//   return [
//     {
//       amount:
//         this.notificationData.purchases.pendingDealVendorPO +
//         this.notificationData.purchases.waitingProductPO +
//         this.notificationData.purchases.dangerPO,
//       color: NotiTeam.Purchase,
//     },
//   ]
// case NavigationItemKey.FollowServiceTask:
//   return [
//     { amount: this.notificationData.sales.outdatedFollowServiceTask, color: NotiTeam.Purchase },
//   ]

// // Finance
// case NavigationItemKey.Finance:
//   return [
//     {
//       amount:
//         this.notificationData.finance.pendingPAY +
//         this.notificationData.finance.pendingTransferPAY +
//         this.notificationData.finance.pendingChecquePAY +
//         this.notificationData.finance.pendingReceiptPAY +
//         this.notificationData.finance.pendingStatementPAY,
//       color: NotiTeam.FinanceAP,
//     },
//     {
//       amount:
//         this.notificationData.finance.closeToPayDueDateSO +
//         this.notificationData.finance.latedPaymentSO +
//         this.notificationData.finance.prepareDocumentTask +
//         this.notificationData.finance.returnDocumentTask,
//       color: NotiTeam.FinanceAR,
//     },
//   ]
// case NavigationItemKey.PaymentRequisition:
//   return [{ amount: this.notificationData.finance.pendingPAY, color: NotiTeam.FinanceAP }]
// case NavigationItemKey.PurchasePayment:
//   return [
//     {
//       amount:
//         this.notificationData.finance.pendingTransferPAY +
//         this.notificationData.finance.pendingChecquePAY +
//         this.notificationData.finance.pendingReceiptPAY +
//         this.notificationData.finance.pendingStatementPAY,
//       color: NotiTeam.FinanceAP,
//     },
//   ]
// case NavigationItemKey.SalesDebtors:
//   return [
//     {
//       amount: this.notificationData.finance.closeToPayDueDateSO + this.notificationData.finance.latedPaymentSO,
//       color: NotiTeam.FinanceAR,
//     },
//   ]
// case NavigationItemKey.PrepareDocument:
//   return [
//     {
//       amount: this.notificationData.finance.prepareDocumentTask,
//       color: NotiTeam.FinanceAR,
//     },
//   ]
// case NavigationItemKey.ReturnDocument:
//   return [
//     {
//       amount: this.notificationData.finance.returnDocumentTask,
//       color: NotiTeam.FinanceAR,
//     },
//   ]
