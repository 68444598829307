import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Paper,
  Box,
  Switch,
  Button,
  makeStyles,
  createStyles,
  BoxProps,
  InputAdornment,
  Collapse,
} from '@material-ui/core'
import { Title, TextFieldForm } from '../../custom-components'
import SOCreationFormValues from '../pages/SOCreationPage/SOCreationFormValues'
import _ from 'lodash'

const discountOptions = [5, 10, 15]

const DiscountBox: React.FC = (props) => {
  const { control, getValues, setValue } = useFormContext<SOCreationFormValues>()
  const { discountEnabled, selectedProducts } = getValues()

  const total =
    !!selectedProducts && selectedProducts.length > 0 ? _.sum(selectedProducts.map((p) => p.amount * p.price)) : 0

  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" padding={2}>
      {(props: BoxProps) => (
        <Paper className={props.className}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Title>ส่วนลดสินค้า</Title>
            <Switch checked={!!discountEnabled} onClick={() => setValue('discountEnabled', !discountEnabled)} />
          </Box>
          <Collapse in={!!discountEnabled}>
            <Box mb={2}>
              <TextFieldForm
                control={control}
                name="discount"
                label="ส่วนลด"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                }}
                inputProps={{
                  step: '0.1',
                }}
                type="number"
              />
            </Box>
            <Box>
              {discountOptions.map((d) => (
                <Button
                  key={d}
                  className={classes.percentButton}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => setValue('discount', (total * d) / 100)}
                >
                  {d}%
                </Button>
              ))}
            </Box>
          </Collapse>
        </Paper>
      )}
    </Box>
  )
}

export default DiscountBox

const useStyles = makeStyles((theme) =>
  createStyles({
    percentButton: {
      marginRight: 8,
    },
  }),
)
