import React, { useRef } from 'react'
import { Card, Radio as MUIRadio, RadioProps, FormControlLabel } from '@material-ui/core'
import { useStyles } from './styles'

interface Props extends RadioProps {
  label: string
  value: string | number
  outlined?: boolean
}

const Radio: React.FC<Props> = (props) => {
  const { label, value, outlined, ...radioProps } = props
  const classes = useStyles()
  const radioRef = useRef<HTMLInputElement>(null)

  if (outlined) {
    const classNames = [classes.card]
    const { checked } = radioProps
    if (checked) {
      classNames.push(classes.checked)
    }
    return (
      <Card variant="outlined" className={classes.card} onClick={() => radioRef.current?.click()}>
        <FormControlLabel label={label} value={value} control={<MUIRadio inputRef={radioRef} {...radioProps} />} />
      </Card>
    )
  }
  return <FormControlLabel label={label} value={value} control={<MUIRadio {...radioProps} />} />
}

export default Radio
