import React, { useContext, useState } from 'react'
import { Grid } from '@material-ui/core'
import { BreadcumbsList, withLayout } from '../../../custom-components'
import { useVendorProfile } from '../../../hooks'
import { Loading } from '../../../shared-components'
import VendorProfileCard from '../../purchase-orders/profile/VendorProfileCard'
import VendorPurchasesInformationBox from './VendorPurchasesInfomationBox/VendorPurchasesInfomationBox'
import VendorUpdateFormDialog from './VendorUpdateFormDialog'
import { Edit } from '@material-ui/icons'
import VendorMoreInformationBox from './VendorMoreInformationBox/VendorMoreInformationBox'
import { RootContext } from '../../..'
import { PageName } from '../../../enums'

interface Props {
  vendorId: number
}

const VendorsProfilePage: React.FC<Props> = (props: Props) => {
  //props
  const { vendorId } = props

  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.VENDOR)

  // state
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)
  const [reload, setReload] = useState(false)

  // prepare data
  const [vendorProfile] = useVendorProfile(vendorId, reload)

  if (!vendorProfile) return <Loading />

  // data
  const breadcumbsItems = [{ url: '/vendors', name: 'รายการผู้ขาย' }, { name: vendorProfile.name }]

  // func
  const reloadProfile = () => setReload((prev) => !prev)

  return (
    <Grid container spacing={2}>
      <VendorUpdateFormDialog
        vendorProfile={vendorProfile}
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        onComplete={() => {
          setUpdateDialogOpen(false)
          reloadProfile()
        }}
      />
      <Grid item xs={12}>
        <BreadcumbsList items={breadcumbsItems} />
      </Grid>
      <Grid item xs={12}>
        <VendorProfileCard
          value={vendorProfile}
          options={{
            actionButton: {
              text: '',
              color: 'secondary',
              suffixIcon: <Edit />,
              onClick: () => setUpdateDialogOpen(true),
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <VendorMoreInformationBox
          vendorProfile={vendorProfile}
          onContactUpdate={() => reloadProfile()}
          onAddressUpdate={() => reloadProfile()}
          onBankAccountUpdate={() => reloadProfile()}
          onAttachmentsUpdate={() => reloadProfile()}
        />
      </Grid>
      <Grid item xs={12}>
        <VendorPurchasesInformationBox vendorInfo={vendorProfile} />
      </Grid>
    </Grid>
  )
}

export default withLayout(VendorsProfilePage)
