import { useState, useEffect } from 'react'
import { PaymentRequisitionProfile } from '../../api/smartbestbuys-api'
import { getPaymentRequisitionProfile } from '../../api/smartbestbuys-api'

export const usePaymentRequisitionProfile = (
  paymentRequisitionId: number,
): [PaymentRequisitionProfile | undefined, (data: PaymentRequisitionProfile | undefined) => void] => {
  const [data, setData] = useState<PaymentRequisitionProfile | undefined>(undefined)

  const _fetchPaymentRequisitions = async (paymentRequisitionId: number) => {
    const response = await getPaymentRequisitionProfile(paymentRequisitionId)
    setData(response?.data)
  }

  useEffect(() => {
    _fetchPaymentRequisitions(paymentRequisitionId)
  }, [paymentRequisitionId])

  return [data, setData]
}
