import React, { useContext } from 'react'
import ProductProfileContext from '../../ProductProfileContext'
import { UserActivityTable } from '../../../../../../shared-components'
import { BaseUserActivity } from '../../../../../../types'

const ProductActivityLogs: React.FC = () => {
  const { productProfile } = useContext(ProductProfileContext)
  const { activities } = productProfile

  return (
    <UserActivityTable<BaseUserActivity>
      title="ประวัติการจัดการสินค้าและบริการ"
      activities={activities?.map((act) => ({ ...act, actionName: act.action })) ?? []}
    />
  )
}

export default ProductActivityLogs
