import { SvgIcon } from '@material-ui/core'
import { Description, LocalShipping } from '@material-ui/icons'
import { ReactComponent as PrepareProductIconSVG } from '../assets/icons/prepare-product-icon.svg'
import { ReactComponent as ReturnProductIconSVG } from '../assets/icons/return-product-icon.svg'
import { ReactComponent as ReturnDocumentIconSVG } from '../assets/icons/return-document-icon.svg'
import { TaskStatus } from '../enums'

import './TransportTaskIcon.less'

const _getClassStatus = (status?: TaskStatus) => {
  switch (status) {
    case TaskStatus.Pending:
      return 'transport-task-icon--pending'
    case TaskStatus.Processing:
      return 'transport-task-icon--processing'
    case TaskStatus.Done:
      return 'transport-task-icon--success'
    case TaskStatus.Failed:
    case TaskStatus.PreCancel:
      return 'transport-task-icon--failed'
    default:
      return 'transport-task-icon--not-found'
  }
}

interface Props {
  status?: TaskStatus
  size?: 'small'
}

export const PrepareProductIcon = (props: Props) => {
  const classNames = ['transport-task-icon']

  if (props.status) classNames.push(_getClassStatus(props.status))
  if (props.size) classNames.push('transport-task-icon--small')

  return (
    <span className={classNames.join(' ')}>
      <SvgIcon component={PrepareProductIconSVG} />
    </span>
  )
}

export const PrepareDocumentIcon = (props: Props) => {
  const classNames = ['transport-task-icon']

  if (props.status) classNames.push(_getClassStatus(props.status))
  if (props.size) classNames.push('transport-task-icon--small')

  return (
    <span className={classNames.join(' ')}>
      <Description />
    </span>
  )
}

export const MessengerIcon = (props: Props) => {
  const classNames = ['transport-task-icon']

  if (props.status) classNames.push(_getClassStatus(props.status))
  if (props.size) classNames.push('transport-task-icon--small')

  return (
    <span className={classNames.join(' ')}>
      <LocalShipping />
    </span>
  )
}

export const ReturnProductIcon = (props: Props) => {
  const classNames = ['transport-task-icon']

  if (props.status) classNames.push(_getClassStatus(props.status))
  if (props.size) classNames.push('transport-task-icon--small')

  return (
    <span className={classNames.join(' ')}>
      <SvgIcon component={ReturnProductIconSVG} viewBox="-4 -4 24 24" />
    </span>
  )
}

export const ReturnDocumentIcon = (props: Props) => {
  const classNames = ['transport-task-icon']

  if (props.status) classNames.push(_getClassStatus(props.status))
  if (props.size) classNames.push('transport-task-icon--small')

  return (
    <span className={classNames.join(' ')}>
      <SvgIcon component={ReturnDocumentIconSVG} viewBox="0 0 24 24" />
    </span>
  )
}
