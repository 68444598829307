import { useState, useEffect } from 'react'
import { FetchVendor, fetchVendors } from '../../api/smartbestbuys-api'

export const useVendors = (): FetchVendor[] => {
  const [data, setData] = useState<FetchVendor[]>([])

  const _fetchVendors = async () => {
    const response = await fetchVendors()
    setData(response?.data || [])
  }

  useEffect(() => {
    _fetchVendors()
  }, [])

  return data
}
