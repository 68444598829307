import React, { useContext, useState } from 'react'
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { confirmShippingTaskAPI, TransportShipingTaskMobileData } from '../../../api/smartbestbuys-api'
import { SubmitForm } from '../../../shared-components'
import { RootContext } from '../../..'

interface ConfirmTaskDialogData {
  id: number
}

interface Props {
  data: ConfirmTaskDialogData
  onSuccess?: (data: TransportShipingTaskMobileData) => void
  onFail?: () => void
}

const ConfirmTaskDialog: React.FC<Props & DialogProps> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const [submitting, setSubmitting] = useState(false)
  const { data, onSuccess, onFail, onClose, ...othersProps } = props

  const handleSubmit = async () => {
    setSubmitting(true)

    const response = await confirmShippingTaskAPI(data.id)

    if (response?.status === 200) {
      if (onSuccess) onSuccess(response.data)
      triggerSnackbar('ยืนยันรับงานสำเร็จ')
    } else {
      if (onFail) onFail()
      triggerSnackbar('ยืนยันรับงานไม่สำเร็จ')
    }

    setSubmitting(false)
  }

  return (
    <Dialog {...othersProps} fullWidth maxWidth="sm">
      <DialogTitle>ยืนยันรับงาน</DialogTitle>
      <DialogContent>ต้องการยืนยันรับงานใช่หรือไม่?</DialogContent>
      <DialogActions>
        <SubmitForm
          onCancel={() => {
            if (onClose) onClose({}, 'escapeKeyDown')
          }}
          disableSubmit={submitting}
          onSubmit={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmTaskDialog
