import { useEffect, useState } from 'react'
import {
  GetQuotationReportRequestQueryParams,
  IQuotationReport,
  getQuotationReportAPI,
} from '../../api/smartbestbuys-api'

export const useQuotationReport = (
  startDate: Date,
  endDate: Date,
): {
  data: IQuotationReport | undefined
  pendingSearch: GetQuotationReportRequestQueryParams
  handleSearchParamsChange: <K extends keyof GetQuotationReportRequestQueryParams>(
    key: K,
    value: GetQuotationReportRequestQueryParams[K],
  ) => void
  handleSearch: () => void
} => {
  const [data, setData] = useState<IQuotationReport | undefined>(undefined)
  const [pendingSearch, setPendingSearch] = useState<GetQuotationReportRequestQueryParams>({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  })

  useEffect(
    () => {
      _loadData({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    }, // eslint-disable-next-line
    [],
  )

  const handleSearchParamsChange = <K extends keyof GetQuotationReportRequestQueryParams>(
    _key: K,
    _value: GetQuotationReportRequestQueryParams[K],
  ) => {
    const newPendingSearch = {
      ...pendingSearch,
      [_key]: _value,
    }

    setPendingSearch(newPendingSearch)
  }

  const handleSearch = () => {
    _loadData(pendingSearch)
  }

  const _loadData = async (_search: GetQuotationReportRequestQueryParams) => {
    const response = await getQuotationReportAPI(_search)
    setData(response?.data)
  }

  return {
    data,
    pendingSearch,
    handleSearchParamsChange,
    handleSearch,
  }
}
