import { useRef } from 'react'
import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { CameraAlt, Repeat } from '@material-ui/icons'
import React, { useState } from 'react'
import { FlexBox } from '../../custom-components'
import { compressImage, fileToBase64 } from '../../functions'

import './UploadImageCard.less'

interface Props {
  uniqKey?: any
  key?: any
  title: string
  onChange: (image: ImageSource) => void
  error?: boolean
  message?: string
  previewOnChange?: boolean
  defaultSrc?: string
  uploading?: boolean
}

export interface ImageSource {
  name: string
  base64: string
}

const UploadImageCard: React.FC<Props> = (props) => {
  const [imageSource, setImageSource] = useState<ImageSource | undefined>(undefined)
  const [preview, setPreview] = useState(false)
  const ref = useRef<HTMLInputElement | null>(null)
  const { uniqKey: key, title, onChange, error, previewOnChange, message, defaultSrc, uploading } = props

  return (
    <>
      <Dialog key={key} open={preview} onClose={() => {}} fullWidth maxWidth="md">
        <DialogTitle>ภาพหลักฐาน</DialogTitle>
        <DialogContent>
          <FlexBox justifyContent="center">
            <img src={imageSource?.base64 || defaultSrc} alt="payslip" />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button key={key} color="primary" onClick={() => ref.current?.click()}>
            <Repeat /> เปลี่ยนรูป
          </Button>
          <Button variant="contained" color="primary" onClick={() => setPreview(false)}>
            รูปภาพถูกต้อง
          </Button>
        </DialogActions>
      </Dialog>
      <FlexBox className={uploading ? 'upload-image-card--uploading' : ''} key={key} flexDirection="column">
        {uploading && <CircularProgress className="upload-image-card--uploading__spinner" />}
        <FlexBox
          className={uploading ? 'upload-image-card--uploading__card' : ''}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderColor={error ? 'error.main' : undefined}
          width={200}
          clone
        >
          <Card
            variant="outlined"
            style={{
              borderStyle: 'dashed',
              borderWidth: 2,
            }}
          >
            <input
              key={`input-${key}`}
              ref={ref}
              accept="image/*"
              style={{ display: 'none' }}
              id={`icon-button-file-${key ?? 'default'}`}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                const fileList = e.target.files
                if (fileList) {
                  const [image] = await Promise.all(
                    Array.from(fileList).map(async (file) => {
                      const compressed = await compressImage(file, 0.5)
                      const compressed64 = await fileToBase64(compressed)
                      return {
                        name: file.name,
                        base64: compressed64,
                      } as ImageSource
                    }),
                  )
                  setPreview(!!previewOnChange)
                  setImageSource(image)
                  onChange(image)
                }
              }}
              type="file"
            />
            <label htmlFor={`icon-button-file-${key ?? 'default'}`}>
              <IconButton aria-label="upload picture" component="span">
                <Avatar
                  style={{
                    width: 120,
                    height: 120,
                  }}
                  src={imageSource?.base64 || defaultSrc}
                  variant="rounded"
                >
                  <CameraAlt style={{ fontSize: 60 }} />
                </Avatar>
              </IconButton>
            </label>
            <Typography color="primary" align="center" display="block">
              {imageSource ? imageSource.name : title}
            </Typography>
          </Card>
        </FlexBox>
        {message && (
          <>
            <div className="mt-2" />
            <Typography variant="body2" color="error">
              {message}
            </Typography>
          </>
        )}
      </FlexBox>
    </>
  )
}
export default UploadImageCard
