import React from 'react'

import { TableCell, IconButton, Typography, TableRow, Grid, Box } from '@material-ui/core'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { PriceRangeFormValue, PriceRange } from '../../pages/products/create/ProductCreationFormValues'
import { Table as CustomTable, FlexBox, GeneralTextFieldForm } from '../../custom-components'
import { ButtonRadios } from '..'
import { AddCircle, Delete } from '@material-ui/icons'
import numeral from 'numeral'
import imgEmptyTable from '../../assets/images/image-empty-table.png'
const reCalculatePriceRange = (priceRange: PriceRange[], startIndex: number): PriceRange[] => {
  const slicedPriceRange = priceRange.slice(startIndex)
  priceRange[startIndex - 1].toAmount = Math.max(
    Number(priceRange[startIndex - 1].toAmount.toFixed()),
    priceRange[startIndex - 1].fromAmount + 1,
  )
  let previousToAmount = priceRange[startIndex - 1].toAmount
  const calculatedPriceRange = slicedPriceRange.map((current) => {
    const updatedFromAmount = previousToAmount + 1
    const updatedToAmount = Math.max(updatedFromAmount + 1, current.toAmount)
    const updatedPriceRange = {
      ...current,
      fromAmount: updatedFromAmount,
      toAmount: updatedToAmount,
    }
    // update previousToAmount for using in next index
    previousToAmount = updatedToAmount
    return updatedPriceRange
  })
  return [...priceRange.slice(0, startIndex), ...calculatedPriceRange]
}

const increaseStep = 6

const ModifyPriceRange = (): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { isSubmitted },
  } = useFormContext<PriceRangeFormValue>()

  const { append } = useFieldArray<PriceRangeFormValue>({
    control,
    name: 'priceRange',
  })

  const { priceRange } = watch()

  const typeOptions = [
    { id: 1, name: 'ช่วง' },
    { id: 2, name: 'หนึ่งจำนวน' },
  ]

  const getPreviousToAmount = (index: number) => {
    if (index === 0) return 0
    return priceRange[index - 1].toAmount
  }

  const handleRemoveItem = (index: number): void => {
    priceRange.splice(index, 1)
    setValue('priceRange', priceRange)
    if (index === 0 && priceRange.length > 0) {
      setValue('priceRange.0.fromAmount', 1)
    } else if (index !== priceRange.length) {
      setValue(`priceRange.${index}.fromAmount`, priceRange[index - 1].toAmount + 1)
    }
    if (isSubmitted) trigger()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTable<PriceRange>
          color="primary.dark"
          verticalAlign="middle"
          columns={[
            {
              name: 'รูปแบบ',
              dataIndex: 'type',
              fixedWidth: 200,
              render: (rowData, index) => {
                return (
                  <ButtonRadios
                    label=""
                    value={rowData.type}
                    items={typeOptions}
                    onChange={(value) => {
                      setValue(`priceRange.${index}.type`, value)
                      if (value === 2) {
                        setValue(`priceRange.${index}.toAmount`, getPreviousToAmount(index) + 1)
                        if (index + 1 < priceRange.length) {
                          setValue(`priceRange.${index + 1}.fromAmount`, getPreviousToAmount(index) + 2)
                        }
                      }
                    }}
                  />
                )
              },
            },
            {
              name: 'จำนวนที่',
              dataIndex: 'toAmount',
              fixedWidth: 240,
              render: (data, index) => {
                const { fromAmount, toAmount } = data
                const { type } = priceRange[index]
                return (
                  <FlexBox alignItems="flex-start" justifyContent="flex-end" gridGap={4}>
                    <Typography
                      variant="body2"
                      style={{ marginRight: type === 1 ? 0 : 124, marginTop: type === 1 ? 20 : 6 }}
                    >
                      {numeral(fromAmount).format('0,0')}&nbsp;
                    </Typography>
                    {type === 1 && (
                      <>
                        <Box component="span" marginTop="20px">
                          -
                        </Box>
                        <GeneralTextFieldForm
                          control={control}
                          name={`priceRange.${index}.toAmount`}
                          rules={{
                            required: 'กรุณากรอกจำนวน',
                            min: { value: fromAmount + 1, message: 'น้อยกว่าจำนวนตั้งต้น' },
                          }}
                          style={{ width: 144 }}
                          defaultValue={toAmount}
                          type="number"
                          onBlur={() => {
                            if (index + 1 < priceRange.length) {
                              const newPriceRange = reCalculatePriceRange(priceRange, index + 1)
                              setValue('priceRange', newPriceRange)
                              if (isSubmitted) trigger()
                            } else {
                              setValue(
                                `priceRange.${index}.toAmount`,
                                Math.max(
                                  Number(priceRange[index].toAmount.toFixed()),
                                  priceRange[index].fromAmount + 1,
                                ),
                              )
                            }
                          }}
                        />
                      </>
                    )}
                  </FlexBox>
                )
              },
            },
            {
              name: 'ราคาต่อหน่วย',
              dataIndex: 'price',
              numeric: true,
              fixedWidth: 240,
              render: ({ price }, index) => (
                <GeneralTextFieldForm
                  control={control}
                  name={`priceRange.${index}.price`}
                  rules={{ required: 'กรุณาใส่ราคาต่อหน่วย' }}
                  defaultValue={price}
                  type="number"
                  onBlur={({ target: { value } }) => {
                    if (Number(value) < 0) {
                      setValue(`priceRange.${index}.price`, 0)
                    }
                  }}
                />
              ),
            },
            {
              name: 'ราคารวม',
              numeric: true,
              render: (data: PriceRange) => {
                if (data.type === 1) {
                  return `${numeral(data.fromAmount * data.price).format('0,0.00')} - ${numeral(
                    data.toAmount * data.price,
                  ).format('0,0.00')} `
                }
                return numeral(data.fromAmount * data.price).format('0,0.00')
              },
            },
            {
              name: '',
              fixedWidth: 54,
              render: (_, index) => (
                <IconButton onClick={() => handleRemoveItem(index)}>
                  <Delete color="inherit" />
                </IconButton>
              ),
            },
          ]}
          data={priceRange}
          size="small"
          pagination={false}
          emptyImage={imgEmptyTable}
          emptyText="ยังไม่มีช่วงราคา"
          cleanSpace
          postCells={
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    const appendObject =
                      priceRange.length > 0
                        ? {
                            type: 1,
                            toAmount: priceRange[priceRange.length - 1].toAmount + increaseStep,
                            fromAmount: priceRange[priceRange.length - 1].toAmount + 1,
                            price: 0,
                          }
                        : {
                            type: 1,
                            toAmount: increaseStep,
                            fromAmount: 1,
                            price: 0,
                          }
                    append(appendObject)
                  }}
                >
                  <AddCircle color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          }
        />
      </Grid>
    </Grid>
  )
}

export default ModifyPriceRange
