import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { RootContext } from '../../../../../../..'
import CustomerProfileContext from '../../../CustomerProfileContext'
import { useForm } from 'react-hook-form'
import { Form, GeneralTextFieldForm } from '../../../../../../../custom-components'
import CustomerBillingAddressCreationFormValue from './CustomerBillingAddressCreationFormValue'
import { createCustomerBillingAddress } from '../../service'
import { useAddressData } from '../../../../../../../hooks/data/useAddressData'
import { District, Province, SubDistrict } from '../../../../../../../api/smartbestbuys-api/data/data-types'
import { getAddressText } from '../../../../../../../functions'

interface Props {
  onSuccess?: () => void
}

const CustomerBillingAddressCreationDialog: React.FC<DialogProps & Props> = (props) => {
  const { onClose, onSuccess, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)
  const { reload, customerProfile } = useContext(CustomerProfileContext)

  const { id: customerId } = customerProfile

  const [validPostalCode, setValidPostalCode] = useState(false)
  const [districtFilter, setDistrictFilter] = useState<District[]>([])
  const [provinceFilter, setProvinceFilter] = useState<Province[]>([])
  const [subDistrictFilter, setSubDistrictFilter] = useState<SubDistrict[]>([])

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<CustomerBillingAddressCreationFormValue>({
    defaultValues: {
      name: '',
      branchName: 'สำนักงานใหญ่',
      provinceId: '',
      districtId: '',
      subDistrictId: '',
    },
  })

  const { addressLine1, branchName, provinceId, districtId, subDistrictId, postalCode } = watch()

  const addressData = useAddressData()
  // eslint-disable-next-line
  const provinces = useMemo(() => addressData?.provinces ?? [], [(addressData?.provinces ?? []).length])
  // eslint-disable-next-line
  const districts = useMemo(() => addressData?.districts ?? [], [(addressData?.districts ?? []).length])
  // eslint-disable-next-line
  const subDistricts = useMemo(() => addressData?.subDistricts ?? [], [(addressData?.subDistricts ?? []).length])

  const addressText = useMemo(() => {
    return getAddressText({
      branchName: branchName,
      addressLine1: addressLine1,
      province: provinceId ? provinces.find((province) => province.id === Number(provinceId)) : undefined,
      district: districtId ? districts.find((district) => district.id === Number(districtId)) : undefined,
      subDistrict: subDistrictId
        ? subDistricts.find((subDistrict) => subDistrict.id === Number(subDistrictId))
        : undefined,
      postalCode: postalCode ? postalCode.toString() : '',
    })
    // eslint-disable-next-line
  }, [
    addressLine1,
    branchName,
    provinceId,
    districtId,
    subDistrictId,
    postalCode,
    provinces.length,
    districts.length,
    subDistricts.length,
  ])

  useEffect(() => {
    if (provinceId && !validPostalCode) {
      const districtsFilter = districts.filter((d) => d.provinceId === Number(provinceId))

      if (districtsFilter) {
        setDistrictFilter([...districtsFilter])
      }
    }
    // eslint-disable-next-line
  }, [setValue, provinceId, validPostalCode])

  useEffect(() => {
    if (subDistrictId && validPostalCode) {
      const initDistrictId = subDistricts.find((sd) => sd.id === Number(subDistrictId))?.districtId
      const district = districts.find((d) => d.id === initDistrictId)

      if (district) {
        setDistrictFilter([district])
        setValue('districtId', initDistrictId?.toString())
      }
    }
    // eslint-disable-next-line
  }, [setValue, subDistrictId, validPostalCode])

  useEffect(() => {
    if (districtId && validPostalCode) {
      const initProvinceId = districts.find((d) => d.id === Number(districtId))?.provinceId
      const province = provinces.find((p) => p.id === initProvinceId)

      if (province) {
        setProvinceFilter([province])
        setValue('provinceId', initProvinceId?.toString())
      }
    } else if (districtId && !validPostalCode) {
      const subDistrictsFilter = subDistricts.filter((sd) => sd.districtId === Number(districtId))

      if (subDistrictsFilter) {
        setSubDistrictFilter([...subDistrictsFilter])
      }
    }
    // eslint-disable-next-line
  }, [setValue, districtId, validPostalCode])

  const handleSubmitCustomerBillingAddressCreationForm = async (value: CustomerBillingAddressCreationFormValue) => {
    const response = await createCustomerBillingAddress(customerId, value)
    if (response?.status === 201) {
      triggerSnackbar('เพิ่มที่อยู่เปิดบิลสำเร็จ')
      reload()
      onClose && onClose({}, 'escapeKeyDown')
      reset()
      onSuccess && onSuccess()
    } else {
      triggerSnackbar('เพิ่มที่อยู่เปิดบิลไม่สำเร็จ')
    }
    return true
  }

  const disabledSubDistrict = !validPostalCode && !provinceId && !districtId
  const disabledDistrict = !!(subDistrictId && validPostalCode) || (!validPostalCode && !provinceId)
  const disabledProvince = !!(districtId && validPostalCode)

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitCustomerBillingAddressCreationForm)}>
        <DialogTitle>เพิ่มที่อยู่เปิดบิล</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="name"
                rules={{ required: 'กรุณาใส่ชื่อ' }}
                label="ชื่อเรียกที่อยู่ *"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="branchName"
                label="สาขา (ถ้ามี)"
                onPostChange={() => setValue('overrideAddress', undefined)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="postalCode"
                rules={{ required: 'กรุณาใส่รหัสไปรษณีย์' }}
                type="number"
                label="รหัสไปรษณีย์ *"
                defaultValue=""
                onPostChange={(postalCode) => {
                  const subDistrict = subDistricts.find((sd) => sd.postalCode === postalCode)
                  if (subDistrict) {
                    setValidPostalCode(true)
                    setValue('subDistrictId', subDistrict.id.toString())
                  } else {
                    setValidPostalCode(false)
                    setValue('provinceId', '')
                    setValue('districtId', '')
                    setValue('subDistrictId', '')
                  }
                  setValue('overrideAddress', undefined)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="provinceId"
                rules={{ required: 'กรุณาเลือกจังหวัด' }}
                label="จังหวัด *"
                select
                disabled={disabledProvince}
                onPostChange={() => setValue('overrideAddress', undefined)}
              >
                {(validPostalCode ? provinceFilter : provinces).map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="districtId"
                rules={{ required: 'กรุณาเลือกเขตหรืออำเภอ' }}
                label="เขต / อำเภอ *"
                select
                disabled={disabledDistrict}
                onPostChange={() => setValue('overrideAddress', undefined)}
              >
                {districtFilter.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="subDistrictId"
                rules={{ required: 'กรุณาเลือกแขวงหรือตำบล' }}
                label="แขวง / ตำบล *"
                select
                disabled={disabledSubDistrict}
                onPostChange={() => setValue('overrideAddress', undefined)}
              >
                {validPostalCode
                  ? subDistricts
                      .filter((sd) => sd.postalCode === postalCode || !postalCode)
                      .map((element) => {
                        const district = districts.find((d) => d.id === element.districtId)
                        return (
                          <MenuItem key={element.id} value={element.id}>
                            {element.nameLocal} (เขต {district?.nameLocal})
                          </MenuItem>
                        )
                      })
                  : subDistrictFilter.map((element) => {
                      const district = districts.find((d) => d.id === element.districtId)
                      return (
                        <MenuItem key={element.id} value={element.id}>
                          {element.nameLocal} (เขต {district?.nameLocal})
                        </MenuItem>
                      )
                    })}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="addressLine1"
                label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
                rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
                defaultValue=""
                onPostChange={() => setValue('overrideAddress', undefined)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="phoneNumber"
                type="tel"
                rules={{ required: 'กรุณาใส่เบอร์โทร', maxLength: 10, minLength: 10 }}
                label="เบอร์โทร *"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="faxNumber" label="เบอร์แฟกซ์ (ถ้ามี)" defaultValue="" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="overrideCustomerName"
                label="ชื่อลูกค้าบนเอกสาร"
                defaultValue=""
                placeholder={customerProfile.nameLocal}
                InputLabelProps={{ shrink: true }}
              />
              <div>
                ค่าเริ่มต้น
                <span
                  className="text-red-800 underline cursor-pointer ml-1"
                  onClick={() => {
                    setValue('overrideCustomerName', customerProfile.nameLocal)
                  }}
                >
                  {customerProfile.nameLocal}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="overrideTaxNumber"
                label="เลขผู้เสียภาษีลูกค้าบนเอกสาร"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <GeneralTextFieldForm
                control={control}
                name="overrideAddress"
                label="ที่อยู่ลูกค้าบนเอกสาร"
                defaultValue=""
                InputLabelProps={{ shrink: addressText.length > 0 }}
                placeholder={addressText}
              />
              <div>
                ค่าเริ่มต้น
                <span
                  className="text-red-800 underline cursor-pointer ml-1"
                  onClick={() => {
                    setValue('overrideAddress', addressText)
                  }}
                >
                  {addressText}
                </span>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            ยืนยันเพิ่มที่อยู่เปิดบิล
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CustomerBillingAddressCreationDialog
