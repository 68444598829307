import {
  Box,
  Button,
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import { Close, Photo, Print } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { TransportShipingTaskMobileData_Product } from '../../../api/smartbestbuys-api'
import {
  BreadcumbsList,
  FlexBox,
  Link,
  SubTopic,
  withLayout,
  CustomButton,
  TypographyWithLabel,
  Table,
  Avatar,
} from '../../../custom-components'
import { PageName, TaskStatus, TaskType } from '../../../enums'
import { formatNumberInt } from '../../../functions/number-utils'
import useTransportMessengerTask from '../../../hooks/transport/useTrasnportMessengerTask'
import {
  ConditionTooltip,
  Loading,
  Space,
  TaskStatusChip,
  TypographyLink,
  PrepareProductIcon,
  PrepareDocumentIcon,
} from '../../../shared-components'
import ConfirmCancelDialog from './ConfirmCancelDialog'
import ConfirmPickupFailureDialog from './ConfirmPickupFailureDialog'
import ConfirmPickupResultDialog from './ConfirmPickupResultDialog'
import ConfirmPickupTaskDialog from './ConfirmPickupTaskDialog'

interface Props {
  id: number
}

const PickupTaskPage = (props: Props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.MESSENGER_PICKUP_TASK)

  const { id } = props

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [finish1Open, setFinish1Open] = useState(false)
  const [finish2Open, setFinish2Open] = useState(false)
  const history = useHistory()

  const [pickupTask, setPickupTask] = useTransportMessengerTask(id)

  if (!pickupTask) return <Loading />

  const breadcumbsItems = [
    { url: '/transport-pickup-tasks/messenger-tasks', name: 'รายการงานรับของ' },
    { name: `งานรับของ ${pickupTask.code}` },
  ]

  const ableToConfirmTask = pickupTask.status.id === TaskStatus.Pending
  const ableToFinishTask = pickupTask.status.id === TaskStatus.Processing
  const ableToConfirmCancel = pickupTask.status.id === TaskStatus.PreCancel

  const showFailureMessage = [TaskStatus.Failed, TaskStatus.PreCancel, TaskStatus.Canceled].includes(
    pickupTask.status.id,
  )

  return (
    <>
      {ableToConfirmTask && (
        <ConfirmPickupTaskDialog
          open={confirmOpen}
          data={{ id: pickupTask.transportTask.id, subTaskId: pickupTask.id }}
          onSuccess={(data) => {
            setPickupTask((prev) => {
              if (!!prev)
                return {
                  ...prev,
                  ...data,
                }
            })
            setConfirmOpen(false)
          }}
          onClose={() => setConfirmOpen(false)}
        />
      )}
      {ableToConfirmCancel && (
        <ConfirmCancelDialog
          open={confirmOpen}
          data={{ id: pickupTask.id }}
          onSuccess={(data) => {
            setPickupTask((prev) => {
              if (!!prev)
                return {
                  ...prev,
                  ...data,
                }
            })
            setConfirmOpen(false)
          }}
          onClose={() => setConfirmOpen(false)}
        />
      )}
      {ableToFinishTask && (
        <>
          <FinishTaskSelectionDialog
            onSuccess={() => {
              setFinish1Open(true)
            }}
            onFail={() => {
              setFinish2Open(true)
            }}
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
          />
          <ConfirmPickupResultDialog
            open={finish1Open}
            data={{ id: pickupTask.id }}
            onSuccess={(data) => {
              setPickupTask((prev) => ({
                ...prev,
                ...data,
              }))
              setFinish1Open(false)
              setConfirmOpen(false)
            }}
            onClose={() => setFinish1Open(false)}
          />
          <ConfirmPickupFailureDialog
            open={finish2Open}
            data={{
              id: pickupTask.id,
            }}
            onSuccess={(data) => {
              setPickupTask(data)
              setFinish2Open(false)
              setConfirmOpen(false)
            }}
            onClose={() => setFinish2Open(false)}
          />
        </>
      )}
      <Box p={3} clone>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <BreadcumbsList items={breadcumbsItems} />
          </Grid>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <FlexBox gridGap={8}>
                <TaskStatusChip label={pickupTask.status.name} status={pickupTask.status.id} />
                {showFailureMessage ? (
                  <FlexBox alignItems="center">
                    <Typography variant="body2" color="error">
                      เหตุผล: {pickupTask.result?.failureMessage}
                    </Typography>
                  </FlexBox>
                ) : (
                  pickupTask.preparationTaskStatuses?.map((pt) => {
                    const Icon =
                      pt.type.id === TaskType.WarehousePrepareProduct ? PrepareProductIcon : PrepareDocumentIcon
                    return (
                      <FlexBox alignItems="center" pl={1}>
                        {pt.success ? (
                          <Icon size="small" status={TaskStatus.Done} />
                        ) : (
                          <Icon size="small" status={undefined} />
                        )}
                        {pt.success ? (
                          <Box color="success.main" clone>
                            <Typography>งานเสร็จสิ้น</Typography>
                          </Box>
                        ) : (
                          <Typography color="textSecondary">ยังเตรียมไม่เสร็จ</Typography>
                        )}
                      </FlexBox>
                    )
                  })
                )}
              </FlexBox>

              <FlexBox>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push(`/transport-tasks/${pickupTask.transportTask.id}/pickup-cover-pdf`)}
                >
                  <Print />
                  <span className="ml-1">พิมพ์ใบปะหน้า</span>
                </Button>
                {ableToConfirmTask && (
                  <>
                    <Space direction="horizontal" />
                    <ConditionTooltip showTooltip={!pickupTask.readyToConfirm} title="เตรียมสินค้า / เอกสารยังไม่ครบ">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!pickupTask.readyToConfirm}
                        onClick={() => setConfirmOpen(true)}
                      >
                        ยืนยันรับงาน
                      </Button>
                    </ConditionTooltip>
                  </>
                )}
                {ableToFinishTask && (
                  <>
                    <Space direction="horizontal" />
                    <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>
                      จบงาน
                    </Button>
                  </>
                )}
                {ableToConfirmCancel && (
                  <>
                    <Space direction="horizontal" />
                    <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>
                      รับทราบการยกเลิกงาน
                    </Button>
                  </>
                )}
              </FlexBox>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <FlexBox p={3} clone justifyContent="space-between" alignItems="center">
              <Paper>
                <SubTopic>งานจัดส่งอ้างอิง {pickupTask.transportTask.code}</SubTopic>
                <Link
                  color="primary"
                  to={`/transport-pickup-tasks/${pickupTask.transportTask.id}/profile`}
                  target="_blank"
                >
                  ไปที่หน้ารายละเอียดงานขนส่ง
                </Link>
              </Paper>
            </FlexBox>
          </Grid>
          <Grid item xs={12}>
            <Box p={3} clone>
              <Paper>
                <SubTopic>รายละเอียดขนส่ง</SubTopic>
                <Space />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="คำร้องขออ้างอิง:">
                      {pickupTask.customerRequisitionInfo?.code}
                    </TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="ฝ่ายขายผู้รับผิดชอบ:">
                      {pickupTask.saleOrderInfo?.assignee}
                    </TypographyWithLabel>
                  </Grid>
                  {/* <Grid item xs={6}> */}
                  {/* <TypographyWithLabel label="เงื่อนไขการชำระเงิน:"> */}
                  {/* {pickupTask.saleOrderInfo?.paymentCondition} */}
                  {/* </TypographyWithLabel> */}
                  {/* </Grid> */}
                  {/* {pickupTask.shippingInfo.chargeAmount && (
                    <Grid item xs={6}>
                      <TypographyWithLabel label="ยอดเงินที่ต้องเก็บ:" color="primary">
                        {formatNumber(pickupTask.shippingInfo.chargeAmount)} บาท
                      </TypographyWithLabel>
                    </Grid>
                  )} */}
                  {/* <Grid item xs={6}>
                    <TypographyWithLabel label="ขนส่งที่เลือกใช้:">
                      {pickupTask.shippingInfo.shippingProvider}
                    </TypographyWithLabel>
                  </Grid> */}
                  <Grid item xs={6}>
                    <TypographyWithLabel label="ผู้รับของ:">{pickupTask.assignee.name}</TypographyWithLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TypographyWithLabel label="หมายเหตุ:">{pickupTask.remark ?? '-'}</TypographyWithLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Box p={3} clone>
                      <Paper variant="outlined">
                        <SubTopic>{pickupTask.saleOrderInfo?.customerName}</SubTopic>
                        <Space />
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TypographyWithLabel label="รายละเอียดที่อยู่:">
                              {pickupTask.shippingInfo.address}
                            </TypographyWithLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <TypographyWithLabel label="เบอร์โทร:">
                              {pickupTask.shippingInfo.consignee.phoneNumber}
                            </TypographyWithLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="overflow-hidden hover:overflow-unset">
                              <TypographyWithLabel label="ลิงก์ googlemap:">
                                {pickupTask.shippingInfo.googleMapLink ? (
                                  <TypographyLink
                                    target="_blank"
                                    href={pickupTask.shippingInfo.googleMapLink}
                                    color="secondary.main"
                                  >
                                    {pickupTask.shippingInfo.googleMapLink}
                                  </TypographyLink>
                                ) : (
                                  '-'
                                )}
                              </TypographyWithLabel>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ชื่อผู้ติดต่อ:">
                              {pickupTask.shippingInfo.consignee.name}
                            </TypographyWithLabel>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          {pickupTask.shippingInfo.products && (
            <Grid item xs={12}>
              <Box p={3} clone>
                <Paper>
                  <SubTopic>ข้อมูลสินค้าสำหรับขนส่ง</SubTopic>
                  <Space />
                  <Table<TransportShipingTaskMobileData_Product & { id: number }>
                    color="primary.dark"
                    columns={[
                      { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70 },
                      {
                        name: 'ชื่อสินค้า/บริการ',
                        render: ({ imageUrl, code, name }) => {
                          return (
                            <Box display="flex" alignItems="flex-start">
                              <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                                <Photo />
                              </Avatar>
                              <FlexBox ml={1} flexDirection="column">
                                <Typography variant="body2" color="textSecondary">
                                  {code}
                                </Typography>
                                <Typography variant="body2">{name}</Typography>
                              </FlexBox>
                            </Box>
                          )
                        },
                      },
                      {
                        name: 'จำนวนที่จะรับ',
                        render: ({ amount, unit }) => `${formatNumberInt(amount)} ${unit ?? ''}`,
                        align: 'right',
                        fixedWidth: 200,
                      },
                    ]}
                    data={pickupTask.shippingInfo.products as any}
                    cleanSpace
                    pagination={false}
                    size="small"
                  />
                </Paper>
              </Box>
            </Grid>
          )}
          {/* {pickupTask.shippingInfo.documents && (
            <Grid item xs={12}>
              <Box p={3} clone>
                <Paper>
                  <SubTopic>รายการเอกสารสำหรับขนส่ง</SubTopic>
                  <Space />
                  <Table<TransportShipingTaskMobileData_Document & { id: number }>
                    color="secondary.dark"
                    columns={[
                      { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70 },
                      {
                        name: 'ชื่อเอกสาร',
                        dataIndex: 'name',
                      },
                      {
                        name: 'จำนวนที่จะส่ง',
                        render: ({ amount }) => `${formatNumberInt(amount)} ใบ`,
                        align: 'right',
                        fixedWidth: 200,
                      },
                    ]}
                    data={pickupTask.shippingInfo.documents as any}
                    cleanSpace
                    pagination={false}
                    size="small"
                  />
                </Paper>
              </Box>
            </Grid>
          )} */}
        </Grid>
      </Box>
    </>
  )
}

export default withLayout(PickupTaskPage)

interface FinishTaskSelectionDialogProps extends DialogProps {
  onSuccess: () => void
  onFail: () => void
}

const FinishTaskSelectionDialog = (props: FinishTaskSelectionDialogProps) => {
  const { onClose, onSuccess, onFail, ...otherProps } = props
  return (
    <Dialog {...otherProps} fullWidth maxWidth="sm">
      <DialogTitle>
        เลือกผลการจบงาน
        <IconButton
          onClick={() => {
            if (!!onClose) onClose({}, 'escapeKeyDown')
          }}
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FlexBox flexDirection="column">
          <CustomButton fullWidth color="success" onClick={onSuccess}>
            รับสำเร็จ
          </CustomButton>
          <Space />
          <CustomButton fullWidth color="error" onClick={onFail}>
            รับไม่สำเร็จ
          </CustomButton>
        </FlexBox>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}
