import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    image: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      width: '100%',
      height: 150,
    },
    chip: {
      position: 'absolute',
      bottom: theme.spacing(1),
    },
    productName: {
      height: 40,
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    stockAmount: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
)
