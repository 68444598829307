/* eslint-disable react-hooks/exhaustive-deps */
import {
  GetSOPaymentTransactionTableDataRequestSearchParams,
  ISOPaymentTransactionTableData_Data,
  getSOPaymentTransactionTableDataAPI,
} from '../../api/smartbestbuys-api'
import { useTableData } from '../useTablaData'

export interface ISOPaymentTransactionTableData_Search {
  code?: string
  saleOrderCode?: string
  customerName?: string
  phoneNumber?: string
  statusIds?: number[]
  paymentMethodIds?: number[]
  startDate?: Date
  endDate?: Date
  pendingVerifyStatement?: string
  withholdingTaxDocumentReceived?: string
  hasWithholdingTax?: string
  statementStartDate?: Date
  statementEndDate?: Date
  hasTaxInvoice?: string
}

const _buildRequestParams = (
  _s: ISOPaymentTransactionTableData_Search,
): GetSOPaymentTransactionTableDataRequestSearchParams => {
  const {
    code,
    saleOrderCode,
    customerName,
    phoneNumber,
    statusIds,
    paymentMethodIds,
    pendingVerifyStatement,
    startDate,
    endDate,
    hasWithholdingTax,
    withholdingTaxDocumentReceived,
    statementStartDate,
    statementEndDate,
    hasTaxInvoice,
  } = _s

  const _hasWithholdingTax = hasWithholdingTax?.toString()
  const _hasTaxInvoice = hasTaxInvoice?.toString()
  const _documentReceived = withholdingTaxDocumentReceived?.toString()

  return {
    code,
    saleOrderCode,
    customerName,
    phoneNumber,
    statusIds: statusIds ? statusIds.join(',') : undefined,
    paymentMethodIds: paymentMethodIds ? paymentMethodIds.join(',') : undefined,
    pendingVerifyStatement: pendingVerifyStatement || 'false',
    hasWithholdingTax: _hasWithholdingTax === 'true' ? 'true' : undefined,
    hasTaxInvoice: _hasTaxInvoice,
    withholdingTaxDocumentReceived:
      _hasWithholdingTax === 'true' && _documentReceived === 'false' ? 'false' : undefined,
    startDate: startDate ? new Date(startDate).toISOString() : undefined,
    endDate: endDate ? new Date(endDate).toISOString() : undefined,
    statementStartDate: statementStartDate ? new Date(statementStartDate).toISOString() : undefined,
    statementEndDate: statementEndDate ? new Date(statementEndDate).toISOString() : undefined,
  }
}

export const useSOPaymentTransactionTableData = () =>
  useTableData<
    ISOPaymentTransactionTableData_Data,
    {},
    ISOPaymentTransactionTableData_Search,
    GetSOPaymentTransactionTableDataRequestSearchParams
  >(
    0,
    10,
    {
      code: '',
      saleOrderCode: '',
      customerName: '',
      phoneNumber: '',
      statusIds: [],
      paymentMethodIds: [],
      pendingVerifyStatement: 'false',
      withholdingTaxDocumentReceived: undefined,
      hasWithholdingTax: undefined,
    },
    getSOPaymentTransactionTableDataAPI,
    _buildRequestParams,
  )
