import { useContext, useMemo, useState } from 'react'
import { FilteredTable, FlexBox, Link, Topic, withLayout } from '../../../custom-components'
import { PageName, PaymentMethod, PaymentRequisitionStatus } from '../../../enums'
import { RootContext } from '../../..'
import { Box, Grid, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { PaymentRequisitionStatusChip, Space } from '../../../shared-components'
import { DateTime } from 'luxon'
import { defaultToday } from '../../../data'
import PayoutDailyPaymentContext from './PayoutDailyPaymentContext'
import { usePaymentRequisitionsPayoutDailyPayment } from '../../../hooks'
import { IGetPaymentRequisitionPayoutDaily } from '../../../api/smartbestbuys-api'
import numeral from 'numeral'

const PayoutDailyPaymentPage = () => {
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.FINANCE_PAYOUT_DAILY_PAYMENT)

  // state
  const [paymentDate, setPaymentDate] = useState(defaultToday)

  const data = usePaymentRequisitionsPayoutDailyPayment(DateTime.fromJSDate(paymentDate))

  const quantityLabels = ['จำนวนรวม', 'จำนวนโดยโอน', 'จำนวนโดยเช็ค']
  const quantityValues = useMemo(() => {
    if (!data) return ['0', '0', '0']
    return [
      data.amountSummary.total.toString(),
      data.amountSummary.transfer.toString(),
      data.amountSummary.cheque.toString(),
    ]
  }, [data])

  const payoutLabels = ['รายจ่ายรวม', 'รายจ่ายโดยโอน', 'รายจ่ายโดยเช็ค']
  const payoutValues = useMemo(() => {
    if (!data) return ['0.00 บาท', '0.00 บาท', '0.00 บาท']
    return [
      `${numeral(data.paidSummary.total).format('0,0.00')} บาท`,
      `${numeral(data.paidSummary.transfer).format('0,0.00')} บาท`,
      `${numeral(data.paidSummary.cheque).format('0,0.00')} บาท`,
    ]
  }, [data])

  return (
    <Box p={2}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Topic>รายการกระแสเงินสดจ่ายรายวัน</Topic>
        <KeyboardDatePicker
          size="small"
          label="วันที่"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          value={paymentDate}
          onChange={(date) => date && setPaymentDate(date)}
        />
      </FlexBox>
      <Space />

      <Box bgcolor="white" p={2}>
        <Grid container spacing={2} className="p-0">
          <Grid item md={6}>
            <PayoutDailyPaymentContext labels={quantityLabels} values={quantityValues} color="secondary.dark" />
          </Grid>
          <Grid item md={6}>
            <PayoutDailyPaymentContext labels={payoutLabels} values={payoutValues} color="primary.dark" />
          </Grid>
        </Grid>
        <FilteredTable<IGetPaymentRequisitionPayoutDaily>
          downloadAsCsv={true}
          csvFilename={`รายการกระแสเงินสดจ่ายรายวันประจำวันที่${paymentDate.toDateString()}.csv`}
          disableDateRangeFilter
          columns={[
            {
              name: 'สถานะการจ่าย',
              render: (item) => <PaymentRequisitionStatusChip status={item.status.id} label={item.status.name} />,
              csvRender: (item) => item.status.name,
            },
            {
              name: 'เลขที่การทำจ่าย',
              render: (item) => (
                <Link color="primary" to={`/purchase-payments/${item.id}/profile`}>
                  {item.paymentCode ?? '-'}
                </Link>
              ),
              csvRender: (item) => item.status.name,
            },
            {
              name: 'ชื่อผู้ขาย',
              dataIndex: 'vendor',
              render: (item) => (
                <Typography className="displayInline" variant="body2">
                  {item.vendor.name}
                </Typography>
              ),
              csvRender: (item) => item.vendor.name,
            },
            {
              name: 'ยอดทำเบิก',
              dataIndex: 'total',
              numeric: true,
              format: '0,0.00',
              csvRender: (item) => item.total,
            },
            {
              name: 'หัก ณ ที่จ่าย',
              dataIndex: 'withholdingTaxAmount',
              numeric: true,
              format: '0,0.00',
              csvRender: (item) => item.withholdingTaxAmount,
            },
            {
              name: 'ยอดทำเบิกสุทธิ',
              dataIndex: 'totalNet',
              numeric: true,
              format: '0,0.00',
              csvRender: (item) => item.totalNet,
            },
            {
              name: 'วิธีจ่ายเงิน',
              dataIndex: 'paymentMethod',
              render: (item) => (
                <Typography className="displayInline" variant="body2">
                  {item.paymentMethod.name}
                </Typography>
              ),
              csvRender: (item) => item.paymentMethod.name,
            },
          ]}
          data={data?.paymentRequisitions ?? []}
          color="primary.dark"
          size="small"
          pagination={false}
          filters={[
            {
              name: 'สถานะการจ่าย',
              values: [
                {
                  name: 'รอยืนยันการโอนเงิน',
                  value: PaymentRequisitionStatus.PendingTransfer,
                },
                {
                  name: 'รอยืนยันการออกเช็ค',
                  value: PaymentRequisitionStatus.PendingCheque,
                },
                {
                  name: 'รอใบเสร็จจากผู้ขาย',
                  value: PaymentRequisitionStatus.PendingReceipt,
                },
                {
                  name: 'รอตรวจสอบรายการธนาคาร',
                  value: PaymentRequisitionStatus.PendingStatement,
                },
                {
                  name: 'ทำจ่ายเสร็จสิ้น',
                  value: PaymentRequisitionStatus.Success,
                },
              ],
              filter: (d, values) => values.includes(d.status.id),
            },
            {
              name: 'วิธีจ่ายเงิน',
              values: [
                {
                  name: 'โอน',
                  value: PaymentMethod.Transfer,
                },
                {
                  name: 'เช็ค',
                  value: PaymentMethod.Cheque,
                },
              ],
              filter: (d, values) => values.includes(d.paymentMethod.id),
            },
          ]}
          cleanSpace
        />
      </Box>
    </Box>
  )
}

export default withLayout(PayoutDailyPaymentPage, true)
