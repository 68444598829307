import { useState, useEffect } from 'react'
import { getDocumentById, Document } from '../api/smartbestbuys-api'

export const useDocument = <DState>(id: number): Document<any, DState> | undefined => {
  const [document, setDocument] = useState<Document<any, any> | undefined>(undefined)

  const _fetchDocumentById = async (id: number) => {
    const response = await getDocumentById(id)
    setDocument(response?.data)
  }

  useEffect(() => {
    if (id) {
      _fetchDocumentById(id)
    }
  }, [id])

  return document
}
