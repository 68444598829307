import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { KeyboardDatePickerForm, SubTopic } from '../../../../../../custom-components'
import { ButtonRadios } from '../../../../../../shared-components'
import { CustomerPickUpMethod, customerPickUpMethods } from '../../../../../../types'
import SOUpdationFormValue from '../SOUpdationFormValues'

const ReceiveProductsOption: React.FC = () => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<SOUpdationFormValue>()

  const { customerPickUpMethod, dueDate } = getValues()
  const activeMethod = customerPickUpMethods.find((cpm) => cpm.id === customerPickUpMethod)

  if (customerPickUpMethod === 3 && dueDate !== undefined) setValue('customerPickUpMethod', undefined)

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>การรับสินค้า</SubTopic>
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonRadios
            label="วิธีการรับสินค้า *"
            defaultValue={customerPickUpMethod}
            items={customerPickUpMethods}
            onChange={(id) => setValue('customerPickUpMethod', id)}
          />
          {activeMethod?.warningMessage && (
            <Box color="warning.dark" mt={0.5}>
              <Typography variant="body2">{activeMethod.warningMessage}</Typography>
            </Box>
          )}
        </Grid>
        {customerPickUpMethod !== CustomerPickUpMethod.PickUpAtShop && (
          <Grid item xs={12} md={6}>
            <KeyboardDatePickerForm
              control={control}
              label={
                customerPickUpMethod === CustomerPickUpMethod.PickUpWithTime
                  ? 'วันที่ลูกค้าต้องการ'
                  : 'วันที่ลูกค้าต้องการ (ถ้ามี)'
              }
              name="dueDate"
              value={dueDate}
              defaultValue={null}
              // inputVariant="outlined"
              size="small"
              fullWidth
              error={!!errors.dueDate}
              helperText={errors.dueDate?.message}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default ReceiveProductsOption
