import { createProductAPI } from '../../../api/smartbestbuys-api'
import ProductCreationFormValue from './ProductCreationFormValues'
import { ProductCreationRequest, ProductPriceRequest } from '../../../api/smartbestbuys-api'

const mapPriceRange = (priceRange: ProductPriceRequest) => {
  const { fromAmount, toAmount, price } = priceRange
  return {
    fromAmount,
    toAmount,
    price,
  }
}

const parseProductCreationFormValue = (value: ProductCreationFormValue): ProductCreationRequest => {
  const prices = value.priceRange.map(mapPriceRange)

  return {
    typeId: value.typeOfProduct,
    code: value?.customCodeProduct,
    nameLocal: value.productNameTh,
    nameEn: value?.productNameEn === '' ? undefined : value?.productNameEn,
    unitLocal: value.productUnitTh,
    unitEn: value?.productUnitEn === '' ? undefined : value?.productUnitEn,
    businessUnitId: value?.businessId ? Number(value?.businessId) : undefined,
    categoryId: value?.categoryProductId ? Number(value.categoryProductId) : undefined,
    subCategoryId: value?.subCategoryProductId ? Number(value.subCategoryProductId) : undefined,
    warrantyPeriodDay: value.productWarranty,
    stockAmount: 0,
    productImageBase64: value.productImageBase64,
    storageId: value?.storageProductId ? Number(value.storageProductId) : undefined,
    prices,
    leadTime: value.leadTime,
    safetyStockPolicy: value.safetyStockPolicy,
    dealerPrice: value.dealerPrice,
    brand: value.brand,
    color: value.color,
    material: value.material,
    widthInMm: value?.widthInMm ? value.widthInMm * value.unitSizeId : 0,
    heightInMm: value?.heightInMm ? value.heightInMm * value.unitSizeId : 0,
    depthInMm: value?.depthInMm ? value.depthInMm * value.unitSizeId : 0,
    weightInGram: value?.weightInGram ? value.weightInGram * value.unitWeightId : 0,
    warrantyCondition: value.warrantyCondition,
    karajicMatrixId: value.karajicMatrixId,
  }
}

export const createProductService = (values: ProductCreationFormValue) => {
  const parseFormValue = parseProductCreationFormValue(values)
  return createProductAPI(parseFormValue)
}
