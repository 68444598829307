import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { FlexBox, TypographyWithLabel } from '../../../../custom-components'
import { TaskStatus, TaskType } from '../../../../enums'
import { dateFormat } from '../../../../functions'
import { Loading, TaskStatusChip } from '../../../../shared-components'
import { CustomerRequisitionProfileContext } from '../CustomerRequisitionProfilePage'
import { useStyles } from '../styles'
import ShippingProductTable from './ShippingProductTable'

const ShippingTaskList: React.FC = () => {
  const classes = useStyles()
  const customerRequisitionProfile = useContext(CustomerRequisitionProfileContext)
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined)

  if (!customerRequisitionProfile) return <Loading />

  const { tasks } = customerRequisitionProfile

  const crShippingTasks = tasks.filter((t) => [TaskType.TransportScheduleTask].includes(t.type.id))

  return (
    <Grid container spacing={2}>
      {crShippingTasks.map((task, index) => {
        const isExpanded: boolean = activeIndex === index
        const handleExpand = () =>
          setActiveIndex((prev) => {
            if (isExpanded) return undefined
            return index
          })
        const { dueDate, cancelledMessage } = task

        const childrenTasks = tasks.filter((t) => t.parentTaskId === task.id)

        const warehousePrepareProductTask = childrenTasks.find((t) => t.type.id === TaskType.WarehousePrepareProduct)
        const messengerShippingTask = childrenTasks.find((t) => t.type.id === TaskType.MessengerShippingTask)
        const warehouseReturnProduct = childrenTasks.find((t) => t.type.id === TaskType.WarehouseReceivedProduct)
        const shippingProductTableData = task.state?.items ?? []

        const messengerFailureMessage = messengerShippingTask?.state?.failureMessages

        return (
          <Grid item xs={12} key={task.id}>
            <Accordion
              square
              key={task.id.toString()}
              expanded={isExpanded}
              onChange={handleExpand}
              TransitionProps={{ unmountOnExit: true }}
              className={classes.accordion}
            >
              <AccordionSummary expandIcon={<ExpandMore />} classes={{ content: classes.accordionSummary }}>
                <Typography variant="h5">{task.code}</Typography>
                <TaskStatusChip status={task.status.id} label={task.status.name} />
                {task.status.id !== TaskStatus.Canceled && !!dueDate && (
                  <TypographyWithLabel label="วันที่ขนส่ง:" variant="body2">
                    {dateFormat(dueDate)}
                  </TypographyWithLabel>
                )}
                {!!cancelledMessage && (
                  <TypographyWithLabel label="เหตุผลที่ยกเลิก:" variant="body2" color="primary">
                    {cancelledMessage}
                  </TypographyWithLabel>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FlexBox alignItems="center" gridGap={16}>
                      {!!warehousePrepareProductTask && (
                        <TypographyWithLabel label="งานเตรียมสินค้า:" alignItems="center">
                          <TaskStatusChip
                            status={warehousePrepareProductTask.status.id}
                            label={warehousePrepareProductTask.status.name}
                          />
                        </TypographyWithLabel>
                      )}
                      {!!messengerShippingTask && (
                        <TypographyWithLabel label="งานส่งของ:" alignItems="center">
                          <TaskStatusChip
                            status={messengerShippingTask.status.id}
                            label={messengerShippingTask.status.name}
                          />
                        </TypographyWithLabel>
                      )}
                      {!!warehouseReturnProduct && (
                        <TypographyWithLabel label="งานคืนสต๊อก:" alignItems="center">
                          <TaskStatusChip
                            status={warehouseReturnProduct.status.id}
                            label={warehouseReturnProduct.status.name}
                          />
                        </TypographyWithLabel>
                      )}
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12}>
                    <FlexBox alignItems="center" gridGap={16}>
                      {!!messengerFailureMessage?.canceled && (
                        <TypographyWithLabel label="เหตุผลที่ยกเลิก:" variant="body2" color="primary">
                          {messengerFailureMessage?.canceled}
                        </TypographyWithLabel>
                      )}
                      {!!messengerFailureMessage?.failed && (
                        <TypographyWithLabel label="เหตุผลที่ส่งไม่สำเร็จ:" variant="body2" color="primary">
                          {messengerFailureMessage?.failed}
                        </TypographyWithLabel>
                      )}
                      {!!messengerShippingTask?.state?.nextShippingDate && (
                        <TypographyWithLabel label="วันที่ต้องไปส่งใหม่:" variant="body2" color="primary">
                          {dateFormat(messengerShippingTask.state.nextShippingDate)}
                        </TypographyWithLabel>
                      )}
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">รายการขนส่ง</Typography>
                    <ShippingProductTable data={shippingProductTableData} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ShippingTaskList
