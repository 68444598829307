import { Grid, Paper, Tooltip } from '@material-ui/core'
import { Description } from '@material-ui/icons'
// import { Description } from '@material-ui/icons'
import numeral from 'numeral'
import React, { useContext, useState } from 'react'
import { RootContext } from '../../..'
import { QuotationRowData } from '../../../api/smartbestbuys-api'
import { FilteredTable, FlexBox, Link, Topic, withLayout } from '../../../custom-components'
import { defaultEndDate, defaultStartDate } from '../../../data'
import { PageName } from '../../../enums'
import { dateTimeFormat } from '../../../functions'
import { useQuotations } from '../../../hooks'
import { Loading, QuotationStatusChip } from '../../../shared-components'
import { FollowCustomerSignal } from '../../../shared-components/Signal'
import lodash from 'lodash'

const QuotationsListPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)

  setCurrentPage(PageName.QUOTATION)

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const quotations = useQuotations(startDate, endDate)

  const quotationStatuses = lodash
    .chain(quotations ?? [])
    .uniqBy('status.id')
    .orderBy('status.id')
    .value()
    .map((q) => {
      return {
        name: q.status.name,
        value: q.status.id,
      }
    })

  if (!quotations) return <Loading />

  return (
    <Paper>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between">
            <Topic>รายการใบเสนอราคา</Topic>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <FilteredTable<QuotationRowData>
            // TODO: download to CSV func(), and filter component
            color="primary.dark"
            columns={[
              {
                name: '',
                render: (data) => (!!data.needToFollow ? <FollowCustomerSignal /> : null),
              },
              {
                name: 'สถานะใบเสนอราคา',
                render: ({ status: { id, name } }) => <QuotationStatusChip status={id} label={name} />,
              },
              {
                name: 'การเปิดใบสั่งขาย',
                render: (data) => {
                  if (data.activeSaleOrderAmount > 0) {
                    return (
                      <Tooltip title={`เปิดใบสั่งขายแล้ว ${data.activeSaleOrderAmount} ใบ`}>
                        <Description fontSize="large" className="document__icon-green" />
                      </Tooltip>
                    )
                  } else {
                    return (
                      <Tooltip title="ยังไม่เปิดใบสั่งขาย">
                        <Description fontSize="large" color="disabled" />
                      </Tooltip>
                    )
                  }
                },
              },
              {
                name: 'เลขที่ใบเสนอราคา',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/quotations/${id}/profile`}>
                    {code}
                  </Link>
                ),
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
              {
                name: 'สร้างโดย',
                dataIndex: 'recCreatedBy',
              },
              {
                name: 'ชื่อลูกค้า',
                render: (data) => data.customer.nameLocal,
              },
              {
                name: 'เบอร์โทรศัพท์',
                render: (data) => data.customer.phoneNumber,
              },
              {
                name: 'วิธีชำระเงิน',
                render: (data) => data.paymentMethod.name,
              },
              {
                name: 'ยอดรวมสุทธิ',
                render: (data) => numeral(data.total).format('0,0.00'),
              },
            ]}
            data={quotations}
            defaultDateRage={{
              startDate: defaultStartDate,
              endDate: defaultEndDate,
            }}
            onDateChange={(startDate, endDate) => {
              setStartDate(startDate)
              setEndDate(endDate)
            }}
            size="small"
            filters={[
              {
                name: 'สถานะใบเสนอราคา',
                values: quotationStatuses,
                filter: (data, values) => values.includes(data.status.id),
              },
              {
                name: 'สถานะอื่น ๆ',
                values: [
                  { name: 'ยังไม่มีการเปิดใบสั่งขาย', value: 1 },
                  { name: 'ใบเสนอราคาที่ต้องติดตาม', value: 2 },
                  // { name: 'ใบเสนอราคาที่สร้างโดยฉัน', value: 3 },
                ],
                filter: (data, values) => {
                  let matched = true
                  if (values.includes(1)) matched = data.activeSaleOrderAmount === 0
                  if (values.includes(2)) matched = !!data.needToFollow
                  // if (values.includes(3)) matched = data.recCreatedBy === getUserName
                  return matched
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withLayout(QuotationsListPage)
