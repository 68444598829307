import {
  SaleOrderProfile,
  SaleOrderProduct,
  SaleOrderDocument,
  TaxInvoiceItem,
} from '../../../../../../api/smartbestbuys-api'
import { DocumentStatus, DocumentType } from '../../../../../../enums'
import { calculateExcludingVat, calculateVatAmount } from '../../../../../../functions'
import _ from 'lodash'

export const mapToItem = (product: SaleOrderProduct): TaxInvoiceItem => {
  return {
    id: product.productId,
    name: product.nameLocal,
    code: product.code,
    imageUrl: product.imageUrl,
    amount: product.amount,
    pricePerEach: product.price,
    total: product.amount * product.price,
  }
}

export const getTaxInvoiceItems = (
  saleOrderProfile: SaleOrderProfile,
  refInvoice?: SaleOrderDocument<any>,
): TaxInvoiceItem[] => {
  if (refInvoice) {
    const excludeVatAmount = calculateExcludingVat(refInvoice.total || 0)
    return [
      {
        id: -1,
        name: `ค่ามัดจำล่วงหน้า (${refInvoice.code})`,
        code: refInvoice.code,
        amount: 1,
        pricePerEach: excludeVatAmount,
        total: excludeVatAmount,
      },
    ]
  }
  return saleOrderProfile.products?.map(mapToItem) || []
}

export const getTaxInvoicePriceBreakdowns = (
  saleOrderProfile: SaleOrderProfile,
  refInvoice?: SaleOrderDocument<any>,
  overrideProductTotal?: number,
) => {
  const isFromInvoice = !!refInvoice
  const { discountAmount, estimatedShippingFee, isShippingFeeIncludingVat, vatIncluded, documents } = saleOrderProfile

  const taxInvoiceItems = getTaxInvoiceItems(saleOrderProfile, refInvoice)
  const saleOrderProductTotal = _.sum(taxInvoiceItems.map((ti) => ti.total))
  const itemTotal = overrideProductTotal ?? saleOrderProductTotal

  let depositedAmount = 0
  let discount = 0
  let shippingFee = 0

  if (!isFromInvoice) {
    const creditNotesTaxInvoiceCodes =
      documents?.reduce<string[]>((codes, doc) => {
        if (!doc.state) return codes
        return [...codes, ...(doc.state.taxInvoiceReferences ?? [])]
      }, []) ?? []

    const depositedTotalWithVat =
      documents?.reduce<number>((sumOfTotal, doc) => {
        if (!doc.referenceDocument) return sumOfTotal
        if (doc.status.id === DocumentStatus.Cancelled) return sumOfTotal
        if (doc.type.id !== DocumentType.TaxInvoice) return sumOfTotal
        if (creditNotesTaxInvoiceCodes.includes(doc.code)) return sumOfTotal
        if (!doc.total) return sumOfTotal
        return sumOfTotal + doc.total
      }, 0) ?? 0

    discount = discountAmount ?? 0
    shippingFee = estimatedShippingFee ?? 0
    depositedAmount = calculateExcludingVat(depositedTotalWithVat)

    if (isShippingFeeIncludingVat) {
      shippingFee = calculateExcludingVat(shippingFee)
    }

    // if customer product
    if (!!overrideProductTotal) {
      const ratio = overrideProductTotal / saleOrderProductTotal
      depositedAmount = depositedAmount * ratio
      discount = discount * ratio
      shippingFee = shippingFee * ratio
    }
  }

  let actualTotal = itemTotal - discount + shippingFee

  const totalExcludingAmount = actualTotal - depositedAmount

  const vatAmount = vatIncluded ? calculateVatAmount(totalExcludingAmount) : 0

  let total = totalExcludingAmount + vatAmount

  if (isFromInvoice) {
    return [
      { label: 'รวมเงิน', amount: itemTotal },
      { label: 'ยอดเงินมัดจำ', amount: depositedAmount },
      { label: 'จำนวนเงินหลังหักยอดเงินมัดจำ', amount: totalExcludingAmount },
      { label: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
      { label: 'ยอดเงินสุทธิ', amount: total },
    ]
  }

  return [
    { label: 'รวมเงิน', amount: itemTotal },
    ...(shippingFee ? [{ label: 'ค่าจัดส่ง', amount: shippingFee }] : []),
    ...(discount ? [{ label: 'ส่วนลด', amount: discount }] : []),
    { label: 'มูลค่าคงเหลือ', amount: actualTotal },
    { label: 'ยอดเงินมัดจำ', amount: depositedAmount },
    { label: 'จำนวนเงินหลังหักยอดเงินมัดจำ', amount: totalExcludingAmount },
    { label: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
    { label: 'ยอดเงินสุทธิ', amount: total },
  ]
}
