import { BaseData } from '../api/smartbestbuys-api'
import { CustomerRequisitionCondition, CustomerRequisitionType } from '../enums'

export const customerRequisitionConditions: BaseData[] = [
  { id: CustomerRequisitionCondition.CustomerReturnAtSmart, name: 'ลูกค้าส่งผ่านขนส่ง' },
  { id: CustomerRequisitionCondition.CustomerDeliverProduct, name: 'ลูกค้ามาส่งที่หน้าร้าน' },
  { id: CustomerRequisitionCondition.SmartToPickupProduct, name: 'งานรับสินค้าคืนคลัง' },
]

export const customerRequisitionTypes: BaseData[] = [
  { id: CustomerRequisitionType.Claim, name: 'เคลมสินค้า' },
  { id: CustomerRequisitionType.CancelSO, name: 'คืนสินค้า' },
]
