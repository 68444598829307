import React, { useContext } from 'react'
import { Box, Link, Paper, Typography } from '@material-ui/core'
import { withLayout } from '../custom-components'
import { RootContext } from '..'
import { PageName } from '../enums'
import { getV3Url } from '../functions'

interface Props {
  name: PageName
  v3Path: string
}

const PendingVersionPage: React.FC<Props> = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  const { name, v3Path } = props

  setCurrentPage(name)

  return (
    <Box mt={2} clone>
      <Paper>
        <Typography>หน้านี้ยังต้องใช้เวอร์ชันเดิม กรุณาคลิกลิงก์ด้านล่าง</Typography>
        <br />
        <br />
        <Link href={getV3Url(v3Path)} target="_blank">
          <Typography>กลับไปอันเก่าก่อนจ้า</Typography>
        </Link>
      </Paper>
    </Box>
  )
}

export default withLayout(PendingVersionPage)
