import { Box, Checkbox, Grid, InputAdornment, MenuItem, Paper, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ICustomerProfile } from '../../../../../../api/smartbestbuys-api'
import { FlexBox, GeneralTextFieldForm, SubTopic } from '../../../../../../custom-components'
import { PaymentCondition } from '../../../../../../enums'
import { WarningDialog } from '../../../../../../sales-system/components'
import { ButtonRadios } from '../../../../../../shared-components'
import { contactChannels, currencies, paymentConditions, paymentMethods } from '../../../../../../types'
import SOUpdationFormValue from '../SOUpdationFormValues'

interface Props {
  customerPORefs?: string[]
  customerProfile?: ICustomerProfile
}

const SOInfomationBox: React.FC<Props> = (props) => {
  const { customerPORefs, customerProfile } = props
  const [duplicatedAlert, setDuplicatedAlert] = useState(false)
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<SOUpdationFormValue>()

  const { paymentMethodId, paymentConditionId, customerPurchaseReference } = getValues()

  const actualPaymentConditions = customerProfile?.creditPeriodDay
    ? paymentConditions
    : paymentConditions.filter((pc) => pc.id !== PaymentCondition.Credit)

  const [hasNoRefPO, setHasNoRefPO] = useState<boolean>(
    !!customerPurchaseReference && customerPurchaseReference?.includes('ไม่มีPO'),
  )

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>ข้อมูลเพิ่มเติม</SubTopic>
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            fullWidth
            control={control}
            name="customerPurchaseReference"
            label="เลขที่การสั่งซื้อของลูกค้า *"
            rules={{ required: 'กรุณาเลือกเลขที่การสั่งซื้อของลูกค้า' }}
            InputLabelProps={hasNoRefPO ? { shrink: true } : undefined}
            onBlur={(e) => {
              if (!e.target.value) return
              const lowerCase = e.target.value.toLowerCase()
              const lowerCasePOs = customerPORefs
                ?.filter((po) => !!po && po !== 'ไม่มี PO')
                .map((po) => po.toLowerCase())
              setDuplicatedAlert(!!lowerCasePOs && lowerCasePOs.includes(lowerCase))
            }}
          />
          <FlexBox alignItems="center">
            <Checkbox
              size="small"
              checked={hasNoRefPO}
              onChange={() => {
                setHasNoRefPO((prev) => {
                  !prev
                    ? setValue('customerPurchaseReference', 'ไม่มีPO')
                    : setValue('customerPurchaseReference', undefined)
                  return !prev
                })
              }}
            />
            <Typography variant="body2" color="textSecondary">
              ลูกค้าไม่มี PO อ้างอิง
            </Typography>
          </FlexBox>
          <WarningDialog
            title="เลขที่การสั่งซื้อของลูกค้านี้เคยถูกสร้างใบสั่งขายแล้ว"
            open={duplicatedAlert}
            onOk={() => setDuplicatedAlert(false)}
            onClose={() => setDuplicatedAlert(false)}
          >
            เลขที่การสั่งซื้อของลูกค้าเลขที่ {customerPurchaseReference} เคยถูกสร้างใบสั่งขายแล้ว
            กรุณาตรวจสอบให้แน่ใจว่าต้องการสร้างใบสั่งขายนี้ต่อ
          </WarningDialog>
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            select
            fullWidth
            control={control}
            name="contactChannelId"
            label="ช่องทางการติดต่อ (ถ้ามี)"
            type="number"
          >
            {contactChannels.map((cc) => (
              <MenuItem key={cc.id} value={cc.id}>
                {cc.name}
              </MenuItem>
            ))}
          </GeneralTextFieldForm>
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            select
            fullWidth
            control={control}
            name="currencyId"
            label="สกุลเงิน *"
            type="number"
            rules={{ required: { value: true, message: 'กรุณาเลือกสกุลเงิน' } }}
          >
            {currencies.map((c) => (
              <MenuItem key={c.name} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </GeneralTextFieldForm>
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            fullWidth
            control={control}
            name="exchangeRate"
            label="อัตราแลกเปลี่ยน *"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
            }}
            type="number"
            rules={{ required: { value: true, message: 'กรุณาใส่อัตราแลกเปลี่ยน' } }}
            error={!!errors.exchangeRate}
            helperText={errors.exchangeRate?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonRadios
            label="วิธีการชำระเงิน *"
            onChange={(paymentMethodId) => setValue('paymentMethodId', paymentMethodId)}
            items={paymentMethods}
            defaultValue={paymentMethodId}
          />
          {errors.paymentMethodId && (
            <Box>
              <Typography variant="caption" color="error" gutterBottom>
                {errors.paymentMethodId?.message}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonRadios
            label="เงื่อนไขการชำระเงิน *"
            onChange={(paymentConditionId) => setValue('paymentConditionId', paymentConditionId)}
            items={actualPaymentConditions}
            defaultValue={paymentConditionId}
          />
          {errors.paymentConditionId && (
            <Box>
              <Typography variant="caption" color="error" gutterBottom>
                {errors.paymentConditionId?.message}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            fullWidth
            multiline
            rowsMax={4}
            control={control}
            name="remark"
            label="หมายเหตุ"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            *** ซื้อครบ 499 บาท ส่งมอบสินค้าภายในกรุงเทพ และ ปริมณฑล ฟรี!!
            <br />
            *** ต่างจังหวัด ลูกค้าเป็นผู้ออกค่าขนส่ง ทางบริษัทฯมีบริการส่งสินค้าตามบริษัทขนส่งที่ลูกค้ากำหนด
            <br />
            *** กรุณาตรวจสอบรายการสินค้าก่อนการสั่งซื้อ ทางบริษัทฯไมมีนโยบายรับคืนสินค้าหากมีการเปิดใบสั่งซื้อมาแล้ว
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SOInfomationBox
