import {
  SaleOrderAttachmentCreation,
  SaleOrderProductCreation,
  createSaleOrder as createSaleOrderAPI,
  ICustomerProfile,
} from '../../../api/smartbestbuys-api'
import { SelectedProduct } from '../../types'
import SOCreationFormValues from './SOCreationFormValues'
import { calculatePriceBreakdowns } from '../../utils/sale-order-price-calculator'
import { format } from 'date-fns'
import { uploadPublicFile } from '../../../api/aws-api'
import { nanoid } from 'nanoid'

export const createSaleOrder = async (
  formValues: SOCreationFormValues,
  customerProfile: ICustomerProfile,
  contactId?: number,
  quotationId?: number,
) => {
  try {
    const nanoId = nanoid()
    const attachments = !!formValues.attachments
      ? await Promise.all(
          formValues.attachments?.map(async (attachment, index) => {
            const attachmentUrl = (await uploadPublicFile(
              attachment.src,
              `sale-orders/attachments/attachment-${nanoId}-${index}`,
            )) as string
            return {
              attachmentUrl,
            }
          }) as SaleOrderAttachmentCreation[],
        )
      : []

    const request = buildSOCreationRequest(formValues, customerProfile, attachments, contactId, quotationId)
    const response = await createSaleOrderAPI(request)
    return response
  } catch (err) {
    throw err
  }
}

const buildSOCreationRequest = (
  formValues: SOCreationFormValues,
  customerProfile: ICustomerProfile,
  attachments: SaleOrderAttachmentCreation[],
  contactId?: number,
  quotationId?: number,
) => {
  const priceBreakdown = calculatePriceBreakdowns(formValues)
  return {
    customerId: customerProfile.id,
    contactId,
    billingAddressId: formValues.billingAddressId,
    contactChannelId: formValues.contactChannelId,
    customerPurchaseReference: formValues.customerPurchaseReference,
    currencyId: formValues.currencyId,
    exchangeRate: formValues.exchangeRate,
    paymentMethodId: formValues.paymentMethodId,
    paymentConditionId: formValues.paymentConditionId,
    vatIncluded: formValues.vatIncluded,
    discountAmount: formValues.discountEnabled ? formValues.discount : 0,
    discountAmountTypeId: 1,
    estimatedShippingFee: formValues.shippingFeeEnabled ? formValues.shippingFee : 0,
    depositRequired: formValues.depositRequired,
    remark: formValues.remark,
    dueDate: formValues.dueDate ? format(formValues.dueDate, 'yyyy-MM-dd') : undefined,
    paymentDueDate: formValues.paymentDueDate ? format(formValues.paymentDueDate, 'yyyy-MM-dd') : undefined,
    customerPickUpMethod: formValues.customerPickUpMethod,
    creditPeriodDay: customerProfile.creditPeriodDay,
    isShippingFeeIncludingVat: formValues.isShippingFeeIncludingVat,
    attachments,
    products: formValues.selectedProducts.map(mapToProductRequest),
    total: priceBreakdown.total,
    quotationId,
  }
}

const mapToProductRequest = (selectedProduct: SelectedProduct): SaleOrderProductCreation => {
  return {
    id: selectedProduct.id,
    code: selectedProduct.code,
    nameLocal: selectedProduct.nameLocal,
    nameEn: selectedProduct.nameEn,
    unitLocal: selectedProduct.unitLocal,
    unitEn: selectedProduct.unitEn,
    imageUrl: selectedProduct.imageUrl,
    amount: selectedProduct.amount,
    price: selectedProduct.price,
  }
}

// const mapToAttachment = (attachment: SOAttachment): SaleOrderAttachmentCreation => {
//   return {
//     base64: attachment.src,
//   }
// }
