import { Avatar, Paper, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IGetCustomerRequisitionFormData_SaleOrderProduct } from '../../../api/smartbestbuys-api'
import { FlexBox, GeneralTextFieldForm, SubTopic, Table } from '../../../custom-components'
import { CustomerRequisitionProductTypeSuffix, CustomerRequisitionTypeId } from '../../../types'
import { CustomerRequisitionFormValues } from '.'
import { CustomerRequisitonCreatePageContext } from '../create/CustomerRequisitionCreatePage'
import { Loading } from '../../../shared-components'
import { CustomerRequisitionUpdatePageContext } from '../update/CustomerRequisitionUpdatePage'

interface Props {
  defaultValues?: CustomerRequisitionFormValues
}

const CustomerRequisitionProductForm: React.FC<Props> = (props) => {
  const { defaultValues } = props

  const { customerRequisitionCreateData } = useContext(CustomerRequisitonCreatePageContext)
  const { customerRequisitionUpdateData } = useContext(CustomerRequisitionUpdatePageContext)

  const isUpdateMode = useMemo(
    () => !!defaultValues, // eslint-disable-next-line
    [!!defaultValues],
  )

  const saleOrderProducts = isUpdateMode
    ? customerRequisitionUpdateData.saleOrderProducts
    : customerRequisitionCreateData.saleOrderProducts

  const saleOrderInfo = isUpdateMode
    ? customerRequisitionUpdateData.saleOrderInfo
    : customerRequisitionCreateData.saleOrderInfo

  const [selected, setSelected] = useState<number[]>(
    !!isUpdateMode && !!defaultValues
      ? customerRequisitionUpdateData.customerRequisition.products
          .filter((p) => defaultValues.products.map((p) => p.productId).includes(p.productId))
          .map((p) => p.saleOrderProductId)
      : [],
  )

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<CustomerRequisitionFormValues>()

  const { typeId } = watch()

  useEffect(
    () => {
      const crProducts = saleOrderProducts.map((product) => {
        if (isUpdateMode) {
          const reserveAmount = customerRequisitionUpdateData.customerRequisition.products.find(
            (p) => p.productId === product.productId,
          )?.amount
          return {
            saleOrderProductId: product.id,
            productId: product.productId,
            amount: reserveAmount,
          }
        } else {
          return {
            saleOrderProductId: product.id,
            productId: product.productId,
            amount: undefined,
          }
        }
      })

      setValue('products', crProducts)
    }, // eslint-disable-next-line
    [saleOrderInfo.id, !!defaultValues],
  )

  if (isUpdateMode && !defaultValues) return <Loading />

  return (
    <FlexBox
      p={3}
      justifyContent="space-between"
      flexDirection="column"
      border={errors.products ? 1 : undefined}
      borderColor={errors.products ? 'error.main' : undefined}
      clone
    >
      <Paper id="claim-product-selector-box">
        <SubTopic>เลือกสินค้าสำหรับเคลม</SubTopic>
        <div className="mb-4" />
        <Table<IGetCustomerRequisitionFormData_SaleOrderProduct>
          color="primary.dark"
          size="small"
          columns={[
            {
              name: 'ลำดับ',
              render: (_, index) => index + 1,
            },
            {
              name: 'ชื่อสินค้า',
              render: (product) => (
                <div className="transport-task-products-table__product-cell" key={'product-' + product.id}>
                  <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                    <Photo />
                  </Avatar>
                  <div className="transport-task-products-table__product-cell__info">
                    <Typography variant="body2" color="textSecondary">
                      {typeId === CustomerRequisitionTypeId.Claim
                        ? CustomerRequisitionProductTypeSuffix.Claim
                        : CustomerRequisitionProductTypeSuffix.CanceledSO}
                      {product.code}
                    </Typography>
                    <Typography variant="body2">{product.nameLocal}</Typography>
                  </div>
                </div>
              ),
            },
            {
              name: 'จำนวนที่ขาย',
              dataIndex: 'amount',
              numeric: true,
              format: '0,0',
            },
            {
              name: 'จำนวนที่ส่งแล้ว',
              dataIndex: 'shippedAmount',
              numeric: true,
              format: '0,0',
            },
            {
              name: 'จำนวนที่จะเคลม',
              render: (product, index) => {
                return (
                  <GeneralTextFieldForm
                    key={`products.${index}.amount`}
                    margin="none"
                    control={control}
                    name={`products.${index}.amount`}
                    rules={{
                      required: selected?.includes(product.id) ? 'กรุณากรอกจำนวน' : undefined,
                      max: {
                        value: product.shippedAmount,
                        message: 'จำนวนเคลมเกินจำนวนที่ส่งแล้ว',
                      },
                    }}
                    onPostChange={() => {
                      if (!!errors.products) {
                        clearErrors('products')
                      }
                    }}
                    type="number"
                    disabled={!selected.includes(product.id)}
                    defaultValue={defaultValues?.products.find((p) => p.productId === product.productId)?.amount}
                  />
                )
              },
            },
          ]}
          data={saleOrderProducts}
          selectable
          selected={selected}
          onChange={setSelected}
          onSelect={() => {
            if (!!errors.products) {
              clearErrors('products')
            }
          }}
          onDeSelect={(id) => {
            const index = saleOrderProducts.findIndex((p) => p.id === id)
            setValue(`products.${index}.amount`, undefined)
          }}
          pagination={false}
          hideCheckbox={(product) => product.shippedAmount === 0}
          inActive={(product) => product.shippedAmount === 0}
          cleanSpace
        />
        {errors.products && (
          <Typography color="error" variant="caption">
            {errors.products.message}
          </Typography>
        )}
      </Paper>
    </FlexBox>
  )
}

export default CustomerRequisitionProductForm
