import { Box, Grid } from '@material-ui/core'
import React, { createContext, useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { QuotationUpdateDataResponse, updateQuotations } from '../../../api/smartbestbuys-api'
import { UserData } from '../../../api/smartbestbuys-api/data/data-types'
import { Form, Topic, withLayout } from '../../../custom-components'
import { useQuotationsUpdateData, useUserData } from '../../../hooks'
import { Loading, SubmitForm } from '../../../shared-components'
import { mapQuotationsUpdateFormValueToQuotationUpdateRequest } from './mapper'
import QuotationUpdationCustomerProfileCard from './QuotationUpdationCustomerProfileCard'
import QuotationUpdationDiscountBox from './QuotationUpdationDiscountBox'
import { QuotationUpdationFormValue } from './QuotationUpdationFormValue'
import QuotationsMoreInformation from './QuotationUpdationMoreInformation'
import QuotationUpdationProductSelector from './QuotationUpdationProductSelector'
import QuotationUpdationSelectedProduct from './QuotationUpdationSelectedProduct'
import QuotationUpdationShippingFeeBox from './QuotationUpdationShippingFeeBox'
import QuotationUpdationTotalBox from './QuotationUpdationTotalBox'
import QuotationUpdationVatBox from './QuotationUpdationVatBox'
import './QuotationUpdationPage.less'

export interface QuotationUpdationPageContext {
  data: QuotationUpdateDataResponse
  users: UserData[]
}

export const QuotationUpdationPageContextImpl = createContext<QuotationUpdationPageContext>({
  data: {} as QuotationUpdateDataResponse,
  users: [] as UserData[],
})

interface QuotationUpdationPageProps {
  id: number
}

const QuotationUpdationPage: React.FC<QuotationUpdationPageProps> = ({ id }) => {
  const { triggerSnackbar } = useContext(RootContext)

  const history = useHistory()

  const quotationUpdateData = useQuotationsUpdateData(id)
  const userData = useUserData()

  const formMethod = useForm<QuotationUpdationFormValue>({
    defaultValues: {
      selectedProducts: [],
      periodDay: 30,
      exchangeRate: 1,
      isShippingFeeIncludingVat: false,
      vatIncluded: true,
      paymentMethodId: 1,
      currencyId: 1,
    },
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = formMethod

  if (!quotationUpdateData || !userData) return <Loading />

  const { selectedProducts, periodDay, dueDate } = watch()

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const validate = (): boolean => {
    let error = false
    if (selectedProducts.length === 0) {
      setError('selectedProducts', {
        type: 'required',
        message: 'กรุณาเลือกสินค้า/บริการ ลงในใบเสนอราคา',
      })
      error = true
      scrollTo('#quotations-product-selector')
    }
    if (periodDay.toString() !== '' && periodDay <= 0) {
      setError('periodDay', {
        type: 'min',
        message: 'กรุณากรอกระยะยืนราคาไม่ต่ำกว่า 1 วัน',
      })
      error = true
      scrollTo('#quotations-more-information')
    }

    if (!dueDate) {
      setError('dueDate', {
        message: 'กรุณาเลือกวันที่ลูกค้ารับของ',
      })
      error = true
      scrollTo('#quotations-more-information')
    }

    return error
  }

  const handleOnSubmit = async (value: QuotationUpdationFormValue) => {
    if (!validate()) {
      const request = mapQuotationsUpdateFormValueToQuotationUpdateRequest(value)
      const response = await updateQuotations(quotationUpdateData.profile.id, request)
      if (response?.status === 200) {
        triggerSnackbar('แก้ไขใบเสนอราคาสำเร็จ')
        history.push(`/quotations/${id}/profile`)
      } else {
        triggerSnackbar('แก้ไขใบเสนอราคาไม่สำเร็จ')
      }
    }
    return true
  }

  return (
    <QuotationUpdationPageContextImpl.Provider value={{ data: quotationUpdateData, users: userData }}>
      <FormProvider {...formMethod}>
        <Box className="quotation-update-page-header">
          <Topic>แก้ไขใบเสนอราคา</Topic>
        </Box>
        <Form onSubmit={handleSubmit(handleOnSubmit)} onInvalid={() => validate()}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <QuotationUpdationCustomerProfileCard />
            </Grid>
            <Grid item xs={12}>
              <QuotationUpdationProductSelector />
            </Grid>
            <Grid item xs={12}>
              <QuotationUpdationSelectedProduct />
            </Grid>
            <Grid item xs={12} md={6}>
              <QuotationUpdationDiscountBox />
            </Grid>
            <Grid item xs={12} md={6}>
              <QuotationUpdationShippingFeeBox />
            </Grid>
            <Grid item xs={12}>
              <QuotationUpdationVatBox />
            </Grid>
            <Grid item xs={12}>
              <QuotationUpdationTotalBox />
            </Grid>
            <Grid item xs={12}>
              <QuotationsMoreInformation />
            </Grid>
            <Grid item xs={12}>
              <SubmitForm submitText="บันทึกใบเสนอราคา" cancelText="ยกเลิก" isSubmitting={isSubmitting} goBack />
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </QuotationUpdationPageContextImpl.Provider>
  )
}

export default withLayout(QuotationUpdationPage)
