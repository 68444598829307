import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import { FlexBox, SubTopic, Table } from '../../../custom-components'
import { TransportTaskStateV1_Item } from '../../../api/smartbestbuys-api'
import { TransportTaskProfilePageContext } from './TransportTaskProfilePage'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'

interface Props {
  hideSalePrice?: boolean
}

const TransportTaskProfileDocumentsTable: React.FC<Props> = (props: Props) => {
  // context
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const documents = transportTaskProfile.state?.items.filter(
    (row) => row.type === TransportTaskItemType.Document,
  ) as TransportTaskStateV1_Item[]

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ข้อมูลเอกสารสำหรับขนส่ง</SubTopic>
        <div className="mb-4" />
        <Table<TransportTaskStateV1_Item>
          color="secondary.dark"
          columns={[
            { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
            { name: 'ชื่อเอกสาร', render: ({ name }) => name },
            { name: 'จำนวนที่เคยส่งแล้ว', render: () => '-', align: 'right' },
            {
              name: 'จำนวนที่จะส่ง',
              render: ({ amount }) => amount,
              align: 'right',
            },
          ]}
          data={documents}
          pagination={false}
          cleanSpace
        />
      </Paper>
    </FlexBox>
  )
}

export default TransportTaskProfileDocumentsTable
