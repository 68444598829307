import { useHistory, useParams } from 'react-router-dom'
import { Topic, withLayout } from '../../../custom-components'
import { Box } from '@material-ui/core'
import { PWTicketForm, PWTicketFormDefaultValues, PWTicketFormValues } from './forms'
import {
  UpdateProductWithdrawalTicketRequestBody,
  updateProductWithdrawalTicketAPI,
} from '../../../api/smartbestbuys-api/warehouse'
import { useContext, useMemo } from 'react'
import { RootContext } from '../../..'
import { PageName } from '../../../enums'
import { useProductWithdrawalTicketProfile } from '../../../hooks'
import { Loading } from '../../../shared-components'

const PWTicketUpdatePage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  const history = useHistory()
  setCurrentPage(PageName.PRODUCT_WITHDRAWAL_TABLE_DATA)
  const profile = useProductWithdrawalTicketProfile(Number(id))

  const defaultValues = useMemo<PWTicketFormDefaultValues | undefined>(() => {
    if (!profile.data) return

    return {
      actionMessageId: profile.data.actionMessage.id,
      requestedDate: profile.data.requestedDate,
      estimatedReturnedDate: profile.data.estimatedReturnedDate,
      remark: profile.data.remark,
      selectedProducts: profile.data.products.map((product) => ({
        id: product.productId,
        code: product.code,
        nameLocal: product.nameLocal,
        returnedAmount: product.returnedAmount,
        type: product.type,
        imageUrl: product.imageUrl,
        requestToWithdrawnAmount: product.amount,
        stockAmount: product.stockAmount,
        reservedAmount: product.reservedAmount,
        unitLocal: product.unitLocal,
      })),
    }
  }, [profile.data])

  if (!profile.data) return <Loading />

  const { code } = profile.data

  const handleSubmit = async (values: PWTicketFormValues) => {
    const request: UpdateProductWithdrawalTicketRequestBody = {
      actionMessageId: values.actionMessageId,
      requestedDate: values.requestedDate,
      estimatedReturnedDate: values.estimatedReturnedDate,
      remark: values.remark,
      products: values.products
        .filter((product) => !!product.requestToWithdrawnAmount && product.requestToWithdrawnAmount > 0)
        .map((product) => ({
          productId: product.id,
          amount: product.requestToWithdrawnAmount!, // risk to broke
        })),
    }

    const response = await updateProductWithdrawalTicketAPI(Number(id), request)

    if (response?.status === 200) {
      history.replace(`/warehouse/product-withdrawal-tickets/${response.data.id}/profile`)
    } else {
      triggerSnackbar('แก้ไขคำขอเบิกสินค้าไม่สำเร็จ')
    }
  }

  return (
    <Box>
      <Topic style={{ marginBottom: 8 }}>แก้ไขคำขอเบิกสินค้าเลขที่ {code}</Topic>
      <PWTicketForm defaultValues={defaultValues} onSubmit={handleSubmit} />
    </Box>
  )
}

export default withLayout(PWTicketUpdatePage)
