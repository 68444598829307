import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Permission } from '../../api/smartbestbuys-api'

interface UserPermissionCheckboxProps {
  selectedPermissionIds: number[]
  permission: Permission
  onPermissionSelect: (selectedPermissionIds: number[]) => void
  allPermissionIdInSelectedGroup: number[]
}

const UserPermissionCheckbox: React.FC<UserPermissionCheckboxProps> = (props) => {
  const { onPermissionSelect, selectedPermissionIds, permission, allPermissionIdInSelectedGroup } = props

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={
            selectedPermissionIds.includes(permission.id) || allPermissionIdInSelectedGroup.includes(permission.id)
          }
          disabled={allPermissionIdInSelectedGroup.includes(permission.id)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              selectedPermissionIds.push(permission.id)
            } else {
              selectedPermissionIds.splice(
                selectedPermissionIds.findIndex((v) => v === permission.id),
                1,
              )
            }
            onPermissionSelect([...selectedPermissionIds])
          }}
        />
      }
      label={permission.name}
    />
  )
}

export default UserPermissionCheckbox
