import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import { FlexBox, SubTopic, Table } from '../../../custom-components'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import { PurchaseOrderProfile_Activity } from '../../../api/smartbestbuys-api/purchase-order/types'
import { dateTimeFormat } from '../../../functions'

const PurchaseOrderProfileUserActivitiesTable: React.FC = () => {
  // context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { activities } = purchaseOrderProfile

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <SubTopic>ประวัติการจัดการใบสั่งซื้อ</SubTopic>
        <div className="mb-4" />
        <Table<PurchaseOrderProfile_Activity>
          color="grey.800"
          columns={[
            { name: 'การกระทำ', dataIndex: 'actionName' },
            { name: 'รายละเอียดการจัดการ', dataIndex: 'actionMessage' },
            { name: 'จัดการโดย', dataIndex: 'recCreatedBy', align: 'center' },
            {
              name: 'วันที่',
              dataIndex: 'recCreatedAt',
              render: (activity) => dateTimeFormat(activity.recCreatedAt),
              align: 'center',
            },
            { name: 'หมายเหตุ', dataIndex: 'remark', align: 'center' },
          ]}
          data={activities}
          cleanSpace
        />
      </Paper>
    </FlexBox>
  )
}

export default PurchaseOrderProfileUserActivitiesTable
