import { useState, useEffect } from 'react'
import { fetchSalesDebtors, FetchSaleOrderRequest, SaleOrderDebtorRowData } from '../api/smartbestbuys-api'

export const useSalesDebtors = (startDate: Date, endDate: Date): SaleOrderDebtorRowData[] => {
  const [debtors, setDebtors] = useState<SaleOrderDebtorRowData[]>([])

  const _fetchSalesDebtors = async (request: FetchSaleOrderRequest) => {
    const response = await fetchSalesDebtors(request)
    setDebtors(response?.data || [])
  }

  useEffect(() => {
    _fetchSalesDebtors({ startDate, endDate })
  }, [startDate, endDate])

  return debtors
}
