export enum CustomerType {
  Company = 1,
  Regular = 2,
}

export enum CustomerTier {
  Platinum = 1,
  Diamond = 2,
  Wisdom = 3,
}
