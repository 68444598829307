import { Button, Typography, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { PRVendor, PurchaseOrderUpsertData_VendorInfo } from '../../../../api/smartbestbuys-api'

import { FlexBox } from '../../../../custom-components'
import { VendorDialog } from './VendorDialog'
import { VendorInfoCard } from './VendorInfoCard'

export interface VenderBoxFormValues {
  vendor?: PRVendor | PurchaseOrderUpsertData_VendorInfo
}

interface Props {
  vendors: PRVendor[]
  readonly?: boolean
}

const VendorBox: React.FC<Props> = (props: Props) => {
  const { vendors, readonly } = props

  const [vendorDialog, setVendorDialog] = useState(false)

  const { setValue, watch } = useFormContext<VenderBoxFormValues>()

  const { vendor } = watch()

  return (
    <>
      <VendorDialog
        open={vendorDialog}
        onClose={() => {
          setVendorDialog(false)
        }}
        vendors={vendors}
      />
      <Paper style={{ height: '100%' }}>
        <Typography variant="h5">เลือกผู้ขายอ้างอิง (ถ้ามี)</Typography>
        {!!vendor || readonly ? (
          <VendorInfoCard
            vendor={vendor}
            onEdit={() => setVendorDialog(true)}
            onClose={() => setValue('vendor', undefined)}
            readonly={readonly}
          />
        ) : (
          <FlexBox
            style={{ height: '139px' }}
            flexDirection="row"
            gridGap={16}
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="contained" color="secondary" onClick={() => setVendorDialog(true)}>
              เลือกผู้ขายอ้างอิง
            </Button>
          </FlexBox>
        )}
      </Paper>
    </>
  )
}

export default VendorBox
