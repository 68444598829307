import { useEffect, useState } from 'react'
import { getUsersAPI, User_TableData } from '../../api/smartbestbuys-api'

const useUsers = (): User_TableData[] => {
  const [users, setUsers] = useState<User_TableData[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await getUsersAPI()
      if (response?.data) {
        setUsers(response?.data || [])
      }
    })()
  }, [])
  return users
}

export default useUsers
