import { createContext } from 'react'
import { ICustomerProfile } from '../../../../api/smartbestbuys-api'

interface ContextProps {
  customerProfile: ICustomerProfile
  reload: () => void
}

const init = {
  customerProfile: {} as ICustomerProfile,
  reload: () => {},
}

const CustomerProfileContext = createContext<ContextProps>(init)

export default CustomerProfileContext
