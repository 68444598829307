import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { FetchRequest, SaleOrder_TableData } from '..'

const path = '/sale-orders/debtors/fetch'

export const fetchSalesDebtors = errorHandler<SaleOrderDebtorRowData[]>(async (request: FetchRequest) => {
  const result = await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
  return result
})

export interface SaleOrderDebtorRowData extends SaleOrder_TableData {
  paymentDueDate?: Date
  paidAmount: number
  remainingTotal: number
}
