export enum NavigationItemKey {
  Home = 1,
  Customer = 2,
  CustomerList = 201,
  CreditorList = 202,

  Vendor = 3,
  VendorList = 301,

  Sale = 4,
  Quotation = 401,
  SaleOrder = 402,
  Invoice = 403,
  TaxInvoice = 404,
  Receipt = 405,
  BillingNote = 406,
  CreditNote = 407,
  ReadyToShipSaleOrder = 408,

  Transport = 5,
  TransportTask = 501,
  TransportShippingSummary = 502,
  TransportPaymentSummary = 503,
  ShippingTask = 504,

  Purchase = 6,
  PurchaseRequisition = 601,
  PurchaseOrder = 602,
  PendingPaymentPurchaseOrder = 603,
  FollowServiceTask = 604,

  Warehouse = 7,
  Product = 701,
  ReorderPoint = 702,
  PrepareProductToShip = 703,
  CustomerPickupProduct = 704,
  PendingVendorShipping = 705,
  ReturnProduct = 706,
  ReceiveProductBack = 707,
  Transactions = 708,
  ProductAnalytic = 709,
  ProductMustToPurchase = 710,

  Finance = 8,
  SalesPayment = 801,
  PaymentRequisition = 802,
  PurchasePayment = 803,
  SalesDebtors = 804,
  PrepareDocument = 805,
  ReturnDocument = 806,
  ShippingPaymentSummaryData = 807,
  SaleOrderDailyPayment = 808,
  RefundServiceList = 809,
  PayOutDailyPayment = 810,

  User = 9,
  UserList = 901,
  UserPermissionList = 902,

  CustomerService = 10,
  CustomerRequisition = 1001,
  CustomerRequisitionPendingPickup = 1002,

  TransportPickup = 11,
  TransportPickupTask = 1101,
  TransportPickupSummary = 1102,
  PickupTask = 1103,

  Marketing = 12,
  MarketingSalesReport = 1201,
  MarketingSalesGoalReport = 1202,

  // product withdrawal
  ProductWithdrawal = 13,
  ProductWithdrawalTableData = 1301,

  //
  Setting = 14,
  SalesGoalAlignment = 1401,
}
