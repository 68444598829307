import { Grid, isWidthUp, Paper, TablePagination, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { useContext, useState } from 'react'
import { NestedValue, useFieldArray, useFormContext } from 'react-hook-form'
import { PurchaseProductUpsertData } from '../../../../api/smartbestbuys-api'
import { FlexBox, SearchTextField, SubTopic } from '../../../../custom-components'
import { PurchaseProductFilterKey } from '../../../../enums'
import { ButtonRadios } from '../../../../shared-components'
import PurchaseProductCard from '../../../purchase-requisitions/create/PurchaseProductSelector/PurchaseProductCard/PurchaseProductCard'
import PurchaseOrderCreateFormValues from '../PurchaseOrderCreateFormValues'
import { PurchaseOrderCreatePageContext } from '../PurchaseOrderCreatePage'
import PurchaseOrderProductDialog from './PurchaseOrderProductDialog/PurchaseOrderProductDialog'
import { useStyles } from './styles'

interface Props {
  width: Breakpoint
}

export interface PurchaseOrderProduct extends PurchaseProductUpsertData {
  amount: number
  price: number
  base64?: string
  typeId?: number
}

export interface PurchaseOrderProductSelectorFormValues {
  products: NestedValue<Array<PurchaseOrderProduct>>
}

const PurchaseOrderProductSelector: React.FC<Props> = (props: Props) => {
  // props
  const { width } = props

  const { purchaseOrderCreate } = useContext(PurchaseOrderCreatePageContext)
  const { purchaseRequisitionInfo, products: productData } = purchaseOrderCreate

  // hook form
  const { control, watch, setValue } = useFormContext<PurchaseOrderProductSelectorFormValues>()
  const { remove } = useFieldArray<PurchaseOrderProductSelectorFormValues>({
    control,
    name: 'products',
  })
  const { watch: watchPOCreateForm } = useFormContext<PurchaseOrderCreateFormValues>()
  const { vendor } = watchPOCreateForm()

  // style
  const styles = useStyles()
  const rowsPerPage = isWidthUp('xl', width) ? 12 : 8

  // data
  const { products } = watch()
  const filterTypes = [
    { label: 'จาก PR', value: PurchaseProductFilterKey.PurchaseRequisition },
    { label: 'ทั้งหมด', value: PurchaseProductFilterKey.AllProduct },
  ]
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')
  const [typeOfSelector, setTypeOfSelector] = useState(1)

  // handlers
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const getPurchaseProducts = (): PurchaseProductUpsertData[] => {
    if (typeOfSelector === PurchaseProductFilterKey.PurchaseRequisition) {
      return productData.filter((p) => !!purchaseRequisitionInfo.products?.find((sp) => p.code === sp.code)) || []
    }
    return productData
  }

  const filteredPurchaseProducts = () =>
    getPurchaseProducts().filter((p) => {
      const lowerCase = query.toLowerCase()
      return p.code?.toLowerCase().includes(lowerCase) || p.name.toLowerCase().includes(lowerCase)
    })

  const paginate = (products: PurchaseOrderProduct[]) =>
    products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleUnSelectedProduct = (product: PurchaseProductUpsertData) => {
    const index = products.findIndex((p) => p.code === product.code)

    if (index > -1) {
      remove(index)
    }
  }

  const handleSubmitDialog = (data: PurchaseOrderProduct) => {
    setValue('products', [...products, data])
  }

  return (
    <Paper className={styles.root}>
      <SubTopic>เลือกสินค้า/บริการ</SubTopic>
      <FlexBox justifyContent="space-between" alignItems="center">
        <SearchTextField
          onChange={(e) => {
            setQuery(e.target.value)
            setPage(0)
          }}
        />
        <ButtonRadios
          items={filterTypes.map((type) => ({ id: type.value, name: type.label }))}
          onChange={(value) => setTypeOfSelector(value)}
          value={typeOfSelector}
          color="secondary"
        />
      </FlexBox>
      <Grid container spacing={2}>
        {paginate(filteredPurchaseProducts() as PurchaseOrderProduct[]).map((product) => {
          const amount = products.find((_product) => _product.code === product.code)?.amount
          const productName =
            !!vendor && vendor.country !== 'ไทย' && !!product.nameEn ? (product.nameEn as string) : product.name
          const unitName =
            !!vendor && vendor.country !== 'ไทย' && !!product.unitEn ? (product.unitEn as string) : product.unit

          const productMapping: PurchaseOrderProduct = {
            ...product,
            name: productName,
            unit: unitName,
          }

          return (
            <Grid key={product.code} item xs={12} sm={6} md={3} xl={2}>
              <PurchaseProductCard
                key={product.code}
                code={product?.code}
                imageUrl={product?.imageUrl}
                name={productName}
                onClick={() => handleUnSelectedProduct(product)}
                selected={products.length > 0 ? !!products.find((p) => p.code === product.code) : false}
                chipText={amount ? `เลือกแล้ว ${amount} ชิ้น` : undefined}
                dialogComponent={
                  <PurchaseOrderProductDialog
                    onDialogSubmit={handleSubmitDialog}
                    open
                    product={productMapping}
                    options={{ currency: vendor?.currency?.name ?? 'บาท' }}
                  />
                }
                dialogComponentProps={{
                  onDialogSubmit: (data: PurchaseOrderProduct) => handleSubmitDialog(data),
                  productMapping,
                  options: { currency: vendor?.currency?.name ?? 'บาท' },
                }}
              />
            </Grid>
          )
        })}
      </Grid>
      <TablePagination
        component="div"
        count={filteredPurchaseProducts().length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </Paper>
  )
}

export default withWidth()(PurchaseOrderProductSelector)
