import { Button } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { CSVLink } from 'react-csv'
import { IProductYearlyReport } from '../../../api/smartbestbuys-api'
import { useMemo } from 'react'
import { formatNumber } from '../../../functions/number-utils'

interface ObjectType {
  [key: string]: string | number | undefined
}

interface Props {
  headers: { label: string; key: string }[]
  productReports: IProductYearlyReport[]
}

const ProductYearlyReportDownloadCSV = (props: Props) => {
  const { headers, productReports } = props

  const { ready: csvReady, ...csvProps } = useMemo(() => {
    const data = []
    let saleAmountData: ObjectType = {}
    let priceData: ObjectType = {}
    let salesTotalData: ObjectType = {}
    let totalSalesCostData: ObjectType = {}
    let grossMarginData: ObjectType = {}
    let grossMarginPercentData: ObjectType = {}
    let snapshotStockAmountData: ObjectType = {}
    let repeatPurchaseRateData: ObjectType = {}
    for (const product of productReports) {
      product.salesMontlyReport.forEach((p) => {
        const monthKey = `month${p.month + 1}`
        const growPercentKey = `growPercent${p.month + 1}`
        saleAmountData[monthKey] = p.salesAmount.value
        saleAmountData[growPercentKey] = formatNumber(p.salesAmount.growthPercent)
        priceData[monthKey] = formatNumber(p.avgSalesPrice.value)
        priceData[growPercentKey] = formatNumber(p.avgSalesPrice.growthPercent)
        salesTotalData[monthKey] = formatNumber(p.salesTotal.value)
        salesTotalData[growPercentKey] = formatNumber(p.salesTotal.growthPercent)
        totalSalesCostData[monthKey] = formatNumber(p.salesCost.value)
        totalSalesCostData[growPercentKey] = formatNumber(p.salesCost.growthPercent)
        grossMarginData[monthKey] = formatNumber(p.grossMargin.value)
        grossMarginData[growPercentKey] = formatNumber(p.grossMargin.growthPercent)
        grossMarginPercentData[monthKey] = formatNumber(p.grossMarginPercent.value)
        grossMarginPercentData[growPercentKey] = formatNumber(p.grossMarginPercent.growthPercent)
        snapshotStockAmountData[monthKey] = p.snapshotStockAmount.value
        snapshotStockAmountData[growPercentKey] = formatNumber(p.snapshotStockAmount.growthPercent)
        repeatPurchaseRateData[monthKey] = formatNumber(p.repeatPurchaseRate.value)
        repeatPurchaseRateData[growPercentKey] = formatNumber(p.repeatPurchaseRate.growthPercent)
      })

      const arrayData = [
        {
          text: `(${product.code}) ${product.name}`,
        },
        {
          text: 'ปริมาณการขายสินค้า',
          unitLocal: product.unitLocal,
          ...saleAmountData,
        },
        { text: 'ราคาขาย', unitLocal: 'บาท', ...priceData },
        { text: 'ยอดขายสินค้า', unitLocal: 'บาท', ...salesTotalData },
        { text: 'ต้นทุนขายสินค้า', unitLocal: 'บาท', ...totalSalesCostData },
        { text: 'กำไรขั้นต้น', unitLocal: 'บาท', ...grossMarginData },
        { text: 'Margin', unitLocal: '%', ...grossMarginPercentData },
        { text: 'จำนวนสต็อกคงเหลือ', unitLocal: product.unitLocal, ...snapshotStockAmountData },
        { text: 'อัตราการซื้อซ้ำ', unitLocal: '%', ...repeatPurchaseRateData },
        {},
      ]
      data.push(...arrayData)
    }

    return {
      filename: `file.csv`,
      headers: headers,
      data,
      ready: true,
    }
  }, [productReports, headers])

  return (
    <>
      {csvProps.data.length > 0 && (
        <CSVLink {...csvProps} style={{ textDecoration: 'none' }} className="csv-link">
          <Button color="primary" variant="text" startIcon={<CloudDownload color="primary" />}>
            Download CSV
          </Button>
        </CSVLink>
      )}
    </>
  )
}

export default ProductYearlyReportDownloadCSV
