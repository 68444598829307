import React, { useContext } from 'react'
import { Link, makeStyles, Paper, Typography } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { FlexBox, SubTopic } from '../../../custom-components'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import { PurchaseRequisitionStatus } from '../../../enums'
import { PurchaseRequisitionStatusChip } from '../../../shared-components'

const useStyles = makeStyles({
  paper: {
    background: '#FEECEB',
  },
})

const PurchaseRequisitionRefBox: React.FC = (props) => {
  const classes = useStyles()
  // context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const {
    purchaseRequisitionInfo: { id: purchaseRequisitionId, code: purchaseRequisitionCode, status },
  } = purchaseOrderProfile

  // calculation
  const isPRCanceled = status.id === PurchaseRequisitionStatus.Canceled

  // render
  return (
    <FlexBox p={3} justifyContent="space-between" alignItems="center" clone>
      <Paper
        classes={
          isPRCanceled
            ? {
                root: classes.paper,
              }
            : {}
        }
      >
        <SubTopic>
          คำขอซื้ออ้างอิง {purchaseRequisitionCode}{' '}
          {isPRCanceled && (
            <span className="ml-4">
              <PurchaseRequisitionStatusChip icon={true} status={status.id} label={status.name} />
            </span>
          )}
        </SubTopic>
        <Link
          className="flex items-center"
          color="primary"
          href={`/purchase-requisitions/${purchaseRequisitionId}/profile`}
        >
          <Typography color="primary">ไปที่หน้ารายละเอียดคำขอซื้อ</Typography>
          <ArrowForward color="primary" className="ml-2" />
        </Link>
      </Paper>
    </FlexBox>
  )
}

export default PurchaseRequisitionRefBox
