import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../..'
import {
  CustomerShippingAddress,
  TransportTaskProfile,
  updateShippingAddressInfoAPI,
  UpdateShippingAddressInfoRequestBody,
} from '../../../api/smartbestbuys-api'
import { AddressData } from '../../../api/smartbestbuys-api/data/data-types'
import { ShippingAddressForm } from '../../../shared-components'
import { IShippingAddressFormValues } from '../../../shared-components/ShippingAddressForm/shipping-address-form-values'

interface UpdateShippingAddressDialogProps {
  taskId: number
  taskCode: string
  customerId: number
  consigneeName?: string
  consigneePhoneNumber?: string
  shippingAddressId?: number
  onComplete?: (data: TransportTaskProfile) => void
  onClose: () => void
}

export const UpdateShippingAddressDialog = (props: UpdateShippingAddressDialogProps) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { taskCode, taskId, customerId, consigneeName, consigneePhoneNumber, shippingAddressId, onComplete, onClose } =
    props

  const handleSubmit = async (
    values: IShippingAddressFormValues,
    shippingAddresses: CustomerShippingAddress[],
    addressData: AddressData,
  ) => {
    const shippingAddress = shippingAddresses.find((sa) => sa.id === values.id)
    const request: UpdateShippingAddressInfoRequestBody = {
      customerId,
      consigneeName: values.consigneeName,
      consigneePhoneNumber: values.consigneePhoneNumber,
      customerShippingAddress: {
        id: values.id,
        name: shippingAddress?.name ?? values.name,
        branchName: shippingAddress?.branchName ?? values.branchName,
        addressLine1: shippingAddress?.addressLine1 ?? values.addressLine1,
        phoneNumber: shippingAddress?.phoneNumber ?? values.phoneNumber,
        province: shippingAddress?.province ?? addressData?.provinces.find((d) => d.id === values.provinceId),
        district: shippingAddress?.district ?? addressData?.districts.find((d) => d.id === values.districtId),
        subDistrict:
          shippingAddress?.subDistrict ?? addressData?.subDistricts.find((d) => d.id === values.subDistrictId),
        postalCode: shippingAddress?.postalCode ?? values.postalCode,
        googleMapLink: shippingAddress?.googleMapLink ?? values.googleMapLink,
      },
    }

    try {
      const response = await updateShippingAddressInfoAPI(taskId, request)
      if (response && response.status === 200) {
        triggerSnackbar('แก้ไขที่อยู่จัดส่งสำเร็จ')
        if (onComplete) onComplete(response.data)
      } else {
        triggerSnackbar('แก้ไขที่อยู่จัดส่งไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('แก้ไขที่อยู่จัดส่งไม่สำเร็จ')
    }
  }

  return (
    <Dialog open={true} fullWidth maxWidth="lg">
      <DialogTitle>แก้ไขข้อมูลที่อยู่สำหรับขนส่งงานเลขที่ {taskCode}</DialogTitle>
      <DialogContent>
        <ShippingAddressForm
          customerId={props.customerId}
          onSubmit={handleSubmit}
          onCancel={onClose}
          defaultValues={{
            consigneeName,
            consigneePhoneNumber,
            shippingAddressId,
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
