import { useEffect, useState } from 'react'
import {
  GetWithdrawalTicketTableDataQueryParams,
  IWithdrawalTicketTableData,
  IWithdrawalTicketTableData_Data,
  IWithdrawalTicketTableData_Summary,
  getWithdrawalTicketTableDataAPI,
} from '../../api/smartbestbuys-api/warehouse'
import { IUseTableData, IUseTableDataParams } from '../../types/pagination'

export interface IWithdrawalTicketTableData_SearchParams {
  statusIds: number[]
  code?: string
  requestedDate?: string
  estimatedReturnedDate?: string
  createdBy?: string
}

const buildQueryParams = (
  pageIndex: number,
  rowPerPage: number,
  search: IWithdrawalTicketTableData_SearchParams,
): GetWithdrawalTicketTableDataQueryParams => {
  return {
    pageIndex: pageIndex.toString(),
    rowPerPage: rowPerPage.toString(),
    statusIds: search.statusIds.length > 0 ? search.statusIds.join(',') : undefined,
    code: search.code,
    requestedDate: search.requestedDate,
    estimatedReturnedDate: search.estimatedReturnedDate,
    createdBy: search.createdBy,
  }
}

export const useWithdrawalTicketTableData = (
  params: IUseTableDataParams<IWithdrawalTicketTableData_SearchParams>,
):
  | (IUseTableData<
      IWithdrawalTicketTableData_Data,
      IWithdrawalTicketTableData_SearchParams,
      IWithdrawalTicketTableData_Summary
    > & { handleTabChange: (tabValue: number) => void })
  | undefined => {
  // state
  const [pageIndex, setPageIndex] = useState(params.pageIndex)
  const [rowPerPage, setRowPerPage] = useState(params.rowPerPage)

  const [pendingSearch, setPendingSearch] = useState<IWithdrawalTicketTableData_SearchParams>(params.search)
  const [search, setSearch] = useState<IWithdrawalTicketTableData_SearchParams>(params.search)
  const [tableData, setTableData] = useState<IWithdrawalTicketTableData | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(true)

  const _loadData = async (pageIndex: number, rowPerPage: number, search: IWithdrawalTicketTableData_SearchParams) => {
    const response = await getWithdrawalTicketTableDataAPI(buildQueryParams(pageIndex, rowPerPage, search))
    setTableData(response?.data)

    setIsLoading(false)
  }

  // first load
  useEffect(() => {
    _loadData(params.pageIndex, params.rowPerPage, search)
    // eslint-disable-next-line
  }, [])

  // functions
  const handlePageChange = async (_pageIndex: number) => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(_pageIndex, rowPerPage, search)
    setPageIndex(_pageIndex)
  }

  const handleRowPerPageChange = async (_rowPerPage: number) => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(0, _rowPerPage, search)
    setRowPerPage(_rowPerPage)
  }

  const handleSearchParamChange = <T extends keyof IWithdrawalTicketTableData_SearchParams>(
    key: T,
    value: IWithdrawalTicketTableData_SearchParams[T],
  ) => {
    setPendingSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const handleSearch = async () => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(0, rowPerPage, pendingSearch)
    setSearch(pendingSearch)
  }

  const handleTabChange = async (tabValue: number) => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    const _search = { ...pendingSearch, statusIds: [tabValue] }
    _loadData(0, rowPerPage, _search)
    setSearch(_search)
  }

  const refetch = async () => {
    if (!tableData || isLoading) return
    setIsLoading(true)

    _loadData(pageIndex, rowPerPage, search)
  }

  return tableData
    ? {
        ...tableData,
        search,
        pendingSearch,
        isLoading,
        handlePageChange,
        handleRowPerPageChange,
        handleSearchParamChange,
        handleSearchParamsChange: (s) => {},
        handleSearch,
        refetch,
        handleTabChange,
      }
    : undefined
}
