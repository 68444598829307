/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { SubTopic, TypographyWithLabel } from '../../../../../custom-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { Loading } from '../../../../../shared-components'
import RemarkDetail from '../../../../../sales-system/components/RemarkDetail'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'

const SaleOrderDetail: React.FC = () => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  if (!saleOrderProfile) return <Loading />

  const {
    customerPurchaseReference,
    contactChannel,
    currency,
    exchangeRate,
    paymentMethod,
    paymentCondition,
    depositRequired,
    dueDate,
    remark,
    quotationCode,
    paymentDueDate,
  } = saleOrderProfile

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>ข้อมูลใบสั่งขาย</SubTopic>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="เลขที่การสั่งซื้อของลูกค้า:">
              {customerPurchaseReference || '-'}
            </TypographyWithLabel>
          </Grid>
          {quotationCode && (
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="เลขที่ใบเสนอราคา:">{quotationCode}</TypographyWithLabel>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="ช่องทางการติดต่อ:">{contactChannel?.name || '-'}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="สกุลเงิน:">{currency.name}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="อัตราแลกเปลี่ยน:">{exchangeRate}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="วิธีการชำระเงิน:">{paymentMethod.name}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="เงื่อนไขการชำระเงิน:">{paymentCondition.name}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="การมัดจำ:">
              {depositRequired ? 'ต้องการมัดจำก่อนส่งสินค้า' : 'ไม่ต้องมัดจำ'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="วันกำหนดชำระเงิน:">
              {paymentDueDate ? format(new Date(paymentDueDate), 'dd MMM yyyy', { locale: thLocale }) : 'ไม่ระบุ'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="วันที่ลูกค้าต้องการ:">
              {dueDate ? format(dueDate, 'dd MMM yyyy', { locale: thLocale }) : 'ไม่ระบุ'}
            </TypographyWithLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <TypographyWithLabel label="หมายเหตุ:">{remark}</TypographyWithLabel>
          </Grid>
          <Grid item xs={12}>
            <RemarkDetail />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SaleOrderDetail
