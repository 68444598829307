import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles, Typography, TypographyProps } from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import { SOPaymentStatus as SOPaymentStatusEnum } from '../../enums'
import { BaseData } from '../../api/smartbestbuys-api/types'

interface Props {
  status: BaseData
  icon?: boolean
  chip?: number
}

const useStyles = (status: SOPaymentStatusEnum) =>
  makeStyles((theme) => {
    const soPaymentStatusMap = {
      [SOPaymentStatusEnum.Pending]: theme.palette.warning.main,
      [SOPaymentStatusEnum.Deposit]: theme.palette.warning.main,
      [SOPaymentStatusEnum.Paid]: theme.palette.success.main,
    }

    const soStatusIconMap = {
      [SOPaymentStatusEnum.Pending]: theme.palette.warning.darkText,
      [SOPaymentStatusEnum.Deposit]: theme.palette.warning.darkText,
      [SOPaymentStatusEnum.Paid]: theme.palette.success.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: soPaymentStatusMap[status],
      },
      icon: {
        color: soStatusIconMap[status],
      },
      typography: {
        color: soPaymentStatusMap[status],
      },
    })
  })

const SOPaymentStatus: React.FC<Props & Omit<ChipProps, 'icon'> & TypographyProps> = ({
  children,
  status,
  chip,
  icon,
  ...othersProps
}) => {
  const classes = useStyles(status.id)()
  if (chip)
    return (
      <Chip
        classes={{
          icon: classes.icon,
        }}
        className={classes.chip}
        icon={icon ? <AttachMoney /> : undefined}
        {...othersProps}
      />
    )

  return (
    <Typography className={classes.typography} variant="body2" {...othersProps}>
      {status.name}
    </Typography>
  )
}

export default SOPaymentStatus
