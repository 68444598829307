import { useContext } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import DashboardContext from '../DashboardContext'
import { SubTopic } from '../../../custom-components'
// import { useSalesReport } from '../../../hooks'
// import { GridBox } from '../../../shared-components'
// import { Bar, Statistic, NivoColorPalette, TableData, ProgressBar } from '../../../visaulization-components'
// import {
//   QuotationTotalAmountStat,
//   QuotationAmountStat,
//   SuccessOfferRateProgressBar,
//   QuotationProductReportTable,
//   QuotationTotalAmountBar,
//   QuotationAmountBar,
//   QuotationSuccessOfferRateBar,
// } from './QuotationDashboard'
import {
  // NewCustomerPurchasesTotalStat,
  // NewCustomerStat,
  // OldCustomerPurchasesTotalStat,
  // DebtTotalStat,
  ProductSubCategoryPercentageReportTable,
  // ReturnedRateProgressBar,
  // SaleOrderAmountStat,
  // SaleOrderCancellationRateProgressBar,
  // SaleOrderAmountStat,
  // SaleOrderCancellationRateProgressBar,
  // SaleOrderCancellationReportTable,
  // SaleOrderSuccessRateBarGraph,
  // SaleOrderSuccessRateProgressBar,
  // SaleOrderTotalAmountStat,
  // SaleOrderSuccessRateProgressBar,
  // SaleOrderTotalAmountStat,
  SalesTotalBarGraph,
  // SalesTotalStat,
} from './SalesDashboard'
import { Loading, Space } from '../../../shared-components'
import { StaticBox, ProgressBarBox } from '../../../components'
import { NivoColorPalette } from '../../../visaulization-components'
import { roundNumber } from '../../../functions/number-utils'

const SaleDashboard = () => {
  const { salesReport } = useContext(DashboardContext)

  if (!salesReport) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTopic>งานขาย / การตลาด</SubTopic>
        </Grid>
        <Loading />
      </Grid>
    )
  }

  const {
    successRate,
    saleOrdersTotal,
    saleOrderAmount,
    canceledRate,
    newCustomerSaleTotal,
    newCustomerSaleAmount,
    oldCustomerSaleTotal,
    latedDebtTotal,
    debtTotal,
    data,
    canceledSaleOrderTotal,
  } = salesReport
  const roundedSuccessRate = roundNumber(successRate)
  const roundedCanceledRate = roundNumber(canceledRate)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SubTopic>งานขาย / การตลาด</SubTopic>
      </Grid>
      {!salesReport ? (
        <Loading />
      ) : (
        <>
          <SalesTotalBarGraph />

          <StaticBox
            title="ยอดขายจากลูกค้าเก่า"
            colSpan={4}
            value={oldCustomerSaleTotal}
            subTitle="บาท"
            color={NivoColorPalette.oceanGreen}
            decimal
          />

          <StaticBox
            title="ยอดขายจากลูกค้าใหม่"
            colSpan={4}
            value={newCustomerSaleTotal}
            subTitle="บาท"
            color={NivoColorPalette.intenseOrange}
            decimal
          />

          <StaticBox
            title="จำนวนลูกค้าใหม่"
            colSpan={4}
            value={newCustomerSaleAmount}
            subTitle={
              <Typography>
                <span>จากลูกค้าทั้งหมด </span>
                <Box component="span" color={NivoColorPalette.intenseOrange}>
                  {data.length}
                </Box>
                <span> ราย</span>
              </Typography>
            }
            color={NivoColorPalette.oceanGreen}
          />

          <ProductSubCategoryPercentageReportTable />

          <Grid item xs={12} md={4}>
            <StaticBox
              title="มูลค่าใบสั่งขาย (SO)"
              colSpan={12}
              value={saleOrdersTotal}
              subTitle="บาท"
              color={NivoColorPalette.orange}
              decimal
            />
            <Space />
            <StaticBox
              title="มูลค่าใบสั่งขาย (ยกเลิก)"
              colSpan={12}
              value={canceledSaleOrderTotal}
              subTitle="บาท"
              color={NivoColorPalette.intenseOrange}
              decimal
            />
          </Grid>

          <ProgressBarBox
            title="อัตราความสำเร็จรวมจากใบสั่งขาย"
            value={roundedSuccessRate}
            unit="%"
            color={NivoColorPalette.oceanGreen}
          />

          <StaticBox
            title="จำนวนใบสั่งขาย"
            value={saleOrderAmount}
            subTitle="บาท"
            color={NivoColorPalette.intenseOrange}
          />

          <ProgressBarBox
            title="อัตราการยกเลิกใบสั่งขาย"
            value={roundedCanceledRate}
            unit="%"
            color={NivoColorPalette.intenseOrange}
          />

          <StaticBox
            title="ยอดหนี้ค้าง"
            value={debtTotal}
            subTitle="บาท"
            color={NivoColorPalette.intenseOrange}
            decimal
          />

          <StaticBox
            title="ยอดหนี้ค้าง (ล่าช้า)"
            value={latedDebtTotal}
            subTitle="บาท"
            color={NivoColorPalette.softOrange}
            decimal
          />
        </>
      )}
    </Grid>
  )
}

export default SaleDashboard
