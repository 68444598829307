import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { format } from 'date-fns'
import { FetchRequest, BaseData } from '..'
import { DocumentCustomerInfo, DocumentSaleOrderInfo, PriceBreakdownItem, RefSaleOrderPaymentInfo } from '.'

const path = `/documents/invoices`

export const fetchDocumentInvoices = errorHandler<DocumentInvoice[]>(async (request: FetchRequest) => {
  return await smartAuthAPI.get(path, {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
})

export interface DocumentInvoice {
  id: number
  code: string
  type: BaseData
  status: BaseData
  referenceDocumentId?: number
  content?: InvoiceContent
  state?: InvoiceState
  customerInfo?: DocumentCustomerInfo
  saleOrderInfo?: DocumentSaleOrderInfo
  total?: number
  recCreatedAt: Date
  recCreatedBy: string
  recModifiedAt?: Date
  recModifiedBy?: string

  refSaleOrderPaymentInfo?: RefSaleOrderPaymentInfo
}

export interface InvoiceState {
  percentAmount: number
  items: InvoiceItem[]
  priceBreakdowns: PriceBreakdownItem[]
  remark?: string
}

export interface InvoiceContent {
  customerInfo: DocumentCustomerInfo
  saleOrderInfo: DocumentSaleOrderInfo
  items: InvoiceItem[]
  priceBreakdown: InvoicePriceBreakdown
  amountTypeId: number
  percentAmount: number
  depositAmount: string
  remainingAmount: number
}

export interface InvoicePriceBreakdown {
  itemTotal: number
  discountAmount: number
  totalIncludingDiscount: number
  vatAmount: number
  totalIncludingVat: number
  depositAmount: string
  totalDeposit: number
  totalWithoutDeposit: number
  depositWithoutVat: number
  depositVatAmount: number
  total: number
}

export interface InvoiceItem {
  name: string
  code?: string
  imageUrl?: string // option
  amount: number
  pricePerEach: number
  total: number
}
