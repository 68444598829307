export enum CustomerRequisitionType {
  Claim = 1,
  CancelSO = 2,
}

export enum CustomerRequisitionStatus {
  PendingToReview = 101,
  Approved = 102,
  InProgress = 201,
  Done = 500,
  Rejected = 998,
  Canceled = 999,
}

export enum CustomerRequisitionCondition {
  CustomerReturnAtSmart = 1001,
  CustomerDeliverProduct = 1002,
  SmartToPickupProduct = 1003,
}

export enum CRReceiveProductStatus {
  Pending = 1,
  InProgress = 2,
  Done = 3,
}

export enum CRShippingProductStatus {
  Pending = 1,
  InProgree = 2,
  SomeDone = 3,
  Done = 4,
}
