import { Button, Grid, Paper, Tab, Tabs } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FlexBox, SubTopic } from '../../../../../../custom-components'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../../../../shared-components'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import CustomerProfileContext from '../../CustomerProfileContext'
import renderCustomerQuotationTable from './renderCustomerQuotationTable'
import renderCustomerSaleOrder from './renderCustomerSaleOrder'

const CustomerPurchaseLogBox: React.FC = () => {
  const history = useHistory()

  const [tableIndex, setTableIndex] = useState<number>(0)

  const {
    customerProfile: { id, quotations, saleOrders, recDeletedAt },
  } = useContext(CustomerProfileContext)

  const tables = [
    renderCustomerQuotationTable(
      quotations?.sort((a, b) => new Date(b.recCreatedAt).getTime() - new Date(a.recCreatedAt).getTime()),
    ),
    renderCustomerSaleOrder(
      saleOrders?.sort((a, b) => new Date(b.recCreatedAt).getTime() - new Date(a.recCreatedAt).getTime()),
    ),
  ]

  const writeQTPermission = getUserPermissionInfo(PermissionEnum.Sales_Quotation_Write)

  const isActiveCustomer = !recDeletedAt

  const disableWrite = !writeQTPermission.hasPermission || !isActiveCustomer

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SubTopic>ประวัติการซื้อของลูกค้า</SubTopic>
        </Grid>
        <Grid item xs={12} md={6}>
          <FlexBox gridGap={8} justifyContent="flex-end">
            <ConditionTooltip
              title={
                !writeQTPermission.hasPermission ? writeQTPermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
              }
              showTooltip={disableWrite}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Description />}
                onClick={() => history.push(`/quotations/create?customerId=${id}`)}
                disabled={disableWrite}
              >
                สร้างใบเสนอราคา
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Paper square style={{ padding: 0 }}>
            <Tabs
              value={tableIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, value) => setTableIndex(value)}
              aria-label="disabled tabs example"
              centered
              variant="fullWidth"
            >
              <Tab label="ใบเสนอราคา" value={0} />
              <Tab label="ใบสั่งขาย" value={1} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {tables[tableIndex]}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerPurchaseLogBox
