import { useMemo } from 'react'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { getUserPermissionInfo } from '../../../../utils/permission'
import { WithdrawalTicketStatus } from '../../../../enums'
import { IComponentSetting } from '../../../interfaces'
import { IProductWithdrawalTicketProfile } from '../../../../api/smartbestbuys-api/warehouse'

export enum PWTicketProfilePageMenuItemTopic {
  Edit = 'edit',
  Cancel = 'cancel',
}

export interface IPWTicketProfilePageSetting {
  actionBox: {
    hidden: boolean
    menu: IComponentSetting & {
      menuItems: Record<PWTicketProfilePageMenuItemTopic, IComponentSetting>
    }
    confirmReceivedButton: IComponentSetting
    confirmReturnedButton: IComponentSetting
    followUpButton: IComponentSetting
  }
  reviewActionBox: IComponentSetting
}

// constants
export const defaultPageSettings: IPWTicketProfilePageSetting = {
  actionBox: {
    hidden: true,
    menu: {
      hidden: true,
      menuItems: {
        [PWTicketProfilePageMenuItemTopic.Edit]: {
          hidden: true,
        },
        [PWTicketProfilePageMenuItemTopic.Cancel]: {
          hidden: true,
        },
      },
    },
    confirmReceivedButton: {
      hidden: true,
    },
    confirmReturnedButton: {
      hidden: true,
    },
    followUpButton: {
      hidden: true,
    },
  },
  reviewActionBox: {
    hidden: true,
  },
}

export const profilePageText = {
  activity: {
    title: 'ประวัติการจัดการคำขอเบิกสินค้า',
  },
  dataBox: {
    detail: {
      title: 'รายละเอียดคำขอเบิก',
      requester: 'ผู้ขอเบิกสินค้า:',
      requestedDate: 'วันที่ต้องการสินค้า:',
      estimatedReturnedDate: 'วันที่คาดว่าจะคืนสินค้า:',
      withdrawalReason: 'เหตุผลคำขอเบิกสินค้า:',
      remark: 'หมายเหตุเพิ่มเติม:',
    },
    productTable: {
      title: 'รายละเอียดสินค้าที่ขอเบิก',
      header: {
        productDetail: 'รายละเอียด',
        withdrawnAmount: 'จำนวนที่ขอเบิก',
        returnedAmount: 'จำนวนที่คืนแล้ว',
      },
      detail: {
        code: 'รหัสสินค้า:',
        nameLocal: 'ชื่อสินค้า:',
      },
    },
    approveButton: {
      title: 'อนุมัติ',
    },
    rejectButton: {
      title: 'ไม่อนุมัติ',
    },
  },
  header: {
    title: 'คำขอเบิกสินค้าเลขที่',
  },
  actionBox: {
    editButton: {
      title: 'แก้ไขข้อมูลคำขอเบิก',
    },
    cancelButton: {
      title: 'ยกเลิกคำขอเบิก',
    },
    confirmReceivedButton: {
      title: 'เบิกสินค้าให้ผู้ขอ',
    },
    confirmReturnedButton: {
      title: 'รับสินค้าคืน',
    },
    followUpButton: {
      title: 'ติดตามคำขอเบิกสินค้า',
    },
  },
  dialog: {
    followUpDialog: {
      title: 'ติดตามคำขอเบิกสินค้า',
      content: 'ต้องการติดตามคำขอเบิกสินค้านี้ใช่ไหม ?',
      remarkLabel: 'หมายเหตุเพิ่มเติม',
      confirmButton: 'ยืนยัน',
      cancelButton: 'ย้อนกลับ',
      successText: 'ติดตามคำขอเบิกสินค้าสำเร็จ',
      failText: 'ติดตามคำขอเบิกสินค้าไม่สำเร็จ',
    },
  },
}

export const usePWTicketProfilePageSetting = (
  profile: IProductWithdrawalTicketProfile | undefined,
): IPWTicketProfilePageSetting => {
  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Warehouse_WithdrawalTicket_Write)
  const reviewPermission = getUserPermissionInfo(PermissionEnum.Warehouse_WithdrawalTicket_Review)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Warehouse_WithdrawalTicket_Cancel)

  return useMemo<IPWTicketProfilePageSetting>(() => {
    if (!profile) return defaultPageSettings

    // conditions
    const editTooltipConditions = [
      {
        showTooltip: !writePermission.hasPermission,
        message: writePermission.alertMessage,
      },
      {
        showTooltip: profile.status.id === WithdrawalTicketStatus.Approved,
        message: 'คำขอถูกอนุมัติแล้ว',
      },
      {
        showTooltip: profile.status.id === WithdrawalTicketStatus.InProgress,
        message: 'สินค้าถูกเบิกแล้ว',
      },
    ]

    const cancelTooltipConditions = [
      {
        showTooltip: !cancelPermission.hasPermission,
        message: cancelPermission.alertMessage,
      },
      {
        showTooltip: profile.status.id === WithdrawalTicketStatus.InProgress,
        message: 'สินค้าถูกเบิกแล้ว',
      },
    ]

    // actions
    const hiddenMenu =
      [...editTooltipConditions, ...cancelTooltipConditions].some((con) => con.showTooltip) ||
      [WithdrawalTicketStatus.Cancelled, WithdrawalTicketStatus.Rejected, WithdrawalTicketStatus.Done].includes(
        profile.status.id,
      )

    const hiddenReviewButton = !reviewPermission.hasPermission || profile.status.id !== WithdrawalTicketStatus.Pending

    const hiddenConfirmReceivedButton =
      !writePermission.hasPermission || profile.status.id !== WithdrawalTicketStatus.Approved

    const hiddenConfirmReturnedButton =
      !writePermission.hasPermission || profile.status.id !== WithdrawalTicketStatus.InProgress

    const hiddenFollowupButton =
      !writePermission.hasPermission || profile.status.id !== WithdrawalTicketStatus.InProgress

    return {
      actionBox: {
        hidden: hiddenMenu,
        menu: {
          hidden: hiddenMenu,
          menuItems: {
            [PWTicketProfilePageMenuItemTopic.Edit]: {
              disabled: editTooltipConditions.some((con) => con.showTooltip),
              tooltipConditions: editTooltipConditions,
            },
            [PWTicketProfilePageMenuItemTopic.Cancel]: {
              disabled: cancelTooltipConditions.some((con) => con.showTooltip),
              tooltipConditions: cancelTooltipConditions,
            },
          },
        },
        confirmReceivedButton: {
          hidden: hiddenConfirmReceivedButton,
        },
        confirmReturnedButton: {
          hidden: hiddenConfirmReturnedButton,
        },
        followUpButton: {
          hidden: hiddenFollowupButton,
        },
      },
      reviewActionBox: {
        hidden: hiddenReviewButton,
      },
    }
  }, [profile, writePermission, reviewPermission, cancelPermission])
}
