import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'

const Title: React.FC<TypographyProps> = (props) => (
  <Typography variant="h6" {...props}>
    {props.children}
  </Typography>
)

export default Title
