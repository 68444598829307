import { useState, useEffect } from 'react'
import { IProductChangeData, getProductPreviewDataAPI } from '../../api/smartbestbuys-api'

export const useProductPreviewData = (id?: number, reload?: boolean): IProductChangeData | undefined => {
  const [previewData, setPreviewData] = useState<IProductChangeData | undefined>(undefined)

  const _getProductProfile = async (id: number) => {
    const response = await getProductPreviewDataAPI(id)
    setPreviewData(response?.data)
  }

  useEffect(() => {
    if (id) _getProductProfile(id)
  }, [id, reload])

  return previewData
}
