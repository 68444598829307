import { FetchTaskRequest } from '..'
import { dateSqlFormat } from '../../../functions'
import { errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import {
  FollowServiceTask_TableData,
  PurchaseTaskReceiveServiceRequestBody,
  SinglePurchaseTask,
} from './purchase-types'

export const fetchPurchaseTasks = errorHandler<FollowServiceTask_TableData[]>(async (request: FetchTaskRequest) => {
  return smartAuthAPI.get('/purchase/v1/purchase-tasks/fetch', {
    params: {
      typeId: request.typeId,
      startDate: dateSqlFormat(request.startDate),
      endDate: dateSqlFormat(request.endDate),
    },
  })
})

export const getPurchaseTask = errorSingleHandler<SinglePurchaseTask>(async (id: number, typeId?: number) => {
  return smartAuthAPI.get(`purchase/v1/purchase-tasks/${id}`, {
    params: {
      typeId,
    },
  })
})

export const receiveServiceAPI = errorSingleHandler<SinglePurchaseTask>(
  async (id: number, request: PurchaseTaskReceiveServiceRequestBody) => {
    return smartAuthAPI.post(`purchase/v1/purchase-tasks/${id}/receive-service`, request)
  },
)
