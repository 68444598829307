import React, { useEffect, useMemo } from 'react'
import clsx from 'clsx'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  Box,
  Avatar,
  CircularProgress,
  Card,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { TableProps, HeadCell } from './types'
import { stableSort, getComparator, tColumnToHeadCells } from './table-function'
import { useStyles, useToolbarStyles } from './styles'
import numeral from 'numeral'

import './Table.less'

interface EnhancedTableProps {
  selectable?: boolean
  hideSelectAll?: boolean
  headCells: HeadCell[]
  styles: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: 'asc' | 'desc'
  orderBy?: string
  rowCount: number
  color?: 'primary' | 'primary.dark' | 'secondary.dark' | 'grey.800'
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    styles,
    onSelectAllClick,
    numSelected,
    rowCount,
    order,
    orderBy,
    onRequestSort,
    headCells,
    selectable,
    color,
    hideSelectAll,
  } = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort && onRequestSort(event, property)
  }

  let headerClassName: string | undefined = undefined
  switch (color) {
    case 'primary':
      headerClassName = styles.primaryHead
      break
    case 'primary.dark':
      headerClassName = styles.primaryDarkHead
      break
    case 'secondary.dark':
      headerClassName = styles.secondaryDarkHead
      break
    case 'grey.800':
      headerClassName = styles.grey800Head
      break
    default:
      break
  }

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <Box bgcolor={color} clone>
            <TableCell className={headerClassName} padding="checkbox">
              {hideSelectAll && (
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              )}
            </TableCell>
          </Box>
        )}
        {headCells.map((headCell, index) => (
          <Box bgcolor={color} clone key={`head-${index}`}>
            <TableCell
              className={headerClassName}
              key={headCell.label}
              align={headCell.numeric ? 'right' : headCell.align ?? 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {onRequestSort && headCell.sort ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={styles.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          </Box>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  name: string
  numSelected: number
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const styles = useToolbarStyles()
  const { name, numSelected } = props

  return (
    <Toolbar
      className={clsx(styles.root, {
        [styles.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={styles.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={styles.title} variant="h6" id="tableTitle" component="div">
          {name}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

const EnhancedTable = <T extends { id: number }>(props: TableProps<T>): JSX.Element => {
  const {
    name,
    columns,
    data,
    selectable,
    disableCheckbox,
    hideCheckbox,
    pagination = true,
    size,
    color,
    sortable,
    postCells,
    cleanSpace,
    emptyText,
    emptyImage,
    rowsPerPage: initRowsPerPage,
    selected: inputSelected,
    verticalAlign,
    onChange,
    onSelect,
    onDeSelect,
    paperClassName,
    forcePageIndex,
    setForcePageIndex,
    inActive,
    shouldRender,
    paginationInfo,
    onPageChange,
    onRowPerPageChange,
    isLoading,
  } = props
  const styles = useStyles()
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = React.useState<string | undefined>(undefined)
  const [selected, setSelected] = React.useState<number[]>(inputSelected ?? [])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(initRowsPerPage ?? 10)

  useEffect(() => {
    if (forcePageIndex !== undefined && page !== forcePageIndex) {
      setPage(forcePageIndex)
      if (!!setForcePageIndex) setForcePageIndex(undefined)
    }
    // eslint-disable-next-line
  }, [forcePageIndex])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    if (!sortable) return
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data
        .filter((d, index) => {
          if (!!disableCheckbox && !!hideCheckbox) return !disableCheckbox(d, index) && !hideCheckbox(d, index)
          else if (disableCheckbox) return !disableCheckbox(d, index)
          else if (hideCheckbox) return !hideCheckbox(d, index)
          return true
        })
        .map((n) => n.id)
      setSelected(newSelecteds)
      if (!!onChange) onChange(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    if (!selectable) return
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = [...selected]

    if (selectedIndex === -1) {
      newSelected = [...newSelected, id]
      if (onSelect) onSelect(id)
    } else {
      newSelected = [...newSelected.slice(0, selectedIndex), ...newSelected.slice(selectedIndex + 1)]
      if (onDeSelect) onDeSelect(id)
    }

    setSelected(newSelected)
    if (!!onChange) onChange([...newSelected])
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    if (onPageChange) onPageChange(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _rowPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(_rowPerPage)
    setPage(0)
    if (onRowPerPageChange) onRowPerPageChange(_rowPerPage)
  }

  const emptyRows = paginationInfo ? 0 : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
  const paginate = (data: T[]) =>
    paginationInfo ? data : pagination ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data

  const validColumns = useMemo(() => columns.filter((c) => !c.hide), [columns])

  return (
    <div className={`${styles.root} ${paperClassName}`}>
      <div className={styles.paper}>
        {isLoading && (
          <Card className={styles.loading}>
            <CircularProgress color="secondary" />
            <Typography color="secondary">กรุณารอสักครู่...</Typography>
          </Card>
        )}
        {name && <EnhancedTableToolbar name={name} numSelected={selected.length} />}
        <TableContainer>
          <Table
            className={styles.table}
            aria-labelledby="tableTitle"
            size={size || 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              selectable={selectable}
              headCells={validColumns.map(tColumnToHeadCells)}
              styles={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={sortable ? handleRequestSort : undefined}
              rowCount={data.length}
              color={color}
            />
            <TableBody>
              {data.length > 0 ? (
                paginate(sortable ? stableSort(data, getComparator(order, orderBy)) : data).map((rowData, rowIndex) => {
                  const isItemSelected = selected.indexOf(rowData.id) !== -1
                  const labelId = `enhanced-table-checkbox-${rowIndex}`
                  const isInActive = inActive ? inActive(rowData, rowIndex) : false
                  const isShouldRender = shouldRender ? shouldRender(rowData, rowIndex) : true

                  const classNames = []
                  if (isInActive) classNames.push('table-row-inactive')
                  if (!isShouldRender) classNames.push('table-row-hide')

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, rowData.id)}
                      className={classNames.join(' ')}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={rowIndex}
                      selected={isItemSelected}
                    >
                      {/* This is for selectable table */}
                      {selectable && (
                        <TableCell padding="checkbox" style={{ verticalAlign: verticalAlign || 'top', paddingTop: 4 }}>
                          {(!hideCheckbox || !hideCheckbox(rowData, rowIndex)) && (
                            <Checkbox
                              key={`checkbox-${rowIndex}`}
                              checked={isItemSelected}
                              disabled={!!disableCheckbox && !disableCheckbox(rowData, rowIndex)}
                              onClick={(e) => handleClick(e, rowData.id)}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          )}
                        </TableCell>
                      )}
                      {validColumns.map((column, index) => {
                        const { numeric, render, format } = column
                        const cellValue = render ? render(rowData, rowIndex) : rowData[column.dataIndex as keyof T]

                        return (
                          <TableCell
                            key={`${column.name}-${index}`}
                            align={column.numeric ? 'right' : column.align ?? 'left'}
                            style={{
                              width: column.fixedWidth,
                              verticalAlign: verticalAlign || 'top',
                              height: '3rem',
                            }}
                          >
                            {numeric && format ? numeral(cellValue).format(format) : cellValue}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow style={{ height: cleanSpace ? 53 : 53 * emptyRows }}>
                  <TableCell align="center" colSpan={validColumns.length}>
                    {emptyImage && <Avatar src={emptyImage} className={styles.avatar} />}
                    <Typography color="textSecondary">{emptyText || 'Data is Empty'}</Typography>
                  </TableCell>
                </TableRow>
              )}
              {postCells}
              {emptyRows > 0 && !cleanSpace && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={validColumns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!paginationInfo ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={paginationInfo.totalData}
            rowsPerPage={paginationInfo.rowPerPage}
            page={paginationInfo.pageIndex}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          pagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )
        )}
      </div>
    </div>
  )
}

export default EnhancedTable
