import { CreatedDocument } from '..'
import { errorSingleHandler } from '../../middleware'
import { CreditNoteItem, PriceBreakdownItem } from '../document-management'
import smartAuthAPI from '../smartbestbuys-api'

export const createCreditNote = errorSingleHandler<CreatedDocument>(
  async (saleOrderId: number, request: CreditNoteCreationRequest) => {
    const path = `/sale-orders/${saleOrderId}/create-credit-note`
    return await smartAuthAPI.post(path, request)
  },
)

export interface CreditNoteCreationRequest {
  taxInvoiceId?: number
  customerId: number
  total: number
  items: CreditNoteItem[]
  priceBreakdowns: PriceBreakdownItem[]
  taxInvoiceReferences: string[]
  remark?: string

  actionMessageId?: number
}
