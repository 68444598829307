import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { SOPaymentTransactionStatus } from '../../enums'

interface Props {
  status: SOPaymentTransactionStatus
  icon?: boolean
}

const useStyles = (status: SOPaymentTransactionStatus) =>
  makeStyles((theme) => {
    const chipColors = {
      [SOPaymentTransactionStatus.PENDING]: theme.palette.warning.light,
      [SOPaymentTransactionStatus.APPROVED]: theme.palette.success.light,
      [SOPaymentTransactionStatus.DECLINED]: theme.palette.error.light,
      [SOPaymentTransactionStatus.CANCELLED]: theme.palette.error.light,
    }

    const iconColors = {
      [SOPaymentTransactionStatus.PENDING]: theme.palette.warning.darkText,
      [SOPaymentTransactionStatus.APPROVED]: theme.palette.success.darkText,
      [SOPaymentTransactionStatus.DECLINED]: theme.palette.error.darkText,
      [SOPaymentTransactionStatus.CANCELLED]: theme.palette.error.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: chipColors[status],
      },
      icon: {
        color: iconColors[status],
      },
    })
  })

const PaymentTransactionStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({
  children,
  status,
  icon,
  ...othersProps
}) => {
  const classes = useStyles(status)()

  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      {...othersProps}
    />
  )
}

export default PaymentTransactionStatusChip
