import { Checkbox, Grid, MenuItem, Paper, Typography } from '@material-ui/core'
import React, { useMemo, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseData } from '../../../../api/smartbestbuys-api'
import { GeneralTextFieldForm, KeyboardDatePickerForm } from '../../../../custom-components'
import { CurrenciesType } from '../../../../enums'
import { PurchaseRequisitionTopic } from '../../../../enums/PurchaseRequisitionEnums'
import PurchaseRequisitionCreateFormValues from '../PurchaseRequisitionCreateFormValue'

interface Props {
  purchaseRequisitionTopics: BaseData[]
  currencies: BaseData[]
  isPRRefFromSaleOrder: boolean
  rop?: boolean
}

const PurchaseProductInfo: React.FC<Props> = (props) => {
  const { purchaseRequisitionTopics, currencies, isPRRefFromSaleOrder, rop } = props
  const [isUseCurrency, setIsUseCurrency] = useState(false)
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<PurchaseRequisitionCreateFormValues>()
  const { dueDate, currencyId } = watch()
  const topicSelectLocked = isPRRefFromSaleOrder || !!rop

  const whitelistTopics = useMemo(() => {
    if (topicSelectLocked) return purchaseRequisitionTopics
    const whitelistTopicsId = [
      PurchaseRequisitionTopic.InternalPurchase,
      PurchaseRequisitionTopic.ForcastStock,
      PurchaseRequisitionTopic.FoundDamangedProduct,
    ]
    return purchaseRequisitionTopics.filter((topic) => whitelistTopicsId.includes(topic.id))
    // eslint-disable-next-line
  }, [purchaseRequisitionTopics.length, topicSelectLocked])

  useEffect(() => {
    if (currencyId !== CurrenciesType.THB) {
      setIsUseCurrency(true)
    }
  }, [currencyId, isUseCurrency, setIsUseCurrency])

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUseCurrency(event.target.checked)
  }

  return (
    <Paper style={{ height: '100%' }}>
      <Typography variant="h5">ข้อมูลคำขอซื้อ</Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            control={control}
            name="topicId"
            defaultValue=""
            rules={{ required: 'กรุณากรอกเหตุผล' }}
            label="เหตุผล *"
            select
            disabled={topicSelectLocked}
          >
            {whitelistTopics.map((topic) => (
              <MenuItem key={topic.id} value={topic.id}>
                {topic.name}
              </MenuItem>
            ))}
          </GeneralTextFieldForm>
        </Grid>

        <Grid item xs={12} md={6}>
          <KeyboardDatePickerForm
            control={control}
            label="วันที่ต้องการสินค้า"
            name="dueDate"
            size="small"
            value={dueDate}
            fullWidth
            defaultValue={null}
            rules={{ required: { value: true, message: 'กรุณาระบุวันที่ต้องการสินค้า' } }}
            error={!!errors.dueDate}
            helperText={errors.dueDate?.message}
          ></KeyboardDatePickerForm>
        </Grid>

        <Grid item xs={12} md={6} style={{ alignItems: 'inherit', display: 'flex' }}>
          <Checkbox size="small" checked={isUseCurrency} onChange={handleSelect} />
          <Typography variant="body1">ซื้อจากต่างประเทศ</Typography>
          {isUseCurrency && (
            <GeneralTextFieldForm
              style={{ flex: 1, paddingLeft: '10px' }}
              control={control}
              name="currencyId"
              defaultValue=""
              rules={{ required: 'กรุณากรอกสกุลเงินที่ใช้' }}
              label="สกุลเงินที่ใช้ *"
              select
            >
              {currencies.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {currency.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PurchaseProductInfo
