import { DialogProps } from '@material-ui/core'
import {
  IProductWithdrawalTicketProfile,
  cancelProductWithdrawalTicketAPI,
} from '../../../../../api/smartbestbuys-api/warehouse'
import { UserActivityDialog, UserActivityDialog_Option, UserActivityValues } from '../../../../../shared-components'
import { UserAction } from '../../../../../enums'

interface Props extends DialogProps {
  profile: IProductWithdrawalTicketProfile
  onSuccess: (updatedProfile: IProductWithdrawalTicketProfile) => void
  onFail: () => void
}

const PWTicketCancelDialog: React.FC<Props> = (props) => {
  const { profile, onSuccess, onFail, onClose, ...dialogProps } = props

  const { id, code } = profile

  const handleClose = () => {
    onClose && onClose({}, 'escapeKeyDown')
  }

  const handleSubmit = async (values: UserActivityValues) => {
    const response = await cancelProductWithdrawalTicketAPI(id, {
      actionId: values.actionId,
      actionMessage: values.actionMessage,
      actionMessageId: values.actionMessageId === -1 ? undefined : values.actionMessageId,
      remark: values.remark,
    })

    if (response?.status === 200) {
      onSuccess(response.data)
    } else {
      onFail()
    }
    return true
  }

  const textOption = {
    title: `ยกเลิกคำขอเบิกสินค้าเลขที่ ${code}`,
    description: `ต้องการยกเลิกคำขอเบิกใช่ไหม ?`,
    actionMessageText: 'เหตุผลของการยกเลิกคำขอ',
    actionMessageErrorText: 'กรุณากรอกเหตุผลใหม่',
    cancelText: 'ย้อนกลับ',
  }

  const option: UserActivityDialog_Option = {
    enableActionMessage: true,
    enableRemark: true,
    allowNewMessage: true,
  }

  return (
    <UserActivityDialog
      {...dialogProps}
      fullWidth
      userAction={UserAction.CancelProductWithdrawalTicket}
      textOption={textOption}
      option={option}
      onDialogSubmit={handleSubmit}
      onClose={handleClose}
    />
  )
}

export default PWTicketCancelDialog
