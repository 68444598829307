import { FormProvider, useForm } from 'react-hook-form'
import { UserProfile } from '../../../api/smartbestbuys-api'
import { Form, GeneralTextFieldForm, Grid } from '../../../custom-components'
import { SubmitForm, UploadImageCard } from '../../../shared-components'

interface Props {
  user: UserProfile
  onSubmit: (values: UserInformationUpdateFormValues) => void
  onCancel: () => void
}

export interface UserInformationUpdateFormValues {
  nickName: string
  firstName: string
  lastName: string
  birthDate: string
  email?: string
  phoneNumber?: string
  base64?: string
  imageUrl?: string
}

export const UserInformationUpdateForm: React.FC<Props> = (props) => {
  const { user, onSubmit, onCancel } = props
  const formMethod = useForm<UserInformationUpdateFormValues>({
    defaultValues: {
      nickName: user.nickName,
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: user.birthDay,
      email: user.email,
      phoneNumber: user.phoneNumber,
      base64: undefined,
      imageUrl: user.imageUrl,
    },
  })
  const {
    control,
    setValue,
    formState: { isSubmitting },
  } = formMethod

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={(value) => onSubmit(value)}>
        <Grid container spacing={2}>
          <Grid item xs={12} container justify="center">
            <UploadImageCard
              previewOnChange
              title="ภาพโปรไฟล์ (ถ้ามี)"
              onChange={(image) => setValue('base64', image.base64)}
              defaultSrc={user.imageUrl}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="firstName"
              label="ชื่อ *"
              rules={{ required: 'กรุณากรอกชื่อ' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="lastName"
              label="นามสกุล *"
              rules={{ required: 'กรุณากรอกนามสกุล' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="nickName"
              label="ชื่อเล่น *"
              rules={{ required: 'กรุณากรอกชื่อเล่น' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="birthDate" type="date" />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="phoneNumber" label="เบอร์โทรศัพท์ (ถ้ามี)" />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name="email" label="อีเมล (ถ้ามี)" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SubmitForm
            submitText="ยืนยันการแก้ไขข้อมูล"
            cancelText="ย้อนกลับ"
            disableSubmit={isSubmitting}
            onCancel={onCancel}
          />
        </Grid>
      </Form>
    </FormProvider>
  )
}
