import React, { useState, useContext, useEffect, useMemo } from 'react'
import { RootContext } from '../../../..'
import { withLayout, FilteredTable, Topic, Link } from '../../../../custom-components'
import { NavigationItemKey, PageName, WarehouseTaskType } from '../../../../enums'
import { Grid, Box, Paper } from '@material-ui/core'
import { WarehouseTask } from '../../../../api/smartbestbuys-api'
import { useWarehouseTasks } from '../../../../hooks'
import { defaultEndDate, defaultStartDate } from '../../../../data'
import { NotificationHead, TaskStatusChip } from '../../../../shared-components'
import { dateFormat } from '../../../../functions'
import WarehouseTaskDialog from '../../../../shared-components/WarehouseTaskDialog/WarehouseTaskDialog'
import lodash from 'lodash'

const WarehousePendingPickupTasksPage: React.FC = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.WAREHOUSE_PEDNING_CUSTOMER_PICKUP)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const tasks = useWarehouseTasks(startDate, endDate, WarehouseTaskType.PendingCustomerPickup)

  const [data, setData] = useState<WarehouseTask[]>([])

  useEffect(() => {
    if (tasks.length > 0) {
      setData(tasks)
    }
  }, [tasks])

  const statuses = useMemo(() => {
    return lodash
      .chain(tasks)
      .uniqBy('status.id')
      .orderBy('status.id')
      .value()
      .map((doc) => {
        return {
          name: doc.status.name,
          value: doc.status.id,
        }
      })
    // eslint-disable-next-line
  }, [tasks.map((doc) => doc.code).toString()])

  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>รายการนัดรับสินค้าหน้าร้าน</Topic>
        </Grid>
        <Grid item xs={12}>
          <NotificationHead navigationKey={NavigationItemKey.CustomerPickupProduct} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<WarehouseTask>
              color="primary.dark"
              columns={[
                { name: 'สถานะงาน', render: ({ status }) => <TaskStatusChip status={status.id} label={status.name} /> },
                {
                  name: 'เลขที่งานคลัง',
                  render: (task) => (
                    <>
                      <WarehouseTaskDialog
                        open={selectedId === task.id}
                        onClose={() => setSelectedId(undefined)}
                        onSuccess={(updatedData) => {
                          let newData = data
                          const findIndex = newData.findIndex((d) => d.id === updatedData.id)
                          if (findIndex !== -1) {
                            const found = newData[findIndex]
                            const updated = {
                              ...found,
                              ...updatedData,
                            }
                            newData[findIndex].status = updated.status
                            setData([...newData])
                          }
                        }}
                        task={task}
                      />
                      <Link style={{ cursor: 'pointer' }} onClick={() => setSelectedId(task.id)}>
                        {task.code}
                      </Link>
                    </>
                  ),
                },
                {
                  name: 'เลขใบสั่งขายอ้างอิง',
                  render: ({ saleOrderInfo }) =>
                    saleOrderInfo ? (
                      <Link color="secondary" to={`/sale-orders/${saleOrderInfo.id}`}>
                        {saleOrderInfo.code}
                      </Link>
                    ) : (
                      'ไม่พบใบสั่งขาย'
                    ),
                },
                { name: 'ชื่อลูกค้า', render: ({ customerInfo }) => customerInfo?.name },
                { name: 'วันที่นัดรับ', render: ({ recCreatedAt }) => dateFormat(recCreatedAt) },
              ]}
              size="small"
              data={data}
              filters={[
                {
                  name: 'สถานะ',
                  values: statuses,
                  filter: (data: WarehouseTask, values: (string | number)[]) => values.includes(data.status.id),
                },
              ]}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(WarehousePendingPickupTasksPage)
