import { Box, Paper, Grid, MenuItem, Divider, Button } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ButtonRadios } from '.'
import { ICustomerProfile_Address } from '../api/smartbestbuys-api'
import { SubDistrict, District } from '../api/smartbestbuys-api/data/data-types'
import { GeneralTextFieldForm, TypographyWithLabel } from '../custom-components'
import { useAddressData } from '../hooks/data/useAddressData'
import PurchaseOrderCreateFormValues from '../pages/purchase-orders/create/PurchaseOrderCreateFormValues'

enum CustomerFormType {
  Local = 1,
  OtherCountry = 2,
}

interface CustomerAddressFormProps {
  name?: string
  supportOversea?: boolean
  refCustomerAddresses?: ICustomerProfile_Address[]
}

const CustomerAddressForm = (props: CustomerAddressFormProps) => {
  // props
  const { refCustomerAddresses, name, supportOversea } = props
  const [selectedRefCustomerAddress, setSelectedRefCustomerAddress] = useState<number>(0)

  // form hook
  const { control, watch, setValue } = useFormContext<PurchaseOrderCreateFormValues>()
  const info = watch()[name as keyof PurchaseOrderCreateFormValues] as {
    postalCode: string
    provinceId: number
    districtId: number
    subDistrictId: number
  }
  const { postalCode, provinceId, districtId, subDistrictId } = { ...info }

  const addressData = useAddressData()
  // eslint-disable-next-line
  const provinces = useMemo(() => addressData?.provinces ?? [], [(addressData?.provinces ?? []).length])
  // eslint-disable-next-line
  const districts = useMemo(() => addressData?.districts ?? [], [(addressData?.districts ?? []).length])
  // eslint-disable-next-line
  const subDistricts = useMemo(() => addressData?.subDistricts ?? [], [(addressData?.subDistricts ?? []).length])

  const subDistrictOptions: SubDistrict[] = useMemo(() => {
    if (!postalCode) return [] as SubDistrict[]
    return subDistricts.filter((sd) => sd.postalCode.toString() === postalCode)
    // eslint-disable-next-line
  }, [postalCode, subDistricts.length])

  const selectedSubDistrict: SubDistrict | undefined = useMemo(() => {
    const subDistrict = subDistricts.find((sd) => sd.id === subDistrictId)
    setValue(`${name}.subDistrict` as any, subDistrict)
    return subDistrict
    // eslint-disable-next-line
  }, [subDistrictId])

  const districtOptions: District[] = useMemo(() => {
    if (!selectedSubDistrict?.districtId) return [] as District[]
    return districts.filter((d) => d.id === selectedSubDistrict.districtId)
    // eslint-disable-next-line
  }, [selectedSubDistrict?.districtId])

  const selectedDistrict: District | undefined = useMemo(() => {
    const district = districts.find((d) => d.id === selectedSubDistrict?.districtId)
    setValue(`${name}.districtId` as unknown as keyof PurchaseOrderCreateFormValues, district?.id)
    setValue(`${name}.district` as any, district)
    return district
    // eslint-disable-next-line
  }, [selectedSubDistrict?.districtId, setValue, name])

  useEffect(() => {
    const provice = provinces.find((v) => v.id === selectedDistrict?.provinceId)
    setValue(`${name}.provinceId` as unknown as keyof PurchaseOrderCreateFormValues, provice?.id)
    setValue(`${name}.province` as any, provice)
    // eslint-disable-next-line
  }, [selectedDistrict?.provinceId, setValue, name])

  // state
  const [formType, setFormType] = useState<number>(CustomerFormType.Local)

  const handleOnClickRefCustomerAddress = (id: number) => {
    const billingAddress = refCustomerAddresses?.find((el) => el.id === id)

    if (billingAddress) {
      const shippingAddressValue = {
        name: billingAddress.name,
        branchName: billingAddress.branchName,
        postalCode: billingAddress.postalCode,
        provinceId: billingAddress.province?.id,
        districtId: billingAddress.district?.id,
        subDistrictId: billingAddress.subDistrict?.id,
        addressLine1: billingAddress.addressLine1,
        phoneNumber: billingAddress.phoneNumber,
        faxNumber: billingAddress.faxNumber,
        googleMapLink: billingAddress.googleMapLink,
      }

      setValue(name as any, shippingAddressValue)
    }
    setSelectedRefCustomerAddress(id)
  }

  if (!!supportOversea && formType === CustomerFormType.OtherCountry) {
    return (
      <Box p={2} clone>
        <Paper variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ButtonRadios
                label="ประเทศ *"
                defaultValue={CustomerFormType.Local}
                value={formType}
                onValueChange={(value) => setFormType(Number(value))}
                items={[
                  { id: 1, value: CustomerFormType.Local, name: 'ประเทศไทย' },
                  { id: 2, value: CustomerFormType.OtherCountry, name: 'ต่างประเทศ' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.country`}
                label="ประเทศ *"
                rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.addressLine1`}
                label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
                rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.phoneNumber`}
                label="เบอร์โทร *"
                rules={{ required: 'กรุณากรอกเบอร์โทร' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name={`${name}.faxNumber`} label="เบอร์แฟกซ์ (ถ้ามี)" />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )
  }

  return (
    <Box p={2} clone>
      <Paper variant="outlined">
        <Grid container spacing={2}>
          {!!supportOversea && (
            <Grid item xs={12} md={6}>
              <ButtonRadios
                label="ประเทศ *"
                defaultValue={CustomerFormType.Local}
                value={formType}
                onValueChange={(value) => setFormType(Number(value))}
                items={[
                  { id: 1, value: CustomerFormType.Local, name: 'ประเทศไทย' },
                  { id: 2, value: CustomerFormType.OtherCountry, name: 'ต่างประเทศ' },
                ]}
              />
            </Grid>
          )}
          {!!refCustomerAddresses && refCustomerAddresses.length > 0 && (
            <>
              <Grid item xs={12}>
                <TypographyWithLabel label="เลือกใช้ที่อยู่เดียวกับที่อยู่เปิดบิล" />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} direction="row">
                  {refCustomerAddresses?.map((ba, index) => (
                    <Grid item key={ba.id}>
                      <Button
                        onClick={() => handleOnClickRefCustomerAddress(ba.id)}
                        variant={selectedRefCustomerAddress === ba.id ? `contained` : `outlined`}
                        color="primary"
                      >
                        {ba.name || `ที่อยู่ ${index + 1}`}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TypographyWithLabel label="รายละเอียดที่อยู่ขนส่ง" />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name={`${name}.name`} label="ชื่อเรียกที่อยู่" defaultValue="" />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm control={control} name={`${name}.branchName`} label="สาขา (ถ้ามี)" defaultValue="" />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.postalCode`}
              label="รหัสไปรษณีย์ *"
              rules={{ required: 'กรุณากรอกรหัสไปรษณีย์' }}
              defaultValue=""
            />
          </Grid>
          {provinceId && (
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.provinceId`}
                label="จังหวัด *"
                rules={{ required: 'กรุณาเลือกจังหวัด' }}
                value={provinceId}
                disabled={true}
                defaultValue={undefined}
                select
              >
                {provinces.map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          )}
          {districtId && (
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.districtId`}
                label="เขต / อำเภอ *"
                rules={{ required: 'กรุณาเลือกเขต/อำเภอ' }}
                value={districtId}
                disabled={true}
                defaultValue={undefined}
                select
              >
                {districtOptions.map((d) => (
                  <MenuItem key={d.id} value={d.id}>
                    {d.nameLocal}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          )}
          {subDistrictOptions.length > 0 && (
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name={`${name}.subDistrictId`}
                label="แขวง / ตำบล *"
                rules={{ required: 'กรุณาเลือกแขวง/ตำบล' }}
                value={subDistrictId}
                disabled={subDistrictOptions.length === 0}
                select
              >
                {subDistrictOptions.map((sd) => (
                  <MenuItem key={sd.id} value={sd.id}>
                    {sd.nameLocal} ({districts.find((d) => d.id === sd.districtId)?.nameLocal ?? '-'})
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.addressLine1`}
              label="รายละเอียดที่อยู่ (บ้านเลขที่, เลขห้อง, ชั้น, ตึก, ถนน, ฯลฯ) *"
              rules={{ required: 'กรุณากรอกรายละเอียดที่อยู่' }}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.phoneNumber`}
              label="เบอร์โทร *"
              rules={{ required: 'กรุณากรอกเบอร์โทร' }}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.faxNumber`}
              label="เบอร์แฟกซ์ (ถ้ามี)"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name={`${name}.googleMapLink`}
              label="ลิงก์ Google Map (ถ้ามี)"
              defaultValue=""
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default CustomerAddressForm
