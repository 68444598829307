import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'

interface Props extends DialogProps {
  title: string
  onOk?: () => void
}

const WarningDialog: React.FC<Props> = (props) => {
  const { title, onOk, children, onClose } = props
  return (
    <Dialog {...props}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onOk) {
              onOk()
              return
            }
            onClose && onClose({} as any, 'backdropClick')
          }}
          color="primary"
        >
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WarningDialog
