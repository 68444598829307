import { useState, useEffect } from 'react'
import { fetchSOTransactions, FetchRequest, FetchSOPaymentTransaction } from '../api/smartbestbuys-api'

export const useSOPaymentTransactions = (startDate: Date, endDate: Date): FetchSOPaymentTransaction[] => {
  const [transactions, setTransactions] = useState<FetchSOPaymentTransaction[]>([])

  const _fetchTransactions = async (startDate: Date, endDate: Date) => {
    const request: FetchRequest = {
      startDate,
      endDate,
    }
    const response = await fetchSOTransactions(request)
    setTransactions(response?.data || [])
  }

  useEffect(() => {
    _fetchTransactions(startDate, endDate)
  }, [startDate, endDate])

  return transactions
}
