import React, { FC } from 'react';
import ReactPDF, { View, StyleSheet } from '@react-pdf/renderer';
import { getViewStyles } from './styles';
import { PDFProps } from './props';

interface Props {
  align?: 'flex-start'
}

const PDFTableRow: FC<ReactPDF.ViewProps & PDFProps & Props> = (props) => {
  const { align, ...viewProps } = props;

  const commonStyles = getViewStyles(props);

  const styles = StyleSheet.create({
    row: {
      padding: 12,
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: align || 'space-between'
    }
  })

  const { style, ...otherProps } = viewProps;
  return (
    <View style={{
      ...commonStyles,
      ...styles.row,
      ...style,
    }} {...otherProps} />
  );
}

export default PDFTableRow;