import React, { useState, useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, Link } from '../../../../custom-components'
import { useSalesDebtors } from '../../../../hooks'
import { SOStatusChip, SOPaymentStatusChip, NotificationHead } from '../../../../shared-components'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import { defaultStartDate, defaultEndDate } from '../../../../data'
import { NavigationItemKey, PageName } from '../../../../enums'
import { RootContext } from '../../../..'
import { SaleOrderDebtorRowData } from '../../../../api/smartbestbuys-api'

const SalesDebtorsPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SO_DEBTOR)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const saleOrders = useSalesDebtors(startDate, endDate)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Topic>รายการลูกหนี้</Topic>
      </Grid>
      <Grid item xs={12}>
        <NotificationHead navigationKey={NavigationItemKey.SalesDebtors} />
      </Grid>
      <Grid item xs={12}>
        <FilteredTable<SaleOrderDebtorRowData>
          color="primary.dark"
          columns={[
            {
              name: 'สถานะใบสั่งขาย',
              render: ({ status: { id, name } }) => <SOStatusChip status={id} label={name} />,
              csvRender: ({ status: { name } }) => name,
            },
            {
              name: 'สถานะชำระเงิน',
              render: ({ paymentStatus: { id, name } }) => <SOPaymentStatusChip status={id} label={name} />,
              csvRender: ({ paymentStatus: { name } }) => name,
            },
            {
              name: 'วันที่ต้องจ่ายเงิน',
              dataIndex: 'paymentDueDate',
              fixedWidth: 140,
              render: ({ paymentDueDate }) =>
                paymentDueDate ? (
                  format(new Date(paymentDueDate), 'dd MMM yyyy', { locale: thLocale })
                ) : (
                  <Typography color="textSecondary">ไม่ระบุ</Typography>
                ),
              csvRender: ({ paymentDueDate }) =>
                paymentDueDate ? format(new Date(paymentDueDate), 'dd MMM yyyy', { locale: thLocale }) : 'ไม่ระบุ',
            },
            {
              name: 'เลขที่ใบสั่งขาย',
              dataIndex: 'code',
              render: ({ id, code }) => (
                <Link color="primary" to={`/sale-orders/${id}`}>
                  {code}
                </Link>
              ),
              csvRender: ({ code }) => code,
            },
            {
              name: 'วันที่สร้าง',
              render: ({ recCreatedAt }) => format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
              csvRender: ({ recCreatedAt }) =>
                format(new Date(recCreatedAt), 'dd MMM yyyy HH:mm', { locale: thLocale }),
            },
            {
              name: 'ชื่อลูกค้า',
              render: (saleOrder) => saleOrder.customer.nameLocal,
              csvRender: (saleOrder) => saleOrder.customer.nameLocal,
            },
            {
              name: 'เบอร์โทรศัพท์',
              render: (saleOrder) => saleOrder.customer.phoneNumber,
              csvRender: (saleOrder) => saleOrder.customer.phoneNumber,
            },
            {
              name: 'วิธีชำระเงิน',
              render: (saleOrder) => saleOrder.paymentMethod.name,
              csvRender: (saleOrder) => saleOrder.paymentMethod.name,
            },
            {
              name: 'ยอดค้างชำระ',
              dataIndex: 'remainingTotal',
              numeric: true,
              format: '0,0.00',
            },
          ]}
          data={saleOrders}
          defaultDateRage={{
            startDate: defaultStartDate,
            endDate: defaultEndDate,
          }}
          onDateChange={(startDate, endDate) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          size="small"
          csvFilename={`รายการลูกหนี้_${format(new Date(), 'dd_MMM_yyyy', { locale: thLocale })}.csv`}
          downloadAsCsv
        />
      </Grid>
    </Grid>
  )
}

export default withLayout(SalesDebtorsPage, true)
