import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { LocalPhone } from '@material-ui/icons'

interface Props {
  icon?: boolean
}

const useStyles = () =>
  makeStyles((theme) => {
    return createStyles({
      chip: {
        backgroundColor: theme.palette.error.light,
      },
      icon: {
        color: theme.palette.error.darkText,
      },
    })
  })

const SONeedToFollowChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({ children, icon, ...othersProps }) => {
  const classes = useStyles()()

  return (
    <Chip
      icon={<LocalPhone />}
      classes={{
        icon: classes.icon,
      }}
      className={classes.chip}
      {...othersProps}
      label={'ไม่ได้ติดตามนานเกิน 7 วัน'}
    />
  )
}

export default SONeedToFollowChip
