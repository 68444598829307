import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { ReceiveProductTable, ReceiveProductTableData } from '..'
import { ICustomerRequisitionProfile_Task } from '../../../../api/smartbestbuys-api'
import { FlexBox, TypographyWithLabel } from '../../../../custom-components'
import { TaskStatus, TaskType } from '../../../../enums'
import { dateFormat } from '../../../../functions'
import { TaskStatusChip } from '../../../../shared-components'
import { useStyles } from '../styles'

interface TransportPickupTaskProps {
  isExpanded: boolean
  handleExpand: () => void
  task: ICustomerRequisitionProfile_Task
  childrenTasks: ICustomerRequisitionProfile_Task[]
  productTableData: ReceiveProductTableData[]
}

export const TransportPickupTask: React.FC<TransportPickupTaskProps> = ({
  isExpanded,
  handleExpand,
  task,
  childrenTasks,
  productTableData,
}) => {
  const classes = useStyles()
  const { dueDate, cancelledMessage } = task

  const messengerPickupTask = childrenTasks.find((t) => t.type.id === TaskType.MessengerPickupTask)
  const warehouseReceiveProductTask = childrenTasks.find((t) => t.type.id === TaskType.WarehouseReceiveProductTask)

  const messengerFailureMessage = messengerPickupTask?.state?.failureMessages

  return (
    <Grid item xs={12} key={task.id}>
      <Accordion
        square
        key={task.id.toString()}
        expanded={isExpanded}
        onChange={handleExpand}
        TransitionProps={{ unmountOnExit: true }}
        className={classes.accordion}
      >
        <AccordionSummary expandIcon={<ExpandMore />} classes={{ content: classes.accordionSummary }}>
          <Typography variant="h5">{task.code}</Typography>
          <TaskStatusChip status={task.status.id} label={task.status.name} />
          {!!cancelledMessage && (
            <TypographyWithLabel label="เหตุผลที่ยกเลิก:" variant="body2" color="primary">
              {cancelledMessage}
            </TypographyWithLabel>
          )}
          {task.status.id !== TaskStatus.Canceled && !!dueDate && (
            <TypographyWithLabel label="วันที่รับ:" variant="body2">
              {dateFormat(dueDate)}
            </TypographyWithLabel>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {childrenTasks.length > 0 && (
              <Grid item xs={12}>
                <FlexBox alignItems="center" gridGap={16}>
                  {!!messengerPickupTask && (
                    <TypographyWithLabel label="งานรับของ:" alignItems="center">
                      <TaskStatusChip status={messengerPickupTask.status.id} label={messengerPickupTask.status.name} />
                    </TypographyWithLabel>
                  )}
                  {!!messengerFailureMessage?.canceled && (
                    <TypographyWithLabel label="เหตุผลที่ยกเลิก:" variant="body2" color="primary">
                      {messengerFailureMessage.canceled}
                    </TypographyWithLabel>
                  )}
                  {!!messengerFailureMessage?.failed && (
                    <TypographyWithLabel label="เหตุผลที่รับไม่สำเร็จ:" variant="body2" color="primary">
                      {messengerFailureMessage.failed}
                    </TypographyWithLabel>
                  )}
                  {!!messengerFailureMessage?.remark && (
                    <TypographyWithLabel label="หมายเหตุ:" variant="body2" color="primary">
                      {messengerFailureMessage.remark}
                    </TypographyWithLabel>
                  )}
                  {!!warehouseReceiveProductTask && (
                    <TypographyWithLabel label="งานรับเข้าสต๊อก:" alignItems="center">
                      <TaskStatusChip
                        status={warehouseReceiveProductTask.status.id}
                        label={warehouseReceiveProductTask.status.name}
                      />
                    </TypographyWithLabel>
                  )}
                </FlexBox>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle2">รายการขนส่ง</Typography>
              <ReceiveProductTable data={productTableData} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
