import { VendorCreateRequest, VendorCreateRequest_Attachment } from '../../../api/smartbestbuys-api'
import { VendorCreateFormValues, VendorCreateFormValues_Attachment } from './VendorCreatePage'

export const mapVendorCreateFromValuesToRequest = (value: VendorCreateFormValues): VendorCreateRequest => {
  return {
    name: value.vendorName,
    phoneNumber: value.phoneNumber,
    referenceNumber: value.referenceNumber,
    websiteUrl: value.websiteUrl,
    base64: value.base64,
    email: value.email,
    currencyId: value.currencyId,
    creditPeriodDay: value.creditPeriodDay,
    scopeWork: value.scopeWork,
    remark: value.remark,
    refPaymentMethodId: value.refPaymentMethodId,
    isVatVendor: value.isVatVendor,
    address: {
      addressLine1: value.address.addressLine1,
      provinceId: value.address.provinceId,
      districtId: value.address.districtId,
      subDistrictId: value.address.subDistrictId,
      phoneNumber: value.address.phoneNumber,
      faxNumber: value.address.faxNumber,
      postalCode: value.address.postalCode,
      country: value.address.country,
    },
    contact: {
      name: value.contact.name,
      phoneNumber: value.contact.phoneNumber,
    },
    attachments: value.attachments?.map(mapAttachmentFormValuesToAttachment),
    bankAccount:
      !!value.enabledBankAccount && !!value.bankAccount
        ? {
            bankId: value.bankAccount.bankId,
            accountName: value.bankAccount.accountName,
            accountNumber: value.bankAccount.accountNumber,
            branchName: value.bankAccount.branchName,
          }
        : undefined,
  }
}

export const mapAttachmentFormValuesToAttachment = (
  attachment: VendorCreateFormValues_Attachment,
): VendorCreateRequest_Attachment => {
  return {
    base64: attachment.base64,
  }
}
