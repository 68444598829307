import { useEffect, useState } from 'react'
import { ITableData, IUseTableData } from '../types/pagination'
import { AxiosResponse } from 'axios'
import { APIResponse, TableDataQueryParams } from '../api/smartbestbuys-api'

// D = datatype
// T = summary info
// S = search object
// Q = search params

export const useTableData = <D, T, S = {}, Q = {}>(
  pageIndex_: number,
  rowPerPage_: number,
  search_: S,
  cb: (q: TableDataQueryParams & Q) => Promise<AxiosResponse<ITableData<D, S, T>> | null>,
  parser: (s: S) => Q,
): IUseTableData<D, S, T> | undefined => {
  const [pageIndex, setPageIndex] = useState(pageIndex_)
  const [rowPerPage, setRowPerPage] = useState(rowPerPage_)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [pendingSearch, setPendingSearch] = useState<S>(search_)
  const [search, setSearch] = useState<S>(search_)
  const [tableData, setTableData] = useState<ITableData<D, S, T> | undefined>(undefined)

  // first load
  useEffect(() => {
    ;(async () => {
      _loadData(pageIndex_, rowPerPage_, search_)
    })()
    // eslint-disable-next-line
  }, [])

  const handlePageChange = (_pageIndex: number) => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(_pageIndex, rowPerPage, search)
      setPageIndex(_pageIndex)
    }
  }

  const handleRowPerPageChange = (_rowPerPage: number) => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(0, _rowPerPage, search)
      setRowPerPage(_rowPerPage)
    }
  }

  const handleSearchParamChange = <K extends keyof S>(_key: K, _value: S[K], _triggerSearch?: boolean) => {
    const newPendingSearch = {
      ...pendingSearch,
      [_key]: _value,
    }

    setPendingSearch(newPendingSearch)

    if (!!_triggerSearch) {
      setIsLoading(true)
      _loadData(0, rowPerPage, newPendingSearch)
      setSearch(newPendingSearch)
    }
  }

  const handleSearchParamsChange = (_value: S, _triggerSearch?: boolean) => {
    const newPendingSearch = {
      ...pendingSearch,
      ..._value,
    }

    setPendingSearch(newPendingSearch)

    if (!!_triggerSearch) {
      setIsLoading(true)
      _loadData(0, rowPerPage, newPendingSearch)
      setSearch(newPendingSearch)
    }
  }

  const handleSearch = () => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(0, rowPerPage, pendingSearch)
      setSearch(pendingSearch)
    }
  }

  const refetch = () => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(pageIndex, rowPerPage, search)
    }
  }

  const _preLoad = () => {
    if (isLoading) return false
    return true
  }

  const _loadData = async (_pageIndex: number, _rowPerPage: number, _search: S) => {
    const queryParams = {
      pageIndex: _pageIndex.toString(),
      rowPerPage: _rowPerPage.toString(),
      ...parser(_search),
    }
    const response = await cb(queryParams)
    if (response?.data) {
      setTableData(response.data)
    } else {
      setIsError(true)
    }

    setIsLoading(false)
  }

  return tableData
    ? {
        ...tableData,
        search,
        pendingSearch,
        handlePageChange,
        handleRowPerPageChange,
        handleSearch,
        handleSearchParamChange,
        handleSearchParamsChange,
        refetch,
        isLoading,
        isError,
      }
    : undefined
}

export const useTableDataV2 = <D, T, S = {}, Q = {}>(
  pageIndex_: number,
  rowPerPage_: number,
  search_: S,
  cb: (q: TableDataQueryParams & Q) => Promise<AxiosResponse<APIResponse<ITableData<D, S, T>>> | null>,
  parser: (s: S) => Q,
): IUseTableData<D, S, T> | undefined => {
  const [pageIndex, setPageIndex] = useState(pageIndex_)
  const [rowPerPage, setRowPerPage] = useState(rowPerPage_)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [pendingSearch, setPendingSearch] = useState<S>(search_)
  const [search, setSearch] = useState<S>(search_)
  const [tableData, setTableData] = useState<ITableData<D, S, T> | undefined>(undefined)

  // first load
  useEffect(() => {
    ;(async () => {
      _loadData(pageIndex_, rowPerPage_, search_)
    })()
    // eslint-disable-next-line
  }, [])

  const handlePageChange = (_pageIndex: number) => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(_pageIndex, rowPerPage, search)
      setPageIndex(_pageIndex)
    }
  }

  const handleRowPerPageChange = (_rowPerPage: number) => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(0, _rowPerPage, search)
      setRowPerPage(_rowPerPage)
    }
  }

  const handleSearchParamChange = <K extends keyof S>(_key: K, _value: S[K], _triggerSearch?: boolean) => {
    const newPendingSearch = {
      ...pendingSearch,
      [_key]: _value,
    }

    setPendingSearch(newPendingSearch)

    if (!!_triggerSearch) {
      setIsLoading(true)
      _loadData(0, rowPerPage, newPendingSearch)
      setSearch(newPendingSearch)
    }
  }

  const handleSearchParamsChange = (_value: S, _triggerSearch?: boolean) => {
    const newPendingSearch = {
      ...pendingSearch,
      ..._value,
    }

    setPendingSearch(newPendingSearch)

    if (!!_triggerSearch) {
      setIsLoading(true)
      _loadData(0, rowPerPage, newPendingSearch)
      setSearch(newPendingSearch)
    }
  }

  const handleSearch = () => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(0, rowPerPage, pendingSearch)
      setSearch(pendingSearch)
    }
  }

  const refetch = () => {
    if (_preLoad()) {
      setIsLoading(true)
      _loadData(pageIndex, rowPerPage, search)
    }
  }

  const _preLoad = () => {
    if (isLoading) return false
    return true
  }

  const _loadData = async (_pageIndex: number, _rowPerPage: number, _search: S) => {
    const queryParams = {
      pageIndex: _pageIndex.toString(),
      rowPerPage: _rowPerPage.toString(),
      ...parser(_search),
    }
    const response = await cb(queryParams)

    if (response?.data?.data) {
      setTableData(response.data.data)
    } else {
      setIsError(true)
    }

    setIsLoading(false)
  }

  return tableData
    ? {
        ...tableData,
        search,
        pendingSearch,
        handlePageChange,
        handleRowPerPageChange,
        handleSearch,
        handleSearchParamChange,
        handleSearchParamsChange,
        refetch,
        isLoading,
        isError,
      }
    : undefined
}
