import React from 'react'
import { Grid as MUIGrid, GridProps } from '@material-ui/core'
import { useStyles } from './styles'

const Grid: React.FC<GridProps> = (props) => {
  const { item } = props
  const styles = useStyles()
  return <MUIGrid classes={{ container: !item ? styles.container : '' }} {...props} />
}

export default Grid
