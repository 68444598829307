import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { RootContext } from '../../../../../..'
import { Form } from '../../../../../../custom-components'
import { useProductCreation } from '../../../../../../hooks/useProductCreation'
import { SubmitForm } from '../../../../../../shared-components'
import { ProductInformationInput } from '../../../../create/ProductCreationForm'
import ProductProfileContext from '../../ProductProfileContext'
import ProductInformationFormValue from './ProductUpdateFormValues'
import { requestToUpdateProductInformation } from './service'
import { calculateProductUnitSizeId, calculateProductUnitWeightId } from '../../../../../../functions'

const ProductInformationDialog: React.FC<DialogProps> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { productProfile, reload } = useContext(ProductProfileContext)
  const { widthInMm, heightInMm, depthInMm, weightInGram } = productProfile

  const calculateSizeId = calculateProductUnitSizeId(
    Math.max((widthInMm as number) || 0, (heightInMm as number) || 0, (depthInMm as number) || 0),
  )

  const calculateWeightId = calculateProductUnitWeightId((weightInGram as number) || 0)

  const formMethod = useForm<ProductInformationFormValue>({
    defaultValues: {
      id: productProfile.id,
      headerId: productProfile.headerId,
      typeOfProduct: productProfile.type.id,
      businessId: String(productProfile.businessUnit?.id),
      categoryProductId: String(productProfile.category?.id),
      subCategoryProductId: String(productProfile.subCategory?.id),
      productNameTh: productProfile.nameLocal,
      productNameEn: productProfile?.nameEn,
      productUnitTh: productProfile.unitLocal,
      productUnitEn: productProfile?.unitEn,
      productWarranty: productProfile.warrantyPeriodDay,
      storageProductId: productProfile.storage?.id,
      customCodeProduct: productProfile?.code,
      imageUrl: productProfile?.imageUrl,
      leadTime: productProfile?.leadTime,
      safetyStockPolicy: productProfile?.safetyStockPolicy,
      dealerPrice: productProfile?.dealerPrice,
      brand: productProfile?.brand,
      color: productProfile?.color,
      material: productProfile?.material,
      widthInMm: (productProfile?.widthInMm || 0) / calculateSizeId,
      heightInMm: (productProfile?.heightInMm || 0) / calculateSizeId,
      depthInMm: (productProfile?.depthInMm || 0) / calculateSizeId,
      weightInGram: (productProfile?.weightInGram || 0) / calculateWeightId,
      warrantyCondition: productProfile?.warrantyCondition,
      karajicMatrixId: productProfile?.karajicMatrix?.id,
      unitSizeId: calculateSizeId,
      unitWeightId: calculateWeightId,
    },
  })

  const {
    formState: { isSubmitting },
  } = formMethod

  const { onClose, ...otherProps } = props
  const productCreationInfo = useProductCreation()
  if (!productCreationInfo) return null
  const { businessUnits, productStorages } = productCreationInfo

  const handleSubmitForm = async (values: ProductInformationFormValue) => {
    const response = await requestToUpdateProductInformation(values)
    if (response?.status === 200) {
      triggerSnackbar('บันทึกการขอแก้ไขข้อมูลสินค้าสำเร็จ')
      onClose && onClose({}, 'escapeKeyDown')
      reload()
    } else {
      triggerSnackbar('บันทึกการขอแก้ไขข้อมูลสินค้าไม่สำเร็จ')
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <FormProvider {...formMethod}>
        <Form onSubmit={handleSubmitForm}>
          <DialogTitle>แก้ไขข้อมูลสินค้า</DialogTitle>
          <DialogContent>
            <ProductInformationInput businessUnits={businessUnits} productStorages={productStorages} edit />
          </DialogContent>
          <DialogActions>
            <Box p={2}>
              <SubmitForm
                submitText="บันทึกการแก้ไข"
                cancelText="ยกเลิก"
                onCancel={() => onClose && onClose({}, 'backdropClick')}
                isSubmitting={isSubmitting}
              />
            </Box>
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

export default ProductInformationDialog
