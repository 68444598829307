import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'

import { KeyboardDatePicker } from '@material-ui/pickers'
import { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'

import { FlexBox, Table, Topic, withLayout } from '../../../custom-components'
import { defaultToday } from '../../../data'
import { NavigationItemKey, PageName } from '../../../enums'
import { NotificationHead, SearchBar, Space } from '../../../shared-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Empty from '../../../custom-components/Empty/Empty'
import { calculateTotal, IShippingCarSummaryData, useTransportShippingDailySummary } from '../../../hooks'
import { formatNumber, formatNumberInt } from '../../../functions/number-utils'
import './TransportShippingDailySummaryPage.less'
import { TransportTaskCard } from './TransportTaskCard'
import Filter, { FilterValues } from '../../../shared-components/Filter/Filter'
import { shippingCarOptions, transportTaskStatuses, isShippingProviderTypes } from '../../../constants'
import { getShippingCar } from '../../../utils/task'

interface TransportShippingDialyFilterValues extends FilterValues {
  carIds: number[]
  transportStatusIds: number[]
  shippingProviderTypeIds: number[]
}

const TransportTaskListPage = () => {
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.MESSENGER_TASK_DAILY_SUMMARY)

  // state
  const [date, setDate] = useState(defaultToday)

  const [summaryData, forceReload] = useTransportShippingDailySummary(date, '3001')

  const { tasks, shippingCarSummaryData } = summaryData

  const [filterValues, setFilterValues] = useState<TransportShippingDialyFilterValues>()

  const [query, setQuery] = useState<string>('')

  const netAmount = calculateTotal(shippingCarSummaryData)

  const filteredTasks = useMemo(
    () => {
      const filteredByQuery = tasks.filter((t) => (query.length !== 0 ? t.saleOrderInfo?.code.includes(query) : true))

      if (!filterValues) return filteredByQuery

      const filteredByCarId = filteredByQuery.filter((t) => {
        if (filterValues.carIds.length === 0) return true

        const carId = getShippingCar(t).id
        return filterValues.carIds.includes(carId)
      })

      const filteredByTransportStatusIds = filteredByCarId.filter((t) => {
        if (filterValues.transportStatusIds.length === 0) return true
        return filterValues.transportStatusIds.includes(t.status.id)
      })

      const filteredByTransportTypeIds = filteredByTransportStatusIds.filter((t) => {
        const useShippingProvider = t.state?.shippingInfo.useShippingProvider ?? false
        if (filterValues.shippingProviderTypeIds.length === 0) return true
        return filterValues.shippingProviderTypeIds.includes(useShippingProvider ? 1 : 0)
      })

      return filteredByTransportTypeIds
    }, // eslint-disable-next-line
    [tasks.map((t) => t.id).join(','), query, JSON.stringify(filterValues)],
  )

  return (
    <Box p={2}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Topic>การขนส่งรายวัน</Topic>
        <NotificationHead navigationKey={NavigationItemKey.TransportShippingSummary} />
        <KeyboardDatePicker
          size="small"
          label="วันที่"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          value={date}
          onChange={(date) => date && setDate(date)}
        />
      </FlexBox>
      <Space />

      <Box bgcolor="white">
        <Table<IShippingCarSummaryData>
          columns={[
            {
              name: 'ทะเบียนรถ',
              dataIndex: 'name',
              sort: true,
              render: (task) => <Typography variant="body2">{task.name}</Typography>,
            },
            { name: 'จำนวนงานโดย Smart', dataIndex: 'smartTaskAmount' },
            { name: 'จำนวนงานโดยขนส่งอื่นๆ', dataIndex: 'shippingProviderAmount' },
            { name: 'จำนวนงานที่ไม่สำเร็จ', dataIndex: 'failedAmount' },
            { name: 'จำนวนงานที่ยกเลิก', dataIndex: 'canceledTaskAmount' },
            { name: 'จำนวนงานทั้งหมด', dataIndex: 'totalTaskAmount' },
            {
              name: 'ยอดรับจริงรวม',
              dataIndex: 'actualChargeAmount',
              align: 'right',
              render: ({ actualChargeAmount, expectedChargeAmount }) => (
                <Typography variant="body2" color={actualChargeAmount < expectedChargeAmount ? 'error' : 'textPrimary'}>
                  {formatNumber(actualChargeAmount)}
                </Typography>
              ),
            },
            { name: 'ยอดที่ต้องรับชำระ', dataIndex: 'expectedChargeAmount', numeric: true, format: '0,0.00' },
          ]}
          postCells={
            <TableRow className="summary-row">
              <TableCell>รวม</TableCell>
              <TableCell>{formatNumberInt(netAmount.smartTaskAmount)}</TableCell>
              <TableCell>{formatNumberInt(netAmount.shippingProviderAmount)}</TableCell>
              <TableCell>{formatNumberInt(netAmount.failedAmount)}</TableCell>
              <TableCell>{formatNumberInt(netAmount.canceledTaskAmount)}</TableCell>
              <TableCell>{formatNumberInt(netAmount.totalTaskAmount)}</TableCell>
              <TableCell align="right">
                <Typography
                  variant="body2"
                  className="actual-charge-amount"
                  color={netAmount.actualChargeAmount < netAmount.expectedChargeAmount ? 'error' : 'textPrimary'}
                >
                  {formatNumber(netAmount.actualChargeAmount)}
                </Typography>
              </TableCell>
              <TableCell align="right">{formatNumber(netAmount.expectedChargeAmount)}</TableCell>
            </TableRow>
          }
          data={shippingCarSummaryData}
          sortable
          color="primary.dark"
          size="small"
          cleanSpace
          pagination={false}
        />
      </Box>
      <Space />
      <Divider />
      <Space />

      <FlexBox gridGap={15}>
        <Grid item xs={12} sm={9} md={4}>
          <SearchBar onChange={setQuery} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Filter<TransportShippingDialyFilterValues>
            options={[
              {
                label: 'ทะเบียนรถ',
                key: 'carIds',
                values: shippingCarOptions.map((car) => {
                  return {
                    label: car.name,
                    value: car.id,
                  }
                }),
              },
              {
                label: 'สถานะงานจัดส่ง',
                key: 'transportStatusIds',
                values: transportTaskStatuses.map((status) => {
                  return {
                    label: status.name,
                    value: status.id,
                  }
                }),
              },
              {
                label: 'วิธีการขนส่ง',
                key: 'shippingProviderTypeIds',
                values: isShippingProviderTypes.map((type) => ({
                  label: type.name,
                  value: type.id,
                })),
              },
            ]}
            onValueChange={(values) => setFilterValues(values)}
          />
        </Grid>
      </FlexBox>

      <Space />
      {shippingCarSummaryData.length > 0 ? (
        shippingCarSummaryData
          .filter((car) => filteredTasks.some((t) => getShippingCar(t).id === car.id))
          .map((car) => {
            const carTasks = filteredTasks.filter((task) => getShippingCar(task).id === car.id)
            return (
              <Box p={2} clone>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h5">
                      {car.name} ({carTasks.length} งาน)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mb-3" />
                    <Grid container spacing={4}>
                      {carTasks.map((task) => (
                        <Grid key={task.code} item xs={6}>
                          <TransportTaskCard task={task} onUpdate={forceReload} />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )
          })
      ) : (
        <Empty emptyText="ไม่มีการขนส่งสำหรับวันนี้" />
      )}
    </Box>
  )
}

export default withLayout(TransportTaskListPage)
