import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Typography, TypographyProps } from '@material-ui/core'

interface Props extends TypographyProps {
  icon?: JSX.Element
  label: string
  labelWidth?: number
  valueWidth?: number | string
  labelProps?: TypographyProps
  alignItems?: 'center'
  justifyContent?: 'space-between'
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    labelWithIcon: {
      paddingLeft: theme.spacing(1),
    },
  }),
)

// TODO: check color and font size
const TypographyWithLabel: React.FC<Props> = ({
  icon,
  label,
  labelWidth,
  children,
  valueWidth,
  labelProps,
  alignItems,
  justifyContent,
  ...typographyProps
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root} style={{ alignItems, justifyContent }}>
      {icon}
      <Typography
        style={{ width: labelWidth }}
        className={icon && classes.labelWithIcon}
        variant="subtitle2"
        {...labelProps}
      >
        {label}
      </Typography>
      <Typography
        style={{ width: valueWidth }}
        className="pl-2"
        variant="body2"
        color="textSecondary"
        {...typographyProps}
      >
        {children}
      </Typography>
    </div>
  )
}

export default TypographyWithLabel
