import React, { useState, createContext, useContext } from 'react'
import { Grid, Box, Popover, MenuItem, Button, Typography, Card, Paper } from '@material-ui/core'
import {
  BreadcumbsList,
  FlexBox,
  SubTopic,
  Title,
  Topic,
  TypographyWithLabel,
  withLayout,
} from '../../../custom-components'
import {
  ComparedTypography,
  ConditionTooltip,
  ImageCard,
  Loading,
  MessengerIcon,
  MultipleConditionTooltip,
  PrepareDocumentIcon,
  PrepareProductIcon,
  ReturnDocumentIcon,
  ReturnProductIcon,
  Space,
  TaskStatusChip,
} from '../../../shared-components'
import { PageName, TaskStatus, TaskType } from '../../../enums'
import { RootContext } from '../../..'
import { useTransportTaskProfile } from '../../../hooks/transport'
import { TransportTaskProfile } from '../../../api/smartbestbuys-api'
import SaleOrderReferenceBox from './SaleOrderReferenceBox'
import AssigneeUserBox from './AssigneeUserBox'
import TransportTaskDetail from './TransportTaskDetail'
import TransportTaskProfileProductsTable from './TransportTaskProfileProductsTable'
import TransportTaskProfileDocumentsTable from './TransportTaskProfileDocumentsTable'
import TransportTaskProfileUserActivitiesTable from './TransportTaskProfileUserActivitiesTable'
import { useHistory } from 'react-router-dom'
import { MoreHoriz, Print } from '@material-ui/icons'
import TransportTaskProfileCancelDialog from './TransportTaskProfileCancelDialog'
import TransportTaskProfileAssignOtherTeamDialog from './TransportTaskProfileSendWorkToOtherTeamDialog'
import ConfirmShippingResultDialog from './ConfirmShippingResultsDialog'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'
import { formatNumber } from '../../../functions/number-utils'
import CustomerRequisitionReferenceBox from './CustomerRequisitionReferenceBox'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { getUserPermissionInfo } from '../../../utils/permission'

interface ITransportTaskProfilePageContext {
  transportTaskProfile: TransportTaskProfile
  forceReload: () => void
}

export const TransportTaskProfilePageContext = createContext<ITransportTaskProfilePageContext>({
  transportTaskProfile: {} as TransportTaskProfile,
  forceReload: () => {},
})

interface Props {
  id: number
}

const TransportTaskProfilePage: React.FC<Props> = (props) => {
  // set up props & state & context
  const { id } = props
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.TRANSPORT)
  const [reload, setReload] = useState(false)

  // call hooks
  const profile = useTransportTaskProfile(id, reload)
  const forceReload = () => {
    setReload((prev) => !prev)
  }

  if (!profile) return <Loading />

  // calculation
  const breadcumbsItems = [{ url: '/transport-tasks', name: 'รายการบริการงานขนส่ง' }, { name: profile?.code ?? '...' }]
  const { saleOrderInfo, customerRequisitionInfo, relatedTasks, state } = profile

  const shippingTaskState = relatedTasks?.find((rt) => rt.type.id === TaskType.MessengerShippingTask)?.state
  const failureMessages = shippingTaskState?.failureMessages
  const shippingResult = shippingTaskState?.shippingResult
  const transportShippingResult = profile.state?.shippingResultV2
  const products = profile.state?.items.filter((row) => row.type === TransportTaskItemType.Product) ?? []
  const documents = profile.state?.items.filter((row) => row.type === TransportTaskItemType.Document) ?? []

  // render
  return (
    <TransportTaskProfilePageContext.Provider
      value={{
        transportTaskProfile: profile,
        forceReload,
      }}
    >
      <Box p={3} clone>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BreadcumbsList items={breadcumbsItems} />
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <FlexBox alignItems="center">
              <Topic>งานขนส่ง {profile.code}</Topic>
            </FlexBox>
            <TransportTaskProfileMenues />
          </Grid>
          <Grid item xs={12} container justify="space-between" alignItems="center">
            <TransportTaskProfileStatusGroup />
            <TransportTaskProfileActionGroup />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {profile.relatedTasks?.map((t) => {
                const icon = () => {
                  switch (t.type.id) {
                    case TaskType.WarehousePrepareProduct:
                      return <PrepareProductIcon status={t.status.id} />
                    case TaskType.FinancePrepareDocument:
                      return <PrepareDocumentIcon status={t.status.id} />
                    case TaskType.MessengerShippingTask:
                      return <MessengerIcon status={t.status.id} />
                    case TaskType.WarehouseReclaimProduct:
                      return <ReturnProductIcon status={t.status.id} />
                    case TaskType.FinanceReturnDocument:
                      return <ReturnDocumentIcon status={t.status.id} />
                  }
                }
                return (
                  <Grid item xs={3}>
                    <Card>
                      <FlexBox justifyContent="space-around" alignItems="center">
                        <FlexBox flexDirection="column">
                          <Title>{t.type.name}</Title>
                          <Space />
                          <Typography color="primary">{t.code}</Typography>
                        </FlexBox>
                        {icon()}
                      </FlexBox>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
            <Space />
            {profile.status.id === TaskStatus.Done && (
              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SubTopic>รายละเอียดการจบงาน</SubTopic>
                    </Grid>
                    <Grid item xs={6}>
                      <TypographyWithLabel label="ผลการขนส่ง:" color={failureMessages ? 'error' : 'textSecondary'}>
                        {failureMessages ? 'ขนส่งไม่สำเร็จ' : 'ขนส่งสำเร็จ'}
                      </TypographyWithLabel>
                    </Grid>
                    {state?.shippingInfo.useShippingProvider && (
                      <Grid item xs={6}>
                        <TypographyWithLabel label="ขนส่งที่มาจากจัดส่ง:">
                          {state?.shippingInfo.shippingProvider?.name ?? 'ไม่ระบุ'}
                        </TypographyWithLabel>
                      </Grid>
                    )}
                    {!failureMessages && shippingResult && (
                      <>
                        {state?.shippingInfo.useShippingProvider && shippingResult.actualShippingProvider && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ขนส่งที่ใช้ส่งจริง:">
                              <ComparedTypography
                                actual={shippingResult.actualShippingProvider.name}
                                expected={transportShippingResult?.actualShippingProvider?.name}
                              />
                            </TypographyWithLabel>
                          </Grid>
                        )}
                        {shippingResult.trackingNumber && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="หมายเลขพัสดุ:">
                              <ComparedTypography
                                actual={shippingResult.trackingNumber}
                                expected={transportShippingResult?.trackingNumber}
                              />
                            </TypographyWithLabel>
                          </Grid>
                        )}
                        {shippingResult.payAmount && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ค่าขนส่ง:">
                              <ComparedTypography
                                actual={formatNumber(shippingResult.payAmount)}
                                expected={
                                  transportShippingResult?.payAmount
                                    ? formatNumber(transportShippingResult?.payAmount)
                                    : undefined
                                }
                              />
                            </TypographyWithLabel>
                          </Grid>
                        )}
                        {state?.shippingInfo.chargeAmount && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="ยอดเงินที่ต้องเก็บ:">
                              {formatNumber(state.shippingInfo.chargeAmount)}
                            </TypographyWithLabel>
                          </Grid>
                        )}
                        {shippingResult.chargeAmount && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="จำนวนเงินที่เก็บจากลูกค้า:">
                              <ComparedTypography
                                actual={formatNumber(shippingResult.chargeAmount)}
                                expected={
                                  transportShippingResult?.chargeAmount
                                    ? formatNumber(transportShippingResult.chargeAmount)
                                    : undefined
                                }
                              />
                            </TypographyWithLabel>
                          </Grid>
                        )}
                        {shippingResult.paymentMethod && (
                          <Grid item xs={6}>
                            <TypographyWithLabel label="วิธีชำระ:">
                              <ComparedTypography
                                actual={shippingResult.paymentMethod.name}
                                expected={transportShippingResult?.paymentMethod?.name}
                              />
                            </TypographyWithLabel>
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <TypographyWithLabel label="หมายเหตุ (คนส่ง):">
                            {shippingResult.remark ?? '-'}
                          </TypographyWithLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="หมายเหตุจบงาน:">
                            {transportShippingResult?.remark ?? '-'}
                          </TypographyWithLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TypographyWithLabel label="รูปหลักฐาน:"></TypographyWithLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <FlexBox gridGap={8}>
                            {(transportShippingResult ?? shippingResult).attachedImages?.map((image, index) => (
                              <ImageCard key={index} name={`หลักฐาน ${index + 1}`} src={image} />
                            ))}
                          </FlexBox>
                        </Grid>
                      </>
                    )}
                    {failureMessages && (
                      <>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="สาเหตุของการส่งของไม่สำเร็จ:">
                            {failureMessages.failed}
                          </TypographyWithLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="วันที่ต้องจัดส่งใหม่:">
                            {shippingTaskState?.nextShippingDate ?? 'ไม่มีการนัดหมาย'}
                          </TypographyWithLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <TypographyWithLabel label="หมายเหตุ:">{failureMessages.remark ?? '-'}</TypographyWithLabel>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          {saleOrderInfo?.id && (
            <Grid item xs={12}>
              <SaleOrderReferenceBox />
            </Grid>
          )}
          {customerRequisitionInfo?.id && (
            <Grid item xs={12}>
              <CustomerRequisitionReferenceBox />
            </Grid>
          )}
          <Grid item xs={12}>
            <AssigneeUserBox />
          </Grid>
          <Grid item xs={12}>
            <TransportTaskDetail />
          </Grid>
          {products.length > 0 && (
            <Grid item xs={12}>
              <TransportTaskProfileProductsTable />
            </Grid>
          )}
          {documents.length > 0 && (
            <Grid item xs={12}>
              <TransportTaskProfileDocumentsTable />
            </Grid>
          )}
          <Grid item xs={12}>
            <TransportTaskProfileUserActivitiesTable />
          </Grid>
        </Grid>
      </Box>
    </TransportTaskProfilePageContext.Provider>
  )
}

export default withLayout(TransportTaskProfilePage)

const TransportTaskProfileStatusGroup: React.FC = () => {
  // set up props & state
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const { status } = transportTaskProfile
  return (
    <FlexBox alignItems="center">
      <TaskStatusChip size="small" status={status.id} label={status.name} />
    </FlexBox>
  )
}

const TransportTaskProfileActionGroup: React.FC = () => {
  // set up props & state
  const { transportTaskProfile, forceReload } = useContext(TransportTaskProfilePageContext)
  const { triggerSnackbar } = useContext(RootContext)
  const history = useHistory()
  const { status, dueDate, id } = transportTaskProfile
  const [confirmDataDialogOpen, setConfirmDataDialogOpen] = useState(false)
  const [confirmResultOpen, setConfirmResultOpen] = useState(false)

  const showedAssignOtherTeamButton = status.id === TaskStatus.TransportPending
  const showedCompleteTaskButton = status.id === TaskStatus.PendingApproval

  const shippingTask = transportTaskProfile.relatedTasks?.find((t) => t.type.id === TaskType.MessengerShippingTask)
  const shippingTaskState = shippingTask?.state

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)

  // condition items
  const confirmWithCustomerConditions = [
    { showTooltip: !writePermission.hasPermission, message: writePermission.alertMessage },
    { showTooltip: !dueDate, message: 'ยังไม่กำหนดวันส่ง' },
  ]

  return (
    <FlexBox alignItems="center">
      {!!shippingTask && !!shippingTaskState && (
        <ConfirmShippingResultDialog
          open={confirmResultOpen}
          readOnly
          data={{
            id: transportTaskProfile.id,
            shippingInfo: transportTaskProfile.state?.shippingInfo!,
            shippingTaskStatus: shippingTask.status,
            shippingTaskState: shippingTaskState,
          }}
          onSuccess={() => {
            forceReload()
            setConfirmResultOpen(false)
          }}
          onClose={() => setConfirmResultOpen(false)}
        />
      )}
      <TransportTaskProfileAssignOtherTeamDialog
        taskId={id}
        open={confirmDataDialogOpen}
        onClose={() => setConfirmDataDialogOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ส่งงานให้ฝ่ายที่เกี่ยวข้องสำเร็จ`)
          forceReload()
          setConfirmDataDialogOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ส่งงานให้ฝ่ายที่เกี่ยวข้องไม่สำเร็จ`)
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => history.push(`/transport-tasks/${id}/shipping-cover-pdf`)}
      >
        <Print />
        <span className="ml-1">พิมพ์ใบปะหน้า</span>
      </Button>
      {showedAssignOtherTeamButton && (
        <>
          <Space direction="horizontal" />
          <Box color="primary.dark" clone>
            <MultipleConditionTooltip conditionItems={confirmWithCustomerConditions}>
              <Button
                classes={{ label: 'px-4 py-1 leading-none' }}
                variant="contained"
                color="primary"
                disabled={confirmWithCustomerConditions.some((cond) => cond.showTooltip)}
                onClick={() => {
                  setConfirmDataDialogOpen(true)
                }}
              >
                ยืนยันข้อมูลกับลูกค้าเรียบร้อย
              </Button>
            </MultipleConditionTooltip>
          </Box>
        </>
      )}
      {showedCompleteTaskButton && (
        <>
          <Space direction="horizontal" />
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <Button
              disabled={!writePermission.hasPermission}
              variant="contained"
              color="primary"
              classes={{ label: 'px-4 py-1 leading-none' }}
              onClick={() => setConfirmResultOpen(true)}
            >
              จบงานจัดส่ง
            </Button>
          </ConditionTooltip>
        </>
      )}
    </FlexBox>
  )
}

const TransportTaskProfileMenues: React.FC = () => {
  // set up props & state & context
  const { triggerSnackbar } = useContext(RootContext)
  const { transportTaskProfile, forceReload } = useContext(TransportTaskProfilePageContext)
  const { id, code, status } = transportTaskProfile
  const history = useHistory()
  const [cancelOpen, setCancelOpen] = useState(false)

  const statusShowedMenu = [TaskStatus.TransportPending, TaskStatus.Processing]

  const statusEdit = [TaskStatus.TransportPending]
  const showedEditMenuItem = statusEdit.includes(status.id)

  const showedCancelMenuItem = statusShowedMenu.includes(status.id)
  const showedMenu = statusShowedMenu.includes(status.id)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const menuOpened = Boolean(anchorEl)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Cancel)

  if (!showedMenu) return <></>

  return (
    <FlexBox>
      <TransportTaskProfileCancelDialog
        taskId={id}
        taskCode={code}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ยกเลิกงานจัดส่งเลขที่ ${code} สำเร็จ`)
          forceReload()
          setCancelOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ยกเลิกงานจัดส่งเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <Button variant="contained" size="large" onClick={handleClick}>
        <MoreHoriz />
      </Button>
      <Popover
        open={menuOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showedEditMenuItem && (
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <MenuItem
              disabled={!writePermission.hasPermission}
              onClick={() => history.push(`/transport-tasks/${id}/update`)}
            >
              แก้ไขงานจัดส่ง
            </MenuItem>
          </ConditionTooltip>
        )}
        {showedCancelMenuItem && (
          <ConditionTooltip showTooltip={!cancelPermission.hasPermission} title={cancelPermission.alertMessage}>
            <MenuItem disabled={!cancelPermission.hasPermission} onClick={() => setCancelOpen(true)}>
              ยกเลิกงานจัดส่ง
            </MenuItem>
          </ConditionTooltip>
        )}
      </Popover>
    </FlexBox>
  )
}
