import { Paper, Typography } from '@material-ui/core'
import { IRefundRequisitionProfile_SaleOrderDocument } from '../../../api/smartbestbuys-api'
import { Link, SubTopic, Table } from '../../../custom-components'
import { dateTimeFormat } from '../../../functions'
import { DocumentStatusChip } from '../../../shared-components'

interface Props {
  saleOrderDocuments: IRefundRequisitionProfile_SaleOrderDocument[]
}

const RefundRequisitionSaleOrderDocumentTable: React.FC<Props> = (props: Props) => {
  // props and state
  const { saleOrderDocuments } = props

  return (
    <Paper>
      <SubTopic>รายการเอกสารจากใบสั่งขาย</SubTopic>
      <div className="mb-4" />
      <Table<IRefundRequisitionProfile_SaleOrderDocument>
        size="small"
        color="primary.dark"
        columns={[
          {
            name: 'สถานะเอกสาร',
            dataIndex: 'status',
            render: ({ status }) => {
              return <DocumentStatusChip status={status.id} label={status.name} />
            },
          },
          {
            name: 'เลขที่เอกสาร',
            render: (record) => (
              //TODO : change Link
              <Link color="primary" to={`/finance/${record.id}/profile`}>
                <Typography variant="body2">{record.code}</Typography>
              </Link>
            ),
          },
          {
            name: 'เลขที่ใบสั่งขายอ้างอิง',
            render: (record) => (
              //TODO : change Link
              <Link color="secondary" to={`/finance/${record.id}/profile`}>
                <Typography variant="body2">{record.saleOrderCode}</Typography>
              </Link>
            ),
          },
          {
            //TODO: change field name
            name: 'ชื่อเอกสาร',
            render: (record) => record.code,
          },
          { name: 'วันที่สร้าง', render: (record) => dateTimeFormat(record.recCreatedAt) },
          {
            name: 'ยอดเอกสาร',
            dataIndex: 'total',
            numeric: true,
            format: '0,0.00',
          },
        ]}
        data={saleOrderDocuments}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default RefundRequisitionSaleOrderDocumentTable
