import React, { useState, useEffect, useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Radio,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { useStyles } from './styles'
import { DocumentStatus, DocumentType, UserAction } from '../../../../../enums'
import { Form, GeneralTextFieldForm } from '../../../../../custom-components'
import { useActionMessages } from '../../../../../hooks'
import { SOFollowUpFormValues } from './types'
import { cancelSaleOrder } from './service'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { Loading } from '../../../../../shared-components'
import { RootContext } from '../../../../..'
import { saleOrderTakeNoteAPI } from '../../../../../api/smartbestbuys-api/sale/sale-apis'

const SOFollowUpDialog: React.FC<DialogProps> = (props) => {
  const { onClose } = props
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)
  const { triggerSnackbar } = useContext(RootContext)
  const [activeIndex, setActionIndex] = useState<number | undefined>(undefined)
  const messages = useActionMessages(UserAction.CancelSaleOrderByCustomer)
  const classes = useStyles()
  const methods = useForm<SOFollowUpFormValues>()

  const {
    control,
    watch,
    unregister,
    formState: { isSubmitting },
  } = methods
  const { actionMessageId } = watch()

  useEffect(() => {
    if (actionMessageId !== -1) {
      unregister('actionMessage')
    }
    if (activeIndex !== 1) {
      unregister('actionMessage')
      unregister('actionMessageId')
    }
  }, [unregister, actionMessageId, activeIndex])

  if (!saleOrderProfile) return <Loading />
  const { documents } = saleOrderProfile
  const validTaxInvoices =
    documents?.filter((doc) => doc.type.id === DocumentType.TaxInvoice && doc.status.id !== DocumentStatus.Cancelled) ??
    []
  const ableToCancel = validTaxInvoices.length === 0

  const options = [
    { userAction: UserAction.SOContactCustomer, name: 'บันทึกการขาย' },
    {
      userAction: UserAction.CancelSaleOrderByCustomer,
      name: 'ลูกค้าขอยกเลิก',
      disabledMessage: 'ไม่สามารถขอยกเลิกได้ เนื่องจากมีการออกใบกำกับภาษีไปแล้ว',
    },
  ]

  const onSubmit = async (values: SOFollowUpFormValues) => {
    if (activeIndex === undefined) {
      triggerSnackbar('กรุณาเลือกหัวข้อ')
      return
    }
    if (activeIndex === 1) {
      if (!ableToCancel) return
      const cancallationResponse = await cancelSaleOrder(saleOrderProfile.id, values)
      if (cancallationResponse?.status === 200) {
        triggerSnackbar(`ยกเลิกใบสั่งขาย ${saleOrderProfile.code}`)
        window.location.reload()
      } else {
        triggerSnackbar(`ไม่สามารถยกเลิกใบสั่งขาย ${saleOrderProfile.code} ได้`)
      }
    } else {
      const response = await saleOrderTakeNoteAPI(saleOrderProfile.id, { remark: values.remark })
      if (response?.status === 201) {
        triggerSnackbar('บันทึกการขายเรียบร้อย')
        window.location.reload()
      } else {
        triggerSnackbar('ไม่สามารถบันทึกการขายได้')
      }
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <DialogTitle>ติดตามการขาย</DialogTitle>
          <DialogContent>
            {options.map((op, index) => {
              const handleExpand = () => setActionIndex(index)

              const isChecked = activeIndex === index
              const disabled = index === 2 && !ableToCancel
              const disabledMessage = disabled ? ` (${op.disabledMessage})` : ''

              return (
                <Accordion
                  key={op.name}
                  expanded={isChecked}
                  onChange={handleExpand}
                  disabled={disabled}
                  TransitionProps={{ unmountOnExit: true }}
                  className={classes.accordion}
                >
                  <AccordionSummary>
                    <FormControlLabel
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={<Radio size="small" checked={isChecked} onClick={handleExpand} />}
                      label={`${op.name}${disabledMessage}`}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {op.userAction === UserAction.CancelSaleOrderByCustomer && (
                        <>
                          <Grid item xs={12} md={6}>
                            <GeneralTextFieldForm
                              control={control}
                              name="actionMessageId"
                              select
                              label="สาเหตุ *"
                              rules={{ required: { value: true, message: 'กรุณาเลือกสาเหตุ' } }}
                            >
                              {messages.map((message) => (
                                <MenuItem key={message.id} value={message.id}>
                                  {message.name}
                                </MenuItem>
                              ))}
                            </GeneralTextFieldForm>
                          </Grid>
                        </>
                      )}
                      {activeIndex === 0 ? (
                        <Grid item xs={12} md={6}>
                          <GeneralTextFieldForm
                            control={control}
                            name="remark"
                            label="รายละเอียด *"
                            rules={{ required: { value: true, message: 'กรุณาใส่รายละเอียด' } }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={6}>
                          <GeneralTextFieldForm control={control} name="remark" label="รายละเอียด (ถ้ามี)" />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => onClose && onClose({}, 'backdropClick')}>
              ยกเลิก
            </Button>
            <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
              ยืนยัน
            </Button>
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  )
}

export default SOFollowUpDialog
