import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core'
import { Build, Close, ShoppingCart } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import { PurchaseRequisitionType } from '../../../../enums'
import { useStyles } from './style'

const PRPurchaseListCreateDialog: React.FC<DialogProps> = (props) => {
  const { onClose, ...otherProps } = props

  const classes = useStyles()

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps} onClose={onClose}>
      <DialogTitle>
        เลือกประเภทคำขอซื้อ
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose && onClose({}, 'backdropClick')}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Link
              to={{
                pathname: `/purchase-requisition/create`,
                state: { type: PurchaseRequisitionType.BuyProductAndServices },
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button fullWidth variant="contained" color="primary" startIcon={<ShoppingCart />}>
                ซื้อสินค้า/บริการ
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link
              to={{ pathname: `/purchase-requisition/create`, state: { type: PurchaseRequisitionType.ClaimProduct } }}
              style={{ textDecoration: 'none' }}
            >
              <Button fullWidth variant="contained" color="secondary" startIcon={<Build />}>
                เคลมสินค้า/ซ่อมสินค้า
              </Button>
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions />
    </Dialog>
  )
}

export default PRPurchaseListCreateDialog
