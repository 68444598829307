import { errorGenericHandle, errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import {
  FetchPurchaseOrder,
  PurchaseOrderCreateData,
  PurchaseOrderCreateRequest,
  PurchaseOrderProfile,
  PurchaseProductPurchaseHistory,
  UpdatePurchaseOrderStatus,
  PurchaseOrderAppointShippingDateRequest,
  UpdatedPurchaseOrderShippingDate,
  UploadPurchaseOrderDocumentRequestBody,
  DeletePurchaseOrderDocumentURLParams,
  DeletedPurchaseOrderDocumentResponse,
  PurchaseOrderUpdateData,
  PurchaseOrderInfo,
  PurchaseOrderUpdateRequest,
  PendingPaymentPurchaseOrder,
  IPurchaseOrderTableData,
  GetPurchaseOrderTableDataQueryParams,
  PurchaseOrderTakeNoteRequestBody,
} from './types'
import { format } from 'date-fns'
import { CreatedInfo, FetchRequest, IStatusResponse, PurchaseOrderPDFInfo, UserActivityNote } from '..'
import smartAuthAPI2 from '../smartbestbuys-api-2'

export const createPurchaseOrderApi = errorHandler<CreatedInfo>((request: PurchaseOrderCreateRequest) => {
  return smartAuthAPI.post('purchase/v1/purchase-orders/create', request)
})

export const getPurchaseOrderProfileAPI = errorHandler<PurchaseOrderProfile>(async (id: number) => {
  return smartAuthAPI.get(`purchase/v1/purchase-orders/${id}/profile`)
})

export const fetchPurchaseOrders = errorHandler<FetchPurchaseOrder[]>(async (request: FetchRequest) => {
  return smartAuthAPI.get('/purchase/v1/purchase-orders/fetch', {
    params: {
      startDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
      endDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  })
})

export const getPurchaseProductHistory = errorHandler<PurchaseProductPurchaseHistory[]>((productId: number) => {
  return smartAuthAPI.get(`purchase/v1/purchase-product/${productId}/purchase-history`)
})

export const getCreatePurchaseOrder = errorHandler<PurchaseOrderCreateData>((purchaseRequisitionId: number) => {
  return smartAuthAPI.get('purchase/v1/purchase-orders/create-data', {
    params: {
      purchaseRequisitionId,
    },
  })
})

export const callLeaderPurchaseOrder = errorSingleHandler<UpdatePurchaseOrderStatus>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.patch(`purchase/v1/purchase-orders/${id}/call-leader`, request)
  },
)

export const cancelPurchaseOrder = errorSingleHandler<UpdatePurchaseOrderStatus>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.patch(`purchase/v1/purchase-orders/${id}/cancel`, request)
  },
)

export const markAsUnCancellablePurchaseOrder = errorSingleHandler<UpdatePurchaseOrderStatus>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.patch(`purchase/v1/purchase-orders/${id}/mark-as-uncancellable`, request)
  },
)

export const approvePurchaseOrder = errorHandler<UpdatePurchaseOrderStatus>(async (id: number) => {
  return smartAuthAPI.patch(`purchase/v1/purchase-orders/${id}/approve`)
})

export const rejectPurchaseOrder = errorSingleHandler<UpdatePurchaseOrderStatus>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.patch(`purchase/v1/purchase-orders/${id}/reject`, request)
  },
)

export const updateAppointShippingDatePurchaseOrder = errorSingleHandler<UpdatedPurchaseOrderShippingDate>(
  async (id: number, request: PurchaseOrderAppointShippingDateRequest) => {
    return smartAuthAPI.patch(`purchase/v1/purchase-orders/${id}/appoint-shipping-date`, request)
  },
)

export const uploadPurchaseOrderDocument = errorSingleHandler<CreatedInfo>(
  async (id: number, request: UploadPurchaseOrderDocumentRequestBody) => {
    return smartAuthAPI.post(`purchase/v1/purchase-orders/${id}/upload-document`, request)
  },
)

export const deletePurchaseOrderDocument = errorGenericHandle<
  DeletedPurchaseOrderDocumentResponse,
  DeletePurchaseOrderDocumentURLParams
>(async (urlParams: DeletePurchaseOrderDocumentURLParams, request: UserActivityNote) => {
  return smartAuthAPI.patch(
    `purchase/v1/purchase-orders/${urlParams.id}/document/${urlParams.documentId}/delete`,
    request,
  )
})

export const getUpdatePurchaseOrder = errorHandler<PurchaseOrderUpdateData>((purchaseOrderId: number) => {
  const path = `/purchase/v1/purchase-orders/${purchaseOrderId}/update-data`

  return smartAuthAPI.get(path)
})

export const updatePurchaseOrder = errorSingleHandler<PurchaseOrderInfo>(
  (purchaseOrderId: number, request: PurchaseOrderUpdateRequest) => {
    const path = `/purchase/v1/purchase-orders/${purchaseOrderId}/update`

    return smartAuthAPI.put(path, request)
  },
)

export const getPurchaseOrderPDFInfo = errorHandler<PurchaseOrderPDFInfo>((purchaseOrderId: number) => {
  return smartAuthAPI.get(`purchase/v1/purchase-orders/${purchaseOrderId}/pdf-info`)
})

export const fetchPendingPaymentPurchaseOrders = errorHandler<PendingPaymentPurchaseOrder[]>(async () => {
  return smartAuthAPI.get('/purchase/v1/purchase-orders/pending-payment')
})

export const getPurchaseOrderTableDataAPI = errorHandler<IPurchaseOrderTableData>(
  async (queryParams: GetPurchaseOrderTableDataQueryParams) => {
    return await smartAuthAPI2.get('/purchase/v1/purchase-orders/table-data', {
      params: queryParams,
    })
  },
)

export const purchaseOrderTakeNoteAPI = errorSingleHandler<IStatusResponse>(
  async (id: number, request: PurchaseOrderTakeNoteRequestBody) => {
    return smartAuthAPI.post(`purchase/v1/purchase-orders/${id}/take-note`, request)
  },
)
