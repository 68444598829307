export interface Currency {
  id: number
  name: string
}

export const currencies: Currency[] = [
  { id: 1, name: 'บาท' },
  { id: 2, name: 'USD' },
]

export const getCurrencyNameById = (id: number): string => {
  const currency = currencies.find((pc) => pc.id === id)
  return currency ? currency.name : 'unknown'
}

export enum CurrencyID {
  Baht = 1,
  USD = 2,
}
