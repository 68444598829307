import { TColumn } from '../../../../../../../custom-components'
import { DocumentType } from '../../../../../../../enums'
import { ICustomerProfile_Document } from '../../../../../../../api/smartbestbuys-api'
import taxInvoiceDocumentColumns from './taxInvoiceDocumentColumn'
import creditNoteDocumentColumns from './creditNoteDocumentColumns'
import receiptsDocumentColumns from './receiptsDocumentColumns'
import invoiceDocumentColumns from './invoiceDocumentColumns'
import billingNoteDocumentColumns from './billingNoteDocumentColumns'

// TODO: change any to something better..
const generateDocumentColumns = (
  documentType: DocumentType,
  onCancel: (data: ICustomerProfile_Document<any>) => void,
): TColumn<ICustomerProfile_Document<any>>[] => {
  switch (documentType) {
    case DocumentType.TaxInvoice:
      return taxInvoiceDocumentColumns
    case DocumentType.Receipts:
      return receiptsDocumentColumns(onCancel)
    case DocumentType.Invoice:
      return invoiceDocumentColumns
    case DocumentType.BillingNote:
      return billingNoteDocumentColumns(onCancel)
    case DocumentType.CreditNote:
      return creditNoteDocumentColumns
    default:
      alert('🚀 ยังไม่รองรับเคสนี้')
      return []
  }
}

export default generateDocumentColumns
