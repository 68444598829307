/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { withLayout, FilteredTable, Topic, FlexBox, Link, Table, GeneralTextField } from '../../../custom-components'
import { IPaymentRequisitionTableData_Data, IPaymentRequisitionTableData_Summary } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'
import { NavigationItemKey, PageName, PaymentRequisitionStatus, PaymentRequisitionStatusGroup } from '../../../enums'
import {
  Loading,
  NotificationHead,
  PaymentReceivingProductStatusChip,
  PaymentRequisitionStatusChip,
} from '../../../shared-components'
import { dateTimeFormat, getPaymentRequisitionStatusByGroup } from '../../../functions'
import { usePaymentRequisitionTableData } from '../../../hooks/purchase/usePaymentRequisitionTableData'

const PaymentRequisitionTableDataPage: React.FC = (props) => {
  // context & state
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.PAYMENT_REQUISITION)
  const [tabValue, setTabValue] = useState(1)
  // prepare
  const tableData = usePaymentRequisitionTableData({
    pageIndex: 0,
    rowPerPage: 10,
    search: { code: '', vendorName: '', statusGroupId: PaymentRequisitionStatusGroup.Pending },
  })

  if (!tableData) return <Loading />
  const {
    data,
    summary,
    paginationInfo,
    handlePageChange,
    handleSearchGroup,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    handleSearchGroup(newValue)
  }

  const summaryData = summary as IPaymentRequisitionTableData_Summary
  const summaryGroup = {
    pending: getPaymentRequisitionStatusByGroup(PaymentRequisitionStatusGroup.Pending).reduce(
      (prev, current: PaymentRequisitionStatus) => {
        const currentValue = (Number(summaryData?.amountOfStatus[current]) || 0) as number
        return prev + currentValue
      },
      0,
    ),
    approved: getPaymentRequisitionStatusByGroup(PaymentRequisitionStatusGroup.Approved).reduce(
      (prev, current: PaymentRequisitionStatus) => {
        const currentValue = (Number(summaryData?.amountOfStatus[current]) || 0) as number
        return prev + currentValue
      },
      0,
    ),
    rejected: getPaymentRequisitionStatusByGroup(PaymentRequisitionStatusGroup.Rejected).reduce(
      (prev, current: PaymentRequisitionStatus) => {
        const currentValue = (Number(summaryData?.amountOfStatus[current]) || 0) as number
        return prev + currentValue
      },
      0,
    ),
    canceled: getPaymentRequisitionStatusByGroup(PaymentRequisitionStatusGroup.Canceled).reduce(
      (prev, current: PaymentRequisitionStatus) => {
        const currentValue = (Number(summaryData?.amountOfStatus[current]) || 0) as number
        return prev + currentValue
      },
      0,
    ),
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการทำเบิก</Topic>
          </FlexBox>
          <NotificationHead navigationKey={NavigationItemKey.PaymentRequisition} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="name"
                  onChange={(e) => handleSearchParamChange('vendorName', e.target.value)}
                  label="ชื่อผู้ขาย"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="code"
                  onChange={(e) => handleSearchParamChange('code', e.target.value)}
                  label="เลขที่ทำเบิก"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <FilteredTable<IPaymentRequisitionTableData_Data>
            color="primary.dark"
            columns={[
              {
                name: 'สถานะการทำเบิก',
                dataIndex: 'status',
                render: ({ status }) => <PaymentRequisitionStatusChip status={status.id} label={status.name} />,
              },
              {
                name: 'สถานะการรับของ',
                dataIndex: 'paymentReceivingProductStatus',
                render: ({ paymentReceivingProductStatus }) => (
                  <PaymentReceivingProductStatusChip status={paymentReceivingProductStatus} />
                ),
              },
              {
                name: 'เลขที่การทำเบิก',
                render: ({ id, code }) => (
                  <Link color="primary" to={`/payment-requisitions/${id}/profile`}>
                    <Typography variant="body2">{code}</Typography>
                  </Link>
                ),
              },
              {
                name: 'วันที่สร้าง',
                render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
              },
              {
                name: 'ชื่อผู้ขาย',
                dataIndex: 'vendor',
                render: ({ vendor }) => vendor.name,
              },
              { name: 'ยอดทำเบิก', dataIndex: 'total', numeric: true, format: '0,0.00' },
              { name: 'หัก ณ ที่จ่าย', dataIndex: 'withholdingTaxAmount', numeric: true, format: '0,0.00' },
              {
                name: 'ยอดทำเบิกสุทธิ',
                render: ({ total, withholdingTaxAmount }) => total - (withholdingTaxAmount ?? 0),
                numeric: true,
                format: '0,0.00',
              },
              {
                name: 'จำนวนใบสั่งขาย',
                dataIndex: 'countPurchaseOrder',
                numeric: true,
              },
              { name: 'วิธีการชำระเงิน', render: ({ paymentMethod }) => paymentMethod.name },
            ]}
            tabOption={{
              tabs: [
                {
                  label: `รอตรวจสอบ (${summaryGroup.pending})`,
                  value: PaymentRequisitionStatusGroup.Pending,
                },
                { label: 'อนุมัติ', value: PaymentRequisitionStatusGroup.Approved },
                { label: 'ไม่อนุมัติ', value: PaymentRequisitionStatusGroup.Rejected },
                { label: 'ยกเลิก', value: PaymentRequisitionStatusGroup.Canceled },
              ],
              value: tabValue,
              onChange: handleTabChange,
            }}
            data={data}
            size="small"
            sortable
            disableSearch={true}
            disableDateRangeFilter
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(PaymentRequisitionTableDataPage)
