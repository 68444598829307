import React, { useState, useContext } from 'react'
import { DialogProps, Typography, Box, TextField } from '@material-ui/core'
import { ConfirmationDialog } from '../../ConfirmationDialog'
import { FlexBox } from '../../../custom-components'
import { PAYMENT_CONFIRMED_TEXT } from '../../../contants'
import { approvePaymentTransaction } from './service'
import { SOPaymentTransaction, UpdatedSOPaymentTransactionStatus } from '../../../api/smartbestbuys-api'
import { RootContext } from '../../..'

interface Props extends DialogProps {
  transaction: SOPaymentTransaction
  saleOrderId: number
  onConfirm?: (data: UpdatedSOPaymentTransactionStatus) => void
}

const SOPaymentApproveDialog: React.FC<Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const [text, setText] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const { transaction, saleOrderId, onConfirm, ...dialogProps } = props
  const { id, code } = transaction
  const mismatched = text !== PAYMENT_CONFIRMED_TEXT
  const handleTextChange = (e: { target: { value: string } }) => setText(e.target.value)
  return (
    <ConfirmationDialog
      title="ยืนยันอนุมัติการชำระเงิน"
      textOk="ยืนยันอนุมัติ"
      disabledCloseOnDim
      disableOkButton={mismatched}
      isSubmitting={submitting}
      onOk={async () => {
        setSubmitting(true)
        const response = await approvePaymentTransaction(saleOrderId, id)
        if (response?.status === 200) {
          triggerSnackbar('อนุมติการจ่ายเงินสำเร็จ')
          setSubmitting(false)
          onConfirm && onConfirm(response.data)
        } else {
          triggerSnackbar('อนุมติการจ่ายเงินไม่สำเร็จ')
          setSubmitting(false)
        }
      }}
      {...dialogProps}
    >
      <FlexBox flexDirection="column" gridGap={8}>
        <Typography variant="body1" color="textSecondary">
          <Box component="span" color="success.dark">
            อนุมัติ
          </Box>{' '}
          การจ่ายเงินหมายเลข {code} นี้ใช่หรือไม่?
        </Typography>
        <TextField
          autoFocus
          label={`พิมพ์ “${PAYMENT_CONFIRMED_TEXT}” เพื่อยืนยันว่าคุณได้ตรวจสอบข้อมูลแล้ว`}
          variant="outlined"
          onChange={handleTextChange}
          fullWidth
          margin="dense"
        />
      </FlexBox>
    </ConfirmationDialog>
  )
}

export default SOPaymentApproveDialog
