import { Card, Typography } from '@material-ui/core'
import numeral from 'numeral'
import React from 'react'
import { FlexBox } from '../../custom-components'
import { NivoColorPalette } from '../palette'
import { useStyles } from './styles'

interface Props {
  title: string
  amount: number
  color?: NivoColorPalette
  unit?: string
  subtitle?: string | React.ReactNode
  format?: '0,0' | '0,0.00'
}

const Statistic: React.FC<Props> = (props) => {
  const { title, amount, color, unit, subtitle, format } = props
  const classes = useStyles()
  return (
    <FlexBox flexDirection="column" alignItems="center" justifyContent="space-between" clone>
      <Card className={classes.card}>
        <Typography variant="subtitle1">{title}</Typography>
        <FlexBox color={color} flexDirection="row" alignItems="flex-end" gridGap={8}>
          <Typography className={classes.amount}>{numeral(amount).format(format)}</Typography>
          <Typography variant="subtitle1" color="textPrimary">
            {unit}
          </Typography>
        </FlexBox>
        {React.isValidElement(subtitle) ? subtitle : <Typography variant="subtitle1">{subtitle || ' '}</Typography>}
      </Card>
    </FlexBox>
  )
}

export default Statistic
