import React, { useContext } from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import { SubTopic, TypographyWithLabel } from '../../../custom-components'
import { getAddressText } from '../../../functions'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import numeral from 'numeral'
import { Space } from '../../../shared-components'

const VendorAppointmentBox: React.FC = (props) => {
  // context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const {
    paymentMethod,
    paymentCondition,
    currency,
    vendorQuotationCode,
    productTotal,
    discountAmount,
    depositAmount,
    withholdingTaxPercentage,
    remark,
    customerShippingAddress,
  } = purchaseOrderProfile

  // calculation
  const totalBeforeVat = productTotal - (discountAmount ?? 0)
  const withholdingTaxAmount = !!withholdingTaxPercentage ? (totalBeforeVat * withholdingTaxPercentage) / 100.0 : 0
  const fields = [
    { label: 'วิธีการชำระเงิน:', value: paymentMethod.name },
    { label: 'เงื่อนไขการซื้อ:', value: paymentCondition.name },
    { label: 'สกุลเงินที่ใช้:', value: currency.name },
    { label: 'เลขใบเสนอราคาของผู้ขาย', value: vendorQuotationCode ?? 'ไม่มีเลขที่อ้างอิง' },
    {
      label: 'มัดจำ:',
      value: !!depositAmount
        ? `${numeral(depositAmount).format('0,0.00')} (${numeral((depositAmount / totalBeforeVat) * 100).format(
            '0,0.00',
          )}%)`
        : 'ไม่มีมัดจำ',
    },
    {
      label: 'หัก ณ ที่จ่าย:',
      value: !!withholdingTaxAmount
        ? `${numeral(withholdingTaxAmount).format('0,0.00')} (${numeral(withholdingTaxAmount).format('0,0.00')}%)`
        : 'ไม่มีหัก ณ ที่จ่าย',
    },
    { label: 'หมายเหตุ:', value: remark ?? '-' },
  ]
  // render
  return (
    <Box p={3} clone>
      <Paper>
        <SubTopic>ข้อมูลใบสั่งซื้อ</SubTopic>
        <Space />
        <Grid container spacing={1}>
          {fields.map((field) => (
            <Grid item xs={6} key={field.label}>
              <TypographyWithLabel label={field.label}>{field.value}</TypographyWithLabel>
            </Grid>
          ))}
        </Grid>
        {!!customerShippingAddress && (
          <>
            <Space />
            <SubTopic>ข้อมูลขนส่งสำหรับผู้ขาย</SubTopic>
            <Space />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TypographyWithLabel label="ชื่อผู้ติดต่อ:">
                  {customerShippingAddress.consigneeName}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="เบอร์โทร:">
                  {customerShippingAddress.consigneePhoneNumber}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={6}>
                <TypographyWithLabel label="สถานที่ส่ง:">{getAddressText(customerShippingAddress)}</TypographyWithLabel>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Box>
  )
}

export default VendorAppointmentBox
