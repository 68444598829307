import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles } from '@material-ui/core'
import { PaymentReceivingProductStatus } from '../../enums'
import { Description } from '@material-ui/icons'
import { BaseData } from '../../api/smartbestbuys-api'

interface Props {
  status: BaseData
  icon?: boolean
}

const useStyles = (status: PaymentReceivingProductStatus) =>
  makeStyles((theme) => {
    const chipColors = {
      [PaymentReceivingProductStatus.Pending]: theme.palette.warning.main,
      [PaymentReceivingProductStatus.Partial]: theme.palette.warning.main,
      [PaymentReceivingProductStatus.ReceivedAll]: theme.palette.success.main,
    }

    const iconColors = {
      [PaymentReceivingProductStatus.Pending]: theme.palette.warning.darkText,
      [PaymentReceivingProductStatus.Partial]: theme.palette.warning.darkText,
      [PaymentReceivingProductStatus.ReceivedAll]: theme.palette.success.darkText,
    }

    return createStyles({
      chip: {
        backgroundColor: chipColors[status],
      },
      icon: {
        color: iconColors[status],
      },
    })
  })

const PaymentReceivingProductStatusChip: React.FC<Props & Omit<ChipProps, 'icon'>> = ({
  children,
  status,
  icon,
  ...othersProps
}) => {
  const classes = useStyles(status.id)()

  return (
    <Chip
      classes={{
        icon: classes.icon,
      }}
      icon={icon ? <Description /> : undefined}
      className={classes.chip}
      label={status.name}
      {...othersProps}
    />
  )
}

export default PaymentReceivingProductStatusChip
