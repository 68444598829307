import { Grid, Paper, TablePagination, TextField } from '@material-ui/core'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import { ICustomerProfile_TransportTask } from '../../../../../../api/smartbestbuys-api'
import { EmptyCard, SubTopic } from '../../../../../../custom-components'
import { defaultStartDate, defaultEndDate } from '../../../../../../data'
import CustomerProfileContext from '../../CustomerProfileContext'
import { CustomerTransportationCard } from './CustomerTransportationCard'

const CustomerTransportationBox: React.FC = () => {
  const { customerProfile } = useContext(CustomerProfileContext)

  const { transportTasks } = customerProfile
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [search, setSearch] = useState('')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const pagination = useMemo(() => {
    return transportTasks && transportTasks.length > rowsPerPage
  }, [rowsPerPage, transportTasks])

  const filterTransportTasks = useMemo(() => {
    return (
      transportTasks &&
      transportTasks
        .filter((transportTask) => {
          const dueDate = transportTask.dueDate ? new Date(transportTask.dueDate) : undefined
          return dueDate && startDate <= dueDate && dueDate <= endDate
        })
        .filter((transportTasks) => {
          return transportTasks.code.toLowerCase().includes(search.toLowerCase())
        })
    )
  }, [endDate, search, startDate, transportTasks])

  const paginate = (data: ICustomerProfile_TransportTask[]) => {
    return pagination ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <SubTopic>ประวัติงานขนส่ง</SubTopic>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              id="date"
              label="วันที่"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={moment(startDate).format('YYYY-MM-DD')}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const updatedStartDate = moment(e.target.value).startOf('day').toDate()
                setStartDate(updatedStartDate)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              id="date"
              label="ถึงวันที่"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={moment(endDate).format('YYYY-MM-DD')}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const updatedEndDate = moment(e.target.value).endOf('day').toDate()
                setEndDate(updatedEndDate)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={9} md={4}>
            <TextField
              fullWidth
              label="ค้นหา"
              variant="outlined"
              size="small"
              onChange={({ target: { value } }) => {
                setSearch(value)
              }}
            />
          </Grid>
          <Grid item md={12}>
            {filterTransportTasks && filterTransportTasks.length > 0 ? (
              <Grid container spacing={2}>
                {paginate(filterTransportTasks).map((transportTask) => (
                  <Grid item xs={12} key={transportTask.id}>
                    <CustomerTransportationCard transportTask={transportTask} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <EmptyCard emptyText="ยังไม่มีประวัติการขนส่ง" />
              </Grid>
            )}
          </Grid>
          {pagination && (
            <Grid item md={12}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filterTransportTasks ? filterTransportTasks.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerTransportationBox
