import React, { useState } from 'react'
import { Grid, isWidthUp, Paper, TablePagination, withWidth } from '@material-ui/core'
import { useFieldArray, useFormContext, NestedValue } from 'react-hook-form'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

import { FlexBox, SearchTextField, SubTopic } from '../../../../custom-components'
import PurchaseProductCard from './PurchaseProductCard/PurchaseProductCard'
import { useStyles } from './styles'
import { PRSaleOrderProduct, PurchaseProductUpsertData } from '../../../../api/smartbestbuys-api'
import { ButtonRadios } from '../../../../shared-components'
import { PurchaseProductFilterKey } from '../../../../enums'

export interface PurchaseProduct extends PurchaseProductUpsertData {
  amount: number
  price?: number
  base64?: string
  typeId?: number
}

export interface PurchaseProductSelectorFormValues {
  products: NestedValue<Array<PurchaseProduct>>
}

interface Props {
  width: Breakpoint
  productData: PurchaseProductUpsertData[]
  saleOrderProductsData?: PRSaleOrderProduct[]
  rop?: boolean
}

const PurchaseProductSelector: React.FC<Props> = (props: Props) => {
  const { width, productData, saleOrderProductsData, rop } = props

  const { control, setValue, watch } = useFormContext<PurchaseProductSelectorFormValues>()

  const { append } = useFieldArray<PurchaseProductSelectorFormValues>({
    control,
    name: 'products',
  })

  const styles = useStyles()
  const rowsPerPage = isWidthUp('xl', width) ? 12 : 8

  const { products } = watch()

  const isPRRefFromSaleOrder = saleOrderProductsData && saleOrderProductsData.length > 0

  const filterTypes = !rop
    ? [
        { label: 'จาก SO', value: PurchaseProductFilterKey.SaleOrder },
        { label: 'ทั้งหมด', value: PurchaseProductFilterKey.AllProduct },
      ]
    : [
        { label: 'ถึงจุดที่ต้องซื้อ', value: PurchaseProductFilterKey.PendingPurchase },
        { label: 'ทั้งหมด', value: PurchaseProductFilterKey.AllProduct },
      ]

  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')
  const [typeOfSelector, setTypeOfSelector] = useState(
    isPRRefFromSaleOrder
      ? PurchaseProductFilterKey.SaleOrder
      : !!rop
      ? PurchaseProductFilterKey.PendingPurchase
      : PurchaseProductFilterKey.AllProduct,
  )

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const getPurchaseProducts = (): PurchaseProductUpsertData[] => {
    if (isPRRefFromSaleOrder && typeOfSelector === PurchaseProductFilterKey.SaleOrder) {
      return productData.filter((p) => !!saleOrderProductsData?.find((sp) => p.code === sp.code)) || []
    }
    if (rop && typeOfSelector === PurchaseProductFilterKey.PendingPurchase) {
      return productData.filter((p) => !!p.haveToPurchase)
    }
    return productData
  }

  const filteredPurchaseProducts = () =>
    getPurchaseProducts().filter((p) => {
      const lowerCase = query.toLowerCase()
      return p.code?.toLowerCase().includes(lowerCase) || p.name.toLowerCase().includes(lowerCase)
    })

  const handleSelectProduct = (product: PurchaseProductUpsertData) => {
    const index = products.findIndex((p) => p.code === product.code)
    if (index > -1) {
      products.splice(index, 1)
      setValue('products', products)
    } else {
      append(product)
    }
  }

  const paginate = (products: PurchaseProductUpsertData[]) =>
    products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <Paper className={styles.root}>
      <SubTopic>เลือกสินค้า/บริการ ลงในคำขอซื้อ</SubTopic>
      <FlexBox justifyContent="space-between" alignItems="center">
        <SearchTextField
          onChange={(e) => {
            setQuery(e.target.value)
            setPage(0)
          }}
        />
        {(isPRRefFromSaleOrder || rop) && (
          <ButtonRadios
            items={filterTypes.map((type) => ({ id: type.value, name: type.label }))}
            onChange={(value) => setTypeOfSelector(value)}
            value={typeOfSelector}
            color="secondary"
          />
        )}
      </FlexBox>
      <Grid container spacing={2}>
        {paginate(filteredPurchaseProducts()).map((product) => {
          return (
            <Grid key={product.code} item xs={12} sm={6} md={3} xl={2}>
              <PurchaseProductCard
                key={product.code}
                code={product.code}
                imageUrl={product?.imageUrl}
                name={product.name}
                onClick={() => handleSelectProduct(product)}
                selected={products.length > 0 ? !!products.find((p) => p.code === product.code) : false}
              />
            </Grid>
          )
        })}
      </Grid>
      <TablePagination
        component="div"
        count={filteredPurchaseProducts().length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </Paper>
  )
}

export default withWidth()(PurchaseProductSelector)
