import React, { useContext } from 'react'
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  KeyboardDatePickerForm,
  TypographyWithLabel,
} from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'
import { useForm } from 'react-hook-form'
import { dateFormat, dateSqlFormat } from '../../../functions'
import {
  PurchaseOrderAppointShippingDateRequest,
  updateAppointShippingDatePurchaseOrder,
  UpdatedPurchaseOrderShippingDate,
} from '../../../api/smartbestbuys-api'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'

export interface ShippingDateProps extends DialogProps {
  onComplete: (data: UpdatedPurchaseOrderShippingDate) => void
  onFail: () => void
}

interface ShippingDateFormValues {
  vendorShippingDate: Date
  remark?: string
}

const PurchaseOrderProfileShippingDateDialog: React.FC<ShippingDateProps> = (props) => {
  // set up props & context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { id, vendorShippingDate } = purchaseOrderProfile
  const isFirstTime = !vendorShippingDate
  const titleModal = isFirstTime ? 'นัดหมายผู้ขาย' : 'แก้ไขวันนัดหมายผู้ขาย'
  const { onClose, onComplete, onFail, ...otherProps } = props
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<ShippingDateFormValues>({})

  const onDialogSubmit = async (values: ShippingDateFormValues) => {
    const { remark, vendorShippingDate } = values
    const request: PurchaseOrderAppointShippingDateRequest = {
      vendorShippingDate: dateSqlFormat(vendorShippingDate),
      remark,
    }
    const response = await updateAppointShippingDatePurchaseOrder(id, request)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <Dialog {...otherProps}>
      <Form onSubmit={handleSubmit(onDialogSubmit)}>
        <DialogTitle>{titleModal}</DialogTitle>
        <DialogContent>
          <FlexBox flexDirection="column" gridGap={8}>
            <KeyboardDatePickerForm
              control={control}
              label={isFirstTime ? 'วันที่นัดหมายผู้ขาย' : 'วันที่นัดหมายผู้ขาย (ใหม่)'}
              name="vendorShippingDate"
              size="small"
              value=""
              rules={{ required: { value: true, message: 'กรุณาเลือกวันที่ส่งของ' } }}
              fullWidth
              defaultValue={null}
            ></KeyboardDatePickerForm>
            {!!vendorShippingDate && (
              <>
                <GeneralTextFieldForm
                  control={control}
                  name="remark"
                  label="เหตุผลที่แก้ไขใบสั่งซื้อ"
                  variant="outlined"
                  defaultValue=""
                />
                <TypographyWithLabel label="วันที่นัดหมายล่าสุด:">{dateFormat(vendorShippingDate)}</TypographyWithLabel>
              </>
            )}

            <Typography variant="body1" style={{ color: '#C77700' }}>
              งานฝ่ายคลังจะถูกแก้ไขวันที่นัดหมายอัตโนมัติหลังจากแก้ไขวันนัดหมายกับผู้ขายสำเร็จ
            </Typography>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText="ยืนยันวันนัดหมาย"
            cancelText="ยกเลิก"
            onCancel={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default PurchaseOrderProfileShippingDateDialog
