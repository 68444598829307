import SOUpdationFormValue, { SOAttachment } from './SOUpdationFormValues'
import {
  SaleOrderAttachmentUpdation,
  SaleOrderProductUpdation,
  SaleOrderUpdationRequest,
  updateSaleOrderAPI,
} from '../../../../../api/smartbestbuys-api/sale/update-sale-order-api'
import { calculatePriceBreakdowns } from '../../../../../sales-system/utils/sale-order-price-calculator'
import { SelectedUpdationProduct, SQLCommand } from '../../../../../sales-system/types'
import { SaleOrderProfile } from '../../../../../api/smartbestbuys-api'
import { format } from 'date-fns'

export const updateSaleOrderService = (saleOrderProfile: SaleOrderProfile, values: SOUpdationFormValue) => {
  const priceBreakdown = calculatePriceBreakdowns(values)

  const requestForm: SaleOrderUpdationRequest = {
    contactId: saleOrderProfile.contact?.id,
    billingAddressId: values?.billingAddressId,
    contactChannelId: values?.contactChannelId,
    customerPurchaseReference: values?.customerPurchaseReference,
    currencyId: values?.currencyId,
    exchangeRate: values?.exchangeRate,
    paymentMethodId: values?.paymentMethodId,
    paymentConditionId: values?.paymentConditionId,
    paymentStatusId: saleOrderProfile.paymentStatus?.id,
    statusId: saleOrderProfile.status?.id,
    vatIncluded: values?.vatIncluded ? values.vatIncluded : false,
    discountAmount: values.discountEnabled ? values.discount : 0,
    discountAmountTypeId: 1,
    estimatedShippingDay: undefined,
    estimatedShippingFee: values?.shippingFeeEnabled ? values?.shippingFee : 0,
    depositRequired: values?.depositRequired || false,
    remark: values?.remark,
    dueDate: values.dueDate ? format(values.dueDate, 'yyyy-MM-dd') : undefined,
    creditPeriodDay: values?.creditPeriodDay,
    isShippingFeeIncludingVat: values?.isShippingFeeIncludingVat,
    attachments: values?.attachments?.map(mapToAttachment),
    products: values?.selectedProducts.map(mapToProductRequest),
    total: priceBreakdown.total,
    customerPickUpMethod: values.customerPickUpMethod,
    createdAt: values.createdAt.toString(),
  }

  return updateSaleOrderAPI(saleOrderProfile.id, requestForm)
}

const mapToProductRequest = (selectedProduct: SelectedUpdationProduct): SaleOrderProductUpdation => {
  return {
    id: selectedProduct.id,
    productId: selectedProduct.productId,
    code: selectedProduct.code,
    nameLocal: selectedProduct.nameLocal,
    nameEn: selectedProduct.nameEn,
    unitLocal: selectedProduct.unitLocal,
    unitEn: selectedProduct.unitEn,
    imageUrl: selectedProduct.imageUrl,
    amount: selectedProduct.amount,
    price: selectedProduct.price,
    command: selectedProduct.command,
  }
}

const mapToAttachment = (attachment: SOAttachment): SaleOrderAttachmentUpdation => {
  return {
    id: attachment.id,
    base64: attachment.command === SQLCommand.CREATE ? attachment.src : undefined,
    command: attachment.command,
  }
}
