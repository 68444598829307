import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  isWidthUp,
  TablePagination,
  withWidth,
} from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { PRVendor } from '../../../../../api/smartbestbuys-api'
import { FlexBox, SearchTextField } from '../../../../../custom-components'
import { VenderBoxFormValues } from '../VendorBox'
import { VendorCard } from '../VendorCard'

interface Props extends DialogProps {
  width: Breakpoint
  vendors: PRVendor[]
  onVendorChange?: (vendor: PRVendor | undefined) => void
}

const VendorDialog: React.FC<Props> = (props) => {
  const { onClose, width, vendors, onVendorChange, ...otherProps } = props

  const rowsPerPage = isWidthUp('xl', width) ? 6 : 4

  const { setValue, watch, clearErrors } = useFormContext<VenderBoxFormValues>()

  const { vendor } = watch()

  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')
  const [_selectedVendor, setSelectedVendor] = useState<PRVendor | undefined>(vendor)

  useEffect(() => {
    if (!_selectedVendor) {
      setSelectedVendor(vendor)
    }
  }, [vendor, _selectedVendor])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const paginate = (vendors: PRVendor[]) => vendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleSelectedVendor = (vendor: PRVendor | undefined) => {
    setSelectedVendor(vendor)
  }

  const handleSubmitVendor = () => {
    setValue('vendor', _selectedVendor)
    if (onVendorChange) onVendorChange(_selectedVendor)
    clearErrors('vendor')
    setSelectedVendor(undefined)
    setQuery('')
    onClose && onClose({}, 'escapeKeyDown')
  }

  const _onClose = () => {
    setQuery('')
    onClose && onClose({}, 'escapeKeyDown')
  }

  const filteredVendors = vendors.filter((p) => {
    const lowerCase = query.toLowerCase()
    return p?.name.toLowerCase().includes(lowerCase)
  })

  return (
    <Dialog fullWidth maxWidth="lg" {...otherProps} onClose={onClose}>
      <DialogTitle>เลือกผู้ขาย</DialogTitle>
      <DialogContent>
        <SearchTextField
          onChange={(e) => {
            setQuery(e.target.value)
            setPage(0)
          }}
        />
        <Grid item container spacing={2} style={{ paddingTop: 12, height: 316 }}>
          {paginate(filteredVendors).map((_vendor, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={3} xl={2}>
                <VendorCard
                  onSelect={handleSelectedVendor}
                  vendor={_vendor}
                  isSelected={_selectedVendor && _vendor.id === _selectedVendor.id}
                />
              </Grid>
            )
          })}
        </Grid>
        <TablePagination
          style={{ paddingTop: 12 }}
          component="div"
          count={filteredVendors.length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </DialogContent>
      <DialogActions style={{ paddingBottom: 16 }}>
        <FlexBox justifyContent="end" alignItems="center" gridGap={16}>
          <Button color="primary" onClick={() => _onClose()}>
            ยกเลิก
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmitVendor}>
            เลือกผู้ขายอ้างอิง
          </Button>
        </FlexBox>
      </DialogActions>
    </Dialog>
  )
}

export default withWidth()(VendorDialog)
