import React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import { useController, RegisterOptions } from 'react-hook-form'

type Props = Omit<TextFieldProps, 'ref'> & {
  control: any
  // TODO: refactor
  rules?: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

const TextFieldForm: React.FC<Props> = (props) => {
  const { control, rules, name, type, defaultValue, ...textFieldProps } = props
  const {
    field: { ref, onChange, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    formState: { errors },
  } = useController({
    name: name as any,
    control,
    defaultValue: defaultValue,
    rules,
  })

  return (
    <TextField
      type={type}
      onChange={(e) => {
        if (type === 'number' && e.target.value !== '') {
          onChange(Number(e.target.value))
        } else {
          onChange(e.target.value)
        }
      }}
      error={!!errors[name as any]}
      helperText={errors[name as any]?.message}
      {...inputProps}
      {...textFieldProps}
      inputRef={ref}
    />
  )
}

export default TextFieldForm
