import { Box, Typography } from '@material-ui/core'
import { ICustomerProfile_SaleOrder } from '../../../../../../api/smartbestbuys-api'
import { IconChip, Link, Table } from '../../../../../../custom-components'
import { EmptyBoxIcon } from '../../../../../../custom-components/Icon'
import { dateTimeFormat } from '../../../../../../functions'
import { SOPaymentStatusChip, SOStatusChip } from '../../../../../../shared-components'

const renderCustomerSaleOrder = (saleOrders?: ICustomerProfile_SaleOrder[]) => {
  return (
    <Table<ICustomerProfile_SaleOrder>
      size="small"
      color="primary.dark"
      columns={[
        {
          name: '',
          render: ({ stockAvailable }) =>
            !!stockAvailable && (
              <IconChip
                icon={
                  <Box color="error.darkText">
                    <EmptyBoxIcon fontSize="small" color="inherit" />
                  </Box>
                }
                color="error.light"
              />
            ),
        },
        {
          name: 'สถานะใบสั่งขาย',
          render: ({ status }) => <SOStatusChip status={status.id} label={status.name} />,
        },
        {
          name: 'สถานะชำระเงิน',
          render: ({ paymentStatus }) => <SOPaymentStatusChip status={paymentStatus.id} label={paymentStatus.name} />,
        },
        {
          name: 'เลขที่ใบสั่งขาย',
          render: ({ id, code }) => (
            <Box color="primary.dark">
              <Link color="inherit" href={`/sale-orders/${id}/profile`} target="_blank">
                <Typography variant="body2">{code}</Typography>
              </Link>
            </Box>
          ),
        },
        {
          name: 'ชื่อผู้ติดต่อ',
          render: ({ recCreatedBy }) => recCreatedBy,
        },
        {
          name: 'วันที่สร้าง',
          render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
        },
        {
          name: 'เลขที่ PO ของลูกค้า',
          render: ({ customerPurchaseReference }) => customerPurchaseReference,
        },
        {
          name: 'วิธีชำระเงิน',
          render: ({ paymentMethod }) => paymentMethod.name,
        },
        {
          name: 'ยอดรวมสุทธิ',
          numeric: true,
          format: '0,0.00',
          dataIndex: 'total',
        },
      ]}
      data={saleOrders || []}
      cleanSpace
      pagination
    />
  )
}

export default renderCustomerSaleOrder
