import { Grid, Typography, Paper } from '@material-ui/core'
import { useContext } from 'react'
import { SubTopic } from '../../../../custom-components'
import { Space } from '../../../../shared-components'
import SaleOrderProfileContext from './SaleOrderProfileContext'

const SaleOrderProfileTodoListToCompleteCancel: React.FC = () => {
  const { saleOrderProfile } = useContext(SaleOrderProfileContext)

  if (!saleOrderProfile?.checklistToCompleteCancel) return <></>

  const { reclaimProductBack, createCreditNote, refundToCustomer } = saleOrderProfile.checklistToCompleteCancel

  let checklistMessages: string[] = []
  if (reclaimProductBack)
    checklistMessages = [...checklistMessages, 'ยังไม่ได้เรียกคืนสินค้าทั้งหมด, โปรดติดตามงานเรียกคืนสืนค้า']
  if (createCreditNote) checklistMessages = [...checklistMessages, 'มีใบกำกับภาษีค้างอยู่ กรุณาออกใบลดหนี้มาหักล้าง']
  if (refundToCustomer) checklistMessages = [...checklistMessages, 'ยังคืนเงินลูกค้าไม่ครบ กรุณาสร้างงานคืนเงินลูกค้า']

  if (checklistMessages.length === 0) return <></>

  return (
    <Paper>
      <Grid item xs={12}>
        <SubTopic>รายการที่ต้องทำเพราะยกเลิกใบสั่งขายนี้</SubTopic>
        <Space />
        <ol>
          {checklistMessages.map((message) => {
            return (
              <li className="mb-1">
                <Typography variant="body2" color="error">
                  - {message}
                </Typography>
              </li>
            )
          })}
        </ol>
      </Grid>
    </Paper>
  )
}

export default SaleOrderProfileTodoListToCompleteCancel
