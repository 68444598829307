import React, { useContext } from 'react'
import { Paper, Grid, Card } from '@material-ui/core'
import { FlexBox, SubTopic, TypographyWithLabel } from '../../../custom-components'
import { Loading, TypographyLink } from '../../../shared-components'
import { dateFormat } from '../../../functions'
import { formatNumber } from '../../../functions/number-utils'
import { TransportTaskProfilePageContext } from '../profile/TransportTaskProfilePage'

const TransportPickupTaskDetail: React.FC = () => {
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const { state, saleOrderInfo } = transportTaskProfile
  if (!transportTaskProfile || !state) return <Loading />
  const shipBySmart = !state.shippingInfo?.useShippingProvider

  return (
    <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>รายละเอียดขนส่ง</SubTopic>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="วิธีการขนส่ง:">
                {state?.shippingInfo.useShippingProvider ? 'ขนส่งอื่น' : 'SMART BEST BUYS'}
              </TypographyWithLabel>
            </Grid>
            {!shipBySmart && (
              <>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="ขนส่งที่เลือก:">
                    {state.shippingInfo.shippingProvider?.name ?? 'ไม่ระบุขนส่ง'}
                  </TypographyWithLabel>
                </Grid>
                {state.shippingInfo.shippingProvider?.detail && (
                  <Grid item xs={12} md={6}>
                    <TypographyWithLabel label="รายละเอียดเพิ่มเติม:">
                      {state.shippingInfo.shippingProvider?.detail}
                    </TypographyWithLabel>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label={'วันที่รับสินค้า:'}>
                {transportTaskProfile.dueDate ? dateFormat(transportTaskProfile.dueDate) : 'ยังไม่ระบุ'}
              </TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="เลขทะเบียนรถ:">
                {state?.shippingInfo.messengerInfo?.car?.name || '-'}
              </TypographyWithLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label={'ผู้รับของ:'}>
                {state?.shippingInfo.messengerInfo?.name || '-'}
              </TypographyWithLabel>
            </Grid>
            {shipBySmart ? (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="ยอดเงินที่ต้องเรียกเก็บ:">
                  {state?.shippingInfo.chargeAmount
                    ? `${formatNumber(state?.shippingInfo.chargeAmount)} บาท`
                    : 'ไม่ต้องเก็บเงินเพิ่ม'}
                </TypographyWithLabel>
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="รูปแบบการจ่ายเงินให้ขนส่ง:">
                  {state.shippingInfo.payShippingFeeBySmart ? 'ต้นทาง' : 'ปลายทาง'}
                </TypographyWithLabel>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TypographyWithLabel label="หมายเหตุ:">{state?.shippingInfo.remark ?? '-'}</TypographyWithLabel>
            </Grid>
          </Grid>
        </Grid>
        <div className="mb-4" />
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SubTopic>{saleOrderInfo?.customerName ?? '-'}</SubTopic>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="รายละเอียดที่อยู่:">
                  {state?.shippingInfo.shippingAddress?.text ?? '-'}
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="เบอร์โทรศัพท์:">{state?.shippingInfo.phoneNumber}</TypographyWithLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="overflow-hidden hover:overflow-unset">
                  <TypographyWithLabel label="ลิงก์ googlemap:">
                    {state?.shippingInfo.googleMapLink ? (
                      <TypographyLink target="_blank" href={state?.shippingInfo.googleMapLink} color="secondary.main">
                        {state?.shippingInfo.googleMapLink || '-'}
                      </TypographyLink>
                    ) : (
                      '-'
                    )}
                  </TypographyWithLabel>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label={'ชื่อผู้ติดต่อ:'}>{state?.shippingInfo.consignee}</TypographyWithLabel>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Paper>
    </FlexBox>
  )
}

export default TransportPickupTaskDetail
