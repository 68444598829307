import { Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import numeral from 'numeral'
import { IRefundRequisitionProfile, IRefundRequisitionProfile_SaleOrder } from '../../../api/smartbestbuys-api'
import { FlexBox, SubTopic, Table, TypographyWithLabel } from '../../../custom-components'
import { ImageCard } from '../../../shared-components'

interface Props {
  refundRequisitionProfile: IRefundRequisitionProfile
}

const RefundRequisitionProfileInfoBox: React.FC<Props> = (props: Props) => {
  // props and state
  const { refundRequisitionProfile } = props
  const { saleOrders, attachments, remark } = refundRequisitionProfile

  let netAmount: number = 0
  saleOrders.forEach((item) => (netAmount += item.total))

  return (
    <Paper>
      <SubTopic>รายละเอียดการคืนเงิน</SubTopic>
      <div className="mb-4" />
      <Typography variant="subtitle2">รายการใบสั่งขายสำหรับคืนเงิน</Typography>
      <Table<IRefundRequisitionProfile_SaleOrder>
        size="small"
        color="primary.dark"
        columns={[
          { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center', fixedWidth: 75 },
          {
            name: 'เลขที่ใบสั่งขาย',
            render: (record) => (
              <Typography variant="body2" color="primary">
                {record.code}
              </Typography>
            ),
          },
          {
            name: 'ยอดหนี้ที่จะคืน',
            dataIndex: 'total',
            numeric: true,
            format: '0,0.00',
            fixedWidth: 200,
          },
        ]}
        postCells={
          <TableRow>
            <TableCell colSpan={1} />
            <TableCell align="right">ยอดหนี้รวม</TableCell>
            <TableCell align="right">{numeral(netAmount).format('0,0.00')}</TableCell>
          </TableRow>
        }
        data={saleOrders}
        pagination={false}
        cleanSpace
      />
      <div className="mb-4" />
      {!!remark && (
        <TypographyWithLabel variant="subtitle2" label="หมายเหตุ">
          {remark}
        </TypographyWithLabel>
      )}
      <div className="mb-4" />
      {attachments.length > 0 && (
        <>
          <Typography variant="subtitle2">ไฟล์แนบเพิ่มเติม</Typography>
          <FlexBox gridGap={16}>
            {attachments.map((attachment, index) => (
              <ImageCard key={`image-${index}`} showName src={attachment.url} name={`ไฟล์แนบ ${index + 1}`} />
            ))}
          </FlexBox>
        </>
      )}
    </Paper>
  )
}

export default RefundRequisitionProfileInfoBox
