import { Checkbox, FormControlLabel } from '@material-ui/core'
import { PermissionGroup } from '../../api/smartbestbuys-api'

interface UserPermissionGroupCheckboxProps {
  selectedPermissionGroupIds: number[]
  permissionGroup: PermissionGroup
  onPermissionGroupSelect: (selectedPermissionGroupIds: number[]) => void
}

const UserPermissionGroupCheckbox: React.FC<UserPermissionGroupCheckboxProps> = (props) => {
  const { onPermissionGroupSelect, selectedPermissionGroupIds, permissionGroup } = props

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={selectedPermissionGroupIds.includes(permissionGroup.id)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              selectedPermissionGroupIds.push(permissionGroup.id)
            } else {
              selectedPermissionGroupIds.splice(
                selectedPermissionGroupIds.findIndex((v) => v === permissionGroup.id),
                1,
              )
            }
            onPermissionGroupSelect([...selectedPermissionGroupIds])
          }}
        />
      }
      label={permissionGroup.name}
    />
  )
}

export default UserPermissionGroupCheckbox
