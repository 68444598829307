import React from 'react'
import { Grid, Avatar, Typography, Chip } from '@material-ui/core'
import {
  PermIdentityTwoTone as PermIdentityIcon,
  DesktopWindowsTwoTone as DesktopWindowsIcon,
  LocalPhoneTwoTone as LocalPhoneIcon,
  EmailTwoTone as EmailIcon,
} from '@material-ui/icons'
import { useStyles, useCustomerStyle, useCompanyStyle } from './styles'
import { Title, TypographyWithLabel } from '../../custom-components'
import { CustomerType } from '../../enums'
import { ICustomerProfile } from '../../api/smartbestbuys-api'
import { ImageIcon } from '../ImageIcon'
import dealerLogo from '../../assets/images/dealer-logo.png'
interface Props {
  profile: ICustomerProfile
}

const CustomerInfo: React.FC<Props> = (props) => {
  const { profile } = props
  const { customerType, nameLocal, code, creditPeriodDay, referenceNumber, websiteUrl, phoneNumber, email, isDealer } =
    profile

  const commonStyles = useStyles()

  const customerStyles = useCustomerStyle()
  const companyStyles = useCompanyStyle()

  const isCompany = customerType.id === CustomerType.Company
  const isCredit = (creditPeriodDay || 0) > 0
  const styles = isCompany ? companyStyles : customerStyles

  const avatarClassNames = [commonStyles.avatar]
  if (isCredit) {
    avatarClassNames.push(commonStyles.creditAvatar)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <Avatar alt="Customer Avatar" className={avatarClassNames.join(' ')}></Avatar>
        {isCredit && (
          <Typography className={commonStyles.creditBadge} variant="body2" component="div">
            ลูกค้าเครดิต <br /> {creditPeriodDay} วัน
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={10} container spacing={2} className={commonStyles.customerInfo}>
        <Grid item xs={12} container>
          <Grid item xs={12} className={commonStyles.customerTitle}>
            <Typography variant="h6" color="textSecondary" component="span">
              {code}
            </Typography>
            <Chip label={customerType.name} className={styles.chip} />
            {isDealer && (
              <span className="ml-2">
                <ImageIcon name="dealer" src={dealerLogo} />
              </span>
            )}
          </Grid>
          <Grid item xs={12} className={commonStyles.customerTitle}>
            <Title>{nameLocal}</Title>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel icon={<PermIdentityIcon fontSize="small" />} label="เลขประจำตัวผู้เสียภาษี:">
            {referenceNumber}
          </TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel icon={<DesktopWindowsIcon fontSize="small" />} label="เว็บไซต์:">
            {websiteUrl}
          </TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel icon={<LocalPhoneIcon fontSize="small" />} label="เบอร์โทร:">
            {phoneNumber}
          </TypographyWithLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <TypographyWithLabel icon={<EmailIcon fontSize="small" />} label="อีเมล:">
            {email}
          </TypographyWithLabel>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TypographyWithLabel icon={<LocationOnIcon fontSize="small" />} label="ที่อยู่เปิดบิล:">
            {addressText}
          </TypographyWithLabel>
        </Grid> */}
      </Grid>
    </Grid>
  )
}

export default CustomerInfo
