import { useState, useEffect } from 'react'
import { DocumentInvoice, fetchDocumentInvoices, FetchRequest } from '../api/smartbestbuys-api'

export const useDocumentInvoices = (startDate: Date, endDate: Date): DocumentInvoice[] => {
  const [documentInvoices, setDocumentInvoice] = useState<DocumentInvoice[]>([])

  const _fetchDocumentInvoices = async (request: FetchRequest) => {
    const response = await fetchDocumentInvoices(request)
    setDocumentInvoice(response?.data || [])
  }

  useEffect(() => {
    _fetchDocumentInvoices({ startDate, endDate })
  }, [startDate, endDate])

  return documentInvoices
}
