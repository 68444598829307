import { Grid } from '@material-ui/core'
import { SubTopic } from '../../../custom-components'
// import { GridBox } from '../../../shared-components'
import {
  CashFlowBar,
  CashTotalAmountStat,
  TransferedTotalAmountStat,
  CreditCardTotalAmountStat,
  ChequeTotalAmountStat,
  TotalAmountStat,
  // DebtTotalLineGraph,
  // CashFlowTotal,
  CustomerCreditRatioProgressBar,
  // LateDebtTotalLineGraph,
  // DSOLineGraph,
} from './Income'

const FinanceDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SubTopic>ฝ่ายบัญชี</SubTopic>
      </Grid>
      <CashFlowBar />
      <CashTotalAmountStat />
      <TransferedTotalAmountStat />
      <CreditCardTotalAmountStat />
      <ChequeTotalAmountStat />
      <TotalAmountStat />
      <CustomerCreditRatioProgressBar />
      {/* <CashFlowBar keys={['total', 'total2']} />
      <GridBox item xs={12} md={4} row={12}>
        <Grid container spacing={2}>
          <CashFlowTotal />
          <CustomerCreditRatioProgressBar />
        </Grid>
      </GridBox>
      <DebtTotalLineGraph />
      <LateDebtTotalLineGraph />
      <GridBox item xs={12} md={4} row={12}>
        <Grid container spacing={2}></Grid>
      </GridBox>
      <DSOLineGraph /> */}
    </Grid>
  )
}

export default FinanceDashboard
