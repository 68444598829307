import { Box, Paper, Typography } from '@material-ui/core'
import { Photo } from '@material-ui/icons'
import { PurchaseOrderUpsertData_RelatedPRProduct } from '../../../../api/smartbestbuys-api'
import { SubTopic, FlexBox, Table, Avatar } from '../../../../custom-components'
import { formatNumber, formatNumberInt } from '../../../../functions/number-utils'
import { Space } from '../../../../shared-components'

import './RelatedPurchaseOrderProductInfoTable.less'

interface Props {
  products: PurchaseOrderUpsertData_RelatedPRProduct[]
}

const PurchaseRequisitionProductInfoTable = (props: Props) => {
  const { products } = props

  return (
    <Paper className="flex flex-col purchase-order-product-info">
      <SubTopic>รายการสินค้า/บริการ จากใบขอซื้อเดียวกัน</SubTopic>
      <Space />
      <Table<PurchaseOrderUpsertData_RelatedPRProduct>
        size="small"
        paperClassName="table--white"
        color="secondary.dark"
        columns={[
          {
            name: 'เลขที่ใบสั่งซื้อ',
            render: ({ purchaseOrderCode }) => (
              <Box color="primary.dark">
                <Typography variant="body2">{purchaseOrderCode}</Typography>
              </Box>
            ),
          },
          {
            name: 'ชื่อสินค้า/บริการ',
            fixedWidth: 417,
            render: ({ imageUrl, name, code }) => (
              <FlexBox alignItems="flex-start">
                <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                  <Photo />
                </Avatar>
                <FlexBox flexDirection="column" ml={1} className="purchase-product-header">
                  <FlexBox gridGap={8}>
                    <Typography variant="body2" className="purchase-product-header__code">
                      {code}
                    </Typography>
                  </FlexBox>
                  <Typography variant="body2" className="purchase-product-header__name">
                    {name}
                  </Typography>
                </FlexBox>
              </FlexBox>
            ),
          },
          {
            name: 'จำนวน',
            render: ({ amount }) => (
              <Typography color="textPrimary" variant="body2">
                {formatNumberInt(amount)}
              </Typography>
            ),
            align: 'right',
          },
          {
            name: 'ราคาต่อหน่วย',
            render: ({ price }) =>
              price ? (
                formatNumber(price)
              ) : (
                <Typography color="textSecondary" variant="body2">
                  ไม่ระบุ
                </Typography>
              ),
            align: 'right',
          },
        ]}
        data={products}
        pagination={false}
        cleanSpace
      />
    </Paper>
  )
}

export default PurchaseRequisitionProductInfoTable
