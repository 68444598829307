import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { FilteredTable, Topic, withLayout } from '../../../../custom-components'
import {
  Loading,
  PaymentTransactionStatusChip,
  SOPaymentTransactionDialog,
  StatementVerifiedSignal,
  StatementVerifyingSignal,
} from '../../../../shared-components'
import { useSOPaymentTransactions } from '../../../../hooks'
import { FetchSOPaymentTransaction } from '../../../../api/smartbestbuys-api'
import { dateFormat, dateTimeFormat } from '../../../../functions'
import { defaultStartDate, defaultEndDate } from '../../../../data'
import { RootContext } from '../../../..'
import { PageName, SOPaymentTransactionStatus } from '../../../../enums'
import lodash from 'lodash'
import { DocumentType } from '../../../../enums'

const SOPaymentTransactionsPage: React.FC = (props) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SO_PAYMENT)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [selected, setSelected] = useState<FetchSOPaymentTransaction | undefined>(undefined)
  const rawData = useSOPaymentTransactions(startDate, endDate)

  const [data, setData] = useState<FetchSOPaymentTransaction[]>([])

  useEffect(
    () => {
      if (rawData.length > 0) {
        setData(rawData)
      }
    }, // eslint-disable-next-line
    [rawData],
  )

  const paymentMethods = useMemo(() => {
    return lodash
      .chain(data)
      .uniqBy('paymentMethod.id')
      .orderBy('paymentMethod.id')
      .value()
      .map((doc) => {
        return {
          name: doc.paymentMethod.name,
          value: doc.paymentMethod.id,
        }
      })
    // eslint-disable-next-line
  }, [data.map((doc) => doc.code).toString()])

  const handleSelectTransaction = (transaction: FetchSOPaymentTransaction) => setSelected(transaction)
  const handleCloseDialog = () => setSelected(undefined)

  if (data.length === 0) return <Loading />

  return (
    <Box p={2}>
      {selected && (
        <SOPaymentTransactionDialog
          open={!!selected}
          onClose={handleCloseDialog}
          saleOrderId={selected.saleOrderInfo.id}
          transaction={selected}
          total={selected.saleOrderInfo.total}
          onConfirm={(updatedData) => {
            let newData = data
            const findIndex = newData.findIndex((d) => d.id === updatedData.id)
            if (findIndex !== -1) {
              newData[findIndex].status = updatedData.status
              setData([...newData])
            }
            setSelected(undefined)
          }}
          onVerify={(updatedData) => {
            let newData = data
            const findIndex = newData.findIndex((d) => d.id === updatedData.id)
            if (findIndex !== -1) {
              newData[findIndex].statementVerifiedAt = updatedData.statementVerifiedAt
              setData([...newData])
            }
            setSelected(undefined)
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>รายการรายได้</Topic>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<FetchSOPaymentTransaction>
              color="primary.dark"
              columns={[
                {
                  name: 'สถานะรายได้',
                  render: ({ status }) => <PaymentTransactionStatusChip status={status.id} label={status.name} />,
                },
                {
                  name: 'ตรวจสอบ Statememt',
                  render: (transaction) => {
                    const isApproved = transaction.status.id === SOPaymentTransactionStatus.APPROVED
                    const isVerifiedStatement = !!transaction.statementVerifiedAt

                    if (isApproved) {
                      return isVerifiedStatement ? <StatementVerifiedSignal /> : <StatementVerifyingSignal />
                    } else {
                      return <></>
                    }
                  },
                  fixedWidth: 100,
                },
                {
                  name: 'เลขที่การรับรายได้',
                  render: (transaction) => (
                    <Button color="primary" onClick={() => handleSelectTransaction(transaction)}>
                      {transaction.code}
                    </Button>
                  ),
                },
                {
                  name: 'เอกสารที่เกี่ยวข้อง',
                  render: ({ referenceDocuments }) =>
                    !!referenceDocuments && referenceDocuments.length > 0 ? (
                      referenceDocuments.map((ref) => {
                        return (
                          <div id={ref.code}>
                            <Typography variant="body2" color="secondary">
                              {getDocumentTypeName(ref.typeId)} {ref.code}
                            </Typography>
                          </div>
                        )
                      })
                    ) : (
                      <Typography color="textSecondary">-</Typography>
                    ),
                },
                {
                  name: 'วันที่บันทึก',
                  render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                },
                {
                  name: 'วันและเวลาที่เงินเข้าบัญชี',
                  render: ({ statementTimestamp }) => (statementTimestamp ? dateTimeFormat(statementTimestamp) : '-'),
                },
                { name: 'ชื่อลูกค้า', render: ({ customerInfo }) => customerInfo.name },
                { name: 'เบอร์โทรศัพท์', render: ({ customerInfo }) => customerInfo.phoneNumber },
                { name: 'วิธีชำระเงิน', render: ({ paymentMethod }) => paymentMethod.name },
                { name: 'ยอดรวมสุทธิ', dataIndex: 'amount', numeric: true, format: '0,0.00' },
              ]}
              data={data}
              defaultDateRage={{
                startDate: defaultStartDate,
                endDate: defaultEndDate,
              }}
              onDateChange={(startDate, endDate) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              filters={[
                {
                  name: 'วีธีการชำระเงิน',
                  values: paymentMethods,
                  filter: (data: FetchSOPaymentTransaction, values: (string | number)[]) =>
                    values.includes(data.paymentMethod.id),
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(SOPaymentTransactionsPage)

const getDocumentTypeName = (documentTypeId: number): string | undefined => {
  switch (documentTypeId) {
    case DocumentType.Invoice:
      return 'ใบแจ้งหนี้'
    case DocumentType.TaxInvoice:
      return 'ใบกำกับภาษี'
    case DocumentType.CreditNote:
      return 'ใบลดหนี้'
    default:
      return undefined
  }
}
