import { dateSqlFormat } from '../../../functions'
import { errorHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { FetchRequest } from '../types'
import { QuotationRowData } from './quotation-types'

export const fetchQuotations = errorHandler<QuotationRowData[]>(async (request: FetchRequest) => {
  const result = await smartAuthAPI.get('/quotations', {
    params: {
      startDate: dateSqlFormat(request.startDate),
      endDate: dateSqlFormat(request.endDate),
    },
  })
  return result
})
