import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import React, { ReactNode, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import {
  BaseData,
  createQuotationTakeNote,
  patchApproveQuotation,
  patchRejectQuotation,
  QuotationRejectRequest,
  QuotationTakeNoteRequest,
} from '../../../api/smartbestbuys-api'
import { FlexBox, Form, GeneralTextFieldForm, KeyboardDatePickerForm } from '../../../custom-components'
import { UserAction } from '../../../enums'
import { useActionMessages } from '../../../hooks'
import { ButtonRadios, SubmitForm, UserActivityValues } from '../../../shared-components'
import { QuotationsProfilePageContextImpl } from '../QuotationProfilePage/QuotationsProfilePage'

interface QuotationsUserActivityValues extends Omit<UserActivityValues, 'actionMessageId'> {
  nextFollowSchedule?: Date
  service: number
  actionMessageId?: number
}

interface QuotationsFollowCustomerDialogProps extends DialogProps {
  toggleReload: () => void
}

const QuotationsFollowCustomerDialog: React.FC<QuotationsFollowCustomerDialogProps> = (props) => {
  const { toggleReload, onClose, ...otherProps } = props

  const { triggerSnackbar } = useContext(RootContext)

  const { profile } = useContext(QuotationsProfilePageContextImpl)

  const history = useHistory()

  const {
    register,
    unregister,
    control,
    formState: { isSubmitting, errors },
    watch,
    setValue,
    reset,
    handleSubmit,
    clearErrors,
  } = useForm<QuotationsUserActivityValues>()

  const { actionId, nextFollowSchedule, actionMessageId } = watch()

  const actionMessages = useActionMessages(actionId || UserAction.CantContactCustomerQuotation)

  useEffect(() => {
    register('actionId', {
      required: { value: true, message: 'กรุณาเลือกวิธีติดตามลูกค้า' },
      valueAsNumber: true,
    })
    return () => {
      unregister('actionId')
    }
  }, [register, unregister])

  const userActions: Array<BaseData> = [
    { id: UserAction.ContactCustomerQuotation, name: 'รอลูกค้าอนุมัติ' },
    { id: UserAction.ConfirmQuotation, name: 'ลูกค้าอนุมัติแล้ว' },
    { id: UserAction.CustomerRejectQuotation, name: 'ลูกค้ายกเลิก' },
  ]

  const forceReset = () => {
    setValue('actionMessage', undefined)
    setValue('actionMessageId', undefined)
    setValue('remark', undefined)
    setValue('nextFollowSchedule', undefined)
  }

  const recommendNextFollowSchedule = DateTime.now().plus({ day: 7 }).toFormat('dd LLL yyyy', { locale: 'th' })

  const handleOnSubmit = async (value: QuotationsUserActivityValues) => {
    switch (value.actionId) {
      case UserAction.ContactCustomerQuotation:
        const quotationTakeNoteRequest: QuotationTakeNoteRequest = {
          actionId: value.actionId,
          nextFollowSchedule: value.nextFollowSchedule
            ? DateTime.fromJSDate(new Date(value.nextFollowSchedule)).toFormat('yyyy-MM-dd')
            : undefined,
          actionMessageId: value.actionMessageId === -1 ? undefined : value.actionMessageId,
          actionMessage: value.actionMessage,
          remark: value.remark,
        }
        const quotationTakeNoteResponse = await createQuotationTakeNote(profile.id, quotationTakeNoteRequest)
        if (quotationTakeNoteResponse?.status === 200) {
          reset()
          forceReset()
          triggerSnackbar('บันทึกการติดตามลูกค้าสำเร็จ')
          toggleReload()
          if (!!onClose) onClose({}, 'escapeKeyDown')
        } else {
          triggerSnackbar('บันทึกไม่สำเร็จ')
        }
        break
      case UserAction.ConfirmQuotation:
        const quotationApproveResponse = await patchApproveQuotation(profile.id)
        if (quotationApproveResponse?.status === 200) {
          reset()
          forceReset()
          triggerSnackbar('อนุมัติใบเสนอราคาสำเร็จ')
          toggleReload()
          if (!!onClose) onClose({}, 'escapeKeyDown')
          history.push(`/sale-orders/create?quotationId=${profile.id}`)
        } else {
          triggerSnackbar('อนุมัติใบเสนอราคาไม่สำเร็จ')
        }
        break
      case UserAction.CustomerRejectQuotation:
        const quotationRejectRequest: QuotationRejectRequest = {
          actionMessageId: value.actionMessageId === -1 ? undefined : value.actionMessageId,
          actionMessage: value.actionMessage,
          remark: value.remark,
        }
        const quotationRejectResponse = await patchRejectQuotation(profile.id, quotationRejectRequest)
        if (quotationRejectResponse?.status === 200) {
          reset()
          forceReset()
          triggerSnackbar('บันทึกการปฏิเสธลูกค้าสำเร็จ')
          toggleReload()
          if (!!onClose) onClose({}, 'escapeKeyDown')
        } else {
          triggerSnackbar('บันทึกไม่สำเร็จ')
        }
        break
    }
    return true
  }

  const handleOnCancel = () => {
    if (!!onClose) onClose({}, 'escapeKeyDown')
    reset()
  }

  const renderInputContent = (userAction?: UserAction): ReactNode => {
    switch (userAction) {
      case UserAction.CantContactCustomerQuotation:
      case UserAction.ContactCustomerQuotation:
        return (
          <>
            <GeneralTextFieldForm key="remark" control={control} label="หมายเหตุ (ถ้ามี)" name="remark" />
            <KeyboardDatePickerForm
              key="nextFollowSchedule"
              control={control}
              label="วันที่จะต้องติดตามต่อ (ถ้ามี)"
              name="nextFollowSchedule"
              value={nextFollowSchedule}
              size="small"
              fullWidth
              defaultValue={null}
            />
            <Box>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                {`วันที่ต้องตามต่อ(คำนวณโดยระบบ): ${recommendNextFollowSchedule}`}
              </Typography>
            </Box>
          </>
        )
      case UserAction.CustomerRequestQuotation:
        return (
          <>
            <GeneralTextFieldForm
              key="actionMessageId"
              control={control}
              name="actionMessageId"
              select
              label="บริการ"
              rules={{ required: { value: true, message: 'กรุณาเลือกบริการ' } }}
              variant="outlined"
              defaultValue=""
            >
              {actionMessages.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
              <MenuItem value={-1}>
                <Typography color="textSecondary">เพิ่มบริการใหม่</Typography>
              </MenuItem>
            </GeneralTextFieldForm>
            {actionMessageId === -1 && (
              <GeneralTextFieldForm
                key="actionMessage"
                control={control}
                label="บริการใหม่ *"
                name="actionMessage"
                rules={{ required: { value: true, message: 'กรุณาใส่บริการใหม่' } }}
              />
            )}
            <GeneralTextFieldForm key="remark" control={control} label="หมายเหตุ (ถ้ามี)" name="remark" />
            <KeyboardDatePickerForm
              key="nextFollowSchedule"
              control={control}
              label="วันที่จะต้องติดตามต่อ (ถ้ามี)"
              name="nextFollowSchedule"
              value={nextFollowSchedule || null}
              size="small"
              fullWidth
              defaultValue={null}
            />
            <Box>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                {`วันที่ต้องตามต่อ(คำนวณโดยระบบ): ${recommendNextFollowSchedule}`}
              </Typography>
            </Box>
          </>
        )
      case UserAction.CustomerRejectQuotation:
        return (
          <>
            <GeneralTextFieldForm
              key="actionMessageId"
              control={control}
              name="actionMessageId"
              select
              label="สาเหตุของการยกเลิก"
              rules={{ required: { value: true, message: 'กรุณาสาเหตุของการยกเลิก' } }}
              variant="outlined"
              defaultValue=""
            >
              {actionMessages.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
            <GeneralTextFieldForm key="remark" control={control} label="หมายเหตุ (ถ้ามี)" name="remark" />
          </>
        )
      case UserAction.ConfirmQuotation:
      default:
        return undefined
    }
  }

  return (
    <Dialog fullWidth maxWidth="xs" {...otherProps}>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle>ติดตามลูกค้า</DialogTitle>
        <DialogContent>
          <FlexBox flexDirection="column" gridGap={8}>
            <ButtonRadios
              onChange={(actionId) => {
                switch (actionId) {
                  case UserAction.CantContactCustomerQuotation:
                    break
                  case UserAction.ContactCustomerQuotation:
                    break
                  case UserAction.CustomerRequestQuotation:
                    break
                  case UserAction.ConfirmQuotation:
                    setValue('remark', undefined)
                    break
                  case UserAction.CustomerRejectQuotation:
                    setValue('nextFollowSchedule', undefined)
                    break
                  default:
                    break
                }
                setValue('actionMessage', undefined)
                setValue('actionMessageId', undefined)
                clearErrors('actionId')
                setValue('actionId', actionId)
              }}
              items={userActions}
              errorMessage={errors.actionId?.message}
            />
            {renderInputContent(actionId)}
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <SubmitForm
            submitText={'ยืนยัน'}
            cancelText={'ย้อนกลับ'}
            onCancel={handleOnCancel}
            isSubmitting={isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default QuotationsFollowCustomerDialog
