import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogProps } from '@material-ui/core'
import { Repeat } from '@material-ui/icons'
import { FlexBox } from '../../custom-components'

interface Props extends DialogProps {
  source?: string
  refInput?: React.MutableRefObject<HTMLInputElement | null>
  readOnly?: boolean
}

const PreviewImageDialog = (props: Props) => {
  const { onClose, source, readOnly, refInput, ...otherProps } = props

  return (
    <Dialog {...otherProps} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>ตัวอย่างรูปภาพ</DialogTitle>
      <DialogContent>
        <FlexBox justifyContent="center">
          <img src={source} alt="preview-upload" />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        {readOnly ? (
          <Button variant="contained" color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยืนยัน
          </Button>
        ) : (
          <>
            <Button color="primary" onClick={() => refInput?.current?.click()}>
              <Repeat /> เปลี่ยนรูป
            </Button>
            <Button variant="contained" color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
              รูปภาพถูกต้อง
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default PreviewImageDialog
