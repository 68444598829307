import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getTransportMessengerTaskAPI, IMessengerTaskMobileData } from '../../api/smartbestbuys-api'

const useTransportMessengerTask = (
  id: number,
): [IMessengerTaskMobileData | undefined, Dispatch<SetStateAction<IMessengerTaskMobileData | undefined>>] => {
  const [data, setData] = useState<IMessengerTaskMobileData | undefined>(undefined)

  useEffect(
    () => {
      ;(async () => {
        const response = await getTransportMessengerTaskAPI(id)
        const data = response?.data ?? undefined
        setData(data)
      })()
    }, // eslint-disable-next-line
    [id],
  )

  return [data, setData]
}

export default useTransportMessengerTask
