/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { createUserAPI } from '../../../api/smartbestbuys-api'
import { Form, Grid, Topic } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'
import { mapCreateFromValueToCreateUserRequest } from '../mapper'
import UserPermissionGroupBox from '../UserPermissionGroupBox'
import UserCreateFromValue from './UserCreateFromValue'
import UserCreateInfoBox from './UserCreateInfoBox'
import UserCreateLoginBox from './UserCreateLoginBox'
import { UserCreatePageContext } from './UserCreatePage'

const UserCreateForm: React.FC = (props) => {
  const { permissionGroups, permissionTopics } = useContext(UserCreatePageContext)
  const { handleSubmit, setError } = useFormContext<UserCreateFromValue>()

  const [selectedPermissionIds, setSelectedPermissionIds] = useState<number[]>([])
  const [selectedPermissionGroupIds, setSelectedPermissionGroupIds] = useState<number[]>([])

  const { triggerSnackbar } = useContext(RootContext)

  const history = useHistory()

  const scrollTo = (id: string, position?: ScrollLogicalPosition) => {
    const anchor = document.querySelector(id)

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: position || 'end' })
    }
  }

  const onHandleSubmit = async (values: UserCreateFromValue) => {
    const request = mapCreateFromValueToCreateUserRequest(
      values,
      selectedPermissionGroupIds,
      selectedPermissionIds,
      allPermissionIdInSelectedGroup,
    )

    const response: any = await createUserAPI(request)

    if (response?.status === 201) {
      triggerSnackbar('สร้างผู้ใช้งานสำเร็จ')
      history.replace(`/users`)
    } else {
      triggerSnackbar('สร้างผู้ใช้งานไม่สำเร็จ')
      setError('username', {
        type: 'required',
        message: response?.message,
      })
      scrollTo('#user-login-box')
    }
  }

  const allPermissionIdInSelectedGroup = useMemo(() => {
    return permissionGroups.reduce<number[]>((previousValue, currentValue) => {
      if (selectedPermissionGroupIds.includes(currentValue.id)) {
        return [...previousValue, ...(currentValue.permissionIds ?? [])]
      } else {
        return previousValue
      }
    }, [])
  }, [selectedPermissionGroupIds.length])

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Topic>เพิ่มผู้ใช้</Topic>
        </Grid>
        <Grid item xs={12}>
          <UserCreateInfoBox />
        </Grid>
        <Grid item xs={12}>
          <UserCreateLoginBox />
        </Grid>
        <Grid item xs={12}>
          <UserPermissionGroupBox
            permissionGroups={permissionGroups}
            permissionTopics={permissionTopics}
            selectedPermissionGroupIds={selectedPermissionGroupIds}
            selectedPermissionIds={selectedPermissionIds}
            onPermissionGroupSelect={setSelectedPermissionGroupIds}
            onPermissionSelect={setSelectedPermissionIds}
            allPermissionIdInSelectedGroup={allPermissionIdInSelectedGroup}
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitForm submitText={'ยืนยันเพิ่มผู้ใช้'} cancelText="ย้อนกลับ" />
        </Grid>
      </Grid>
    </Form>
  )
}

export default UserCreateForm
