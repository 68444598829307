import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    accordion: {
      padding: theme.spacing(1),
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  }),
)
