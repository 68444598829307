import { useContext, useMemo, useState } from 'react'
import { Box, Paper } from '@material-ui/core'
import { TransportShipingTaskMobileData } from '../../../api/smartbestbuys-api'
import { FilteredTable, FlexBox, Topic, withLayout } from '../../../custom-components'
import { NavigationItemKey, PageName, TaskStatus } from '../../../enums'
import { NotificationHead, Space, TypographyLink } from '../../../shared-components'
import { formatNumberInt } from '../../../functions/number-utils'
import { RootContext } from '../../..'
import { useTransportShippingTasks } from '../../../hooks/transport/useTransportShippingTasks'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'

const MessengerTaskListPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.MESSENGER_TASK)

  // state
  const [dueDate, setDueDate] = useState<Date>(DateTime.now().toJSDate())
  const [tabValue, setTabValue] = useState<TaskStatus>(TaskStatus.Pending)

  const [subTasks] = useTransportShippingTasks(dueDate)

  const pendingAmount = useMemo(
    () => subTasks.filter((t) => t.status.id === TaskStatus.Pending).length,
    // eslint-disable-next-line
    [subTasks.map((t) => t.status.id).join(',')],
  )

  return (
    <Box p={3}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Topic>รายการงานส่งของ</Topic>
        <NotificationHead navigationKey={NavigationItemKey.ShippingTask} />
        <KeyboardDatePicker
          size="small"
          inputVariant="outlined"
          value={dueDate}
          defaultValue={null}
          onChange={(newDate) => {
            if (newDate) setDueDate(newDate)
          }}
        />
      </FlexBox>
      <Space />
      <Paper>
        <FilteredTable<TransportShipingTaskMobileData>
          columns={[
            {
              name: 'เลขที่งานขนส่ง',
              dataIndex: 'code',
              sort: true,
              fixedWidth: 170,
              render: (task) => (
                <TypographyLink
                  variant="body2"
                  color="primary"
                  href={`/transport/shipping-tasks/${task.id}`}
                  target="_blank"
                >
                  {task.code}
                </TypographyLink>
              ),
            },
            {
              name: 'ใบสั่งขายอ้างอิง',
              fixedWidth: 170,
              render: (task) => {
                const { saleOrderInfo } = task
                if (!saleOrderInfo) return 'loading...'
                return (
                  <TypographyLink
                    variant="body2"
                    color="secondary.dark"
                    href={`/sale-orders/${saleOrderInfo.saleOrderId}/profile`}
                    target="_blank"
                  >
                    {saleOrderInfo.saleOrderCode}
                  </TypographyLink>
                )
              },
            },
            { name: 'ชื่อลูกค้า', render: (task) => task.saleOrderInfo?.customerName },
            { name: 'ชื่อผู้รับ', render: (task) => task.shippingInfo.consignee.name },
            { name: 'เบอร์โทรศัพท์', fixedWidth: 170, render: (task) => task.shippingInfo.consignee.phoneNumber },
          ]}
          data={subTasks.filter((t) => t.status.id === tabValue)}
          sortable
          disableDateRangeFilter
          color="primary.dark"
          cleanSpace
          tabOption={{
            tabs: [
              {
                label: `งานส่งที่ต้องส่ง (${formatNumberInt(pendingAmount)})`,
                value: TaskStatus.Pending,
              },
              { label: 'กำลังดำเนินการ', value: TaskStatus.Processing },
              { label: 'ส่งสำเร็จ', value: TaskStatus.Done },
              { label: 'ส่งไม่สำเร็จ', value: TaskStatus.Failed },
              { label: 'ถูกยกเลิก', value: TaskStatus.PreCancel },
              { label: 'งานที่ยกเลิก', value: TaskStatus.Canceled },
            ],
            value: tabValue,
            onChange: (_, newValue) => setTabValue(newValue),
          }}
        />
      </Paper>
    </Box>
  )
}

export default withLayout(MessengerTaskListPage)
