import { useEffect, useState } from 'react'
import { getWarehouseTaskV2API, NewWarehouseTask } from '../../api/smartbestbuys-api'

export const useWarehouseTaskV2 = (id: number, typeId?: number) => {
  const [data, setData] = useState<NewWarehouseTask | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getWarehouseTaskV2API(id, typeId)
      setData(response?.data)
    })()
  }, [id, typeId])

  return data
}
