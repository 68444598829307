import { Button, Grid, Paper } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { EmptyCard, FlexBox, SubTopic } from '../../../../../../custom-components'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../../../../shared-components'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import CustomerProfileContext from '../../CustomerProfileContext'
import { CustomerAddressList } from '../CustomerAddressBox'
import CustomerShippingAddressCreationDialog from './CustomerShippingAddressCreationDialog/CustomerShippingAddressCreationDialog'

const CustomerShippingAddressBox: React.FC = () => {
  const [createCustomerContact, setCreateCustomerContact] = useState(false)

  const { customerProfile } = useContext(CustomerProfileContext)

  const { shippingAddresses, recDeletedAt } = customerProfile

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Write)

  const isActiveCustomer = !recDeletedAt

  const disableWrite = !writePermission.hasPermission || !isActiveCustomer

  return (
    <>
      <CustomerShippingAddressCreationDialog
        open={createCustomerContact}
        onClose={() => {
          setCreateCustomerContact(false)
        }}
      />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SubTopic>ที่อยู่สำหรับขนส่ง</SubTopic>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexBox justifyContent="flex-end">
              <ConditionTooltip
                title={
                  !writePermission.hasPermission ? writePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
                }
                showTooltip={disableWrite}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setCreateCustomerContact(true)}
                  disabled={disableWrite}
                >
                  + เพิ่มที่อยู่สำหรับขนส่ง
                </Button>
              </ConditionTooltip>
            </FlexBox>
          </Grid>
          {shippingAddresses && shippingAddresses.length > 0 ? (
            <Grid item xs={12}>
              <CustomerAddressList addresses={shippingAddresses} isActiveCustomer={isActiveCustomer} type="shipping" />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <EmptyCard emptyText="ยังไม่มีที่อยู่ขนส่ง" />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default CustomerShippingAddressBox
