import { Typography } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { Form, Grid } from '../../../custom-components'
import { SubmitForm } from '../../../shared-components'

interface Props {
  enabled: boolean
  onSubmit: (values: UserActivationUpdateFormValues) => void
  onCancel: () => void
}

export interface UserActivationUpdateFormValues {
  enabled: boolean
}

export const UserActivationUpdateForm: React.FC<Props> = (props) => {
  const { enabled, onSubmit, onCancel } = props
  const formMethod = useForm<UserActivationUpdateFormValues>({
    defaultValues: { enabled },
  })
  const {
    setValue,
    formState: { isSubmitting },
  } = formMethod

  return (
    <FormProvider {...formMethod}>
      <Form onSubmit={(value) => onSubmit(value)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {enabled ? (
              <Typography color="textSecondary">ยืนยันปิดการใช้งานผู้ใช้นี้ใช่หรือไม่?</Typography>
            ) : (
              <Typography color="textSecondary">ยืนยันเปิดการใช้งานผู้ใช้นี้ใช่หรือไม่?</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SubmitForm
            onSubmit={() => setValue('enabled', !enabled)}
            submitText="ยืนยัน"
            cancelText="ย้อนกลับ"
            disableSubmit={isSubmitting}
            onCancel={onCancel}
          />
        </Grid>
      </Form>
    </FormProvider>
  )
}
