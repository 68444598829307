import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { useForm } from 'react-hook-form'
import { Form, GeneralTextFieldForm } from '../../../custom-components'
import { partialUpdateQuotation } from '../../../api/smartbestbuys-api'
import { useUserData } from '../../../hooks'

interface QuotationPartialUpdateAssigneeDialogFromValues {
  assigneeUserId: number
}

interface Props extends DialogProps {
  quotation: {
    id: number
    assigneeUserId?: number
  }
  onComplete?: () => void
}

const QuotationUpdateAssigneeDialog: React.FC<Props> = (props: Props) => {
  const { quotation, onComplete, onClose, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)

  const users = useUserData()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<QuotationPartialUpdateAssigneeDialogFromValues>({
    defaultValues: {
      assigneeUserId: quotation.assigneeUserId,
    },
  })

  const handleSubmitQuotationPartialUpdateForm = async (value: QuotationPartialUpdateAssigneeDialogFromValues) => {
    const request = {
      assigneeUserId: value.assigneeUserId,
    }

    const response = await partialUpdateQuotation(quotation.id, request)
    if (response?.status === 200) {
      triggerSnackbar('แก้ไขผู้เสนอราคาสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('แก้ไขผู้เสนอราคาไม่สำเร็จ')
      if (!!onClose) onClose({}, 'escapeKeyDown')
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitQuotationPartialUpdateForm)}>
        <DialogTitle>แก้ไขผู้เสนอราคา</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm control={control} name="assigneeUserId" defaultValue="" label="ผู้เสนอราคา" select>
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.firstName + ' ' + user.lastName}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ปิด
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            บันทึก
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default QuotationUpdateAssigneeDialog
