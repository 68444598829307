export const banks = [
  { id: 1, name: 'กสิกรไทย' },
  { id: 2, name: 'กรุงเทพ' },
  { id: 3, name: 'กรุงไทย' },
  { id: 4, name: 'กรุงศรี' },
  { id: 5, name: 'ซีไอเอมบี' },
  { id: 6, name: 'ทหารไทย' },
  { id: 7, name: 'ไทยพาณิชย์' },
  { id: 8, name: 'ยูโอบี' },
  { id: 9, name: 'แลนด์ แอนด์ เฮ้าส์' },
  { id: 10, name: 'แสตนดาร์ดฯ' },
  { id: 11, name: 'ธนชาต' },
  { id: 12, name: 'ออมสิน' },
  { id: 13, name: 'เกียรตินาคิน' },
  { id: 14, name: 'ซิตี้แบงค์' },
  { id: 15, name: 'อาคารสงเคราะห์' },
  { id: 16, name: 'ธ.ก.ส.' },
  { id: 17, name: 'มิซูโฮ' },
  { id: 18, name: 'ทิสโก้' },
  { id: 19, name: 'ไอซีบีซี (ไทย)' },
  { id: 20, name: 'ไทยเครดิต' },
  { id: 21, name: 'ซูมิโตโม มิตซุย' },
  { id: 22, name: 'เอชเอสบีซี' },
  { id: 23, name: 'ธนาคารแห่งอเมริกา เนชั่นแนล แอสโซซิเอชั่น' },
  { id: 24, name: 'ธนาคารดอยซ์แบงก์' },
  { id: 25, name: 'ธนาคาร เมกะ สากลพาณิชย์ จำกัด (มหาชน)' },
  { id: 26, name: 'EXIM Thailand ธนาคารเพื่อการส่งออกและนำเข้าประเทศไทย' },
  { id: 27, name: 'พัฒนาวิสาหกิจ' },
  { id: 28, name: 'ธนาคารไทยเครดิต' },
  { id: 29, name: 'ธนาคารทหารไทยธนชาต (ttb)' },
  { id: 30, name: 'KEB Hana Bank' },
]
