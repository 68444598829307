import React, { useContext } from 'react'
import { Paper, Grid, TableRow, TableCell, Typography, Avatar, Button, Box } from '@material-ui/core'
import { FlexBox, SubTopic, Table } from '../../../../../custom-components'
import { Loading } from '../../../../../shared-components'
import SaleOrderProfileContext from '../SaleOrderProfileContext'
import { SaleOrderProduct } from '../../../../../api/smartbestbuys-api'
import numeral from 'numeral'
import { getSOPricebreakdown } from '../../../../../functions'
import { Description, Photo } from '@material-ui/icons'
import './SOProductsTable.less'
import { calculatePercent, formatNumberInt } from '../../../../../functions/number-utils'
import { ProductType, SaleOrderStatus } from '../../../../../enums'
import { useHistory } from 'react-router-dom'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'

const SOProductsTable: React.FC = (props) => {
  const { saleOrderProfile /* insufficientProduct */ } = useContext(SaleOrderProfileContext)
  const history = useHistory()
  if (!saleOrderProfile) {
    return <Loading />
  }

  //calculate
  const soStatusGroupNotOpenPR = [SaleOrderStatus.Closed, SaleOrderStatus.ClosedAtShop, SaleOrderStatus.Cancelled]
  const showedCreatePurchaseRequisitionButton =
    !soStatusGroupNotOpenPR.includes(saleOrderProfile.status.id) &&
    (!saleOrderProfile.purchaseRequisitions || saleOrderProfile.purchaseRequisitions.length === 0)
  // TODO: uncomment after refactor product system
  // && insufficientProduct
  const priceBreakdowns = getSOPricebreakdown(saleOrderProfile)

  // permission
  const pricingPermission = getUserPermissionInfo(PermissionEnum.Sales_SO_Pricing)
  const saleProfitRead = getUserPermissionInfo(PermissionEnum.Sales_Profit_Read)

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <SubTopic>ข้อมูลสินค้า/บริการ</SubTopic>
            {showedCreatePurchaseRequisitionButton && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Description />}
                onClick={() => history.push(`/sale-orders/${saleOrderProfile.id}/create-purchase-requisition`)}
              >
                สร้างคำขอชื้อ
              </Button>
            )}
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Table<SaleOrderProduct>
            color="primary.dark"
            size="small"
            columns={[
              {
                name: 'ลำดับ',
                render: (_, index) => index + 1,
              },
              {
                name: 'ชื่อสินค้า',
                render: (product) => (
                  <div className="sale-order-products-table__product-cell" key={'product-' + product.id}>
                    <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                      <Photo />
                    </Avatar>
                    <div className="sale-order-products-table__product-cell__info">
                      <Typography color="textSecondary">{product.code}</Typography>
                      <Typography>{product.nameLocal}</Typography>
                    </div>
                  </div>
                ),
              },
              {
                name: 'พร้อมขาย',
                numeric: true,
                render: ({ stockAmount, allReservedAmount, amount, reservedAmount, shippedAmount, productTypeId }) => {
                  const readyToSellAmount = stockAmount - allReservedAmount
                  const pendingToShipAmount = amount - reservedAmount - shippedAmount

                  if (productTypeId === ProductType.Service)
                    return (
                      <Typography variant="body2" color="textSecondary">
                        งานบริการ
                      </Typography>
                    )

                  if (readyToSellAmount < pendingToShipAmount) {
                    return (
                      <Typography variant="body2" color="error">
                        {formatNumberInt(readyToSellAmount)} (ไม่พอ)
                      </Typography>
                    )
                  }
                  return formatNumberInt(readyToSellAmount)
                },
              },
              {
                name: 'ราคาต่อหน่วย',
                numeric: true,
                format: '0,0.00',
                dataIndex: 'price',
                hide: !pricingPermission.hasPermission,
              },
              {
                name: 'จำนวนขาย',
                numeric: true,
                format: '0,0',
                dataIndex: 'amount',
              },
              {
                name: 'จำนวนที่ส่งให้ลูกค้าแล้ว',
                numeric: true,
                format: '0,0',
                dataIndex: 'shippedAmount',
              },
              {
                name: 'ราคารวม',
                numeric: true,
                format: '0,0.00',
                render: (product) => product.amount * product.price,
                hide: !pricingPermission.hasPermission,
              },
              {
                name: 'กำไร/ขาดทุน',
                dataIndex: 'totalSellCost',
                numeric: true,
                hide: !saleProfitRead.hasPermission,
                render: (product) => {
                  const _totalCost = product.totalSellCost ?? 0
                  if (!_totalCost || product.shippedAmount < product.amount)
                    return <span className="text-gray-500">คำนวณไม่ได้</span>
                  const _totalSell = product.amount * product.price
                  const _pnl = _totalSell - _totalCost
                  if (_pnl > 0) {
                    return (
                      <Box color="success.main" clone>
                        <Typography variant="body2">+{calculatePercent(_totalSell, _totalCost)}</Typography>
                      </Box>
                    )
                  } else {
                    return (
                      <Typography variant="body2" color="error">
                        {calculatePercent(_totalSell, _totalCost)}
                      </Typography>
                    )
                  }
                },
              },
            ]}
            data={saleOrderProfile.products}
            postCells={
              <>
                {pricingPermission.hasPermission &&
                  priceBreakdowns
                    .filter((price) => {
                      return price.amount > 0 || !['ส่วนลด', 'ค่าจัดส่ง'].includes(price.name)
                    })
                    .map((price) => (
                      <TableRow key={price.name}>
                        <TableCell colSpan={5} />
                        <TableCell align="right">{price.name}</TableCell>
                        <TableCell align="right">{numeral(price.amount).format('0,0.00')}</TableCell>
                      </TableRow>
                    ))}
              </>
            }
            pagination={false}
            cleanSpace
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SOProductsTable
