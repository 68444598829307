import { FormProvider, useForm } from 'react-hook-form'
import { Dialog, DialogTitle, DialogContent, Grid, DialogProps, DialogActions, InputAdornment } from '@material-ui/core'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  KeyboardDateTimePickerForm,
  TypographyWithLabel,
} from '../../../custom-components'
import { SubmitForm, UploadImageCard } from '../../../shared-components'

import { formatNumber } from '../../../functions/number-utils'
import { PaymentRequisitionProfile } from '../../../api/smartbestbuys-api/payment-requisitions/types'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { uploadPaymentRequisitionStatement } from '../../../api/smartbestbuys-api'

interface ConfirmStatementFormValues {
  base64?: string
  withdrawalAt: string
  withdrawalAmount: number
}

interface PaymentRequisitionInfo {
  id: number
  finalTotal: number
}

interface ConfirmStatementDialogProps extends DialogProps {
  paymentRequisitionInfo: PaymentRequisitionInfo
  onSuccess?: (paymentRequisitionProfile: PaymentRequisitionProfile) => void
  onFailed?: () => void
}

const ConfirmStatementDialog = (props: ConfirmStatementDialogProps) => {
  // root context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { paymentRequisitionInfo, onSuccess, onFailed, onClose, ...otherProps } = props

  // form hook
  const formMethod = useForm<ConfirmStatementFormValues>()
  const {
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = formMethod

  // prepare data
  const { finalTotal } = paymentRequisitionInfo
  const { withdrawalAt } = watch()

  // handle
  const handleSubmit = async (values: ConfirmStatementFormValues) => {
    const response = await uploadPaymentRequisitionStatement(paymentRequisitionInfo.id, values)
    if (response?.status === 200) {
      triggerSnackbar('ตรวจสอบรายการธนาคารสำเร็จ')
      if (!!onSuccess) onSuccess(response.data)
    } else {
      triggerSnackbar('ตรวจสอบรายการธนาคารไม่สำเร็จ')
      if (!!onFailed) onFailed()
    }
    return true
  }

  return (
    <FormProvider {...formMethod}>
      <Dialog {...otherProps} fullWidth maxWidth="sm">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>ตรวจสอบ Statement</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="จำนวนเงินที่ต้องจ่าย:">{formatNumber(finalTotal)} บาท</TypographyWithLabel>
              </Grid>
              <Grid item xs={12}>
                <FlexBox justifyContent="center">
                  <UploadImageCard
                    previewOnChange
                    title="อัปโหลดรูปหลักฐาน (ถ้ามี)"
                    onChange={(image) => setValue('base64', image.base64)}
                    error={!!errors.base64}
                    message={errors.base64?.message}
                  />
                </FlexBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyboardDateTimePickerForm
                  control={control}
                  label="วัน-เวลาที่เงินออกจากบัญชี *"
                  name="withdrawalAt"
                  value={withdrawalAt}
                  defaultValue={null}
                  size="small"
                  gutterTop
                  fullWidth
                  rules={{
                    required: 'กรุณาระบุวันเวลา',
                  }}
                  error={!!errors.withdrawalAt}
                  helperText={errors.withdrawalAt?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="withdrawalAmount"
                  label="จำนวนเงินจาก statement *"
                  type="number"
                  inputProps={{
                    step: '0.01',
                  }}
                  rules={{
                    required: 'กรุณากรอกจำนวนเงิน',
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                  }}
                  error={!!errors.withdrawalAmount}
                  helperText={!!errors.withdrawalAmount && errors.withdrawalAmount.message}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitForm
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  )
}
export default ConfirmStatementDialog
