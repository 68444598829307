import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import CloseIcon from '@material-ui/icons/Close'

import { Avatar, FlexBox } from '../../../../custom-components'

import './FileCard.less'
import { Typography } from '@material-ui/core'
import { FileType, SourceFile } from '../../../../types'

interface Props {
  value: SourceFile
  onRemove?: () => void
  readonly?: boolean
}

const FileCard: React.FC<Props> = (props: Props) => {
  const { value, onRemove, readonly } = props
  const { url, base64, type, name } = value

  return (
    <FlexBox flexDirection="column" alignItems="center" className="file-card">
      {type === FileType.Image ? (
        <Avatar
          style={{
            width: 120,
            height: 120,
          }}
          className="mt-3"
          src={url ?? base64}
          variant="rounded"
        />
      ) : (
        <PictureAsPdfIcon
          className="mt-3"
          style={{
            width: 120,
            height: 120,
          }}
        />
      )}
      {!!onRemove && !readonly && <CloseIcon className="file-card__close-icon" onClick={onRemove} />}
      <Typography variant="subtitle2" align="center" display="block" className="pt-3 px-2 break-all overflow-hidden">
        {name}
      </Typography>
    </FlexBox>
  )
}

export default FileCard
