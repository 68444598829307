import React, { useContext } from 'react'
import { DialogProps, Typography, Box, MenuItem } from '@material-ui/core'
import { ConfirmationDialog } from '../../ConfirmationDialog'
import { FlexBox, GeneralTextFieldForm } from '../../../custom-components'

import { SOPaymentTransaction, UpdatedSOPaymentTransactionStatus } from '../../../api/smartbestbuys-api'
import { useForm } from 'react-hook-form'
import { UserAction } from '../../../enums'
import { useActionMessages } from '../../../hooks'
import { rejectPaymentTransaction } from './service'
import { RootContext } from '../../..'

interface Props extends DialogProps {
  transaction: SOPaymentTransaction
  saleOrderId: number
  onConfirm?: (data: UpdatedSOPaymentTransactionStatus) => void
  userAction: UserAction.SOCancelPaymentTransaction | UserAction.SODeclinePaymentTransaction
}

export interface RejectFormValues {
  actionMessageId?: number
  actionMessage?: string
}

const SOPaymentRejectDialog: React.FC<Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { transaction, saleOrderId, onConfirm, userAction, onClose, ...dialogProps } = props
  const { id, code } = transaction
  const text = userAction === UserAction.SOCancelPaymentTransaction ? 'ยกเลิก' : 'ไม่อนุมัติ'
  const messages = useActionMessages(userAction)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm<RejectFormValues>()

  const onSubmit = async (values: RejectFormValues) => {
    const response = await rejectPaymentTransaction(saleOrderId, id, userAction, values)
    if (response?.status === 200) {
      triggerSnackbar(`${text}เงินเลขที่ ${code} `)
      onConfirm && onConfirm(response.data)
      onClose && onClose({}, 'escapeKeyDown')
    } else {
      triggerSnackbar(`ไม่สามารถ${text}เงินเลขที่ ${code} ได้`)
    }
    return true
  }

  const { actionMessageId } = watch()
  // console.log(watch(), errors)

  return (
    <ConfirmationDialog
      title={`ยืนยัน${text}การชำระเงิน`}
      textOk={`ยืนยัน${text}`}
      disabledCloseOnDim
      isSubmitting={isSubmitting}
      onOk={handleSubmit(onSubmit)}
      onClose={onClose}
      {...dialogProps}
    >
      <FlexBox flexDirection="column" gridGap={8}>
        <Typography variant="body1" color="textSecondary">
          <Box component="span" color="error.dark">
            {text}
          </Box>{' '}
          การจ่ายเงินหมายเลข {code} นี้ใช่หรือไม่?
        </Typography>
        <GeneralTextFieldForm
          control={control}
          name="actionMessageId"
          select
          label="สาเหตุ *"
          rules={{ required: { value: true, message: 'กรุณาเลือกสาเหตุ' } }}
          variant="outlined"
        >
          {messages.map((message) => (
            <MenuItem key={message.id} value={message.id}>
              {message.name}
            </MenuItem>
          ))}
          <MenuItem value={-1}>
            <Typography color="textSecondary">เพิ่มสาเหตุใหม่</Typography>
          </MenuItem>
        </GeneralTextFieldForm>
        {actionMessageId === -1 && (
          <GeneralTextFieldForm
            control={control}
            label="เหตุผลใหม่ *"
            name="actionMessage"
            rules={{ required: { value: true, message: 'กรุณาใส่เหตุผลใหม่' } }}
          />
        )}
      </FlexBox>
    </ConfirmationDialog>
  )
}

export default SOPaymentRejectDialog
