export enum CustomerRequisitionTypeId {
  Claim = 1,
  CanceledSO = 2,
}

export enum CustomerRequisitionProductTypeSuffix {
  Claim = 'A',
  CanceledSO = 'B',
}

export const CustomerRequisitionTypes = [
  { id: CustomerRequisitionTypeId.Claim, name: 'เคลม', value: CustomerRequisitionTypeId.Claim },
  { id: CustomerRequisitionTypeId.CanceledSO, name: 'คืน', value: CustomerRequisitionTypeId.CanceledSO },
]
