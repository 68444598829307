import { useState } from 'react'
import { Box, Grid, IconButton, Paper } from '@material-ui/core'
import {
  PaymentRequisitionProfile,
  PaymentRequisitionState,
  PaymentRequisitionState_PayslipInfo,
} from '../../../api/smartbestbuys-api/payment-requisitions/types'
import { BaseData } from '../../../api/smartbestbuys-api/types'
import { VendorBankAccount } from '../../../api/smartbestbuys-api/vendor/vendor-types'
import { EmptyCard, Title, TypographyWithLabel } from '../../../custom-components'
import { PaymentMethod, PaymentRequisitionStatus } from '../../../enums'
import { dateTimeFormat } from '../../../functions/date-formatter'
import { formatNumber } from '../../../functions/number-utils'
import { ConditionTooltip, ImageCard } from '../../../shared-components'
import UploadPurchasePayslipDialog from './UploadPurchasePayslipDialog'
import { Edit } from '@material-ui/icons'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const blacklistedStatusToUploadPayslip = [
  PaymentRequisitionStatus.Success,
  PaymentRequisitionStatus.Rejected,
  PaymentRequisitionStatus.Canceled,
]

interface PaymentRequisitionInfo {
  id: number
  status: BaseData
  paymentMethod: BaseData
  state?: PaymentRequisitionState
  vendorInfo: { bankAccount: VendorBankAccount }
  total: number
  withholdingTaxAmount?: number
}

interface Props {
  paymentRequisitionInfo: PaymentRequisitionInfo
  // TODO: should not return whole profile
  onUploadPaySlipSuccess?: (data: PaymentRequisitionProfile) => void
}

const PayslipBox = (props: Props) => {
  // props
  const { paymentRequisitionInfo, onUploadPaySlipSuccess } = props

  // state
  const [uploadPaySlipOpen, setUploadPaySlipOpen] = useState(false)

  const paymentMethodId = paymentRequisitionInfo.paymentMethod.id
  const payslipInfo = paymentRequisitionInfo.state?.payslipInfo

  const showedReUploadPaySlip =
    !blacklistedStatusToUploadPayslip.includes(paymentRequisitionInfo.status.id) && !!payslipInfo

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Write)

  return (
    <Box p={3} clone>
      <Paper>
        <UploadPurchasePayslipDialog
          open={uploadPaySlipOpen}
          paymentRequisitionInfo={{
            id: paymentRequisitionInfo.id,
            paymentMethod: paymentRequisitionInfo.paymentMethod,
            vendorInfo: paymentRequisitionInfo.vendorInfo,
            total: paymentRequisitionInfo.total,
            withholdingTaxAmount: paymentRequisitionInfo.withholdingTaxAmount,
          }}
          onSuccess={(data) => {
            if (!!onUploadPaySlipSuccess) onUploadPaySlipSuccess(data)
            setUploadPaySlipOpen(false)
          }}
          onClose={() => setUploadPaySlipOpen(false)}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} container justify="space-between">
            <Title>{paymentMethodId === PaymentMethod.Transfer ? 'หลักฐานการโอนเงิน' : 'หลักฐานการออกเช็ค'}</Title>
            {showedReUploadPaySlip && (
              <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
                <IconButton
                  disabled={!writePermission.hasPermission}
                  aria-label="re upload payslip"
                  color="secondary"
                  onClick={() => setUploadPaySlipOpen(true)}
                >
                  <Edit />
                </IconButton>
              </ConditionTooltip>
            )}
          </Grid>
          <Grid item xs={12}>
            {payslipInfo ? (
              <Grid container spacing={1}>
                <Grid item xs={12} container justify="center">
                  <ImageCard size="big" name="payslip" src={payslipInfo.payslipImageUrl} />
                </Grid>
                {buildFields(paymentMethodId, payslipInfo).map((field) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <TypographyWithLabel label={field.label}>{field.value}</TypographyWithLabel>
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <EmptyCard error emptyText="ยังไม่ได้อัปโหลดหลักฐานการออกเช็ค" />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default PayslipBox

const buildFields = (paymentMethodId: PaymentMethod, payslipInfo: PaymentRequisitionState_PayslipInfo) => {
  const { bankAccount } = payslipInfo
  return paymentMethodId === PaymentMethod.Transfer
    ? [
        { label: 'วัน-เวลาที่โอนเงิน:', value: dateTimeFormat(payslipInfo.payDate) },
        { label: 'จำนวนเงิน:', value: formatNumber(payslipInfo.total) + ' บาท' },
        {
          label: 'ธนาคารที่ใช้โอน:',
          value: bankAccount ? `${bankAccount.number} (${bankAccount.bankName})` : 'ข้อมูลเก่า',
        },
        { label: 'หมายเหตุ:', value: payslipInfo.remark ?? '-' },
      ]
    : [
        { label: 'เลขที่เช็ค:', value: payslipInfo.chequeNumber },
        { label: 'จำนวนเงิน:', value: formatNumber(payslipInfo.total) + ' บาท' },
        { label: 'สาขา:', value: payslipInfo.branchName },
        { label: 'วันที่ออกเช็ค:', value: dateTimeFormat(payslipInfo.payDate) },
        { label: 'หมายเหตุ:', value: payslipInfo.remark ?? '-' },
      ]
}
