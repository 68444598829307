import { useState, useEffect } from 'react'
import { getRefundRequisitionProfile, IRefundRequisitionProfile } from '../../api/smartbestbuys-api'

export const useRefundRequisitionProfile = (
  refundRequisitionId: number,
): [IRefundRequisitionProfile | undefined, (data: IRefundRequisitionProfile | undefined) => void] => {
  const [data, setData] = useState<IRefundRequisitionProfile | undefined>(undefined)

  const _fetchRefundRequisitions = async (refundRequisitionId: number) => {
    const response = await getRefundRequisitionProfile(refundRequisitionId)
    setData(response?.data)
  }

  useEffect(() => {
    _fetchRefundRequisitions(refundRequisitionId)
  }, [refundRequisitionId])

  return [data, setData]
}
