import { useState, useEffect } from 'react'
import { getTransportTaskUpdateDataAPI, TransportTaskUpdateData } from '../../api/smartbestbuys-api'

export const useTransportTaskUpdateData = (taskId: number): TransportTaskUpdateData | undefined => {
  const [data, setData] = useState<TransportTaskUpdateData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getTransportTaskUpdateDataAPI(taskId)
      if (response?.data) {
        setData(new TransportTaskUpdateData(response.data))
      }
    })()
  }, [taskId])

  return data
}
