import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { RootContext } from '../../../..'
import { createVendorBankAccount, VendorCreateBankAccountRequestBody } from '../../../../api/smartbestbuys-api'
import { FlexBox, Form, GeneralTextFieldForm } from '../../../../custom-components'
import { banks } from '../../../../data'
import { Space } from '../../../../shared-components'

interface VendorBankAccountCreateFormValues {
  bankId: number
  accountName: string
  accountNumber: string
  branchName: string
}

interface VendorBankAccountCreateFormDialogProps extends DialogProps {
  vendorId: number
  onComplete?: () => void
  onFail?: () => void
}

const VendorBankAccountCreateFormDialog = (props: VendorBankAccountCreateFormDialogProps) => {
  // context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { vendorId, onClose, onComplete, onFail, ...dialogProps } = props

  // hook form
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<VendorBankAccountCreateFormValues>()

  // handler
  const handleFormSubmit = async (values: VendorBankAccountCreateFormValues) => {
    const request: VendorCreateBankAccountRequestBody = {
      bankId: values.bankId,
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      branchName: values.branchName,
    }

    const response = await createVendorBankAccount(vendorId, request)
    if (response?.status === 201) {
      triggerSnackbar('เพิ่มข้อมูลบัญชีธนาคารสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('เพิ่มข้อมูลบัญชีธนาคารไม่สำเร็จ')
      if (!!onFail) onFail()
    }
    return true
  }

  return (
    <Dialog {...dialogProps} maxWidth="md">
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        {/* TODO: change to เพิ่มผู้ติดต่อ when support multiple contact */}
        <DialogTitle>เพิ่มบัญชีธนาคาร</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="accountName"
                label="ชื่อบัญชี *"
                rules={{ required: 'กรุณากรอกชื่อบัญชี' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="accountNumber"
                label="เลขบัญชี *"
                rules={{ required: 'กรุณากรอกเลขบัญชี' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="bankId"
                label="ธนาคาร *"
                rules={{
                  required: 'กรุณาเลือกธนาคาร',
                }}
                select
              >
                {banks.map((b) => (
                  <MenuItem key={b.name} value={b.id}>
                    <FlexBox flexDirection="column">
                      <div>{b.name}</div>
                    </FlexBox>
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                control={control}
                name="branchName"
                label="สาขา *"
                rules={{ required: 'กรุณากรอกสาขา' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              if (!!onClose) onClose({}, 'escapeKeyDown')
            }}
          >
            ยกเลิก
          </Button>
          <Space direction="horizontal" />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            ยืนยันเพิ่มบัญชีธนาคาร
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default VendorBankAccountCreateFormDialog
