export interface SelectedProduct {
  id: number
  code: string
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  amount: number
  price: number
  stockAmount: number
  dealerPrice?: number

  // HACK:
  command?: SQLCommand
  // name in system
  productNameLocal?: string
  productNameEn?: string
}

export interface SelectedUpdationProduct {
  id: number
  productId: number
  code?: string
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  amount: number
  price: number
  command?: SQLCommand
  stockAmount: number
  dealerPrice?: number
}

export enum SQLCommand {
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
}
