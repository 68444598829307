import React, { useEffect, useContext } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Grid, withLayout, Topic, Form } from '../../../../../custom-components'
import { useCustomerPORefs, useCustomerProfile, useProducts, useSaleOrderProfile } from '../../../../../hooks'
import { CustomerProfileCard, Loading, SubmitForm } from '../../../../../shared-components'

import { ProductDiscountBox } from './ProductDiscountBox'
import { ShippingFeeBox } from './ShippingFeeBox'
import { VatBox } from './VatBox'
import { NetTotalContent } from './NetTotalContent'
import { SOInformationBox } from './SOInformationBox'
import { ReceiveProductsOption } from './ReceiveProductsOption'
import { DepositOptionBox } from './DepositOptionBox'
import { AttachmentArea } from './AttachmentArea'
import { SelectedProductTable } from './SelectedProductTable'
import { ProductSelector } from './ProductSelector'
import SOUpdationFormValue from './SOUpdationFormValues'
import mapToFormValues from './mapper'
import { updateSaleOrderService } from './service'
import { RootContext } from '../../../../..'
import { PageName } from '../../../../../enums'
import './SaleOrderUpdationPage.less'
import { Box } from '@material-ui/core'

interface Props {
  id: number
}

const SaleOrderUpdationPage: React.FC<Props> = ({ id }) => {
  const { triggerSnackbar, setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SALE_ORDER)
  const history = useHistory()
  const formMethod = useForm<SOUpdationFormValue>()

  const {
    reset,
    formState: { isSubmitting },
    getValues,
    setValue,
  } = formMethod

  const { billingAddressId } = getValues()

  const saleOrderProfile = useSaleOrderProfile(id)
  const customerProfile = useCustomerProfile(saleOrderProfile?.customer?.id)
  const customerPORefs = useCustomerPORefs(saleOrderProfile?.customer?.id)
  const products = useProducts()

  useEffect(() => {
    if (saleOrderProfile) {
      const values: SOUpdationFormValue = mapToFormValues(saleOrderProfile, products)
      reset(values)
    }
  }, [saleOrderProfile, reset, products])

  if (!saleOrderProfile || !customerProfile) return <Loading />

  // const { customer, contact, billingAddress } = saleOrderProfile
  const { contact } = saleOrderProfile

  const handleSubmitForm = async (values: SOUpdationFormValue) => {
    const response = await updateSaleOrderService(saleOrderProfile, values)
    if (response?.status === 200) {
      triggerSnackbar(`อัพเดตข้อมูล ${saleOrderProfile.code} สำเร็จ`)
      history.replace(`/sale-orders/${saleOrderProfile.id}`)
    } else {
      triggerSnackbar(`อัพเดตข้อมูล ${saleOrderProfile.code} ไม่สำเร็จ`)
    }
    return true
  }

  return (
    <FormProvider {...formMethod}>
      <Box className="sale-order-update-page-header">
        <Topic>แก้ไขใบสั่งขาย</Topic>
      </Box>
      <Form onSubmit={handleSubmitForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomerProfileCard
              contactId={contact?.id}
              customerProfile={customerProfile}
              billingAddressId={billingAddressId}
              billingAddressSelect
              disableBtnCustomerDetail
              onBillingAddressChange={(id: number) => setValue('billingAddressId', id)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductSelector products={products} isDealer={customerProfile.isDealer} />
          </Grid>
          <Grid item xs={12}>
            <SelectedProductTable isDealer={customerProfile.isDealer} />
          </Grid>
          <Grid item xs={6}>
            <ProductDiscountBox />
          </Grid>
          <Grid item xs={6}>
            <ShippingFeeBox />
          </Grid>
          <Grid item xs={12}>
            <VatBox />
          </Grid>
          <Grid item xs={12}>
            <NetTotalContent />
          </Grid>
          <Grid item xs={12}>
            <SOInformationBox customerPORefs={customerPORefs} customerProfile={customerProfile} />
          </Grid>
          <Grid item xs={12}>
            <ReceiveProductsOption />
          </Grid>
          <Grid item xs={12}>
            <DepositOptionBox />
          </Grid>
          <Grid item xs={12}>
            <AttachmentArea />
          </Grid>
          <Grid item xs={12}>
            <SubmitForm
              submitText="บันทึกการแก้ไข"
              cancelText="ยกเลิก"
              redirectPath={`/sale-orders/${saleOrderProfile.id}`}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  )
}

export default withLayout(SaleOrderUpdationPage)
