import React from 'react'

import { Grid } from '@material-ui/core'
import { Radio } from '../../../../custom-components'
import { getAddressText } from '../../../../functions'
import { ICustomerProfile_Address } from '../../../../api/smartbestbuys-api'

interface Props {
  options: ICustomerProfile_Address[]
  value?: number
  onChange: (id: number) => void
}

const BillingAddressRadioGroup: React.FC<Props> = ({ options, value, onChange }) => (
  <Grid container spacing={2}>
    {options.map((option, index) => (
      <Grid item xs={12} key={`adress-${index}`}>
        <Radio
          label={getAddressText(option)}
          value={option.id}
          outlined
          checked={value === option.id}
          onChange={(e) => onChange(Number(e.target.value))}
          color="primary"
        />
      </Grid>
    ))}
  </Grid>
)

export default BillingAddressRadioGroup
