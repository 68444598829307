import { Button, Chip, Grid } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CustomerRequisitionProfileUpdateConditionDialog,
  CustomerRequisitionProfileApproveDialog,
  CustomerRequisitionProfileRejectDialog,
} from '..'
import { RootContext } from '../../../..'
import { FlexBox, TypographyWithLabel } from '../../../../custom-components'
import {
  CustomerRequisitionCondition,
  CustomerRequisitionStatus,
  CustomerRequisitionType,
  PageName,
  TaskStatus,
  TaskType,
  TransportTaskItemType,
  UserAction,
} from '../../../../enums'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { ConditionTooltip, CRStatusChip, MultipleConditionTooltip } from '../../../../shared-components'
import { getUserPermissionInfo } from '../../../../utils/permission'
import { CustomerRequisitionProfileContext } from '../CustomerRequisitionProfilePage'

const CRStatusBar: React.FC = () => {
  const customerRequisitionProfile = useContext(CustomerRequisitionProfileContext)
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.CUSTOMER_REQUISITION)

  const [openUpdateConditionDialog, setOpenUpdateConditionDialog] = useState<boolean>(false)
  const [openApproveDialog, setOpenApprovalDialog] = useState<boolean>(false)
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false)
  const history = useHistory()

  const { id, saleOrderInfo, status, condition, products, tasks, activities } = customerRequisitionProfile

  const hasSomeDoneReceiveTask = tasks.some(
    (t) =>
      [
        TaskType.WaitingCustomerShipProduct,
        TaskType.WaitingCustomerReturnProduct,
        TaskType.WarehouseReceiveProductTask,
      ].includes(t.type.id) && t.status.id === TaskStatus.Done,
  )

  const isShippedAllProducts = !products.find((p) => p.shippedAmount < p.salesAmount)

  const isSmartPickupCondition = CustomerRequisitionCondition.SmartToPickupProduct === condition?.id

  // permission
  const writeTransportPermission = getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write)
  const reviewCRPermission = getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Review)
  const writeCRPermission = getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Write)

  let showStatusChip = {
    pendingToReview: false,
    approved: false,
    rejected: false,
    inProgress: false,
    done: false,
    canceled: false,
  }

  let remark: string | undefined = undefined

  switch (status.id) {
    case CustomerRequisitionStatus.PendingToReview:
      showStatusChip = { ...showStatusChip, pendingToReview: true }
      break
    case CustomerRequisitionStatus.Approved:
      showStatusChip = { ...showStatusChip, approved: true }
      break
    case CustomerRequisitionStatus.Rejected:
      showStatusChip = { ...showStatusChip, rejected: true }
      remark = activities.find((ua) => ua.action.id === UserAction.RejectCustomerRequisition)?.remark
      break
    case CustomerRequisitionStatus.InProgress:
      showStatusChip = { ...showStatusChip, inProgress: true }
      break
    case CustomerRequisitionStatus.Done:
      showStatusChip = { ...showStatusChip, done: true }
      break
    case CustomerRequisitionStatus.Canceled:
      showStatusChip = { ...showStatusChip, canceled: true }
      remark = activities.find((ua) => ua.action.id === UserAction.CancelCustomerRequisition)?.remark
      break
  }

  // available transport pickup task should have only 1 task per customer requisition
  const hasAvailablePickupTask = customerRequisitionProfile.tasks.some(
    (t) =>
      t.type.id === TaskType.TransportPickupTask &&
      [
        TaskStatus.Pending,
        TaskStatus.Processing,
        TaskStatus.TransportPending,
        TaskStatus.PendingApproval,
        TaskStatus.Done,
      ].includes(t.status.id),
  )

  const ableToCreatePickupTask = writeTransportPermission.hasPermission && !hasAvailablePickupTask

  const availableTransportTasks = tasks.filter((tt) => {
    const messengerTask = tasks.find((t) => t.parentTaskId === tt.id && t.type.id === TaskType.MessengerShippingTask)

    const hasNoAvailableMessengerTask =
      !messengerTask ||
      ![TaskStatus.Failed, TaskStatus.Canceled, TaskStatus.PreCancel].includes(messengerTask.status.id)

    return (
      ![TaskStatus.Canceled, TaskStatus.PreCancel].includes(tt.status.id) &&
      tt.type.id === TaskType.TransportScheduleTask &&
      hasNoAvailableMessengerTask
    )
  })

  const pendingToTransportProducts = availableTransportTasks.reduce<
    { productId: number; pendingToTransportAmount: number }[]
  >((summary, each) => {
    const transportProducts = each.state?.items?.filter((item) => item.type === TransportTaskItemType.Product)

    if (transportProducts && transportProducts.length > 0) {
      transportProducts.forEach((tp) => {
        const index = summary.findIndex((s) => s.productId === tp.id) ?? -1

        if (index !== -1) {
          summary[index].pendingToTransportAmount += tp.amount
        } else {
          summary = [...summary, { productId: tp.id, pendingToTransportAmount: tp.amount }]
        }
      })
    }

    return summary
  }, [])

  const transportTaskCreationConditions = [
    {
      showTooltip: !writeTransportPermission.hasPermission,
      message: writeTransportPermission.alertMessage,
    },
    {
      showTooltip: products.every((p) => {
        const pendingToTransportProduct = pendingToTransportProducts.find((tp) => tp.productId === p.productId)

        return pendingToTransportProduct && p.amount === pendingToTransportProduct.pendingToTransportAmount
      }),
      message: 'สินค้าทุกตัว มีงานขนส่งกำลังดำเนินการแล้ว',
    },
  ]

  return (
    <>
      <CustomerRequisitionProfileUpdateConditionDialog
        customerRequisitionId={id}
        saleOrderId={saleOrderInfo.id}
        open={openUpdateConditionDialog}
        onClose={() => setOpenUpdateConditionDialog(false)}
      />
      <CustomerRequisitionProfileApproveDialog
        customerRequisitionId={id}
        open={openApproveDialog}
        onClose={() => setOpenApprovalDialog((prev) => !prev)}
      />
      <CustomerRequisitionProfileRejectDialog
        customerRequisitionId={id}
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog((prev) => !prev)}
      />
      <Grid container>
        {showStatusChip.pendingToReview && (
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between" alignItems="center">
              <CRStatusChip
                icon
                status={customerRequisitionProfile.status.id}
                label={customerRequisitionProfile.status.name}
              />
              {reviewCRPermission.hasPermission && (
                <FlexBox gridGap={8} alignItems="center" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<Cancel />}
                    onClick={() => setOpenRejectDialog(true)}
                  >
                    ไม่อนุมัติ
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: '#4CAF50', color: '#FFF' }}
                    startIcon={<CheckCircle />}
                    onClick={() => setOpenApprovalDialog(true)}
                  >
                    อนุมัติ
                  </Button>
                </FlexBox>
              )}
            </FlexBox>
          </Grid>
        )}
        {showStatusChip.approved && (
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <CRStatusChip
                icon
                status={customerRequisitionProfile.status.id}
                label={customerRequisitionProfile.status.name}
              />
              <ConditionTooltip title={writeCRPermission.alertMessage} showTooltip={!writeCRPermission.hasPermission}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setOpenUpdateConditionDialog((prev) => !prev)}
                  disabled={!writeCRPermission.hasPermission}
                >
                  เลือกวิธีรับของจากลูกค้า
                </Button>
              </ConditionTooltip>
            </FlexBox>
          </Grid>
        )}
        {(showStatusChip.rejected || showStatusChip.canceled) && (
          <FlexBox gridGap={16} alignItems="center">
            <CRStatusChip
              icon
              status={customerRequisitionProfile.status.id}
              label={customerRequisitionProfile.status.name}
            />
            {!!remark && (
              <TypographyWithLabel
                variant="body2"
                label={showStatusChip.rejected ? 'สาเหตุที่ไม่อนุมัติ:' : 'สาเหตุที่ยกเลิก:'}
                color="error"
              >
                {remark}
              </TypographyWithLabel>
            )}
          </FlexBox>
        )}
        {showStatusChip.inProgress && (
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between" alignItems="center">
              <FlexBox gridGap={8} alignItems="center">
                <CRStatusChip
                  icon
                  status={customerRequisitionProfile.status.id}
                  label={customerRequisitionProfile.status.name}
                />
                {!!condition && (
                  <Chip label="เลือกวิธีรับของจากลูกค้าแล้ว" style={{ backgroundColor: '#7BC67E' }}></Chip>
                )}
              </FlexBox>
              {isSmartPickupCondition && !hasSomeDoneReceiveTask ? (
                <MultipleConditionTooltip
                  conditionItems={[
                    {
                      showTooltip: !writeTransportPermission.hasPermission,
                      message: writeTransportPermission.alertMessage,
                    },
                    {
                      showTooltip: hasAvailablePickupTask,
                      message: 'มีงานบริการรับสินค้าแล้ว',
                    },
                  ]}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!ableToCreatePickupTask}
                    onClick={() =>
                      history.push(
                        `/transport-tasks/create-pickup-task?customerRequisitionId=${id}&saleOrderId=${saleOrderInfo.id}`,
                      )
                    }
                  >
                    สร้างงานไปรับสินค้าจากลูกค้า
                  </Button>
                </MultipleConditionTooltip>
              ) : hasSomeDoneReceiveTask &&
                !isShippedAllProducts &&
                customerRequisitionProfile.type.id === CustomerRequisitionType.Claim ? (
                <MultipleConditionTooltip conditionItems={transportTaskCreationConditions}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={transportTaskCreationConditions.some((con) => con.showTooltip)}
                    onClick={() =>
                      history.push(`/transport-tasks/${saleOrderInfo.id}/create?customerRequisitionId=${id}`)
                    }
                  >
                    สร้างงานส่งสินค้าคืนลูกค้า
                  </Button>
                </MultipleConditionTooltip>
              ) : (
                <></>
              )}
            </FlexBox>
          </Grid>
        )}
        {showStatusChip.done && (
          <FlexBox gridGap={16}>
            <CRStatusChip
              icon
              status={customerRequisitionProfile.status.id}
              label={customerRequisitionProfile.status.name}
            />
          </FlexBox>
        )}
      </Grid>
    </>
  )
}

export default CRStatusBar
