import { useEffect, useState } from 'react'
import {
  IProductWithdrawalTicketProfile,
  getProductWithdrawalTicketProfileAPI,
} from '../../api/smartbestbuys-api/warehouse'
import { IUseHook } from '../interfaces'

export const useProductWithdrawalTicketProfile = (id: number): IUseHook<IProductWithdrawalTicketProfile> => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [data, setData] = useState<IProductWithdrawalTicketProfile | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await getProductWithdrawalTicketProfileAPI(id)
        if (response?.data) {
          setData(response.data)
        } else {
          setErrorMessage('Data not found')
        }
        setIsLoading(false)
      } catch (error) {
        setErrorMessage('failed to access API')
        setIsLoading(false)
      }
    })()
  }, [id])

  return {
    data,
    isLoading,
    updateData: setData,
    errorMessage,
  }
}
