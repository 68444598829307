import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { updateTaskAssigneeById } from '../../../api/smartbestbuys-api/tasks/task-apis'
import { UpdateAssigneeURLParams } from '../../../api/smartbestbuys-api/tasks/task-types'
import { Form, GeneralTextFieldForm } from '../../../custom-components'
import { useUserData } from '../../../hooks/data/useUserData'
import { TransportTaskProfilePageContext } from './TransportTaskProfilePage'

interface Props extends DialogProps {
  onComplete: (data: any) => void
  onFail: () => void
}

const TransportTaskProfileAssigneeUpdateDialog: React.FC<Props> = (props) => {
  const { onClose, onComplete, onFail, ...otherProps } = props
  const { transportTaskProfile } = useContext(TransportTaskProfilePageContext)
  const users = useUserData()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<any>({
    defaultValues: {
      userId: transportTaskProfile.assignee?.userId,
    },
  })

  const handleSubmitAssigneeUpdateForm = async (value: any) => {
    const params: UpdateAssigneeURLParams = {
      id: transportTaskProfile.id,
      typeId: transportTaskProfile.type.id,
    }
    const response = await updateTaskAssigneeById(params, value)
    if (response?.status === 200) {
      onComplete(response.data)
    } else {
      onFail()
    }
    return true
  }

  return (
    <Dialog fullWidth maxWidth="sm" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitAssigneeUpdateForm)}>
        <DialogTitle>แก้ไขผู้รับผิดชอบ</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <GeneralTextFieldForm control={control} name="userId" label="ผู้รับผิดชอบ" defaultValue="" select>
                {users?.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                ))}
              </GeneralTextFieldForm>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : undefined}
          >
            ยืนยันการแก้ไข
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default TransportTaskProfileAssigneeUpdateDialog
