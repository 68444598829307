import { BaseMultiLangData } from '../api/smartbestbuys-api'

export const getAddressText = (address?: {
  branchName?: string
  addressLine1: string
  subDistrict?: BaseMultiLangData
  district?: BaseMultiLangData
  province?: BaseMultiLangData
  postalCode?: string
}): string => {
  if (!address) return ''
  const { branchName, addressLine1, subDistrict, district, province, postalCode } = address
  const addressInfo = [
    branchName,
    addressLine1,
    subDistrict
      ? province
        ? mapBangkokPrefix(province, 'แขวง', 'ตำบล') + subDistrict.nameLocal
        : subDistrict.nameLocal
      : undefined,
    district
      ? province
        ? mapBangkokPrefix(province, 'เขต', 'อำเภอ') + district.nameLocal
        : district.nameLocal
      : undefined,
    province ? mapBangkokPrefix(province, '', 'จังหวัด') + province?.nameLocal : undefined,
    postalCode,
  ]
  return addressInfo.filter((a) => a).join(' ')
}

const mapBangkokPrefix = (province: BaseMultiLangData, str: string, str2: string) => (province.id === 1 ? str : str2)
