export const customerBusinessTypes = [
  // { id: 1, name: 'บริษัท/หจก/หสม', detail: '' }, // doesn't use
  { id: 2, name: 'ราชการ', detail: 'หน่วยงานของรัฐ, สถานที่สาธารณะ' },
  // { id: 3, name: 'บุคคลธรรมดา', detail: '' }, // doesn't use
  {
    id: 4,
    name: 'โครงการที่อยู่อาศัย',
    detail: 'คอนโดมิเนียม, หมู่บ้านจัดสรร, นิติบุคคลอาคารชุด, สำนักงานอาคาร, อพาร์ทเมนท์',
  },
  {
    id: 5,
    name: 'อุตสาหกรรมและการผลิต',
    detail: 'โรงงานผลิต, โกดังสินค้า',
  },
  {
    id: 6,
    name: 'ห้างสรรพสินค้า',
    detail: 'ศูนย์การค้า, ร้านค้าปลีก, ตลาด',
  },
  {
    id: 7,
    name: 'ภาคบริการที่พัก',
    detail: 'โรงแรม, บังกะโล, รีสอร์ท, หอพัก',
  },
  {
    id: 8,
    name: 'สถาบันการศึกษา',
    detail: 'โรงเรียน, มหาวิทยาลัย',
  },
  {
    id: 9,
    name: 'ภาคก่อสร้าง',
    detail: 'ผู้รับเหมา, ซื้อไปติดตั้งให้ลูกค้า, สถาปนิก, วิศวกร',
  },
  {
    id: 10,
    name: 'บริษัทขนาดเล็กถึงกลาง',
    detail: 'ร้านอาหาร, ร้านกาแฟ, ร้านซักรีด, สตูดิโอ',
  },
  {
    id: 11,
    name: 'ตัวแทนจำหน่าย',
    detail: 'ซื้อไปขายต่อแต่ไม่ได้ติดตั้งให้, การฝากขาย',
  },
  {
    id: 12,
    name: 'ผู้บริโภครายบุคคล',
    detail: 'ใช้งานภายในบ้านหรือส่วนตัว',
  },
  {
    id: 13,
    name: 'โรงพยาบาลเอกชน',
    detail: 'โรงพยาบาลเอกชน',
  },
]
