import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler } from '../../middleware'
import { BaseData } from '../types'

const path = '/sale-orders/v1/sale-orders/shipping-pending'

export const getSaleOrderShippingPending = errorHandler<SaleOrdersShippingPendingRowData[]>(async () => {
  const results = await smartAuthAPI.get(path)
  return results
})

export interface SaleOrdersShippingPendingRowData {
  id: number
  code: string
  status: BaseData
  paymentStatus: BaseData
  customerName: string
  customerPhoneNumber: string
  paymentMethod: BaseData
  total: number
  hasTransportTaskProcessing: boolean
  recCreatedAt: Date
  recCreatedBy: string
}
