import { useEffect } from 'react'
import { Grid, InputAdornment, MenuItem, Paper, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { VendorBankAccount } from '../../../api/smartbestbuys-api/vendor/vendor-types'
import { GeneralTextFieldForm, Title } from '../../../custom-components'
import { ButtonRadios, Space } from '../../../shared-components'
import { purchasePaymentMethods } from '../../../types'
import { PaymentRequisitionCreateFormValues } from './PaymentRequisitionCreatePage'
import { CurrenciesType } from '../../../enums'
import { BaseData } from '../../../api/smartbestbuys-api/types'

interface VendorInfo {
  refPaymentReference?: BaseData
  currency: BaseData
  bankAccounts: VendorBankAccount[]
}

interface Props {
  vendorInfo: VendorInfo
}

const PaymentRequisitionFormBox = (props: Props) => {
  // props
  const { vendorInfo } = props
  const { refPaymentReference, currency, bankAccounts } = vendorInfo

  // hook form
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<PaymentRequisitionCreateFormValues>()
  const { paymentMethodId } = watch()

  // effect
  useEffect(() => {
    register('paymentMethodId', {
      required: 'กรุณาเลือกวิธีการชำระเงิน',
    })
  }, [register])

  return (
    <Paper>
      <Title>เลือกช่องทางการจ่ายเงิน</Title>
      <Space />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ButtonRadios
            label="วิธีการจ่ายเงิน *"
            items={purchasePaymentMethods}
            onChange={(value) => setValue('paymentMethodId', value)}
            errorMessage={errors.paymentMethodId?.message}
            value={paymentMethodId}
          />
          {refPaymentReference && (
            <>
              <Space size="small" />
              <Typography color="textSecondary">(อ้างอิงวิธีการชำระจากผู้ขาย {refPaymentReference.name})</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralTextFieldForm
            control={control}
            name="vendorBankAccountId"
            defaultValue=""
            rules={{ required: 'กรุณากรอกบัญชีธนาคารที่ใช้' }}
            label="บัญชีธนาคาร *"
            select
          >
            {bankAccounts.map((bankAccount) => (
              <MenuItem key={bankAccount.id} value={bankAccount.id}>
                {`${bankAccount.bank.name} ${bankAccount.accountNumber} (${bankAccount.accountName})`}
              </MenuItem>
            ))}
          </GeneralTextFieldForm>
        </Grid>
        {currency.id !== CurrenciesType.THB && (
          <Grid item xs={12} md={6}>
            <GeneralTextFieldForm
              control={control}
              name="exchangeRate"
              type="number"
              label="อัตราแลกเปลี่ยน *"
              defaultValue=""
              rules={{ required: 'กรุณากรอกอัตราแลกเปลี่ยนที่ใช้' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="start">บาท</InputAdornment>,
              }}
              inputProps={{
                step: '0.01',
              }}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default PaymentRequisitionFormBox
