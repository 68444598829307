import { BaseData } from '../api/smartbestbuys-api/types'

// TODO: should move out from types
export const paymentMethods: BaseData[] = [
  { id: 1, name: 'เงินสด' },
  { id: 2, name: 'โอน' },
  { id: 3, name: 'บัตรเครดิต / บัตรเดบิต' },
  { id: 4, name: 'เช็ค' },
]

// TODO: should move out from types
export const purchasePaymentMethods: BaseData[] = [
  { id: 2, name: 'โอน' },
  { id: 4, name: 'เช็ค' },
]

export const getPaymentMethodNameById = (id: number): string => {
  const paymentMethod = paymentMethods.find((pc) => pc.id === id)
  return paymentMethod ? paymentMethod.name : 'unknown'
}
