import { createContext } from 'react'
import { SalesPaymentTransaction, SalesReport } from '../../api/smartbestbuys-api'

interface PaymentTransactionData {
  approved: SalesPaymentTransaction[]
  pending: SalesPaymentTransaction[]
  canceled: SalesPaymentTransaction[]
  rejected: SalesPaymentTransaction[]
}
export interface PaymentTransactionInfo {
  cash: PaymentTransactionData
  transfer: PaymentTransactionData
  credit: PaymentTransactionData
  cheque: PaymentTransactionData
}
interface ContextProps {
  salesReport?: SalesReport
  paymentTransactionInfo?: PaymentTransactionInfo
}

const init = {
  salesReport: undefined,
  paymentTransactionInfo: undefined,
}

const SalesDashboardContext = createContext<ContextProps>(init)

export default SalesDashboardContext
