import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../../..'
import { PermissionTopic } from '../../../../api/smartbestbuys-api'
import { createPermissionGroupDataAPI } from '../../../../api/smartbestbuys-api/data/data-apis'
import {
  PermissionGroupData,
  PermissionGroupDataCreateRequestBody,
} from '../../../../api/smartbestbuys-api/data/data-types'
import PermissionGroupForm from '../PermissionGroupForm'
import { PermissionGroupFormValues } from '../PermissionGroupFormValue'

interface CreatePermissionGroupDialogProps extends DialogProps {
  permissionTopics: PermissionTopic[]
  onComplete?: (data: PermissionGroupData) => void
  onClose: () => void
}

const PermissionGroupCreateDialog = (props: CreatePermissionGroupDialogProps) => {
  const { triggerSnackbar } = useContext(RootContext)

  const { permissionTopics, onClose, onComplete, ...dialogProps } = props

  const handleSubmit = async (values: PermissionGroupFormValues) => {
    const request: PermissionGroupDataCreateRequestBody = {
      permissionGroupName: values.permissionGroupName,
      permissionIds: values.permissionIds,
    }
    try {
      const response = await createPermissionGroupDataAPI(request)
      if (response && response.status === 201) {
        triggerSnackbar('เพิ่มกลุ่มสิทธิ์การใช้งานสำเร็จ')
        if (onComplete) onComplete(response.data)
      } else {
        triggerSnackbar('เพิ่มกลุ่มสิทธิ์การใช้งานไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('เพิ่มกลุ่มสิทธิ์การใช้งานไม่สำเร็จ')
    }
  }

  return (
    <Dialog fullWidth maxWidth="lg" {...dialogProps}>
      <DialogTitle>เพิ่มกลุ่มสิทธิ์การใช้งาน</DialogTitle>
      <DialogContent>
        <PermissionGroupForm permissionTopics={permissionTopics} onSubmit={handleSubmit} onCancel={onClose} />
      </DialogContent>
    </Dialog>
  )
}

export default PermissionGroupCreateDialog
