import { Box, Grid, Paper, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'
import { VendorProfile_HistoryPurchaseOrder, VendorProfile_PaymentRequisition } from '../../../../api/smartbestbuys-api'
import { PurchaseOrderPaymentStatus, PurchaseOrderStatus } from '../../../../enums'
import { Space } from '../../../../shared-components'
import VendorPaymentRequisitionTable from './VendorPaymentRequisitionTable'
import VendorPurchasesHistoryTable from './VendorPurchasesHistoryTable'

enum VendorPurchasesInformationTableType {
  History = 1,
  Order = 2,
}
interface VendorInfo {
  id: number
  historyPurchaseOrders?: VendorProfile_HistoryPurchaseOrder[]
  paymentRequisitions?: VendorProfile_PaymentRequisition[]
}

interface Props {
  vendorInfo: VendorInfo
}

const VendorPurchasesInformationBox: React.FC<Props> = (props: Props) => {
  //props
  const { vendorInfo } = props
  const { id, historyPurchaseOrders, paymentRequisitions } = vendorInfo

  //state
  const [tableType, setTableType] = useState<VendorPurchasesInformationTableType>(
    VendorPurchasesInformationTableType.History,
  )

  const ableToCreatePayment = !!historyPurchaseOrders?.find(
    (po) =>
      po.paymentStatus.id !== PurchaseOrderPaymentStatus.Deposited &&
      ![PurchaseOrderStatus.Canceled, PurchaseOrderStatus.Rejected].includes(po.status.id),
  )

  return (
    <Box p={3} clone>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              style={{
                marginBottom: 4,
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
              }}
              value={tableType}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={(_, newValue) => setTableType(newValue)}
            >
              <Tab label="ประวัติใบสั่งซื้อ" value={VendorPurchasesInformationTableType.History} />
              <Tab
                label={`รายการทำเบิก (${paymentRequisitions?.length ?? 0})`}
                value={VendorPurchasesInformationTableType.Order}
              />
            </Tabs>
            <Space />
            {tableType === VendorPurchasesInformationTableType.History ? (
              <VendorPurchasesHistoryTable historyPurchaseOrders={historyPurchaseOrders ?? []} />
            ) : (
              <VendorPaymentRequisitionTable
                vendorId={id}
                ableToCreatePayment={ableToCreatePayment}
                paymentRequisitions={paymentRequisitions ?? []}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default VendorPurchasesInformationBox
