import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { fetchMessengerTasksAPI, IMessengerTaskMobileData } from '../../api/smartbestbuys-api'
import { dateSqlFormat } from '../../functions'

export const useMessengerTasks = (
  dueDate: Date,
): [IMessengerTaskMobileData[], Dispatch<SetStateAction<IMessengerTaskMobileData[]>>] => {
  const [tasks, setTasks] = useState<IMessengerTaskMobileData[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await fetchMessengerTasksAPI({ dueDate: dateSqlFormat(dueDate, 'yyyy-MM-dd') })
      const data = response?.data ?? []

      setTasks(data)
    })()
  }, [dueDate])

  return [tasks, setTasks]
}
